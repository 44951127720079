import { Field } from 'react-final-form'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'

import CustomLabel from './CustomLabel'

const textArea = {
  border: '1px solid #CCC',
  borderRadius: 5,
  width: '100%',
  height: 40,
  paddingLeft: 5
}

type InputType = {
  label?: string
  name?: any
  isDisabled?: boolean
  options?: any
  isMulti?: boolean
}

export default function Select({
  label,
  name,
  isDisabled = false,
  options,
  isMulti = true
}: InputType) {
  return (
    <div key={`${name}*${label}`} style={{ width: '-webkit-fill-available' }}>
      {label && <CustomLabel label={label} />}
      <Field name={name} style={textArea}>
        {({ input, meta }) => (
          <div key={`${name}^${label}`}>
            <ReactSelect {...input} options={options} isMulti={isMulti} />
            {meta.error && meta.touched && (
              <span style={{ color: 'red', fontWeight: 500 }}>
                {meta.error}
              </span>
            )}
          </div>
        )}
      </Field>
    </div>
  )
}
