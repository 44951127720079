import moment from 'moment'
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import { useRef } from 'react'

import { PrintHeaderLogos } from './PrintHeaderLogos'
import { PrintHistory } from './PrintHistory'
import { ViewCloseOutDetails } from '../../pages/ViewCM'
import Accknowledgements from './../previews/ViewAccknowledgements'
import BPUTable from './../Tables/PBUTable'
import GasReadingsTable from './../Tables/GasReadingsTable'
import PrintPermitDetails from '../previews/PrintPermitDetails'
import PrintPermitHeader from './PrintPermitHeader'
import PrintPermitIsolations from '../previews/PrintPermitIsolations'
import PrintRiskAssessment from './PrintRiskAssessment'
import ReactToPrint from 'react-to-print'
import Spacer from './../Spacer'
import theme from './../../components/constants/defaultTheme.json'
import toNormalCase from './../lib/toNormalCase'
import ToolBoxMeeting from './../ToolBoxTalkMeeting'
import ViewConfinedSpace from './../previews/ViewConfinedSpace'
import ViewExcavation from './../previews/ViewExcavationPermit'
import ViewGasReadings from './../previews/ViewGasReadings'
import ViewPBUReadings from './../previews/ViewPBUReadings'
import ViewPositiveIsolation from './../previews/ViewPositiveIsolation'
import ViewVehiclePermit from './../previews/ViewVehiclePermit'
import './../css/print.css'
import ViewWorkAtHeight from '../previews/ViewWorkAtHeight'

export function Text({ children, styles }: any) {
  return (
    <p style={{ margin: 0, lineHeight: 2, textOverflow: 'clip', ...styles }}>
      {children}
    </p>
  )
}

type PreviewFormType = {
  attachments?: any
  gasReadings?: any
  isolationHistory?: any
  isolationValues?: any
  pbuData?: any
  permitHistory?: any
  riskAssesmentHistory?: any
  riskAssesmentValues?: any
  selectedPermit?: any
  showStatus?: any
  refetch?: any
  viewNextPermit?: any
  riskAssesment?: any
  report?: any
  isOpen?: any
  toggle: any
}

export const toNormalDate = (date: any) => moment(date).format('DD-MM-YYYY')

export const getPermitHeaderDetails = (selectedPermit: any) => ({
  'Permit Number': selectedPermit?.woNumber,
  'Permit Type': toNormalCase(selectedPermit?.type),
  'Start Date': toNormalDate(selectedPermit?.startDate),
  'End Date': toNormalDate(selectedPermit?.endDate)
  // Status: <b>{toNormalCase(selectedPermit?.status)}</b>
})

export const getUserData = (selectedPermit: any) => ({
  Name: selectedPermit?.applicantName,
  Designation: selectedPermit?.applicantDesignation,
  Department: selectedPermit?.applicantDepartment,
  'Employee Id': selectedPermit?.employeeId
})

export const permitColor: any = (permitType: any) =>
  permitType === 'hotWork'
    ? theme.workPermit.hotWork
    : theme.workPermit.coldWork

const renderTitle = (title: string) => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <Text>
      <b>{title}</b>
    </Text>
    <Text>:</Text>
  </div>
)

export const rowStyle: any = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'auto',
  paddingTop: 5,
  paddingBottom: 5,
  fontSize: 16,
  WebkitPrintColorAdjust: 'exact'
}

export default function PrintPermit({
  attachments,
  gasReadings,
  isolationHistory,
  isolationValues,
  pbuData,
  permitHistory,
  riskAssesmentHistory,
  riskAssesmentValues,
  selectedPermit,
  showStatus,
  report,
  isOpen,
  toggle
}: PreviewFormType) {
  const permitType = selectedPermit?.type

  const defaultStyles: any = {
    ...rowStyle,
    backgroundColor: permitColor(permitType)
  }

  const renderRow = (title: string, content?: any) => (
    <div key={title}>
      <Row style={{ margin: 0 }}>
        <Col
          xs="3"
          style={{
            backgroundColor:
              permitType === 'hotWork'
                ? theme.workPermit.hotWork
                : theme.workPermit.coldWork,
            WebkitPrintColorAdjust: 'exact'
          }}
        >
          {renderTitle(title)}
        </Col>
        <Col xs="9">
          <Text>{content || 'NA'}</Text>
        </Col>
      </Row>
    </div>
  )

  const titleAndDescription: any = {
    'Permit Title': selectedPermit?.permitTitle,
    'Work Description': selectedPermit?.workDescription
  }

  const componentRef: any = useRef()

  return (
    <Modal isOpen={isOpen} size="xl" toggle={toggle}>
      <ModalBody>
        <div
          style={{
            fontSize: 14,
            fontFamily: 'Nunito, sans-serif',
            backgroundColor: '#FFF',
            padding: 2
          }}
          ref={componentRef}
          className="print-page-break"
        >
          <Row>
            <Col>
              <PrintHeaderLogos permitType={permitType} />
            </Col>
          </Row>
          <PrintPermitHeader
            permitData={selectedPermit}
            printData={selectedPermit}
          />
          <Spacer height={10} />
          {Object.keys(titleAndDescription).map((key) =>
            renderRow(key, titleAndDescription[key])
          )}
          <PrintPermitDetails
            rowStyle={defaultStyles}
            selectedPermit={selectedPermit}
            renderRow={renderRow}
          />
          {gasReadings?.length > 0 && (
            <ViewGasReadings data={gasReadings} rowStyle={defaultStyles} />
          )}
          {/* {permitHistory?.length > 0 && (
            <PrintHistory
              data={permitHistory}
              title="Permit Approval History"
              permitColor={permitColor(permitType)}
            />
          )} */}
          <div>
            {selectedPermit?.isJSA && (
              <div className="print-page-break">
                <PrintRiskAssessment
                  permitData={selectedPermit}
                  data={
                    riskAssesmentValues?.template?._id
                      ? riskAssesmentValues?.template
                      : riskAssesmentValues
                  }
                  rowStyle={defaultStyles}
                  renderRow={renderRow}
                />
              </div>
            )}
            {/* {riskAssesmentHistory?.length > 0 &&
              selectedPermit?.isJSA &&
              selectedPermit?.riskAssesmentPermit && (
                <PrintHistory
                  data={riskAssesmentHistory}
                  title="Risk Assessment Approval History"
                  permitColor={permitColor(permitType)}
                />
              )} */}
          </div>
          {isolationValues?.length > 0 && (
            <div className="print-page-break">
              {isolationValues?.length > 0 && (
                <>
                  <PrintPermitIsolations
                    data={
                      selectedPermit?.Isolations ||
                      selectedPermit?.isolations?.isolations
                    }
                    permitData={selectedPermit}
                    rowStyle={defaultStyles}
                    renderRow={renderRow}
                    showPrintHeader
                  />
                  <PrintHistory
                    data={isolationHistory}
                    title="Isolations Approval History"
                    permitColor={permitColor(permitType)}
                  />
                </>
              )}
              {pbuData?.length > 0 && (
                <ViewPBUReadings data={pbuData} rowStyle={defaultStyles} />
              )}
              {/* {isolationHistory?.length > 0 && (
              <PrintHistory
                data={isolationHistory}
                title="Isolations Approval History"
                permitColor={permitColor(permitType)}
              />
            )} */}
            </div>
          )}
          {attachments?.vehicle && (
            <div>
              <div className="print-page-break" />
              <ViewVehiclePermit
                showPrintHeader
                permitData={selectedPermit}
                data={attachments?.vehicle}
                rowStyle={defaultStyles}
                renderRow={renderRow}
              />
              {permitHistory?.length > 0 && (
                <PrintHistory
                  data={permitHistory}
                  title="Vehicle Entry Permit Approval History"
                  permitColor={permitColor(permitType)}
                />
              )}
            </div>
          )}
          {attachments?.excavation && (
            <div>
              <div className="print-page-break" />
              <ViewExcavation
                showPrintHeader
                permitData={selectedPermit}
                data={attachments?.excavation}
                rowStyle={defaultStyles}
                renderRow={renderRow}
              />
              {permitHistory?.length > 0 && (
                <PrintHistory
                  data={permitHistory}
                  title="Excavation Permit Approval History"
                  permitColor={permitColor(permitType)}
                />
              )}
            </div>
          )}
          {attachments?.positiveIsolation && (
            <div>
              <div className="print-page-break" />
              <ViewPositiveIsolation
                showPrintHeader
                permitData={selectedPermit}
                data={attachments?.positiveIsolation}
                rowStyle={defaultStyles}
                renderRow={renderRow}
              />
              {permitHistory?.length > 0 && (
                <PrintHistory
                  data={permitHistory}
                  title="Positive Isolation Permit Approval History"
                  permitColor={permitColor(permitType)}
                />
              )}
            </div>
          )}
          {attachments?.confinedSpace && (
            <div>
              <div className="print-page-break" />
              <ViewConfinedSpace
                showPrintHeader
                permitData={selectedPermit}
                data={attachments?.confinedSpace}
                rowStyle={defaultStyles}
                renderRow={renderRow}
              />
              {permitHistory?.length > 0 && (
                <PrintHistory
                  data={permitHistory}
                  title="Confined Space Entry Permit Approval History"
                  permitColor={permitColor(permitType)}
                />
              )}
            </div>
          )}
          {attachments?.workAtHeight && (
            <div>
              <div className="print-page-break" />
              <ViewWorkAtHeight
                showPrintHeader
                permitData={selectedPermit}
                data={attachments?.workAtHeight}
                rowStyle={defaultStyles}
                renderRow={renderRow}
              />
            </div>
          )}
          {/* {selectedPermit?.consent && (
            <div className="print-page-break">
              <Accknowledgements
                data={selectedPermit?.consent}
                rowStyle={defaultStyles}
              />
            </div>
          )} */}
          <div className="print-page-break">
            {selectedPermit?.status === 'live' && (
              <ToolBoxMeeting
                rowStyle={defaultStyles}
                selectedPermit={selectedPermit}
              />
            )}
          </div>
          <div>
            {selectedPermit?.status === 'live' && (
              <GasReadingsTable
                rowStyle={defaultStyles}
                measureMents={selectedPermit?.gasMeasurements}
              />
            )}
          </div>
          <div>
            {selectedPermit?.status === 'live' && (
              <BPUTable rowStyle={defaultStyles} isolations={isolationValues} />
            )}
          </div>
          {selectedPermit?.status === 'closed' && report && (
            <ViewCloseOutDetails data={report} epermit />
          )}
          {(selectedPermit?.status === 'live' ||
            selectedPermit?.status === 'closed') && (
            <ModalFooter>
              <Button onClick={() => toggle()}>Cancel</Button>
              <ReactToPrint
                documentTitle={selectedPermit?.woNumber}
                pageStyle={'padding: 20'}
                trigger={() => <Button color="warning" children="Print" />}
                content={() => componentRef.current}
              />
            </ModalFooter>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}
