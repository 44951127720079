import { Button, Col, ModalFooter, Row } from 'reactstrap'
import { useContext, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import ProtectedLayout from '../components/Layout/ProtectedLayout'
import Spacer from '../components/Spacer'
import { Box, renderData } from './workManagement/PmTree'
import { getReadableTime } from './workManagement/WorkOrderDetails'
import { makeAuthenticatedRequest } from '../apiRequests'
import { UserContext } from '../App'
import { usersEndpoint } from '../components/serverurl'
import SubmitModalWithForm from '../components/Modals/SubmitModalWithForm'
import { Form } from 'react-final-form'
import {
  CrewDetails,
  Destination,
  EmergencyContact
} from './travel/CreateTravelForm'
import toNormalCase from '../components/lib/toNormalCase'

const PassportDetails = ({
  data,
  accounts,
  instance,
  refetch
}: {
  data: any
  accounts: any
  instance: any
  refetch: any
}) => {
  const passportInformation = data?.passport
  const [updateModal, setUpdateModal]: any = useState(false)
  const [isSubmitting, setIsSubmitting]: any = useState(false)
  const [isSuccess, setIsSuccess]: any = useState(false)
  const [isFailure, setIsFailure]: any = useState(false)

  const updateInfo = async (values: any) => {
    setIsSubmitting(true)

    try {
      const body: any = { ...values, _id: passportInformation?._id }
      const res = await makeAuthenticatedRequest(
        `${usersEndpoint}/${data?._id}`,
        'PATCH',
        body,
        accounts,
        instance
      )

      if (res?.status === 'success') {
        setIsSuccess(true)
        refetch()
      }
    } catch (error) {
      setIsSubmitting(false)
      setIsFailure(true)
    }

    return null
  }

  const resetState = () => {
    setIsSubmitting(false)
    setIsFailure(false)
    setIsSuccess(false)
    setUpdateModal(false)
  }

  const passportDetails: any = {
    number: passportInformation?.number,
    firstName: passportInformation?.firstName,
    middleName: passportInformation?.middleName,
    lastName: passportInformation?.lastName,
    issuePlace: passportInformation?.issuePlace,
    dob: getReadableTime(passportInformation?.dob),
    issue: getReadableTime(passportInformation?.issue),
    expiry: getReadableTime(passportInformation?.expiry)
  }

  return (
    <Box heading="Passport Details">
      {!passportInformation?.number ? (
        <center>Passport Information not found</center>
      ) : (
        <>
          {Object.keys(passportDetails)?.map((k: any) =>
            renderData(toNormalCase(k), passportDetails[k] || 'NA')
          )}
        </>
      )}
      <Spacer height={10} />
      <ModalFooter>
        <Button color="warning" onClick={() => setUpdateModal(true)}>
          {!passportInformation?.number
            ? 'Add Information'
            : 'Update Information'}
        </Button>
      </ModalFooter>
      <SubmitModalWithForm
        isOpen={updateModal}
        onClose={(val: any) => {
          setUpdateModal(false)
        }}
        // onSubmit={async (e: any) => await updateInfo(e)}
        size="lg"
        header="Update Passport Information"
        showDefaultButtons={false}
      >
        {isSuccess ? (
          <div className="error-message">
            Form submission successfull.
            <ModalFooter>
              <Button
                type="button"
                color="warning"
                onClick={(e) => {
                  e.preventDefault()
                  resetState()
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        ) : isFailure ? (
          <div className="error-message">
            Form submission failed. Please try again.
            <ModalFooter>
              <Button
                type="button"
                color="warning"
                onClick={(e) => {
                  e.preventDefault()
                  resetState()
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        ) : (
          <Form
            onSubmit={(values: any) => console.log(values)}
            initialValues={{ passport: passportInformation }}
            render={({ values, form }) => (
              <form>
                <Row>
                  <CrewDetails />
                </Row>
                <ModalFooter>
                  <Button
                    color="warning"
                    onClick={(e) => {
                      e.preventDefault()
                      resetState()
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    color="warning"
                    disabled={isSubmitting}
                    onClick={(e) => {
                      e.preventDefault()
                      updateInfo(values)
                    }}
                  >
                    {isSubmitting ? 'Submitting ...' : 'Submit'}
                  </Button>
                </ModalFooter>
              </form>
            )}
          />
        )}
      </SubmitModalWithForm>
    </Box>
  )
}

const EmergencyDetails = ({
  data,
  accounts,
  instance,
  refetch
}: {
  data: any
  accounts: any
  instance: any
  refetch: any
}) => {
  const emergencyInformation = data?.emergency
  const [isFailure, setIsFailure]: any = useState(false)
  const [isSubmitting, setIsSubmitting]: any = useState(false)
  const [isSuccess, setIsSuccess]: any = useState(false)
  const [updateModal, setUpdateModal]: any = useState(false)

  const updateInfo = async (values: any) => {
    setIsSubmitting(true)

    try {
      const body: any = { ...values, _id: emergencyInformation?._id }
      const res = await makeAuthenticatedRequest(
        `${usersEndpoint}/${data?._id}`,
        'PATCH',
        body,
        accounts,
        instance
      )

      if (res?.status === 'success') {
        setIsSuccess(true)
        refetch()
      }
    } catch (error) {
      setIsSubmitting(false)
      setIsFailure(true)
    }

    return null
  }

  const resetState = () => {
    setIsSubmitting(false)
    setIsFailure(false)
    setIsSuccess(false)
    setUpdateModal(false)
  }

  const emergencyDetails: any = {
    contactRelation: emergencyInformation?.contactRelation,
    contactName: emergencyInformation?.contactName,
    medicalHistory: emergencyInformation?.medicalHistory,
    physicialContact: emergencyInformation?.physicialContact,
    physicialContactNumber: emergencyInformation?.physicialContactNumber
  }

  return (
    <Box heading="Emergency Details">
      {!emergencyInformation?.contactName ? (
        <center>Emergency Information not found</center>
      ) : (
        <>
          {Object.keys(emergencyDetails)?.map((k: any) =>
            renderData(toNormalCase(k), emergencyDetails[k] || 'NA')
          )}
        </>
      )}
      <Spacer height={10} />
      <ModalFooter>
        <Button color="warning" onClick={() => setUpdateModal(true)}>
          {!emergencyInformation?.contactName
            ? 'Add Information'
            : 'Update Information'}
        </Button>
      </ModalFooter>
      <SubmitModalWithForm
        isOpen={updateModal}
        onClose={(val: any) => {
          setUpdateModal(false)
        }}
        // onSubmit={async (e: any) => await updateInfo(e)}
        size="lg"
        header="Update Emergency Information"
        showDefaultButtons={false}
      >
        {isSuccess ? (
          <div className="error-message">
            Form submission successfull.
            <ModalFooter>
              <Button
                type="button"
                color="warning"
                onClick={(e) => {
                  e.preventDefault()
                  resetState()
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        ) : isFailure ? (
          <div className="error-message">
            Form submission failed. Please try again.
            <ModalFooter>
              <Button
                type="button"
                color="warning"
                onClick={(e) => {
                  e.preventDefault()
                  resetState()
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        ) : (
          <Form
            onSubmit={(values: any) => console.log(values)}
            initialValues={{ emergency: emergencyDetails }}
            render={({ values, form }) => (
              <form>
                <Row>
                  <EmergencyContact />
                </Row>
                <ModalFooter>
                  <Button
                    color="warning"
                    onClick={(e) => {
                      e.preventDefault()
                      resetState()
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    color="warning"
                    disabled={isSubmitting}
                    onClick={(e) => {
                      e.preventDefault()
                      updateInfo(values)
                    }}
                  >
                    {isSubmitting ? 'Submitting ...' : 'Submit'}
                  </Button>
                </ModalFooter>
              </form>
            )}
          />
        )}
      </SubmitModalWithForm>
    </Box>
  )
}

const DestinationDetails = ({
  data,
  accounts,
  instance,
  refetch
}: {
  data: any
  accounts: any
  instance: any
  refetch: any
}) => {
  const destinationInformation = data?.destination1
  const [isFailure, setIsFailure]: any = useState(false)
  const [isSubmitting, setIsSubmitting]: any = useState(false)
  const [isSuccess, setIsSuccess]: any = useState(false)
  const [updateModal, setUpdateModal]: any = useState(false)

  const updateInfo = async (values: any) => {
    setIsSubmitting(true)

    try {
      const body: any = { ...values, _id: destinationInformation?._id }
      const res = await makeAuthenticatedRequest(
        `${usersEndpoint}/${data?._id}`,
        'PATCH',
        body,
        accounts,
        instance
      )

      if (res?.status === 'success') {
        setIsSuccess(true)
        refetch()
      }
    } catch (error) {
      setIsSubmitting(false)
      setIsFailure(true)
    }

    return null
  }

  const resetState = () => {
    setIsSubmitting(false)
    setIsFailure(false)
    setIsSuccess(false)
    setUpdateModal(false)
  }

  const destinationDetails: any = {
    travelDate: destinationInformation?.travelDate,
    mode: destinationInformation?.mode,
    origin: destinationInformation?.origin,
    destination: destinationInformation?.destination,
    suggestedMode: destinationInformation?.suggestedMode,
    suggestedRouteOrigin: destinationInformation?.suggestedRouteOrigin,
    suggestedRouteDestination: destinationInformation?.suggestedRouteDestination
  }

  return (
    <Box heading="Destinaton Details">
      {!destinationInformation?.origin ? (
        <center>Destination Information not found</center>
      ) : (
        <>
          {Object.keys(destinationDetails)?.map((k: any) =>
            renderData(toNormalCase(k), destinationDetails[k] || 'NA')
          )}
        </>
      )}
      <Spacer height={10} />
      <ModalFooter>
        <Button color="warning" onClick={() => setUpdateModal(true)}>
          {!destinationDetails?.contactName
            ? 'Add Information'
            : 'Update Information'}
        </Button>
      </ModalFooter>
      <SubmitModalWithForm
        isOpen={updateModal}
        onClose={(val: any) => {
          setUpdateModal(false)
        }}
        // onSubmit={async (e: any) => await updateInfo(e)}
        size="lg"
        header="Update Emergency Information"
        showDefaultButtons={false}
      >
        {isSuccess ? (
          <div className="error-message">
            Form submission successfull.
            <ModalFooter>
              <Button
                type="button"
                color="warning"
                onClick={(e) => {
                  e.preventDefault()
                  resetState()
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        ) : isFailure ? (
          <div className="error-message">
            Form submission failed. Please try again.
            <ModalFooter>
              <Button
                type="button"
                color="warning"
                onClick={(e) => {
                  e.preventDefault()
                  resetState()
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        ) : (
          <Form
            onSubmit={(values: any) => console.log(values)}
            initialValues={{ destination1: destinationDetails }}
            render={({ values, form }) => (
              <form>
                <Row>
                  <Destination name="destination1" title="Destination" />
                </Row>
                <ModalFooter>
                  <Button
                    color="warning"
                    onClick={(e) => {
                      e.preventDefault()
                      resetState()
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    color="warning"
                    disabled={isSubmitting}
                    onClick={(e) => {
                      e.preventDefault()
                      updateInfo(values)
                    }}
                  >
                    {isSubmitting ? 'Submitting ...' : 'Submit'}
                  </Button>
                </ModalFooter>
              </form>
            )}
          />
        )}
      </SubmitModalWithForm>
    </Box>
  )
}

export default function MyProfile() {
  const { userData } = useContext(UserContext)
  const { accounts, instance } = useMsal()

  const fetchUser = async () => {
    const response = await makeAuthenticatedRequest(
      `${usersEndpoint}/${userData?.id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchUser', fetchUser)

  const userDetails: any = {
    Name: userData?.givenName,
    'Last Name': userData?.surname,
    'Employee Id': userData?.employeeId,
    Designation: userData?.jobTitle,
    Department: userData?.department,
    'Email Id': userData?.userPrincipalName,
    'Date of Joining': getReadableTime(userData?.createdDateTime),
    'User Type': userData?.userType,
    'Mail Nickname': userData?.mailNickname,
    'Account Status': (
      <p style={{ marginBottom: 0, color: 'green' }}>
        {userData?.accountEnabled ? 'Active' : 'Disabled'}
      </p>
    )
  }

  const maintenanceItems = [
    'WO Overdue ( MECH) - 14 days (P1,P2,P3)',
    'WO Overdue ( MECH)- PY1 -14 days',
    'WO Overdue ( MECH)- PY2- 14 days	',
    'WO Overdue ( MECH)- PY3- - 14 days	',
    'WO Overdue (MECH)- 28 days -P1	',
    'WO Overdue (MECH)- 28 days -P2	',
    'WO Overdue (MECH)- 28 days -P3'
  ]

  const procurementItems = [
    'Trip Urgent Requestion ( Mech)',
    'Urgent Requestion (Mech)',
    'Approved Work Request (Mech',
    'Approved PO (Mech)	Approved RFQ (Mech)'
  ]

  const ColorBox = ({ color }: { color: any }) => (
    <div
      style={{
        backgroundColor: color,
        border: '1px solid #d3d3d3',
        marginRight: '1vh',
        height: '5vh',
        width: '10vh',
        borderRadius: 5
      }}
    />
  )

  return (
    <div style={{ height: '100vh', width: '100vw', overflow: 'scroll' }}>
      <ProtectedLayout onBack="/">
        <div style={{ padding: '3vh' }}>
          <div style={{ height: 'inherit', width: 'inherit' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end'
              }}
            >
              <h1>My Profile</h1>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h6>Select Language:</h6>
                <img
                  src={require('./../images/Icons/brazil flag.webp')}
                  style={{
                    height: '3vh',
                    width: '4vh',
                    marginRight: 5,
                    marginLeft: 5,
                    paddingBottom: 3
                  }}
                  alt="flag"
                />
                <img
                  src={require('./../images/british flag.png')}
                  style={{
                    height: '3vh',
                    width: '4vh',
                    paddingBottom: 3,
                    objectFit: 'cover',
                    borderBottom: '3px solid green'
                  }}
                  alt="flag"
                />
              </div>
            </div>
            <Spacer height={'3vh'} />
            <div
              style={{
                height: '63vh',
                width: 'inherit',
                display: 'flex',
                backgroundColor: 'rgba(211,211,211,0.3)',
                borderRadius: 10,
                padding: '3vh'
              }}
            >
              <div
                style={{
                  width: '30%',
                  backgroundColor: '#fff',
                  borderRadius: 10,
                  boxShadow: '13px 14px 20px 2px rgba(0,0,0,0.1)'
                }}
              >
                <div style={{ padding: '3vh' }}>
                  <img
                    alt="Profile Pictur"
                    src={require('./../images/sample pic.webp')}
                    style={{
                      height: '20vh',
                      width: '-webkit-fill-available',
                      border: '1px solid #d3d3d3',
                      objectFit: 'cover',
                      borderRadius: 5,
                      boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px'
                    }}
                  />
                </div>
                <div style={{ padding: '0vh 3vh 3vh 3vh' }}>
                  {Object.keys(userDetails)?.map((key: any) =>
                    renderData(key, userDetails[key])
                  )}
                  <div
                    style={{
                      display: 'flex',
                      paddingTop: '3vh',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Button
                      style={{
                        borderRadius: 50,
                        color: '#000',
                        padding: '8px 16px 8px 16px',
                        backgroundImage:
                          'linear-gradient(to right, #ff512f, #f09819)'
                      }}
                    >
                      Edit Details
                    </Button>
                    <Button
                      style={{
                        borderRadius: 50,
                        color: '#000',
                        padding: '8px 16px 8px 16px',
                        backgroundImage:
                          'linear-gradient(to right, #ff512f, #f09819)'
                      }}
                    >
                      Reset Password
                    </Button>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginLeft: '3vh',
                  width: '70%'
                }}
              >
                <div
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 10,
                    padding: '3vh',
                    height: '50%',
                    marginBottom: '3vh',
                    boxShadow: '13px 14px 20px 2px rgba(0,0,0,0.1)'
                  }}
                >
                  <h2>Setup Preferences</h2>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div style={{ width: '50%' }}>
                      <h5>Maintenance</h5>
                      <div style={{ overflowY: 'scroll', height: '90%' }}>
                        {maintenanceItems?.map((i: any) => (
                          <p style={{ marginBottom: 0 }} key={i}>
                            {i}
                          </p>
                        ))}
                      </div>
                    </div>
                    <div style={{ width: '50%', marginLeft: '2vh' }}>
                      <h5>Procurement</h5>
                      <div style={{ overflowY: 'scroll', height: '90%' }}>
                        {procurementItems?.map((i: any) => (
                          <p style={{ marginBottom: 0 }} key={i}>
                            {i}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 10,
                    padding: '3vh',
                    height: '44%',
                    boxShadow: '13px 14px 20px 2px rgba(0,0,0,0.1)'
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h2>Select Background Color</h2>
                    <div
                      style={{
                        backgroundColor: '#d3d3d3',
                        marginLeft: '3vh',
                        height: '5vh',
                        width: '10vh',
                        borderRadius: 5
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', paddingTop: '3vh' }}>
                    <ColorBox color="ligtblue" />
                    <ColorBox color="#B0DDF2" />
                    <ColorBox color="#FFEDBB" />
                    <ColorBox color="#FFC6C6" />
                    <ColorBox color="#E8FFC6" />
                    <ColorBox color="#FFCDF7" />
                    <ColorBox color="#FFE7C7" />
                    <ColorBox color="#E1F8DC" />
                    <ColorBox color="#CAF1DE" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Row style={{ margin: 15 }}>
          <Col xs={12} md={6}>
            <PassportDetails
              data={data?.data}
              refetch={refetch}
              accounts={accounts}
              instance={instance}
            />
          </Col>
          <Col xs={12} md={6}>
            <EmergencyDetails
              data={data?.data}
              refetch={refetch}
              accounts={accounts}
              instance={instance}
            />
            <DestinationDetails
              data={data?.data}
              refetch={refetch}
              accounts={accounts}
              instance={instance}
            />
          </Col>
        </Row>
      </ProtectedLayout>
    </div>
  )
}
