export const workOrderStatuses: any = {
  wrCreated: 'WR Created',
  woCreated: 'WO Created',
  wrApproved: 'WO Approved',
  cancelled: 'Cancelled',
  todo: 'Todo',
  inProgress: 'In Progess',
  waitingForMaterial: 'Waiting For Material',
  waitingFor3rdParty: 'Waiting For 3rd Party',
  waitingForShutdown: 'Waiting For Shutdown',
  closed: 'Closed',
  postpone: 'Postpone'
}

export const prStatuses: any = {
  created: 'Created',
  approved: 'Approved',
  approvedPO: 'Approved PO',
  createdPO: 'Created PO',
  issuedPO: 'Issued PO',
  materialReceived: 'Material Received',
  requestedForQuotation: 'Requested For Vendors',
  approvedLevelOnePO: 'Approved Level One',
  acceptedByVendor: 'Accepted By Vendor',
  transportOrderCreated: 'Tranport Order Created',
  sparesReceived: 'Spares Received ONSHORE',
  consignmentManifestClosed: 'Consignment Manifest Closed',
  consignmentManifestCreated: 'Consignment Manifest Created',
  poCompleted: 'PO Completed',
  jobOrderCreated: 'Job Order Created',
  jobOrderClosed: 'Job Order Closed',
  discripencyRaised: 'Discripancy Raised'
}
