import { Container, Row, Col, Button } from "reactstrap";
import { useNavigate } from "react-router-dom"
import Select from 'react-select'

import DashboardHeader from "../components/Dashboard/DashboardHeader";
import PermitsPagination from "../components/PermitsPagination";
import Spacer from "../components/Spacer";
import theme from './../components/constants/defaultTheme.json'
import CustomLabel from "../components/InputFields/CustomLabel";
import { Form } from "react-final-form";
import { AiOutlineAudit } from "react-icons/ai";
import LabeledTextInput from "../components/InputFields/LabeledTextInput";
import MultiSelect from "../components/InputFields/MultiSelect";
import LabledParagraphInput from "../components/InputFields/LabledParagraph";
import LabeledRadioButtons from "../components/InputFields/LabeledRadioButton";

const permitTypes = [{
  label: "Hot Work",
  value: 0
}, {
  label: "Cold Work",
  value: 1
}, {
  label: "Vehicle Permit ",
  value: 2
}
]

export default function EquipmentOverrides() {
  const navigate = useNavigate()
  return (
    <Container fluid>
      <Row
        style={{
          backgroundColor: '#E5EAF0',
          padding: 30,
        }}
      >
        <Row
          style={{
            backgroundColor: '#D6D6D6',
            border: '5px solid #FFF',
            borderRadius: 25,
            margin: 5,
            padding: 10
          }}
        >
          <div
            style={{
              backgroundColor: theme.colors.lightBlue1,
              borderRadius: 15,
              marginLeft: 1,
              padding: 0,
              marginRight: 0,
              width: '100%',
              paddingRight: 10
            }}
          >
            <Row>
              <Col xs="12">
                <DashboardHeader onBack={() => navigate('/dashboard')} />
              </Col>
            </Row>
            <Row style={{ display: 'flex', padding: 0 }}>
              <Col xs="12" md="2">
                <div
                  style={{
                    padding: 5,
                    boxShadow: 'rgba(0, 0, 0, 0.4) 0px 1px 2px, rgba(0, 0, 0, 0.3) 0px 2px 3px -3px, rgba(0, 0, 0, 0.2) 0px -2px 0px inset',
                    background: theme.gradient.primary,
                    margin: '10px 10px 0 10px',
                    borderRadius: 10,
                    border: '3px solid #FFF',
                    textAlign: 'center',
                    color: '#FFF',
                    fontWeight: 700
                  }}
                >
                  Equipment Overrides
                </div>
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <CustomLabel label="Department" />
                  <Select
                    options={[
                      { value: 'mechanical', label: 'Mechanical' },
                      { value: 'electrical', label: 'Electrical' },
                      { value: 'product', label: 'Product' }
                    ]}
                    // onChange={(item: any) => filterPermits(item)}
                    onMenuClose={() => null}
                  />
                </div>
                <Spacer height={20} />
                <PermitsPagination />
              </Col>
              <Col xs="12" md="10" style={{ backgroundColor: theme.colors.lightGray1, padding: 10, display: 'flex', justifyContent: 'space-between', borderTopLeftRadius: 10, minHeight: '100vh' }}>
                <Col style={{ border: '3px solid #FFF', borderRadius: 10, padding: 10, backgroundColor: theme.colors.white }}>
                  <Row>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: 15,
                      backgroundColor: 'orange',
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                      padding: 10,
                      borderBottom: '3px solid #e67e00'
                    }}
                    >
                      <div style={{ backgroundColor: 'white', borderRadius: '50%', padding: 15, marginRight: 10 }}>
                        <AiOutlineAudit size={25} />
                      </div>
                      <h3>Equipment Overrides & Equipmwnt out of service</h3>
                    </div>
                  </Row>
                  <Form
                    onSubmit={(values: any) => {}}
                    render={({ handleSubmit, form, hasValidationErrors, values }) => (
                      <form>
                        <Row>
                          <MultiSelect name="permitType" label="Permit Type" options={permitTypes} />
                          <LabeledTextInput label="Isolation Certificate Number" name='isolationCertificate' />
                        </Row>
                        <Row>
                          <MultiSelect name="tagNumber" label="Tag Number" options={[{ label: 'Tag Number 1', value: 'tagNumber1' }, { label: 'Tag Number 2', value: 'tagNumber2' }]} />
                          <MultiSelect name="equipment" label="Equipment" options={[{ label: 'Equipment 1', value: 'equipment1' }, { label: 'Equipment 2', value: 'equipment2' }]} />
                        </Row>
                        <Row>
                          <LabeledTextInput label="Permit Applicant" name='permitApplicant' />
                          <LabledParagraphInput name="comments" label="Comments" occupy={6} />
                        </Row>
                        <Spacer height={20} />
                        <Row>
                          <LabeledRadioButtons occupy={2} name="unitShutdown" label="Unit Shut Down" list={[{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]} />
                          <LabeledRadioButtons occupy={2} name="plantShutdown" label="Plant Shut Down" list={[{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]} />
                          <LabeledRadioButtons occupy={2} name="lossOfProduction" label="Loss of Production" list={[{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]} />
                          <LabeledRadioButtons occupy={3} name="precautionNeeded" label="Any Precaution Needed" list={[{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]} />
                          <LabeledRadioButtons occupy={3} name="ltiIncident" label="LTI Incident Happened" list={[{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]} />
                        </Row>
                        <Spacer height={30} />
                        <Row>
                          <Col>
                            <Button color="primary" onClick={() => form.submit()}>Submit</Button>
                          </Col>
                        </Row>
                      </form>)}
                  />
                </Col>
              </Col>
            </Row>
          </div>
        </Row>
      </Row>
    </Container>
  )
}