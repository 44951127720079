import React from 'react'

import FailureModes from '../rcm/FailureModes'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { Row } from 'reactstrap'

export default function FirsStratefyAndTactics({
  setPin,
  pin
}: {
  pin: any
  setPin: any
}) {
  return (
    <ProtectedLayout
      onBack={'/firs'}
      tabHeader={<h4 style={{ margin: 0 }}>Strategies and Tactics</h4>}
    >
      <Row style={{ margin: 15 }}>
        <FailureModes setPin={setPin} pin={pin} />
      </Row>
    </ProtectedLayout>
  )
}
