import axios from 'axios'
import moment from 'moment'
import { Container, Row, Col, Button, Input, ModalFooter } from 'reactstrap'
import { Field, Form } from 'react-final-form'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'

import ConfinedSpaceEntry from './ConfinedSpaceEntryPermit'
import CustomLabel from '../InputFields/CustomLabel'
import DropDownFetch from '../InputFields/DropDownFetch'
import EquipmentHistory from '../Modals/EquipmentHistory'
import equipmentPreparationList from './../constants/quipment.json'
import ExcavationPermit from './ExcavationPermit'
import FormPreview from '../FormPreview'
import HOECRiskAssesmentForm from './HOECRiskAssesmentForm'
import IsolationForm from './IsolationForm'
import LabeledCheckBoxes from '../InputFields/LabeledCheckBoxes'
import LabeledDateTimeInput from '../InputFields/DateTime'
import LabeledRadioButtons from '../InputFields/LabeledRadioButton'
import LabeledStartDateInput from '../InputFields/LabeledStartDate'
import LabeledTextInput from '../InputFields/LabeledTextInput'
import LabledParagraphInput from '../InputFields/LabledParagraph'
import MultiSelect from '../InputFields/MultiSelect'
import PositiveIsolation from './PositiveIsolationPermit'
import ppeRequirementList from './../constants/ppeRequirements.json'
import PreviewPandID from '../Modals/PreviewPandID'
import Select from '../InputFields/Select'
import Spacer from '../Spacer'
import SubmitModalWithForm from '../Modals/SubmitModalWithForm'
import theme from './../../components/constants/defaultTheme.json'
import toolsList from './../constants/toolsList.json'
import VehiclePermit from './VehiclePermit'
import { epermit, tagsAPI, workOrderAPI } from '../serverurl'
import { getAccessToken } from '../../apiRequests'
import { getOptions } from '../../pages/firs'
import { loginRequest } from '../../authConfig'
import { Text } from '../RiskAssesmentPreview'
import { useMsal } from '@azure/msal-react'
import { UserContext } from '../../App'
import { validateWordCount } from '../lib/capitalizeFirstLetter'
import { MdDelete } from 'react-icons/md'
import { renderField } from '../Modals/CloseCMPermit'
import WorkAtHeight from './WorkAtHeight'
import AutoCompleteAndApi from '../InputFields/AutoCompleteAndAPI'
import { isolationDetails } from '../constants/IsolationDetails'

const formTypes = {
  VE: 'VEHICLE / EQUIPMENT ENTRY ',
  EP: 'EXCAVATION / PENETRATION ',
  CF: 'CONFINED SPACE ENTRY ',
  WH: 'WORK AT HEIGHT'
}

const otherLocations = [
  'Yard Area',
  'Scrapper Receiver Area',
  'Slug Cather Area',
  'Inlet Separator',
  'Coalescer Filter',
  'Chiller & MRU Unit',
  'HC Liquid Processing',
  'Sales Gas Heater',
  '1st & 2nd Stage Separator',
  'FG KOD Systems',
  'Hot oil Heater Area',
  'Instrument Air Compressor Area',
  'Loading Bag Area',
  'Slop Oil Tank',
  'Condensate Tanks & Pumps',
  'Fire Water Pump House Area',
  'HP & LP Metering Skid',
  'HP & LP Flare System',
  'PR 1, 2 & 3',
  'CRWS Area',
  'Closed Drain Drum Area',
  'Fuel Storage Tank & Pumps',
  'PW Degasser & Holding Pond Area'
]

export const protectiveEquipment = [
  {
    label: 'Fire Extinguisher',
    value: 'Fire Extinguisher'
  },
  {
    label: 'Running Fire House',
    value: 'Running Fire House'
  },
  {
    label: 'Standy By Fire Tender',
    value: 'Standy By Fire Tender'
  },
  {
    label: 'Continous gas Monitoring',
    value: 'Continous gas Monitoring'
  },
  {
    label: 'Fire Watch',
    value: 'Fire Watch'
  },
  {
    label: 'Fire Bucket',
    value: 'Fire Bucket'
  },
  {
    label: 'Fire Blanket',
    value: 'Fire Blanket'
  }
]

export const renderTeam = ({ fields, meta: { error } }: any) => (
  <>
    {fields.map((resource: any, index: any) => (
      <Row>
        <Col style={{ marginTop: 5 }}>
          <Field
            name={`${resource}.name`}
            type="text"
            component={renderField}
            label={`Name #${index + 1}`}
          />
        </Col>
        <Col>
          <MdDelete
            onClick={() => fields.remove(index)}
            style={{ color: 'red' }}
          />
        </Col>
      </Row>
    ))}
    <Row>
      <Col>
        <Button
          color="warning"
          style={{ marginTop: 10 }}
          onClick={() => fields.push()}
        >
          Add Member
        </Button>
      </Col>
    </Row>
  </>
)

export const permitValidation = (values: any) => {
  var date1: any = new Date(values?.startDate)
  var date2: any = new Date(values?.endDate)
  const diffTime = Math.abs(date2 - date1)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  const errors: any = {}
  if (diffDays > 15 || Date.parse(date1) > Date.parse(date2)) {
    errors.endDate = `End Date is not valid`
  }
  if (!values.permitTitle) {
    errors.permitTitle = 'Required'
  } else if (validateWordCount(values.permitTitle, 1000, true)) {
    errors.permitTitle = `Permit title should not exceed ${1000} charecters`
  }

  if (!values.permitTitle) {
    errors.permitTitle = 'Required'
  }
  if (!values.startDate) {
    errors.createDate = 'Required'
  }
  if (!values.endDate) {
    errors.endDate = 'Required'
  }
  if (!values.department) {
    errors.department = 'Required'
  }
  if (!values.workLocation) {
    errors.workLocation = 'Required'
  }
  if (!values.workDescription) {
    errors.workDescription = 'Required'
  } else if (validateWordCount(values.workDescription, 2000, true)) {
    errors.workDescription = `Should not exceed ${2000} characters`
  }
  if (validateWordCount(values?.othersProtectStdByEquipment, 250, false)) {
    errors.othersProtectStdByEquipment = `Should not exceed ${250} characters`
  }
  if (validateWordCount(values?.othersEquipmentPreparation, 250, false)) {
    errors.othersEquipmentPreparation = `Should not exceed ${250} characters`
  }
  if (validateWordCount(values?.othersPpeRequirement, 250, false)) {
    errors.othersPpeRequirement = `Should not exceed ${250} characters`
  }
  if (validateWordCount(values?.specialInstructions, 250, false)) {
    errors.specialInstructions = `Should not exceed ${250} characters`
  }

  return errors
}

export default function NewPermitForm({ type, onChange, initialValues }: any) {
  const { accounts, instance } = useMsal()

  const liveIsolations = initialValues?.isolations?.[0]?.isolations
  const existingIsolationsId = initialValues?.isolations[0]?._id
  const { userData } = useContext(UserContext)
  const [isIsolationOpen, setIsIsolationOpen] = useState(false)
  const [isolationValues, setIsolationValues] = useState(liveIsolations || [])
  const [isRiskAssesmentOpen, setIsRiskAssesmentOpen] = useState(false)
  const [riskAssesmentValues, setRiskAssesmentValues]: any = useState()
  const [isFormPreviewOpen, setIsFormPreviewOpen] = useState(false)
  const [pandID, setPandID] = useState(false)
  const [permitValues, setPermitValues] = useState({})
  const [cosh, setCosh] = useState()
  const [pid, setPid] = useState()
  const [msd, setMsd] = useState()
  const [viewEquipmentHistory, setViewEquipmentHistory] =
    useState<boolean>(false)
  const [stockList, setStockList] = useState<Array<any>>([])
  const [workOrders, setWorkOrders] = useState<Array<any>>([])
  const [vehiclePermit, setVehiclePermit] = useState<any>()
  const [vehicleModal, setVehicleModal] = useState(false)
  const [excavation, setExcavation] = useState()
  const [excavatinModal, setExcavationModal] = useState(false)
  const [positiveIsolation, setPositiveIsolation] = useState()
  const [positiveModal, setPostiveModal] = useState(false)
  const [confinedSpace, setConfinedSpace] = useState()
  const [confinedModal, setConfinedModal] = useState(false)
  const [workAtHeight, setWorkAtHeight] = useState()
  const [workAtHeightModal, setWorkAtHeightModal] = useState(false)
  const [draftModal, setDraftModal] = useState(false)

  const equipmentPandID: any = localStorage.getItem('equipments')
  const parsedEquipments = JSON.parse(equipmentPandID)
  const [equipment, setEquipment]: any = useState(
    equipmentPandID
      ? getOptions(parsedEquipments?.map((a: any) => a?.id))
      : undefined
  )

  const localStorageIsolations: any = localStorage.getItem('isolations')
  const localIsolations = JSON.parse(localStorageIsolations)?.map(
    (a: any) => a?.id
  )

  if (localIsolations?.length > 0 && isolationValues?.length === 0) {
    const fromPID = localIsolations?.map((l: any) => ({
      isolationPoint: l,
      isolationDescription: isolationDetails[l] || ''
    }))
    setIsolationValues([...isolationValues, ...fromPID])
  }

  const navigate = useNavigate()

  const toggleIsolationModal = () => setIsIsolationOpen(!isIsolationOpen)
  const toggleRiskAssesment = () => setIsRiskAssesmentOpen(!isRiskAssesmentOpen)
  const toggleFormPreview = () => setIsFormPreviewOpen(!isFormPreviewOpen)

  useEffect(() => {
    setStockList([])
    setWorkOrders([])
  }, [onChange])

  const electricalIsolationCount = isolationValues.filter(
    (isolation: any) => isolation.isolationType?.value === 'Electrical'
  ).length
  const excavationCount = isolationValues.filter(
    (isolation: any) => isolation.isolationType?.value === 'Mechanical'
  ).length
  const confinedSpaceEntryCount = isolationValues.filter(
    (isolation: any) => isolation.isolationType?.value === 'Instrument'
  ).length
  const blindListCount = isolationValues.filter(
    (isolation: any) => isolation.isolationType?.value === 'Production'
  ).length

  const getCount = (value: any, label: string, viewCount = true) =>
    value > 0 && (
      <div
        style={{
          borderRadius: 5,
          backgroundColor: '#FFF',
          padding: 5,
          marginBottom: 5
        }}
      >
        {viewCount ? (
          <p style={{ margin: 0 }}>{`${value} - ${label} Added`}</p>
        ) : (
          <p style={{ margin: 0 }}>{`${value} - ${label} Added`}</p>
        )}
      </div>
    )

  const shoWAttchedInfo = (label: string) => (
    <div
      style={{
        borderRadius: 5,
        backgroundColor: '#FFF',
        padding: 5,
        marginBottom: 5
      }}
    >
      <p style={{ margin: 0 }}>{`${label} Added`}</p>
    </div>
  )

  const reset = () => {
    setPermitValues({})
    setIsolationValues([])
    setRiskAssesmentValues({})
    setStockList([])
    setWorkOrders([])
  }

  const draftPermit = async (val: any) => {
    const finalValues = {
      permit: {
        ...val,
        type
      },
      vehiclePermitValues: vehiclePermit,
      excavationPermitValues: excavation,
      positiveIsolationValues: positiveIsolation,
      confinedSpaceEntryValues: confinedSpace,
      workAtHeightValues: workAtHeight,
      isolationValues,
      riskAssessmentValues: riskAssesmentValues?.template?._id
        ? riskAssesmentValues?.template
        : riskAssesmentValues
    }

    const token = await getAccessToken(loginRequest, accounts, instance)

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    const config = {
      method: 'POST',
      url: `${epermit}/draft?isLTI=${existingIsolationsId}`,
      headers,
      data: {
        ...finalValues,
        isLTI: existingIsolationsId,
        isDraft: true
      }
    }

    const res: any = await axios(config)

    if (res?.data?.status === 'success') {
      localStorage.setItem('createFromLTI', JSON.stringify(null))
      // navigate('/eptw/dashboard')
      reset()
    }

    return res?.data?.status
  }

  return (
    <Container fluid>
      <Form
        onSubmit={async (values: any) => {
          await setPermitValues({
            ...values,
            department: values?.department?.map((d: any) => d?.value),
            locationOthers: values?.locationOthers?.map((d: any) => d?.value),
            workLocation: values?.workLocation?.value,
            equipment: equipment?.map((m: any) => m?.value),
            machinery: values?.machinery?.map((m: any) => m?.value),
            createdBy: userData?.id,
            applicantName: `${userData?.surname}. ${userData?.givenName}`,
            applicantDesignation: userData?.jobTitle,
            applicantDepartment: userData?.department.trim(),
            employeeId: userData?.employeeId
          })
        }}
        mutators={{
          ...arrayMutators
        }}
        validate={(values: any) => permitValidation(values)}
        initialValues={{
          permitTitle: initialValues?.permitTitle
        }}
        render={({ handleSubmit, hasValidationErrors, form, values }) => (
          <form onSubmit={handleSubmit}>
            <>
              <Row>
                <Col xs="3">
                  <LabeledTextInput
                    name="applicantName"
                    label="Applicant Name"
                    value={`${userData?.surname}. ${userData?.givenName}`}
                    isDisabled
                    occupy={12}
                  />
                </Col>
                <Col xs="3">
                  <LabeledTextInput
                    name="applicantDesignation"
                    label="Applicant Designation"
                    value={userData?.jobTitle}
                    isDisabled
                    occupy={12}
                  />
                </Col>
                <Col xs="3">
                  <LabeledTextInput
                    name="applicantDepartment"
                    label="Applicant Department"
                    value={userData?.department}
                    isDisabled
                    occupy={12}
                  />
                </Col>
                <Col xs="3">
                  <LabeledTextInput
                    name="employeeID"
                    label="Employee ID"
                    value={userData?.employeeId}
                    isDisabled
                    occupy={12}
                  />
                </Col>
                <LabledParagraphInput
                  name="permitTitle"
                  label="Permit Title"
                  occupy={12}
                />
                <LabledParagraphInput
                  name="workDescription"
                  label="Description of work"
                  occupy={12}
                />
              </Row>
              {type === 'hotWork' ? (
                <Row>
                  <LabeledStartDateInput
                    name="startDate"
                    label="Start Date"
                    occupy={5}
                    permitType={type}
                  />
                  <LabeledTextInput
                    name="endDate"
                    label="End Date"
                    occupy={6}
                    value={
                      values?.startDate &&
                      moment(values?.endDate).format('DD-MM-YYYY, hh:mm:ss A')
                    }
                    isDisabled
                  />
                </Row>
              ) : (
                <Row>
                  <LabeledDateTimeInput
                    name="startDate"
                    label="Start Date"
                    occupy={6}
                  />
                  <LabeledDateTimeInput
                    name="endDate"
                    label="End Date"
                    occupy={6}
                    startDate={values?.startDate}
                  />
                </Row>
              )}
              <Row>
                <Col xs="5">
                  <CustomLabel label="Equipment" />
                  <DropDownFetch
                    name="equipment"
                    value={equipment}
                    setValue={setEquipment}
                    url={`${workOrderAPI}/filters`}
                    isMulti
                    needSelect={false}
                  />
                </Col>
                <LabeledTextInput occupy="1" name="system" label="System" />
                {/* <Col xs="6">
                  <CustomLabel label="Equipment" />
                  <Field name="equipment" style={{ background: '#DCDCDC' }}>
                    {({ input, ...rest }) => (
                      <div>
                        <AutoCompleteAndApi
                          isMulti={false}
                          name="tagId"
                          url={`${workOrderAPI}/filters`}
                          handleChange={(option: any, change: any) => {
                            setEquipment([option?.value])
                          }}
                          needSelect={false}
                          input={input}
                          {...rest}
                        />
                      </div>
                    )}
                  </Field>
                </Col> */}
                <LabeledTextInput
                  name="othersEquipmentNumbers"
                  label="Equipment Others"
                />
                <Col xs={6}>
                  <Select
                    isMulti
                    name="department"
                    label="Department"
                    options={[
                      'Mechanical',
                      'Electrical',
                      'Instrument',
                      'Production',
                      'Admin Ware House',
                      'HSE'
                    ]?.map((d: any) => ({ value: d, label: d }))}
                  />
                </Col>
                <Col xs={3}>
                  <Select
                    name="workLocation"
                    isMulti={false}
                    label="Location of Work"
                    options={[
                      'Mechanical Workshop',
                      'Fire Station',
                      'Sub Station',
                      'Control Room',
                      // 'Instrument Workshop',
                      'Process Area',
                      'Admin Ware House'
                    ]?.map((d: any) => ({ value: d, label: d }))}
                  />
                </Col>
                <Col xs={3}>
                  <MultiSelect
                    name="locationOthers"
                    label="Others Location"
                    options={getOptions(otherLocations)}
                    occupy={12}
                  />
                </Col>
                <MultiSelect
                  name="machinery"
                  label="Mobile machinery and tools"
                  options={toolsList}
                />
                <LabeledTextInput
                  name="othersTools"
                  label="Mobile Tools Others"
                />
              </Row>
              <Row>
                <Col xs="6">
                  <p style={{ margin: 0, width: 'auto', padding: 10 }}>
                    {values?.tagNumber
                      ?.map((item: any) => item.description)
                      .join(', ')}
                  </p>
                </Col>
              </Row>
              {type === 'hotWork' && (
                <Row>
                  <LabeledCheckBoxes
                    name="protectStdByEquipment"
                    label="Protective Equipment & Stand by Equipment"
                    list={protectiveEquipment}
                    hasOthers
                    othersName="othersProtectStdByEquipment"
                  />
                  <Col>
                    {/* <Row>
                      <LabeledRadioButtons
                        defaultValue="No"
                        name="otherPermitNo"
                        label="Any other permits in the Area"
                        list={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' }
                        ]}
                        occupy={6}
                      />
                      {values?.otherPermitNo === 'Yes' && (
                        <LabeledTextInput
                          name="otherPermitNumber"
                          label="Permit Number"
                        />
                      )}
                    </Row> */}
                    <Row>
                      <LabeledRadioButtons
                        defaultValue="NA"
                        name="gasMeasurements"
                        label="Gas Measurements"
                        list={['Continuous', 'Intermittent', 'NA']?.map(
                          (k: any) => ({
                            label: k,
                            value: k
                          })
                        )}
                      />
                    </Row>
                  </Col>
                </Row>
              )}
              <Row>
                <LabeledCheckBoxes
                  name="equipmentPreparation"
                  label="Equipment Preparation"
                  list={equipmentPreparationList}
                  hasOthers
                  othersName="othersEquipmentPreparation"
                />
                <LabeledCheckBoxes
                  name="ppeRequirement"
                  label="PPE Required"
                  list={ppeRequirementList}
                  hasOthers
                  othersName="othersPpeRequirement"
                />
              </Row>
              <Row>
                <LabeledRadioButtons
                  name="watchRequired"
                  label="Safety watch required"
                  list={[
                    { label: 'Yes', value: 'Yes' },
                    { label: 'No', value: 'No' }
                  ]}
                />
                <LabledParagraphInput
                  name="specialInstructions"
                  label="Special Precautions"
                  occupy={6}
                />
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col xs="9">
                  <LabeledRadioButtons
                    occupy={12}
                    defaultValue="Yes"
                    name="riskAssessment"
                    label="HSE Assistance for Risk Assessment / Site inspection required"
                    list={[
                      { label: 'Yes', value: 'Yes' },
                      { label: 'No', value: 'No' }
                    ]}
                    errorText="Add Risk Assessment"
                  />
                </Col>
                <Col xs="3">
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => toggleRiskAssesment()}
                    color="primary"
                  >
                    {riskAssesmentValues?.task
                      ? 'Edit Risk Assessment'
                      : 'Add Risk Assessment'}
                  </Button>
                  {!riskAssesmentValues && (
                    <>
                      <span
                        style={{
                          fontSize: 12,
                          color: 'red'
                        }}
                      >
                        * Please add Risk Assessment
                      </span>
                    </>
                  )}
                </Col>
                <Col xs="9">
                  <LabeledRadioButtons
                    occupy={12}
                    defaultValue="no"
                    name="needIsolation"
                    label="Need Isolation"
                    list={[
                      { label: 'Yes', value: 'Yes' },
                      { label: 'No', value: 'No' }
                    ]}
                    hasError={
                      values.needIsolation === 'yes' &&
                      isolationValues.length === 0
                    }
                    errorText="Add Isolations"
                  />
                </Col>
                <Col xs="3">
                  {values.needIsolation === 'Yes' && !existingIsolationsId && (
                    <Button
                      style={{ width: '100%' }}
                      onClick={() => toggleIsolationModal()}
                      color="primary"
                    >
                      {isolationValues.length > 0
                        ? 'Edit Isolations'
                        : 'Add Isolations'}
                    </Button>
                  )}
                </Col>
                <Col xs="8" style={{ fontSize: 12 }}>
                  <LabeledCheckBoxes
                    occupy={12}
                    backgroundColor="transparent"
                    borderWidth={0}
                    name="attachments"
                    label="Attach Forms"
                    list={[
                      formTypes.VE,
                      formTypes.EP,
                      formTypes.CF,
                      formTypes.WH
                    ]?.map((a: string) => ({ label: a, value: a }))}
                  />
                </Col>
                <Row>
                  <Col xs="8">
                    {values?.attachments?.includes(formTypes.VE) && (
                      <Button
                        color="primary"
                        style={{ fontSize: 14, marginRight: 10 }}
                        onClick={() => setVehicleModal(true)}
                      >
                        Vehicle Entry Permit
                      </Button>
                    )}

                    {values?.attachments?.includes(formTypes.EP) && (
                      <Button
                        color="primary"
                        style={{ fontSize: 14, marginRight: 10 }}
                        onClick={() => setExcavationModal(true)}
                      >
                        Excavation Certificate
                      </Button>
                    )}

                    {values?.attachments?.includes(formTypes.CF) && (
                      <Button
                        color="primary"
                        style={{ fontSize: 14, marginRight: 10 }}
                        onClick={() => setConfinedModal(true)}
                      >
                        Confined Space Entry
                      </Button>
                    )}

                    {values?.attachments?.includes(formTypes.WH) && (
                      <Button
                        color="primary"
                        style={{ fontSize: 14, marginRight: 10 }}
                        onClick={() => setWorkAtHeightModal(true)}
                      >
                        Work at Height
                      </Button>
                    )}
                  </Col>
                </Row>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col xs="4">
                  <>
                    {getCount(
                      electricalIsolationCount,
                      'Electrical isolations'
                    )}
                    {getCount(confinedSpaceEntryCount, 'Mechical isolations')}
                    {getCount(blindListCount, 'Instrument isolations')}
                    {getCount(excavationCount, 'Production isolations')}
                    {values?.attachments?.includes(formTypes.VE) &&
                      vehiclePermit &&
                      shoWAttchedInfo('Vehicle Entry Permit')}
                    {values?.attachments?.includes(formTypes.EP) &&
                      excavation &&
                      shoWAttchedInfo('Excavation Certificate')}
                    {values?.attachments?.includes(formTypes.CF) &&
                      confinedSpace &&
                      shoWAttchedInfo('Confined Space Entry Certificate')}
                    {values?.attachments?.includes(formTypes.WH) &&
                      workAtHeight &&
                      shoWAttchedInfo('Work At Height Permit')}
                  </>
                </Col>
              </Row>
              <Row>
                <LabeledRadioButtons
                  occupy={12}
                  defaultValue="No"
                  name="teamType"
                  label="Will permit applicant be the job performer for this job"
                  list={[
                    { label: 'Yes', value: 'Yes' },
                    { label: 'No', value: 'No' }
                  ]}
                  errorText="Add team"
                />
                <CustomLabel
                  label={
                    values?.teamType === 'Yes'
                      ? 'Enter Team Members'
                      : 'Enter Vendor Details'
                  }
                />
                <FieldArray name="teamMembers" component={renderTeam} />
              </Row>
              {stockList?.length > 0 && (
                <Row
                  style={{
                    marginTop: 20,
                    border: '1px solid #ccc',
                    borderRadius: 5,
                    backgroundColor: theme.colors.lightGray
                  }}
                >
                  <Row>
                    <Col>
                      <Text>
                        <b>Stock List</b>
                      </Text>
                    </Col>
                  </Row>
                  {stockList?.map((stock: any, index: number) => (
                    <Row key={`stock-${index}-${stock?.StockNumber}`}>
                      <Col xs={12} style={{ display: 'flex' }}>
                        <p>
                          <b>Stock Number</b>
                        </p>
                        &nbsp;-&nbsp;
                        <p>{stock?.StockNumber}</p>&nbsp;&nbsp;&nbsp;
                        <p>
                          <b>Description</b>
                        </p>
                        &nbsp;-&nbsp;
                        <p>{stock?.Description}</p>
                      </Col>
                    </Row>
                  ))}
                </Row>
              )}
              {workOrders?.length > 0 && (
                <Row
                  style={{
                    marginTop: 20,
                    border: '1px solid #ccc',
                    borderRadius: 5,
                    backgroundColor: theme.colors.lightGray
                  }}
                >
                  <Row>
                    <Col>
                      <Text>
                        <b>Work Orders Todo</b>
                      </Text>
                    </Col>
                  </Row>
                  {workOrders?.map((order: any, index: number) => (
                    <Row key={`stock-${index}-${order?.WO_Number}`}>
                      <Col xs={12} style={{ display: 'flex' }}>
                        <p>{order?.WO_Number}</p>&nbsp; -&nbsp;
                        <p>{order?.Description}</p>
                      </Col>
                    </Row>
                  ))}
                </Row>
              )}
              <Spacer height={10} />
              <ModalFooter>
                <Button
                  style={{}}
                  onClick={() => setDraftModal(true)}
                  disabled={Boolean(!riskAssesmentValues)}
                  color={
                    Boolean(!riskAssesmentValues) ? 'secondary' : 'warning'
                  }
                >
                  Save as Draft
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    form.submit()
                    setIsFormPreviewOpen(true)
                  }}
                  color={
                    hasValidationErrors || Boolean(!riskAssesmentValues)
                      ? 'secondary'
                      : 'warning'
                  }
                  disabled={
                    hasValidationErrors || Boolean(!riskAssesmentValues)
                  }
                >
                  Preview
                </Button>
              </ModalFooter>
            </>
            <HOECRiskAssesmentForm
              isOpen={isRiskAssesmentOpen}
              setValues={setRiskAssesmentValues}
              toggle={toggleRiskAssesment}
              values={riskAssesmentValues}
              equipment={values?.equipment?.[0]?.label}
              permitValues={values}
            />
            <IsolationForm
              isOpen={isIsolationOpen}
              setValues={setIsolationValues}
              toggle={toggleIsolationModal}
              values={isolationValues}
            />
            <FormPreview
              form={form}
              isolationValues={isolationValues}
              isOpen={isFormPreviewOpen}
              permitValues={permitValues}
              equipment={values?.equipment?.[0]?.value}
              riskAssesmentValues={riskAssesmentValues}
              toggle={toggleFormPreview}
              type={type}
              reset={reset}
              excavation={excavation}
              attachments={{
                coshh: cosh,
                pid: pid,
                msd: msd,
                vehicle:
                  values?.attachments?.includes(formTypes.VE) && vehiclePermit,
                workAtHeight:
                  values?.attachments?.includes(formTypes.WH) && workAtHeight,
                excavation:
                  values?.attachments?.includes(formTypes.EP) && excavation,
                confinedSpace:
                  values?.attachments?.includes(formTypes.CF) && confinedSpace
              }}
              existingIsolationsId={existingIsolationsId}
            />
            <PreviewPandID
              isOpen={!!pandID}
              toggle={() => setPandID(false)}
              filepath={pandID}
            />
            {draftModal && (
              <SubmitModalWithForm
                isOpen={draftModal}
                isDisabled={hasValidationErrors}
                onClose={(val: any) => {
                  setDraftModal(false)
                  val && navigate('/eptw/dashboard')
                }}
                onSubmit={async (e: any) => {
                  e.preventDefault()
                  const res = await draftPermit({
                    ...values,
                    department: values?.department?.map((d: any) => d?.value),
                    locationOthers: values?.locationOthers?.map(
                      (d: any) => d?.value
                    ),
                    workLocation: values?.workLocation?.value,
                    equipment: equipment?.map((m: any) => m?.value),
                    machinery: values?.machinery?.map((m: any) => m?.value),
                    createdBy: userData?.id,
                    applicantName: `${userData?.surname}. ${userData?.givenName}`,
                    applicantDesignation: userData?.jobTitle,
                    applicantDepartment: userData?.department.trim(),
                    employeeId: userData?.employeeId
                  })

                  return res
                }}
                size="xs"
                header="Draft Permit"
                form="Are you sure you want to save as draft ?"
              />
            )}
          </form>
        )}
      />
      <EquipmentHistory
        isOpen={viewEquipmentHistory}
        toggle={() => setViewEquipmentHistory(!viewEquipmentHistory)}
        stockList={stockList}
        setStockList={setStockList}
        workOrders={workOrders}
        setWorkOrders={setWorkOrders}
      />
      <VehiclePermit
        toggle={() => setVehicleModal(!vehicleModal)}
        isOpen={vehicleModal}
        attachedForms={vehiclePermit}
        setAttachedForms={setVehiclePermit}
      />
      <WorkAtHeight
        toggle={() => setWorkAtHeightModal(!workAtHeightModal)}
        isOpen={workAtHeightModal}
        attachedForms={workAtHeight}
        setAttachedForms={setWorkAtHeight}
      />
      <ExcavationPermit
        toggle={() => setExcavationModal(!excavatinModal)}
        isOpen={excavatinModal}
        attachedForms={excavation}
        setAttachedForms={setExcavation}
      />
      <PositiveIsolation
        toggle={() => setPostiveModal(!positiveModal)}
        isOpen={positiveModal}
        attachedForms={positiveIsolation}
        setAttachedForms={setPositiveIsolation}
      />
      <ConfinedSpaceEntry
        toggle={() => setConfinedModal(!confinedModal)}
        isOpen={confinedModal}
        attachedForms={confinedSpace}
        setAttachedForms={setConfinedSpace}
      />
    </Container>
  )
}
