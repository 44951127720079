import { Route, Routes } from 'react-router-dom'
import InformationCardList from '../pages/informationCard/InformationCardList'
import ObservationCardActions from '../pages/obersvationCard/ObservationCardActions'
import ViewInformationCard from '../pages/informationCard/ViewInformationCard'
import ObservationCard from '../pages/ObservationCard'
import RecomendedForAward from '../pages/informationCard/RecomendedForAward'
import CloseShecard from '../pages/informationCard/CloseSheCard'
import WarningLetterList from '../pages/WarningLetterList'
import WarningLetter from '../pages/WarningLetter'
import ViewDraftCard from '../pages/informationCard/ViewDraftCard'

export default function InformationCardRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ObservationCardActions />} />
      <Route path="/create" element={<ObservationCard />} />
      <Route
        path="/created"
        element={
          <InformationCardList status="created" title={'TSHE Card - CREATED'} />
        }
      />
      <Route
        path="/draft"
        element={
          <InformationCardList status="draft" title={'TSHE Card - DRAFT'} />
        }
      />
      <Route
        path="/approved"
        element={
          <InformationCardList
            status="approved"
            title={'TSHE Card - APPROVED'}
          />
        }
      />
      <Route
        path="/recommendAward"
        element={
          <InformationCardList
            status="recomendAward"
            title={'TSHE Card - RECOMMEND TO AWARD'}
          />
        }
      />
      <Route
        path="/awarded"
        element={
          <InformationCardList status="awarded" title={'TSHE Card - AWARDED'} />
        }
      />
      <Route
        path="/inProgress"
        element={
          <InformationCardList
            status="icProgress"
            title={'TSHE Card - IN PROGRESS'}
          />
        }
      />
      <Route
        path="/closeSafetyOfficer"
        element={
          <InformationCardList
            status="departmentClosed"
            title={'TSHE Card - CLOSE'}
          />
        }
      />
      <Route
        path="/closeOim"
        element={
          <InformationCardList
            status="safetyOfficerClosed"
            title={'TSHE Card - CLOSE'}
          />
        }
      />
      <Route
        path="/closed"
        element={
          <InformationCardList status="closed" title={'TSHE Card - CLOSED'} />
        }
      />
      <Route
        path="/warninglist"
        element={<WarningLetterList onBack={'/firs/sheCard'} />}
      />
      <Route
        path="/warninglist/:number"
        element={<WarningLetter onBack={'/firs/sheCard/warninglist'} />}
      />
      <Route path="/draft/:id" element={<ViewDraftCard />} />
      <Route path="/created/:id" element={<ViewInformationCard />} />
      <Route
        path="/inProgress/:id"
        element={
          <CloseShecard
            onBack="/firs/sheCard/inProgress"
            currentStatus="icProgress"
            nextStatus="departmentClosed"
          />
        }
      />
      <Route
        path="/closeSafetyOfficer/:id"
        element={
          <CloseShecard
            onBack="/firs/sheCard/closeSafetyOfficer"
            currentStatus="departmentClosed"
            nextStatus="safetyOfficerClosed"
          />
        }
      />
      <Route
        path="/closeOim/:id"
        element={
          <CloseShecard
            onBack="/firs/sheCard/closeOim"
            currentStatus="safetyOfficerClosed"
            nextStatus="closed"
          />
        }
      />
      <Route
        path="/closed/:id"
        element={<RecomendedForAward onBack="/firs/sheCard/closed" />}
      />
      <Route
        path="/recommendAward/:id"
        element={<RecomendedForAward onBack="/firs/sheCard/recommendAward" />}
      />
      <Route
        path="/awarded/:id"
        element={<RecomendedForAward onBack="/firs/sheCard/awarded" />}
      />
    </Routes>
  )
}
