import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Col, ModalFooter, Row, Table } from 'reactstrap'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { incrementby1 } from '../../components/Forms/JSAForm'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import toNormalCase from '../../components/lib/toNormalCase'
import { stock } from '../../components/serverurl'
import { Box, renderData } from '../workManagement/PmTree'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { useContext, useState } from 'react'
import { UserContext } from '../../App'
import { prStatuses } from '../../components/constants/woStatuses'
import { ViewHistory } from '../ViewCM'

export default function ViewPR({ title }: { title: any }) {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const [submit, setSubmit]: any = useState()
  const navigate = useNavigate()

  const fetchpr = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/inventory/po/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, isRefetching }: any = useQuery('pr', fetchpr)

  const pr = data?.pr
  const history = data?.history

  const generalDetails: any = {
    'PR Number': pr?.number,
    Status: prStatuses[pr?.status],
    date: pr?.date,
    department: pr?.department,
    priority: pr?.priority,
    raisedBy: pr?.raisedBy
  }

  const equipmentDetail: any = {
    // classCode: pr?.classCode,
    tag: pr?.tag,
    tagDescription: pr?.tagDescription,
    tagId: pr?.tagId,
    tagType: pr?.tagType
  }

  const MaterialsTable = () => (
    <>
      <Table style={{ textAlign: 'center' }}>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Line Code</th>
            <th>Part Number</th>
            <th>Part Description</th>
            <th>Spare Type</th>
            <th>UOM</th>
            <th>Quantity on Hand</th>
            <th>Last Purchase Cost</th>
            <th>Currency</th>
            <th>Cost Center</th>
            <th>Quantity Required</th>
            <th>Date Required</th>
          </tr>
        </thead>
        <tbody>
          {pr?.materialRequest?.map(
            (
              {
                quantityRequested,
                costCenter,
                dateRequested,
                lineCode,
                spare: s
              }: any,
              index: number
            ) => (
              <tr key={s?.['Spare Part Number']}>
                <td>{incrementby1(index)}</td>
                <td>{lineCode}</td>
                <td>{s?.['Spare Part Number']}</td>
                <td>{s?.['Spare Part Description']}</td>
                <td>{s?.spareType}</td>
                <td>{s?.uom}</td>
                <td>{s?.onHandQuantity}</td>
                <td>{s?.priceIncludingTax}</td>
                <td>{s?.priceCurrency}</td>
                <td>{costCenter}</td>
                <td>{quantityRequested}</td>
                <td>{getReadableTime(dateRequested)}</td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    </>
  )

  const Preview = () => {
    return (
      <Row>
        <Col xs="6">
          <Box heading="General Details">
            {Object.keys(generalDetails)?.map((key: any) =>
              renderData(toNormalCase(key), generalDetails[key])
            )}
          </Box>
        </Col>
        <Col xs="6">
          <Box heading="Equipment">
            {Object.keys(equipmentDetail)?.map((key: any) =>
              renderData(toNormalCase(key), equipmentDetail[key])
            )}
          </Box>
        </Col>
        <Col xs="12">
          <MaterialsTable />
        </Col>
        <ViewHistory
          data={history}
          title={
            <p
              style={{
                margin: 0,
                fontSize: 20,
                fontWeight: 600,
                paddingTop: 5,
                paddingBottom: 5
              }}
            >
              APPROVAL HISTORY
            </p>
          }
          permitColor="#FFD580"
        />
      </Row>
    )
  }

  return (
    <ProtectedLayout onBack="/purchase/approve" title={title}>
      <Row style={{ margin: 15 }}>
        <Preview />
        <Spacer height={10} />
        {pr?.status === 'created' && (
          <ModalFooter>
            <Button color="secondary">Cancel</Button>
            <Button color="warning" onClick={() => setSubmit(true)}>
              Approve
            </Button>
          </ModalFooter>
        )}
      </Row>
      <SubmitModalWithForm
        isOpen={submit}
        onClose={(val: any) => {
          setSubmit(false)
          val && navigate('/purchase/approve')
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()
          const body: any = {
            status: 'approvedPR',
            lastestUpdatedBy: userData?.id
          }

          try {
            const res = await makeAuthenticatedRequest(
              `${stock}/inventory/po/${pr?._id}`,
              'PATCH',
              body,
              accounts,
              instance
            )

            return res.status
          } catch (error) {}
        }}
        size="xl"
        header="Approve Purchase Request"
      />
    </ProtectedLayout>
  )
}
