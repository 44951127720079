import { Row } from 'reactstrap'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import HOECOutline from '../SVGs/HOECOUtline'
import HOECSVG from '../SVGs/HOECSVG'

export default function HOECLayout() {
  return (
    <ProtectedLayout onBack="/eptw/dashboard">
      <Row style={{ margin: 0 }}>
        <img alt="hoec_logo" />
      </Row>
      <HOECSVG />
      {/* <HOECOutline /> */}
    </ProtectedLayout>
  )
}
