import { Row } from 'reactstrap'
import moment from 'moment'
import React from 'react'

import PrintExcavationTalk from '../prints/PrintExcavationTalk'
import PrintPermitHeader from '../prints/PrintPermitHeader'
import Spacer from '../Spacer'
import { PrintHeaderLogos } from '../prints/PrintHeaderLogos'
import { renderLongRow } from '../Modals/AuditPreviewModal'

type ViewExcavationType = {
  showPrintHeader?: any
  data: any
  rowStyle: any
  renderRow: any
  permitData: any
}

export const ColoredSubHeader = ({ rowStyle, text }: any) => {
  return (
    <>
      <Spacer height={10} />
      <Row
        style={{
          ...rowStyle,
          backgroundColor: rowStyle?.backgroundColor || 'orange',
          margin: 0
        }}
      >
        <p
          style={{
            margin: 0,
            lineHeight: 2,
            textAlign: 'center'
          }}
        >
          <b>{text}</b>
        </p>
      </Row>
      <Spacer height={10} />
    </>
  )
}

export default function ViewExcavation({
  showPrintHeader,
  data,
  rowStyle,
  renderRow,
  permitData
}: ViewExcavationType) {
  const fields: any = {
    'Start Date': moment(data?.startDate).format('DD-MM-YYYY'),
    'Estimated completion time': moment(data?.endDate).format('DD-MM-YYYY'),
    'Extent of Excavation': data?.extentExcavatoin,
    'Description of the job performed': data?.description,
    'Drawing Enclosed': data?.drawingEnclosed,
    'Tools / equipment to be used': data?.tools,
    'Type of Escort': data?.type
  }

  const header: any = {
    'Excavation Permit Number': data?.permitNumber,
    'Permit Number': permitData?.woNumber,
    'Permit Title': permitData?.permitTitle
  }

  return (
    <>
      {showPrintHeader && (
        <>
          <PrintHeaderLogos permitType={permitData?.type} />
          <PrintPermitHeader permitData={permitData} printData={permitData} />
        </>
      )}
      <ColoredSubHeader rowStyle={rowStyle} text="Excavation Permit" />
      <Row
        style={{
          ...rowStyle,
          fontSize: 14,
          margin: 0
        }}
      >
        {data?.permitNumber &&
          Object.keys(header).map((key) => renderRow(key, header[key]))}
      </Row>
      <Spacer height={10} />
      <Row>
        {Object.keys(fields).map((key) =>
          renderLongRow(key, fields[key], rowStyle.backgroundColor, 6)
        )}
      </Row>
      <Spacer height={10} />
      {permitData?.status === 'live' && (
        <Row
          style={{
            margin: 0,
            // backgroundColor: rowStyle?.backgroundColor,
            WebkitPrintColorAdjust: 'exact'
          }}
        >
          <PrintExcavationTalk />
        </Row>
      )}
    </>
  )
}
