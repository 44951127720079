import axios from 'axios'
import React, { useState } from 'react'
import { useMsal } from '@azure/msal-react'

import ProtectedLayout from './Layout/ProtectedLayout'
import { Button, Col, Input, Row } from 'reactstrap'
import { getAccessToken } from '../apiRequests'
import { loginRequest } from '../authConfig'
import { thermalImageApi } from './serverurl'

const ThermalImageToText = () => {
  const { accounts, instance } = useMsal()

  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])
  const [selectedImages, setSelectedImages] = useState([])

  const handleImageChange = (event: any) => {
    setSelectedImages(event.target.files)
  }

  const handleImageUpload = async () => {
    if (selectedImages.length > 0) {
      setLoading(true)

      const formData = new FormData()
      for (const image of selectedImages) {
        formData.append('images', image)
      }

      try {
        const token = await getAccessToken(loginRequest, accounts, instance)

        const response = await axios.post(thermalImageApi, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
          }
        })

        setResults(response.data.results)
      } catch (error) {
        console.error('Error:', error)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <ProtectedLayout>
      <Row style={{ margin: 15 }}>
        <Col xs="2">
          <Input type="file" multiple onChange={handleImageChange} />
        </Col>
        <Col xs="2" style={{ marginBottom: 10 }}>
          <Button color="warning" onClick={handleImageUpload}>
            Extract Text
          </Button>
        </Col>

        {loading ? (
          <p>Loading...</p>
        ) : (
          <div>
            {results.map((result: any, index) => (
              <div key={index}>
                <h4>{result.file}</h4>
                <p>{result.error || result.text}</p>
              </div>
            ))}
          </div>
        )}
      </Row>
    </ProtectedLayout>
  )
}

export default ThermalImageToText
