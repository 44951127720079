import { Row } from "reactstrap";
import { useQuery } from "react-query";
import { useMsal } from "@azure/msal-react";

import ProtectedLayout from "../../components/Layout/ProtectedLayout";
import { IconCard } from "../../components/Dashboard/IconCard";
import { stock, workOrderAPI } from "../../components/serverurl";
import { makeAuthenticatedRequest } from "../../apiRequests";

export default function WarehouseDashboard() {
  const { accounts, instance } = useMsal();

  const fetchprs = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/inventory/po`,
      "GET",
      null,
      accounts,
      instance
    );

    return response;
  };

  const { data }: any = useQuery("prs", fetchprs);

  const prs = data?.prList;

  const getcount = (status: any) => {
    const list = prs?.filter((wo: any) => wo?.status === status);

    return list?.length;
  };

  const fetchWorkRequests = async () => {
    const response = await makeAuthenticatedRequest(
      `${workOrderAPI}`,
      "GET",
      null,
      accounts,
      instance
    );

    return response;
  };

  const wres: any = useQuery("workRequestsAll", fetchWorkRequests);

  const workrequests = wres?.data?.workrequests;

  const materialRequests = workrequests?.filter(
    (w: any) =>
      w?.woStatus !== "closed" &&
      w?.mrStatus !== "materialIssued" &&
      w?.materialRequest?.length > 0
  );

  const materialReturned = workrequests?.filter(
    (w: any) =>
      w?.woStatus === "closed" &&
      w?.mrStatus !== "materialReturned" &&
      w?.materialRequest?.some((r: any) => r?.quantityReturned > 0)
  );

  const flows: any = [
    {
      name: "STOCK REGISTER",
      nav: "/cmms/supplychain/warehouse/stockRegister",
      src: require("./../../../src/images/Icons/stockRegister.png"),
    },
    {
      name: "ADD New Stock Item",
      nav: "/cmms/supplychain/warehouse/addNewStockItem",
      src: require("./../../../src/images/Icons/Add New Stock.png"),
    },
    {
      name: "MATERIAL REQUESTS",
      nav: "/cmms/supplychain/warehouse/materialRequests",
      count: materialRequests?.length,
      src: require("./../../../src/images/Icons/materialRequest.png"),
    },
    {
      name: "MATERIAL RETURNED",
      nav: "/warehouse/materialsReturned",
      count: materialReturned?.length,
      src: require("./../../../src/images/Icons/materialeturned.png"),
    },
    {
      name: "MATERIAL RECEIPTS",
      nav: "/cmms/supplychain/warehouse/materialsReceipts",
      count: getcount("issuedPO"),
      src: require("./../../../src/images/Icons/materialReceipt.png"),
    },
    {
      name: "MATERIAL RECEIVED ONBOARD",
      nav: "/cmms/supplychain/warehouse/materialsReceived",
      count: getcount("materialReceived"),
      src: require("./../../../src/images/Icons/materialOnBoard.png"),
    },
  ];

  return (
    <>
      <ProtectedLayout onBack="/inventory/services" title="WARE HOUSE">
        <Row style={{ margin: 15 }}>
          {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
            <IconCard
              key={`${index}-${name}`}
              name={name}
              nav={nav}
              canView={canView}
              count={count}
              src={src}
            />
          ))}
        </Row>
      </ProtectedLayout>
    </>
  );
}
