const jobsA = [
  {
    number: 1,
    description: 'Verify with Operations for availability of Equipment',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Check availability of Equipment',
    name: 'equipmetnAvailability'
  },
  {
    number: 2,
    description: 'Obtain a Copy of Performance Verification Scheme',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer Company Policy',
    name: 'verificationScheme'
  },
  {
    number: 3,
    description:
      'Obtain all required Permits and ensure Task Risk Assessment is carried out ',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer Company TRA',
    name: 'riskAssessment'
  },
  {
    number: 4,
    description:
      'Ensure a Tool Box Talk is carried out prior troubleshooting commencing and that all members in the work force were briefed Properly',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer IC EPTW ',
    name: 'toolboxTalk'
  },
  {
    number: 5,
    description:
      'If Required adhere to requirements in the relevant Codes of Practice related to the work to be done.',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer Codes of Practice',
    name: 'practiceCodes'
  }
]

const jobsB = [
  {
    number: 1,
    description: '⁠Lockout/Tagout equipment as required',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer Electrical Isolation philosophy'
  },
  {
    number: 2,
    description:
      'Ensure that equipment is adhered as per Isolation Standards COP 00 A081 and that Isolations are Verified with Area Authority',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer Isolation Standards COP 00 A081'
  },
  {
    number: 3,
    description:
      'Override all Shutdown Associated with the Equipment to be applied. Refer to Overrid Control Sheet COP CO009',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer COP CO009'
  },
  {
    number: 4,
    description:
      'Ensure a Tool Box Talk is carried out prior troubleshooting commencing and that all members in the work force were briefed Properly',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer IC EPTW '
  },
  {
    number: 5,
    description:
      'If Required adhere to requirements in the relevant Codes of Practice related to the work to be done.',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer Codes of Practice'
  }
]

const jobsC = [
  {
    number: '1',
    description: 'Verfy with Operations for availability of Equipment',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Check availability of Equipment'
  },
  {
    number: '2',
    description: 'Obtain a Copy of Performance Verification Scheme',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer Company Policy'
  },
  {
    number: '3',
    description:
      'Obtain all required Permits and ensure Task Risk Assessment is carried out',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer Company TRA'
  },
  {
    number: '4',
    description:
      'Refer to the appropriate loop.circuit diagrams and Cause & Effects Charts',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer C&E Charts'
  },
  {
    number: '5',
    description:
      'Override all Shutdown Associated with the Equipment to be applied. Refer to Overrid Control Sheet COP CO009',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer COP CO009'
  },
  {
    number: '6',
    description: '⁠Lockout/Tagout equipment as required',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer Electrical Isolation philosophy'
  },
  {
    number: '7',
    description:
      'Ensure that equipment is adhered as per Isolation Standards COP 00 A081 and that Isolations are Verified with Area Authority',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer Isolation Standards COP 00 A081'
  },
  {
    number: '8',
    description:
      'Remove Equipment from Service following approved depressurisation, bleeding, purging and Venting procedure',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Check availability of Equipment'
  },
  {
    number: '9',
    description:
      'Ensure a Tool Box Talk is carried out prior troubleshooting commencing and that all members in the work force were briefed Properly',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer IC EPTW'
  },
  {
    number: '10',
    description:
      'If Required adhere to requirements in the relevant Codes of Practice related to the work to be done.',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer Codes of Practice'
  }
]

const jobsD = [
  {
    number: '21',
    description:
      'Perform turbine compressor ingestive cleaning. Refer Appendix for task data.',
    mainOrg: 'MAINT',
    plannedHours: '75',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '75',
    remarks: 'Refer Company Policy'
  },
  {
    number: '22',
    description:
      'Inspect Air Inlet system from Air Inlet filter assembly to Air Inlet collector.',
    mainOrg: 'MAINT',
    plannedHours: '75',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '75',
    remarks: 'Check availability of Equipment'
  },
  {
    number: '23',
    description:
      'Inspect and replace Air Inlet filters. If pre-filters are installed, remove and visually inspect primary filter. Refer to the Appendix for filters replaced.',
    mainOrg: 'MAINT',
    plannedHours: '75',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '75',
    remarks: 'Refer Company Policy'
  },
  {
    number: '24',
    description:
      'Inspect PCD system. Check turbine connections, tubing, fittings, insulation and supports for wear, leaks and damage.',
    mainOrg: 'MAINT',
    plannedHours: '75',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '75',
    remarks: 'Refer Company TRA'
  },
  {
    number: '25',
    description:
      'Perform Borescope Inspection. Attach applicable report to Work Order.NOTE: Send report to RFE and FSS for review before submittal to customer, be precise in package and engine running hrs.',
    mainOrg: 'MAINT',
    plannedHours: '75',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '75',
    remarks: 'Refer C&E Charts'
  },
  {
    number: '26',
    description:
      'Inspect Variable Guide Vane system.NOTE: If the unit will be on standby for more than 1 week, recommend or implement a guide vane corrosion prevention program per SB 8.6/112.',
    mainOrg: 'MAINT',
    plannedHours: '75',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '75',
    remarks: 'Refer COP CO009'
  },
  {
    number: '27',
    description: 'Inspect Bleed Valve system.',
    mainOrg: 'MAINT',
    plannedHours: '75',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '75',
    remarks: 'Refer Electrical Isolation philosophy'
  },
  {
    number: '28',
    description:
      'Inspect Exhaust system from the turbine collector and drain through to the stack(s).',
    mainOrg: 'MAINT',
    plannedHours: '75',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '75',
    remarks: 'Refer Isolation Standards COP 00 A081'
  },
  {
    number: '29',
    description:
      'Ensure a Tool Box Talk is carried out prior troubleshooting commencing and that all members in the work force were briefed Properly',
    mainOrg: 'MAINT',
    plannedHours: '10',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '10',
    remarks: 'Check availability of Equipment'
  },
  {
    number: '30',
    description:
      'Obtain all required Permits and ensure Task Risk Assessment is carried out',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer IC EPTW'
  }
]

const jobsE = [
  {
    number: '21',
    description:
      'Perform turbine compressor ingestive cleaning. Refer Appendix for task data.',
    mainOrg: 'MAINT',
    plannedHours: '75',
    plannedResources: 'Planned Resources',
    totalManHours: '75',
    remarks: 'Check availability of Equipment',
    '': ''
  },
  {
    number: '22',
    description:
      'Inspect Air Inlet system from Air Inlet filter assembly to Air Inlet collector.',
    mainOrg: 'MAINT',
    plannedHours: '75',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '75',
    remarks: 'Check availability of Equipment',
    '': ''
  },
  {
    number: '23',
    description:
      'Inspect and replace Air Inlet filters. If pre-filters are installed, remove and visually inspect primary filter. Refer to the Appendix for filters replaced.',
    mainOrg: 'MAINT',
    plannedHours: '75',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '75',
    remarks: 'Refer Company Policy',
    '': ''
  },
  {
    number: '24',
    description:
      'Inspect PCD system. Check turbine connections, tubing, fittings, insulation and supports for wear, leaks and damage.',
    mainOrg: 'MAINT',
    plannedHours: '75',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '75',
    remarks: 'Refer Company TRA',
    '': ''
  },
  {
    number: '25',
    description:
      'Perform Borescope Inspection. Attach applicable report to Work Order.NOTE: Send report to RFE and FSS for review before submittal to customer, be precise in package and engine running hrs.',
    mainOrg: 'MAINT',
    plannedHours: '75',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '75',
    remarks: 'Refer C&E Charts',
    '': ''
  },
  {
    number: '26',
    description:
      'Inspect Variable Guide Vane system.NOTE: If the unit will be on standby for more than 1 week, recommend or implement a guide vane corrosion prevention program per SB 8.6/112.',
    mainOrg: 'MAINT',
    plannedHours: '75',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '75',
    remarks: 'Refer COP CO009',
    '': ''
  },
  {
    number: '27',
    description: 'Inspect Bleed Valve system.',
    mainOrg: 'MAINT',
    plannedHours: '75',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '75',
    remarks: 'Refer Electrical Isolation philosophy',
    '': ''
  },
  {
    number: '28',
    description:
      'Inspect Exhaust system from the turbine collector and drain through to the stack(s).',
    mainOrg: 'MAINT',
    plannedHours: '75',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '75',
    remarks: 'Refer Isolation Standards COP 00 A081',
    '': ''
  },
  {
    number: '29',
    description:
      'Ensure a Tool Box Talk is carried out prior troubleshooting commencing and that all members in the work force were briefed Properly',
    mainOrg: 'MAINT',
    plannedHours: '10',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '10',
    remarks: 'Check availability of Equipment',
    '': ''
  },
  {
    number: '30',
    description:
      'Obtain all required Permits and ensure Task Risk Assessment is carried out',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer IC EPTW'
  }
]

const jobsF = [
  {
    number: '1',
    description: 'Verfy with Operations for availability of Equipment',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Check availability of Equipment'
  },
  {
    number: '2',
    description: 'Obtain a Copy of Performance Verification Scheme',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer Company Policy'
  },
  {
    number: '3',
    description:
      'Obtain all required Permits and ensure Task Risk Assessment is carried out',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer Company TRA'
  },
  {
    number: '4',
    description:
      'Refer to the appropriate loop.circuit diagrams and Cause & Effects Charts',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer C&E Charts'
  },
  {
    number: '5',
    description:
      'Override all Shutdown Associated with the Equipment to be applied. Refer to Overrid Control Sheet COP CO009',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer COP CO009'
  },
  {
    number: '6',
    description: '⁠Lockout/Tagout equipment as required',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer Electrical Isolation philosophy'
  },
  {
    number: '7',
    description:
      'Ensure that equipment is adhered as per Isolation Standards COP 00 A081 and that Isolations are Verified with Area Authority',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer Isolation Standards COP 00 A081'
  },
  {
    number: '8',
    description:
      'Remove Equipment from Service following approved depressurisation, bleeding, purging and Venting procedure',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Check availability of Equipment'
  },
  {
    number: '9',
    description:
      'Ensure a Tool Box Talk is carried out prior troubleshooting commencing and that all members in the work force were briefed Properly',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer IC EPTW'
  },
  {
    number: '10',
    description:
      'If Required adhere to requirements in the relevant Codes of Practice related to the work to be done.',
    mainOrg: 'MAINT',
    plannedHours: '4',
    plannedResources: 'Mechanical Engineer',
    totalManHours: '4',
    remarks: 'Refer Codes of Practice'
  }
]

export const jobcodes: any = {
  CAOO5: jobsA,
  CA006: jobsB,
  CA011: jobsC,
  CAOO7: jobsD,
  CAOO9: jobsE,
  CA040: jobsF,
  CAO10: jobsD,
  CA012: jobsF,
  CA013: jobsA,
  CAO14: jobsC,
  CAO15: jobsF,
  CAO16: jobsF,
  CAO17: jobsE,
  CAO18: jobsC,
  CAO19: jobsF,
  CAO20: jobsB,
  CAO21: jobsD,
  CAO22: jobsD,
  CAO23: jobsC,
  CAO24: jobsB,
  CAO25: jobsD,
  CA026: jobsA,
  CA027: jobsE,
  CAO28: jobsE,
  CAO68: jobsB,
  CAO29: jobsD,
  CAO30: jobsC,
  CA031: jobsC,
  CA032: jobsD,
  CAO33: jobsB,
  CAO34: jobsE,
  CAO35: jobsA
}
