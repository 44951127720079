import axios from 'axios'
import React, { useState } from 'react'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { FaFileCircleXmark } from 'react-icons/fa6'
import { FaFileExport, FaFileImport, FaSave } from 'react-icons/fa'
import { MdCancel, MdEditDocument } from 'react-icons/md'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { ToastContainer, toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import CustomLabel from '../../components/InputFields/CustomLabel'
import ModalTitle from '../../components/ModalTitle'
import RcmLayout from '../../components/rcm/RcmLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { Box, renderData } from '../workManagement/PmTree'
import { getAttachment, systemsApi } from '../../components/serverurl'
import { getAccessToken, makeAuthenticatedRequest } from '../../apiRequests'
import { SubHeader } from '../VerifyCM'
import { IconButton, handleExport } from './Systems'
import { loginRequest } from '../../authConfig'

export const functionFailureHeaderKeys = [
  'FLOC',
  'Failure Mode',
  'Failure Mode Type',
  'Functional System Unit',
  'Functional System Number',
  'Functional System',
  'Function'
]

export const functionFailureDataKeys = [
  'floc',
  'failureMode',
  'failureModeType',
  'functionalSystemUnit',
  'functionalSystemNumber',
  'functionalSystem',
  'function'
]

export default function RcmFunctionFailure({
  setPin,
  pin
}: {
  pin?: any
  setPin?: any
}) {
  const { accounts, instance } = useMsal()
  const [file, setFile] = useState()
  const [array, setArray]: any = useState([])
  const [importModal, setImportModal]: any = useState(false)
  const [save, setSave]: any = useState(false)
  const [selected, setSelected]: any = useState()
  const [edit, setEdit]: any = useState(false)
  const [formData, setFormData]: any = useState({})
  const [deleteSystem, setDeleteSystem]: any = useState(false)

  const fileReader = new FileReader()

  const fetchFailureModes = async () => {
    const response = await makeAuthenticatedRequest(
      `${systemsApi}/functionFailure?system=${pin?.['System Number']}`,
      'GET',
      null,
      accounts,
      instance
    )
    return response
  }

  const { data, refetch }: any = useQuery(
    'failureModes data',
    fetchFailureModes
  )

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0])
  }

  const csvFileToArray = (string: any) => {
    const csvHeader = string
      .slice(0, string.indexOf('\n'))
      .split(',')
      .map((header: string) => header.trim())
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n')

    const array = csvRows.map((i: any) => {
      const values = i.split(',').map((value: string) => value.trim())
      const obj = csvHeader.reduce((object: any, header: any, index: any) => {
        const trimmedValue = values[index].replace(/^"|"$/g, '')
        object[header] = trimmedValue
        return object
      }, {})
      return obj
    })

    setArray(array)
  }

  const handleOnSubmit = (e: any) => {
    e.preventDefault()

    if (file) {
      fileReader.onload = function (event: any) {
        const text = event?.target?.result
        csvFileToArray(text)
      }

      fileReader.readAsText(file)
    }
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const updateSystemForm = (
    <Row>
      <Col xs="6">
        <CustomLabel label="Discipline" />
        <Input
          type="text"
          name="Discipline"
          onChange={handleInputChange}
          defaultValue={selected?.['Discipline']}
        />
        <CustomLabel label="Asset Type" />
        <Input
          type="text"
          name="Asset Type"
          onChange={handleInputChange}
          defaultValue={selected?.['Asset Type']}
        />
      </Col>
      <Col xs="6">
        <CustomLabel label="EQ Object Type" />
        <Input
          type="text"
          name="EQ Object Type"
          onChange={handleInputChange}
          defaultValue={selected?.['EQ Object Type']}
        />
        <CustomLabel label="Failure Mode" />
        <Input
          type="text"
          name="Failure Mode"
          onChange={handleInputChange}
          defaultValue={selected?.['Failure Mode']}
        />
      </Col>
    </Row>
  )

  const onEdit = () => {
    setEdit(true)
    setFormData({
      Discipline: selected?.['Discipline'],
      'Asset Type': selected?.['Asset Type'],
      'EQ Object Type': selected?.['EQ Object Type'],
      'Failure Mode': selected?.['Failure Mode']
    })
  }

  return (
    <RcmLayout
      onBack={'/cmms/rcm'}
      tabHeader={<h4 style={{ margin: 0 }}>Failure Modes</h4>}
    >
      <div style={{ display: 'flex', marginBottom: 10 }}>
        <IconButton
          icon={<FaFileImport size={25} />}
          buttonText={'Import'}
          onClick={() => setImportModal(true)}
        />
        <IconButton
          icon={<FaFileExport size={25} />}
          buttonText={'Export'}
          onClick={() => handleExport(data?.systems)}
        />
        <IconButton
          icon={<FaFileCircleXmark size={25} />}
          buttonText={'Delete'}
          onClick={() => setDeleteSystem(true)}
          isDisabled={!selected}
        />
        <IconButton
          icon={<MdEditDocument size={25} />}
          buttonText={'Edit'}
          onClick={onEdit}
          isDisabled={!selected}
        />
        <IconButton
          icon={<FaSave size={25} />}
          buttonText={'Save'}
          onClick={() => setSave(true)}
          isDisabled={array?.length === 0}
        />
        <IconButton
          icon={<MdCancel size={25} />}
          buttonText={'Cancel Import'}
          isDisabled={array?.length === 0}
          onClick={() => {
            setArray([])
            toast('Import Cancelled')
          }}
        />
      </div>
      <div style={{ minHeight: '80vh' }}>
        {/* {array?.length > 0 && (
          <>
            <SubHeader header="Imported Data" permitColor="gold" />
            <Table
              bordered
              style={{
                width: 'max-content',
                overflow: 'scroll',
                height: 100,
                borderColor: '#000'
              }}
            >
              <thead>
                <tr key={'header'}>
                  {functionFailureHeaderKeys?.map((key) => <th>{key}</th>)}
                </tr>
              </thead>
              <tbody>
                {array.map((item: any, index: any) => (
                  <tr key={`${index}-${item?.['HIERARCHY FLOC ID']}`}>
                    {functionFailureHeaderKeys.map(
                      (key: any, index: number) => (
                        <td key={index}>{item[key]}</td>
                      )
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )} */}
        {array?.length === 0 && pin && (
          <SubHeader
            header={`System - ${pin?.['System Number']} - ${pin?.['System Name']}`}
            permitColor="gold"
          />
        )}
        {data?.systems?.length > 0 && array?.length === 0 && (
          <>
            <Table
              key={'failure modes data'}
              style={{ width: 'max-content', borderColor: '#000' }}
              bordered
            >
              <thead>
                <tr key={'header'}>
                  <th></th>
                  {functionFailureHeaderKeys?.map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.systems?.map((item: any, index: any) => (
                  <tr key={`${index}-${item?.['floc']}`}>
                    <td>
                      <Input
                        type="checkbox"
                        checked={selected?._id === item?._id}
                        onClick={(e: any) =>
                          e?.target?.checked ? setSelected(item) : setSelected()
                        }
                      />
                    </td>
                    {functionFailureDataKeys.map((key: any, index: number) => (
                      <td key={index}>{item[key]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </div>
      {importModal && (
        <Modal isOpen={importModal} toggle={() => setImportModal(!importModal)}>
          <ModalTitle height={50} title="Import File" />
          <ModalBody>
            <div>
              <form>
                <Input
                  type={'file'}
                  id={'csvFileInput'}
                  accept={'.csv'}
                  onChange={handleOnChange}
                />
                <br />
                <a
                  href={`${getAttachment}/template.csv`}
                  target="_blank"
                  download={'template.csv'}
                  rel="noreferrer"
                >
                  Download Template
                </a>
              </form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={() => setImportModal(false)}>
              Close
            </Button>
            <Button
              color="warning"
              onClick={async (e) => {
                await handleOnSubmit(e)
                setPin()
                setSelected()
                setImportModal(false)
              }}
            >
              Import
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {save && (
        <SubmitModalWithForm
          isOpen={save}
          size="sm"
          onClose={(val: any) => {
            setSave(false)
          }}
          onSubmit={async (e: any) => {
            e.preventDefault()
            const token = await getAccessToken(loginRequest, accounts, instance)
            const formData: any = new FormData()

            formData.append('file', file)

            try {
              const res: any = await axios.post(
                `${systemsApi}/functionFailure`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                  }
                }
              )

              if (res?.data?.status === 'success') {
                await refetch()
                setArray([])
                setPin()
                setSelected()
              }

              return res?.data?.status
            } catch (error) {
              console.error(error)
            }
          }}
          header={`Update Systems`}
        />
      )}
      {edit && (
        <SubmitModalWithForm
          isOpen={edit}
          size="lg"
          onClose={(val: any) => {
            setEdit(false)
          }}
          form={updateSystemForm}
          onSubmit={async (e: any) => {
            e.preventDefault()
            try {
              const res = await makeAuthenticatedRequest(
                `${systemsApi}/functionFailure`,
                'PATCH',
                formData,
                accounts,
                instance
              )

              if (res?.status === 'success') {
                await refetch()
              }

              return res?.status
            } catch (error) {
              console.error(error)
            }
          }}
          header={`Update System `}
        />
      )}
      {deleteSystem && (
        <SubmitModalWithForm
          isOpen={deleteSystem}
          size="lg"
          onClose={(val: any) => {
            setDeleteSystem(false)
            setSelected()
          }}
          onSubmit={async (e: any) => {
            e.preventDefault()
            try {
              const res = await makeAuthenticatedRequest(
                `${systemsApi}/failureMode?id=${selected?._id}`,
                'DELETE',
                null,
                accounts,
                instance
              )

              if (res?.status === 'success') {
                await refetch()
              }

              return res?.status
            } catch (error) {
              console.error(error)
            }
          }}
          form={
            <>
              <p>Are you sure you want to delete System ?</p>
              <Box heading="System Details">
                {functionFailureHeaderKeys?.map((key: any) =>
                  renderData(key, selected?.[key])
                )}
              </Box>
            </>
          }
          header={`Delete Floc System`}
        />
      )}
      <ToastContainer
        theme="dark"
        position="top-center"
        style={{ fontSize: 16 }}
      />
    </RcmLayout>
  )
}
