import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import React, { useContext, useState } from 'react'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import ViewPermits from '../../components/Tables/ViewPermits'
import { epermit } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { useLocation } from 'react-router-dom'
import { UserContext } from '../../App'
import { Row } from 'reactstrap'

export default function LivePermits({ status }: { status: String }) {
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const [pageNumber, setPageNumber] = useState(0)

  const location = useLocation()

  const currentStage = location?.pathname?.split('/')?.at(-1)

  const fetchAdminPermissions = async () => {
    const response = await makeAuthenticatedRequest(
      `${epermit}?page=${pageNumber}&status=${status}&department=${userData?.department}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, refetch }: any = useQuery(
    'eptwLive',
    fetchAdminPermissions
  )

  return (
    <ProtectedLayout onBack="/eptw/dashboard">
      <Row style={{ margin: 10 }}>
        <ViewPermits
          data={data}
          isLoading={isLoading}
          refetch={refetch}
          setPageNumber={setPageNumber}
          navigateTo={`/eptw/newPermit/${currentStage}`}
        />
      </Row>
    </ProtectedLayout>
  )
}
