import { Route, Routes } from 'react-router-dom'

import ApprovePermit from '../pages/eptw/ApprovePermit'
import EditRisks from '../pages/eptw/EditRisks'
import FinishIsolation from '../pages/eptw/FinishIsolation'
import Issue from '../pages/eptw/Issue'
import IssuePermit from '../pages/eptw/IssuePermit'
import LiveIsolation from '../pages/eptw/LiveIsolation'
import LivePermits from '../pages/eptw/LivePermits'
import MakeLivePermit from '../pages/eptw/MakeLivePermit'
import PermitsByStatus from '../pages/deIsolationAndClose/ViewPermits'
import StartIsolation from '../pages/eptw/startIsolation'
import UpdateLivePermit from '../pages/eptw/UpdateLivePermit'
import UpdateRiskAssessment from '../pages/eptw/UpdateRiskAssessment'
import Verify from '../pages/eptw/verify'
import VerifyPermit from '../pages/eptw/VerifyPermit'
import PermitsWithIsolations from '../pages/eptw/PermitsWithIsolations'
import HandleBackIsolations from '../pages/eptw/GoBacktoIsolations'
import FetchPermitsByLocation from '../pages/FetchPermitsByLocation'
import RejectedPermit from '../pages/eptw/RejectedPermit'

export default function NewPermitRoutes() {
  return (
    <Routes>
      <Route path="/issue" element={<Issue />} />
      <Route
        path="/approve"
        element={<FetchPermitsByLocation status="issued" flow="newPermit" />}
      />
      <Route
        path="/makeLive"
        element={
          <PermitsByStatus
            currentPermits={'approved'}
            checkDepartment
            flow="newPermit"
          />
        }
      />
      {/* <Route
        path="/rejected"
        element={
          <PermitsByStatus
            currentPermits={'rejected'}
            checkDepartment
            flow="newPermit"
          />
        }
      /> */}
      <Route path="/rejected/:id" element={<RejectedPermit />} />
      <Route path="/live" element={<LivePermits status="live" />} />
      <Route path="/issue/:id" element={<IssuePermit />} />
      <Route path="/approve/:id" element={<ApprovePermit />} />
      <Route path="/makeLive/:id" element={<MakeLivePermit />} />
      <Route
        path="/live/:id"
        element={<UpdateLivePermit onBack={'/eptw/newPermit/live'} />}
      />
      <Route path="/updateRiskAssessments/:id" element={<EditRisks />} />
      <Route path="/verify" element={<Verify />} />
      <Route
        path="/verify/:id"
        element={<VerifyPermit onBack="/eptw/newPermit/verify" />}
      />
      <Route path="/updateRiskAssessments" element={<UpdateRiskAssessment />} />
      <Route path="/isolation" element={<HandleBackIsolations />} />
      <Route
        path="/isolation/start"
        element={
          <PermitsWithIsolations
            currentPermits="verified"
            flow="newPermit/isolation"
            isolationStatus="created"
          />
        }
      />
      <Route
        path="/isolation/finish"
        element={
          <PermitsWithIsolations
            currentPermits="verified"
            flow="newPermit/isolation"
            isolationStatus="started"
          />
        }
      />
      <Route
        path="/isolation/live"
        element={
          <PermitsWithIsolations
            currentPermits="verified"
            flow="newPermit/isolation"
            isolationStatus="finished"
          />
        }
      />
      <Route path="/isolation/start/:id" element={<StartIsolation />} />
      <Route path="/isolation/finish/:id" element={<FinishIsolation />} />
      <Route path="/isolation/live/:id" element={<LiveIsolation />} />
    </Routes>
  )
}
