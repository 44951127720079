import { Col, Row, Spinner, Table } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import ReactPaginate from 'react-paginate'
import Select from 'react-select'

import DropDownFetch from '../InputFields/DropDownFetch'
import { EmptyContent } from '../../pages/CreateNewPermit'
import { getOptions } from '../../pages/firs'
import { incrementby1 } from '../Forms/JSAForm'
import { permitFilters, tagsAPI } from '../serverurl'
import { renderArray } from '../../pages/WaitingForPIC'

type ViewPermitsType = {
  isLoading: any
  data: any
  refetch: any
  setPageNumber: any
  navigateTo: String
  equipment?: any
  setEquipment?: any
  location?: any
  setLocation?: any
  department?: any
  setDepartment?: any
  permitNumber?: any
  setPermitNumber?: any
  closed?: any
  setUnit?: any
  setProd?: any
  setLti?: any
  setPlant?: any
}

export const workLcoations = [
  'Mechanical Workshop',
  'Fire Station',
  'Sub Station',
  'Control Room',
  'Instrument Workshop',
  'Process Area',
  'Admin Ware House'
]

export const departments = [
  'Mechanical',
  'Electrical',
  'Instrument',
  'Production',
  'Admin Ware House',
  'HSE'
]

export default function LessonLearntTable({
  isLoading,
  data,
  refetch,
  setPageNumber,
  equipment,
  setEquipment,
  setLocation,
  setDepartment,
  permitNumber,
  setPermitNumber,
  setUnit,
  setProd,
  setLti,
  setPlant
}: ViewPermitsType) {
  const navigate = useNavigate()

  const styles = `@media print
  {    
      .no-print, .no-print *
      {
          display: none !important;
      }
  }`
  return (
    <>
      <style type="text/css" media="print">
        {styles}
      </style>
      <Row className="no-print">
        {setUnit && (
          <Col xs="12" md="3">
            <p style={{ fontWeight: 700 }}>Unit Shutdown</p>
          </Col>
        )}
        {setProd && (
          <Col xs="12" md="3">
            <p style={{ fontWeight: 700 }}>Production Loss</p>
          </Col>
        )}
        {setLti && (
          <Col xs="12" md="3">
            <p style={{ fontWeight: 700 }}>LTI Incident</p>
          </Col>
        )}
        {setPlant && (
          <Col xs="12" md="3">
            <p style={{ fontWeight: 700 }}>Plant Shutdown</p>
          </Col>
        )}
      </Row>
      <Row className="no-print">
        {setPermitNumber && (
          <Col xs="12" md="3">
            <DropDownFetch
              value={permitNumber}
              setValue={setPermitNumber}
              url={`${permitFilters}/number`}
            />
          </Col>
        )}
        {setEquipment && (
          <Col xs="12" md="3">
            <DropDownFetch
              name="equipment"
              value={equipment}
              setValue={setEquipment}
              url={`${tagsAPI}`}
            />
          </Col>
        )}
        {setLocation && (
          <Col xs="12" md="3">
            <Select
              options={[
                { value: undefined, label: 'Select' },
                ...getOptions(workLcoations)
              ]}
              onChange={(e: any) => setLocation(e?.value)}
            />
          </Col>
        )}
        {setDepartment && (
          <Col xs="12" md="3">
            <Select
              options={[
                { value: undefined, label: 'Select' },
                ...getOptions(departments)
              ]}
              onChange={(e: any) => setDepartment(e?.value)}
            />
          </Col>
        )}
        {setUnit && (
          <Col xs="12" md="3">
            <Select
              options={[
                { value: undefined, label: 'Select' },
                ...getOptions(['Yes', 'No'])
              ]}
              onChange={(e: any) => setUnit(e?.value)}
            />
          </Col>
        )}
        {setProd && (
          <Col xs="12" md="3">
            <Select
              options={[
                { value: undefined, label: 'Select' },
                ...getOptions(['Yes', 'No'])
              ]}
              onChange={(e: any) => setProd(e?.value)}
            />
          </Col>
        )}
        {setLti && (
          <Col xs="12" md="3">
            <Select
              options={[
                { value: undefined, label: 'Select' },
                ...getOptions(['Yes', 'No'])
              ]}
              onChange={(e: any) => setLti(e?.value)}
            />
          </Col>
        )}
        {setPlant && (
          <Col xs="12" md="3">
            <Select
              options={[
                { value: undefined, label: 'Select' },
                ...getOptions(['Yes', 'No'])
              ]}
              onChange={(e: any) => setPlant(e?.value)}
            />
          </Col>
        )}
      </Row>
      <Table
        striped
        bordered
        style={{ borderColor: '#000', fontSize: 12, marginTop: 10 }}
      >
        <thead>
          <tr style={{ backgroundColor: 'orange', whiteSpace: 'nowrap' }}>
            {[
              'S.No.',
              'Permit Number',
              'Equipment Number',
              'Lesson Learnt',
              'Unit Shutdown',
              'Plant Shutdown',
              'Prod Loss',
              'LTI Incident'
            ]?.map((h: any) => <th key={h}>{h}</th>)}
          </tr>
        </thead>
        <tbody>
          {isLoading && !data && <EmptyContent label={<Spinner />} />}
          {data?.map((p: any, index: number) => (
            <React.Fragment key={p?.woNumber}>
              <tr style={{ textAlign: 'center' }}>
                <td>{incrementby1(index)}</td>
                <td
                  style={{
                    width: '30%',
                    cursor: 'pointer',
                    color: 'blue',
                    textDecoration: 'underline'
                  }}
                  onClick={() => navigate(`/eptw/lessonLearnt/${p?._id}`)}
                >
                  {p?.permit?.woNumber}
                </td>
                <td style={{ width: '10%' }}>
                  {renderArray(p?.permit?.equipment, false)}
                </td>
                <td style={{ width: '50%' }}>{p?.lessonLearnt}</td>
                <td style={{ width: '5%' }}>{p?.unitShutdown}</td>
                <td style={{ width: '5%' }}>{p?.plantShutdown}</td>
                <td style={{ width: '5%' }}>{p?.productionLoss}</td>
                <td style={{ width: '5%' }}>{p?.ltiIncident}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(data?.totalDocuments / 20)}
        marginPagesDisplayed={10}
        pageRangeDisplayed={2}
        onPageChange={async (values) => {
          await setPageNumber(values.selected + 1)
          refetch()
        }}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </>
  )
}
