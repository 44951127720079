import { Table } from 'reactstrap'

export default function PrintExcavationTalk() {
  return (
    <>
      <Table bordered style={{ borderColor: '#000', fontSize: 10 }}>
        <thead>
          <tr>
            <th>PRECAUTIONS</th>
            <th>CHECKED / REQUIRED / COMMENTS</th>
            <th>SINGATURE</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ backgroundColor: 'lightgray' }}>
              Safety (Special Precautions)
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Lighting</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Access / Excape Route</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Barrier Covers</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td style={{ backgroundColor: 'lightgray' }}>Electrical</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Have Drawings been checked for underground power lines</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Electrical power required to be isolated / tagged</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Has Cathode protection been bonded</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Has site been inspected for known cables</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td style={{ backgroundColor: 'lightgray' }}>Instruments</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Have underground instrument cables existing</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Requires Isolation ?</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td style={{ backgroundColor: 'lightgray' }}>IT</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Have underground instrument cables existing</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Requires Isolation ?</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td style={{ backgroundColor: 'lightgray' }}>Production</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Have drawings been checked for underground lines</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Have site been inspected for burried cables / services</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Have underground drinking water lines been checked</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Fluid services requires isolation / tagging</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </Table>
      <hr />
      <p style={{ fontSize: 10 }}>
        This work sall proceed provided the above requirements / precautions are
        executed through work permit and Safety Preacutions shall be adhered by
        Job Performer.
      </p>
    </>
  )
}
