import { Row } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import RcmLayout from '../../components/rcm/RcmLayout'
import { IconCard } from '../../components/Dashboard/IconCard'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'

export default function RcmDashboard() {
  const { accounts, instance } = useMsal()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/counts`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchRcmCounts', fetchRcm)

  const { Stage2, Stage3, Stage4, Stage5, Stage6 } = data?.countsByStatus || {}

  const flows: any = [
    {
      name: 'STAGE 1 (CREATE ANALYSIS)',
      nav: '/cmms/rcm/createAnalysis'
    },
    {
      name: 'View Stage 2 (Add Tags)',
      nav: '/cmms/rcm/stage2',
      count: Stage2
    },
    {
      name: 'Stage 3 (Define Function) ',
      nav: '/cmms/rcm/stage3',
      count: Stage3
    },
    {
      name: 'Stage 4 (Function Failure) ',
      nav: '/cmms/rcm/stage4',
      count: Stage4
    },
    {
      name: 'Stage 5 (Risk Assessment) ',
      nav: '/cmms/rcm/stage5',
      count: Stage5
    },
    {
      name: 'Stage 6 (Tree) ',
      nav: '/cmms/rcm/stage6',
      count: Stage6
    },
    {
      name: 'Print ',
      nav: '/cmms/rcm/print'
    }
  ]

  return (
    <RcmLayout
      onBack={'/cmms'}
      title={'RCM'}
      tabHeader={<h4 style={{ margin: 0 }}>Dashboard</h4>}
    >
      <Row style={{ margin: 15 }}>
        {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
          <IconCard
            key={`${index}-${name}`}
            name={name}
            nav={nav}
            canView={canView}
            count={count}
            src={src}
          />
        ))}
      </Row>
    </RcmLayout>
  )
}
