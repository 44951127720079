import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { informationCardEndpoint } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import EditDraftCard from './EditDraftCard'
import { Row } from 'reactstrap'

export default function ViewDraftCard() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()

  const fetchCard = async () => {
    const response = await makeAuthenticatedRequest(
      `${informationCardEndpoint}/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchCard', fetchCard)

  return (
    <ProtectedLayout
      onBack="/eptw/informationCard"
      title={data?.data?.cardNumber}
    >
      <Row style={{ margin: 10 }}>
        <EditDraftCard initialValues={data?.data} refetch={refetch} />
      </Row>
    </ProtectedLayout>
  )
}
