import { Col, Row, Table } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import AshokLeylandLayout from './AshokLeylandLayout'
import { alEquipmentApi } from '../components/serverurl'
import { getReadableTime } from '../pages/workManagement/WorkOrderDetails'
import { incrementby1 } from '../components/Forms/JSAForm'
import { Link } from '../pages/workManagement/PmTree'
import { makeAuthenticatedRequest } from '../apiRequests'
import toNormalCase from '../components/lib/toNormalCase'

export const AlWorkOrderTable = ({ list, navigate }: any) => {
  return (
    <Table bordered style={{ borderColor: '#000' }}>
      <thead>
        <tr style={{ backgroundColor: 'gold' }}>
          {[
            'S.No',
            'Work Order Number',
            'Description',
            'Work Type',
            'Department',
            // 'Group',
            // 'Service Provider',
            'Location',
            // 'Frequency',
            'Creted on'
          ]?.map((h: any) => <th key={h}>{h}</th>)}
        </tr>
      </thead>
      <tbody>
        {list?.map((workorder: any, index: any) => (
          <tr key={workorder?.number}>
            <td>{incrementby1(index)}</td>
            <td>
              <Link onClick={() => navigate(`${workorder?._id}`)}>
                {workorder?.number}
              </Link>
            </td>
            <td>
              {toNormalCase(workorder?.workType) || 'Preventive Maintenance'}
            </td>
            <td>
              {workorder?.pmJob?.pmJobDescription || workorder?.cm?.description}
            </td>
            <td>{workorder?.pmJob?.department || workorder?.cm?.department}</td>
            {/* <td>{workorder?.pmJob?.equipmentGroup}</td> */}
            {/* <td>{workorder?.pmJob?.serviceProvider}</td> */}
            <td style={{ width: '10%', textOverflow: 'ellipsis' }}>
              {workorder?.pmJob?.location || workorder?.cm?.location}
            </td>
            {/* <td>{workorder?.pmJob?.frequency}</td> */}
            <td>{getReadableTime(workorder?.pmTriggerDate)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default function AlWorkOrderList({ status }: any) {
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const fetchAlPMList = async () =>
    await makeAuthenticatedRequest(
      `${alEquipmentApi}/workorders?status=${status}`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data }: any = useQuery('AL PM list', fetchAlPMList)
  const workOrderList = data?.data?.workorders
  const orderedList = workOrderList?.sort(
    (a: any, b: any) => a?.serial - b?.serial
  )

  return (
    <AshokLeylandLayout
      onBack="/ashokleyland/mms/maintenance"
      title={`WORK ORDER LIST - ${status}`}
    >
      <Row style={{ margin: 15 }}>
        <Col>
          <AlWorkOrderTable list={orderedList} navigate={navigate} />
        </Col>
      </Row>
    </AshokLeylandLayout>
  )
}
