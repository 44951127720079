import defaultTheme from './constants/defaultTheme.json'

type GradientBoxType = {
  children?: any
  height?: number
  width?: number | string
  style?: object
}

export default function GradientBox({
  children,
  height = 60,
  width = 60,
  style
}: GradientBoxType) {
  const gradiantBox = {
    height: height,
    width: width,
    background: 'gold',
    border: '3px solid #FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    cursor: 'pointer',
    boxShadow:
      'rgba(0, 0, 0, 0.4) 0px 1px 2px, rgba(0, 0, 0, 0.3) 0px 2px 3px -3px, rgba(0, 0, 0, 0.2) 0px -2px 0px inset'
  }

  return <div style={{ ...gradiantBox, ...style }}>{children}</div>
}
