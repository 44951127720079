import arrayMutators from 'final-form-arrays'
import React, { useContext } from 'react'
import { Button, Col, Input, ModalBody, Row, Table } from 'reactstrap'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { MdDelete } from 'react-icons/md'

import LabeledRadioButtons from '../InputFields/LabeledRadioButton'
import LabledParagraphInput from '../InputFields/LabledParagraph'
import ModalTitle from '../ModalTitle'
import MultiSelect from '../InputFields/MultiSelect'
import Spacer from '../Spacer'
import LabeledDateTimeInput from '../InputFields/DateTime'
import ControlRiskMatrix from '../Modals/ControlRiskMatrix'
import SeverityRiskMatrix from '../Modals/SeverityRiskMatrix'
import {
  HIGH_RISK_COLOR,
  LOW_RISK_COLOR,
  NO_RISK_COLOR
} from '../constants/riskMatrixColors'
import { AllUsersContext } from '../../App'
import CustomLabel from '../InputFields/CustomLabel'
import { getOptions } from '../../pages/firs'

export const incrementby1 = (n: number) => n + 1

export const getRisk = (SP: any, CP: any) => {
  const regex = /\d+/g

  if (!SP && !CP) {
    return null
  }

  const SPMatches = SP?.match(regex) || [1, 1]
  const CPMatches = CP?.match(regex) || [1, 1]

  const [Severity, Probability, Control, People] = [...SPMatches, ...CPMatches]

  return Severity * Probability * Control * People
}

export const riskLevel = (level: any) => {
  if (!level) {
    return null
  }

  if (level < 15) {
    return 'Tolerable Risk'
  } else if (level >= 15 && level <= 30) {
    return 'Substantial Risk'
  } else if (level > 31) {
    return 'Significant Risk'
  } else {
    return null
  }
}

export const getColorforRiskLevel = (level: any) => {
  if (!level) {
    return null
  }

  if (level < 15) {
    return '#32CD32'
  } else if (level >= 15 && level <= 30) {
    return '#ff9248'
  } else if (level > 31) {
    return '#D22B2B'
  } else {
    return null
  }
}

export const getRiskColor: any = (level: any) => {
  if (!level) {
    return null
  }

  if (level < 15) {
    return NO_RISK_COLOR
  } else if (level >= 15 && level <= 30) {
    return LOW_RISK_COLOR
  } else if (level > 31) {
    return HIGH_RISK_COLOR
  } else {
    return null
  }
}

type QRAFormType = {
  values?: any
  setValues: any
  toggle?: any
}

export default function QRAForm({ values, setValues, toggle }: QRAFormType) {
  const { allUsers } = useContext(AllUsersContext)

  const validate = (values: any) => {
    const errors: any = {}
    if (!values.team) {
      errors.team = 'Required'
    }
    if (!values.task) {
      errors.task = 'Required'
    }
    if (!values.hazards || !values.hazards.length) {
      errors.hazards = { _error: 'At least one Activity must be entered' }
    } else {
      const hazardArrayErrors: any = []
      values.hazards.forEach((hazard: any, hazardIndex: any) => {
        const hazardErrors: any = {}
        if (!hazard || !hazard?.name) {
          hazardErrors.name = 'Hazard Title is Required'
          hazardArrayErrors[hazardIndex] = hazardErrors
        }
        if (!hazard || !hazard.effect) {
          hazardErrors.effect = 'Effect is Required'
          hazardArrayErrors[hazardErrors] = hazardErrors
        }
        if (hazard && hazard.controls && hazard.controls.length) {
          const controlsArrayErrors: any = []
          hazard.controls.forEach((control: any, controlIndex: any) => {
            if (!control || !control.length) {
              controlsArrayErrors[controlIndex] = 'Required'
            }
          })
          if (controlsArrayErrors.length) {
            hazardErrors.controls = controlsArrayErrors
            hazardArrayErrors[hazardIndex] = hazardErrors
          }
        }
      })
      if (hazardArrayErrors.length) {
        errors.hazards = hazardArrayErrors
      }
    }
    return errors
  }

  const renderField = ({
    input,
    label,
    type,
    name,
    meta: { touched, error }
  }: any) => (
    <div>
      <div>
        <Input {...input} type={'textarea'} placeholder={label} />
        {touched && error && <span style={{ color: 'red' }}>{error}</span>}
      </div>
    </div>
  )

  const renderControls = ({ fields, meta: { error } }: any) => (
    <>
      {fields.map((control: any, index: any) => (
        <React.Fragment key={index}>
          <Row style={{ marginBottom: 3 }}>
            <Col xs="11">
              <Field
                name={control}
                type="textarea"
                component={renderField}
                label={`Control #${index + 1}`}
              />
            </Col>
            <Col xs="1">
              <MdDelete
                onClick={() => fields.remove(index)}
                style={{ color: 'red' }}
              />
            </Col>
          </Row>
        </React.Fragment>
      ))}
      <>
        <Button color="link" onClick={() => fields.push()}>
          Add Control
        </Button>
      </>
    </>
  )

  const renderHazards = ({
    values,
    fields,
    meta: { error, submitFailed }
  }: any) => (
    <div
      style={{
        minHeight: '45vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Table bordered striped style={{ border: '1px solid #000' }}>
        <thead
          style={{ backgroundColor: 'orange', fontSize: 13, fontWeight: 900 }}
        >
          <tr>
            <th colSpan={4} />
            <th colSpan={2} style={{ textAlign: 'center' }}>
              Risk Evaluation
            </th>
            <th colSpan={2} />
            <th colSpan={2} style={{ textAlign: 'center' }}>
              After Protective
            </th>
            <th colSpan={3} />
          </tr>
          <tr>
            <th>Hazard</th>
            <th>Consquence / Effect</th>
            <th>Activity</th>
            <th>Condition</th>
            <th>Severity/ Probability</th>
            <th>Control/ People</th>
            <th>Initial Risk</th>
            <th style={{ textAlign: 'center' }}>Existing Control Measures</th>
            <th>Severity/ Probability</th>
            <th>Control/ People</th>
            <th>Residual Risk</th>
            <th>Risk Category</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fields.map((hazard: any, index: any) => (
            <React.Fragment key={index}>
              <tr>
                <td>
                  <Field
                    name={`${hazard}.name`}
                    type="textarea"
                    component={renderField}
                    label="Hazard"
                  />
                </td>
                <td>
                  <Field
                    name={`${hazard}.effect`}
                    type="textarea"
                    component={renderField}
                    label="Effect"
                  />
                </td>
                <td>
                  <Field name={`${hazard}.routine`} component="select">
                    <option />
                    <option value="Routine">Routine</option>
                    <option value="Non Routine">Non Routine</option>
                  </Field>
                </td>
                <td>
                  <Field name={`${hazard}.activity`} component="select">
                    <option />
                    <option value="Normal">Normal</option>
                    <option value="Abnormal">Abnormal</option>
                    <option value="Emergency">Emergency</option>
                  </Field>
                </td>
                <td>
                  <SeverityRiskMatrix
                    name={`${hazard}.initalSeverityProb`}
                    values={values?.hazards?.[index]?.initalSeverityProb}
                  />
                </td>
                <td>
                  <ControlRiskMatrix
                    name={`${hazard}.initalControlPeople`}
                    values={values?.hazards?.[index]?.initalControlPeople}
                  />
                </td>
                <td
                  style={{
                    width: '1%',
                    backgroundColor: getRiskColor(
                      getRisk(
                        values?.hazards?.[index]?.initalSeverityProb,
                        values?.hazards?.[index]?.initalControlPeople
                      )
                    ),
                    textAlign: 'center',
                    color: 'white',
                    fontWeight: 700
                  }}
                >
                  {getRisk(
                    values?.hazards?.[index]?.initalSeverityProb,
                    values?.hazards?.[index]?.initalControlPeople
                  )}
                </td>
                <td>
                  <Row>
                    <FieldArray
                      name={`${hazard}.controls`}
                      component={renderControls}
                    />
                  </Row>
                </td>
                <td>
                  <SeverityRiskMatrix
                    name={`${hazard}.residualSeverityProb`}
                    values={values?.hazards?.[index]?.residualSeverityProb}
                  />
                </td>
                <td>
                  <ControlRiskMatrix
                    name={`${hazard}.residualControlPeople`}
                    values={values?.hazards?.[index]?.residualControlPeople}
                  />
                </td>
                <td
                  style={{
                    width: '1%',
                    backgroundColor: getRiskColor(
                      getRisk(
                        values?.hazards?.[index]?.residualSeverityProb,
                        values?.hazards?.[index]?.residualControlPeople
                      )
                    ),
                    textAlign: 'center',
                    color: 'white',
                    fontWeight: 700
                  }}
                >
                  {getRisk(
                    values?.hazards?.[index]?.residualSeverityProb,
                    values?.hazards?.[index]?.residualControlPeople
                  )}
                </td>
                <td>
                  {riskLevel(
                    getRisk(
                      values?.hazards?.[index]?.residualSeverityProb,
                      values?.hazards?.[index]?.residualControlPeople
                    )
                  )}
                </td>
                <td style={{ color: 'red' }}>
                  <MdDelete onClick={() => fields.remove(index)} />
                </td>
              </tr>
            </React.Fragment>
          ))}
          <>
            {submitFailed && error && (
              <span style={{ color: 'red' }}>{error._error}</span>
            )}
          </>
        </tbody>
      </Table>
      <Button
        type="button"
        style={{ width: '100%' }}
        onClick={() => fields.push({})}
        color="warning"
      >
        Add Hazard
      </Button>
    </div>
  )

  const teamList = allUsers?.map(
    (user: any) => `${user?.jobTitle}-${user?.givenName}`
  )

  const onChange = (values: any) => {
    setValues({
      ...values,
      template: null,
      team: values?.team?.map((t: any) => t?.value)
    })
  }

  const riskInitialData: any = localStorage.getItem('riskData')
  const risks = JSON.parse(riskInitialData)

  return (
    <ModalBody style={{ backgroundColor: 'white' }}>
      <ModalTitle title="Qualitative Risk Assesment" height={50} />
      <Form
        onSubmit={onChange}
        initialValues={{ ...risks, team: values && getOptions(risks?.team) }}
        initialValuesEqual={() => true}
        validate={validate}
        mutators={{
          ...arrayMutators
        }}
        render={({
          handleSubmit,
          form,
          hasValidationErrors,
          values,
          ...rest
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs="3">
                <CustomLabel label="Department" />
                <Field
                  name={'department'}
                  component="select"
                  style={{
                    width: '-webkit-fill-available',
                    height: 38,
                    borderRadius: 5
                  }}
                >
                  <option value="" disabled selected>
                    Select Department
                  </option>
                  <option value="Mechanical">Mechanical</option>
                  <option value="Electrical">Electrical</option>
                  <option value="Instrument">Instrument</option>
                  <option value="Production">Production</option>
                  <option value="HSE">HSE</option>
                  <option value="Admin Ware House">Admin Ware House</option>
                </Field>
              </Col>
              <Col xs="3">
                <CustomLabel label="Location" />
                <Field
                  name={'location'}
                  component="select"
                  style={{
                    width: '-webkit-fill-available',
                    height: 38,
                    borderRadius: 5
                  }}
                >
                  <option value="" disabled selected>
                    Select Location
                  </option>
                  <option value="Admin Ware House">Admin Ware House</option>
                  <option value="Fire Station">Fire Station</option>
                  <option value="Mechanical Workshop">
                    Mechanical Workshop
                  </option>
                  <option value="Substation">Substation</option>
                  <option value="Control Room">Control Room</option>
                  <option value="Process Area">Process Area</option>
                </Field>
              </Col>
              <LabeledDateTimeInput name="date" label="Date" occupy={3} />
              <LabeledRadioButtons
                name="toolBox"
                label="Toolbox Talk Conducted"
                list={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' }
                ]}
                defaultValue="Yes"
                occupy={3}
              />
              <MultiSelect
                name="team"
                label="Assesment Team"
                options={getOptions(teamList)}
                occupy={6}
              />
              <LabledParagraphInput
                name="task"
                label="Activity / Task"
                occupy={6}
              />
            </Row>
            <Spacer height={20} />
            <FieldArray
              update={onChange}
              name="hazards"
              component={renderHazards}
              values={values}
            />
            <Spacer height={20} />
            <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
              <Button
                onClick={() => {
                  form.submit()
                  toggle()
                }}
                color={hasValidationErrors ? 'secondary' : 'success'}
                disabled={hasValidationErrors}
              >
                Save & Next
              </Button>
            </Row>
          </form>
        )}
      />
    </ModalBody>
  )
}
