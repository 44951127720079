import { useMsal } from '@azure/msal-react'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import ReactSelect from 'react-select'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Col,
  Container,
  Row
} from 'reactstrap'

import { incrementby1 } from '../Forms/JSAForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { riskAssessmentAPI } from '../serverurl'
import CustomLabel from '../InputFields/CustomLabel'
import Loader from '../Loader'
import ModalTitle from '../ModalTitle'
import QRATemplateView from '../Forms/QRATemplateView'
import Spacer from '../Spacer'

export default function SelectQRATemplate({
  setSelect,
  select
}: {
  setSelect?: any
  select?: any
}) {
  const [open, setOpen]: any = useState('1')

  const toggle: any = (id: any) => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  const { accounts, instance } = useMsal()

  const fetchRiskAssesmentTemplates = async () => {
    const response = await makeAuthenticatedRequest(
      riskAssessmentAPI,
      'GET',
      null,
      accounts,
      instance
    )

    return response.data
  }

  const { data, isLoading }: any = useQuery(
    'riskAssessments',
    fetchRiskAssesmentTemplates
  )
  const [templates, setTemplates]: any = useState(data)
  const [department, setDepartment]: any = useState('Mechanical')

  useEffect(() => {
    if (data && department) {
      setTemplates(
        data.qraTemplates.filter(
          (template: any) => template.department === department?.value
        )
      )
    } else {
      // If no department is selected, display all templates
      setTemplates(data?.qraTemplates)
    }
  }, [department, data?.qraTemplates])

  if (isLoading || !data) {
    return <Loader />
  }

  return (
    <>
      <Container fluid style={{ backgroundColor: 'white' }}>
        <Spacer height={10} />
        <ModalTitle title="Approved QRA Templates" height={50} />
        <CustomLabel label="Select Department" />
        <Row>
          <Col xs="3">
            <ReactSelect
              isMulti={false}
              options={[
                'Mechanical',
                'Electrical',
                'Instrument',
                'Production',
                'Admin Ware House',
                'HSE'
              ]?.map((d: any) => ({ value: d, label: d }))}
              onChange={(v: any) => setDepartment(v)}
            />
          </Col>
          <Col xs="2">
            <Button
              color="link"
              onClick={() => setTemplates(data?.qraTemplates)}
            >
              Show all
            </Button>
          </Col>
        </Row>
        <h6 style={{ textAlign: 'center', fontWeight: 700, padding: 10 }}>
          Select QRA Template
        </h6>
        {templates?.length > 0 && (
          <>
            {templates?.map((template: any, index: number) => (
              <React.Fragment key={`{${index}_${template.hazard}}`}>
                <Accordion open={open} {...{ toggle: toggle }}>
                  <AccordionItem>
                    <AccordionHeader targetId={`${incrementby1(index)}`}>
                      {template?.templateNumber}-{template?.task}
                    </AccordionHeader>
                    <AccordionBody
                      accordionId={`${incrementby1(index)}`}
                      children={
                        <QRATemplateView
                          template={{ template }}
                          setTemplate={setSelect}
                          select={select}
                        />
                      }
                    />
                  </AccordionItem>
                </Accordion>
              </React.Fragment>
            ))}
          </>
        )}
      </Container>
    </>
  )
}
