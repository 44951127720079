import arrayMutators from 'final-form-arrays'
import moment from 'moment'
import { useMsal } from '@azure/msal-react'
import { useContext, useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import AutoCompleteAndApi from '../../components/InputFields/AutoCompleteAndAPI'
import CustomLabel from '../../components/InputFields/CustomLabel'
import LabeledTextInput from '../../components/InputFields/LabeledTextInput'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import toNormalCase from '../../components/lib/toNormalCase'
import { Box, renderData } from '../workManagement/PmTree'
import { Button, Col, Input, ModalFooter, Row, Table } from 'reactstrap'
import { FormSection } from '../workManagement/WorkRequestForm'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { sparesList } from '../../components/constants/sparesParts'
import { stock, workOrderAPI } from '../../components/serverurl'
import { UserContext } from '../../App'

const validate = (values: any) => {
  const errors: any = {}
  if (!values.vessel) {
    errors.vessel = 'Required'
  }
  if (!values.priority) {
    errors.priority = 'Required'
  }

  return errors
}

const PurchaseSubmitModal = ({
  submit,
  setSubmit,
  formValues,
  spares
}: {
  submit: any
  setSubmit: any
  formValues: any
  spares: any
}) => {
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const [result, setResult]: any = useState()
  const generalDetails: any = {
    date: formValues?.date,
    department: formValues?.department,
    priority: formValues?.priority,
    raisedBy: formValues?.raisedBy
  }

  const orderDetails: any = {
    Vendor: (
      <div>
        <p style={{ margin: 0 }}>ABC Pvt Ltd</p>
        <p style={{ margin: 0 }}>KERKEPLAAT 14</p>
        <p style={{ margin: 0 }}>ZUID-HOLLAND</p>
        <p style={{ margin: 0 }}>3313 LC DORDRECHT</p>
        <p style={{ margin: 0 }}>Netherlands</p>
      </div>
    ),
    Attn: 'Gert Jan van Os',
    Phone: '+31786306200 '
  }

  const equipmentDetail: any = {
    costCenter: formValues?.costCenter,
    unit: formValues?.vessel,
    classCode: formValues?.classCode,
    tag: formValues?.tag,
    tagDescription: formValues?.tagDescription,
    tagId: formValues?.tagId,
    tagType: formValues?.tagType
  }

  const MaterialsTable = () => (
    <>
      <Table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Unit</th>
            <th>Cost Center</th>
            <th>Part Number</th>
            <th>Part Description</th>
            <th>Spare Type</th>
            <th>UOM</th>
            <th>Quantity on Hand</th>
            <th>Quantity Required</th>
            <th>Date Required</th>
          </tr>
        </thead>
        <tbody>
          {spares?.map((s: any, index: number) => (
            <tr key={s?.['Spare Part Number']}>
              <td>{incrementby1(index)}</td>
              <td>{formValues?.vessel}</td>
              <td>{s?.costCenter}</td>
              <td>{s?.['Spare Part Number']}</td>
              <td>{s?.['Spare Part Description']}</td>
              <td>{s?.spareType}</td>
              <td>{s?.uom}</td>
              <td>{s?.onHandQuantity}</td>
              <td>{s?.quantityRequested}</td>
              <td>{getReadableTime(s?.dateRequested)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )

  const Preview = () => {
    return (
      <Row>
        <Col xs="6">
          <Box heading="General Details">
            {Object.keys(generalDetails)?.map((key: any) =>
              renderData(toNormalCase(key), generalDetails[key])
            )}
          </Box>
        </Col>
        <Col xs="6">
          <Box heading="Equipment">
            {Object.keys(equipmentDetail)?.map((key: any) =>
              renderData(toNormalCase(key), equipmentDetail[key])
            )}
          </Box>
        </Col>
        <Col xs="12">
          <MaterialsTable />
        </Col>
      </Row>
    )
  }

  return (
    <SubmitModalWithForm
      isOpen={submit}
      onClose={(val: any) => {
        setSubmit(false)
        val && navigate('/purchase/dashboard')
        val && localStorage.removeItem('prItems')
        val && localStorage.removeItem('selectedSpare')
      }}
      onSubmit={async (e: any) => {
        e.preventDefault()
        try {
          const body = {
            ...formValues,
            materialRequest: spares,
            createdAt: moment()
          }

          const res = await makeAuthenticatedRequest(
            `${stock}/inventory/po`,
            'POST',
            body,
            accounts,
            instance
          )

          setResult(res.po)

          return res.status
        } catch (error) {}
      }}
      form={<Preview />}
      size="xl"
      header="Create Purchase Request"
      sucessView={
        <div>
          <Table bordered style={{ borderColor: '#000' }}>
            <tbody>
              <tr>
                <td>PR Number</td>
                <td>{result?.number || 'Number'}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      }
    />
  )
}

export default function PurchaseRequestForm() {
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const navigate = useNavigate()

  const localData: any = localStorage.getItem('prItems')
  const mrData = JSON.parse(localData)
  const tagDetails = mrData?.tagDetails

  const itemsFromReg: any = localStorage.getItem('selectedSpare')
  const selectedFromReg = JSON.parse(itemsFromReg)

  const [spares, setSpares]: any = useState(
    mrData?.spares || selectedFromReg || []
  )
  const [preview, setPreview]: any = useState(false)
  const [searchText, setSearchText]: any = useState()

  const fetchStock = async () =>
    await makeAuthenticatedRequest(
      `${stock}/inventory`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data }: any = useQuery('PurchaseStock', fetchStock)

  const [filtered, setFiltered]: any = useState(data?.inventoryList)

  useEffect(() => {
    setFiltered(data?.inventoryList)
  }, [data])

  const handleCheckboxClick = (spare: any) => {
    const id = spare?.['Spare Part Number']
    const isSelected = spares?.some((a: any) => a?.['Spare Part Number'] === id)

    if (isSelected) {
      setSpares(spares?.filter((s: any) => s?.['Spare Part Number'] !== id))
    } else {
      setSpares([...spares, { ...spare, spare: spare?._id }])
    }
  }

  const handleQuantityChange = (spareId: any, quantity: any) => {
    setSpares(
      spares?.map((s: any) =>
        s?.['Spare Part Number'] === spareId
          ? { ...s, quantityRequested: quantity, spare: s?._id }
          : s
      )
    )
  }

  const handleCostCenterChange = (spareId: any, quantity: any) => {
    setSpares(
      spares?.map((s: any) =>
        s?.['Spare Part Number'] === spareId
          ? { ...s, costCenter: quantity, spare: s?._id }
          : s
      )
    )
  }

  const handleDateChange = (spareId: any, date: any) => {
    setSpares(
      spares?.map((s: any) =>
        s?.['Spare Part Number'] === spareId
          ? { ...s, dateRequested: date, spare: s?._id }
          : s
      )
    )
  }

  const validateSpares = spares?.every(
    (s: any) => s?.quantityRequested && s?.dateRequested
  )

  useEffect(() => {
    if (searchText) {
      setFiltered([
        ...spares,
        ...filtered?.filter(
          (s: any) => s?.['Spare Part Description']?.includes(searchText)
        )
      ])
    }
  }, [searchText])

  const redirectToStockRegister = async (e: any, formValues: any) => {
    e.preventDefault()
    const storeValues = { tagDetails: formValues }
    await localStorage.setItem('prItems', JSON.stringify(storeValues))
    navigate('/warehouse/stockRegister')
  }

  return (
    <ProtectedLayout onBack="/purchase/dashboard" title="Purchase Request Form">
      <Row style={{ margin: 15 }}>
        <Form
          onSubmit={(values: any) => {}}
          mutators={{
            ...arrayMutators
          }}
          validate={validate}
          initialValues={{
            raisedBy: userData?.givenName,
            department: userData?.department,
            date: getReadableTime(new Date()),
            lastestUpdatedBy: userData?.id,
            tag: tagDetails?.tag,
            tagId: tagDetails?.tag,
            tagDescription: tagDetails?.tagDescription,
            tagType: tagDetails?.tagType,
            classCode: tagDetails?.classCode || 15,
            costCenter: tagDetails?.costCenter || 15
          }}
          render={({ values, form, hasValidationErrors }) => (
            <form>
              <FormSection title="General Details">
                <Row>
                  <Col xs="3">
                    <CustomLabel label="Vessel / RIG *" />
                    <Field
                      name={'vessel'}
                      component="select"
                      defaultValue=""
                      style={{
                        width: '-webkit-fill-available',
                        height: 38,
                        borderRadius: 5,
                        backgroundColor: '#DCDCDC'
                      }}
                    >
                      <option value="" disabled>
                        Select Vessel / Unit
                      </option>
                      <option>UNIT 1</option>
                      <option>UNIT 2</option>
                      <option>UNIT 3</option>
                      <option>UNIT 4</option>
                    </Field>
                  </Col>
                  <Col xs="3">
                    <CustomLabel label="Priority *" />
                    <Field
                      name={'priority'}
                      component="select"
                      defaultValue=""
                      style={{
                        width: '-webkit-fill-available',
                        height: 38,
                        borderRadius: 5,
                        backgroundColor: '#DCDCDC'
                      }}
                    >
                      <option value="" disabled>
                        Select Priority
                      </option>
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                    </Field>
                  </Col>
                  <LabeledTextInput
                    name="raisedBy"
                    label="Raised By"
                    occupy={3}
                    isDisabled
                  />
                  <LabeledTextInput
                    name="department"
                    label="Department"
                    occupy={3}
                    isDisabled
                  />
                  <LabeledTextInput
                    name="date"
                    label="Date"
                    occupy={3}
                    isDisabled
                  />
                </Row>
              </FormSection>
              <FormSection title="Equipment">
                <Col xs="3">
                  <CustomLabel label="Tag" />
                  <Field name="tag" style={{ background: '#DCDCDC' }}>
                    {({ input, ...rest }) => (
                      <div>
                        <AutoCompleteAndApi
                          isMulti={false}
                          name="tagId"
                          url={`${workOrderAPI}/filters`}
                          handleChange={(option: any, change: any) => {
                            console.log(option)
                            input.onChange(option?.value)
                            change('tagId', option?.value)
                            change('tagDescription', option?.description)
                            change('classCode', option?.classCode)
                            change('tagType', option?.tagType)
                            change('costCenter', option?.costCenter)
                            change('vessel', option?.vessel)
                          }}
                          needSelect={false}
                          input={input}
                          {...rest}
                        />
                      </div>
                    )}
                  </Field>
                </Col>
                <LabeledTextInput name="tagId" label="Tag Id" occupy={3} />
                <LabeledTextInput
                  name="tagDescription"
                  label="Tag Description"
                  occupy={6}
                />
                <LabeledTextInput name="tagType" label="Tag Type" occupy={3} />
                <LabeledTextInput
                  name="classCode"
                  label="Class Code"
                  occupy={3}
                />
                <LabeledTextInput
                  name="costCenter"
                  label="Cost Center"
                  occupy={3}
                />
              </FormSection>
              <FormSection title="Material Request">
                <>
                  <Row>
                    <Col xs="3" />
                    <Col xs="3">
                      <CustomLabel label="Search Part Number" />
                      <Input
                        type="text"
                        name="searchText"
                        onChange={(e: any) => setSearchText(e?.target?.value)}
                      />
                    </Col>
                    <Col xs="2">
                      <CustomLabel label="Search Part Number" />
                      <Button
                        color="warning"
                        onClick={() => setFiltered(sparesList)}
                      >
                        Clear
                      </Button>
                    </Col>
                    <Col xs="3">
                      <Button
                        style={{ marginTop: 40 }}
                        color="warning"
                        onClick={(e) => redirectToStockRegister(e, values)}
                      >
                        Select from Stock Register
                      </Button>
                    </Col>
                  </Row>
                  <Spacer height={15} />
                  <Row>
                    <Table>
                      <thead>
                        <tr>
                          <th>
                            <Input onChange={(e) => {}} type="checkbox" />
                          </th>
                          <th>Part Number</th>
                          <th>Part Description</th>
                          <th>Spare Type</th>
                          <th>UOM</th>
                          <th>Function Number</th>
                          <th>Function Description</th>
                          <th>Model</th>
                          <th>Manufacturer</th>
                          <th>Serial Number</th>
                          <th>Quantity on Hand</th>
                          <th>Cost Center</th>
                          <th>Quantity Required</th>
                          <th>Date Required</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values?.tagId &&
                          filtered?.length > 0 &&
                          filtered?.map((s: any) => (
                            <tr key={s?.['Spare Part Number']}>
                              <td>
                                <Input
                                  onClick={() => handleCheckboxClick(s)}
                                  checked={
                                    spares?.filter(
                                      (spare: any) =>
                                        spare?.['Spare Part Number'] ===
                                        s?.['Spare Part Number']
                                    )?.length === 1
                                  }
                                  type="checkbox"
                                />
                              </td>
                              <td>{s?.['Spare Part Number']}</td>
                              <td>{s?.['Spare Part Description']}</td>
                              <td>{s?.spareType}</td>
                              <td>{s?.uom}</td>
                              <td>{s?.functionNumber}</td>
                              <td>{s?.functionDescription}</td>
                              <td>{s?.model}</td>
                              <td>{s?.manufacturer}</td>
                              <td>{s?.serialNumber}</td>
                              <td>{s?.onHandQuantity}</td>
                              <td>
                                <Input
                                  type="text"
                                  defaultValue={s?.costCenter}
                                  onChange={(e: any) =>
                                    handleCostCenterChange(
                                      s?.['Spare Part Number'],
                                      e?.target?.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  onChange={(e: any) =>
                                    handleQuantityChange(
                                      s?.['Spare Part Number'],
                                      e?.target?.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="date"
                                  onChange={(e: any) =>
                                    handleDateChange(
                                      s?.['Spare Part Number'],
                                      e?.target?.value
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Row>
                  {!validateSpares && (
                    <span
                      style={{
                        fontSize: 12,
                        color: 'red'
                      }}
                    >
                      * Quantity And Date are mandatory to enter for Selected
                      spares
                    </span>
                  )}
                </>
              </FormSection>
              <ModalFooter>
                <Button
                  onClick={() => setPreview(true)}
                  color={hasValidationErrors ? 'secondary' : 'warning'}
                  disabled={hasValidationErrors}
                >
                  Preview
                </Button>
              </ModalFooter>
              <PurchaseSubmitModal
                submit={preview}
                setSubmit={() => setPreview(!preview)}
                formValues={values}
                spares={spares}
              />
            </form>
          )}
        />
      </Row>
    </ProtectedLayout>
  )
}
