import axios from "axios"
import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, Row, Table } from "reactstrap"
import { incrementby1 } from "../components/Forms/JSAForm"
import SectionHeader from "../components/Layout/SectionHeader"
import { protectiveMaintenancePermit } from "../components/serverurl"
import Spacer from "../components/Spacer"
import { renderRow, SubHeader } from "./VerifyCM"

export default function PMAction({ data, hasOngoingPermit }: any) {
  const navigate = useNavigate()

  const permitData: any = {
    "Title": data?.protectiveMaintenance?.title,
    "Tag Number": data?.protectiveMaintenance?.tagNumber,
    "Tag Description": data?.protectiveMaintenance?.tagDescription,
    "Instrument Type": data?.protectiveMaintenance?.instrumentType,
    "Responsible Department": data?.protectiveMaintenance?.respDepartment,
    "Frequency": data?.protectiveMaintenance?.frequency,
    "Plant Location": data?.protectiveMaintenance?.plantLocation,
    "Due Date": data?.protectiveMaintenance?.dueDate,
    "Priority": data?.protectiveMaintenance?.priority,
    "Status": data?.protectiveMaintenance?.status,
    "location": data?.protectiveMaintenance?.location,
    "P&ID": data?.protectiveMaintenance?.pidNumber,
    "Line Number": data?.protectiveMaintenance?.lineNumber,
    "Equipment Name": data?.protectiveMaintenance?.equipmentName,
    "Max Range ": data?.protectiveMaintenance?.maxRange,
    "Units": data?.protectiveMaintenance?.units,
    "Process HookUp Number": data?.protectiveMaintenance?.processHookUpNumber,
    "Functional Loop": data?.protectiveMaintenance?.processHookUpNumber,
    "Model Number": data?.protectiveMaintenance?.modelNo,
    "Manufacturer Name": data?.protectiveMaintenance?.manufacturerName,
    "PR Number": data?.protectiveMaintenance?.prNumber,
    "Remarks": data?.protectiveMaintenance?.remarks
  }

  const onSubmit = async (status: string) => {
    try {
      await axios.post(`${protectiveMaintenancePermit}?status=${status}&id=${data?._id}`, data).then(response => {
        navigate('/pm/dashboard')
      })
    } catch (error) {
      console.info(error)
    }
  }

  return (
    <>
      <SectionHeader title='PROTECTIVE MAINTENANCE' />
      <Spacer height={10} />
      <SubHeader header={data?.tagNumber} />
      <Spacer height={10} />
      {Object.keys(permitData).map(key => renderRow(key, permitData[key]))}
      <Spacer height={20} />

    </>
  )
}