import { Route, Routes } from 'react-router-dom'

import ManualDashboard from '../pages/manual/ManualDashboard'
import DocumentationTree from '../pages/workManagement/Documentation'
import Manuals from '../pages/dashboardPages/Manuals'
import {
  DepartmentCodesFolder,
  DocumentTypeCodesFolder,
  LogicCodesFolder,
  OriginFolders,
  OriginatorFolders
} from '../pages/manual/ManualFolders'
import ViewManuals from '../pages/dashboardPages/ViewManuals'
import DraftManuals from '../pages/manual/DraftManuals'
import CreatedManuals from '../pages/manual/CreatedManuals'
import ApprovedManuals from '../pages/manual/ApprovedManuals'
import RejectedManuals from '../pages/manual/RejectedManuals'

export default function ManualRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ManualDashboard />} />
      <Route path="/hierarchy" element={<DocumentationTree />} />
      <Route path="/create" element={<Manuals />} />
      <Route path="/view" element={<OriginatorFolders />} />
      <Route path="/draft" element={<DraftManuals />} />
      <Route path="/created" element={<CreatedManuals />} />
      <Route path="/rejected" element={<RejectedManuals />} />
      <Route path="/view/:orginator" element={<OriginFolders />} />
      <Route path="/view/:orginator/:origin" element={<LogicCodesFolder />} />
      <Route
        path="/view/:orginator/:origin/:logicCode"
        element={<DepartmentCodesFolder />}
      />
      <Route
        path="/view/:orginator/:origin/:logicCode/:departmentCode"
        element={<DocumentTypeCodesFolder />}
      />
      <Route
        path="/view/:orginator/:origin/:logicCode/:departmentCode/:documentType/docs/"
        element={<ApprovedManuals />}
      />
    </Routes>
  )
}
