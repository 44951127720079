import { Row, Col } from 'reactstrap'
import theme from './../../components/constants/defaultTheme.json'

const coldWork = require('./../../images/Cold_Work.png')
const hotWork = require('./../../images/Hot_Work.png')
const vehiclePermit = require('./../../images/VehiclePermit.png')

const getColor = (value: any) => {
  if (value === 0 || value === 'hotWork') {
    return theme.workPermit.hotWork
  } else if (value === 1 || value === 'coldWork') {
    return theme.workPermit.coldWork
  } else if (value === 2 || value === 'vehiclePermit') {
    return theme.workPermit.vehiclePermit
  } else {
    return theme.colors.lightGray1
  }
}

const getImage = (value: any) => {
  if (value === 0 || value === 'hotWork') {
    return hotWork
  } else if (value === 1 || value === 'coldWork') {
    return coldWork
  } else if (value === 2 || value === 'vehiclePermit') {
    return vehiclePermit
  } else {
    return hotWork
  }
}

export const renderArray = (items: any, iterated: boolean) =>
  items?.map((t: any) => (iterated ? t.value : t)).join(', ')

const getType = (value: any) => {
  if (value === 0 || value === 'hotWork') {
    return 'Hot Work Permit'
  } else if (value === 1 || value === 'coldWork') {
    return 'Cold Work Permit'
  } else if (value === 2 || value === 'vehiclePermit') {
    return 'Vehicle Permit'
  } else {
    return '-'
  }
}

export function PrintHeaderLogos({ permitType }: any) {
  return (
    <>
      <Row
        style={{
          padding: '10px 0 10px 0',
          background:
            'linear-gradient(90deg, rgba(247,118,42,1) 29%, rgba(0,42,105,1) 76%)',
          marginBottom: 20,
          WebkitPrintColorAdjust: 'exact',
          display: 'flex',
          alignItems: 'center',
          maxHeight: '20',
          overflow: 'hidden'
        }}
      >
        <Col style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div
            style={{
              backgroundColor: getColor(permitType),
              padding: 10,
              display: 'flex',
              alignItems: 'center',
              border: '5px solid #FFF',
              borderRadius: 20
            }}
          >
            <img
              src={require('./../../../src/images/Logo.png')}
              alt="HOEC Logo"
              height={40}
            />
          </div>
        </Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <p
            style={{
              margin: 0,
              fontFamily: 'times new roman',
              whiteSpace: 'nowrap',
              fontSize: 36,
              fontWeight: 900,
              color: '#FFF',
              textShadow: '4px 4px 5px rgba(0,0,0,0.6)'
            }}
          >
            {getType(permitType)}
          </p>
        </Col>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <img src={getImage(permitType)} alt="Work Permit Logo" height={60} />
        </Col>
        <Col>
          <div
            style={{
              backgroundColor: getColor(permitType),
              padding: 10,
              display: 'flex',
              alignItems: 'center',
              border: '5px solid #FFF',
              borderRadius: 20,
              justifyContent: 'center'
              // boxShadow: '10px 10px 17px -1px rgba(0,0,0,0.75)'
            }}
          >
            <img
              src={require('./../../../src/images/hoec-logo.png')}
              alt="HOEC Logo"
              height={40}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}
