import { Route, Routes } from 'react-router-dom'
import ApproveDeIsolationAndClose from '../pages/deIsolationAndClose/ApproveDeIsolationAndClose'
import CloseDeIsolation from '../pages/deIsolationAndClose/CloseDeIsolation'
import FinishDeIsolation from '../pages/deIsolationAndClose/FinishDeIsolation'
import IssueDeIsolationAndClose from '../pages/deIsolationAndClose/IssueDeIsolationAndClose'
import StartDeIsolation from '../pages/deIsolationAndClose/StartDeIsolationAndClose'
import VerifyDeIsolationAndClose from '../pages/deIsolationAndClose/VerifyDeIsolationClose'

import PermitsByStatus from '../pages/deIsolationAndClose/ViewPermits'
import PermitsWithIsolations from '../pages/eptw/PermitsWithIsolations'
import FetchPermitsByLocation from '../pages/FetchPermitsByLocation'

export default function DICRoutes() {
  return (
    <Routes>
      <Route
        path="/verify"
        element={
          <PermitsByStatus currentPermits="deIsolationAndCloseInitiated" />
        }
      />
      <Route
        path="/start"
        element={
          <PermitsWithIsolations
            currentPermits="deIsolationAndCloseVerified"
            flow="deIsolationAndClose"
            isolationStatus="live"
          />
        }
      />
      <Route path="/start/:id" element={<StartDeIsolation />} />
      <Route path="/verify/:id" element={<VerifyDeIsolationAndClose />} />
      <Route
        path="/finish"
        element={
          <PermitsWithIsolations
            currentPermits="deIsolationAndCloseVerified"
            flow="deIsolationAndClose"
            isolationStatus="startedDeIsolation"
          />
        }
      />
      <Route path="/finish/:id" element={<FinishDeIsolation />} />
      <Route
        path="/close"
        element={
          <PermitsWithIsolations
            currentPermits="deIsolationAndCloseVerified"
            flow="deIsolationAndClose"
            isolationStatus="finishedDeIsolation"
          />
        }
      />
      <Route path="/close/:id" element={<CloseDeIsolation />} />
      <Route
        path="/approve"
        element={<FetchPermitsByLocation status="deIsolatedForClose" />}
      />
      <Route path="/approve/:id" element={<ApproveDeIsolationAndClose />} />

      <Route
        path="/issue"
        element={
          <PermitsByStatus currentPermits="deIsolationAndCloseApproved" />
        }
      />
      <Route path="/issue/:id" element={<IssueDeIsolationAndClose />} />
    </Routes>
  )
}
