import { Button, Input, Row } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useContext, useState } from 'react'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { ltiEndPoint } from '../../components/serverurl'
import React from 'react'
import { useAdminPermissions } from '../eptw/EditRisks'
import ViewPermit from '../eptw/ViewPermit'
import { PermissionsContext } from '../../App'
import SubmitPermitReport from '../../components/Modals/SubmitPermitReport'

export default function MakeLiveLTI() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const { permissions } = useContext(PermissionsContext)

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const [submitModal, setSubmitModal]: any = useState(false)

  const handleSubmit = async () => {
    const submitValues: any = {
      permitId: data?.permit?._id,
      isolationsId: data?.permit?.isolations?._id
    }

    const response = await makeAuthenticatedRequest(
      `${ltiEndPoint}/makeLive`,
      'PATCH',
      submitValues,
      accounts,
      instance
    )

    return response.status
  }

  return (
    <ProtectedLayout onBack="/eptw/lti/makeLive">
      <ViewPermit data={data} isLoading={isLoading} />
      {data?.permit?.status === 'ltiIssued' && permissions?.canMakeLiveLTI && (
        <>
          <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Button
              color="warning"
              style={{ marginTop: 10 }}
              onClick={() => setSubmitModal(true)}
            >
              Close Permit and Make Isolation Live
            </Button>
          </Row>
          {submitModal && (
            <SubmitPermitReport
              size="xl"
              isOpen={submitModal}
              permit={data?.permit}
              onClose={async (val: any) => {
                setSubmitModal(false)
                handleSubmit()
                val && navigate('/eptw/lti/makeLive')
              }}
            />
          )}
          {/* {submitModal && (
            <SubmitModalWithForm
              isOpen={submitModal}
              onClose={(val: any) => {
                setSubmitModal(false)
                val && navigate('/eptw/lti/issue')
              }}
              onSubmit={handleSubmit}
            />
          )} */}
        </>
      )}
    </ProtectedLayout>
  )
}
