import Loader from '../../components/Loader'
import ViewAndApprovePermit from '../../components/ViewAndApprovePermit'

export default function ViewPermit({ data, isLoading, showStatus }: any) {
  if (isLoading) {
    return <Loader />
  }

  return (
    <ViewAndApprovePermit
      selectedPermit={data?.permit}
      refetch={() => {}}
      viewNextPermit={() => {}}
      attachments={{
        vehicle: data?.permit?.vehiclePermit,
        excavation: data?.permit?.excavationPermit,
        positiveIsolation: data?.permit?.positiveIsolation,
        confinedSpace: data?.permit?.confinedSpaceEntry,
        workAtHeight: data?.permit?.workAtHeight
      }}
      riskAssesmentValues={data?.permit?.riskAssesmentPermit}
      isolationValues={data?.permit?.isolations?.isolations}
      permitHistory={data?.history}
      riskAssesmentHistory={data?.riskAssesmentHistory}
      isolationHistory={data?.isolationHistory}
      gasReadings={data?.gasReadings}
      showStatus={data?.permit?.status === 'live'}
      pbuData={data?.pbuReadings}
      report={data?.report}
    />
  )
}
