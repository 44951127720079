import { Col, Row, Table } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import { alEquipmentApi } from '../components/serverurl'
import { getReadableTime } from '../pages/workManagement/WorkOrderDetails'
import { incrementby1 } from '../components/Forms/JSAForm'
import { Link } from '../pages/workManagement/PmTree'
import { makeAuthenticatedRequest } from '../apiRequests'
import AshokLeylandLayout from './AshokLeylandLayout'

export const PmListTable = ({ list, navigate }: any) => {
  return (
    <Table bordered style={{ borderColor: '#000' }}>
      <thead>
        <tr style={{ backgroundColor: 'gold' }}>
          {[
            'S.No',
            'Job Plan Number',
            'PM Description',
            'Department',
            'Group',
            'Service Provider',
            'Location',
            'Frequency',
            'Trigger Date',
            'PM Starting Month'
          ]?.map((h: any) => <th key={h}>{h}</th>)}
        </tr>
      </thead>
      <tbody>
        {list?.map((pm: any, index: any) => (
          <tr key={pm?.pmJobNo}>
            <td>{incrementby1(index)}</td>
            <td>
              <Link onClick={() => navigate(`${pm?.pmJobNo}`)}>
                {pm?.pmJobNo}
              </Link>
            </td>
            <td>{pm?.pmJobDescription}</td>
            <td>{pm?.department}</td>
            <td>{pm?.equipmentGroup}</td>
            <td>{pm?.serviceProvider}</td>
            <td style={{ width: '10%', textOverflow: 'ellipsis' }}>
              {pm?.location}
            </td>
            <td>{pm?.frequency}</td>
            <td>{getReadableTime(pm?.pmTriggerDate)}</td>
            <td>{getReadableTime(pm?.pmStartingMonth)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default function ALPmList() {
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const fetchAlPMList = async () =>
    await makeAuthenticatedRequest(
      `${alEquipmentApi}/pm`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data }: any = useQuery('AL PM list', fetchAlPMList)
  const pmList = data?.data?.list
  const orderedList = pmList?.sort((a: any, b: any) => a?.pmJobNo - b?.pmJobNo)

  return (
    <AshokLeylandLayout onBack="/ashokleyland/pm" title="PM LIST">
      <Row style={{ margin: 15 }}>
        <Col>
          <PmListTable list={orderedList} navigate={navigate} />
        </Col>
      </Row>
    </AshokLeylandLayout>
  )
}
