export const Designations = {
  MM: 'MINES MANAGER',
  IM: 'INSTALLATION MANAGER',
  DIM: 'DEPUTY INSTALLATION MANAGER',
  PIC: 'PRODUCTION INCHARGE',
  HSE: 'DEPUTY HSE',
  FO: 'FIRE OFFICER',
  AWH: 'ADMIN / WARE HOUSE',
  MI: 'MECHANICAL INCHARGE',
  PE: 'PRODUCTION INCHARGE',
  ME: 'MECHANICAL ENGINEER',
  EE: 'ELECTRICAL ENGINEER',
  IE: 'INSTRUMENT ENGINEER',
  PAE: 'PANEL ENGINEER',
  FE: 'FIELD ENGINEER'
}