import moment from 'moment'
import ReactPaginate from 'react-paginate'
import { useNavigate } from 'react-router-dom'
import { Spinner, Table } from 'reactstrap'
import { EmptyContent } from '../../pages/CreateNewPermit'
import { incrementby1 } from '../Forms/JSAForm'

export default function PMPreviewTable({
  data,
  setPageNumber,
  totalCount,
  loading
}: any) {
  const navigate = useNavigate()
  const headers = [
    'S.No',
    'WO Number',
    'Tag Number',
    'Resp. Department',
    'Frequency',
    'Priority',
    'Due Date',
    'Created At'
  ]

  return (
    <>
      <Table
        bordered
        striped
        style={{ border: '1px solid #000', fontSize: 14 }}
      >
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            {headers.map((header: string) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody style={{ height: '100%' }}>
          {!loading &&
            data?.map((d: any, index: number) => (
              <tr key={d.title}>
                <td>{incrementby1(index)}</td>
                <td>{d?.woNumber}</td>
                <td
                  onClick={() => navigate(`/protectiveMaintenance/${d?._id}`)}
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                >
                  {d?.protectiveMaintenance?.tagNumber}
                </td>
                {[
                  // d?.description,
                  d?.protectiveMaintenance?.respDepartment,
                  d?.protectiveMaintenance?.frequency,
                  d?.protectiveMaintenance?.priority,
                  d?.protectiveMaintenance?.dueDate,
                  moment(d?.createdAt).format('DD-MM-YYYY')
                ]?.map((f: any) => <td key={f}>{f}</td>)}
              </tr>
            ))}
          {loading && (
            <tr>
              <td colSpan={9}>
                <EmptyContent label={<Spinner />} />
              </td>
            </tr>
          )}
          {data?.length === 0 && (
            <tr>
              <td colSpan={9}>
                <EmptyContent label="No data found" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={totalCount}
        marginPagesDisplayed={10}
        pageRangeDisplayed={2}
        onPageChange={(values) => setPageNumber(values.selected)}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </>
  )
}
