import { Row, Table } from 'reactstrap'
import { renderRow } from '../../pages/VerifyCM'
import { incrementby1 } from '../Forms/JSAForm'
import toNormalCase from '../lib/toNormalCase'
import { ColoredSubHeader } from '../previews/ViewExcavationPermit'
import Spacer from '../Spacer'
import { permitColor } from '../ViewAndApprovePermit'
import './../css/a4print.css'

type ViewIsolationsType = {
  data: any
  rowStyle: any
  renderRow?: any
  permitData: any
}

export default function PrintIsolationsTable({
  data,
  rowStyle,
  permitData
}: ViewIsolationsType) {
  const header: any = {
    // 'Permit Number': permitData?.woNumber,
    // 'Permit Title': permitData?.permitTitle,
    'Isolation Number': data?.[0]?.number?.replace(/-\d+$/, ''),
    'Lock Box Number': <b>{permitData?.isolations?.lockBoxNumber}</b>
  }
  return (
    <>
      <ColoredSubHeader rowStyle={rowStyle} text="Isolations" />
      <Row
        style={{
          ...rowStyle,
          fontSize: 12,
          marginLeft: 0
        }}
      >
        {Object.keys(header).map((key) =>
          renderRow(key, header[key], permitColor(permitData?.type))
        )}
      </Row>
      <Spacer height={10} />
      <Table
        className="custom-print-table"
        bordered
        striped
        style={{
          fontSize: 12,
          borderCollapse: 'collapse',
          border: '1px solid #000',
          borderColor: '#000'
        }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            {[
              'S.No',
              ...(data?.[0]?.number ? ['Number'] : []),
              'Description',
              'Isolation Point',
              'Method',
              'Type',
              'State',
              'DeIsolated State',
              'PBU Readings',
              ...(data?.[0]?.number ? ['Status'] : []),
              'Comment'
            ]?.map((s: any) => <th key={s}>{s}</th>)}
          </tr>
        </thead>
        <tbody>
          {data?.map((i: any, index: number) => (
            <tr>
              <td>{incrementby1(index)}</td>
              <td style={{ whiteSpace: 'nowrap' }}>{i?.number}</td>
              {[
                i?.isolationDescription,
                i?.isolationPoint,
                i?.isolationMethod,
                i?.isolationType,
                i?.isolationState,
                i?.deIsolatedState,
                i?.readings || 'NA',
                ...(i?.status ? [toNormalCase(i?.status)] : []),
                i?.isolationComment
              ]?.map((f: any, index: number) => (
                <td key={`${f}_${index}`}>{f}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}
