import arrayMutators, { update } from 'final-form-arrays'
import React, { useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Col, Input, ModalFooter, Row, Table } from 'reactstrap'
import { Form } from 'react-final-form'
import { ToastContainer, toast } from 'react-toastify'

import { makeAuthenticatedRequest } from '../apiRequests'
import { alEquipmentApi } from '../components/serverurl'
import { Box, renderData } from '../pages/workManagement/PmTree'
import { AlLoader } from './AlViewPM'
import AshokLeylandLayout from './AshokLeylandLayout'
import toNormalCase from '../components/lib/toNormalCase'
import { getReadableTime } from '../pages/workManagement/WorkOrderDetails'
import { AlStage2Fields } from '../pages/firs'
import SubmitModalWithForm from '../components/Modals/SubmitModalWithForm'
import { incrementby1 } from '../components/Forms/JSAForm'
import { ViewHistory } from '../pages/ViewCM'
import CustomLabel from '../components/InputFields/CustomLabel'

const ViewMitigations = ({ data }: { data: any }) => {
  return (
    <Row>
      <Col>
        <Box heading="Root cause Analysis">{data?.rootCause}</Box>
        <Box heading="Team Members">
          <Table bordered>
            <thead>
              <tr style={{ backgroundColor: 'gold' }}>
                <th />
                <th>Name</th>
                <th>Department</th>
              </tr>
            </thead>
            <tbody>
              {data?.teamMembers?.map((t: any, index: any) => (
                <tr key={index}>
                  <td>{incrementby1(index)}</td>
                  <td>{t?.name}</td>
                  <td>{t?.department}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
        <Box heading="5 Why's - Root Cause Analysis">
          <Table striped bordered>
            <thead style={{ backgroundColor: 'gold' }}>
              <tr>
                {[
                  '',
                  'Understaing',
                  'Parent Cause',
                  'AND / OR',
                  'Potential Cause',
                  'Is this True?',
                  'Evidence',
                  'Action to Understand'
                ]?.map((h: any) => <th key={h}>{h}</th>)}
              </tr>
            </thead>
            <tbody>
              {data?.understandings?.map((u: any, index: any) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{incrementby1(index)}</td>
                    <td>{u?.understanding}</td>
                    <td>{u?.parentCause}</td>
                    <td colSpan={5}></td>
                  </tr>
                  {u?.potentialCause?.map((p: any) => (
                    <>
                      <tr key={index}>
                        <td colSpan={3} />
                        <td>{p?.condition}</td>
                        <td>{p?.potentialCause}</td>
                        <td>{p?.isTrue}</td>
                        {/* <td>{p?.isTrue}</td> */}
                        <td>{p?.evidence}</td>
                        <td>{p?.actionToUnderstand}</td>
                      </tr>
                    </>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </Box>
        <Box heading="Mitigations">
          <Table striped bordered>
            <thead style={{ backgroundColor: 'gold' }}>
              <tr>
                {[
                  'Action Number',
                  'What do you want to understand ?',
                  'What Action is required',
                  'Action Owner',
                  'Status',
                  'Target Date',
                  'Priority Actions',
                  'Findings'
                ]?.map((h: any) => <th key={h}>{h}</th>)}
              </tr>
            </thead>
            <tbody>
              {data?.mitigations?.map((m: any, index: any) => (
                <tr key={index}>
                  <td>{m?.actionNumber}</td>
                  <td>{m?.understanding}</td>
                  <td>{m?.actionRequired}</td>
                  <td>{m?.actionOwner}</td>
                  <td>{m?.status}</td>
                  <td>{m?.targetDate}</td>
                  <td>{m?.priorityAction}</td>
                  <td>{m?.findings}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
        <Box heading="Sustainable Action">{data?.sustainableAction}</Box>
      </Col>
    </Row>
  )
}

const Stage2Form = () => {
  const initialValues = {
    teamMembers: [
      {
        name: 'Nimesh',
        department: 'Maintenance'
      },
      {
        name: 'Anim',
        department: 'CTL'
      },
      {
        name: 'Kyush',
        department: 'Proto Shop'
      }
    ],
    rootCause:
      'On August 5, 2024, at approximately 10:30 AM, a high-pressure pipeline leak was detected at Well Site A. The leak resulted in the release of approximately 500 gallons of crude oil into the surrounding environment before being contained. No injuries were reported, but the incident led to a temporary shutdown of the site for safety reasons.\n\n',
    understandings: [
      {
        understanding: 'Understand 1',
        parentCause: 'Parent Cause 1',
        potentialCause: [
          {
            potentialCause: 'Potential Cause 1',
            evidence: 'Evidence 1',
            actionToUnderstand: 'Actionn 1',
            isTrue: 'Yes',
            condition: 'OR'
          },
          {
            condition: 'AND',
            potentialCause: 'Potential Cause 2',
            evidence: 'Evidence 2',
            actionToUnderstand: 'Action 9',
            isTrue: 'Yes'
          }
        ]
      },
      {
        understanding: 'Understanding 2',
        parentCause: 'Parent Cause 4',
        potentialCause: [
          {
            condition: 'AND',
            potentialCause: 'Potential Cause 10',
            evidence: 'Evidence 6',
            actionToUnderstand: 'Action 34',
            isTrue: 'Yes'
          }
        ]
      }
    ],
    mitigations: [
      {
        actionNumber: '1',
        understanding: 'Undertanding 1',
        actionRequired: 'Action 3',
        actionOwner: 'Owner 4',
        targetDate: '2024-08-26',
        findings: 'Findings 4',
        priorityAction: 'Yes',
        status: 'Open'
      },
      {
        actionNumber: 2,
        understanding: 'Understanding 5',
        actionRequired: 'Action 8',
        actionOwner: 'Owner 4',
        targetDate: '2024-08-21',
        findings: 'Finding 5',
        priorityAction: 'Yes',
        status: 'Open'
      }
    ],
    sustainableAction: 'Initiate Preventive Action'
  }
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const [formValues, setFormValues]: any = useState(false)
  const [modal, setModal]: any = useState(false)
  return (
    <Form
      onSubmit={(values) => console.log(values)}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators
      }}
      render={({ form, values }) => (
        <form>
          <AlStage2Fields />
          <ModalFooter>
            <Button
              color="warning"
              // onClick={() => {
              //   setFormValues(values)
              //   setModal(true)
              //   // form.submit()
              // }}
            >
              Reject
            </Button>
            <Button
              color="warning"
              onClick={() => {
                setFormValues(values)
                setModal(true)
                // form.submit()
              }}
            >
              Submit
            </Button>
          </ModalFooter>
          <SubmitModalWithForm
            isOpen={modal}
            onClose={(val: any) => {
              setModal(false)
              if (val) {
                navigate('/ashokleyland/firs/incidentreporting/stage2')
              }
            }}
            onSubmit={async (e: any) => {
              e.preventDefault()
              const res = await makeAuthenticatedRequest(
                `${alEquipmentApi}/firs/stage2/${id}`,
                'PATCH',
                values,
                accounts,
                instance
              )

              return res?.status
            }}
            form={<ViewMitigations data={formValues} />}
            size="xl"
            header="Update Incident Report"
          />
        </form>
      )}
    />
  )
}

export default function AlViewReport({ onBack }: { onBack: any }) {
  const { accounts, instance } = useMsal()
  const { id } = useParams()
  const navigate = useNavigate()
  const [modal, setModal] = useState(false)
  const [updateStatus, setUpdateStatus]: any = useState()
  const [reject, setReject]: any = useState(false)
  const [rejectComments, setRejectComments]: any = useState()

  const fetchReport = async () =>
    await makeAuthenticatedRequest(
      `${alEquipmentApi}/firs/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data, isLoading }: any = useQuery('AlIncidentReport', fetchReport)

  if (isLoading) {
    return <AlLoader />
  }

  const incidentReport = data?.incidentReport || {}
  const history = data?.history || []
  const { general, injured, personalInvolved, stage2 } = incidentReport || {}
  const { status } = incidentReport || ''

  return (
    <AshokLeylandLayout title="INCIDENT REPORT" onBack={onBack}>
      <Row style={{ margin: 15 }}>
        <Col md="6" xs="12">
          <Box heading="General Details">
            {incidentReport?.number && (
              <>
                {renderData('Number', incidentReport?.number)}
                {renderData('Status', incidentReport?.status)}
                {Object.keys(general)?.map((k: any) =>
                  renderData(
                    toNormalCase(k),
                    k === 'dateOfIncident'
                      ? getReadableTime(general[k])
                      : general[k]
                  )
                )}
                {renderData(
                  'Created On',
                  getReadableTime(incidentReport?.createdOn)
                )}
              </>
            )}
          </Box>
          <Box heading="Personal Involved">
            {personalInvolved &&
              Object.keys(personalInvolved)?.map((k: any) =>
                renderData(toNormalCase(k), personalInvolved[k])
              )}
          </Box>
        </Col>
        <Col md="6" xs="12">
          <Box heading="Injured Details">
            {injured &&
              Object.keys(injured)?.map((k: any) =>
                renderData(
                  toNormalCase(k),
                  k === 'dateOfBirth' ? getReadableTime(injured[k]) : injured[k]
                )
              )}
          </Box>
        </Col>
        {history?.length > 0 && (
          <Col>
            <Box heading="Approval History">
              <ViewHistory data={history} showTitle={false} />
            </Box>
          </Col>
        )}
        <Col md="12">
          {incidentReport?.status === 'Created' && <Stage2Form />}
          {stage2 && <ViewMitigations data={stage2} />}
        </Col>
        {status !== 'Closed' && (
          <ModalFooter>
            {status !== 'Created' && (
              <Button
                color="warning"
                onClick={() => {
                  setModal(true)
                  setUpdateStatus('Rejected')
                }}
              >
                Reject
              </Button>
            )}
            {status === 'Stage3' && (
              <Button
                color="warning"
                onClick={() => {
                  setModal(true)
                  setUpdateStatus('Stage4')
                }}
              >
                Stage 4
              </Button>
            )}
            {status === 'Stage4' && (
              <Button
                color="warning"
                onClick={() => {
                  setModal(true)
                  setUpdateStatus('Stage5')
                }}
              >
                Stage 5
              </Button>
            )}
            {status === 'Stage5' && (
              <Button
                color="warning"
                onClick={() => {
                  setModal(true)
                  setUpdateStatus('Closed')
                }}
              >
                Close
              </Button>
            )}
          </ModalFooter>
        )}
      </Row>
      <ToastContainer
        theme="dark"
        position="top-center"
        style={{ fontSize: 16 }}
      />
      <SubmitModalWithForm
        isOpen={modal}
        onClose={(val: any) => {
          setModal(false)
          if (val) {
            navigate(onBack)
          }
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()
          const body: any = {
            status: updateStatus
          }
          const res = await makeAuthenticatedRequest(
            `${alEquipmentApi}/firs/${id}`,
            'PATCH',
            body,
            accounts,
            instance
          )
          return res?.status
        }}
        size="md"
        header="Update Incident Report"
      />
      <SubmitModalWithForm
        isOpen={reject}
        onClose={(val: any) => {
          setReject(false)
          if (val) {
            navigate(onBack)
          }
        }}
        form={
          <div>
            <CustomLabel label="Reject Comment" />
            <Input
              type="textarea"
              onClick={(e: any) => setRejectComments(e?.target?.value)}
            />
          </div>
        }
        onSubmit={async (e: any) => {
          e.preventDefault()
          const body: any = {
            status: updateStatus,
            rejectComments: rejectComments
          }
          const res = await makeAuthenticatedRequest(
            `${alEquipmentApi}/firs/${id}`,
            'PATCH',
            body,
            accounts,
            instance
          )

          return res?.status
        }}
        size="md"
        header="Reject Incident Report"
      />
    </AshokLeylandLayout>
  )
}
