import ViewManuals from '../dashboardPages/ViewManuals'

export default function CreatedManuals() {
  return (
    <ViewManuals
      status="Created"
      onBack="/cmms/manual"
      title="Created Manuals"
    />
  )
}
