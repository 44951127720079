import {
  Button,
  Col,
  Input,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane
} from 'reactstrap'
import { Field, Form } from 'react-final-form'
import { useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'

import CustomLabel from '../../components/InputFields/CustomLabel'
import LabeledTextInput from '../../components/InputFields/LabeledTextInput'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { Box, Link, renderData } from '../workManagement/PmTree'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { renderArray } from '../WaitingForPIC'
import { SubHeader } from '../VerifyCM'
import { usersEndpoint } from '../../components/serverurl'
import { useQuery } from 'react-query'
import { incrementby1 } from '../../components/Forms/JSAForm'

const initalValues = {
  CEO: 'EXECUTIVE MANAGEMENT',
  CFO: 'EXECUTIVE MANAGEMENT',
  'HEAD MAINT': 'SENIOR MANAGEMENT',
  'HEAD PROJECTS': 'SENIOR MANAGEMENT',
  'HEAD IT': 'SENIOR MANAGEMENT',
  'HEAD SC': 'SENIOR MANAGEMENT',
  'MANAGER SC': 'PURCHASE',
  PURCHASER: 'PURCHASE',
  'MANAGER FINANCE': 'PURCHASE',
  'FINANCIAL CONTROLLER': 'PURCHASE',
  'RIG MANAGER': 'SENIOR MANAGEMENT',
  OIM: 'MANAGEMENT',
  'TOOL PUSHER': 'MANAGEMENT',
  'TOUR PUSHER': 'MANAGEMENT',
  DRILLER: 'SUPERINTENDENT',
  CAPTAIN: 'SUPERINTENDENT',
  'CHIEF MECHANIC': 'SUPERINTENDENT',
  'ASST DRILLER': 'SUPERVISOR',
  'SOLID CONTROL ENGR': 'SUPERVISOR',
  'CRANE OPERATOR': 'SUPERVISOR',
  QHSE: 'SUPERVISOR',
  MECHANIC: 'SUPERVISOR',
  'CHIEF ELECTRICIAN': 'SUPERVISOR',
  'CHIEF ENGINEER': 'SUPERVISOR',
  'SUBSEA SUPERVISOR': 'SUPERVISOR',
  MEDIC: 'TECHNICIAN',
  'RADIO OFFICER': 'TECHNICIAN',
  'ELECTRONIC TECHNICIAN': 'TECHNICIAN',
  'SECOND ENGINEER': 'TECHNICIAN',
  'THIRD ENGINEER': 'TECHNICIAN',
  STOREKEEPER: 'PURCHASE',
  'CUSTOMS CLEARING AGENT': 'CUSTOMS-HANDLER',
  'FREIGHT FORWARDER': 'FREIGHT FORWARDER',
  'Logistics Coordinator': 'LOGISTICS',
  'Logistics Assistant': 'LOGISTICS',
  'ON-Storekeeper': 'STORE',
  'OFF-STOREKEEPER': 'STORE'
}

const initalAccessRights = {
  canCreatePR: ['MANAGEMENT', 'PURCHASE', 'SUPERINTENDENT', 'SUPERVISOR'],
  canCreateRFQ: ['PURCHASE'],
  canApprovePOLevel1: ['PURCHASE', 'MANAGEMENT'],
  canReleasePO: ['PURCHASE'],
  canApprovePOLevel2: ['MANAGEMENT', 'SENIOR MANAGEMENT'],
  canApprovePoLevel3: [
    'MANAGEMENT',
    'SENIOR MANAGEMENT',
    'EXECUTIVE MANAGEMENT'
  ],
  canIssuePO: ['PURCHASE'],
  canCreateWR: ['SUPERINTENDENT', 'SUPERVISOR', 'TECHNICIAN'],
  canCreateWO: ['SUPERVISOR', 'SUPERINTENDENT'],
  canApproveWO: ['SUPERINTENDENT'],
  canCloseWO: ['SUPERINTENDENT', 'SUPERVISOR'],
  canIssueMR: ['PURCHASE'],
  canReceiveMR: ['PURCHASE'],
  canMakeInProgress: ['SUPERVISOR'],
  canMakeWaitingForMaterial: ['SUPERVISOR'],
  canMakeWaitingFor3rdParty: ['SUPERVISOR'],
  canMakeWaitingForShutdown: ['SUPERVISOR'],
  'Posting Invoice': ['PURCHASE', 'VENDOR'],
  Mail: ['PURCHASE'],
  'Delivery terms and conditions': ['PURCHASE'],
  'Updating PO delivery date': ['VENDOR'],
  'Downloading PO': ['VENDOR'],
  'PO acceptance': ['VENDOR'],
  'Update material readiness': ['VENDOR'],
  'Revised delivery date update': ['VENDOR'],
  'Amending PO': ['PURCHASE'],
  'Updating Payment day': ['PURCHASE'],
  'Advance Payment': ['PURCHASE'],
  'Milestone Payment': ['PURCHASE'],
  'Payment close out': ['PURCHASE'],
  'Assigning FF& consignment': ['LOGISTICS'],
  'Holding the delivery': ['LOGISTICS'],
  'Updating the consignment': ['FREIGHT FORWARDER'],
  'Create Transport Order': ['LOGISTICS', 'VENDOR'],
  'Update / Delete Transport Order': ['LOGISTICS'],
  'Freight Forwarder selection': ['LOGISTICS'],
  'Logistics Coordinator selection': ['LOGISTICS'],
  'Assign Customs clearance agent': ['CUSTOMS-HANDLER'],
  'Update customs clearance level': ['CUSTOMS-HANDLER'],
  'Update Material receipt': ['CUSTOMS-HANDLER', 'STORE'],
  'Over Short report': ['STORE'],
  'PO Updateby Base Store keeper': ['STORE'],
  'Preparing Consignment manifest to Rig': ['STORE', 'LOGISTICS'],
  'Preparing Consignment manifest to Service Vendor': ['STORE', 'LOGISTICS'],
  'Downloading Manifest': ['STORE']
}

const assignedGroups = {
  CEO: undefined,
  CFO: undefined,
  'HEAD MAINT': undefined,
  'HEAD PROJECTS': undefined,
  'HEAD IT': undefined,
  'HEAD SC': undefined,
  'MANAGER SC': undefined,
  PURCHASER: undefined,
  'MANAGER FINANCE': undefined,
  'FINANCIAL CONTROLLER': undefined,
  'RIG MANAGER': undefined,
  OIM: undefined,
  'TOOL PUSHER': undefined,
  'TOUR PUSHER': undefined,
  DRILLER: undefined,
  CAPTAIN: undefined,
  'CHIEF MECHANIC': undefined,
  'ASST DRILLER': undefined,
  'SOLID CONTROL ENGR': undefined,
  'CRANE OPERATOR': undefined,
  QHSE: undefined,
  MECHANIC: undefined,
  'CHIEF ELECTRICIAN': undefined,
  'CHIEF ENGINEER': undefined,
  'SUBSEA SUPERVISOR': undefined,
  MEDIC: undefined,
  'RADIO OFFICER': undefined,
  'ELECTRONIC TECHNICIAN': undefined,
  'SECOND ENGINEER': undefined,
  'THIRD ENGINEER': undefined,
  DERRICKMAN: undefined,
  FLOORMAN: undefined,
  'DECK FOREMAN': undefined,
  WELDER: undefined,
  ROUSTABOUT: undefined,
  PAINTER: undefined,
  'DECK RATING': undefined,
  MOTORMAN: undefined,
  'OFF-STOREKEEPER': undefined,
  'ON-Storekeeper': undefined,
  'Logistics Assistant': undefined,
  'Logistics Coordinator': undefined,
  'FREIGHT FORWARDER': undefined,
  'CUSTOMS CLEARING AGENT': undefined
}

const designations = Object.keys(assignedGroups)?.map((e: any) => e)

const accessRights = {
  canCreatePR: undefined,
  canCreateRFQ: undefined,
  canApprovePOLevel1: undefined,
  canReleasePO: undefined,
  canApprovePOLevel2: undefined,
  canApprovePoLevel3: undefined,
  canIssuePO: undefined,
  canCreateWR: undefined,
  canCreateWO: undefined,
  canApproveWO: undefined,
  canCloseWO: undefined,
  canIssueMR: undefined,
  canReceiveMR: undefined,
  canMakeInProgress: undefined,
  canMakeWaitingForMaterial: undefined,
  canMakeWaitingFor3rdParty: undefined,
  canMakeWaitingForShutdown: undefined,
  'Posting Invoice': undefined,
  Mail: undefined,
  'Delivery terms and conditions': undefined,
  'Updating PO delivery date': undefined,
  'Downloading PO': undefined,
  'PO acceptance': undefined,
  'Update material readiness': undefined,
  'Revised delivery date update': undefined,
  'Amending PO': undefined,
  'Updating Payment day': undefined,
  'Advance Payment': undefined,
  'Milestone Payment': undefined,
  'Payment close out': undefined,
  'Assigning FF& consignment': undefined,
  'Holding the delivery': undefined,
  'Updating the consignment': undefined,
  'Create Transport Order': undefined,
  'Update / Delete Transport Order': undefined,
  'Freight Forwarder selection': undefined,
  'Logistics Coordinator selection': undefined,
  'Assign Customs clearance agent': undefined,
  'Update customs clearance level': undefined,
  'Update Material receipt': undefined,
  'Over Short report': undefined,
  'PO Updateby Base Store keeper': undefined,
  'Preparing Consignment manifest to Rig': undefined,
  'Preparing Consignment manifest to Service Vendor': undefined,
  'Downloading Manifest': undefined
}

const accessRightsDefs: any = {
  canCreatePR: 'Create PR',
  canCreateRFQ: 'Create RFQ',
  canApprovePOLevel1: 'Approve PO Level 1',
  canReleasePO: 'Release PO',
  canApprovePOLevel2: 'Approve PO Level 2',
  canApprovePoLevel3: 'Approve PO Level 3',
  canIssuePO: 'Issue PO',
  canCreateWR: 'Create WR',
  canCreateWO: 'Create WO',
  canApproveWO: 'Approve WO',
  canCloseWO: 'Close WO',
  canIssueMR: 'Issue MR',
  canReceiveMR: 'Receive MR',
  canMakeInProgress: 'InProgress WO',
  canMakeWaitingForMaterial: 'Waiting For Material WO',
  canMakeWaitingFor3rdParty: 'Waiting For 3rd Party WO',
  canMakeWaitingForShutdown: 'Waiting For SHutdown WO',
  canPostpone: 'Postpone WO',
  'Posting Invoice': 'Posting Invoice',
  Mail: 'Mail',
  'Delivery terms and conditions': 'Delivery terms and conditions',
  'Updating PO delivery date': 'Updating PO delivery date',
  Delivery: 'Delivery',
  'Downloading PO': 'Downloading PO',
  'PO acceptance': 'PO acceptance',
  'Update material readiness': 'Update material readiness',
  'Revised delivery date update': 'Revised delivery date update',
  'Amending PO': 'Amending PO',
  'Updating Payment day': 'Updating Payment day',
  'Advance Payment': 'Advance Payment',
  'Milestone Payment': 'Milestone Payment',
  'Payment close out': 'Payment close out',
  'Assigning FF& consignment': 'Assigning FF& consignment',
  'Holding the delivery': 'Holding the delivery',
  'Updating the consignment': 'Updating the consignment',
  'Create Transport Order': 'Create Transport Order',
  'Update / Delete Transport Order': 'Update / Delete Transport Order',
  'Freight Forwarder selection': 'Freight Forwarder selection',
  'Logistics Coordinator selection': 'Logistics Coordinator selection',
  'Assign Customs clearance agent': 'Assign Customs clearance agent',
  'Update customs clearance level': 'Update customs clearance level',
  'Update Material receipt': 'Update Material receipt',
  'Over Short report': 'Over Short report',
  'PO Updateby Base Store keeper': 'PO Updateby Base Store keeper',
  'Preparing Consignment manifest to Rig':
    'Preparing Consignment manifest to Rig',
  'Preparing Consignment manifest to Service Vendor':
    'Preparing Consignment manifest to Service Vendor',
  'Downloading Manifest': 'Downloading Manifest'
}

const actions = Object.keys(accessRights)?.map((e: any) => e)

const group = {
  EXEC_MGMT: 'EXECUTIVE MANAGEMENT',
  SN_MGMT: 'SENIOR MANAGEMENT',
  MGMT: 'MANAGEMENT',
  PURH: 'PURCHASE',
  SIT: 'SUPERINTENDENT',
  SV: 'SUPERVISOR',
  TECH: 'TECHNICIAN',
  STORE: 'STORE',
  LOGISTICS: 'LOGISTICS',
  CUSTOMS: 'CUSTOMS-HANDLER',
  FREIGHTFORWARDER: 'FREIGHT FORWARDER',
  VENDOR: 'VENDOR'
}

const groups = [
  group.EXEC_MGMT,
  group.SN_MGMT,
  group.MGMT,
  group.PURH,
  group.SIT,
  group.SV,
  group.TECH,
  group.STORE,
  group.CUSTOMS,
  group.FREIGHTFORWARDER,
  group.LOGISTICS,
  group.VENDOR
]

const tabs = {
  PG: 'POSITION-GROUPS',
  AG: 'ACCESS-GROUPS',
  PREVIEW: 'PREVIEW',
  CREW: 'CREW-DETAILS',
  ADD_CREW: 'ADD-CREW'
}

const units = {
  UNIT1: 'UNIT1',
  UNIT2: 'UNIT2',
  UNIT3: 'UNIT3',
  UNIT4: 'UNIT4'
}

const AbanUsersTable = ({
  data,
  isLoading,
  isRefetching,
  page,
  refetch // setPage,
  // url
}: {
  data: any
  isLoading: boolean
  isRefetching: boolean
  refetch: any
  page: any
  // setPage: any
  // url: string
}) => {
  const { accounts, instance } = useMsal()
  const [searchText, setSearchText]: any = useState([])
  const [filtered, setFiltered]: any = useState(data)
  const [update, setUpdate]: any = useState(false)
  const [selectedUser, setSelectedUser]: any = useState({})

  useEffect(() => {
    setFiltered(data)
  }, [data, refetch])

  useEffect(() => {
    if (searchText && data?.length > 0) {
      setFiltered([
        ...data?.filter((s: any) => s?.userId?.includes(searchText))
      ])
    }
  }, [searchText])

  return (
    <>
      <Row>
        <Col xs="3">
          <CustomLabel label="Search User Id" />
          <Input
            type="text"
            name="searchText"
            onChange={(e: any) => setSearchText(e?.target?.value)}
          />
        </Col>
        <Col xs="3">
          <CustomLabel label="Clear" />
          <Button color="warning" onClick={() => setFiltered(data)}>
            Clear
          </Button>
        </Col>
      </Row>
      <Table>
        <thead
          style={{ position: 'sticky', top: 0, zIndex: 1, background: 'white' }}
        >
          <tr>
            <th>S.No</th>
            <th>Id</th>
            <th>Name</th>
            <th>Email</th>
            <th>Designation</th>
            <th>Group</th>
            <th>Unit</th>
          </tr>
        </thead>
        <tbody>
          <>
            {isLoading || isRefetching ? (
              <tr>
                <td>
                  <Row
                    style={{
                      height: 500,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Spinner />
                  </Row>
                </td>
              </tr>
            ) : (
              filtered?.length > 0 &&
              filtered?.map((w: any, index: number) => (
                <tr key={`${w?._id}-${index}`}>
                  <td>{incrementby1(page * 20 + index)}</td>
                  <td>
                    <Link
                      onClick={() => {
                        setUpdate(true)
                        setSelectedUser(w)
                      }}
                    >
                      {w?.userId}
                    </Link>
                  </td>
                  <td>{w?.userName}</td>
                  <td>{w?.emailId}</td>
                  <td>{w?.designation}</td>
                  <td>{w?.group}</td>
                  <td>{renderArray(w?.unit, false)}</td>
                </tr>
              ))
            )}
          </>
        </tbody>
      </Table>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Users Found,
            <span
              style={{
                color: 'blue',
                textDecoration: 'underline',
                marginLeft: 2,
                cursor: 'pointer'
              }}
              onClick={() =>
                // navigate('/workManagement/correctiveMaintenance/create')
                {}
              }
            >
              Add one?
            </span>
          </p>
        </Row>
      )}
      <SubmitModalWithForm
        isOpen={update}
        onClose={(val: any) => {
          setUpdate(false)
        }}
        form={
          <>
            <Box heading={`Crew Member Details`}>
              {renderData('Name', selectedUser?.userName)}
              {renderData('Id', selectedUser?.userId)}
              {renderData('Email', selectedUser?.emailId)}
              {renderData('Designation', selectedUser?.desgination)}
              {renderData('Group', selectedUser?.group)}
            </Box>
            <Row>
              <Col xs="10">
                <CustomLabel label="Group" />
                <Input
                  type="select"
                  onChange={(e: any) =>
                    setSelectedUser({
                      ...selectedUser,
                      group: e?.target?.value
                    })
                  }
                >
                  <option value="" disabled>
                    Select
                  </option>

                  {groups?.map((d: any) => (
                    <option selected={d === selectedUser?.group} value={d}>
                      {d}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col xs="10">
                <CustomLabel label="Designation" />
                <Input
                  type="select"
                  onChange={(e: any) =>
                    setSelectedUser({
                      ...selectedUser,
                      designation: e?.target?.value
                    })
                  }
                >
                  <option value="" disabled>
                    Select
                  </option>

                  {designations?.map((d: any) => (
                    <option
                      selected={d === selectedUser?.designation}
                      value={d}
                    >
                      {d}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
            <CustomLabel label="Select Units" />
            <Row>
              <Col xs="1">
                <Input
                  type="checkbox"
                  id="unit"
                  value={units.UNIT1}
                  checked={selectedUser?.unit?.includes(units.UNIT1)}
                  onChange={(e: any) =>
                    setSelectedUser({
                      ...selectedUser,
                      unit: e?.target?.checked
                        ? [...selectedUser?.unit, e?.target?.value]
                        : selectedUser?.unit?.filter(
                            (i: any) => i !== e?.target?.value
                          )
                    })
                  }
                />
              </Col>
              <Col>
                <p>{units.UNIT1}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="1">
                <Input
                  type="checkbox"
                  id="unit"
                  value={units.UNIT2}
                  checked={selectedUser?.unit?.includes(units.UNIT2)}
                  onChange={(e: any) =>
                    setSelectedUser({
                      ...selectedUser,
                      unit: e?.target?.checked
                        ? [...selectedUser?.unit, e?.target?.value]
                        : selectedUser?.unit?.filter(
                            (i: any) => i !== e?.target?.value
                          )
                    })
                  }
                />
              </Col>
              <Col>
                <p>{units.UNIT2}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="1">
                <Input
                  type="checkbox"
                  id="unit"
                  value={units.UNIT3}
                  checked={selectedUser?.unit?.includes(units.UNIT3)}
                  onChange={(e: any) =>
                    setSelectedUser({
                      ...selectedUser,
                      unit: e?.target?.checked
                        ? [...selectedUser?.unit, e?.target?.value]
                        : selectedUser?.unit?.filter(
                            (i: any) => i !== e?.target?.value
                          )
                    })
                  }
                />
              </Col>
              <Col>
                <p>{units.UNIT3}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="1">
                <Input
                  type="checkbox"
                  id="unit"
                  value={units.UNIT4}
                  checked={selectedUser?.unit?.includes(units.UNIT4)}
                  onChange={(e: any) =>
                    setSelectedUser({
                      ...selectedUser,
                      unit: e?.target?.checked
                        ? [...selectedUser?.unit, e?.target?.value]
                        : selectedUser?.unit?.filter(
                            (i: any) => i !== e?.target?.value
                          )
                    })
                  }
                />
              </Col>
              <Col>
                <p>{units.UNIT4}</p>
              </Col>
            </Row>
            <Spacer height={20} />
            <Row>
              <Col xs="1">
                <Input type="checkbox" id="pass" value={'reset'} />
              </Col>
              <Col>
                <p>Reset Password</p>
              </Col>
            </Row>
          </>
        }
        onSubmit={async (e: any) => {
          e?.preventDefault()
          const res = await makeAuthenticatedRequest(
            `${usersEndpoint}/aban`,
            'PATCH',
            selectedUser,
            accounts,
            instance
          )

          if (res.status === 'success') {
            await refetch()
          }

          return res.status
        }}
        size="xs"
        header="Create Crew"
      />
    </>
  )
}

export default function AssignGroups() {
  const { accounts, instance } = useMsal()
  const [newGroup, setNewGroup]: any = useState(initalValues)
  const [newAccess, setNewAccess]: any = useState(initalAccessRights)
  const [active, setActive]: any = useState(tabs.CREW)
  const [createUser, setCreateUser]: any = useState(false)

  const handleOnChange = (e: any, d: any) =>
    setNewGroup({ ...newGroup, [`${d}`]: e?.target?.value })

  const handleOnAccessChange = (e: any, d: any) => {
    setNewAccess({
      ...newAccess,
      [`${d}`]: e?.target?.checked
        ? [
            ...(newAccess?.[d]?.length > 0 ? newAccess?.[d] : []),
            e?.target?.value
          ]
        : newAccess?.[d]?.filter((i: any) => i !== e?.target?.value)
    })
  }

  const fetchCrewDetails = async () => {
    const response = await makeAuthenticatedRequest(
      `${usersEndpoint}/aban`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, isRefetching, refetch }: any = useQuery(
    'crewDataALL',
    fetchCrewDetails
  )

  const crewData = data?.crew

  return (
    <ProtectedLayout onBack="/cmms/admin" title={'ADMIN'}>
      <Row style={{ margin: 15 }}>
        <Nav tabs style={{ backgroundColor: '#fff', paddingTop: 0 }}>
          <NavItem>
            <NavLink
              className={active === tabs.ADD_CREW ? 'active' : ''}
              onClick={() => setActive(tabs.ADD_CREW)}
            >
              {tabs?.ADD_CREW}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === tabs.CREW ? 'active' : ''}
              onClick={() => setActive(tabs.CREW)}
            >
              {tabs?.CREW}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === tabs.PG ? 'active' : ''}
              onClick={() => setActive(tabs.PG)}
            >
              {tabs?.PG}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === tabs.AG ? 'active' : ''}
              onClick={() => setActive(tabs.AG)}
            >
              {tabs?.AG}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === tabs.PREVIEW ? 'active' : ''}
              onClick={() => setActive(tabs.PREVIEW)}
            >
              {tabs?.PREVIEW}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={active}>
          <TabPane tabId={tabs.PG}>
            <SubHeader header="POSITION - GROUP TABLE" permitColor="#FFD580" />
            <Row>
              <Col xs="12">
                <Table>
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      background: 'white'
                    }}
                  >
                    <tr>
                      <th>Designation</th>
                      {groups?.map((g: any) => (
                        <th style={{ textAlign: 'center' }} key={g}>
                          {g}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {designations?.map((d: any) => (
                      <tr key={d}>
                        <th>{d}</th>

                        {groups?.map((g: any, index: number) => (
                          <td
                            key={`${index}-${g}`}
                            style={{ textAlign: 'center' }}
                          >
                            <Input
                              type="radio"
                              id={g}
                              name={d}
                              value={g}
                              checked={newGroup[d] === g}
                              onChange={(e: any) => handleOnChange(e, d)}
                            />
                          </td>
                        ))}
                        {/* <td style={{ textAlign: 'center' }}>
                          <Input
                            type="radio"
                            id={group.EXEC_MGMT}
                            name={d}
                            value={group.EXEC_MGMT}
                            checked={newGroup[d] === group.EXEC_MGMT}
                            onChange={(e: any) => handleOnChange(e, d)}
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Input
                            id={group.SN_MGMT}
                            type="radio"
                            name={d}
                            value={group.SN_MGMT}
                            checked={newGroup[d] === group.SN_MGMT}
                            onChange={(e: any) => handleOnChange(e, d)}
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Input
                            id={group.MGMT}
                            type="radio"
                            name={d}
                            value={group.MGMT}
                            checked={newGroup[d] === group.MGMT}
                            onChange={(e: any) => handleOnChange(e, d)}
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Input
                            id={group.PURH}
                            type="radio"
                            name={d}
                            value={group.PURH}
                            checked={newGroup[d] === group.PURH}
                            onChange={(e: any) => handleOnChange(e, d)}
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Input
                            id={group.SIT}
                            type="radio"
                            name={d}
                            value={group.SIT}
                            checked={newGroup[d] === group.SIT}
                            onChange={(e: any) => handleOnChange(e, d)}
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Input
                            id={group.SV}
                            type="radio"
                            name={d}
                            value={group.SV}
                            checked={newGroup[d] === group.SV}
                            onChange={(e: any) => handleOnChange(e, d)}
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Input
                            id={group.TECH}
                            type="radio"
                            name={d}
                            value={group.TECH}
                            checked={newGroup[d] === group.TECH}
                            onChange={(e: any) => handleOnChange(e, d)}
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Input
                            id={group.STORE}
                            type="radio"
                            name={d}
                            value={group.STORE}
                            checked={newGroup[d] === group.STORE}
                            onChange={(e: any) => handleOnChange(e, d)}
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Input
                            id={group.LOGISTICS}
                            type="radio"
                            name={d}
                            value={group.LOGISTICS}
                            checked={newGroup[d] === group.LOGISTICS}
                            onChange={(e: any) => handleOnChange(e, d)}
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Input
                            id={group.FREIGHTFORWARDER}
                            type="radio"
                            name={d}
                            value={group.FREIGHTFORWARDER}
                            checked={newGroup[d] === group.FREIGHTFORWARDER}
                            onChange={(e: any) => handleOnChange(e, d)}
                          />
                          <td style={{ textAlign: 'center' }}>
                            <Input
                              id={group.CUSTOMS}
                              type="radio"
                              name={d}
                              value={group.CUSTOMS}
                              checked={newGroup[d] === group.CUSTOMS}
                              onChange={(e: any) => handleOnChange(e, d)}
                            />
                          </td>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <ModalFooter>
              <Button color="warning">Submit</Button>
            </ModalFooter>
          </TabPane>
          <TabPane tabId={tabs.AG}>
            <SubHeader header="ACCESS - GROUP TABLE" permitColor="#FFD580" />
            <Table>
              <thead
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  background: 'white'
                }}
              >
                <tr>
                  <th>Actions</th>
                  {groups?.map((g: any) => (
                    <th style={{ textAlign: 'center' }} key={g}>
                      {g}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {actions?.map((d: any) => (
                  <tr key={d}>
                    <th>{accessRightsDefs[d]}</th>
                    {groups?.map((g: any) => (
                      <td style={{ textAlign: 'center' }}>
                        <Input
                          id={g}
                          type="checkbox"
                          name={d}
                          value={g}
                          checked={newAccess[d]?.includes(g)}
                          onChange={(e: any) => handleOnAccessChange(e, d)}
                        />
                      </td>
                    ))}
                    {/* <td style={{ textAlign: 'center' }}>
                      <Input
                        id={group.LOGISTICS}
                        type="checkbox"
                        name={d}
                        value={group.LOGISTICS}
                        checked={newAccess[d]?.includes(group.LOGISTICS)}
                        onChange={(e: any) => handleOnAccessChange(e, d)}
                      />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Input
                        id={group.CUSTOMS}
                        type="checkbox"
                        name={d}
                        value={group.CUSTOMS}
                        checked={newAccess[d]?.includes(group.CUSTOMS)}
                        onChange={(e: any) => handleOnAccessChange(e, d)}
                      />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Input
                        id={group.STORE}
                        type="checkbox"
                        name={d}
                        value={group.STORE}
                        checked={newAccess[d]?.includes(group.STORE)}
                        onChange={(e: any) => handleOnAccessChange(e, d)}
                      />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Input
                        id={group.FREIGHTFORWARDER}
                        type="checkbox"
                        name={d}
                        value={group.FREIGHTFORWARDER}
                        checked={newAccess[d]?.includes(group.FREIGHTFORWARDER)}
                        onChange={(e: any) => handleOnAccessChange(e, d)}
                      />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Input
                        id={group.TECH}
                        type="checkbox"
                        name={d}
                        value={group.TECH}
                        checked={newAccess[d]?.includes(group.TECH)}
                        onChange={(e: any) => handleOnAccessChange(e, d)}
                      />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Input
                        id={group.SV}
                        type="checkbox"
                        name={d}
                        value={group.SV}
                        checked={newAccess[d]?.includes(group.SV)}
                        onChange={(e: any) => handleOnAccessChange(e, d)}
                      />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Input
                        id={group.SIT}
                        type="checkbox"
                        name={d}
                        value={group.SIT}
                        checked={newAccess[d]?.includes(group.SIT)}
                        onChange={(e: any) => handleOnAccessChange(e, d)}
                      />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Input
                        id={group.PURH}
                        type="checkbox"
                        name={d}
                        value={group.PURH}
                        checked={newAccess[d]?.includes(group.PURH)}
                        onChange={(e: any) => handleOnAccessChange(e, d)}
                      />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Input
                        id={group.MGMT}
                        type="checkbox"
                        name={d}
                        value={group.MGMT}
                        checked={newAccess[d]?.includes(group.MGMT)}
                        onChange={(e: any) => handleOnAccessChange(e, d)}
                      />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Input
                        id={group.SN_MGMT}
                        type="checkbox"
                        name={d}
                        value={group.SN_MGMT}
                        checked={newAccess[d]?.includes(group.SN_MGMT)}
                        onChange={(e: any) => handleOnAccessChange(e, d)}
                      />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Input
                        type="checkbox"
                        id={group.EXEC_MGMT}
                        name={d}
                        value={group.EXEC_MGMT}
                        checked={newAccess[d]?.includes(group.EXEC_MGMT)}
                        onChange={(e: any) => handleOnAccessChange(e, d)}
                      />
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
            <ModalFooter>
              <Button color="warning">Submit</Button>
            </ModalFooter>
          </TabPane>
          <TabPane tabId={tabs.PREVIEW}>
            <SubHeader header="VIEW" permitColor="#FFD580" />
            <Col xs="12">
              <Box heading="Latest Groups">
                {groups?.map((v: any, index: any) => (
                  <Row key={`${index}-${v}`}>
                    <Col xs="4">
                      <p
                        style={{
                          minWidth: '40%',
                          marginRight: 5,
                          fontWeight: 600
                        }}
                      >
                        {v}
                      </p>
                    </Col>
                    <Col xs="8">
                      <p>
                        {renderArray(
                          Object.keys(newGroup)?.filter(
                            (key) => newGroup?.[key] === v
                          ),
                          false
                        )}
                      </p>
                    </Col>
                  </Row>
                ))}
              </Box>

              <Box heading="Latest Access Rights">
                {actions?.map((v: any, index: any) => (
                  <Row key={`${index}-${v}`}>
                    <Col xs="4">
                      <p
                        style={{
                          minWidth: '40%',
                          marginRight: 5,
                          fontWeight: 600
                        }}
                      >
                        {accessRightsDefs[v]}
                      </p>
                    </Col>
                    <Col xs="8">
                      <p>{renderArray(newAccess[v], false)}</p>
                    </Col>
                  </Row>
                ))}
              </Box>
            </Col>
          </TabPane>
          <TabPane tabId={tabs.CREW}>
            <SubHeader header="CREW-DETAILS" permitColor="#FFD580" />
            <AbanUsersTable
              isLoading={isLoading}
              isRefetching={isRefetching}
              data={crewData}
              page={0}
              refetch={refetch}
              // setPage={() => {}}
              // url=""
            />
          </TabPane>
          <TabPane tabId={tabs.ADD_CREW}>
            <SubHeader header="ADD CREW" permitColor="#FFD580" />
            <Form
              onSubmit={(values: any) => {}}
              // validate={validate}
              initialValues={{}}
              render={({ form, values, hasValidationErrors }) => (
                <form
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <LabeledTextInput
                    label="User Name"
                    name="userName"
                    occupy={3}
                  />
                  <LabeledTextInput label="Email" name="emailId" occupy={3} />
                  <LabeledTextInput
                    label="Employee Id"
                    name="userId"
                    occupy={3}
                  />

                  <Col xs="3">
                    <CustomLabel label="Designation *" />
                    <Field
                      name={'designation'}
                      component="select"
                      defaultValue=""
                      style={{
                        width: '-webkit-fill-available',
                        height: 38,
                        borderRadius: 5,
                        backgroundColor: '#DCDCDC'
                      }}
                    >
                      <option value="" disabled>
                        Select
                      </option>

                      {designations?.map((d: any) => (
                        <option value={d}>{d}</option>
                      ))}
                    </Field>
                  </Col>
                  <Col xs="3">
                    <CustomLabel label="Group *" />
                    <Field
                      name={'group'}
                      component="select"
                      defaultValue=""
                      style={{
                        width: '-webkit-fill-available',
                        height: 38,
                        borderRadius: 5,
                        backgroundColor: '#DCDCDC'
                      }}
                    >
                      <option value="" disabled>
                        Select
                      </option>

                      {groups?.map((d: any) => <option value={d}>{d}</option>)}
                    </Field>
                  </Col>
                  <Col xs="3">
                    <CustomLabel label="Warehouse Code *" />
                    <Field
                      name={'warehouseCode'}
                      component="select"
                      defaultValue=""
                      style={{
                        width: '-webkit-fill-available',
                        height: 38,
                        borderRadius: 5,
                        backgroundColor: '#DCDCDC'
                      }}
                    >
                      <option value="" disabled>
                        Select
                      </option>

                      <option value={'CH001'}>CH001</option>
                      <option value={'PON001'}>PON001</option>
                      <option value={'MUM001'}>MUM001</option>
                      <option value={'KAK001'}>KAK001</option>
                    </Field>
                  </Col>
                  <Col xs="3">
                    <CustomLabel label="Assign Warehouse *" />
                    <Field
                      name={'assignWarehouse'}
                      component="select"
                      defaultValue=""
                      style={{
                        width: '-webkit-fill-available',
                        height: 38,
                        borderRadius: 5,
                        backgroundColor: '#DCDCDC'
                      }}
                    >
                      <option value="" disabled>
                        Select
                      </option>

                      <option value={'Chennai'}>Chennai</option>
                      <option value={'Pondy'}>Pondy</option>
                      <option value={'Mumbai'}>Mumbai</option>
                      <option value={'Kakinada'}>Kakinada</option>
                    </Field>
                  </Col>
                  <Col xs="3">
                    <CustomLabel label="Shore Type *" />
                    <Field
                      name={'shoreType'}
                      component="select"
                      defaultValue=""
                      style={{
                        width: '-webkit-fill-available',
                        height: 38,
                        borderRadius: 5,
                        backgroundColor: '#DCDCDC'
                      }}
                    >
                      <option value="" disabled>
                        Select
                      </option>

                      <option value={'ONSHORE'}>ONSHORE</option>
                      <option value={'OFFFSHORE'}>OFFSHORE</option>
                    </Field>
                  </Col>
                  <Col xs="3">
                    <CustomLabel label="Unit *" />
                    <Field
                      name={'unit'}
                      component="select"
                      defaultValue=""
                      style={{
                        width: '-webkit-fill-available',
                        height: 38,
                        borderRadius: 5,
                        backgroundColor: '#DCDCDC'
                      }}
                    >
                      <option value="" disabled>
                        Select
                      </option>

                      {Object.keys(units)?.map((d: any) => (
                        <option value={d}>{d}</option>
                      ))}
                    </Field>
                  </Col>
                  <Spacer height={20} />
                  <Button color="warning" onClick={() => setCreateUser(true)}>
                    Add Crew Member
                  </Button>
                  <SubmitModalWithForm
                    isOpen={createUser}
                    onClose={(val: any) => {
                      setCreateUser(false)
                    }}
                    onSubmit={async (e: any) => {
                      e?.preventDefault()
                      const res = await makeAuthenticatedRequest(
                        `${usersEndpoint}/aban`,
                        'POST',
                        values,
                        accounts,
                        instance
                      )

                      if (res.status === 'success') {
                        await refetch()
                        form.reset()
                      }

                      return res.status
                    }}
                    size="xs"
                    header="Create Crew"
                  />
                </form>
              )}
            />
          </TabPane>
        </TabContent>
      </Row>
    </ProtectedLayout>
  )
}
