import { useMsal } from '@azure/msal-react'
import { FaEdit } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Row, Table } from 'reactstrap'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { jobcodes } from '../../components/constants/jobcodes'
import { incrementby1 } from '../../components/Forms/JSAForm'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import { pmAPI } from '../../components/serverurl'
import { pmTableHeaders } from '../ViewCM'
import { Link } from './PmTree'

type JobType = {
  number: string
  title: string
  component: string
  componentDescription: string
  function: string
  functionDescription: string
  jobDescription: {
    title: string
    subheader: string
    steps: any
  }
}

export default function PMJobs() {
  const navigate = useNavigate()
  const { accounts, instance } = useMsal()

  const fetchPmList = async () => {
    const response = await makeAuthenticatedRequest(
      `${pmAPI}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  // const create = () =>
  //   makeAuthenticatedRequest(
  //     `${pmAPI}?workType=${type}&priority=${priority}`,
  //     'POST',
  //     pmList,
  //     accounts,
  //     instance
  //   )

  const { data }: any = useQuery('pmListJOBS', fetchPmList)

  const pm = data?.pmList

  return (
    <ProtectedLayout onBack="/cmms/planning/pm" title="Job Plan list">
      <Row style={{ margin: 10 }}>
        <Table striped>
          <thead>
            <tr>
              <th>S.No</th>
              <th>JP Code</th>
              <th>Job Title</th>
              <th>Function</th>
              <th>Org Code</th>
              <th>Planned Hours</th>
              <th>Planned Resources</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {pm?.map((j: any, index: number) => (
              <tr key={j?.jobPlanCode}>
                <td>{incrementby1(index)}</td>
                <td
                  onClick={() => navigate(`/cmms/planning/pm/jobs/${j?._id}`)}
                >
                  <Link
                    onClick={() => navigate(`/cmms/planning/pm/jobs/${j?._id}`)}
                  >
                    {j?.jobPlanCode}
                  </Link>
                </td>
                <td>{j?.workDescription}</td>
                <td>{j?.functionNumber}</td>
                <td>{jobcodes?.[j?.jobPlanCode]?.[0]?.mainOrg}</td>
                <td>{jobcodes?.[j?.jobPlanCode]?.[0]?.plannedHours}</td>
                <td>{jobcodes?.[j?.jobPlanCode]?.[0]?.plannedResources}</td>
                <td>{jobcodes?.[j?.jobPlanCode]?.[0]?.remarks}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </ProtectedLayout>
  )
}
