import { Form } from 'react-final-form'
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap'
import { getOptions } from '../../pages/firs'
import LabeledTextInput from '../InputFields/LabeledTextInput'
import MultiSelect from '../InputFields/MultiSelect'
import ModalTitle from '../ModalTitle'
import Spacer from '../Spacer'

export default function ConfinedSpaceEntry({
  toggle,
  setAttachedForms,
  attachedForms,
  isOpen
}: any) {
  const validate = (values: any) => {
    const errors: any = {}
    if (!values.location) {
      errors.location = 'Required'
    }

    return errors
  }
  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalBody>
          <ModalTitle title="Confined Space Entry Certificate" />
          <Form
            validate={validate}
            initialValues={{
              ...attachedForms,
              department: getOptions(attachedForms?.department)
            }}
            onSubmit={(values: any) => {
              setAttachedForms({
                ...values,
                department: values?.department?.map((d: any) => d?.value)
              })
              toggle()
            }}
            render={({ form, hasValidationErrors }) => (
              <form>
                <Row>
                  <LabeledTextInput
                    name="location"
                    label="Location of Work"
                    occupy={12}
                  />
                  <LabeledTextInput
                    name="vessel"
                    label="Vessel / Pit / Tank worked on"
                    occupy={12}
                  />
                  <LabeledTextInput
                    name="contractor"
                    label="Contractor"
                    occupy={12}
                  />
                  <MultiSelect
                    occupy={12}
                    name="department"
                    label="Section / Department"
                    options={getOptions([
                      'Mechanical',
                      'Electrical',
                      'Instrumental',
                      'Production',
                      'HSE',
                      'Admin Ware House'
                    ])}
                  />
                </Row>
                <Spacer height={30} />
                <Row>
                  <Col xs="12">
                    <Button
                      style={{ width: 'inherit' }}
                      onClick={form.submit}
                      color={hasValidationErrors ? 'secondary' : 'warning'}
                      disabled={hasValidationErrors}
                    >
                      Save & Next
                    </Button>
                    <Spacer height={10} />
                    <Button
                      style={{ width: 'inherit' }}
                      color="warning"
                      onClick={toggle}
                    >
                      Cancel
                    </Button>
                    <Spacer height={10} />
                  </Col>
                </Row>
              </form>
            )}
          />
        </ModalBody>
      </Modal>
    </>
  )
}
