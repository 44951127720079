import { Route, Routes } from 'react-router-dom'

import PermitsByStatus from '../pages/deIsolationAndClose/ViewPermits'
import ApproveWOI from '../pages/woi/ApproveWOI'
import MakeLiveWOI from '../pages/woi/MakeLiveWOI'
import IssueWOI from '../pages/woi/IssueWOI'
import VerifyPermit from '../pages/eptw/VerifyPermit'
import FetchPermitsByLocation from '../pages/FetchPermitsByLocation'

export default function WOIRoutes() {
  return (
    <Routes>
      <Route
        path="/verify"
        element={
          <PermitsByStatus
            currentPermits="woiCreated"
            flow="woi"
            noIsolations
          />
        }
      />
      <Route
        path="/verify/:id"
        element={<VerifyPermit onBack="/eptw/woi/verify" />}
      />
      <Route
        path="/issue"
        element={<PermitsByStatus currentPermits="Verified woi" flow="woi" />}
      />
      {/* <Route
        path="/issue"
        element={<PermitsByStatus currentPermits="Verified woi" flow="woi" />}
      /> */}
      <Route path="/issue/:id" element={<IssueWOI />} />
      <Route
        path="/approve"
        element={
          <FetchPermitsByLocation
            status="Issued WOI"
            flow="woi"
            checkLocation
          />
        }
      />
      <Route path="/approve/:id" element={<ApproveWOI />} />
      <Route
        path="/makeLive"
        element={
          <PermitsByStatus
            currentPermits="Approved WOI"
            flow="woi"
            checkDepartment
          />
        }
      />
      <Route path="/makeLive/:id" element={<MakeLiveWOI />} />
    </Routes>
  )
}
