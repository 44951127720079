import { Form } from 'react-final-form'
import { Button, Modal, ModalBody, Row } from 'reactstrap'
import LabeledTextInput from '../InputFields/LabeledTextInput'
import MultiSelect from '../InputFields/MultiSelect'
import ModalTitle from '../ModalTitle'

import equipmentList from '../../components/constants/equipmentList.json'
import locationList from '../../components/constants/locationList.json'

import { departmentList, getOptions } from '../../pages/firs'
import LabeledDateTimeInput from '../InputFields/DateTime'
import Spacer from '../Spacer'
import { useContext, useState } from 'react'
import { getPMList } from '../serverurl'
import { UserContext } from '../../App'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { useMsal } from '@azure/msal-react'

const initial = {
  title: 'srg',
  tagNumber: '43PB002 A',
  instrumentType: 'Electric Cable',
  respDepartment: 'Mechanical',
  frequency: 'sfg',
  plantLocation: 'gfs',
  dueDate: '2023-01-01',
  priority: 'P2',
  status: 'Active',
  location: 'Machinery Space',
  pidNumber: 'sd',
  lineNumber: 'sfdg',
  equipmentName: 'efhh',
  minRange: 'sfdht',
  maxRange: 'eh',
  units: 'h',
  processHookUpNumber: 'he',
  functionalLoop: 'grw',
  modelNo: 'ge',
  manufacturerName: 'gw',
  prNumber: 'gr',
  remarks: 'rw'
}

export default function CreateNewPM({ isOpen, toggle }: any) {
  const { accounts, instance } = useMsal()
  const [submitting, setSubmitting]: any = useState(false)
  const { userData }: any = useContext(UserContext)

  const createNewPM = (values: any) => {
    setSubmitting(true)
    makeAuthenticatedRequest(
      getPMList,
      'POST',
      { ...values, createdBy: userData?.id },
      accounts,
      instance
    )
      .then()
      .catch((error) => console.info(error))
      .finally(() => setSubmitting(false))
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} fullscreen>
        <ModalBody>
          <ModalTitle title="Create New PM" />
          <Form
            initialValues={initial}
            onSubmit={(values) =>
              createNewPM({
                ...values,
                tagNumber: values?.tagNumber.value,
                instrumentType: values?.instrumentType?.value,
                respDepartment: values?.respDepartment?.value,
                priority: values?.priority?.value,
                status: values?.status?.value,
                location: values?.location?.value
              })
            }
            render={({ form, values }) => (
              <form>
                <Row>
                  <LabeledTextInput name="title" label="Title" occupy={3} />
                  <MultiSelect
                    isMulti={false}
                    options={equipmentList}
                    name="tagNumber"
                    occupy={3}
                    label="Tag Number"
                  />
                  <LabeledTextInput
                    value={values?.tagNumber?.description}
                    name="tagDescription"
                    label="Tag Description"
                    occupy={3}
                    isDisabled
                  />
                  <MultiSelect
                    isMulti={false}
                    options={getOptions(['Electric Cable'])}
                    name="instrumentType"
                    occupy={3}
                    label="Instrument Type"
                  />
                  <MultiSelect
                    isMulti={false}
                    options={departmentList}
                    name="respDepartment"
                    occupy={3}
                    label="Responsible Department"
                  />
                  <LabeledTextInput
                    name="frequency"
                    label="Frequency (Months)"
                    occupy={3}
                  />
                  <LabeledTextInput
                    name="plantLocation"
                    label="Plant Location"
                    occupy={3}
                  />
                  <LabeledDateTimeInput
                    name="dueDate"
                    label="Due Date"
                    occupy={3}
                  />
                  <MultiSelect
                    isMulti={false}
                    options={getOptions(['P1', 'P2', 'P3'])}
                    name="priority"
                    occupy={3}
                    label="Priority"
                  />
                  <MultiSelect
                    isMulti={false}
                    options={getOptions(['Active', 'Obselete'])}
                    name="status"
                    occupy={3}
                    label="Status"
                  />
                  <MultiSelect
                    isMulti={false}
                    options={locationList}
                    name="location"
                    occupy={3}
                    label="Location"
                  />
                  <LabeledTextInput
                    name="pidNumber"
                    label="PID Number"
                    occupy={3}
                  />
                  <LabeledTextInput
                    name="lineNumber"
                    label="Line Number"
                    occupy={3}
                  />
                  <LabeledTextInput
                    name="equipmentName"
                    label="Equipment Number"
                    occupy={3}
                  />
                  <LabeledTextInput
                    name="minRange"
                    label="Min Range"
                    occupy={3}
                  />
                  <LabeledTextInput
                    name="maxRange"
                    label="Max Range"
                    occupy={3}
                  />
                  <LabeledTextInput name="units" label="Units" occupy={3} />
                  <LabeledTextInput
                    name="processHookUpNumber"
                    label="Process Hook up Number"
                    occupy={3}
                  />
                  <LabeledTextInput
                    name="functionalLoop"
                    label="Functional Loop"
                    occupy={3}
                  />
                  <LabeledTextInput
                    name="modelNo"
                    label="Model Number"
                    occupy={3}
                  />
                  <LabeledTextInput
                    name="manufacturerName"
                    label="Manufacturer Name"
                    occupy={3}
                  />
                  <LabeledTextInput
                    name="prNumber"
                    label="PR Number"
                    occupy={3}
                  />
                  <LabeledTextInput name="remarks" label="Remarks" occupy={3} />
                  <Spacer height={20} />
                  <Button
                    color="success"
                    onClick={form.submit}
                    disabled={submitting}
                  >
                    Create
                  </Button>
                </Row>
              </form>
            )}
          />
        </ModalBody>
      </Modal>
    </>
  )
}
