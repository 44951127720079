import React, { useRef } from 'react'
import { Button, Col, ModalFooter, Row } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import Loader from '../../components/Loader'
import { analyticsEndpoint } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { permitStatuses } from '../../components/previews/ViewPermitdetails'
import ReactToPrint from 'react-to-print'

const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

const generateColors = (count: any) => {
  const colors = []
  for (let i = 0; i < count; i++) {
    const color = getRandomColor()
    colors.push(color)
  }
  return colors
}

export default function PermitsByStatusTiles() {
  const { accounts, instance } = useMsal()
  const componentRef: any = useRef()

  const fetchAnalytis = async () => {
    const response = await makeAuthenticatedRequest(
      `${analyticsEndpoint}/UtilizationHours`,
      'POST',
      null,
      accounts,
      instance
    )

    return response
  }

  const {
    data: analyticsData,
    isLoading,
    refetch
  }: any = useQuery('analyticsData', fetchAnalytis)

  const permitsByStatus = analyticsData?.permitsByStatus

  const permitsDataColors = generateColors(permitsByStatus?.length)

  const permitsByStatusData = permitsByStatus?.map((v: any, index: any) => ({
    title: v?._id,
    value: v?.count,
    color: permitsDataColors[index]
  }))

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div ref={componentRef}>
          <Row style={{ margin: 0 }}>
            <Row>
              <Col xs="12" md="12">
                <div
                  style={{
                    padding: 10,
                    border: '1px solid orange',
                    marginLeft: 20,
                    marginBottom: 20,
                    justifyContent: 'center',
                    printColorAdjust: 'exact'
                  }}
                >
                  <p
                    style={{
                      fontSize: 26,
                      textAlign: 'center',
                      fontWeight: '900'
                    }}
                  >
                    Permits By Status
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      height: 'auto',
                      marginBottom: 10
                    }}
                  >
                    {permitsByStatusData?.map((p: any) => (
                      <div
                        style={{
                          height: '10vh',
                          width: '14vh',
                          marginLeft: 5,
                          marginBottom: 5,
                          WebkitPrintColorAdjust: 'exact',
                          backgroundColor: p?.color,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          color: '#fff',
                          border: '1px solid #000'
                        }}
                      >
                        <p style={{ textAlign: 'center', fontSize: '20px' }}>
                          {p?.value}
                        </p>
                        <p style={{ textAlign: 'center' }}>
                          {permitStatuses[p?.title] || p?.title}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <img
                      src={require('./../../images/cieptw.jpeg')}
                      alt="HOEC Logo"
                      height={50}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Row>
          <ModalFooter>
            <ReactToPrint
              pageStyle={'padding: 20'}
              trigger={() => <Button color="warning" children="Print" />}
              content={() => componentRef.current}
            />
          </ModalFooter>
        </div>
      )}
    </>
  )
}
