import { Route, Routes } from 'react-router-dom'

import ItemMasterDashboard from '../pages/itemmaster/ItemMasterDashboard'
import Viewitem from '../pages/itemmaster/ViewItem'

export default function ItemMasterRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ItemMasterDashboard />} />
      <Route path="/view" element={<Viewitem />} />
    </Routes>
  )
}
