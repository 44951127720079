import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useContext, useState } from 'react'

import { useAdminPermissions } from './EditRisks'
import IsolationsCheckboxTable from '../../components/Tables/IsolationsCheckBoxTable'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import ViewPermit from './ViewPermit'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { newPermitEndpoint } from '../../components/serverurl'
import { SubHeader } from '../VerifyCM'

import React from 'react'
import { RadioButtonGroup, yesNoOptions } from './VerifyPermit'
import { UserContext } from '../../App'
import { capitalizeFirstLetter } from '../../components/lib/capitalizeFirstLetter'

export default function FinishIsolation() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const { userData } = useContext(UserContext)

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const [submitModal, setSubmitModal]: any = useState(false)

  const [formData, setFormData]: any = useState({
    isolationDone: 'No',
    allIsolated: 'No',
    electricalIsolationsRecorded: 'No'
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const submitValues = {
      ...formData,
      department: capitalizeFirstLetter(userData?.department)?.trim(),
      isolationsId: data?.permit?.isolations?._id,
      consentId: data?.permit?.consent?._id
    }
    const response = await makeAuthenticatedRequest(
      `${newPermitEndpoint}/isolation/finish`,
      'PATCH',
      submitValues,
      accounts,
      instance
    )

    return response.status
  }

  const isolations = data?.permit?.isolations?.isolations
  const currentUserDesignation = capitalizeFirstLetter(
    userData?.department
  ).trim()

  const filterIsolation = isolations?.filter(
    (i: any) =>
      i?.isolationType.valueOf() === currentUserDesignation.valueOf() &&
      i?.status === 'started'
  )

  const hasIsolations = filterIsolation?.length > 0

  const form = (
    <div>
      <RadioButtonGroup
        question="Is the Isolation done as per the Company isolation philosophy ? "
        name="isolationDone"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Have you isolated all the Points under your Department as per Isolation certificate ?"
        name="allIsolated"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Is the Electrical isolation have been recorded in the electrical shift logbook"
        name="electricalIsolationsRecorded"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
    </div>
  )

  return (
    <ProtectedLayout onBack="/eptw/newPermit/isolation/finish">
      <ViewPermit data={data} isLoading={isLoading} />
      {data?.permit?.status === 'verified' && hasIsolations && (
        <>
          {hasIsolations && <SubHeader header="Finish Isolations" />}
          <IsolationsCheckboxTable
            printData={data}
            checkBoxHeader="Finish"
            buttonText="Finish Isolation"
            data={filterIsolation}
            onSubmit={(val: any) => {
              setSubmitModal(true)
            }}
          />
          {submitModal && (
            <SubmitModalWithForm
              isOpen={submitModal}
              onClose={(val: any) => {
                setSubmitModal(false)
                val && navigate('/eptw/newPermit/isolation/finish')
              }}
              onSubmit={handleSubmit}
              form={form}
            />
          )}
        </>
      )}
    </ProtectedLayout>
  )
}
