import { useMsal } from '@azure/msal-react'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { makeAuthenticatedRequest } from '../../apiRequests'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import Loader from '../../components/Loader'
import { epermitCloseEndPoint } from '../../components/serverurl'
import ViewPermits from '../../components/Tables/ViewPermits'

export default function CloseOutList() {
  const { accounts, instance } = useMsal()
  const [pageNumber, setPageNumber] = useState(0)

  const fetchCloseOutReports = async () => {
    const response = await makeAuthenticatedRequest(
      `${epermitCloseEndPoint}?page=${pageNumber}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response?.data
  }

  const { data, isLoading, refetch }: any = useQuery(
    'ePermitCloseOut',
    fetchCloseOutReports
  )

  return (
    <ProtectedLayout onBack="/eptw/dashboard">
      {isLoading ? (
        <Loader />
      ) : (
        <ViewPermits
          data={{ permits: data }}
          isLoading={isLoading}
          refetch={refetch}
          setPageNumber={setPageNumber}
          navigateTo={`/eptw/closeOutReports`}
        />
      )}
    </ProtectedLayout>
  )
}
