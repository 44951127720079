import React, { useRef } from 'react'
import { PieChart } from 'react-minimal-pie-chart'
import { Button, Col, Input, ModalFooter, Row, Spinner } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'

import Loader from '../../components/Loader'
import CustomLabel from '../../components/InputFields/CustomLabel'
import { analyticsEndpoint } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { EmptyContent } from '../CreateNewPermit'
import ReactToPrint from 'react-to-print'

const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

const generateColors = (count: any) => {
  const colors = []
  for (let i = 0; i < count; i++) {
    const color = getRandomColor()
    colors.push(color)
  }
  return colors
}

export default function ResourceStatistics() {
  const { accounts, instance } = useMsal()
  const componentRef: any = useRef()

  const date = new Date()

  const [startDate, setStartDate]: any = useState('2023-11-01')
  const [endDate, setEndDate]: any = useState(
    `${date.toISOString().split('T')[0]}`
  )

  const fetchAnalytis = async () => {
    const response = await makeAuthenticatedRequest(
      `${analyticsEndpoint}/UtilizationHours?startDate=${startDate}&endDate=${endDate}`,
      'POST',
      null,
      accounts,
      instance
    )

    return response
  }

  const {
    data: analyticsData,
    isLoading,
    refetch,
    isRefetching,
    error
  }: any = useQuery('analyticsData', fetchAnalytis)

  const pieData = analyticsData?.data[0]
  const vendorsData = analyticsData?.vendorHours
  const coreCrewData = analyticsData?.coreCrewHours

  const colors = generateColors(vendorsData?.length)
  const coreCrewColors = generateColors(coreCrewData?.length)

  const vendorPieData = vendorsData?.map((v: any, index: any) => ({
    title: v?.vendor,
    value: v?.hours,
    color: colors[index],
    labelPosition: 110 + index * 50
  }))

  const coewCrewPieData = coreCrewData?.map((v: any, index: any) => ({
    title: v?.vendor,
    value: v?.hours,
    color: coreCrewColors[index],
    labelPosition: 110 + index * 50
  }))

  useEffect(() => {
    refetch()
  }, [startDate, endDate])

  const pieDataTotal = [
    {
      title: 'Core Crew',
      value: pieData?.corecrew,
      color: '#E38627'
    },
    {
      title: 'Vendors',
      value: pieData?.vendors,
      color: '#C13C37'
    }
  ]

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div ref={componentRef}>
          <Row style={{ margin: 0 }}>
            <Row
              style={{
                margin: 0,
                marginBottom: 10,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Col xs="3">
                <CustomLabel label="Start Date" />
                <Input
                  type="date"
                  defaultValue={startDate}
                  onClick={(e: any) => setStartDate(e.target.value)}
                />
              </Col>
              <Col xs="3">
                <CustomLabel label="End Date" />
                <Input
                  type="date"
                  defaultValue={endDate}
                  onClick={(e: any) => {
                    setEndDate(e.target.value)
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="16">
                <Row>
                  <Col xs="12" md="12">
                    <div
                      style={{
                        height: 500,
                        padding: 10,
                        border: '1px solid orange',
                        marginLeft: 20,
                        marginBottom: 20,
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <div
                        style={{
                          width: '90%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}
                      >
                        <p
                          style={{
                            fontSize: 26,
                            textAlign: 'center',
                            fontWeight: '900'
                          }}
                        >
                          Vendor Utilization Staticstics
                        </p>
                        {isLoading || isRefetching ? (
                          <EmptyContent>
                            <Spinner />
                          </EmptyContent>
                        ) : (
                          pieData && (
                            <div style={{ display: 'flex' }}>
                              <div style={{ height: 350 }}>
                                <PieChart
                                  style={{ height: 300 }}
                                  label={({ dataEntry }: any) =>
                                    `${dataEntry.title}: ${dataEntry.value}`
                                  }
                                  labelStyle={{
                                    fontSize: '4px'
                                  }}
                                  data={vendorPieData}
                                />
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  marginLeft: 30
                                }}
                              >
                                {vendorPieData?.length > 0 &&
                                  vendorPieData?.map((p: any) => (
                                    <div
                                      key={p?.color}
                                      style={{ display: 'flex' }}
                                    >
                                      <div
                                        style={{
                                          width: '20px',
                                          height: '20px',
                                          backgroundColor: p?.color
                                        }}
                                      />
                                      <p
                                        style={{
                                          fontSize: '18px',
                                          fontWeight: '600',
                                          margin: 0,
                                          marginLeft: 10,
                                          whiteSpace: 'nowrap'
                                        }}
                                      >
                                        {p?.title} - {p?.value}
                                      </p>
                                    </div>
                                  ))}
                              </div>{' '}
                            </div>
                          )
                        )}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'end' }}>
                        <img
                          src={require('./../../images/cieptw.jpeg')}
                          alt="HOEC Logo"
                          height={50}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs="12" md="12">
                    <div
                      style={{
                        height: 500,
                        padding: 10,
                        border: '1px solid orange',
                        marginLeft: 20,
                        marginBottom: 20,
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '90%',
                          flexDirection: 'column'
                        }}
                      >
                        <p
                          style={{
                            fontSize: 26,
                            textAlign: 'center',
                            fontWeight: '900'
                          }}
                        >
                          Core Crew Statistics
                        </p>
                        {isLoading || isRefetching ? (
                          <EmptyContent>
                            <Spinner />
                          </EmptyContent>
                        ) : (
                          pieData && (
                            <div style={{ display: 'flex' }}>
                              <div style={{ height: 350 }}>
                                <PieChart
                                  style={{ height: 300 }}
                                  label={({ dataEntry }: any) =>
                                    `${dataEntry.title}: ${dataEntry.value}`
                                  }
                                  labelStyle={{
                                    fontSize: '4px'
                                  }}
                                  data={coewCrewPieData}
                                />
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  marginLeft: 30
                                }}
                              >
                                {coewCrewPieData?.length > 0 &&
                                  coewCrewPieData?.map((p: any) => (
                                    <div
                                      key={p?.title}
                                      style={{ display: 'flex' }}
                                    >
                                      <div
                                        style={{
                                          width: '20px',
                                          height: '20px',
                                          backgroundColor: p?.color
                                        }}
                                      />
                                      <p
                                        style={{
                                          fontSize: '18px',
                                          fontWeight: '600',
                                          margin: 0,
                                          marginLeft: 10,
                                          whiteSpace: 'nowrap'
                                        }}
                                      >
                                        {p?.title} - {p?.value}
                                      </p>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'end' }}>
                        <img
                          src={require('./../../images/cieptw.jpeg')}
                          alt="HOEC Logo"
                          height={50}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" md="12">
                <div
                  style={{
                    height: '100%',
                    padding: 10,
                    border: '1px solid orange',
                    marginLeft: 20,
                    marginBottom: 20,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '90%',
                      flexDirection: 'column'
                    }}
                  >
                    <p
                      style={{
                        fontSize: 26,
                        textAlign: 'center',
                        fontWeight: '900'
                      }}
                    >
                      Resource Utilization chart
                    </p>
                    {isLoading || isRefetching ? (
                      <EmptyContent>
                        <Spinner />
                      </EmptyContent>
                    ) : (
                      pieData && (
                        <div style={{ height: 350, display: 'flex' }}>
                          <div>
                            <PieChart
                              style={{ height: 300 }}
                              label={({ dataEntry }: any) =>
                                `${dataEntry.title}: ${dataEntry.value}`
                              }
                              labelStyle={{
                                fontSize: '4px'
                              }}
                              data={pieDataTotal}
                            />
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              marginLeft: 30,
                              marginTop: 20
                            }}
                          >
                            {pieDataTotal?.length > 0 &&
                              pieDataTotal?.map((p: any) => (
                                <div
                                  key={p?.color}
                                  style={{ display: 'flex', width: 'auto' }}
                                >
                                  <div
                                    style={{
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: p?.color
                                    }}
                                  />
                                  <p
                                    style={{
                                      fontSize: '18px',
                                      fontWeight: '600',
                                      margin: 0,
                                      marginLeft: 10
                                    }}
                                  >
                                    {p?.title} - {p?.value}
                                  </p>
                                </div>
                              ))}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                      height: '100%'
                    }}
                  >
                    <img
                      src={require('./../../images/cieptw.jpeg')}
                      alt="HOEC Logo"
                      height={50}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Row>
        </div>
      )}
      <ReactToPrint
        pageStyle={'paddingtop: 1 0'}
        trigger={() => (
          <ModalFooter style={{ marginTop: 10 }}>
            <Button color="warning" children="Print" style={{ margin: 10 }} />
          </ModalFooter>
        )}
        content={() => componentRef.current}
      />
    </>
  )
}
