import { Route, Routes } from 'react-router-dom'

import KPIStatistics from '../pages/analytics/KPIStatictics'
import PermitsByStatusTiles from '../pages/analytics/PermitsByStatusTiles'
import PermitStatistics from '../pages/analytics/PermitStatistics'
import ResourceStatistics from '../pages/analytics/ResourceStatistics'

export default function AnalyticsRoutes() {
  return (
    <Routes>
      <Route path="/permitsByStatus" element={<PermitsByStatusTiles />} />
      <Route path="/resource" element={<ResourceStatistics />} />
      <Route path="/permit" element={<PermitStatistics />} />
      <Route path="/kpi" element={<KPIStatistics />} />
    </Routes>
  )
}
