import { Button, Col, Input, ModalFooter, Row } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import { AcceptedPOTable } from '../vendor/vendorView'
import { Box, renderData } from '../workManagement/PmTree'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { invoiceAPI, stock } from '../../components/serverurl'
import { Text } from '../../components/RiskAssesmentPreview'
import ReactToPrint from 'react-to-print'
import { useContext, useRef, useState } from 'react'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { UserContext } from '../../App'
import { prStatuses } from '../../components/constants/woStatuses'
import CustomLabel from '../../components/InputFields/CustomLabel'

export default function ViewPaymentRequest({
  nextStatus,
  onBack
}: {
  nextStatus: any
  onBack: any
}) {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const [closeTo, setClostTo]: any = useState(false)
  const [date, setDate]: any = useState()
  const componentRef: any = useRef()

  const navigate = useNavigate()

  const fetchto = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/inventory/vendorOrders/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, isRefetching, refetch }: any = useQuery(
    'toDetails',
    fetchto
  )

  const toData = data?.voDetails?.transportOrder
  const attachments = data?.voDetails?.attachments

  const isDisabled =
    toData?.jobOrderC?.status === 'jobOrderClosed' &&
    toData?.jobOrderF?.status === 'jobOrderClosed'

  const trasnportOrderDetails: any = {
    'TO Number': toData?.number,
    'TO Status': prStatuses?.[toData?.status],
    'Vendor Name': toData?.vendorName,
    'Vendor Id': toData?.vendorId,
    'Logistics Coordinator Name': toData?.logisticsCoordinator,
    'Created On': getReadableTime(toData?.createdOn),
    'Freight Forwarder Name': toData?.forwarderName,
    'Freight Forwareder Id': toData?.forwarderId,
    'Warehouse Id': toData?.wareHouseId,
    'Warehouse Location': toData?.wareHouseLocation
  }

  const models: any = {
    JOC: 'JOC',
    JOF: 'JOF',
    VO: 'VO'
  }

  return (
    <ProtectedLayout onBack={onBack}>
      <div ref={componentRef}>
        <SectionHeader title={'Payment Request'} />
        <hr />
        <Row style={{ margin: 10 }}>
          <Box heading="Transport Order Details">
            {Object.keys(trasnportOrderDetails)?.map((key: any) =>
              renderData(key, trasnportOrderDetails[key])
            )}
          </Box>
          {data?.voDetails?.paymentDate && (
            <Box heading="Payment Details">
              <>
                {renderData(
                  'Payment Release Date',
                  getReadableTime(data?.voDetails?.paymentDate)
                )}
                {renderData('Payment Releasd By', 'RIG Manager')}
              </>
            </Box>
          )}
          <Box heading="Purchase Orders">
            <AcceptedPOTable data={toData?.poList} />
          </Box>
          <Row>
            <Col xs="6">
              <Box heading="Document 1">
                <iframe
                  title="Document Preview"
                  src={`${invoiceAPI}/${attachments?.[0]}`}
                  width="100%"
                  height="700px"
                />
              </Box>
            </Col>
            {attachments?.[1] && (
              <Col xs="6">
                <Box heading="Document 2">
                  <iframe
                    title="Document Preview"
                    src={`${invoiceAPI}/${attachments?.[1]}`}
                    width="100%"
                    height="700px"
                  />
                </Box>
              </Col>
            )}
          </Row>
        </Row>
      </div>
      <ModalFooter>
        {data?.voDetails?.status === 'invoiceSent' && (
          <Button onClick={() => setClostTo(true)} color="warning">
            Approve Payment Request
          </Button>
        )}
        {data?.voDetails?.status === 'verifiedPayment' && (
          <Button onClick={() => setClostTo(true)} color="warning">
            Approve Payment Request
          </Button>
        )}
        {data?.voDetails?.status === 'approvedPayment' && (
          <Button onClick={() => setClostTo(true)} color="warning">
            Initiate Payment and Close PO
          </Button>
        )}
      </ModalFooter>
      <SubmitModalWithForm
        isOpen={closeTo}
        onClose={(val: any) => {
          setClostTo(false)
          // val && navigate('/purchase/dashboard')
        }}
        form={
          nextStatus === 'approvedPayment' && (
            <Row>
              <CustomLabel label="Payment Date" />
              <Input
                type="date"
                onChange={(e: any) => setDate(e?.target?.value)}
              />
            </Row>
          )
        }
        onSubmit={async (e: any) => {
          e.preventDefault()
          const body: any = {
            status: nextStatus,
            paymentDate: date,
            model: models[data?.voDetails?.number?.split('-')?.[0]]
          }
          try {
            const res = await makeAuthenticatedRequest(
              `${stock}/inventory/payment/${data?.voDetails?._id}`,
              'PATCH',
              body,
              accounts,
              instance
            )

            if (res.status === 'success') {
              await refetch()
            }

            return res.status
          } catch (error) {}
        }}
        size="xs"
        header="Approve Payment"
      />
    </ProtectedLayout>
  )
}
