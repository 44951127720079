import { useMsal } from '@azure/msal-react'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { PermissionsContext, UserContext } from '../../App'
import toNormalCase from '../../components/lib/toNormalCase'
import { countsEndpoint } from '../../components/serverurl'
import ViewIcons from '../../components/ViewIcons'

export default function CIOActions() {
  const { permissions } = useContext(PermissionsContext)
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const userlocation = toNormalCase(permissions?.location)

  const permitsCountByflow = async () => {
    const response = await makeAuthenticatedRequest(
      `${countsEndpoint}/cio?department=${userData?.department}&location=${userlocation}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data }: any = useQuery('cioCount', permitsCountByflow)

  const counts = data?.counts

  const icons = [
    {
      name: 'VERIFY (CIO)',
      src: require('./../../images/Icons/Change in ownership_Verify.png'),
      nav: '/eptw/cio/verify',
      canView: permissions?.canVerifyCIO,
      count: counts?.verify
    },
    {
      name: 'ISSUE (CIO)',
      src: require('./../../images/Icons/Change in ownership_Issue.png'),
      nav: '/eptw/cio/issue',
      canView: permissions?.canIssueCIO,
      count: counts?.issue
    },
    {
      name: 'APPROVE (CIO)',
      src: require('./../../images/Icons/Change in ownership_Approve.png'),
      nav: '/eptw/cio/approve',
      canView: permissions?.canApproveCIO,
      count: counts?.approve
    },
    {
      name: 'MAKE PERMIT LIVE (CIO)',
      src: require('./../../images/Icons/Change in ownership_Live.png'),
      nav: '/eptw/cio/makeLive',
      canView: permissions?.canMakeLiveCIO,
      count: counts?.makeLive
    }
  ]

  return <ViewIcons icons={icons} />
}
