import { useMsal } from '@azure/msal-react'
import { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import { Row } from 'reactstrap'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { UserContext } from '../../App'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { capitalizeFirstLetter } from '../../components/lib/capitalizeFirstLetter'
import { epermit } from '../../components/serverurl'
import ViewPermits from '../../components/Tables/ViewPermits'

export default function PermitsWithIsolations({
  currentPermits,
  hasIsolations,
  flow = 'newPermit',
  isolationStatus
}: {
  currentPermits: String
  hasIsolations?: Boolean
  checkLocation?: Boolean
  flow?: String
  isolationStatus?: String
}) {
  const { accounts, instance } = useMsal()
  const [pageNumber, setPageNumber] = useState(0)
  const { userData } = useContext(UserContext)

  const location = useLocation()

  const currentStage = location?.pathname?.split('/')?.at(-1)

  const currentRoute = location?.pathname

  const baseUrl = currentRoute.substring(0, currentRoute.lastIndexOf('/'))

  const isolationURI = `${epermit}/isolations?page=${pageNumber}&permitStatus=${currentPermits}&isolationStatus=${isolationStatus}&department=${capitalizeFirstLetter(
    userData?.department
  )}`

  const fetchAdminPermissions = async () => {
    const response = await makeAuthenticatedRequest(
      isolationURI,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, refetch }: any = useQuery(
    `eptw${currentPermits}`,
    fetchAdminPermissions
  )

  return (
    <ProtectedLayout onBack={baseUrl}>
      <Row style={{ margin: 0 }}>
        <ViewPermits
          data={data}
          isLoading={isLoading}
          refetch={refetch}
          setPageNumber={setPageNumber}
          navigateTo={`/eptw/${flow}/${currentStage}`}
        />
      </Row>
    </ProtectedLayout>
  )
}
