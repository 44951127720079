import { useState } from 'react'
import {
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane
} from 'reactstrap'
import { SubHeader } from '../VerifyCM'
import { Link } from '../workManagement/PmTree'

const TABS = {
  FIVE: 'SPECIFICATION',
  SEVEN: 'ASSOCIATE TAG',
  EIGHT: 'ASSOCIATE DOCUMENT',
  NINE: 'MANUFACTURER DETAILS'
}

const TabFive = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Table bordered style={{ borderColor: '#000' }}>
        <thead>
          <tr>
            <th>ATTRIBUTES</th>
            <th>VALUES</th>
            <th>UO</th>
            <th>MANDATORY</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Item type</td>
            <td></td>
            <td />
            <td>
              <Input type="checkbox" />
            </td>
          </tr>
          <tr>
            <td>Size</td>
            <td></td>
            <td />
            <td>
              <Input type="checkbox" />
            </td>
          </tr>
          <tr>
            <td>Material</td>
            <td></td>
            <td />
            <td>
              <Input type="checkbox" />
            </td>
          </tr>
          <tr>
            <td>Part Number</td>
            <td>RR023702</td>
            <td />
            <td>
              <Input type="checkbox" />
            </td>
          </tr>
          <tr>
            <td>Position Number</td>
            <td></td>
            <td />
            <td>
              <Input type="checkbox" />
            </td>
          </tr>
          <tr>
            <td>Drawing Number</td>
            <td></td>
            <td />
            <td>
              <Input type="checkbox" />
            </td>
          </tr>
          <tr>
            <td>Eqpt Model</td>
            <td>BCL-255/256</td>
            <td />
            <td>
              <Input type="checkbox" />
            </td>
          </tr>
          <tr>
            <td>Eqpt Type</td>
            <td>CENTRIFUGAL</td>
            <td />
            <td>
              <Input type="checkbox" />
            </td>
          </tr>
          <tr>
            <td>Additional Information</td>
            <td>FOR SEAL OIL RESERVIOR</td>
            <td />
            <td>
              <Input type="checkbox" />
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

const TabSeven = () => {
  const data = [
    {
      tagNumber: '17-VI-3',
      storeBox: 'MAB-MAIN'
    },
    {
      tagNumber: '17-VI-5',
      storeBox: 'MAB-MAIN'
    },
    {
      tagNumber: '17-VI-7',
      storeBox: 'MAB-MAIN'
    },
    {
      tagNumber: '17-ZI-201',
      storeBox: 'MAB-MAIN'
    },
    {
      tagNumber: '17-ZI-203',
      storeBox: 'MAB-MAIN'
    }
  ]
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Table bordered style={{ borderColor: '#000' }}>
        <thead>
          <tr>
            <th>Tag No.</th>
            <th>Equipment Number</th>
            <th>Equipment Description</th>
            <th>Store Number</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((d: any) => (
            <tr key={d?.tagNumber}>
              <td>{d?.tagNumber}</td>
              <td></td>
              <td></td>
              <td>{d?.storeBox}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
const TabEight = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Table bordered style={{ borderColor: '#000' }}>
        <thead>
          <tr>
            <th>Doc FileName</th>
            <th>Doc Type</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>MAB-AIR-DRESSOR-001</td>
            <td>MIR</td>
            <td>
              <Link>documents\MAB-MIR-DRESSOR-001</Link>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
const TabNine = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {/* <img
        src={require('./../images/uptime.jpeg')}
        alt="kpi"
        style={{ height: '60vh', marginTop: 20 }}
      /> */}
    </div>
  )
}

export default function EnchancedMaterialTabs() {
  const [active, setActive] = useState(TABS.FIVE)
  return (
    <Row style={{ margin: 10 }}>
      <Col>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={active === TABS.FIVE ? 'active' : ''}
              onClick={() => setActive(TABS.FIVE)}
            >
              {TABS.FIVE}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === TABS.SEVEN ? 'active' : ''}
              onClick={() => setActive(TABS.SEVEN)}
            >
              {TABS.SEVEN}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === TABS.EIGHT ? 'active' : ''}
              onClick={() => setActive(TABS.EIGHT)}
            >
              {TABS.EIGHT}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === TABS.NINE ? 'active' : ''}
              onClick={() => setActive(TABS.NINE)}
            >
              {TABS.NINE}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={active}>
          <TabPane tabId={TABS.FIVE}>
            <SubHeader header={TABS.FIVE} permitColor="#FFD580" />
            <TabFive />
          </TabPane>
          <TabPane tabId={TABS.SEVEN}>
            <SubHeader header={TABS.SEVEN} permitColor="#FFD580" />
            <TabSeven />
          </TabPane>
          <TabPane tabId={TABS.EIGHT}>
            <SubHeader header={TABS.EIGHT} permitColor="#FFD580" />
            <TabEight />
          </TabPane>
          <TabPane tabId={TABS.NINE}>
            <SubHeader header={TABS.NINE} permitColor="#FFD580" />
            <TabNine />
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  )
}
