import React, { useRef } from 'react'
import { PieChart } from 'react-minimal-pie-chart'
import { Button, Col, Input, ModalFooter, Row, Spinner } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  DefaultTooltipContent,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import ReactToPrint from 'react-to-print'

import Loader from '../../components/Loader'
import CustomLabel from '../../components/InputFields/CustomLabel'
import { EmptyContent } from '../CreateNewPermit'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { analyticsEndpoint } from '../../components/serverurl'
import { capitalizeFirstLetter } from '../../components/lib/capitalizeFirstLetter'

export default function PermitStatistics() {
  const { accounts, instance } = useMsal()
  const date = new Date()
  const componentRef: any = useRef()

  const [startDate, setStartDate]: any = useState('2023-11-01')
  const [endDate, setEndDate]: any = useState(
    `${date.toISOString().split('T')[0]}`
  )

  const duration: any = {
    startDate: startDate,
    endDate: endDate
  }

  const fetchAnalytis = async () => {
    const response = await makeAuthenticatedRequest(
      `${analyticsEndpoint}/UtilizationHours`,
      'POST',
      duration,
      accounts,
      instance
    )

    return response
  }

  const fetchPermitsAnalytics = async () => {
    const response = await makeAuthenticatedRequest(
      `${analyticsEndpoint}/PermitsCount`,
      'POST',
      duration,
      accounts,
      instance
    )

    return response
  }

  const {
    data: analyticsData,
    isLoading,
    refetch,
    isRefetching
  }: any = useQuery('analyticsData', fetchAnalytis)

  const {
    data: permitAnalytics,
    isLoading: permitsLoading,
    isRefetching: permitsRefetch
  }: any = useQuery('permitsAnalyticsData', fetchPermitsAnalytics)

  const pieData = analyticsData?.data[0]
  const permitsData = permitAnalytics?.data
  const permitTypeData = analyticsData?.permits
  const permitsByLocation = analyticsData?.permitsByLocation

  useEffect(() => {
    refetch()
  }, [startDate, endDate])

  const permitsPieData = [
    {
      title: 'Cold Work',
      value: permitTypeData?.cold,
      color: '#fefaa2'
    },
    {
      title: 'Hot Work',
      value: permitTypeData?.hot,
      color: '#f5cee2'
    }
  ]

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div ref={componentRef}>
          <Row style={{ margin: 0 }}>
            <Row
              style={{
                margin: 0,
                marginBottom: 10,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Col xs="3">
                <CustomLabel label="Start Date" />
                <Input
                  type="date"
                  defaultValue={startDate}
                  onClick={(e: any) => setStartDate(e.target.value)}
                />
              </Col>
              <Col xs="3">
                <CustomLabel label="End Date" />
                <Input
                  type="date"
                  defaultValue={endDate}
                  onClick={(e: any) => setEndDate(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="12">
                <div
                  style={{
                    height: 300,
                    padding: 10,
                    border: '1px solid orange',
                    marginBottom: 20,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '90%',
                      flexDirection: 'column'
                    }}
                  >
                    <p
                      style={{
                        fontSize: 26,
                        textAlign: 'center',
                        fontWeight: '900'
                      }}
                    >
                      Work Nature Statistics
                    </p>
                    {isLoading || isRefetching ? (
                      <EmptyContent>
                        <Spinner />
                      </EmptyContent>
                    ) : (
                      permitsPieData && (
                        <div style={{ height: 300, display: 'flex' }}>
                          <div>
                            <PieChart
                              style={{ height: 200 }}
                              // label={({ dataEntry }) => <p>{dataEntry.title}</p>}
                              label={({ dataEntry, dataIndex }: any) =>
                                `${dataEntry.title}: ${dataEntry.value}`
                              }
                              labelStyle={{
                                fontSize: '6px'
                              }}
                              data={permitsPieData}
                            />
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              marginLeft: 30
                            }}
                          >
                            {permitsPieData?.length > 0 &&
                              permitsPieData?.map((p: any) => (
                                <div style={{ display: 'flex', width: 'auto' }}>
                                  <div
                                    style={{
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: p?.color
                                    }}
                                  />
                                  <p
                                    style={{
                                      fontSize: '18px',
                                      fontWeight: '600',
                                      margin: 0,
                                      marginLeft: 10
                                    }}
                                  >
                                    {p?.title} - {p?.value}
                                  </p>
                                </div>
                              ))}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                      height: '100%'
                    }}
                  >
                    <img
                      src={require('./../../images/cieptw.jpeg')}
                      alt="HOEC Logo"
                      height={50}
                    />
                  </div>
                </div>
              </Col>
              <Col xs="12" md="12">
                <div
                  style={{
                    border: '1px solid orange',
                    display: 'flex'
                  }}
                >
                  <div
                    style={{
                      height: 800,
                      width: '75%',
                      padding: 10,
                      marginLeft: 20,
                      marginBottom: 20
                    }}
                  >
                    <div>
                      <p
                        style={{
                          fontSize: 26,
                          textAlign: 'center',
                          fontWeight: '900'
                        }}
                      >
                        Permits Analytics
                      </p>
                      {permitsLoading || permitsRefetch ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 'inehrit'
                          }}
                        >
                          <Spinner />
                        </div>
                      ) : (
                        analyticsData && (
                          <div>
                            <div>
                              <BarChart
                                style={{
                                  width: '90%',
                                  height: 600
                                }}
                                width={600}
                                height={400}
                                data={permitsData}
                              >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="_id" />
                                <YAxis />
                                <DefaultTooltipContent />
                                <Bar dataKey="count" fill="#8884d8" />
                              </BarChart>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      width: '25%',
                      height: 'inherit',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    {permitsData?.map((p: any) => (
                      <div style={{ display: 'flex' }}>
                        <p
                          style={{ marginLeft: 10, fontWeight: 700 }}
                        >{`${capitalizeFirstLetter(p?._id)} - `}</p>
                        <p style={{ marginLeft: 10 }}>{` ${p?.count}`}</p>
                      </div>
                    ))}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                      marginBottom: 10,
                      marginRight: 10
                    }}
                  >
                    <img
                      src={require('./../../images/cieptw.jpeg')}
                      alt="HOEC Logo"
                      height={50}
                    />
                  </div>
                </div>
              </Col>
              <Col xs="12" md="12">
                <div
                  style={{
                    border: '1px solid orange',
                    display: 'flex',
                    marginTop: 10
                  }}
                >
                  <div
                    style={{
                      height: 800,
                      width: '75%',
                      padding: 10,
                      marginLeft: 20,
                      marginBottom: 20
                    }}
                  >
                    <div>
                      <p
                        style={{
                          fontSize: 26,
                          textAlign: 'center',
                          fontWeight: '900'
                        }}
                      >
                        PTW as per Locations
                      </p>
                      {permitsLoading || permitsRefetch ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 'inehrit'
                          }}
                        >
                          <Spinner />
                        </div>
                      ) : (
                        permitsByLocation && (
                          <>
                            <BarChart
                              style={{
                                width: '90%',
                                height: 600,
                                fontSize: 8
                              }}
                              width={600}
                              height={400}
                              data={permitsByLocation}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis
                                dataKey="_id"
                                angle={-45}
                                textAnchor="end"
                                interval={0}
                                height={80}
                                padding={{ left: 30, right: 30 }}
                              />
                              <YAxis />
                              <Tooltip />
                              <Bar dataKey="count" fill="orange">
                                {permitsByLocation.map(
                                  (entry: any, index: any) => (
                                    <React.Fragment key={`bar-${index}`}>
                                      <Bar dataKey="count" fill="orange" />
                                      <text
                                        fill="#8884d8"
                                        fontSize={12}
                                        textAnchor="middle"
                                      >
                                        {entry.count}
                                      </text>
                                    </React.Fragment>
                                  )
                                )}
                              </Bar>
                            </BarChart>
                          </>
                        )
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      width: '25%',
                      height: 'inherit',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    {permitsByLocation?.map((p: any) => (
                      <div
                        style={{
                          display: 'flex'
                        }}
                      >
                        <p
                          style={{ marginLeft: 10, fontWeight: 700 }}
                        >{`${capitalizeFirstLetter(p?._id)} - `}</p>
                        <p style={{ marginLeft: 10 }}>{`${p?.count}`}</p>
                      </div>
                    ))}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                      marginBottom: 10,
                      marginRight: 10
                    }}
                  >
                    <img
                      src={require('./../../images/cieptw.jpeg')}
                      alt="HOEC Logo"
                      height={50}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Row>
          <ReactToPrint
            trigger={() => (
              <ModalFooter style={{ marginTop: 10 }}>
                <Button
                  color="warning"
                  children="Print"
                  style={{ margin: 10 }}
                />
              </ModalFooter>
            )}
            content={() => componentRef.current}
          />
        </div>
      )}
    </>
  )
}
