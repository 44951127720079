import { callMsGraph } from './../graph'
import { loginRequest2 } from './../authConfig'
import { useMsal } from '@azure/msal-react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { MdCloudDone, MdSmsFailed } from 'react-icons/md'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Spinner,
  Table
} from 'reactstrap'
import Spacer from './Spacer'
import { epermit } from './serverurl'
import { makeAuthenticatedRequest } from '../apiRequests'
import { Form } from 'react-final-form'
import LabeledRadioButtons from './InputFields/LabeledRadioButton'
import { getOptions } from '../pages/firs'
import { useNavigate } from 'react-router-dom'

export default function PermitSubmitStatusModal({
  form,
  isolationValues,
  isOpen,
  permitValues,
  riskAssesmentValues,
  toggle,
  type,
  reset,
  attachments,
  existingIsolationsId
}: any) {
  const [submissionStatus, setSubmissionStatus]: any = useState('notStarted')
  const [result, setResult]: any = useState()
  const navigate = useNavigate()

  const { instance, accounts } = useMsal()

  const finalValues: any = {
    permit: {
      ...permitValues,
      isolations: null,
      riskAnalysis: null,
      type,
      watchRequired: permitValues.watchRequired,
      needIsolation: permitValues.needIsolation
    },
    vehiclePermitValues: attachments?.vehicle,
    excavationPermitValues: attachments?.excavation,
    positiveIsolationValues: attachments?.positiveIsolation,
    confinedSpaceEntryValues: attachments?.confinedSpace,
    workAtHeightValues: attachments?.workAtHeight,
    isolationValues,
    riskAssessmentValues: riskAssesmentValues?.template?._id
      ? riskAssesmentValues?.template
      : riskAssesmentValues
  }

  const createPermit = async (val: any) => {
    try {
      setSubmissionStatus('started')

      const res = await makeAuthenticatedRequest(
        `${epermit}?isLTI=${existingIsolationsId}`,
        'POST',
        { ...finalValues, consent: { ...val }, isLTI: existingIsolationsId },
        accounts,
        instance
      )

      setResult(res)

      setSubmissionStatus('finished')
      form.reset()
      localStorage.setItem('createFromLTI', JSON.stringify(null))
      // navigate('/eptw/dashboard')
      reset()
    } catch (e: any) {
      setSubmissionStatus('failed')
      console.warn('e', e)
    } finally {
    }
  }

  const onClose = () => {
    toggle()
    setSubmissionStatus('notStarted')
    result && navigate('/eptw/dashboard')
    setResult(undefined)
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader>Submission Status</ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(val: any) => createPermit(val)}
            render={({ form }) => (
              <>
                {submissionStatus === 'notStarted' && (
                  <>
                    <p> Are you sure you want to submit ?</p>
                    <Spacer height={30} />
                    {/* <p><b>Details</b></p> */}
                    <p>
                      <b>Permit Title : </b> {permitValues.permitTitle}
                    </p>
                    <p>
                      <b>Work description</b> {permitValues.workDescription}
                    </p>
                    <form>
                      <LabeledRadioButtons
                        name="discussRiskAssesment"
                        defaultValue="No"
                        label="Discussed the Risk assesment, and work scope of this Permit with IM and with all parties Involved ?"
                        list={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' }
                        ]}
                        occupy={12}
                      />
                      <LabeledRadioButtons
                        name="equipmentPreparation"
                        defaultValue="Not Required"
                        label="Equipment Preparation & Site visit carried out? Done /Not Required"
                        list={[
                          { label: 'Done', value: 'Done' },
                          { label: 'Not Required', value: 'Not Required' }
                        ]}
                        occupy={12}
                      />
                      <LabeledRadioButtons
                        name="materialAvailability"
                        defaultValue="Not Required"
                        label="Checked Material availability for this Permit ?"
                        list={[
                          { label: 'Done', value: 'Done' },
                          { label: 'Not Required', value: 'Not Required' }
                        ]}
                        occupy={12}
                      />
                      <LabeledRadioButtons
                        name="otherHazardsConsidered"
                        defaultValue="No"
                        label="Hazard from other routine / non-routine operations considered and persons alerted ?"
                        list={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' }
                        ]}
                        occupy={12}
                      />
                    </form>
                    {/* <Table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Permit</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </Table> */}
                  </>
                )}
                {submissionStatus === 'started' && (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingBottom: 10
                      }}
                    >
                      <Spinner style={{ marginRight: 10 }} />
                      Submitting ...
                    </div>
                  </div>
                )}
                {submissionStatus === 'finished' && (
                  <div>
                    <p>
                      <MdCloudDone
                        color="green"
                        size="60"
                        style={{ marginRight: 10 }}
                      />
                      Form submission successful
                    </p>
                    <Table bordered style={{ borderColor: '#000' }}>
                      <tbody>
                        <tr>
                          <td>Permit Number</td>
                          <td>{result?.data?.permit?.woNumber}</td>
                        </tr>
                        <tr>
                          <td>Permit Title</td>
                          <td>{result?.data?.permit?.permitTitle}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}
                {submissionStatus === 'failed' && (
                  <div>
                    <p>
                      <MdSmsFailed
                        color="#DC4C64"
                        size="60"
                        style={{ marginRight: 10 }}
                      />
                      Form submission failed
                    </p>
                  </div>
                )}
                <Spacer heihgt={10} />
                <ModalFooter style={{ justifyContent: 'center' }}>
                  <Button color="warning" onClick={onClose}>
                    Close
                  </Button>
                  {submissionStatus !== 'finished' && (
                    <Button
                      color="warning"
                      // onClick={createPermit}
                      onClick={form.submit}
                      disabled={
                        submissionStatus === 'finished' ||
                        submissionStatus === 'started'
                      }
                    >
                      Submit
                    </Button>
                  )}
                </ModalFooter>
              </>
            )}
          />
        </ModalBody>
      </Modal>
    </>
  )
}
