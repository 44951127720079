import moment from 'moment'
import React, { useRef } from 'react'
import Select from 'react-select'
import ReactPaginate from 'react-paginate'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Col,
  Input,
  ModalFooter,
  Row,
  Spinner,
  Table
} from 'reactstrap'
import { EmptyContent } from '../../pages/CreateNewPermit'
import { renderArray } from '../../pages/WaitingForPIC'
import { incrementby1 } from '../Forms/JSAForm'
import DropDownFetch from '../InputFields/DropDownFetch'
import { permitFilters, tagsAPI } from '../serverurl'
import { getOptions } from '../../pages/firs'
import { permitStatuses } from '../previews/ViewPermitdetails'
import ReactToPrint from 'react-to-print'
import Loader from '../Loader'

import './../css/pagination.css'

type ViewPermitsType = {
  isLoading: any
  data: any
  refetch: any
  setPageNumber: any
  navigateTo: String
  equipment?: any
  setEquipment?: any
  location?: any
  setLocation?: any
  department?: any
  setDepartment?: any
  permitNumber?: any
  setPermitNumber?: any
  closed?: any
  showPermitNumber?: any
  status?: any
  setStatus?: any
  setStartDate?: any
  setEndDate?: any
  pageNumber?: any // remove optional and apply for all instaces where this is being used
  isRefetching?: any
}

export const workLcoations = [
  'Mechanical Workshop',
  'Fire Station',
  'Sub Station',
  'Control Room',
  'Instrument Workshop',
  'Process Area',
  'Admin Ware House'
]

export const departments = [
  'Mechanical',
  'Electrical',
  'Instrument',
  'Production',
  'Admin Ware House',
  'HSE'
]

export const statuses = [
  'Created',
  'HSE Verified',
  'isolated',
  'approved',
  'live',
  'overdue',
  'expired',
  'closed'
]

const permitStatuseAsKeyValuePair: any = [
  {
    value: 'draft',
    label: 'Draft'
  },
  {
    value: 'Created',
    label: 'Created'
  },
  {
    value: 'verified',
    label: 'Verified'
  },
  {
    value: 'overdue',
    label: 'Overdue'
  },
  {
    value: 'initiateRevalidation',
    label: 'Initiated Revalid'
  },
  {
    value: 'revalidationVerified',
    label: 'Verified Revalid'
  },
  {
    value: 'revalidationIssued',
    label: 'Verified Revalid'
  },
  {
    value: 'revalidationApproved',
    label: 'Approved Revalid'
  },
  {
    value: 'live',
    label: 'Live'
  },
  {
    value: 'deIsolationAndCloseInitiated',
    label: 'Initated D&C'
  },
  {
    value: 'deIsolationAndCloseVerified',
    label: 'Verified D&C'
  },
  {
    value: 'deIsolationAndCloseApproved',
    label: 'Approved D&C'
  },
  {
    value: 'deIsolatedForClose',
    label: 'DeIsolated'
  },
  {
    value: 'cioInitiated',
    label: 'Initiated CIO'
  },
  {
    value: 'Verified CIO',
    label: 'Verified CIO'
  },
  {
    value: 'Issued CIO',
    label: 'Issued CIO'
  },
  {
    value: 'liApproved CIOve',
    label: 'Approved CIO'
  },
  {
    value: 'Verified woi',
    label: 'Verified WOI'
  },
  {
    value: 'Issued WOI',
    label: 'Issued WOI'
  },
  {
    value: 'Approved WOI',
    label: 'Approved WOI'
  },
  {
    value: 'Initiated CWOI',
    label: 'Initiated CWOI'
  },
  {
    value: 'Verified CWOI',
    label: 'Verified CWOI'
  },
  {
    value: 'Approved CWOI',
    label: 'Approved CWOI'
  },
  {
    value: 'ltiInitiated',
    label: 'Initiated LTI'
  },
  {
    value: 'ltiVerified',
    label: 'Verified LTI'
  },
  {
    value: 'ltiApproved',
    label: 'Approved LTI'
  },
  {
    value: 'ltiIssued',
    label: 'Issued LTI'
  },
  {
    value: 'closed',
    label: 'CLOSED'
  },
  {
    value: 'isolated',
    label: 'Isolated'
  },
  {
    value: 'approved',
    label: 'Approved'
  },
  {
    value: 'rejected',
    label: 'Rejected'
  },
  {
    value: 'issued',
    label: 'Issued'
  },
  {
    value: 'suspended',
    label: 'Suspended'
  },
  {
    value: 'expired',
    label: 'Expired'
  }
]

export default function ViewPermits({
  isLoading,
  data,
  refetch,
  setPageNumber,
  navigateTo,
  equipment,
  setEquipment,
  location,
  setLocation,
  department,
  setDepartment,
  permitNumber,
  setPermitNumber,
  closed,
  showPermitNumber = true,
  status,
  setStatus,
  setStartDate,
  setEndDate,
  pageNumber,
  isRefetching
}: ViewPermitsType) {
  const navigate = useNavigate()
  const componentRef: any = useRef()

  const TableData = ({ p, index }: any) => {
    const equipment = p?.othersEquipmentNumbers
      ? p?.equipment?.length > 0 && p?.equipment[0]
        ? [...p?.equipment, p?.othersEquipmentNumbers]
        : [p?.othersEquipmentNumbers]
      : [...p?.equipment]

    const currentSerialSeries = (pageNumber - 1) * 20

    return (
      <>
        <tr>
          <td>
            {currentSerialSeries
              ? incrementby1(index) + currentSerialSeries
              : incrementby1(index)}
          </td>
          {showPermitNumber && (
            <td
              style={{
                textDecoration: 'underline',
                color: 'blue',
                cursor: 'pointer',
                whiteSpace: 'nowrap'
              }}
              onClick={() => navigate(`${navigateTo}/${p?._id}`)}
            >
              {p?.woNumber}
            </td>
          )}
          {showPermitNumber && <td>{p?.permitTitle}</td>}
          {!showPermitNumber && (
            <td
              style={{
                textDecoration: 'underline',
                color: 'blue',
                cursor: 'pointer',
                whiteSpace: 'nowrap'
              }}
              onClick={() => navigate(`/eptw/drafts/${p?._id}`)}
            >
              {p?.permitTitle}
            </td>
          )}
          <td style={{ width: '30%' }}>{renderArray(equipment, false)}</td>
          <td style={{ whiteSpace: 'nowrap' }}>{p?.workLocation}</td>
          <td>{p?.applicantDepartment}</td>
          {closed && <td>{p?.report?.lessonLearnt}</td>}
          <td style={{ whiteSpace: 'nowrap' }}>
            {permitStatuses[p?.status] || p?.status}
          </td>
          <td style={{ whiteSpace: 'nowrap' }}>
            {moment(p?.startDate).format('DD-MM-YYYY')}
          </td>
        </tr>
      </>
    )
  }

  return (
    <>
      <Row>
        {setPermitNumber && (
          <Col xs="12" md="2">
            <p style={{ fontWeight: 700 }}>Permit Number</p>
          </Col>
        )}
        {setEquipment && (
          <Col xs="12" md="2">
            <p style={{ fontWeight: 700 }}>Equipment</p>
          </Col>
        )}
        {setLocation && (
          <Col xs="12" md="2">
            <p style={{ fontWeight: 700 }}>Location</p>
          </Col>
        )}
        {setDepartment && (
          <Col xs="12" md="2">
            <p style={{ fontWeight: 700 }}>Department</p>
          </Col>
        )}
        {setStatus && (
          <Col xs="12" md="2">
            <p style={{ fontWeight: 700 }}>Status</p>
          </Col>
        )}
        {setStartDate && (
          <Col xs="12" md="2">
            <p style={{ fontWeight: 700 }}>Start Date</p>
          </Col>
        )}
        {setEndDate && (
          <Col xs="12" md="2">
            <p style={{ fontWeight: 700 }}>End Date</p>
          </Col>
        )}
      </Row>
      <Row>
        {setPermitNumber && (
          <Col xs="12" md="2">
            <DropDownFetch
              value={permitNumber}
              setValue={setPermitNumber}
              url={`${permitFilters}/number`}
            />
          </Col>
        )}
        {setEquipment && (
          <Col xs="12" md="2">
            <DropDownFetch
              name="equipment"
              value={equipment}
              setValue={setEquipment}
              url={`${tagsAPI}`}
            />
          </Col>
        )}
        {setLocation && (
          <Col xs="12" md="2">
            <Select
              options={[
                { value: undefined, label: 'Select' },
                ...getOptions(workLcoations)
              ]}
              onChange={(e: any) => setLocation(e?.value)}
            />
          </Col>
        )}
        {setDepartment && (
          <Col xs="12" md="2">
            <Select
              options={[
                { value: undefined, label: 'Select' },
                ...getOptions(departments)
              ]}
              onChange={(e: any) => setDepartment(e?.value)}
            />
          </Col>
        )}
        {setStatus && (
          <Col xs="12" md="2">
            <Select
              options={[
                {
                  value: undefined,
                  label: 'Select'
                },
                ...permitStatuseAsKeyValuePair
              ]}
              onChange={(e: any) => setStatus(e?.value)}
            />
          </Col>
        )}
        {setStartDate && (
          <Col xs="12" md="2">
            <Input
              type="date"
              onChange={(e: any) => setStartDate(e?.target?.value)}
            />
          </Col>
        )}
        {setEndDate && (
          <Col xs="12" md="2">
            <Input
              type="date"
              onChange={(e: any) => setEndDate(e?.target?.value)}
            />
          </Col>
        )}
      </Row>
      <div ref={componentRef}>
        <Table
          striped
          bordered
          style={{ borderColor: '#000', fontSize: 14, marginTop: 10 }}
        >
          <thead>
            <tr style={{ backgroundColor: 'orange', whiteSpace: 'nowrap' }}>
              {[
                'S.No.',
                ...(showPermitNumber ? ['Number'] : []),
                'Title',
                'Equipment',
                'Location',
                'Applicant Department',
                ...(closed
                  ? [
                      'Lesson Learnt'
                      // 'Prod Loss',
                      // 'Unit Shutdown',
                      // 'Plant Shutdown'
                    ]
                  : []),
                'Status',
                'Start Date'
              ]?.map((h: any) => <th key={h}>{h}</th>)}
            </tr>
          </thead>
          <tbody>
            {isLoading && !data && <EmptyContent label={<Spinner />} />}
            {data?.permits?.map((p: any, index: number) => (
              <React.Fragment key={p?._id}>
                {isLoading || isRefetching ? (
                  <Loader />
                ) : (
                  <TableData p={p} index={index} />
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(data?.totalDocuments / 20)}
          marginPagesDisplayed={10}
          pageRangeDisplayed={2}
          onPageChange={async (values) => {
            await setPageNumber(values.selected + 1)
            refetch()
          }}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </div>
      {data?.length > 0 && (
        <ReactToPrint
          documentTitle={status}
          pageStyle={'padding: 20'}
          trigger={() => (
            <ModalFooter>
              <Button color="warning" children="Print" />
            </ModalFooter>
          )}
          content={() => componentRef.current}
        />
      )}
    </>
  )
}
