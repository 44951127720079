import moment from 'moment'
import React, { useRef, useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, Table } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import HOECCRoughLayout from '../SVGs/HOECRoughLayout'
import ModalTitle from '../components/ModalTitle'
import Spacer from '../components/Spacer'
import { epermit } from '../components/serverurl'
import { makeAuthenticatedRequest } from '../apiRequests'

export default function ViewPermitsByLayout() {
  const { accounts, instance } = useMsal()
  const permitsCountByflow = async () => {
    const response = await makeAuthenticatedRequest(
      `${epermit}/livePermits`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data }: any = useQuery('liveCounts', permitsCountByflow)

  let componentRef: any = useRef()

  const [modal, setModal]: any = useState(false)
  const [permits, setPermits]: any = useState()

  const styles = `@page { size: landscape; }`

  return (
    <>
      <div
        ref={componentRef}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          alignItems: 'center',
          backgroundColor: 'orange',
          margin: 0,
          WebkitPrintColorAdjust: 'exact'
        }}
      >
        <style>{styles}</style>
        <img
          alt="hoec"
          src={require('./../images/hoec-logo.png')}
          height={70}
        />
        <p style={{ fontSize: 30, fontWeight: 700, margin: 0 }}>LIVE PERMITS</p>
        <p style={{ fontSize: 30, fontWeight: 700, margin: 0 }}>
          Date: {moment().format('DD-MM-YYYY')}{' '}
        </p>
      </div>
      <HOECCRoughLayout
        data={data?.liveCounts}
        setModal={setModal}
        setPermits={setPermits}
      />
      <ModalFooter>
        <Button color="warning" onClick={() => window.print()}>
          Print
        </Button>
      </ModalFooter>
      <Modal isOpen={modal} size="xl">
        <ModalBody>
          <ModalTitle title={permits?.title} />
          <Spacer height={10} />
          {permits?.data?.length > 0 ? (
            <Table bordered style={{ borderColor: '#000' }}>
              <thead>
                <tr style={{ backgroundColor: 'orange' }}>
                  <th>Permit Number</th>
                  <th>Permit Title</th>
                  <th>Responsible Department</th>
                </tr>
              </thead>
              <tbody>
                {permits?.data?.map((p: any) => (
                  <tr key={p?._id}>
                    <td>{p?.woNumber}</td>
                    <td>{p?.permitTitle}</td>
                    <td>{p?.applicantDepartment}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No Live Permits</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="warning"
            onClick={() => {
              setModal(false)
              setPermits()
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
