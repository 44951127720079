import { Row } from 'reactstrap'
import { IconCard } from '../../components/Dashboard/IconCard'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'

export default function FinanceDashboard() {
  const flows: any = [
    {
      src: require('./../../images/Icons/unit1.png'),
      name: 'UNIT 1 Finance',
      nav: '/cmms/finance/unit1'
    },
    {
      src: require('./../../images/Icons/unit2.png'),
      name: 'UNIT 2 Finance',
      nav: '/cmms/finance/unit2'
    },
    {
      src: require('./../../images/Icons/unit3.png'),
      name: 'UNIT 3 Finance',
      nav: '/cmms/finance/unit3'
    },
    {
      src: require('./../../images/Icons/unit4.png'),
      name: 'UNIT 4 Finance',
      nav: '/cmms/finance/unit4'
    },
    {
      src: require('./../../images/Icons/Project FINANCE.png'),
      name: 'Project Finance',
      nav: '/cmms/finance/project'
    }
  ]

  return (
    <>
      <ProtectedLayout onBack="/cmms" title="FINANCE">
        <Row style={{ margin: 10 }}>
          {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
            <IconCard
              key={`${index}-${name}`}
              name={name}
              nav={nav}
              canView={canView}
              count={count}
              src={src}
            />
          ))}
        </Row>
      </ProtectedLayout>
    </>
  )
}
