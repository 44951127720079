import ReactToPrint from 'react-to-print'
import React, { useContext, useRef, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import {
  Button,
  Col,
  Input,
  ModalFooter,
  Row,
  Spinner,
  Table
} from 'reactstrap'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import toNormalCase from '../../components/lib/toNormalCase'
import { Box, renderData } from '../workManagement/PmTree'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { prStatuses } from '../../components/constants/woStatuses'
import { stock, usersEndpoint } from '../../components/serverurl'
import { SubHeader } from '../VerifyCM'
import { UserContext } from '../../App'

const AbanVendorsTable = ({
  data,
  isLoading,
  isRefetching,
  page,
  selectedVendors,
  setSelectedVendors
}: {
  data: any
  isLoading: boolean
  isRefetching: boolean
  refetch: any
  page: any
  selectedVendors: any
  setSelectedVendors: any
}) => {
  const handleCheckBox = (e: any, id: any) =>
    setSelectedVendors(
      e?.target?.checked
        ? [...selectedVendors, ...data?.filter((v: any) => v?.vendorId === id)]
        : selectedVendors?.filter((v: any) => v?.vendorId !== id)
    )

  return (
    <div>
      <Table style={{ maxWidth: 'inherit', overflow: 'scroll' }}>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Id</th>
            <th>Category Id</th>
            <th>Name</th>
            <th>Email</th>
            <th>Type</th>
            <th>Import Tax</th>
            <th>GST No.</th>
            <th>Approval Status</th>
            <th>Contact Name</th>
            <th>Phone Number</th>
          </tr>
        </thead>
        <tbody>
          <>
            {isLoading || isRefetching ? (
              <tr>
                <td colSpan={12}>
                  <Row
                    style={{
                      height: 500,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Spinner />
                  </Row>
                </td>
              </tr>
            ) : (
              data?.length > 0 &&
              data?.map((w: any, index: number) => (
                <tr key={`${w?._id}-${index}`}>
                  <td>
                    <Input
                      type="checkbox"
                      onClick={(e: any) => handleCheckBox(e, w?.vendorId)}
                      onChange={() => {}}
                      checked={
                        selectedVendors?.filter(
                          (v: any) => v?.vendorId === w?.vendorId
                        )[0]
                      }
                    />
                  </td>
                  <td>{incrementby1(page * 20 + index)}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>{w?.vendorId}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>{w?.catergoryId}</td>
                  <td>{w?.userName}</td>
                  <td>{w?.email}</td>
                  <td>{w?.type}</td>
                  <td>{w?.importTax}</td>
                  <td>{w?.gst}</td>
                  <td>{w?.approvalStatus}</td>
                  <td>{w?.contactPerson}</td>
                  <td>{w?.phone}</td>
                </tr>
              ))
            )}
          </>
        </tbody>
      </Table>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Users Found,
            <span
              style={{
                color: 'blue',
                textDecoration: 'underline',
                marginLeft: 2,
                cursor: 'pointer'
              }}
              onClick={() => {}}
            >
              Add one?
            </span>
          </p>
        </Row>
      )}
    </div>
  )
}

const MaterialsTable = ({
  pr,
  selectedSpares,
  setSelectedSpares
}: {
  pr: any
  selectedSpares: any
  setSelectedSpares: any
}) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>S.No</th>
            <th>Line Code</th>
            <th>Part Number</th>
            <th>Part Description</th>
            <th>Part Type</th>
            <th>UOM</th>
            <th>Cost Center</th>
            <th>Quantity</th>
            <th>Date Required</th>
            <th>RFQ Status</th>
          </tr>
        </thead>
        <tbody>
          {pr?.materialRequest?.map(
            (
              {
                spare: s,
                quantityRequested,
                dateRequested,
                costCenter,
                rfq,
                lineCode
              }: any,
              index: number
            ) => (
              <React.Fragment key={s?.['Spare Part Number']}>
                <tr>
                  <th>
                    {!rfq && (
                      <Input
                        type="checkbox"
                        onChange={() => {}}
                        disabled={rfq}
                        checked={
                          selectedSpares?.filter(
                            (spare: any) =>
                              spare['Spare Part Number'] ===
                              s?.['Spare Part Number']
                          )?.length > 0
                        }
                        onClick={(e: any) =>
                          e?.target?.checked
                            ? setSelectedSpares([
                                ...selectedSpares,
                                {
                                  spare: s?._id,
                                  quantityRequested,
                                  dateRequested,
                                  costCenter,
                                  lineCode,
                                  rfq,
                                  'Spare Part Number': s?.['Spare Part Number']
                                }
                              ])
                            : setSelectedSpares(
                                selectedSpares?.filter(
                                  (sp: any) =>
                                    sp?.['Spare Part Number'] !==
                                    s?.['Spare Part Number']
                                )
                              )
                        }
                      />
                    )}
                  </th>
                  <th>{incrementby1(index)}</th>
                  <th>{lineCode}</th>
                  <th style={{ width: '15%', whiteSpace: 'nowrap' }}>
                    {s?.['Spare Part Number']}
                  </th>
                  <th>{s?.['Spare Part Description']}</th>
                  <th>{s?.partType}</th>
                  <th>{s?.uom}</th>
                  <th>{costCenter}</th>
                  <th>{quantityRequested}</th>
                  <th style={{ width: '15%', whiteSpace: 'nowrap' }}>
                    {getReadableTime(dateRequested)}
                  </th>
                  <th>{rfq ? 'Sent' : 'NA'}</th>
                </tr>
                <tr>
                  <td colSpan={6}>
                    <div>
                      {renderData('Function Number', s?.functionNumber)}
                      {renderData(
                        'Function Description',
                        s?.functionDescription
                      )}
                      {renderData('Model', s?.model)}
                      {renderData('Manufacturer', s?.manufacturer)}
                      {renderData(
                        'Manufacturer Part No.',
                        s?.manufacturerPartNumber
                      )}
                      {renderData('Serial Number', s?.serialNumber)}
                      {renderData('Certificate Needed', s?.certificateNeeded)}
                      {renderData(
                        'Additional Tech Information',
                        s?.serialNumber
                      )}
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            )
          )}
        </tbody>
      </Table>
    </>
  )
}

export default function ViewRFQ({ title }: { title: any }) {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const componentRef: any = useRef()

  const [viewVendors, setViewVendors] = useState(false)
  const [selectedVendors, setSelectedVendors] = useState([])
  const [selectedSpares, setSelectedSpares]: any = useState([])

  const fetchpr = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/inventory/po/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, isRefetching, refetch }: any = useQuery(
    'pr',
    fetchpr
  )

  const fetchVendors = async () => {
    const response = await makeAuthenticatedRequest(
      `${usersEndpoint}/vendor`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const vendorQuery: any = useQuery('vendorsDataLIST', fetchVendors)

  const pr = data?.pr
  const vendorsData = vendorQuery?.data?.vendors

  const generalDetails: any = {
    'PR Number': pr?.number,
    Status: prStatuses[pr?.status],
    date: pr?.date,
    department: pr?.department,
    priority: pr?.priority,
    raisedBy: pr?.raisedBy
  }

  const equipmentDetail: any = {
    tag: pr?.tag,
    tagDescription: pr?.tagDescription,
    tagId: pr?.tagId,
    tagType: pr?.tagType
  }

  const Preview = () => {
    return (
      <Row>
        <Col xs="6">
          <Box heading="General Details">
            {Object.keys(generalDetails)?.map((key: any) =>
              renderData(toNormalCase(key), generalDetails[key])
            )}
          </Box>
        </Col>
        <Col xs="6">
          <Box heading="Equipment">
            {Object.keys(equipmentDetail)?.map((key: any) =>
              renderData(toNormalCase(key), equipmentDetail[key])
            )}
          </Box>
        </Col>
        <Col xs="12">
          <MaterialsTable
            pr={pr}
            selectedSpares={selectedSpares}
            setSelectedSpares={setSelectedSpares}
          />
        </Col>
      </Row>
    )
  }

  return (
    <ProtectedLayout onBack="/purchase/rfq">
      <div ref={componentRef} style={{ margin: 20 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <img
            src={require('./../../images/e73_logo.jpeg')}
            alt="HOEC Logo"
            style={{
              width: 150
            }}
          />
          <SubHeader
            permitColor="FFD580"
            header={
              <p style={{ margin: 0, fontSize: 20 }}>
                Request For Quotation - {pr?.number}
              </p>
            }
          />
        </div>
        <hr />
        <Preview />
        <Spacer height={10} />
      </div>
      <ReactToPrint
        documentTitle={`Request For Quotation - ${pr?.number}`}
        pageStyle={'padding: 20'}
        trigger={() => (
          <ModalFooter>
            <Button color="warning" children="Print" />
          </ModalFooter>
        )}
        content={() => componentRef.current}
      />
      <ModalFooter>
        {selectedSpares?.length > 0 && (
          <Button color={'warning'} onClick={() => setViewVendors(true)}>
            Send to Vendors
          </Button>
        )}
      </ModalFooter>
      <SubmitModalWithForm
        isOpen={viewVendors}
        onClose={(val: any) => {
          setViewVendors(false)
        }}
        onSubmit={async (e: any) => {
          e?.preventDefault()
          try {
            const body: any = {
              lastestUpdatedBy: userData?.id,
              status: 'requestedForQuotation',
              vendors: selectedVendors?.map((v: any) => v?._id),
              prNumber: pr?.number,
              prMaterial: pr?.materialRequest,
              materialRequest: selectedSpares,
              pr: pr?._id
            }

            const res = await makeAuthenticatedRequest(
              `${stock}/inventory/rfq`,
              'POST',
              body,
              accounts,
              instance
            )

            if (res.status === 'success') {
              await refetch()
              setSelectedSpares([])
              setSelectedVendors([])
            }

            return res.status
          } catch (error) {}
        }}
        fullscreen
        header="Select Vendors"
        form={
          <AbanVendorsTable
            data={vendorsData}
            isLoading={vendorQuery.isLoading}
            isRefetching={vendorQuery.isRefetching}
            refetch={vendorQuery.refetch}
            setSelectedVendors={setSelectedVendors}
            selectedVendors={selectedVendors}
            page={0}
          />
        }
      />
    </ProtectedLayout>
  )
}
