import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { useMsal } from '@azure/msal-react'

const DropDownFetch = ({
  value,
  setValue,
  url,
  isMulti = false,
  name,
  setDescription = () => {},
  needSelect = true
}: any) => {
  const [options, setOptions] = useState(
    needSelect ? [{ value: undefined, label: 'Select' }] : []
  )
  const [searchTerm, setSearchTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const { accounts, instance } = useMsal()
  const pageSize = 10

  const loadOptions = () => {
    setIsLoading(true)

    const apiUrl = `${url}?page=${currentPage}&pageSize=${pageSize}&searchTerm=${searchTerm}`

    makeAuthenticatedRequest(apiUrl, 'GET', null, accounts, instance).then(
      (response: any) => {
        const newOptions = response.map((item: any) => ({
          value: item.value,
          label: item.label,
          description: item?.description,
          ...item
        }))
        setOptions((prevOptions: any) => prevOptions?.concat(newOptions))
        setIsLoading(false)
      }
    )
  }

  useEffect(() => {
    loadOptions()
  }, [currentPage, searchTerm])

  const handleInputChange = (inputValue: any) => {
    setSearchTerm(inputValue)
  }

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1)
  }

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      zIndex: 999
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
  }

  const assignValues = (e: any) => {
    setValue(e?.value)
    setDescription(e?.description)
  }

  return (
    <Select
      isSearchable
      name={name}
      isMulti={isMulti}
      onInputChange={handleInputChange}
      options={options}
      styles={customStyles}
      onChange={async (e: any) =>
        isMulti ? setValue(e) : await assignValues(e)
      }
      isLoading={isLoading}
      value={isMulti ? value : { value: value, label: value }}
      placeholder="Search and select..."
      noOptionsMessage={() => (isLoading ? 'Loading...' : 'No options found')}
      menuPortalTarget={document.body}
      isOptionSelected={() => false}
      onMenuScrollToBottom={handleLoadMore}
    />
  )
}

export default DropDownFetch
