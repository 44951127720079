import { Row } from 'reactstrap'

import DashboardNavigation from '../../components/Dashboard/DashboardNavigation'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import LOTORegister from '../LOTORegister'
import ObservationCard from '../ObservationCard'

export default function LOTO() {
  return (
    <>
      <ProtectedLayout
        onBack="/services"
        hasSideList
        hasUserProfile
        sidePanel={<DashboardNavigation />}
      >
        <LOTORegister />
      </ProtectedLayout>
    </>
  )
}
