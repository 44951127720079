import React, { useContext, useEffect, useState } from 'react'
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane
} from 'reactstrap'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import toNormalCase from '../components/lib/toNormalCase'
import Spacer from '../components/Spacer'
import SubmitModal from '../components/SubmitModal'

import { authoritiesForDesignations as authorities } from '../components/constants/authoritiesForDesignations'
import { makeAuthenticatedRequest } from '../apiRequests'
import { adminePermitPermissions } from '../components/serverurl'
import { UserContext } from '../App'
import { useMsal } from '@azure/msal-react'

const getCurrentStage = (value: number) => {
  switch (value) {
    case 0:
      return 'createFlow'
    case 1:
      return 'revalidate'
    case 2:
      return 'deIsolationAndClose'
    case 3:
      return 'deIsolationForTest'
    case 4:
      return 'reIsolation'
    case 5:
      return 'longTermIsolation'
    case 6:
      return 'closeFlow'
    case 7:
      return 'changeInOwnership'
    case 8:
      return 'withOutIsolation'
    case 9:
      return 'closeFlowWithOutIsolation'

    default:
      return 'createFlow'
  }
}

function AuthTable({
  designation,
  create,
  stage,
  data,
  id,
  hideIsolation,
  showDeIsolation,
  reIsolate,
  fetchPermissions
}: any) {
  const currentStage = getCurrentStage(id)
  const { accounts, instance } = useMsal()
  const [isopen, setIsOpen] = useState(false)
  const [update, setUpdate]: any = useState({})
  const [submitting, setSubmitting]: any = useState(false)

  const entries = data?.[currentStage]
  const permitEntries: any = entries?.['permit']
  const isolationEntries = entries?.['isolation']
  const deIsolationEntries = entries?.['deIsolation']
  const reIsolationEntries = entries?.['reIsolation']
  const approveEntries = entries?.['approve']

  const permitCreateActions = [
    'create',
    'createFromLTI',
    'updateRiskAssesment',
    'approveRiskAssesment'
  ]
  const permitGeneralActions = ['initiate', 'verify', 'issue']
  const isolationgeneralActions = ['start', 'finish', 'live']
  const departments: any = [
    'mechanical',
    'electrical',
    'instrument',
    'production'
  ]
  const isolationDepartments: any = departments
  const permitDepartments: any = [...departments, 'hse', 'adminwarehouse']
  const approveActions: any = [
    'mechanicalWorkshop',
    'adminWareHouse',
    'fireStation',
    'subStation',
    'controlRoom',
    'instrumentWorkshop',
    'processArea'
  ]

  const isolationActions: any = create
    ? ['create', ...isolationgeneralActions]
    : isolationgeneralActions
  const permitActions: any = create
    ? [
        ...permitCreateActions,
        permitGeneralActions[1],
        permitGeneralActions[2],
        permitGeneralActions[3]
      ]
    : permitGeneralActions

  const onSubmit = async () => {
    try {
      setSubmitting(true)
      makeAuthenticatedRequest(
        adminePermitPermissions,
        'PATCH',
        update,
        accounts,
        instance
      ).then(async (res: any) => {
        await fetchPermissions()
        setUpdate()
        setSubmitting(false)
      })
    } catch (error) {
      return error
    }
  }

  const renderTableData = (
    data: any,
    action: string,
    department: string,
    type: string
  ) => (
    <td
      style={{
        cursor: 'pointer',
        color: data?.[action]?.[department] === 'Yes' ? 'darkGreen' : 'red',
        fontWeight: data?.[action]?.[department] === 'Yes' ? 700 : 500
        // width: '5%'
      }}
      onClick={() => {
        setIsOpen(true)
        setUpdate({
          designation,
          action,
          department,
          type,
          changeTo: data?.[action]?.[department] === 'Yes' ? 'No' : 'Yes',
          stage
        })
      }}
    >
      <p style={{ margin: 0, fontSize: 16, textAlign: 'center' }}>
        {data?.[action]?.[department]}
      </p>
    </td>
  )

  return (
    <>
      <p style={{ textAlign: 'center' }}>
        You are now Modifying the Authorities of <b>{designation}</b> for{' '}
        <b>{stage} Permit</b>
      </p>
      <Row>
        <Col xs="12" md="12">
          <Table
            bordered
            style={{ border: '1px solid #000', width: '100%', fontSize: 12 }}
          >
            <thead>
              <tr>
                <th
                  colSpan={7}
                  style={{
                    textAlign: 'center',
                    backgroundColor: 'orange',
                    fontSize: 16
                  }}
                >
                  Permit
                </th>
              </tr>
              <tr>
                <th
                  rowSpan={2}
                  style={{ textAlign: 'center', verticalAlign: 'middle' }}
                >
                  Actions
                </th>
                <th colSpan={6} style={{ textAlign: 'center' }}>
                  Departments
                </th>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <th>Mechanical</th>
                <th>Electrical</th>
                <th>Instrument</th>
                <th>Production</th>
                <th>HSE</th>
                <th>Ware House</th>
              </tr>
            </thead>
            <tbody>
              {permitActions?.map((action: string) => (
                <tr key={action}>
                  <td>{toNormalCase(action)}</td>
                  {permitDepartments?.map(
                    (department: string, index: number) => (
                      <React.Fragment key={`${action}-${index}-${department}`}>
                        {renderTableData(
                          permitEntries,
                          action,
                          department,
                          'permit'
                        )}
                      </React.Fragment>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          {!hideIsolation && (
            <Table
              bordered
              style={{ border: '1px solid #000', width: '100%', fontSize: 12 }}
            >
              <thead>
                <tr>
                  <th
                    colSpan={7}
                    style={{
                      textAlign: 'center',
                      backgroundColor: 'orange',
                      fontSize: 16
                    }}
                  >
                    Isolation
                  </th>
                </tr>
                <tr>
                  <th
                    rowSpan={2}
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    Actions
                  </th>
                  <th colSpan={6} style={{ textAlign: 'center' }}>
                    Departments
                  </th>
                </tr>
                <tr style={{ textAlign: 'center' }}>
                  <th>Mechanical</th>
                  <th>Electrical</th>
                  <th>Instrument</th>
                  <th>Production</th>
                </tr>
              </thead>
              <tbody>
                {isolationActions?.map((action: string) => (
                  <tr>
                    <td key={action}>{toNormalCase(action)}</td>
                    {isolationDepartments?.map(
                      (department: string, index: number) => (
                        <React.Fragment
                          key={`${action}-${index}-${department}`}
                        >
                          {renderTableData(
                            isolationEntries,
                            action,
                            department,
                            'isolation'
                          )}
                        </React.Fragment>
                      )
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {showDeIsolation && (
            <Table
              bordered
              style={{ border: '1px solid #000', width: '100%', fontSize: 12 }}
            >
              <thead>
                <tr>
                  <th
                    colSpan={7}
                    style={{
                      textAlign: 'center',
                      backgroundColor: 'orange',
                      fontSize: 16
                    }}
                  >
                    De Isolation
                  </th>
                </tr>
                <tr>
                  <th
                    rowSpan={2}
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    Actions
                  </th>
                  <th colSpan={6} style={{ textAlign: 'center' }}>
                    Departments
                  </th>
                </tr>
                <tr style={{ textAlign: 'center' }}>
                  <th>Mechanical</th>
                  <th>Electrical</th>
                  <th>Instrument</th>
                  <th>Production</th>
                </tr>
              </thead>
              <tbody>
                {isolationActions?.map((action: string) => (
                  <tr>
                    <td key={action}>{toNormalCase(action)}</td>
                    {isolationDepartments?.map(
                      (department: string, index: number) => (
                        <React.Fragment
                          key={`${action}-${index}-${department}`}
                        >
                          {renderTableData(
                            deIsolationEntries,
                            action,
                            department,
                            'deIsolation'
                          )}
                        </React.Fragment>
                      )
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {reIsolate && (
            <Table
              bordered
              style={{ border: '1px solid #000', width: '100%', fontSize: 12 }}
            >
              <thead>
                <tr>
                  <th
                    colSpan={7}
                    style={{
                      textAlign: 'center',
                      backgroundColor: 'orange',
                      fontSize: 16
                    }}
                  >
                    Re Isolation
                  </th>
                </tr>
                <tr>
                  <th
                    rowSpan={2}
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    Actions
                  </th>
                  <th colSpan={6} style={{ textAlign: 'center' }}>
                    Departments
                  </th>
                </tr>
                <tr style={{ textAlign: 'center' }}>
                  <th>Mechanical</th>
                  <th>Electrical</th>
                  <th>Instrument</th>
                  <th>Production</th>
                </tr>
              </thead>
              <tbody>
                {isolationActions?.map((action: string) => (
                  <tr>
                    <td key={action}>{toNormalCase(action)}</td>
                    {isolationDepartments?.map(
                      (department: string, index: number) => (
                        <React.Fragment
                          key={`${action}-${index}-${department}`}
                        >
                          {renderTableData(
                            reIsolationEntries,
                            action,
                            department,
                            'reIsolation'
                          )}
                        </React.Fragment>
                      )
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <Table
            bordered
            style={{ border: '1px solid #000', width: '100%', fontSize: 12 }}
          >
            <thead>
              <tr>
                <th
                  colSpan={7}
                  style={{
                    textAlign: 'center',
                    backgroundColor: 'orange',
                    fontSize: 16
                  }}
                >
                  Area Operator - Approve
                </th>
              </tr>
              <tr>
                <th
                  rowSpan={2}
                  style={{ textAlign: 'center', verticalAlign: 'middle' }}
                >
                  Locations
                </th>
                <th colSpan={6} style={{ textAlign: 'center' }}>
                  Departments
                </th>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <th>Mechanical</th>
                <th>Electrical</th>
                <th>Instrument</th>
                <th>Production</th>
                <th>HSE</th>
                <th>Admin Ware House</th>
              </tr>
            </thead>
            <tbody>
              {approveActions?.map((action: string) => (
                <tr>
                  <td key={action}>{toNormalCase(action)}</td>
                  {permitDepartments?.map(
                    (department: string, index: number) => (
                      <React.Fragment key={`${action}-${index}-${department}`}>
                        {renderTableData(
                          approveEntries,
                          action,
                          department,
                          'approve'
                        )}
                      </React.Fragment>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <SubmitModal
          isOpen={isopen}
          toggle={() => {
            setIsOpen(!isopen)
          }}
          refetch={() => null}
          onSubmit={() => onSubmit()}
          size="md"
          afterClose={() => {
            setIsOpen(false)
            setUpdate({})
          }}
          // submitModalContent={{title: 'some'}}

          submitModalContent={{
            title: (
              <>
                <p> Are you sure you want to Update ? </p>
                <Table bordered style={{ border: '1px solid #000' }}>
                  <tbody>
                    <tr>
                      <th>Designation</th>
                      <td>{update?.designation}</td>
                    </tr>
                    <tr>
                      <th>Flow</th>
                      <td>{toNormalCase(update?.stage)}</td>
                    </tr>
                    <tr>
                      <th>Action</th>
                      <td>{toNormalCase(update?.action)}</td>
                    </tr>
                    <tr>
                      <th>Type</th>
                      <td>{toNormalCase(update?.type)}</td>
                    </tr>
                    <tr>
                      <th>Department</th>
                      <td>{toNormalCase(update?.department)}</td>
                    </tr>
                    <tr>
                      <th>Update Access</th>
                      <td>{update?.changeTo}</td>
                    </tr>
                  </tbody>
                </Table>
                {/* ${update && update?.changeTo === 'Yes' ? 'Add' : 'Remove'} the access for the designation ${update && update?.designation} for the action ${update && update?.action} for the department ${update && update?.department}` */}
              </>
            )
          }}
        />
      </Row>
    </>
  )
}

const stages = [
  {
    name: 'createFlow',
    component: <AuthTable create />
  },
  {
    name: 'revalidate',
    component: <AuthTable hideIsolation />
  },

  {
    name: 'deIsolationAndClose',
    component: <AuthTable showDeIsolation hideIsolation />
  },
  {
    name: 'deIsolationForTest',
    component: <AuthTable showDeIsolation hideIsolation />
  },
  {
    name: 'reIsolation',
    component: <AuthTable reIsolate hideIsolation />
  },
  {
    name: 'longTermIsolation',
    component: <AuthTable showDeIsolation hideIsolation />
  },
  {
    name: 'closeFlow',
    component: <AuthTable showDeIsolation hideIsolation />
  },
  {
    name: 'changeInOwnership',
    component: <AuthTable hideIsolation />
  },
  {
    name: 'withOutIsolation',
    component: <AuthTable hideIsolation create />
  },
  {
    name: 'closeFlowWithOutIsolation',
    component: <AuthTable hideIsolation />
  }
]

function Authorities({ designation, data, fetchPermissions }: any) {
  const [stage, setStage] = useState(0)

  const renderNavItem = (tabNumber: number, label: any) => (
    <NavItem>
      <NavLink
        className={stage === tabNumber ? 'active' : ''}
        onClick={() => setStage(tabNumber)}
      >
        <b>{toNormalCase(label)}</b>
      </NavLink>
    </NavItem>
  )

  const renderTabPane = (
    id: number,
    children: any,
    stage: string,
    fetchPermissions: any
  ) => (
    <TabPane tabId={id}>
      <Row>
        <Col sm="12">
          <Spacer height={10} />
          {React.cloneElement(children, {
            stage,
            designation,
            data,
            id,
            fetchPermissions
          })}
        </Col>
      </Row>
    </TabPane>
  )

  return (
    <>
      <Nav justified pills>
        {stages?.map((stage: any, index: number) => (
          <React.Fragment key={stage?.name}>
            {renderNavItem(index, stage?.name)}
          </React.Fragment>
        ))}
      </Nav>
      <hr />
      <TabContent activeTab={stage} style={{ width: '100%', paddingRight: 10 }}>
        {stages?.map((stage: any, index: number) => (
          <React.Fragment key={`${index}-${stage?.title}`}>
            {renderTabPane(
              index,
              stage?.component,
              stage?.name,
              fetchPermissions
            )}
          </React.Fragment>
        ))}
      </TabContent>
    </>
  )
}

export default function Admin() {
  const [activeTab, setActiveTab] = useState(0)
  const [access, setAccess]: any = useState()

  const { accounts, instance } = useMsal()

  const fetchPermissions = async () => {
    try {
      await makeAuthenticatedRequest(
        adminePermitPermissions,
        'GET',
        null,
        accounts,
        instance
      ).then((res: any) => {
        setAccess(res?.data)
      })
    } catch (error) {
      console.info(error)
    }
  }

  useEffect(() => {
    if (!access) {
      fetchPermissions()
    }
  }, [access])

  const getData = (designation: any) =>
    access?.filter((i: any) => i.userDesignation === designation)[0]
      ?.permissions

  const designations = [
    {
      title: 'MINES MANAGER',
      data: getData('MINES MANAGER')
    },
    {
      title: 'INSTALLATION MANAGER',
      data: getData('INSTALLATION MANAGER')
    },
    {
      title: 'DEPUTY INSTALLATION MANAGER',
      data: getData('DEPUTY INSTALLATION MANAGER')
    },
    {
      title: 'PRODUCTION INCHARGE',
      data: getData('PRODUCTION INCHARGE')
    },
    {
      title: 'DEPUTY MANAGER-HSE',
      data: getData('DEPUTY MANAGER-HSE')
    },
    {
      title: 'FIRE OFFICER',
      data: getData('FIRE OFFICER')
    },
    {
      title: 'ADMIN WARE HOUSE',
      data: getData('ADMIN WARE HOUSE')
    },
    {
      title: 'MECHANICAL INCHARGE',
      data: getData('MECHANICAL INCHARGE')
    },
    {
      title: 'MECHANICAL ENGINEER',
      data: getData('MECHANICAL ENGINEER')
    },
    {
      title: 'ELECTRICAL ENGINEER',
      data: getData('ELECTRICAL ENGINEER')
    },
    {
      title: 'INSTRUMENT INCHARGE',
      data: getData('INSTRUMENT INCHARGE')
    },
    {
      title: 'PRODUCTION ENGINEER',
      data: getData('PRODUCTION ENGINEER')
    },
    {
      title: 'PANEL ENGINEER',
      data: getData('PANEL ENGINEER')
    },
    {
      title: 'FIELD ENGINEER',
      data: getData('FIELD ENGINEER')
    }
  ]

  const renderNavItem = (tabNumber: number, label: any) => (
    <NavItem
      style={{
        borderTop: tabNumber === 0 ? '1px solid #000' : 0,
        borderBottom: '1px solid #000'
      }}
    >
      <NavLink
        className={activeTab === tabNumber ? 'active' : ''}
        onClick={() => setActiveTab(tabNumber)}
        style={{ borderBottom: 0 }}
      >
        <b>{label}</b>
      </NavLink>
    </NavItem>
  )

  const renderTabPane = (
    id: number,
    children: any,
    designation: string,
    data: any,
    fetchPermissions: any
  ) => (
    <TabPane tabId={id}>
      <Row>
        <Col sm="12">
          <Spacer height={10} />
          {React.cloneElement(children, {
            designation,
            data,
            fetchPermissions
          })}
        </Col>
      </Row>
    </TabPane>
  )

  return (
    <ProtectedLayout>
      <Row style={{ height: '100%' }}>
        <Col xs="2">
          <Nav
            pills
            vertical
            style={{
              borderRight: '1px solid #000',
              borderLeft: '1px solid #000'
            }}
          >
            {designations?.map((designation: any, index: number) => (
              <React.Fragment key={designation?.title}>
                {renderNavItem(index, designation?.title)}
              </React.Fragment>
            ))}
          </Nav>
        </Col>
        <Col xs="10">
          <TabContent
            activeTab={activeTab}
            style={{ minHeight: '80vh', width: '100%', paddingRight: 10 }}
          >
            {designations?.map((designation: any, index: number) => (
              <React.Fragment key={`${index}-${designation?.title}`}>
                {renderTabPane(
                  index,
                  <Authorities />,
                  designation?.title,
                  designation?.data,
                  fetchPermissions
                )}
              </React.Fragment>
            ))}
          </TabContent>
        </Col>
      </Row>
    </ProtectedLayout>
  )
}
