import { Button, Row } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useContext, useState } from 'react'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { cioEndpoint } from '../../components/serverurl'
import React from 'react'
import { useAdminPermissions } from '../eptw/EditRisks'
import ViewPermit from '../eptw/ViewPermit'
import { RadioButtonGroup, yesNoOptions } from '../eptw/VerifyPermit'
import { PermissionsContext } from '../../App'

export default function VerifyCIO() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const { permissions } = useContext(PermissionsContext)

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const [submitModal, setSubmitModal]: any = useState(false)

  const [formData, setFormData]: any = useState({
    discussedJob: 'No',
    siteVisit: 'No'
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const submitValues: any = {
      consent: {
        ...formData
      },

      permitId: data?.permit?._id,
      consentId: data?.permit?.consent?._id
    }

    const response = await makeAuthenticatedRequest(
      `${cioEndpoint}/verify`,
      'PATCH',
      submitValues,
      accounts,
      instance
    )

    return response.status
  }

  const form = (
    <div>
      <RadioButtonGroup
        question="Have you discussed the job scope for this Work with new JP ?"
        name="discussedJob"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Equipment Preparation & Site visit carried out with the new JP ?"
        name="siteVisit"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
    </div>
  )

  return (
    <ProtectedLayout onBack="/eptw/cio/verify">
      <ViewPermit data={data} isLoading={isLoading} />
      {data?.permit?.status === 'cioInitiated' && permissions?.canVerifyCIO && (
        <>
          <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Button
              color="warning"
              style={{ marginTop: 10 }}
              onClick={() => setSubmitModal(true)}
            >
              Verify
            </Button>
          </Row>
          {submitModal && (
            <SubmitModalWithForm
              isOpen={submitModal}
              onClose={(val: any) => {
                setSubmitModal(false)
                val && navigate('/eptw/cio/verify')
              }}
              onSubmit={handleSubmit}
              form={form}
            />
          )}
        </>
      )}
    </ProtectedLayout>
  )
}
