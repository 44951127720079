import { useState } from 'react'
import {
  RiCheckboxCircleFill,
  RiErrorWarningFill,
  RiInformationFill
} from 'react-icons/ri'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Spinner
} from 'reactstrap'
import { renderRow } from '../pages/VerifyCM'

type SubmitModalType = {
  isOpen: boolean
  toggle: any
  onSubmit: Function
  refetch?: Function
  submitModalContent?: any
  size?: any
  afterClose?: Function
}

const INITIAL_STATE = 'notStarted'

export default function SubmitModal({
  isOpen,
  toggle,
  onSubmit,
  refetch = () => console.info('No Refetch Function provided'),
  submitModalContent,
  size = 'md',
  afterClose = () => {}
}: SubmitModalType) {
  const [submissionStatus, setSubmissionStatus]: any = useState(INITIAL_STATE)
  const [error, setError]: any = useState(null)

  const createPermit = async () => {
    try {
      setSubmissionStatus('started')

      await onSubmit()
      await refetch()

      setSubmissionStatus('finished')
    } catch (e: any) {
      setSubmissionStatus('failed')
      setError(e?.response?.data?.error)
    }
  }

  const isDisabled =
    submissionStatus === 'finished' || submissionStatus === 'started'

  const onClose = () => {
    setSubmissionStatus(INITIAL_STATE)
    setError(null)
    afterClose()
  }

  const renderIcon = () => {
    switch (submissionStatus) {
      case INITIAL_STATE:
        return <RiInformationFill color="skyBlue" size="60" />

      case 'started':
        return <Spinner style={{ color: 'skyBlue' }} />

      case 'finished':
        return <RiCheckboxCircleFill color="lightGreen" size="60" />

      case 'failed':
        return <RiErrorWarningFill color="#D2042D" size="60" />

      default:
        break
    }
  }

  const getText = () => {
    switch (submissionStatus) {
      case INITIAL_STATE:
        return submitModalContent?.title || 'Are you sure you want to submit'
      case 'started':
        return 'Submitting ...'
      case 'finished':
        return 'Successful'
      case 'failed':
        return error || 'Some Error Occured at our End'

      default:
        break
    }
  }

  const renderContent = () => {
    return (
      <div>
        <div
          style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}
        >
          {renderIcon()}
          <p style={{ marginLeft: 10 }}>
            {getText()}
            {/* {submissionStatus === INITIAL_STATE && title}
            {submissionStatus === 'failed' && (error || 'Some Error Occured at our End')}
            {submissionStatus === 'finished' && 'Successfull'} */}
          </p>
        </div>
        {submitModalContent?.details && (
          <>
            <h6>
              <b>PERMIT DETAILS</b>
            </h6>
            {Object.keys(submitModalContent?.details).map((key: any) =>
              renderRow(key, submitModalContent?.details[key])
            )}
          </>
        )}
      </div>
    )
  }

  // const renderBody = () => {
  //   switch (submissionStatus) {
  //     case 'notStarted':
  //       return renderContent()
  //     case 'started':
  //       return <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
  //         <Spinner style={{ marginRight: 10 }} />
  //         Submitting ...
  //       </div>
  //     case 'finished':
  //       return <div>
  //         <p>
  //           <MdCloudDone color="green" size="60" style={{ marginRight: 10 }} />
  //           Form submission successful
  //         </p>
  //       </div>
  //     case 'failed':
  //       return <p>
  //         <MdSmsFailed color="#DC4C64" size="60" style={{ marginRight: 10 }} />
  //         Form submission failed
  //       </p>

  //     default:
  //       break;
  //   }
  // }

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        onClosed={() => onClose()}
        size={size}
      >
        <ModalHeader>Submission Status</ModalHeader>
        <ModalBody>{renderContent()}</ModalBody>
        <ModalFooter style={{ justifyContent: 'center' }}>
          {submissionStatus !== 'finished' && submissionStatus !== 'failed' && (
            <Button
              color={isDisabled ? '' : 'warning'}
              onClick={(e) => {
                e?.preventDefault()
                createPermit()
              }}
              disabled={isDisabled}
            >
              Submit
            </Button>
          )}
          <Button color="warning" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
