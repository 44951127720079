import { Table } from 'reactstrap'
import { ColoredSubHeader } from '../previews/ViewExcavationPermit'

export const renderPbuTable = (list: any, headers: any) => {
  return (
    list?.length > 0 && (
      <Table bordered striped style={{ borderColor: '#000' }}>
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            <th>Isolation Point</th>
            {headers.map((header: any) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {list.map((item: any) => (
            <tr key={item._id}>
              <th>
                <p>{item.isolationPoint}</p>
              </th>
              {headers.map((header: any) => (
                <td key={header} />
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  )
}

export default function BPUTable({ rowStyle, isolations }: any) {
  const continuous = [
    '7AM',
    '8AM',
    '9AM',
    '10AM',
    '11AM',
    '12PM',
    '1PM',
    '2PM',
    '3PM',
    '4PM',
    '5PM'
  ]
  const intermittent = ['9AM', '12PM', '3PM', '5PM']

  const getList = (type: any) =>
    isolations?.filter(
      (i: any) => i?.pressureBuildup === 'Yes' && i?.readings === type
    )

  const continuousList = getList('Continous')
  const intermittentList = getList('Intermittent')
  const oneTimeList = getList('One Time')

  return (
    <>
      {(continuousList?.length > 0 ||
        intermittentList?.length > 0 ||
        oneTimeList?.length > 0) && (
        <ColoredSubHeader rowStyle={rowStyle} text="PBU" />
      )}
      {renderPbuTable(continuousList, continuous)}
      {renderPbuTable(intermittentList, intermittent)}
      {oneTimeList?.length > 0 && (
        <Table bordered striped style={{ borderColor: '#000' }}>
          <thead>
            <tr
              style={{
                backgroundColor: 'orange',
                WebkitPrintColorAdjust: 'exact'
              }}
            >
              <th>Isolation Point</th>
              <th>6AM</th>
            </tr>
          </thead>
          <tbody>
            {oneTimeList.map((item: any) => (
              <tr key={item._id}>
                <th>
                  <p>{item.isolationPoint}</p>
                </th>
                <td />
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  )
}
