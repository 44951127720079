import { Col, Row } from "reactstrap";
import { Box, renderData } from "../workManagement/PmTree";
import { TravelRequest } from "./types";
import toNormalCase from "../../components/lib/toNormalCase";

export default function ViewTravelRequest({ data }: { data: TravelRequest }) {
  const crewData: any = {
    crewName: data?.crewName,
    employeeId: data?.employeeId,
    designation: data?.designation,
    bookingForOthers: data?.notForMe ? "Yes" : "No",
  };

  const otherCrewData: any = {
    crewName: data?.other?.crewName,
    employeeId: data?.other?.employeeId,
    designation: data?.other?.designation,
  };

  const passportDetails: any = {
    firstName: data?.passport?.firstName,
    middleName: data?.passport?.middleName,
    lastName: data?.passport?.lastName,
    issuePlace: data?.passport?.issuePlace,
    dob: data?.passport?.dob,
    issue: data?.passport?.issue,
    expiry: data?.passport?.expiry,
    number: data?.passport?.number,
  };

  const destination1: any = {
    travelDate: data?.destination1?.travelDate,
    mode: data?.destination1?.mode,
    origin: data?.destination1?.origin,
    destination: data?.destination1?.destination,
    suggestedMode: data?.destination1?.suggestedMode,
    suggestedRouteOrigin: data?.destination1?.suggestedRouteOrigin,
    suggestedRouteDestination: data?.destination1?.suggestedRouteDestination,
  };

  const destination2: any = {
    travelDate: data?.destination2?.travelDate,
    mode: data?.destination2?.mode,
    origin: data?.destination2?.origin,
    destination: data?.destination2?.destination,
    suggestedMode: data?.destination2?.suggestedMode,
    suggestedRouteOrigin: data?.destination2?.suggestedRouteOrigin,
    suggestedRouteDestination: data?.destination2?.suggestedRouteDestination,
  };

  const destination3: any = {
    travelDate: data?.destination3?.travelDate,
    mode: data?.destination3?.mode,
    origin: data?.destination3?.origin,
    destination: data?.destination3?.destination,
    suggestedMode: data?.destination3?.suggestedMode,
    suggestedRouteOrigin: data?.destination3?.suggestedRouteOrigin,
    suggestedRouteDestination: data?.destination3?.suggestedRouteDestination,
  };

  const certificates: any = {
    yellowFeverVaccination: data?.certificates?.yellowFeverVaccination,
    bosiet: data?.certificates?.bosiet,
    malaria: data?.certificates?.malaria,
  };

  const emergency: any = {
    contactRelation: data?.emergency?.contactRelation,
    contactName: data?.emergency?.contactName,
    medicalHistory: data?.emergency?.medicalHistory,
    physicialContact: data?.emergency?.physicialContact,
    physicialContactNumber: data?.emergency?.physicialContactNumber,
  };

  return (
    <div>
      <Row>
        <Col xs={12} md={6}>
          <Box heading="Crew Details">
            <>
              {Object.keys(crewData)?.map((k) =>
                renderData(toNormalCase(k), crewData[k])
              )}
            </>
          </Box>
          {data?.notForMe && (
            <Box heading="Other Crew Details">
              <>
                {Object.keys(otherCrewData)?.map((k) =>
                  renderData(toNormalCase(k), otherCrewData[k])
                )}
              </>
            </Box>
          )}
          {passportDetails && (
            <Box heading="Passport Details">
              <>
                {Object.keys(passportDetails)?.map((k) =>
                  renderData(toNormalCase(k), passportDetails[k])
                )}
              </>
            </Box>
          )}
          {emergency && (
            <Box heading="Emergency Contact Details">
              <>
                {Object.keys(emergency)?.map((k) =>
                  renderData(toNormalCase(k), emergency[k])
                )}
              </>
            </Box>
          )}
          {data?.certificates && (
            <Box heading="Certificate Details">
              <>
                {Object.keys(certificates)?.map((k) =>
                  renderData(toNormalCase(k), certificates[k])
                )}
              </>
            </Box>
          )}
        </Col>
        <Col xs={12} md={6}>
          {data?.destination1 && (
            <Box heading="Destination One Details">
              <>
                {Object.keys(destination1)?.map((k) =>
                  renderData(toNormalCase(k), destination1[k])
                )}
              </>
            </Box>
          )}
          {data?.destination2 && (
            <Box heading="Destination Two Details">
              <>
                {Object.keys(destination2)?.map((k) =>
                  renderData(toNormalCase(k), destination2[k])
                )}
              </>
            </Box>
          )}
          {data?.designation && (
            <Box heading="Destination Three Details">
              <>
                {Object.keys(destination3)?.map((k) =>
                  renderData(toNormalCase(k), destination3[k])
                )}
              </>
            </Box>
          )}
        </Col>
      </Row>
    </div>
  );
}
