import { Button, Input, Row } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useState } from 'react'

import { useAdminPermissions } from './EditRisks'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import ViewPermit from './ViewPermit'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { newPermitEndpoint } from '../../components/serverurl'

import React from 'react'
import {
  doneRequiredOptions,
  RadioButtonGroup,
  yesNoOptions
} from './VerifyPermit'
import CustomLabel from '../../components/InputFields/CustomLabel'

export default function ApprovePermit() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const [submitModal, setSubmitModal]: any = useState(false)

  const [formData, setFormData]: any = useState({
    h2S: '',
    o2: '',
    cO: '',
    lEL: '',
    isSafe: 'No',
    hydroCarbonsPresent: 'No',
    hazardsoursMaterials: 'No',
    properlyDrained: 'Not Required',
    properlySteamed: 'Not Required',
    waterFlushed: 'Not Required',
    oilCheck: 'Not Required',
    areaChecked: 'Not Required'
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const submitValues = {
      ...formData,

      permitId: data?.permit?._id,
      consentId: data?.permit?.consent?._id
    }
    const response = await makeAuthenticatedRequest(
      `${newPermitEndpoint}/approve`,
      'PATCH',
      submitValues,
      accounts,
      instance
    )

    return response.status
  }

  const form = (
    <div>
      <div className="form-group">
        <CustomLabel label="H2S" />
        <Input type="text" name="h2S" onChange={handleInputChange} />
      </div>
      <div className="form-group">
        <CustomLabel label="O2" />
        <Input type="text" name="o2" onChange={handleInputChange} />
      </div>
      <div className="form-group">
        <CustomLabel label="CO" />
        <Input type="text" name="cO" onChange={handleInputChange} />
      </div>
      <div className="form-group">
        <CustomLabel label="LEL" />
        <Input type="text" name="lEL" onChange={handleInputChange} />
      </div>
      <RadioButtonGroup
        question="Is it safe for Job Performer to proceed the Job ?"
        name="isSafe"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Is the area free from Hydrocarbons and acceptable LEL ?"
        name="hydroCarbonsPresent"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Is the area clear from Hazardous materials and Dropped Object and Bad weather Conditions ?"
        name="hazardsoursMaterials"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Equipment properly drained ?"
        name="properlyDrained"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Equipment properly steamed / purged ?"
        name="properlySteamed"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Equipment water flushed ?"
        name="waterFlushed"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Checked for oil / gas trapped behind lining in equipment  ?"
        name="oilCheck"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Surrounding area checked ?"
        name="areaChecked"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
    </div>
  )

  return (
    <ProtectedLayout onBack="/eptw/newPermit/approve">
      <ViewPermit data={data} isLoading={isLoading} />
      {data?.permit?.status === 'issued' && (
        <>
          <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Button color="warning" onClick={() => setSubmitModal(true)}>
              Approve Permit
            </Button>
          </Row>
          {submitModal && (
            <SubmitModalWithForm
              isOpen={submitModal}
              onClose={(val: any) => {
                setSubmitModal(false)
                val && navigate('/eptw/newPermit/approve')
              }}
              onSubmit={handleSubmit}
              form={form}
            />
          )}
        </>
      )}
    </ProtectedLayout>
  )
}
