import moment from 'moment'
import { Row, Spinner, Table } from 'reactstrap'
import { useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { stock } from '../../components/serverurl'
import { Link } from '../workManagement/PmTree'
import { prStatuses } from '../../components/constants/woStatuses'

export const TOTable = ({
  data,
  isLoading,
  isRefetching,
  page,
  setPage,
  url,
  cm
}: {
  data: any
  isLoading: boolean
  isRefetching: boolean
  page: any
  setPage: any
  url: string
  po: boolean
  cm: any
}) => {
  const navigate = useNavigate()
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Number</th>
            <th>Store Keeper</th>
            <th>StoreKeeperId</th>
            <th>Unit</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <>
            {isLoading || isRefetching ? (
              <tr>
                <td colSpan={12}>
                  <Row
                    style={{
                      height: 500,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Spinner />
                  </Row>
                </td>
              </tr>
            ) : (
              data?.length > 0 &&
              data?.map((w: any, index: number) => (
                <tr key={w?._id}>
                  <td>{incrementby1(page * 20 + index)}</td>
                  <td>
                    <Link onClick={() => navigate(`${url}/${w?._id}`)}>
                      {cm ? w?.number?.replace('TO', 'CM') : w?.number}
                    </Link>
                  </td>
                  <td>{w?.storeKeeper}</td>
                  <td>{w?.storeKeeperId}</td>
                  <td>{w?.unit}</td>
                  <td>{prStatuses[w?.status] || w?.status}</td>
                </tr>
              ))
            )}
          </>
        </tbody>
      </Table>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Purchase Requests Found
          </p>
        </Row>
      )}
    </>
  )
}

export default function CMList({
  onBack,
  url,
  title,
  status,
  po,
  cm
}: {
  onBack: string
  url: any
  title: string
  status: string
  po: boolean
  cm?: boolean
}) {
  const { accounts, instance } = useMsal()
  const [page, setPage] = useState(0)

  const fetchtos = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/inventory/cm?status=${status}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, isRefetching }: any = useQuery('tos', fetchtos)

  const tos = data?.tos

  return (
    <ProtectedLayout onBack={onBack} title={title}>
      <Row style={{ margin: 10 }}>
        <TOTable
          data={tos}
          page={page}
          isLoading={isLoading}
          isRefetching={isRefetching}
          setPage={setPage}
          url={url}
          po={false}
          cm={cm}
        />
      </Row>
    </ProtectedLayout>
  )
}
