import { Field, Form } from "react-final-form";
import { Row, Col, Input, Button, ModalFooter } from "reactstrap";
import { useContext, useState } from "react";

import InformationCardModal from "../../components/Modals/InformationCardModal";
import Spacer from "../../components/Spacer";
import MobileLayout from "../../components/Layout/MobileLayout";
import { UserContext } from "../../App";
import { InputType } from "zlib";
import { getToday } from "../../components/InputFields/DateTime";
import ToggleSwitch from "../../components/ToggleSwitch";
import CustomLabel from "../../components/InputFields/CustomLabel";
import DropDownFetch from "../../components/InputFields/DropDownFetch";
import { tagsAPI } from "../../components/serverurl";

const textArea = {
  border: "1px solid #CCC",
  borderRadius: 5,
  width: "100%",
  height: 25,
  paddingLeft: 5,
  backgroundColor: "#DCDCDC",
  fontWeight: 500,
  color: "#000",
  fontSize: 12,
};

const formLabelStyles = {
  fontWeight: 600,
  fontSize: 12,
  color: "#000",
  display: "flex",
  margin: 0,
  marginBottom: 4,
  marginTop: 10,
};

function LabeledDateTimeInput({
  label,
  name,
  isDisabled = false,
  occupy = 6,
  startDate,
  time = false,
}: any) {
  const type = time ? "datetime-local" : "date";

  return (
    <Col md={occupy} key={`${name}*${label}`}>
      {label && <p style={formLabelStyles}>{label}</p>}
      <Field name={name} style={textArea}>
        {({ input, meta }) => (
          <div key={`${name}^${label}`}>
            <input
              {...input}
              type={type}
              style={textArea}
              disabled={isDisabled}
              value={input.value}
              step="1"
              min={getToday()}
            />
            {/* <input type="date" max={startDate} step="1" style={textArea}></input> */}
            {meta.error && meta.touched && (
              <span style={{ color: "red", fontWeight: 500 }}>
                {meta.error}
              </span>
            )}
          </div>
        )}
      </Field>
    </Col>
  );
}

function LabeledTextInput({
  label,
  name,
  isDisabled = false,
  occupy = 6,
  value,
  height = 25,
}: InputType | any) {
  return (
    <Col md={occupy} key={`${name}*${label}`}>
      {label && <p style={formLabelStyles}>{label}</p>}
      <Field name={name} style={textArea}>
        {({ input, meta }) => (
          <div key={`${name}^${label}`}>
            <input
              {...input}
              type="text"
              style={{ ...textArea, height: height || 40 }}
              disabled={isDisabled}
              value={value || input.value}
            />
            {meta.error && meta.touched && (
              <span style={{ color: "red", fontWeight: 400, fontSize: 10 }}>
                {meta.error}
              </span>
            )}
          </div>
        )}
      </Field>
    </Col>
  );
}

const LabeledDropdown = ({
  name,
  options,
  label,
  occupy = 4,
}: {
  name: string;
  options: string[];
  label?: string;
  occupy: string | number;
}) => {
  return (
    <Col xs={`${occupy}`}>
      {label && <p style={formLabelStyles}>{label}</p>}
      <Field
        name={name}
        component="select"
        placeholder="s"
        style={{
          width: "-webkit-fill-available",
          height: 25,
          borderRadius: 5,
          fontSize: 12,
          backgroundColor: "#DCDCDC",
        }}
      >
        <option value="" disabled selected>
          Select
        </option>
        {options?.map((o: any) => (
          <option>{o}</option>
        ))}
      </Field>
    </Col>
  );
};

function CheckBox({ name, label, value }: any) {
  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: 5,
        borderRadius: 5,
      }}
    >
      <Col xs="8" style={{ overflowX: "scroll" }}>
        <p
          style={{
            margin: 0,
            fontSize: 10,
            marginRight: 30,
          }}
        >
          {label}
        </p>
      </Col>
      <Col xs="4" style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex" }}>
          <Field
            defaultValue={"SAFE"}
            name={name}
            component="input"
            type="radio"
            value={"SAFE"}
            style={{ backgroundColor: "#000" }}
          />
          <span style={{ margin: "0px 0px 0px 5px", fontSize: 10 }}>SAFE</span>
        </div>
        <div style={{ display: "flex", marginLeft: 5 }}>
          <Field
            defaultValue={"UN SAFE"}
            name={name}
            component="input"
            type="radio"
            value={"UN SAFE"}
            style={{ backgroundColor: "#000" }}
          />
          <span
            style={{
              margin: "0px 0px 0px 5px",
              fontSize: 10,
              whiteSpace: "nowrap",
            }}
          >
            UN SAFE
          </span>
        </div>
      </Col>
    </Row>
  );
}

export default function CreateTSHEMobile() {
  const { userData } = useContext(UserContext);

  const [invert, setInvert] = useState(true);
  const [equipment, setEquipment]: any = useState();
  const [modal, setModal]: any = useState();
  const [equipmentDescription, setEquipmentDescription]: any = useState();
  const [status, setStatus]: any = useState("");

  function Section({ name, img, children }: any) {
    return (
      <>
        <Spacer height={4} />
        <Row>
          <Col xs="12">
            <div
              style={{
                backgroundColor: invert ? darkBlue : darkOrange,
                border: `2px solid ${invert ? lightBlue : darkOrange}`,
                padding: 4,
                borderRadius: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: 4,
                  paddingBottom: 4,
                }}
              >
                <div style={{ display: "flex" }}>
                  <img
                    src={img}
                    alt={name}
                    style={{
                      height: 20,
                      width: "auto",
                      paddingLeft: 5,
                    }}
                  />
                  <p
                    style={{
                      margin: 0,
                      fontSize: 12,
                      marginBottom: 0,
                      marginLeft: 10,
                    }}
                  >
                    <b>{name}</b>
                  </p>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: invert ? lightBlue : lightOrange,
                  width: "inherit",
                  borderRadius: 2,
                }}
              >
                {children}
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }

  const lightBlue = "#d0ebff";
  const darkBlue = "#2c9aff";
  const lightOrange = "#ffdaca";
  const darkOrange = "#ff7538";

  const textArea = {
    border: `1px solid ${invert ? darkBlue : darkOrange}`,
    borderRadius: 5,
    height: 40,
    paddingLeft: 5,
  };

  const sty = `body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }`;

  return (
    <MobileLayout title="Create New SHE Card" onBack="/firs/sheCard">
      <style>{sty}</style>
      <Row style={{ margin: 0, overflowX: "hidden" }}>
        <Form
          onSubmit={(values: any) => {}}
          render={({ handleSubmit, form, hasValidationErrors, values }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></Col>
              </Row>
              <div>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: invert ? lightBlue : lightOrange,
                      justifyContent: "space-between",
                      padding: 4,
                      borderRadius: 10,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: invert ? darkBlue : lightOrange,
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                        textAlign: "center",
                        padding: 10,
                        color: invert ? lightBlue : darkOrange,
                        cursor: "pointer",
                        width: "50%",
                      }}
                      onClick={() => setInvert(true)}
                    >
                      <span style={{ textAlign: "center", fontSize: 14 }}>
                        Behaviour & Conditions
                      </span>
                    </div>
                    <div
                      style={{
                        backgroundColor: invert ? lightBlue : darkOrange,
                        color: invert ? "#2c9aff" : "#ffdaca",
                        textAlign: "center",
                        cursor: "pointer",
                        padding: 10,
                        borderTopRightRadius: 10,
                        borderBottomRightRadius: 10,
                        width: "50%",
                      }}
                      onClick={() => setInvert(false)}
                    >
                      <span style={{ textAlign: "left", fontSize: 14 }}>
                        Process & Safety
                      </span>
                    </div>
                  </div>
                </Row>
                <Row>
                  <LabeledTextInput
                    label="APPLICANT NAME"
                    name="applicantName"
                    occupy={6}
                    value={userData?.givenName}
                    isDisabled
                  />
                  <LabeledDateTimeInput
                    label="DATE & TIME"
                    name="date"
                    occupy={6}
                  />
                </Row>
                <Row>
                  <Col xs="12">
                    <LabeledDropdown
                      label="DEPARTMENT"
                      name="department"
                      occupy={12}
                      options={[
                        "Production",
                        "HSE",
                        "Admin Ware House",
                        "Mechanical",
                        "Electrical",
                        "Instrument",
                      ]}
                    />
                  </Col>
                  <Col xs="12">
                    <LabeledDropdown
                      label="WHERE DID IT HAPPEN ?"
                      name="happendAt"
                      occupy={12}
                      options={[
                        "Mechanical Workshop",
                        "Fire Station",
                        "Sub Station",
                        "Control Room",
                        "Process Area",
                        "Admin Ware House",
                      ]}
                    />
                  </Col>
                  <Col xs="12">
                    <LabeledDropdown
                      label="BOOTS ON DECK"
                      name="boots"
                      occupy={12}
                      options={["Yes", "No"]}
                    />
                  </Col>
                </Row>
                <Spacer height={10} />
                <Row>
                  <Col xs="12">
                    <p style={formLabelStyles}>EQUIPMENT / TAG NUMBER</p>
                    <DropDownFetch
                      isMulti={false}
                      setDescription={setEquipmentDescription}
                      name="equipment"
                      value={equipment}
                      setValue={setEquipment}
                      url={`${tagsAPI}`}
                    />
                  </Col>
                  <Col>
                    <p style={formLabelStyles}>DESCRIPTION</p>
                    <LabeledTextInput
                      disabled
                      occupy={8}
                      value={equipmentDescription}
                      name="equipmentDescription"
                    />
                  </Col>
                </Row>
                <Spacer height={10} />
                <Row>
                  <Col xs="12">
                    <div
                      style={{
                        backgroundColor: invert ? lightBlue : lightOrange,
                        padding: 4,
                        borderRadius: 10,
                        width: "inherit",
                      }}
                    >
                      <p style={{ margin: 0, fontSize: 12 }}>
                        <b>DESCRIBE YOUR OBSERVATION : </b>
                      </p>
                      <Col xs="12" style={{ display: "flex", marginTop: 4 }}>
                        <Field name="describe">
                          {({ input, meta }) => (
                            <div style={{ width: "inherit" }}>
                              <Input
                                {...input}
                                type="textarea"
                                style={{
                                  ...textArea,
                                  backgroundColor: invert
                                    ? lightBlue
                                    : lightOrange,
                                  height: 40,
                                  width: "inherit",
                                }}
                              />
                              {meta.error && meta.touched && (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: 400,
                                    fontSize: 10,
                                  }}
                                >
                                  {meta.error}
                                </span>
                              )}
                            </div>
                          )}
                        </Field>
                      </Col>
                    </div>
                  </Col>
                </Row>
                <Spacer height={10} />
                <Row>
                  <Col xs="12">
                    <div
                      style={{
                        backgroundColor: invert ? lightBlue : lightOrange,
                        padding: 10,
                        borderRadius: 10,
                        width: "inherit",
                      }}
                    >
                      <p style={{ margin: 0, fontSize: 12 }}>
                        <b>
                          DESCRIBE YOUR ACTION / DISCUSSION / INTERVENTION :{" "}
                        </b>
                      </p>
                      <Col xs="12" style={{ display: "flex", marginTop: 4 }}>
                        <Field name="describeAction">
                          {({ input, meta }) => (
                            <div style={{ width: "inherit" }}>
                              <Input
                                {...input}
                                type="textarea"
                                style={{
                                  ...textArea,
                                  backgroundColor: invert
                                    ? lightBlue
                                    : lightOrange,
                                  height: 40,
                                  width: "inherit",
                                }}
                              />
                              {meta.error && meta.touched && (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: 400,
                                    fontSize: 10,
                                  }}
                                >
                                  {meta.error}
                                </span>
                              )}
                            </div>
                          )}
                        </Field>
                      </Col>
                    </div>
                  </Col>
                </Row>
                {invert && (
                  <>
                    <Section name="PPE" img={require(".././../images/PPE.png")}>
                      <CheckBox
                        value={values?.["ppeWorn"]}
                        name="ppeWorn"
                        label="PPE Worn"
                      />
                      <CheckBox
                        name="meetsJob"
                        value={values?.["meetsJob"]}
                        label="Me../ets Job Requirement"
                      />

                      <CheckBox
                        value={values?.["wornRight"]}
                        name="wornRight"
                        label="Worn Correctly"
                      />
                      <CheckBox
                        name="acceptable"
                        value={values?.["acceptable"]}
                        label="Acceptable Condition"
                      />
                    </Section>
                    <Section
                      name="TOOLS / EQUIPMENT"
                      img={require("../../images/Tools.png")}
                    >
                      <CheckBox
                        value={values?.["tools"]}
                        name="tools"
                        label="Tools / Equipment"
                      />
                      <CheckBox
                        name="safeTools"
                        value={values?.["safeTools"]}
                        label="Safe tools, equipment, material"
                      />
                      <CheckBox
                        name="properGuards"
                        value={values?.["properGuards"]}
                        label="Proper guards, barriers"
                      />
                      <CheckBox name="usedCorrectly" label="Used correctly" />
                    </Section>
                    value={values?.["ion"]}
                    <Section
                      name="PEOPLE"
                      img={require("./../../images/People.png")}
                    >
                      <CheckBox
                        name="exertion"
                        value={values?.["exertion"]}
                        label="Exertion : Pushing / Pulling / Lifting / Reaching"
                      />
                      <CheckBox
                        name="extremeTemperature"
                        value={values?.["extremeTemperature"]}
                        label="Extreme Temperature : Pushing / Pulling"
                      />
                      <CheckBox
                        name="risk"
                        value={values?.["risk"]}
                        label="Risk to be struck by objects"
                      />
                      <CheckBox name="training" label="Training" />
                    </Section>
                    value={values?.["ion"]}
                    <Section
                      name="PROCEDURE"
                      img={require("./../../images/Process.png")}
                    >
                      <CheckBox
                        name="established"
                        value={values?.["established"]}
                        label="Established and Understood"
                      />
                      <CheckBox
                        name="employee"
                        value={values?.["employee"]}
                        label="Employee authorized to operate"
                      />
                      <CheckBox
                        name="maintained"
                        value={values?.["maintained"]}
                        label="Maintained and followed"
                      />
                      <CheckBox name="adequate" label="Adequate for task" />
                    </Section>
                    value={values?.["ion"]}
                    <Section
                      name="HOUSE KEEPING"
                      img={require("./../../images/Housekeeping.png")}
                    >
                      <CheckBox
                        name="clearArea"
                        value={values?.["clearArea"]}
                        label="Area is clear of obstructions"
                      />
                      <CheckBox
                        name="areaPurpose"
                        value={values?.["areaPurpose"]}
                        label="Area is used for intended purpose"
                      />
                      <CheckBox
                        name="material"
                        value={values?.["material"]}
                        label="Material stored in safe manner"
                      />
                      <CheckBox
                        name="disposalProcedure"
                        value={values?.["disposalProcedure"]}
                        label="Proper disposal procedure followed"
                      />
                    </Section>
                    <Section
                      name="CHEMICALS"
                      img={require("./../../images/Chemicals.png")}
                    >
                      <CheckBox
                        value={values?.["msds"]}
                        name="msds"
                        label="MSDS Available"
                      />
                      <CheckBox
                        name="storedProperly"
                        value={values?.["storedProperly"]}
                        label="Chemicals are stored properly"
                      />
                      <CheckBox
                        name="ppe"
                        value={values?.["ppe"]}
                        label="Correct PPE worn around chemicals"
                      />
                    </Section>
                    <Section
                      name="ENVIRONMENT"
                      img={require("./../../images/Environment.png")}
                    >
                      <CheckBox
                        name="accidental"
                        value={values?.["accidental"]}
                        label="Accidental spills during oils transfer operations, equipment failure, or vessel incidents"
                      />
                      <CheckBox
                        name="chemicalUsage"
                        value={values?.["chemicalUsage"]}
                        label="Chemical usage and discharge"
                      />
                      <CheckBox
                        value={values?.["airEmissions"]}
                        name="airEmissions"
                        label="Air Emissions"
                      />
                      <CheckBox
                        value={values?.["noise"]}
                        name="noise"
                        label="Noise and Vibrations"
                      />
                      <CheckBox
                        name="solid"
                        value={values?.["solid"]}
                        label="Solid waste, hazardous waste and sewage"
                      />
                    </Section>
                    <Section
                      name="REPUTATION"
                      img={require("./../../images/Reputation.png")}
                    >
                      <CheckBox
                        name="unethical"
                        value={values?.["unethical"]}
                        label="Unethical behaviour or misconduct"
                      />
                      <CheckBox
                        name="techAdvancement"
                        value={values?.["techAdvancement"]}
                        label="Technological advancement in staying ahead of industry trends"
                      />
                    </Section>
                  </>
                )}
                {!invert && (
                  <>
                    <Section
                      name="PROCESS SAFETY"
                      img={require("./../../images/Process_Safety.png")}
                    >
                      <CheckBox
                        name="hazardous"
                        value={values?.["hazardous"]}
                        label="Information regarding the highly hazardous chemical in process"
                      />
                      <CheckBox
                        value={values?.["operating"]}
                        name="operating"
                        label="Operating Procedure"
                      />
                      <CheckBox
                        name="procedure"
                        value={values?.["procedure"]}
                        label="Procedure not followed"
                      />
                      <CheckBox
                        name="operationDeviate"
                        value={values?.["operationDeviate"]}
                        label="Operation deviate from current practices"
                      />
                      <CheckBox
                        name="operatingLimits"
                        value={values?.["operatingLimits"]}
                        label="Safe operating limitations"
                      />
                      <CheckBox
                        name="information"
                        value={values?.["information"]}
                        label="Information readily available to employees involved"
                      />
                      <CheckBox
                        value={values?.["safety"]}
                        name="safety"
                        label="Safety Lock / Interlock"
                      />
                      <CheckBox
                        name="process"
                        value={values?.["process"]}
                        label="Process Drawings (P&ID, PFD) with latest Revision"
                      />
                    </Section>
                    <Section
                      name="INTEGRITY"
                      img={require("./../../images/Integrity.png")}
                    >
                      <CheckBox
                        name="equipmentConstruction"
                        value={values?.["equipmentConstruction"]}
                        label="Equipment and Construction accordancw with design specifications"
                      />
                      <CheckBox
                        name="properSafety"
                        value={values?.["properSafety"]}
                        label="Proper safety, maintenance, operating and emergency procedures"
                      />
                      <CheckBox
                        name="pipingSystems"
                        value={values?.["pipingSystems"]}
                        label="Piping systems (including components, values"
                      />
                      <CheckBox
                        name="pressureVessels"
                        value={values?.["pressureVessels"]}
                        label="Presuure vessels and storage tanks"
                      />
                      <CheckBox
                        name="relief"
                        value={values?.["relief"]}
                        label="Relief and vent systems and storage tanks"
                      />
                      <CheckBox
                        name="controlsI"
                        value={values?.["controlsI"]}
                        label="Controls (Monitoring devices, alarms, sensors, interlocks)"
                      />
                      <CheckBox
                        name="emergencyShut"
                        value={values?.["emergencyShut"]}
                        label="Emergency shutdown systems"
                      />
                      <CheckBox
                        name="temp"
                        value={values?.["temp"]}
                        label="Temporary Hoses and connections"
                      />
                      <CheckBox
                        name="weep"
                        value={values?.["weep"]}
                        label="Weep and leak in connections and Tubing"
                      />
                      <CheckBox
                        name="equipmentSupport"
                        value={values?.["equipmentSupport"]}
                        label="Equipment Support"
                      />
                    </Section>
                    <Section
                      name="TRAINING"
                      img={require("./../../images/Training.png")}
                    >
                      <CheckBox
                        name="familiar"
                        value={values?.["familiar"]}
                        label="Familiar with facility rules"
                      />
                      <CheckBox
                        name="trainingEducation"
                        value={values?.["trainingEducation"]}
                        label="Training and Education Programs"
                      />
                      <CheckBox
                        name="operatorTraining"
                        value={values?.["operatorTraining"]}
                        label="Operator Training"
                      />
                      <CheckBox
                        name="contractEmployees"
                        value={values?.["contractEmployees"]}
                        label="Contract employees on or near covered process involved in maintainence"
                      />
                      <CheckBox
                        name="informatonProvide"
                        value={values?.["informatonProvide"]}
                        label="Provide information and train their employees how to safely perform their jobs"
                      />
                      <CheckBox
                        name="controls"
                        value={values?.["controls"]}
                        label="Controls (Monitoring deveices, alarms, sensors, interlocks"
                      />
                      <CheckBox
                        name="operatingProcedures"
                        value={values?.["operatingProcedures"]}
                        label="Written operating procedures and tasks with clear instructions"
                      />
                    </Section>
                    <Section
                      name="PROCESS HAZARD"
                      img={require("./../../images/Process_Hazard.png")}
                    >
                      <CheckBox
                        name="failureMode"
                        value={values?.["failureMode"]}
                        label="Failure mode and efects analysis (FMEA)"
                      />
                      <CheckBox
                        name="hazardOperability"
                        value={values?.["hazardOperability"]}
                        label="Hazard and operability study (HAZOP)"
                      />
                      <CheckBox
                        value={values?.["checklist"]}
                        name="checklist"
                        label="What if / Checklist"
                      />
                      <CheckBox
                        name="potential"
                        value={values?.["potential"]}
                        label="Potential process hazards"
                      />
                      <CheckBox
                        name="workspaceAnalysis"
                        value={values?.["workspaceAnalysis"]}
                        label="Workspace Analysis"
                      />
                      <CheckBox
                        name="modifications"
                        value={values?.["modifications"]}
                        label="Modifications to current operting procedures"
                      />
                    </Section>
                    <Section
                      name="PERMIT"
                      img={require("./../../images/Permit.png")}
                    >
                      <CheckBox
                        name="permitRelevant"
                        value={values?.["permitRelevant"]}
                        label="Permit Relevant to work and Valid"
                      />
                      <CheckBox
                        name="permitDisplay"
                        value={values?.["permitDisplay"]}
                        label="Permit Display at the work location"
                      />
                      <CheckBox
                        name="toolBox"
                        value={values?.["toolBox"]}
                        label="Tool box JSA and work party"
                      />
                      <CheckBox
                        name="permitDisplayLocation"
                        value={values?.["permitDisplayLocation"]}
                        label="Permit display proper location and Equipment"
                      />
                      <CheckBox
                        name="isolation"
                        value={values?.["isolation"]}
                        label="Isolation as per Company Management"
                      />
                    </Section>
                  </>
                )}
                <Spacer height={15} />
                <ModalFooter>
                  <Button color="warning" size="sm">
                    Close
                  </Button>
                  <Button
                    size="sm"
                    color="warning"
                    onClick={() => {
                      setStatus("draft");
                      setModal(true);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    size="sm"
                    disabled={hasValidationErrors}
                    color="warning"
                    onClick={() => {
                      setStatus("created");
                      setModal(true);
                    }}
                  >
                    Preview
                  </Button>
                </ModalFooter>
                <InformationCardModal
                  isOpen={modal}
                  toggle={() => setModal(!modal)}
                  form={form}
                  status={status}
                  values={{
                    ...values,
                    type: invert ? "bc" : "ps",
                    equipment,
                    equipmentDescription,
                    applicantName: userData?.givenName,
                  }}
                />
              </div>
            </form>
          )}
        />
      </Row>
    </MobileLayout>
  );
}
