import { Button, Input, ModalFooter, Row } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useState } from 'react'

import CustomLabel from '../../components/InputFields/CustomLabel'
import PreviewInformationCard from '../../components/previews/PreviewInformationCard'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { informationCardEndpoint } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'

export default function CloseShecard({
  onBack,
  currentStatus,
  nextStatus
}: any) {
  const { id } = useParams()
  const { accounts, instance } = useMsal()

  const [rejecting, setRejecting]: any = useState(false)
  const [submitModal, setSubmitModal]: any = useState(false)
  const [status, setStatus]: any = useState(false)

  const fetchCard = async () => {
    const response = await makeAuthenticatedRequest(
      `${informationCardEndpoint}/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchCard', fetchCard)

  const [formData, setFormData]: any = useState({
    comments: 'No',
    departmentComments: data?.data?.departmentComments || '',
    safetyOfficerComments: data?.data?.safetyOfficerComments || '',
    oimComments: data?.data?.oimComments || ''
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const body: any = {
    ...formData,
    status
  }

  const updateStatus = async () => {
    try {
      const res = await makeAuthenticatedRequest(
        `${informationCardEndpoint}/${id}?status=${status}&comments=${formData?.comments}&icDepartment=${formData?.icDepartment}`,
        'PATCH',
        body,
        accounts,
        instance
      )

      if (res?.status === 'success') {
        setSubmitModal(false)
        await refetch()
      }
    } catch (error) {
    } finally {
      setRejecting(false)
    }
  }

  const commentsName: any = {
    icProgress: 'departmentComments',
    departmentClosed: 'safetyOfficerComments',
    safetyOfficerClosed: 'oimComments'
  }

  const form = (
    <div>
      <div className="form-group">
        <CustomLabel label="Close Comments" />
        <Input
          type="text"
          name={commentsName[currentStatus]}
          onChange={handleInputChange}
        />
      </div>
    </div>
  )

  return (
    <ProtectedLayout onBack={onBack}>
      <Row style={{ margin: 10 }}>
        <PreviewInformationCard values={data?.data} history={data?.history} />
        {data?.data?.status === currentStatus && (
          <>
            <ModalFooter style={{ marginTop: 10 }}>
              <Button
                color={rejecting ? 'secondary' : 'warning'}
                disabled={rejecting}
                onClick={() => {
                  setRejecting(true)
                  setStatus(nextStatus)
                  setSubmitModal(true)
                }}
              >
                {rejecting ? 'Submitting...' : 'Close'}
              </Button>
            </ModalFooter>
            {submitModal && (
              <SubmitModalWithForm
                isOpen={submitModal}
                onClose={(val: any) => {
                  setSubmitModal(false)
                  setRejecting(false)
                  setStatus()
                }}
                onSubmit={updateStatus}
                form={form}
              />
            )}
          </>
        )}
      </Row>
    </ProtectedLayout>
  )
}
