import axios from 'axios'
import moment from 'moment'
import { Field, Form } from 'react-final-form'
import { useContext, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { Button, Col, Input, Label, Row } from 'reactstrap'

import AutoCompleteAndApi from '../../components/InputFields/AutoCompleteAndAPI'
import CustomLabel from '../../components/InputFields/CustomLabel'
import LabeledTextInput, {
  textArea
} from '../../components/InputFields/LabeledTextInput'
import LabeledDateTimeInput from '../../components/InputFields/DateTime'
import LabledParagraphInput from '../../components/InputFields/LabledParagraph'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import toNormalCase from '../../components/lib/toNormalCase'
import { Box, Link, renderData } from '../workManagement/PmTree'
import { getAccessToken } from '../../apiRequests'
import { loginRequest } from '../../authConfig'
import { manualAPI, workOrderAPI } from '../../components/serverurl'
import { UserContext } from '../../App'
import { SerialCodes } from '../../components/constants/manualConstants'
import { useNavigate } from 'react-router-dom'
import { dFields } from './ViewManuals'

const LabeledDropdown = ({
  occupy = 6,
  label,
  name,
  options
}: {
  occupy: any
  label: any
  name: any
  options: any
}) => {
  return (
    <Col xs={occupy}>
      <CustomLabel label={label} />
      <Field name={name}>
        {({ input, meta }) => (
          <div key={`${name}^${label}`}>
            {typeof options[0] === 'string' ? (
              <select {...input} style={textArea}>
                <option value="">Select</option>
                {options?.map((o: any, index: any) => (
                  <option key={`${o}-${index}`} value={o}>
                    {o}
                  </option>
                ))}
              </select>
            ) : (
              <select {...input} style={textArea}>
                <option value="">Select</option>
                {options?.map((o: any, index: any) => (
                  <option key={`${o?.value}-${index}`} value={o?.value}>
                    {o?.label}
                  </option>
                ))}
              </select>
            )}
            {meta.error && meta.touched && (
              <span style={{ color: 'red', fontWeight: 400, fontSize: 14 }}>
                {meta.error}
              </span>
            )}
          </div>
        )}
      </Field>
    </Col>
  )
}

export default function Manuals() {
  return (
    <ProtectedLayout title="Upload Manual" onBack="/cmms/manual">
      <Row style={{ margin: 15 }}>
        <Row
          style={{
            margin: '5vh',
            width: '95vw',
            height: 'auto',
            borderRadius: 25,
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            padding: 0
          }}
        >
          <UploadManualForm />
        </Row>
      </Row>
    </ProtectedLayout>
  )
}

export function UploadManualForm({ initialValues }: { initialValues?: any }) {
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const navigate = useNavigate()
  const [preview, setpreview]: any = useState(false)
  const [file, setFile]: any = useState(null)
  const [uploadFile, setUploadfile]: any = useState(null)
  const [res, setRes]: any = useState()

  const handleFileUpload = (event: any) => {
    const uploadedFile = event.target.files[0]
    setUploadfile(event.target.files[0])
    if (uploadedFile) {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(uploadedFile)
      fileReader.onload = () => {
        setFile({
          name: uploadedFile.name,
          url: fileReader.result,
          type: uploadedFile.type
        })
      }
    }
  }

  const onCreateNew = async (e: any, values: any, form: any) => {
    e.preventDefault()

    const formData: any = new FormData()
    formData.append('file', uploadFile)
    formData.append('uploadedBy', userData?.givenName || userData?.displayName)
    formData.append('createdAt', moment.now())
    formData.append('status', uploadFile ? 'Created' : 'Draft')

    Object.keys(values)?.forEach((a: any) => formData.append(a, values[a]))

    const token = await getAccessToken(loginRequest, accounts, instance)

    try {
      const response = await axios.post(`${manualAPI}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      })

      if (response?.data?.status === 'success') {
        form.reset()
        setRes(response?.data?.data)
        setFile(null)
        setUploadfile(null)
      }

      return response?.data?.status
    } catch (error) {}
  }

  const onSubmitDraft = async (e: any, form: any) => {
    e.preventDefault()

    const formData: any = new FormData()
    formData.append('file', uploadFile)
    formData.append('status', 'Created')
    formData.append('id', initialValues?._id)

    const token = await getAccessToken(loginRequest, accounts, instance)

    try {
      const response = await axios.patch(`${manualAPI}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      })

      if (response?.data?.status === 'success') {
        form.reset()
        setRes(response?.data?.data)
        setFile(null)
        setUploadfile(null)
      }

      return response?.data?.status
    } catch (error) {}
  }

  return (
    <Row
      style={{
        margin: '5vh',
        width: '95vw',
        height: 'auto',
        borderRadius: 25,
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        padding: 0
      }}
    >
      <Col xs="12" md="6" style={{ padding: '5vh' }}>
        <h3>Document Details</h3>
        <>
          <Form
            onSubmit={(values) => console.log(values)}
            initialValues={initialValues}
            render={({ form, values }) => (
              <form>
                <Row>
                  <LabledParagraphInput name="title" label="Title" occupy={6} />
                  <LabledParagraphInput
                    name="description"
                    label="Description"
                    occupy={6}
                  />
                  <Col xs="6">
                    <CustomLabel label="Equipment" />
                    <Field name="tag" style={{ background: '#DCDCDC' }}>
                      {({ input, ...rest }) => (
                        <div>
                          <AutoCompleteAndApi
                            isMulti={false}
                            name="tagId"
                            url={`${workOrderAPI}/filters`}
                            handleChange={(option: any, change: any) => {
                              input.onChange(option?.value)
                              console.log(option)
                              // change('equipment', option?.value)
                              // change('equiupmentId', option?.equipmentId)
                            }}
                            needSelect={false}
                            input={input}
                            {...rest}
                          />
                        </div>
                      )}
                    </Field>
                  </Col>
                  <LabledParagraphInput
                    name="wDepartment"
                    label="Department"
                    occupy={6}
                  />
                  <LabeledTextInput
                    name="revNumber"
                    label="Revision Number"
                    occupy={6}
                  />
                  <LabeledDateTimeInput
                    name="revDate"
                    label="Revision Date"
                    occupy={6}
                  />
                  <Spacer height={10} />
                  <CustomLabel label="Upload File" />
                  <Input
                    type="file"
                    accept="application/pdf"
                    style={{ marginLeft: 10 }}
                    onChange={handleFileUpload}
                  />
                </Row>
                <Row>
                  <LabeledDropdown
                    occupy={6}
                    label={'Origantor Code'}
                    options={SerialCodes.originator}
                    name="originatorCode"
                  />
                  <LabeledDropdown
                    occupy={6}
                    label={'Origin Code'}
                    options={SerialCodes.origin}
                    name="originCode"
                  />
                  <LabeledDropdown
                    occupy={6}
                    label={'Logic Code'}
                    options={SerialCodes.logicCodes}
                    name="logicType"
                  />
                  <LabeledDropdown
                    occupy={6}
                    label={'Department'}
                    options={SerialCodes.departmentCodes}
                    name="department"
                  />
                  <LabeledDropdown
                    occupy={6}
                    label={'Document Type'}
                    name="documentType"
                    options={SerialCodes.documentTypeCodes}
                  />
                  {values?.isExisting && (
                    <LabeledTextInput
                      name="serialNumber"
                      label="Serial Number"
                      occupy={6}
                    />
                  )}
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col xs="6">
                    <Field
                      type="checkbox"
                      name="isExisting"
                      component={'input'}
                    />
                    <Label check style={{ marginLeft: 10 }}>
                      Existing Document
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="2" style={{ marginTop: 15 }}>
                    <Button color="warning" onClick={() => setpreview(true)}>
                      Preview
                    </Button>
                  </Col>
                </Row>
                <SubmitModalWithForm
                  isOpen={preview}
                  onClose={(val: any) => {
                    setpreview(false)
                  }}
                  onSubmit={async (e: any) =>
                    initialValues
                      ? onSubmitDraft(e, form)
                      : onCreateNew(e, values, form)
                  }
                  size="xs"
                  header="Upload Manual"
                  sucessView={
                    <div>
                      <p>Document Number: {res?.number}</p>
                      <Link
                        onClick={() => {
                          if (res.satus === 'Created') {
                            navigate('/cmms/manual/created')
                          } else if (res.status === 'Draft') {
                            navigate('/cmms/manual/draft')
                          } else if (res.status === 'Approved') {
                            navigate(
                              `/cmms/manual/view/${res.originatorCode}/${res.originCode}/${res.logicType}/${res.department}/${res.documentType}/docs`
                            )
                          } else if (res.status === 'Rejected') {
                            navigate('/cmms/manual/rejected')
                          }
                        }}
                      >
                        View Document
                      </Link>
                    </div>
                  }
                  form={
                    <Box heading="Manual Details">
                      {dFields?.map((a: any) =>
                        renderData(toNormalCase(a), values[a], 14)
                      )}
                    </Box>
                  }
                />
              </form>
            )}
          />
        </>
      </Col>
      <Col
        xs="12"
        md="6"
        style={{
          backgroundColor: '#FAFAD2',
          margin: 0,
          borderTopRightRadius: 25,
          borderBottomRightRadius: 25,
          color: '#000',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {file?.url ? (
          <iframe
            title="Document Preview"
            src={file.url}
            width="100%"
            height="700px"
          />
        ) : (
          <img
            src={require('./../../images/upload image.png')}
            alt="pdf"
            style={{
              padding: 0,
              margin: 0,
              objectFit: 'contain',
              width: '100%',
              height: '700px'
            }}
          />
        )}
      </Col>
    </Row>
  )
}
