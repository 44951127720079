import { Route, Routes } from 'react-router-dom'
import { useContext } from 'react'

import ApproveWorkRequest from '../pages/workManagement/ApproveWorkRequest'
import CorrectiveMaintenanceDashboard from '../pages/workManagement/CorrectiveMaintenanceDashboard'
// import WorkManagementDashboard from '../pages/workManagement/WorkManagementDashboard'
import WorkOrders from '../pages/workManagement/WorkOrders'
import WorkRequestForm from '../pages/workManagement/WorkRequestForm'
import { UserContext } from '../App'
import EditWRForm from '../pages/workManagement/EditWRForm'

export default function WorkMangementRouter() {
  const { userData } = useContext(UserContext)
  const userDepartment = userData?.department?.toLowerCase()

  return (
    <Routes>
      <Route
        path="/correctiveMaintenance"
        element={<CorrectiveMaintenanceDashboard />}
      />
      <Route
        path="/create"
        element={<WorkRequestForm onBack="/cmms/workRequests" />}
      />
      <Route
        path="/created"
        element={
          <WorkOrders
            status="wrCreated"
            department={userDepartment?.trim()}
            onBack="/cmms/workRequests"
            url="/cmms/workRequests/created"
            title="Work Requests"
          />
        }
      />
      <Route
        path="/draft"
        element={
          <WorkOrders
            status="wrDraft"
            department={userDepartment?.trim()}
            onBack="/cmms/workRequests"
            url="/cmms/workRequests/draft"
            title="Work Requests - Draft"
          />
        }
      />
      <Route
        path="/draft/:id"
        element={<EditWRForm onBack={'/cmms/workRequests/draft'} />}
      />
      <Route
        path="/created/:id"
        element={<ApproveWorkRequest onBack={'/cmms/workRequests/created'} />}
      />
      <Route
        path="/created"
        element={
          <WorkOrders
            status="woCreated"
            department={userDepartment?.trim()}
            onBack="/cmms/workOrders"
            url="/cmms/workorders/created"
            title="Work Orders"
          />
        }
      />
      {/* unsorted */}
      {/* <Route
        path="/correctiveMaintenance/cancelledWorkOrders"
        element={
          <WorkOrders
            status="cancelled"
            department={userDepartment?.trim()}
            onBack="/workManagement/correctiveMaintenance"
            url="/workManagement/correctiveMaintenance"
            title="Cancelled Work Orders"
          />
        }
      />

      <Route
        path="/correctiveMaintenance/workOrders/:id"
        element={
          <ApproveWorkRequest
            onBack={'/workManagement/correctiveMaintenance/workOrders'}
          />
        }
      />
      <Route
        path="/correctiveMaintenance/todo"
        element={
          <WorkOrders
            status="todo"
            department={userDepartment?.trim()}
            onBack="/workManagement/correctiveMaintenance"
            url="/workManagement/correctiveMaintenance/todo"
            title="Work Orders - Todo"
          />
        }
      />
      <Route
        path="/correctiveMaintenance/todo/:id"
        element={
          <ApproveWorkRequest onBack="/workManagement/correctiveMaintenance/todo" />
        }
      />
      <Route
        path="/correctiveMaintenance/inProgress"
        element={
          <WorkOrders
            status="inProgress"
            department={userDepartment?.trim()}
            onBack="/workManagement/correctiveMaintenance"
            url="/workManagement/correctiveMaintenance/inProgress"
            title="Work Orders - In Progress"
          />
        }
      />
      <Route
        path="/correctiveMaintenance/inProgress/:id"
        element={
          <ApproveWorkRequest onBack="/workManagement/correctiveMaintenance/inProgress" />
        }
      />
      <Route
        path="/correctiveMaintenance/waitingForMaterial"
        element={
          <WorkOrders
            status="waitingForMaterial"
            department={userDepartment?.trim()}
            onBack="/workManagement/correctiveMaintenance"
            url="/workManagement/correctiveMaintenance/waitingForMaterial"
            title="Work Orders - Waiting For Material"
          />
        }
      />
      <Route
        path="/correctiveMaintenance/waitingForMaterial/:id"
        element={
          <ApproveWorkRequest onBack="/workManagement/correctiveMaintenance/waitingForMaterial" />
        }
      />
      <Route
        path="/correctiveMaintenance/waitingFor3Party"
        element={
          <WorkOrders
            status="waitingFor3rdParty"
            department={userDepartment?.trim()}
            onBack="/workManagement/correctiveMaintenance"
            url="/workManagement/correctiveMaintenance/waitingFor3Party"
            title="Work Orders - Waiting For 3rd Party"
          />
        }
      />
      <Route
        path="/correctiveMaintenance/waitingFor3Party/:id"
        element={
          <ApproveWorkRequest onBack="/workManagement/correctiveMaintenance/waitingFor3Party" />
        }
      />
      <Route
        path="/correctiveMaintenance/waitingForShutdown"
        element={
          <WorkOrders
            status="waitingForShutdown"
            department={userDepartment?.trim()}
            onBack="/workManagement/correctiveMaintenance"
            url="/workManagement/correctiveMaintenance/waitingForShutdown"
            title="Work Orders - Waiting For Shutdown"
          />
        }
      />
      <Route
        path="/correctiveMaintenance/waitingForShutdown/:id"
        element={
          <ApproveWorkRequest onBack="/workManagement/correctiveMaintenance/waitingForShutdown" />
        }
      />
      <Route
        path="/correctiveMaintenance/closed"
        element={
          <WorkOrders
            status="closed"
            department={userDepartment?.trim()}
            onBack="/workManagement/correctiveMaintenance"
            url="/workManagement/correctiveMaintenance/closed"
            title="Work Orders - Closed"
          />
        }
      />
      <Route
        path="/correctiveMaintenance/closed/:id"
        element={
          <ApproveWorkRequest onBack="/workManagement/correctiveMaintenance/closed" />
        }
      /> */}
    </Routes>
  )
}
