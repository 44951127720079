import React from 'react'
import { Button, Col, Row, Table } from 'reactstrap'
import { renderArray } from '../../pages/WaitingForPIC'
import { hoecControlRiskMatrixColors } from '../constants/riskMatrixColors'
import { getRiskMatrixColors } from '../lib/getRiskLevel'
import Spacer from '../Spacer'
import { incrementby1 } from './JSAForm'
import {
  getColorforRiskLevel,
  getRisk,
  getRiskColor,
  riskLevel
} from './QRAForm'

export default function QRATemplateView({
  template,
  setTemplate,
  select,
  showSelect = true
}: any) {
  const selected = select?.template?.task === template?.template?.task
  const data = template?.template ? template?.template : template
  const riskMatrixColors = getRiskMatrixColors(5)

  const riskStyle: any = {
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'center',
    verticalAlign: 'middle'
  }

  const middle: any = {
    textAlign: 'center',
    verticalAlign: 'middle'
  }

  return (
    <>
      <React.Fragment>
        <Table bordered style={{ border: '1px solid #000', fontSize: 12 }}>
          <thead>
            <tr>
              <th>DEPARTMENT</th>
              <td>{data?.department}</td>
              <th>ACTIVITY / TASK</th>
              <td>{data?.task}</td>
              <th>LOCATION</th>
              <td>{data?.location}</td>
            </tr>
            <tr>
              <th>ASSESMENT TEAM</th>
              <td colSpan={3}>{renderArray(data?.team, false)}</td>
              {/* <th>TOOLBOX TALK CONDUCTED</th> */}
              {/* <td>{data?.toolBox}</td> */}
              <th>DATE</th>
              <td>{data?.date}</td>
            </tr>
          </thead>
          <tbody></tbody>
        </Table>
        <Spacer height={10} />
        <Table
          bordered
          responsive
          striped
          style={{ border: '1px solid #000', fontSize: 12 }}
        >
          <thead
            style={{
              backgroundColor: 'orange',
              fontSize: 13,
              fontWeight: 900,
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            <tr>
              <th colSpan={6} />
              <th colSpan={2} style={{ textAlign: 'center' }}>
                Risk Evaluation
              </th>
              <th colSpan={2} />
              <th colSpan={2} style={{ textAlign: 'center' }}>
                After Protective
              </th>
              <th colSpan={2} />
            </tr>
            <tr>
              <th>S.No</th>
              <th>Task</th>
              <th>Hazard</th>
              <th>Consquence / Effect</th>
              <th>Activity</th>
              <th>Condition</th>
              <th>Severity/ Probability</th>
              <th>Control/ People</th>
              <th>Initial Risk</th>
              <th style={{ textAlign: 'center', width: '50%' }}>
                Existing Control Measures
              </th>
              <th>Severity/ Probability</th>
              <th>Control/ People</th>
              <th>Residual Risk</th>
              <th>Risk Category</th>
            </tr>
          </thead>
          <tbody>
            <React.Fragment>
              {data?.hazards?.map((hazard: any, index: number) => (
                <>
                  <tr key={`${index}_${hazard?.name}`}>
                    <td style={{ width: '1%', ...middle }}>
                      {incrementby1(index)}
                    </td>
                    <td style={{ width: '5%', ...middle }}>{hazard?.task}</td>
                    <td style={{ width: '5%', ...middle }}>{hazard?.name}</td>
                    <td style={{ width: '5%', ...middle }}>{hazard?.effect}</td>
                    <td style={{ width: '5%', ...middle }}>
                      {hazard?.routine}
                    </td>
                    <td style={{ width: '5%', ...middle }}>
                      {hazard?.activity}
                    </td>
                    <td
                      style={{
                        width: '5%',
                        backgroundColor:
                          riskMatrixColors[hazard?.initalSeverityProb],
                        ...riskStyle
                      }}
                    >
                      {hazard?.initalSeverityProb}
                    </td>
                    <td
                      style={{
                        width: '5%',
                        backgroundColor:
                          hoecControlRiskMatrixColors[
                            hazard?.initalControlPeople
                          ],
                        ...riskStyle
                      }}
                    >
                      {hazard?.initalControlPeople}
                    </td>
                    <td
                      style={{
                        width: '5%',
                        backgroundColor: getRiskColor(
                          getRisk(
                            hazard?.initalSeverityProb,
                            hazard?.initalControlPeople
                          )
                        ),
                        ...riskStyle
                      }}
                    >
                      {getRisk(
                        hazard?.initalSeverityProb,
                        hazard?.initalControlPeople
                      )}
                    </td>
                    <td style={{ width: '35%' }}>
                      {hazard?.controls?.map(
                        (control: string, index: number) => (
                          <li key={index}>{control}</li>
                        )
                      )}
                    </td>
                    <td
                      style={{
                        width: '5%',
                        backgroundColor:
                          riskMatrixColors[hazard?.residualSeverityProb],
                        ...riskStyle
                      }}
                    >
                      {hazard?.residualSeverityProb}
                    </td>
                    <td
                      style={{
                        width: '5%',
                        backgroundColor:
                          hoecControlRiskMatrixColors[
                            hazard?.residualControlPeople
                          ],
                        ...riskStyle
                      }}
                    >
                      {hazard?.residualControlPeople}
                    </td>
                    <td
                      style={{
                        width: '5%',
                        backgroundColor: getRiskColor(
                          getRisk(
                            hazard?.residualSeverityProb,
                            hazard?.residualControlPeople
                          )
                        ),
                        ...riskStyle
                      }}
                    >
                      {getRisk(
                        hazard?.residualSeverityProb,
                        hazard?.residualControlPeople
                      )}
                    </td>
                    <td
                      style={{
                        width: '5%',
                        backgroundColor: getColorforRiskLevel(
                          getRisk(
                            hazard?.residualSeverityProb,
                            hazard?.residualControlPeople
                          )
                        ),
                        ...middle,
                        fontWeight: 700
                      }}
                    >
                      {riskLevel(
                        getRisk(
                          hazard?.residualSeverityProb,
                          hazard?.residualControlPeople
                        )
                      )}
                    </td>
                  </tr>
                </>
              ))}
            </React.Fragment>
          </tbody>
        </Table>
        {showSelect && (
          <Row>
            <Col xs="12">
              <Button
                color={selected ? 'success' : 'primary'}
                style={{ width: 'inherit', marginBottom: 10 }}
                onClick={() => setTemplate(template)}
              >
                {selected ? ' Selected' : 'Select'}
              </Button>
            </Col>
          </Row>
        )}
      </React.Fragment>
    </>
  )
}
