import { Table } from 'reactstrap'

export default function RCMTable({
  onClick,
  asset,
  effect
}: {
  onClick: any
  asset?: any
  effect?: any
}) {
  return (
    <Table
      style={{
        textAlign: 'center',
        verticalAlign: 'middle',
        borderColor: '#000',
        fontSize: '18px'
      }}
      bordered
    >
      <thead>
        <tr>
          <th>Severity</th>
          <th colSpan={2}>Asset consequences</th>
          <th colSpan={2}>Environment Consequences</th>
          <th>RCM INCICATOR</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>0</td>
          <td
            style={{ borderWidth: asset === 'No Damage' ? 5 : 1 }}
            onClick={() => onClick({ 'Asset consequences': 'No Damage' })}
          >
            No Damage
          </td>
          <td>None</td>
          <td
            style={{ borderWidth: effect === 'No Effect' ? 5 : 1 }}
            onClick={() => onClick({ 'Environment Consequences': 'No Effect' })}
          >
            No Effect
          </td>
          <td>None</td>
          <td
            style={{ backgroundColor: 'lightblue' }}
            onClick={() => onClick({ ETBC: 'T' })}
          >
            T
          </td>
        </tr>
        <tr>
          <td>1</td>
          <td
            style={{ borderWidth: asset === 'Slight Damage' ? 5 : 1 }}
            onClick={() => onClick({ 'Asset consequences': 'Slight Damage' })}
          >
            Slight Damage
          </td>
          <td>Costs between 100000 and 1 million US$</td>
          <td
            style={{ borderWidth: effect === 'Slight Effect' ? 5 : 1 }}
            onClick={() =>
              onClick({ 'Environment Consequences': 'Slight Effect' })
            }
          >
            Slight Effect
          </td>
          <td>slight environment damage - contained with in the premises</td>
          <td
            style={{ backgroundColor: 'lightblue' }}
            onClick={() => onClick({ ETBC: 'T' })}
          >
            T
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td
            style={{ borderWidth: asset === 'Minor Damage' ? 5 : 1 }}
            onClick={() => onClick({ 'Asset consequences': 'Minor Damage' })}
          >
            Minor Damage
          </td>
          <td>Costs between 1000000 and 1 million US$</td>
          <td
            style={{ borderWidth: effect === 'Minor Effect' ? 5 : 1 }}
            onClick={() =>
              onClick({ 'Environment Consequences': 'Minor Effect' })
            }
          >
            Minor Effect
          </td>
          <td>Minor environmental damage, but no lasting effect</td>
          <td
            style={{ backgroundColor: 'yellow' }}
            onClick={() => onClick({ ETBC: 'Y' })}
          >
            Y
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td
            style={{ borderWidth: asset === 'Moderate Damage' ? 5 : 1 }}
            onClick={() => onClick({ 'Asset consequences': 'Moderate Damage' })}
          >
            Moderate Damage
          </td>
          <td>Costs between 1 million and 10 million US$</td>
          <td
            style={{ borderWidth: effect === 'Moderate Effect' ? 5 : 1 }}
            onClick={() =>
              onClick({ 'Environment Consequences': 'Moderate Effect' })
            }
          >
            Moderate Effect
          </td>
          <td>
            Limited environmental damage that will persist or require cleaning
            up
          </td>
          <td
            style={{ backgroundColor: 'yellow' }}
            onClick={() => onClick({ ETBC: 'Y' })}
          >
            Y
          </td>
        </tr>
        <tr>
          <td>4</td>
          <td
            style={{ borderWidth: asset === 'Major Damage' ? 5 : 1 }}
            onClick={() => onClick({ 'Asset consequences': 'Major Damage' })}
          >
            Major Damage
          </td>
          <td>Costs between 10 and 100 million US$</td>
          <td
            style={{ borderWidth: effect === 'Major Effect' ? 5 : 1 }}
            onClick={() =>
              onClick({ 'Environment Consequences': 'Major Effect' })
            }
          >
            Major Effect
          </td>
          <td>
            Severe environmental damage that will require extensive measures to
            restore beneficial uses of the environment
          </td>
          <td
            style={{ backgroundColor: 'red' }}
            onClick={() => onClick({ ETBC: 'X' })}
          >
            x
          </td>
        </tr>
        <tr>
          <td>5</td>
          <td
            style={{ borderWidth: asset === 'Massive Damage' ? 5 : 1 }}
            onClick={() => onClick({ 'Asset consequences': 'Massive Damage' })}
          >
            Massive Damage
          </td>
          <td>Costs in excess of 100 million US$</td>
          <td
            style={{ borderWidth: effect === 'Massive Effect' ? 5 : 1 }}
            onClick={() =>
              onClick({ 'Environment Consequences': 'Massive Effect' })
            }
          >
            Massive Effect
          </td>
          <td>
            Persistent severe environmental damage that will lead to loss of
            commercial, recreational use or loss of natural resources over a
            wide area
          </td>
          <td
            style={{ backgroundColor: 'red' }}
            onClick={() => onClick({ ETBC: 'X' })}
          >
            x
          </td>
        </tr>
      </tbody>
    </Table>
  )
}
