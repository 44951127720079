import moment from 'moment'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'

import Loader from '../components/Loader'
import ModalTitle from '../components/ModalTitle'
import ObservationCardActions from './obersvationCard/ObservationCardActions'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import { getPermissions } from '../components/lib/firsPermissions'
import { incidentReport } from '../components/serverurl'
import { incrementby1 } from '../components/Forms/JSAForm'
import { makeAuthenticatedRequest } from '../apiRequests'
import { Row, Table } from 'reactstrap'
import { SidePane } from './CMDashboard'
import { Stage1Form } from './firs'

const FIRSTable = ({ active }: any) => {
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const getStatus = () => {
    switch (active) {
      case 'verify':
        return 'created'
      case 'approve':
        return 'verified'
      case 'report':
        return 'approved'
      case 'close':
        return 'reported'

      default:
        break
    }
  }

  const fetchReports = async () =>
    await makeAuthenticatedRequest(
      `${incidentReport}?status=${getStatus()}`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data, isLoading, refetch, isRefetching }: any = useQuery(
    'todos',
    fetchReports
  )

  useEffect(() => {
    refetch()
  }, [active])

  const headers: any = [
    'S.No',
    'FIRS Number',
    'Originator',
    'Tag Number',
    'Department',
    'Location',
    'Created On'
  ]

  if (isLoading || isRefetching) {
    return <Loader />
  }

  return (
    <>
      <Table bordered striped style={{ borderColor: '#000', fontSize: 14 }}>
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            {headers?.map((h: any) => <th key={h}>{h}</th>)}
          </tr>
        </thead>
        <tbody>
          {data?.reports?.map((d: any, index: number) => (
            <React.Fragment key={d?.woNumber}>
              <tr>
                <td>{incrementby1(index)}</td>
                <td
                  style={{
                    textDecoration: 'underline',
                    color: 'blue',
                    cursor: 'pointer'
                  }}
                  onClick={() => navigate(`/incidentReport/${d?._id}`)}
                >
                  {d?.woNumber}
                </td>
                <td>{d?.stage1?.originator}</td>
                <td>{d?.stage1?.tagNumber}</td>
                <td>{d?.stage1?.department}</td>
                <td>{d?.stage1?.location}</td>
                <td>{moment(d?.createdAt).format('DD-MM-YYYY')}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export const FIRSAnalytics = () => {
  const data = [
    {
      name: 'Burns',
      injury: 2
    },
    {
      name: 'Slip and Fall',
      injury: 5
    },
    {
      name: 'Head Injuries',
      injury: 1
    },
    {
      name: 'back injuries',
      injury: 1
    },
    {
      name: 'Falling Object',
      injury: 2
    },
    {
      name: 'Drowning',
      injury: 2
    },
    {
      name: 'Crush Injuries',
      injury: 2
    }
  ]
  return (
    <div style={{ margin: 10 }}>
      <ModalTitle title="Analytics" height={50} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 50
        }}
      >
        <BarChart
          width={1000}
          height={700}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="injury" fill="#8884d8" />
        </BarChart>
      </div>
    </div>
  )
}

export default function FIRSDashboard({ title }: any) {
  const sidePaneItems = ['report', 'close', 'reject']

  const [permissions, setPermissions]: any = useState()
  const items = [
    'create',
    ...sidePaneItems,
    'verify',
    'approve',
    'Observation Card',
    'Analytics',
    'closed '
  ]
  const [searchParams] = useSearchParams()
  const currentView: any = searchParams.get('view')
  const [active, setActive] = useState(currentView || sidePaneItems[0])
  const { accounts, instance } = useMsal()

  const isCreateNew = active === 'create'

  useEffect(() => {
    if (!permissions) {
      getPermissions(accounts, instance).then((response: any) =>
        setPermissions(response)
      )
    }
  })

  return (
    <ProtectedLayout
      onBack="/firs"
      hasSideList
      title={title}
      sidePanel={
        <Row style={{ marginLeft: 10 }}>
          <SidePane
            list={items}
            activeItem={active}
            setActiveItem={setActive}
          />
        </Row>
      }
    >
      <Row style={{ margin: 10 }}>
        {isCreateNew ? (
          <Stage1Form />
        ) : active === 'Observation Card' ? (
          <ObservationCardActions />
        ) : active === 'Analytics' ? (
          <FIRSAnalytics />
        ) : (
          <FIRSTable active={active} />
        )}
      </Row>
    </ProtectedLayout>
  )
}
