import { useState } from 'react'
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap'
import { SubHeader } from '../pages/VerifyCM'
import AshokLeylandLayout from './AshokLeylandLayout'

const TABS = {
  FIVE: 'PM Vs Breakdown Maintenance',
  SEVEN: 'OVERALL RESPONSE',
  EIGHT: 'PM ADHERENCE',
  NINE: 'UP TIME'
}

const TabFive = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img
        src={require('./../images/Picture 1.png')}
        alt="kpi"
        style={{ width: '100%', marginTop: 20 }}
      />
    </div>
  )
}

const TabSeven = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img
        src={require('./../images/overall response.jpeg')}
        alt="kpi"
        style={{ height: '60vh', marginTop: 20 }}
      />
    </div>
  )
}
const TabEight = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img
        src={require('./../images/pm adherence.jpeg')}
        alt="kpi"
        style={{ height: '60vh', marginTop: 20 }}
      />
    </div>
  )
}
const TabNine = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img
        src={require('./../images/uptime.jpeg')}
        alt="kpi"
        style={{ height: '60vh', marginTop: 20 }}
      />
    </div>
  )
}

export default function AlKPIStatistics() {
  const [active, setActive] = useState(TABS.FIVE)
  return (
    <AshokLeylandLayout onBack="/ashokleyland/mms">
      <Row style={{ margin: 10 }}>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={active === TABS.FIVE ? 'active' : ''}
                onClick={() => setActive(TABS.FIVE)}
              >
                {TABS.FIVE}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={active === TABS.SEVEN ? 'active' : ''}
                onClick={() => setActive(TABS.SEVEN)}
              >
                {TABS.SEVEN}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={active === TABS.EIGHT ? 'active' : ''}
                onClick={() => setActive(TABS.EIGHT)}
              >
                {TABS.EIGHT}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={active === TABS.NINE ? 'active' : ''}
                onClick={() => setActive(TABS.NINE)}
              >
                {TABS.NINE}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={active}>
            <TabPane tabId={TABS.FIVE}>
              <SubHeader header={TABS.FIVE} permitColor="#FFD580" />
              <TabFive />
            </TabPane>
            <TabPane tabId={TABS.SEVEN}>
              <SubHeader header={TABS.SEVEN} permitColor="#FFD580" />
              <TabSeven />
            </TabPane>
            <TabPane tabId={TABS.EIGHT}>
              <SubHeader header={TABS.EIGHT} permitColor="#FFD580" />
              <TabEight />
            </TabPane>
            <TabPane tabId={TABS.NINE}>
              <SubHeader header={TABS.NINE} permitColor="#FFD580" />
              <TabNine />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </AshokLeylandLayout>
  )
}
