import { Button, Col, Row } from 'reactstrap'
import AshokLeylandLayout from './AshokLeylandLayout'
import { useNavigate } from 'react-router-dom'
import AlIncidentSelect from '../SVGs/AlIncidentSelect'

export default function AlFirsSelectIncident() {
  const navigate = useNavigate()
  return (
    <AshokLeylandLayout
      title="SELECT INCIDENT TYPE"
      onBack="/ashokleyland/firs"
    >
      <Row style={{ margin: 15 }}>
        <Row>
          <Col
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <div
              style={{
                height: '90vh',
                width: '90vh'
              }}
            >
              <AlIncidentSelect />
            </div>
          </Col>
        </Row>
      </Row>
    </AshokLeylandLayout>
  )
}
