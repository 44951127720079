export default function getStatus(value: number) {
  var status: string = ''

  const _apiStatus = {
    Draft: 0,
    WaitingForHSE: 1,
    WaitingForPIC: 2,
    WaitingForIA: 3,
    IsolatingByIA: 4,
    IsolatedByIA: 5,
    WaitingForPS: 6,
    WaitingForDepIM: 7,
    WaitingForIM: 8,
    WaitingForAO: 9,
    WaitingForJP: 10,
    Live: 11,
    ClosedByJP: 12,
    ClosedByAO: 13,
    Closed: 14,
    Overdue: 15,
    WaitingRevalidByPIC: 16,
    WaitingRevalidByPS: 17,
    WaitingRevalidByDeptIM: 18,
    WaitingRevalidByIM: 19,
    WaitingRevalidByAO: 20,
    WaitingRevalidByJP: 21,
    Rejected: 22,
    LTI: 23,
    Suspended: 24,
    WaitingDeIsoByPIC: 25,
    WaitingStartDeIsoByIA: 26,
    WaitingDeIsoByIA: 27,
    WaitingDeIsoByAO: 28,
    IsolatedClose: 29,
    RiskUpdated: 30,
    WaitingDFTByPIC: 31,
    WaitingForDFTISOStartIA: 32,
    WaitingForDFTISOFinishIA: 33,
    WaitingForDFTByAO: 34,
    WaitingForDFTByJP: 35,
    LTIApprovedByJP: 36,
    LTIApprovedByPIC: 37,
    LTIApprovedByPS: 38,
    LTIApprovedByAO: 39
  }

  switch (value) {
    case 0:
      status = 'Draft'
      break

    case 1:
      status = 'Waiting For HSE Approval'
      break

    case 2:
      status = 'Waiting for PIC Approval'
      break

    case 3:
      status = 'Waiting for IA Approval'
      break

    case 4:
      status = 'Isolation Started'
      break

    case 5:
      status = 'Isolation Completed'
      break

    case 6:
      status = 'Waiting for PS Approval'
      break

    case 7:
      status = 'Waiting for DIM Approval'
      break

    case 8:
      status = 'Waiting for IM Approval'
      break

    case 9:
      status = 'Waiting for AO Approval'
      break

    case 10:
      status = 'Waiting for JP Approval'
      break

    case 11:
      status = 'LIVE'
      break

    case 12:
      status = 'ICDraft'
      break

    case 13:
      status = 'ClosedByAA'
      break

    case 16:
      status = 'Revalidate by PIC'
      break

    case 17:
      status = 'Revalidate by PS'
      break

    case 18:
      status = 'Revalidate by DIM'
      break

    case 19:
      status = 'Revalidate by IM'
      break

    case 20:
      status = 'Revalidate by AO'
      break

    case 25:
      status = 'DeIsolate by PIC'
      break

    case 26:
      status = 'Start DeIsolate by IA'
      break

    case 27:
      status = 'Finish DeIsolate by IA'
      break

    case 28:
      status = 'DeIsolate by AO'
      break

    case 29:
      status = 'Closed'
      break

    case 30:
      status = 'RiskUpdated'
      break

    case 31:
      status = 'WaitingDFTByPIC'
      break

    case 32:
      status = 'WaitingStartDFTByIA'
      break

    case 33:
      status = 'WaitingFinishDFTByIA'
      break

    case 34:
      status = 'WaitingLiveDFTByIA'
      break

    case 35:
      status = 'LiveForDFT'
      break

    case 36:
      status = 'WaitingLTIPIC'
      break

    case 37:
      status = 'WaitingLTIPS'
      break

    case 38:
      status = 'WaitingLTIAO'
      break

    case 39:
      status = 'LTIApprovedByAO'
      break

    default:
      status = 'NA'
      break
  }

  return status
}
