import {
  Button,
  Col,
  Input,
  ModalFooter,
  Row,
  Spinner,
  Table
} from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { Box, renderData } from '../workManagement/PmTree'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { stock } from '../../components/serverurl'
import { Text } from '../../components/RiskAssesmentPreview'
import ReactToPrint from 'react-to-print'
import React, { useContext, useEffect, useRef, useState } from 'react'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { UserContext } from '../../App'
import { incrementby1 } from '../../components/Forms/JSAForm'

const EnterContainer = ({
  data,
  isLoading,
  isRefetching,
  showNote,
  setSpares,
  spares
}: {
  data: any
  isLoading?: boolean
  isRefetching?: boolean
  showNote: any
  spares: any
  setSpares: any
}) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Line.No</th>
            <th>Spares Part Number</th>
            <th>Spare Part Description</th>
            <th>Quantity</th>
            <th>Container ID</th>
          </tr>
        </thead>
        <tbody>
          <>
            {isLoading || isRefetching ? (
              <tr>
                <td colSpan={12}>
                  <Row
                    style={{
                      height: 500,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Spinner />
                  </Row>
                </td>
              </tr>
            ) : (
              data?.map((w: any, index: number) => (
                <React.Fragment key={w?._id}>
                  <tr key={`${index}-${w['Spare Part Number']}`}>
                    <td>{incrementby1(index)}</td>
                    <td>{w?.lineCode}</td>
                    <td>{w?.spare?.['Spare Part Number']}</td>
                    <td>{w?.spare?.['Spare Part Description']}</td>
                    <td>{w?.['quantityRequested']}</td>
                    {showNote && (
                      <td>
                        <Input
                          type="text"
                          onChange={(e: any) =>
                            setSpares(
                              spares?.map((s: any) =>
                                w?.lineCode === s?.lineCode
                                  ? { ...s, containerID: e?.target?.value }
                                  : s
                              )
                            )
                          }
                        />
                      </td>
                    )}
                    {!showNote && <td>{w?.containerID}</td>}
                  </tr>
                </React.Fragment>
              ))
            )}
          </>
        </tbody>
      </Table>
      {data?.length === 0 && (
        <Row
          style={{
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Data Found
          </p>
        </Row>
      )}
    </>
  )
}

const MAnifestWithContainerDetails = ({
  data,
  isLoading,
  isRefetching
}: {
  data: any
  isLoading?: boolean
  isRefetching?: boolean
}) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Line.No</th>
            <th>Spare Part Number</th>
            <th>Spare Part Description</th>
            <th>Quantity Requested</th>
            <th>Container ID</th>
          </tr>
        </thead>
        <tbody>
          <>
            {isLoading || isRefetching ? (
              <tr>
                <td colSpan={12}>
                  <Row
                    style={{
                      height: 500,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Spinner />
                  </Row>
                </td>
              </tr>
            ) : (
              data?.length > 0 &&
              data?.map((w: any, index: number) => (
                <React.Fragment>
                  <tr key={w?._id}>
                    <td>{incrementby1(index)}</td>
                    <td>{w?.lineCode}</td>
                    <td>{w?.spare?.['Spare Part Number']}</td>
                    <td>{w?.spare?.['Spare Part Description']}</td>
                    <td>{w?.['quantityRequested']}</td>
                    <td>{w?.['containerID']}</td>
                  </tr>
                </React.Fragment>
              ))
            )}
          </>
        </tbody>
      </Table>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Old Quotations Found
          </p>
        </Row>
      )}
    </>
  )
}

export default function ViewManifest() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const [createManifest, setCreateManifest]: any = useState(false)
  const [spares, setSpares] = useState([])
  const componentRef: any = useRef()

  const fetchto = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/inventory/cm/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('toDetails', fetchto)

  const toData = data?.toDetails

  useEffect(() => {
    setSpares(toData?.spares)
  }, [data])

  const trasnportOrderDetails: any = {
    'TO Number': toData?.number?.replace('TO', 'CM'),
    'Store Keeper Name': toData?.storeKeeper,
    'Store Keeper Id': toData?.storeKeeper,
    'Warehouse Id': toData?.wareHouseId || 'KAK -004',
    'Warehouse Location': toData?.wareHouseLocation || 'Kakinada',
    'Created On': getReadableTime(toData?.manifestCreatedOn),
    'Unit /Vessel': toData?.unit
  }

  return (
    <ProtectedLayout
      onBack="/purchase/consignmentManifest"
      title={'Consignment Manifest'}
    >
      <div ref={componentRef} style={{ margin: 15 }}>
        <Row style={{ margin: 15 }}>
          <Box heading="Consignment Manifest Details">
            {Object.keys(trasnportOrderDetails)?.map((key: any) =>
              renderData(key, trasnportOrderDetails[key])
            )}
          </Box>
          <Box heading="Purchase Orders">
            <EnterContainer
              data={toData?.spares}
              showNote={true}
              spares={spares}
              setSpares={setSpares}
            />
          </Box>
          <Box heading="Acknowledgment">
            <Row>
              <Col xs="6">
                <Text>For and behalf of </Text>
                <Text>{toData?.storeKeeper} - OFFSHORE Store Keeper</Text>
                <Text>Agreed and Accepted by</Text>
                <br />
                <Text>Name:___________________________ </Text>
                <Text>Title:____________________________</Text>
                <Text>Date:___________________________ </Text>
              </Col>
              <Col xs="6">
                <Text>For and behalf of </Text>
                <Text>{toData?.logisticsCoordinator}</Text>
                <Text>Agreed and Accepted by</Text>
                <br />
                <Text>Name:___________________________ </Text>
                <Text>Title:____________________________ </Text>
                <Text>Date:___________________________ </Text>
              </Col>
            </Row>
          </Box>
        </Row>
      </div>
      <ReactToPrint
        documentTitle={`Transport Number - ${toData?.number}`}
        pageStyle={'padding: 20'}
        trigger={() => (
          <ModalFooter>
            <Button color="warning">Print</Button>
          </ModalFooter>
        )}
        content={() => componentRef.current}
      />
      {toData?.status === 'consignmentManifestCreated' && (
        <ModalFooter>
          <Button onClick={() => setCreateManifest(true)} color="warning">
            Close Consignment Manifest
          </Button>
        </ModalFooter>
      )}
      <SubmitModalWithForm
        isOpen={createManifest}
        onClose={(val: any) => {
          setCreateManifest(false)
          // val && navigate('/purchase/dashboard')
        }}
        form={
          <Row>
            <Col xs="12">
              <MAnifestWithContainerDetails data={spares} />
            </Col>
          </Row>
        }
        onSubmit={async (e: any) => {
          e.preventDefault()

          const body: any = {
            orders: spares,
            latestupdatedBy: userData?.id,
            id: toData?._id
          }

          try {
            const res = await makeAuthenticatedRequest(
              `${stock}/inventory/closeManifest`,
              'POST',
              body,
              accounts,
              instance
            )
            if (res.status === 'success') {
              await refetch()
            }
            return res.status
          } catch (error) {}
        }}
        // size="xs"
        fullscreen
        header="Close Manifest"
      />
    </ProtectedLayout>
  )
}
