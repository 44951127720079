import React from 'react'
import { Table } from 'reactstrap'
import { ColoredSubHeader } from './ViewExcavationPermit'

type ViewExcavationType = {
  data: any
  rowStyle: any
}

export default function ViewPBUReadings({
  data,
  rowStyle
}: ViewExcavationType) {
  const continous: any = [
    '7AM',
    '8AM',
    '9AM',
    '10AM',
    '11AM',
    '12PM',
    '1PM',
    '2PM',
    '3PM',
    '4PM',
    '5PM'
  ]

  return (
    <>
      <ColoredSubHeader rowStyle={rowStyle} text="PBU Readings" />
      <Table bordered striped style={{ borderColor: '#000', fontSize: 14 }}>
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            <th>S.No</th>
            <th>Isolation Point</th>
            {continous?.map((c: any) => <th key={c}>{c}</th>)}
          </tr>
        </thead>
        <tbody>
          {data?.map((d: any, index: any) => (
            <tr key={d._id}>
              <td>{index + 1}</td>
              <td>{d.isolationPoint}</td>
              {continous?.map((c: any) => <td key={c}>{d.readings[c]}</td>)}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}
