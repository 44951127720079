import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { useState } from 'react'

import RcmLayout from '../../components/rcm/RcmLayout'
import Spacer from '../../components/Spacer'
import toNormalCase from '../../components/lib/toNormalCase'
import { assetCriticalityList } from './rcmConstants'
import { Box, Link, renderData } from '../workManagement/PmTree'
import { SubHeader } from '../VerifyCM'
import CustomLabel from '../../components/InputFields/CustomLabel'
import {
  HIGH_RISK_COLOR,
  MEDIUM_RISK_COLOR,
  NO_RISK_COLOR
} from '../../components/constants/riskMatrixColors'

const colors: any = {
  LOW: NO_RISK_COLOR,
  MEDIUM: MEDIUM_RISK_COLOR,
  HIGH: HIGH_RISK_COLOR
}

const numberKey: any = {
  equipment: 'equipmentNumber',
  maintainableItems: 'itemNumber',
  tags: 'tagNo'
}

const descriptionKey: any = {
  equipment: 'equipmentDescription',
  maintainableItems: 'itemDescription',
  tags: 'tagDescription'
}

const mtbfOptions = ['Extreme', 'High', 'Medium', 'Low', 'Negligible']

const envCons = [
  'Massive Effect',
  'Major Effect',
  'Localized Effect',
  'Minor Effect',
  'Slight Effect'
]

const healthCons = [
  'Multiple Fatality',
  'Single Fatality',
  'Major Injury',
  'Minor Injury',
  'Slight Injury'
]

const ecCons = [
  'Exteme (> 100M USD)',
  'High Effect (10-100M USD)',
  'Medium Effect (1-10M USD)',
  'Low Effect (0.1-1M USD)',
  'Negligible Effect < 100K USD'
]

const maintainabilityOptions = [
  'Very Low',
  'Low',
  'Moderate',
  'High',
  'Very High'
]

const YesNo = ['Yes', 'No']

const operatingOptions = ['100%', '< 75%', '< 50%', '< 25%']

const spareOptions = [
  '> 180 Days',
  '90 - 180 Days',
  '30 - 90 Days',
  '15 - 30 Days'
]

const ReAssessModal = ({ isOpen, onClose, onSave, item }: any) => {
  const [values, setValues]: any = useState(item)
  const handleValueChange = (key: any, value: any) =>
    setValues({ ...values, [key]: value })

  function calculate() {
    const {
      mtbfValue,
      envConsValue,
      helthValue,
      ecValue,
      mValue,
      redValue,
      ocValue,
      spareValue,
      hfValue
    } = values || {}
    const weightedAverage =
      0.25 * envConsValue + 0.5 * helthValue + 0.25 * ecValue
    const result =
      (mtbfValue *
        weightedAverage *
        mValue *
        redValue *
        ocValue *
        spareValue *
        hfValue) /
      100
    return result
    // return result > 1 ? result : 1;
  }

  const criticalityLevel = (result: any) => {
    if (result >= 1 && result <= 467) {
      return 'LOW'
    } else if (result >= 468 && result <= 623) {
      return 'MEDIUM'
    } else if (result >= 624) {
      return 'HIGH'
    }
    return 'UNKNOWN' // Optional, for cases where 'result' doesn't fall in any range
  }

  return (
    <Modal isOpen={isOpen} fullscreen>
      <ModalBody>
        <SubHeader header="Re Asses Critiality" permitColor="gold" />
        <Box heading={`${toNormalCase(item?.type)} Details`}>
          {[
            {
              label: 'Type :',
              value: toNormalCase(item?.type)
            },
            {
              label: 'Number :',
              value: item[numberKey[item?.type]]
            },
            {
              label: 'Description :',
              value: item[descriptionKey[item?.type]]
            }
          ]?.map((i: any) => renderData(i?.label, i?.value))}
        </Box>
        <Row>
          <Col xs="4">
            <CustomLabel label="Probability of Failure(MTBF)" />
            <Input
              type="select"
              value={item?.mtbf}
              onChange={(e) => {
                handleValueChange('mtbf', e?.target?.value)
                handleValueChange(
                  'mtbfValue',
                  5 - mtbfOptions?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {mtbfOptions?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input>
          </Col>
          <Col xs="4">
            <CustomLabel label="Consequence Environment" />
            <Input
              type="select"
              value={item?.environmentConsequence}
              onChange={(e) => {
                handleValueChange('environmentConsequence', e?.target?.value)
                handleValueChange(
                  'envConsValue',
                  5 - envCons?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {envCons?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input>
          </Col>
          <Col xs="4">
            <CustomLabel label="Consequence Health & Safety" />
            <Input
              type="select"
              value={item?.healthSafetyConsequence}
              onChange={(e) => {
                handleValueChange('healthSafetyConsequence', e?.target?.value)
                handleValueChange(
                  'helthValue',
                  5 - healthCons?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {healthCons?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input>
          </Col>
          <Col xs="4">
            <CustomLabel label="Consequence Economic" />
            <Input
              type="select"
              value={item?.economicConsequence}
              onChange={(e) => {
                handleValueChange('economicConsequence', e?.target?.value)
                handleValueChange(
                  'ecValue',
                  5 - ecCons?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {ecCons?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input>
          </Col>
          <Col xs="4">
            <CustomLabel label="Maintainability" />
            <Input
              type="select"
              value={item?.maintainability}
              onChange={(e) => {
                handleValueChange('maintainability', e?.target?.value)
                handleValueChange(
                  'mValue',
                  5 - maintainabilityOptions?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {maintainabilityOptions?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input>
          </Col>
          <Col xs="4">
            <CustomLabel label="Redundancy Available" />
            <Input
              value={item?.redundancy}
              type="select"
              onChange={(e) => {
                handleValueChange('redundancy', e?.target?.value)
                handleValueChange(
                  'redValue',
                  5 - YesNo?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {YesNo?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input>
          </Col>
          <Col xs="4">
            <CustomLabel label="Operating Capacity" />
            <Input
              type="select"
              value={item?.operatingCapacity}
              onChange={(e) => {
                handleValueChange('operatingCapacity', e?.target?.value)
                handleValueChange(
                  'ocValue',
                  5 - operatingOptions?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {operatingOptions?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input>
          </Col>
          <Col xs="4">
            <CustomLabel label="Spare Lead time" />
            <Input
              type="select"
              value={item?.spareLeadTime}
              onChange={(e) => {
                handleValueChange('spareLeadTime', e?.target?.value)
                handleValueChange(
                  'spareValue',
                  5 - spareOptions?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {spareOptions?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input>
          </Col>
          <Col xs="4">
            <CustomLabel label="Prone to Hidden Failures" />
            <Input
              type="select"
              value={item?.hiddenFailures}
              onChange={(e) => {
                handleValueChange('hiddenFailures', e?.target?.value)
                handleValueChange(
                  'hfValue',
                  5 - YesNo?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {['Yes', 'No']?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={() => onClose()}>
          Close
        </Button>
        <Button
          color="warning"
          onClick={() => {
            const total = calculate()
            const level = criticalityLevel(total)
            onSave(item?.index, item, values, total, level)
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const AssetCriticalityTable = ({
  item,
  entries,
  setModal,
  setCurrentItem
}: {
  item: any
  entries: any
  setModal: any
  setCurrentItem: any
}) => {
  return (
    <>
      <h4 style={{ fontWeight: 700 }}>{toNormalCase(item)}</h4>
      <div
        style={{
          backgroundColor: '#fff',
          borderRadius: 10,
          maxHeight: '90vh',
          overflow: 'scroll'
        }}
      >
        <Table striped className="text-center">
          <thead>
            <tr style={{ backgroundColor: 'lightgray' }}>
              <th className="top-left" style={{ width: '3vw' }}>
                S.No
              </th>
              <th style={{ width: '10vw' }}>Number</th>
              <th style={{ width: '25vw' }}>Description</th>
              <th style={{ width: '10vw' }}>Manufacturer</th>
              <th style={{ width: '10vw' }}>Current Criticality</th>
              <th style={{ width: '10vw' }}>New Criticality Score</th>
              <th style={{ width: '10vw' }}>New Criticality Level</th>
              <th className="top-right" style={{ width: '10vw' }} />
            </tr>
          </thead>
          <tbody>
            {entries?.[item]?.map((entry: any, index: any) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{entry?.[numberKey[item]]}</td>
                <td>{entry?.[descriptionKey[item]]}</td>
                <td>{entry?.manufacturer}</td>
                <td>{entry?.criticality}</td>
                <th>{entry?.totalValue}</th>
                <th style={{ backgroundColor: colors[entry?.riskCategory] }}>
                  {entry?.riskCategory}
                </th>
                <td>
                  <Link
                    onClick={() => {
                      setModal(true)
                      setCurrentItem({ ...entry, type: entry, index: index })
                    }}
                  >
                    Calculate New Criticality
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default function AssetCriticality() {
  const [modal, setModal] = useState(false)
  const [currentItem, setCurrentItem] = useState({})
  const [entries, setEntries]: any = useState(assetCriticalityList)

  return (
    <RcmLayout onBack={'/cmms/rcm'} title={'Asset Criticality'}>
      <Row style={{ margin: 15 }}>
        <style>
          {`
          table {
            width: 100%;
            border-spacing: 0; 
            border-collapse: separate;
            font-family: Helvetica;
          }

          .top-left {
            border: 2px solid lightgray;
            border-top-left-radius: 10px;
          }

          .top-right {
            border: 2px solid lightgray;
            border-top-right-radius: 10px;
          }
          `}
        </style>
        <Spacer height={20} />
        <Col md={6}>
          <AssetCriticalityTable
            item={'equipment'}
            setModal={setModal}
            setCurrentItem={setCurrentItem}
            entries={entries}
          />
          <Spacer height={20} />
          <AssetCriticalityTable
            item={'maintainableItems'}
            setModal={setModal}
            setCurrentItem={setCurrentItem}
            entries={entries}
          />
        </Col>
        <Col md={6}>
          <AssetCriticalityTable
            item={'tags'}
            setModal={setModal}
            setCurrentItem={setCurrentItem}
            entries={entries}
          />
        </Col>
      </Row>
      <ReAssessModal
        isOpen={modal}
        onClose={() => setModal(false)}
        onSave={(
          itemIndex: any,
          item: any,
          values: any,
          total: any,
          level: any
        ) => {
          const updatedEntries = [...entries[item?.type]]
          const newEntries = updatedEntries?.map((u: any, index: any) =>
            index === itemIndex
              ? { ...item, ...values, totalValue: total, riskCategory: level }
              : u
          )
          setEntries((prev: any) => ({ ...prev, [item?.type]: newEntries }))
          setModal(false)
        }}
        item={currentItem}
      />
    </RcmLayout>
  )
}
