import { Table } from 'reactstrap'
import Loader from '../../components/Loader'
import React from 'react'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

export default function FIRSTable({ data, isLoading, isRefetching }: any) {
  const navigate = useNavigate()
  const headers: any = [
    'S.No',
    'FIRS Number',
    'Originator',
    'Tag Number',
    'Department',
    'Location',
    'Created On'
  ]

  if (isLoading || isRefetching) {
    return <Loader />
  }

  return (
    <>
      <Table bordered striped style={{ borderColor: '#000', fontSize: 14 }}>
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            {headers?.map((h: any) => <th key={h}>{h}</th>)}
          </tr>
        </thead>
        <tbody>
          {isLoading || isRefetching ? (
            <Loader />
          ) : (
            data?.reports?.map((d: any, index: number) => (
              <React.Fragment key={d?.woNumber}>
                <tr>
                  <td>{incrementby1(index)}</td>
                  <td
                    style={{
                      textDecoration: 'underline',
                      color: 'blue',
                      cursor: 'pointer'
                    }}
                    onClick={() => navigate(`${d?._id}`)}
                  >
                    {d?.woNumber}
                  </td>
                  <td>{d?.stage1?.originator}</td>
                  <td>{d?.stage1?.tagNumber}</td>
                  <td>{d?.stage1?.department}</td>
                  <td>{d?.stage1?.location}</td>
                  <td>{moment(d?.createdAt).format('DD-MM-YYYY')}</td>
                </tr>
              </React.Fragment>
            ))
          )}
        </tbody>
      </Table>
    </>
  )
}
