import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Row } from 'reactstrap'
import { makeAuthenticatedRequest } from '../../apiRequests'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import Loader from '../../components/Loader'
import ViewClosedReport from '../../components/previews/ViewClosedReport'
import { epermitCloseEndPoint } from '../../components/serverurl'

export default function LessonLearntPage() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()

  const fetchReport = async () => {
    const response = await makeAuthenticatedRequest(
      `${epermitCloseEndPoint}/report?id=${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response?.data
  }

  const { data, isLoading }: any = useQuery('riskAssessments', fetchReport)

  return (
    <ProtectedLayout onBack="/eptw/lessonLearnt">
      {isLoading ? (
        <Loader />
      ) : (
        <Row style={{ margin: 10 }}>
          <ViewClosedReport data={data?.report} userData={data?.userData} />
        </Row>
      )}
    </ProtectedLayout>
  )
}
