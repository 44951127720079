// [
//   {
//     "label": "Dashboard",
//     "routeName": "dashboard"
//   },
//   {
//     "label": "Information Card",
//     "routeName": "informationCard/create"
//   },
//   {
//     "label": "Permit Audit",
//     "routeName": "permitAudit/create"
//   },
//   {
//     "label": "View By Layout",
//     "routeName": "viewByLayout"
//   },
//   {
//     "label": "Lesson Learnt",
//     "routeName": "lessonLearnt"
//   },
//   {
//     "label": "Loto Register",
//     "routeName": "lotoRegister"
//   },
//   {
//     "label": "Closed Permits",
//     "routeName": "closedPermits"
//   },
//   {
//     "label": "Analytics",
//     "routeName": "analytics"
//   }
// ]

export const Routes: any = [
  {
    label: 'Dashboard',
    routeName: 'dashboard'
  },
  {
    label: 'Information Card',
    routeName: 'informationCard/create'
  },
  {
    label: 'View By Layout',
    routeName: 'viewByLayout'
  },
  {
    label: 'Lesson Learnt',
    routeName: 'lessonLearnt'
  },
  {
    label: 'Loto Register',
    routeName: 'lotoRegister'
  },
  {
    label: 'Closed Permits',
    routeName: 'closedPermits'
  },
  {
    label: 'Analytics',
    routeName: 'analytics'
  }
]

export const WarehouseRoutes: any = [
  {
    label: 'Material Request',
    routeName: 'materialRequest'
  },
  {
    label: 'Stock Register',
    routeName: 'stockRegister'
  },
  {
    label: 'Stock Control',
    routeName: 'stockControl'
  },
  {
    label: 'Stock Analytics',
    routeName: 'stockAnalytics'
  }
]

export const PurchaseRoutes: any = [
  {
    label: 'Purchase Request',
    routeName: 'materialRequest'
  },
  {
    label: 'Purchase Order',
    routeName: 'stockRegister'
  },
  {
    label: 'Change Order',
    routeName: 'stockControl'
  },
  {
    label: 'Purchase Analytics',
    routeName: 'stockAnalytics'
  }
]

export const PMJobsRoutes: any = [
  {
    label: 'Create New PM',
    routeName: 'materialRequest'
  },
  {
    label: 'Job Plans',
    routeName: '/planning/pmjobs/jobPlans'
  }
]

export const LookAheadRoutes: any = [
  {
    label: '14 days',
    routeName: 'materialRequest'
  },
  {
    label: '3 Months',
    routeName: 'stockRegister'
  },
  {
    label: '6 Months',
    routeName: 'stockControl'
  },
  {
    label: 'Yearly',
    routeName: 'stockAnalytics'
  }
]

export const WorkManagementRoutes: any = [
  {
    label: 'Corrective Maintenance',
    routeName: 'correctiveMaintenance'
  },
  {
    label: 'Preventive Maintenance',
    routeName: 'preventiveMaintenance'
  }
]
