import { Col, Row } from 'reactstrap'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { Form } from 'react-final-form'
import LabeledTextInput from '../../components/InputFields/LabeledTextInput'
import Spacer from '../../components/Spacer'
import { yesNoOptions } from '../eptw/VerifyPermit'
import LabeledRadioButtons from '../../components/InputFields/LabeledRadioButton'
import LabledParagraphInput from '../../components/InputFields/LabledParagraph'
import LabeledCheckBoxes from '../../components/InputFields/LabeledCheckBoxes'
import { getOptions } from '../firs'
import EnchancedMaterialTabs from './EnchancedMaterialTabs'
const initalValues = {
  itemNumber: '019157',
  storeRoom: 'MAB_MAIN',
  batchNumber: '1 Rev 1',
  submissionDate: '09-09-2024',
  manufacturer: 'BENTLY NEVADA',
  vendor: 'DRESSOR-RAND S.A',
  modelNumber: '',
  partNumber: 'BA2150400.08',
  uomIssue: 'EACH',
  uomPurchase: 'EACH',
  itemType: 'MS_COMPRSR',
  noun: 'Compressor',
  modifierOne: 'Spare Part',
  modifierTwo: 'No Mod',
  modifierThree: 'No Mod',
  shortDescription: 'KEYPHASOR - ComPressor,BA215040.08.1200,452B9,WITHOUT BOX',
  longDescription:
    'COMPRESSOR TAG NO: c-17-101, SERIAL NO: 451.9.5023F; ITEM MANUFACTURER: BENTLY'
}

export default function Viewitem() {
  return (
    <>
      <ProtectedLayout
        onBack="/cmms/supplyChain/itemmaster"
        title="ENCHANCED MATERIAL CATALOGUE"
      >
        <Row style={{ margin: 15 }}>
          <Col>
            <Form
              initialValues={initalValues}
              onSubmit={(values: any) => {}}
              render={({ handleSubmit, form, hasValidationErrors, values }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <LabeledTextInput
                      name="itemNumber"
                      label="Item Number"
                      occupy={3}
                    />
                    <LabeledTextInput
                      name="storeRoom"
                      label="Store Room"
                      occupy={3}
                    />
                    <LabeledTextInput
                      name="batchNumber"
                      label="Batch Room"
                      occupy={3}
                    />
                    <LabeledTextInput
                      name="submissionDate"
                      label="Date of Submission"
                      occupy={3}
                    />
                    <LabeledTextInput
                      name="manufacturer"
                      label="Manufacturer"
                      occupy={3}
                    />
                    <LabeledTextInput name="vendor" label="Vendor" occupy={3} />
                    <Spacer height={20} />
                    <hr />
                    <LabeledTextInput
                      name="modelNumber"
                      label="Model Number"
                      occupy={3}
                    />
                    <LabeledTextInput
                      name="partNumber"
                      label="Part Number"
                      occupy={3}
                    />
                    <LabeledTextInput
                      name="uomIssue"
                      label="Uom Issue"
                      occupy={3}
                    />
                    <LabeledTextInput
                      name="uomPurchase"
                      label="Uom Purchase"
                      occupy={3}
                    />
                    <LabeledTextInput
                      name="itemType"
                      label="Item Type"
                      occupy={3}
                    />
                    <LabeledTextInput
                      name="mescNumber"
                      label="MESC Number"
                      occupy={3}
                    />
                    <LabeledTextInput
                      name="shelfLife"
                      label="Shelf Life"
                      occupy={3}
                    />
                    <Spacer height={20} />
                    <hr />
                    <LabeledTextInput name="noun" label="Noun" occupy={3} />
                    <LabeledTextInput
                      name="modifierOne"
                      label="Modifier 1"
                      occupy={3}
                    />
                    <LabeledTextInput
                      name="modifierTwo"
                      label="Modifier 2"
                      occupy={3}
                    />
                    <LabeledTextInput
                      name="modifierThree"
                      label="Modifier 3"
                      occupy={3}
                    />
                    <Spacer height={30} />
                    <Col xs={12} md={7}>
                      <EnchancedMaterialTabs />
                    </Col>
                    <Col xs={12} md={5}>
                      <LabeledRadioButtons
                        name="manufacturerDiscripencies"
                        label="Manufactuer Discrepencies"
                        list={yesNoOptions}
                        occupy={12}
                      />
                      <LabledParagraphInput
                        label="Short Description"
                        name="shortDescription"
                        occupy={12}
                        height={'10vh'}
                      />
                      <LabledParagraphInput
                        label="long Description"
                        name="longDescription"
                        occupy={12}
                        height={'10vh'}
                      />
                      <LabledParagraphInput
                        label="KNPC Remarks"
                        name="knpcRemarks"
                        occupy={12}
                        height={'10vh'}
                      />
                      <LabeledCheckBoxes
                        name="dataType"
                        label="Manufactuer Discrepencies"
                        list={getOptions(['EXISTING DATA', 'ENCHANCED DATA'])}
                        occupy={12}
                      />
                    </Col>
                  </Row>
                </form>
              )}
            />
          </Col>
        </Row>
      </ProtectedLayout>
    </>
  )
}
