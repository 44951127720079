import { levelThreeRiskMatrixColors, levelOneRisksMatrixColors, levelTwoRiskMatrixColors, hoecRiskMatrixColors } from '../constants/riskMatrixColors'
import equipmentList from './../constants/equipmentList.json'

export const getRiskLevel = (equipment: string) => {
  const selectedEquipment : any = equipmentList.filter((e : any) => e.label === equipment)

  return selectedEquipment?.[0]?.riskLevel
}

export const getRiskMatrixColors = (riskLevel : number) => {
  if (riskLevel === 1) {
    return levelOneRisksMatrixColors
  }  else if (riskLevel === 2) {
    return levelTwoRiskMatrixColors
  }
   else if (riskLevel === 3) {
    return levelThreeRiskMatrixColors
  } else return hoecRiskMatrixColors
}

export const getRiskColor = (equipment : string, risk: string) => {
  const riskLevel = getRiskLevel(equipment)

  if (riskLevel === 1) {
    return levelOneRisksMatrixColors[risk]
  }  else if (riskLevel === 2) {
    return levelTwoRiskMatrixColors[risk]
  }
   else if (riskLevel === 3) {
    return levelThreeRiskMatrixColors[risk]
  } else return levelOneRisksMatrixColors[risk]
}