import { Route, Routes, useNavigate } from "react-router-dom";
import ApprovePO from "../pages/inventory/ApprovePO";
import CMList from "../pages/inventory/CMList";
import CreateConsignmentManifest from "../pages/inventory/CreateConsignmentManifest";
import CreatePO from "../pages/inventory/CreatePO";
import CreateTransportOrder from "../pages/inventory/CreateTransportOrder";
import DSList from "../pages/inventory/DSList";
import PrintPO from "../pages/inventory/PrintPo";
import PRList from "../pages/inventory/PRList";

import PurchaseDashboard from "../pages/inventory/PurchaseDashboard";
import PurchaseRequestForm from "../pages/inventory/PurchaseRequestForm";
import TOList from "../pages/inventory/TOList";
import VendorPaymentRequests from "../pages/inventory/VendorPaymentRequests";
import Vendors from "../pages/inventory/Vendors";
import VerifyStock from "../pages/inventory/VerifyStock";
import ViewManifest from "../pages/inventory/ViewManifest";
import ViewPaymentRequest from "../pages/inventory/ViewPaymentRequest";
import ViewPO from "../pages/inventory/ViewPO";
import ViewPR from "../pages/inventory/ViewPR";
import ViewRFQ from "../pages/inventory/ViewRFQ";
import ViewTransportOrder from "../pages/inventory/ViewTransportOrder";
import VendorQuotationsList from "../pages/inventory/VendorQuotationslist";
import ViewQuotations from "../pages/inventory/ViewQuotations";
import ApproveRFQ from "../pages/inventory/ApproveRFQ";
import ApprovedPORFQ from "../pages/inventory/ApprovedPORFQ";
import { useEffect } from "react";

export function RedirectToPurchase() {
  const navigate = useNavigate();

  useEffect(() => navigate(`/purchase/dashboard`), []);

  return null;
}

export default function PurchaseRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RedirectToPurchase />} />
      <Route path="/dashboard" element={<PurchaseDashboard />} />
      <Route path="/create" element={<PurchaseRequestForm />} />
      <Route
        path="/approve"
        element={
          <PRList
            onBack="/purchase/dashboard"
            title="Purchase Requests"
            url="/purchase/approve"
            status="created"
            po={false}
          />
        }
      />
      <Route
        path="/approve/:id"
        element={<ViewPR title={"Approve Purchase Request"} />}
      />
      <Route
        path="/approved"
        element={
          <PRList
            onBack="/purchase/dashboard"
            title="Purchase Requests"
            url="/purchase/approve"
            status="approved"
            po={false}
          />
        }
      />
      <Route
        path="/approved/:id"
        element={<ViewPR title={"Approved Purchase Request"} />}
      />
      <Route
        path="/rfq"
        element={
          <PRList
            onBack="/purchase/dashboard"
            title="REQUEST FOR QUOTATION"
            url="/purchase/rfq"
            status="approvedPR"
            po={false}
          />
        }
      />
      <Route
        path="/rfq/:id"
        element={<ViewRFQ title={"Request For Quotation"} />}
      />
      <Route
        path="/po"
        element={
          <PRList
            onBack="/purchase/dashboard"
            title="PURCHASE ORDER"
            url="/purchase/po"
            status="approved"
            po={false}
          />
        }
      />
      <Route path="/po/:id" element={<CreatePO />} />
      <Route
        path="/wpo"
        element={
          <VendorQuotationsList
            onBack="/purchase/dashboard"
            title="LEVEL ONE PO LIST"
            url="/purchase/wpo"
            status="vendorFinalized"
            po
          />
        }
      />
      <Route
        path="/wpo/:id"
        element={
          <ApproveRFQ
            // nextStatus={'approvedLevelOnePO'}
            // buttonText="Approve Level One PO"
            hasPrint
          />
        }
      />
      {/* <Route
        path="/wpo"
        element={
          <PRList
            onBack="/purchase/dashboard"
            title="PURCHASE ORDER"
            url="/purchase/wpo"
            status="createdPO"
          />
        }
      /> */}
      <Route
        path="/apoLevelTwo"
        element={
          <PRList
            onBack="/purchase/dashboard"
            title="LEVEL 2 PO LIST"
            url="/purchase/apoLevelTwo"
            status="approvedLevelOnePO"
            po
          />
        }
      />
      <Route
        path="/apoLevelTwo/:id"
        element={
          <ApproveRFQ
            // nextStatus={'approvedLevelTwoPO'}
            // buttonText="Approve Level Two PO"
            hasPrint
          />
        }
      />
      <Route
        path="/apoLevelThree"
        element={
          <PRList
            onBack="/purchase/dashboard"
            title="LEVEL 3 PO LIST"
            url="/purchase/apoLevelThree"
            status="approvedLevelTwoPO"
            po
          />
        }
      />
      <Route
        path="/apoLevelThree/:id"
        element={
          <ApprovePO
            // nextStatus={'approvedLevelThreePO'}
            // buttonText="Approve Level Three PO"
            hasPrint
          />
        }
      />

      <Route
        path="/apo"
        element={
          <VendorQuotationsList
            onBack="/purchase/dashboard"
            title="APPROVED PO LIST"
            url="/purchase/apo"
            status="approvedPO"
            po
          />
        }
      />

      <Route path="/apo/:id" element={<ApprovedPORFQ hasPrint />} />
      <Route
        path="/ipo"
        element={
          <PRList
            onBack="/purchase/dashboard"
            title="PURCHASE ORDER"
            url="/purchase/ipo"
            status="issuedPO"
            po
          />
        }
      />
      <Route path="/ipo/:id" element={<PrintPO hasPrint />} />
      <Route
        path="/rfqVendors"
        element={
          <VendorQuotationsList
            onBack="/purchase/dashboard"
            title="RECEIVED QUOTATIONS LIST"
            url="/purchase/rfqVendors"
            status="requestedForQuotation"
            po
          />
        }
      />
      <Route
        path="/rfqVendors/:id"
        element={
          <ViewQuotations
            nextStatus={"vendorFinalized"}
            buttonText="Finalize Vendor"
          />
        }
      />
      <Route path="/vendors" element={<Vendors />} />
      <Route
        path="/vendorAcceptedPO"
        element={
          <CreateTransportOrder
            onBack="/purchase/dashboard"
            title="VENDOR ACCEPTED ORDERS"
            url="/purchase/vendorAcceptedPO"
            status="acceptedByVendor"
            po
          />
        }
      />
      <Route
        path="/createdTos"
        element={
          <TOList
            onBack="/purchase/dashboard"
            title="CREATED TO LIST"
            url="/purchase/createdTos"
            status="transportOrderCreated"
            po
          />
        }
      />
      <Route path="/createdTos/:id" element={<ViewTransportOrder />} />
      <Route
        path="/sparesReceivedOnshore"
        element={
          <TOList
            onBack="/purchase/dashboard"
            title="LIST OF SPARES RECEIVED ONSHORE"
            url="/purchase/sparesReceivedOnshore"
            status="sparesReceived"
            po
          />
        }
      />
      <Route
        path="/sparesReceivedOnshore/:id"
        element={<CreateConsignmentManifest />}
      />
      <Route
        path="/consignmentManifest"
        element={
          <CMList
            onBack="/purchase/dashboard"
            title="CONSIGNMENT MANIFEST LIST"
            url="/purchase/consignmentManifest"
            status="consignmentManifestCreated"
            po={false}
            cm
          />
        }
      />
      <Route path="/consignmentManifest/:id" element={<ViewManifest />} />
      <Route
        path="/verifyStock"
        element={
          <CMList
            onBack="/purchase/dashboard"
            title="VERIFY STOCK LIST"
            url="/purchase/verifyStock"
            status="consignmentManifestClosed"
            po={false}
            cm
          />
        }
      />
      <Route path="/verifyStock/:id" element={<VerifyStock />} />
      <Route
        path="/closedPO"
        element={
          <PRList
            onBack="/purchase/dashboard"
            title="PURCHASE ORDER"
            url="/purchase/closedPO"
            status="poCompleted"
            po
          />
        }
      />
      <Route path="/closedPO/:id" element={<ViewPO hasPrint />} />
      <Route
        path="/discripancies"
        element={
          <DSList
            onBack="/purchase/dashboard"
            title="DISCREPANCIES"
            url="/purchase/descripancies"
            po
          />
        }
      />
      <Route
        path="/vendorPayments"
        element={
          <VendorPaymentRequests
            onBack="/purchase/dashboard"
            title="PAYMENT REQUESTS"
            url="/purchase/payments"
            status="invoiceSent"
          />
        }
      />
      <Route
        path="/vendorPayments/:id"
        element={
          <ViewPaymentRequest
            nextStatus="verifiedPayment"
            onBack="/purchase/vendorPayments"
          />
        }
      />
      <Route
        path="/approvePayments"
        element={
          <VendorPaymentRequests
            onBack="/purchase/dashboard"
            title="PAYMENTS LIST"
            url="/purchase/payments"
            status="verifiedPayment"
          />
        }
      />
      <Route
        path="/approvePayments/:id"
        element={
          <ViewPaymentRequest
            nextStatus="approvedPayment"
            onBack="/purchase/approvePayments"
          />
        }
      />
      <Route
        path="/approvedPayments"
        element={
          <VendorPaymentRequests
            onBack="/purchase/dashboard"
            title="PAYMENTS LIST"
            url="/purchase/payments"
            status="approvedPayment"
          />
        }
      />
      <Route
        path="/approvedPayments/:id"
        element={
          <ViewPaymentRequest
            nextStatus="jobOrderClosed"
            onBack="/purchase/approvedPayments"
          />
        }
      />
    </Routes>
  );
}
