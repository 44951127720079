export default function NotFound() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh',
        alignItems: 'center'
      }}
    >
      <p>
        404
      </p>
      <p
        style={{
          borderRight: '2px solid #000',
          height: '4vh',
          margin: 15
        }} />
      <p>
        Page Not Found
      </p>
    </div>
  )
}