import React from 'react'
import { Stepper } from 'react-form-stepper'
import { Col, Row } from 'reactstrap'

export default function RcmStepper({ stage }: { stage: any }) {
  const sty = `.StepperContainer-0-2-1 {
  padding: 0px;}	`

  const stages: any = {
    Stage2: 2,
    Stage3: 3,
    Stage4: 4,
    Stage5: 5,
    Stage6: 6,
    Stage7: 7,
    Stage8: 8,
    Stage9: 9
  }

  return (
    <Row>
      <Col>
        <div
          style={{
            backgroundColor: '#fff',
            borderRadius: 10,
            padding: 10,
            paddingTop: 20,
            margin: 0
          }}
        >
          <style>{sty}</style>
          <Stepper
            steps={[
              { label: 'Select Boundary' },
              { label: 'Define Function' },
              { label: 'Functional Failure' },
              { label: 'Define Risk' },
              { label: 'Assign Task' },
              { label: 'Task Decision' },
              { label: 'Clamp Recomendation' },
              { label: 'Task Approval' }
            ]}
            styleConfig={{
              completedBgColor: 'gold',
              activeBgColor: 'green',
              activeTextColor: '#000',
              completedTextColor: '#000',
              inactiveBgColor: '#e0e0e0',
              inactiveTextColor: '#000',
              size: '2rem',
              circleFontSize: '1rem',
              labelFontSize: '0.875rem',
              borderRadius: '50%',
              fontWeight: 500
            }}
            activeStep={stages[stage] - 2}
          />
        </div>
      </Col>
    </Row>
  )
}
