import { Route, Routes } from 'react-router-dom'

import AuditList from '../pages/audits/AuditList'

export default function AuditRoutes() {
  return (
    <Routes>
      <Route path="/created" element={<AuditList auditStatus="created" />} />
      <Route path="/approved" element={<AuditList auditStatus="approved" />} />
      <Route path="/rejected" element={<AuditList auditStatus="rejected" />} />
    </Routes>
  )
}
