import { useMsal } from '@azure/msal-react'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { PermissionsContext, UserContext } from '../../App'
import toNormalCase from '../../components/lib/toNormalCase'
import { countsEndpoint } from '../../components/serverurl'
import ViewIcons from '../../components/ViewIcons'

export default function CWOIActions() {
  const { permissions } = useContext(PermissionsContext)
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const userlocation = toNormalCase(permissions?.location)

  const permitsCountByflow = async () => {
    const response = await makeAuthenticatedRequest(
      `${countsEndpoint}/cwoi?department=${userData?.department}&location=${userlocation}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data }: any = useQuery('cwoiCount', permitsCountByflow)

  const counts = data?.counts

  const icons = [
    {
      name: 'VERIFY (CWOI)',
      src: require('./../../images/Icons/Verify_Close_without_Isolation.png'),
      nav: '/eptw/cwoi/verify',
      canView: permissions?.canVerifyCWOI,
      count: counts?.verify
    },
    {
      name: 'APPROVE (CWOI)',
      src: require('./../../images/Icons/Approve_Close_without_Isolation.png'),
      nav: '/eptw/cwoi/approve',
      canView: permissions?.canApproveCWOI,
      count: counts?.approve
    },
    {
      name: 'ISSUE (CWOI)',
      src: require('./../../images/Icons/Issue_Close_without_Isolation.png'),
      nav: '/eptw/cwoi/issue',
      canView: permissions?.canIssueCWOI,
      count: counts?.issue
    }
  ]

  return <ViewIcons icons={icons} />
}
