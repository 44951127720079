import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import ViewPermit from '../eptw/ViewPermit'
import { EmptyContent } from '../CreateNewPermit'
import { useAdminPermissions } from '../eptw/EditRisks'
import { Row, Spinner } from 'reactstrap'

export default function ClosedPermit() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  return (
    <ProtectedLayout onBack="/eptw/closedPermits">
      {data ? (
        <>
          {isLoading ? (
            <EmptyContent label={<Spinner />} />
          ) : (
            <ViewPermit data={data} isLoading={isLoading} />
          )}
        </>
      ) : (
        <EmptyContent label={<Spinner />} />
      )}
    </ProtectedLayout>
  )
}
