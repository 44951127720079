import { useState } from "react"
import { useForm } from "react-final-form"
import { Button, Col, Modal, ModalBody, ModalFooter, Row, Table } from "reactstrap"
import { hoecControlRiskMatrixColors, LOW_RISK_COLOR } from "../constants/riskMatrixColors"
import ModalTitle from "../ModalTitle"
import { Text } from "../RiskAssesmentPreview"

export default function ControlRiskMatrix({ values, name, equipment }: any) {
  const [isOpen, setIsOpen] = useState(false)
  const { change } = useForm();

  const toggle = () => setIsOpen(!isOpen)

  const renderDetails = (heading?: string, content?: string) => (
    <>
      <Text styles={{ textOVerflow: 'hidden' }}>{heading}</Text>
      {content && <Text>{content}</Text>}
    </>
  )

  const tableHeaders = [
    { title: 'Factor' },
    {
      title: '1',
      content: 'Exposure to Person < 5'
    },
    {
      title: '2',
      content: 'Exposure to Person 5-30'
    },
    {
      title: '3',
      content: 'Exposure to Person > 30'
    }
  ]

  const riskMatrixLevel1 = [

    {
      "factor": "Control applicable, but not Available",
      1: { text: '3', value: 'C3/P1' },
      2: { text: '6', value: 'C3/P2' },
      3: { text: '9', value: 'C3/P3' }
    },
    {
      "factor": "Control applicable, Available but not Effective",
      1: { text: '2', value: 'C2/P1' },
      2: { text: '4', value: 'C2/P2' },
      3: { text: '6', value: 'C2/P3' }
    },
    {
      "factor": "Control applicable, Available & Effective",
      1: { text: '1', value: 'C1/P1' },
      2: { text: '2', value: 'C1/P2' },
      3: { text: '3', value: 'C1/P3' }
    }
  ]

  return (
    <>
      {
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 60,
            backgroundColor: Boolean(values) ? hoecControlRiskMatrixColors[values] : 'gray',
            cursor: 'pointer',
            color: hoecControlRiskMatrixColors[values] === LOW_RISK_COLOR ? '#000' : '#FFF',
          }}
          onClick={() => toggle()}
        >
          {Boolean(values) ? values : 'Set Risk'}
        </Col>
      }
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalBody>
          <ModalTitle title="QUALITATIVE RISK ASSESMENT MATRIX (CONTROL / PEOPLE)" />
          <Row>
            <Col xs="2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <p style={{ writingMode: 'vertical-rl', textOrientation: 'upright', textAlign: 'center', border: '1px solid #000', marginLeft: 0, fontWeight: 900, fontSize: 12, paddingLeft: 5, paddingRight: 5 }}>EFFECTIVENESS OF CONTROL</p>
            </Col>
            <Col xs="10">
              <Table bordered style={{ borderColor: '#000' }} responsive>
                <thead>
                  <tr>
                    <th colSpan={1}></th>
                    <th colSpan={5} style={{ textAlign: 'center', fontSize: 20 }}>RISK TO PEOPLE</th>
                  </tr>
                  <tr>
                    {tableHeaders?.map((header: any) => <th style={{
                      fontSize: 16,
                      textAlign: 'center',
                      verticalAlign: 'middle',
                    }}>{renderDetails(header.title, header.content)}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {riskMatrixLevel1?.map((item: any) =>
                    <><tr>
                      <td style={{ fontSize: 14, textAlign: 'center', fontWeight: 500 }}>
                        <div style={{ height: 55, overflow: 'scroll' }}>
                          {renderDetails(item?.factor)}
                        </div>
                      </td>
                      {[item[1], item[2], item[3]]?.map(({ text, value, color }) => <td
                        onClick={() => {
                          change(name, value)
                          toggle()
                        }}
                        style={{
                          backgroundColor: hoecControlRiskMatrixColors[value],
                          color: hoecControlRiskMatrixColors[value] === LOW_RISK_COLOR ? '#000' : '#FFF',
                          textAlign: 'center',
                          verticalAlign: 'middle',
                          height: '10%',
                          width: '20%',
                          fontWeight: 700,
                          fontSize: 24,
                          cursor: 'pointer'
                        }}>{text}</td>)}
                    </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'center' }}>
          <Button color="warning" onClick={toggle}>Close</Button>
          <Button color="success" onClick={toggle}>Save</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}