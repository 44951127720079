import { Row } from 'reactstrap'
import AuditForm from '../components/Forms/AuditForm'
import ProtectedLayout from '../components/Layout/ProtectedLayout'

export default function Audit() {
  return (
    <ProtectedLayout>
      <Row style={{ margin: 20 }}>
        <AuditForm />
      </Row>
    </ProtectedLayout>
  )
}
