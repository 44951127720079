import axios from 'axios'
import React, { useState } from 'react'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { FaFileCircleXmark } from 'react-icons/fa6'
import { FaFileExport, FaFileImport, FaSave } from 'react-icons/fa'
import { IoSettings } from 'react-icons/io5'
import { MdCancel, MdEditDocument } from 'react-icons/md'
import { PiEngineFill } from 'react-icons/pi'
import { ToastContainer, toast } from 'react-toastify'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import 'react-toastify/dist/ReactToastify.css'

import CustomLabel from '../../components/InputFields/CustomLabel'
import ModalTitle from '../../components/ModalTitle'
import RcmLayout from '../../components/rcm/RcmLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { Box, renderData } from '../workManagement/PmTree'
import { getAttachment, systemsApi } from '../../components/serverurl'
import { getAccessToken, makeAuthenticatedRequest } from '../../apiRequests'
import { SubHeader } from '../VerifyCM'
import { IconButton, handleExport } from './Systems'
import { loginRequest } from '../../authConfig'

export const flocHeaderKeys = [
  'HIERARCHY FLOC ID',
  'Description',
  'Perform RCM',
  'Location Type',
  'Startup',
  'Site',
  'Plant',
  'Unit',
  'System',
  'CMMS Type',
  'Parent FLOC ID',
  'EQ'
]

const flockAllHeaderKeys = [
  'RSL Strategy',
  'Area',
  '3D Area',
  'HIERARCHY FLOC ID',
  'Description',
  'Perform RCM',
  'Location Type',
  'Startup',
  'Site',
  'Plant',
  'Unit',
  'System',
  'CMMS Type',
  'Parent FLOC ID',
  'EQ',
  'FMs',
  'Tasks',
  'Base Risk',
  'Residual Risk',
  'RCM Priority',
  'Total Annual POC $k/yr',
  'MEI',
  'AC',
  'Labels',
  'Due Date',
  'RSL',
  'RSL Status',
  'RSL Revision',
  'MEI Status',
  'ETBF',
  'ETBC',
  'Failure M',
  'FM Notes'
]

const colors: any = {
  RED: '#FFC0C0',
  YELLOW: '#FDFF86',
  BROWN: '#D6C4B1',
  WHITE: '#fff'
}

export default function FLoc({ setPin, pin }: { pin: any; setPin: any }) {
  const { accounts, instance } = useMsal()
  const [file, setFile] = useState()
  const [array, setArray]: any = useState([])
  const [importModal, setImportModal]: any = useState(false)
  const [save, setSave]: any = useState(false)
  const [selected, setSelected]: any = useState()
  const [edit, setEdit]: any = useState(false)
  const [formData, setFormData]: any = useState({})
  const [deleteSystem, setDeleteSystem]: any = useState(false)
  const [headerKeys, setHeaderKeys]: any = useState(flocHeaderKeys)
  const [settings, setSettings]: any = useState(false)
  const [color, setColor]: any = useState(colors.WHITE)

  const fileReader = new FileReader()

  const fetchSystems = async () => {
    const response = await makeAuthenticatedRequest(
      `${systemsApi}/floc?system=${pin?.['System Number']}`,
      'GET',
      null,
      accounts,
      instance
    )
    return response
  }

  const { data, refetch }: any = useQuery('flock data', fetchSystems)
  const navigate = useNavigate()

  console.log(data)

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0])
  }

  const csvFileToArray = (string: any) => {
    const csvHeader = string
      .slice(0, string.indexOf('\n'))
      .split(',')
      .map((header: string) => header.trim())
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n')

    const array = csvRows.map((i: any) => {
      const values = i.split(',').map((value: string) => value.trim())
      const obj = csvHeader.reduce((object: any, header: any, index: any) => {
        const trimmedValue = values[index].replace(/^"|"$/g, '')
        object[header] = trimmedValue
        return object
      }, {})
      return obj
    })

    setArray(array)
  }

  const handleOnSubmit = (e: any) => {
    e.preventDefault()

    if (file) {
      fileReader.onload = function (event: any) {
        const text = event?.target?.result
        csvFileToArray(text)
      }

      fileReader.readAsText(file)
    }
  }

  const keysToValidate = [
    'System Number',
    'F LOC',
    'Equipment',
    'Failure Mode',
    'Total Annual Price of Confirmation (POC)'
  ]

  const isNumber = (v: any) => {
    if (!v) {
      return false
    }

    if (isNaN(v)) {
      return true
    } else return false
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const updateSystemForm = (
    <Row>
      <Col xs="6">
        <CustomLabel label="HIERARCHY FLOC ID" />
        <Input
          type="text"
          name="HIERARCHY FLOC ID"
          onChange={handleInputChange}
          defaultValue={selected?.['HIERARCHY FLOC ID']}
        />
        <CustomLabel label="Description" />
        <Input
          type="text"
          name="Description"
          onChange={handleInputChange}
          defaultValue={selected?.['Description']}
        />
        <CustomLabel label="Perform RCM" />
        <Input
          type="text"
          name="Perform RCM"
          defaultValue={selected?.['Perform RCM']}
          onChange={handleInputChange}
        />
        <CustomLabel label="Location Type" />
        <Input
          type="text"
          name="Location Type"
          onChange={handleInputChange}
          defaultValue={selected?.['Location Type']}
        />
        <CustomLabel label="Startup" />
        <Input
          type="text"
          name="Startup"
          onChange={handleInputChange}
          defaultValue={selected?.['Startup']}
        />
        <CustomLabel label="Site" />
        <Input
          type="text"
          name="Site"
          onChange={handleInputChange}
          defaultValue={selected?.['Site']}
        />
      </Col>
      <Col xs="6">
        <CustomLabel label="Plant" />
        <Input
          type="text"
          name="Plant"
          onChange={handleInputChange}
          defaultValue={selected?.['Plant']}
        />
        <CustomLabel label="Unit" />
        <Input
          type="text"
          name="Unit"
          onChange={handleInputChange}
          defaultValue={selected?.['Unit']}
        />
        <CustomLabel label="System" />
        <Input
          type="text"
          name="System"
          onChange={handleInputChange}
          defaultValue={selected?.['System']}
        />
        <CustomLabel label="CMMS Type" />
        <Input
          type="text"
          name="CMMS Type"
          onChange={handleInputChange}
          defaultValue={selected?.['CMMS Type']}
        />
        <CustomLabel label="Parent FLOC ID" />
        <Input
          type="text"
          name="Parent FLOC ID"
          onChange={handleInputChange}
          defaultValue={selected?.['Parent FLOC ID']}
        />
        <CustomLabel label="EQ" />
        <Input
          type="text"
          name="EQ"
          onChange={handleInputChange}
          defaultValue={selected?.['EQ']}
        />
      </Col>
    </Row>
  )

  const onEdit = () => {
    setEdit(true)
    setFormData({
      'HIERARCHY FLOC ID': selected?.['HIERARCHY FLOC ID'],
      Description: selected?.['Description'],
      'Perform RCM': selected?.['Perform RCM'],
      'Location Type': selected?.['Location Type'],
      Startup: selected?.['Startup'],
      Site: selected?.['Site'],
      Plant: selected?.['Plant'],
      Unit: selected?.['Unit'],
      System: selected?.['System'],
      'CMMS Type': selected?.['CMMS Type'],
      'Parent FLOC ID': selected?.['Parent FLOC ID'],
      EQ: selected?.['EQ']
    })
  }

  return (
    <RcmLayout
      onBack={'/cmms'}
      title={'RCM'}
      tabHeader={<h4 style={{ margin: 0 }}>F LOC</h4>}
      color={color}
      counts={{
        floc: data?.systems?.length
      }}
    >
      <div style={{ display: 'flex' }}>
        <IconButton
          icon={<FaFileImport size={25} />}
          buttonText={'Import'}
          onClick={() => setImportModal(true)}
        />
        <IconButton
          icon={<FaFileExport size={25} />}
          buttonText={'Export'}
          onClick={() => handleExport(data?.systems)}
        />
        <IconButton
          icon={<FaFileCircleXmark size={25} />}
          buttonText={'Delete'}
          onClick={() => setDeleteSystem(true)}
          isDisabled={!selected}
        />
        <IconButton
          icon={<MdEditDocument size={25} />}
          buttonText={'Edit'}
          onClick={onEdit}
          isDisabled={!selected}
        />
        <IconButton
          icon={<FaSave size={25} />}
          buttonText={'Save'}
          onClick={() => setSave(true)}
          isDisabled={array?.length === 0}
        />
        <IconButton
          icon={<PiEngineFill size={25} />}
          buttonText={'Update Tag'}
          onClick={() => navigate(`${selected?._id}`)}
          isDisabled={!selected}
        />
        <IconButton
          icon={<MdCancel size={25} />}
          buttonText={'Cancel Import'}
          isDisabled={array?.length === 0}
          onClick={() => {
            setArray([])
            toast('Import Cancelled')
          }}
        />
        <IconButton
          icon={<IoSettings size={25} />}
          isDisabled={data?.systems?.length === 0}
          buttonText={'Settings'}
          onClick={() => setSettings(true)}
        />
      </div>
      <div style={{ minHeight: '80vh' }}>
        {array?.length > 0 && (
          <>
            <SubHeader header="Imported Data" permitColor="gold" />
            <Table
              style={{
                width: '90vw',
                overflow: 'scroll',
                height: 100,
                borderColor: '#000'
              }}
              bordered
            >
              <thead>
                <tr key={'header'}>
                  {headerKeys?.map((key: any) => <th>{key}</th>)}
                </tr>
              </thead>
              <tbody>
                {array.map((item: any, index: any) => (
                  <tr key={`${index}-${item?.['HIERARCHY FLOC ID']}`}>
                    {headerKeys.map((key: any, index: number) => (
                      <td key={index}>
                        {item[key]}
                        {keysToValidate?.includes(key) &&
                          isNumber(item[key]) && (
                            <p
                              style={{
                                fontSize: 12,
                                color: 'red',
                                margin: 0,
                                fontWeight: 600
                              }}
                            >
                              ! invalid value
                            </p>
                          )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
        {array?.length === 0 && pin && (
          <SubHeader
            header={`System - ${pin?.['System Number']} - ${pin?.['System Name']}`}
            permitColor="gold"
          />
        )}
        {data?.systems?.length > 0 && array?.length === 0 && (
          <>
            <SubHeader
              header={`Total Number of FLoc's - ${data?.systems?.length}`}
              permitColor="gold"
            />
            <Table key={'flock data'} bordered style={{ borderColor: '#000' }}>
              <thead>
                <tr key={'header'}>
                  <th></th>
                  {headerKeys?.map((key: any) => <th key={key}>{key}</th>)}
                </tr>
              </thead>
              <tbody>
                {data?.systems?.map((item: any, index: any) => (
                  <tr key={`${index}-${item?.['HIERARCHY FLOC ID']}`}>
                    <td>
                      <Input
                        type="checkbox"
                        checked={selected?._id === item?._id}
                        onClick={(e: any) =>
                          e?.target?.checked ? setSelected(item) : setSelected()
                        }
                      />
                    </td>
                    {headerKeys.map((key: any, index: number) => (
                      <td key={index}>{item[key]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </div>
      {importModal && (
        <Modal isOpen={importModal} toggle={() => setImportModal(!importModal)}>
          <ModalTitle height={50} title="Import File" />
          <ModalBody>
            <div>
              <form>
                <Input
                  type={'file'}
                  id={'csvFileInput'}
                  accept={'.csv'}
                  onChange={handleOnChange}
                />
                <br />
                <a
                  href={`${getAttachment}/template.csv`}
                  target="_blank"
                  download={'template.csv'}
                  rel="noreferrer"
                >
                  Download Template
                </a>
              </form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={() => setImportModal(false)}>
              Close
            </Button>
            <Button
              color="warning"
              onClick={async (e) => {
                await handleOnSubmit(e)
                setPin()
                setSelected()
                setImportModal(false)
              }}
            >
              Import
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {save && (
        <SubmitModalWithForm
          isOpen={save}
          size="sm"
          onClose={(val: any) => {
            setSave(false)
          }}
          onSubmit={async (e: any) => {
            e.preventDefault()
            const token = await getAccessToken(loginRequest, accounts, instance)
            const formData: any = new FormData()

            formData.append('file', file)

            try {
              const res: any = await axios.post(
                `${systemsApi}/floc`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                  }
                }
              )

              if (res?.data?.status === 'success') {
                await refetch()
                setArray([])
                setPin()
                setSelected()
              }

              return res?.data?.status
            } catch (error) {
              console.error(error)
            }
          }}
          header={`Update Systems`}
        />
      )}
      {edit && (
        <SubmitModalWithForm
          isOpen={edit}
          size="lg"
          onClose={(val: any) => {
            setEdit(false)
          }}
          form={updateSystemForm}
          onSubmit={async (e: any) => {
            e.preventDefault()
            try {
              const res = await makeAuthenticatedRequest(
                `${systemsApi}/floc?id=${selected?._id}`,
                'PATCH',
                formData,
                accounts,
                instance
              )

              if (res?.status === 'success') {
                await refetch()
              }

              return res?.status
            } catch (error) {
              console.error(error)
            }
          }}
          header={`Update System `}
        />
      )}
      {deleteSystem && (
        <SubmitModalWithForm
          isOpen={deleteSystem}
          size="lg"
          onClose={(val: any) => {
            setDeleteSystem(false)
          }}
          onSubmit={async (e: any) => {
            e.preventDefault()
            try {
              const res = await makeAuthenticatedRequest(
                `${systemsApi}/floc?id=${selected?._id}`,
                'DELETE',
                null,
                accounts,
                instance
              )

              if (res?.status === 'success') {
                await refetch()
              }

              return res?.status
            } catch (error) {
              console.error(error)
            }
          }}
          form={
            <>
              <p>Are you sure you want to delete System ?</p>
              <Box heading="System Details">
                {headerKeys?.map((key: any) =>
                  renderData(key, selected?.[key])
                )}
              </Box>
            </>
          }
          header={`Delete Floc System`}
        />
      )}
      {settings && (
        <Modal
          isOpen={settings}
          toggle={() => setSettings(!settings)}
          size="xl"
        >
          <ModalTitle height={50} title="Settings" />
          <ModalBody>
            <div>
              <h5>Select Background Color</h5>
              <div style={{ display: 'flex', gap: 10 }}>
                {Object.keys(colors)?.map((k: any) => (
                  <div
                    key={k}
                    style={{
                      height: 50,
                      width: 80,
                      backgroundColor: colors[k],
                      border: '1px solid #000',
                      cursor: 'pointer'
                    }}
                    onClick={() => setColor(colors[k])}
                  />
                ))}
              </div>
              <div style={{ marginBottom: 10 }} />

              {flockAllHeaderKeys?.map((key: any) => (
                <div>
                  <Input
                    type="checkbox"
                    id={'fLocHeaders'}
                    name={key}
                    value={key}
                    checked={headerKeys?.includes(key)}
                    onChange={(e: any) =>
                      e?.target?.checked
                        ? setHeaderKeys([...headerKeys, key])
                        : setHeaderKeys(
                            headerKeys?.filter((k: any) => k !== key)
                          )
                    }
                  />
                  <label style={{ marginLeft: 10 }}>{key}</label>
                </div>
              ))}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={() => setSettings(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <ToastContainer
        theme="dark"
        position="top-center"
        style={{ fontSize: 16 }}
      />
    </RcmLayout>
  )
}
