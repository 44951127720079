import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Row, Table } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'

import { getPMPermissions } from '../components/lib/PMPermissions'
import { makeAuthenticatedRequest } from '../apiRequests'
import { protectiveMaintenancePermit } from '../components/serverurl'
import { renderRow, SubHeader } from './VerifyCM'
import { ViewCloseOutDetails, ViewHistory } from './ViewCM'
import CloseCMPermit from '../components/Modals/CloseCMPermit'
import Loader from '../components/Loader'
import SectionHeader from '../components/Layout/SectionHeader'
import Spacer from '../components/Spacer'
import SubmitModal from '../components/SubmitModal'
import toNormalCase from '../components/lib/toNormalCase'

const PMStatuses = {
  INPROGRESS: 'inProgress',
  WAITINGFORMATERIAL: 'waitingForMaterial',
  WAITINGFOR3RDPARTY: 'waitingFor3rdParty',
  WAITINGFORSHUTDOWN: 'waitingForShutdown',
  CLOSE: 'closed'
}

export default function PMPreview({
  data,
  hasOngoingPermit,
  approvalHistory,
  closeOutData
}: any) {
  const navigate = useNavigate()
  const { accounts, instance } = useMsal()
  const activeWO = hasOngoingPermit[hasOngoingPermit?.length - 1]

  const [permissions, setPermissions]: any = useState()
  const [closeModal, setCloseModal]: any = useState(false)
  const [submitModal, setSubmitModal]: any = useState(false)
  const [status, setStatus]: any = useState(false)

  const permitData: any = {
    Title: data?.title,
    'Tag Number': data?.tagNumber,
    'Tag Description': data?.tagDescription,
    'Instrument Type': data?.instrumentType,
    'Responsible Department': data?.respDepartment,
    Frequency: data?.frequency,
    'Plant Location': data?.plantLocation,
    'Due Date': moment(data?.dueDate).format('DD-MM-YYYY'),
    Priority: data?.priority,
    Status: data?.status,
    location: data?.location,
    'P&ID': data?.pidNumber,
    'Line Number': data?.lineNumber,
    'Equipment Name': data?.equipmentName,
    'Max Range ': data?.maxRange,
    Units: data?.units,
    'Process HookUp Number': data?.processHookUpNumber,
    'Functional Loop': data?.processHookUpNumber,
    'Model Number': data?.modelNo,
    'Manufacturer Name': data?.manufacturerName,
    'PR Number': data?.prNumber,
    Remarks: data?.remarks
  }

  const tagDetails: any = {
    'Tag Number': 'GT1-PZIT-1001',
    'Tag Description': 'BALL VALVE'
  }

  useEffect(() => {
    if (!permissions) {
      getPMPermissions(accounts, instance).then((response: any) =>
        setPermissions(response)
      )
    }
  })

  const onSubmit = async (status: string) => {
    try {
      makeAuthenticatedRequest(
        `${protectiveMaintenancePermit}?status=${status}&id=${data?._id}`,
        'POST',
        data,
        accounts,
        instance
      ).then((response) => {
        navigate('/pm/dashboard')
      })
    } catch (error) {
      console.info(error)
    }
  }

  const updateStatus = async () => {
    try {
      makeAuthenticatedRequest(
        `${protectiveMaintenancePermit}?status=${status}&id=${data?._id}`,
        activeWO?.permitStatus === PMStatuses.CLOSE
          ? 'POST'
          : activeWO
          ? 'PATCH'
          : 'POST',
        data,
        accounts,
        instance
      ).then((response) => {
        setStatus()
        navigate('/pm/dashboard')
      })
    } catch (error) {
      console.info(error)
    }
  }

  if (!permissions) {
    return <Loader />
  }

  const SubmitButton = ({ nextStatus, children, canView }: any) =>
    canView && (
      <Button
        color="success"
        style={{ marginBottom: 10 }}
        onClick={() => {
          setSubmitModal(true)
          setStatus(nextStatus)
          // updateStatus(nextStatus)
        }}
      >
        {children}
      </Button>
    )

  const canMakeInProgress =
    permissions?.canMakeInProgress(data?.respDepartment.toLowerCase()) &&
    activeWO?.permitStatus !== PMStatuses.INPROGRESS

  const canMakeWaitingForMaterial =
    permissions?.canMakeWaitingForMaterial(
      data?.respDepartment.toLowerCase()
    ) && activeWO?.permitStatus !== PMStatuses.WAITINGFORMATERIAL

  const canMakeWaitingFor3rdParty =
    permissions?.canMakeWaitingFor3rdParty(
      data?.respDepartment.toLowerCase()
    ) && activeWO?.permitStatus !== PMStatuses.WAITINGFOR3RDPARTY

  const canMakeWaitingForShutdown =
    permissions?.canMakeWaitingForShutdown(
      data?.respDepartment.toLowerCase()
    ) && activeWO?.permitStatus !== PMStatuses.WAITINGFORSHUTDOWN

  const canMakeClose =
    permissions?.canMakeClose(data?.respDepartment.toLowerCase()) &&
    activeWO?.permitStatus === PMStatuses.INPROGRESS

  return (
    <>
      <SectionHeader title="PREVENTIVE MAINTENANCE" />
      <Spacer height={10} />
      {hasOngoingPermit?.length > 0 && (
        <>
          <SubHeader header="Work Order Status" />
          <Spacer height={10} />
          <Table bordered striped style={{ border: '1px solid #000' }}>
            <thead>
              <tr style={{ backgroundColor: 'orange' }}>
                <th>Work Order Number</th>
                <th>Status</th>
                <th>Created on</th>
              </tr>
            </thead>
            <tbody>
              {hasOngoingPermit?.map((permit: any, index: number) => (
                <React.Fragment key={permit?.woNumber}>
                  <tr>
                    <td>{permit?.woNumber}</td>
                    <td>{toNormalCase(permit?.permitStatus)}</td>
                    <td>{moment(permit?.createdAt).format('DD-MM-YYYY')}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </>
      )}
      <SubHeader header="WORK ORDER DETAILS" />
      <Spacer height={10} />
      {Object.keys(permitData).map((key) => renderRow(key, permitData[key]))}
      <Spacer height={10} />
      <SubHeader header="Job Description" />
      <div style={{ marginLeft: 20 }}>
        {Object.keys(tagDetails).map((key) => renderRow(key, tagDetails[key]))}
        <Spacer height={10} />
        <h4>
          Before Commencing any work preparations reference is to be made to the
          following Code of Practice
        </h4>
        <p>
          Safe Execution of Plant Shutdown and reinstatement for major Intrusive
          Maintenance
        </p>
        <p>Code Of Practice: SO 072</p>
        <p>
          Prior to commenting this work instruction conduct the following as
          necessary{' '}
        </p>
        <ol>
          <li>Verfy with Operations for availability of Equipment</li>
          <li>Obtain a Copy of Performance Verification Scheme</li>
          <li>
            Obtain all required Permits and ensure Task Risk Assessment is
            carried out
          </li>
          <li>
            Refer to the appropriate loop.circuit diagrams and Cause & Effects
            Charts
          </li>
          <li>
            Override all Shutdown Associated with the Equipment to be applied.
            Refer to Overrid Control Sheet COP CO009
          </li>
          <li>Lockout/Tagout equipment as required</li>
          <li>
            Ensure that equipment is adhered as per Isolation Standards COP 00
            A081 and that Isolations are Verified with Area Authority
          </li>
          <li>
            Remove Equipment from Service following approved depressurisation,
            bleeding, purging and Venting procedure
          </li>
          <li>
            Ensure a Tool Box Talk is carried out prior troubleshooting
            commencing and that all members in the work force were briefed
            Properly
          </li>
          <li>
            If Required adhere to requirements in the relevant Codes of Practice
            related to the work to be done.
          </li>
        </ol>
      </div>
      <Spacer height={20} />
      {approvalHistory.length > 0 && <ViewHistory data={approvalHistory} />}
      {closeOutData && <ViewCloseOutDetails data={closeOutData[0]} cm />}
      {activeWO?.permitStatus !== PMStatuses.CLOSE && (
        <Row>
          <SubmitButton canView={canMakeInProgress} nextStatus="inProgress">
            In Progress
          </SubmitButton>
          <SubmitButton
            canView={canMakeWaitingForMaterial}
            nextStatus="waitingForMaterial"
          >
            Waiting For Material
          </SubmitButton>
          <SubmitButton
            canView={canMakeWaitingFor3rdParty}
            nextStatus="waitingFor3rdParty"
          >
            Waiting For 3rd Party
          </SubmitButton>
          <SubmitButton
            canView={canMakeWaitingForShutdown}
            nextStatus="waitingForShutdown"
          >
            Waiting For Shutdown
          </SubmitButton>
          {canMakeClose && (
            <>
              <Button color="success" onClick={() => setCloseModal(true)}>
                Close
              </Button>
              <CloseCMPermit
                isOpen={closeModal}
                toggle={() => setCloseModal(!closeModal)}
                permitId={activeWO?._id}
                type="PM"
                navigateTo="/pm/dashboard?view=inProgress"
              />
            </>
          )}
          <SubmitModal
            isOpen={submitModal}
            toggle={() => {
              setSubmitModal(!submitModal)
            }}
            onSubmit={updateStatus}
            submitModalContent={{
              title: 'Are you sure you want to submit ?'
            }}
          />
        </Row>
      )}
    </>
  )
}
