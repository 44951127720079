import { Input, Table } from 'reactstrap'
import { ColoredSubHeader } from './previews/ViewExcavationPermit'

export const renderTableData = (
  value: any,
  span = 1,
  rowSpan = 1,
  align: any = 'center'
) => (
  <td
    colSpan={span}
    rowSpan={rowSpan}
    style={{
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 2,
      paddingRight: 0,
      textAlign: align,
      verticalAlign: 'middle'
    }}
  >
    {value}
  </td>
)

export default function ToolBoxMeeting({ rowStyle, selectedPermit }: any) {
  const TextInput = () => (
    <Input
      type="textarea"
      style={{ width: '100%', height: 10, marginLeft: 5 }}
    />
  )

  const TickBox = () => (
    <input
      type="checkbox"
      size={400}
      style={{
        transform: 'scale(1.5)',
        backgroundColor: '#000',
        verticalAlign: 'middle',
        marginLeft: 5,
        marginRight: 5
      }}
    />
  )

  return (
    <>
      <ColoredSubHeader rowStyle={rowStyle} text="TOOLBOX TALK" />
      <Table
        cellSpacing="0"
        cellPadding="0"
        bordered
        style={{ borderColor: '#000', fontSize: 12 }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            {renderTableData(
              <b>
                (STEP 1) TASK PLANNING (THE TASK IDENTIFICATION AND DETAILS)
              </b>,
              4
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            {[
              'Supervisor name',
              selectedPermit?.applicantName,
              'Work area',
              selectedPermit?.workLocation
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              'Designation',
              selectedPermit?.applicantDesignation,
              'Contractor Name',
              <Input
                type="text"
                style={{ width: 'auto', height: 15, marginLeft: 5 }}
              />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              'Number of men in work party',
              <Input
                type="text"
                style={{ width: 'auto', height: 15, marginLeft: 5 }}
              />,
              'Permit to Work number',
              selectedPermit?.woNumber
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {renderTableData('What is the task?')}
            {renderTableData(selectedPermit?.permitTitle, 3)}
          </tr>
        </tbody>
      </Table>
      <Table
        cellspacing="0"
        cellpadding="0"
        bordered
        style={{
          borderColor: '#000'
        }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            {renderTableData(
              <b>
                (STEP 2) TASK PLANNING (COMPETENT PERSONS, TOOLS AND EQUIPMENT
                NECESSARY TO DO THE TASK)
              </b>,
              3
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            {[
              <>
                <p style={{ margin: 0, fontSize: 12 }}>
                  Competent Persons / 3 <sup>rd</sup> Party Required
                </p>
                <p style={{ margin: 0, fontSize: 12 }}>
                  Example: Lifting, Equipment Operator, Scaffolder, Vendors
                </p>
              </>,
              <>
                <p style={{ margin: 0, fontSize: 12 }}>Tools required</p>
                <p style={{ margin: 0, fontSize: 12 }}>
                  Example: radios, special testing meters, electric tools, spark
                  proof tools
                </p>
              </>,
              <>
                <p style={{ margin: 0, fontSize: 12 }}>Equipment required</p>
                <p style={{ margin: 0, fontSize: 12 }}>
                  Example: Excavator, manlift, crane, forklift, truck
                </p>
              </>
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[<TextInput />, <TextInput />, <TextInput />]?.map((i: any) =>
              renderTableData(i)
            )}
          </tr>
          <tr>
            {[<TextInput />, <TextInput />, <TextInput />]?.map((i: any) =>
              renderTableData(i)
            )}
          </tr>
          <tr>
            {[<TextInput />, <TextInput />, <TextInput />]?.map((i: any) =>
              renderTableData(i)
            )}
          </tr>
        </tbody>
      </Table>
      <Table
        bordered
        cellspacing="0"
        cellpadding="0"
        style={{
          borderColor: '#000'
        }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            {renderTableData(
              <b>(STEP 3) RISK IDENTIFICATION AND RISK MANAGEMENT</b>,
              3
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            {[
              <strong>List task steps</strong>,
              <strong>List potential hazards (write words or codes)</strong>,
              <strong>List controls (write words or codes)</strong>
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />, 1, 8)}
            {renderTableData(<TextInput />)}
          </tr>
          <tr>
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
          </tr>
          <tr>
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
          </tr>
          <tr>
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
          </tr>
          <tr>
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
          </tr>
          <tr>
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
          </tr>
          <tr>
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
          </tr>
          <tr>
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
          </tr>
        </tbody>
      </Table>
      <Table
        bordered
        cellspacing="0"
        cellpadding="0"
        style={{
          borderColor: '#000'
        }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            {renderTableData(
              <b>
                (STEP 4) TASK RISK MANAGEMENT (TASK HAZARD AWARENESS,
                RECOGNITION AND CONTROLS)
              </b>,
              6
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            {renderTableData(
              <>
                <b>HAZARDS OF THE TASK (H)</b>(tick if applicable to task) √
              </>,
              3
            )}
            {renderTableData(
              <>
                <b>THE CONTROLS FOR THE HAZARDS</b>(tick if applicable to task)
                √
              </>,
              3
            )}
          </tr>
          <tr>
            {[
              <b>H1</b>,
              'Working at Height (2m and above)',
              <TickBox />,
              <b>C1</b>,
              'Work at Height training,100% tie off, factory made ladder, proper working platform',
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H2</b>,
              'Slips Trips and Fall Potential',
              <TickBox />,
              <b>C2</b>,
              'Good Housekeeping, access free from obstructions',
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H3</b>,
              'Electrical / mechanical isolation',
              <TickBox />,
              <b>C3</b>,
              'Electrically tested gloves, isolation form / permit, right toolfor the job, LOTO tag / display',
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H4</b>,
              'Vehicle entry inside plant',
              <TickBox />,
              <b>C4</b>,
              'Vehicle inspection, vehicle entry permit, Spark arrestor',
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H5</b>,
              'Scaffolding',
              <TickBox />,
              <b>C5</b>,
              'Work at Height training, Green tag on scaffold, access ladder',
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H6</b>,
              'Lifting operations',
              <TickBox />,
              <b>C6</b>,
              'Lifting radius Barricade, third party inspection certificate, Qualified crane operator rigger in work team',
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H7</b>,
              'Excavation',
              <TickBox />,
              <b>C7</b>,
              'Excavation training, Excavation Permit, hard barricade, blinker,sign board',
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H8</b>,
              'Confined Space',
              <TickBox />,
              <b>C8</b>,
              'Confined space training, Confined Space Permit, Hole Watch, Gas Test',
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H9</b>,
              'Falling Objects',
              <TickBox />,
              <b>C9</b>,
              'Housekeeping Tied off tools, safety net',
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H10</b>,
              'Hot work',
              <TickBox />,
              <b>C10</b>,
              <>
                Hot work permit, Area free of combustible materials{' '}
                <strong>,</strong> fire watch, Fire extinguisher / blankets,
                fire hydrant hose, barricade, caution board, face shield
              </>,
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H11</b>,
              'Manual Handling',
              <TickBox />,
              <b>C11</b>,
              'Assess manual lift before starting work, use trolley / pallet truck',
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H12</b>,
              'Work in Rotating Equipment',
              <TickBox />,
              <b>C12</b>,
              'Machine Guarding, Lock Out Tag Out, no loose clothing, no jewel on hand',
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H13</b>,
              'Hot Surfaces',
              <TickBox />,
              <b>C13</b>,
              <>
                Heat resistant gloves <strong>,</strong> guarding and signage
              </>,
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H14</b>,
              'Sharp and Jagged Objects',
              <TickBox />,
              <b>C14</b>,
              'Correct PPE, guarding, re-bar caps in place',
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H15</b>,
              'Hydro testing',
              <TickBox />,
              <b>C15</b>,
              'Barricades, Hydro test checklist, face shield, caution board',
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H16</b>,
              <>Exposed man-holes &amp; incomplete structures</>,
              <TickBox />,
              <b>C16</b>,
              'Hard barricades, signage and covers, grating removal checklist',
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H17</b>,
              'Chemical containment / fumes',
              <TickBox />,
              <b>C17</b>,
              'Chemical safety training, appropriate PPE, material correctly stored, MSDS, spill kits',
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H18</b>,
              'High Noise level',
              <TickBox />,
              <b>C18</b>,
              'Ear plug / ear muff',
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H19</b>,
              'Oxygen deficient atmosphere inert gases',
              <TickBox />,
              <b>C19</b>,
              ' Confined space permit. Purging, barricades. Awareness on pressurized vessels, awareness on Breathing apparatus usage.',
              <TickBox />
            ]?.map((i: any) => renderTableData(i))}
          </tr>
          <tr>
            {[
              <b>H20</b>,
              'Insects and birds disturbances',
              <></>,
              <b>C20</b>,
              'Full set PPE if bird disturbance in the area',
              <></>
            ]?.map((i: any) => renderTableData(i))}
          </tr>
        </tbody>
      </Table>
      <Table
        bordered
        cellspacing="0"
        cellpadding="0"
        style={{ borderColor: '#000' }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            {renderTableData(
              <b>(STEP 5) INCIDENT (EMERGENCY) PLAN AND ACTIONS</b>,
              4
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            {renderTableData('Emergency telephone number')}
            {renderTableData(<TextInput />)}
            {renderTableData('Alarm Code:')}
            {renderTableData(<TextInput />)}
          </tr>
          <tr>
            {renderTableData('Nearest Muster Point :')}
            {renderTableData(<TextInput />)}
            {renderTableData('Nearest first aid station location')}
            {renderTableData(<TextInput />)}
          </tr>
          <tr>
            {renderTableData(
              'Evacuation Route (Supervisor to explain to Workers):'
            )}
            {renderTableData(<TextInput />)}
            {renderTableData('Safety shower / eye washer location:')}
            {renderTableData(<TextInput />)}
          </tr>
        </tbody>
      </Table>
      <Table
        bordered
        cellspacing="0"
        cellpadding="0"
        style={{
          borderColor: '#000'
        }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            {renderTableData(
              <b>(STEP 6) THE WORKING PARTY (WORKERS DETAILS)</b>,
              4
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            {renderTableData(
              'I acknowledge receiving and understanding these instructions, I will fully comply with the assigned task requirements:',
              4
            )}
          </tr>
          <tr>
            {renderTableData('Name')}
            {renderTableData('Designation')}
            {renderTableData('Company')}
            {renderTableData('Signature')}
          </tr>
          <tr>
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
          </tr>
          <tr>
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
          </tr>
          <tr>
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
          </tr>
          <tr>
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
          </tr>
          <tr>
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
          </tr>
          <tr>
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
          </tr>
          <tr>
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
          </tr>
          <tr>
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
            {renderTableData(<TextInput />)}
          </tr>
          <tr>
            <td colSpan={4}>
              I have briefed my workers on the safety carrying out this task
              <br />
              Supervisor signature, Date &amp; Time:
              <Input type={'textarea'} style={{ width: '100%', height: 40 }} />
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}
