import { Route, Routes } from 'react-router-dom'

import Analytics from '../pages/dashboardPages/Analytics'
import AuditActions from '../pages/audits/AuditActions'
import DynamicKeyDrawings from '../pages/rcm/RcmDynamicDrawings'
import FIRSActions from '../pages/firs/FirsActions'
import FIRSDashboard from '../pages/FIRSDashboard'
import FIRSKpi from '../pages/firs/FirsKpi'
import FIRSPage from '../pages/FIRSPage'
import FirsStratefyAndTactics from '../pages/firs/FirsStrategyTactics'
import InformationCardRoutes from './InformationCardRoutes'
import LessonLearnt from '../pages/dashboardPages/LessonLearnt'
import ObservationCardActions from '../pages/obersvationCard/ObservationCardActions'
import ViewFIR from '../pages/ViewFIR'
import ViewReports from '../pages/firs/ViewReports'
import { Stage1Form } from '../pages/firs'
import MocForm from '../pages/obersvationCard/MocForm'
import MocActions from '../pages/moc/MocActions'
import MocList from '../pages/moc/MocList'
import ViewMoc from '../pages/moc/ViewMoc'

const firsData = [
  {
    'S:No': '1',
    Number: 'FIRS-SYS09-007',
    Description: 'Injury due to Oil spillage at Module 09',
    Status: 'Approved',
    System: '9',
    Priority: 'HIGH'
  },
  {
    'S:No': '2',
    Number: 'RCM-SYS005-009',
    Description: 'LTI investigation during PSV calibration',
    Status: 'Approved',
    System: '5',
    Priority: 'HIGH'
  },
  {
    'S:No': '3',
    Number: 'RCM-SYS43-011',
    Description: 'Minor injury during errection of valve',
    Status: 'Approved',
    System: '43',
    Priority: 'HIGH'
  },
  {
    'S:No': '4',
    Number: 'RCM-SYS91-012',
    Description: 'Incident enquiry while decanting Chemicals',
    Status: 'Approved',
    System: '91',
    Priority: 'HIGH'
  },
  {
    'S:No': '5',
    Number: 'RCM-SYS22-013',
    Description: 'LTI investigation during offloading',
    Status: 'Approved',
    System: '22',
    Priority: 'HIGH'
  }
]

export default function FIRSRoutes() {
  return (
    <Routes>
      <Route path="/menu" element={<FIRSPage />} />
      <Route path="/lessonLearnt" element={<LessonLearnt />} />
      <Route path="/incidentManagement" element={<FIRSActions />} />
      <Route path="/stage1" element={<Stage1Form />} />
      <Route
        path="/stage2"
        element={
          <ViewReports
            title="Stage 2 Reports"
            onBack="/firs/incidentManagement"
            stage="stage2"
          />
        }
      />
      <Route
        path="/stage3"
        element={
          <ViewReports
            title="Stage 3 Reports"
            onBack="/firs/incidentManagement"
            stage="stage3"
          />
        }
      />
      <Route
        path="/stage4"
        element={
          <ViewReports
            title="Stage 4 Reports"
            onBack="/firs/incidentManagement"
            stage="stage4"
          />
        }
      />
      <Route
        path="/stage5"
        element={
          <ViewReports
            title="Stage 5 Reports"
            onBack="/firs/incidentManagement"
            stage="stage5"
          />
        }
      />
      <Route
        path="/stage6"
        element={
          <ViewReports
            title="Stage 6 Reports"
            onBack="/firs/incidentManagement"
            stage="moc"
          />
        }
      />
      <Route
        path="/stage7"
        element={
          <ViewReports
            title="Stage 7 Reports"
            onBack="/firs/incidentManagement"
            stage="cm"
          />
        }
      />
      <Route
        path="/stage8"
        element={
          <ViewReports
            title="Stage 8 Reports"
            onBack="/firs/incidentManagement"
            stage="closed"
          />
        }
      />
      <Route
        path="/stageA"
        element={
          <ViewReports
            title="Stage A Reports"
            onBack="/firs/incidentManagement"
            stage="rejected"
          />
        }
      />
      <Route path="/stage2/:id" element={<ViewFIR onBack="/firs/stage2" />} />
      <Route path="/stage3/:id" element={<ViewFIR onBack="/firs/stage3" />} />
      <Route path="/stage4/:id" element={<ViewFIR onBack="/firs/stage4" />} />
      <Route path="/stage5/:id" element={<ViewFIR onBack="/firs/stage5" />} />
      <Route path="/stage6/:id" element={<ViewFIR onBack="/firs/stage6" />} />
      <Route path="/stage7/:id" element={<ViewFIR onBack="/firs/stage7" />} />
      <Route path="/stage8/:id" element={<ViewFIR onBack="/firs/stage8" />} />
      <Route path="/stageA/:id" element={<ViewFIR onBack="/firs/stageA" />} />
      <Route path="/im" element={<FIRSDashboard title={'FIRS'} />} />
      <Route path="/audit" element={<AuditActions onBack="/firs" />} />
      <Route path="/dashboard" element={<FIRSDashboard />} />
      <Route path="/analytics" element={<Analytics />} />
      <Route path="/sheCard" element={<ObservationCardActions />} />
      <Route
        path="/strategyTactics"
        element={
          <FirsStratefyAndTactics
            setPin={() => {}}
            pin={{ 'System Number': 43, 'System Name': 'Gas Compressor' }}
          />
        }
      />
      <Route path="/kpi" element={<FIRSKpi />} />
      <Route
        path="/dynamicDrawings"
        element={<DynamicKeyDrawings rcmData={firsData} onBack={'/firs'} />}
      />
      <Route path="/sheCard/*" element={<InformationCardRoutes />} />

      <Route path="/moc" element={<MocActions />} />
      <Route path="/moc/stage1" element={<MocForm />} />
      <Route
        path="/moc/stage2"
        element={<MocList title="Stage 2 Reports" stage="mocStage2" />}
      />
      <Route
        path="moc/stage2/:id"
        element={<ViewMoc onBack="/firs/moc/stage2" />}
      />
    </Routes>
  )
}
