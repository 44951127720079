export const LOW_RISK_COLOR = '#f2eb16'
export const MEDIUM_RISK_COLOR = '#f89921'
export const HIGH_RISK_COLOR = '#ec2226'
export const CRITICAL_RISK_COLOR = '#010101'
export const NO_RISK_COLOR = '#238636'

export const levelThreeRiskMatrixColors: any = {
  'S6/L1': MEDIUM_RISK_COLOR,
  'S6/L2': MEDIUM_RISK_COLOR,
  'S6/L3': HIGH_RISK_COLOR,
  'S6/L4': HIGH_RISK_COLOR,
  'S6/L5': CRITICAL_RISK_COLOR,
  'S6/L6': CRITICAL_RISK_COLOR,
  'S5/L1': MEDIUM_RISK_COLOR,
  'S5/L2': MEDIUM_RISK_COLOR,
  'S5/L3': HIGH_RISK_COLOR,
  'S5/L4': HIGH_RISK_COLOR,
  'S5/L5': HIGH_RISK_COLOR,
  'S5/L6': CRITICAL_RISK_COLOR,
  'S4/L1': LOW_RISK_COLOR,
  'S4/L2': MEDIUM_RISK_COLOR,
  'S4/L3': MEDIUM_RISK_COLOR,
  'S4/L4': MEDIUM_RISK_COLOR,
  'S4/L5': HIGH_RISK_COLOR,
  'S4/L6': HIGH_RISK_COLOR,
  'S3/L1': LOW_RISK_COLOR,
  'S3/L2': LOW_RISK_COLOR,
  'S3/L3': MEDIUM_RISK_COLOR,
  'S3/L4': MEDIUM_RISK_COLOR,
  'S3/L5': MEDIUM_RISK_COLOR,
  'S3/L6': HIGH_RISK_COLOR,
  'S2/L1': LOW_RISK_COLOR,
  'S2/L2': LOW_RISK_COLOR,
  'S2/L3': LOW_RISK_COLOR,
  'S2/L4': MEDIUM_RISK_COLOR,
  'S2/L5': MEDIUM_RISK_COLOR,
  'S2/L6': MEDIUM_RISK_COLOR,
  'S1/L1': LOW_RISK_COLOR,
  'S1/L2': LOW_RISK_COLOR,
  'S1/L3': LOW_RISK_COLOR,
  'S1/L4': LOW_RISK_COLOR,
  'S1/L5': MEDIUM_RISK_COLOR,
  'S1/L6': MEDIUM_RISK_COLOR
}

export const hoecRiskMatrixColors: any = {
  'S5/P1': MEDIUM_RISK_COLOR,
  'S5/P2': HIGH_RISK_COLOR,
  'S5/P3': HIGH_RISK_COLOR,
  'S5/P4': HIGH_RISK_COLOR,
  'S5/P5': HIGH_RISK_COLOR,
  'S4/P1': MEDIUM_RISK_COLOR,
  'S4/P2': MEDIUM_RISK_COLOR,
  'S4/P3': HIGH_RISK_COLOR,
  'S4/P4': HIGH_RISK_COLOR,
  'S4/P5': HIGH_RISK_COLOR,
  'S3/P1': NO_RISK_COLOR,
  'S3/P2': MEDIUM_RISK_COLOR,
  'S3/P3': MEDIUM_RISK_COLOR,
  'S3/P4': MEDIUM_RISK_COLOR,
  'S3/P5': HIGH_RISK_COLOR,
  'S2/P1': NO_RISK_COLOR,
  'S2/P2': NO_RISK_COLOR,
  'S2/P3': MEDIUM_RISK_COLOR,
  'S2/P4': MEDIUM_RISK_COLOR,
  'S2/P5': MEDIUM_RISK_COLOR,
  'S1/P1': NO_RISK_COLOR,
  'S1/P2': NO_RISK_COLOR,
  'S1/P3': NO_RISK_COLOR,
  'S1/P4': MEDIUM_RISK_COLOR,
  'S1/P5': MEDIUM_RISK_COLOR
}

export const hoecControlRiskMatrixColors: any = {
  'C3/P1': MEDIUM_RISK_COLOR,
  'C3/P2': HIGH_RISK_COLOR,
  'C3/P3': HIGH_RISK_COLOR,
  'C2/P1': MEDIUM_RISK_COLOR,
  'C2/P2': MEDIUM_RISK_COLOR,
  'C2/P3': HIGH_RISK_COLOR,
  'C1/P1': NO_RISK_COLOR,
  'C1/P2': NO_RISK_COLOR,
  'C1/P3': MEDIUM_RISK_COLOR
}

export const levelTwoRiskMatrixColors: any = {
  'S6/L1': MEDIUM_RISK_COLOR,
  'S6/L2': MEDIUM_RISK_COLOR,
  'S6/L3': HIGH_RISK_COLOR,
  'S6/L4': HIGH_RISK_COLOR,
  'S6/L5': HIGH_RISK_COLOR,
  'S6/L6': HIGH_RISK_COLOR,
  'S5/L1': LOW_RISK_COLOR,
  'S5/L2': MEDIUM_RISK_COLOR,
  'S5/L3': MEDIUM_RISK_COLOR,
  'S5/L4': HIGH_RISK_COLOR,
  'S5/L5': HIGH_RISK_COLOR,
  'S5/L6': HIGH_RISK_COLOR,
  'S4/L1': LOW_RISK_COLOR,
  'S4/L2': LOW_RISK_COLOR,
  'S4/L3': MEDIUM_RISK_COLOR,
  'S4/L4': MEDIUM_RISK_COLOR,
  'S4/L5': HIGH_RISK_COLOR,
  'S4/L6': HIGH_RISK_COLOR,
  'S3/L1': LOW_RISK_COLOR,
  'S3/L2': LOW_RISK_COLOR,
  'S3/L3': LOW_RISK_COLOR,
  'S3/L4': MEDIUM_RISK_COLOR,
  'S3/L5': MEDIUM_RISK_COLOR,
  'S3/L6': MEDIUM_RISK_COLOR,
  'S2/L1': LOW_RISK_COLOR,
  'S2/L2': LOW_RISK_COLOR,
  'S2/L3': LOW_RISK_COLOR,
  'S2/L4': LOW_RISK_COLOR,
  'S2/L5': LOW_RISK_COLOR,
  'S2/L6': MEDIUM_RISK_COLOR,
  'S1/L1': LOW_RISK_COLOR,
  'S1/L2': LOW_RISK_COLOR,
  'S1/L3': LOW_RISK_COLOR,
  'S1/L4': LOW_RISK_COLOR,
  'S1/L5': LOW_RISK_COLOR,
  'S1/L6': LOW_RISK_COLOR
}

export const levelOneRisksMatrixColors: any = {
  'S6/L1': MEDIUM_RISK_COLOR,
  'S6/L2': MEDIUM_RISK_COLOR,
  'S6/L3': HIGH_RISK_COLOR,
  'S6/L4': HIGH_RISK_COLOR,
  'S6/L5': HIGH_RISK_COLOR,
  'S6/L6': HIGH_RISK_COLOR,
  'S5/L1': LOW_RISK_COLOR,
  'S5/L2': MEDIUM_RISK_COLOR,
  'S5/L3': MEDIUM_RISK_COLOR,
  'S5/L4': HIGH_RISK_COLOR,
  'S5/L5': HIGH_RISK_COLOR,
  'S5/L6': HIGH_RISK_COLOR,
  'S4/L1': LOW_RISK_COLOR,
  'S4/L2': LOW_RISK_COLOR,
  'S4/L3': MEDIUM_RISK_COLOR,
  'S4/L4': MEDIUM_RISK_COLOR,
  'S4/L5': HIGH_RISK_COLOR,
  'S4/L6': HIGH_RISK_COLOR,
  'S3/L1': NO_RISK_COLOR,
  'S3/L2': LOW_RISK_COLOR,
  'S3/L3': LOW_RISK_COLOR,
  'S3/L4': MEDIUM_RISK_COLOR,
  'S3/L5': MEDIUM_RISK_COLOR,
  'S3/L6': MEDIUM_RISK_COLOR,
  'S2/L1': NO_RISK_COLOR,
  'S2/L2': NO_RISK_COLOR,
  'S2/L3': LOW_RISK_COLOR,
  'S2/L4': LOW_RISK_COLOR,
  'S2/L5': LOW_RISK_COLOR,
  'S2/L6': MEDIUM_RISK_COLOR,
  'S1/L1': NO_RISK_COLOR,
  'S1/L2': NO_RISK_COLOR,
  'S1/L3': NO_RISK_COLOR,
  'S1/L4': NO_RISK_COLOR,
  'S1/L5': LOW_RISK_COLOR,
  'S1/L6': LOW_RISK_COLOR
}
