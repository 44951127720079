import React from 'react'
import { Table } from 'reactstrap'
import { renderArray } from '../../pages/WaitingForPIC'
import Spacer from '../Spacer'
import { renderTableData } from '../ToolBoxTalkMeeting'

export default function PrintRiskHeader({ risk }: any) {
  const data = risk?.template ? risk?.template : risk
  return (
    <>
      <React.Fragment>
        <Table
          bordered
          striped
          style={{ border: '1px solid #000', fontSize: 12 }}
        >
          <thead>
            <tr>
              {/* <th>DEPARTMENT</th> */}
              {renderTableData(<strong>DEPARTMENT</strong>, 1, 1, 'left')}
              {renderTableData(
                <strong>{data?.department}</strong>,
                1,
                1,
                'left'
              )}
              {renderTableData(<strong>ACTIVITY / TASK</strong>, 1, 1, 'left')}
              {renderTableData(<strong>{data?.task}</strong>, 1, 1, 'left')}
              {renderTableData(<strong>LOCATION</strong>, 1, 1, 'left')}
              {renderTableData(<strong>{data?.location}</strong>, 1, 1, 'left')}

              {/* <td>{data?.department}</td>
              <th>ACTIVITY / TASK</th>
              <td>{data?.task}</td>
              <th>LOCATION</th>
              <td>{data?.location}</td> */}
            </tr>
            <tr>
              {renderTableData(<strong>ASSESSMENT TEAM</strong>, 1, 1, 'left')}
              {renderTableData(renderArray(data?.team, false), 1, 1, 'left')}
              {renderTableData(
                <strong>TOOLBOX TALK CONDUCTED</strong>,
                1,
                1,
                'left'
              )}
              {renderTableData(<>{data?.toolBox}</>, 1, 1, 'left')}
              {renderTableData(<strong>DATE</strong>, 1, 1, 'left')}
              {renderTableData(<>{data?.date}</>, 1, 1, 'left')}

              {/* <th>ASSESMENT TEAM</th> */}
              {/* <td>{renderArray(data?.team, false)}</td> */}
              {/* <th>TOOLBOX TALK CONDUCTED</th>
              <td>{data?.toolBox}</td>
              <th>DATE</th>
              <td>{data?.date}</td> */}
            </tr>
          </thead>
          <tbody></tbody>
        </Table>
        <Spacer height={10} />
      </React.Fragment>
    </>
  )
}
