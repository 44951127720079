import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import OilStorageSVG from '../../SVGs/OilStorageSVG'
import OilStorage from '../OilStorage'

export default function OilStorageFull() {
  return (
    <ProtectedLayout onBack="/pAndId">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 10,
          marginBottom: 10
        }}
      >
        <OilStorageSVG />
      </div>
    </ProtectedLayout>
  )
}
