import { Field } from 'react-final-form'

export default function LabeledRadioButtons({
  label,
  name,
  list,
  hasError = false,
  errorText = '',
  defaultValue = 'No',
  description = false,
  occupy = 6
}: any) {
  const formLabelStyles = {
    fontWeight: 600,
    fontSize: 16,
    color: '#000',
    display: 'flex',
    margin: 0,
    marginBottom: 1,
    marginTop: 10
  }

  return (
    <div className={`col-md-${occupy}`} key={`${name}`}>
      {label && <p style={formLabelStyles}>{label}</p>}
      {description && <p style={{ fontSize: 14, margin: 0 }}>{description}</p>}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap'
        }}
      >
        {list.map((item: any) => (
          <div
            key={item.value}
            style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}
          >
            <Field
              name={name}
              component="input"
              type="radio"
              value={item.value}
              style={{ backgroundColor: '#000' }}
              defaultValue={defaultValue}
            />
            <p style={{ margin: '0px 0px 0px 5px', whiteSpace: 'nowrap' }}>
              {item.label}
            </p>
          </div>
        ))}
      </div>
      {hasError && (
        <span style={{ color: 'red', fontWeight: 400, fontSize: 14 }}>
          {errorText}
        </span>
      )}
    </div>
  )
}
