import { useMsal } from '@azure/msal-react'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { PermissionsContext, UserContext } from '../../App'
import toNormalCase from '../../components/lib/toNormalCase'
import { countsEndpoint } from '../../components/serverurl'
import ViewIcons from '../../components/ViewIcons'

export default function LTIActions() {
  const { permissions } = useContext(PermissionsContext)
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const userlocation = toNormalCase(permissions?.location)

  const permitsCountByflow = async () => {
    const response = await makeAuthenticatedRequest(
      `${countsEndpoint}/lti?department=${userData?.department}&location=${userlocation}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data }: any = useQuery('ltiCount', permitsCountByflow)

  const counts = data?.counts

  const icons = [
    {
      name: 'VERIFY (LTI)',
      src: require('./../../images/Icons/LTI_Verify.png'),
      nav: '/eptw/lti/verify',
      canView: permissions?.canVerifyLTI,
      count: counts?.verify
    },
    {
      name: 'APPROVE (LTI)',
      src: require('./../../images/Icons/LTI_Approve.png'),
      nav: '/eptw/lti/approve',
      canView: permissions?.canApproveLTI,
      count: counts?.approve
    },
    {
      name: 'ISSUE (LTI)',
      src: require('./../../images/Icons/LTI_Issue.png'),
      nav: '/eptw/lti/issue',
      canView: permissions?.canIssueLTI,
      count: counts?.issue
    },
    {
      name: 'Make LTI Live (LTI)',
      src: require('./../../images/Icons/LTI_Live.png'),
      nav: '/eptw/lti/makeLive',
      canView: permissions?.canMakeLiveLTI,
      count: counts?.makeLive
    }
  ]

  return <ViewIcons icons={icons} />
}
