import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { Table } from 'reactstrap'
import { EmptyContent } from '../../pages/CreateNewPermit'
import { incrementby1 } from '../Forms/JSAForm'
import toNormalCase from '../lib/toNormalCase'

export default function CMTable({ headers, data }: any) {
  const navigate = useNavigate()

  const redirectTo = (id: any) =>
    navigate(`../correctiveMaintenance/closed/${id}`)

  return (
    <>
      <Table bordered striped style={{ border: '1px solid #000' }}>
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            {headers.map((header: string) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((d: any, index: number) => (
            <tr key={d.title}>
              <td>{incrementby1(index)}</td>
              <td
                onClick={() => navigate(`/correctiveMaintence/${d?._id}`)}
                style={{
                  color: 'blue',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
              >
                {d?.woNumber}
              </td>
              {[
                d?.title,
                d?.priority,
                d?.equipment || d?.equipmentOthers,
                d?.respDiscipline
              ]?.map((f: any) => <td key={f}>{f}</td>)}
              <td
                onClick={() => d?.status === 'closed' && redirectTo(d?._id)}
                style={{
                  color: d?.status === 'closed' ? 'blue' : '#000',
                  textDecoration: d?.status === 'closed' ? 'underline' : 'none',
                  cursor: d?.status === 'closed' ? 'pointer' : 'default'
                }}
              >
                {toNormalCase(d?.status)}
              </td>
              {[
                moment(d?.createdAt).format('DD/MM/YYYY'),
                moment(d?.dueFinishDate).format('DD/MM/YYYY')
              ]?.map((f: any) => <td key={f}>{f}</td>)}
            </tr>
          ))}
          {data?.length === 0 && (
            <tr>
              <td colSpan={9}>
                <EmptyContent label="No data found" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  )
}
