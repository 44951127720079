import { Route, Routes } from 'react-router-dom'

import AllPermitsList from '../pages/eptw/AllPermitsList'
import AnalyticsActions from '../pages/analytics/AnalyticsActions'
import AnalyticsRoutes from './AnalyticsRoutes'
import AuditActions from '../pages/audits/AuditActions'
import AuditRoutes from './AuditRoutes'
import CIOActions from '../pages/flows/CIOActions'
import CIORoutes from './CIORoutes'
import ClosedPermit from '../pages/dashboardPages/ClosedPermit'
import ClosedPermits from '../pages/dashboardPages/ClosedPermits'
import CloseOutList from '../pages/closeOutReports/CloseOutList'
import CloseOutPermit from '../pages/closeOutReports/CloseOutPermit'
import CreateFromDraft from '../pages/viewDraft'
import CWOIActions from '../pages/flows/CWOIActions'
import CWOIRoutes from './CWOIRoutes'
import DICActions from '../pages/flows/DICActions'
import DICRoutes from './DICRoutes'
import ExpiredList from '../pages/eptw/ExpiredList'
import InformationCard from '../pages/dashboardPages/InformationCard'
import InformationCardList from '../pages/informationCard/InformationCardList'
import InformationCardRoutes from './InformationCardRoutes'
import LessonLearnt from '../pages/dashboardPages/LessonLearnt'
import LessonLearntPage from '../pages/dashboardPages/LessonLearntPage'
import LiveIsolationsLTI from '../pages/LiveIsolationsLTI'
import LOTO from '../pages/dashboardPages/LOTORegister'
import LTIActions from '../pages/flows/LTIActions'
import LTIRoutes from './LTIRoutes'
import NewPermitActions from '../pages/flows/NewPermitActions'
import NewPermitRoutes from './NewPermitRoutes'
import NotFound from '../pages/NotFound'
import ObservationCardActions from '../pages/obersvationCard/ObservationCardActions'
import OverdueList from '../pages/eptw/OverdueList'
import OverrideRegister from '../pages/eptw/Overrides'
import PermitAudit from '../pages/dashboardPages/PermitAudit'
import PermitsByStatus from '../pages/deIsolationAndClose/ViewPermits'
import ReValidateActions from '../pages/flows/ReValidateActions'
import RevalidateRoutes from './RevalidateRoutes'
import RiskAssessmentTemplates from '../pages/RiskAssessmentsTemplates'
import SuspendedList from '../pages/eptw/SuspendedList'
import UpdateLivePermit from '../pages/eptw/UpdateLivePermit'
import ViewAudit from '../pages/audits/ViewAudit'
import ViewByLayout from '../pages/dashboardPages/ViewByLayout'
import ViewFullPermit from '../pages/eptw/ViewFullPermit'
import ViewInformationCard from '../pages/informationCard/ViewInformationCard'
import ViewRiskTemplate from '../components/previews/ViewRiskTemplate'
import WOIActions from '../pages/flows/WOIActions'
import WOIRoutes from './WOIRoutes'
import CreateFromPID from '../pages/eptw/CreateFromPID'
import IsolationsActions from '../pages/eptw/IsolationsActions'
import ValueRegister from '../pages/eptw/ValveRegister'

export default function EPTWRoutes() {
  return (
    <Routes>
      <Route path="/createFromPID" element={<CreateFromPID />} />
      <Route path="/newPermit" element={<NewPermitActions />} />
      <Route path="/newPermit/*" element={<NewPermitRoutes />} />
      <Route path="/revalidate" element={<ReValidateActions />} />
      <Route path="/revalidate/*" element={<RevalidateRoutes />} />
      <Route path="/deIsolationAndClose" element={<DICActions />} />
      <Route path="/deIsolationAndClose/*" element={<DICRoutes />} />
      <Route path="/lti" element={<LTIActions />} />
      <Route path="/lti/*" element={<LTIRoutes />} />
      <Route path="/cio" element={<CIOActions />} />
      <Route path="/cio/*" element={<CIORoutes />} />
      <Route path="/woi" element={<WOIActions />} />
      <Route path="/woi/*" element={<WOIRoutes />} />
      <Route path="/cwoi" element={<CWOIActions />} />
      <Route path="/cwoi/*" element={<CWOIRoutes />} />
      <Route path="/informationCard/*" element={<InformationCardRoutes />} />
      <Route path="/audits/*" element={<AuditRoutes />} />
      <Route path="/analytics" element={<AnalyticsActions />} />
      <Route path="/analytics/*" element={<AnalyticsRoutes />} />
      {/* <Route path="/analytics/resource" element={<AnalyticsActions />} /> */}
      {/* <Route path="/analytics/permitsByStatus" element={<></>} /> */}

      <Route path="/liveIsolations" element={<LiveIsolationsLTI />} />
      <Route
        path="/rejected"
        element={
          <PermitsByStatus
            currentPermits={'rejected'}
            checkDepartment
            flow="newPermit"
            onBack={'/eptw/dashboard'}
          />
        }
      />
      <Route
        path="/riskAssessmentTemplates"
        element={<RiskAssessmentTemplates />}
      />
      <Route
        path="/riskAssessmentTemplates/:id"
        element={<ViewRiskTemplate />}
      />
      <Route path="/closeOutReports" element={<CloseOutList />} />
      <Route path="/closeOutReports/:id" element={<CloseOutPermit />} />
      <Route path="/closedPermits" element={<ClosedPermits />} />
      <Route path="/closedPermits/:id" element={<ClosedPermit />} />
      <Route path="/rejectedPermits" element={<ClosedPermits />} />
      <Route path="/viewByLayout" element={<ViewByLayout />} />
      <Route path="/permitAudit/create" element={<PermitAudit />} />
      <Route path="/audits" element={<AuditActions />} />
      <Route path="/audits/viewAll/:id" element={<ViewAudit />} />
      <Route
        path="/informationCard/viewAll"
        element={<InformationCardList title={'VIEW ALL'} />}
      />
      <Route
        path="/informationCard/viewAll/:id"
        element={<ViewInformationCard />}
      />
      <Route path="/informationCard" element={<ObservationCardActions />} />
      <Route path="/informationCard/create" element={<InformationCard />} />
      <Route path="/lessonLearnt" element={<LessonLearnt />} />
      <Route path="/lessonLearnt/:id" element={<LessonLearntPage />} />
      <Route path="/lotoRegister" element={<LOTO />} />
      <Route path="/allPermits" element={<AllPermitsList />} />
      <Route
        path="/allPermits/:id"
        element={<ViewFullPermit onBack="/eptw/allPermits" />}
      />
      <Route path="/overdue" element={<OverdueList />} />
      <Route
        path="/overdue/:id"
        element={<UpdateLivePermit onBack={'/eptw/overdue'} />}
      />
      <Route path="/expired" element={<ExpiredList />} />
      <Route
        path="/expired/:id"
        element={<UpdateLivePermit onBack={'/eptw/expired'} />}
      />
      <Route path="/suspended" element={<SuspendedList />} />
      <Route path="/overrides" element={<OverrideRegister />} />
      <Route
        path="/suspended/:id"
        element={<UpdateLivePermit onBack={'/eptw/suspended'} />}
      />
      <Route
        path="/drafts"
        element={
          <PermitsByStatus
            onBack="/eptw/dashboard"
            currentPermits="draft"
            checkDepartment
            showPermitNumber={false}
          />
        }
      />
      <Route path="/drafts/:id" element={<CreateFromDraft />} />
      <Route path="/isolations" element={<IsolationsActions />} />
      <Route path="/valveRegister" element={<ValueRegister />} />
      <Route path="/audit" element={<AuditActions onBack="/eptw/menu" />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
