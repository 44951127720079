import { Col, Row, Table } from 'reactstrap'
import AshokLeylandLayout from './AshokLeylandLayout'
import { Link } from '../pages/workManagement/PmTree'
import { useNavigate } from 'react-router-dom'

export const warningListData = [
  {
    serial: 1,
    name: 'Manikandan',
    number: 'AL-PPKM-WL-001',
    desgination: 'Safety Committee Member',
    department: 'PPKM',
    createdOn: '04-05-2024'
  }
]

export default function AlWarningList({ onBack }: { onBack: any }) {
  const navigate = useNavigate()
  return (
    <AshokLeylandLayout title="WARNING LIST" onBack={onBack}>
      <Row style={{ margin: 15 }}>
        <Col>
          <Table>
            <thead>
              <tr style={{ backgroundColor: 'gold' }}>
                {[
                  'S.No',
                  'Number',
                  'Department',
                  'Name',
                  'Desgination',
                  'Created on'
                ]?.map((h: any) => <th key={h}>{h}</th>)}
              </tr>
            </thead>
            <tbody>
              {warningListData?.map((w: any) => (
                <tr key={w?.number}>
                  <td>{w?.serial}</td>
                  <td>
                    <Link onClick={() => navigate(w?.number)}>{w?.number}</Link>
                  </td>
                  <td>{w?.department}</td>
                  <td>{w?.name}</td>
                  <td>{w?.desgination}</td>
                  <td>{w?.createdOn}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </AshokLeylandLayout>
  )
}
