import { Row, Table } from 'reactstrap'
import { renderRow } from '../../pages/VerifyCM'
import { incrementby1 } from '../Forms/JSAForm'
import toNormalCase from '../lib/toNormalCase'
import Spacer from '../Spacer'
import { permitColor } from '../ViewAndApprovePermit'
import { ColoredSubHeader } from './ViewExcavationPermit'

type ViewIsolationsType = {
  data: any
  rowStyle: any
  renderRow: any
  permitData: any
}

export default function ViewIsolations({
  data,
  rowStyle,
  permitData
}: ViewIsolationsType) {
  const header: any = {
    'Permit Number': permitData?.woNumber,
    'Permit Title': permitData?.permitTitle,
    'Isolation Number': data?.[0]?.number?.replace(/-\d+$/, ''),
    'Lock Box Number': <b>{permitData?.isolations?.lockBoxNumber}</b>
  }
  return (
    <>
      <ColoredSubHeader rowStyle={rowStyle} text="Isolations" />
      <Row
        style={{
          ...rowStyle,
          fontSize: 14,
          margin: 0
        }}
      >
        {Object.keys(header).map((key) =>
          renderRow(key, header[key], permitColor(permitData?.type))
        )}
      </Row>
      <Spacer height={10} />
      <Row style={{ margin: 0 }}>
        <Table bordered striped style={{ borderColor: '#000' }}>
          <thead>
            <tr
              style={{
                backgroundColor: 'orange',
                WebkitPrintColorAdjust: 'exact'
              }}
            >
              {[
                'S.No',
                ...(data?.[0]?.number ? ['Number'] : []),
                'Equipment Description',
                'Equipment Tag',
                'Isolation Tag',
                'Method',
                'Type',
                'Isolation State',
                'DeIsolated State',
                'Override',
                'PBU Readings',
                ...(data?.[0]?.status ? ['Status'] : []),
                'Remarks'
              ]?.map((s: any) => <th key={s}>{s}</th>)}
            </tr>
          </thead>
          <tbody>
            {data?.map((i: any, index: number) => (
              <tr>
                {[
                  incrementby1(index),
                  ...(i?.number ? [i?.number] : []),
                  i?.isolationDescription,
                  i?.isolationPoint || 'NA',
                  i?.isolationTag,
                  i?.isolationMethod,
                  i?.isolationType,
                  i?.isolationState,
                  i?.deIsolatedState,
                  i?.override || 'NA',
                  i?.readings || 'NA',
                  ...(i?.status ? [toNormalCase(i?.status)] : []),
                  i?.isolationComment
                ]?.map((f: any, index: number) => (
                  <td key={`${f}_${index}`}>{f}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </>
  )
}
