import React from "react";
import { Col, Row, Container } from "reactstrap";
import { IoCaretBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import GradientBox from "../../components/GradientBox";
import Spacer from "../../components/Spacer";
import SuppluChainMenu from "../../SVGs/SupplyChainSVG";

export default function InventorySerivces() {
  const navigate = useNavigate();

  return (
    <Container fluid style={{ backgroundColor: "#FFF", minHeight: "100vh" }}>
      <Row>
        <Spacer height={10} />
        <Col
          xs="12"
          style={{
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: "#fff",
            padding: "10px 0 10px 0",
            alignItems: "center",
          }}
        >
          <GradientBox>
            <IoCaretBack
              size={60}
              color="#FFF"
              onClick={() => navigate("/cmms")}
            />
          </GradientBox>
          <div style={{ border: "1px solid #ccc" }}>
            <img
              src={require("./../../../src/images/e73_logo.jpeg")}
              alt="HOEC Logo"
              height={120}
            />
          </div>
          <div />
        </Col>
      </Row>
      <Col
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <SuppluChainMenu />
      </Col>
    </Container>
  );
}
