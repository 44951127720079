import { Col, Row, Table } from 'reactstrap'
import AshokLeylandLayout from './AshokLeylandLayout'

export default function AlScoreCardDashboard() {
  const TD = ({ children, style, colSpan, rowSpan }: any) => (
    <td
      colSpan={colSpan}
      rowSpan={rowSpan}
      style={{ ...style, padding: 0, paddingLeft: 5, paddingRight: 5 }}
    >
      {children}
    </td>
  )
  return (
    <AshokLeylandLayout
      title="SCORE CARD DASHBOARD"
      onBack="/ashokleyland/training/scorecard"
    >
      <Row style={{ margin: 15 }}>
        <Col>
          <Table
            bordered
            border={1}
            style={{
              width: '100%',
              borderCollapse: 'collapse',
              borderColor: '#000'
            }}
          >
            <thead>
              <tr>
                <th colSpan={13} style={{ textAlign: 'center' }}>
                  MONTHLY EHS SCORE CARD MAPPING
                </th>
              </tr>
              <tr>
                <th colSpan={13} style={{ textAlign: 'center', fontSize: 12 }}>
                  EHS KRA coverage should be 20% for SCM & 10% for Section Heads
                </th>
              </tr>
              <tr>
                <th>Accountability</th>
                <th>Responsibility</th>
                <th>Measures</th>
                <th>#</th>
                <th>Concerns</th>
                <th>Each Department Coverage</th>
                <th colSpan={2}>Dept./ Month</th>
                <th colSpan={2}>Dept./Year</th>
                <th colSpan={2}>For 10 Dept.</th>
                <th>Sharing</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TD rowSpan={24}>EHS LEAD</TD>
                <TD rowSpan={24}>Section Head & SCM</TD>
                <TD
                  rowSpan={18}
                  style={{
                    backgroundColor: 'lightgreen',
                    verticalAlign: 'middle',
                    textAlign: 'center'
                  }}
                >
                  Proactive
                </TD>
                <TD>1</TD>
                <TD colSpan={9}>Trainings</TD>
              </tr>
              <tr>
                <TD></TD>
                <TD rowSpan={3}>Pep talk</TD>
                <TD rowSpan={3}>
                  4 Peptalk <br />
                  (Per Peptalk- 10 Head Count/10 Minutes)
                </TD>
                <TD>40</TD>
                <TD>Head Count</TD>
                <TD>480</TD>
                <TD>Head Count</TD>
                <TD>4800</TD>
                <TD>Head Count</TD>
                <TD rowSpan={6}>10</TD>
              </tr>
              <tr>
                <TD />
                <TD>400</TD>
                <TD> Minutes</TD> <TD>4800</TD> <TD>Minutes</TD> <TD>48000 </TD>
                <TD>Minutes</TD>
              </tr>
              <tr>
                <TD />
                <TD>6.7</TD>
                <TD> Hours</TD> <TD>80.4</TD> <TD>Hours</TD> <TD>80 </TD>
                <TD>Hours</TD>
              </tr>
              <tr>
                <TD></TD>
                <TD rowSpan={3}>Awareness Training</TD>
                <TD rowSpan={3}>
                  1 Awarness Meeting <br />
                  (Per Meeting - 10 Head Count/60 Minutes)
                </TD>
                <TD>10</TD>
                <TD>Head Count</TD>
                <TD>120</TD>
                <TD>Head Count</TD>
                <TD>1200</TD>
                <TD>Head Count</TD>
              </tr>
              <tr>
                <TD />
                <TD>600</TD>
                <TD> Minutes</TD> <TD>7200</TD> <TD>Minutes</TD> <TD>72000 </TD>
                <TD>Minutes</TD>
              </tr>
              <tr>
                <TD />
                <TD>10</TD>
                <TD> Hours</TD> <TD>120</TD> <TD>Hours</TD> <TD>1200</TD>
                <TD>Hours</TD>
              </tr>
              <tr>
                <TD>2</TD>
                <TD colSpan={9}>Safety Observations</TD>
              </tr>
              <tr>
                <TD />
                <TD>Unsafe Act (identifying Risk Behaviour)</TD>
                <TD>1 Observation for every forthnight</TD>
                <TD>2</TD>
                <TD>Numbers</TD>
                <TD>24</TD>
                <TD>Numbers</TD>
                <TD>240</TD>
                <TD>Numbers</TD>
                <TD rowSpan={4}>10</TD>
              </tr>
              <tr>
                <TD />
                <TD>Unsafe Condition</TD>
                <TD>1 Observation for every forthnight</TD>
                <TD>2</TD>
                <TD>Numbers</TD>
                <TD>24</TD>
                <TD>Numbers</TD>
                <TD>240</TD>
                <TD>Numbers</TD>
              </tr>
              <tr>
                <TD />
                <TD>Unsafe condition due to Unsafe Act</TD>
                <TD>1 Observation for every forthnight</TD>
                <TD>2</TD>
                <TD>Numbers</TD>
                <TD>24</TD>
                <TD>Numbers</TD>
                <TD>240</TD>
                <TD>Numbers</TD>
              </tr>
              <tr>
                <TD />
                <TD>5S</TD>
                <TD>1 Observation for every forthnight</TD>
                <TD>2</TD>
                <TD>Numbers</TD>
                <TD>24</TD>
                <TD>Numbers</TD>
                <TD>240</TD>
                <TD>Numbers</TD>
              </tr>
              <tr>
                <TD>3</TD>
                <TD colSpan={8}>Capturing of Near Miss</TD>
                <TD>15</TD>
              </tr>
              <tr>
                <TD />
                <TD>Capturing & Closing</TD>
                <TD>1 Incident capturing for every forthnight</TD> <TD>2</TD>
                <TD>Numbers</TD> <TD>24</TD> <TD>Numbers</TD> <TD>240</TD>
                <TD>Numbers</TD> <TD>CGPA</TD>
              </tr>
              <tr>
                <TD>4</TD>
                <TD colSpan={8}>Capturing of Positive behaviour </TD>
                <TD rowSpan={2}>10</TD>
              </tr>
              <tr>
                <TD />
                <TD>Identifying Safe Behaviour</TD>
                <TD>1 Behaviour</TD> <TD>1</TD>
                <TD>Safe Behaviour</TD> <TD>12</TD> <TD>Safe Behaviour</TD>{' '}
                <TD>120</TD>
                <TD>Safe Behaviour</TD>
              </tr>
              <tr>
                <TD>5</TD>
                <TD colSpan={8}>Simplified HIRA Project</TD>
                <TD rowSpan={2}>10</TD>
              </tr>
              <tr>
                <TD />
                <TD>HIRA Projects for identified Risk</TD>
                <TD>1 Project (Up on 100% Implementation)</TD> <TD>1</TD>
                <TD>Project</TD> <TD>12</TD> <TD>Project</TD> <TD>120</TD>
                <TD>Project</TD>
              </tr>
              <tr>
                <TD
                  rowSpan={6}
                  style={{
                    backgroundColor: 'red',
                    verticalAlign: 'middle',
                    textAlign: 'center'
                  }}
                >
                  Reactive
                </TD>
                <TD>6</TD>
                <TD colSpan={8}>Injury/Incident Rate (Target-Zero) </TD>
                <TD rowSpan={2}>15</TD>
              </tr>
              <tr>
                <TD />
                <TD>Number Of Occurances</TD>
                <TD style={{ whiteSpace: 'nowrap' }}>
                  5 Marks reduction for One Occurance <br />
                  10 Marks reduction for One Occurance
                  <br />
                  15 Marks reduction for One Occurance
                  <br />
                </TD>
                <TD>0</TD>
                <TD>Numbers</TD> <TD>0</TD> <TD>Numbers</TD> <TD>0</TD>
                <TD>Numbers</TD>
              </tr>
              <tr>
                <TD>7</TD>
                <TD colSpan={8}>No. Of Warning Card Issued (Target-Zero) </TD>
                <TD rowSpan={2}>15</TD>
              </tr>
              <tr>
                <TD />
                <TD>Number Of Occurances</TD>
                <TD style={{ whiteSpace: 'nowrap' }}>
                  5 Marks reduction for One Occurance <br />
                  10 Marks reduction for One Occurance
                  <br />
                  15 Marks reduction for One Occurance
                  <br />
                </TD>
                <TD>0</TD>
                <TD>Numbers</TD> <TD>0</TD> <TD>Numbers</TD> <TD>0</TD>
                <TD>Numbers</TD>
              </tr>
              <tr>
                <TD>8</TD>
                <TD colSpan={8}>Repetative Safety Violations (Target-Zero) </TD>
                <TD rowSpan={2}>15</TD>
              </tr>
              <tr>
                <TD />
                <TD>Number Of Occurances</TD>
                <TD style={{ whiteSpace: 'nowrap' }}>
                  5 Marks reduction for One Occurance <br />
                  10 Marks reduction for One Occurance
                  <br />
                  15 Marks reduction for One Occurance
                  <br />
                </TD>
                <TD>0</TD>
                <TD>Numbers</TD> <TD>0</TD> <TD>Numbers</TD> <TD>0</TD>
                <TD>Numbers</TD>
              </tr>
              <tr>
                <TD
                  colSpan={13}
                  style={{ textAlign: 'right', fontWeight: 700 }}
                >
                  Total Weigthage: 100
                </TD>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </AshokLeylandLayout>
  )
}
