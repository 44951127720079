import { useEffect, useState } from 'react'
import { useForm } from 'react-final-form'
import { Field } from 'react-final-form'
import { InputActionMeta } from 'react-select'
import Select from 'react-select/dist/declarations/src/Select'
import { Col, Input } from 'reactstrap'
import CustomLabel from './CustomLabel'
import { getToday } from './DateTime'
import MultiSelect from './MultiSelect'

const textArea = {
  border: '1px solid #CCC',
  borderRadius: 5,
  width: '100%',
  height: 40,
  paddingLeft: 5
}

type InputType = {
  label?: string
  name?: any
  isDisabled?: boolean
  occupy?: number
  permitType: string
}

export default function LabeledStartDateInput({
  label,
  name,
  isDisabled = false,
  occupy = 6,
  permitType
}: InputType) {
  const [date, setDate]: any = useState()
  const [shift, setShift]: any = useState('AM')

  const { change } = useForm()

  useEffect(() => {
    if (permitType === 'hotWork' && date) {
      const startDate = new Date(date)
      const selectedDate = new Date(date)

      shift === 'AM'
        ? selectedDate.setMinutes(selectedDate.getMinutes() + 840)
        : selectedDate.setMinutes(selectedDate.getMinutes() + 1560)

      change(name, startDate.toISOString().slice(0, 10))
      change('endDate', selectedDate)
    }
  }, [date, shift])

  const today = getToday()

  return (
    <>
      <Col md={occupy} key={`${name}*${label}`}>
        <CustomLabel label={label} />
        <Field name={name} style={textArea}>
          {({ input, meta }) => (
            <div key={`${name}^${label}`}>
              <Input
                {...input}
                type="date"
                style={textArea}
                disabled={isDisabled}
                onChange={(e) =>
                  setDate(e.target.value < today ? today : e.target.value)
                }
                min={getToday()}
              />
              {meta.error && meta.touched && (
                <span style={{ color: 'red', fontWeight: 500 }}>
                  {meta.error}
                </span>
              )}
            </div>
          )}
        </Field>
      </Col>
      <Col md={1}>
        <CustomLabel label="Shift" />
        <select
          style={{ ...textArea, width: 60 }}
          name="cars"
          id="cars"
          onChange={(e: any) => setShift(e.target.value)}
        >
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </Col>
    </>
  )
}
