import moment from 'moment'
import { Col, Row, Spinner } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { Box, renderData } from '../workManagement/PmTree'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { mocApi } from '../../components/serverurl'
import { renderArray } from '../WaitingForPIC'
import { ViewHistory } from '../ViewCM'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'

const getStage1Data = (data: any) => ({
  'MOC Number': data?.number,
  Equipment: data?.equipment,
  Department: data?.department,
  'Moc Type': data?.mocType,
  Benefits: renderArray(data?.benefits, false),
  'Type of Change': renderArray(data?.typeOfChange, false),
  createdOn: moment(data?.createdOn).format('DD-MM-YYYY hh:mm:ss A'),
  Status: 'MOC Stage 2',
  'Incident Report Number': data?.incidentReportNumber
})

export default function ViewMoc({ onBack }: any) {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const fetchMoc = async () =>
    await makeAuthenticatedRequest(
      `${mocApi}/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data, isLoading }: any = useQuery('MOC', fetchMoc)

  const stage1Data: any = getStage1Data(data?.data?.moc)

  if (isLoading) {
    return (
      <ProtectedLayout onBack={onBack}>
        <Row style={{ margin: 15 }}>
          <div
            style={{
              display: 'flex',
              height: '80vh',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Spinner size={'lg'} />
          </div>
        </Row>
      </ProtectedLayout>
    )
  }

  return (
    <ProtectedLayout onBack={onBack}>
      <Row style={{ margin: 15 }}>
        <ViewHistory data={data?.data?.history} title="MOC APPROVAL HISTORY" />
        <Col md="6">
          <Box heading="MOC Details">
            <>
              {Object.keys(stage1Data)?.map(
                (k: any) => stage1Data[k] && renderData(k, stage1Data[k])
              )}
            </>
          </Box>
          {/* {data?.report?.stage2 && (
            <Box heading="Stage 2 Details">
              <>
                {Object.keys(stage2Data)?.map(
                  (k: any) => stage2Data[k] && renderData(k, stage2Data[k])
                )}
              </>
            </Box>
          )} */}
        </Col>
      </Row>
    </ProtectedLayout>
  )
}
