import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, ModalFooter, Table } from "reactstrap";

import RcmLayout from "../../components/rcm/RcmLayout";
import { SubHeader } from "../VerifyCM";

export const maintainableItems = [
  {
    "item": "Actuating device",
    "equipmentNumber": "71-HV-0009",
    "equipmentDescription": "Actuated Valve From Lp Fuel Gas System To Deck Water Seal",
  },
  {
    "item": "Antisurge System",
    "equipmentNumber": "71-FV-6538A",
    "equipmentDescription": "Antisurge Valve for Gas Export Compressor Train A 27-KA-6003A",
  },
  {
    "item": "Antisurge System",
    "equipmentNumber": "71-FV-6538B",
    "equipmentDescription": "Antisurge Valve for Gas Export Compressor Train B 27-KA-6003A",
  },
  {
    "item": "Balance piston ",
    "equipmentNumber": "71-XV-0909A",
    "equipmentDescription": "F.O. Burner Purge Steam Outlet Check Piston Valve",
  },
  {
    "item": "Balance piston ",
    "equipmentNumber": "71-XV-0912A",
    "equipmentDescription": "Atomiz. Steam Inlet Piston Valve",
  },
  {
    "item": "Filter",
    "equipmentNumber": "71-FL-001",
    "equipmentDescription": "Main - Coarse Filters / Multimedia Filters",
  },
  {
    "item": "Pump",
    "equipmentNumber": "71-PE-6401A",
    "equipmentDescription": "1St Stage Standby Oil Pump",
  },
  {
    "item": "Pump",
    "equipmentNumber": "71-PE-6402A",
    "equipmentDescription": "1St Stage Duty Oil Pump",
  },
  {
    "item": "Pump",
    "equipmentNumber": "71-PE-6403A",
    "equipmentDescription": "1St Stage Oil Pump",
  },
  {
    "item": "Pump",
    "equipmentNumber": "71-PE-6451A",
    "equipmentDescription": "2ND Stage Standby Oil Pump",
  },
  {
    "item": "Pump",
    "equipmentNumber": "71-PE-6452A",
    "equipmentDescription": "2ND Stage Duty Oil Pump",
  },
  {
    "item": "Pump",
    "equipmentNumber": "71-PE-6453A",
    "equipmentDescription": "2ND Stage Oil Pump",
  }
]

export default function FpsoBoundaryMaintainable() {
  const [selected, setSelected]: any = useState([])

  const eqString: any = localStorage.getItem('equipmentList')
  const eq = JSON.parse(eqString || `[]`)
  const navigate = useNavigate()

  // useEffect(() => {
  //   if (selected?.length === 0) {
  //     setSelected(eq)
  //   }
  // }, [eq]
  // )

  return (
    <RcmLayout title={'FPSO Boundary'} onBack=''>
      <SubHeader header="Maintainable Item Level Analysis" permitColor="gold" />
      <Table bordered style={{ borderColor: '#000' }}>
        <thead>
          <tr>
            <th>Select</th>
            <th className="text-center">S.No</th>
            {/* <th>Maintainable Items</th> */}
            <th>Maintainable Item No</th>
            <th>Maintainable Item Description</th>
          </tr>
        </thead>
        <tbody>
          {maintainableItems?.map((i: any, index: any) => <tr key={index}>
            <td className="text-center" style={{ width: '3vw' }}><Input type="checkbox"
              onChange={(e) =>
                e?.target?.checked
                  ? setSelected([...selected, i])
                  : setSelected(selected?.filter((s: any) => s?.equipmentNumber !== i?.equipmentNumber))
              } checked={selected?.some((s: any) => s?.equipmentNumber === i?.equipmentNumber)} /></td>
            <td className="text-center">{index + 1}</td>
            {/* <td>{i?.item}</td> */}
            <td>{i?.equipmentNumber}</td>
            <td>{i?.equipmentDescription}</td>
          </tr>)}
        </tbody>
      </Table>
      <ModalFooter >
        <Button color="warning" onClick={() => navigate('/cmms/rcm/stage2/boundarySystem')}>Go back</Button>
        <Button color="warning" onClick={() => {
          localStorage.setItem('equipmentList', JSON.stringify(selected))
          const rcmId = localStorage.getItem('rcmId')
          navigate(`/cmms/rcm/stage2/${rcmId}`)
        }}>Add Items</Button>
      </ModalFooter>

    </RcmLayout >
  )
}