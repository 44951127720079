import moment from 'moment'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { useState } from 'react'

import CustomLabel from '../../components/InputFields/CustomLabel'
import ModalTitle from '../../components/ModalTitle'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import Spacer from '../../components/Spacer'
import SectionHeader from '../../components/Layout/SectionHeader'
import { incrementby1 } from '../../components/Forms/JSAForm'

const UpdateCounter = ({
  isOpen,
  toggle,
  setCounter
}: {
  isOpen: any
  toggle: any
  setCounter: any
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalTitle title="Update Counter" height={100} />
      <ModalBody>
        <CustomLabel label="Update Current Value" />
        <Input
          onChange={(e: any) => setCounter(e.target.value)}
          defaultValue="256"
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Cancel</Button>
        <Button color="warning" onClick={toggle}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const data = [
  {
    number: '20024/000003',
    title: 'PM - 2000 hours Maintenance for Solar Turbine',
    compoent: 'PF566-CMVH-0001_MIS',
    priority: '1',
    department: 'Mechanical',
    status: 'To DO',
    function: 'PF-566-20001',
    functionDescription: 'VHF GMDSS Survival Craft Portable Radio',
    maintenanceType: 'Essential',
    maintenanceClass: '',
    maintenanceClause: 'Conition Based',
    attachment: 'No',
    jobCode: 'SA-00089',
    dueFinish: '30-04-08',
    frequency: '2000 hrs'
  },
  {
    number: '2024/000004',
    title: 'PF-566-20001',
    compoent: 'PF566-CMVH-0013_MIS ',
    priority: '1',
    department: 'Safety',
    status: 'To DO',
    function: 'PF566-40001',
    functionDescription: 'VHF Handportable Radio With Charger',
    maintenanceType: 'Essential',
    maintenanceClass: '',
    maintenanceClause: 'Conition Based',
    attachment: 'No',
    jobCode: 'SA-00089',
    dueFinish: '30-04-08',
    frequency: 'Weekly'
  },
  {
    number: '2024/000005',
    title: 'SA-00089 - Portable VHF GMDSS Radio Checks',
    compoent: 'PF566-CMVH-0014_MIS ',
    priority: '1',
    department: 'Safety',
    status: 'To DO',
    function: 'PF566-50001',
    functionDescription: 'VHF Fixed Radio With Charger',
    maintenanceType: 'Important',
    maintenanceClass: '',
    maintenanceClause: 'Condition Based',
    attachment: 'No',
    jobCode: 'SA-00089',
    dueFinish: '30-04-08',
    frequency: '100 hrs'
  },
  {
    number: '2024/000006',
    title: 'ME-00057 - Crane Weekly Inspection',
    compoent: 'X-G0503A',
    priority: '3',
    department: 'Maint T/S',
    status: 'To DO',
    function: 'TS874-10001',
    functionDescription: 'Crane - Marine Crane 25 ton',
    maintenanceType: 'Important',
    maintenanceClass: '',
    maintenanceClause: 'Condition Based',
    attachment: 'No',
    jobCode: 'ME-00057',
    dueFinish: '14-03-08',
    frequency: '100 hrs'
  },
  {
    number: '2024/000007',
    title: 'MM-00008 - Hawser Pre/During/Post Offload Inspn',
    compoent: 'BB0100',
    priority: '2',
    department: 'Mooring Master',
    status: 'To DO',
    function: 'VE885-00001',
    functionDescription: 'Hawser',
    maintenanceType: 'Important',
    maintenanceClass: '',
    maintenanceClause: 'Condition Based',
    attachment: 'No',
    jobCode: 'MM-00008',
    dueFinish: '04-07-08',
    frequency: '100 hrs'
  },
  {
    number: '2024/000008',
    title: 'CA-00127IC - Inspection of Export Meter System IC',
    compoent: 'A-F6501',
    priority: '2',
    department: 'Cargo',
    status: 'To DO',
    function: 'TS664-00000',
    functionDescription: 'METERING PKG',
    maintenanceType: 'Important',
    maintenanceClass: '',
    maintenanceClause: 'Conition Based',
    attachment: 'No',
    jobCode: 'CA-00127IC',
    dueFinish: '01-07-09',
    frequency: '100 hrs'
  },
  {
    number: '2024/8789',
    title: 'Inspection and Replace Filters',
    compoent: 'A-V1610',
    priority: '2',
    department: 'MECH',
    status: 'To DO',
    function: 'VE116-00050',
    functionDescription: 'MGO Purifier Package',
    maintenanceType: 'Important',
    maintenanceClass: '',
    maintenanceClause: 'Conition Based',
    attachment: 'No',
    jobCode: 'CA-00127IC',
    dueFinish: '01-07-09',
    frequency: '100 hrs'
  },
  {
    number: '2023/09787',
    title: 'Inspection and Replace Filters',
    compoent: 'A-V1810',
    priority: '2',
    department: 'MECH',
    status: 'To DO',
    function: 'VE116-10000',
    functionDescription: 'Air Compressor Package-1',
    maintenanceType: 'Important',
    maintenanceClass: '',
    maintenanceClause: 'Periodic',
    attachment: 'No',
    jobCode: 'CA-00127IC',
    dueFinish: '01-07-09',
    frequency: '100 hrs'
  },
  {
    number: '2024/8989',
    title: 'Inspection and Replace Filters',
    compoent: 'A-V1840',
    priority: '2',
    department: 'MECH',
    status: 'To DO',
    function: 'VE118 04000',
    functionDescription: 'Air Compressor Package-4',
    maintenanceType: 'Important',
    maintenanceClass: '',
    maintenanceClause: 'Conition Based',
    attachment: 'No',
    jobCode: 'CA-00127IC',
    dueFinish: '01-07-09',
    frequency: '100 hrs'
  }
]

export default function PreventiveMaintenanceList() {
  const [modal, setModal]: any = useState(false)
  const [counter, setCounter]: any = useState()

  return (
    <ProtectedLayout onBack="/planning/pmjobs/dashboard">
      <Row style={{ margin: 10 }}>
        <SectionHeader title="Preventive Maintainenace list" />
        <Spacer height={10} />
        <Table bordered style={{ border: '1px solid #000' }}>
          <thead>
            <tr style={{ backgroundColor: 'orange' }}>
              <th>S.No</th>
              <th>Number</th>
              <th>Title</th>
              <th>Component</th>
              <th>Priority</th>
              <th>Resp. Discipline</th>
              <th>Status</th>
              <th>Function No.</th>
              <th>Function Description</th>
              <th>Frequency</th>
              <th>Maintenance Type</th>
              <th>Maintenance Class</th>
              <th>Maintenance Clause</th>
              <th>Attachments</th>
              <th>Job Code</th>
              <th>Due Finish</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((p: any, index: number) => (
              <tr key={`${p?.tagNumber}-${index}`}>
                <td>{incrementby1(index)}</td>
                <td>{p?.number}</td>
                <td>{p?.title}</td>
                <td>{p?.compoent}</td>
                <td>{p?.priority}</td>
                <td>{p?.department}</td>
                <td>{p?.status}</td>
                <td>{p?.function}</td>
                <td>{p?.maintenanceType}</td>
                <td>{p?.frequency}</td>
                <td>{p?.maintenanceClass}</td>
                <td>{p?.maintenanceClause}</td>
                <td>PM-Planned</td>
                <td>{p?.attachment}</td>
                <td>{p?.jobCode}</td>
                <td>{p?.dueFinish}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      <UpdateCounter
        isOpen={modal}
        toggle={() => setModal(!modal)}
        setCounter={setCounter}
      />
    </ProtectedLayout>
  )
}
