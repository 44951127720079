import { Col, Container } from 'reactstrap'

export default function MobileLayout({ logo, backgroundColor, children }: any) {
  return (
    <Container fluid style={{ padding: 0 }}>
      <div
        style={{
          backgroundColor: backgroundColor || '#FAFAD2',
          overflowX: 'hidden',
          width: '100%'
        }}
      >
        <Col style={{ padding: 10 }}>
          <img
            src={logo || require('./../../images/Icons/Clamp.png')}
            alt="Brand Logo"
            height={30}
            style={{ marginLeft: logo ? 10 : 10, mixBlendMode: 'darken' }}
          />
        </Col>
      </div>
      {children}
    </Container>
  )
}
