import { Row, Spinner, Table } from 'reactstrap'
import React from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { stock } from '../../components/serverurl'
import { Link } from '../workManagement/PmTree'
import { prStatuses } from '../../components/constants/woStatuses'

export const PaymentRequestsTable = ({
  data,
  isLoading,
  isRefetching,
  refetch,
  clickable
}: {
  data: any
  isLoading?: boolean
  isRefetching?: boolean
  refetch: any
  clickable: any
}) => {
  const navigate = useNavigate()
  return (
    <>
      <>
        <Table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>JOB Number</th>
              <th>TO Number</th>
              <th>TO Status</th>
              <th>Vendor Name</th>
              <th>Warehouse Location</th>
            </tr>
          </thead>
          <tbody>
            <>
              {isLoading || isRefetching ? (
                <tr>
                  <td colSpan={12}>
                    <Row
                      style={{
                        height: 500,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Spinner />
                    </Row>
                  </td>
                </tr>
              ) : (
                data?.length > 0 &&
                data?.map((w: any, index: number) => (
                  <React.Fragment key={w?._id}>
                    <tr key={w?._id}>
                      <td>{incrementby1(index)}</td>
                      <td>
                        {
                          <Link onClick={() => navigate(w?._id)}>
                            {w?.number}
                          </Link>
                        }
                      </td>
                      <td>{w?.transportOrder?.number}</td>
                      <td>{prStatuses[w?.transportOrder?.status]}</td>
                      <td>{w?.transportOrder?.vendorName}</td>
                      <td>{w?.transportOrder?.wareHouseLocation}</td>
                    </tr>
                  </React.Fragment>
                ))
              )}
            </>
          </tbody>
        </Table>
      </>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Data Found
          </p>
        </Row>
      )}
    </>
  )
}

export default function VendorPaymentRequests({
  onBack,
  url,
  title,
  status
}: {
  onBack: string
  url: any
  title: string
  status: string
}) {
  const { accounts, instance } = useMsal()

  const fetchprs = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/inventory/vendorOrders`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, isRefetching, refetch }: any = useQuery(
    'prs',
    fetchprs
  )

  const prs = data?.voDetails?.filter((v: any) => v?.status === status)

  return (
    <ProtectedLayout onBack={onBack} title={title}>
      <Row style={{ marginLeft: 15, marginRight: 15 }}>
        <PaymentRequestsTable
          data={prs}
          clickable={false}
          isLoading={isLoading}
          isRefetching={isRefetching}
          refetch={refetch}
        />
      </Row>
    </ProtectedLayout>
  )
}
