import { useState } from 'react'
import { Form } from 'react-final-form'
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import { yesNoOptions } from '../../pages/eptw/VerifyPermit'
import { getOptions } from '../../pages/firs'
import CustomLabel from '../InputFields/CustomLabel'
import DropDownFetch from '../InputFields/DropDownFetch'
import LabeledCheckBoxes from '../InputFields/LabeledCheckBoxes'
import LabeledRadioButtons from '../InputFields/LabeledRadioButton'
import LabledParagraphInput from '../InputFields/LabledParagraph'
import Select from '../InputFields/Select'
import { tagsAPI } from '../serverurl'
import Spacer from '../Spacer'
import { criticalList } from './HOECRiskAssesmentForm'
import { protectiveEquipment } from './NewPermitForm'
import equipmentPreparationList from './../constants/quipment.json'
import ppeRequirementList from './../constants/ppeRequirements.json'
import MultiSelect from '../InputFields/MultiSelect'
import LabeledTextInput from '../InputFields/LabeledTextInput'
import toolsList from './../constants/toolsList.json'

export default function EditPermitDetails({
  isOpen,
  toggle,
  values,
  setValues
}: {
  isOpen: any
  toggle: any
  values: any
  setValues: any
}) {
  const [equipment, setEquipment]: any = useState(getOptions(values?.equipment))
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} size="xl">
        <ModalBody>
          <Form
            onSubmit={(v: any) =>
              setValues({
                department: v?.department?.map((d: any) => d?.value),
                workLocation: v?.workLocation?.value,
                permitTitle: v?.permitTitle,
                workDescription: v?.workDescription,
                riskAssessment: v?.riskAssessment,
                isJSA: v?.isJSA,
                needIsolation: v?.needIsolation,
                equipmentPreparation: v?.equipmentPreparation,
                othersEquipmentPreparation: v?.othersEquipmentPreparation,
                protectStdByEquipment: v?.protectStdByEquipment,
                othersProtectStdByEquipment: v?.othersProtectStdByEquipment,
                ppeRequirement: v?.ppeRequirement,
                othersPpeRequirement: v?.othersPpeRequirement,
                gasMeasurements: v?.gasMeasurements,
                equipment: values?.equipment,
                // equipment: equipment?.map((m: any) => m?.value),
                machinery: v?.machinery?.map((m: any) => m?.value),
                othersTools: v?.othersTools,
                watchRequired: v?.watchRequired,
                specialInstructions: v?.specialInstructions
              })
            }
            // validate={(values: any) => permitValidation(values)}
            initialValues={{
              department: getOptions(values?.department),
              workLocation: {
                value: values?.workLocation,
                label: values?.workLocation
              },
              permitTitle: values?.permitTitle,
              workDescription: values?.workDescription,
              riskAssessment: values?.riskAssessment,
              // isJSA: values?.isJSA,
              needIsolation: values?.needIsolation,
              equipmentPreparation: values?.equipmentPreparation,
              othersEquipmentPreparation: values?.othersEquipmentPreparation,
              protectStdByEquipment: values?.protectStdByEquipment,
              othersProtectStdByEquipment: values?.othersProtectStdByEquipment,
              ppeRequirement: values?.ppeRequirement,
              othersPpeRequirement: values?.othersPpeRequirement,
              gasMeasurements: values?.gasMeasurements,
              equipment: getOptions(values?.equipment),
              // equipment: equipment?.map((m: any) => m?.value),
              machinery: getOptions(values?.machinery),
              othersTools: values?.othersTools,
              watchRequired: values?.watchRequired,
              specialInstructions: values?.specialInstructions
            }}
            render={({ handleSubmit, hasValidationErrors, form, values }) => (
              <form onSubmit={handleSubmit}>
                <>
                  <LabledParagraphInput
                    name="permitTitle"
                    label="Permit Title"
                    occupy={12}
                  />
                  <LabledParagraphInput
                    name="workDescription"
                    label="Description of work"
                    occupy={12}
                  />
                  <Row>
                    <Col xs={4}>
                      <Select
                        isMulti
                        name="department"
                        label="Department"
                        options={[
                          'Mechanical',
                          'Electrical',
                          'Instrument',
                          'Production',
                          'Admin Ware House',
                          'HSE'
                        ]?.map((d: any) => ({ value: d, label: d }))}
                      />
                    </Col>
                    <Col xs={4}>
                      <Select
                        name="workLocation"
                        isMulti={false}
                        label="Location of Work"
                        options={[
                          'Mechanical Workshop',
                          'Fire Station',
                          'Sub Station',
                          'Control Room',
                          'Process Area',
                          'Admin Ware House'
                        ]?.map((d: any) => ({ value: d, label: d }))}
                      />
                    </Col>
                    <Col xs="4" />
                    {/* <Col xs="4">
                      <CustomLabel label="Equipment" />
                      <DropDownFetch
                        name="equipment"
                        value={equipment}
                        setValue={setEquipment}
                        url={`${tagsAPI}`}
                        isMulti
                      />
                    </Col> */}

                    <LabeledRadioButtons
                      occupy={8}
                      defaultValue="Yes"
                      name="riskAssessment"
                      label="HSE Assistance for Risk Assesment / Site inspection required"
                      list={yesNoOptions}
                      errorText="Add Risk Assessment"
                    />

                    {/* <LabeledRadioButtons
                      occupy={4}
                      name="isJSA"
                      label="Task Criticality"
                      list={criticalList}
                      defaultValue={'Critical'}
                    /> */}
                    {/* <LabeledRadioButtons
                      occupy={4}
                      defaultValue="yes"
                      name="needIsolation"
                      label="Need Isolation"
                      list={yesNoOptions}
                    /> */}
                    <Col xs="4" />

                    <LabeledCheckBoxes
                      occupy={12}
                      name="equipmentPreparation"
                      label="Equipment Preparation"
                      list={equipmentPreparationList}
                      hasOthers
                      othersName="othersEquipmentPreparation"
                    />
                    <LabeledCheckBoxes
                      occupy={12}
                      name="protectStdByEquipment"
                      label="Protective Equipment & Stand by Equipment"
                      list={protectiveEquipment}
                      hasOthers
                      othersName="othersProtectStdByEquipment"
                    />
                    <LabeledCheckBoxes
                      occupy={12}
                      name="ppeRequirement"
                      label="PPE Required"
                      list={ppeRequirementList}
                      hasOthers
                      othersName="othersPpeRequirement"
                    />
                    <LabeledRadioButtons
                      occupy={4}
                      defaultValue="One Time"
                      name="gasMeasurements"
                      label="Gas Measurements"
                      list={['Continuous', 'Intermittent', 'One Time']?.map(
                        (k: any) => ({ label: k, value: k })
                      )}
                    />
                    <MultiSelect
                      occupy={4}
                      name="machinery"
                      label="Mobile machinery and tools"
                      options={toolsList}
                    />
                    <LabeledTextInput
                      occupy={4}
                      name="othersTools"
                      label="Mobile Tools Others"
                    />
                    <LabeledRadioButtons
                      occupy={4}
                      name="watchRequired"
                      label="Safety watch required"
                      list={yesNoOptions}
                    />
                    <LabledParagraphInput
                      name="specialInstructions"
                      label="Special Precautions"
                      occupy={8}
                    />
                  </Row>
                </>
                <Spacer height={10} />
                <ModalFooter>
                  <Button color="warning" onClick={toggle}>
                    Cancel
                  </Button>
                  <Button
                    color="warning"
                    onClick={
                      () => {
                        form.submit()
                        toggle()
                      }

                      // toggle()
                    }
                  >
                    Save & Next
                  </Button>
                </ModalFooter>
              </form>
            )}
          />
        </ModalBody>
      </Modal>
    </>
  )
}
