import { Col, Row } from 'reactstrap'

import AshokLeylandLayout from './AshokLeylandLayout'
import AlFirsMenu from '../SVGs/AlFirsMenu'

export default function AlFirsServices() {
  return (
    <AshokLeylandLayout onBack="/ashokLeyland" title="Services">
      <Row style={{ margin: 10 }}>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <AlFirsMenu />
        </Col>
      </Row>
    </AshokLeylandLayout>
  )
}
