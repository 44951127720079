import { useState } from 'react'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane
} from 'reactstrap'

import './../../components/nav.css'

const workOrderDetails = [
  {
    WO_Number: '2016/008515',
    Description: 'ME-00016 - CBM Generic Oil Sampling Routine',
    Equipment: '43PB002B',
    Priority: 1,
    RespDiscipline: 'Topside Maint',
    Status: 'Completed',
    FuncNo: 'VE331-03201'
  },
  {
    WO_Number: '2017/000027',
    Description: 'ME-00016 - CBM Generic Oil Sampling Routine',
    Equipment: '43PB002B',
    Priority: 1,
    RespDiscipline: 'Topside Maint',
    Status: 'Completed',
    FuncNo: 'VE331-03201'
  },
  {
    WO_Number: '2017/002833',
    Description: 'ME-00016 - CBM Generic Oil Sampling Routine',
    Equipment: '43PB002B',
    Priority: 1,
    RespDiscipline: 'Topside Maint',
    Status: 'Completed',
    FuncNo: 'VE331-03201'
  },
  {
    WO_Number: '2017/005623',
    Description: 'ME-00016 - CBM Generic Oil Sampling Routine',
    Equipment: '43PB002B',
    Priority: 1,
    RespDiscipline: 'Topside Maint',
    Status: 'Completed',
    FuncNo: 'VE331-03201'
  },
  {
    WO_Number: '2017/008533',
    Description: 'PM00072A - CBM Generic Oil Sampling Routine',
    Equipment: '43PB002B',
    Priority: 1,
    RespDiscipline: 'Topside Maint',
    Status: 'Completed',
    FuncNo: 'VE331-03201'
  },
  {
    WO_Number: '2018/000295',
    Description: 'PM00072A - CBM Generic Oil Sampling Routine',
    Equipment: '43PB002B',
    Priority: 1,
    RespDiscipline: 'Topside Maint',
    Status: 'Completed',
    FuncNo: 'VE331-03201'
  },
  {
    WO_Number: '2018/003819',
    Description: 'PM00072A - Visual Inpection & Lube Oil Sampling Rou',
    Equipment: '43PB002B',
    Priority: 1,
    RespDiscipline: 'Topside Maint',
    Status: 'Completed',
    FuncNo: 'VE331-03201'
  },
  {
    WO_Number: '2018/007085',
    Description: 'PM00072A - Visual Inpection & Lube Oil Sampling Rou',
    Equipment: '43PB002B',
    Priority: 2,
    RespDiscipline: 'Topside Maint',
    Status: 'Completed',
    FuncNo: 'VE331-03201'
  },
  {
    WO_Number: '2019/002235',
    Description: 'PM00072A - Visual Inpection & Lube Oil Sampling Rou',
    Equipment: '43PB002B',
    Priority: 2,
    RespDiscipline: 'Topside Maint',
    Status: 'Completed',
    FuncNo: 'VE331-03201'
  },
  {
    WO_Number: '2019/005975',
    Description: 'PM00072A - Visual Inpection & Lube Oil Sampling Rou',
    Equipment: '43PB002B',
    Priority: 2,
    RespDiscipline: 'Topside Maint',
    Status: 'Held For Materials',
    FuncNo: 'VE331-03201'
  }
]
const workOrderDueDetails = [
  {
    WO_Number: '2022/006738',
    Description: 'CA-00375 - Clean/Inspect Pump Suction Strainer',
    Equipment: '43PB002B',
    Priority: 1,
    RespDiscipline: 'Topside Maint',
    Status: 'Todo',
    DueDate: '25-12-2022'
  },
  {
    WO_Number: '2022/006739',
    Description: 'ME-00015 - Re-greasing of Bearings',
    Equipment: '43PB002B',
    Priority: 1,
    RespDiscipline: 'Topside Maint',
    Status: 'Todo',
    DueDate: '25-12-2022'
  },
  {
    WO_Number: '2022/006740',
    Description: 'PR-00130 - 1W Pump Suction Strainer Inspection',
    Equipment: '43PB002B',
    Priority: 1,
    RespDiscipline: 'Topside Maint',
    Status: 'Todo',
    DueDate: '25-12-2022'
  },
  {
    WO_Number: '2019/005975',
    Description: 'PM00072A - Visual Inpection & Lube Oil Sampling Rou',
    Equipment: '43PB002B',
    Priority: 2,
    RespDiscipline: 'Topside Maint',
    Status: 'Held For Materials (1)',
    DueDate: '25-03-2023'
  },
  {
    WO_Number: '2022/006743',
    Description: 'Tubin Leak on the downsteam of PumpPB002B',
    Equipment: '43PB002B',
    Priority: 1,
    RespDiscipline: 'Topside Maint',
    Status: 'Todo',
    DueDate: '25-12-2022'
  }
]

const incidentDetails = [
  {
    'FIRS No': 'FIRS-12-01',
    Description: 'Shutdown due to high vibration which lead to production Loss',
    Equipment: '43PB002B',
    'MOC raised': 'YES',
    'Loss Estimated': '15000 USD',
    'Potentail Loss Matrix': 'Click here to View',
    'Root Cause Identified': 'Yes',
    'Show the Technical Report': 'Click here to Show'
  }
]

const workOrderTodo = [
  {
    id: 1,
    WO_Number: '2022/006738',
    Description: 'CA-00375 - Clean/Inspect Pump Suction Strainer',
    Equipment: '43PB002B',
    Priority: 1,
    RespDiscipline: 'Topside Maint',
    Status: 'Todo',
    DueDate: '21-12-2022',
    checked: false
  },
  {
    id: 2,
    WO_Number: '2022/006739',
    Description: 'ME-00015 - Re-greasing of Bearings',
    Equipment: '43PB002B',
    Priority: 1,
    RespDiscipline: 'Topside Maint',
    Status: 'Todo',
    DueDate: '07-12-2022',
    checked: false
  },
  {
    id: 3,
    WO_Number: '2022/006740',
    Description: 'PR-00130 - 1W Pump Suction Strainer Inspection',
    Equipment: '43PB002B',
    Priority: 1,
    RespDiscipline: 'Topside Maint',
    Status: 'Todo',
    DueDate: '03-12-2022',
    checked: false
  },
  {
    id: 4,
    WO_Number: '2022/006743',
    Description: 'Tubin Leak on the downsteam of PumpPB002B',
    Equipment: '43PB002B',
    Priority: 1,
    RespDiscipline: 'Topside Maint',
    Status: 'Todo',
    DueDate: '25-12-2022',
    checked: false
  }
]

const stockDetails = [
  {
    id: 1,
    StockNumber: 'S000000853',
    Description: 'Peel Off Shim - Discharge pump',
    Equipment: '43PB002B',
    makerCode: 'WARTSILA       ',
    StockLocation: 'Engine Room Store',
    BinLocation: 'S02CEB4 - B4',
    ItemonStock: 2,
    MakerRef: '31511546',
    checked: false
  },
  {
    id: 2,
    StockNumber: 'S000000854',
    Description: 'Impeller Screw - Discharge pump',
    Equipment: '43PB002B',
    makerCode: 'WARTSILA       ',
    StockLocation: 'Engine Room Store',
    BinLocation: 'S02CEB6 - B6',
    ItemonStock: 1,
    MakerRef: "'32521502",
    checked: false
  },
  {
    id: 3,
    StockNumber: 'S000000855',
    Description: 'Casing O-Ring',
    Equipment: '43PB002B',
    makerCode: 'HAMWORTHY      ',
    StockLocation: 'Engine Room Store',
    BinLocation: 'S02C6B5 - B5',
    ItemonStock: 10,
    MakerRef: "'42030445",
    checked: false
  },
  {
    id: 4,
    StockNumber: 'S000000856',
    Description: 'Tab Washer - Discharge pump',
    Equipment: '43PB002B',
    makerCode: 'WARTSILA       ',
    StockLocation: 'Engine Room Store',
    BinLocation: 'S02CEB4 - B4',
    ItemonStock: 2,
    MakerRef: "'31512585",
    checked: false
  },
  {
    id: 5,
    StockNumber: 'S000000858',
    Description: 'Mechanical Seal Kit - Discharge Pump',
    Equipment: '43PB002B',
    makerCode: 'WARTSILA       ',
    StockLocation: 'Engine Room Store',
    BinLocation: 'S02CEB4 - B4',
    ItemonStock: 3,
    MakerRef: "'42619072",
    checked: false
  },
  {
    id: 6,
    StockNumber: 'S000000823',
    Description: 'Ball bearings',
    Equipment: '43PB002B',
    makerCode: 'HAMWORTHY      ',
    StockLocation: 'Engine Room Store',
    BinLocation: 'NBS/RB - S01A',
    ItemonStock: 10,
    MakerRef: '20135-050 - SKF 6318',
    checked: false
  },
  {
    id: 7,
    StockNumber: 'S000000824',
    Description: 'Mechanical Seal, upper',
    Equipment: '43PB002B',
    makerCode: 'HAMWORTHY      ',
    StockLocation: 'Engine Room Store',
    BinLocation: 'S01A6B6 - B6',
    ItemonStock: 20,
    MakerRef: '20078-009',
    checked: false
  },
  {
    id: 8,
    StockNumber: 'S000000825',
    Description: 'Mechanical Seal, lower',
    Equipment: '43PB002B',
    makerCode: 'HAMWORTHY      ',
    StockLocation: 'Engine Room Store',
    BinLocation: 'S01A6B6 - B6',
    ItemonStock: 34,
    MakerRef: '20078-010',
    checked: false
  },
  {
    id: 9,
    StockNumber: 'S000000826',
    Description: 'O-Ring ',
    Equipment: '43PB002B',
    makerCode: 'HAMWORTHY      ',
    StockLocation: 'A/C Room Store-Upper Deck',
    BinLocation: 'S01A6B7 - B5',
    ItemonStock: 12,
    MakerRef: '20070-232',
    checked: false
  },
  {
    id: 10,
    StockNumber: 'S000000827',
    Description: 'Metaflex Gasket',
    Equipment: '43PB002B',
    makerCode: 'HAMWORTHY      ',
    StockLocation: 'Engine Room Store',
    BinLocation: 'S01A6B5 - B5',
    ItemonStock: 34,
    MakerRef: '20061-001',
    checked: false
  },
  {
    id: 11,
    StockNumber: 'S000000828',
    Description: 'Oil Seal 100 x 125 x 16',
    Equipment: '43PB002B',
    makerCode: 'HAMWORTHY      ',
    StockLocation: 'A/C Room Store-Upper Deck',
    BinLocation: 'S01A6B7 - B5',
    ItemonStock: 45,
    MakerRef: '20071-075',
    checked: false
  },
  {
    id: 12,
    StockNumber: 'S000000829',
    Description: 'O-Ring ',
    Equipment: '43PB002B',
    makerCode: 'HAMWORTHY      ',
    StockLocation: 'A/C Room Store-Upper Deck',
    BinLocation: 'S01A6B7 - B5',
    ItemonStock: 39,
    MakerRef: '20070-195',
    checked: false
  },
  {
    id: 13,
    StockNumber: 'S000000830',
    Description: 'O-Ring ',
    Equipment: '43PB002B',
    makerCode: 'HAMWORTHY      ',
    StockLocation: 'A/C Room Store-Upper Deck',
    BinLocation: 'S01A6B7 - B5',
    ItemonStock: 12,
    MakerRef: '20070-249',
    checked: false
  }
]

const Tab1 = () => {
  const [show, setShow] = useState<boolean>(false)
  return (
    <>
      <Table striped>
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            {[
              'WO Number',
              'Description',
              'Equipment',
              'Priority',
              'Resp. Discipline',
              'Status',
              'Func. No.',
              'History'
            ]?.map((header: string) => (
              <th style={{ fontSize: 14 }} key={header}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {workOrderDetails?.map((detail: any) => (
            <tr key={detail?.WO_Number}>
              {[
                detail?.WO_Number,
                detail?.Description,
                detail?.Equipment,
                detail?.Priority,
                detail?.RespDiscipline,
                detail?.Status,
                detail?.FuncNo
              ]?.map((item) => (
                <td style={{ fontSize: 12 }} key={item}>
                  {item}
                </td>
              ))}
              <td
                style={{
                  fontSize: 12,
                  color: 'orange',
                  textDecoration: 'underline',
                  fontWeight: 700
                }}
                onClick={() => setShow(true)}
              >
                Click here to View
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal
        style={{ zIndex: '9999' }}
        isOpen={show}
        toggle={() => setShow(!show)}
      >
        <ModalHeader style={{ backgroundColor: 'orange' }}>
          <p style={{ textAlign: 'center', padding: 0, margin: 0 }}>
            <span style={{ fontSize: '18px' }}>History of - </span>
            <span style={{ fontWeight: '900', fontSize: '18px' }}>
              {' '}
              ME-00016 - ( CBM Generic Oil Sampling Routine )
            </span>
          </p>
        </ModalHeader>
        <ModalBody>
          <div>
            <p style={{ textAlign: 'justify', fontSize: '16px' }}>
              After sample collection, store in a cool and dark place in order
              to reduce evaporative changes or microbial degradation of oil
              samples. Samples taken from the surface can be collected by hand
              or by using a sampling pole. The sampling bottles from the EPA
              Tasmania AST laboratories are analyte-specific but if required,
              additional information or instructions can be written on the
              sample submission form. Additionally, if using sample bottles not
              obtained from AST ensure you note on the sample bottle the type of
              analysis requested.
            </p>
            <p>
              The label should contain: Client e.g. EPA - Incident Response Date
              of sampling Time of sampling Name of sampler Site
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Col style={{ display: 'flex', justifyContent: 'center' }}>
            <Button color="warning" onClick={() => setShow(false)}>
              Close
            </Button>
          </Col>
        </ModalFooter>
      </Modal>
    </>
  )
}

const Tab2 = ({
  stockList,
  setStockList
}: {
  stockList: Array<any>
  setStockList: Function
}) => {
  const addToList = (value: boolean, item: any) => {
    if (value) {
      setStockList([...stockList, item])
    } else {
      setStockList(stockList?.filter((i: any) => i.id !== item.id))
    }
  }

  return (
    <>
      <Table striped>
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            {[
              'Action',
              'Stock Number',
              'Description',
              'Equipment',
              'Maker Code',
              'Stock Location',
              'Bin Location',
              'Items on Stock',
              "Maker's Reference"
            ]?.map((header: string) => (
              <th style={{ fontSize: 14 }} key={header}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {stockDetails?.map((detail: any) => (
            <tr key={detail?.StockNumber}>
              <td>
                <Input
                  type="checkbox"
                  onChange={(e) => addToList(e.target.checked, detail)}
                  checked={stockList?.some(
                    (stock: any) => stock.id === detail.id
                  )}
                />
              </td>
              {[
                detail?.StockNumber,
                detail?.Description,
                detail?.Equipment,
                detail?.makerCode,
                detail?.StockLocation,
                detail?.BinLocation,
                detail?.ItemonStock,
                detail?.MakerRef,
                detail?.checked
              ]?.map((item) => (
                <td style={{ fontSize: 12 }} key={item}>
                  {item}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

const Tab3 = () => {
  return (
    <>
      <Table striped>
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            {[
              'FIRS No',
              'Description',
              'Equipment',
              'MOC raised',
              'Loss Estimated',
              'Potential Loss Matrix',
              'Root Cause Identified',
              'Technical Report'
            ]?.map((header: string) => (
              <th style={{ fontSize: 14 }} key={header}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {incidentDetails?.map((detail: any, index: number) => (
            <tr
              key={`tbaleCell-${detail?.index}-${detail?.Equipment}-${detail['FIRS No']}`}
            >
              {[
                detail['FIRS No'],
                detail?.Description,
                detail?.Equipment,
                detail?.['MOC raised'],
                detail?.['Loss Estimated'],
                detail?.['Potentail Loss Matrix'],
                detail?.['Root Cause Identified'],
                detail?.['Show the Technical Report']
              ]?.map((item) => (
                <td style={{ fontSize: 12 }} key={item}>
                  {item}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

const Tab4 = () => {
  return (
    <>
      <Table striped>
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            {[
              'WO Number',
              'Description',
              'Equipment',
              'Priority',
              'Resp. Discipline',
              'Status',
              'Due Date'
            ]?.map((header: string) => (
              <th style={{ fontSize: 14 }} key={header}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {workOrderDueDetails?.map((detail: any) => (
            <tr key={detail?.WO_Number}>
              {[
                detail['WO_Number'],
                detail?.Description,
                detail?.Equipment,
                detail?.['Priority'],
                detail?.['RespDiscipline'],
                detail?.['Status'],
                detail?.['DueDate']
              ]?.map((item) => (
                <td style={{ fontSize: 12 }} key={item}>
                  {item}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

const Tab5 = ({
  workOrders,
  setWorkOrders
}: {
  workOrders: any
  setWorkOrders: Function
}) => {
  const addToList = (value: boolean, item: any) => {
    if (value) {
      setWorkOrders([...workOrders, item])
    } else {
      setWorkOrders(workOrders?.filter((i: any) => i.id !== item.id))
    }
  }

  return (
    <>
      <Table striped>
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            {[
              'Action',
              'WO Number',
              'Description',
              'Equipment',
              'Priority',
              'Resp. Discipline',
              'Status',
              'Due Date'
            ]?.map((header: string) => (
              <th style={{ fontSize: 14 }} key={header}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {workOrderTodo?.map((detail: any) => (
            <tr key={detail?.WO_Number}>
              <td>
                <Input
                  type="checkbox"
                  onChange={(e) => addToList(e.target.checked, detail)}
                  checked={workOrders?.some(
                    (stock: any) => stock.id === detail.id
                  )}
                />
              </td>
              {[
                detail['WO_Number'],
                detail?.Description,
                detail?.Equipment,
                detail?.['Priority'],
                detail?.['RespDiscipline'],
                detail?.['Status'],
                detail?.['DueDate']
              ]?.map((item) => (
                <td style={{ fontSize: 12 }} key={item}>
                  {item}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

type viewEquipmentHistory = {
  isOpen: boolean
  toggle: VoidFunction
  setStockList: Function
  stockList: Array<any>
  workOrders: Array<any>
  setWorkOrders: Function
}

export default function ViewEquipmwntHistory({
  isOpen,
  toggle,
  stockList,
  setStockList,
  workOrders,
  setWorkOrders
}: viewEquipmentHistory) {
  const [activeTab, setActiveTab] = useState<number>(1)

  return (
    <Modal isOpen={isOpen} toggle={toggle} fullscreen>
      <ModalBody>
        <div>
          <Nav justified pills>
            <NavItem>
              <NavLink
                className={activeTab === 1 ? 'active' : ''}
                onClick={() => setActiveTab(1)}
              >
                <>WO HISTORY</>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 2 ? 'active' : ''}
                onClick={() => setActiveTab(2)}
              >
                <b> STOCK HISTORY</b>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 3 ? 'active' : ''}
                onClick={() => setActiveTab(3)}
              >
                <b>FIRST HISTORY</b>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 4 ? 'active' : ''}
                onClick={() => setActiveTab(4)}
              >
                <b>WO - DUE</b>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 5 ? 'active' : ''}
                onClick={() => setActiveTab(5)}
              >
                <b>WO - TODO</b>
              </NavLink>
            </NavItem>
          </Nav>
          <hr />
          <TabContent activeTab={activeTab} style={{ minHeight: '80vh' }}>
            <TabPane tabId={1}>
              <Row>
                <Col sm="12">
                  <p style={{ textAlign: 'center', fontWeight: 700 }}>
                    HISTORY OF WORK ORDER
                  </p>
                  <Tab1 />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={2}>
              <Row>
                <Col sm="12">
                  <p style={{ textAlign: 'center', fontWeight: 700 }}>
                    STOCK ITEM DETAILS
                  </p>
                  <Tab2 stockList={stockList} setStockList={setStockList} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={3}>
              <Row>
                <Col sm="12">
                  <p style={{ textAlign: 'center', fontWeight: 700 }}>
                    INCIDENT FOUND
                  </p>
                  <Tab3 />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={4}>
              <Row>
                <Col sm="12">
                  <p style={{ textAlign: 'center', fontWeight: 700 }}>
                    WORK ORDER DUE
                  </p>
                  <Tab4 />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={5}>
              <Row>
                <Col sm="12">
                  <p style={{ textAlign: 'center', fontWeight: 700 }}>
                    WORK ORDER TODO
                  </p>
                  <Tab5 workOrders={workOrders} setWorkOrders={setWorkOrders} />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <Row>
            <Button color="warning" onClick={() => toggle()}>
              <b>Close</b>
            </Button>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  )
}
