import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useContext, useState } from 'react'

import IsolationsCheckboxTable from '../../components/Tables/IsolationsCheckBoxTable'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import {
  deIsolationCloseEndPoint,
  newPermitEndpoint
} from '../../components/serverurl'
import { SubHeader } from '../VerifyCM'

import React from 'react'
import { UserContext } from '../../App'
import { capitalizeFirstLetter } from '../../components/lib/capitalizeFirstLetter'
import { useAdminPermissions } from '../eptw/EditRisks'
import { RadioButtonGroup, yesNoOptions } from '../eptw/VerifyPermit'
import ViewPermit from '../eptw/ViewPermit'

export default function FinishDeIsolation() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const { userData } = useContext(UserContext)

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const [submitModal, setSubmitModal]: any = useState(false)

  const [formData, setFormData]: any = useState({
    isolationDone: 'No',
    allIsolated: 'No',
    electricalIsolationsRecorded: 'No'
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const submitValues: any = {
      ...formData,
      department: capitalizeFirstLetter(userData?.department)?.trim(),
      isolationsId: data?.permit?.isolations?._id,
      consentId: data?.permit?.consent?._id
    }
    const response = await makeAuthenticatedRequest(
      `${deIsolationCloseEndPoint}/finishDeIsolation`,
      'PATCH',
      submitValues,
      accounts,
      instance
    )

    return response.status
  }

  const isolations = data?.permit?.isolations?.isolations
  const currentUserDesignation = capitalizeFirstLetter(
    userData?.department
  ).trim()

  const filterIsolation = isolations?.filter(
    (i: any) =>
      i?.isolationType.valueOf() === currentUserDesignation.valueOf() &&
      i?.status === 'startedDeIsolation'
  )

  const hasIsolations = filterIsolation?.length > 0

  const form = (
    <div>
      <RadioButtonGroup
        question="Is the De Isolation done as per the Company isolation philosophy ? "
        name="isolationDone"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Have you De isolated all the Points under your Department as per Isolation certificate ?"
        name="allIsolated"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Is the Electrical isolation have been recorded in the electrical shift logbook"
        name="electricalIsolationsRecorded"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
    </div>
  )

  return (
    <ProtectedLayout onBack="/eptw/deIsolationAndClose/finish">
      <ViewPermit data={data} isLoading={isLoading} />
      {data?.permit?.status === 'deIsolationAndCloseVerified' &&
        hasIsolations && (
          <>
            {hasIsolations && <SubHeader header="Finish De Isolations" />}
            <IsolationsCheckboxTable
              printData={data}
              checkBoxHeader="Finish"
              buttonText="Finish DeIsolation"
              data={filterIsolation}
              onSubmit={(val: any) => {
                setSubmitModal(true)
              }}
            />
            {submitModal && (
              <SubmitModalWithForm
                isOpen={submitModal}
                onClose={(val: any) => {
                  setSubmitModal(false)
                  val && navigate('/eptw/deIsolationAndClose/finish')
                }}
                onSubmit={handleSubmit}
                form={form}
              />
            )}
          </>
        )}
    </ProtectedLayout>
  )
}
