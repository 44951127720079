import { Col, Container, Row, Table } from "reactstrap"

import { CRITICAL_RISK_COLOR } from "./constants/riskMatrixColors"
import { ControlType } from "./InputFields/SelectControlsModal"
import { getRiskColor } from "./lib/getRiskLevel"
import toNormalCase from "./lib/toNormalCase"

export function Text({ children, styles }: any) { return <p style={{ margin: 0, lineHeight: 2, textOverflow: 'clip', ...styles }}>{children}</p> }

export default function RiskAssesmentPreview({ riskAssesmentValues, equipment }: { riskAssesmentValues: any, equipment: any }) {

  const renderControl = (control: ControlType) => (
    <Row style={{ borderBottom: '1px solid #000' }}>
      <Col xs="8" style={{ borderRight: '1px solid #000' }}>
        <Text>{control?.controlName}</Text>
      </Col>
      <Col xs="2" style={{ borderRight: '1px solid #000', textAlign: 'center' }}>
        <Text>{control?.preRequisite ? 'Yes' : 'No'}</Text>
      </Col>
      <Col xs="2" style={{ borderRight: '1px solid #000', textAlign: 'center' }}>
        <Text>{control?.action === 'select' ? 'NA' : toNormalCase(control?.action)}</Text>
      </Col>
    </Row>
  )

  return (
    <>
      <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
      </Row>
      <Container fluid style={{ border: '1px solid #000', paddingLeft: 10, paddingRight: 10 }}>
        <Row>
          <Col xs="2" style={{ borderBottom: '1px solid #000', textAlign: 'center' }}>
            <Text><b>Task Name</b></Text>
          </Col>
          <Col style={{ borderLeft: '1px solid #000', textAlign: 'center' }}>
            <Row>
              <Col xs="2">
                <Text> <b> Hazard Title</b>
                </Text>
              </Col>
              <Col xs="1" style={{ borderLeft: '1px solid #000' }}>
                <Text><b>Inital Risk</b>
                </Text>
              </Col>
              <Col style={{ borderLeft: '1px solid #000' }}>
                <Row style={{ padding: 0, textAlign: 'center' }}>
                  <Text><b>Control Measures</b></Text>
                  <Row style={{ padding: 0, margin: 0, borderTop: '1px solid #000' }}>
                    <Col xs="8" style={{ borderRight: '1px solid #000' }}>
                      <Text><b>Work task Control Measures</b></Text>
                    </Col>
                    <Col xs="2" style={{ borderRight: '1px solid #000' }}>
                      <Text><b>Pre Requisite</b></Text>
                    </Col>
                    <Col xs="2">
                      <Text><b>Action by</b></Text>
                    </Col>
                  </Row>
                </Row>
              </Col>
              <Col xs="1" style={{ borderLeft: '1px solid #000', textAlign: 'center' }}>
                <Text><b>Residual Risk</b></Text>
              </Col>
            </Row>
          </Col>
        </Row>
        {(riskAssesmentValues)?.hazards?.map((h: any) => (
          <Row>
            <Col xs="2" style={{ borderBottom: '1px solid #000', display: 'flex', alignItems: 'center' }}>
              <Text>{h?.hazard?.label}</Text>
            </Col>
            <Col style={{ borderLeft: '1px solid #000' }}>
              {
                h?.hazardDetails?.map((hd: any) => <Row>
                  <Col xs="2" style={{ borderTop: '1px solid #000', display: 'flex', alignItems: 'center' }}>
                    <Text>
                      {hd?.title?.label}
                    </Text>
                  </Col>
                  <Col xs="1" style={{
                    borderTop: '1px solid #000',
                    borderLeft: '1px solid #000',
                    backgroundColor: getRiskColor(equipment, hd?.initalRisk),
                    color: getRiskColor(equipment, hd?.initalRisk) === CRITICAL_RISK_COLOR ? '#FFF' : '#000',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    WebkitPrintColorAdjust: 'exact'
                  }}>
                    <Text>
                      <b>{hd.initalRisk}</b>
                    </Text>
                  </Col>
                  <Col style={{ borderLeft: '1px solid #000' }}>
                    <Row style={{ borderTop: '1px solid #000', padding: 0 }}>
                      <Text>{hd?.controls[0]?.map((c: any, index: any) => renderControl(c))}
                      </Text>
                    </Row>
                  </Col>
                  <Col xs="1" style={{
                    borderTop: '1px solid #000',
                    borderLeft: '1px solid #000',
                    backgroundColor: getRiskColor(equipment, hd?.residualRisk),
                    color: getRiskColor(equipment, hd?.initalRisk) === CRITICAL_RISK_COLOR ? '#FFF' : '#000',
                    WebkitPrintColorAdjust: 'exact',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center' }}>
                    <Text>
                      <b>{hd.residualRisk}</b>
                    </Text>
                  </Col>
                </Row>)
              }
            </Col>
          </Row>
        ))}
      </Container>
      <Table bordered style={{ borderColor: '#000', marginTop: 10 }}>
        <thead>
          <tr>
            <th>
              Team Members
            </th>
            <th>
              {riskAssesmentValues?.teamMembers?.map((member: any) => member.label).join(', ')}
            </th>
          </tr>
        </thead>
      </Table>
    </>
  )
}