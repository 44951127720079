import { useMsal } from '@azure/msal-react'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import ReactPaginate from 'react-paginate'
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { makeAuthenticatedRequest } from '../apiRequests'
import { UserContext } from '../App'
import { incrementby1 } from '../components/Forms/JSAForm'
import CustomLabel from '../components/InputFields/CustomLabel'
import LabeledDateTimeInput from '../components/InputFields/DateTime'
import DropDownFetch from '../components/InputFields/DropDownFetch'
import LabeledRadioButtons from '../components/InputFields/LabeledRadioButton'
import LabeledTextInput from '../components/InputFields/LabeledTextInput'
import LabledParagraphInput from '../components/InputFields/LabledParagraph'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import SectionHeader from '../components/Layout/SectionHeader'
import Loader from '../components/Loader'
import ModalTitle from '../components/ModalTitle'
import { stock, stockFilters } from '../components/serverurl'
import Spacer from '../components/Spacer'
import { EmptyContent } from './CreateNewPermit'

const UpdateStockModal = ({
  stockDetails,
  isOpen,
  toggle,
  refetch,
  setStock,
  submitting,
  setSubmitting,
  accounts,
  instance
}: any) => {
  const onSubmit = async (values: any) => {
    setSubmitting(true)
    await makeAuthenticatedRequest(
      `${stock}/${stockDetails?._id}`,
      'PATCH',
      values,
      accounts,
      instance
    ).then(async (response) => {
      await refetch().then(() => {
        toggle()
      })
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      size="xs"
      toggle={toggle}
      onClosed={() => {
        setStock(false)
      }}
    >
      <ModalBody>
        <ModalTitle title={stockDetails?.itemCode} />
        <Form
          onSubmit={(values) =>
            onSubmit({
              unitPrice: values.unitPrice,
              issueQuantity: values?.quantity,
              stockDescription: values?.description,
              location: values?.location
            })
          }
          initialValues={{
            quantity: stockDetails?.issueQuantity,
            unitPrice: stockDetails?.unitPrice,
            description: stockDetails?.stockDescription,
            location: stockDetails?.location
          }}
          render={({ form }) => (
            <form>
              <Row>
                <LabledParagraphInput
                  name="description"
                  label="Description"
                  occupy={12}
                />
                <LabeledTextInput
                  name="quantity"
                  label="Quantity"
                  occupy={12}
                />
                <LabeledTextInput
                  name="unitPrice"
                  label="Unit Price"
                  occupy={12}
                />
                <LabeledTextInput
                  name="location"
                  label="Location"
                  occupy={12}
                />
              </Row>
              <ModalFooter>
                <Button color="warning" onClick={toggle}>
                  Close
                </Button>
                <Button
                  color="success"
                  onClick={form.submit}
                  disabled={submitting}
                >
                  Update
                </Button>
              </ModalFooter>
            </form>
          )}
        />
      </ModalBody>
    </Modal>
  )
}

function NewStockModal({
  isOpen,
  toggle,
  submitting,
  refetch,
  setSubmitting,
  accounts,
  instance
}: any) {
  const onSubmit = async (values: any) => {
    setSubmitting(true)
    await makeAuthenticatedRequest(
      stock,
      'POST',
      values,
      accounts,
      instance
    ).then(async (response) => {
      await refetch().then(() => {
        toggle()
        setSubmitting(false)
      })
    })
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} fullscreen>
        <ModalBody>
          <ModalTitle title="Create New Stock" />
          <Form
            onSubmit={(values) => onSubmit(values)}
            render={({ form }) => (
              <form>
                <Row>
                  <LabledParagraphInput
                    name="stockDescription"
                    label="Description"
                  />
                  <LabeledTextInput name="itemCode" label="Item Code" />
                  <LabeledTextInput name="location" label="Location" />
                  <LabeledTextInput name="uom" label="UOM" />
                  <LabeledDateTimeInput
                    name="receiptDate"
                    label="Receipt Date"
                  />
                  <LabeledTextInput
                    name="receiptQuantity"
                    label="Receipt Quantity"
                  />
                  <LabeledDateTimeInput name="issueDate" label="Issue Date" />
                  <LabeledTextInput name="issueNumber" label="Issue Number" />
                  <LabeledTextInput
                    name="issueQuantity"
                    label="Issue Quantity"
                  />
                  <LabeledTextInput name="closingStock" label="Closing Stock" />
                  <LabeledTextInput name="unitPrice" label="Unit Price" />
                  <LabeledRadioButtons
                    name="currency"
                    label="Currency"
                    list={[
                      { label: 'INR', value: 'INR' },
                      { label: 'USD', value: 'USD' },
                      { label: 'GBT', value: 'GBT' },
                      { label: 'EURO', value: 'EURO' }
                    ]}
                  />
                  <LabeledTextInput name="closingValue" label="Closing Value" />
                  <LabeledTextInput name="stockType" label="Stock Type" />
                  <LabeledRadioButtons
                    name="department"
                    label="Department"
                    list={[
                      'Mechanical',
                      'Electrical',
                      'Instrument',
                      'Production',
                      'HSE',
                      'Admin Ware House',
                      'IT'
                    ]?.map((i) => ({ label: i, value: i }))}
                  />
                  <LabeledTextInput name="productCode" label="Product Code" />
                </Row>
                <ModalFooter>
                  <Button color="warning" onClick={toggle}>
                    Close
                  </Button>
                  <Button
                    color="success"
                    onClick={form.submit}
                    disabled={submitting}
                  >
                    Add
                  </Button>
                </ModalFooter>
              </form>
            )}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default function StockList() {
  const [stockData, setStockData]: any = useState([])
  const [loading, setLoading]: any = useState(true)
  const [pageNumber, setPageNumber]: any = useState(1)
  const [update, setUpdate]: any = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [newStock, setNewStock] = useState(false)
  const [department, setDepartment]: any = useState('')
  const [location, setLocation]: any = useState('')
  const [stockType, setStockType]: any = useState('')
  const [itemCode, setItemCode]: any = useState('')
  const [openUpdate, setOpenUpdate]: any = useState(false)

  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)

  const isWareHouseAdmin = userData?.jobTitle === 'Warehouse Admin'

  const fetchStock = async () =>
    await makeAuthenticatedRequest(
      `${stock}?page=${pageNumber}&department=${department}&stockType=${stockType}&location=${location}&itemCode=${itemCode}`,
      'GET',
      null,
      accounts,
      instance
    ).then((response) => {
      setStockData(response?.data)
      setLoading(false)
      // setSubmitting(false)
    })

  useEffect(() => {
    setLoading(true)
    fetchStock()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, department, stock, stockType, location, itemCode])

  return (
    <ProtectedLayout onBack="/cmms/services">
      <SectionHeader title="Stock List" />
      {isWareHouseAdmin && (
        <>
          <Button color="success" onClick={() => setNewStock(true)}>
            Add New Stock
          </Button>
          {update && (
            <Button
              color="success"
              style={{ marginLeft: 10 }}
              onClick={() => setOpenUpdate(true)}
            >
              Edit Stock
            </Button>
          )}
        </>
      )}
      <Spacer height={20} />
      <Row style={{ display: 'flex', alignItems: 'end' }}>
        <Col>
          <CustomLabel label="Department" />
          <DropDownFetch
            value={department}
            setValue={setDepartment}
            url={`${stockFilters}/departments`}
          />
        </Col>
        <Col>
          <CustomLabel label="Location" />
          <DropDownFetch
            value={location}
            setValue={setLocation}
            url={`${stockFilters}/locations`}
          />
        </Col>
        <Col>
          <CustomLabel label="ItemCode" />
          <DropDownFetch
            value={itemCode}
            setValue={setItemCode}
            url={`${stockFilters}/itemCodes`}
          />
        </Col>
        <Col>
          <CustomLabel label="Stock Type" />
          <DropDownFetch
            value={stockType}
            setValue={setStockType}
            url={`${stockFilters}/stockTypes`}
          />
        </Col>
        <Col style={{ justifyContent: 'right', display: 'flex' }}>
          <Button
            color="link"
            onClick={() => {
              setDepartment('')
              setStockType('')
              setLocation('')
              setItemCode('')
            }}
          >
            Clear Filter
          </Button>
        </Col>
      </Row>
      <Spacer height={10} />
      <Table bordered striped style={{ borderColor: '#000' }}>
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            {isWareHouseAdmin && <th />}
            <th>S.No</th>
            <th>Description</th>
            <th>Item Code</th>
            <th>Location</th>
            <th>Bin</th>
            <th>Issue Quantity</th>
            <th>Unit Price</th>
            <th>Stock Type</th>
            <th>Department</th>
            <th>Expiry Date</th>
          </tr>
        </thead>
        <tbody>
          {stockData?.paginatedStock?.length === 0 && (
            <tr>
              <td colSpan={9}>
                <EmptyContent label="No Stock Details Available" />
              </td>
            </tr>
          )}
          {loading && (
            <tr>
              <td colSpan={9}>
                <Loader />
              </td>
            </tr>
          )}
          {!loading &&
            stockData?.paginatedStock?.map((stock: any, index: any) => (
              <React.Fragment key={`${stock.itemCode}-${index}`}>
                <tr>
                  {isWareHouseAdmin && (
                    <td>
                      <input
                        onChange={(e) => {
                          setSubmitting(false)
                          setUpdate(e.target.checked ? stock : null)
                        }}
                        type="checkbox"
                        checked={stock?._id === update?._id}
                      />
                    </td>
                  )}
                  <td>{incrementby1(index)}</td>
                  <td style={{ width: '45%' }}>{stock.stockDescription}</td>
                  <td>{stock.itemCode}</td>
                  <td>{stock.location}</td>
                  <td />
                  <td>{stock.issueQuantity}</td>
                  <td>{stock.unitPrice}</td>
                  <td>{stock.stockType}</td>
                  <td>{stock.department}</td>
                  <td />
                </tr>
              </React.Fragment>
            ))}
        </tbody>
      </Table>
      <Spacer height={20} />
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={stockData?.totalStock / 20}
        marginPagesDisplayed={10}
        pageRangeDisplayed={3}
        onPageChange={(number) => {
          setLoading(true)
          setPageNumber(number.selected)
        }}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
      <UpdateStockModal
        stockDetails={update}
        isOpen={openUpdate}
        toggle={() => setOpenUpdate(!openUpdate)}
        refetch={fetchStock}
        setStock={setUpdate}
        submitting={submitting}
        setSubmitting={setSubmitting}
        accounts={accounts}
        instance={instance}
      />
      <NewStockModal
        isOpen={newStock}
        toggle={() => setNewStock(!newStock)}
        refetch={fetchStock}
        submitting={submitting}
        setSubmitting={setSubmitting}
        accounts={accounts}
        instance={instance}
      />
    </ProtectedLayout>
  )
}
