import { Button, Input, ModalFooter, Row } from 'reactstrap'
import { useContext, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import CustomLabel from '../../components/InputFields/CustomLabel'
import PreviewAudit from '../../components/previews/PreviewAudit'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { auditEndpoint } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { RadioButtonGroup, yesNoOptions } from '../eptw/VerifyPermit'
import { UserContext } from '../../App'

export default function ViewAudit() {
  const { id } = useParams()
  const { userData } = useContext(UserContext)
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const [rejecting, setRejecting]: any = useState(false)
  const [approving, setApproving]: any = useState(false)
  const [status, setStatus]: any = useState()
  const [submitModal, setSubmitModal]: any = useState(false)
  const [formData, setFormData]: any = useState({
    needCM: 'No',
    comments: 'No'
  })

  const fetchAudits = async () => {
    const response = await makeAuthenticatedRequest(
      `${auditEndpoint}/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response.data
  }

  const { data }: any = useQuery('fetchAudits', fetchAudits)

  const updateStatus = async () => {
    try {
      const res = await makeAuthenticatedRequest(
        `${auditEndpoint}/${id}?status=${status}&comments=${formData?.comments}&needCM=${formData?.needCM}`,
        'PATCH',
        null,
        accounts,
        instance
      )

      if (res?.status === 'success') {
        navigate('/eptw/audits')
      }
    } catch (error) {
    } finally {
      setApproving(false)
      setRejecting(false)
    }
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const form = (
    <div>
      {
        <RadioButtonGroup
          question="Need to create a Corrective Maintenance"
          name="needCM"
          options={yesNoOptions}
          formData={formData}
          onChange={handleInputChange}
          defaultValue="No"
        />
      }
      {status === 'rejected' && (
        <div className="form-group">
          <CustomLabel label="Reject Reason" />
          <Input type="text" name="comments" onChange={handleInputChange} />
        </div>
      )}
    </div>
  )

  return (
    <ProtectedLayout onBack="/eptw/audits">
      <Row style={{ margin: 10 }}>
        <PreviewAudit values={data} />
        {userData?.department === 'HSE' && data?.status === 'created' && (
          <>
            <ModalFooter style={{ marginTop: 10 }}>
              <Button
                color={rejecting ? 'secondary' : 'danger'}
                disabled={rejecting}
                onClick={() => {
                  setRejecting(true)
                  setStatus('rejected')
                  setSubmitModal(true)
                }}
              >
                {rejecting ? 'Rejecting' : 'Reject'}
              </Button>
              <Button
                color={approving ? 'secondary' : 'success'}
                disabled={approving}
                onClick={() => {
                  setApproving(true)
                  setStatus('approved')
                  setSubmitModal(true)
                }}
              >
                {approving ? 'Approving' : 'Approve'}
              </Button>
            </ModalFooter>
            {submitModal && (
              <SubmitModalWithForm
                isOpen={submitModal}
                onClose={(val: any) => {
                  setSubmitModal(false)
                  setRejecting(false)
                  setApproving(false)
                  setStatus()
                }}
                onSubmit={updateStatus}
                form={form}
              />
            )}
          </>
        )}
      </Row>
    </ProtectedLayout>
  )
}
