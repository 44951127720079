import arrayMutators from 'final-form-arrays'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { Button, Col, Input, ModalFooter, Row, Table } from 'reactstrap'
import { Field, Form, useForm } from 'react-final-form'
import { useMsal } from '@azure/msal-react'

import AshokLeylandLayout from './AshokLeylandLayout'
import CustomLabel from '../components/InputFields/CustomLabel'
import LabeledTextInput from '../components/InputFields/LabeledTextInput'
import LabledParagraphInput from '../components/InputFields/LabledParagraph'
import SubmitModalWithForm from '../components/Modals/SubmitModalWithForm'
import toNormalCase from '../components/lib/toNormalCase'
import { Box, renderData } from '../pages/workManagement/PmTree'
import { capitalizeFirstLetter } from '../components/lib/capitalizeFirstLetter'
import { FormSection } from '../pages/workManagement/WorkRequestForm'
import { makeAuthenticatedRequest } from '../apiRequests'
import { UserContext } from '../App'
import { alEquipmentApi, workOrderAPI } from '../components/serverurl'

export const alEquipment = [
  {
    _id: '66acb79e21792d91313d115a',
    department: 'Mechanical',
    serviceProv: 'SS Eng',
    location: 'R&D Center Chennai',
    tagNumber: '73-MD-002',
    description: 'Store Fork Lift',
    system: 73,
    pmJobNo: 1004,
    rev: 0,
    frequency: '3 Months',
    pmStartingMonth: 'June',
    jun: 'ok'
  },
  {
    _id: '66acb79e21792d91313d1155',
    department: 'Mechanical',
    serviceProv: 'M/s Prodectvity',
    location: 'R&D Center Chennai',
    tagNumber: '63-KB-502',
    description: 'Proto Atlos copco COMPRESSOR',
    system: 63,
    pmJobNo: 1001,
    rev: 0,
    frequency: '3 months',
    pmStartingMonth: 'May',
    may: 'ok'
  },
  {
    _id: {
      $oid: '66acb79e21792d91313d1179'
    },
    department: 'Mechanical',
    serviceProv: 'CARRIER',
    location: 'R&D Center Chennai',
    tagNumber: '97-GB-028',
    description: 'CTL-OLD FF CARRIER Air Conditioner',
    system: 97,
    manufacturer: 'TOSHIBA-IN',
    type: 'SPLIT',
    capacity: 1.8,
    pmJobNo: 1007,
    rev: 0,
    frequency: '3 Months',
    pmStartingMonth: 'April',
    apr: 'Done'
  },
  {
    _id: {
      $oid: '66acb79e21792d91313d1226'
    },
    department: 'Electrical',
    serviceProv: 'Hari Prasanna',
    group: 'EPABX',
    location: 'R&D Center Chennai',
    tagNumber: '86-RP-002',
    description: 'EPABX-2 (SV-8300)& EDC',
    system: 86,
    pmJobNo: 2010,
    rev: 0,
    frequency: '3 Months',
    pmStartingMonth: 'May',
    jun: 29
  },
  {
    _id: '66acb79e21792d91313d124a',
    department: 'Electrical',
    serviceProv: 'Zenith',
    group: 'ACCESS CONTROL',
    location: 'R&D Center Chennai',
    tagNumber: '76-RV-036',
    description: 'NDO 2 BR 31 ACCESS CONTROL',
    system: 76,
    pmJobNo: 2011,
    rev: 0,
    frequency: 'Monthly',
    pmStartingMonth: 'April',
    apr: '24/04/24',
    may: 24,
    jun: 22
  },
  {
    _id: '66acb79e21792d91313d128d',
    department: 'Electrical',
    serviceProv: 'Siemens',
    group: 'LT ACB',
    location: 'R&D Center Chennai',
    tagNumber: '82-EC-033',
    description: 'APFC Panel 3 LT Breaker',
    system: 82,
    pmJobNo: 2018,
    rev: 0,
    frequency: 'Yearly',
    pmStartingMonth: 'September'
  },
  {
    _id: '66acb79e21792d91313d12c8',
    department: 'Electrical',
    serviceProv: 'Emmar Elect',
    group: 'HT VCB',
    location: 'R&D Center Chennai',
    tagNumber: '81-EC-009',
    description: 'MPSB Panel - Electrical Panels',
    system: 81,
    pmJobNo: 2021,
    rev: 0,
    frequency: 'Yearly',
    pmStartingMonth: 'December'
  },
  {
    _id: '66ae047b21792d91313d1374',
    department: 'In house',
    serviceProv: 'Electrical',
    group: 'Electrical Panels',
    location: 'R&D Center Chennai',
    tagNumber: '81-EC-001',
    description: 'MV Panel Substation 3',
    system: 81,
    pmJobNo: 3010,
    rev: 0,
    frequency: 'Yearly',
    pmTriggerDate: '01/06/24',
    pmStartingMonth: '01/07/24'
  }
]

export const WorkRequestPreview = ({
  wr,
  fullScreen
}: {
  wr: any
  fullScreen: boolean
}) => {
  const generalDetails: any = {
    Location: toNormalCase(wr?.location),
    'WO description': wr?.description,
    'Created Date': moment(wr?.createdOn).format('DD-MM-YYYY'),
    'Reported By': wr?.reportedBy,
    'Tag ID': wr?.tagId,
    'Tag Description': wr?.tagDescription
  }

  const planningInformation: any = {
    Department: capitalizeFirstLetter(wr?.department),
    'Work Type': toNormalCase(wr?.workType),
    Priority: wr?.priority,
    'Estimated Hours': wr?.estimatedHours
  }

  const prepare: any = {
    'Work Description': wr?.problemNoticed,
    'Job to be Done': wr?.jobPending
  }
  return (
    <Row>
      <Col md={fullScreen ? 12 : 6}>
        <Box heading="General Details">
          {Object.keys(generalDetails)?.map((key: any) =>
            renderData(key, generalDetails[key])
          )}
        </Box>
      </Col>
      <Col md={fullScreen ? 12 : 6}>
        <Box heading="Planning Information">
          {Object.keys(planningInformation)?.map((key: any) =>
            renderData(key, planningInformation[key])
          )}
        </Box>
        <Box heading="Prepare" height={'5vh'}>
          {Object.keys(prepare)?.map((key: any) =>
            renderData(key, prepare[key])
          )}
        </Box>
      </Col>
    </Row>
  )
}

const WOPreview = ({
  formValues,
  toggle,
  isOpen
}: {
  formValues: any
  toggle: any
  isOpen: any
}) => {
  const { accounts, instance } = useMsal()
  const [result, setResult]: any = useState()

  const { userData } = useContext(UserContext)

  const userDepartment = userData?.department?.toLowerCase()

  const onSubmit = async () => {
    try {
      const res: any = await makeAuthenticatedRequest(
        `${alEquipmentApi}/cm`,
        'POST',
        {
          ...formValues,
          createdAt: moment(),
          createdDepartment: userDepartment?.trim(),
          lastestUpdatedBy: userData?.id
        },
        accounts,
        instance
      )

      setResult(res)
      return res?.status
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <SubmitModalWithForm
        isOpen={isOpen}
        onClose={(val: any) => {
          toggle()
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()
          const res = await onSubmit()
          return res
        }}
        size="lg"
        header="Create Work Request"
        form={<WorkRequestPreview wr={formValues} fullScreen />}
        sucessView={
          <div>
            <Table bordered style={{ borderColor: '#000' }}>
              <tbody>
                <tr>
                  <td>WO Number</td>
                  <td>{result?.permit?.number || 'Number'}</td>
                </tr>
                <tr>
                  <td>WO Description</td>
                  <td>{result?.permit?.cm?.description || 'Title'}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        }
      />
    </>
  )
}

export default function AlWorkRequestForm({ onBack }: { onBack: string }) {
  const { userData } = useContext(UserContext)
  const [preview, setPreview] = useState(false)
  const TagDropDown = () => {
    const { change } = useForm()
    return (
      <Input
        type="select"
        onChange={(e: any) => {
          console.log(e?.target?.value)
          const eq: any = alEquipment?.filter(
            (t: any) => t?.tagNumber === e?.target?.value
          )?.[0]
          change('tagId', eq?.tagNumber)
          change('tagDescription', eq?.description)
          change('location', eq?.location)
        }}
      >
        {alEquipment?.map((e: any) => (
          <option key={e?._id}>{e?.tagNumber}</option>
        ))}
      </Input>
    )
  }

  return (
    <AshokLeylandLayout onBack={onBack} title={'CREATE WORK REQUEST'}>
      <Row style={{ margin: 10 }}>
        <Form
          onSubmit={(values: any) => {}}
          initialValues={{
            reportedBy: userData?.givenName,
            location: 'R&D Center Chennai',
            workType: 'Breakdown Maintenance'
          }}
          mutators={{
            ...arrayMutators
          }}
          render={({ values, form, hasValidationErrors }) => (
            <form>
              <FormSection title="General Details">
                <LabeledTextInput
                  name="location"
                  label="Location *"
                  isDisabled
                  occupy={3}
                />
                <LabledParagraphInput
                  name="description"
                  label="WO Description  *"
                  occupy={6}
                />
                <LabeledTextInput
                  name="reportedBy"
                  label="Reported By"
                  isDisabled
                  occupy={3}
                  value={userData?.givenName}
                />
                <Col xs="3">
                  <CustomLabel label="Tag" />
                  <Field name="tag" style={{ background: '#DCDCDC' }}>
                    {({ input, ...rest }) => <TagDropDown />}
                  </Field>
                </Col>

                <LabeledTextInput
                  name="tagDescription"
                  label="Tag Description *"
                  isDisabled
                  occupy={6}
                />
                {/* <LabeledTextInput
                  name="tagType"
                  label="Tag Type *"
                  isDisabled
                  occupy={3}
                />
                <LabeledTextInput
                  name="function"
                  label="Function Number *"
                  isDisabled
                  occupy={3}
                />
                <LabeledTextInput
                  name="functionDescription"
                  label="Function Description *"
                  isDisabled
                  occupy={6}
                />
                <LabeledTextInput
                  name="criticality"
                  label="Equipment Criticality *"
                  isDisabled
                  occupy={3}
                /> */}
                <LabeledTextInput
                  name="createdOn"
                  label="Created Date"
                  value={moment().format('DD-MM-YYYY hh:mm:ss')}
                  occupy={3}
                />
                <LabeledTextInput
                  label="Incident Report Number"
                  name="incidentReportNumber"
                  occupy={3}
                />
              </FormSection>
              <FormSection title="Planning Information">
                <Col xs="3">
                  <CustomLabel label="Department *" />
                  <Field
                    name={'department'}
                    component="select"
                    defaultValue=""
                    style={{
                      width: '-webkit-fill-available',
                      height: 38,
                      borderRadius: 5,
                      backgroundColor: '#DCDCDC'
                    }}
                  >
                    <option value="" disabled>
                      Select Department
                    </option>
                    <option>Maintenance</option>
                    <option>Canteen & Security</option>
                    <option>Civil</option>
                    <option>Proto Shop</option>
                    <option>EDC</option>
                    <option>CTL</option>
                    <option>VET</option>
                    <option>VD/NVH</option>
                    <option>IVT</option>
                    <option>PPKM</option>
                  </Field>
                </Col>
                <Col xs="3">
                  <CustomLabel label="Work Type *" />
                  <Field
                    name={'workType'}
                    component="select"
                    defaultValue="Breakdown Maintenance"
                    style={{
                      width: '-webkit-fill-available',
                      height: 38,
                      borderRadius: 5,
                      backgroundColor: '#DCDCDC'
                    }}
                  >
                    <option value="" disabled>
                      Select Work Type
                    </option>
                    <option value="Corrective Maintenance">
                      Corrective Maintenance
                    </option>
                    <option value="Breakdown Maintenance">
                      Breakdown Maintenance
                    </option>
                    <option value="Modification">Modification</option>
                  </Field>
                </Col>
                <Col xs="3">
                  <CustomLabel label="Priority *" />
                  <Field
                    name={'priority'}
                    component="select"
                    placeholder="s"
                    style={{
                      width: '-webkit-fill-available',
                      height: 38,
                      borderRadius: 5,
                      backgroundColor: '#DCDCDC'
                    }}
                  >
                    <option value="" disabled>
                      Select Priority
                    </option>
                    <option value="1 (1-14 Days)">1 (1-14 Days)</option>
                    <option value="2 (15-60 Days)">2 (15-60 Days)</option>
                    <option value="3 (90 Days)">3 (90 Days)</option>
                  </Field>
                </Col>
                <LabeledTextInput
                  name="estimatedHours"
                  label="Estimated Hours *"
                  occupy={3}
                />
              </FormSection>
              <FormSection title="Prepare">
                <LabledParagraphInput
                  name="problemNoticed"
                  label="Work Description *"
                  occupy={12}
                />
                <LabledParagraphInput
                  name="jobPending"
                  label="Job to be Done *"
                  occupy={12}
                />
              </FormSection>
              <ModalFooter>
                <Button
                  color={hasValidationErrors ? 'secondary' : 'warning'}
                  onClick={() => setPreview(true)}
                  disabled={hasValidationErrors}
                >
                  Preview
                </Button>
              </ModalFooter>
              <WOPreview
                isOpen={preview}
                toggle={() => setPreview(!preview)}
                formValues={values}
              />
            </form>
          )}
        />
      </Row>
    </AshokLeylandLayout>
  )
}
