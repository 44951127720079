import { Route, Routes } from 'react-router-dom'
import { Row } from 'reactstrap'
import { useState } from 'react'

import CreateRiskMatrix from '../pages/rcm/RiskMatrix'
import DynamicKeyDrawings from '../pages/rcm/RcmDynamicDrawings'
import Floc from '../pages/rcm/Floc'
import FlocTag from '../pages/rcm/FLocTag'
import PMTree from '../pages/workManagement/PmTree'
import RcmAdmin from '../pages/rcm/RcmAdmin'
import RcmAnalysis from '../pages/rcm/RcmAnalysis'
import RcmAnalysisManual from './RcmAnalysisManual'
import RcmConditionHistory from '../pages/rcm/RcmConditionHistory'
import RcmCustomReports from '../pages/rcm/RcmCustomReports'
import RcmDashboard from '../pages/rcm/RcmDashboard'
import RcmDecissionTree from '../pages/rcm/RcmDescissionTree'
import RcmEquipment from '../pages/rcm/RcmEquipment'
import RcmFailureModes from '../pages/rcm/RcmFailureModes'
import RcmFMEA from '../pages/rcm/rcmFMEA'
import RcmFunctionFailure from '../pages/rcm/RcmFunctionFailure'
import RcmKpi from '../pages/rcm/RcmKpi'
import RcmLayout from '../components/rcm/RcmLayout'
import RcmMaintenance from '../pages/rcm/RcmMaintenance'
import RcmPidEquipmentAnalysis from '../pages/rcm/RcmPidEquipmentAnalysis'
import RcmSerivces from '../pages/RcmServices'
import RcmSystem43 from '../pages/rcm/RcmSystem43'
import RcmSystemBoundary from '../pages/rcm/RCMSystemBoundary'
import RcmSystemDetail from '../pages/rcm/RcmSystemDetail'
import RcmSystems from '../pages/rcm/Systems'
import { SubHeader } from '../pages/VerifyCM'
import RcmCreateAnalysis from '../pages/rcm/RcmCreateAnalysis'
import RcmViewStage1 from '../pages/rcm/RcmViewStage1'
import RcmAnalysisList from '../pages/rcm/RcmStage1'
import RcmViewStage3 from '../pages/rcm/RcmViewStage3'
import RcmViewStage4 from '../pages/rcm/RcmViewStage4'
import RcmViewStage5 from '../pages/rcm/RcmViewStage5'
import RcmViewStage6 from '../pages/rcm/RcmViewStage6'
import RcmViewStage7 from '../pages/rcm/RcmViewStage7'
import RcmAnalysisPrint from '../pages/rcm/RcmAnalysisPrint'
import RcmViewStage8 from '../pages/rcm/RcmViewStage8'
import RcmViewStage9 from '../pages/rcm/RcmViewStage9'
import FPSO from '../SVGs/Fpso'
import FpsoBoundary from '../pages/rcm/FpsoBoundary'
import FpsoBoundary2 from '../pages/rcm/FpsoBoundary2'
import FpsoBoundary3 from '../pages/rcm/FpsoBoundary3'
import FpsoSystemBoundary from '../pages/rcm/FpsoSystemBoundary'
import FpsoBoundaryMaintainable from '../pages/rcm/FpsoBoundaryMaintainable'
import AssetCriticality from '../pages/rcm/AssetCriticality'
import RCM from '../pages/workManagement/RCM'

const rcmData = [
  {
    'S:No': '1',
    Number: 'RCM-SYS12-002',
    Description: 'Review Criticality and MEI Value',
    Status: 'Approved',
    System: '12',
    Criticality: 'SECE'
  },
  {
    'S:No': '2',
    Number: 'RCM-SYS43-003',
    Description: 'Review failure modes and Criticality',
    Status: 'Approved',
    System: '43',
    Criticality: 'HIGH'
  },
  {
    'S:No': '3',
    Number: 'RCM-SYS13-004',
    Description: 'Review Criticality and MEI Value',
    Status: 'Approved',
    System: '13',
    Criticality: 'HIGH'
  },
  {
    'S:No': '4',
    Number: 'RCM-SYS44-007',
    Description: 'Task review and update risk assesment',
    Status: 'Approved',
    System: '44',
    Criticality: 'HIGH'
  },
  {
    'S:No': '5',
    Number: 'RCM-SYS45-009',
    Description: 'Review failure modes and Criticality',
    Status: 'Approved',
    System: '47',
    Criticality: 'HIGH'
  }
]

function RcmAdminRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RcmAdmin />} />
      <Route path="/riskmatrix" element={<CreateRiskMatrix />} />
      <Route
        path="/hierarchy"
        element={<PMTree onBack={'/cmms/rcm/admin'} />}
      />
    </Routes>
  )
}

function SampleComp({ title }: { title: string }) {
  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15 }}>
        <SubHeader header={title} permitColor="gold" />
      </Row>
    </RcmLayout>
  )
}

export default function RcmRoutes() {
  const [pin, setPin]: any = useState()
  return (
    <Routes>
      <Route path="/" element={<RcmSerivces />} />
      <Route path="/admin/*" element={<RcmAdminRoutes />} />
      <Route path="/dashboard" element={<RcmDashboard />} />
      <Route
        path="/sytems"
        element={<RcmSystems pin={pin} setPin={setPin} />}
      />
      <Route path="/sytems/:id" element={<RcmSystemDetail />} />
      <Route path="/floc" element={<Floc pin={pin} setPin={setPin} />} />
      <Route
        path="/functionFailure"
        element={<RcmFunctionFailure pin={pin} setPin={setPin} />}
      />
      <Route path="/equipment" element={<RcmEquipment />} />
      <Route
        path="/analysis"
        element={<RcmAnalysis pin={pin} setPin={setPin} />}
      />
      <Route path="/maintenanceLibrary" element={<RcmMaintenance />} />
      <Route path="/conditionHistory" element={<RcmConditionHistory />} />
      <Route path="/customReports" element={<RcmCustomReports />} />
      <Route
        path="/failureModes"
        element={<RcmFailureModes pin={pin} setPin={setPin} />}
      />
      <Route path="/fmea" element={<RcmFMEA pin={pin} setPin={setPin} />} />
      <Route
        path="/decissionChart"
        element={<RcmDecissionTree pin={pin} setPin={setPin} />}
      />
      <Route path="/kpi" element={<RcmKpi />} />
      <Route
        path="/dynamicDrawings"
        element={<DynamicKeyDrawings onBack={'/cmms/rcm'} rcmData={rcmData} />}
      />
      <Route path="/floc/:id" element={<FlocTag />} />
      <Route path="/createAnalysis" element={<RcmCreateAnalysis />} />
      <Route
        path="/stage2"
        element={
          <RcmAnalysisList
            stage={'Stage2'}
            title={'Stage 2 - Boundary Selection'}
          />
        }
      />
      <Route path="/stage2/:id" element={<RcmViewStage1 />} />
      <Route
        path="/stage3"
        element={<RcmAnalysisList stage={'Stage3'} title={'Stage 3'} />}
      />
      <Route path="/stage3/:id" element={<RcmViewStage3 />} />
      <Route
        path="/stage4"
        element={<RcmAnalysisList stage={'Stage4'} title={'Stage 4'} />}
      />
      <Route path="/stage4/:id" element={<RcmViewStage4 />} />
      <Route
        path="/stage5"
        element={<RcmAnalysisList stage={'Stage5'} title={'Stage 5'} />}
      />
      <Route path="/stage5/:id" element={<RcmViewStage5 />} />
      <Route
        path="/stage6"
        element={<RcmAnalysisList stage={'Stage6'} title={'Stage 6'} />}
      />
      <Route path="/stage6/:id" element={<RcmViewStage6 />} />
      <Route
        path="/stage7"
        element={<RcmAnalysisList stage={'Stage7'} title={'Stage 7'} />}
      />
      <Route path="/stage7/:id" element={<RcmViewStage7 />} />
      <Route
        path="/stage8"
        element={<RcmAnalysisList stage={'Stage8'} title={'Stage 8'} />}
      />
      <Route path="/stage8/:id" element={<RcmViewStage8 />} />
      <Route
        path="/stage9"
        element={<RcmAnalysisList stage={'Stage9'} title={'Stage 9'} />}
      />
      <Route path="/stage9/:id" element={<RcmViewStage9 />} />
      <Route
        path="/print"
        element={<RcmAnalysisList stage={'Stage7'} title={'Approved'} />}
      />
      <Route path="/print/:id" element={<RcmAnalysisPrint />} />
      <Route path="/systemBoundary" element={<RcmSystemBoundary />} />
      <Route
        path="/defineFunction"
        element={<SampleComp title="Define Function" />}
      />
      <Route
        path="/identifyFunctionalFailures"
        element={<SampleComp title="Identify Functional Failures" />}
      />
      <Route
        path="/mapFailureModes"
        element={<SampleComp title="Map Failure Modes" />}
      />
      <Route
        path="/mapFailureEffect"
        element={<SampleComp title="Map Failure Effect" />}
      />
      <Route
        path="/calculateFailureConsequence"
        element={<SampleComp title="Calculate Failure Consequence" />}
      />
      <Route
        path="/maintenanceTaskAnalysis"
        element={<SampleComp title="Maintenance Task Analysis" />}
      />
      <Route
        path="/generateMaintenancePlan"
        element={<SampleComp title="Generate Maintenance Plan" />}
      />
      <Route path="/systemBoundary/System43" element={<RcmSystem43 />} />
      <Route path="/systemBoundary/manual" element={<RcmAnalysisManual />} />
      <Route
        path="/systemBoundary/pidAnalysis"
        element={<RcmPidEquipmentAnalysis />}
      />
      <Route path="/riskmatrix" element={<CreateRiskMatrix />} />
      <Route path="/hierarchy" element={<RCM />} />
      <Route path="/stage2/boundary1" element={<FpsoBoundary />} />
      <Route path="/stage2/boundary1/boundary2" element={<FpsoBoundary2 />} />
      <Route path="/stage2/boundary1/boundary2/boundary3" element={<FpsoBoundary3 />} />
      <Route path="/stage2/boundarySystem" element={<FpsoSystemBoundary />} />
      <Route path="/boundaryMaintainable" element={<FpsoBoundaryMaintainable />} />
      <Route path="/assetcriticality" element={<AssetCriticality />} />
    </Routes>
  )
}
