import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import React, { useState } from 'react'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import ViewPermits from '../../components/Tables/ViewPermits'
import { epermit } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { SidePane } from '../CMDashboard'

export default function Issue() {
  const { accounts, instance } = useMsal()
  const [pageNumber, setPageNumber] = useState(0)
  const [active, setActive]: any = useState()

  const fetchAdminPermissions = async () => {
    const response = await makeAuthenticatedRequest(
      `${epermit}?page=${pageNumber}&status=isolated`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, refetch }: any = useQuery(
    'eptwIssue',
    fetchAdminPermissions
  )

  const flows = [
    { name: 'New Permit', count: data?.length },
    { name: 'Revalidation', count: null },
    { name: 'Deisolation & Close', count: null },
    { name: 'Deisolation for Test', count: undefined },
    { name: 'Reisolation Permit', count: null },
    { name: 'Long term Isolation', count: null },
    { name: 'Close Permit', count: '' },
    { name: 'Change In Ownership', count: null },
    { name: 'Permit Without Isolation', count: null }
  ]

  return (
    <ProtectedLayout
      onBack="/eptw/dashboard"
      // hasSideList
      sidePanel={
        <SidePane
          list={flows}
          activeItem={active}
          setActiveItem={setActive}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      }
    >
      <ViewPermits
        data={data}
        isLoading={isLoading}
        refetch={refetch}
        setPageNumber={setPageNumber}
        navigateTo="/eptw/newPermit/issue"
      />
    </ProtectedLayout>
  )
}
