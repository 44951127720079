import React, { useRef } from 'react'
import { PieChart } from 'react-minimal-pie-chart'
import { Button, Col, Input, ModalFooter, Row, Spinner } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'

import Loader from '../../components/Loader'
import CustomLabel from '../../components/InputFields/CustomLabel'
import { analyticsEndpoint } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { EmptyContent } from '../CreateNewPermit'
import ReactToPrint from 'react-to-print'

export default function KPIStatistics() {
  const { accounts, instance } = useMsal()
  const date = new Date()
  const componentRef: any = useRef()

  const [startDate, setStartDate]: any = useState('2023-11-01')
  const [endDate, setEndDate]: any = useState(
    `${date.toISOString().split('T')[0]}`
  )

  const duration: any = {
    startDate: startDate,
    endDate: endDate
  }

  const fetchAnalytis = async () => {
    const response = await makeAuthenticatedRequest(
      `${analyticsEndpoint}/UtilizationHours`,
      'POST',
      duration,
      accounts,
      instance
    )

    return response
  }

  const {
    data: analyticsData,
    isLoading,
    isRefetching,
    refetch
  }: any = useQuery('analyticsData', fetchAnalytis)

  useEffect(() => {
    refetch()
  }, [startDate, endDate])

  const permitsPieData = [
    {
      title: 'On Time',
      value: analyticsData?.kpi?.cold,
      color: '#fefaa2'
    },
    {
      title: 'Not on Time',
      value:
        Number(analyticsData?.kpi?.totalCold) -
        Number(analyticsData?.kpi?.cold),
      color: '#d3d3d3'
    }
  ]

  const kpiHotPieData = [
    {
      title: 'On Time',
      value: analyticsData?.kpi?.hot,
      color: '#f5cee2'
    },
    {
      title: 'Not on Time',
      value:
        Number(analyticsData?.kpi?.totalHot) - Number(analyticsData?.kpi?.hot),
      color: '#d3d3d3'
    }
  ]

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div ref={componentRef}>
          <Row style={{ margin: 0 }}>
            <Row
              style={{
                margin: 0,
                marginBottom: 10,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Col xs="3">
                <CustomLabel label="Start Date" />
                <Input
                  type="date"
                  defaultValue={startDate}
                  onClick={(e: any) => setStartDate(e.target.value)}
                />
              </Col>
              <Col xs="3">
                <CustomLabel label="End Date" />
                <Input
                  type="date"
                  defaultValue={endDate}
                  onClick={(e: any) => setEndDate(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div
                  style={{
                    height: 460,
                    padding: 10,
                    border: '1px solid orange',
                    marginLeft: 20,
                    marginBottom: 20,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <div style={{}}>
                    <p
                      style={{
                        fontSize: 26,
                        textAlign: 'center',
                        fontWeight: '900'
                      }}
                    >
                      KPI Cold Work
                    </p>
                    {isLoading || isRefetching ? (
                      <EmptyContent>
                        <Spinner />
                      </EmptyContent>
                    ) : (
                      permitsPieData && (
                        <div style={{ display: 'flex' }}>
                          <div style={{ height: 370 }}>
                            <PieChart
                              label={({ dataEntry }: any) =>
                                `${dataEntry.title}: ${dataEntry.value}`
                              }
                              labelStyle={{
                                fontSize: '4px'
                              }}
                              data={permitsPieData}
                            />
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              marginLeft: 30
                            }}
                          >
                            {permitsPieData?.length > 0 &&
                              permitsPieData?.map((p: any) => (
                                <div style={{ display: 'flex' }}>
                                  <div
                                    style={{
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: p?.color
                                    }}
                                  />
                                  <p
                                    style={{
                                      fontSize: '18px',
                                      fontWeight: '600',
                                      margin: 0,
                                      marginLeft: 10,
                                      whiteSpace: 'nowrap'
                                    }}
                                  >
                                    {p?.title} - {p?.value}
                                  </p>
                                </div>
                              ))}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                      marginBottom: 10,
                      marginRight: 10
                    }}
                  >
                    <img
                      src={require('./../../images/cieptw.jpeg')}
                      alt="HOEC Logo"
                      height={50}
                    />
                  </div>
                </div>
              </Col>
              <Col xs="12" md="6">
                <div
                  style={{
                    height: 460,
                    padding: 10,
                    border: '1px solid orange',
                    marginLeft: 20,
                    marginBottom: 20,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <div style={{}}>
                    <p
                      style={{
                        fontSize: 26,
                        textAlign: 'center',
                        fontWeight: '900'
                      }}
                    >
                      KPI Hot Work
                    </p>
                    {isLoading || isRefetching ? (
                      <EmptyContent>
                        <Spinner />
                      </EmptyContent>
                    ) : (
                      kpiHotPieData && (
                        <div style={{ display: 'flex' }}>
                          <div style={{ height: 370 }}>
                            <PieChart
                              label={({ dataEntry }: any) =>
                                `${dataEntry.title}: ${dataEntry.value}`
                              }
                              labelStyle={{
                                fontSize: '4px'
                              }}
                              data={kpiHotPieData}
                            />
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              marginLeft: 30
                            }}
                          >
                            {kpiHotPieData?.length > 0 &&
                              kpiHotPieData?.map((p: any) => (
                                <div style={{ display: 'flex' }}>
                                  <div
                                    style={{
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: p?.color
                                    }}
                                  />
                                  <p
                                    style={{
                                      fontSize: '18px',
                                      fontWeight: '600',
                                      margin: 0,
                                      marginLeft: 10,
                                      whiteSpace: 'nowrap'
                                    }}
                                  >
                                    {p?.title} - {p?.value}
                                  </p>
                                </div>
                              ))}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                      marginBottom: 10,
                      marginRight: 10
                    }}
                  >
                    <img
                      src={require('./../../images/cieptw.jpeg')}
                      alt="HOEC Logo"
                      height={50}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Row>
          <ReactToPrint
            trigger={() => (
              <ModalFooter style={{ marginTop: 10 }}>
                <Button color="warning" children="Print" />
              </ModalFooter>
            )}
            content={() => componentRef.current}
          />
        </div>
      )}
    </>
  )
}
