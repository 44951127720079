import { Button, ModalFooter, Row, Spinner, Table } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { Box, renderData } from '../workManagement/PmTree'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { stock } from '../../components/serverurl'
import ReactToPrint from 'react-to-print'
import React, { useContext, useEffect, useRef, useState } from 'react'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { UserContext } from '../../App'
import { incrementby1 } from '../../components/Forms/JSAForm'

const EnterVessel = ({
  data,
  isLoading,
  isRefetching,
  spares,
  setSpares
}: {
  data: any
  isLoading?: boolean
  isRefetching?: boolean
  spares: any
  setSpares: any
}) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Line.No</th>
            <th>Spare Part Number</th>
            <th>Spare Part Description</th>
            <th>Quantity Requested</th>
            <th>Quantity Received</th>
          </tr>
        </thead>
        <tbody>
          <>
            {isLoading || isRefetching ? (
              <tr>
                <td colSpan={12}>
                  <Row
                    style={{
                      height: 500,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Spinner />
                  </Row>
                </td>
              </tr>
            ) : (
              data?.length > 0 &&
              data?.map((w: any, index: number) => (
                <React.Fragment>
                  <tr key={w?._id}>
                    <td>{incrementby1(index)}</td>
                    <td>{w?.lineCode}</td>
                    <td>{w?.spare?.['Spare Part Number']}</td>
                    <td>{w?.spare?.['Spare Part Description']}</td>
                    <td>{w?.['quantityRequested']}</td>
                    <td>{w?.['quantityRequested']}</td>
                  </tr>
                </React.Fragment>
              ))
            )}
          </>
        </tbody>
      </Table>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Old Quotations Found
          </p>
        </Row>
      )}
    </>
  )
}

export default function VerifyStock() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const [createManifest, setCreateManifest]: any = useState(false)
  const [spares, setSpares] = useState([])
  const componentRef: any = useRef()

  const fetchto = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/inventory/cm/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('toDetails', fetchto)

  const toData = data?.toDetails

  useEffect(() => {
    setSpares(toData?.spares)
  }, [data])

  const trasnportOrderDetails: any = {
    'TO Number': toData?.number?.replace('TO', 'CM'),
    'Store Keeper Name': toData?.storeKeeper,
    'Store Keeper Id': toData?.storeKeeper,
    'Warehouse Id': toData?.wareHouseId,
    'Warehouse Location': toData?.wareHouseLocation,
    'Created On': getReadableTime(toData?.manifestCreatedOn),
    'Unit /Vessel': toData?.unit
  }

  return (
    <ProtectedLayout
      onBack="/purchase/verifyStock"
      title={'Consignment Manifest'}
    >
      <div ref={componentRef} style={{ margin: 15 }}>
        <Row style={{ margin: 0 }}>
          <Box heading="Consignment Manifest Details">
            {Object.keys(trasnportOrderDetails)?.map((key: any) =>
              renderData(key, trasnportOrderDetails[key])
            )}
          </Box>
          <Box heading="Purchase Orders">
            <EnterVessel data={spares} spares={spares} setSpares={setSpares} />
          </Box>
        </Row>
      </div>
      <ReactToPrint
        documentTitle={`Transport Number - ${toData?.number}`}
        pageStyle={'padding: 20'}
        trigger={() => (
          <ModalFooter>
            <Button color="warning">Print</Button>
          </ModalFooter>
        )}
        content={() => componentRef.current}
      />
      {toData?.status === 'consignmentManifestClosed' && (
        <ModalFooter>
          <Button onClick={() => setCreateManifest(true)} color="warning">
            Complete PO
          </Button>
        </ModalFooter>
      )}
      <SubmitModalWithForm
        isOpen={createManifest}
        onClose={(val: any) => {
          setCreateManifest(false)
          // val && navigate('/purchase/dashboard')
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()
          const body: any = {
            latestUpdatedby: userData?.id,
            id: toData?._id
          }

          try {
            const res = await makeAuthenticatedRequest(
              `${stock}/inventory/closePO`,
              'POST',
              body,
              accounts,
              instance
            )
            if (res.status === 'success') {
              await refetch()
            }
            return res.status
          } catch (error) {}
        }}
        size="xs"
        header="Complete PO"
      />
    </ProtectedLayout>
  )
}
