import React, { useState, useRef } from 'react'
import ReactPaginate from 'react-paginate'
import ReactToPrint from 'react-to-print'
import moment from 'moment'
import { Col, Row, Spinner, Table, Button } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import { EmptyContent } from '../CreateNewPermit'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { liveIsolationsLTI } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { renderTableData } from '../../components/ToolBoxTalkMeeting'
import { capitalizeFirstLetter } from '../../components/lib/capitalizeFirstLetter'

const getTimeAndDateIsolation = (data) => {
  return (
    <>
      <td style={{ whiteSpace: 'nowrap', textAlign: 'center', verticalAlign: 'middle' }}>
        {moment(data?.updatedAt).format('DD-MM-YYYY')}
      </td>
      <td style={{ whiteSpace: 'nowrap', textAlign: 'center', verticalAlign: 'middle' }}>
        {moment(data?.updatedAt).format('hh:mm A')}
      </td>
    </>
  )
}

const getTimeAndDateDeIsolation = (data) => {
  return (
    <>
      <td style={{ whiteSpace: 'nowrap', textAlign: 'center', verticalAlign: 'middle' }}>
        {data && moment(data?.updatedAt).format('DD-MM-YYYY')}
      </td>
      <td style={{ whiteSpace: 'nowrap', textAlign: 'center', verticalAlign: 'middle' }}>
        {data && moment(data?.updatedAt).format('hh:mm A')}
      </td>
    </>
  )
}

export default function OverrideRegister() {
  const { accounts, instance } = useMsal()
  const [pageNumber, setPageNumber] = useState(0)
  const componentRef = useRef()

  const fetchLiveIsolations = async () => {
    const response = await makeAuthenticatedRequest(
      `${liveIsolationsLTI}/override?page=${pageNumber}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, refetch } = useQuery('lti', fetchLiveIsolations)

  const renderArrayAsList = (values) =>
    values?.map((v, index) => <><p key={`${v}-${index}`} style={{ padding: 0, margin: 0 }}>{v}</p></>)

  const styles = `@page { size: landscape; }`

  const isolations = data?.isolations

  return (
    <ProtectedLayout onBack="/eptw/dashboard">
      <div
        style={{
          fontSize: 14,
          fontFamily: 'Nunito, sans-serif',
          backgroundColor: '#FFF',
          padding: 2
        }}
        ref={componentRef}
        className="print-page-break"
      >
        <style type="text/css" media="print">
          {styles}
        </style>
        <Row style={{ margin: 10 }}>
          <SectionHeader title="Override Register" />
          <>
            <Table
              striped
              bordered
              style={{ borderColor: '#000', fontSize: 12, marginTop: 10, padding: 0 }}
            >
              <thead>
                <tr style={{ backgroundColor: 'orange' }}>
                  <th rowSpan={2}>S.No</th>
                  <th style={{ whiteSpace: 'nowrap' }} rowSpan={2}>Isolation Tag No.</th>
                  <th style={{ whiteSpace: 'nowrap' }} rowSpan={2}>Equipment Tag No.</th>
                  <th style={{ whiteSpace: 'nowrap' }} rowSpan={2}>Equipment Description</th>
                  <th style={{ whiteSpace: 'nowrap' }} rowSpan={2}>Override</th>
                  <th rowSpan={2}>Permit No.</th>
                  <th style={{ whiteSpace: 'nowrap', width: '13%' }} rowSpan={2}>Isolation Certificate No.</th>
                  <th style={{ whiteSpace: 'nowrap' }} rowSpan={2}>Lock Key No.</th>
                  <th rowSpan={2}>Status</th>
                  <th colSpan={3} style={{ textAlign: 'center' }}>Isolation Details</th>
                  <th colSpan={3} style={{ textAlign: 'center' }}>De Isolation Details</th>
                  <th rowSpan={2}>Remarks</th>
                </tr>
                <tr style={{ background: 'orange' }}>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && !data && <EmptyContent label={<Spinner />} />}
                {isolations?.map((p, index) => (
                  <React.Fragment key={`${p?._id}-${index}`}>
                    <tr>
                      {renderTableData(incrementby1(index))}
                      {renderTableData(
                        renderArrayAsList(
                          p?.isolationDetails?.isolations?.map((i) => i?.override === 'Yes' && (i?.isolationTag || 'NA'))
                        ))}
                      {renderTableData(renderArrayAsList(
                        p?.isolationDetails?.isolations?.map((i) => i?.override === 'Yes' && i?.isolationPoint)
                      ))}
                      {renderTableData(renderArrayAsList(
                        p?.isolationDetails?.isolations?.map((i) => i?.override === 'Yes' && i?.isolationDescription)
                      ))}
                      {renderTableData(renderArrayAsList(
                        p?.isolationDetails?.isolations?.map((i) => i?.override === 'Yes' && i?.override)
                      ))}
                      {renderTableData(p?.permitDetails)}
                      {renderTableData(renderArrayAsList(
                        p?.isolationDetails?.isolations?.map((i) => i?.override === 'Yes' && i?.number)
                      ))}
                      {renderTableData(p?.isolationDetails?.lockBoxNumber || '-')}
                      {renderTableData(renderArrayAsList(
                        p?.isolationDetails?.isolations?.map((i) => i?.override === 'Yes' && capitalizeFirstLetter(i?.status))
                      ))}
                      {getTimeAndDateIsolation(p?.historyData?.filter((h) => h?.updatedTo === 'Live Isolation')[0])}
                      {renderTableData(renderArrayAsList(
                        p?.isolationDetails?.isolations?.map((i) => i?.override === 'Yes' && i?.isolatedBy)
                      ))}
                      {getTimeAndDateDeIsolation(p?.historyData?.filter((h) => h?.updatedTo === 'Closed De Isolation')[0])}
                      {renderTableData(renderArrayAsList(
                        p?.isolationDetails?.isolations?.map((i) => i?.override === 'Yes' && i?.deIsolatedBy)
                      ))}
                      {renderTableData(renderArrayAsList(
                        p?.isolationDetails?.isolations?.map((i) => i?.override === 'Yes' && i?.isolationComment)
                      ))}
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={Math.ceil(data?.totalDocuments / 20)}
              marginPagesDisplayed={10}
              pageRangeDisplayed={2}
              onPageChange={async (values) => {
                await setPageNumber(values.selected + 1)
                refetch()
              }}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
            <ReactToPrint
              documentTitle={'LOTO'}
              pageStyle={'padding: 20'}
              trigger={() => <Button color="warning" children="Print" />}
              content={() => componentRef.current}
            />
            <Row>
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 10
                }}
              >
                {/* <CSVLink data={csvData} filename={'LOTO DATA'}>
                  Download CSV
                </CSVLink> */}
              </Col>
            </Row>
          </>
        </Row>
      </div>
    </ProtectedLayout>
  )
}
