import React, { useState } from 'react'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import { AiFillCloseSquare } from 'react-icons/ai'
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import ModalTitle from '../ModalTitle'
import Spacer from '../Spacer'
import CustomLabel from '../InputFields/CustomLabel'
import { FieldArray } from 'react-final-form-arrays'
import { renderResources, renderRisks, renderVendor } from './CloseCMPermit'
import LabeledTextInput from '../InputFields/LabeledTextInput'
import LabeledRadioButtons from '../InputFields/LabeledRadioButton'
import {
  doneRequiredOptions,
  yesNoOptions
} from '../../pages/eptw/VerifyPermit'
import LabledParagraphInput from '../InputFields/LabledParagraph'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { epermitCloseEndPoint } from '../serverurl'
import { useMsal } from '@azure/msal-react'
import { renderRow } from '../../pages/VerifyCM'

export const validation = (values: any) => {
  const errors: any = {}

  if (!values.lessonLearnt) {
    errors.lessonLearnt = 'Required'
  }

  return errors
}

function SubmitPermitReport({
  isOpen,
  onClose,
  isDisabled,
  size = null,
  permit,
  refetch
}: any) {
  const [showSuccess, setShowSuccess] = useState(false)
  const [showFailure, setShowFailure] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { accounts, instance } = useMsal()

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true)

    try {
      const response: any = await makeAuthenticatedRequest(
        epermitCloseEndPoint,
        'PATCH',
        values,
        accounts,
        instance
      )

      const isSuccess = response?.status === 'success'
      if (isSuccess) {
        if (refetch) {
          await refetch()
        }
        setShowSuccess(true)
      } else {
        setShowFailure(true)
      }
    } catch (error) {
      setShowFailure(true)
    } finally {
      setIsSubmitting(false)
    }
  }

  // useEffect(() => {
  //   setShowSuccess(false)
  //   setShowFailure(false)
  //   setIsSubmitting(false)
  // }, [form])

  return (
    <Modal isOpen={isOpen} size={size}>
      <ModalBody>
        <Row style={{ paddingRight: 10 }}>
          <Col xs="11">
            <ModalTitle title={`Submit Close Out Report`} height={40} />
          </Col>
          <Col xs="1">
            <AiFillCloseSquare
              onClick={() => onClose(showSuccess)}
              size={40}
              color="red"
            />
          </Col>
        </Row>
        {showSuccess ? (
          <div className="success-message">
            <p>Form submitted successfully!</p>
            <ModalFooter>
              <Button
                type="button"
                color="warning"
                onClick={async () => {
                  onClose(showSuccess)
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        ) : showFailure ? (
          <div className="error-message">
            Form submission failed. Please try again.
          </div>
        ) : (
          <div>
            <Form
              onSubmit={(values: any) =>
                handleSubmit({ ...values, permit: permit?._id })
              }
              validate={validation}
              mutators={{
                ...arrayMutators
              }}
              render={({ handleSubmit, form, hasValidationErrors, values }) => (
                <form id="my-form" onSubmit={handleSubmit}>
                  {renderRow('Permit Number', permit?.woNumber, 'orange')}
                  {renderRow('Permit Title', permit?.permitTitle, 'orange')}
                  {renderRow('Work Location', permit?.workLocation, 'orange')}
                  {renderRow('Created By', permit?.applicantName, 'orange')}
                  <Spacer height={10} />
                  <LabeledRadioButtons
                    defaultValue="No"
                    name="jobComplete"
                    label="Job Complete"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    defaultValue="Not Required"
                    label="If Incomplete do u need to create a new CM Work Order"
                    name="needCMWorkOrder"
                    list={doneRequiredOptions}
                  />
                  <LabeledRadioButtons
                    defaultValue="No"
                    label="Unit shutdown occurred while performing this Task"
                    name="unitShutdown"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    defaultValue="No"
                    label="Plant shutdown occurred while performing this Task"
                    name="plantShutdown"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    defaultValue="No"
                    label="Any Production loss occurred while performing this Task"
                    name="productionLoss"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    defaultValue="No"
                    label="Any LTI incident while performing this Task"
                    name="ltiIncident"
                    list={yesNoOptions}
                  />
                  {/* <Row>
                    <Col xs="7">
                      <CustomLabel label="Stock Item Code" />
                    </Col>
                    <Col xs="2">
                      <CustomLabel label="Price" />
                    </Col>
                    <Col xs="2">
                      <CustomLabel label="Currency" />
                    </Col>
                    <Col xs="1" />
                  </Row>
                  <FieldArray name="stockUsed" component={renderRisks} /> */}
                  <Table
                    bordered
                    style={{ borderColor: '#000', marginTop: 10 }}
                  >
                    <thead>
                      <tr style={{ backgroundColor: 'orange' }}>
                        <th>Stock Item Code</th>
                        <th>Stock Name</th>
                        <th>Quantity</th>
                        <th>Units</th>
                        {/* <th>Currency</th> */}
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray name="stockUsed" component={renderRisks} />
                    </tbody>
                  </Table>
                  {/* <Row>
                    <Col xs="9">
                      <CustomLabel label="Resources Used" />
                    </Col>
                    <Col xs="2">
                      <CustomLabel label="Hours" />
                    </Col>
                    <Col xs="1" />
                  </Row> */}
                  <Table bordered style={{ borderColor: '#000' }}>
                    <thead>
                      <tr style={{ backgroundColor: 'orange' }}>
                        <th>Resources Used</th>
                        <th>Hours</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray
                        name="resourceUsed"
                        component={renderResources}
                      />
                    </tbody>
                  </Table>
                  <Table bordered style={{ borderColor: '#000' }}>
                    <thead>
                      <tr style={{ backgroundColor: 'orange' }}>
                        <th>Vendors Used</th>
                        <th>Hours</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray name="vendorUsed" component={renderVendor} />
                    </tbody>
                  </Table>
                  {/* <Row>
                    <Col xs="9">
                      <CustomLabel label="Vendors Used" />
                    </Col>
                    <Col xs="2">
                      <CustomLabel label="Hours" />
                    </Col>
                    <Col xs="1" />
                  </Row> */}
                  <Spacer height={10} />
                  <LabledParagraphInput
                    label="Lesson Learnt"
                    name="lessonLearnt"
                    occupy={12}
                  />
                  <LabledParagraphInput
                    label="Comments"
                    name="comments"
                    occupy={12}
                  />
                  <Spacer height={10} />
                  <ModalFooter>
                    <Button
                      type="button"
                      color="warning"
                      onClick={async () => {
                        await form.submit()
                        onClose(showSuccess)
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      disabled={
                        isSubmitting || isDisabled || hasValidationErrors
                      }
                      color={isDisabled ? 'secondary' : 'warning'}
                    >
                      {isSubmitting ? 'Submitting...' : 'Submit'}
                    </Button>
                  </ModalFooter>
                </form>
              )}
            />
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

export default SubmitPermitReport
