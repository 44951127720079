import moment from 'moment'
import { Table } from 'reactstrap'

import PermitHeaderDetails from '../PermitHeaderDetails'
import ProtectedLayout from '../Layout/ProtectedLayout'
import SectionHeader from '../Layout/SectionHeader'
import Spacer from '../Spacer'
import { incrementby1 } from '../Forms/JSAForm'
import { renderRow, SubHeader } from '../../pages/VerifyCM'
import { useNavigate } from 'react-router-dom'

export default function ViewClosedReport({ data, userData }: any) {
  const navigate = useNavigate()

  const fields = {
    'Work Order Number': data?.permit?.woNumber,
    'Permit Title': data?.permit?.permitTitle
  }

  const formData: any = {
    'Lesson Learnt': data?.lessonLearnt,
    'Job Completed': data?.jobComplete,
    'Need CM Workorder': (
      <div style={{ display: 'flex' }}>
        <p style={{ margin: 0 }}>{data?.needCMWorkOrder} </p>
        <p
          style={{
            margin: 0,
            paddingLeft: 10,
            color: 'blue',
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
          onClick={() => {
            localStorage.setItem('createCM', JSON.stringify(data))
            navigate('/cmms/dashboard')
          }}
        >
          Create CM
        </p>
      </div>
    ),
    'Date Completed': moment(data?.dateCompleted).format('DD/MM/YYYY'),
    'Plant ShutDown': data?.plantShutdown,
    'Unit ShutDown': data?.unitShutdown,
    'LTI Incident': data?.ltiIncident
  }

  const userDetails: any = {
    'Job Performer': userData?.givenName,
    Department: userData?.department,
    'Job Title': userData?.jobTitle,
    'Employee Id': userData?.employeeId,
    Location: data?.permit?.workLocation
  }

  return (
    <>
      <SectionHeader title="EPERmit close out report" />
      <hr style={{ margin: 0 }} />
      <Spacer height={10} />
      <SubHeader header="Permit details" />
      <PermitHeaderDetails permit={fields} />
      <SubHeader header="Closed By" />
      <PermitHeaderDetails permit={userDetails} />
      <SubHeader header="Close Out details" />
      {Object.keys(formData).map((key: any) => renderRow(key, formData[key]))}
      <Spacer height={10} />
      <SubHeader header="Stock Used" />
      <Spacer height={10} />
      <Table bordered striped style={{ border: '1px solid #000' }}>
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            <td>S.No</td>
            <td>Stock Name</td>
            <td>Currency</td>
          </tr>
        </thead>
        <tbody>
          {data?.stockUsed?.map((stock: any, index: number) => (
            <tr key={index}>
              <td style={{ width: '5%' }}>{incrementby1(index)}</td>
              <td>{stock?.name}</td>
              <td>{stock?.currency}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Spacer height={10} />
      <SubHeader header="Vendor Used" />
      <Spacer height={10} />
      <Table bordered striped style={{ border: '1px solid #000' }}>
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            <td>S.No</td>
            <td>Vendor Name</td>
            <td>Hours</td>
          </tr>
        </thead>
        <tbody>
          {data?.vendorUsed?.map((stock: any, index: number) => (
            <tr key={index}>
              <td style={{ width: '5%' }}>{incrementby1(index)}</td>
              <td>{stock?.name}</td>
              <td>{stock?.hours}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Spacer height={10} />
      <SubHeader header="Resource Used" />
      <Spacer height={10} />
      <Table bordered striped style={{ border: '1px solid #000' }}>
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            <td>S.No</td>
            <td>Resource Name</td>
            <td>Hours</td>
          </tr>
        </thead>
        <tbody>
          {data?.resourceUsed?.map((resource: any, index: number) => (
            <tr key={index}>
              <td style={{ width: '5%' }}>{incrementby1(index)}</td>
              <td>{resource?.name}</td>
              <td>{resource?.hours}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}
