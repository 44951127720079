import { useState } from 'react'
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap'
import DashboardNavigation from '../../components/Dashboard/DashboardNavigation'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import ViewIcons from '../../components/ViewIcons'
import KPIStatistics from './KPIStatictics'
import PermitsByStatusTiles from './PermitsByStatusTiles'
import PermitStatistics from './PermitStatistics'
import ResourceStatistics from './ResourceStatistics'

export default function AnalyticsActions() {
  const [activeTab, setActiveTab] = useState<number>(1)

  const icons = [
    {
      name: 'PERMITS BY STATUS',
      // src: require('./../../images/Icons/Permits with isolations_Verify.png'),
      nav: '/eptw/analytics/permitsByStatus'
    },
    {
      name: 'RESOURCE UTILIZATION',
      // src: require('./../../images/Icons/Permits with isolations_Issue.png'),
      nav: '/eptw/analytics/resource'
    },
    {
      name: 'PERMIT STATISTICS',
      // src: require('./../../images/Icons/Permits with isolations_Approve.png'),
      nav: '/eptw/analytics/permit'
    },
    {
      name: 'KPI STATISTICS',
      // src: require('./../../images/Icons/Permits with isolations_Approve.png'),
      nav: '/eptw/analytics/kpi'
    }
  ]

  return (
    <ProtectedLayout
      onBack={'/services'}
      hasSideList
      hasUserProfile
      sidePanel={<DashboardNavigation />}
    >
      <SectionHeader title="Analytics" />
      <Row
        style={{ display: 'flex', marginLeft: 5, marginRight: 5, marginTop: 5 }}
      >
        <hr style={{ margin: 0, backgroundColor: '#000' }} />
        <Nav pills style={{ fontSize: '16px', marginTop: 10 }}>
          <NavItem>
            <NavLink
              className={activeTab === 1 ? 'active' : ''}
              onClick={() => setActiveTab(1)}
              style={{ fontSize: 20 }}
            >
              RESOURCE UTILIZATION STATISTICS
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 2 ? 'active' : ''}
              onClick={() => setActiveTab(2)}
              style={{ fontSize: 20 }}
            >
              PERMITS BY STATUS
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 3 ? 'active' : ''}
              onClick={() => setActiveTab(3)}
              style={{ fontSize: 20 }}
            >
              PERMIT STATISTICS
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 4 ? 'active' : ''}
              onClick={() => setActiveTab(4)}
              style={{ fontSize: 20 }}
            >
              KPI STATISTICS
            </NavLink>
          </NavItem>
        </Nav>
        <hr style={{ marginTop: 10 }} />
        <TabContent activeTab={activeTab} style={{ minHeight: '80vh' }}>
          <TabPane tabId={1}>
            <Row>
              <Col sm="12">
                <ResourceStatistics />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={2}>
            <Row>
              <PermitsByStatusTiles />
            </Row>
          </TabPane>

          <TabPane tabId={3}>
            <Row>
              <Col sm="12">
                <PermitStatistics />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={4}>
            <Row>
              <Col sm="12">
                <KPIStatistics />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
        <hr style={{ marginTop: 10 }} />
      </Row>
    </ProtectedLayout>
  )
}
