import arrayMutators from "final-form-arrays";
import React, { useState } from "react";
import {
  Button,
  Col,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { MdDelete } from "react-icons/md";
import { useMsal } from "@azure/msal-react";

import LabeledTextInput from "../../components/InputFields/LabeledTextInput";
import LabledParagraphInput from "../../components/InputFields/LabledParagraph";
import ProtectedLayout from "../../components/Layout/ProtectedLayout";
import Spacer from "../../components/Spacer";
import SubmitModalWithForm from "../../components/Modals/SubmitModalWithForm";
import toNormalCase from "../../components/lib/toNormalCase";
import { Box, renderData } from "../workManagement/PmTree";
import { incrementby1 } from "../../components/Forms/JSAForm";
import { makeAuthenticatedRequest } from "../../apiRequests";
import { renderField } from "../../components/Modals/CloseCMPermit";
import { stock } from "../../components/serverurl";
import { SubHeader } from "../VerifyCM";

const TABS = {
  GENERAL_DETAILS: "General Details",
  TECH_DETAILS: "Technical Details",
  COSTING_DETAILS: "Costing Details",
  CONTACT_DETAILS: "Primary Contact Details",
  PREVIEW: "Preview",
};

const GeneralDetilasFields = () => {
  return (
    <Row>
      <LabeledTextInput name="vessel" label="Vessel" occupy={3} />
      <LabeledTextInput
        name="Spare Part Number"
        label="Spare part Number"
        occupy={3}
      />
      <LabledParagraphInput
        name="Spare Part Description"
        label="Spare part Description"
        occupy={3}
      />
      <LabeledTextInput name="Tag Number" label="Tag Number" occupy={3} />
      <LabeledTextInput
        name="Equipment Description"
        label="Equipment Description"
        occupy={3}
      />
      <LabeledTextInput name="spareType" label="Spare Type" occupy={3} />
      <LabeledTextInput
        name="functionNumber"
        label="Function Number"
        occupy={3}
      />
      <LabeledTextInput
        name="locationNumber"
        label="Location Number"
        occupy={3}
      />
      <LabeledTextInput name="Warehouse" label="Warehouse" occupy={3} />
      <LabeledTextInput name="tier" label="Tier" occupy={3} />
      <LabeledTextInput name="netWeight" label="Net Weight" occupy={3} />
      <LabeledTextInput name="weightUnit" label="Weight Unit" occupy={3} />
      <LabeledTextInput name="attachments" label="Attachments" occupy={3} />
      <LabeledTextInput
        name="inventoryType"
        label="Inventory Type"
        occupy={3}
      />
      <LabeledTextInput
        name="qualityApprovalDate"
        label="Quality Approval Date"
        occupy={3}
      />
      <LabeledTextInput
        name="functionDescription"
        label="Function Description"
        occupy={3}
      />
      <LabeledTextInput
        name="productFamily"
        label="Product Family"
        occupy={3}
      />
      <LabeledTextInput name="locationType" label="Location Type" occupy={3} />
      <LabeledTextInput name="bay" label="Bay" occupy={3} />
      <LabeledTextInput name="bin" label="Bin" occupy={3} />
      <LabeledTextInput name="netVolume" label="Net Volume" occupy={3} />
      <LabeledTextInput name="weight" label="Weight" occupy={3} />
      <LabeledTextInput name="uom" label="UOM" occupy={3} />
      <LabeledTextInput name="dimQuanity" label="Dim Quantity" occupy={3} />
      <LabeledTextInput
        name="proposalRelease"
        label="Proposal Release"
        occupy={3}
      />
      <LabeledTextInput name="department" label="Department" occupy={3} />
      <LabeledTextInput name="originalPO" label="Original PO" occupy={3} />
      <LabeledTextInput name="projectId" label="Project Id" occupy={3} />
      <LabeledTextInput name="status" label="Status" occupy={3} />
      <LabeledTextInput name="productCode" label="Product Code" occupy={3} />
      <LabeledTextInput
        name="locationGroup"
        label="Location Group"
        occupy={3}
      />
      <LabeledTextInput name="row" label="Row" occupy={3} />
      <LabeledTextInput
        name="onHandQuantity"
        label="On Hand Quantity"
        occupy={3}
      />
      <LabeledTextInput name="estEquipType" label="Est Equip Type" occupy={3} />
      <LabeledTextInput name="stdLotSize" label="Std Lot Size" occupy={3} />
      <LabeledTextInput
        name="qCAnalystDate"
        label="QC Analysics Date"
        occupy={3}
      />
      <LabeledTextInput name="isGeneric" label="Is Generic" occupy={3} />
      <LabeledTextInput
        name="planningMethod"
        label="Planning Method"
        occupy={3}
      />
      <LabeledTextInput name="safetyStock" label="Safety Stock" occupy={3} />
      <LabeledTextInput name="orderPoint" label="Order Point" occupy={3} />
      <LabeledTextInput name="lotSize" label="Lot Size" occupy={3} />
      <LabeledTextInput
        name="orderCoverTime"
        label="Order Cover Time"
        occupy={3}
      />
      <LabeledTextInput name="minLotSize" label="Min Lot Size" occupy={3} />
      <LabeledTextInput name="maxLotSize" label="Max Lot Size" occupy={3} />
      <LabeledTextInput
        name="multipleLotSizw"
        label="Multiple Lot Size"
        occupy={3}
      />
      <LabeledTextInput name="scrapFactor" label="Scrap Factor" occupy={3} />
      <LabeledTextInput
        name="qCAnalystDate"
        label="QC Analysis Date"
        occupy={3}
      />
    </Row>
  );
};

const TechnicalDetailsFields = () => {
  return (
    <Row>
      <LabeledTextInput name="serialNumber" label="Serial Number" occupy={3} />
      <LabeledTextInput
        name="manufacturerPartNumber"
        label="Manufacturer Part Number"
        occupy={3}
      />
      <LabeledTextInput
        name="additionalTechInformation"
        label="Additional Tech Information"
        occupy={3}
      />
      <LabeledTextInput name="model" label="Model" occupy={3} />
      <LabeledTextInput
        name="certificateNeeded"
        label="Certificate Needed"
        occupy={3}
      />
      <LabeledTextInput name="notes" label="Notes" occupy={3} />
      <LabeledTextInput name="manufacturer" label="Manufacturer" occupy={3} />
      <LabeledTextInput
        name="techCoordinates"
        label="Tech Coordinates"
        occupy={3}
      />
      <LabeledTextInput name="consumable" label="Consumable" occupy={3} />
      <LabeledTextInput name="partType" label="Part Type" occupy={3} />
      <LabeledTextInput
        name="defaultSupplyType"
        label="Default Supply Type"
        occupy={3}
      />
      <LabeledTextInput name="remarks" label="Remarks" occupy={3} />
    </Row>
  );
};

const CostingDetailsFields = () => {
  return (
    <Row>
      <LabeledTextInput name="account" label="Account" occupy={3} />
      <LabeledTextInput
        name="priceIncludingTax"
        label="Price Including Tax"
        occupy={3}
      />
      <LabeledTextInput name="buyUnit" label="Buy Unit" occupy={3} />
      <LabeledTextInput name="assetClass" label="Asset Class" occupy={3} />
      <LabeledTextInput name="costCenter" label="Cost Center" occupy={3} />
      <LabeledTextInput
        name="originalQuantity"
        label="Original Quantity"
        occupy={3}
      />
      <LabeledTextInput name="safetyCode" label="Safety Code" occupy={3} />
      <LabeledTextInput
        name="priceCurrency"
        label="Price Currency"
        occupy={3}
      />
      <LabeledTextInput name="currencyCode" label="Currency Code" occupy={3} />
      <LabeledTextInput name="accountGroup" label="Account Group" occupy={3} />
      <LabeledTextInput name="taxable" label="Taxable" occupy={3} />
      <LabeledTextInput name="latestPrice" label="Latest Price" occupy={3} />
      <LabeledTextInput
        name="latestQuantity"
        label="Latest Quantity"
        occupy={3}
      />
      <LabeledTextInput name="averageCost" label="Average Cost" occupy={3} />
    </Row>
  );
};

const renderActivities = ({ fields, meta: { error, submitFailed } }: any) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
  >
    <Table bordered style={{ border: "1px solid #000" }}>
      <thead>
        <tr
          style={{
            backgroundColor: "orange",
            WebkitPrintColorAdjust: "exact",
          }}
        >
          <th style={{ width: "15%" }}>Supplier</th>
          <th>Supplier Contact</th>
          <th>Buyer ID</th>
          <th>Buyer Name</th>
          <th>Email Id</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {fields.map((activity: any, index: any) => (
          <React.Fragment key={index}>
            <tr>
              <td style={{ width: "15%" }}>
                <Field
                  name={`${activity}.supplier`}
                  type="text"
                  component={renderField}
                  label="Supplier Name"
                />
              </td>
              <td style={{ width: "20%" }}>
                <FieldArray
                  name={`${activity}.contact`}
                  component={renderField}
                  type="text"
                />
              </td>
              <td style={{ width: "20%" }}>
                <FieldArray
                  name={`${activity}.buyerId`}
                  component={renderField}
                  type="text"
                />
              </td>
              <td style={{ width: "10%" }}>
                <FieldArray
                  name={`${activity}.buyerName`}
                  component={renderField}
                  type="text"
                />
              </td>
              <td style={{ width: "10%" }}>
                <FieldArray
                  name={`${activity}.emailId`}
                  component={renderField}
                  type="text"
                />
              </td>
              <td style={{ width: "1%", color: "red" }}>
                <MdDelete onClick={() => fields.remove(index)} />
              </td>
            </tr>
          </React.Fragment>
        ))}
        <tr>
          <td style={{ borderRight: 0 }}>
            <Button
              type="button"
              style={{ width: "100%" }}
              onClick={() => fields.push({})}
              color="warning"
            >
              Add Contact
            </Button>
          </td>
        </tr>
        <>
          {submitFailed && error && (
            <span style={{ color: "red" }}>{error._error}</span>
          )}
        </>
      </tbody>
    </Table>
  </div>
);

const ContactDetilsFields = () => {
  return (
    <Row>
      <h3 style={{ textAlign: "center" }}>Primary Contact Details</h3>
      <Spacer height={10} />
      <LabeledTextInput name="supplier" label="Suplier" occupy={3} />
      <LabeledTextInput
        name="supplierContact"
        label="Supplier Contact"
        occupy={3}
      />
      <LabeledTextInput name="buyerID" label="Buyer ID" occupy={3} />
      <LabeledTextInput name="buyerName" label="Buyer Name" occupy={3} />
      <LabeledTextInput name="emailId" label="Email ID" occupy={3} />
      <Spacer height={20} />
      <h3 style={{ textAlign: "center" }}>Other Contact Details</h3>
      <Spacer height={20} />
      <FieldArray name="contacts" component={renderActivities} />
    </Row>
  );
};

const Preview = ({ spare, form }: { spare: any; form: any }) => {
  const { accounts, instance } = useMsal();
  const [submitModal, setSubmitModal]: any = useState(false);

  const generalOne: any = {
    vessel: spare?.vessel,
    "Spare Part Number": spare?.["Spare Part Number"],
    spareType: spare?.spareType,
    functionNumber: spare?.functionNumber,
    partFrequency: spare?.partFrequency,
    locationNumber: spare?.locationNumber,
    Warehouse: spare?.Warehouse,
    tier: spare?.tier,
    netWeight: spare?.netWeight,
    weightUnit: spare?.weightUnit,
    attachments: spare?.attachments,
    inventoryType: spare?.inventoryType,
    qualityApprovalDate: spare?.qualityApprovalDate,
  };

  const generalTwo: any = {
    "Tag Number": spare?.["Tag Number"],
    "Spare Part Description": spare?.["Spare Part Description"],
    "Equipment Description": spare?.["Equipment Description"],
    functionDescription: spare?.functionDescription,
    productFamily: spare?.productFamily,
    locationType: spare?.locationType,
    bay: spare?.bay,
    bin: spare?.bin,
    netVolume: spare?.netVolume,
    weight: spare?.weight,
    uom: spare?.uom,
    dimQuantity: spare?.dimQuantity,
    proposalRelease: spare?.proposalRelease,
  };

  const generalThree: any = {
    department: spare?.department,
    originalPO: spare?.originalPO,
    projectId: spare?.projectId,
    status: spare?.status,
    productCode: spare?.productCode,
    locationGroup: spare?.locationGroup,
    row: spare?.row,
    onHandQuantity: spare?.onHandQuantity,
    estEquipType: spare?.estEquipType,
    stdLotSize: spare?.stdLotSize,
    qCAnalysisID: spare?.qCAnalysisID,
    isGeneric: spare?.isGeneric,
  };

  const generalFour: any = {
    created: spare?.created,
    modified: spare?.modified,
    planningMethod: spare?.planningMethod,
    safetyStock: spare?.safetyStock,
    orderPoint: spare?.orderPoint,
    lotSize: spare?.lotSize,
    orderCoverTime: spare?.orderCoverTime,
    minLotSize: spare?.minLotSize,
    maxLotSize: spare?.maxLotSize,
    multipleLotSize: spare?.multipleLotSize,
    scrapFactor: spare?.scrapFactor,
    qCAnalystDate: spare?.qCAnalystDate,
  };

  const technicalDetails: any = {
    serialNumber: spare?.serialNumber,
    manufacturerPartNumber: spare?.manufacturerPartNumber,
    additionalTechInformation: spare?.additionalTechInformation,
    model: spare?.model,
    certificateNeeded: spare?.certificateNeeded,
    notes: spare?.notes,
    manufacturer: spare?.manufacturer,
    consumable: spare?.consumable,
    techCoordinates: spare?.techCoordinates,
    partType: spare?.partType,
    defaultSupplyType: spare?.defaultSupplyType,
    remarks: spare?.remarks,
  };

  const costingDetails: any = {
    account: spare?.account,
    priceIncludingTax: spare?.priceIncludingTax,
    buyUnit: spare?.buyUnit,
    assetClass: spare?.assetClass,
    costCenter: spare?.costCenter,
    originalQuantity: spare?.originalQuantity,
    safetyCode: spare?.safetyCode,
    priceCurrency: spare?.priceCurrency,
    currencyCode: spare?.currencyCode,
    accountGroup: spare?.accountGroup,
    taxable: spare?.taxable,
    latestPrice: spare?.latestPrice,
    latestQuantity: spare?.latestQuantity,
    averageCost: spare?.averageCost,
  };

  const contactDetails: any = {
    supplier: spare?.supplier,
    supplierContact: spare?.supplierContact,
    buyerID: spare?.buyerID,
    buyerName: spare?.buyerName,
    emailId: spare?.emailId,
  };

  return (
    <Row style={{ margin: 0 }}>
      <Col xs="12">
        <Box heading="General Details">
          <Row>
            <Col xs="3" style={{ borderRight: "1px solid #000" }}>
              {Object.keys(generalOne)?.map((key: any) =>
                renderData(toNormalCase(key), generalOne[key])
              )}
            </Col>
            <Col xs="3" style={{ borderRight: "1px solid #000" }}>
              {Object.keys(generalTwo)?.map((key: any) =>
                renderData(toNormalCase(key), generalTwo[key])
              )}
            </Col>
            <Col xs="3" style={{ borderRight: "1px solid #000" }}>
              {Object.keys(generalThree)?.map((key: any) =>
                renderData(toNormalCase(key), generalThree[key])
              )}
            </Col>
            <Col xs="3">
              {Object.keys(generalFour)?.map((key: any) =>
                renderData(toNormalCase(key), generalFour[key])
              )}
            </Col>
          </Row>
        </Box>
      </Col>

      <Col xs="6">
        <Box heading="Technical Details">
          {Object.keys(technicalDetails)?.map((key: any) =>
            renderData(toNormalCase(key), technicalDetails[key])
          )}
        </Box>
        <Box heading="Contact Details">
          {Object.keys(contactDetails)?.map((key: any) =>
            renderData(toNormalCase(key), contactDetails[key])
          )}
        </Box>
      </Col>
      <Col xs="6">
        <Box heading="Costing Details">
          {Object.keys(costingDetails)?.map((key: any) =>
            renderData(toNormalCase(key), costingDetails[key])
          )}
        </Box>
      </Col>
      <Col xs="12">
        <Box heading="Other Contact Details">
          <Table>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Supplier Name</th>
                <th>Supplier Contact</th>
                <th>Buyer Id</th>
                <th>Buyer Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {spare?.contacts?.map((c: any, index: number) => (
                <tr key={c?.supplier + index}>
                  <td>{incrementby1(index)}</td>
                  <td>{c?.supplier}</td>
                  <td>{c?.contact}</td>
                  <td>{c?.buyerId}</td>
                  <td>{c?.name}</td>
                  <td>{c?.emailId}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      </Col>
      <ModalFooter>
        <Button color="warning" onClick={() => setSubmitModal(true)}>
          Submit
        </Button>
      </ModalFooter>
      {submitModal && (
        <SubmitModalWithForm
          isOpen={submitModal}
          onClose={(val: any) => {
            setSubmitModal(false);
            form.reset();
          }}
          onSubmit={async (e: any) => {
            e?.preventDefault();
            try {
              const res: any = await makeAuthenticatedRequest(
                `${stock}/inventory`,
                "POST",
                spare,
                accounts,
                instance
              );

              console.log(res);

              return res?.status;
            } catch (error) {}
          }}
          header="Add Stock"
        />
      )}
    </Row>
  );
};

export default function AddNewStockItem() {
  const [active, setActive]: any = useState(TABS.GENERAL_DETAILS);

  return (
    <ProtectedLayout
      onBack="/cmms/supplychain/warehouse"
      title="Add New Stock Item"
    >
      <Row style={{ margin: 10 }}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={active === TABS.GENERAL_DETAILS ? "active" : ""}
              onClick={() => setActive(TABS.GENERAL_DETAILS)}
            >
              {TABS?.GENERAL_DETAILS}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === TABS.TECH_DETAILS ? "active" : ""}
              onClick={() => setActive(TABS.TECH_DETAILS)}
            >
              {TABS?.TECH_DETAILS}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === TABS.COSTING_DETAILS ? "active" : ""}
              onClick={() => setActive(TABS.COSTING_DETAILS)}
            >
              {TABS?.COSTING_DETAILS}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === TABS.CONTACT_DETAILS ? "active" : ""}
              onClick={() => setActive(TABS.CONTACT_DETAILS)}
            >
              {TABS?.CONTACT_DETAILS}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={active === TABS.PREVIEW ? "active" : ""}
              onClick={() => setActive(TABS.PREVIEW)}
            >
              {TABS?.PREVIEW}
            </NavLink>
          </NavItem>
        </Nav>
        <Form
          onSubmit={(val: any) => console.log(val)}
          mutators={{
            ...arrayMutators,
          }}
          render={({ form, values }) => (
            <form>
              <TabContent activeTab={active}>
                <TabPane tabId={TABS.GENERAL_DETAILS}>
                  <SubHeader
                    header={TABS.GENERAL_DETAILS}
                    permitColor="#FFD580"
                  />
                  <GeneralDetilasFields />
                </TabPane>
                <TabPane tabId={TABS.TECH_DETAILS}>
                  <SubHeader header={TABS.TECH_DETAILS} permitColor="#FFD580" />
                  <TechnicalDetailsFields />
                </TabPane>
                <TabPane tabId={TABS.COSTING_DETAILS}>
                  <SubHeader
                    header={TABS.COSTING_DETAILS}
                    permitColor="#FFD580"
                  />
                  <CostingDetailsFields />
                </TabPane>
                <TabPane tabId={TABS.CONTACT_DETAILS}>
                  <SubHeader
                    header={TABS.CONTACT_DETAILS}
                    permitColor="#FFD580"
                  />
                  <ContactDetilsFields />
                </TabPane>
                <TabPane tabId={TABS.PREVIEW}>
                  <SubHeader header={TABS.PREVIEW} permitColor="#FFD580" />
                  <Preview spare={values} form={form} />
                </TabPane>
              </TabContent>
            </form>
          )}
        />
      </Row>
    </ProtectedLayout>
  );
}
