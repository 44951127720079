import React from 'react'
import { Table } from 'reactstrap'
import { capitalizeFirstLetter } from '../lib/capitalizeFirstLetter'
import { ColoredSubHeader } from './ViewExcavationPermit'

type ViewVechiclePermit = {
  data: any
  rowStyle: any
}

export default function Accknowledgements({
  data,
  rowStyle
}: ViewVechiclePermit) {
  const startIsolation: any = data?.createFlow?.isolation?.startIsolation
  const finishIsolation: any = data?.createFlow?.isolation?.finishIsolation
  const liveIsolation: any = data?.createFlow?.isolation?.liveIsolation
  const createFlow: any = data?.createFlow
  const revalidate: any = data?.revalidate
  const deIsolationAndClose: any = data?.deIsolationAndClose
  const lti: any = data?.lti
  const cio: any = data?.cio
  const woi: any = data?.woi
  const cwoi: any = data?.cwoi

  const startDeIsolation: any =
    deIsolationAndClose?.deIsolation?.startDeIsolation

  const finishDeIsolation: any =
    deIsolationAndClose?.deIsolation?.finishDeIsolation

  const closedDeIsolation: any =
    deIsolationAndClose?.deIsolation?.closeDeIsolation

  const departments: any = [
    'mechanical',
    'electrical',
    'instrument',
    'production'
  ]

  const assignedTo =
    cio?.initiate?.assignTo && JSON.parse(cio?.initiate?.assignTo)

  return (
    <>
      <ColoredSubHeader rowStyle={rowStyle} text="Acknowledgements" />
      <Table bordered style={{ borderColor: '#000' }}>
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            <th>Flow</th>
            <th>Stage</th>
            <th>Question</th>
            <th>Answer</th>
          </tr>
        </thead>
        <tbody style={{ verticalAlign: 'middle' }}>
          {data?.createFlow?.create && (
            <>
              <tr>
                <td rowSpan={4}>New Permit</td>
                <td rowSpan={4}>Create</td>
                <td>
                  Discussed the Risk assesment, and work scope of this Permit
                  with IM and with all parties Involved
                </td>
                <td>{data?.createFlow?.create?.discussRiskAssesment}</td>
              </tr>
              <tr>
                <td>
                  Equipment Preparation & Site visit carried out? Done /Not
                  Required
                </td>
                <td>{data?.createFlow?.create?.equipmentPreparation}</td>
              </tr>
              <tr>
                <td>Checked Material availability for this Permit</td>
                <td>{data?.createFlow?.create?.materialAvailability}</td>
              </tr>
              <tr>
                <td>
                  Hazard from other routine / non-routine operations considered
                  and persons alerted
                </td>
                <td>{data?.createFlow?.create?.otherHazardsConsidered}</td>
              </tr>
            </>
          )}
          {data?.createFlow?.riskAssessment && (
            <>
              <tr>
                <td rowSpan={4}>New Permit</td>
                <td rowSpan={4}>HSE Verify</td>
                <td>Is the Risk Assessment Reviewed </td>
                <td>{data?.createFlow?.riskAssessment?.approveRisks}</td>
              </tr>
              <tr>
                <td>Any corrections made in risk assessment</td>
                <td>{data?.createFlow?.riskAssessment?.discussedRisk}</td>
              </tr>
              <tr>
                <td>
                  Is this Permit type appropriate for this task carried out in
                  this Permit
                </td>
                <td>{data?.createFlow?.riskAssessment?.typeApproriate}</td>
              </tr>
              <tr>
                <td>Equipment Preparation & Site visit carried out</td>
                <td>{data?.createFlow?.riskAssessment?.appropriatePermit}</td>
              </tr>
              {/* <tr>
                <td>
                  Is Sewers, Manholes, Closed Blow Down (CBD) etc. and Hot
                  Surfaces covered
                </td>
                <td>{data?.createFlow?.riskAssessment?.surfacesCovered}</td>
              </tr>
              <tr>
                <td>
                  Is Portable equipment / Hose nozzles properly groundedst
                </td>
                <td>{data?.createFlow?.riskAssessment?.portable}</td>
              </tr>
              <tr>
                <td>
                  Standby personnel provided for fire watch from Process /
                  Maintenance / Contractor / Fire Department
                </td>
                <td>{data?.createFlow?.riskAssessment?.standbyPersonnel}</td>
              </tr> */}
            </>
          )}
          {data?.createFlow?.verify && (
            <>
              <tr>
                <td rowSpan={4}>New Permit</td>
                <td rowSpan={4}>Verify</td>
                <td>
                  Have you discussed the Isolation requirement is sufficient for
                  this Work with IM,AO and JP
                </td>
                <td>{data?.createFlow?.verify?.discussedIsolations}</td>
              </tr>
              <tr>
                <td>Equipment Preparation & Site visit carried out</td>
                <td>{data?.createFlow?.verify?.equipmentPreparation}</td>
              </tr>
              <tr>
                <td>
                  Is this Permit type appropriate for this task carried out in
                  this Permit
                </td>
                <td>{data?.createFlow?.verify?.appropriatePermit}</td>
              </tr>
              <tr>
                <td>SIMOPS carried out</td>
                <td>{data?.createFlow?.verify?.simopsCarriedOut}</td>
              </tr>
            </>
          )}
          {data?.reject?.reason && (
            <>
              <tr>
                <td rowSpan={1}>New Permit</td>
                <td rowSpan={1}>Rejected</td>
                <td>
                  <b>Rejected Comments : </b>
                  {data?.reject?.reason}
                </td>
                <td>-</td>
              </tr>
            </>
          )}

          {departments?.map(
            (d: any) =>
              startIsolation?.[d] && (
                <>
                  <tr>
                    <td rowSpan={4}>New Permit</td>
                    <td rowSpan={4}>
                      Start Isolation - {capitalizeFirstLetter(d)}
                    </td>
                    <td>
                      Is the isolation points mentioned satisfactory and
                      sufficient to carry out this Job
                    </td>
                    <td>{startIsolation?.[d]?.isolationPoints}</td>
                  </tr>
                  <tr>
                    <td>Received Lock Key for your Isolation</td>
                    <td>{startIsolation?.[d]?.receivedLockKey}</td>
                  </tr>
                  <tr>
                    <td>
                      Is communication established with the IM and Control Room
                    </td>
                    <td>{startIsolation?.[d]?.communicationEstablished}</td>
                  </tr>
                  <tr>
                    <td>Is training sufficient for perforning the Isolation</td>
                    <td>{startIsolation?.[d]?.sufficientTraining}</td>
                  </tr>
                </>
              )
          )}
          {departments?.map(
            (d: any) =>
              finishIsolation?.[d] && (
                <>
                  <tr>
                    <td rowSpan={3}> New Permit</td>
                    <td rowSpan={3}>
                      Finish Isolation - {capitalizeFirstLetter(d)}
                    </td>
                    <td>
                      Is the Isolation done as per the Company isolation
                      philosoph
                    </td>
                    <td>{finishIsolation?.[d]?.isolationDone}</td>
                  </tr>
                  <tr>
                    <td>
                      Have you isolated all the Points under your Department as
                      per Isolation certificate
                    </td>
                    <td>{finishIsolation?.[d]?.allIsolated}</td>
                  </tr>
                  <tr>
                    <td>
                      Is the Electrical isolation have been recorded in the
                      electrical shift logbook
                    </td>
                    <td>
                      {finishIsolation?.[d]?.electricalIsolationsRecorded}
                    </td>
                  </tr>
                </>
              )
          )}
          {departments?.map(
            (d: any) =>
              liveIsolation?.[d] && (
                <>
                  <tr>
                    <td rowSpan={4}>New Permit</td>
                    <td rowSpan={4}>
                      Live Isolation - {capitalizeFirstLetter(d)}
                    </td>
                    <td>PadLock Colour is relevant for your Department</td>
                    <td>{liveIsolation?.[d]?.padLockColor}</td>
                  </tr>
                  <tr>
                    <td>All points are Locked out and Tagged out properly</td>
                    <td>{liveIsolation?.[d]?.allPointsLocked}</td>
                  </tr>
                  <tr>
                    <td>
                      Is the equipment / circuit de-energized and isolated from
                      all live conductors to carry out the maintenance work
                    </td>
                    <td>{liveIsolation?.[d]?.equipmentDeEnergized}</td>
                  </tr>
                  <tr>
                    <td>Is it safe for Job Performer to proceed the Job</td>
                    <td>{liveIsolation?.[d]?.isSafe}</td>
                  </tr>
                </>
              )
          )}
          {createFlow?.issue && (
            <>
              <tr>
                <td rowSpan={3}>New Permit</td>
                <td rowSpan={3}>Issue</td>
                <td>
                  Is the Isolation verified and sufficient to carry other this
                  Work Task for this Permit
                </td>
                <td>{createFlow?.issue?.isolationVerified}</td>
              </tr>
              {/* <tr>
                <td>
                  Is Sufficient Manpower and Materials allocated for this Job?
                </td>
                <td>{createFlow?.issue?.sufficentManPower}</td>
              </tr> */}
              <tr>
                <td>
                  Is communication carried out between all the teams prior
                  applying this Permit
                </td>
                <td>{createFlow?.issue?.coummicationCarried}</td>
              </tr>
              <tr>
                <td>SIMOPS carried out</td>
                <td>{createFlow?.issue?.simopsCarriedOut}</td>
              </tr>
            </>
          )}
          {createFlow?.approve && (
            <>
              <tr>
                <td rowSpan={8}>New Permit</td>
                <td rowSpan={8}>Approve</td>
                <td>Is it safe for Job Performer to proceed the Job</td>
                <td>{createFlow?.approve?.isSafe}</td>
              </tr>
              <tr>
                <td>Is the area free from Hydrocarbons and acceptable LEL</td>
                <td>{createFlow?.approve?.hydroCarbonsPresent}</td>
              </tr>
              <tr>
                <td>
                  Is the area clear from Hazardous materials and Dropped Object
                  and Bad weather Conditions
                </td>
                <td>{createFlow?.approve?.hazardsoursMaterials}</td>
              </tr>
              <tr>
                <td>Equipment properly drained / depressurised</td>
                <td>{createFlow?.approve?.properlyDrained}</td>
              </tr>
              <tr>
                <td>Equipment properly steamed / purged</td>
                <td>{createFlow?.approve?.properlySteamed}</td>
              </tr>
              <tr>
                <td>Equipment water flushed</td>
                <td>{createFlow?.approve?.waterFlushed}</td>
              </tr>
              <tr>
                <td>
                  Checked for oil / gas trapped behind lining in equipment
                </td>
                <td>{createFlow?.approve?.oilCheck}</td>
              </tr>
              <tr>
                <td>Surrounding area checked</td>
                <td>{createFlow?.approve?.areaChecked}</td>
              </tr>
            </>
          )}
          {createFlow?.live && (
            <>
              <tr>
                <td rowSpan={4}>New Permit</td>
                <td rowSpan={4}>Live</td>
                <td>
                  Understood the complete task of this permit and communicated
                  to IM and PIC
                </td>
                <td>{createFlow?.live?.taskUnderstood}</td>
              </tr>
              <tr>
                <td>
                  Is the manpower allotted is sufficient to complete this Task
                </td>
                <td>{createFlow?.live?.manpowerAlloted}</td>
              </tr>
              <tr>
                <td>
                  Is the correct spare taken from the stores if applicable
                </td>
                <td>{createFlow?.live?.correctSpare}</td>
              </tr>
              <tr>
                <td>
                  Is all the documents attached to this Permit sufficient to
                  complete this task
                </td>
                <td>{createFlow?.live?.documentsAttached}</td>
              </tr>
            </>
          )}
          {revalidate?.initiate && (
            <>
              <tr>
                <td rowSpan={3}>Revalidate</td>
                <td rowSpan={3}>Initiate</td>
                <td>
                  Is the Job carried out is properly secured and safe to work
                  for next shift
                </td>
                <td>{revalidate?.initiate?.carriedOutProperly}</td>
              </tr>
              <tr>
                <td>
                  Do you informed the Team members and communicated to IM and
                  PIC for Revalidation
                </td>
                <td>{revalidate?.initiate?.informed}</td>
              </tr>
              <tr>
                <td>Surrounding area checked / cleaned</td>
                <td>{revalidate?.initiate?.areaChecked}</td>
              </tr>
            </>
          )}
          {revalidate?.verify && (
            <>
              <tr>
                <td rowSpan={3}>Revalidate</td>
                <td rowSpan={3}>Verify</td>
                <td>
                  Have you discussed the Revalidation Scope for this Work with
                </td>
                <td>{revalidate?.verify?.revalidationDiscussed}</td>
              </tr>
              <tr>
                <td>Equipment Preparation & Site visit carried out</td>
                <td>{revalidate?.verify?.equipmentPreparation}</td>
              </tr>
              <tr>
                <td>SIMOPS carried out</td>
                <td>{revalidate?.verify?.simops}</td>
              </tr>
            </>
          )}
          {revalidate?.issue && (
            <>
              <tr>
                <td rowSpan={4}>Revalidate</td>
                <td rowSpan={4}>Issue</td>
                <td>
                  Is the Isolation verified and sufficient to carry other this
                  Work Task for this Permit
                </td>
                <td>{revalidate?.issue?.isolationVerified}</td>
              </tr>
              <tr>
                <td>
                  Is Sufficient Manpower and Materials allocated for this Job
                </td>
                <td>{revalidate?.issue?.sufficientManpower}</td>
              </tr>
              <tr>
                <td>
                  Is communication carried out between all the teams prior
                  applying this Permit
                </td>
                <td>{revalidate?.issue?.communicationCarried}</td>
              </tr>
              <tr>
                <td>SIMOPS carried out</td>
                <td>{revalidate?.issue?.simops}</td>
              </tr>
            </>
          )}
          {revalidate?.approve && (
            <>
              <tr>
                <td rowSpan={8}>Revalidate</td>
                <td rowSpan={8}>Approve</td>
                <td>Is it safe for Job Performer to proceed the Job</td>
                <td>{revalidate?.approve?.isSafe}</td>
              </tr>
              <tr>
                <td>Is the area free from Hydrocarbons and acceptable LEL</td>
                <td>{revalidate?.approve?.hydroCarbonsPresent}</td>
              </tr>
              <tr>
                <td>
                  Is the area clear from Hazardous materials and Dropped Object
                  and Bad weather Conditions
                </td>
                <td>{revalidate?.approve?.hazardsoursMaterials}</td>
              </tr>
              <tr>
                <td>Equipment properly drained / depressurised</td>
                <td>{revalidate?.approve?.properlyDrained}</td>
              </tr>
              <tr>
                <td>Equipment properly steamed / purged</td>
                <td>{revalidate?.approve?.properlySteamed}</td>
              </tr>
              <tr>
                <td>Equipment water flushed</td>
                <td>{revalidate?.approve?.waterFlushed}</td>
              </tr>
              <tr>
                <td>
                  Checked for oil / gas trapped behind lining in equipment
                </td>
                <td>{revalidate?.approve?.oilCheck}</td>
              </tr>
              <tr>
                <td>Surrounding area checked</td>
                <td>{revalidate?.approve?.areaChecked}</td>
              </tr>
            </>
          )}
          {revalidate?.live && (
            <>
              <tr>
                <td rowSpan={4}>Revalidate</td>
                <td rowSpan={4}>Live</td>
                <td>
                  Understood the complete task of this permit and communicated
                  to IM and PIC
                </td>
                <td>{revalidate?.live?.taskUnderstood}</td>
              </tr>
              <tr>
                <td>
                  Is the manpower allotted is sufficient to complete this Task
                </td>
                <td>{revalidate?.live?.manpowerAlloted}</td>
              </tr>
              <tr>
                <td>
                  Is the correct spare taken from the stores if applicable
                </td>
                <td>{revalidate?.live?.correctSpare}</td>
              </tr>
              <tr>
                <td>
                  Is all the documents attached to this Permit sufficient to
                  complete this task
                </td>
                <td>{revalidate?.live?.documentsAttached}</td>
              </tr>
            </>
          )}
          {deIsolationAndClose?.initiate && (
            <>
              <tr>
                <td rowSpan={9}>DeIsolation And Close</td>
                <td rowSpan={9}>Initiate</td>
                <td>Is the work</td>
                <td>{deIsolationAndClose?.initiate?.workComplete}</td>
              </tr>
              <tr>
                <td>If Incomplete do u need to create a new CM Work Order</td>
                <td>{deIsolationAndClose?.initiate?.needNew}</td>
              </tr>
              <tr>
                <td>Unit shutdown occurred while performing this Task</td>
                <td>{deIsolationAndClose?.initiate?.unitShutdown}</td>
              </tr>
              <tr>
                <td>Plant shutdown occurred while performing this Task</td>
                <td>{deIsolationAndClose?.initiate?.plantShutdown}</td>
              </tr>
              <tr>
                <td>Department Worked and working hours </td>
                <td>{deIsolationAndClose?.initiate?.departmentWorked}</td>
              </tr>
              <tr>
                <td>Vendor Used and working hours</td>
                <td>{deIsolationAndClose?.initiate?.vendorUsed}</td>
              </tr>
              <tr>
                <td>Comments History</td>
                <td>{deIsolationAndClose?.initiate?.history}</td>
              </tr>
              <tr>
                <td>Equipment witness by</td>
                <td>{deIsolationAndClose?.initiate?.witness}</td>
              </tr>
              <tr>
                <td>Any LTI incident while performing this Task </td>
                <td>{deIsolationAndClose?.initiate?.ltiIncident}</td>
              </tr>
            </>
          )}
          {deIsolationAndClose?.verify && (
            <>
              <tr>
                <td rowSpan={3}>DeIsolate And Close</td>
                <td rowSpan={3}>Verify</td>
                <td>Is the task completed Successfully</td>
                <td>{deIsolationAndClose?.verify?.taskCompleted}</td>
              </tr>
              <tr>
                <td>
                  Lock Key delivered to IA and discussed about the Deislation
                  task
                </td>
                <td>{deIsolationAndClose?.verify?.lockKeyDelivered}</td>
              </tr>
              <tr>
                <td>
                  Communication establihed with IM and all the team members
                  about Deisolation
                </td>
                <td>{deIsolationAndClose?.verify?.taskCompleted}</td>
              </tr>
            </>
          )}
          {departments?.map(
            (d: any) =>
              startDeIsolation?.[d] && (
                <>
                  <tr>
                    <td rowSpan={4}>De Isolation And Close</td>
                    <td rowSpan={4}>
                      Start De Isolation - {capitalizeFirstLetter(d)}
                    </td>
                    <td>
                      Is the isolation points mentioned satisfactory and
                      sufficient to carry out this Job
                    </td>
                    <td>{startDeIsolation?.[d]?.isolationPoints}</td>
                  </tr>
                  <tr>
                    <td>Received Lock Key for your Isolation</td>
                    <td>{startDeIsolation?.[d]?.receivedLockKey}</td>
                  </tr>
                  <tr>
                    <td>
                      Is communication established with the IM and Control Room
                    </td>
                    <td>{startDeIsolation?.[d]?.communicationEstablished}</td>
                  </tr>
                  <tr>
                    <td>Is training sufficient for perforning the Isolation</td>
                    <td>{startDeIsolation?.[d]?.sufficientTraining}</td>
                  </tr>
                </>
              )
          )}
          {departments?.map(
            (d: any) =>
              finishDeIsolation?.[d] && (
                <>
                  <tr>
                    <td rowSpan={3}> De Isolation And Close</td>
                    <td rowSpan={3}>
                      Finish De Isolation - {capitalizeFirstLetter(d)}
                    </td>
                    <td>
                      Is the Isolation done as per the Company isolation
                      philosoph
                    </td>
                    <td>{finishDeIsolation?.[d]?.isolationDone}</td>
                  </tr>
                  <tr>
                    <td>
                      Have you isolated all the Points under your Department as
                      per Isolation certificate
                    </td>
                    <td>{finishDeIsolation?.[d]?.allIsolated}</td>
                  </tr>
                  <tr>
                    <td>
                      Is the Electrical isolation have been recorded in the
                      electrical shift logbook
                    </td>
                    <td>
                      {finishDeIsolation?.[d]?.electricalIsolationsRecorded}
                    </td>
                  </tr>
                </>
              )
          )}
          {departments?.map(
            (d: any) =>
              closedDeIsolation?.[d] && (
                <>
                  <tr>
                    <td rowSpan={2}>De Isolation And Close</td>
                    <td rowSpan={2}>
                      Closed De Isolation - {capitalizeFirstLetter(d)}
                    </td>
                    <td>Is all Locked out and Tagged out removed properly</td>
                    <td>{liveIsolation?.[d]?.lockedOut}</td>
                  </tr>
                  <tr>
                    <td>Is it safe for Job Performer to Close the Permit</td>
                    <td>{liveIsolation?.[d]?.isSafe}</td>
                  </tr>
                </>
              )
          )}
          {deIsolationAndClose?.approve && (
            <>
              <tr>
                <td rowSpan={14}>DeIsolate And Close</td>
                <td rowSpan={14}>Approve</td>
                <td>Is the work Completed</td>
                <td>{deIsolationAndClose?.approve?.workCompleted}</td>
              </tr>
              <tr>
                <td>Is the area is checked for cleanliness after work</td>
                <td>{deIsolationAndClose?.approve?.cleanlinessCheck}</td>
              </tr>
              <tr>
                <td>
                  Equipment Tools are properly secured and kept in correct Place
                </td>
                <td>{deIsolationAndClose?.approve?.properlySecured}</td>
              </tr>
              <tr>
                <td>Is the area cleared and is safe from any hazard</td>
                <td>{deIsolationAndClose?.approve?.isSafe}</td>
              </tr>

              <tr>
                <td>Equipment properly drained / depressurised</td>
                <td>{deIsolationAndClose?.approve?.properlyDrained}</td>
              </tr>

              <tr>
                <td>
                  Equipment properly steamed / purged? Done / Not Required
                </td>
                <td>{deIsolationAndClose?.approve?.properlySteamed}</td>
              </tr>

              <tr>
                <td>Equipment water flushed</td>
                <td>{deIsolationAndClose?.approve?.waterFlushed}</td>
              </tr>

              <tr>
                <td>
                  Checked for oil / gas trapped behind lining in equipment
                </td>
                <td>{deIsolationAndClose?.approve?.oilCheck}</td>
              </tr>

              <tr>
                <td>Surrounding area checked / cleaned</td>
                <td>{deIsolationAndClose?.approve?.areaChecked}</td>
              </tr>
              <tr>
                <td>Unit shutdown occurred while performing this Task</td>
                <td>{deIsolationAndClose?.approve?.unitShutdown}</td>
              </tr>
              <tr>
                <td>Plant shutdown occurred while performing this Task</td>
                <td>{deIsolationAndClose?.approve?.plantshutdown}</td>
              </tr>
              <tr>
                <td>Any Production loss occurred while performing this Task</td>
                <td>{deIsolationAndClose?.approve?.prodLoss}</td>
              </tr>
              <tr>
                <td>Any LTI incident while performing this Task</td>
                <td>{deIsolationAndClose?.approve?.ltiIncident}</td>
              </tr>
              <tr>
                <td>Comments</td>
                <td>{deIsolationAndClose?.approve?.comments}</td>
              </tr>
            </>
          )}
          {deIsolationAndClose?.issue && (
            <>
              <tr>
                <td rowSpan={3}>DeIsolate And Close</td>
                <td rowSpan={3}>Issue</td>
                <td>Is the Deisolation done as per Isolation philosophy</td>
                <td>{deIsolationAndClose?.issue?.deIsolationDone}</td>
              </tr>
              <tr>
                <td>Is the work completed</td>
                <td>{deIsolationAndClose?.issue?.workCompleted}</td>
              </tr>
              <tr>
                <td>Comments</td>
                <td>{deIsolationAndClose?.issue?.comments}</td>
              </tr>
            </>
          )}
          {lti?.initiate && (
            <>
              <tr>
                <td rowSpan={4}>Long Term Isolation</td>
                <td rowSpan={4}>Initate</td>
                <td>
                  Is the Isolation for LTI followed as per Company Procedure
                </td>
                <td>{lti?.initiate?.companyProcedure}</td>
              </tr>
              <tr>
                <td>
                  Do you communicate with all the team members, IM, PIC and
                  Control Room for LTI
                </td>
                <td>{lti?.initiate?.communicated}</td>
              </tr>
              <tr>
                <td>Is the LOTO followed as per Isolation Philosophy</td>
                <td>{lti?.initiate?.lotoFollowed}</td>
              </tr>
              <tr>
                <td>Reason for LTI</td>
                <td>{lti?.initiate?.reason}</td>
              </tr>
            </>
          )}
          {lti?.verify && (
            <>
              <tr>
                <td rowSpan={3}>Long Term Isolation</td>
                <td rowSpan={3}>Verify</td>
                <td>Do the LTI affect any normal Process</td>
                <td>{lti?.verify?.ltiAffect}</td>
              </tr>
              <tr>
                <td>Is the LOTO followed as per Isolation Philosophy</td>
                <td>{lti?.verify?.lotoFollowed}</td>
              </tr>
              <tr>
                <td>Will there be any Production Loss for this LTI</td>
                <td>{lti?.verify?.productionLoss}</td>
              </tr>
            </>
          )}
          {lti?.approve && (
            <>
              <tr>
                <td rowSpan={2}>Long Term Isolation</td>
                <td rowSpan={2}>Approve</td>
                <td>Is the LTI done as per Isolation Philosophy</td>
                <td>{lti?.approve?.ltiDone}</td>
              </tr>
              <tr>
                <td>
                  Is the LTI recorded properly and notified to all concerned
                  Departments and Control Room
                </td>
                <td>{lti?.approve?.ltiRecorded}</td>
              </tr>
            </>
          )}
          {lti?.issue && (
            <>
              <tr>
                <td rowSpan={4}>Long Term Isolation</td>
                <td rowSpan={4}>Issue</td>
                <td>Is the LTI done as per Isolation Philosophy</td>
                <td>{lti?.issue?.ltiDone}</td>
              </tr>
              <tr>
                <td>Is the spares not available</td>
                <td>{lti?.issue?.sparesAvailable}</td>
              </tr>
              <tr>
                <td>Is the manpower not available</td>
                <td>{lti?.issue?.manpower}</td>
              </tr>
              <tr>
                <td>Comments</td>
                <td>{lti?.issue?.comments}</td>
              </tr>
            </>
          )}
          {cio?.initiate && (
            <>
              <tr>
                <td rowSpan={4}>Change In Ownership</td>
                <td rowSpan={4}>Initiate</td>
                <td>
                  Is the job scope is permit is discussed with the existing Job
                  Performer
                </td>
                <td>{cio?.initiate?.jobScope}</td>
              </tr>
              <tr>
                <td>
                  Is your Team members, Control Room,PIC, AO and IM informed
                  about your change in Ownership
                </td>
                <td>{cio?.initiate?.teamMembers}</td>
              </tr>
              <tr>
                <td>
                  Have you discussed the Risk assessment, and work scope of this
                  Permit with IM
                </td>
                <td>{cio?.initiate?.discussed}</td>
              </tr>
              <tr>
                <td>Assigned to</td>
                <td>
                  <p style={{ marginBottom: 0 }}>Name: {assignedTo?.name}</p>
                  <p style={{ marginBottom: 0 }}>
                    Designation: {assignedTo?.desgination}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    Department: {assignedTo?.department}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    Employee Id: {assignedTo?.employeeId}
                  </p>
                </td>
              </tr>
            </>
          )}
          {cio?.verify && (
            <>
              <tr>
                <td rowSpan={2}>Change In Ownership</td>
                <td rowSpan={2}>Verify</td>
                <td>
                  Have you discussed the job scope for this Work with new JP
                </td>
                <td>{cio?.verify?.discussedJob}</td>
              </tr>
              <tr>
                <td>
                  Equipment Preparation & Site visit carried out with the new JP
                </td>
                <td>{cio?.verify?.siteVisit}</td>
              </tr>
            </>
          )}
          {cio?.issue && (
            <>
              <tr>
                <td rowSpan={2}>Change In Ownership</td>
                <td rowSpan={2}>Issue</td>
                <td>
                  Have you discussed the job scope for this Work with new JP
                </td>
                <td>{cio?.issue?.discussedJob}</td>
              </tr>
              <tr>
                <td>
                  Is communication carried out between all the teams prior
                  signing off and sign in for New JP
                </td>
                <td>{cio?.issue?.signing}</td>
              </tr>
            </>
          )}
          {cio?.approve && (
            <>
              <tr>
                <td rowSpan={3}>Change In Ownership</td>
                <td rowSpan={3}>Approve</td>
                <td>
                  Have you discussed the job scope for this Work with new JP
                </td>
                <td>{cio?.approve?.isSafe}</td>
              </tr>
              <tr>
                <td>Is the area free from Hydrocarbons and acceptable LEL</td>
                <td>{cio?.approve?.freeFromHydrocarbons}</td>
              </tr>
              <tr>
                <td>Is the Full scope discussed with the new JP</td>
                <td>{cio?.approve?.scopeDiscussed}</td>
              </tr>
            </>
          )}
          {woi?.issue && (
            <>
              <tr>
                <td rowSpan={3}>Without Isolation</td>
                <td rowSpan={3}>Issue</td>
                <td>
                  Confimed with concerned authority about availability of
                  material and manpower
                </td>
                <td>{woi?.issue?.sufficientManpower}</td>
              </tr>
              <tr>
                <td>
                  Is communication carried out between all the teams prior
                  applying this Permit
                </td>
                <td>{woi?.issue?.communicationCarried}</td>
              </tr>
              <tr>
                <td>SIMOPS ensured</td>
                <td>{woi?.issue?.simopsCarried}</td>
              </tr>
            </>
          )}
          {woi?.approve && (
            <>
              <tr>
                <td rowSpan={3}>Without Isolation</td>
                <td rowSpan={3}>Approve</td>
                <td>Is it safe for Job Performer to proceed the Job</td>
                <td>{woi?.approve?.isSafe}</td>
              </tr>
              <tr>
                <td>Is the area free from Hydrocarbons and acceptable LEL</td>
                <td>{woi?.approve?.hydroCarbonsPresent}</td>
              </tr>
              <tr>
                <td>
                  Is the area clear from Hazardous materials and Dropped Object
                  and Bad weather Conditions
                </td>
                <td>{woi?.approve?.hazardsoursMaterials}</td>
              </tr>
            </>
          )}
          {woi?.makeLive && (
            <>
              <tr>
                <td rowSpan={4}>Without Isolation</td>
                <td rowSpan={4}>Live</td>
                <td>
                  Understood the complete task of this permit and communicated
                  to IM and PIC
                </td>
                <td>{woi?.makeLive?.understoodTask}</td>
              </tr>
              <tr>
                <td>
                  Is the manpower allotted is sufficient to complete this Task
                </td>
                <td>{woi?.makeLive?.manpowerAlloted}</td>
              </tr>
              <tr>
                <td>
                  Is the correct spare taken from the stores if applicable
                </td>
                <td>{woi?.makeLive?.correctSpare}</td>
              </tr>
              <tr>
                <td>
                  Is all the documents attached to this Permit sufficient to
                  complete this task
                </td>
                <td>{woi?.makeLive?.documentsAttached}</td>
              </tr>
            </>
          )}
          {cwoi?.initiate && (
            <>
              <tr>
                <td rowSpan={9}>Close Without Isolation</td>
                <td rowSpan={9}>Initiate</td>
                <td>Is the work</td>
                <td>{cwoi?.initiate?.workComplete}</td>
              </tr>
              <tr>
                <td>If Incomplete do u need to create a new CM Work Order</td>
                <td>{cwoi?.initiate?.needNew}</td>
              </tr>
              <tr>
                <td>Unit shutdown occurred while performing this Task</td>
                <td>{cwoi?.initiate?.unitShutdown}</td>
              </tr>
              <tr>
                <td>Plant shutdown occurred while performing this Task</td>
                <td>{cwoi?.initiate?.plantShutdown}</td>
              </tr>
              <tr>
                <td>Department Worked and working hours </td>
                <td>{cwoi?.initiate?.departmentWorked}</td>
              </tr>
              <tr>
                <td>Vendor Used and working hours</td>
                <td>{cwoi?.initiate?.vendorUsed}</td>
              </tr>
              <tr>
                <td>Comments History</td>
                <td>{cwoi?.initiate?.history}</td>
              </tr>
              <tr>
                <td>Equipment witness by</td>
                <td>{cwoi?.initiate?.witness}</td>
              </tr>
              <tr>
                <td>Any LTI incident while performing this Task </td>
                <td>{cwoi?.initiate?.ltiIncident}</td>
              </tr>
            </>
          )}
          {cwoi?.verify && (
            <>
              <tr>
                <td rowSpan={2}>Close Without Isolation</td>
                <td rowSpan={2}>Verify</td>
                <td>Is the task completed Successfully</td>
                <td>{cwoi?.verify?.taskCompleted}</td>
              </tr>
              <tr>
                <td>Is closeout informed to the IM and Control Room</td>
                <td>{cwoi?.verify?.closeOutInformed}</td>
              </tr>
            </>
          )}
          {cwoi?.approve && (
            <>
              <tr>
                <td rowSpan={14}>Close Without Isolation</td>
                <td rowSpan={14}>Approve</td>
                <td>Is the work Completed</td>
                <td>{cwoi?.approve?.workCompleted}</td>
              </tr>
              <tr>
                <td>Is the area is checked for cleanliness after work</td>
                <td>{cwoi?.approve?.cleanlinessCheck}</td>
              </tr>
              <tr>
                <td>
                  Equipment Tools are properly secured and kept in correct Place
                </td>
                <td>{cwoi?.approve?.properlySecured}</td>
              </tr>
              <tr>
                <td>Is the area cleared and is safe from any hazard</td>
                <td>{cwoi?.approve?.isSafe}</td>
              </tr>

              <tr>
                <td>Equipment properly drained / depressurised</td>
                <td>{cwoi?.approve?.properlyDrained}</td>
              </tr>

              <tr>
                <td>
                  Equipment properly steamed / purged? Done / Not Required
                </td>
                <td>{cwoi?.approve?.properlySteamed}</td>
              </tr>

              <tr>
                <td>Equipment water flushed</td>
                <td>{cwoi?.approve?.waterFlushed}</td>
              </tr>

              <tr>
                <td>
                  Checked for oil / gas trapped behind lining in equipment
                </td>
                <td>{cwoi?.approve?.oilCheck}</td>
              </tr>

              <tr>
                <td>Surrounding area checked / cleaned</td>
                <td>{cwoi?.approve?.areaChecked}</td>
              </tr>
              <tr>
                <td>Unit shutdown occurred while performing this Task</td>
                <td>{cwoi?.approve?.unitShutdown}</td>
              </tr>
              <tr>
                <td>Plant shutdown occurred while performing this Task</td>
                <td>{cwoi?.approve?.plantshutdown}</td>
              </tr>
              <tr>
                <td>Any Production loss occurred while performing this Task</td>
                <td>{cwoi?.approve?.prodLoss}</td>
              </tr>
              <tr>
                <td>Any LTI incident while performing this Task</td>
                <td>{cwoi?.approve?.ltiIncident}</td>
              </tr>
              <tr>
                <td>Comments</td>
                <td>{cwoi?.approve?.comments}</td>
              </tr>
            </>
          )}
          {cwoi?.issue && (
            <>
              <tr>
                <td rowSpan={2}>Close Without Isolation</td>
                <td rowSpan={2}>Issue</td>
                <td>Is the work completed</td>
                <td>{cwoi?.issue?.taskCompleted}</td>
              </tr>
              <tr>
                <td>Comments</td>
                <td>{cwoi?.issue?.comments}</td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
    </>
  )
}
