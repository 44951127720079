import { useMsal } from '@azure/msal-react'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import {
  Button,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane
} from 'reactstrap'
import { getAccessToken, makeAuthenticatedRequest } from '../../apiRequests'
import { UserContext } from '../../App'
import { loginRequest } from '../../authConfig'
import { prStatuses } from '../../components/constants/woStatuses'
import { incrementby1 } from '../../components/Forms/JSAForm'
import CustomLabel from '../../components/InputFields/CustomLabel'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { stock } from '../../components/serverurl'
import { SubHeader } from '../VerifyCM'
import { Link } from '../workManagement/PmTree'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

const tabs = {
  NEW_RFQ: 'NEW RFQ',
  SUBMIT_RFQ: 'SUBMITTED RFQ',
  ACCEPT_PO: 'ACCEPT PO',
  ACCEPTED_PO: 'ACCEPTED PO',
  INVOICE_REQUESTS: 'INVOICE REQUESTS'
}

export const QuotedPRTable = ({
  data,
  isLoading,
  isRefetching,
  vendorId
}: {
  data: any
  isLoading: boolean
  isRefetching: boolean
  vendorId: any
}) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Spare Part Number</th>
            <th>Spare Part Description</th>
            <th>Gross Price</th>
            <th>Promised Date</th>
          </tr>
        </thead>
        <tbody>
          <>
            {isLoading || isRefetching ? (
              <tr>
                <td colSpan={12}>
                  <Row
                    style={{
                      height: 500,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Spinner />
                  </Row>
                </td>
              </tr>
            ) : (
              data?.length > 0 &&
              data?.map((w: any, index: number) => (
                <React.Fragment>
                  <tr key={w?._id}>
                    <th colSpan={12}>{w?.number}</th>
                  </tr>
                  {w?.vendorQuotations
                    ?.filter(
                      (quotation: any) => quotation?.vendor === vendorId
                    )?.[0]
                    ?.quotation?.map((mr: any, index: any) => (
                      <tr key={`${index}-${mr['Spare Part Number']}`}>
                        <td>{incrementby1(index)}</td>
                        <td>{mr?.spare?.['Spare Part Number']}</td>
                        <td>{mr?.spare?.['Spare Part Description']}</td>
                        <td>{mr?.['grossPrice']}</td>
                        <td>{getReadableTime(mr['promisedDate'])}</td>
                      </tr>
                    ))}
                </React.Fragment>
              ))
            )}
          </>
        </tbody>
      </Table>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Old Quotations Found
          </p>
        </Row>
      )}
    </>
  )
}

export const AcceptedPOTable = ({
  data,
  isLoading,
  isRefetching
}: {
  data: any
  isLoading?: boolean
  isRefetching?: boolean
}) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Spare Part Number</th>
            <th>Spare Part Description</th>
            <th>Quantity</th>
            <th>Gross Price</th>
            <th>Promised Date</th>
          </tr>
        </thead>
        <tbody>
          <>
            {isLoading || isRefetching ? (
              <tr>
                <td colSpan={12}>
                  <Row
                    style={{
                      height: 500,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Spinner />
                  </Row>
                </td>
              </tr>
            ) : (
              data?.length > 0 &&
              data?.map((w: any, index: number) => (
                <React.Fragment>
                  <tr key={w?._id}>
                    <th colSpan={12}>
                      {w?.number?.replace('PR', 'PO')}-{w?.tagDescription}
                      <br />
                      Order Value: {w?.approvedVendor?.total}
                    </th>
                  </tr>
                  {w?.approvedVendor?.materialRequest?.map(
                    (mr: any, index: any) => (
                      <tr key={`${index}-${mr['Spare Part Number']}`}>
                        <td>{incrementby1(index)}</td>
                        <td>{mr?.['Spare Part Number']}</td>
                        <td>{mr?.['Spare Part Description']}</td>
                        <td>{mr?.['quantityRequested']}</td>
                        <td>{mr?.['grossPrice']}</td>
                        <td>{getReadableTime(mr?.['promisedDate'])}</td>
                      </tr>
                    )
                  )}
                </React.Fragment>
              ))
            )}
          </>
        </tbody>
      </Table>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Old Quotations Found
          </p>
        </Row>
      )}
    </>
  )
}

export const JobOrdersTable = ({
  data,
  isLoading,
  isRefetching,
  refetch,
  clickable
}: {
  data: any
  isLoading?: boolean
  isRefetching?: boolean
  refetch: any
  clickable: any
}) => {
  const { accounts, instance } = useMsal()
  const [close, setClose]: any = useState(false)
  const [id, setId]: any = useState()
  const [attachment, setAttachment]: any = useState()

  const handleFileUpload = (event: any) => {
    setAttachment(event.target.files)
  }

  return (
    <>
      <>
        <Table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>JOB Number</th>
              <th>TO Number</th>
              <th>TO Status</th>
              <th>Vendor Name</th>
              <th>Warehouse Location</th>
              <th>Created on</th>
            </tr>
          </thead>
          <tbody>
            <>
              {isLoading || isRefetching ? (
                <tr>
                  <td colSpan={12}>
                    <Row
                      style={{
                        height: 500,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Spinner />
                    </Row>
                  </td>
                </tr>
              ) : (
                data?.length > 0 &&
                data?.map((w: any, index: number) => (
                  <React.Fragment key={w?._id}>
                    <tr key={w?._id}>
                      <td>{incrementby1(index)}</td>
                      <td>
                        {clickable ? (
                          <Link
                            onClick={() => {
                              setClose(true)
                              setId(w?._id)
                            }}
                          >
                            {w?.number}
                          </Link>
                        ) : (
                          w?.number
                        )}
                      </td>
                      <td>{w?.transportOrder?.number}</td>
                      <td>{prStatuses[w?.transportOrder?.status]}</td>
                      <td>{w?.transportOrder?.vendorName}</td>
                      <td>{w?.transportOrder?.wareHouseLocation}</td>
                      <td>{getReadableTime(w?.transportOrder?.createdAt)}</td>
                    </tr>
                    {w?.transportOrder?.poList?.map((po: any) => (
                      <React.Fragment key={po?.number}>
                        <tr>
                          <td></td>
                          <th colSpan={12}>
                            {po?.number?.replace('PR', 'PO')}
                          </th>
                        </tr>
                        {po?.materialRequest?.map((spare: any, index: any) => (
                          <tr key={`${index}-${spare['Spare Part Number']}`}>
                            <td></td>
                            <td>{spare['Spare Part Number']}</td>
                            <td>{spare?.quantityRequested}</td>
                            <td colSpan={12}></td>
                          </tr>
                        ))}
                        <tr></tr>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))
              )}
            </>
          </tbody>
        </Table>
        <ModalFooter>
          <Button color="warning">Print</Button>
        </ModalFooter>
        <SubmitModalWithForm
          isOpen={close}
          onClose={(val: any) => {
            setClose(false)
            // val && navigate('/purchase/dashboard')
          }}
          form={
            <div>
              {/* <Row>
                <CustomLabel label="Upload Invoice" />
                <input
                  type="file"
                  name="attachment"
                  accept="application/pdf, image/*"
                  onChange={(e: any) => setInvoice(e?.target?.files)}
                  multiple
                />
              </Row> */}
              <Row>
                <CustomLabel label="Upload Attachments" />
                <input
                  type="file"
                  name="attachment"
                  accept="application/pdf, image/*"
                  onChange={handleFileUpload}
                  multiple
                />
              </Row>
            </div>
          }
          onSubmit={async (e: any) => {
            e.preventDefault()
            const token = await getAccessToken(loginRequest, accounts, instance)

            const formData: any = new FormData()

            if (attachment) {
              for (let i = 0; i < attachment.length; i++) {
                formData.append('attachments', attachment[i])
              }
            }
            try {
              const response = await axios.patch(
                `${stock}/inventory/vendorOrders/${id}`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                  }
                }
              )

              if (response?.data.status === 'success') {
                await refetch()
              }

              return response?.data?.status
            } catch (error) {}
          }}
          size="xs"
          header="Close Job Order"
        />
      </>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Data Found
          </p>
        </Row>
      )}
    </>
  )
}

const PRTable = ({
  data,
  isLoading,
  isRefetching,
  page,
  setPage,
  url,
  po
}: {
  data: any
  isLoading: boolean
  isRefetching: boolean
  page: any
  setPage: any
  url: string
  po: boolean
}) => {
  const navigate = useNavigate()
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Line Code</th>
            <th>Number</th>
            <th>Status</th>
            <th>Created on</th>
          </tr>
        </thead>
        <tbody>
          <>
            {isLoading || isRefetching ? (
              <tr>
                <td colSpan={12}>
                  <Row
                    style={{
                      height: 500,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Spinner />
                  </Row>
                </td>
              </tr>
            ) : (
              data?.length > 0 &&
              data?.map((w: any, index: number) => (
                <tr key={w?._id}>
                  <td>{incrementby1(page * 20 + index)}</td>
                  <td>
                    <Link onClick={() => navigate(`${url}/${w?._id}`)}>
                      {w?.number}
                    </Link>
                  </td>
                  <td>{prStatuses[w?.status] || w?.status}</td>
                  <td>{moment(w?.createdAt).format('DD/MM/YYYY')}</td>
                </tr>
              ))
            )}
          </>
        </tbody>
      </Table>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Purchase Requests Found
          </p>
        </Row>
      )}
    </>
  )
}

export default function VendorView() {
  const [active, setActive]: any = useState(tabs.NEW_RFQ)
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)

  const fetchPrs = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/inventory/vendor?id=${userData?.givenName}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, isRefetching }: any = useQuery('VendorPRS', fetchPrs)

  const fetchIssuedOrders = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/inventory/vendor/issued?id=${userData?.givenName}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const issuedOrders: any = useQuery('IssuedVendorOrders', fetchIssuedOrders)

  console.log(issuedOrders?.data?.prs)

  const issuedPrs = issuedOrders?.data?.prs

  const fetchAcceptedOrders = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/inventory/vendor/accepted?id=${userData?.givenName}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const acceptedQuery: any = useQuery(
    'fetchAcceptedOrdersVendor',
    fetchAcceptedOrders
  )

  const fetchVendorOrders = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/inventory/vendorOrders?name=${userData?.givenName}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const voQuery: any = useQuery('vendorOrderQuery', fetchVendorOrders)
  const voData = voQuery?.data?.voDetails
  const acceptedPrs = acceptedQuery?.data?.prs
  const prs = data?.prs
  const newprs = prs?.filter(
    (pr: any) =>
      pr?.vendors?.materialRequest?.length === 0 ||
      pr?.vendors?.materialRequest === undefined
  )

  return (
    <ProtectedLayout title={`VENDOR DASHBOARD - ${userData?.givenName}`}>
      <Row style={{ margin: 10 }}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={active === tabs.NEW_RFQ ? 'active' : ''}
              onClick={() => setActive(tabs.NEW_RFQ)}
            >
              {tabs?.NEW_RFQ}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === tabs.SUBMIT_RFQ ? 'active' : ''}
              onClick={() => setActive(tabs.SUBMIT_RFQ)}
            >
              {tabs?.SUBMIT_RFQ}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === tabs.ACCEPT_PO ? 'active' : ''}
              onClick={() => setActive(tabs.ACCEPT_PO)}
            >
              {tabs?.ACCEPT_PO}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === tabs.ACCEPTED_PO ? 'active' : ''}
              onClick={() => setActive(tabs.ACCEPTED_PO)}
            >
              {tabs?.ACCEPTED_PO}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === tabs.INVOICE_REQUESTS ? 'active' : ''}
              onClick={() => setActive(tabs.INVOICE_REQUESTS)}
            >
              {tabs?.INVOICE_REQUESTS}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={active}>
          <TabPane tabId={tabs.NEW_RFQ}>
            <SubHeader header={tabs.NEW_RFQ} permitColor="#FFD580" />
            <PRTable
              data={newprs}
              page={0}
              isLoading={isLoading}
              isRefetching={isRefetching}
              setPage={() => {}}
              url={'/vendor/newRfq'}
              po={false}
            />
          </TabPane>
          <TabPane tabId={tabs.SUBMIT_RFQ}>
            <SubHeader header={tabs.SUBMIT_RFQ} permitColor="#FFD580" />
            <QuotedPRTable
              data={data?.populatedQuotations}
              isLoading={isLoading}
              isRefetching={isRefetching}
              vendorId={data?.vendor?._id}
            />
          </TabPane>
          <TabPane tabId={tabs.ACCEPT_PO}>
            <SubHeader header={tabs.ACCEPT_PO} permitColor="#FFD580" />
            <PRTable
              data={issuedPrs}
              page={0}
              isLoading={issuedOrders?.isLoading}
              isRefetching={issuedOrders?.isRefetching}
              setPage={() => {}}
              url={'/vendor/acceptPO'}
              po={false}
            />
          </TabPane>
          <TabPane tabId={tabs.ACCEPTED_PO}>
            <SubHeader header={tabs.ACCEPTED_PO} permitColor="#FFD580" />
            <AcceptedPOTable
              data={acceptedPrs}
              isLoading={isLoading}
              isRefetching={isRefetching}
            />
          </TabPane>
          <TabPane tabId={tabs.INVOICE_REQUESTS}>
            <SubHeader header={tabs.INVOICE_REQUESTS} permitColor="#FFD580" />
            <JobOrdersTable
              data={voData?.filter(
                (v: any) =>
                  v?.status === 'jobOrderCreated' && v?.number?.includes('VO')
              )}
              isLoading={voQuery?.isLoading}
              isRefetching={voQuery?.isRefetching}
              refetch={voQuery?.refetch}
              clickable={true}
            />
          </TabPane>
        </TabContent>
      </Row>
    </ProtectedLayout>
  )
}
