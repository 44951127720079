import moment from 'moment'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import {
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane
} from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import React, { useState } from 'react'

import CustomLabel from '../../components/InputFields/CustomLabel'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { financeAPI } from '../../components/serverurl'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { SubHeader } from '../VerifyCM'

const titles: any = {
  unit1: 'Unit 1',
  unit2: 'Unit 2',
  unit3: 'Unit 3',
  unit4: 'Unit 4',
  project: 'Project'
}

const TABS = {
  INCOME: 'INCOME',
  FORECAST: 'FORECAST',
  PAYABLES: 'ACC. PAYABLES',
  RECEIVABLES: 'ACC. RECEIVEABLES',
  BUDGET: 'BUDGET',
  LOGS: 'LOGS',
  ANALYTICS: 'ANALYTICS'
}

const LOGS: any = {
  unit1: '65f817e0e4b848fb074094fe',
  unit2: '65f81820e4b848fb074094ff',
  unit3: '65f81829e4b848fb07409500',
  unit4: '65f81830e4b848fb07409501',
  project: '65f81837e4b848fb07409502'
}

const headers = (
  <tr style={{ backgroundColor: 'orange', position: 'sticky' }}>
    <th>S.No</th>
    <th>Cost Center</th>
    <th>Description</th>
    <th>Jan 24</th>
    <th>Feb 24</th>
    <th>Mar 24</th>
    <th>Apr 24</th>
    <th>May 24</th>
    <th>Jun 24</th>
    <th>Jul 24</th>
    <th>Aug 24</th>
    <th>Sep 24</th>
    <th>Oct 24</th>
    <th>Nov 24</th>
    <th>Dec 24</th>
    <th>Jan 25</th>
    <th>Feb 25</th>
    <th>Mar 25</th>
    <th>Apr 25</th>
    <th>May 25</th>
    <th>Jun 25</th>
    <th>Jul 25</th>
    <th>Aug 25</th>
    <th>Sep 25</th>
    <th>Oct 25</th>
    <th>Nov 25</th>
    <th>Dec 25</th>
  </tr>
)

const months = [
  'jan24',
  'feb24',
  'mar24',
  'apr24',
  'may24',
  'jun24',
  'jul24',
  'aug24',
  'sep24',
  'oct24',
  'nov24',
  'dec24',
  'jan25',
  'feb25',
  'mar25',
  'apr25',
  'may25',
  'jun25',
  'jul25',
  'aug25',
  'sep25',
  'oct25',
  'nov25',
  'dec25'
]

const style: any = {
  padding: 0,
  verticalAlign: 'middle',
  textAlign: 'center'
}
export const TD = ({ children, onClick, textAlign, marginLeft }: any) => (
  <td
    style={{
      ...style,
      cursor: onClick ? 'pointer' : 'default',
      textAlign: textAlign || 'center',
      paddingLeft: 5,
      paddingRight: 5
    }}
    onClick={() => {
      onClick && onClick()
    }}
  >
    {children}
  </td>
)

const FinanceTable = ({ data, refetch }: { data: any; refetch: any }) => {
  const [update, setUpdate]: any = useState()
  const [modal, setModal]: any = useState()
  const [newValue, setNewValue]: any = useState()

  const { accounts, instance } = useMsal()

  const getCatData = (low: any, up: any) =>
    data?.filter(({ costCenter }: any) => costCenter >= low && costCenter <= up)

  const classC = getCatData(300, 320)
  const portCharges = getCatData(400, 400)
  const handling = getCatData(700, 710)
  const drilling = getCatData(10, 19)
  const mainProcess = getCatData(20, 29)
  const exportHandling = getCatData(30, 39)
  const process = getCatData(40, 69)
  const satefySystems = getCatData(80, 88)
  const electrical = getCatData(70, 79)
  const structural = getCatData(90, 99)
  const stores = getCatData(800, 800)
  const crewCost = getCatData(510, 530)
  const crewNational = getCatData(540, 560)
  const onShore = getCatData(570, 610)
  const income = getCatData(1000, 1400)

  const renderData = (d: any) => {
    return (
      <>
        {d?.map((f: any, index: any) => (
          <tr key={`${f?.constCenter}-${index}`}>
            <TD>{incrementby1(index)}</TD>
            <TD>{f?.costCenter}</TD>
            <TD textAlign="left" marginLeft={15}>
              {f?.detail}
            </TD>
            {months?.map((m: any) => (
              <TD
                onClick={() => {
                  setUpdate({
                    unit: f?.unit,
                    costCenter: f?.costCenter,
                    type: f?.type,
                    subCatogory: f?.detail,
                    key: m,
                    currentValue: f?.[m],
                    id: f?._id
                  })
                  setModal(true)
                }}
                key={`${m}-${f?.costCenter}-${index}`}
              >
                {f?.[m]}
              </TD>
            ))}
          </tr>
        ))}
      </>
    )
  }

  const renderSum = (header: any, cdata: any) => {
    const getSum = (key: any) =>
      cdata?.reduce((sum: any, o: any) => sum + parseInt(o?.[key]) || 0, 0)

    return (
      <>
        <tr style={{ backgroundColor: '#abf7b1' }}>
          <th></th>
          <th></th>
          <th colSpan={1}>{header}</th>
          {months?.map((m: any) => <TD key={m}>{getSum(m)}</TD>)}
        </tr>
        {renderData(cdata)}
      </>
    )
  }

  return (
    <>
      <p>* Amount in 1K USD</p>
      <Table
        bordered
        style={{ borderColor: '#000', textAlign: 'center', fontSize: 14 }}
      >
        <thead>{headers}</thead>
        <tbody>
          {crewCost?.length > 0 && renderSum('Crew Cost Expat', crewCost)}
          {crewNational?.length > 0 &&
            renderSum('Crew Cost National', crewNational)}
          {onShore?.length > 0 && renderSum('Onshore Office', onShore)}
          {portCharges?.length > 0 && renderSum('Port Charges', portCharges)}
          {classC?.length > 0 && renderSum('Class', classC)}
          {handling?.length > 0 && renderSum('Handling', handling)}
          {drilling?.length > 0 &&
            renderSum(
              '10-19 Drilling, Well and Subsea related Systems',
              drilling
            )}
          {mainProcess?.length > 0 &&
            renderSum('20-29 Main process systems', mainProcess)}
          {exportHandling?.length > 0 &&
            renderSum('30-39 Export and byproduct handling', exportHandling)}
          {process?.length > 0 &&
            renderSum('40-69 Process support and utility systems', process)}
          {satefySystems?.length > 0 &&
            renderSum('70-79 Safety systems', satefySystems)}
          {electrical?.length > 0 && renderSum('80-89 Electrical', electrical)}
          {structural?.length > 0 &&
            renderSum(
              '90-99 Structural, civil, Marine and Architectural systems',
              structural
            )}
          {stores?.length > 0 && renderSum('Stores', stores)}
          {income?.length > 0 && renderSum('Income', income)}
        </tbody>
      </Table>
      <SubmitModalWithForm
        isOpen={modal}
        onClose={(val: any) => {
          setModal(false)
          // val && navigate('/warehouse/materialsReceipts')
        }}
        form={
          <div>
            <p>Cost Center : {update?.costCenter}</p>
            <p>Description : {update?.subCatogory}</p>
            <p>Month : {update?.key}</p>
            <p>Current Value: {update?.currentValue}</p>
            <CustomLabel label="Enter Latest Value" />
            <Input
              type="text"
              onChange={(e) => setNewValue(parseInt(e?.target?.value))}
            />
          </div>
        }
        onSubmit={async (e: any) => {
          e.preventDefault()
          const body: any = {
            ...update,
            newValue
          }

          try {
            const res = await makeAuthenticatedRequest(
              `${financeAPI}/${update?.id}`,
              'PATCH',
              body,
              accounts,
              instance
            )

            if (res?.status === 'success') {
              await refetch()
            }

            return res.status
          } catch (error) {}
        }}
        size="lg"
        header={`Update ${update?.subCatogory} for ${update?.key} `}
      />
    </>
  )
}

const ForecastTable = ({ data }: { data: any }) => {
  const forecastData = data?.map((d: any) => ({
    costCenter: d?._id?.costCenter,
    detail: d?._id?.detail,
    type: d?._id?.type,
    forecast: d?.forecast,
    tillToday: d?.tillToday,
    estimated: d?.estimated,
    remainingBudget: parseInt(d?.tillToday) - parseInt(d?.forecast),
    estimatedBudget: parseInt(d?.tillToday) + parseInt(d?.estimated)
  }))

  const getCatData = (low: any, up: any) =>
    forecastData?.filter(
      ({ costCenter }: any) => costCenter >= low && costCenter <= up
    )

  const getSum = (cdata: any, key: any) =>
    cdata?.reduce((sum: any, o: any) => sum + parseInt(o?.[key]) || 0, 0)

  const renderData = (header: any, catData: any) => (
    <>
      <tr style={{ backgroundColor: '#abf7b1' }}>
        <TD></TD>
        <TD></TD>
        <TD>{header}</TD>
        <TD>{getSum(catData, 'forecast')}</TD>
        <TD>{getSum(catData, 'tillToday')}</TD>
        <TD>{getSum(catData, 'remainingBudget')}</TD>
        <TD>{getSum(catData, 'estimatedBudget')}</TD>
      </tr>
      {catData?.map((c: any, index: any) => (
        <React.Fragment key={index}>
          <tr>
            <TD>{incrementby1(index)}</TD>
            <TD>{c?.costCenter}</TD>
            <TD textAlign="left">{c?.detail}</TD>
            <TD>{c?.forecast}</TD>
            <TD>{c?.tillToday}</TD>
            <TD>{c?.remainingBudget}</TD>
            <TD>{c?.estimatedBudget}</TD>
          </tr>
        </React.Fragment>
      ))}
    </>
  )

  const classC = getCatData(300, 320)
  const portCharges = getCatData(400, 400)
  const handling = getCatData(700, 710)
  const drilling = getCatData(10, 19)
  const mainProcess = getCatData(20, 29)
  const exportHandling = getCatData(30, 39)
  const process = getCatData(40, 69)
  const satefySystems = getCatData(80, 88)
  const electrical = getCatData(70, 79)
  const structural = getCatData(90, 99)
  const stores = getCatData(800, 800)
  const crewCost = getCatData(510, 530)
  // const crewNational = getCatData(540, 560)
  // const onShore = getCatData(570, 610)
  // const income = getCatData(1000, 1400)

  return (
    <>
      <p>* Budget in 1K USD</p>
      <Table
        bordered
        style={{ borderColor: '#000', textAlign: 'center', fontSize: 14 }}
      >
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            <th>S.No</th>
            <th>Cost Center</th>
            <th>Detail</th>
            <th>Actual till today</th>
            <th>Budget</th>
            <th>Remaining Budget</th>
            <th>Budget Forecast</th>
          </tr>
        </thead>
        <tbody>
          {renderData('Class', classC)}
          {renderData('Port Charges', portCharges)}
          {renderData('Port Charges', handling)}
          {renderData(
            '10-19 Drilling, Well and Subsea related Systems',
            drilling
          )}
          {renderData('20-29 Main process systems', mainProcess)}
          {renderData('30-39 Export and byproduct handling', exportHandling)}
          {renderData('40-69 Process support and utility systems', process)}
          {renderData('70-79 Safety systems', satefySystems)}
          {renderData('80-89 Electrical', electrical)}
          {renderData(
            '90-99 Structural, civil, Marine and Architectural systems',
            structural
          )}
          {renderData('Stores', stores)}
          {renderData('Income', crewCost)}
          {/* {renderData('Port Charges', crewNational)}
          {renderData('Port Charges', onShore)}
          {renderData('Port Charges', income)} */}
        </tbody>
      </Table>
    </>
  )
}

const Logs = ({ unit, data }: { unit: any; data: any }) => {
  return (
    <>
      <p>* Amount in 1K USD</p>
      <Table bordered striped style={{ borderColor: '#000', fontSize: 14 }}>
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            <th>S.No</th>
            <th>Type</th>
            <th>Cost Center</th>
            <th>Detail</th>
            <th>Month</th>
            <th>Modified</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Department</th>
            <th>Updated On</th>
          </tr>
        </thead>
        <tbody>
          {data?.history?.map((history: any, index: number) => (
            <React.Fragment key={history?._id}>
              <tr>
                <td>{incrementby1(index)}</td>
                <td>{history?.type}</td>
                <td>{history?.costCenter}</td>
                <td>{history?.detail}</td>
                <td>{history?.month}</td>
                <td>{history?.updatedTo}</td>
                <td>{history?.userDetails?.givenName}</td>
                <td>{history?.userDetails?.jobTitle}</td>
                <td>{history?.userDetails?.department}</td>
                <td>
                  {moment(history?.updatedAt).format('DD-MM-YYYY, hh:mm:ss A')}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </>
  )
}

const Analytics = ({
  payables,
  receivables
}: {
  payables: any
  receivables: any
}) => {
  const getCatData = (low: any, up: any, entries: any) =>
    entries?.filter(
      ({ costCenter }: any) => costCenter >= low && costCenter <= up
    )

  const drillingPay = getCatData(10, 19, payables)
  const drillingRec = getCatData(10, 19, receivables)

  const getSum = (key: any, cdata: any) =>
    cdata?.reduce((sum: any, o: any) => sum + parseInt(o?.[key]) || 0, 0)

  const analyticsData = months?.map((m: any) => ({
    name: m,
    payables: getSum(m, drillingPay),
    actual: getSum(m, drillingRec)
  }))

  // const data = [
  //   {
  //     name: 'January',
  //     uv: 4000,
  //     pv: 2400
  //   },
  //   {
  //     name: 'February',
  //     uv: 3000,
  //     pv: 1398
  //   },
  //   {
  //     name: 'March',
  //     uv: 2000,
  //     pv: 9800
  //   },
  //   {
  //     name: 'April',
  //     uv: 2780,
  //     pv: 3908
  //   },
  //   {
  //     name: 'May',
  //     uv: 1890,
  //     pv: 4800
  //   },
  //   {
  //     name: 'June',
  //     uv: 2390,
  //     pv: 3800
  //   },
  //   {
  //     name: 'July',
  //     uv: 3490,
  //     pv: 4300
  //   },
  //   {
  //     name: 'August',
  //     uv: 3490,
  //     pv: 4300
  //   },
  //   {
  //     name: 'September',
  //     uv: 3490,
  //     pv: 4300
  //   },
  //   {
  //     name: 'October',
  //     uv: 3490,
  //     pv: 4300
  //   },
  //   {
  //     name: 'November',
  //     uv: 3490,
  //     pv: 4300
  //   },
  //   {
  //     name: 'December',
  //     uv: 3490,
  //     pv: 4300
  //   }
  // ]
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        marginTop: '10vh'
      }}
    >
      <BarChart
        width={1300}
        height={500}
        data={analyticsData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
        <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
        <Tooltip />
        <Legend />
        <Bar yAxisId="left" dataKey="payables" fill="#8884d8" />
        <Bar yAxisId="right" dataKey="actual" fill="#82ca9d" />
      </BarChart>
    </div>
  )
}

export default function Payables({ unit }: { unit: any }) {
  const { accounts, instance } = useMsal()
  const [active, setActive]: any = useState(TABS.PAYABLES)

  const fetchFinance = async () => {
    const response = await makeAuthenticatedRequest(
      `${financeAPI}?unit=${unit}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('finance', fetchFinance)

  const fetchLogs = async () => {
    const response = await makeAuthenticatedRequest(
      `${financeAPI}/logs?unit=${LOGS[unit]}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const logsQuery: any = useQuery('logs', fetchLogs)

  const refetchAll = async () => {
    await refetch()
    await logsQuery.refetch()
  }

  return (
    <ProtectedLayout onBack="/cmms/finance" title={titles[unit]}>
      <Row style={{ margin: 15 }}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={active === TABS.FORECAST ? 'active' : ''}
              onClick={() => setActive(TABS.FORECAST)}
            >
              {TABS?.FORECAST}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === TABS.PAYABLES ? 'active' : ''}
              onClick={() => setActive(TABS.PAYABLES)}
            >
              {TABS?.PAYABLES}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === TABS.RECEIVABLES ? 'active' : ''}
              onClick={() => setActive(TABS.RECEIVABLES)}
            >
              {TABS?.RECEIVABLES}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === 'BUDGET' ? 'active' : ''}
              onClick={() => setActive('BUDGET')}
            >
              BUDGET
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === TABS.LOGS ? 'active' : ''}
              onClick={() => setActive(TABS.LOGS)}
            >
              {TABS?.LOGS}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === TABS.INCOME ? 'active' : ''}
              onClick={() => setActive(TABS.INCOME)}
            >
              {TABS?.INCOME}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === TABS.ANALYTICS ? 'active' : ''}
              onClick={() => setActive(TABS.ANALYTICS)}
            >
              {TABS?.ANALYTICS}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={active}>
          <TabPane tabId={TABS.FORECAST}>
            <SubHeader
              header={`${titles[unit]} -${TABS.FORECAST}`}
              permitColor="#FFD580"
            />
            <ForecastTable data={data?.foreCast} />
          </TabPane>
          <TabPane tabId={TABS.PAYABLES}>
            <SubHeader
              header={`${titles[unit]} -${TABS.PAYABLES}`}
              permitColor="#FFD580"
            />
            <FinanceTable data={data?.payables} refetch={refetchAll} />
          </TabPane>
          <TabPane tabId={TABS.RECEIVABLES}>
            <SubHeader
              header={`${titles[unit]}-${TABS.RECEIVABLES}`}
              permitColor="#FFD580"
            />
            <FinanceTable data={data?.receivables} refetch={refetchAll} />
          </TabPane>
          <TabPane tabId={'BUDGET'}>
            <SubHeader
              header={`${titles[unit]}-BUDGET`}
              permitColor="#FFD580"
            />
            <FinanceTable data={data?.actual} refetch={refetchAll} />
          </TabPane>
          <TabPane tabId={TABS.LOGS}>
            <SubHeader
              header={`${titles[unit]}-${TABS.LOGS}`}
              permitColor="#FFD580"
            />
            <Logs unit={unit} data={logsQuery?.data} />
          </TabPane>
          <TabPane tabId={TABS.INCOME}>
            <SubHeader
              header={`${titles[unit]}-${TABS.INCOME}`}
              permitColor="#FFD580"
            />
            <FinanceTable data={data?.income} refetch={refetchAll} />
          </TabPane>
          <TabPane tabId={TABS.ANALYTICS}>
            <SubHeader
              header={`${titles[unit]}-${TABS.ANALYTICS}`}
              permitColor="#FFD580"
            />
            <Analytics payables={data?.payables} receivables={data?.actual} />
            {/* <FinanceTable data={data?.income} refetch={refetchAll} /> */}
          </TabPane>
        </TabContent>
      </Row>
    </ProtectedLayout>
  )
}
