import { useMsal } from '@azure/msal-react'
import { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Row, Table } from 'reactstrap'

import { makeAuthenticatedRequest } from '../../apiRequests'
import JSATemplateView from '../Forms/JSATemplateView'
import QRATemplateView from '../Forms/QRATemplateView'
import ProtectedLayout from '../Layout/ProtectedLayout'
import Loader from '../Loader'
import SubmitModalWithForm from '../Modals/SubmitModalWithForm'
import Spacer from '../Spacer'
import { riskAssessmentAPI } from '../serverurl'
import ModalTitle from '../ModalTitle'
import { UserContext } from '../../App'
import moment from 'moment'

function checkIfJSA(templateNumber: String) {
  return Boolean(templateNumber?.match(/JSA/))
}

export default function ViewRiskTemplate() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const navigate = useNavigate()

  const [rejectModal, setRejectModal]: any = useState(false)

  const fetchRiskAssesmentTemplate = async () => {
    const response = await makeAuthenticatedRequest(
      `${riskAssessmentAPI}/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const handleHide = async (e: any) => {
    e.preventDefault()

    const response = await makeAuthenticatedRequest(
      `${riskAssessmentAPI}/${id}`,
      'DELETE',
      null,
      accounts,
      instance
    )

    return response.status
  }

  const { data, isLoading }: any = useQuery(
    'riskAssessmentTempalte',
    fetchRiskAssesmentTemplate
  )

  const isJSA = checkIfJSA(data?.data?.templateNumber)

  const ViewTemplate = () => {
    return (
      <Row style={{ margin: 10 }}>
        <ModalTitle
          title={isJSA ? 'Job Safety Analysis' : 'Qualitative Risk Assessment'}
        />
        {isJSA ? (
          <JSATemplateView risk={data?.data} showSelect={false} />
        ) : (
          <QRATemplateView template={data?.data} showSelect={false} />
        )}
        {data?.history && (
          <>
            <ModalTitle title={'Approval History'} height={50} />
            <Row>
              <Table bordered style={{ borderColor: '#000', marginLeft: 10 }}>
                <thead>
                  <tr style={{ backgroundColor: 'orange' }}>
                    <th>Sl.No</th>
                    <th>Status</th>
                    <th>Name</th>
                    <th>Department</th>
                    <th>Designation</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Created</td>
                    <td>{data?.history?.createdBy?.givenName}</td>
                    <td>{data?.history?.createdBy?.department}</td>
                    <td>{data?.history?.createdBy?.jobTitle}</td>
                    <td>
                      {moment(data?.data?.createdAt).format(
                        'DD-MM-YYYY hh:mm A'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Approved</td>
                    <td>{data?.history?.approvedBy?.givenName}</td>
                    <td>{data?.history?.approvedBy?.department}</td>
                    <td>{data?.history?.approvedBy?.jobTitle}</td>
                    <td>
                      {moment(data?.data?.approvedAt).format(
                        'DD-MM-YYYY hh:mm A'
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </>
        )}
        {userData?.department === 'HSE' && (
          <>
            <Row style={{ margin: 0 }}>
              <Button color="danger" onClick={() => setRejectModal(true)}>
                Delete This Template
              </Button>
            </Row>
          </>
        )}
        {rejectModal && (
          <SubmitModalWithForm
            isOpen={rejectModal}
            onClose={(val: any) => {
              setRejectModal(false)
              val && navigate('/eptw/riskAssessmentTemplates')
            }}
            onSubmit={handleHide}
            size="xs"
            header="Hide Template"
          />
        )}
      </Row>
    )
  }

  return (
    <ProtectedLayout onBack="/eptw/riskAssessmentTemplates">
      <Spacer height={20} />
      {isLoading ? <Loader /> : <ViewTemplate />}
    </ProtectedLayout>
  )
}
