import { Row } from 'reactstrap'
import AshokLeylandLayout from './AshokLeylandLayout'
import { IconCard } from '../components/Dashboard/IconCard'

export default function AlTrainingActions() {
  const flows: any = [
    {
      name: 'Training Data',
      nav: '/ashokleyland/training/trainingData',
      src: require('../images/Icons/TRAINING_DATA.png')
    },
    {
      name: 'Pep Talk Awareness',
      nav: '/ashokleyland/training/peptalkawareness',
      src: require('../images/Icons/PREP_TALK_AWARENESS.png')
    },
    {
      name: 'Training List',
      nav: '/ashokleyland/training/traininglist',
      src: require('../images/Icons/TRAINING_LIST.png')
    },
    {
      name: 'Score card Dashboard',
      src: require('../images/Icons/SCORE_CARD.png'),
      nav: '/ashokleyland/training/scorecard'
    },
    {
      name: 'KPI',
      // src: require('../images/Icons/SCORE_CARD.png'),
      nav: '/ashokleyland/training/kpi'
    }
  ]

  return (
    <AshokLeylandLayout onBack="/ashokleyland" title="SAFETY EHS">
      <Row style={{ margin: 10, fontSize: 20 }}>
        {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
          <IconCard
            key={`${index}-${name}`}
            name={name}
            nav={nav}
            canView={canView}
            count={count}
            src={src}
          />
        ))}
      </Row>
    </AshokLeylandLayout>
  )
}
