import { Button, Col, Input, ModalFooter, Row } from "reactstrap";
import { useMsal } from "@azure/msal-react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import ProtectedLayout from "../../components/Layout/ProtectedLayout";
import SectionHeader from "../../components/Layout/SectionHeader";
import toNormalCase from "../../components/lib/toNormalCase";
import { Box, renderData } from "../workManagement/PmTree";
import { makeAuthenticatedRequest } from "../../apiRequests";
import { stock } from "../../components/serverurl";
import { useState } from "react";
import SubmitModalWithForm from "../../components/Modals/SubmitModalWithForm";
import { Form } from "react-final-form";
import LabeledTextInput from "../../components/InputFields/LabeledTextInput";
import CustomLabel from "../../components/InputFields/CustomLabel";

export default function ViewSpareDetails() {
  const { accounts, instance } = useMsal();
  const { id } = useParams();

  const fetchSpare = async () =>
    await makeAuthenticatedRequest(
      `${stock}/inventory/${id}`,
      "GET",
      null,
      accounts,
      instance
    );

  const { data, refetch }: any = useQuery("Spare Details", fetchSpare);
  const [submitModal, setSubmitModal]: any = useState(false);

  const spare = data?.spare;

  const generalOne: any = {
    vessel: spare?.vessel,
    "Spare Part Number": spare?.["Spare Part Number"],
    spareType: spare?.spareType,
    functionNumber: spare?.functionNumber,
    partFrequency: spare?.partFrequency,
    locationNumber: spare?.locationNumber,
    Warehouse: spare?.Warehouse,
    tier: spare?.tier,
    netWeight: spare?.netWeight,
    weightUnit: spare?.weightUnit,
    attachments: spare?.attachments,
    inventoryType: spare?.inventoryType,
    qualityApprovalDate: spare?.qualityApprovalDate,
  };

  const generalTwo: any = {
    "Tag Number": spare?.["Tag Number"],
    "Spare Part Description": spare?.["Spare Part Description"],
    "Equipment Description": spare?.["Equipment Description"],
    functionDescription: spare?.functionDescription,
    productFamily: spare?.productFamily,
    locationType: spare?.locationType,
    bay: spare?.bay,
    bin: spare?.bin,
    netVolume: spare?.netVolume,
    weight: spare?.weight,
    uom: spare?.uom,
    dimQuantity: spare?.dimQuantity,
    proposalRelease: spare?.proposalRelease,
  };

  const generalThree: any = {
    department: spare?.department,
    originalPO: spare?.originalPO,
    projectId: spare?.projectId,
    status: spare?.status,
    productCode: spare?.productCode,
    locationGroup: spare?.locationGroup,
    row: spare?.row,
    onHandQuantity: spare?.onHandQuantity,
    estEquipType: spare?.estEquipType,
    stdLotSize: spare?.stdLotSize,
    qCAnalysisID: spare?.qCAnalysisID,
    isGeneric: spare?.isGeneric,
  };

  const generalFour: any = {
    created: spare?.created,
    modified: spare?.modified,
    planningMethod: spare?.planningMethod,
    safetyStock: spare?.safetyStock,
    orderPoint: spare?.orderPoint,
    lotSize: spare?.lotSize,
    orderCoverTime: spare?.orderCoverTime,
    minLotSize: spare?.minLotSize,
    maxLotSize: spare?.maxLotSize,
    multipleLotSize: spare?.multipleLotSize,
    scrapFactor: spare?.scrapFactor,
    qCAnalystDate: spare?.qCAnalystDate,
  };

  const technicalDetails: any = {
    serialNumber: spare?.serialNumber,
    manufacturerPartNumber: spare?.manufacturerPartNumber,
    additionalTechInformation: spare?.additionalTechInformation,
    model: spare?.model,
    certificateNeeded: spare?.certificateNeeded,
    notes: spare?.notes,
    manufacturer: spare?.manufacturer,
    consumable: spare?.consumable,
    techCoordinates: spare?.techCoordinates,
    partType: spare?.partType,
    defaultSupplyType: spare?.defaultSupplyType,
    remarks: spare?.remarks,
  };

  const costingDetails: any = {
    account: spare?.account,
    priceIncludingTax: spare?.priceIncludingTax,
    buyUnit: spare?.buyUnit,
    assetClass: spare?.assetClass,
    costCenter: spare?.costCenter,
    originalQuantity: spare?.originalQuantity,
    safetyCode: spare?.safetyCode,
    priceCurrency: spare?.priceCurrency,
    currencyCode: spare?.currencyCode,
    accountGroup: spare?.accountGroup,
    taxable: spare?.taxable,
    latestPrice: spare?.latestPrice,
    latestQuantity: spare?.latestQuantity,
    averageCost: spare?.averageCost,
  };

  const contactDetails: any = {
    supplier: spare?.supplier,
    supplierContact: spare?.supplierContact,
    buyerID: spare?.buyerID,
    buyerName: spare?.buyerName,
    emailId: "sales@e73.ai",
  };

  const [formData, setFormData]: any = useState({
    maxLotSize: spare?.maxLotSize,
    minLotSize: spare?.minLotSize,
    Warehouse: spare?.Warehouse,
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <ProtectedLayout onBack="/cmms/supplychain/warehouse/stockRegister">
      <Row style={{ margin: 10 }}>
        <SectionHeader
          title={`SPARE PART DETAIL - ${spare?.["Spare Part Number"]}`}
        />
        <hr />
        <Col xs="12">
          <Box heading="General Details">
            <Row>
              <Col xs="3" style={{ borderRight: "1px solid #000" }}>
                {Object.keys(generalOne)?.map((key: any) =>
                  renderData(toNormalCase(key), generalOne[key])
                )}
              </Col>
              <Col xs="3" style={{ borderRight: "1px solid #000" }}>
                {Object.keys(generalTwo)?.map((key: any) =>
                  renderData(toNormalCase(key), generalTwo[key])
                )}
              </Col>
              <Col xs="3" style={{ borderRight: "1px solid #000" }}>
                {Object.keys(generalThree)?.map((key: any) =>
                  renderData(toNormalCase(key), generalThree[key])
                )}
              </Col>
              <Col xs="3">
                {Object.keys(generalFour)?.map((key: any) =>
                  renderData(toNormalCase(key), generalFour[key])
                )}
              </Col>
            </Row>
          </Box>
        </Col>

        <Col xs="6">
          <Box heading="Technical Details">
            {Object.keys(technicalDetails)?.map((key: any) =>
              renderData(toNormalCase(key), technicalDetails[key])
            )}
          </Box>
          <Box heading="Contact Details">
            {Object.keys(contactDetails)?.map((key: any) =>
              renderData(toNormalCase(key), contactDetails[key])
            )}
          </Box>
        </Col>
        <Col xs="6">
          <Box heading="Costing Details">
            {Object.keys(costingDetails)?.map((key: any) =>
              renderData(toNormalCase(key), costingDetails[key])
            )}
          </Box>
        </Col>
        <ModalFooter>
          <Button color="warning" onClick={() => setSubmitModal(true)}>
            Update Details
          </Button>
          {submitModal && (
            <SubmitModalWithForm
              isOpen={submitModal}
              onClose={(val: any) => {
                setSubmitModal(false);
              }}
              onSubmit={async (e: any) => {
                e?.preventDefault();
                try {
                  const res: any = await makeAuthenticatedRequest(
                    `${stock}/inventory/updateDetail/${spare?._id}`,
                    "PATCH",
                    formData,
                    accounts,
                    instance
                  );

                  if (res?.status === "success") {
                    await refetch();
                  }

                  return res?.status;
                } catch (error) {}
              }}
              header="Update Stock Item Details"
              form={
                <div>
                  <CustomLabel label="Min Lot Size" />
                  <Input
                    name="minLotSize"
                    onChange={handleInputChange}
                    defaultValue={spare?.minLotSize}
                  />
                  <CustomLabel label="Max Lot Size" />
                  <Input
                    name="maxLotSize"
                    onChange={handleInputChange}
                    defaultValue={spare?.maxLotSize}
                  />
                  <CustomLabel label="Warehouse" />
                  <Input
                    name="Warehouse"
                    onChange={handleInputChange}
                    defaultValue={spare?.Warehouse}
                  />
                </div>
              }
            />
          )}
        </ModalFooter>
      </Row>
    </ProtectedLayout>
  );
}
