import React, { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import moment from 'moment'

import Loader from '../components/Loader'
import PermitHeaderDetails from '../components/PermitHeaderDetails'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import toNormalCase from '../components/lib/toNormalCase'
import { incidentReport } from '../components/serverurl'
import { makeAuthenticatedRequest } from '../apiRequests'
import { renderArray } from './WaitingForPIC'
import { renderRow, SubHeader } from './VerifyCM'
import { ViewHistory } from './ViewCM'
import { getPermissions } from '../components/lib/firsPermissions'
import { Button, Col, ModalFooter, Row, Table } from 'reactstrap'
import FIRApprovalModal from '../components/Modals/FIRApprovalModal'
import Spacer from '../components/Spacer'
import { Box, renderData } from './workManagement/PmTree'

const hideApprove = ['moc', 'cm', 'closed', 'rejected']

const getPermitData = (stage1: any) => ({
  Originator: stage1?.originator,
  Equipment: stage1?.equipment,
  'Tag Number': stage1?.tagNumber,
  Location: stage1?.location,
  Department: stage1?.department,
  Description: stage1?.description,
  Date: moment(stage1?.date).format('DD-MM-YYYY'),
  'Incident Type': stage1?.incidentType,
  'Other Incident Type': stage1?.others_incidentType,
  'Immidiate Actioin Required': toNormalCase(stage1?.actionRequired),
  'Immidiate Action Description': stage1?.immidateDescription,
  Service: stage1?.service,
  'Production Loss': stage1?.prodLoss,
  'Injury Details': renderArray(stage1?.injuryDetails, false),
  'Other Injury Details': stage1?.others_injuryDetails,
  'Part of Body': renderArray(stage1?.partOgBody, false),
  Witnes: stage1?.witness,
  'Installation Manager': stage1?.im,
  'Area Authority': stage1?.areaAuthority,
  'Safety Officer': stage1?.safetyOfficer
})

const getFields = (data: any) => ({
  'Work Order Number': data?.report?.woNumber,
  'Incident Title': data?.report?.stage1?.incidentTitle,
  'Created On': moment(data?.report?.stage1?.createdAt).format('DD/MM/YYYY')
})

const getInjuredDetails = (stage1: any) => ({
  Name: stage1?.victimName,
  Gender: stage1?.victimGender,
  'Date of Birth': stage1?.dateofBirth,
  Age: stage1?.victimAge,
  Employeer: stage1?.victimEmployeer,
  'Job Title': stage1?.victimJobTitle,
  'Job Experience': stage1?.victimExperience,
  'Employment Type': stage1?.victimEmploymentType,
  'Duty Type': stage1?.victimDutyType
})

const getStage2Data = (stage2: any) => ({
  'Following Procedures': renderArray(stage2?.followingProcedures, false),
  'Following Procedures Others': stage2?.others_followingProcedures,
  'Use of Tools & Equipment': renderArray(stage2?.tools, false),
  'Use of Tools & Equipment Others': stage2?.others_tools,
  'Use of Protective Methods': renderArray(stage2?.protectiveMethods, false),
  'Usde of Protective Methods Others': stage2?.others_protectiveMethods,
  'Inattention/Lack of Awareness ': renderArray(stage2?.awareness, false),
  'Inattention/Lack of Awareness Others': stage2?.others_awareness,
  'Protective Systems': renderArray(stage2?.protectiveSystems, false),
  'Protective Systems Others': stage2?.others_protectiveSystems,
  'Tools, Equipment & Vehicles': renderArray(stage2?.equipmentTools, false),
  'Tools, Equipment & Vehicles Others': stage2?.others_equipmentTools,
  'Work Exposure to': renderArray(stage2?.workExposure, false),
  'Work Exposure to Others': stage2?.others_workExposure,
  'Work Place Environment/Layout': renderArray(stage2?.environment, false),
  'Work Place Environment/Layout Others': stage2?.others_environment,
  'Investigation Tier': stage2?.investigationTier,
  Priority: stage2?.assignPriority,
  'Standard Action': renderArray(stage2?.immidiateCausesSA, false),
  'Others Standard Action': stage2?.others_immidiateCausesSA,
  'Standard Condition': renderArray(stage2?.immidiateCausesSC, false),
  'Others Standard Condition': stage2?.others_immidiateCausesSC,
  'Immidiate Cause Description': stage2?.immidiateCauseDescription,
  'Personal Factors': renderArray(stage2?.personalFactors, false),
  'Others Personal Factors': stage2?.others_personalFactors,
  'Job Factors': renderArray(stage2?.jobFactors, false),
  'Others Job Factors': stage2?.others_jobFactors,
  'Basic Cause Description': stage2?.basicCauseDescription,
  'Others Observations': stage2?.otherObservations,
  'Assign Department': stage2?.assignDepartment,
  'Investigation Member': stage2?.investigationMember,
  'Start Date': moment(stage2?.startDate).format('DD-MM-YYYY'),
  'Completioon Date': moment(stage2?.completionDate).format('DD-MM-YYYY'),
  'Incident Repeatable': stage2?.incidentRepeatable
})

const getStage3Data = (stage3: any) => ({
  'Is the Incident happened in our workplace': stage3?.incidentAtWorkplace,
  'Send safety alert to all department': stage3?.stsafetyAlert,
  'Opportunity Approximate Budget Estimation': stage3?.budgetEstimation,
  'Payback returns': stage3?.paybackReturns,
  Fatigue: renderArray(stage3?.fatigue, false),
  'Diminished performance': renderArray(stage3?.dimishedPerformance, false),
  'Impairment due to drug or alcohol use': renderArray(
    stage3?.impairment,
    false
  ),
  'Job factors': renderArray(stage3?.jobFactors, false)
})

const getStage4Data = (stage4: any) => ({
  'Regulatory Authorities may conduct their own investigation':
    stage4?.regulatoryAuthorities,
  'Approximate Budget Estimation': stage4?.budgetEstimation,
  'Your Score on Production Impact': stage4?.productionImpact,
  'Your Score on Environment Impac': stage4?.environmentImpact,
  'Your Score on Human Impact': stage4?.humanImpact,
  'Your Score on Equipment Impact': stage4?.equipmentImpact,
  'Your Score on Financial Impact': stage4?.financialImpact,
  'Your Score on Company Reputation': stage4?.companyReputation
})

const getCloseDetails = (report: any) => ({
  Comments: report?.comments,
  'Corrective Plan': report?.correctiveActionPlan,
  'Root Cause Identified': report?.rootCauseIdentified
})

export const ViewDetails = ({
  header,
  data
}: {
  header: String
  data: any
}) => {
  return (
    <>
      <SubHeader header={header} />
      {Object.keys(data).map((key) => renderRow(key, data[key]))}
    </>
  )
}

const Stage4Deatils = ({ data }: any) => {
  const stage4Data: any = getStage4Data(data?.report?.stage4)
  return (
    <>
      <ViewDetails header="STAGE 4" data={stage4Data} />
      <Spacer height={20} />
      <SubHeader header="CORRECTIVE ACTIONS" />
      <Table bordered striped style={{ borderColor: '#000' }}>
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            {['Code', 'Measure', 'Person', 'Date', 'Ref.No']?.map((i: any) => (
              <th key={i}>{i}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.report?.stage4?.actions?.map((action: any, index: any) => (
            <React.Fragment key={index}>
              <tr>
                <td>{action.code}</td>
                <td>{action.correctiveAction}</td>
                <td>{action?.responsiblePerson}</td>
                <td>{action?.date}</td>
                <td>{action?.refeNo}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      <SubHeader header="INVESTIGATION DEPARTMENT" />
      <Table bordered striped style={{ borderColor: '#000' }}>
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            {['Investigator Name', 'Department']?.map((i: any) => (
              <th key={i}>{i}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.report?.stage4?.investigationTeam?.map(
            (action: any, index: any) => (
              <React.Fragment key={index}>
                <tr>
                  <td>{action?.name}</td>
                  <td>{action?.department}</td>
                </tr>
              </React.Fragment>
            )
          )}
        </tbody>
      </Table>
    </>
  )
}

export default function ViewFIR({ onBack }: any) {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const [permissions, setPermissions]: any = useState()
  const [submitModal, setSubmitModal]: any = useState(false)
  const [reject, setReject]: any = useState(false)

  const fetchReport = async () =>
    await makeAuthenticatedRequest(
      `${incidentReport}/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data, isLoading }: any = useQuery('FIR', fetchReport)

  useEffect(() => {
    if (!permissions) {
      getPermissions(accounts, instance).then((response: any) =>
        setPermissions(response)
      )
    }
  })

  if (isLoading) {
    return <Loader />
  }

  const permitData: any = getPermitData(data?.report?.stage1)
  const headerData: any = getFields(data)
  const injuredDetails: any = getInjuredDetails(data?.report?.stage1)
  const stage2Data: any = getStage2Data(data?.report?.stage2)
  const stage3Data: any = getStage3Data(data?.report?.stage3)
  const stage4Data: any = getStage4Data(data?.report?.stage4)
  const closeOutData: any = getCloseDetails(data?.report)

  const SubmitButton = ({ canView, color, text, onClick }: any) => (
    <Button
      color={color}
      style={{ marginTop: 10 }}
      onClick={onClick}
      children={text}
    />
  )

  return (
    <ProtectedLayout onBack={onBack} title="FIRST INCIDENT REPORTING SYSTEM">
      <Row style={{ margin: 15 }}>
        <PermitHeaderDetails permit={headerData} />
        <ViewHistory data={data?.history} title="INCIDENT APPROVAL HISTORY" />
        <Row>
          <Col md="6">
            <Box heading="Incident Details">
              <>
                {Object.keys(permitData)?.map(
                  (k: any) => permitData[k] && renderData(k, permitData[k])
                )}
              </>
            </Box>
            {data?.report?.stage2 && (
              <Box heading="Stage 2 Details">
                <>
                  {Object.keys(stage2Data)?.map(
                    (k: any) => stage2Data[k] && renderData(k, stage2Data[k])
                  )}
                </>
              </Box>
            )}
          </Col>
          <Col md="6">
            <Box heading="Details of Injured">
              <>
                {Object.keys(injuredDetails)?.map(
                  (k: any) =>
                    injuredDetails[k] && renderData(k, injuredDetails[k])
                )}
              </>
            </Box>
            {data?.report?.stage3 && (
              <Box heading="Stage 3 Details">
                <>
                  {Object.keys(stage3Data)?.map(
                    (k: any) => stage3Data[k] && renderData(k, stage3Data[k])
                  )}
                </>
              </Box>
            )}
            {data?.report?.stage4 && (
              <Box heading="Stage 4 Details">
                <>
                  {Object.keys(stage4Data)?.map(
                    (k: any) => stage4Data[k] && renderData(k, stage4Data[k])
                  )}
                </>
              </Box>
            )}
          </Col>
        </Row>

        {data?.report?.status === 'rejected' && (
          <>
            <SubHeader header="REJECTED" />
            {renderRow('Rejected Reason', data?.report?.rejectReason)}
          </>
        )}
        {/* {data?.report?.stage4 && <Stage4Deatils data={data} />} */}
        {data?.report?.comments && (
          <ViewDetails header="CLOSE OUT" data={closeOutData} />
        )}
        <ModalFooter>
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
            {!hideApprove?.includes(data?.report?.status) && (
              <SubmitButton
                color="warning"
                text="Approve"
                onClick={() => setSubmitModal(true)}
              />
            )}
            {data?.report?.status === 'stage2' && (
              <SubmitButton
                color="danger"
                text="Reject"
                onClick={() => {
                  setReject(true)
                  setSubmitModal(true)
                }}
              />
            )}
            {data?.report?.status === 'moc' && (
              <SubmitButton
                color="warning"
                text="Create Management of Change"
                onClick={() => {
                  localStorage.setItem(
                    'moc',
                    JSON.stringify({
                      tag: data?.report?.stage1?.tagNumber,
                      number: data?.report?.woNumber,
                      firs: data?.report?._id
                    })
                  )
                  navigate(`/firs/moc/stage1`)
                }}
              />
            )}
            {data?.report?.status === 'cm' && (
              <SubmitButton
                color="warning"
                text="Create Corrective WorkOrder"
                onClick={() => {
                  localStorage.setItem(
                    'cm',
                    JSON.stringify({
                      tag: data?.report?.stage1?.tagNumber,
                      number: data?.report?.woNumber
                    })
                  )
                  navigate('/cmms/workRequests/create')
                }}
              />
            )}
          </div>
        </ModalFooter>
        <FIRApprovalModal
          isOpen={submitModal}
          toggle={() => {
            setSubmitModal(!submitModal)
            setReject(false)
          }}
          status={data?.report?.status}
          reject={reject}
        />
      </Row>
    </ProtectedLayout>
  )
}
