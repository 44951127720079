import { Table } from 'reactstrap'
import { renderTableData } from '../ToolBoxTalkMeeting'

export default function PrintConfinedSpaceEntryTalk() {
  const Columns = ({ label }: any) => (
    <tr>
      {renderTableData(<b>{label}</b>)}
      <th />
      <th />
      <th />
      <th />
      <th />
      <th />
      <th />
      <th />
      <th />
      <th />
      <th />
    </tr>
  )
  const ReadingsTable = () => (
    <Table bordered style={{ borderColor: '#000' }}>
      <thead>
        <Columns label="Time" />
        <Columns label="Result" />
        <Columns label="Sign." />
      </thead>
    </Table>
  )

  const GasDetails = ({ serial, gas }: any) => (
    <Table bordered style={{ borderColor: '#000' }}>
      <thead>
        <tr
          style={{ backgroundColor: 'orange', WebkitPrintColorAdjust: 'exact' }}
        >
          {renderTableData(<b>{serial}</b>)}
          {renderTableData(<b>{gas}</b>, 5)}
          {renderTableData('Continous')}
          {renderTableData('Intermittent')}
          {renderTableData('Not Required')}
        </tr>
        <tr>
          {renderTableData(<></>)}
          {renderTableData(<b>{gas}</b>, 5)}
          {renderTableData(<></>, 5)}
        </tr>
      </thead>
    </Table>
  )

  const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  return (
    <>
      <Table bordered style={{ borderColor: '#000' }}>
        <thead>
          <tr>
            {renderTableData(1)}
            {renderTableData('Risk Assessment (ex.JSA) Conducted', 4)}
            {renderTableData('Yes')}
            {renderTableData('No')}
          </tr>
          <tr>
            {renderTableData(2)}
            {renderTableData(
              'Special Precautions for Confined Vessel Entry',
              6
            )}
          </tr>
          <tr>
            {renderTableData('a')}
            {renderTableData('Person on Safety Watch', 4)}
            {renderTableData('Required')}
            {renderTableData('Not Required')}
          </tr>
          <tr>
            {renderTableData('b')}
            {renderTableData(
              'Lighting: (Instrinsically safe torch / 24 V hand lamp)',
              6
            )}
          </tr>
          <tr>
            {renderTableData('c')}
            {renderTableData(
              'Lighting: (Instrinsically safe torch / 24 V hand lamp)',
              4
            )}
            {renderTableData('Required')}
            {renderTableData('Not Required')}
          </tr>
          <tr>
            {renderTableData('d')}
            {renderTableData('SBCA / Mobile BA unit with long house', 4)}
            {renderTableData('Required')}
            {renderTableData('Not Required')}
          </tr>
          <tr>
            {renderTableData('e')}
            {renderTableData('Ventillation: (Blower / Exhause fan)', 4)}
            {renderTableData('Required')}
            {renderTableData('Not Required')}
          </tr>
          <tr>
            {renderTableData('f')}
            {renderTableData('Communication)', 3)}
            {renderTableData('Radio')}
            {renderTableData('Life Line')}
            {renderTableData('Voice Contact')}
          </tr>
          <tr>
            {renderTableData('g')}
            {renderTableData('Any Other', 6)}
          </tr>
        </thead>
      </Table>
      <b>3. Atmospheric Gas Testing inside the Confined Space :</b>
      <GasDetails serial="a" gas="Hydrocarbons (0% LEL)" />
      <ReadingsTable />
      <GasDetails serial="b" gas="Oxygen (Minimum 19.5%)" />
      <ReadingsTable />
      <GasDetails serial="c" gas="Hydrogen Sulphide  (Less than 10 PPM)" />
      <ReadingsTable />
      <b>4. Persons Entering / Leaving the Vessel:</b>
      <Table bordered style={{ borderColor: '#000', textAlign: 'center' }}>
        <tbody>
          <tr style={{ backgroundColor: 'orange' }}>
            {renderTableData(<b>No.</b>)}
            {renderTableData(<b>Name</b>)}
            {renderTableData(<b>Time In</b>)}
            {renderTableData(<b>Time Out</b>)}
            {renderTableData(<b>Signature</b>)}
          </tr>
          {rows?.map((r: any) => (
            <tr key={r}>
              <th style={{ height: 12 }} />

              {renderTableData(<></>)}
              {renderTableData(<></>)}
              {renderTableData(<></>)}
              {renderTableData(<></>)}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}
