import { Table } from 'reactstrap'

export default function RcmMatrix({
  onClick,
  Economic,
  health,
  environment,
  probability
}: {
  onClick: any
  Probalbility?: any
  Economic?: any
  health?: any
  environment?: any
  probability?: any
}) {
  const economicValus = [
    '<100 k USD',
    '0.1 - 1 M USD',
    '1 - 10 M USD',
    '10 - 100 M USD',
    '>100 M USD'
  ]

  const healthSafetyOptions = [
    'Slight Injury',
    'Minor Injury',
    'Major Injury',
    'Single Fatalities',
    'Multiple Fatalities'
  ]

  const environmentOptions = [
    'Slight Effect',
    'Minor Effect',
    'Localised Effect',
    'Major Effect',
    'Massive Effect'
  ]

  return (
    <>
      <Table
        bordered
        style={{
          borderColor: '#000',
          textAlign: 'center',
          verticalAlign: 'middle',
          fontSize: '14px'
        }}
      >
        <tbody>
          <tr>
            <td colSpan={3}>ETBF</td>
            <td></td>
            <td colSpan={5}>Criticality</td>
          </tr>
          <tr>
            <td colSpan={12}></td>
          </tr>
          <tr>
            <td rowSpan={4} style={{ width: '3%' }}>
              <p
                style={{
                  writingMode: 'vertical-rl',
                  textOrientation: 'upright',
                  textAlign: 'center',
                  marginLeft: 0,
                  fontWeight: 700
                }}
              >
                PROBABILITY
              </p>
            </td>
            <td>H</td>
            <td
              onClick={() => onClick({ Probalbility: '<0.5Y' })}
              style={{ borderWidth: probability === '<0.5Y' ? 5 : 1 }}
            >
              {'<0.5Y'}
            </td>
            <td style={{ width: '1%' }}></td>
            <td
              style={{ backgroundColor: 'lightgreen', width: '10%' }}
              onClick={() => onClick({ ETBF: 'L' })}
            >
              L
            </td>
            <td
              style={{ backgroundColor: 'orange', width: '10%' }}
              onClick={() => onClick({ ETBF: 'MH' })}
            >
              MH
            </td>
            <td
              style={{ backgroundColor: 'red', width: '10%' }}
              onClick={() => onClick({ ETBF: 'H' })}
            >
              H
            </td>
            <td
              style={{ backgroundColor: 'purple', width: '10%', color: '#fff' }}
              onClick={() => onClick({ ETBF: 'E' })}
            >
              E
            </td>
            <td
              style={{ backgroundColor: 'purple', width: '10%', color: '#fff' }}
              onClick={() => onClick({ ETBF: 'E' })}
            >
              E
            </td>
          </tr>
          <tr>
            <td>M</td>
            <td
              onClick={() => onClick({ Probalbility: '0.5 - 4 Y' })}
              style={{ borderWidth: probability === '0.5 - 4 Y' ? 5 : 1 }}
            >
              {'0.5 - 4 Y'}
            </td>
            <td></td>
            <td
              style={{ backgroundColor: 'lightgreen', width: '10%' }}
              onClick={() => onClick({ ETBF: 'L' })}
            >
              L
            </td>
            <td
              style={{ backgroundColor: 'yellow', width: '10%' }}
              onClick={() => onClick({ ETBF: 'M' })}
            >
              M
            </td>
            <td
              style={{ backgroundColor: 'orange', width: '10%' }}
              onClick={() => onClick({ ETBF: 'MH' })}
            >
              MH
            </td>
            <td
              style={{ backgroundColor: 'red', width: '10%' }}
              onClick={() => onClick({ ETBF: 'H' })}
            >
              H
            </td>
            <td
              style={{ backgroundColor: 'purple', width: '10%', color: '#fff' }}
              onClick={() => onClick({ ETBF: 'E' })}
            >
              E
            </td>
          </tr>
          <tr>
            <td>L</td>
            <td
              onClick={() => onClick({ Probalbility: '4 - 20 Y' })}
              style={{ borderWidth: probability === '4 - 20 Y' ? 5 : 1 }}
            >
              {'4 - 20 Y'}
            </td>
            <td></td>
            <td
              style={{ backgroundColor: 'green', width: '10%' }}
              onClick={() => onClick({ ETBF: 'N' })}
            >
              N
            </td>
            <td
              style={{ backgroundColor: 'lightgreen', width: '10%' }}
              onClick={() => onClick({ ETBF: 'L' })}
            >
              L
            </td>
            <td
              style={{ backgroundColor: 'yellow', width: '10%' }}
              onClick={() => onClick({ ETBF: 'M' })}
            >
              M
            </td>
            <td
              style={{ backgroundColor: 'orange', width: '10%' }}
              onClick={() => onClick({ ETBF: 'MH' })}
            >
              MH
            </td>
            <td
              style={{ backgroundColor: 'red', width: '10%' }}
              onClick={() => onClick({ ETBF: 'H' })}
            >
              H
            </td>
          </tr>
          <tr>
            <td>L</td>
            <td
              onClick={() => onClick({ Probalbility: '>20 Y' })}
              style={{ borderWidth: probability === '>20 Y' ? 5 : 1 }}
            >
              {'>20 Y'}
            </td>
            <td></td>
            <td
              style={{ backgroundColor: 'green', width: '10%' }}
              onClick={() => onClick({ ETBF: 'N' })}
            >
              N
            </td>
            <td
              style={{ backgroundColor: 'green', width: '10%' }}
              onClick={() => onClick({ ETBF: 'N' })}
            >
              N
            </td>
            <td
              style={{ backgroundColor: 'lightgreen', width: '10%' }}
              onClick={() => onClick({ ETBF: 'L' })}
            >
              L
            </td>
            <td
              style={{ backgroundColor: 'yellow', width: '10%' }}
              onClick={() => onClick({ ETBF: 'M' })}
            >
              M
            </td>
            <td
              style={{ backgroundColor: 'orange', width: '10%' }}
              onClick={() => onClick({ ETBF: 'MH' })}
            >
              MH
            </td>
          </tr>
          <tr>
            <td colSpan={12}></td>
          </tr>
          <tr>
            <td rowSpan={3} style={{ width: '3%' }}>
              <p
                style={{
                  writingMode: 'vertical-rl',
                  textOrientation: 'upright',
                  textAlign: 'center',
                  marginLeft: 0,
                  fontWeight: 700
                }}
              >
                CONSEQUENCE
              </p>
            </td>
            <td colSpan={2}>Economic</td>
            <td />
            {economicValus?.map((v: any) => (
              <td
                key={v}
                onClick={() => onClick({ Economic: v })}
                style={{ borderWidth: Economic === v ? 5 : 1 }}
              >
                {v}
              </td>
            ))}
          </tr>
          <tr>
            <td colSpan={2}>Health & Safety</td>
            <td />
            {healthSafetyOptions?.map((v: any) => (
              <td
                key={v}
                onClick={() => onClick({ 'Health Safety': v })}
                style={{ borderWidth: health === v ? 5 : 1 }}
              >
                {v}
              </td>
            ))}
          </tr>
          <tr>
            <td colSpan={2}>Environment</td>
            <td />
            {environmentOptions?.map((v: any) => (
              <td
                key={v}
                onClick={() => onClick({ 'Environment Safety': v })}
                style={{ borderWidth: environment === v ? 5 : 1 }}
              >
                {v}
              </td>
            ))}
          </tr>
          <tr>
            <td colSpan={12}></td>
          </tr>
          <tr>
            <td colSpan={3}>CONSEQUENCE</td>
            <td />
            <td>N</td>
            <td>L</td>
            <td>M</td>
            <td>H</td>
            <td>F</td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}
