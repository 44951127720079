import { Container } from 'reactstrap'
import moment from 'moment'

import SectionHeader from '../Layout/SectionHeader'
import toNormalCase from '../lib/toNormalCase'
import { renderLongRow } from '../Modals/AuditPreviewModal'
import { renderRow } from '../../pages/VerifyCM'
import { permitStatuses } from './ViewPermitdetails'
import { ColoredSubHeader } from './ViewExcavationPermit'
import { useNavigate } from 'react-router-dom'

export default function PreviewAudit({ values }: any) {
  const color: any = '#d0ebff'
  const navigate = useNavigate()

  const headerBackground: any = {
    backgroundColor: color
  }

  const fields: any = {
    'Permit Orignator': values?.permitOriginator,
    'Permit Number': values?.permitNumber,
    'Permit Status':
      permitStatuses[values?.permitStatus] || values?.permitStatus,
    'Audit Number': values?.auditNumber,
    'Audit Person': values?.auditPerson?.value,
    'Audit Date': moment(values?.auditDate).format('DD-MM-YYYY'),
    ...(values?.status && { Status: <b>{toNormalCase(values?.status)}</b> }),
    ...(values?.comments &&
      values?.status === 'rejected' && {
        'Rejected Comments': <b>{values?.comments}</b>
      }),
    ...((values?.stauts === 'rejected' || values?.status === 'approved') &&
      values?.needCM === 'Yes' && {
        'Need CM': (
          <div style={{ display: 'flex' }}>
            <p style={{ margin: 0 }}>{values?.needCM} </p>
            <p
              style={{
                margin: 0,
                paddingLeft: 10,
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
              onClick={() => {
                localStorage.setItem('createCM', JSON.stringify(values))
                navigate('/cmms/dashboard')
              }}
            >
              Create CM
            </p>
          </div>
        )
      })
  }

  const distribution: any = {
    'Have permit copies been properly distributed, and where necessary is a permit prominently displayed ?':
      values?.distributionAndDisplay
  }

  const scope: any = {
    'Is the work description on the permit adequate, (ie) does it adequately describe the work location, the equipment to be worked on , and the exact nature and scope of the work ?':
      values?.scopeOfWork
  }

  const validity: any = {
    'The permit should clear it state the time and dates between which its valid ?':
      values?.validityA,
    'Is permit revalidation being signed and dated by Issued Authority ?':
      values?.validityB,
    'Are revalidations up to date ?': values?.validityC
  }

  const hazards: any = {
    'Are hazards clearlyidentified ?': values?.hazardA,
    'Are they directly applicable to the job being undertaken ?':
      values?.hazardB
  }

  const precautions: any = {
    'Are appropriate precautions identified and specific enough ?':
      values?.precautionA,
    'Have other affected personnel outside the permit area been modified of the permit work ?':
      values?.precautionB,
    'Have all the precautions been implemented at the worksite ?':
      values?.precautionC,
    'Have all the precautions been implemented at the worksites ?':
      values?.precautionD,
    'If not, explain deficiency ?': values?.precautionE
  }

  const gasTests: any = {
    'Have gas test been undertaken ?': values?.gasTestA,
    'Are tests valid for this perios ?': values?.gasTestB,
    'Are portable gas monitors fully operable at worksite and caliberated ?':
      values?.gasTestC,
    'Are on-site personnel knowledgeable on how to operate equipment ?':
      values?.gasTestD,
    'Have they been properly trained to use ?': values?.gasTestE,
    'Has perodic testing been carried out as appropriate ?': values?.gasTestF
  }

  const performing: any = {
    'Has performing Authority  briefed everyone in the Work party ?':
      values?.precautionPAA,
    'Have all persons in the Work Party read the permit ?':
      values?.precautionPAB,
    'Do all fully understand the safety requirements and the precautions stated on the PTW ?':
      values?.precautionPAC,
    'Are they in compliance, eg using safety clothing as specified, isolation equipment at the breaks, etc ?':
      values?.precautionPAD,
    'If not Specify ?': values?.precautionPAE
  }

  const isolation: any = {
    'Are isolation cerified attached to the permits ?': values?.isolationA,
    'Are they cross-refrenced ?': values?.isolationB,
    'Are all isolations secure ?': values?.isolationC,
    'Do all fully understand the safety requirements and the precautions stated on PTW ?':
      values?.isolationD,
    'Do Certificate or attachments detail specific isolaions point ?':
      values?.isolationE,
    'If more then one task, on same isolation, has multiple lock / key system or other suitable control been used ?':
      values?.isolationF,
    'If so, give details ?': values?.isolationG
  }

  const issue: any = {
    'Has task been fully discussed with person carrying out the isolation ?':
      values?.precautionIAA,
    'Has the task been dicussed with performing Authority ?':
      values?.precautionIAB,
    'If more than one permit on equipment / system, have all appropriate cross -reference been made and all necessary personnel been made aware ?':
      values?.precautionIAC,
    'Where task may impinge on other responsible persons / areas, have affected persons signed to acknowledge their awareness of the work ?':
      values?.precautionIAD
  }

  const handOver: any = {
    'Has handover of permit been done between Issue Authority at shift change ?':
      values?.handOverA,
    'How is this being documented ?': values?.handOverB,
    'Has a handover of jobs between Performing Authorities been done ?':
      values?.handOverC,
    'How is this being documented  ?': values?.handOverD
  }

  const work: any = {
    'Has site be left in a safe and tidy conditions ?': values?.WOCA,
    'If work not completed, are isolations secure ?': values?.WOCB,
    'Is Issue Authority aware of status ?': values?.WOCC,
    'Is control room aware of status ?': values?.WOCD
  }

  const traning: any = {
    'Have personnel, who are currently associated with this permit, received training in the companys PTW system ?':
      values?.trainingA,
    'Is the type and frequency of training in accordance with company policy ?':
      values?.trainingB,
    'Is evidence of their training and appointment readily available ?':
      values?.trainingC
  }
  return (
    <Container>
      <SectionHeader title={'PERMIT AUDIT'} />
      <hr />
      <ColoredSubHeader rowStyle={headerBackground} text="AUDIT DETAILS" />
      {Object.keys(fields).map((key: any) =>
        renderRow(key, fields[key], color)
      )}
      <ColoredSubHeader
        rowStyle={headerBackground}
        text="DISTRIBUTION AND DISPLAY"
      />
      {Object.keys(distribution).map((key: any) =>
        renderLongRow(key, distribution[key], color, 6)
      )}
      <ColoredSubHeader rowStyle={headerBackground} text="SCOPE OF WORK" />
      {Object.keys(scope).map((key: any) =>
        renderLongRow(key, scope[key], color, 6)
      )}
      <ColoredSubHeader rowStyle={headerBackground} text="VALIDITY" />
      {Object.keys(validity).map((key: any) =>
        renderLongRow(key, validity[key], color, 6)
      )}
      <ColoredSubHeader rowStyle={headerBackground} text="HAZARDS" />
      {Object.keys(hazards).map((key: any) =>
        renderLongRow(key, hazards[key], color, 6)
      )}
      <ColoredSubHeader rowStyle={headerBackground} text="PRECAUTIONS" />
      {Object.keys(precautions).map((key: any) =>
        renderLongRow(key, precautions[key], color, 6)
      )}
      <ColoredSubHeader rowStyle={headerBackground} text="GAS TESTS" />
      {Object.keys(gasTests).map((key: any) =>
        renderLongRow(key, gasTests[key], color, 6)
      )}
      <ColoredSubHeader
        rowStyle={headerBackground}
        text="PRECAUTIONS TAKEN BY PERFORMING AUTHORITY"
      />
      {Object.keys(performing).map((key: any) =>
        renderLongRow(key, performing[key], color, 6)
      )}
      <ColoredSubHeader rowStyle={headerBackground} text="ISOLATION" />
      {Object.keys(isolation).map((key: any) =>
        renderLongRow(key, isolation[key], color, 6)
      )}
      <ColoredSubHeader
        rowStyle={headerBackground}
        text="PRECAUTIONS TAKEN BY ISSUE AUTHORITY"
      />
      {Object.keys(issue).map((key: any) =>
        renderLongRow(key, issue[key], color, 6)
      )}
      <ColoredSubHeader rowStyle={headerBackground} text="HAND OVER" />
      {Object.keys(handOver).map((key: any) =>
        renderLongRow(key, handOver[key], color, 6)
      )}
      <ColoredSubHeader
        rowStyle={headerBackground}
        text="WORK SUSPEND / ON HOLD / COMPLETED"
      />
      {Object.keys(work).map((key: any) =>
        renderLongRow(key, work[key], color, 6)
      )}
      <ColoredSubHeader rowStyle={headerBackground} text="TRAINING" />
      {Object.keys(traning).map((key: any) =>
        renderLongRow(key, traning[key], color, 6)
      )}
    </Container>
  )
}
