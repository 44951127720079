import './../components/css/toggle.css'

export default function ProjectsOperationsToggle({ onClick, mode }: any) {
  return (
    <>
      <div className="container">
        <input
          className="toggleSwitch"
          type="checkbox"
          id="cup"
          onClick={onClick}
          defaultChecked={mode}
        />
        <label htmlFor="cup"></label>
      </div>
    </>
  )
}
