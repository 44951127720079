import { Table } from 'reactstrap'

export default function PrintPositiveIsolationTalk() {
  const rows = [1, 2, 3, 4, 5, 6, 7]
  return (
    <>
      <Table bordered style={{ borderColor: '#000', textAlign: 'center' }}>
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Location Description</th>
            <th>Rating</th>
            <th colSpan={2}>Signature when Installed</th>
            <th colSpan={2}>Signature when removed</th>
          </tr>
          <tr>
            <th />
            <th />
            <th />
            <th>Job Performer</th>
            <th>MIC</th>
            <th>Job Performer</th>
            <th>MIC</th>
          </tr>
        </thead>
        <tbody>
          {rows?.map((r: any) => (
            <tr key={r}>
              <td style={{ height: 40 }} />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
          ))}
        </tbody>
      </Table>
      <p style={{ padding: 5, border: '2px solid #000' }}>
        FOR INSTALLING BLINDS
      </p>
      <p style={{ padding: 5, border: '2px solid #000' }}>
        <b>Prepared by (Producntion In-Charge: ) </b>
        <br />
        <hr />
        Date:
      </p>
      <p style={{ padding: 5, border: '2px solid #000' }}>
        <b>Authorized by (Prodution Superintendent: ) </b>
        <br />
        <hr />
        Date:
      </p>
      <p style={{ padding: 5, border: '2px solid #000' }}>
        FOR REMOVING BLINDS
      </p>
      <p style={{ padding: 5, border: '2px solid #000' }}>
        <b>Authorized by (Producntion In-Charge: ) </b>
        <br />
        <hr />
        Date:
      </p>
    </>
  )
}
