import { getAllFIRSPermissions } from '../../apiRequests'

export const getPermissions = async (accounts: any, instance: any) => {
  const data = await getAllFIRSPermissions(accounts, instance).then(
    (res: any) => res.data
  )
  const createPermissions = data?.permissions?.permit?.create
  const verifyPermissions = data?.permissions?.permit?.verify
  const rejectPermissions = data?.permissions?.permit?.reject
  const approvePermissions = data?.permissions?.permit?.approve
  const reportPermissions = data?.permissions?.permit?.report
  const closePermnissions = data?.permissions?.permit?.close

  const getTruthy = (value: String) => value === 'Yes'
  const getAccess = (values: any) =>
    values?.map((d: any) => getTruthy(d))?.includes(true)

  const canCreate = getAccess([
    createPermissions?.mechanical,
    createPermissions?.electrical,
    createPermissions?.instrument,
    createPermissions?.production,
    createPermissions?.hse,
    createPermissions?.wareHouse
  ])

  const canVerify = getAccess([
    verifyPermissions?.mechanical,
    verifyPermissions?.electrical,
    verifyPermissions?.instrument,
    verifyPermissions?.production,
    verifyPermissions?.hse,
    verifyPermissions?.wareHouse
  ])

  const canReject = getAccess([
    rejectPermissions?.mechanical,
    rejectPermissions?.electrical,
    rejectPermissions?.instrument,
    rejectPermissions?.production,
    rejectPermissions?.hse,
    rejectPermissions?.wareHouse
  ])

  const canApprove = getAccess([
    approvePermissions?.mechanical,
    approvePermissions?.electrical,
    approvePermissions?.instrument,
    approvePermissions?.production,
    approvePermissions?.hse,
    approvePermissions?.wareHouse
  ])

  const canReport = getAccess([
    reportPermissions?.mechanical,
    reportPermissions?.electrical,
    reportPermissions?.instrument,
    reportPermissions?.production,
    reportPermissions?.hse,
    reportPermissions?.wareHouse
  ])

  const canClose = getAccess([
    closePermnissions?.mechanical,
    closePermnissions?.electrical,
    closePermnissions?.instrument,
    closePermnissions?.production,
    closePermnissions?.hse,
    closePermnissions?.wareHouse
  ])

  return {
    canCreate,
    canVerify,
    canApprove,
    canReject,
    canReport,
    canClose
  }
}
