import React from 'react'
import { PieChart } from 'react-minimal-pie-chart'
import { Col, Input, Row, Spinner } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'

import DashboardNavigation from '../../components/Dashboard/DashboardNavigation'
import Loader from '../../components/Loader'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import CustomLabel from '../../components/InputFields/CustomLabel'
import { analyticsEndpoint } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import {
  Bar,
  BarChart,
  CartesianGrid,
  DefaultTooltipContent,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { EmptyContent } from '../CreateNewPermit'
import { permitStatuses } from '../../components/previews/ViewPermitdetails'

const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

const generateColors = (count: any) => {
  const colors = []
  for (let i = 0; i < count; i++) {
    const color = getRandomColor()
    colors.push(color)
  }
  return colors
}

export default function Analytics() {
  const { accounts, instance } = useMsal()
  const [startDate, setStartDate]: any = useState('2023-11-01')
  const [endDate, setEndDate]: any = useState('2023-12-01')

  const duration: any = {
    startDate: startDate,
    endDate: endDate
  }

  const fetchAnalytis = async () => {
    const response = await makeAuthenticatedRequest(
      `${analyticsEndpoint}/UtilizationHours`,
      'POST',
      duration,
      accounts,
      instance
    )

    return response
  }

  const fetchPermitsAnalytics = async () => {
    const response = await makeAuthenticatedRequest(
      `${analyticsEndpoint}/PermitsCount`,
      'POST',
      duration,
      accounts,
      instance
    )

    return response
  }

  const {
    data: analyticsData,
    isLoading,
    refetch,
    isRefetching
  }: any = useQuery('analyticsData', fetchAnalytis)

  const {
    data: permitAnalytics,
    isLoading: permitsLoading,
    isRefetching: permitsRefetch
  }: any = useQuery('permitsAnalyticsData', fetchPermitsAnalytics)

  const pieData = analyticsData?.data[0]
  const permitsData = permitAnalytics?.data
  const vendorsData = analyticsData?.vendorHours
  const coreCrewData = analyticsData?.coreCrewHours
  const permitTypeData = analyticsData?.permits
  const permitsByLocation = analyticsData?.permitsByLocation
  const permitsByStatus = analyticsData?.permitsByStatus

  const colors = generateColors(vendorsData?.length)
  const permitsDataColors = generateColors(permitsByStatus?.length)

  const vendorPieData = vendorsData?.map((v: any, index: any) => ({
    title: v?.vendor,
    value: v?.hours,
    color: colors[index],
    labelPosition: 110 + index * 50
  }))

  const coewCrewPieData = coreCrewData?.map((v: any, index: any) => ({
    title: v?.vendor,
    value: v?.hours,
    color: colors[index],
    labelPosition: 110 + index * 50
  }))

  const permitsByStatusData = permitsByStatus?.map((v: any, index: any) => ({
    title: v?._id,
    value: v?.count,
    color: permitsDataColors[index]
  }))

  useEffect(() => {
    refetch()
  }, [startDate, endDate])

  const pieDataTotal = [
    {
      title: 'Core Crew',
      value: pieData?.corecrew,
      color: '#E38627'
    },
    {
      title: 'Vendors',
      value: pieData?.vendors,
      color: '#C13C37'
    }
  ]

  const permitsPieData = [
    {
      title: 'Cold Work',
      value: permitTypeData?.cold,
      color: '#fefaa2'
    },
    {
      title: 'Hot Work',
      value: permitTypeData?.hot,
      color: '#f5cee2'
    }
  ]

  return (
    <>
      <ProtectedLayout
        onBack="/services"
        hasSideList
        hasUserProfile
        sidePanel={<DashboardNavigation />}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Row style={{ margin: 0 }}>
              <Row
                style={{
                  margin: 0,
                  marginBottom: 10,
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Col xs="3">
                  <CustomLabel label="Start Date" />
                  <Input
                    type="date"
                    onClick={(e: any) => setStartDate(e.target.value)}
                  />
                </Col>
                <Col xs="3">
                  <CustomLabel label="End Date" />
                  <Input
                    type="date"
                    onClick={(e: any) => setEndDate(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12">
                  <div
                    style={{
                      padding: 10,
                      border: '1px solid orange',
                      marginLeft: 20,
                      marginBottom: 20,
                      justifyContent: 'center'
                    }}
                  >
                    <p
                      style={{
                        fontSize: 26,
                        textAlign: 'center',
                        fontWeight: '900'
                      }}
                    >
                      Permits By Status
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        height: 'auto',
                        marginBottom: 10
                      }}
                    >
                      {permitsByStatusData?.map((p: any) => (
                        <div
                          style={{
                            height: '10vh',
                            width: '15vh',
                            marginLeft: 5,
                            marginBottom: 5,
                            backgroundColor: p?.color,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            color: '#fff',
                            border: '1px solid #000'
                          }}
                        >
                          <p style={{ textAlign: 'center', fontSize: '20px' }}>
                            {p?.value}
                          </p>
                          <p style={{ textAlign: 'center' }}>
                            {permitStatuses[p?.title] || p?.title}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="12">
                  <div
                    style={{
                      height: 500,
                      padding: 10,
                      border: '1px solid orange',
                      marginLeft: 20,
                      marginBottom: 20,
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <div style={{}}>
                      <p
                        style={{
                          fontSize: 26,
                          textAlign: 'center',
                          fontWeight: '900'
                        }}
                      >
                        Resource Utilization chart
                      </p>
                      {isLoading || isRefetching ? (
                        <EmptyContent>
                          <Spinner />
                        </EmptyContent>
                      ) : (
                        pieData && (
                          <div style={{ height: 400, display: 'flex' }}>
                            <div>
                              <PieChart
                                // label={({ dataEntry }) => <p>{dataEntry.title}</p>}
                                label={({ dataEntry, dataIndex }: any) =>
                                  `${dataEntry.title}: ${dataEntry.value}`
                                }
                                labelStyle={{
                                  fontSize: '4px'
                                }}
                                data={pieDataTotal}
                              />
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginLeft: 30
                              }}
                            >
                              {pieDataTotal?.length > 0 &&
                                pieDataTotal?.map((p: any) => (
                                  <div
                                    style={{ display: 'flex', width: 'auto' }}
                                  >
                                    <div
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: p?.color
                                      }}
                                    />
                                    <p
                                      style={{
                                        fontSize: '18px',
                                        fontWeight: '600',
                                        margin: 0,
                                        marginLeft: 10
                                      }}
                                    >
                                      {p?.title} - {p?.value}
                                    </p>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="12">
                  <div
                    style={{
                      height: 500,
                      padding: 10,
                      border: '1px solid orange',
                      marginLeft: 20,
                      marginBottom: 20,
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <div style={{}}>
                      <p
                        style={{
                          fontSize: 26,
                          textAlign: 'center',
                          fontWeight: '900'
                        }}
                      >
                        Vendor Utilization Staticstics
                      </p>
                      {isLoading || isRefetching ? (
                        <EmptyContent>
                          <Spinner />
                        </EmptyContent>
                      ) : (
                        pieData && (
                          <div style={{ display: 'flex' }}>
                            <div style={{ height: 400 }}>
                              <PieChart
                                label={({ dataEntry, dataIndex }: any) =>
                                  `${dataEntry.title}: ${dataEntry.value}`
                                }
                                labelStyle={{
                                  fontSize: '4px'
                                }}
                                data={vendorPieData}
                              />
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginLeft: 30
                              }}
                            >
                              {vendorPieData?.length > 0 &&
                                vendorPieData?.map((p: any) => (
                                  <div style={{ display: 'flex' }}>
                                    <div
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: p?.color
                                      }}
                                    />
                                    <p
                                      style={{
                                        fontSize: '18px',
                                        fontWeight: '600',
                                        margin: 0,
                                        marginLeft: 10,
                                        whiteSpace: 'nowrap'
                                      }}
                                    >
                                      {p?.title} - {p?.value}
                                    </p>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="12">
                  <div
                    style={{
                      height: 500,
                      padding: 10,
                      border: '1px solid orange',
                      marginLeft: 20,
                      marginBottom: 20,
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <div style={{}}>
                      <p
                        style={{
                          fontSize: 26,
                          textAlign: 'center',
                          fontWeight: '900'
                        }}
                      >
                        Coew Crew Statistics
                      </p>
                      {isLoading || isRefetching ? (
                        <EmptyContent>
                          <Spinner />
                        </EmptyContent>
                      ) : (
                        pieData && (
                          <div style={{ display: 'flex' }}>
                            <div style={{ height: 400 }}>
                              <PieChart
                                label={({ dataEntry, dataIndex }: any) =>
                                  `${dataEntry.title}: ${dataEntry.value}`
                                }
                                labelStyle={{
                                  fontSize: '4px'
                                }}
                                data={coewCrewPieData}
                              />
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginLeft: 30
                              }}
                            >
                              {coewCrewPieData?.length > 0 &&
                                coewCrewPieData?.map((p: any) => (
                                  <div style={{ display: 'flex' }}>
                                    <div
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: p?.color
                                      }}
                                    />
                                    <p
                                      style={{
                                        fontSize: '18px',
                                        fontWeight: '600',
                                        margin: 0,
                                        marginLeft: 10,
                                        whiteSpace: 'nowrap'
                                      }}
                                    >
                                      {p?.title} - {p?.value}
                                    </p>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="12">
                  <div
                    style={{
                      height: 500,
                      padding: 10,
                      border: '1px solid orange',
                      marginLeft: 20,
                      marginBottom: 20,
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <div style={{}}>
                      <p
                        style={{
                          fontSize: 26,
                          textAlign: 'center',
                          fontWeight: '900'
                        }}
                      >
                        Work Nature Statistics
                      </p>
                      {isLoading || isRefetching ? (
                        <EmptyContent>
                          <Spinner />
                        </EmptyContent>
                      ) : (
                        permitsPieData && (
                          <div style={{ height: 400, display: 'flex' }}>
                            <div>
                              <PieChart
                                // label={({ dataEntry }) => <p>{dataEntry.title}</p>}
                                label={({ dataEntry, dataIndex }: any) =>
                                  `${dataEntry.title}: ${dataEntry.value}`
                                }
                                labelStyle={{
                                  fontSize: '4px'
                                }}
                                data={permitsPieData}
                              />
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginLeft: 30
                              }}
                            >
                              {permitsPieData?.length > 0 &&
                                permitsPieData?.map((p: any) => (
                                  <div
                                    style={{ display: 'flex', width: 'auto' }}
                                  >
                                    <div
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: p?.color
                                      }}
                                    />
                                    <p
                                      style={{
                                        fontSize: '18px',
                                        fontWeight: '600',
                                        margin: 0,
                                        marginLeft: 10
                                      }}
                                    >
                                      {p?.title} - {p?.value}
                                    </p>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="12">
                  <div
                    style={{
                      height: 800,
                      width: '100%',
                      padding: 10,
                      border: '1px solid orange',
                      marginLeft: 20,
                      marginBottom: 20
                    }}
                  >
                    <div>
                      <p
                        style={{
                          fontSize: 26,
                          textAlign: 'center',
                          fontWeight: '900'
                        }}
                      >
                        Permits Analytics
                      </p>
                      {permitsLoading || permitsRefetch ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 'inehrit'
                          }}
                        >
                          <Spinner />
                        </div>
                      ) : (
                        analyticsData && (
                          <>
                            <BarChart
                              style={{
                                width: '90%',
                                height: 700
                              }}
                              width={600}
                              height={400}
                              data={permitsData}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="_id" />
                              <YAxis />
                              <DefaultTooltipContent />
                              <Legend />
                              <Bar dataKey="count" fill="#8884d8" />
                            </BarChart>
                          </>
                        )
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="12">
                  <div
                    style={{
                      height: 800,
                      width: '100%',
                      padding: 10,
                      border: '1px solid orange',
                      marginLeft: 20,
                      marginBottom: 20
                    }}
                  >
                    <div>
                      <p
                        style={{
                          fontSize: 26,
                          textAlign: 'center',
                          fontWeight: '900'
                        }}
                      >
                        PTW as per Locations
                      </p>
                      {permitsLoading || permitsRefetch ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 'inehrit'
                          }}
                        >
                          <Spinner />
                        </div>
                      ) : (
                        permitsByLocation && (
                          <>
                            <BarChart
                              style={{
                                width: '90%',
                                height: 700,
                                fontSize: 8
                              }}
                              width={600}
                              height={400}
                              data={permitsByLocation}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 40 // Increase the bottom margin
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis
                                dataKey="_id"
                                angle={-45}
                                textAnchor="end"
                                interval={0}
                                height={80}
                                padding={{ left: 30, right: 30 }}
                              />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              <Bar dataKey="count" fill="orange">
                                {permitsByLocation.map(
                                  (entry: any, index: any) => (
                                    <React.Fragment key={`bar-${index}`}>
                                      <Bar dataKey="count" fill="orange" />
                                      <text
                                        fill="#8884d8"
                                        fontSize={12}
                                        textAnchor="middle"
                                      >
                                        {entry.count}
                                      </text>
                                    </React.Fragment>
                                  )
                                )}
                              </Bar>
                            </BarChart>
                          </>
                        )
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Row>
          </>
        )}
      </ProtectedLayout>
    </>
  )
}
