import arrayMutators from 'final-form-arrays'
import React, { useContext, useState } from 'react'
import { Alert, Button, Col, Input, ModalBody, Row, Table } from 'reactstrap'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { MdDelete } from 'react-icons/md'

import CustomLabel from '../InputFields/CustomLabel'
import LabeledRadioButtons from '../InputFields/LabeledRadioButton'
import LabledParagraphInput from '../InputFields/LabledParagraph'
import ModalTitle from '../ModalTitle'
import MultiSelect from '../InputFields/MultiSelect'
import Spacer from '../Spacer'
import LabeledDateTimeInput from '../InputFields/DateTime'
// import { AllUsersContext } from '../../App'
import { getOptions } from '../../pages/firs'
import SeverityRiskMatrix from '../Modals/SeverityRiskMatrix'
import ControlRiskMatrix from '../Modals/ControlRiskMatrix'
import { getRisk, getRiskColor, riskLevel } from './QRAForm'
import { usersEndpoint } from '../serverurl'
import { useMsal } from '@azure/msal-react'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { useQuery } from 'react-query'

export const incrementby1 = (n: number) => n + 1

export default function RiskAssessmentForm({
  setValues,
  values,
  toggle,
  isJSA,
  initialValuesEqual
}: any) {
  // const { allUsers } = useContext(AllUsersContext)
  const isCritical = isJSA === 'Critical'
  const [alert, setAlert]: any = useState(false)
  const { accounts, instance } = useMsal()

  const fetchUsers = async () => {
    const response = await makeAuthenticatedRequest(
      usersEndpoint,
      'GET',
      null,
      accounts,
      instance
    )

    return response.data
  }

  const { data, isLoading }: any = useQuery('fetchUsers', fetchUsers)

  const validate = (values: any) => {
    const errors: any = {}
    if (!values.team) {
      errors.team = 'Required'
    }
    if (!values.task) {
      errors.task = 'Required'
    }

    if (!isCritical) {
      if (!values.activities || !values.activities.length) {
        errors.activities = { _error: 'At least one Activity must be entered' }
      } else {
        const activityArrayErrors: any = []
        values.activities.forEach((activity: any, activityIndex: any) => {
          const activityErrors: any = {}
          if (!activity || !activity?.name) {
            activityErrors.name = 'Activity name is Required'
            activityArrayErrors[activityIndex] = activityErrors
          }
          if (!activity || !activity.responsible) {
            activityErrors.responsible = 'Reponsible is Required'
            activityArrayErrors[activityErrors] = activityErrors
          }
          if (activity && activity.risks && activity.risks.length) {
            const riskArrayErrors: any = []
            activity.risks.forEach((risk: any, riskIndex: any) => {
              if (!risk || !risk.length) {
                riskArrayErrors[riskIndex] = 'Required'
              }
            })
            if (riskArrayErrors.length) {
              activityErrors.risks = riskArrayErrors
              activityArrayErrors[activityIndex] = activityErrors
            }
          }
          if (activity && activity.controls && activity.controls.length) {
            const controlsArrayErrors: any = []
            activity.controls.forEach((control: any, controlIndex: any) => {
              if (!control || !control.length) {
                controlsArrayErrors[controlIndex] = 'Required'
              }
            })
            if (controlsArrayErrors.length) {
              activityErrors.controls = controlsArrayErrors
              activityArrayErrors[activityIndex] = activityErrors
            }
          }
        })
        if (activityArrayErrors.length) {
          errors.activities = activityArrayErrors
        }
      }
    }

    if (isCritical) {
      if (!values.hazards || !values.hazards.length) {
        errors.hazards = { _error: 'At least one Activity must be entered' }
      } else {
        const hazardArrayErrors: any = []
        values.hazards.forEach((hazard: any, hazardIndex: any) => {
          const hazardErrors: any = {}
          if (!hazard || !hazard?.name) {
            hazardErrors.name = 'Hazard Title is Required'
            hazardArrayErrors[hazardIndex] = hazardErrors
          }
          if (!hazard || !hazard.effect) {
            hazardErrors.effect = 'Effect is Required'
            hazardArrayErrors[hazardErrors] = hazardErrors
          }
          if (!hazard || !hazard.task) {
            hazardErrors.effect = 'Task is Required'
            hazardArrayErrors[hazardErrors] = hazardErrors
          }
          if (hazard && hazard.controls && hazard.controls.length) {
            const controlsArrayErrors: any = []
            hazard.controls.forEach((control: any, controlIndex: any) => {
              if (!control || !control.length) {
                controlsArrayErrors[controlIndex] = 'Required'
              }
            })
            if (controlsArrayErrors.length) {
              hazardErrors.controls = controlsArrayErrors
              hazardArrayErrors[hazardIndex] = hazardErrors
            }
          }
        })
        if (hazardArrayErrors.length) {
          errors.hazards = hazardArrayErrors
        }
      }
    }
    return errors
  }

  const renderField = ({
    input,
    label,
    type,
    meta: { touched, error }
  }: any) => (
    <div>
      <div>
        <Input {...input} type={'textarea'} placeholder={label} />
        {touched && error && <span style={{ color: 'red' }}>{error}</span>}
      </div>
    </div>
  )

  const renderRisks = ({ fields, meta: { error } }: any) => (
    <>
      {fields.map((risk: any, index: any) => (
        <React.Fragment key={index}>
          <Row style={{ marginBottom: 3 }}>
            <Col xs="11">
              <Field
                name={risk}
                type="text"
                component={renderField}
                label={`Risk #${index + 1}`}
              />
            </Col>
            <Col xs="1">
              <MdDelete
                onClick={() => fields.remove(index)}
                style={{ color: 'red' }}
              />
            </Col>
          </Row>
        </React.Fragment>
      ))}
      <>
        <Button color="link" onClick={() => fields.push()}>
          Add Risk
        </Button>
      </>
    </>
  )

  const renderControls = ({ fields, meta: { error } }: any) => (
    <>
      {fields.map((control: any, index: any) => (
        <React.Fragment key={index}>
          <Row style={{ marginBottom: 3 }}>
            <Col xs="11">
              <Field
                name={control}
                type="text"
                component={renderField}
                label={`Control #${index + 1}`}
              />
            </Col>
            <Col xs="1">
              <MdDelete
                onClick={() => fields.remove(index)}
                style={{ color: 'red' }}
              />
            </Col>
          </Row>
        </React.Fragment>
      ))}
      <>
        <Button color="link" onClick={() => fields.push()}>
          Add Control
        </Button>
      </>
    </>
  )

  const renderActivities = ({ fields, meta: { error, submitFailed } }: any) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Table bordered style={{ border: '1px solid #000' }}>
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            <th style={{ width: '15%' }}>Activity</th>
            <th>Risks</th>
            <th>Controls</th>
            <th>Responsible</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fields.map((activity: any, index: any) => (
            <React.Fragment key={index}>
              <tr>
                <td style={{ width: '15%' }}>
                  <Field
                    name={`${activity}.name`}
                    type="text"
                    component={renderField}
                    label="Activity"
                  />
                </td>
                <td style={{ width: '20%' }}>
                  <FieldArray
                    name={`${activity}.risks`}
                    component={renderRisks}
                  />
                </td>
                <td style={{ width: '20%' }}>
                  <FieldArray
                    name={`${activity}.controls`}
                    component={renderControls}
                  />
                </td>
                <td style={{ width: '10%' }}>
                  <MultiSelect
                    name={`${activity}.responsible`}
                    options={[
                      'HSE LEAD',
                      'Production Engineer',
                      'Production Incharge',
                      'Electrical Engineer',
                      'Mechanical Engineer',
                      'Instrument Engineer',
                      'Field Operator',
                      'Admin Operator',
                      'Panel Operator',
                      'Deputy IM',
                      'IM',
                      'Vendor',
                      'Fire Officer',
                      'Vessel Master'
                    ].map((role: string) => ({ value: role, label: role }))}
                    occupy={12}
                  />
                </td>
                <td style={{ width: '1%', color: 'red' }}>
                  <MdDelete onClick={() => fields.remove(index)} />
                </td>
              </tr>
            </React.Fragment>
          ))}
          <tr>
            <td style={{ borderRight: 0 }}>
              <Button
                type="button"
                style={{ width: '100%' }}
                onClick={() => fields.push({})}
                color="warning"
              >
                Add Activity
              </Button>
            </td>
          </tr>
          <>
            {submitFailed && error && (
              <span style={{ color: 'red' }}>{error._error}</span>
            )}
          </>
        </tbody>
      </Table>
    </div>
  )

  const renderHazards = ({
    values,
    fields,
    meta: { error, submitFailed }
  }: any) => (
    <div
      style={{
        // minHeight: '45vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Table bordered style={{ border: '1px solid #000' }}>
        <thead
          style={{ backgroundColor: 'orange', fontSize: 13, fontWeight: 900 }}
        >
          <tr>
            <th colSpan={5} />
            <th colSpan={2} style={{ textAlign: 'center' }}>
              Risk Evaluation
            </th>
            <th colSpan={2} />
            <th colSpan={2} style={{ textAlign: 'center' }}>
              After Protective
            </th>
            <th colSpan={3} />
          </tr>
          <tr>
            <th>Task </th>
            <th>Hazard</th>
            <th>Consquence / Effect</th>
            <th>Activity</th>
            <th>Condition</th>
            <th>Severity/ Probability</th>
            <th>Control/ People</th>
            <th>Initial Risk</th>
            <th style={{ textAlign: 'center' }}>Existing Control Measures</th>
            <th>Severity/ Probability</th>
            <th>Control/ People</th>
            <th>Residual Risk</th>
            <th>Risk Category</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fields.map((hazard: any, index: any) => (
            <React.Fragment key={index}>
              <tr>
                <td>
                  <Field
                    name={`${hazard}.task`}
                    type="textarea"
                    component={renderField}
                    label="Task"
                  />
                </td>
                <td>
                  <Field
                    name={`${hazard}.name`}
                    type="textarea"
                    component={renderField}
                    label="Hazard"
                  />
                </td>
                <td>
                  <Field
                    name={`${hazard}.effect`}
                    type="textarea"
                    component={renderField}
                    label="Effect"
                  />
                </td>
                <td>
                  <Field name={`${hazard}.routine`} component="select">
                    <option />
                    <option value="Routine">Routine</option>
                    <option value="Non Routine">Non Routine</option>
                  </Field>
                </td>
                <td>
                  <Field name={`${hazard}.activity`} component="select">
                    <option />
                    <option value="Normal">Normal</option>
                    <option value="Abnormal">Abnormal</option>
                    <option value="Emergency">Emergency</option>
                  </Field>
                </td>
                <td>
                  <SeverityRiskMatrix
                    name={`${hazard}.initalSeverityProb`}
                    values={values?.hazards?.[index]?.initalSeverityProb}
                  />
                </td>
                <td>
                  <ControlRiskMatrix
                    name={`${hazard}.initalControlPeople`}
                    values={values?.hazards?.[index]?.initalControlPeople}
                  />
                </td>
                <td
                  style={{
                    width: '1%',
                    backgroundColor: getRiskColor(
                      getRisk(
                        values?.hazards?.[index]?.initalSeverityProb,
                        values?.hazards?.[index]?.initalControlPeople
                      )
                    ),
                    textAlign: 'center',
                    color: 'white',
                    fontWeight: 700
                  }}
                >
                  {getRisk(
                    values?.hazards?.[index]?.initalSeverityProb,
                    values?.hazards?.[index]?.initalControlPeople
                  )}
                </td>
                <td>
                  <Row>
                    <FieldArray
                      name={`${hazard}.controls`}
                      component={renderControls}
                    />
                  </Row>
                </td>
                <td>
                  <SeverityRiskMatrix
                    name={`${hazard}.residualSeverityProb`}
                    values={values?.hazards?.[index]?.residualSeverityProb}
                  />
                </td>
                <td>
                  <ControlRiskMatrix
                    name={`${hazard}.residualControlPeople`}
                    values={values?.hazards?.[index]?.residualControlPeople}
                  />
                </td>
                <td
                  style={{
                    width: '1%',
                    backgroundColor: getRiskColor(
                      getRisk(
                        values?.hazards?.[index]?.residualSeverityProb,
                        values?.hazards?.[index]?.residualControlPeople
                      )
                    ),
                    textAlign: 'center',
                    color: 'white',
                    fontWeight: 700
                  }}
                >
                  {getRisk(
                    values?.hazards?.[index]?.residualSeverityProb,
                    values?.hazards?.[index]?.residualControlPeople
                  )}
                </td>
                <td>
                  {riskLevel(
                    getRisk(
                      values?.hazards?.[index]?.residualSeverityProb,
                      values?.hazards?.[index]?.residualControlPeople
                    )
                  )}
                </td>
                <td style={{ color: 'red' }}>
                  <MdDelete onClick={() => fields.remove(index)} />
                </td>
              </tr>
            </React.Fragment>
          ))}
          <tr>
            <td style={{ borderRight: 0 }}>
              <Button
                type="button"
                style={{ width: '100%' }}
                onClick={() => fields.push({})}
                color="warning"
              >
                Add Hazard
              </Button>
            </td>
          </tr>
          <>
            {submitFailed && error && (
              <span style={{ color: 'red' }}>{error._error}</span>
            )}
          </>
        </tbody>
      </Table>
    </div>
  )

  const teamList = data?.map(
    (user: any) => `${user?.jobTitle}-${user?.givenName}`
  )

  return (
    <ModalBody style={{ backgroundColor: 'white' }}>
      <ModalTitle
        title={`${
          isCritical ? 'Qualitative Risk Assessment ' : 'Job Safety Analysis'
        }`}
        height={50}
      />
      {alert && (
        <Alert show={alert} variant="success" dismissible>
          <p style={{ textAlign: 'center', margin: 0 }}>
            Risk Assessment Saved Successfully.
          </p>
        </Alert>
      )}
      <Form
        onSubmit={(val: any) =>
          setValues({
            ...val,
            new: true,
            team: val?.team?.map((t: any) => t?.value),
            activities: val?.activities?.map((a: any) => ({
              ...a,
              responsible: a?.responsible?.map((m: any) => m?.value)
            }))
          })
        }
        initialValues={{
          ...values,
          team: getOptions(values?.team),
          activities: values?.activities?.map((a: any) => ({
            ...a,
            responsible: getOptions(a?.responsible)
          }))
        }}
        initialValuesEqual={() =>
          initialValuesEqual ? initialValuesEqual : {}
        }
        validate={validate}
        mutators={{
          ...arrayMutators
        }}
        render={({
          handleSubmit,
          form,
          hasValidationErrors,
          values,
          ...rest
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs="3">
                <CustomLabel label="Department" />
                <Field
                  name={'department'}
                  component="select"
                  style={{
                    width: '-webkit-fill-available',
                    height: 38,
                    borderRadius: 5
                  }}
                >
                  <option value="" disabled selected>
                    Select Department
                  </option>
                  <option value="Mechanical">Mechanical</option>
                  <option value="Electrical">Electrical</option>
                  <option value="Instrument">Instrument</option>
                  <option value="Production">Production</option>
                  <option value="HSE">HSE</option>
                  <option value="Admin Ware House">Admin Ware House</option>
                </Field>
              </Col>
              <Col xs="3">
                <CustomLabel label="Location" />
                <Field
                  name={'location'}
                  component="select"
                  style={{
                    width: '-webkit-fill-available',
                    height: 38,
                    borderRadius: 5
                  }}
                >
                  <option value="" disabled selected>
                    Select Location
                  </option>
                  <option value="Admin Ware House">Admin Ware House</option>
                  <option value="Fire Station">Fire Station</option>
                  <option value="Mechanical Workshop">
                    Mechanical Workshop
                  </option>
                  <option value="Substation">Substation</option>
                  <option value="Control Room">Control Room</option>
                  <option value="Process Area">Process Area</option>
                </Field>
              </Col>
              <LabeledDateTimeInput name="date" label="Date" occupy={3} />
              <LabeledRadioButtons
                name="toolBox"
                label="Toolbox Talk Conducted"
                list={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' }
                ]}
                defaultValue="Yes"
                occupy={3}
              />
              <MultiSelect
                name="team"
                label="Assesment Team"
                options={getOptions(teamList)}
                occupy={6}
              />
              <LabledParagraphInput
                name="task"
                label="Activity / Task"
                occupy={6}
              />
            </Row>
            <Spacer height={20} />
            {!isCritical && (
              <FieldArray name="activities" component={renderActivities} />
            )}
            {isCritical && (
              <FieldArray
                name="hazards"
                component={renderHazards}
                values={values}
              />
            )}
            <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
              <Button
                onClick={() => {
                  form.submit()
                  // toggle && toggle()
                  setAlert(true)
                  setTimeout(() => {
                    setAlert(false)
                  }, 2000)
                }}
                color={hasValidationErrors ? 'secondary' : 'success'}
                disabled={hasValidationErrors}
              >
                Save
              </Button>
              <Spacer height={10} />
            </Row>
          </form>
        )}
      />
    </ModalBody>
  )
}
