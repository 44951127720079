import { Container } from "reactstrap"
import DashboardHeader from "../components/Dashboard/DashboardHeader"
import DashboardNavigation from "../components/Dashboard/DashboardNavigation"
import defualtTheme from "./../components/constants/defaultTheme.json"

export default function Layout() {
  return (
    <Container fluid>
      <div
        style={{
          backgroundColor: '#E5EAF0',
          padding: 30
        }}>
        <div
          style={{
            backgroundColor: '#D6D6D6',
            border: '5px solid #FFF',
            borderRadius: 25,
            margin: 5,
            padding: 10
          }}
        >
          <div style={{
            backgroundColor: defualtTheme.colors.lightBlue1,
            borderRadius: 15
          }}>
            <DashboardHeader />
            <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'space-between' }}>
              <DashboardNavigation />
              <div style={{ backgroundColor: '#D7D7D7', width: '85%', padding: 10, display: 'flex', justifyContent: 'space-between', borderTopLeftRadius: 10 }}>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}