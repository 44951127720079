import moment from 'moment'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useState } from 'react'

import CustomLabel from '../../components/InputFields/CustomLabel'
import ModalTitle from '../../components/ModalTitle'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import Spacer from '../../components/Spacer'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { protectiveMaintenancePermit } from '../../components/serverurl'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { SubHeader } from '../VerifyCM'
import SectionHeader from '../../components/Layout/SectionHeader'

const UpdateCounter = ({
  isOpen,
  toggle,
  setCounter
}: {
  isOpen: any
  toggle: any
  setCounter: any
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalTitle title="Update Counter" height={100} />
      <ModalBody>
        <CustomLabel label="Update Current Value" />
        <Input
          onChange={(e: any) => setCounter(e.target.value)}
          defaultValue="256"
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Cancel</Button>
        <Button color="warning" onClick={toggle}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const data = [
  {
    componentNumber: 'A-V1610',
    name: 'Purifier / Clarifier unit',
    functionNumber: 'VE116-00050',
    function: 'MGO Purifier Package',
    counterType: 'Running Hours',
    currentValue: 256.0,
    dateRead: moment().format('DD-MM-YYYY')
  },
  {
    componentNumber: 'A-V1810',
    name: 'Air Compressor Package',
    functionNumber: 'VE118-01000',
    function: 'Air Compressor 1 Package',
    counterType: 'Running Hours',
    currentValue: 2543.0,
    dateRead: moment().format('DD-MM-YYYY')
  },
  {
    componentNumber: 'A-V1820',
    name: 'Air Compressor Package',
    functionNumber: 'VE118-02000',
    function: 'Air Compressor 2 Package',
    counterType: 'Running Hours',
    currentValue: 2258.0,
    dateRead: moment().format('DD-MM-YYYY')
  },
  {
    componentNumber: 'A-V1830',
    name: 'Air Compressor Package',
    functionNumber: 'VE118-03000',
    function: 'Air Compressor 3 Package',
    counterType: 'Running Hours',
    currentValue: 228.0,
    dateRead: moment().format('DD-MM-YYYY')
  },
  {
    componentNumber: 'A-V1840',
    name: 'Air Compressor Package',
    functionNumber: 'VE118-04000',
    function: 'Air Compressor 4 Package',
    counterType: 'Running Hours',
    currentValue: 228.0,
    dateRead: moment().format('DD-MM-YYYY')
  },
  {
    componentNumber: 'EXA V1862A',
    name: 'Air Compressor Package',
    functionNumber: 'VE118-07000',
    function: 'Main Air Compressor',
    counterType: 'Running Hours',
    currentValue: 86.0,
    dateRead: moment().format('DD-MM-YYYY')
  }
]

export default function ComponentList() {
  const [modal, setModal]: any = useState(false)
  const [counter, setCounter]: any = useState()

  return (
    <ProtectedLayout onBack="/planning/pmjobs/dashboard">
      <Row style={{ margin: 10 }}>
        <SectionHeader title="update Running counters" />
        <Spacer height={10} />
        <Table bordered style={{ border: '1px solid #000' }}>
          <thead>
            <tr style={{ backgroundColor: 'orange' }}>
              <th>S.No</th>
              <th>Component No.</th>
              <th>Component Name</th>
              <th>Function No.</th>
              <th>Function</th>
              <th>Counter Type</th>
              <th>Current Value</th>
              <th>Date Read</th>
              <th>Update</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((p: any, index: number) => (
              <tr key={`${p?.tagNumber}-${index}`}>
                <td>{incrementby1(index)}</td>
                <td>{p?.componentNumber}</td>
                <td>{p?.name || 'NA'}</td>
                <td>{p?.functionNumber}</td>
                <td>{p?.function}</td>
                <td>{p?.counterType}</td>
                <td>{p?.currentValue}</td>
                <td>{p?.dateRead}</td>
                <td
                  onClick={() => setModal(true)}
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                >
                  Update Counter
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      <UpdateCounter
        isOpen={modal}
        toggle={() => setModal(!modal)}
        setCounter={setCounter}
      />
    </ProtectedLayout>
  )
}
