import arrayMutators from 'final-form-arrays'
import React, { useContext } from 'react'
import { Button, Col, Input, ModalBody, Row, Table } from 'reactstrap'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { MdDelete } from 'react-icons/md'

import CustomLabel from '../InputFields/CustomLabel'
import LabeledRadioButtons from '../InputFields/LabeledRadioButton'
import LabledParagraphInput from '../InputFields/LabledParagraph'
import ModalTitle from '../ModalTitle'
import MultiSelect from '../InputFields/MultiSelect'
import Spacer from '../Spacer'
import LabeledDateTimeInput from '../InputFields/DateTime'
import { AllUsersContext } from '../../App'
import { getOptions } from '../../pages/firs'

export const incrementby1 = (n: number) => n + 1

export default function JSAForm({ setValues, values, toggle }: any) {
  const { allUsers } = useContext(AllUsersContext)

  const validate = (values: any) => {
    const errors: any = {}
    if (!values.team) {
      errors.team = 'Required'
    }
    if (!values.task) {
      errors.task = 'Required'
    }
    if (!values.activities || !values.activities.length) {
      errors.activities = { _error: 'At least one Activity must be entered' }
    } else {
      const activityArrayErrors: any = []
      values.activities.forEach((activity: any, activityIndex: any) => {
        const activityErrors: any = {}
        if (!activity || !activity?.name) {
          activityErrors.name = 'Activity name is Required'
          activityArrayErrors[activityIndex] = activityErrors
        }
        if (!activity || !activity.responsible) {
          activityErrors.responsible = 'Reponsible is Required'
          activityArrayErrors[activityErrors] = activityErrors
        }
        if (activity && activity.risks && activity.risks.length) {
          const riskArrayErrors: any = []
          activity.risks.forEach((risk: any, riskIndex: any) => {
            if (!risk || !risk.length) {
              riskArrayErrors[riskIndex] = 'Required'
            }
          })
          if (riskArrayErrors.length) {
            activityErrors.risks = riskArrayErrors
            activityArrayErrors[activityIndex] = activityErrors
          }
        }
        if (activity && activity.controls && activity.controls.length) {
          const controlsArrayErrors: any = []
          activity.controls.forEach((control: any, controlIndex: any) => {
            if (!control || !control.length) {
              controlsArrayErrors[controlIndex] = 'Required'
            }
          })
          if (controlsArrayErrors.length) {
            activityErrors.controls = controlsArrayErrors
            activityArrayErrors[activityIndex] = activityErrors
          }
        }
      })
      if (activityArrayErrors.length) {
        errors.activities = activityArrayErrors
      }
    }
    return errors
  }

  const renderField = ({
    input,
    label,
    type,
    meta: { touched, error }
  }: any) => (
    <div>
      <div>
        <Input {...input} type={'textarea'} placeholder={label} />
        {touched && error && <span style={{ color: 'red' }}>{error}</span>}
      </div>
    </div>
  )

  const renderRisks = ({ fields, meta: { error } }: any) => (
    <>
      {fields.map((risk: any, index: any) => (
        <React.Fragment key={index}>
          <Row style={{ marginBottom: 3 }}>
            <Col xs="11">
              <Field
                name={risk}
                type="text"
                component={renderField}
                label={`Risk #${index + 1}`}
              />
            </Col>
            <Col xs="1">
              <MdDelete
                onClick={() => fields.remove(index)}
                style={{ color: 'red' }}
              />
            </Col>
          </Row>
        </React.Fragment>
      ))}
      <>
        <Button color="link" onClick={() => fields.push()}>
          Add Risk
        </Button>
      </>
    </>
  )

  const renderControls = ({ fields, meta: { error } }: any) => (
    <>
      {fields.map((control: any, index: any) => (
        <React.Fragment key={index}>
          <Row style={{ marginBottom: 3 }}>
            <Col xs="11">
              <Field
                name={control}
                type="text"
                component={renderField}
                label={`Control #${index + 1}`}
              />
            </Col>
            <Col xs="1">
              <MdDelete
                onClick={() => fields.remove(index)}
                style={{ color: 'red' }}
              />
            </Col>
          </Row>
        </React.Fragment>
      ))}
      <>
        <Button color="link" onClick={() => fields.push()}>
          Add Control
        </Button>
      </>
    </>
  )

  const renderActivities = ({ fields, meta: { error, submitFailed } }: any) => (
    <div
      style={{
        minHeight: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Table bordered striped style={{ border: '1px solid #000' }}>
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            <th>Activity</th>
            <th>Risks</th>
            <th>Controls</th>
            <th>Responsible</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fields.map((activity: any, index: any) => (
            <React.Fragment key={index}>
              <tr>
                <td style={{ width: '15%' }}>
                  <Field
                    name={`${activity}.name`}
                    type="text"
                    component={renderField}
                    label="Activity"
                  />
                </td>
                <td style={{ width: '20%' }}>
                  <FieldArray
                    name={`${activity}.risks`}
                    component={renderRisks}
                  />
                </td>
                <td style={{ width: '20%' }}>
                  <FieldArray
                    name={`${activity}.controls`}
                    component={renderControls}
                  />
                </td>
                <td style={{ width: '10%' }}>
                  <MultiSelect
                    name={`${activity}.responsible`}
                    options={[
                      'HSE LEAD',
                      'Production Engineer',
                      'Production Incharge',
                      'Electrical Engineer',
                      'Mechanical Engineer',
                      'Instrument Engineer',
                      'Field Operator',
                      'Admin Operator',
                      'Panel Operator',
                      'Deputy IM',
                      'IM',
                      'Vendor',
                      'Fire Officer',
                      'Vessel Master'
                    ].map((role: string) => ({ value: role, label: role }))}
                    occupy={12}
                  />
                </td>
                <td style={{ width: '1%', color: 'red' }}>
                  <MdDelete onClick={() => fields.remove(index)} />
                </td>
              </tr>
            </React.Fragment>
          ))}
          <>
            {submitFailed && error && (
              <span style={{ color: 'red' }}>{error._error}</span>
            )}
          </>
        </tbody>
      </Table>
      <Button
        type="button"
        style={{ width: '100%' }}
        onClick={() => fields.push({})}
        color="warning"
      >
        Add Activity
      </Button>
    </div>
  )

  const teamList = allUsers?.map(
    (user: any) => `${user?.jobTitle}-${user?.givenName}`
  )

  const riskInitialData: any = localStorage.getItem('riskData')
  const risks = JSON.parse(riskInitialData)

  return (
    <ModalBody style={{ backgroundColor: 'white' }}>
      <ModalTitle title="Job Safety Analysis" height={50} />
      <Form
        onSubmit={(val: any) => {
          setValues({
            ...val,
            template: null,
            team: val?.team?.map((t: any) => t?.value),
            activities: val?.activities?.map((a: any) => ({
              name: a?.name,
              controls: a?.controls,
              risks: a?.risks,
              responsible: a?.responsible?.map((b: any) => b?.value)
            }))
          })
        }}
        initialValuesEqual={() => true}
        initialValues={{
          ...risks,
          team: risks && getOptions(risks?.team),
          activities: risks?.activities?.map((a: any) => ({
            name: a?.name,
            controls: a?.controls,
            risks: a?.risks,
            responsible: getOptions(a?.responsible)
          }))
        }}
        validate={validate}
        mutators={{
          ...arrayMutators
        }}
        render={({
          handleSubmit,
          form,
          hasValidationErrors,
          values,
          ...rest
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs="3">
                <CustomLabel label="Department" />
                <Field
                  name={'department'}
                  component="select"
                  style={{
                    width: '-webkit-fill-available',
                    height: 38,
                    borderRadius: 5
                  }}
                >
                  <option value="" disabled selected>
                    Select Department
                  </option>
                  <option value="Mechanical">Mechanical</option>
                  <option value="Electrical">Electrical</option>
                  <option value="Instrument">Instrument</option>
                  <option value="Production">Production</option>
                  <option value="HSE">HSE</option>
                  <option value="Admin Ware House">Admin Ware House</option>
                </Field>
              </Col>
              <Col xs="3">
                <CustomLabel label="Location" />
                <Field
                  name={'location'}
                  component="select"
                  style={{
                    width: '-webkit-fill-available',
                    height: 38,
                    borderRadius: 5
                  }}
                >
                  <option value="" disabled selected>
                    Select Location
                  </option>
                  <option value="Admin Ware House">Admin Ware House</option>
                  <option value="Fire Station">Fire Station</option>
                  <option value="Mechanical Workshop">
                    Mechanical Workshop
                  </option>
                  <option value="Substation">Substation</option>
                  <option value="Control Room">Control Room</option>
                  <option value="Process Area">Process Area</option>
                </Field>
              </Col>
              <LabeledDateTimeInput name="date" label="Date" occupy={3} />
              <LabeledRadioButtons
                name="toolBox"
                label="Toolbox Talk Conducted"
                list={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' }
                ]}
                defaultValue="Yes"
                occupy={3}
              />
              <MultiSelect
                name="team"
                label="Assesment Team"
                options={getOptions(teamList)}
                occupy={6}
              />
              <LabledParagraphInput
                name="task"
                label="Activity / Task"
                occupy={6}
              />
            </Row>
            <Spacer height={20} />
            <FieldArray name="activities" component={renderActivities} />
            <Spacer height={20} />
            <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
              <Button
                onClick={() => {
                  form.submit()
                  toggle()
                }}
                color={hasValidationErrors ? 'secondary' : 'success'}
                disabled={hasValidationErrors}
              >
                Save & Next
              </Button>
            </Row>
          </form>
        )}
      />
    </ModalBody>
  )
}
