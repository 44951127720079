import { Row } from 'reactstrap'
import AshokLeylandLayout from './AshokLeylandLayout'
import { IconCard } from '../components/Dashboard/IconCard'

export default function AlAdminActions() {
  const flows: any = [
    {
      name: 'CREW',
      nav: '/ashokleyland/admin/crew',
      src: require('../images/Icons/Crew.png')
    },
    {
      name: 'Venors',
      nav: '/ashokleyland/admin/vendors',
      src: require('../images/Icons/vendor.png')
    }
  ]

  return (
    <>
      <AshokLeylandLayout onBack="/ashokleyland" title="ADMIN">
        <Row style={{ margin: 10, fontSize: 20 }}>
          {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
            <IconCard
              key={`${index}-${name}`}
              name={name}
              nav={nav}
              canView={canView}
              count={count}
              src={src}
            />
          ))}
        </Row>
      </AshokLeylandLayout>
    </>
  )
}
