import { Field } from 'react-final-form'
import { Col, Input } from 'reactstrap'

const textArea: any = {
  border: '1px solid #CCC',
  borderRadius: 5,
  width: '100%',
  height: 40,
  paddingLeft: 5,
  background: '#DCDCDC',
  fontWeight: 500,
  color: '#000'
}

type InputType = {
  label?: string
  name?: any
  isDisabled?: boolean
  description?: any
  occupy?: number
  height?: any
}

const formLabelStyles = {
  fontWeight: 600,
  fontSize: 16,
  color: '#000',
  display: 'flex',
  margin: 0,
  marginBottom: 10,
  marginTop: 10
}

export default function LabledParagraphInput({
  label,
  name,
  isDisabled = false,
  description = false,
  occupy = 6,
  height = 40
}: InputType) {
  return (
    <Col md={occupy} key={`${name}*${label}`}>
      {label && <p style={formLabelStyles}>{label}</p>}
      {description && <p style={{ fontSize: 14, margin: 0 }}>{description}</p>}
      <Field name={name} style={textArea}>
        {({ input, meta }) => (
          <div key={`${name}^${label}`}>
            <Input
              {...input}
              type="textarea"
              style={{ ...textArea, height: height }}
              disabled={isDisabled}
            />
            {meta.error && meta.touched && (
              <span style={{ color: 'red', fontWeight: 400, fontSize: 14 }}>
                {meta.error}
              </span>
            )}
          </div>
        )}
      </Field>
    </Col>
  )
}
