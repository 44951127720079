import { Form } from "react-final-form"
import TextInput from "../components/InputFields/TextInput"
import Spacer from "../components/Spacer"

type NewUserType = {
  firstname: 'string'
}

export default function CreateUser() {

  const validate = (values: any) => {
    const errors: any = {}
    if (!values.firstname) {
      errors.firstname = 'Required'
    }
    if (!values.position) {
      errors.position = 'Required'
    }

    return errors
  }

  const createUser = (values: NewUserType) => {
    console.info(values)
  }

  return (
    <div style={{ backgroundColor: '#ccc', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div
        style={{
          backgroundColor: '#FFF',
          height: '80vh',
          width: '80vh',
          padding: 50,
          borderRadius: 15,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        >
        <h3>
          Create new user
        </h3>
        <Spacer height={15} />
        <Form
          onSubmit={(values: any) => createUser(values)}
          validate={(values: NewUserType) => validate(values)}
          render={({ handleSubmit, form, }) => (
            <form onSubmit={handleSubmit}>
              <TextInput name="firstname" label="First Name" />
              <Spacer height={10} />
              <TextInput name="lastname" label="Last Name" />
              <Spacer height={10} />
              <TextInput name="position" label="Position" />
              <Spacer height={10} />
              <TextInput name="bloodGroup" label="Blood Group" />
              <Spacer height={40} />
              <div style={{
                backgroundColor: 'brown',
                width: '80px',
                height: '30px',
                borderRadius: 5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }} onClick={() => form.submit()}>
                <p>
                  create
                </p>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  )
}