import { Row } from 'reactstrap'
import DashboardNavigation from './Dashboard/DashboardNavigation'
import { IconCard } from './Dashboard/IconCard'
import ProtectedLayout from './Layout/ProtectedLayout'

export default function ViewIcons({ icons, onBack }: any) {
  return (
    <ProtectedLayout
      onBack={onBack || '/eptw/dashboard'}
      hasSideList
      hasUserProfile
      sidePanel={<DashboardNavigation />}
    >
      <Row
        style={{ display: 'flex', marginLeft: 5, marginRight: 5, marginTop: 5 }}
      >
        {icons?.map(({ name, nav, src, canView, count }: any, index: any) => (
          <IconCard
            key={`${index}-${name}`}
            name={name}
            nav={nav}
            src={src}
            canView={canView}
            count={count}
          />
        ))}
      </Row>
    </ProtectedLayout>
  )
}
