import { Field } from 'react-final-form'

type InputType = {
  name: string
  placeholder?: string
  label?: string
}

const formLabelStyles = {
  fontWeight: 900,
  fontSize: 18,
  fontFamily: 'Arial',
  color: '#000',
  display: 'flex',
  marginBottom: 10,
  marginTop: 10
}

export default function TextInput({ name, placeholder, label }: InputType) {
  return (
    <div>
      <Field name={name}>
        {({ input, meta }) => (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {label && <p style={formLabelStyles}>{label}</p>}
              <input
                {...input}
                type="text"
                placeholder={placeholder}
                style={{
                  border: '1px solid #CCC',
                  borderRadius: 5,
                  outline: 'none',
                  height: '30px'
                }}
              />
            </div>
            {meta.error && meta.touched && (
              <>
                <span
                  style={{
                    fontSize: 12,
                    color: 'red'
                  }}
                >
                  * {meta.error}
                </span>
              </>
            )}
          </>
        )}
      </Field>
    </div>
  )
}
