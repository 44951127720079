import { useMsal } from '@azure/msal-react'
import React, { useContext, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Button, Row, Spinner, Table } from 'reactstrap'
import { makeAuthenticatedRequest } from '../apiRequests'
import { UserContext } from '../App'
import { incrementby1 } from '../components/Forms/JSAForm'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import { capitalizeFirstLetter } from '../components/lib/capitalizeFirstLetter'
import { liveIsolationsLTI } from '../components/serverurl'
import { EmptyContent } from './CreateNewPermit'

export default function LiveIsolationsLTI() {
  const { accounts, instance } = useMsal()
  const { userData }: any = useContext(UserContext)

  const fetchLiveIsolations = async () => {
    const response = await makeAuthenticatedRequest(
      `${liveIsolationsLTI}?department=${userData?.department}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading }: any = useQuery('lti', fetchLiveIsolations)

  const navigate = useNavigate()

  const [select, setSelect]: any = useState()

  const renderArrayAsList = (values: any) =>
    values?.map((v: any) => <li key={v}>{v}</li>)

  return (
    <ProtectedLayout onBack="/eptw/dashboard">
      <Row style={{ margin: 10 }}>
        {select && (
          <Button
            style={{ marginTop: 10 }}
            color="warning"
            onClick={() => {
              localStorage.setItem('createFromLTI', JSON.stringify(select))
              navigate('/createPermit')
            }}
          >
            Create Permit
          </Button>
        )}
        <Table
          striped
          bordered
          style={{ borderColor: '#000', fontSize: 14, marginTop: 10 }}
        >
          <thead>
            <tr style={{ backgroundColor: 'orange' }}>
              {[
                '',
                'S.No.',
                'Permit Number',
                'Location',
                'Lock Box',
                'Isolation Number',
                'Isolation Title',
                'Responsible Department',
                'Isolation Status'
              ]?.map((h: any) => <th key={h}>{h}</th>)}
            </tr>
          </thead>
          <tbody>
            {isLoading && !data && <EmptyContent label={<Spinner />} />}
            {data?.permits?.map((p: any, index: number) => (
              <React.Fragment key={p?.woNumber}>
                <tr>
                  <td style={{ verticalAlign: 'middle' }}>
                    <input
                      onChange={(e) => {
                        setSelect(p)
                      }}
                      type="checkbox"
                    />
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {incrementby1(index)}
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>{p?.woNumber}</td>
                  <td style={{ verticalAlign: 'middle' }}>{p?.workLocation}</td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {p?.isolations?.[0]?.lockBoxNumber}
                  </td>
                  <td>
                    {renderArrayAsList(
                      p?.isolations?.[0]?.isolations?.map((i: any) => i?.number)
                    )}
                  </td>
                  <td>
                    {renderArrayAsList(
                      p?.isolations?.[0]?.isolations?.map(
                        (i: any) => i?.isolationDescription
                      )
                    )}
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {p?.applicantDepartment}
                  </td>
                  <td>
                    {renderArrayAsList(
                      p?.isolations?.[0]?.isolations?.map((i: any) =>
                        capitalizeFirstLetter(i?.status)
                      )
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(data?.totalDocuments / 20)}
          marginPagesDisplayed={10}
          pageRangeDisplayed={2}
          // onPageChange={async (values) => {
          //   await setPageNumber(values.selected + 1)
          //   refetch()
          // }}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </Row>
    </ProtectedLayout>
  )
}
