import moment from 'moment'
import React, { useContext, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import AutoCompleteAndApi from '../../components/InputFields/AutoCompleteAndAPI'
import CustomLabel from '../../components/InputFields/CustomLabel'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import LabeledTextInput from '../../components/InputFields/LabeledTextInput'
import LabledParagraphInput from '../../components/InputFields/LabledParagraph'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import toNormalCase from '../../components/lib/toNormalCase'
import { Box, renderData } from './PmTree'
import { Button, Col, ModalFooter, Row, Table } from 'reactstrap'
import { capitalizeFirstLetter } from '../../components/lib/capitalizeFirstLetter'
import { FormSection } from './WorkRequestForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { UserContext } from '../../App'
import { workOrderAPI } from '../../components/serverurl'
import { SubHeader } from '../VerifyCM'

const WOPreview = ({
  formValues,
  toggle,
  isOpen,
  status,
  refetch
}: {
  formValues: any
  toggle: any
  isOpen: any
  status: any
  refetch: any
}) => {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const { userData } = useContext(UserContext)

  const generalDetails: any = {
    'Vessel / Rig': formValues?.vessel,
    'WO description': formValues?.description,
    'Created Date': moment().format('DD-MM-YYYY'),
    'Reported By': formValues?.reportedBy,
    'Tag ID': formValues?.tagId,
    'Tag Description': formValues?.tagDescription,
    'Tag Type': formValues?.tagType,
    Function: formValues?.function,
    'Function Description': formValues?.functionDescription
  }

  const planningInformation: any = {
    Department: capitalizeFirstLetter(formValues?.department),
    'Work Type': toNormalCase(formValues?.workType),
    // 'Operation Status': capitalizeFirstLetter(formValues?.operationStatus),
    Priority: formValues?.priority,
    Criticality: formValues?.criticality,
    'Estimated Hours': formValues?.estimatedHours
  }

  const prepare: any = {
    'Work Description': formValues?.problemNoticed,
    'Job to be Done': formValues?.jobPending
  }

  const onSubmit = async () => {
    try {
      const res: any = await makeAuthenticatedRequest(
        `${workOrderAPI}/${id}`,
        'PATCH',
        {
          ...formValues,
          lastestUpdatedBy: userData?.id,
          woStatus: status,
          id: id
        },
        accounts,
        instance
      )

      if (res?.status === 'success') {
        await refetch()
      }

      return res?.status
    } catch (error) {
      console.error(error)
    }
  }

  const header: any = {
    wrDraft: 'Draft',
    wrCreated: 'Create',
    wrDeleted: 'Delete'
  }

  return (
    <>
      <SubmitModalWithForm
        isOpen={isOpen}
        onClose={(val: any) => {
          toggle()
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()
          const res = await onSubmit()
          return res
        }}
        size="lg"
        header={`${header[status]} Work Request`}
        form={
          <Row style={{ margin: 10 }}>
            <Box heading="General Details">
              {Object.keys(generalDetails)?.map((key: any) =>
                renderData(key, generalDetails[key])
              )}
            </Box>
            <Box heading="Planning Information">
              {Object.keys(planningInformation)?.map((key: any) =>
                renderData(key, planningInformation[key])
              )}
            </Box>
            <Box heading="Prepare" height={'5vh'}>
              {Object.keys(prepare)?.map((key: any) =>
                renderData(key, prepare[key])
              )}
            </Box>
          </Row>
        }
      />
    </>
  )
}

export default function EditWRForm({ onBack }: { onBack: any }) {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const [preview, setPreview]: any = useState(false)
  const [status, setStatus]: any = useState()

  const fetchWorkRequests = async () => {
    const response = await makeAuthenticatedRequest(
      `${workOrderAPI}/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, isRefetching, refetch }: any = useQuery(
    'workRequests',
    fetchWorkRequests
  )

  const workOrder = data?.workrequest

  return (
    <ProtectedLayout title="Work Request - Draft" onBack={onBack}>
      <SubHeader header={workOrder?.number} permitColor="gold" />
      <Form
        onSubmit={(values: any) => {}}
        initialValues={{
          ...workOrder
        }}
        render={({ values, form, hasValidationErrors }) => (
          <form>
            <FormSection title="General Details">
              <LabeledTextInput
                name="vessel"
                label="Vessel / Rig *"
                isDisabled
                occupy={3}
              />
              <LabledParagraphInput
                name="description"
                label="WO Description  *"
                occupy={6}
              />
              <LabeledTextInput
                name="reportedBy"
                label="Reported By"
                isDisabled
                occupy={3}
              />
              <Col xs="3">
                <CustomLabel label="Tag" />
                <Field
                  name="tag"
                  style={{ background: '#DCDCDC' }}
                  value={workOrder?.tagId}
                >
                  {({ input, ...rest }) => (
                    <div>
                      <AutoCompleteAndApi
                        value={input?.value || workOrder?.tagId}
                        isMulti={false}
                        name="tagId"
                        url={`${workOrderAPI}/filters`}
                        handleChange={(option: any, change: any) => {
                          input.onChange(option?.value)
                          change('tagId', option?.value)
                          change('tagDescription', option?.description)
                          change('tagType', option?.tagType)
                          change(
                            'functionDescription',
                            option?.functionDescription
                          )
                          change('function', option?.function)
                          change('criticality', option?.criticality)
                          change('vessel', option?.vessel)
                        }}
                        needSelect={false}
                        input={input}
                        {...rest}
                      />
                    </div>
                  )}
                </Field>
              </Col>
              <LabeledTextInput
                name="tagDescription"
                label="Tag Description *"
                isDisabled
                occupy={6}
              />
              <LabeledTextInput
                name="tagType"
                label="Tag Type *"
                isDisabled
                occupy={3}
              />
              <LabeledTextInput
                name="function"
                label="Function Number *"
                isDisabled
                occupy={3}
              />
              <LabeledTextInput
                name="functionDescription"
                label="Function Description *"
                isDisabled
                occupy={6}
              />
              <LabeledTextInput
                name="criticality"
                label="Equipment Criticality *"
                isDisabled
                occupy={3}
              />
              <LabeledTextInput
                name="createdOn"
                label="Created Date"
                value={moment().format('DD-MM-YYYY hh:mm:ss')}
                occupy={3}
              />
              <LabeledTextInput
                label="Incident Report Number"
                name="incidentReportNumber"
                occupy={3}
              />
            </FormSection>
            <FormSection title="Planning Information">
              <Col xs="3">
                <CustomLabel label="Department *" />
                <Field
                  name={'department'}
                  component="select"
                  defaultValue=""
                  style={{
                    width: '-webkit-fill-available',
                    height: 38,
                    borderRadius: 5,
                    backgroundColor: '#DCDCDC'
                  }}
                >
                  <option value="" disabled>
                    Select Department
                  </option>
                  <option value="mechanical">Mechanical</option>
                  <option value="electrical">Electrical</option>
                  <option value="instrument">Instrument</option>
                  <option value="production">Production</option>
                  <option value="hse">HSE</option>
                  <option value="adminWareHouse">Admin Ware House</option>
                </Field>
              </Col>
              <Col xs="3">
                <CustomLabel label="Work Type *" />
                <Field
                  name={'workType'}
                  component="select"
                  defaultValue=""
                  style={{
                    width: '-webkit-fill-available',
                    height: 38,
                    borderRadius: 5,
                    backgroundColor: '#DCDCDC'
                  }}
                >
                  <option value="" disabled>
                    Select Work Type
                  </option>
                  <option value="correctiveMaintenance">
                    Corrective Maintenance
                  </option>
                  <option value="breakdownMaintenance">
                    Breakdown Maintenance
                  </option>
                  <option value="modification">Modification</option>
                </Field>
              </Col>
              <Col xs="3">
                <CustomLabel label="Priority *" />
                <Field
                  name={'priority'}
                  component="select"
                  placeholder="s"
                  style={{
                    width: '-webkit-fill-available',
                    height: 38,
                    borderRadius: 5,
                    backgroundColor: '#DCDCDC'
                  }}
                >
                  <option value="" disabled>
                    Select Priority
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </Field>
              </Col>
              <LabeledTextInput
                name="estimatedHours"
                label="Estimated Hours *"
                occupy={3}
              />
            </FormSection>
            <FormSection title="Prepare">
              <LabledParagraphInput
                name="problemNoticed"
                label="Work Description *"
                occupy={12}
              />
              <LabledParagraphInput
                name="jobPending"
                label="Job to be Done *"
                occupy={12}
              />
            </FormSection>
            {workOrder?.woStatus === 'wrDraft' && (
              <ModalFooter>
                <Button
                  color={'danger'}
                  onClick={() => {
                    setStatus('wrDeleted')
                    setPreview(true)
                  }}
                >
                  Delete
                </Button>
                <Button
                  color={'warning'}
                  onClick={() => {
                    setStatus('wrDraft')
                    setPreview(true)
                  }}
                >
                  Save
                </Button>
                <Button
                  color={hasValidationErrors ? 'secondary' : 'warning'}
                  onClick={() => {
                    setStatus('wrCreated')
                    setPreview(true)
                  }}
                  disabled={hasValidationErrors}
                >
                  Submit
                </Button>
              </ModalFooter>
            )}{' '}
            <WOPreview
              isOpen={preview}
              toggle={() => setPreview(!preview)}
              formValues={values}
              status={status}
              refetch={refetch}
            />
          </form>
        )}
      />
    </ProtectedLayout>
  )
}
