import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function HandleBackIsolations() {
  const navigate = useNavigate()

  useEffect(() => navigate('/eptw/newPermit'))

  return <></>
}
