import { Route, Routes } from 'react-router-dom'

import ApproveCWOI from '../pages/cwoi/ApproveCWOI'
import IssueCWOI from '../pages/cwoi/IssueCWOI'
import PermitsByStatus from '../pages/deIsolationAndClose/ViewPermits'
import VerifyCWOI from '../pages/cwoi/VerifyCWOI'
import FetchPermitsByLocation from '../pages/FetchPermitsByLocation'

export default function CWOIRoutes() {
  return (
    <Routes>
      <Route
        path="/verify"
        element={
          <PermitsByStatus currentPermits="Initiated CWOI" flow={'cwoi'} />
        }
      />
      <Route path="/verify/:id" element={<VerifyCWOI />} />
      <Route
        path="/approve"
        element={<FetchPermitsByLocation status="Verified CWOI" flow="cwoi" />}
      />
      <Route path="/approve/:id" element={<ApproveCWOI />} />
      <Route
        path="/issue"
        element={
          <PermitsByStatus currentPermits="Approved CWOI" flow={'cwoi'} />
        }
      />
      <Route path="/issue/:id" element={<IssueCWOI />} />
    </Routes>
  )
}
