import { useMsal } from '@azure/msal-react'
import moment from 'moment'
import { useContext, useState } from 'react'
import { Field, Form, useForm } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap'

import { makeAuthenticatedRequest } from '../apiRequests'
import { UserContext } from '../App'
import CustomLabel from '../components/InputFields/CustomLabel'
import LabeledDateTimeInput from '../components/InputFields/DateTime'
import LabeledCheckBoxes from '../components/InputFields/LabeledCheckBoxes'
import LabeledRadioButtons from '../components/InputFields/LabeledRadioButton'
import LabeledTextInput from '../components/InputFields/LabeledTextInput'
import LabledParagraphInput from '../components/InputFields/LabledParagraph'
import toNormalCase from '../components/lib/toNormalCase'
import SubmitModalWithForm from '../components/Modals/SubmitModalWithForm'
import { alEquipmentApi } from '../components/serverurl'
import Spacer from '../components/Spacer'
import { yesNoOptions } from '../pages/eptw/VerifyPermit'
import {
  getOptions,
  incidentType,
  injuryDetails,
  partOfBody
} from '../pages/firs'
import { SubHeader } from '../pages/VerifyCM'
import { Box, renderData } from '../pages/workManagement/PmTree'
import { alDepartments, LabeledDropdown } from './AlViewWo'
import { alEquipment } from './AlWorkRequestForm'
import AshokLeylandLayout from './AshokLeylandLayout'

const initialValues = {
  general: {
    originator: 'Alejandro',
    location: 'R&D Chennai',
    incidentTitle: 'High-Pressure Pipeline Leak at Well Site A',
    dateOfIncident: '2024-08-20',
    department: 'Canteen & Security',
    incidentType: 'Injury/ Illness'
  },
  injured: {
    victimName: 'Vinod',
    victimEmployer: 'ASHOK LEYLAND',
    dateOfBirth: '2024-01-01',
    jobTitle: 'Engineer',
    Experienceh: '23',
    age: '45',
    gender: 'Male',
    employeementType: 'Full Time',
    dutyType: 'On duty',
    injuryDetails: ['Concussion', 'Dislocation', 'Open Wounds'],
    partOfBody: ['Arm / wrist', 'Hand / Finger', 'Multiple', 'shoulder'],
    service: 'Medical Evacuation',
    immediateDescription: 'High-Pressure Pipeline Leak at Well Site A',
    actionRequired: 'Yes',
    productionLoss: 'Yes'
  },
  personalInvolved: {
    departmentHod: 'Kishore',
    safetyCommitee: 'Vijay',
    safetyEhs: 'Ramesh',
    witness: 'Vamshi'
  }
}

const AlIRPreview = ({ values, preview, setPreview }: any) => {
  const { general, injured, personalInvolved } = values || {}
  const { accounts, instance } = useMsal()
  const [modal, setModal] = useState(false)
  const [result, setResult]: any = useState({})
  const navigate = useNavigate()
  return (
    <Modal isOpen={preview} size="xl">
      <ModalBody>
        <SubHeader header="Incident Report Details" permitColor="gold" />
        <Row>
          <Col>
            <Box heading="General Details">
              {Object.keys(general)?.map((k: any) =>
                renderData(toNormalCase(k), general[k])
              )}
            </Box>
            <Box heading="Injured Details">
              {Object.keys(injured)?.map((k: any) =>
                renderData(toNormalCase(k), injured[k])
              )}
            </Box>
            <Box heading="Personal Involved">
              {Object.keys(personalInvolved)?.map((k: any) =>
                renderData(toNormalCase(k), personalInvolved[k])
              )}
            </Box>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={() => setPreview(false)}>
          Close
        </Button>
        <Button color="warning" onClick={() => setModal(true)}>
          Submit
        </Button>
      </ModalFooter>
      <SubmitModalWithForm
        isOpen={modal}
        onClose={(val: any) => {
          setModal(false)

          if (val) {
            navigate('/ashokleyland/firs/incidentreporting')
          }
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()

          const res = await makeAuthenticatedRequest(
            `${alEquipmentApi}/firs`,
            'POST',
            values,
            accounts,
            instance
          )

          if (res?.status === 'success') {
            setResult(res?.incident)
          }

          return res?.status
        }}
        size="xs"
        header="Create Incident Report"
        sucessView={
          <div>
            {renderData('Incident Report Number', result?.number)}
            {renderData(
              'Incident Report Title',
              result?.general?.incidentTitle
            )}
          </div>
        }
      />
    </Modal>
  )
}

export default function AlStage1Form({
  title,
  onBack
}: {
  title: string
  onBack: string
}) {
  const { userData } = useContext(UserContext)
  const [preview, setPreview] = useState(false)

  const TagDropDown = () => {
    const { change } = useForm()
    return (
      <Input
        style={{ background: '#DCDCDC' }}
        type="select"
        onChange={(e: any) => {
          console.log(e?.target?.value)
          const eq: any = alEquipment?.filter(
            (t: any) => t?.tagNumber === e?.target?.value
          )?.[0]
          change('tagId', eq?.tagNumber)
          change('tagDescription', eq?.description)
          change('location', eq?.location)
        }}
      >
        {alEquipment?.map((e: any) => (
          <option key={e?._id}>{e?.tagNumber}</option>
        ))}
      </Input>
    )
  }

  const generalDetailsObj = 'general'
  const injuredDetails = 'injured'
  const personalInvolved = 'personalInvolved'

  return (
    <AshokLeylandLayout title={title} onBack={onBack}>
      <Row style={{ margin: 15 }}>
        <Col>
          <Form
            onSubmit={(values) => console.log(values)}
            initialValues={{
              ...initialValues
            }}
            render={({ values, form }) => (
              <form>
                <>
                  <Row>
                    <LabeledTextInput
                      name={`${generalDetailsObj}.originator`}
                      label="Originator"
                      occupy={4}
                      isDisabled
                    />
                    <LabeledTextInput
                      name={`${generalDetailsObj}.incidentTitle`}
                      label="Incident Title"
                      occupy={8}
                    />
                    <Col xs="4">
                      <CustomLabel label="Tag" />
                      <Field
                        name={`${generalDetailsObj}.tag`}
                        style={{ background: '#DCDCDC' }}
                      >
                        {({ input, ...rest }) => <TagDropDown />}
                      </Field>
                    </Col>
                    <LabeledTextInput
                      name={`${generalDetailsObj}.location`}
                      label="Location"
                      occupy={4}
                      isDisabled
                    />
                    <LabeledDropdown
                      name={`${generalDetailsObj}.department`}
                      label="Department"
                      options={alDepartments}
                      occupy={4}
                    />
                    <LabeledDateTimeInput
                      name={`${generalDetailsObj}.dateOfIncident`}
                      label="Date and Time of Incident"
                      occupy={4}
                    />
                    <LabledParagraphInput
                      name={`${generalDetailsObj}.description`}
                      label="Description of the Incident"
                      occupy={4}
                    />
                    <LabeledDropdown
                      name={`${generalDetailsObj}.incidentType`}
                      label="Incident Type"
                      options={incidentType}
                      occupy={4}
                    />
                    <LabeledDropdown
                      name={`${generalDetailsObj}.repeatability`}
                      label="Repeatability"
                      options={[
                        'First Time',
                        'Second Time',
                        'Third Time',
                        'Not Happend'
                      ]}
                      occupy={4}
                    />
                  </Row>
                  <Spacer height={10} />
                  <SubHeader header="Details of Injured" />
                  <Spacer height={10} />
                  <Row>
                    <LabeledTextInput
                      name={`${injuredDetails}.victimName`}
                      label="Name"
                      occupy={4}
                    />
                    <LabeledTextInput
                      name={`${injuredDetails}.victimEmployer`}
                      label="Employer"
                      occupy={4}
                    />
                    <LabeledDateTimeInput
                      name={`${injuredDetails}.dateOfBirth`}
                      label="Date of Birth"
                      occupy={4}
                    />
                    <LabeledTextInput
                      name={`${injuredDetails}.jobTitle`}
                      label="Job Title"
                      occupy={4}
                    />
                    <LabeledTextInput
                      name={`${injuredDetails}.Experienceh`}
                      label="Experience"
                      occupy={4}
                    />
                    <LabeledTextInput
                      name={`${injuredDetails}.age`}
                      label="Age"
                      occupy={4}
                    />
                    <LabeledRadioButtons
                      name={`${injuredDetails}.gender`}
                      label="Gender"
                      list={[
                        { label: 'Male', value: 'Male' },
                        { label: 'Female', value: 'Female' }
                      ]}
                      occupy={4}
                    />
                    <LabeledRadioButtons
                      name={`${injuredDetails}.employeementType`}
                      label="Employment Type"
                      list={getOptions([
                        'Full Time',
                        'Part Time',
                        'Contractor',
                        'Contracted Directly',
                        'Service Company',
                        'Others'
                      ])}
                      occupy={4}
                    />
                    <LabeledRadioButtons
                      name={`${injuredDetails}.dutyType`}
                      label="Duty Type at time of injury"
                      list={getOptions([
                        'On duty',
                        'Off duty',
                        'Travelling to / from work'
                      ])}
                      occupy={4}
                    />
                    <LabeledCheckBoxes
                      name={`${injuredDetails}.injuryDetails`}
                      label="Injury Details"
                      list={getOptions(injuryDetails)}
                      hasOthers
                      othersName="others_injuryDetails"
                      occupy={12}
                    />
                    <LabeledCheckBoxes
                      name={`${injuredDetails}.partOfBody`}
                      label="Part of Body Injured"
                      list={getOptions(partOfBody)}
                      occupy={12}
                    />
                    <LabeledRadioButtons
                      name={`${injuredDetails}.service`}
                      label="Service"
                      list={getOptions([
                        'Medical Evacuation',
                        'Scheduled Service',
                        'Unscheduled Service'
                      ])}
                      occupy={4}
                    />
                    <LabeledRadioButtons
                      name={`${injuredDetails}.actionRequired`}
                      label="Immediate Action Required"
                      list={yesNoOptions}
                      occupy={4}
                    />
                    <LabeledRadioButtons
                      name={`${injuredDetails}.productionLoss`}
                      label="Loss of Deferment of Production"
                      list={yesNoOptions}
                      occupy={4}
                    />
                    <LabledParagraphInput
                      name={`${injuredDetails}.immediateDescription`}
                      label="Immediate Action Description"
                      occupy={12}
                    />
                  </Row>
                  <Spacer height={10} />
                  <SubHeader header="Personal Involved during Incident" />
                  <Spacer height={10} />
                  <Row>
                    <LabeledTextInput
                      name={`${personalInvolved}.departmentHod`}
                      label="Department HOD"
                      occupy={12}
                    />
                    <LabeledTextInput
                      name={`${personalInvolved}.safetyCommitee`}
                      label="Safety Commitee Member"
                      occupy={12}
                    />
                    <LabeledTextInput
                      name={`${personalInvolved}.safetyEhs`}
                      label="Safety EHS"
                      occupy={12}
                    />
                    <LabeledTextInput
                      name={`${personalInvolved}.witness`}
                      label="Witness"
                      occupy={12}
                    />
                  </Row>
                  <Spacer height={15} />
                  <Row>
                    <Col xs="1">
                      <p>Attachments :</p>
                    </Col>
                    <Col xs="11">
                      <Input type="file" accept="application/pdf" />
                    </Col>
                  </Row>
                </>
                <ModalFooter>
                  <Button color="warning" onClick={() => setPreview(true)}>
                    Preview
                  </Button>
                </ModalFooter>
                <AlIRPreview
                  values={values}
                  preview={preview}
                  setPreview={setPreview}
                />
              </form>
            )}
          />
        </Col>
      </Row>
    </AshokLeylandLayout>
  )
}
