import { useContext } from 'react'
import { Col, Row } from 'reactstrap'
import { UserContext } from '../../App'
import { IconCard } from '../../components/Dashboard/IconCard'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import { Box } from '../workManagement/PmTree'

const hasAccess: any = {
  canCreate: ['Purchaser', 'Warehouse Admin'],
  canApprove: ['INSTALLATION MANAGER'],
  canApproved: ['Purchaser'],
  canrfq: ['Purchaser'],
  canCreatePO: ['Purchaser'],
  canApprovePO: ['INSTALLATION MANAGER'],
  canissued: ['Purchaser']
}

export default function PurchaseDashboard() {
  const { userData } = useContext(UserContext)
  const flows: any = [
    {
      name: 'CREATE PURCHASE REQUEST',
      nav: '/purchase/create',
      canView: hasAccess?.['canCreate']?.includes(userData?.jobTitle),
      src: require('./../../images/Icons/Create Purchase Request.png'),
      level: 1
    },
    {
      name: 'APPROVE PURCHASE REQUEST',
      nav: '/purchase/approve',
      canView: hasAccess?.['canApprove']?.includes(userData?.jobTitle),
      src: require('./../../images/Icons/Approve Purchase Request.png'),
      level: 1
    },
    {
      name: 'CREATE REQUEST FOR QUOTATION',
      nav: '/purchase/rfq',
      canView: hasAccess?.['canrfq']?.includes(userData?.jobTitle),
      src: require('./../../images/Icons/Create Request for Quotation.png'),
      level: 2
    },
    {
      name: 'QUOTATIONS FROM VENDORS',
      nav: '/purchase/rfqVendors',
      canView: hasAccess?.['canrfq']?.includes(userData?.jobTitle),
      src: require('./../../images/Icons/quotationFromVendor.png'),
      level: 2
    },

    {
      name: 'APPROVE Level One PURCHASE ORDER',
      nav: '/purchase/wpo',
      canView: hasAccess?.['canApprovePO']?.includes(userData?.jobTitle),
      src: require('./../../images/Icons/approve1.png'),
      level: 2
    },
    {
      name: 'APPROVE Level Two PURCHASE ORDER',
      nav: '/purchase/apoLevelTwo',
      canView: hasAccess?.['canApprovePO']?.includes(userData?.jobTitle),
      src: require('./../../images/Icons/approve2.png'),
      level: 2
    },
    {
      name: 'APPROVE Level Three PURCHASE ORDER',
      nav: '/purchase/apoLevelThree',
      canView: hasAccess?.['canApprovePO']?.includes(userData?.jobTitle),
      src: require('./../../images/Icons/approve3.png'),
      level: 2
    },
    {
      name: 'APPROVED PURCHASE ORDER',
      nav: '/purchase/apo',
      canView: hasAccess?.['canissued']?.includes(userData?.jobTitle),
      src: require('./../../images/Icons/Approved Purchase order.png'),
      level: 3
    },
    {
      name: 'ISSUED PURCHASE ORDER',
      nav: '/purchase/ipo',
      canView: hasAccess?.['canissued']?.includes(userData?.jobTitle),
      src: require('./../../images/Icons/Issued Purchase order.png'),
      level: 3
    },
    {
      name: 'PO ACCEPTED BY VENDOR',
      nav: '/purchase/vendorAcceptedPO',
      canView: hasAccess?.['canissued']?.includes(userData?.jobTitle),
      src: require('./../../images/Icons/PO Accepted by Vendor.png'),
      level: 3
    },
    {
      name: "To's CREATED",
      nav: '/purchase/createdTOs',
      canView: hasAccess?.['canissued']?.includes(userData?.jobTitle),
      src: require('./../../images/Icons/toCreated.png'),
      level: 4
    },
    {
      name: 'SPARES RECEIVED ONSHORE',
      nav: '/purchase/sparesReceivedOnshore',
      canView: hasAccess?.['canissued']?.includes(userData?.jobTitle),
      src: require('./../../images/Icons/Spares Received Onshore.png'),
      level: 4
    },
    {
      name: 'CONSIGNMENT MANIFEST',
      nav: '/purchase/consignmentManifest',
      canView: hasAccess?.['canissued']?.includes(userData?.jobTitle),
      src: require('./../../images/Icons/cmManifest.png'),
      level: 4
    },
    {
      name: 'VERIFY STOCK',
      nav: '/purchase/verifyStock',
      canView: hasAccess?.['canissued']?.includes(userData?.jobTitle),
      src: require('./../../images/Icons/verifyStock.png'),
      level: 4
    },
    {
      name: 'COMPLETED PO',
      nav: '/purchase/closedPO',
      canView: hasAccess?.['canissued']?.includes(userData?.jobTitle),
      src: require('./../../images/Icons/Completed Purchase order.png'),
      level: 4
    },
    {
      name: 'DISCREPANCY',
      nav: '/purchase/discripancies',
      canView: hasAccess?.['canissued']?.includes(userData?.jobTitle),
      src: require('./../../images/Icons/Dicripancies.png'),
      level: 5
    },
    {
      name: 'REQUEST FOR PAYMENTS (PURCHASER)',
      nav: '/purchase/vendorPayments',
      canView: hasAccess?.['canissued']?.includes(userData?.jobTitle),
      src: require('./../../../src/images/Icons/requestForPaymentP.png'),
      level: 6
    },
    {
      name: 'APPROVE PAYMENT REQUESTS',
      nav: '/purchase/approvePayments',
      canView: hasAccess?.['canissued']?.includes(userData?.jobTitle),
      src: require('./../../../src/images/Icons/approvePaymentRequest.png'),
      level: 6
    },
    {
      name: 'APPROVED PAYMENT REQUESTS',
      nav: '/purchase/approvedPayments',
      canView: hasAccess?.['canissued']?.includes(userData?.jobTitle),
      src: require('./../../../src/images/Icons/approvedPaymentRequest.png'),
      level: 6
    }
  ]
  return (
    <>
      <ProtectedLayout onBack="/inventory/services" title="PURCHASE">
        <Row style={{ margin: 10 }}>
          {/* {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
            <IconCard
              key={`${index}-${name}`}
              name={name}
              nav={nav}
              // canView={canView}
              count={count}
              src={src}
            />
          ))} */}
          <Row style={{ display: 'flex' }}>
            <Col xs="2">
              <Box heading="Level 1" headingCenter>
                <Col style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {flows
                    ?.filter((f: any) => f?.level === 1)
                    .map(
                      ({ name, nav, count, canView, src }: any, index: any) => (
                        <IconCard
                          key={`${index}-${name}`}
                          name={name}
                          nav={nav}
                          count={count}
                          src={src}
                        />
                      )
                    )}
                </Col>
              </Box>
            </Col>
            <Col xs="2">
              <Box heading="Level 2" headingCenter>
                <Col style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {flows
                    ?.filter((f: any) => f?.level === 2)
                    .map(
                      ({ name, nav, count, canView, src }: any, index: any) => (
                        <IconCard
                          key={`${index}-${name}`}
                          name={name}
                          nav={nav}
                          count={count}
                          src={src}
                        />
                      )
                    )}
                </Col>
              </Box>
            </Col>
            <Col xs="2">
              <Box heading="Level 3" headingCenter>
                <Col style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {flows
                    ?.filter((f: any) => f?.level === 3)
                    .map(
                      ({ name, nav, count, canView, src }: any, index: any) => (
                        <IconCard
                          key={`${index}-${name}`}
                          name={name}
                          nav={nav}
                          count={count}
                          src={src}
                        />
                      )
                    )}
                </Col>
              </Box>
            </Col>
            <Col xs="2">
              <Box heading="Level 4" headingCenter>
                <Col style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {flows
                    ?.filter((f: any) => f?.level === 4)
                    .map(
                      ({ name, nav, count, canView, src }: any, index: any) => (
                        <IconCard
                          key={`${index}-${name}`}
                          name={name}
                          nav={nav}
                          count={count}
                          src={src}
                        />
                      )
                    )}
                </Col>
              </Box>
            </Col>
            <Col xs="2">
              <Box heading="Level 5" headingCenter>
                <Col style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {flows
                    ?.filter((f: any) => f?.level === 5)
                    .map(
                      ({ name, nav, count, canView, src }: any, index: any) => (
                        <IconCard
                          key={`${index}-${name}`}
                          name={name}
                          nav={nav}
                          count={count}
                          src={src}
                        />
                      )
                    )}
                </Col>
              </Box>
            </Col>
            <Col xs="2">
              <Box heading="Level 6" headingCenter>
                <Col style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {flows
                    ?.filter((f: any) => f?.level === 6)
                    .map(
                      ({ name, nav, count, canView, src }: any, index: any) => (
                        <IconCard
                          key={`${index}-${name}`}
                          name={name}
                          nav={nav}
                          count={count}
                          src={src}
                        />
                      )
                    )}
                </Col>
              </Box>
            </Col>
          </Row>
        </Row>
      </ProtectedLayout>
    </>
  )
}
