import { Row } from 'reactstrap'
import { useContext } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import DashboardNavigation, {
  canViewPermitAudit
} from '../components/Dashboard/DashboardNavigation'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import toNormalCase from '../components/lib/toNormalCase'
import { countsEndpoint, epermit } from '../components/serverurl'
import { IconCard } from '../components/Dashboard/IconCard'
import { makeAuthenticatedRequest } from '../apiRequests'
import { PermissionsContext, UserContext } from '../App'
import {
  getCIOCounts,
  getCWOICounts,
  getDICCounts,
  getLTICounts,
  getNewPermitCounts,
  getrevalidateCounts,
  getWOICounts
} from '../components/getCounts'

export default function Dashboard() {
  const { accounts, instance } = useMsal()
  const { permissions } = useContext(PermissionsContext)
  const { userData } = useContext(UserContext)
  const userlocation = toNormalCase(permissions?.location)

  const newPermitsCounts = async () => {
    const response = await makeAuthenticatedRequest(
      `${countsEndpoint}/newPermit?department=${userData?.department}&location=${userlocation}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response?.counts
  }

  const { data: newPermitData }: any = useQuery(
    'newPermitCount',
    newPermitsCounts
  )

  const revalidateCounts = async () => {
    const response = await makeAuthenticatedRequest(
      `${countsEndpoint}/revalidate?department=${userData?.department}&location=${userlocation}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response?.counts
  }

  const { data: revalidateCountsData }: any = useQuery(
    'revalidateCounts',
    revalidateCounts
  )

  const dicCounts = async () => {
    const response = await makeAuthenticatedRequest(
      `${countsEndpoint}/dic?department=${userData?.department}&location=${userlocation}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response?.counts
  }

  const { data: dicCountsData }: any = useQuery('dicCounts', dicCounts)

  const ltiCounts = async () => {
    const response = await makeAuthenticatedRequest(
      `${countsEndpoint}/lti?department=${userData?.department}&location=${userlocation}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response?.counts
  }

  const { data: ltiCountsData }: any = useQuery('ltiCountsData', ltiCounts)

  const cioCounts = async () => {
    const response = await makeAuthenticatedRequest(
      `${countsEndpoint}/cio?department=${userData?.department}&location=${userlocation}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response?.counts
  }

  const checkForOverdue = async () => {
    const response = await makeAuthenticatedRequest(
      `${epermit}/overdue`,
      'GET',
      null,
      accounts,
      instance
    )

    return response?.counts
  }

  const { data: cioCountsData }: any = useQuery('cioCounts', cioCounts)
  const { data: overdue }: any = useQuery('overdueCheck', checkForOverdue)

  const woiCounts = async () => {
    const response = await makeAuthenticatedRequest(
      `${countsEndpoint}/woi?department=${userData?.department}&location=${userlocation}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response?.counts
  }

  const { data: woiCountsData }: any = useQuery('woiCounts', woiCounts)

  const cwoiCounts = async () => {
    const response = await makeAuthenticatedRequest(
      `${countsEndpoint}/cwoi?department=${userData?.department}&location=${userlocation}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response?.counts
  }

  const { data: cwoiCountsData }: any = useQuery('cwoiCounts', cwoiCounts)

  const generalCounts = async () => {
    const response = await makeAuthenticatedRequest(
      `${countsEndpoint}/general?department=${userData?.department}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response?.counts
  }

  const { data: generalCountsData }: any = useQuery(
    'cwogeneralCountsiCounts',
    generalCounts
  )

  const flows: any = [
    {
      src: require('./../../src/images/Icons/Create_New_Permit.png'),
      name: 'CREATE NEW',
      nav: '/createPermit',
      canView: permissions?.canCreate
    },
    {
      src: require('./../../src/images/Icons/CreateFromPID.png'),
      name: 'CREATE FROM P&ID',
      nav: '/eptw/createFromPID',
      canView: permissions?.canCreate
    },
    {
      src: require('./../../src/images/Icons/Draft.png'),
      name: 'DRAFT',
      nav: '/eptw/drafts',
      canView: permissions?.canCreate,
      count: generalCountsData?.draft
    },
    {
      src: require('./../../src/images/Icons/Permits with isolations.png'),
      name: 'WITH ISOLATION',
      nav: '/eptw/newPermit',
      count: getNewPermitCounts(permissions, newPermitData)
    },
    {
      src: require('./../images/Icons/Revalidate.png'),
      name: 'REVALIDATE',
      nav: '/eptw/revalidate',
      count: getrevalidateCounts(permissions, revalidateCountsData)
    },
    {
      src: require('./../images/Icons/DIC_Icon.png'),
      name: 'DEISOLATION AND CLOSE',
      nav: '/eptw/deisolationAndClose',
      count: getDICCounts(permissions, dicCountsData)
    },
    {
      src: require('./../images/Icons/LTI.png'),
      name: 'LONG TERM ISOLATION',
      nav: '/eptw/lti',
      count: getLTICounts(permissions, ltiCountsData)
    },
    {
      src: require('./../../src/images/Icons/Change in ownership.png'),
      name: 'CHANGE IN OWNERSHIP',
      nav: '/eptw/cio',
      count: getCIOCounts(permissions, cioCountsData)
    },
    {
      src: require('./../../src/images/Icons/Permits without isolations.png'),
      name: 'WITHOUT ISOLATION',
      nav: '/eptw/woi',
      count: getWOICounts(permissions, woiCountsData)
    },
    {
      src: require('./../images/Icons/Close_without_Isolation.png'),
      name: 'CLOSE WITHOUT ISOLATION',
      nav: '/eptw/cwoi',
      count: getCWOICounts(permissions, cwoiCountsData)
    }
  ]

  const normalIcons: any = [
    {
      name: 'Pending for action',
      src: require('./../images/Icons/LTI_Button.png'),
      nav: '/eptw/newPermit/updateRiskAssessments',
      canView: permissions?.canUpdateRisk,
      count: newPermitData?.updateRiskAssesment
    },
    {
      name: 'VIEW ALL PERMITS',
      src: require('./../images/Icons/LTI_Button.png'),
      nav: '/eptw/allPermits'
    },
    {
      name: 'SIMOPS',
      src: require('./../images/Icons/Simops.png'),
      nav: 'SIMOPS'
    },
    {
      src: require('./../../src/images/Icons/overdueIcon.png'),
      name: 'Over Due',
      nav: '/eptw/overdue',
      count: generalCountsData?.overdue
    },
    {
      src: require('./../../src/images/Icons/Expired.png'),
      name: 'Expired',
      nav: '/eptw/expired',
      count: generalCountsData?.expired
    },
    {
      src: require('./../../src/images/Icons/suspend.png'),
      name: 'Suspended',
      nav: '/eptw/suspended',
      count: generalCountsData?.suspendedCount
    },
    {
      src: require('./../../src/images/Icons/search.png'),
      name: 'SEARCH',
      nav: '/eptw/searchPermit'
    },
    {
      name: 'LIVE ISOLATIONS (LTI)',
      src: require('./../images/Icons/LTI_Button.png'),
      nav: '/eptw/liveIsolations',
      count: generalCountsData?.liveLtiCount
    },
    {
      src: require('./../images/Icons/Risk Assesment Template.png'),
      name: 'Risk Assessment Templates',
      nav: '/eptw/riskAssessmentTemplates'
    },
    {
      name: 'LIVE PERMITS',
      src: require('./../images/Icons/Live_Button.png'),
      nav: '/eptw/newPermit/live',
      count: generalCountsData?.livePermits
    },
    {
      src: require('./../../src/images/Icons/rejected.png'),
      name: 'REJECTED PERMITS',
      nav: '/eptw/rejected',
      count: generalCountsData?.rejectedPermits
    },
    {
      src: require('./../../src/images/Icons/View Audit.png'),
      name: 'VIEW AUDITS',
      nav: '/eptw/audits',
      count: generalCountsData?.audits,
      canView: canViewPermitAudit.includes(userData?.jobTitle)
    },
    {
      src: require('./../../src/images/Icons/information.png'),
      name: 'VIEW INFORMATION CARDS',
      // nav: '/eptw/informationCard/viewAll',
      nav: '/eptw/informationCard',
      count: generalCountsData?.informationCards
    },
    {
      src: require('./../../src/images/Icons/EQUIPMENT OVERRIDE.png'),
      name: 'OVERRIDE REGISTER',
      // nav: '/eptw/informationCard/viewAll',
      nav: '/eptw/overrides'
      // count: generalCountsData?.informationCards
    }
  ]

  return (
    <>
      <ProtectedLayout
        onBack="/eptw/menu"
        hasSideList
        hasUserProfile
        sidePanel={<DashboardNavigation />}
      >
        <Row
          style={{
            marginTop: 10,
            marginLeft: 5,
            marginRight: 5,
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
            <IconCard
              key={`${index}-${name}`}
              name={name}
              nav={nav}
              canView={canView}
              count={count}
              src={src}
            />
          ))}
        </Row>
        <Row
          style={{
            marginTop: 10,
            marginLeft: 5,
            marginRight: 5,
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          {normalIcons?.map(
            ({ name, src, nav, canView, count }: any, index: any) => (
              <IconCard
                key={`${index}-${name}`}
                src={src}
                name={name}
                nav={nav}
                canView={canView}
                count={count}
              />
            )
          )}
        </Row>
      </ProtectedLayout>
    </>
  )
}
