import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Col, Row, Table } from 'reactstrap'
import { makeAuthenticatedRequest } from '../apiRequests'
import { incrementby1 } from '../components/Forms/JSAForm'
import { alEquipmentApi } from '../components/serverurl'
import { Link } from '../pages/workManagement/PmTree'
import { getReadableTime } from '../pages/workManagement/WorkOrderDetails'
import { alEquipment } from './AlWorkRequestForm'
import AshokLeylandLayout from './AshokLeylandLayout'

export default function AlFirsTable({
  status,
  onBack
}: {
  status: any
  onBack: any
}) {
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const fetchReport = async () =>
    await makeAuthenticatedRequest(
      `${alEquipmentApi}/firs?status=${status}`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data }: any = useQuery('AL FIR List', fetchReport)

  const reports = data?.incidentReports
  return (
    <AshokLeylandLayout title="INCIDENT REPORTING" onBack={onBack}>
      <Row style={{ margin: 15 }}>
        <Col>
          <Table bordered style={{ borderColor: '#000' }}>
            <thead>
              <tr style={{ backgroundColor: 'gold' }}>
                <th>S.no</th>
                {['Number', 'Title', 'Originator', 'Created on']?.map(
                  (k: any) => <th key={k}>{k}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {reports?.map((report: any, index: any) => (
                <tr key={report?._id}>
                  <td>{incrementby1(index)}</td>
                  <td>
                    <Link onClick={() => navigate(report?._id)}>
                      {report?.number}
                    </Link>
                  </td>
                  <td>{report?.general?.incidentTitle}</td>
                  <td>{report?.general?.originator}</td>
                  <td>{getReadableTime(report?.general?.createdOn)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </AshokLeylandLayout>
  )
}
