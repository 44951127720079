import { Button, ModalFooter, Row } from 'reactstrap'
import { useEffect, useRef, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import DashboardNavigation from '../../components/Dashboard/DashboardNavigation'
import LessonLearntTable from '../../components/Tables/LessonLearntTable'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import { epermitCloseEndPoint } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import ReactToPrint from 'react-to-print'

export default function LessonLearnt() {
  const { accounts, instance } = useMsal()
  const [pageNumber, setPageNumber] = useState(0)
  const [unit, setUnit]: any = useState()
  const [prod, setProd]: any = useState()
  const [lti, setLti]: any = useState()
  const [plant, setPlant]: any = useState()
  const componentRef: any = useRef()

  const fetchCloseOutReports = async () => {
    const response = await makeAuthenticatedRequest(
      `${epermitCloseEndPoint}?page=${pageNumber}&plant=${plant}&prod=${prod}&lti=${lti}&unit=${unit}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response?.data
  }

  const { data, isLoading, refetch }: any = useQuery(
    'ePermitCloseOut',
    fetchCloseOutReports
  )

  useEffect(() => {
    refetch()
  }, [unit, plant, prod, lti])

  const styles = `@page { size: landscape; }`

  return (
    <>
      <ProtectedLayout
        onBack="/firs"
        hasSideList
        hasUserProfile
        sidePanel={<DashboardNavigation />}
      >
        <div ref={componentRef} className="print-page-break">
          <style type="text/css" media="print">
            {styles}
          </style>
          <Row style={{ margin: 10 }}>
            <LessonLearntTable
              data={data}
              isLoading={isLoading}
              refetch={refetch}
              setPageNumber={setPageNumber}
              navigateTo={`/eptw/closedPermits`}
              setUnit={setUnit}
              setProd={setProd}
              setLti={setLti}
              setPlant={setPlant}
              closed
            />
          </Row>
          <ModalFooter style={{ margin: 10 }}>
            <ReactToPrint
              documentTitle={'LOTO'}
              pageStyle={'padding: 20'}
              trigger={() => <Button color="warning" children="Print" />}
              content={() => componentRef.current}
            />
          </ModalFooter>
        </div>
      </ProtectedLayout>
    </>
  )
}
