import { useContext } from 'react'
import { FaUserAlt } from 'react-icons/fa'
import { UserContext } from '../App'

import defaultTheme from './../components/constants/defaultTheme.json'
import TitleDetail from './TitleDetail'

export default function UserProfile() {
  const { userData }: any = useContext(UserContext)

  const styles = `@media print
  {    
      .no-print, .no-print *
      {
          display: none !important;
      }
  }`

  return (
    <div
      className="no-print"
      style={{
        backgroundColor: defaultTheme.colors.lightBlue1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: `2px solid ${defaultTheme.borderColor.Blue}`,
        borderRadius: 10,
        paddingTop: 10,
        paddingBottom: 10,
        height: 'min-height',
        marginBottom: 15,
        marginRight: 20
      }}
    >
      <style>{styles}</style>
      <div
        style={{
          marginBottom: 10,
          backgroundColor: defaultTheme.colors.lightBlue,
          display: 'flex',
          justifyContent: 'center',
          padding: '2px 10px 5px 10px',
          borderRadius: 5,
          color: defaultTheme.colors.white,
          fontWeight: 800,
          boxShadow:
            'rgba(0, 0, 0, 0.4) 0px 1px 2px, rgba(0, 0, 0, 0.3) 0px 2px 3px -3px, rgba(0, 0, 0, 0.2) 0px -2px 0px inset'
        }}
      >
        PROFILE
      </div>
      <FaUserAlt size={80} style={{ padding: 10 }} />
      <TitleDetail
        title="Name"
        detail={userData?.givenName || userData?.displayName}
      />
      <TitleDetail title="Employee Id" detail={userData?.employeeId || '-'} />
      <TitleDetail title="Designation" detail={userData?.jobTitle} />
      <TitleDetail title="Department" detail={userData?.department} />
    </div>
  )
}
