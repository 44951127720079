import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

type PreviewPandIDModalType = {
  filepath: any,
  isOpen: Boolean,
  toggle: Function
}

export default function PreviewPandID({filepath, isOpen, toggle}: any) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} fullscreen>
      <ModalBody>
        <object data={filepath} type="application/pdf" width="100%" height="100%">
          {/* <p>Alternative text - include a link <a href="http://africau.edu/images/default/sample.pdf">to the PDF!</a></p> */}
        </object>
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={() => toggle()}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}