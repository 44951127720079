import {
  Button,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useContext, useState } from 'react'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import RcmMatrix from '../../components/rcm/RcmMatrix'
import RCMTable from '../../components/rcm/RcmTable'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { Box, renderData } from '../workManagement/PmTree'
import { flocHeaderKeys } from './Floc'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { SubHeader } from '../VerifyCM'
import { systemsApi } from '../../components/serverurl'
import { UserContext } from '../../App'
import moment from 'moment'

const TABS = {
  FAILURE_EFFECTS: 'Failure Mode',
  FM_NOTES: 'FM Notes',
  STRATEGY: 'Strategy',
  CALCULATIONS: 'Calculations'
}

export default function FlocTag() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)

  const fetchFlocTag = async () => {
    const response = await makeAuthenticatedRequest(
      `${systemsApi}/floc/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchTagDetailsFloc', fetchFlocTag)

  const [active, setActive]: any = useState(TABS.FAILURE_EFFECTS)
  const [criticality, setCriticality]: any = useState()
  const [text, setText]: any = useState()

  const InputTag = () => (
    <div>
      <p>
        Latest Updated by {data?.flocTag['latest Updated By']} at {''}
        {moment(data?.flocTag['latest Updated At']).format(
          'DD-MM-YYYY hh:mm:ss A'
        )}
      </p>
      <Input
        type="textarea"
        style={{ height: 500 }}
        name={active}
        defaultValue={data?.flocTag[active]}
        onChange={(e: any) => setText(e?.target?.value)}
      />
      <br />
      <Button
        color="warning"
        onClick={() => setCriticality({ [active]: text })}
      >
        Save
      </Button>
    </div>
  )

  const criticalityColors: any = {
    E: 'purple',
    H: 'red',
    MH: 'orange',
    M: 'yellow',
    N: 'green',
    T: 'lightblue',
    Y: 'yellow',
    X: 'red'
  }

  return (
    <ProtectedLayout title="F Loc Tag Details" onBack="/cmms/rcm/floc">
      <Row style={{ margin: 15 }}>
        <Col xs="6">
          <Box heading="Estimated time between Failure (ETBF)">
            <RcmMatrix
              onClick={(e: any) => {
                setCriticality(e)
              }}
              Economic={data?.flocTag?.['Economic']}
              health={data?.flocTag?.['Health Safety']}
              environment={data?.flocTag?.['Environment']}
              probability={data?.flocTag?.['Probalbility']}
            />
          </Box>
          <Box heading="ETBC">
            <RCMTable
              onClick={(e: any) => {
                setCriticality(e)
              }}
              asset={data?.flocTag?.['Asset consequences']}
              effect={data?.flocTag?.['Environment Consequences']}
            />
          </Box>
        </Col>
        <Col xs="6">
          <Box heading="Tag Details">
            {[
              ...flocHeaderKeys,
              // 'PLC',
              'Health Safety',
              'Environment',
              'Probalbility',
              'Economic',
              'Asset consequences',
              'Environment Consequences'
            ]?.map((key: any) => renderData(key, data?.flocTag[key]))}
            {renderData(
              'ETBF',
              <p
                style={{
                  margin: 0,
                  backgroundColor: criticalityColors[data?.flocTag['ETBF']],
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 5,
                  paddingBottom: 5,
                  color: '#FFF',
                  fontWeight: 700
                }}
              >
                {data?.flocTag['ETBF']}
              </p>
            )}
            {renderData(
              'ETBC',
              <p
                style={{
                  margin: 0,
                  backgroundColor: criticalityColors[data?.flocTag['ETBC']],
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 5,
                  paddingBottom: 5,
                  color: '#FFF',
                  fontWeight: 700
                }}
              >
                {data?.flocTag['ETBC']}
              </p>
            )}
          </Box>
          <Box heading="Update Details">
            <Nav tabs style={{ backgroundColor: '#fff', paddingTop: 0 }}>
              <NavItem>
                <NavLink
                  className={active === TABS.FAILURE_EFFECTS ? 'active' : ''}
                  onClick={() => setActive(TABS.FAILURE_EFFECTS)}
                >
                  {TABS?.FAILURE_EFFECTS}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={active === TABS.FM_NOTES ? 'active' : ''}
                  onClick={() => setActive(TABS.FM_NOTES)}
                >
                  {TABS?.FM_NOTES}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={active === TABS.STRATEGY ? 'active' : ''}
                  onClick={() => setActive(TABS.STRATEGY)}
                >
                  {TABS?.STRATEGY}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={active === TABS.CALCULATIONS ? 'active' : ''}
                  onClick={() => setActive(TABS.CALCULATIONS)}
                >
                  {TABS?.CALCULATIONS}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={active}>
              <TabPane tabId={TABS.FAILURE_EFFECTS}>
                <SubHeader header={TABS.FAILURE_EFFECTS} />
                <InputTag />
              </TabPane>
              <TabPane tabId={TABS.FM_NOTES}>
                <SubHeader header={TABS.FM_NOTES} />
                <InputTag />
              </TabPane>
              <TabPane tabId={TABS.STRATEGY}>
                <SubHeader header={TABS.STRATEGY} />
                <InputTag />
              </TabPane>
              <TabPane tabId={TABS.CALCULATIONS}>
                <SubHeader header={TABS.CALCULATIONS} />
                <InputTag />
              </TabPane>
            </TabContent>
          </Box>
        </Col>
      </Row>
      {criticality && (
        <SubmitModalWithForm
          isOpen={criticality}
          size="lg"
          onClose={(val: any) => {
            setCriticality(false)
          }}
          // form={updateSystemForm}
          onSubmit={async (e: any) => {
            e.preventDefault()
            const body: any = {
              ...criticality,
              'latest Updated By': userData?.givenName,
              'latest Updated At': moment()
            }
            try {
              const res = await makeAuthenticatedRequest(
                `${systemsApi}/floc?id=${id}`,
                'PATCH',
                body,
                accounts,
                instance
              )

              if (res?.status === 'success') {
                await refetch()
              }

              return res?.status
            } catch (error) {
              console.error(error)
            }
          }}
          header={`Update System `}
        />
      )}
    </ProtectedLayout>
  )
}
