import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Input, Row, Spinner, Table } from "reactstrap";

import ProtectedLayout from "../../components/Layout/ProtectedLayout";
import SectionHeader from "../../components/Layout/SectionHeader";
import SubmitModalWithForm from "../../components/Modals/SubmitModalWithForm";
import { getReadableTime } from "../workManagement/WorkOrderDetails";
import { incrementby1 } from "../../components/Forms/JSAForm";
import { Link } from "../workManagement/PmTree";
import { makeAuthenticatedRequest } from "../../apiRequests";
import { stock, workOrderAPI } from "../../components/serverurl";

const MaterialRequestsTable = ({
  data,
  isLoading,
  isRefetching,
  refetch,
}: {
  data: any;
  isLoading: boolean;
  isRefetching: boolean;
  refetch: any;
}) => {
  const navigate = useNavigate();
  const { accounts, instance } = useMsal();

  const [submit, setSubmit]: any = useState();
  const [request, setRequest]: any = useState();
  const [wo, setWo]: any = useState();

  const handleQuantityChange = (spareId: any, quantity: any) => {
    setRequest(
      request?.map((s: any) =>
        s?.["Spare Part Number"] === spareId
          ? {
              ...s,
              quantityIssued: quantity,
            }
          : s
      )
    );
  };

  const handleCommentChange = (spareId: any, date: any) => {
    setRequest(
      request?.materialRequest?.map((s: any) =>
        s?.["Spare Part Number"] === spareId ? { ...s, dateRequested: date } : s
      )
    );
  };

  const onSubmit = async () => {
    const body = {
      ...wo,
      materialRequest: request,
      mrStatus: "materialIssued",
    };

    try {
      const res = await makeAuthenticatedRequest(
        `${stock}/inventory/${wo?._id}`,
        "PATCH",
        body,
        accounts,
        instance
      );

      await refetch();

      return res?.status;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Number</th>
            <th>Description</th>
            <th>Type</th>
            <th>Serial Number</th>
            <th>UOM</th>
            <th>Manufacturer</th>
            <th>Available</th>
            <th>Quantity Requested</th>
            <th>Date Required</th>
          </tr>
        </thead>
        <tbody>
          <>
            {isLoading || isRefetching ? (
              <tr>
                <td colSpan={12}>
                  <Row
                    style={{
                      height: 500,
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner />
                  </Row>
                </td>
              </tr>
            ) : (
              data?.length > 0 &&
              data?.map((w: any, index: number) => (
                <React.Fragment key={w?._id}>
                  <tr style={{ backgroundColor: "#DCDCDC" }}>
                    <td
                      style={{
                        fontSize: 20,
                        fontWeight: 700,
                      }}
                      colSpan={12}
                    >
                      <Link
                        onClick={() => {
                          setRequest(w?.materialRequest);
                          setWo(w);
                          setSubmit(true);
                        }}
                      >
                        WO: {w?.number}
                      </Link>
                    </td>
                  </tr>
                  {w?.materialRequest?.map((m: any, index: any) => (
                    <tr key={`${w?._id}_${index}`}>
                      <td>{incrementby1(index)}</td>
                      <td>{m?.["Spare Part Number"]}</td>
                      <td>{m?.["Spare Part Description"]}</td>
                      <td>{m?.spareType}</td>
                      <td>{m?.serialNumber}</td>
                      <td>{m?.uom}</td>
                      <td>{m?.manufacturer}</td>
                      <td>{m?.onHandQuantity}</td>
                      <td>{m?.quantityRequested}</td>
                      <td>{getReadableTime(m?.dateRequested)}</td>
                    </tr>
                  ))}
                </React.Fragment>
              ))
            )}
          </>
        </tbody>
      </Table>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: "100%",
            margin: 0,
          }}
        >
          <p
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No Work Orders Found,
            <span
              style={{
                color: "blue",
                textDecoration: "underline",
                marginLeft: 2,
                cursor: "pointer",
              }}
              onClick={() =>
                navigate("/workManagement/correctiveMaintenance/create")
              }
            >
              Create one?
            </span>
          </p>
        </Row>
      )}
      {submit && (
        <SubmitModalWithForm
          isOpen={submit}
          size="xl"
          onClose={(val: any) => {
            setSubmit(false);
          }}
          form={
            <Table>
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Description</th>
                  <th>Available</th>
                  <th>Requested</th>
                  <th>Required</th>
                  <th>Issue</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>
                {request?.map((s: any) => (
                  <tr key={s?.["Spare Part Number"]}>
                    <td>{s?.["Spare Part Number"]}</td>
                    <td>{s?.["Spare Part Description"]}</td>
                    <td>{s?.onHandQuantity}</td>
                    <td>{s?.quantityRequested}</td>
                    <td>{getReadableTime(s?.dateRequested)}</td>
                    <td>
                      <Input
                        type="text"
                        onChange={(e: any) =>
                          handleQuantityChange(
                            s?.["Spare Part Number"],
                            e?.target?.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <Input
                        type="textarea"
                        style={{ height: 40 }}
                        onChange={(e: any) =>
                          handleCommentChange(
                            s?.["Spare Part Number"],
                            e?.target?.value
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }
          onSubmit={async (e: any) => {
            e.preventDefault();
            const res = await onSubmit();
            return res;
            // return result?.data?.status
          }}
          header={`Issue material - ${wo?.number}`}
        />
      )}
    </>
  );
};

export default function MaterialRequests() {
  const { accounts, instance } = useMsal();

  const fetchWorkRequests = async () => {
    const response = await makeAuthenticatedRequest(
      `${workOrderAPI}`,
      "GET",
      null,
      accounts,
      instance
    );

    return response;
  };

  const { data, isLoading, isRefetching, refetch }: any = useQuery(
    "workRequests",
    fetchWorkRequests
  );

  const workOrders = data?.workrequests;

  const materialRequests = workOrders?.filter(
    (w: any) =>
      w?.woStatus !== "closed" &&
      w?.mrStatus !== "materialIssued" &&
      w?.materialRequest?.length > 0
  );

  return (
    <>
      <ProtectedLayout
        onBack="/cmms/supplychain/warehouse"
        title="Material Requests"
      >
        <Row style={{ margin: 15 }}>
          <MaterialRequestsTable
            data={materialRequests}
            isLoading={isLoading}
            isRefetching={isRefetching}
            refetch={refetch}
          />
        </Row>
      </ProtectedLayout>
    </>
  );
}
