import {
  Button,
  Col,
  Input,
  ModalFooter,
  Row,
  Spinner,
  Table
} from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useState } from 'react'

import AshokLeylandLayout from './AshokLeylandLayout'
import CustomLabel from '../components/InputFields/CustomLabel'
import SubmitModalWithForm from '../components/Modals/SubmitModalWithForm'
import { alEquipmentApi } from '../components/serverurl'
import { incrementby1 } from '../components/Forms/JSAForm'
import { makeAuthenticatedRequest } from '../apiRequests'
import {
  getReadableTime,
  JobPlanTable
} from '../pages/workManagement/WorkOrderDetails'
import { Box, renderData } from '../pages/workManagement/PmTree'
import { jobcodes } from '../components/constants/jobcodes'
import { Text } from '../components/RiskAssesmentPreview'

export const AlLoader = () => {
  return (
    <AshokLeylandLayout onBack="/ashokleyland/pm/list">
      <Row
        style={{
          margin: 15,
          height: '80vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Spinner />
      </Row>
    </AshokLeylandLayout>
  )
}

export const StockDetailsTable = () => {
  const data = [
    {
      stockNumber: 'AL-S00004537',
      description: 'Diahragam, Primary, viton P/N: 04-1010-53, for Wildon Pump',
      itemCode: 'ST-034-WP',
      location: 'AC Store',
      department: 'Mechanical',
      dateRequired: '08/09/22'
    },
    {
      stockNumber: 'AL-S00004538',
      description: 'Ball valve,viton P/N: 04-1010-53, for Wildon Pump',
      itemCode: 'ST-450-WP',
      location: 'AC Store',
      department: 'Mechanical',
      dateRequired: '08/09/22'
    },
    {
      stockNumber: 'AL-S00004539',
      description:
        'Piston Inner, P4 (Non-PFTE)P/N: 04-3700-01-700, for Wildon Pump',
      itemCode: 'ST-549-WP',
      location: 'AC Store',
      department: 'Mechanical',
      dateRequired: '08/09/22'
    }
  ]
  return (
    <Table>
      <thead>
        <tr>
          {[
            'S.No',
            'Stock Number',
            'Stock Description',
            'Item Code',
            'Location',
            'Responsible Department',
            'Date Required'
          ]?.map((a: any) => <th key={a}>{a}</th>)}
        </tr>
      </thead>
      <tbody>
        {data?.map((d: any, index: any) => (
          <tr key={d?.itemCode}>
            <td>{incrementby1(index)}</td>
            <td>{d?.stockNumber}</td>
            <td>{d?.description}</td>
            <td>{d?.itemCode}</td>
            <td>{d?.location}</td>
            <td>{d?.department}</td>
            <td>{d?.dateRequired}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export const SpecialToolsTable = () => {
  const data = [
    {
      tools: 'Safety Slings',
      description: 'Certified Slings which can withstand 100 Kgs',
      itemCode: 'SL-9456',
      location: 'Safety Room',
      department: 'Safety',
      date: '08/09/22'
    }
  ]

  return (
    <Table>
      <thead>
        <tr>
          {[
            'Tool',
            'Description',
            'Item Code',
            'Location',
            'Responsible Department',
            'Date Required'
          ]?.map((h: any) => <th key={h}>{h}</th>)}
        </tr>
      </thead>
      <tbody>
        {data?.map((d: any, index: any) => (
          <tr key={d?.itemCode}>
            <td>{d?.tools}</td>
            <td>{d?.description}</td>
            <td>{d?.itemCode}</td>
            <td>{d?.location}</td>
            <td>{d?.department}</td>
            <td>{d?.date}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export const JobPlanDesription = ({ description }: { description: any }) => {
  const jobPlanPoints = [
    'Ensure there are no OIL or WASTE PRODUCTS in the Non-Hazardous drains.',
    'Check drain gullies and drain recesses are kept clean and free from debris.',
    "Check that the top hat' covers allow an unobstructed pathway for drainage to enter the pipe.",
    'Ensure that the drain boxes water seals are full of water, up to the height of of the open-ended standpipe.',
    'Check that the gooseneck seals are intact, gas tests can be taken at the openings around the system. If there is no evidence of hydrocarbons returning from the Slop Tank vapour space, this is indicative that the traps are full of seal water.',
    'Carry out a visual inspection for fabric defects due to corrosion and report any.'
  ]
  return (
    <Box heading={`JD - ${description}`}>
      <Text>
        Inspect the Hazardous and Non-Hazardous drain boxes located in the
        specified Module as follows:
      </Text>
      {jobPlanPoints?.map((p: any, index: any) => (
        <Text key={index}>
          {incrementby1(index)}. {p}
        </Text>
      ))}
    </Box>
  )
}

export const ViewPMDetails = ({ pm, workorder }: any) => {
  const pmDetails: any = {
    'PM Job No.': workorder?.number,
    Department: pm?.department,
    'PM Job desccription': pm?.pmJobDescription,
    'Equipment Group': pm?.equipmentGroup,
    'Serivce Provider': pm?.serviceProvider,
    Location: pm?.location,
    System: pm?.system,
    'System description': pm?.systemDescription,
    rev: pm?.rev,
    Frequency: pm?.frequency,
    'PM Trigger Date': getReadableTime(pm?.pmTriggerDate),
    'PM Starting Month': getReadableTime(pm?.pmStartingMonth)
  }

  return (
    <Row>
      <Col md="6">
        <Box heading="PM Details">
          {Object.keys(pmDetails)?.map((p: any) => renderData(p, pmDetails[p]))}
        </Box>
      </Col>
      <Col md="6">
        <JobPlanDesription description={pm?.pmJobDescription} />
      </Col>
      <Col>
        <Box heading={`Job Plan - ${pm?.pmJobNo}`}>
          <JobPlanTable data={jobcodes.CAOO5} />
        </Box>
        <Box heading={`Stock Details`}>
          <StockDetailsTable />
        </Box>
        <Box heading={`Special Tools Used`}>
          <SpecialToolsTable />
        </Box>
        <Box heading="Equipment List">
          <Table>
            <thead>
              <tr>
                {[
                  'Tag Number',
                  'Tag Description',
                  'Department',
                  'Location'
                ]?.map((h: any) => <th key={h}>{h}</th>)}
              </tr>
            </thead>
            <tbody>
              {pm?.tagDetails?.map((doc: any, index: any) => (
                <tr key={doc?._id}>
                  <td>{doc?.tagNumber}</td>
                  <td>{pm?.pmJobDescription}</td>
                  <td>{pm?.department}</td>
                  <td>{pm?.location}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      </Col>
    </Row>
  )
}

export default function AlViewPM() {
  const { number } = useParams()
  const { accounts, instance } = useMsal()

  const [modal, setModal]: any = useState()
  const [triggerDate, setTriggerDate]: any = useState(new Date())

  const fetchAlPMList = async () =>
    await makeAuthenticatedRequest(
      `${alEquipmentApi}/pm/${number}`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data, refetch, isloading, isRefetching }: any = useQuery(
    'AL PM',
    fetchAlPMList
  )

  const pmList = data?.data?.list
  const pm = pmList?.[0] || {}

  if (isloading) {
    return <AlLoader />
  }

  return (
    <AshokLeylandLayout onBack="/ashokleyland/pm/list">
      <Row style={{ margin: 15 }}>
        {pm && <ViewPMDetails pm={pm} />}
        <ModalFooter>
          <Button
            color="warning"
            onClick={() => {
              setModal(true)
              setTriggerDate(pm?.pmTriggerDate)
            }}
          >
            Update Trigger
          </Button>
        </ModalFooter>
      </Row>
      <SubmitModalWithForm
        isOpen={modal}
        onClose={(val: any) => {
          setModal(false)
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()
          const body: any = {
            pmTriggerDate: triggerDate
          }

          const res = await makeAuthenticatedRequest(
            `${alEquipmentApi}/workorder/${pm?.pmJobNo}`,
            'PATCH',
            body,
            accounts,
            instance
          )

          if (res?.status === 'success') {
            await refetch()
          }

          return res?.status
        }}
        size="xs"
        header="Update Trigger Date"
        form={
          <Row>
            <Col>
              <CustomLabel label="Update Trigger Date" />
              <Input
                type="date"
                defaultValue={
                  triggerDate &&
                  new Date(triggerDate)?.toISOString()?.split('T')[0]
                }
                onChange={(e: any) => setTriggerDate(e?.target?.value)}
              />
            </Col>
          </Row>
        }
      />
    </AshokLeylandLayout>
  )
}
