import { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

import PermitSubmitStatusModal from './PermitSubmitStatusModal'
import ViewAndApprovePermit from './ViewAndApprovePermit'
import { getEpermitPermissions } from './lib/epermitPermissions'
import { useMsal } from '@azure/msal-react'

export default function FormPreview({
  permitValues,
  isolationValues,
  riskAssesmentValues,
  isOpen,
  toggle,
  form,
  type,
  reset,
  attachments,
  existingIsolationsId
}: any) {
  const { accounts, instance } = useMsal()

  const [submissionModalOpen, setSubmissionModal] = useState(false)
  const [permissions, setPermissions]: any = useState()
  const toggleSubmissionStatus = () => setSubmissionModal(!submissionModalOpen)

  useEffect(() => {
    if (!permissions) {
      getEpermitPermissions(accounts, instance).then((response: any) =>
        setPermissions(response)
      )
    }
  })

  // const canCreate = permissions?.canCreate(permitValues?.department?.[0]?.label)
  const canCreate = true

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} fullscreen>
        <ModalBody>
          <ViewAndApprovePermit
            riskAssesmentValues={riskAssesmentValues}
            isolationValues={isolationValues}
            refetch={() => null}
            selectedPermit={{
              permitType: type,
              ...permitValues,
              Isolations: isolationValues,
              type: type
            }}
            viewNextPermit={() => {}}
            attachments={attachments}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" children="Edit" onClick={toggle} />
          {canCreate && (
            <Button
              color="warning"
              children={
                permitValues?.riskAssessment === 'Yes'
                  ? 'Submit to HSE Department'
                  : 'Submit for Production Incharge'
              }
              onClick={() => {
                toggle()
                setSubmissionModal(true)
              }}
            />
          )}
        </ModalFooter>
      </Modal>
      <PermitSubmitStatusModal
        form={form}
        isolationValues={isolationValues}
        isOpen={submissionModalOpen}
        permitValues={permitValues}
        reset={reset}
        riskAssesmentValues={riskAssesmentValues}
        toggle={toggleSubmissionStatus}
        type={type}
        attachments={attachments}
        existingIsolationsId={existingIsolationsId}
      />
    </>
  )
}
