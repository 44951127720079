import { Row } from 'reactstrap'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { IconCard } from '../../components/Dashboard/IconCard'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { incidentReport } from '../../components/serverurl'

export default function FIRSActions() {
  const { accounts, instance } = useMsal()

  const fetchReport = async () =>
    await makeAuthenticatedRequest(
      `${incidentReport}/count`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data }: any = useQuery('FIR Counts', fetchReport)

  const counts = data?.data?.countsByStatus

  const flows: any = [
    {
      src: require('./../../images/Icons/Stage - 1.png'),
      name: 'Stage 1 Inital Contributor',
      nav: 'stage1'
    },
    {
      src: require('./../../images/Icons/Stage - 2.png'),
      name: 'Stage 2 Area Authority', // hold or reject
      nav: 'stage2',
      count: counts?.stage2
    },
    {
      src: require('./../../images/Icons/Stage - 3.png'),
      name: 'Stage 3 OIM',
      nav: 'stage3',
      count: counts?.stage3
    },
    {
      src: require('./../../images/Icons/Stage - 4.png'),
      name: 'Stage 4 Safety HSSEQ',
      nav: 'stage4',
      count: counts?.stage4
    },
    {
      src: require('./../../images/Icons/Stage - 5.png'),
      name: 'Stage 5 Intermediate & Permanent Mitigation ',
      nav: 'stage5',
      count: counts?.stage5
    },
    {
      src: require('./../../images/Icons/Stage - 6.png'),
      name: 'Stage 6 Management of Change',
      nav: 'stage6',
      count: counts?.moc
    },
    {
      src: require('./../../images/Icons/Stage - 7.png'),
      name: 'Stage 7 Corrective Work Order',
      nav: 'stage7',
      count: counts?.cm
    },
    {
      src: require('./../../images/Icons/Stage - 8.png'),
      name: 'Stage 8 Closed',
      nav: 'stage8',
      count: counts?.closed
    },
    {
      src: require('./../../images/Icons/Stage - A.png'),
      name: 'Stage A Rejectecd',
      nav: 'stageA',
      count: counts?.rejected
    }
  ]

  return (
    <>
      <ProtectedLayout
        onBack="/firs"
        title="INCIDENT
       MANAGEMENT"
      >
        <Row style={{ margin: 10, fontSize: 20 }}>
          {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
            <IconCard
              key={`${index}-${name}`}
              name={name}
              nav={nav}
              canView={canView}
              count={count}
              src={src}
            />
          ))}
        </Row>
      </ProtectedLayout>
    </>
  )
}
