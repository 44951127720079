import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Col, Input, ModalFooter, Row, Table } from 'reactstrap'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import Spacer from '../../components/Spacer'
import { stock } from '../../components/serverurl'
import { Box, renderData } from '../workManagement/PmTree'
import { ViewHistory } from '../ViewCM'
import { SubHeader } from '../VerifyCM'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { Text } from '../workManagement/WorkRequestForm'
import './../../components/css/print.css'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { UserContext } from '../../App'

const date = new Date()
date.setDate(date.getDate() + 5)

export default function CreatePO() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const navigate = useNavigate()
  const componentRef: any = useRef()
  const [fCharges, setFCharges]: any = useState(0)
  const [mCharges, setMCharges]: any = useState(0)
  const [pCharges, setPCharges]: any = useState(0)
  const [total, setTotal] = useState(0)
  const [submit, setSubmit]: any = useState(false)

  const fetchpr = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/inventory/po/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const [spares, setSpares]: any = useState([])

  const { data, isLoading, isRefetching }: any = useQuery('pr', fetchpr, {
    onSuccess: (data: any) => {
      // setSpares(data?.pr?.materialRequest)
    }
  })

  useEffect(() => {
    setSpares(data?.pr?.materialRequest)
  }, [data])

  const pr = data?.pr
  const history = data?.history
  const poNumber = `PO-${pr?.number?.split('-')[1]}`

  const poDetails: any = {
    Version: 0,
    'PR Number': pr?.number,
    'Document Date': '31-01-2023',
    Requisitioner: 'RUZANNA',
    'Our Reference': 'PKL-RQ-21021-MRN-021',
    'Vendor Reference': '1410/48',
    Buyer: 'MAZAITUL SHILA',
    "Buyer's Location": 'FPSO',
    "BUYER's Phone Number": '603-21715799',
    "BUYER's Fax Number": '603-21715799',
    'Terms of Payment': '45 days from invoice receipt date by finance',
    'Delivery Terms': 'CFR Signapore Port Incoterms 2010',
    'Reference Terms': 'Supply of Goods'
  }

  const getAmount = (numVal1 = 0, numVal2 = 0) => {
    const discount = (100 - numVal2) / 100
    const totalValue = numVal1 * discount

    return totalValue
  }

  const handlePriceChange = (spareId: any, price: any) => {
    setSpares(
      spares?.map((s: any) =>
        s?.['Spare Part Number'] === spareId
          ? {
              ...s,
              grossPrice: price,
              amount: getAmount(
                parseInt(price || 0),
                parseInt(s?.discount || 0)
              )
            }
          : s
      )
    )
  }

  const handleQuantityChange = (spareId: any, quantity: any) => {
    setSpares(
      spares?.map((s: any) =>
        s?.['Spare Part Number'] === spareId
          ? { ...s, quantityRequested: quantity }
          : s
      )
    )
  }

  const handleDiscountChange = (spareId: any, discount: any) => {
    setSpares(
      spares?.map((s: any) =>
        s?.['Spare Part Number'] === spareId
          ? {
              ...s,
              discount: discount,
              amount: getAmount(
                parseInt(s?.grossPrice || 0),
                parseInt(discount || 0)
              )
            }
          : s
      )
    )
  }

  const handleNotesChange = (spareId: any, notes: any) => {
    setSpares(
      spares?.map((s: any) =>
        s?.['Spare Part Number'] === spareId ? { ...s, notes: notes } : s
      )
    )
  }

  const getTotal = () => {
    let lineCost: any = 0
    spares?.forEach(
      (s: any) =>
        (lineCost = parseInt(lineCost || 0) + parseInt(s?.amount || 0))
    )

    lineCost = parseInt(lineCost) + parseInt(fCharges)
    lineCost = parseInt(lineCost) + parseInt(mCharges)
    lineCost = parseInt(lineCost) + parseInt(pCharges)

    setTotal(parseInt(lineCost))
  }

  useEffect(() => {
    getTotal()
  }, [spares, fCharges, mCharges, pCharges])

  const styles = `@media print
  {    
      .no-print, .no-print *
      {
          display: none !important;
      }
  }`

  return (
    <>
      <>
        <style>{styles}</style>
      </>
      <ProtectedLayout onBack="/purchase/po">
        <div ref={componentRef} style={{ margin: 20 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <SubHeader
              permitColor="#fff"
              header={
                <p style={{ margin: 0, fontSize: 20 }}>
                  Purchase Order - {poNumber}
                </p>
              }
            />
            <div
              style={{ display: 'flex', width: 500, justifyContent: 'center' }}
            >
              <img
                src={require('./../../images/e73_logo.jpeg')}
                alt="HOEC Logo"
                style={{
                  height: 100,
                  marginRight: 30
                }}
              />
              <div style={{ paddingLeft: 30, borderLeft: '3px solid #FFD580' }}>
                <p style={{ margin: 0 }}>E73 AI Innovations Private Ltd</p>
                <p style={{ margin: 0 }}>Level 21, Menara Perak</p>
                <p style={{ margin: 0 }}>24, Jalan Perak</p>
                <p style={{ margin: 0 }}>50450 KUALA LUMPUR</p>
                <p style={{ margin: 0 }}>Malaysia</p>
              </div>
            </div>
          </div>
          <hr />
          <Row>
            <Col xs="6">
              <Box heading="Supplier Details">
                <>
                  <p style={{ margin: 0, marginBottom: 20 }}>
                    GALL THOMPSON ENVIRONMENT LTD
                  </p>
                  <p style={{ margin: 0 }}>POMMERS LANE</p>
                  <p style={{ margin: 0 }}>GREAT YARMOUTH</p>
                  <p style={{ margin: 0 }}>NR 30 3PE NORFOLK United Kingdom</p>
                  <p style={{ margin: 0 }}>Contact Person:</p>
                  <p style={{ margin: 0 }}>
                    Tel: 441493 857936 Fax: 44 1493 850888
                  </p>
                </>
              </Box>
              <Box heading="Invoice Address">
                <>
                  <p style={{ margin: 0 }}>Finance &Accounts Department</p>
                  <p style={{ margin: 0 }}>E73 AI Innovations Private Ltd</p>
                  <p style={{ margin: 0 }}>Level 21, Menara Perak</p>
                  <p style={{ margin: 0 }}>24, Jalan Perak</p>
                  <p style={{ margin: 0 }}>50450</p>
                  <p style={{ margin: 0 }}>Kuala Lumpur, Malaysia </p>
                </>
              </Box>
            </Col>
            <Col xs="6">
              <Box heading="Information">
                {Object.keys(poDetails)?.map((key: any) =>
                  renderData(key, poDetails[key])
                )}
              </Box>
            </Col>
            <Col xs="12">
              <Table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Part Number</th>
                    <th>Part Description</th>
                    <th>UOM</th>
                    <th>Required Quantity</th>
                    <th>Offered Quantity</th>
                    <th>Gross Price</th>
                    <th>Discount</th>
                    <th>Line Cost</th>
                    <th>Currency</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  {spares?.map((s: any, index: number) => (
                    <React.Fragment key={s?.['Spare Part Number']}>
                      <tr>
                        <td>{incrementby1(index)}</td>
                        <td style={{ width: '15%', whiteSpace: 'nowrap' }}>
                          {s?.['Spare Part Number']}
                        </td>
                        <td>{s?.['Spare Part Description']}</td>
                        <td>{s?.uom}</td>
                        <td style={{ width: '5%' }}>{s?.quantityRequested}</td>
                        <td style={{ width: '5%' }}>
                          <Input
                            type="text"
                            defaultValue={s?.quantityRequested}
                            onChange={(e: any) =>
                              handleQuantityChange(
                                s?.['Spare Part Number'],
                                e?.target?.value
                              )
                            }
                          />
                        </td>
                        <td style={{ width: '5%' }}>
                          <Input
                            type="text"
                            onChange={(e: any) => {
                              handlePriceChange(
                                s?.['Spare Part Number'],
                                e?.target?.value
                              )
                            }}
                          />
                        </td>
                        <td style={{ width: '5%' }}>
                          <Input
                            type="text"
                            onChange={(e: any) =>
                              handleDiscountChange(
                                s?.['Spare Part Number'],
                                e?.target?.value
                              )
                            }
                          />
                        </td>
                        <td>{s?.amount}</td>
                        <td>{s?.priceCurrency}</td>
                        <td>
                          <Input
                            name="note"
                            type="textarea"
                            style={{ height: 40 }}
                            onChange={(e: any) =>
                              handleNotesChange(
                                s?.['Spare Part Number'],
                                e?.target?.value
                              )
                            }
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                  <tr>
                    <td>{spares?.length + 1}</td>
                    <td>0001</td>
                    <td>Freight Charges</td>
                    <td colSpan={5}></td>
                    <td style={{ width: '5%' }}>
                      <Input
                        type="text"
                        onChange={(e: any) =>
                          setFCharges(parseInt(e?.target?.value))
                        }
                      />
                    </td>
                    <td colSpan={2}></td>
                  </tr>
                  <tr>
                    <td>{spares?.length + 2}</td>
                    <td>002</td>
                    <td>Mobilization</td>
                    <td colSpan={5}></td>
                    <td style={{ width: '5%' }}>
                      <Input
                        type="text"
                        onChange={(e: any) =>
                          setMCharges(parseInt(e?.target?.value))
                        }
                      />
                    </td>
                    <td colSpan={2}></td>
                  </tr>
                  <tr>
                    <td>{spares?.length + 3}</td>
                    <td>003</td>
                    <td>Packaging Charges</td>
                    <td colSpan={5}></td>
                    <td style={{ width: '5%' }}>
                      <Input
                        type="text"
                        onChange={(e: any) =>
                          setPCharges(parseInt(e?.target?.value))
                        }
                      />
                    </td>
                    <td colSpan={2}></td>
                  </tr>
                  <tr>
                    <td colSpan={3}></td>
                    <td colSpan={3}></td>
                    <th colSpan={2}>Total Cost</th>
                    <th>{total}</th>
                    <td colSpan={2}></td>
                  </tr>
                  <tr>
                    <td colSpan={12}>
                      <div>
                        <p style={{ margin: 0, fontWeight: 600 }}>
                          1. Packing and Marking
                        </p>
                        <p style={{ margin: 0, fontWeight: 600 }}>
                          2. Third Party Certification
                        </p>
                        <p style={{ margin: 0, fontWeight: 600 }}>
                          3. Deliver CFR to Singapore Port
                        </p>
                        <p style={{ margin: 0, fontWeight: 600 }}>
                          Delivery Date: {getReadableTime(date)}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <div className="print-page-break" />
            <Row>
              <Box heading="Terms and Conditions">
                <Text styles={{ marginBottom: 10 }}>
                  PURCHASE ORDER TERMS AND CONDITIONS
                </Text>
                <Text>
                  This PURCHASE ORDER/SERVICE ORDER <b>{poNumber} </b>shall be
                  governed by the terms and conditions herein below and shall be
                  adhered to and strictly complied with at all times.
                </Text>
                <Text>
                  A The terms and conditions of this PURCHASE ORDER/SERVICE
                  ORDER shall be comprised of the following documents, listed in
                  the following order of precedence
                </Text>
                <br />
                <Text>
                  1.⁠ ⁠PURCHASE ORDER <b>{poNumber} </b> <br />
                  2.⁠ ⁠EXCEPTIONS AND DEVIATIONS: [Not Applicable] <br />
                  3.⁠ ⁠Section:I Form of Agreement <br />
                  4.⁠ ⁠Section I <br />
                  (b): Special Conditions of Contract Rev 6 of General
                  Conditions of Contract for Supply of Major Items of Plant and
                  Equipment, Edition 2# December 2005 which comprises of: <br />
                  a. Section I: Remuneration #As stated in the Purchase Order{' '}
                  <br />
                  b. Section IV: Scope of Work c Section V: Administration
                  Instructions [If Applicable] <br />
                  d. Section VI: Health, Safety and Environment Requirements for
                  Contractors <br />e Section VI I: QAQ/ C Requirements for
                  Contractors <br />
                  f.Section VI [This Section is not used]
                  <br /> g. Section XI: Documents and Drawings <br />
                  h. Materials, Services and Facilities to be provided by the
                  COMPANY.
                  <br />i CONTRACTOR's Plans <br />
                  .5 Section I <br />
                  (a): General Conditions of Contract for Supply of Major Items
                  of Plant and Equipment, Edition 2#December 2005 <br />
                  B. Any additional claims) on whatever grounds over and above
                  the Price under this PURCHASE ORDER/SERVICE ORDER shall not be
                  allowed for work(s) and services performed within the original
                  scope of the PURCHASE ORDER/SERVICE ORDER. Any requirements)
                  for CONTRACTOR 10 to perform works and service(s) beyond the
                  original scope of the PURCHASE ORDER/SERVICE ORDER shall be
                  strictly pre-approved prior by the COMPANY at its discretion
                  in the form of a variation order under the relevant provision
                  under the PURCHASE ORDER/SERVICE ORDER terms and conditions
                  either based on a lump sum or reimbursable basis. <br />
                  C. The CONTRACTOR shall not discontinue or suspend any
                  services/works under this PURCHASE ORDER/SERVICE ORDER for the
                  reason of any.
                </Text>
                <Text styles={{ marginTop: 20 }}>
                  Commercial Terms and Conditions:
                  <br /> 1.⁠ ⁠Liquidated Damages LD charges for delivery delay
                  at rates of 0.5% per week up to a maximum of 5%.
                  <br /> 2.⁠ ⁠Warranty Period 36 months from the delivery date
                  or 24 months from the date of Commissioning whichever occurs
                  first.
                  <br />
                  3.⁠ ⁠Payment Terms, Milestone and Invoicing <br />
                  a) All payments will be made 45 days from the date of receipt
                  invoice by E73AI Finance Department. <br />
                  b) Milestone Payment Payments as agreed are as follows:
                  <br /> 1)100% upon complete delivery c/w supporting documents
                  c Invoicing 1 original invoice shall be sent to:
                </Text>
                <br />
                <Text styles={{ marginTop: 20 }}>
                  E73AI Innovations Ltd, Level 21, Menara Perak, 24, Jalan
                  Perak, 50450 Kuala Lumpur, Malaysia.
                </Text>
                <br />
                <Text styles={{ marginTop: 20 }}>
                  Attention: Accounting Department
                  <br />
                  50450 Kuala Lumpur, Malaysia. <br />
                  In addition, an electronic copy of the invoice is to be sent
                  by e-mail to John Le Jeng Horng at <b>john@e73.ai</b> and
                  Nazli Bt Ahmad Bokhari at Sales@e73.ai <br /> .4 Delivery
                  Terms <br />
                  ⁠CFR Singapore Port
                </Text>
                <br />
                <Text>
                  IMPORTANT: <br /> Al CONTRACTOR/SUPPLIER documents and
                  document transmittals shall make clear reference to the listed
                  document codes assigned by COMPANY to the required
                  deliverables for this PURCHASE ORDER.
                  <br /> Failure by the CONTRACTOR to comply with the above may
                  lead to rejection of documents or delay in document approval
                  and subsequent payment. COMPANY/PURCHASER rejects any
                  responsibility for any delays in the completion of the WORK
                  due to incorrect document submission. <br />
                  9.⁠ ⁠Order Acknowledgement
                  <br /> It is essential that the PURCHASE ORDER acknowledgement
                  form attached is duly signed and returned to COMPANY,
                  Procurement Buyer, Mazaitul Shila Abd Ghani, e-mail:
                  Sales@e73.ai within 2 working days of the order date. Failure
                  to do so may result in payment delays. <br />
                  <br />
                  Please acknowledge this order within two(2) working days of
                  receipt.
                </Text>
                <br />
                <hr />
                <Row>
                  <Col xs="6">
                    <Text>For and behalf of </Text>
                    <Text>GALL THOMPSON ENVIRONMENT LTD</Text>
                    <Text>Agreed and Accepted by</Text>
                    <br />
                    <Text>Name:___________________________ </Text>
                    <Text>Title:____________________________</Text>
                    <Text>Date:___________________________ </Text>
                  </Col>
                  <Col xs="6">
                    <Text>For and behalf of </Text>
                    <Text>E73AI Innovations LTD</Text>
                    <Text>Agreed and Accepted by</Text>
                    <br />
                    <Text>Name:___________________________ </Text>
                    <Text>Title:____________________________ </Text>
                    <Text>Date:___________________________ </Text>
                  </Col>
                </Row>
              </Box>
            </Row>
            <div className="no-print">
              <ViewHistory
                data={history}
                title={
                  <p
                    style={{
                      margin: 0,
                      fontSize: 20,
                      fontWeight: 600,
                      paddingTop: 5,
                      paddingBottom: 5
                    }}
                  >
                    APPROVAL HISTORY
                  </p>
                }
                permitColor="#FFD580"
              />
            </div>
          </Row>
          <Spacer height={10} />
        </div>
        {/* <ReactToPrint
          documentTitle={`Purchase Request - ${pr?.number}`}
          pageStyle={'padding: 20'}
          trigger={() => (
            <ModalFooter>
              <Button color="warning" children="Print" />
            </ModalFooter>
          )}
          content={() => componentRef.current}
        /> */}
        <ModalFooter>
          <Button color="warning" onClick={() => setSubmit(true)}>
            Submit
          </Button>
        </ModalFooter>
        <SubmitModalWithForm
          isOpen={submit}
          onClose={(val: any) => {
            setSubmit(false)
            val && navigate('/purchase/dashboard')
          }}
          onSubmit={async (e: any) => {
            e.preventDefault()
            try {
              const body = {
                ...data?.pr,
                materialRequest: spares,
                fCharges,
                mCharges,
                pCharges,
                total,
                lastestUpdatedBy: userData?.id,
                status: 'createdPO'
              }

              const res = await makeAuthenticatedRequest(
                `${stock}/inventory/po/${id}`,
                'PATCH',
                body,
                accounts,
                instance
              )

              return res.status
            } catch (error) {}
          }}
          size="lg"
          header="Create Purchase Order"
        />
      </ProtectedLayout>
    </>
  )
}
