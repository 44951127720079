export const newFailureRates = [
  '4 to 10',
  '3 to 8',
  '5 to 12',
  '50 ',
  '25 to 50 ',
  '25  ',
  '20 ',
  '15 ',
  '30 ',
  '1 to 5 ',
  '25 ',
  '4 to 25 ',
  '1 to 20 ',
  '5 to 100 ',
  ' 20 ',
  '10  ',
  '10 ',
  '2 to 25 ',
  ' 5 to 25 ',
  '4 to 50 ',
  '3  ',
  '2 to 30 ',
  '6 to 15',
  '8 to 20',
  '10 to 25',
  '12 to 25'
]

export const newFailureModesRate: any = {
  'Valve Failure': '3 to 8',
  'Valve Spring Fatigue': '5 to 12',
  'Seal Leakage': '4 to 10',
  'Piston Rod and Crankshaft Wear': '4 to 10',
  'Motor or Drive Failure': '5 to 12',
  'Piston Ring Stick or Groove Deposits': '4 to 10',
  'High Discharge Temperature': '5 to 12',
  'Low Flow or Pressure': '3 to 8',
  'Gas Leakage': '5 to 12',
  'Drive Coupling Misalignment': '4 to 10',
  'Cylinder Cooling System Failure': '3 to 8',
  'Electrical Component Failure': '3 to 8',
  Overheating: '4 to 10',
  'Drive Belt Wear': '4 to 10',
  'Bearing Wear': '6 to 15',
  Contamination: '4 to 10',
  'Valve Wear': '4 to 10',
  'Cylinder Wear': '5 to 12',
  'FD Fan rotor damage (rotor change-out)': '50 ',
  'ID Fan rotor damage (rotor change-out)': '25 to 50 ',
  'Bearing failure (sleeve)': '25 ',
  'Bearing failure (ball)': '20 ',
  'Coupling failure (greased)': '25 ',
  'Coupling failure (non-greased; membrane/diaph.)': '15 ',
  'Housing damage': '25 ',
  Fouling: '30 ',
  'Belt failure': '2 to 25 ',
  'Rotor failure (rotor change-out)': '25 ',
  'Fouling (application specific)': '30 ',
  'Bearing failure (sleeve bearings)': '50 ',
  'Coupling failure (non-grea : membrane/diaph.)': '25 ',
  'Seal failure': '4 to 25 ',
  'Guide Vanes failure': '25 ',
  'Valve failure': '1 to 20 ',
  'Compression rings, rider rings failure': '5 to 100 ',
  'Rod packing failure': ' 20 ',
  'Seal system failure': '10 ',
  'Bearing failure (main/big end)': '10 ',
  'Offloading valves failure': '10 ',
  'Piston Rod failure': '25 ',
  'Cylinder Lining failure': '25 ',
  'Cooling System failure (application specific)': '25 ',
  'Blade/Propeller failure': '25 ',
  'Bearing failure': '4 to 50 ',
  'Pitch control mechanism': ' 5 to 25 ',
  'Gear failure': '20 ',
  'Oil seal failure': '3 ',
  'Control Circuit Failure': '4 to 10',
  'Failure of Indicating Devices': '3 to 8',
  'Loose Connections': '5 to 12',
  'Control Power Failure': '4 to 10',
  'Short Circuit': '3 to 8',
  'Insufficient Cooling': '4 to 10',
  'Reciprocating Compressor+B335': '3 to 8',
  'Circuit Breaker Failure': '3 to 8',
  'Auxiliary System Failure': '4 to 10',
  'Overcurrent Protection Failure': '5 to 12',
  'Contact Welding': '4 to 10',
  'Phase Imbalance': '4 to 10',
  'Busbar Failure': '4 to 10',
  'Motor Failure': '4 to 10',
  'Grounding System Failure': '5 to 12',
  'Insulation Failure': '3 to 8',
  Corrosion: '5 to 12',
  'Insufficient Lubrication': '4 to 10',
  'Lubrication Failure': '3 to 8',
  'Lubrication System Failure': '5 to 12',
  'Lubricator failure': '2 to 30 ',
  'Lubrication System failure': '10 ',
  'Power Supply Interruption': '3 to 8',
  'Controller Malfunction': '5 to 12',
  'Voltage Fluctuations': '3 to 8',
  'Electromagnetic Interference': '4 to 10',
  'Abnormal Noise': '4 to 10',
  'Excessive Load': '5 to 12',
  'Inadequate Grounding': '3 to 8',
  'Insufficient Starting Torque': '4 to 10',
  'External Contamination': '5 to 12',
  'Shaft Seal Leakage': '5 to 12',
  'Rotor Imbalance': '4 to 10',
  'Shaft Misalignment': '5 to 12',
  'Stator Winding Insulation Degradation': '3 to 8',
  'Phase Unbalance': '5 to 12',
  'Electrical Wiring fault': '4 to 10',
  'Actuator Pump Failure': '4 to 10',
  'Limit Switch Failure': '4 to 10',
  'Actuator Feedback Sensor Failure': '4 to 10',
  'Valve Position Feedback Error': '5 to 12',
  'Actuator Control Failure': '4 to 10',
  'Actuator Hydraulic Fluid Contamination': '5 to 12',
  'Actuator Drive Belt Failure': '4 to 10',
  'Valve Leakage': '3 to 8',
  'Valve Misalignment': '3 to 8',
  'Valve Sticking': '4 to 10',
  'Actuator Valve Seizure': '3 to 8',
  'Valve Corrosion and Erosion': '6 to 15',
  'Motor Overheating': '4 to 10',
  'Actuator Gear Failure': '5 to 12',
  'Actuator Malfunction': '5 to 12',
  'Sensor Drift': '3 to 8',
  'Mechanical Damage': '4 to 10',
  Overloading: '8 to 20',
  'Bushing Insulation Failure': '10 to 25',
  'Pressure Range fault': '3 to 8',
  'Excessive Heat': '5 to 12',
  'Output Signal Loss': '3 to 8',
  'Power Surge': '4 to 10',
  'Overpressure Conditions': '5 to 12',
  'Blockage or Clogging': '3 to 8',
  'Power Supply Failure': '4 to 10',
  'Communication fault': '4 to 10',
  'Zero Drift': '3 to 8',
  'Cable Damage': '5 to 12',
  'Sensor Failure': '6 to 15',
  'Signal Drift': '5 to 12',
  'Display Malfunction': '5 to 12',
  'Fluid Temperature Fluctuations': '3 to 8',
  'Flow Turbulence': '5 to 12',
  'Cooling System Failure': '12 to 25',
  'Data Loss': '4 to 10',
  'Environmental Conditions': '5 to 12',
  'Wiring Connection fault': '4 to 10',
  'Power Source fault': '3 to 8',
  'Moisture Ingress': '4 to 10',
  'Excessive Vibration': '3 to 8',
  'Electrical Connection fault': '4 to 10',
  'Corrosion and Erosion': '4 to 10',
  'Transmitter Communication Failure': '4 to 10',
  'Sensor Contamination': '4 to 10',
  'Power Surge or Transient': '4 to 10',
  'Data Inaccuracy': '3 to 8',
  'Sensor Calibration Drift': '4 to 10',
  'High Process Pressure': '5 to 12',
  'Power Supply fault': '3 to 8',
  'Wiring or Connection fault': '5 to 12',
  'EMI/RFI Interference': '4 to 10',
  'Diaphragm Damage': '3 to 8',
  'Vibration and Shock': '4 to 10',
  'Water Ingress': '4 to 10'
}

export const csTask1Options = [
  'Regularly check indicator lights and panel displays',
  'Regularly check indicator lights and alarms',
  'Regularly check for visible signs of loose connections',
  'Regularly check control circuit breaker status',
  'Monitor terminal screw torque and cable connections',
  'Monitor MCC operation during load fluctuations',
  'Monitor incoming power supply for fluctuations',
  'Monitor fan operation and airflow',
  'Monitor digital displays and communication interfaces',
  'Monitor communication interfaces and remote controls',
  'Monitor breaker operation and mechanical sounds',
  'Monitor auxiliary contactor operation'
]

export const ccTask1Options = [
  'Monitor valve positions and operation',
  'Monitor valve lift and response',
  'Monitor seal temperatures and pressure',
  'Monitor rod and crankshaft temperatures',
  'Monitor motor temperature and electrical parameters',
  'Monitor exhaust gas temperature and opacity',
  'Monitor discharge temperature and alarms',
  'Monitor discharge pressure and flow',
  'Monitor discharge gas composition and pressure',
  'Monitor coupling alignment and vibration',
  'Monitor cooling water temperature and flow',
  'Monitor control panel indicators and alarms',
  'Monitor compressor temperature and alarms',
  'Monitor belt tension and alignment',
  'Monitor bearing temperature and vibration',
  'Monitor air intake filters and separator performance',
  'Listen for unusual valve noises',
  'Check piston ring conditions and deposits',
  'Check for valve noises and inconsistencies',
  'Check for unusual gas odors and leaks',
  'Check for pressure drops and leaks',
  'Check for overheating signs and ventilation',
  'Check for oil leaks around seals',
  'Check for oil carryover and moisture',
  'Check for leaks in the cooling system',
  'Check for insulation damage and ventilation',
  'Check for burnt components and loose connections',
  'Check for belt cracks and wear',
  'Check cylinder liners for scoring and wear',
  'Check bearing lubrication and noise'
]

export const lsTask1Options = [
  'Monitor oil levels and color',
  'Check oil pressure and temperature'
]

export const mdTask1Options = [
  'Verify power backup systems and emergency shutdown',
  'Verify motor controller settings and parameters',
  'Verify incoming voltage using a multimeter',
  'Verify grounding and shielding of cables',
  'Regularly check indicator lights and panel displays',
  'Regularly check indicator lights and alarms',
  'Regularly check for visible signs of loose connections',
  'Regularly check control circuit breaker status',
  'Perform sound level measurements',
  'Observe motor performance for signs of strain',
  'Observe motor for sparks or abnormal operation',
  'Monitor terminal screw torque and cable connections',
  'Monitor power supply status and fluctuations',
  'Monitor motor performance for erratic behavior',
  'Monitor MCC operation during load fluctuations',
  'Monitor incoming power supply for fluctuations',
  'Monitor fan operation and airflow',
  'Monitor digital displays and communication interfaces',
  'Monitor communication interfaces and remote controls',
  'Monitor breaker operation and mechanical sounds',
  'Monitor auxiliary contactor operation',
  'Listen for unusual motor sounds during startup',
  'Listen for unusual motor sounds',
  'Listen for abnormal motor noise',
  'Inspect motor surroundings for dust and debris',
  'Inspect motor area for oil stains',
  'Inspect and secure motor cabling',
  'Check shaft seals for leaks',
  'Check power source connections and voltage stability',
  'Check motor grounding connections',
  'Check motor controller interface and displays',
  'Check cooling fan operation'
]

export const vsTask1Options = [
  'Verify temperature readings against reference standards',
  'Report any unusual impacts or physical damage',
  'Regularly check display for unusual readings',
  'Record load data and variations',
  'Record bushing oil levels and visual condition',
  'Perform zero and span adjustment',
  'Observe transmitter temperature',
  'Observe load fluctuations and report unusual patterns',
  'Observe for signal loss alarms',
  'Observe for power-related error messages',
  'Observe for physical contaminants in flow',
  'Observe for overpressure alarms and alerts',
  'Observe flow rate fluctuations and notify abnormalities',
  'Observe display for low battery warnings',
  'Observe communication status and error messages',
  'Monitor zero signal stability',
  'Monitor signal quality and strength',
  'Monitor sensor readings for consistency',
  'Monitor pressure range settings',
  'Monitor output signal for deviations',
  'Monitor for flickering or fading display',
  'Monitor fluid temperature variations',
  'Monitor fluid quality and potential contaminants',
  'Monitor flow patterns and irregularities',
  'Monitor fan speeds and temperatures',
  'Monitor digital display for anomalies',
  'Monitor differential pressure across the flowmeter',
  'Monitor data storage and retrieval status',
  'Monitor communication signal strength',
  'Monitor bushing temperatures and leaks',
  'Monitor backup power source',
  'Monitor ambient temperature and humidity',
  'Inspect wiring connections and terminals',
  'Inspect power connections and sources',
  'Inspect for signs of overheating or thermal stress',
  'Inspect flowmeter and piping for physical obstructions',
  'Check water levels, flow rates, and pump operation',
  'Check for sensor error messages',
  'Check for moisture buildup or water ingress',
  'Check for condensation or moisture buildup',
  'Check for changes in zero readings',
  'Check display readability and contrast',
  'Check display for error messages',
  'Check display and compare with process conditions',
  'Check data transmission and logging functions',
  'Check communication cables and connectors'
]

export const task1Options: any = {
  'Centrifugal Compressor': ccTask1Options,
  'Motor Drive': mdTask1Options,
  'Lubrication System': lsTask1Options,
  Cooler: lsTask1Options,
  'Control System': csTask1Options,
  'Vibration Sensors': vsTask1Options
}

export const csTask2Options = [
  'Regularly check for tripping anomalies and unusual noises',
  'Regularly check for temperature rise in MCC components',
  'Regularly check for alarms and abnormal behavior',
  'Regularly check breaker alignment and tripping mechanism'
]

export const ccTask2Options = [
  'Monitor cylinder temperatures and vibrations',
  'Check for abnormal vibration and noise'
]

export const mdTask2Options = [
  'Monitor motor vibration levels',
  'Monitor motor vibration for signs of misalignment',
  'Monitor bearing temperature and vibration',
  'Measure motor current during operation',
  'Check starting current during motor startup'
]

export const vsTask2Options = [
  'Perform vibration analysis and spectrum measurements',
  'Monitor flowmeter and transmitter for unusual vibrations',
  'Perform vibration analysis on critical parts',
  'Monitor vibrations using sensors'
]

export const task2Options: any = {
  'Centrifugal Compressor': ccTask2Options,
  'Motor Drive': mdTask2Options,
  'Lubrication System': mdTask2Options,
  Cooler: mdTask2Options,
  'Control System': csTask2Options,
  'Vibration Sensors': vsTask2Options
}

export const csTask4Options = [
  'Verify coordination with upstream protection',
  'Test contactor operation and auxiliary contacts',
  'Perform thermal scans to detect abnormal heat dissipation',
  'Perform thermal imaging on MCC assemblies',
  'Perform thermal imaging on busbar assemblies',
  'Perform motor insulation resistance test',
  'Perform ground resistance testing',
  'Perform dielectric tests on key components',
  'Monitor motor currents and temperature',
  'Monitor MCC room temperature and humidity',
  'Monitor MCC phase currents and voltage imbalance',
  'Monitor MCC current readings',
  'Monitor insulation resistance of cables and connections',
  'Monitor humidity levels and corrosive environments',
  'Monitor ground fault indicators and alarms',
  'Monitor contact resistance and voltage drops',
  'Monitor busbar temperature rise and load imbalance',
  'Implement corrosion prevention measures',
  'Verify voltage balance across MCC phases',
  'Tighten loose terminal connections and fasteners',
  'Test MCC control power sources',
  "Test MCC breakers ' trip settings",
  'Test auxiliary systems and relays',
  'Test and calibrate indicating devices',
  'Perform MCC protective relay tests',
  'Inspect motors for overheating and vibration',
  'Inspect MCC grounding connections and rods',
  'Inspect MCC components for signs of corrosion',
  'Inspect control wiring and connections',
  'Inspect contactors and relays for signs of welding',
  'Inspect circuit breaker contacts and auxiliary switches',
  'Inspect busbars for signs of corrosion or damage',
  'Clean cooling fans and ventilation openings',
  'Check temperature of MCC components',
  'Check insulation resistance and leakage currents'
]

export const ccTask4Options = [
  'Test cooling water for pH and corrosion',
  'Perform valve spring load tests',
  'Perform dynamic balancing of crankshaft',
  'Perform cylinder pressure and efficiency tests',
  'Perform compression and leak tests',
  'Monitor valve clearance and performance',
  'Monitor main bearing and connecting rod clearances',
  'Inspect valve springs and lifters',
  'Inspect valve spring condition and fatigue',
  'Inspect piston grooves for carbon deposits',
  'Check valve plate integrity and sealing',
  'Replace valve springs and inspect retainers',
  'Replace valve plates or rings',
  'Perform cylinder decarbonization',
  'Measure and replace piston rings',
  'Inspect and replace worn piston rods and bearings',
  'Clean and flush cylinder cooling system',
  'Perform valve leak tests and check for fouling',
  'Perform thermography and analyze cooling efficiency',
  'Perform seal integrity tests and check for wear',
  'Perform oil analysis and particle count',
  'Perform laser alignment and analyze coupling wear',
  'Perform gas analysis and leak detection',
  'Perform flow and pressure measurement and analysis',
  'Perform electrical testing and motor current analysis',
  'Perform electrical testing and inspect control circuits',
  'Perform belt tension measurement and visual inspection',
  'Inspect cooling system components'
]

export const lsTask4Options = [
  'Monitor bearing temperature and noise',
  'Inspect lubrication system for leaks and blockages',
  'Check and replenish lubrication',
  'Perform oil analysis and wear debris analysis',
  'Monitor oil viscosity and contaminants',
  'Inspect lubrication system components',
  'Change lubricant and filters',
  'Monitor oil quality and perform oil analysis',
  'Inspect oil cooler and heat exchanger',
  'Change compressor oil and oil filter'
]

export const mdTask4Options = [
  'Clean motor exterior and intake vents',
  'Perform partial discharge testing',
  'Perform ground resistance testing',
  'Monitor temperature rise during operation',
  'Monitor motor performance for uneven load',
  'Monitor motor load and current draw',
  'Monitor lubricant levels and quality',
  'Monitor controller response and motor behavior',
  'Monitor air quality and filter effectiveness',
  'Measure motor starting current and compare with specifications',
  'Measure electromagnetic interference levels',
  'Measure and record motor voltage variations',
  'Measure and record motor temperature rise',
  'Inspect ventilation and cooling systems',
  'Inspect rotor assembly for signs of imbalance',
  'Inspect mechanical components for signs of wear',
  'Inspect coupling and mounting for alignment issues',
  'Inspect connections and terminals for tightness',
  'Inspect bearings for wear and damage',
  'Perform dynamic balancing of the rotor',
  'Lubricate motor bearings',
  'Conduct insulation resistance tests',
  'Check phase currents for balance',
  'Check motor temperature during operation',
  'Align motor shaft using laser alignment',
  'Verify coordination with upstream protection',
  'Test contactor operation and auxiliary contacts',
  'Perform thermal scans to detect abnormal heat dissipation',
  'Perform thermal imaging on MCC assemblies',
  'Perform thermal imaging on busbar assemblies',
  'Perform motor insulation resistance test',
  'Perform ground resistance testing',
  'Perform dielectric tests on key components',
  'Monitor motor currents and temperature',
  'Monitor MCC room temperature and humidity',
  'Monitor MCC phase currents and voltage imbalance',
  'Monitor MCC current readings',
  'Monitor insulation resistance of cables and connections',
  'Monitor humidity levels and corrosive environments',
  'Monitor ground fault indicators and alarms',
  'Monitor contact resistance and voltage drops',
  'Monitor busbar temperature rise and load imbalance',
  'Implement corrosion prevention measures',
  'Verify voltage balance across MCC phases',
  'Tighten loose terminal connections and fasteners',
  'Test MCC control power sources',
  "Test MCC breakers' trip settings",
  'Test auxiliary systems and relays',
  'Test and calibrate indicating devices',
  'Perform MCC protective relay tests',
  'Inspect motors for overheating and vibration',
  'Inspect MCC grounding connections and rods',
  'Inspect MCC components for signs of corrosion',
  'Inspect control wiring and connections',
  'Inspect contactors and relays for signs of welding',
  'Inspect circuit breaker contacts and auxiliary switches',
  'Inspect busbars for signs of corrosion or damage',
  'Clean cooling fans and ventilation openings',
  'Check temperature of MCC components',
  'Check insulation resistance and leakage currents',
  'Verify insulation and perform continuity checks',
  'Test emergency shutdown systems and interlocks',
  'Inspect electrical connections, terminals, and wires',
  'Verify pump suction and discharge pressure',
  'Verify proper functioning of limit switches',
  'Verify proper functioning of feedback sensors',
  'Verify feedback signal accuracy and consistency',
  'Verify control loop performance and tuning',
  'Sample hydraulic fluid for analysis and contamination',
  'Replace drive belts as part of preventive maintenance',
  'Perform visual inspection for external leaks',
  'Perform laser alignment to ensure accurate positioning',
  'Operate valve to ensure smooth movement',
  'Operate valve periodically to prevent seizure',
  'Monitor valve movement and operating behavior',
  'Monitor torque values during operation',
  'Monitor sensor output and compare with actual position',
  'Monitor process fluid quality and pH',
  'Monitor process fluid parameters for anomalies',
  'Monitor motor temperature during operation',
  'Monitor hydraulic pump performance and noise',
  'Monitor gear backlash and alignment',
  'Monitor digital/analog signals for anomalies',
  'Monitor belt alignment and pulley condition',
  'Monitor actuator position and travel',
  'Lubricate valve stem and sealing components',
  'Lubricate gears and moving parts',
  'Inspect wiring connections and communication',
  'Inspect valve mounting and alignment',
  'Inspect valve internals for debris and fouling',
  'Inspect valve body and internals for corrosion',
  'Inspect filters and reservoir for cleanliness',
  'Inspect electrical connections and wiring',
  'Inspect drive belts for signs of wear and tension',
  'Implement protective coatings as necessary',
  'Conduct hydrostatic testing to verify sealing',
  'Clean motor components and air pathways',
  'Clean and lubricate valve stem and seals',
  'Check ventilation and cooling mechanisms',
  'Check hydraulic fluid levels and condition',
  'Check gear teeth for wear and proper meshing',
  'Check actuator motor for unusual noises',
  'Check actuator control panel for proper function',
  'Calibrate valve position indicators and feedback',
  'Calibrate limit switch positions and feedback',
  'Calibrate feedback sensors and position indicators',
  'Adjust or replace limit switches as needed'
]

export const task4Options: any = {
  'Centrifugal Compressor': ccTask4Options,
  'Motor Drive': mdTask4Options,
  'Lubrication System': lsTask4Options,
  Cooler: lsTask4Options,
  'Control System': csTask4Options,
  'Vibration Sensors': vsTask2Options
}

export const taskInterval: any = {
  Daily: 365,
  Weekly: 52,
  Monthly: 12,
  '3 Monthly': 4,
  '6 Monthly': 2,
  '1 Yearly': 1,
  '2 Yearly': 0.6,
  '3 Yearly': 0.4,
  '5 Yearly': 0.2,
  '10 Yearly': 0.1,
  '25000 hrs': 0.4,
  '50000 hrs': 0.2,
  '30000 h': 0.3
}

export const dropdownTasksOptions = [
  'Monitor valve positions and operation',
  'Monitor valve lift and response',
  'Monitor seal temperatures and pressure',
  'Monitor rod and crankshaft temperatures',
  'Monitor motor temperature and electrical parameters',
  'Monitor exhaust gas temperature and opacity',
  'Monitor discharge temperature and alarms',
  'Monitor discharge pressure and flow',
  'Monitor discharge gas composition and pressure',
  'Monitor coupling alignment and vibration',
  'Monitor cooling water temperature and flow',
  'Monitor control panel indicators and alarms',
  'Monitor compressor temperature and alarms',
  'Monitor belt tension and alignment',
  'Monitor bearing temperature and vibration',
  'Monitor air intake filters and separator performance',
  'Listen for unusual valve noises',
  'Check piston ring conditions and deposits',
  'Check for valve noises and inconsistencies',
  'Check for unusual gas odors and leaks',
  'Check for pressure drops and leaks',
  'Check for overheating signs and ventilation',
  'Check for oil leaks around seals',
  'Check for oil carryover and moisture',
  'Check for leaks in the cooling system',
  'Check for insulation damage and ventilation',
  'Check for burnt components and loose connections',
  'Check for belt cracks and wear',
  'Check cylinder liners for scoring and wear',
  'Check bearing lubrication and noise',
  'Verify temperature readings against reference standards',
  'Report any unusual impacts or physical damage',
  'Regularly check display for unusual readings',
  'Record load data and variations',
  'Record bushing oil levels and visual condition',
  'Perform zero and span adjustment',
  'Observe transmitter temperature',
  'Observe load fluctuations and report unusual patterns',
  'Observe for signal loss alarms',
  'Observe for power-related error messages',
  'Observe for physical contaminants in flow',
  'Observe for overpressure alarms and alerts',
  'Observe flow rate fluctuations and notify abnormalities',
  'Observe display for low battery warnings',
  'Observe communication status and error messages',
  'Monitor zero signal stability',
  'Monitor signal quality and strength',
  'Monitor sensor readings for consistency',
  'Monitor pressure range settings',
  'Monitor output signal for deviations',
  'Monitor for flickering or fading display',
  'Monitor fluid temperature variations',
  'Monitor fluid quality and potential contaminants',
  'Monitor flow patterns and irregularities',
  'Monitor fan speeds and temperatures',
  'Monitor digital display for anomalies',
  'Monitor differential pressure across the flowmeter',
  'Monitor data storage and retrieval status',
  'Monitor communication signal strength',
  'Monitor bushing temperatures and leaks',
  'Monitor backup power source',
  'Monitor ambient temperature and humidity',
  'Inspect wiring connections and terminals',
  'Inspect power connections and sources',
  'Inspect for signs of overheating or thermal stress',
  'Inspect flowmeter and piping for physical obstructions',
  'Check water levels, flow rates, and pump operation',
  'Check for sensor error messages',
  'Check for moisture buildup or water ingress',
  'Check for condensation or moisture buildup',
  'Check for changes in zero readings',
  'Check display readability and contrast',
  'Check display for error messages',
  'Check display and compare with process conditions',
  'Check data transmission and logging functions',
  'Check communication cables and connectors',
  'Verify power backup systems and emergency shutdown',
  'Verify motor controller settings and parameters',
  'Verify incoming voltage using a multimeter',
  'Verify grounding and shielding of cables',
  'Regularly check indicator lights and panel displays',
  'Regularly check indicator lights and alarms',
  'Regularly check for visible signs of loose connections',
  'Regularly check control circuit breaker status',
  'Perform sound level measurements',
  'Observe motor performance for signs of strain',
  'Observe motor for sparks or abnormal operation',
  'Monitor terminal screw torque and cable connections',
  'Monitor power supply status and fluctuations',
  'Monitor motor performance for erratic behavior',
  'Monitor MCC operation during load fluctuations',
  'Monitor incoming power supply for fluctuations',
  'Monitor fan operation and airflow',
  'Monitor digital displays and communication interfaces',
  'Monitor communication interfaces and remote controls',
  'Monitor breaker operation and mechanical sounds',
  'Monitor auxiliary contactor operation',
  'Listen for unusual motor sounds during startup',
  'Listen for unusual motor sounds',
  'Listen for abnormal motor noise',
  'Inspect motor surroundings for dust and debris',
  'Inspect motor area for oil stains',
  'Inspect and secure motor cabling',
  'Check shaft seals for leaks',
  'Check power source connections and voltage stability',
  'Check motor grounding connections',
  'Check motor controller interface and displays',
  'Check cooling fan operation',
  'Regularly check indicator lights and panel displays',
  'Regularly check indicator lights and alarms',
  'Regularly check for visible signs of loose connections',
  'Regularly check control circuit breaker status',
  'Monitor terminal screw torque and cable connections',
  'Monitor MCC operation during load fluctuations',
  'Monitor incoming power supply for fluctuations',
  'Monitor fan operation and airflow',
  'Monitor digital displays and communication interfaces',
  'Monitor communication interfaces and remote controls',
  'Monitor breaker operation and mechanical sounds',
  'Monitor auxiliary contactor operation'
]

export const disciplineDepartments = [
  'Electrical Technician',
  'Mechanical Technician',
  'Instrument technician',
  'Electrical Supervisor',
  'Mechanical Supervisor',
  'Instrument Supervisor'
]

export const healthScoreSconsequences: any = {
  'Multiple Fatality': 0,
  'Single Fatality': 1,
  'Major Injury': 2,
  'Minor Injury': 3,
  'Slight Injury': 4
}

export const environmentSconsequences: any = {
  'Massive Effect': 0,
  'Major Effect': 1,
  'Localised Effect': 2,
  'Minor Effect': 3,
  'Slight Effect': 4
}

export const economicRiskValues: any = {
  Extreme: [11, 16, 20, 23, 25],
  High: [7, 12, 17, 21, 24],
  Medium: [4, 8, 13, 18, 22],
  Low: [2, 5, 9, 14, 19],
  Negligible: [1, 3, 6, 10, 15]
}

export const economicConsequences: any = {
  'Extreme Effect': 4,
  'High Effect': 3,
  'Medium Effect': 2,
  'Low Effect': 1,
  'Negligble Effect': 0
}

export const tagsList = [
  {
    itemNumber: '23-GT-2331A',
    itemDescription: 'Gas turbine driver LM2500 SAC Gas Fuel',
    manufacturer: 'General Electric',
    criticality: 'HIGH',
    isHeader: true
  },
  {
    equipmentNumber: '23-BE-2331A',
    equipmentDescription: 'Combustion ignition unit',
    manufacturer: 'General Electric',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-IG-2352A',
    equipmentDescription: 'Combustion ignition plug',
    manufacturer: 'General Electric',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-PIT-2353A',
    equipmentDescription: 'HP recoup pressure',
    manufacturer: 'Yokogawa',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-PIT-2355A',
    equipmentDescription: 'PT inlet pressure (P48)',
    manufacturer: 'Yokogawa',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-PIT-2356A',
    equipmentDescription: 'GG inlet air pressure (P2)',
    manufacturer: 'Yokogawa',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-PT-2351AA',
    equipmentDescription: 'CDP - compressor discharge pressure (PS3)',
    manufacturer: 'Druck',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-PT-2351BA',
    equipmentDescription: 'CDP - compressor discharge pressure (PS3)',
    manufacturer: 'Druck',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-SE-2346AA',
    equipmentDescription: 'GG speed',
    manufacturer: 'General Electric',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-SE-2346BA',
    equipmentDescription: 'GG speed',
    manufacturer: 'General Electric',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-SE-2347AA',
    equipmentDescription: 'PT speed',
    manufacturer: 'General Electric',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-SE-2347BA',
    equipmentDescription: 'PT speed',
    manufacturer: 'General Electric',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-TE-2337AA',
    equipmentDescription: 'PT inlet temperature (T4.8)',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2337BA',
    equipmentDescription: 'PT inlet temperature (T4.8)',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2337CA',
    equipmentDescription: 'PT inlet temperature (T4.8)',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2337DA',
    equipmentDescription: 'PT inlet temperature (T4.8)',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2337EA',
    equipmentDescription: 'PT inlet temperature (T4.8)',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2337GA',
    equipmentDescription: 'PT inlet temperature (T4.8)',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2337HA',
    equipmentDescription: 'PT inlet temperature (T4.8)',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2337IA',
    equipmentDescription: 'PT inlet temperature (T4.8)',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2337JA',
    equipmentDescription: 'PT inlet temperature (T4.8)',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2337KA',
    equipmentDescription: 'PT inlet temperature (T4.8)',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2348AA',
    equipmentDescription: 'GG inlet temperature (T2)',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2348BA',
    equipmentDescription: 'GG inlet temperature (T2)',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-VE-2359A',
    equipmentDescription: 'GG forward vibration',
    manufacturer: 'Bently Nevada',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-VI-2359A',
    equipmentDescription: 'GG forward vibration',
    manufacturer: 'Bently Nevada',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-VE-2360A',
    equipmentDescription: 'PT aft vibration',
    manufacturer: 'Bently Nevada',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-VI-2360A',
    equipmentDescription: 'PT aft vibration',
    manufacturer: 'Bently Nevada',
    criticality: 'MEDIUM'
  },
  {
    itemNumber: '23-FS-2349A',
    itemDescription: 'Air intake filter house',
    manufacturer: 'Camfil Power',
    criticality: 'HIGH',
    isHeader: true
  },
  {
    equipmentNumber: '23-ELH-2332A ',
    equipmentDescription: 'Gas turbine enclosure heater ',
    manufacturer: 'Elmess ',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-HS-2333AA',
    equipmentDescription: 'Local shut-down switches',
    manufacturer: 'Cooper Crouse',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-HS-2333BA',
    equipmentDescription: 'Local shut-down switches',
    manufacturer: 'Cooper Crouse',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-KF-2336AA',
    equipmentDescription: 'Ventilation fans',
    manufacturer: 'Camfil/Akron',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-KF-2336BA',
    equipmentDescription: 'Ventilation fans',
    manufacturer: 'Camfil/Akron',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ME-2336AA',
    equipmentDescription: 'Ventilation fan electrical motors',
    manufacturer: 'Camfil/Siemens/',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ME-2336BA',
    equipmentDescription: 'Ventilation fan electrical motors',
    manufacturer: 'Camfil/Siemens/',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PDIT-2331A',
    equipmentDescription: 'Diff. pressure transmitter, combustion air filter',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PDIT-2333AA',
    equipmentDescription: 'Diff. pressure transmitter, enclosure to ambient',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PDIT-2333BA',
    equipmentDescription: 'Diff. pressure transmitter, enclosure to ambient',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TIT-2336BA',
    equipmentDescription: 'Temperature element (ventilation outlet)',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TZT-2336AA',
    equipmentDescription: 'Temperature element (ventilation outlet)',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XD-2336AA',
    equipmentDescription: 'Enclosure shut-off dampers with actuators',
    manufacturer: 'Camfil/NECO',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-XD-2336BA',
    equipmentDescription: 'Enclosure shut-off dampers with actuators',
    manufacturer: 'Camfil/NECO',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-XY-2336A',
    equipmentDescription: 'Enclosure shut-off damper shut-off valve',
    manufacturer: 'Asco',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-ZSC-2336AA',
    equipmentDescription: 'Limit switch for enclosure inlet and outlet dampers',
    manufacturer: 'Camfil/Heinrich',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-ZSC-2336BA',
    equipmentDescription: 'Limit switch for enclosure inlet and outlet dampers',
    manufacturer: 'Camfil/Heinrich',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-ZSO-2336AA',
    equipmentDescription: 'Limit switch for enclosure inlet and outlet dampers',
    manufacturer: 'Camfil/Heinrich',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-ZSO-2336BA',
    equipmentDescription: 'Limit switch for enclosure inlet and outlet dampers',
    manufacturer: 'Camfil/Heinrich',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-ZSC-2351AA',
    equipmentDescription: 'Limit switch for turbine enclosure door',
    manufacturer: 'Pepperl+Fuchs',
    criticality: 'LOW'
  },
  {
    equipmentNumber: '23-ZSC-2351BA',
    equipmentDescription: 'Limit switch for turbine enclosure door',
    manufacturer: 'Pepperl+Fuchs',
    criticality: 'LOW'
  },
  {
    equipmentNumber: '23-ZSC-2351CA',
    equipmentDescription: 'Limit switch for turbine enclosure door',
    manufacturer: 'Pepperl+Fuchs',
    criticality: 'LOW'
  },
  {
    itemNumber: '23-XZ-2331A',
    itemDescription: 'HV synchronous generator',
    manufacturer: 'WEG Electric',
    criticality: 'MEDIUM',
    isHeader: true
  },
  {
    equipmentNumber: '23-ELH-2335A-MA',
    equipmentDescription: 'Generator space heater',
    manufacturer: 'Bartec',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-KF-2332AA',
    equipmentDescription: 'Generator cooler fan',
    manufacturer: 'Sterling/WEG',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-KF-2332BA',
    equipmentDescription: 'Generator cooler fan',
    manufacturer: 'Sterling/WEG',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-KF-2332CA',
    equipmentDescription: 'Generator cooler fan',
    manufacturer: 'Sterling/WEG',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-KF-2332DA',
    equipmentDescription: 'Generator cooler fan',
    manufacturer: 'Sterling/WEG',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ME-2332AA',
    equipmentDescription: 'Generator cooler fan motor',
    manufacturer: 'WEG Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ME-2332BA',
    equipmentDescription: 'Generator cooler fan motor',
    manufacturer: 'WEG Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ME-2332CA',
    equipmentDescription: 'Generator cooler fan motor',
    manufacturer: 'WEG Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ME-2332DA',
    equipmentDescription: 'Generator cooler fan motor',
    manufacturer: 'WEG Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-SE-2382A',
    equipmentDescription: 'Key phasor',
    manufacturer: 'Bently Nevada',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-ST-2382A',
    equipmentDescription: 'Key phasor',
    manufacturer: 'Bently Nevada',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-TE-2332AA',
    equipmentDescription: 'Temperature element, exciter hot air',
    manufacturer: 'HB Sensors',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2332BA',
    equipmentDescription: 'Temperature element, exciter hot air',
    manufacturer: 'HB Sensors',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2334AA',
    equipmentDescription: 'Temperature element, bearing temp NDE',
    manufacturer: 'HB Sensors',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2334BA',
    equipmentDescription: 'Temperature element, bearing temp NDE',
    manufacturer: 'HB Sensors',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2335AA',
    equipmentDescription: 'Temperature element, cold air temp',
    manufacturer: 'HB Sensors',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2335BA',
    equipmentDescription: 'Temperature element, cold air temp',
    manufacturer: 'HB Sensors',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2351AA',
    equipmentDescription: 'Temperature element, generator stator phase U',
    manufacturer: 'HB Sensors',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2351BA',
    equipmentDescription: 'Temperature element, generator stator phase U',
    manufacturer: 'HB Sensors',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2352AA',
    equipmentDescription: 'Temperature element, generator stator phase V',
    manufacturer: 'HB Sensors',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2352BA',
    equipmentDescription: 'Temperature element, generator stator phase V',
    manufacturer: 'HB Sensors',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2353AA',
    equipmentDescription: 'Temperature element, generator stator phase W',
    manufacturer: 'HB Sensors',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2353BA',
    equipmentDescription: 'Temperature element, generator stator phase W',
    manufacturer: 'HB Sensors',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2385AA',
    equipmentDescription: 'Temperature element, thrust bearing DE temp',
    manufacturer: 'HB Sensors',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2385BA',
    equipmentDescription: 'Temperature element, thrust bearing DE temp',
    manufacturer: 'HB Sensors',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-VE-2391AA',
    equipmentDescription: 'Vibration sensor, generator bearing NDE',
    manufacturer: 'Bently Nevada',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-VE-2391BA',
    equipmentDescription: 'Vibration sensor, generator bearing NDE',
    manufacturer: 'Bently Nevada',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-VT-2391AA',
    equipmentDescription: 'Vibration sensor, generator bearing NDE',
    manufacturer: 'Bently Nevada',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-VT-2391BA',
    equipmentDescription: 'Vibration sensor, generator bearing NDE',
    manufacturer: 'Bently Nevada',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ZE-2392AA',
    equipmentDescription: 'Vibration sensor, generator bearing DE',
    manufacturer: 'Bently Nevada',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ZE-2392BA',
    equipmentDescription: 'Vibration sensor, generator bearing DE',
    manufacturer: 'Bently Nevada',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ZT-2392AA',
    equipmentDescription: 'Vibration sensor, generator bearing DE',
    manufacturer: 'Bently Nevada',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ZT-2392BA',
    equipmentDescription: 'Vibration sensor, generator bearing DE',
    manufacturer: 'Bently Nevada',
    criticality: 'MEDIUM'
  },
  {
    itemNumber: '23-FIC-2338',
    itemDescription: 'Gas fuel metering valve drive',
    manufacturer: 'Woodward Governor',
    criticality: 'HIGH',
    isHeader: true
  },
  {
    equipmentNumber: '23-FV-2338',
    equipmentDescription: 'Gas fuel metering valv',
    manufacturer: 'Woodward Governor',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-FT-2331',
    equipmentDescription: 'Flow meter, gas fuel suppl',
    manufacturer: ' Yokogawa',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-PIT-2334',
    equipmentDescription: 'Pressure transmitter, gas fuel supply',
    manufacturer: ' Yokogawa',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-SDV-2339',
    equipmentDescription: 'Gas fuel sec. shut-off valv',
    manufacturer: 'Woodward Governor',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-SDV-2340',
    equipmentDescription: 'Gas fuel prim. shut-off valv',
    manufacturer: 'Woodward Governor',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-SXY-2339A',
    equipmentDescription: 'Solenoid, gas fuel secondary shut-off valve',
    manufacturer: 'Woodward Governor',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-SXY-2340A',
    equipmentDescription: 'Solenoid, gas fuel primary shut-off valve',
    manufacturer: 'Woodward Governor',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-TIT-2340AA',
    equipmentDescription: 'Temperature element w/thermowell, gas fuel supply',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TIT-2340BA',
    equipmentDescription: 'Temperature element w/thermowell, gas fuel supply',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TW-2340AA',
    equipmentDescription: 'Temperature element w/thermowell, gas fuel supply',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TW-2340BA',
    equipmentDescription: 'Temperature element w/thermowell, gas fuel supply',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XV-2341A',
    equipmentDescription: 'Gas fuel vent-to-flare valve',
    manufacturer: '',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XV-2345A',
    equipmentDescription: 'Gas fuel shut-off bleed valve',
    manufacturer: 'Rotork Midland Ltd.',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XY-2341A',
    equipmentDescription: 'Solenoid, gas fuel vent-to-flare.valve',
    manufacturer: 'Asco',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XY-2345A',
    equipmentDescription: 'Solenoid, gas fuel shut-off.bleed valve',
    manufacturer: 'Parker Lucifer',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ZSC-2339A',
    equipmentDescription:
      'Limit switch, gas fuel sec. shut-off valve closed/open (part of 75-SDV-2339A)',
    manufacturer: 'Topworx',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ZSO-2339A',
    equipmentDescription:
      'Limit switch, gas fuel sec. shut-off valve closed/open (part of 75-SDV-2339A)',
    manufacturer: 'Topworx',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ZSC-2340A',
    equipmentDescription:
      'Limit switch, gas fuel prim.shut-off valve closed/open (part of 75-SDV-2340A)',
    manufacturer: 'Topworx',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ZSO-2340A',
    equipmentDescription:
      'Limit switch, gas fuel prim.shut-off valve closed/open (part of 75-SDV-2340A)',
    manufacturer: 'Topworx',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ZSC-2341A',
    equipmentDescription:
      'Limit switch, gas fuel vent-to-flare valve closed/open',
    manufacturer: 'Pepperl+Fuchs',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ZSO-2341A',
    equipmentDescription:
      'Limit switch, gas fuel vent-to-flare valve closed/open',
    manufacturer: 'Pepperl+Fuchs',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ZSC-2345A',
    equipmentDescription:
      'Limit switch, gas fuel shut-off bleed valve closed/open',
    manufacturer: 'Pepperl+Fuchs',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ZSO-2345A',
    equipmentDescription:
      'Limit switch, gas fuel shut-off bleed valve closed/open',
    manufacturer: 'Pepperl+Fuchs',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ZT-2338A',
    equipmentDescription:
      'Displacement transmitter, gas fuel metering valve (part of 75-FV-2338A)',
    manufacturer: 'Woodward Governor',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-SY-2340A',
    equipmentDescription: 'Gas fuel supply strainer',
    manufacturer: 'Vee Bee Ltd.',
    criticality: 'LOW'
  },
  {
    itemNumber: '23-AZ-2334A',
    itemDescription: 'Liquid fuel pump skid',
    manufacturer: 'D-R',
    criticality: 'HIGH',
    isHeader: true
  },
  {
    equipmentNumber: '23-FF-2331AA',
    equipmentDescription: 'Liquid fuel filter',
    manufacturer: 'Indufil BV',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FF-2331BA',
    equipmentDescription: 'Liquid fuel filter',
    manufacturer: 'Indufil BV',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FIC-2335A',
    equipmentDescription: 'Liquid fuel metering valve digital driver',
    manufacturer: 'Woodward Governor',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FT-2332A',
    equipmentDescription: 'Flow meter, liquid fuel supply',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FV-2332A',
    equipmentDescription: 'Flow divider',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FV-2335A',
    equipmentDescription: 'Liquid fuel metering valve',
    manufacturer: 'Woodward Governor',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-LG-2336A',
    equipmentDescription: 'Level gauge, hydrocarbon tank',
    manufacturer: 'Klinger',
    criticality: 'LOW'
  },
  {
    equipmentNumber: '23-ME-2336A',
    equipmentDescription: 'Liquid fuel supply pump motor',
    manufacturer: 'Siemens/Loher',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PDIT-2334A',
    equipmentDescription:
      'Diff. pressure transmitter, liquid fuel supply filter',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PIT-2331A',
    equipmentDescription: 'Pressure transmitter, liquid fuel supply',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PIT-2340AA',
    equipmentDescription:
      'Pressure transmitter, liquid fuel supply pump suction',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PIT-2340BA',
    equipmentDescription:
      'Pressure transmitter, liquid fuel supply pump suction',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PR-2336A',
    equipmentDescription: 'Liquid fuel supply pump',
    manufacturer: 'Bucher Hydraulics',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PSV-2332A',
    equipmentDescription: 'Safety relief valve, liquid fuel pump protection',
    manufacturer: 'Tai Milano',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-SDV-2331A',
    equipmentDescription: 'Liquid fuel secondary shut-off valve',
    manufacturer: 'Rotork Midland Ltd.',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-SXY-2331A',
    equipmentDescription: 'Solenoid, liquid fuel sec shut-off valve',
    manufacturer: 'Parker Lucifer ',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-SXY-2335A',
    equipmentDescription:
      'Liquid fuel primary shut-off valve (part of 75-FV-2335A)',
    manufacturer: 'Woodward Governor',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TA-2362A',
    equipmentDescription: 'Hydrocarbon tank',
    manufacturer: 'Dresser-Rand A/S',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2344A',
    equipmentDescription: 'Temperature element, prim.liquid fuel manifold',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2345A',
    equipmentDescription: 'Temperature element, sec liquid fuel manifold',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XV-2332A',
    equipmentDescription: 'Liquid fuel manifold purge valve',
    manufacturer: 'Rotork Midland Ltd.',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XV-2333A',
    equipmentDescription: 'Liquid fuel primary drain valve',
    manufacturer: 'Rotork Midland Ltd.',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XV-2334A',
    equipmentDescription: 'Liquid fuel secondary drain valve',
    manufacturer: 'Rotork Midland Ltd.',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: 'SG-2387A',
    equipmentDescription: 'Sight glass, liquid fuel filter',
    manufacturer: 'Corri Servais',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: 'SY-2336A',
    equipmentDescription: 'Liquid fuel supply strainer',
    manufacturer: 'Vee Bee Ltd.',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ZSC-2334A',
    equipmentDescription: 'Limit switch, liquid fuel sec. drain valve',
    manufacturer: 'Pepperl+Fuchs',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ZSO-2334A',
    equipmentDescription: 'Limit switch, liquid fuel sec. drain valve',
    manufacturer: 'Pepperl+Fuchs',
    criticality: 'MEDIUM'
  },
  {
    itemNumber: '23-AZ-2355A',
    itemDescription: 'Synthetic lube oil system',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM',
    isHeader: true
  },
  {
    equipmentNumber: '23-AE-2331AA',
    equipmentDescription: 'Chip detector',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-AE-2331BA',
    equipmentDescription: 'Chip detector',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-AE-2331CA',
    equipmentDescription: 'Chip detector',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-AE-2331DA',
    equipmentDescription: 'Chip detector',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-AE-2331EA',
    equipmentDescription: 'Chip detector',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ELH-2331A',
    equipmentDescription: 'Electric heater, SLO tank',
    manufacturer: 'Elmess',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FF-2334AA',
    equipmentDescription: 'Duplex filter unit, SLO scavenge',
    manufacturer: 'John Crane',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FF-2334BA',
    equipmentDescription: 'Duplex filter unit, SLO scavenge',
    manufacturer: 'John Crane',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FF-2335AA',
    equipmentDescription: 'Duplex filter unit, SLO supply',
    manufacturer: 'John Crane/Indufil',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FF-2335BA',
    equipmentDescription: 'Duplex filter unit, SLO supply',
    manufacturer: 'John Crane/Indufil',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-LG-2363A',
    equipmentDescription: 'Level gauge, tank level',
    manufacturer: 'ABB/K-Tek',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-LIT-2362A',
    equipmentDescription: 'Level transmitter, tank level',
    manufacturer: 'ABB/K-Tek',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PDIT-2367A',
    equipmentDescription: 'Differential pressure transmitter (supply filter)',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PDIT-2374A',
    equipmentDescription: 'Differential pressure transmitter (scavenge filter)',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2338AA',
    equipmentDescription:
      'Temperature element, aux. gearbox and sumps A-D scavenge',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2338BA',
    equipmentDescription:
      'Temperature element, aux. gearbox and sumps A-D scavenge',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2339AA',
    equipmentDescription:
      'Temperature element, aux. gearbox and sumps A-D scavenge',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2339BA',
    equipmentDescription:
      'Temperature element, aux. gearbox and sumps A-D scavenge',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2342AA',
    equipmentDescription:
      'Temperature element, aux. gearbox and sumps A-D scavenge',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2342BA',
    equipmentDescription:
      'Temperature element, aux. gearbox and sumps A-D scavenge',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2343AA',
    equipmentDescription:
      'Temperature element, aux. gearbox and sumps A-D scavenge',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2343BA',
    equipmentDescription:
      'Temperature element, aux. gearbox and sumps A-D scavenge',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2346AA',
    equipmentDescription:
      'Temperature element, aux. gearbox and sumps A-D scavenge',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2346BA',
    equipmentDescription:
      'Temperature element, aux. gearbox and sumps A-D scavenge',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: 'SY-2332A',
    equipmentDescription: 'Strainer, hydraulic start pump case drain',
    manufacturer: 'Vee Bee',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: 'SY-2343A',
    equipmentDescription: 'Strainer, hydraulic start pump case drain',
    manufacturer: 'Vee Bee',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: 'FA-2354A',
    equipmentDescription: 'SLO flame arrester',
    manufacturer: 'Amal',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-VZ-2317A',
    equipmentDescription: 'SLO demister',
    manufacturer: 'SPX Dollinger',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TV-2331A',
    equipmentDescription: 'Temperature control valve',
    manufacturer: 'Amot',
    criticality: 'MEDIUM'
  },
  {
    itemNumber: '23-AZ-2344A',
    itemDescription: 'Mineral lube oil system',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM',
    isHeader: true
  },
  {
    equipmentNumber: '23-ELH-2337A',
    equipmentDescription: 'Electric heater, MLO tank',
    manufacturer: 'Elmess',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FF-2332AA',
    equipmentDescription: 'Duplex filter unit, MLO supply',
    manufacturer: 'John Cran',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FF-2332BA',
    equipmentDescription: 'Duplex filter unit, MLO supply',
    manufacturer: 'John Cran',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FF-2351A',
    equipmentDescription: 'Single filter, jacking oil',
    manufacturer: '',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FV-2351A',
    equipmentDescription: 'Jacking oil diverter',
    manufacturer: 'Hawe Hydraulik',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-HA-2331AA',
    equipmentDescription: 'Common lube oil cooler',
    manufacturer: 'Specialist Heat Exchangers Ltd.',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-HA-2331BA',
    equipmentDescription: 'Common lube oil cooler',
    manufacturer: 'Specialist Heat Exchangers Ltd.',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-KF-2334AA',
    equipmentDescription: 'Lube oil cooler fan',
    manufacturer: 'London Fan Company / SHE',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-KF-2334BA',
    equipmentDescription: 'Lube oil cooler fan',
    manufacturer: 'London Fan Company / SHE',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-KF-2340A',
    equipmentDescription: 'MLO demister fan',
    manufacturer: 'FPZ Blower',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-LG-2383A',
    equipmentDescription: 'Level glass, MLO tank',
    manufacturer: 'ABB/K-Tek',
    criticality: 'LOW'
  },
  {
    equipmentNumber: '23-LIT-2351A',
    equipmentDescription: 'A Level transmitter,',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ME-2339A',
    equipmentDescription: 'MLO pump motor',
    manufacturer: 'Siemens/Loher',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ME-2340A',
    equipmentDescription: 'MLO demister fan motor',
    manufacturer: 'Siemens/Loher',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ME-2341A',
    equipmentDescription: 'Jacking oil pump motor',
    manufacturer: 'Siemens/Loher',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ME-2334AA',
    equipmentDescription: 'Lube oil cooler fan motor',
    manufacturer: 'Siemens/Loher',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ME-2334BA',
    equipmentDescription: 'Lube oil cooler fan motor',
    manufacturer: 'Siemens/Loher',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PCV-2333A',
    equipmentDescription: 'Pressure control valve, MLO supply',
    manufacturer: 'Fisher/Emerson',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PCV-2351A',
    equipmentDescription: 'Pressure control valve, jacking oil',
    manufacturer: 'Bosch Rexroth',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PDIT-2382A',
    equipmentDescription: 'Differential pressure transmitter, MLO filters',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PDIT-2383A',
    equipmentDescription: 'Differential pressure transmitter, MLO tank vent',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PG-2351A',
    equipmentDescription: 'Pressure gauge, jacking oil',
    manufacturer: 'Wika',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PIT-2384AA',
    equipmentDescription: 'Pressure transmitter, MLO supply',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PIT-2384BA',
    equipmentDescription: 'Pressure transmitter, MLO supply',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PR-2339A',
    equipmentDescription: 'MLO supply pump',
    manufacturer: 'Kral AG',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PR-2341A',
    equipmentDescription: 'Jacking oil pump',
    manufacturer: 'Bucher',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PR-2342A',
    equipmentDescription: 'Shaft-driven pump, MLO supply',
    manufacturer: 'IMO AB',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TIT-2379A',
    equipmentDescription: 'Temperature element w/thermowell, MLO supply',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TW-2379AA',
    equipmentDescription: 'Temperature element w/thermowell, MLO supply',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: 'FA-2336A',
    equipmentDescription: 'MLO flame arrester',
    manufacturer: 'Amal/SPX',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: 'SG-2384A',
    equipmentDescription: 'Sight glass, generator bearing outlet',
    manufacturer: 'WEG',
    criticality: 'LOW'
  },
  {
    equipmentNumber: 'SG-2385A',
    equipmentDescription: 'Sight glass, MLO tank',
    manufacturer: 'Corri Servais',
    criticality: 'LOW'
  },
  {
    itemNumber: '23-AZ-2332A',
    itemDescription: 'Hydraulic start system',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM',
    isHeader: true
  },

  {
    equipmentNumber: '23-AZ-2332A',
    equipmentDescription: 'Hydraulic start module',
    manufacturer: 'Bosch Rexroth',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FF-2333A',
    equipmentDescription: 'Filter, hydraulic start supply and return',
    manufacturer: 'Indufil BV',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FF-2337A',
    equipmentDescription: 'Filter, hydraulic start supply and return',
    manufacturer: 'Indufil BV',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FV-2345A',
    equipmentDescription: 'Hydraulic start pump control valve',
    manufacturer: 'Bosch Rexroth',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ME-2333A',
    equipmentDescription: 'Hydraulic start motor',
    manufacturer: 'Bosch Rexroth',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-ME-2338A',
    equipmentDescription: 'Hydraulic start pump motor (el.)',
    manufacturer: 'Siemens/Loher',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PG-2343A',
    equipmentDescription:
      'Hydraulic start supply and return pressure indicators',
    manufacturer: 'Wika',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PG-2344A',
    equipmentDescription:
      'Hydraulic start supply and return pressure indicators',
    manufacturer: 'Wika',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PZ-2338A',
    equipmentDescription: 'Hydraulic start pump',
    manufacturer: 'Bosch Rexroth',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-SE-2342A',
    equipmentDescription: 'Starter motor speed sensor',
    manufacturer: 'Jaquet',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2341AA',
    equipmentDescription:
      'Temperature element for lube oil scavenge line from clutch',
    manufacturer: 'The Hilliard Corporation',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-TE-2341BA',
    equipmentDescription:
      'Temperature element for lube oil scavenge line from clutch',
    manufacturer: 'The Hilliard Corporation',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XZ-2355A',
    equipmentDescription: 'Hydraulic starter clutch',
    manufacturer: 'The Hilliard Corporation',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: 'SY-2341A',
    equipmentDescription: 'Hydr. start clutch outlet strainer',
    manufacturer: 'Vee Bee Ltd.',
    criticality: 'LOW'
  },
  {
    itemNumber: '23-FS-2349A',
    itemDescription: 'Air inlet and ventilation system',
    manufacturer: 'Camfil Power Systems AB',
    criticality: 'MEDIUM',
    isHeader: true
  },

  {
    equipmentNumber: '23-XY-2336A',
    equipmentDescription: 'Ventilation shut-off dampers',
    manufacturer: 'Camfil Power Systems AB',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XY-2345A',
    equipmentDescription: 'Gas fuel shut-off valves',
    manufacturer: 'Camfil Power Systems AB',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XY-2341A',
    equipmentDescription: 'Gas fuel shut-off valves',
    manufacturer: 'Camfil Power Systems AB',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-SXY-2331A',
    equipmentDescription: 'Liquid fuel sec. shut-off valve',
    manufacturer: 'Camfil Power Systems AB',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XY-2332A',
    equipmentDescription: 'Liquid fuel manifold purge valve',
    manufacturer: 'Camfil Power Systems AB',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XY-2333A',
    equipmentDescription: 'Liquid fuel manifold drain valves',
    manufacturer: 'Camfil Power Systems AB',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XY-2334A',
    equipmentDescription: 'Liquid fuel manifold drain valves',
    manufacturer: 'Camfil Power Systems AB',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XY-2352A',
    equipmentDescription: 'Quick filling valve',
    manufacturer: 'Camfil Power Systems AB',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FZ-2332A',
    equipmentDescription: 'Air filter regulator',
    manufacturer: 'Bifold Company',
    criticality: 'MEDIUM'
  },
  {
    itemNumber: '23-AZ-2333A',
    itemDescription: 'Fire and gas system',
    manufacturer: 'FPE Fire Protection Engineering',
    criticality: 'SAFETY CRITICAL',
    isHeader: true
  },

  {
    equipmentNumber: '23-FD-4540A-101',
    equipmentDescription: 'Flame detector, turbine enclosure',
    manufacturer: 'Det-Tronics',
    criticality: 'SAFETY'
  },
  {
    equipmentNumber: '23-FD-4540A-102',
    equipmentDescription: 'Flame detector, turbine enclosure',
    manufacturer: 'Det-Tronics',
    criticality: 'SAFETY'
  },
  {
    equipmentNumber: '23-FD-4540A-103',
    equipmentDescription: 'Flame detector, turbine enclosure',
    manufacturer: 'Det-Tronics',
    criticality: 'SAFETY'
  },
  {
    equipmentNumber: '23-GD-4540A-101',
    equipmentDescription: 'Point gas detector, filter house',
    manufacturer: 'Simtronics ASA',
    criticality: 'SAFETY'
  },
  {
    equipmentNumber: '23-GD-4540A-102',
    equipmentDescription: 'Point gas detector, filter house',
    manufacturer: 'Simtronics ASA',
    criticality: 'SAFETY'
  },
  {
    equipmentNumber: '23-GD-4540A-103',
    equipmentDescription: 'Point gas detector, filter house',
    manufacturer: 'Simtronics ASA',
    criticality: 'SAFETY'
  },
  {
    equipmentNumber: '23-GD-4540A-104',
    equipmentDescription: 'Point gas detector, ventilation outlet',
    manufacturer: 'Det-Tronics',
    criticality: 'SAFETY'
  },
  {
    equipmentNumber: '23-GD-4540A-105',
    equipmentDescription: 'Point gas detector, ventilation outlet',
    manufacturer: 'Det-Tronics',
    criticality: 'SAFETY'
  },
  {
    equipmentNumber: '23-GD-4540A-106',
    equipmentDescription: 'Point gas detector, ventilation outlet',
    manufacturer: 'Det-Tronics',
    criticality: 'SAFETY'
  },
  {
    equipmentNumber: '23-HD-4540A-101',
    equipmentDescription: 'Heat detector, turbine enclosure',
    manufacturer: 'MEDC',
    criticality: 'SAFETY'
  },
  {
    equipmentNumber: '23-HD-4540A-102',
    equipmentDescription: 'Heat detector, turbine enclosure',
    manufacturer: 'MEDC',
    criticality: 'SAFETY'
  },
  {
    equipmentNumber: '23-HD-4540A-103',
    equipmentDescription: 'Heat detector, turbine enclosure',
    manufacturer: 'MEDC',
    criticality: 'SAFETY'
  },
  {
    equipmentNumber: '23-HS-4540A-101',
    equipmentDescription: 'Manual water mist release push button',
    manufacturer: 'MEDC',
    criticality: 'SAFETY'
  },
  {
    equipmentNumber: '23-HS-4540A-102',
    equipmentDescription: 'Manual water mist release push button',
    manufacturer: 'MEDC',
    criticality: 'SAFETY'
  },
  {
    equipmentNumber: '23-XI-4540A-101',
    equipmentDescription: 'Alarm beacon inside enclosure',
    manufacturer: 'MEDC',
    criticality: 'SAFETY'
  },
  {
    equipmentNumber: '23-XI-4540A-102',
    equipmentDescription: 'Alarm beacon inside enclosure',
    manufacturer: 'MEDC',
    criticality: 'SAFETY'
  },
  {
    itemNumber: '23-AZ-2333A',
    itemDescription: 'Water mist skid',
    manufacturer: 'FPE',
    criticality: 'MEDIUM',
    isHeader: true
  },
  {
    equipmentNumber: '23-LG-2335A',
    equipmentDescription: 'Level gauge, water mist vessel',
    manufacturer: 'KSR Kübler',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PCV-2331A',
    equipmentDescription: 'Pressure control valve, water mist N2 supply',
    manufacturer: 'Insert Deal',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PG-2336A',
    equipmentDescription: 'Pressure gauge, N2 bottle',
    manufacturer: 'Marsh Bellofram',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PG-2337A',
    equipmentDescription: 'Pressure gauge, N2 bottle',
    manufacturer: 'Marsh Bellofram',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PG-2338A',
    equipmentDescription: 'Pressure gauge, water mist pressure',
    manufacturer: 'Marsh Bellofram',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PG-2339A',
    equipmentDescription: 'Pressure gauge, water mist pressure',
    manufacturer: 'Marsh Bellofram',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PIT-2333A',
    equipmentDescription: 'Pressure transmitter, water mist release',
    manufacturer: 'Yokogawa',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-PSV-2334A',
    equipmentDescription: 'Safety relief valve, water mist N2 supply',
    manufacturer: 'Seetru Ltd.',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-VB-2340A',
    equipmentDescription: 'Water mist vessel',
    manufacturer: 'Gilwood Fabricators',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XY-2350A',
    equipmentDescription: 'Water mist release valve',
    manufacturer: 'Parker Lucifer',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XY-2354A',
    equipmentDescription: 'Water mist release valve',
    manufacturer: 'Parker Lucifer',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XY-2351A',
    equipmentDescription: 'Water mist N2 release valve',
    manufacturer: 'Parker Lucifer',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-XY-2353A',
    equipmentDescription: 'Water mist N2 release valve',
    manufacturer: 'Parker Lucifer',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-SY-2342A',
    equipmentDescription: 'Strainer, water mist vessel filling',
    manufacturer: 'Valnor/FPE',
    criticality: 'MEDIUM'
  }
]

export const equipmentList = [
  {
    equipmentNumber: 'K-T7111',
    equipmentDescription: '1st Stage Gas Compressor',
    manufacturer: 'General Electric',
    criticality: 'HIGH',
    type: 'Equipment'
  }
]

export const maintItemsList = [
  {
    equipmentNumber: '23-GT-2331A',
    equipmentDescription: 'Gas turbine driver LM2500 SAC Gas Fuel',
    manufacturer: 'General Electric',
    criticality: 'HIGH',
    isHeader: true
  },
  {
    equipmentNumber: '23-FS-2349A',
    equipmentDescription: 'Air intake filter house',
    manufacturer: 'General Electric',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '71-IG-7152A',
    equipmentDescription: 'Combustion ignition plug',
    manufacturer: 'Camfil Power',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-XZ-2331A',
    equipmentDescription: 'HV synchronous generator',
    manufacturer: 'WEG Electric',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-FIC-2338',
    equipmentDescription: 'Gas fuel metering valve drive',
    manufacturer: 'Woodward Governor',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-AZ-2334A',
    equipmentDescription: 'Liquid fuel pump skid',
    manufacturer: 'D-R',
    criticality: 'HIGH'
  },
  {
    equipmentNumber: '23-AZ-2355A',
    equipmentDescription: 'Synthetic lube oil system',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-AZ-2344A',
    equipmentDescription: 'Mineral lube oil system',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-AZ-2332A',
    equipmentDescription: 'Hydraulic start system',
    manufacturer: 'General Electric',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-FS-2349A',
    equipmentDescription: 'Air inlet and ventilation system',
    manufacturer: 'Camfil Power Systems AB',
    criticality: 'MEDIUM'
  },
  {
    equipmentNumber: '23-AZ-2333A',
    equipmentDescription: 'Fire and gas system',
    manufacturer: 'FPE Fire Protection Engineering',
    criticality: 'SAFETY CRITICAL'
  },
  {
    equipmentNumber: '23-AZ-2333A',
    equipmentDescription: 'Water mist skid',
    manufacturer: 'FPE',
    criticality: 'MEDIUM'
  }
]

export const failureModes = [
  {
    failureModeCode: 'AIR',
    failureDescription: 'Abnormal Instrument reading',
    equipmentClassCode: 'False alarm, faulty instrument indication'
  },
  {
    failureModeCode: 'BRD',
    failureDescription: 'Breakdown',
    equipmentClassCode: 'Serious damage (seizure, breakage)'
  },
  {
    failureModeCode: 'ERO',
    failureDescription: 'Erratic output',
    equipmentClassCode: 'Oscillating, hunting, instability'
  },
  {
    failureModeCode: 'ELF',
    failureDescription: 'External leakage - fuel',
    equipmentClassCode: 'External leakage of supplied fuel/gas'
  },
  {
    failureModeCode: 'ELP',
    failureDescription: 'External leakage - process medium',
    equipmentClassCode: 'Oil, gas, condensate, water'
  },
  {
    failureModeCode: 'ELU',
    failureDescription: 'External leakage - Utility medium',
    equipmentClassCode: 'Lubricant, cooling water'
  },
  {
    failureModeCode: 'FTS',
    failureDescription: 'Failure to Start on demand',
    equipmentClassCode: "Doesn't start on demand"
  },
  {
    failureModeCode: 'HIO',
    failureDescription: 'High output',
    equipmentClassCode: 'Overspeed/output above acceptance'
  },
  {
    failureModeCode: 'INL',
    failureDescription: 'Internal leakage',
    equipmentClassCode: 'Leakage internally of process or utility fluids'
  },
  {
    failureModeCode: 'LOO',
    failureDescription: 'Low output',
    equipmentClassCode: 'Delivery/output below acceptance'
  },
  {
    failureModeCode: 'NOI',
    failureDescription: 'Noise',
    equipmentClassCode: 'Abnormal noise'
  },
  {
    failureModeCode: 'OHE',
    failureDescription: 'Overheating',
    equipmentClassCode: 'Machine parts, exhaust, cooling water'
  },
  {
    failureModeCode: 'PDE',
    failureDescription: 'Parameter deviation',
    equipmentClassCode:
      'Monitored parameter exceeding limits, e.g. high/ low alarm'
  },
  {
    failureModeCode: 'PLU',
    failureDescription: 'Plugged/ choked',
    equipmentClassCode: 'Flow restriction(s)'
  },
  {
    failureModeCode: 'SER',
    failureDescription: 'Minor in-service problems',
    equipmentClassCode: 'Loose items, discoloration, dirt'
  },
  {
    failureModeCode: 'STD',
    failureDescription: 'Structural deficiency',
    equipmentClassCode: 'Material damages (cracks, wear, fracture, corrosion)'
  },
  {
    failureModeCode: 'STP',
    failureDescription: 'Failure to stop on demand',
    equipmentClassCode: "Doesn't stop on demand"
  },
  {
    failureModeCode: 'OTH',
    failureDescription: 'Other',
    equipmentClassCode: 'Failure modes not covered above'
  },
  {
    failureModeCode: 'UNK',
    failureDescription: 'Unknown',
    equipmentClassCode: 'Too little information to define a failure mode'
  },
  {
    failureModeCode: 'UST',
    failureDescription: 'Spurious stop',
    equipmentClassCode: 'Unexpected shutdown'
  },
  {
    failureModeCode: 'VIB',
    failureDescription: 'Vibration',
    equipmentClassCode: 'Abnormal vibration'
  },
  {
    failureModeCode: 'FCO',
    failureDescription: 'Failure to connect',
    equipmentClassCode: 'Failure to connect'
  },
  {
    failureModeCode: 'IHT',
    failureDescription: 'Insufficient heat transfer',
    equipmentClassCode: 'Missing, or too low, heat transfer'
  },
  {
    failureModeCode: 'IHT-1',
    failureDescription: 'Insufficient heat transfer',
    equipmentClassCode: 'Cooling/heating below acceptance'
  },
  {
    failureModeCode: 'FLP',
    failureDescription: 'Failure in lightning protection system ',
    equipmentClassCode: 'Failure in grounding, insufficient roofthickness, etc.'
  },
  {
    failureModeCode: 'FRO',
    failureDescription: 'Failure to rotate',
    equipmentClassCode: 'Failure to rotate'
  },
  {
    failureModeCode: 'FTD',
    failureDescription: 'Failure to disconnect',
    equipmentClassCode: 'Failure to disconnect upper connector'
  },
  {
    failureModeCode: 'FTI',
    failureDescription: 'Failure to function as intended ',
    equipmentClassCode: 'General operation failure'
  },
  {
    failureModeCode: 'LBP',
    failureDescription: 'Low oil supply pressure',
    equipmentClassCode: 'Low oil supply pressure'
  },
  {
    failureModeCode: 'LOA',
    failureDescription: 'Load drop',
    equipmentClassCode: 'Load drop'
  },
  {
    failureModeCode: 'LOB',
    failureDescription: 'Loss of buoyancy',
    equipmentClassCode: 'Loss of buoyancy in idle position'
  },
  {
    failureModeCode: 'MOF',
    failureDescription: 'Mooring failure',
    equipmentClassCode: 'Mooring failure'
  },
  {
    failureModeCode: 'PTF',
    failureDescription: 'Power/signal transmission failure',
    equipmentClassCode: 'Power/signal transmission failure'
  },
  {
    failureModeCode: 'SBU',
    failureDescription: 'Sludge build-up',
    equipmentClassCode: 'Sludge build-up'
  },
  {
    failureModeCode: 'SLP',
    failureDescription: 'Slippage',
    equipmentClassCode: 'Wire slippage'
  },
  {
    failureModeCode: 'SPO',
    failureDescription: 'Spurious operation',
    equipmentClassCode: 'Unexpected operation'
  },
  {
    failureModeCode: 'DOP',
    failureDescription: 'Delayed operation',
    equipmentClassCode: 'Delayed response to commands'
  },
  {
    failureModeCode: 'FOF',
    failureDescription: 'Faulty output frequency',
    equipmentClassCode: 'Wrong/oscillating frequency'
  },
  {
    failureModeCode: 'FOV',
    failureDescription: 'Faulty output voltage',
    equipmentClassCode: 'Wrong/unstable output voltage'
  },
  {
    failureModeCode: 'FTF',
    failureDescription: 'Failure to function on demand',
    equipmentClassCode:
      'Doesn’t start on demand, or failure to respond on signal/ activation, or does not respond to input commands'
  },
  {
    failureModeCode: 'FTF-1',
    failureDescription: 'Failure to function on demand',
    equipmentClassCode:
      'Auxiliary function, subsystem, monitoring or control device fails to operate when demanded'
  },
  {
    failureModeCode: 'FTI-1',
    failureDescription: 'Failure to function as intended',
    equipmentClassCode: 'Response not as expected'
  },
  {
    failureModeCode: 'FTI-2',
    failureDescription: 'Failure to function as intended',
    equipmentClassCode:
      'Protection device/ circuit breaker/ switch fails to clear a fault on the circuit'
  },
  {
    failureModeCode: 'FTO',
    failureDescription: 'Failure to open on demand',
    equipmentClassCode:
      'Circuit breaker/switchfuse/disconnector/bus tie fails to open when demanded'
  },
  {
    failureModeCode: 'FTR',
    failureDescription: 'Failure to regulate',
    equipmentClassCode: 'Fails to control the load, poor response to feedback'
  },
  {
    failureModeCode: 'OHE-1',
    failureDescription: 'Overheating',
    equipmentClassCode: 'Too high internal temperature'
  },
  {
    failureModeCode: 'FTF-2',
    failureDescription: 'Spurious operation',
    equipmentClassCode:
      'Intermittent disconnection or connection unintended operation.'
  },
  {
    failureModeCode: 'FTF-3',
    failureDescription: 'Spurious operation',
    equipmentClassCode: 'Unexpected operation'
  },
  {
    failureModeCode: 'STD-1',
    failureDescription: 'Structural deficiency',
    equipmentClassCode: 'Reservoir rupture'
  },
  {
    failureModeCode: 'UST-1',
    failureDescription: 'Spurious stop',
    equipmentClassCode: 'Unintended disconnection of a circuit'
  },
  {
    failureModeCode: 'FTC',
    failureDescription: 'Failure to close on demand',
    equipmentClassCode: 'Doesn’t close on demand'
  },
  {
    failureModeCode: 'LCP',
    failureDescription: 'Leakage in closed position',
    equipmentClassCode: 'Leak through valve in closed position'
  },
  {
    failureModeCode: 'LOA-1',
    failureDescription: 'Load drop',
    equipmentClassCode: 'Unintended drop/launch of a lifeboat'
  },
  {
    failureModeCode: 'POW',
    failureDescription: 'Insufficient power',
    equipmentClassCode: 'Lack of or too low power supply'
  },
  {
    failureModeCode: 'SHH',
    failureDescription: 'Spurious high alarm level',
    equipmentClassCode: ' e.g. 60% of Lower Explosive Limit (LEL)'
  },
  {
    failureModeCode: 'SLL',
    failureDescription: 'Spurious low alarm level',
    equipmentClassCode: 'e.g. 20 % of Lower Explosive Limit (LEL)'
  },
  {
    failureModeCode: 'SPO-1',
    failureDescription: 'Spurious operation',
    equipmentClassCode: 'e.g. false alarm'
  },
  {
    failureModeCode: 'SPO-2',
    failureDescription: 'Spurious operation',
    equipmentClassCode: 'Undesired opening'
  },
  {
    failureModeCode: 'SPO-3',
    failureDescription: 'Spurious operation',
    equipmentClassCode:
      'Fails to operate as demanded, false alarm, premature closure/stop, unexpected operation/fails to operate as demanded'
  },
  {
    failureModeCode: 'VLO',
    failureDescription: 'Very low output',
    equipmentClassCode:
      'e.g. reading between 11 % LEL to 30 % Lower Explosive Limit (LEL) upon test gas.'
  },
  {
    failureModeCode: 'CSF',
    failureDescription: 'Control/ signal failure',
    equipmentClassCode:
      'No, or faulty monitoring or regulation, failure to transmit or receive command or data, failure to actuate function'
  },
  {
    failureModeCode: 'FTL',
    failureDescription: 'Failure to lock/unlock',
    equipmentClassCode:
      'Doesn’t lock or unlock when demanded, failure to connect or disconnect, failure to release SCM from its mounting base'
  },
  {
    failureModeCode: 'HTF',
    failureDescription: 'Heating failure',
    equipmentClassCode: 'Loss of ability to provide heating'
  },
  {
    failureModeCode: 'IHT',
    failureDescription: 'Insufficient heat transfer',
    equipmentClassCode:
      'Lack off or reduced ability to transfer heat from hot temperature areas, such as power transformers or power supply electronics'
  },
  {
    failureModeCode: 'ILP',
    failureDescription: 'Internal leakage - process medium',
    equipmentClassCode:
      'Leakage process medium going through heat coils or similar'
  },
  {
    failureModeCode: 'ILU',
    failureDescription: 'Internal leakage - utility medium',
    equipmentClassCode: 'Leakage internally of utility fluids'
  },
  {
    failureModeCode: 'SET',
    failureDescription: 'Failure to set/retrieve',
    equipmentClassCode: 'Failed set/retrieve operations'
  },
  {
    failureModeCode: 'UBU',
    failureDescription: 'Global buckling',
    equipmentClassCode: 'Upheaval or lateral buckling'
  },
  {
    failureModeCode: 'CLW',
    failureDescription: 'Control-line-to-well communication',
    equipmentClassCode: 'Loss of hydraulic control fluids into the well bore'
  },
  {
    failureModeCode: 'PCL',
    failureDescription: 'Premature closure',
    equipmentClassCode: 'Spurious closure of valve without command'
  },
  {
    failureModeCode: 'WCL',
    failureDescription: 'Well-to-control-line communication',
    equipmentClassCode: 'Influx of well fluids into valve control line'
  },
  {
    failureModeCode: 'FCU',
    failureDescription: 'Failure to cut',
    equipmentClassCode: 'Shear cut valve unable to cut equipment'
  },
  {
    failureModeCode: 'POD',
    failureDescription: 'Loss of functions on both pods',
    equipmentClassCode: 'Both pods are not functioning as desired'
  },
  {
    failureModeCode: 'FWR',
    failureDescription: 'Failure while running',
    equipmentClassCode: 'Unable to run equipment or tools'
  },
  {
    failureModeCode: 'STU',
    failureDescription: 'Stuck',
    equipmentClassCode: 'Tools becoming stuck in the BOP or X-mas Tree'
  },
  {
    failureModeCode: 'LOL',
    failureDescription: 'Overloading',
    equipmentClassCode: 'Overloading'
  },
  {
    failureModeCode: 'LOG',
    failureDescription: 'Over greasing/Over fill Tube Tevels',
    equipmentClassCode: 'Over greasing/Over fill Tube Tevels'
  },
  {
    failureModeCode: 'LOC',
    failureDescription: 'Location/(No Air movement)',
    equipmentClassCode: 'Location/(No Air movement)'
  },
  {
    failureModeCode: 'LHV',
    failureDescription: 'High Viscosity',
    equipmentClassCode: 'High Viscosity'
  },
  {
    failureModeCode: 'LWR',
    failureDescription: 'Wror viscosity (Oil or Grease)',
    equipmentClassCode: 'Wror viscosity (Oil or Grease)'
  },
  {
    failureModeCode: 'LPL',
    failureDescription: 'Poor lube circulation',
    equipmentClassCode: 'Poor lube circulation'
  },
  {
    failureModeCode: 'LIC',
    failureDescription: 'Improper/inadequate cooling',
    equipmentClassCode: 'Improper/inadequate cooling'
  },
  {
    failureModeCode: 'LNL',
    failureDescription: 'No lube cooling',
    equipmentClassCode: 'No lube cooling'
  },
  {
    failureModeCode: 'LMS',
    failureDescription:
      'Multi speed/Load components (high speed input, Low speed outlet)',
    equipmentClassCode:
      'Multi speed/Load components (high speed input, Low speed outlet)'
  }
]

export const applicableModes: any = {
  'Actuating device': 'AIR,ELP,ERO,INL,STD',
  'Antisurge System': 'AIR,ELP,ELU,ERO,INL,LOO,OTH,PDE,SER,UNK,VIB',
  'Balance piston': 'PDE,UST',
  'Cabling & junction boxes': 'AIR,ERO',
  Casing: 'ELP,ELU,OTH,SER',
  'Control unit': 'AIR,ELP,ELU,ERO,INL,OTH,PDE,UST',
  'Control,isolating & check valves': 'ELP,ERO,INL,SER,UST',
  'Cooler(s)': 'ELU,OHE,SER',
  'Coupling to driven unit': 'ELU,SER,VIB',
  'Coupling to driver': 'VIB',
  'Dry gas seal ': 'BRD,ELP,ELU,FTS,INL,OTH,PDE,SER,UST,VIB',
  'Filter(s) ': 'AIR,OTH,PDE,PLU,SER',
  'Flange joints': 'ELP,ELU',
  'Gearbox/var.drive ': 'ELU,FTS,OHE,STD,VIB',
  'Instrument, flow': 'AIR,ERO,SER,UST',
  'Instrument, general': 'AIR,ELP,ERO,FTS',
  'Instrument, level': 'AIR,OTH,PDE,SER,UST',
  'Instrument, pressure ': 'AIR,ELU,ERO,INL,OTH,PDE,UST',
  'Instrument, speed': 'AIR',
  'Instrument, temperature ': 'AIR,ELU,OHE,SER,UST',
  'Instrument, vibration ': 'AIR,PDE,SER,UST,VIB',
  'Internal piping': 'SER',
  'Internal power supply ': 'FTS,SER',
  'Interstage seals': 'PDE,VIB',
  Monitoring: 'AIR',
  Motor: 'FTS,SER,UST',
  'Oil ': 'SER',
  Other: 'AIR,ELP,ELU,FTS,INL,PDE,PLU,SER,STD,UNK,VIB',
  Packing: 'UNK',
  'Piping ': 'ELU,PLU,SER',
  'Pump ': 'LOO,OTH,UST',
  'Purge air': 'ELU',
  'Radial bearing ': 'PDE,VIB',
  'Reservoir incl. heating system ': 'AIR,OTH,SER',
  'Rotor w/ impellers': 'LOO',
  'Seal gas': 'AIR,ELP,FTS,INL',
  'Seals ': 'ELP,ELU,FTS,SER,UST',
  'Shaft seals ': 'ELP,ELU,INL,SER,UNK,VIB',
  'Subunit ': 'AIR,BRD,ELP,ELU,FTS,HIO,INL,LOO,OHE,OTH,PDE,PLU,SER,UNK,UST,VIB',
  'Thrust bearing ': 'AIR,PDE,VIB',
  'Unknown ':
    'AIR,BRD,ELP,ELU,ERO,FTS,HIO,INL,LOO,NOI,OTH,PDE,SER,STD,UNK,UST,VIB',
  'Valves ': 'AIR,ELP,ELU,ERO,FTS,INL,OTH,PDE,PLU,SER,UST',
  'Wiring ': 'FTS,OTH,UST',
  'Blockage/plugged': 'ERO,OTH,PDE,PLU,SER',
  'Breakage ': 'AIR,ELP,OTH,SER,STD,VIB',
  'Burst ': 'FTS,UST',
  'Clearance/ alignment failure ': 'BRD,ELU,ERO,PDE,PLU,SER,VIB',
  'Combined causes': 'STD,UNK',
  Contamination: 'FTS,INL,LOO,OTH,UST',
  'Control failure ': 'AIR,ERO,FTS,OHE,OTH,PDE,SER,STP,UST,VIB',
  'Corrosion ': 'ELP,OTH,SER,STD',
  'Deformation ': 'BRD,OTH,STD',
  'Earth/isolation fault': 'SER',
  'Electrical failure - general': 'AIR,ERO,FTS,OTH,PDE,SER,UST',
  'External influence - general': 'ERO,PDE',
  'Faulty power/voltage': 'UNK',
  'Faulty signal/indication/alarm ': 'AIR,ERO,FTS,INL,PDE,SER,STP,UST',
  'Instrument failure - general ': 'AIR,ELU,ERO,FTS,OTH,PDE,SER,UNK,UST,VIB',
  Leakage: 'ELP,ELU,FTS,INL,LOO,SER,UNK,VIB',
  'Looseness ': 'ELP,ELU,SER,VIB',
  'Material failure - general ': 'ELP,ELU,ERO,INL,OTH,PDE,SER,VIB',
  'Mechanical Failure - general':
    'AIR,ELP,ELU,ERO,FTS,INL,OTH,PDE,PLU,SER,STD,UNK,UST,VIB',
  'Misc. external influences ': 'FTS,SER',
  'Miscellaneous - general': 'OTH,VIB',
  'No cause found': 'OHE',
  'No signal/indication/alarm ': 'AIR,OTH,SER,STP',
  'Open circuit': 'UST',
  'Out of adjustment ': 'AIR,ELP,ERO,INL,LOO,OHE,OTH,PDE,SER,UST',
  Overheating: 'OHE,PDE',
  'Short circuiting': 'SER',
  'Software failure ': 'AIR,PDE,UNK,UST,VIB',
  Sticking: 'AIR,BRD,INL,OTH,SER,UST',
  'Vibration ': 'ELU,FTS,NOI,UST,VIB',
  'Wear ': 'AIR,ELP,ELU,INL,LOO,OHE,OTH,SER,UNK,VIB',
  Compressor:
    'AIR,BRD,ERO,ELP,ELU,FTS,HIO,INL,LOO,NOI,OHE,PDE,SER,STD,STP,OTH,UNK,UST,VIB'
}

export const assetCriticalityList: any = {
  equipment: equipmentList,
  maintainableItems: maintItemsList,
  tags: tagsList?.filter((t: any) => !t?.isHeader)
}

export const existingTaksList = [
  {
    name: 'Visual Inpection & Lube Oil Sampling Round',
    interval: 'Weekly',
    type: 'PM Task',
    status: 'EXISTING'
  },
  {
    name: 'GE HC COMP Minor Inspection (25000 hrs)',
    interval: '25000 hrs',
    type: 'Time Based',
    status: 'EXISTING'
  },
  {
    name: 'GE HC COMP Major Inspection (50000 hrs)',
    interval: '50000 hrs',
    type: 'Time Based',
    status: 'EXISTING'
  },
  {
    name: 'Centrifugal Compressor Checks',
    interval: '3 Monthly',
    type: 'PM Task',
    status: 'CLASS'
  },
  {
    name: 'Inspection/Function Checks (12M)',
    interval: '1 Yearly',
    type: 'PM Task',
    status: 'EXISTING'
  },
  {
    name: 'Centrifugal Compressor Annual (12M)',
    interval: '1 Yearly',
    type: 'PM Task',
    status: 'EXISTING'
  }
]

export const receomendedTaskList = [
  {
    name: 'Monitor valve positions and operation',
    type: 'Condition Based',
    interval: 'Weekly',
    status: 'NEW'
  },
  {
    name: 'Monitor valve lift and response',
    type: 'Time Based',
    interval: 'Monthly',
    status: 'NEW'
  },
  {
    name: 'Monitor seal temperatures and pressure',
    type: 'Operator Monitoring',
    interval: '1 Yearly',
    status: ' NEW'
  },
  {
    name: 'Monitor rod and crankshaft temperatures',
    type: 'PM Task',
    interval: '6 Monthly',
    status: 'NEW'
  },
  {
    name: 'Monitor motor temperature and electrical parameters',
    type: 'PM Task',
    interval: '6 Monthly',
    status: 'NEW'
  },
  {
    name: 'Monitor exhaust gas temperature and opacity',
    type: 'PM Task',
    interval: '2 Yearly',
    status: 'NEW'
  }
]

export const compressorFMs = [
  'Valve Failure',
  'Valve Spring Fatigue',
  'Seal Leakage',
  'Piston Rod and Crankshaft Wear',
  'Motor or Drive Failure',
  'Piston Ring Stick or Groove Deposits',
  'High Discharge Temperature',
  'Low Flow or Pressure',
  'Gas Leakage',
  'Drive Coupling Misalignment',
  'Cylinder Cooling System Failure',
  'Electrical Component Failure',
  'Overheating',
  'Drive Belt Wear',
  'Bearing Wear',
  'Contamination',
  'Valve Wear',
  'Cylinder Wear',
  'FD Fan rotor damage (rotor change-out)',
  'ID Fan rotor damage (rotor change-out)',
  'Bearing failure (sleeve)',
  'Bearing failure (ball)',
  'Coupling failure (greased)',
  'Coupling failure (non-greased; membrane/diaph.)',
  'Housing damage',
  'Fouling',
  'Belt failure',
  'Rotor failure (rotor change-out)',
  'Fouling (application specific)',
  'Bearing failure (sleeve bearings)',
  'Coupling failure (non-greased: membrane/diaph.)',
  'Seal failure',
  'Guide Vanes failure',
  'Compression rings, rider rings failure',
  'Rod packing failure',
  'Seal system failure',
  'Bearing failure (main/big end)',
  'Offloading valves failure',
  'Piston Rod failure',
  'Cylinder Lining failure',
  'Cooling System failure (application specific)',
  'Blade/Propeller failure',
  'Bearing failure',
  'Pitch control mechanism',
  'Gear failure',
  'Oil seal failure',
  'Air Intake failure',
  'Starting system failure',
  'Inlet guide vane failure',
  'Air inlet/filter failure',
  'Burner/combustion chamber failure',
  'Compressor rotor failure',
  'Compressor blades failure',
  'Compressor bearing failure',
  'Casing / split line / bolting failure',
  'Nozzle failure',
  'Bucket failure',
  'Shroud failure',
  'Turbine rotor failure',
  'Seal/labyrinth failure',
  'Coupling system failure',
  'Turbine bearing failure',
  'Bleed valve failure',
  'Overspeed protection system failure',
  'Speed Control Valve failure',
  'Online vibration monitoring system failure',
  'Lube oil system failure',
  'Hydraulic oil system failure',
  'air ventilation system failure',
  'Gas Fuel system failure',
  'Exhaust failure',
  'Fail to operate on demand',
  'Fire protection system failure',
  'Drop in performance due to fouling',
  'Lube oil cooler failure',
  'Cooling and sealing air system failure',
  'Load gear box - shaft & gear wheel failure',
  'Load gear box -journal & thrust bearing failure',
  'Load gear box - Labyrinth seats',
  'Accessory gear box - shaft & gear wheel failure',
  'Accessory gear box journal & thrust bearing failure',
  'Accessory gear box - labyrinth seats',
  'Fuel gas treatment system failure',
  'Instrument Failure'
]

export const motorFMs = [
  'Power Supply Interruption',
  'Controller Malfunction',
  'Voltage Fluctuations',
  'Electromagnetic Interference',
  'Control Circuit Failure',
  'Failure of Indicating Devices',
  'Loose Connections',
  'Control Power Failure',
  'Abnormal Noise',
  'Excessive Load',
  'Inadequate Grounding',
  'Short Circuit',
  'Insufficient Cooling',
  'Circuit Breaker Failure',
  'Auxiliary System Failure',
  'Insufficient Starting Torque',
  'External Contamination',
  'Shaft Seal Leakage',
  'Rotor Imbalance',
  'Shaft Misalignment',
  'Bearing Wear',
  'Stator Winding Insulation Degradation',
  'Phase Unbalance',
  'Overheating',
  'Overcurrent Protection Failure',
  'Contact Welding',
  'Phase Imbalance',
  'Busbar Failure',
  'Motor Failure',
  'Grounding System Failure',
  'Insulation Failure',
  'Corrosion',
  'Electrical Wiring fault',
  'Actuator Pump Failure',
  'Limit Switch Failure',
  'Actuator Feedback Sensor Failure',
  'Valve Position Feedback Error',
  'Actuator Control Failure',
  'Actuator Hydraulic Fluid Contamination',
  'Actuator Drive Belt Failure',
  'Valve Leakage',
  'Valve Misalignment',
  'Valve Sticking',
  'Actuator Valve Seizure',
  'Valve Corrosion and Erosion',
  'Motor Overheating',
  'Actuator Gear Failure',
  'Actuator Malfunction'
]

export const lubricationFMs = [
  'Insufficient Lubrication',
  'Lubrication System Failure',
  'Lubrication Failure'
]

export const vibrationFMs = [
  'Sensor Drift',
  'Mechanical Damage',
  'Overloading',
  'Bushing Insulation Failure',
  'Pressure Range fault',
  'Excessive Heat',
  'Output Signal Loss',
  'Power Surge',
  'External Contamination',
  'Overpressure Conditions',
  'Blockage or Clogging',
  'Power Supply Failure',
  'Communication fault',
  'Zero Drift',
  'Cable Damage',
  'Sensor Failure',
  'Signal Drift',
  'Display Malfunction',
  'Fluid Temperature Fluctuations',
  'Flow Turbulence',
  'Cooling System Failure',
  'Data Loss',
  'Environmental Conditions',
  'Wiring Connection fault',
  'Power Source fault',
  'Moisture Ingress',
  'Excessive Vibration',
  'Electrical Connection fault',
  'Corrosion and Erosion',
  'Transmitter Communication Failure',
  'Sensor Contamination',
  'Power Surge or Transient',
  'Data Inaccuracy',
  'Sensor Calibration Drift',
  'High Process Pressure',
  'Power Supply fault',
  'Wiring or Connection fault',
  'EMI/RFI Interference',
  'Diaphragm Damage',
  'Vibration and Shock',
  'Water Ingress'
]

export const compressotTL = [
  {
    interval: '30000 h',
    type: 'Condition Monitoring',
    name: 'Mobilize SOLAR representative for assistance if required.',
    status: 'EXISTING'
  },
  {
    interval: '30000 h',
    type: 'Condition Monitoring',
    name: 'Major Overhauling to be carried out',
    status: 'EXISTING'
  },
  {
    interval: '4000 h',
    type: 'Condition Monitoring',
    name: 'Mobilize  Roving Team to perform this job.',
    status: 'EXISTING'
  },
  {
    interval: '4000 h',
    type: 'Condition Monitoring',
    name: 'Air Systems Checks and Maintenance',
    status: 'EXISTING'
  },
  {
    interval: '4000 h',
    type: 'Condition Monitoring',
    name: 'Lube Oil and Servo Oil Systems Checks and Maintenance',
    status: 'EXISTING'
  },
  {
    interval: '4000 h',
    type: 'Condition Monitoring',
    name: 'Gas Fuel System Checks and Maintenance',
    status: 'EXISTING'
  },
  {
    interval: '4000 h',
    type: 'Condition Monitoring',
    name: 'Liquid Fuel System Checks and Maintenance',
    status: 'EXISTING'
  },
  {
    interval: '4000 h',
    type: 'Condition Monitoring',
    name: 'Start System & Auxiliary Motors Checks',
    status: 'EXISTING'
  },
  {
    interval: '4000 h',
    type: 'Condition Monitoring',
    name: 'General Condition Checks and Maintenance',
    status: 'EXISTING'
  },
  {
    interval: '4000 h',
    type: 'Condition Monitoring',
    name: 'Refer to attached 4307-STI-EM221-O-MB-00001, Chapter 5.4',
    status: 'EXISTING'
  },
  {
    interval: '4000 h',
    type: 'Condition Monitoring',
    name: 'General',
    status: 'EXISTING'
  },
  {
    interval: '4000 h',
    type: 'Condition Monitoring',
    name: 'Backup Overspeed Monitor (OSM) Test',
    status: 'EXISTING'
  },
  {
    interval: '4000 h',
    type: 'Condition Monitoring',
    name: 'Battery and Battery Charger Maintenance',
    status: 'EXISTING'
  },
  {
    interval: '4000 h',
    type: 'Condition Monitoring',
    name: 'On-crank water washing',
    status: 'EXISTING'
  },
  {
    interval: '4000 h',
    type: 'Condition Monitoring',
    name: "GTG 'A' - 4000H Maintenance",
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Annual Ground System Checks',
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Annual Postlube System Test (Procedure Part II)',
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Annual Postlube System Test (Procedure Part I)',
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Controller Battery Replacement',
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Annual Ground System and Connections Inspection',
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Annual Postlube System Test (Test conditions)',
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Replace 2nd and 3rd stg air intake filters',
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Boroscope of unit',
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Coupling and foundation bolts Inspection / Alignment Check',
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Check/Clean Suction filter',
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Line Valves Inspection',
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Gland Packing / Mechanical seal check',
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Bearings Condition check',
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Performance testing and overhauling (if necessary)',
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Generator Checks and Maintenance',
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Start System & Auxiliary Motors Maintenance',
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Liquid Fuel System Maintenance',
    status: 'EXISTING'
  },
  {
    interval: '8000 h',
    type: 'Condition Monitoring',
    name: 'Air Systems Maintenance',
    status: 'EXISTING'
  },
  {
    interval: '1 Month',
    type: 'Preventive Maintenance',
    name: 'Monthly Battery and Charger Maintenance (Procedure Part I)',
    status: 'EXISTING'
  },
  {
    interval: '1 Month',
    type: 'Preventive Maintenance',
    name: 'Monthly Battery and Charger Maintenance (Warning/Notes)',
    status: 'EXISTING'
  },
  {
    interval: '1 Month',
    type: 'Preventive Maintenance',
    name: 'General Maintenance',
    status: 'EXISTING'
  },
  {
    interval: '1 Month',
    type: 'Preventive Maintenance',
    name: 'Refer: SOLAR Service Bulletin - 6.5/109B',
    status: 'EXISTING'
  },
  {
    interval: '1 Month',
    type: 'Preventive Maintenance',
    name: 'Monthly Battery and Charger Maintenance',
    status: 'EXISTING'
  },
  {
    interval: '3 Month',
    type: 'Preventive Maintenance',
    name: '3 Monthly Battery and Battery Charger Maintenance',
    status: 'EXISTING'
  },
  {
    interval: '3 Month',
    type: 'Preventive Maintenance',
    name: 'Inspection',
    status: 'EXISTING'
  },
  {
    interval: '1 Month',
    type: 'Preventive Maintenance',
    name: "GTG's Inspection: 1M PM",
    status: 'EXISTING'
  },
  {
    interval: '6 Month',
    type: 'Preventive Maintenance',
    name: '6 Monthly Battery and Battery Charger Maintenance',
    status: 'EXISTING'
  }
]

export const lubricationTL = [
  [
    {
      interval: '12 Month',
      type: 'Preventive Maintenance',
      name: 'Performance testing and overhauling (if necessary)',
      status: 'EXISTING'
    },
    {
      interval: '12 Month',
      type: 'Preventive Maintenance',
      name: 'Bearings Condition check',
      status: 'EXISTING'
    },
    {
      interval: '12 Month',
      type: 'Preventive Maintenance',
      name: 'Coupling and foundation bolts Inspection / Alignment Check',
      status: 'EXISTING'
    },
    {
      interval: '12 Month',
      type: 'Preventive Maintenance',
      name: 'Line Valves Inspection',
      status: 'EXISTING'
    },
    {
      interval: '12 Month',
      type: 'Preventive Maintenance',
      name: 'Check/Clean Suction filter',
      status: 'EXISTING'
    },
    {
      interval: '12 Month',
      type: 'Preventive Maintenance',
      name: 'Gland Packing / Mechanical seal check',
      status: 'EXISTING'
    }
  ]
]

export const motorTL = [
  {
    interval: '3 Month',
    type: 'Preventive Maintenance',
    name: 'LO samples collection and analysis',
    status: 'EXISTING'
  },
  {
    interval: '3 Month',
    type: 'Preventive Maintenance',
    name: 'LO samples collection and analysis',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Performance testing and overhauling (if necessary)',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Bearings Condition check',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Coupling and foundation bolts Inspection / Alignment Check',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Line Valves Inspection',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Check/Clean Suction filter',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Gland Packing / Mechanical seal check',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Performance testing and overhauling (if necessary)',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Bearings Condition check',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Coupling and foundation bolts Inspection / Alignment Check',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Line Valves Inspection',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Check/Clean Suction filter',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Gland Packing / Mechanical seal check',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Performance testing and overhauling (if necessary)',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Bearings Condition check',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Coupling and foundation bolts Inspection / Alignment Check',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Line Valves Inspection',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Check/Clean Suction filter',
    status: 'EXISTING'
  },
  {
    interval: '12 Month',
    type: 'Preventive Maintenance',
    name: 'Gland Packing / Mechanical seal check',
    status: 'EXISTING'
  },
  {
    interval: '60 Month',
    type: 'Preventive Maintenance',
    name: 'Testing/Overhaul/Re-certification of PSV',
    status: 'EXISTING'
  },
  {
    interval: '60 Month',
    type: 'Safety Critical',
    name: 'Testing/Overhaul/Re-certification of PSV',
    status: 'EXISTING'
  }
]

export const compressorRTL = [
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor valve positions and operation',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor valve lift and response',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor seal temperatures and pressure',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor rod and crankshaft temperatures',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor motor temperature and electrical parameters',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor exhaust gas temperature and opacity',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor discharge temperature and alarms',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor discharge pressure and flow',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor discharge gas composition and pressure',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor coupling alignment and vibration',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor cooling water temperature and flow',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor control panel indicators and alarms',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor compressor temperature and alarms',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor belt tension and alignment',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor bearing temperature and vibration',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor air intake filters and separator performance',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Listen for unusual valve noises',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check piston ring conditions and deposits',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check for valve noises and inconsistencies',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check for unusual gas odors and leaks',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check for pressure drops and leaks',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check for overheating signs and ventilation',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check for oil leaks around seals',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check for oil carryover and moisture',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check for leaks in the cooling system',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check for insulation damage and ventilation',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check for burnt components and loose connections',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check for belt cracks and wear',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check cylinder liners for scoring and wear',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check bearing lubrication and noise',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Test cooling water for pH and corrosion',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform valve spring load tests',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform dynamic balancing of crankshaft',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform cylinder pressure and efficiency tests',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform compression and leak tests',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor valve clearance and performance',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor main bearing and connecting rod clearances',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect valve springs and lifters',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect valve spring condition and fatigue',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect piston grooves for carbon deposits',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Check valve plate integrity and sealing',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Replace valve springs and inspect retainers',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Replace valve plates or rings',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform cylinder decarbonization',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Measure and replace piston rings',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect and replace worn piston rods and bearings',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Clean and flush cylinder cooling system',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform valve leak tests and check for fouling',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform thermography and analyze cooling efficiency',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform seal integrity tests and check for wear',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform oil analysis and particle count',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform laser alignment and analyze coupling wear',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform gas analysis and leak detection',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform flow and pressure measurement and analysis',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform electrical testing and motor current analysis',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform electrical testing and inspect control circuits',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform belt tension measurement and visual inspection',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect cooling system components',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Condition  Based',
    name: 'Online Condition monitoring of filter DP',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Preventive Maintenance',
    name: 'PM of torque convertor, clutch & functional test of solenoid valve ',
    status: 'NEW'
  },
  {
    interval: '20 years',
    type: 'Condition  Based',
    name: 'PM of torque convertor & clutch and repalce solenoid valve',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Preventive Maintenance',
    name: 'Perform motor PM',
    status: 'NEW'
  },
  {
    interval: '20 years',
    type: 'Condition  Based',
    name: 'Perform motor overhaul',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Condition  Monitoring',
    name: 'Perform charge pump PM',
    status: 'NEW'
  },
  {
    interval: '20 years',
    type: 'Condition  Based',
    name: 'Perform charge pump overhaul',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Condition  Monitoring',
    name: 'Inspection of inlet guide vane (IGV functional test & inspection',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Condition  Based',
    name: 'Repair / replace inlet guide vane assembly and associated auxillery',
    status: 'NEW'
  },
  {
    interval: 'monthly',
    type: 'Operator Maintenance',
    name: 'Monitoring of delta P across filter',
    status: 'NEW'
  },
  {
    interval: '1 yearly',
    type: 'Condition  Based',
    name: 'Replace inlet air filters',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Preventive Maintenance',
    name: 'Inspection of combustion internals (hot gas path inspection)',
    status: 'NEW'
  },
  {
    interval: '6 monthly',
    type: 'Preventive Maintenance',
    name: 'Filter replacement',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Condition  Monitoring',
    name: 'Perform rotor inspection (Boroscopic inspection)',
    status: 'NEW'
  },
  {
    interval: 'monthly',
    type: 'Condition  Monitoring',
    name: 'Vibration monitoring, trending & analysis',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Preventive Maintenance',
    name: 'Replace rotor',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Condition  Monitoring',
    name: 'Perform blade inspection (Boroscopic inspection)',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Preventive Maintenance',
    name: 'Repair / replacement of compressor blades',
    status: 'NEW'
  },
  {
    interval: 'daily',
    type: 'Operator Maintenance',
    name: 'Operator round and check for any abnormality, vibration or noise',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Preventive Maintenance',
    name: 'Inspect & repalce bearings',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Preventive Maintenance',
    name: 'Inspect & repair if needed',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Preventive Maintenance',
    name: 'Inspect & repair / replace based on inspection (Hot gas path / Major repair inspection)',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Condition  Monitoring',
    name: 'Inspect turbine rotor',
    status: 'NEW'
  },
  {
    interval: '1 year',
    type: 'Condition  Based',
    name: 'Repair / replace rotor ',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Condition  Monitoring',
    name: 'Inspect seals & labyrinths ',
    status: 'NEW'
  },
  {
    interval: '6 monthly',
    type: 'Preventive Maintenance',
    name: 'Repair / repalce (seal gas filter & seperation gas filter) & labyrinth',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Condition  Monitoring',
    name: 'Inspection of couplings ( Generator, load & auxillary GB)',
    status: 'NEW'
  },
  {
    interval: '1 yearly',
    type: 'Condition  Based',
    name: 'Replace couplings (Generator, load & auxillary GB)',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Condition  Based',
    name: 'Carryout lube oil sampling and vibration monitoring ',
    status: 'NEW'
  },
  {
    interval: '2 yearly',
    type: 'Condition  Based',
    name: 'Online condition monitoring of the low speed shaft of the gear',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Preventive Maintenance',
    name: 'Calibration check',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Preventive Maintenance',
    name: 'Overhaul Bleed valve',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Preventive Maintenance',
    name: 'Test overspeed',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Preventive Maintenance',
    name: 'PM and calibration of speed control valve',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Preventive Maintenance',
    name: 'Overhaul speed control valve',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Condition  Monitoring',
    name: 'Calibration & testing of probes, cards and other electronic compe',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Preventive Maintenance',
    name: 'Replace probes, cards, cable and other electronic components',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Condition  Monitoring',
    name: 'Perform lube oil sampling & analysis',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Condition  Monitoring',
    name: 'Carryout vibration checks on the lube ail pump DC motors',
    status: 'NEW'
  },
  {
    interval: '2 yearly',
    type: 'Condition  Monitoring',
    name: 'Carryout IR test on DC motors',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Preventive Maintenance',
    name: 'Perform lube oil system PM',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Preventive Maintenance',
    name: 'Repair / replace lube oil system components / equipment',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Condition  Monitoring',
    name: 'perform hydraulic oil system PM',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Preventive Maintenance',
    name: 'Repair / replace hydraulic oil system components / equipment',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Condition  Monitoring',
    name: 'Perfrom PM of ventilation system',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Condition  Based',
    name: 'Repair / replace air ventilation system components',
    status: 'NEW'
  },
  {
    interval: '6 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform PM of fuel gas system (detailed include all accessories),',
    status: 'NEW'
  },
  {
    interval: '2 yearly',
    type: 'Condition  Monitoring',
    name: 'Remote monitaring of the PCV using MD Pro',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Condition  Based',
    name: 'Repair / replace fuel gas system components',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Condition  Monitoring',
    name: 'Perform inspection of exhaust plenum, exhaust duct, ',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Condition  Monitoring',
    name: 'online monitoring of T5 in smart connect',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Condition  Based',
    name: 'Carry out repair or repaice based on inspection of exhaust plenur',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Condition  Monitoring',
    name: 'perform assurnace tasks for SCE and IPF loops ',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Preventive Maintenance',
    name: 'Test, calibration & certification',
    status: 'NEW'
  },
  {
    interval: '1 yearly',
    type: 'Condition  Based',
    name: 'Repair / replace as needed',
    status: 'NEW'
  },
  {
    interval: 'monthly',
    type: 'Operator Maintenance',
    name: 'Monitor compressor performance',
    status: 'NEW'
  },
  {
    interval: '2 yearly',
    type: 'Condition  Based',
    name: 'Perform water wash',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Preventive Maintenance',
    name: 'Perform PM for wash system',
    status: 'NEW'
  },
  {
    interval: '1 yearly',
    type: 'Condition  Based',
    name: 'Repair / overhaul wash system components (pump,motor & Solenoid)',
    status: 'NEW'
  },
  {
    interval: 'W1',
    type: 'Condition  Monitoring',
    name: 'Monitor lube oil temperature',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Condition  Based',
    name: 'Perform cooler cleaning. Carry out tubes inspection and perform hydrotest water jet during planned outage ',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Condition  Monitoring',
    name: 'Perform PM of cooling and sealing air system system',
    status: 'NEW'
  },
  {
    interval: '1 yearly',
    type: 'Condition  Based',
    name: 'Repair / replace cooling and sealing air system components',
    status: 'NEW'
  },
  {
    interval: '4 yearly',
    type: 'Condition  Monitoring',
    name: 'GB internals inspection',
    status: 'NEW'
  },
  {
    interval: '1 yearly',
    type: 'Condition  Based',
    name: 'GB shaft / wheel assembly replacement',
    status: 'NEW'
  },
  {
    interval: 'monthly',
    type: 'Condition  Monitoring',
    name: 'Vibration monitoring, trending & analysis (to check for contaminated oil, degradation and bearing health)',
    status: 'NEW'
  },
  {
    interval: '2 yearly',
    type: 'Condition  Monitoring',
    name: 'Carryout high speed shaft online vibration monitoring',
    status: 'NEW'
  },
  {
    interval: 'daily',
    type: 'Operator Maintenance',
    name: 'Operator round and check for any abnormality, vibration or nois',
    status: 'NEW'
  },
  {
    interval: '8 yearly',
    type: 'Preventive Maintenance',
    name: 'Inspect & Repalce labyrinth',
    status: 'NEW'
  },
  {
    interval: '1 yearly',
    type: 'Condition  Monitoring',
    name: 'Perform PM of fuel gas treatement system (filters, level control valve, solenoid valve, RV)',
    status: 'NEW'
  },
  {
    interval: '1 yearly',
    type: 'Condition  Based',
    name: 'Repair / replace fuel gas treatement system components',
    status: 'NEW'
  },
  {
    interval: 'monthly',
    type: 'Operator Maintenance',
    name: 'Build fault. finding manuat based on experience/past failure',
    status: 'NEW'
  },
  {
    interval: 'monthly',
    type: 'Operator Maintenance',
    name: 'Cary out minor design on the panel lack of ventilation ',
    status: 'NEW'
  },
  {
    interval: '1 year',
    type: 'Preventive Maintenance',
    name: "Inspect HMI's & Work stations",
    status: 'NEW'
  }
]

export const lubricationRTL = [
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor oil levels and color',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Check oil pressure and temperature',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor bearing temperature and noise',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect lubrication system for leaks and blockages',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Check and replenish lubrication',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform oil analysis and wear debris analysis',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor oil viscosity and contaminants',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect lubrication system components',
    status: 'NEW'
  },
  {
    interval: '3 monthly',
    type: 'Preventive Maintenance',
    name: 'Change lubricant and filters',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor oil quality and perform oil analysis',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect oil cooler and heat exchanger',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Change compressor oil and oil filter',
    status: 'NEW'
  }
]

export const motorRTL = [
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Verify power backup systems and emergency shutdown',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Verify motor controller settings and parameters',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Verify incoming voltage using a multimeter',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Verify grounding and shielding of cables',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Regularly check indicator lights and panel displays',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Regularly check indicator lights and alarms',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Regularly check for visible signs of loose connections',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Regularly check control circuit breaker status',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Perform sound level measurements',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Observe motor performance for signs of strain',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Observe motor for sparks or abnormal operation',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor terminal screw torque and cable connections',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor power supply status and fluctuations',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor motor performance for erratic behavior',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor MCC operation during load fluctuations',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor incoming power supply for fluctuations',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor fan operation and airflow',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor digital displays and communication interfaces',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor communication interfaces and remote controls',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor breaker operation and mechanical sounds',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Monitor auxiliary contactor operation',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Listen for unusual motor sounds during startup',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Listen for unusual motor sounds',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Listen for abnormal motor noise',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Inspect motor surroundings for dust and debris',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Inspect motor area for oil stains',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Inspect and secure motor cabling',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check shaft seals for leaks',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check power source connections and voltage stability',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check motor grounding connections',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check motor controller interface and displays',
    status: 'NEW'
  },
  {
    interval: 'weekly',
    type: 'Operator Maintenance',
    name: 'Check cooling fan operation',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Condition Monitoring',
    name: 'Monitor motor vibration levels',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Condition Monitoring',
    name: 'Monitor motor vibration for signs of misalignment',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Condition Monitoring',
    name: 'Monitor bearing temperature and vibration',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Condition Monitoring',
    name: 'Measure motor current during operation',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Condition Monitoring',
    name: 'Check starting current during motor startup',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Condition Monitoring',
    name: 'Regularly check for tripping anomalies and unusual noises',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Condition Monitoring',
    name: 'Regularly check for temperature rise in MCC components',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Condition Monitoring',
    name: 'Regularly check for alarms and abnormal behavior',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Condition Monitoring',
    name: 'Regularly check breaker alignment and tripping mechanism',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Clean motor exterior and intake vents',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform partial discharge testing',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform ground resistance testing',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor temperature rise during operation',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor motor performance for uneven load',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor motor load and current draw',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor lubricant levels and quality',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor controller response and motor behavior',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor air quality and filter effectiveness',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Measure motor starting current and compare with specifications',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Measure electromagnetic interference levels',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Measure and record motor voltage variations',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Measure and record motor temperature rise',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect ventilation and cooling systems',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect rotor assembly for signs of imbalance',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect mechanical components for signs of wear',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect coupling and mounting for alignment issues',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect connections and terminals for tightness',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect bearings for wear and damage',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform dynamic balancing of the rotor',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Lubricate motor bearings',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Conduct insulation resistance tests',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Check phase currents for balance',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Check motor temperature during operation',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Align motor shaft using laser alignment',
    status: 'NEW'
  }
]

export const instrumentRTL = [
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Verify temperature readings against reference standards',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Report any unusual impacts or physical damage',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Regularly check display for unusual readings',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Record load data and variations',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Record bushing oil levels and visual condition',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Perform zero and span adjustment',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Observe transmitter temperature',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Observe load fluctuations and report unusual patterns',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Observe for signal loss alarms',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Observe for power-related error messages',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Observe for physical contaminants in flow',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Observe for overpressure alarms and alerts',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Observe flow rate fluctuations and notify abnormalities',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Observe display for low battery warnings',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Observe communication status and error messages',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor zero signal stability',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor signal quality and strength',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor sensor readings for consistency',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor pressure range settings',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor output signal for deviations',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor for flickering or fading display',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor fluid temperature variations',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor fluid quality and potential contaminants',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor flow patterns and irregularities',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor fan speeds and temperatures',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor digital display for anomalies',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor differential pressure across the flowmeter',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor data storage and retrieval status',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor communication signal strength',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor bushing temperatures and leaks',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor backup power source',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Monitor ambient temperature and humidity',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Inspect wiring connections and terminals',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Inspect power connections and sources',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Inspect for signs of overheating or thermal stress',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Inspect flowmeter and piping for physical obstructions',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Check water levels, flow rates, and pump operation',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Check for sensor error messages',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Check for moisture buildup or water ingress',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Check for condensation or moisture buildup',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Check for changes in zero readings',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Check display readability and contrast',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Check display for error messages',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Check display and compare with process conditions',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Check data transmission and logging functions',
    status: 'NEW'
  },
  {
    interval: 'Daily',
    type: 'Operator Maintenance',
    name: 'Check communication cables and connectors',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect mounting and attachment for signs of vibration',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Verify grounding and bonding systems',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect wiring connections and cable glands',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor voltage drop and resistance',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect flowmeter housing and components for corrosion',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Verify communication signals and protocols',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect sensor elements for dirt and debris',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect external housing and protection',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect housing, connectors, and enclosures',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Check external communication interfaces',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Check exposed cables and connectors for signs of wear',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Verify surge protection devices and grounding',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Verify flowmeter and transmitter accuracy',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform zero/span adjustments as needed',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform temperature calibration and adjustments',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform system overpressure tests',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform power quality analysis and testing',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform data logging and trend analysis',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform computational fluid dynamics (CFD) analysis',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor pressure levels and fluctuations',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor power quality and transient events',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor measurement accuracy and compare with reference',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor equipment installation and surroundings',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor communication error logs and data transmission',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect pressure relief valves and safety systems',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect insulation and heat protection',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect flow straighteners and piping layout',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Compare flow measurement with reference standards',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Check filter screens and strainers for clogs',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Calibrate flowmeter and transmitter',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Verify stable power supply to the flowmeter',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect power cables and connections',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor voltage and current levels',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Visually inspect wiring connections and terminations',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform continuity tests and resistance measurements',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor for voltage fluctuations and irregularities',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Test communication protocols and data integrity',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Verify proper grounding and electrical supply',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Verify pressure transducer response to overpressure',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Monitor for moisture-related changes in readings',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect sensor housing and connections',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Check for external interference sources',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Verify sensor response to changing pressures',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Verify sealing and integrity of enclosure',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Verify power supply connections and stability',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Verify cooling mechanisms and thermal management',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Test pressure relief devices and safety mechanisms',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Test data backup and recovery procedures',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform zero offset calibration',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform sensor calibration and verification',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform radiated and conducted interference tests',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Perform leak test to ensure diaphragm integrity',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect shielding and grounding of the transmitter',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Inspect diaphragm for wear, tears, or punctures',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Ensure transmitter enclosure is properly sealed',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Check for proper seating and alignment',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Calibrate transmitter for accurate readings',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Calibrate display to ensure accurate readings',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Verify pressure readings against calibrated standards',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Check for loose or corroded connections',
    status: 'NEW'
  },
  {
    interval: '1 monthly',
    type: 'Preventive Maintenance',
    name: 'Verify connection integrity and perform continuity test',
    status: 'NEW'
  }
]
