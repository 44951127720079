import axios from 'axios'
import { Button, Col, Row, Table } from 'reactstrap'
import { Form } from 'react-final-form'
import { useContext, useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'

import CustomLabel from '../components/InputFields/CustomLabel'
import DropDownFetch from '../components/InputFields/DropDownFetch'
import LabeledDateTimeInput from '../components/InputFields/DateTime'
import LabeledRadioButtons from '../components/InputFields/LabeledRadioButton'
import LabeledTextInput from '../components/InputFields/LabeledTextInput'
import LabledParagraphInput from '../components/InputFields/LabledParagraph'
import Loader from '../components/Loader'
import ModalTitle from '../components/ModalTitle'
import MultiSelect from '../components/InputFields/MultiSelect'
import Spacer from '../components/Spacer'
import SubmitModalWithForm from '../components/Modals/SubmitModalWithForm'
import { createCMMSWO, tagsAPI } from '../components/serverurl'
import { getAccessToken } from '../apiRequests'
import { getOptions } from './firs'
import { loginRequest } from '../authConfig'
import { UserContext } from '../App'

export default function CorrectiveMeasureForm({ initalData }: any) {
  const { userData }: any = useContext(UserContext)
  const { accounts, instance } = useMsal()

  const [submit, setSubmit] = useState(false)
  const [equipment, setEquipment] = useState(null)
  const [eDescription, setEdescription] = useState(null)
  const [submitValues, setSubmitValues]: any = useState()
  const [attachment, setAttachment]: any = useState()
  const [result, setResult]: any = useState()

  if (!userData.id) {
    return <Loader />
  }

  const onSubmit = async () => {
    const token = await getAccessToken(loginRequest, accounts, instance)

    const formData: any = new FormData()
    if (attachment) {
      for (let i = 0; i < attachment.length; i++) {
        formData.append('attachments', attachment[i])
      }
    }

    formData.append('title', submitValues?.title)
    formData.append('equipment', equipment || '')
    formData.append('equipmentDescription', eDescription || '')
    formData.append('equipmentOthers', submitValues?.equipmentOthers || '')
    formData.append(
      'eDescriptionOthers',
      submitValues?.eDescriptionOthers || ''
    )
    formData.append('reason', submitValues?.reason || '')
    formData.append('systemCriticality', submitValues?.systemCriticality || '')
    formData.append('vendorRequired', submitValues?.need3rdPartyVendor || '')
    formData.append('needShutdown', submitValues?.needShutdown || '')
    formData.append('location', submitValues?.location?.value || '')
    formData.append(
      'maintenanceCause',
      submitValues?.maintenanceCause?.value || ''
    )
    formData.append(
      'maintenanceClass',
      submitValues?.maintenanceClass?.value || ''
    )
    formData.append('priority', submitValues?.priority?.value || '')
    formData.append('respDiscipline', submitValues?.respDiscipline?.value || '')
    formData.append('woCategoty', submitValues?.woCategoty?.value || '')
    formData.append('createdBy', userData?.id || '')
    formData.append('estimatedDuration', submitValues?.estimatedDuration || '')
    formData.append('planningDate', submitValues?.planningDate || '')
    formData.append('dueFinishDate', submitValues?.dueFinishDate || '')
    formData.append('scheduledStart', submitValues?.scheduledStart || '')
    formData.append('scheduledFinish', submitValues?.scheduledFinish || '')

    try {
      const response = await axios.post(createCMMSWO, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      })
      setResult(response)
    } catch (e) {
      console.warn(e)
    }

    return result?.data?.status
  }

  const renderLabel = (label: string) => (
    <Col xs="4">
      <CustomLabel label={label} />
    </Col>
  )

  const renderDate = (label: string, name: string) => (
    <Row style={{ display: 'flex', alignItems: 'center' }}>
      {renderLabel(label)}
      <LabeledDateTimeInput name={name} occupy={8} />
    </Row>
  )

  const renderTextInput = (label: string, name: string) => (
    <Row style={{ display: 'flex', alignItems: 'center' }}>
      {renderLabel(label)}
      <LabeledTextInput name={name} occupy={8} />
    </Row>
  )

  const renderDropdown = (label: string, name: string, options: any) => (
    <Row style={{ display: 'flex', alignItems: 'center' }}>
      {renderLabel(label)}
      <MultiSelect name={name} isMulti={false} options={options} occupy={8} />
    </Row>
  )

  const RenderSection = ({ heading, children }: any) => (
    <Col
      xs="4"
      style={{
        border: '1px solid #000',
        backgroundColor: '',
        padding: 5,
        marginRight: 1
      }}
    >
      <div style={{ backgroundColor: 'orange' }}>
        <p style={{ fontWeight: 700, margin: 0, textAlign: 'center' }}>
          {heading}
        </p>
      </div>
      <Spacer height={5} />
      {children}
    </Col>
  )

  const handleFileUpload = (event: any) => {
    setAttachment(event.target.files)
  }

  const resetFormState = () => {
    setEdescription(null)
    setEquipment(null)
    setAttachment(null)
    setSubmitValues(null)
    setSubmit(false)
  }

  return (
    <Row style={{ margin: 0 }}>
      <ModalTitle title="CORRECTIVE MAINTENANCE FORM" height={40} />
      <Form
        onSubmit={() => {}}
        render={({ handleSubmit, form, values }) => (
          <form
            onSubmit={handleSubmit}
            action="/upload"
            method="post"
            encType="multipart/form-data"
          >
            <Row>
              <LabeledTextInput name="title" label="Title" occupy={12} />
              <Col>
                <CustomLabel label="Equipment / Tag Number" />
                <DropDownFetch
                  name="equipment"
                  value={equipment}
                  setValue={setEquipment}
                  url={`${tagsAPI}`}
                  isMulti={false}
                  needSelect={false}
                  setDescription={setEdescription}
                />
              </Col>
              <LabeledTextInput
                name="equipmentDescription"
                label="Equipment Description"
                value={eDescription}
                occupy={8}
                isDisabled
              />
              <LabeledTextInput
                name="equipmentOthers"
                label="Equipment Others"
                occupy={6}
              />
              <LabeledTextInput
                label="Equipment Others Description"
                name="eDescriptionOthers"
                occupy={6}
              />
              <LabledParagraphInput
                name="reason"
                label="Corrective Maintenance Description"
                occupy={12}
              />
              <MultiSelect
                name="location"
                label="Location"
                options={getOptions([
                  'Mechanical Workshop',
                  'Fire Station',
                  'Sub Station',
                  'Control Room',
                  'Process Area',
                  'Admin Ware House'
                ])}
                occupy={3}
                isMulti={false}
              />
              <LabeledTextInput
                name="systemCriticality"
                label="System Criticality"
                occupy={3}
              />
              {/* <LabeledTextInput name="pAndId" label="P & ID" occupy={4} /> */}
              <LabeledRadioButtons
                name="need3rdPartyVendor"
                label="Need 3rd Party Vendor"
                list={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' }
                ]}
                occupy={3}
              />
              <LabeledRadioButtons
                name="needShutdown"
                label="Need Shutdown"
                list={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' }
                ]}
                occupy={3}
              />
              <Spacer height={10} />
              <div style={{ display: 'flex' }}>
                <RenderSection heading="WORK CLASSIFICATION">
                  {renderDropdown('WOCategory', 'woCategoty', [
                    {
                      label: 'Breakdown Maintenance',
                      value: 'Breakdown Maintenance'
                    },
                    {
                      label: 'Corrective Maintenance',
                      value: 'Corrective Maintenance'
                    }
                  ])}
                  {renderDropdown(
                    'Main. Class',
                    'maintenanceClass',
                    [
                      'Non Intrusive',
                      'Item Intrusive',
                      'Package Intrusive',
                      'Partial Prod SD',
                      'Total Prod SD'
                    ].map((o) => ({ value: o, label: o }))
                  )}
                  {renderDropdown(
                    'Main. Cause',
                    'maintenanceCause',
                    [
                      'Wear',
                      'Aging',
                      'Vibration',
                      'Control Failure',
                      'Breakage',
                      'Corrosion',
                      'Leakage External',
                      'Lubrication'
                    ].map((o) => ({ value: o, label: o }))
                  )}
                </RenderSection>
                <RenderSection heading="PLANNING">
                  {renderDropdown('Priority', 'priority', [
                    { label: 'P1', value: 'P1' },
                    { label: 'P2', value: 'P2' },
                    { label: 'P3', value: 'P3' }
                  ])}
                  {renderDropdown(
                    'Resp. Discipline',
                    'respDiscipline',
                    [
                      'Mechanical',
                      'Electrical',
                      'Instrument',
                      'Production',
                      'HSE',
                      'Admin Ware House'
                    ].map((o) => ({ value: o, label: o }))
                  )}
                  {renderTextInput('Est. Duration(Hrs)', 'estimatedDuration')}
                </RenderSection>
                <RenderSection heading="PLANNING DATES">
                  {renderDate('Planning Date', 'planningDate')}
                  {renderDate('Due Finish Date', 'dueFinishDate')}
                  {renderDate('Sch. Start', 'scheduledStart')}
                  {renderDate('Sch. Finish', 'scheduledFinish')}
                </RenderSection>
              </div>
            </Row>
            <Row>
              <CustomLabel label="Upload Attachment" />
              <input
                type="file"
                name="attachment"
                accept="application/pdf, image/*"
                onChange={handleFileUpload}
                multiple
              />
            </Row>
            <Spacer height={10} />
            <Row style={{ margin: 0 }}>
              <Button
                type="button"
                color="success"
                onClick={(e) => {
                  setSubmit(true)
                  setSubmitValues(values)
                }}
              >
                Submit
              </Button>
            </Row>
            {submit && (
              <SubmitModalWithForm
                isOpen={submit}
                onClose={(val: any) => {
                  setSubmit(false)
                  val && form.reset()
                  val && resetFormState()
                }}
                onSubmit={async (e: any) => {
                  e.preventDefault()
                  await onSubmit()
                  return result?.data?.status
                }}
                size="xs"
                header="Create Corrective Maintenance"
                sucessView={
                  <div>
                    <Table bordered style={{ borderColor: '#000' }}>
                      <tbody>
                        <tr>
                          <td>CM Number</td>
                          <td>
                            {result?.data?.data?.permit?.woNumber || 'Number'}
                          </td>
                        </tr>
                        <tr>
                          <td>CM Title</td>
                          <td>
                            {result?.data?.data?.permit?.permitTitle || 'Title'}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                }
              />
            )}
          </form>
        )}
      />
    </Row>
  )
}
