import moment from 'moment'
import ReactPaginate from 'react-paginate'
import Select from 'react-select'
import { Col, Row, Table } from 'reactstrap'
import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import toNormalCase from '../../components/lib/toNormalCase'
import { auditEndpoint } from '../../components/serverurl'
import { getOptions } from '../firs'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { makeAuthenticatedRequest } from '../../apiRequests'

export default function AuditList({ auditStatus }: any) {
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const [pageNumber, setPageNumber]: any = useState(0)
  const [status, setStatus]: any = useState()
  const [department, setDepartment]: any = useState()

  const fetchAudits = async () => {
    const response = await makeAuthenticatedRequest(
      `${auditEndpoint}?page=${pageNumber}&status=${auditStatus?.toLowerCase()}&department=${department?.toUpperCase()}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response.data
  }

  const { data, refetch }: any = useQuery('fetchAudits', fetchAudits)

  useEffect(() => {
    refetch()
  }, [status, department])

  return (
    <ProtectedLayout onBack="/eptw/audits">
      <Row style={{ margin: 10 }}>
        {/* <Row style={{ marginBottom: 10 }}>
          <Col>
            <p>Status</p>
            <Select
              options={[
                { value: undefined, label: 'Select' },
                ...getOptions(['Rejected', 'Approved', 'Created'])
              ]}
              onChange={(e: any) => setStatus(e?.value)}
            />
          </Col>
          <Col>
            <p>Department</p>
            <Select
              options={[
                { value: undefined, label: 'Select' },
                ...getOptions([
                  'MECHANICAL',
                  'Production',
                  'Instrument',
                  'Admin Ware house',
                  'Electrical',
                  'HSE'
                ])
              ]}
              onChange={(e: any) => setDepartment(e?.value)}
            />
          </Col>
        </Row> */}
        <Table bordered striped style={{ borderColor: '#000' }}>
          <thead>
            <tr style={{ backgroundColor: 'orange' }}>
              <th>S.No</th>
              <th>Audit Number</th>
              <th>Permit Number</th>
              <th>Originator</th>
              <th>Applicant Department</th>
              <th>Created At</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 &&
              data?.map((a: any, index: number) => (
                <tr key={a?._id}>
                  <td>{incrementby1(index)}</td>
                  <td
                    style={{
                      color: 'blue',
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }}
                    onClick={() => navigate(`/eptw/audits/viewAll/${a._id}`)}
                  >
                    {a?.auditNumber}
                  </td>
                  <td>{a?.permitNumber}</td>
                  <td>{a?.permitOriginator}</td>
                  <td>{a?.applicantDepartment}</td>
                  <td>{moment(a?.createdAt).format('DD-MM-YYYY hh:mm A')}</td>
                  <td>{toNormalCase(a?.status)}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Row>
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(data?.totalDocuments / 20)}
        marginPagesDisplayed={10}
        pageRangeDisplayed={2}
        onPageChange={async (values) => {
          await setPageNumber(values.selected + 1)
          refetch()
        }}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </ProtectedLayout>
  )
}
