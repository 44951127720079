import { Row } from 'reactstrap'
import {
  Bar,
  BarChart,
  CartesianGrid,
  DefaultTooltipContent,
  Legend,
  Rectangle,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import ProtectedLayout from '../Layout/ProtectedLayout'
import { useContext } from 'react'
import { UserContext } from '../../App'
import { useNavigate } from 'react-router-dom'

const maintenanceItems = [
  'WO Overdue (MECH) - 14 days (P1,P2,P3) - 33',
  'WO Overdue (MECH)- PY1 -14 days - 43',
  'WO Overdue (MECH)- PY2- 14 days	- 23',
  'WO Overdue (MECH)- PY3- 14 days	- 34'
]

const procurementItems = [
  'Trip Urgent Requestion ( Mech)',
  'Urgent Requestion (Mech)',
  'Approved Work Request (Mech',
  'Approved PO (Mech)	Approved RFQ (Mech)'
]

const shortcuts = [
  'Approve Work Orders',
  'Close Work Orders',
  'Approve Purchase Request',
  'View Stock Register'
]

const TabTwo = () => {
  const data = [
    {
      name: 'JAN -On Time',
      SCPM: 6,
      PM: 15,
      CM: 6
    },
    {
      name: 'JAN Backlog',
      SCPM: 0,
      PM: 1,
      CM: 1
    },
    {
      name: 'JAN Cum Baclog',
      SCPM: 0,
      PM: 1,
      CM: 1
    },
    {
      name: 'Feb -On Time',
      SCPM: 4,
      PM: 14,
      CM: 7
    },
    {
      name: 'Feb Backlog',
      SCPM: 1,
      PM: 1,
      CM: 2
    },
    {
      name: 'Feb Cum Baclog',
      SCPM: 1,
      PM: 2,
      CM: 3
    },
    {
      name: 'Mar -On Time',
      SCPM: 7,
      PM: 18,
      CM: 8
    },
    {
      name: 'Mar Backlog',
      SCPM: 2,
      PM: 2,
      CM: 1
    },
    {
      name: 'Mar Cum Baclog',
      SCPM: 3,
      PM: 4,
      CM: 4
    },
    {
      name: 'Apr -On Time',
      SCPM: 4,
      PM: 15,
      CM: 8
    },
    {
      name: 'Apr Backlog',
      SCPM: 0,
      PM: 0,
      CM: 0
    },
    {
      name: 'Apr Cum Baclog',
      SCPM: 3,
      PM: 2,
      CM: 4
    },
    {
      name: 'May -On Time',
      SCPM: 6,
      PM: 16,
      CM: 3
    },
    {
      name: 'May Backlog',
      SCPM: 0,
      PM: 0,
      CM: 0
    },
    {
      name: 'May Cum Baclog',
      SCPM: 3,
      PM: 0,
      CM: 4
    },
    {
      name: 'Jun -On Time',
      SCPM: 6,
      PM: 15,
      CM: 4
    },
    {
      name: 'Jun Backlog',
      SCPM: 0,
      PM: 1,
      CM: 0
    },
    {
      name: 'Jun Cum Baclog',
      SCPM: 3,
      PM: 1,
      CM: 2
    }
  ]
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <BarChart
        width={1000}
        height={500}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="SCPM" fill="#8884d8" />
        <Bar dataKey="PM" fill="#82ca9d" />
        <Bar dataKey="CM" fill="#28CEE7" />
      </BarChart>
    </div>
  )
}

export default function PersonalDashboard() {
  const { userData } = useContext(UserContext)
  const navigate = useNavigate()

  return (
    <ProtectedLayout onBack="/" title="My Dashboard">
      <Row style={{ margin: 0, overflowX: 'hidden' }}>
        <div
          style={{
            height: '87vh',
            width: '100%',
            backgroundColor: 'rgba(211,211,211,0.3)'
          }}
        >
          <div style={{ margin: '3vh' }}>
            <div
              style={{
                height: 'auto',
                padding: '3vh',
                backgroundColor: '#FFF',
                borderRadius: 10,
                boxShadow: '13px 14px 20px 2px rgba(0,0,0,0.1)'
              }}
            >
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      alt="profile pic"
                      src={require('./../../images/sample pic.webp')}
                      style={{
                        borderRadius: '50%',
                        height: '8vh',
                        width: '8vh',
                        objectFit: 'cover'
                      }}
                    />
                    <div style={{ marginLeft: 10, marginTop: 10 }}>
                      <h3>
                        {userData?.givenName} {userData?.surname}
                      </h3>
                      <h6>
                        {userData?.jobTitle} , {userData?.department}
                      </h6>
                    </div>
                  </div>
                  <div style={{ display: 'flex', textAlign: 'center' }}>
                    <div style={{ paddingRight: '2vh' }}>
                      <h2>
                        <b>76</b>
                      </h2>
                      <span>WO Approved</span>
                    </div>
                    <div
                      style={{
                        borderLeft: '2px solid #d3d3d3',
                        paddingLeft: '2vh'
                      }}
                    >
                      <h2>
                        <b>89</b>
                      </h2>
                      <span>WO Completed</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '3vh', display: 'flex' }}>
              <div style={{ width: '30%' }}>
                <div
                  style={{
                    padding: '3vh',
                    backgroundColor: '#FFF',
                    boxShadow: '13px 14px 20px 2px rgba(0,0,0,0.1)',
                    borderRadius: 10
                  }}
                >
                  <h2 style={{ textAlign: 'center' }}>Preferences</h2>
                  <hr />
                  <div>
                    <h5>Maintenance</h5>
                    {maintenanceItems?.map((i: any) => (
                      <p
                        style={{ marginBottom: 0, cursor: 'pointer' }}
                        key={i}
                        onClick={() => navigate('/myDashboard/overdue')}
                      >
                        {i}
                      </p>
                    ))}
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <h5>Procurement</h5>
                    {procurementItems?.map((i: any) => (
                      <p style={{ marginBottom: 0 }} key={i}>
                        {i}
                      </p>
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    marginTop: '3vh',
                    padding: '3vh',
                    backgroundColor: '#FFF',
                    boxShadow: '13px 14px 20px 2px rgba(0,0,0,0.1)',
                    borderRadius: 10,
                    height: '20vh'
                  }}
                >
                  <h2 style={{ textAlign: 'center' }}>Favorites</h2>
                  <hr />
                  {shortcuts?.map((i: any) => (
                    <p
                      style={{
                        marginBottom: 0,
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline'
                      }}
                      key={i}
                    >
                      {i}
                    </p>
                  ))}
                </div>
              </div>
              <div
                style={{
                  width: '70%',
                  backgroundColor: '#FFF',
                  boxShadow: '13px 14px 20px 2px rgba(0,0,0,0.1)',
                  marginLeft: '3vh',
                  padding: '3vh',
                  borderRadius: 10
                }}
              >
                <h2 style={{ textAlign: 'center' }}>Analytics</h2>
                <hr />
                <TabTwo />
              </div>
            </div>
          </div>
        </div>
      </Row>
    </ProtectedLayout>
  )
}
