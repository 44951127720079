import moment from 'moment'
import { Button, ModalFooter, Row } from 'reactstrap'
import { Form } from 'react-final-form'
import { useContext, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'

import LabeledCheckBoxes from '../../components/InputFields/LabeledCheckBoxes'
import LabeledRadioButtons from '../../components/InputFields/LabeledRadioButton'
import LabeledTextInput from '../../components/InputFields/LabeledTextInput'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { getOptions } from '../firs'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { mocApi } from '../../components/serverurl'
import { UserContext } from '../../App'

export default function MocForm() {
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const initData: any = localStorage.getItem('moc')
  const { number, tag, firs } = (initData && JSON.parse(initData)) || {}
  const navigate = useNavigate()

  const [submit, setSubmit]: any = useState(false)

  return (
    <ProtectedLayout onBack="/firs/moc" title="MOC">
      <Row style={{ margin: 15 }}>
        <Form
          onSubmit={(values: any) => {}}
          initialValues={{
            originator: userData?.givenName,
            incidentReportNumber: number,
            equipment: tag
          }}
          render={({ values, form, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <LabeledRadioButtons
                  defaultValue="Yes"
                  name="mocType"
                  label="MOC Type"
                  list={getOptions([
                    'Modification request',
                    'Deviation Request',
                    'Modification Notice'
                  ])}
                  occupy={4}
                />
                <LabeledRadioButtons
                  defaultValue="Yes"
                  name="safetyCriticalElement"
                  label="Safety Critical Element"
                  list={getOptions(['Yes', 'No'])}
                  occupy={4}
                />
                <LabeledTextInput
                  name="originator"
                  label="Originator"
                  occupy={4}
                />
                <LabeledCheckBoxes
                  occupy={4}
                  name="benefits"
                  label="Benefits"
                  list={getOptions([
                    'Improve Safety',
                    'Improve Operations',
                    'Cost Savings'
                  ])}
                />
                <LabeledCheckBoxes
                  occupy={4}
                  name="typeOfChange"
                  label="Type of Change"
                  list={getOptions([
                    'Technical',
                    'Trip Set Point',
                    'Communication & IT',
                    "Client's Request",
                    'System (including CMMS) Defferal, Degradation & Temporary Repair'
                  ])}
                />

                <LabeledTextInput
                  name="equipment"
                  label="Equipment Tag Number"
                  occupy={4}
                />
                <LabeledTextInput
                  name="pmNumber"
                  label="PM Number"
                  occupy={4}
                />
                <LabeledTextInput
                  name="prNumber"
                  label="Purchase Requisition Number"
                  occupy={4}
                />
                <LabeledTextInput
                  name="incidentReportNumber"
                  label="Incident Report Number"
                  occupy={4}
                />
              </Row>
              <ModalFooter>
                <Button color="warning" onClick={() => setSubmit(true)}>
                  Submit
                </Button>
              </ModalFooter>
              <SubmitModalWithForm
                isOpen={submit}
                onClose={(val: any) => {
                  val && navigate('/firs/moc')
                  setSubmit(!submit)
                }}
                onSubmit={async (e: any) => {
                  const body: any = {
                    ...values,
                    status: 'mocStage2',
                    createdOn: moment(),
                    department: userData?.department,
                    firs: firs
                  }
                  e.preventDefault()
                  const res = await makeAuthenticatedRequest(
                    mocApi,
                    'POST',
                    body,
                    accounts,
                    instance
                  )
                  return res?.status
                }}
                size={'md'}
                header={'Create MOC'}
              />
            </form>
          )}
        />
      </Row>
    </ProtectedLayout>
  )
}
