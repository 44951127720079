import { Col, Row } from "reactstrap";
import RcmLayout from "../../components/rcm/RcmLayout";
import FPSO from "../../SVGs/Fpso";

export default function FpsoBoundary() {
  return (
    <RcmLayout title={'FPSO Boundary'} onBack=''>
      <FPSO />
    </RcmLayout >
  )
}