import React, { useState } from 'react'
import arrayMutators from 'final-form-arrays'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { Button, Col, Input, Modal, ModalBody, Row } from 'reactstrap'
import { SubHeader } from '../../pages/VerifyCM'
import LabeledDateTimeInput from '../InputFields/DateTime'
import LabeledRadioButtons from '../InputFields/LabeledRadioButton'
import LabeledTextInput from '../InputFields/LabeledTextInput'
import LabledParagraphInput from '../InputFields/LabledParagraph'
import SectionHeader from '../Layout/SectionHeader'
import Spacer from '../Spacer'
import { MdDelete } from 'react-icons/md'
import CustomLabel from '../InputFields/CustomLabel'
import SubmitModal from '../SubmitModal'
import { createCMCloseOut } from '../serverurl'
import { useNavigate } from 'react-router-dom'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { useMsal } from '@azure/msal-react'

const sample = {
  serviceMode: 'In-house',
  plantShutdown: 'Yes',
  unitShutdown: 'No',
  workOrderCompleted: 'No',
  trialRunConducted: 'No',
  equipmentHandoverTo: 'Mechanical',
  historyCardUpdated: 'No',
  departmentWorked: 'Electrical',
  dateCompleted: '2023-09-01',
  coreCrewWorking: '23',
  vendorWorking: '435',
  budgetCode: '23',
  history: '234',
  stockUsed: [
    {
      currency: 'INR',
      name: '2154'
    },
    {
      currency: 'INR',
      name: 'asf'
    }
  ],
  resourceUsed: [
    {
      name: 'HSE',
      hours: '34'
    },
    {
      name: 'Admin',
      hours: '1'
    }
  ],
  vendorUsed: [
    {
      name: 'qwer',
      hours: '5'
    },
    {
      name: 'aer',
      hours: '6'
    }
  ],
  checkList: 'as',
  correctiveMeasure: 's',
  specialToolsUsed: 'asdf',
  trialRunObervation: 'asdf',
  equipmentWitnessBy: 'adsf'
}

export const renderField = ({
  input,
  label,
  type,
  meta: { touched, error }
}: any) => (
  <div>
    <div>
      <Input
        {...input}
        type={input?.type}
        placeholder={label}
        style={{ fontWeight: 600, backgroundColor: '#DCDCDC', height: 40 }}
      />
      {touched && error && <span style={{ color: 'red' }}>{error}</span>}
    </div>
  </div>
)

export const units = [
  'UOM',
  'MTR',
  'ROLL',
  'NO',
  'SET',
  'KG',
  'CAN',
  'PKT',
  'COIL',
  'NA',
  'LOT',
  'CATRIDGE',
  'KIT',
  'KGS',
  '1 SET',
  'PR',
  'PAIR',
  'DRUM',
  'LTR',
  'PAIL',
  'EACH',
  'LENGTH',
  'SQFT',
  'LTS',
  'PCS',
  'MT'
]

export const renderRisks = ({ fields, meta: { error } }: any) => (
  <>
    {fields.map((stock: any, index: any) => (
      <>
        <tr key={index}>
          <td>
            <Field
              name={`${stock}.name`}
              type="text"
              component={renderField}
              label={`Stock Item Code #${index + 1}`}
            />
          </td>
          <td>
            <Field
              name={`${stock}.price`}
              type="text"
              component={renderField}
              label={`Stock Name #${index + 1}`}
            />
          </td>
          <td>
            <Field
              name={`${stock}.quantity`}
              type="text"
              component={renderField}
              label={`Stock Quantity #${index + 1}`}
            />
          </td>
          <td>
            <Field
              name={`${stock}.unit`}
              component="select"
              style={{
                width: '-webkit-fill-available',
                height: 38,
                borderRadius: 5
              }}
            >
              <option value="" disabled selected>
                Select Unit
              </option>
              {units?.map((u: any) => (
                <option key={u} value={u}>
                  {u}
                </option>
              ))}
              {/* <option value="Mechanical">Mechanical</option>
              <option value="Electrical">Electrical</option>
              <option value="Instrument">Instrument</option>
              <option value="Production">Production</option>
              <option value="HSE">HSE</option>
              <option value="Admin Ware House">Admin Ware House</option> */}
            </Field>
          </td>
          {/* <td>
            <LabeledRadioButtons
              name={`${stock}.currency`}
              list={['USD', 'INR']?.map((field: string) => ({
                value: field,
                label: field
              }))}
              occupy={12}
            />
          </td> */}
          <td>
            <MdDelete
              onClick={() => fields.remove(index)}
              style={{ color: 'red' }}
            />
          </td>
        </tr>
      </>
    ))}
    <tr>
      <td colSpan={5}>
        <Button color="warning" onClick={() => fields.push()}>
          Add Stock
        </Button>
      </td>
    </tr>
    {/* <>
      <Button color="link" onClick={() => fields.push()}>
        Add Stock
      </Button>
    </> */}
  </>
)

export const renderResources = ({ fields, meta: { error } }: any) => (
  <>
    {fields.map((resource: any, index: any) => (
      <tr key={index}>
        <>
          <td>
            <LabeledRadioButtons
              name={`${resource}.name`}
              list={[
                'Mechanical',
                'Electrical',
                'Instrument',
                'Production',
                'Admin Ware House',
                'HSE'
              ]?.map((field: string) => ({ value: field, label: field }))}
              occupy={12}
            />
          </td>
          <td>
            <Field
              name={`${resource}.hours`}
              type="text"
              component={renderField}
              label={`Hours #${index + 1}`}
            />
          </td>
          <td>
            <MdDelete
              onClick={() => fields.remove(index)}
              style={{ color: 'red' }}
            />
          </td>
        </>
      </tr>
    ))}
    <tr>
      <td colSpan={3}>
        <Button color="warning" onClick={() => fields.push()}>
          Add Resource
        </Button>
      </td>
    </tr>
  </>
)

export const renderVendor = ({ fields, meta: { error } }: any) => (
  <>
    {fields.map((vendor: any, index: any) => (
      <tr key={index}>
        <>
          <td>
            <Field
              name={`${vendor}.name`}
              type="text"
              component={renderField}
              label={`Vendor Name #${index + 1}`}
            />
          </td>
          <td>
            <Field
              name={`${vendor}.hours`}
              type="text"
              component={renderField}
              label={`Hours #${index + 1}`}
            />
          </td>
          <td>
            <MdDelete
              onClick={() => fields.remove(index)}
              style={{ color: 'red' }}
            />
          </td>
        </>
      </tr>
    ))}
    <tr>
      <td colSpan={3}>
        <Button color="warning" onClick={() => fields.push()}>
          Add Vendor
        </Button>
      </td>
    </tr>
  </>
)

const INITIAL_STATE = {
  title: 'Are you sure you want to submit?',
  icon: null,
  details: null
}

export default function CloseCMPermit({
  isOpen,
  toggle,
  permitId,
  type,
  navigateTo = '/cmms/dashboard?view=inProgress'
}: any) {
  const [submit, setSubmit] = useState(false)
  const [message, setMessage]: any = useState(INITIAL_STATE)

  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const onSubmit = (values: any) => {
    try {
      makeAuthenticatedRequest(
        `${createCMCloseOut}?type=${type}`,
        'POST',
        values,
        accounts,
        instance
      ).then((response: any) => {
        setMessage({
          title:
            response?.data?.status === 'success'
              ? 'Verified Successfully'
              : 'Unable to Verify',
          icon: response?.data?.status,
          details: null
          // response?.data?.data?.permit
        })
        navigate(navigateTo)
      })
    } catch (error) {
      console.warn(error)
      return { satus: false }
    }
    setMessage({
      title: 'Are you sure you want to submit?',
      icon: 'success',
      details: null
    })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} fullscreen>
      <ModalBody>
        <SectionHeader title="CORRECTIVE MAINTENANCE CLOSE OUT FORM" />
        <Spacer height={20} />
        <Form
          onSubmit={(values: any) => onSubmit({ ...values, permit: permitId })}
          initialValues={sample}
          mutators={{
            ...arrayMutators
          }}
          render={({ handleSubmit, form, hasValidationErrors, values }) => (
            <form>
              <SubHeader header="GENERAL INFORMATION" />
              <Row>
                <LabeledDateTimeInput
                  name="dateCompleted"
                  label="Date Completed"
                  occupy={4}
                />
                <LabeledTextInput
                  name="totalDuration"
                  label="Total Duration (Hrs)"
                  occupy={4}
                />
                <LabeledRadioButtons
                  name="serviceMode"
                  label="Service Mode"
                  list={['Vendor', 'In-house']?.map((field: string) => ({
                    value: field,
                    label: field
                  }))}
                  occupy={4}
                />
                <LabeledTextInput
                  name="coreCrewWorking"
                  label="Core Crew Working Hours (Hrs)"
                  occupy={4}
                />
                <LabeledTextInput
                  name="vendorWorking"
                  label="Vendor Working Hours (Hrs)"
                  occupy={4}
                />
                <LabeledRadioButtons
                  name="plantShutdown"
                  label="Plant Shut down during this task"
                  list={['Yes', 'No']?.map((field: string) => ({
                    value: field,
                    label: field
                  }))}
                  occupy={4}
                />
                <LabeledRadioButtons
                  name="unitShutdown"
                  label="Unit Shut down during this task"
                  list={['Yes', 'No']?.map((field: string) => ({
                    value: field,
                    label: field
                  }))}
                  occupy={4}
                />
                <LabeledTextInput
                  name="budgetCode"
                  label="Budget code"
                  occupy={4}
                />
                <LabeledRadioButtons
                  name="workOrderCompleted"
                  label="Work Order Completed"
                  list={['Yes', 'No']?.map((field: string) => ({
                    value: field,
                    label: field
                  }))}
                  occupy={4}
                />
              </Row>
              <Spacer height={10} />
              <SubHeader header="REPORTING OPTIONS" />
              <Spacer height={10} />
              <LabledParagraphInput
                name="history"
                label="History"
                occupy={12}
              />
              <LabledParagraphInput
                name="lessonLearnt"
                label="Lesson Learnt"
                occupy={12}
              />
              <Row>
                <Row>
                  <Col xs="9">
                    <CustomLabel label="Stock Used" />
                  </Col>
                  <Col xs="1" />
                </Row>
                <FieldArray name="stockUsed" component={renderRisks} />
                <Row>
                  <Col xs="9">
                    <CustomLabel label="Resources Used" />
                  </Col>
                  <Col xs="2">
                    <CustomLabel label="Hours" />
                  </Col>
                  <Col xs="1" />
                </Row>
                <FieldArray name="resourceUsed" component={renderResources} />
                <Row>
                  <Col xs="9">
                    <CustomLabel label="Vendors Used" />
                  </Col>
                  <Col xs="2">
                    <CustomLabel label="Hours" />
                  </Col>
                  <Col xs="1" />
                </Row>
                <Spacer height={10} />
                <FieldArray name="vendorUsed" component={renderVendor} />
                <LabeledTextInput
                  name="checkList"
                  label="Check List for the Particular job"
                  occupy={4}
                />
                <LabledParagraphInput
                  name="correctiveMeasure"
                  label="Corrective Measures"
                  occupy={4}
                />
                <LabledParagraphInput
                  name="specialToolsUsed"
                  label="Special Tools Used"
                  occupy={4}
                />
                <LabeledRadioButtons
                  name="trialRunConducted"
                  label="Trial Run Conducted"
                  list={['Yes', 'No']?.map((field: string) => ({
                    value: field,
                    label: field
                  }))}
                  occupy={4}
                />
                <LabledParagraphInput
                  name="trialRunObervation"
                  label="Trial Run Observation"
                  occupy={4}
                />
                <LabeledRadioButtons
                  name="equipmentHandoverTo"
                  label="Equipment Handover to"
                  list={[
                    'Mechanical',
                    'Electrical',
                    'Instrument',
                    'Production',
                    'Admin',
                    'HSE'
                  ]?.map((field: string) => ({ value: field, label: field }))}
                  occupy={4}
                />
                <LabeledRadioButtons
                  name="historyCardUpdated"
                  label="History Card Updated"
                  list={['Yes', 'No']?.map((field: string) => ({
                    value: field,
                    label: field
                  }))}
                  occupy={4}
                />
                <LabeledTextInput
                  name="equipmentWitnessBy"
                  label="Equipment Witnessed By"
                  occupy={4}
                />
                <LabeledRadioButtons
                  name="departmentWorked"
                  label="Department Worked"
                  list={[
                    'Mechanical',
                    'Electrical',
                    'Instrument',
                    'Production',
                    'Admin',
                    'HSE'
                  ]?.map((field: string) => ({ value: field, label: field }))}
                  occupy={4}
                />
              </Row>
              <Row>
                <Col xs="1">
                  <th>Evidence :</th>
                </Col>
                <Col xs="11">
                  <Input type="file" accept="application/pdf" />
                </Col>
              </Row>
              <Spacer height={30} />
              <Row>
                <Col xs="12">
                  <Button
                    style={{ width: 'inherit' }}
                    color="warning"
                    onClick={toggle}
                  >
                    Cancel
                  </Button>
                  <Spacer height={10} />
                  <Button
                    style={{ width: 'inherit' }}
                    color="success"
                    onClick={() => setSubmit(true)}
                  >
                    Close work order
                  </Button>
                </Col>
              </Row>
              <SubmitModal
                isOpen={submit}
                toggle={() => setSubmit(!submit)}
                onSubmit={form.submit}
                // refetch={() => fetchPermits()}
                submitModalContent={message}
                afterClose={() => setMessage(INITIAL_STATE)}
                size="lg"
              />
            </form>
          )}
        />
      </ModalBody>
      {/* <ModalFooter>
        <Button color="warning" onClick={() => toggle()}>Close</Button>
      </ModalFooter> */}
    </Modal>
  )
}
