import { Modal, ModalBody } from 'reactstrap'
import {
  CloseFIR,
  RejectFIR,
  Stage2Form,
  Stage3Form,
  Stage4Form,
  Stage5Form
} from '../../pages/firs'
import ModalTitle from '../ModalTitle'

export default function FIRApprovalModal({
  isOpen,
  toggle,
  status,
  reject
}: any) {
  const renderForm = () => {
    switch (status) {
      case 'stage2':
        return <Stage2Form toggle={toggle} />

      case 'stage3':
        return <Stage3Form />

      case 'stage4':
        return <Stage4Form />

      case 'stage5':
        return <Stage5Form />

      case 'stage9':
        return <CloseFIR />

      default:
        break
    }
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle} fullscreen>
      <ModalBody style={{ maxHeight: '90vh', overflow: 'scroll' }}>
        <ModalTitle
          title={`FIRST INCIDENT REPORTING SYSYTEM - ${status?.toUpperCase()}`}
        />
        {reject ? <RejectFIR /> : renderForm()}
      </ModalBody>
    </Modal>
  )
}
