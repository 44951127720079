import { Row } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useState } from 'react'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import ViewPermits from '../../components/Tables/ViewPermits'
import { epermit } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'

export default function UpdateRiskAssessment() {
  const [pageNumber, setPageNumber] = useState(1)
  const { accounts, instance } = useMsal()

  const fetchAdminPermissions = async () => {
    const response = await makeAuthenticatedRequest(
      `${epermit}?page=${pageNumber}&status=risks`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, refetch, isRefetching }: any = useQuery(
    'eptwVerify',
    fetchAdminPermissions
  )
  return (
    <ProtectedLayout onBack="/eptw/dashboard">
      <Row style={{ margin: 15 }}>
        <ViewPermits
          data={data}
          isRefetching={isRefetching}
          isLoading={isLoading}
          refetch={refetch}
          setPageNumber={setPageNumber}
          navigateTo="/eptw/newPermit/updateRiskAssessments"
          pageNumber={pageNumber}
        />
      </Row>
    </ProtectedLayout>
  )
}
