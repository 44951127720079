import { Button, Input, Row, Table } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useContext, useState } from 'react'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { cioEndpoint } from '../../components/serverurl'
import React from 'react'
import { useAdminPermissions } from '../eptw/EditRisks'
import ViewPermit from '../eptw/ViewPermit'
import { RadioButtonGroup, yesNoOptions } from '../eptw/VerifyPermit'
import { PermissionsContext } from '../../App'
import { ColoredSubHeader } from '../../components/previews/ViewExcavationPermit'
import moment from 'moment'

export default function ApproveCIO() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const { permissions } = useContext(PermissionsContext)

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const [submitModal, setSubmitModal]: any = useState(false)

  const [formData, setFormData]: any = useState({
    isSafe: 'No',
    freeFromHydrocarbons: 'No',
    scopeDiscussed: 'No'
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const submitValues: any = {
      consent: {
        ...formData
      },
      gasReadings,
      pbuReadings,

      permitId: data?.permit?._id,
      consentId: data?.permit?.consent?._id
    }

    const response = await makeAuthenticatedRequest(
      `${cioEndpoint}/approve`,
      'PATCH',
      submitValues,
      accounts,
      instance
    )

    return response.status
  }

  const continous: any = [
    '7AM',
    '8AM',
    '9AM',
    '10AM',
    '11AM',
    '12PM',
    '1PM',
    '2PM',
    '3PM',
    '4PM',
    '5PM'
  ]

  const intermittent = ['9AM', '12PM', '3PM', '5PM']

  const interval =
    (data?.permit?.gasMeasurements === 'Continuous' && continous) ||
    (data?.permit?.gasMeasurements === 'Intermittent' && intermittent) ||
    null

  const gases: any = ['H2S', 'O2', 'CO', 'LEL']

  const getIsolationsList = (type: any) =>
    data?.permit?.isolations?.isolations?.filter(
      (i: any) => i?.pressureBuildup === 'Yes' && i?.readings === type
    )

  const continousList = getIsolationsList('Continous')
  const intermittentList = getIsolationsList('Intermittent')
  const oneTimeList = getIsolationsList('One Time')

  const [gasReadings, setGasreadings]: any = useState({})
  const [pbuReadings, setPbuReadings]: any = useState({})

  const handleIsolationInputChange = (e: any) => {
    const { name, value } = e.target
    const [isolationPoint, time] = name.split('_')

    setPbuReadings((prevData: any) => {
      const updatedData = {
        ...prevData,
        [isolationPoint]: {
          ...(prevData[isolationPoint] || {}),
          [time]: value
        }
      }

      return updatedData
    })
  }

  const handleInputGasChange = (e: any) => {
    const { name, value } = e.target
    const [gas, time] = name.split('_')

    setGasreadings((prevData: any) => {
      const updatedData = {
        ...prevData,
        [time]: {
          ...(prevData[time] || {}),
          [gas]: value
        }
      }

      return updatedData
    })
  }

  const renderTable = (list: any, headers: any) => {
    return (
      <Table bordered striped style={{ borderColor: '#000' }}>
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            <th>Isolation Point</th>
            {headers?.map((header: any) => <th key={header}>{header}</th>)}
          </tr>
        </thead>
        <tbody>
          {list?.map((item: any) => (
            <tr key={item._id}>
              <th>
                <p>{item.isolationPoint}</p>
              </th>
              {headers?.map((header: any) => (
                <td key={header}>
                  <Input
                    type="text"
                    name={`${item?.isolationPoint}_${header}`}
                    onChange={handleIsolationInputChange}
                    value={
                      pbuReadings[item?.isolationPoint] &&
                      pbuReadings[item?.isolationPoint][header]
                        ? pbuReadings[item?.isolationPoint][header]
                        : ''
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  const form = (
    <>
      <div>
        <RadioButtonGroup
          question="Understood the complete task of this permit and communicated to IM, PIC and AO ?"
          name="isSafe"
          options={yesNoOptions}
          formData={formData}
          onChange={handleInputChange}
          defaultValue="No"
        />
        <RadioButtonGroup
          question="Is the manpower allotted is sufficient to complete this Task ?"
          name="freeFromHydrocarbons"
          options={yesNoOptions}
          formData={formData}
          onChange={handleInputChange}
          defaultValue="No"
        />
        <RadioButtonGroup
          question="Is the correct spare taken from the stores if applicable ?"
          name="scopeDiscussed"
          options={yesNoOptions}
          formData={formData}
          onChange={handleInputChange}
          defaultValue="No"
        />
        <RadioButtonGroup
          question="Is all the documents attached to this Permit sufficient to complete this task ?"
          name="scopeDiscussed"
          options={yesNoOptions}
          formData={formData}
          onChange={handleInputChange}
          defaultValue="No"
        />
      </div>
      {
        <div>
          <ColoredSubHeader text="Gas Readings" />
          <Table bordered style={{ borderColor: '#000', marginTop: 10 }}>
            <thead>
              <tr style={{ backgroundColor: 'orange' }}>
                <th>Gas</th>
                {[`${moment().format('h A')}`]?.map((g: any) => (
                  <th key={g}>{g}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {gases?.map((g: any) => (
                <tr key={g}>
                  <td>{g}</td>
                  {[[`${moment().format('h A')}`]]?.map((c: any) => (
                    <td key={c}>
                      <div style={{ padding: 1 }}>
                        <Input
                          type="text"
                          name={`${g}_${c}`}
                          onChange={handleInputGasChange}
                          value={
                            gasReadings[c] && gasReadings[c][g]
                              ? gasReadings[c][g]
                              : ''
                          }
                        />
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      }
      {/* <div>
        {continousList?.length > 0 && renderTable(continousList, continous)}
        {intermittentList?.length > 0 &&
          renderTable(intermittentList, intermittent)}
        {oneTimeList?.length > 0 && renderTable(oneTimeList, ['9AM'])}
      </div> */}
    </>
  )

  return (
    <ProtectedLayout onBack="/eptw/cio/approve">
      <ViewPermit data={data} isLoading={isLoading} />
      {data?.permit?.status === 'Issued CIO' && permissions?.canApproveCIO && (
        <>
          <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Button
              color="warning"
              style={{ marginTop: 10 }}
              onClick={() => setSubmitModal(true)}
            >
              Approve
            </Button>
          </Row>
          {submitModal && (
            <SubmitModalWithForm
              isOpen={submitModal}
              onClose={(val: any) => {
                setSubmitModal(false)
                val && navigate('/eptw/cio/approve')
              }}
              onSubmit={handleSubmit}
              form={form}
              size="xl"
            />
          )}
        </>
      )}
    </ProtectedLayout>
  )
}
