import moment from 'moment'
import ReactPaginate from 'react-paginate'
import { Row, Table } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useState } from 'react'
import { makeAuthenticatedRequest } from '../apiRequests'
import { informationCardEndpoint } from '../components/serverurl'
import AshokLeylandLayout from './AshokLeylandLayout'
import { incrementby1 } from '../components/Forms/JSAForm'
import { INFORMATIONCARD_TYPES } from '../components/previews/PreviewInformationCard'

export default function AlSheCardList({ status }: any) {
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const [pageNumber, setPageNumber]: any = useState(0)

  const fetchCards = async () => {
    const response = await makeAuthenticatedRequest(
      `${informationCardEndpoint}?page=${pageNumber}&status=${status}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response.data
  }

  const { data, refetch, loading }: any = useQuery('fetchCards', fetchCards)

  return (
    <AshokLeylandLayout onBack="/ashokleyland/firs/tshe" title="TSHE Card List">
      <Row style={{ margin: 15 }}>
        <Table style={{ borderColor: '#000' }}>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Card Number</th>
              <th>Equipment</th>
              <th>Type</th>
              <th>Department</th>
              <th>Created At</th>
              <th>Originator</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              data?.length > 0 &&
              data?.map((c: any, index: any) => (
                <tr key={c?._id}>
                  <td>{incrementby1(index)}</td>
                  <td
                    style={{
                      color: 'blue',
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }}
                    onClick={() => navigate(`${c?._id}`)}
                  >
                    {c?.cardNumber}
                  </td>
                  <td>{c?.equipment || c?.others}</td>
                  <td>{INFORMATIONCARD_TYPES[c?.type]}</td>
                  <td>{c?.icDepartment || c?.department}</td>
                  <td>{moment(c?.createdAt).format('DD-MM-YYYY HH:MM A')}</td>
                  <td>{c?.applicantName}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(data?.totalDocuments / 20)}
          marginPagesDisplayed={10}
          pageRangeDisplayed={2}
          onPageChange={async (values) => {
            await setPageNumber(values.selected + 1)
            refetch()
          }}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </Row>
    </AshokLeylandLayout>
  )
}
