import arrayMutators from 'final-form-arrays'
import moment from 'moment'
import React, { useContext, useRef, useState } from 'react'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import CustomLabel from '../../components/InputFields/CustomLabel'
import LabeledDateTimeInput from '../../components/InputFields/DateTime'
import LabeledRadioButtons from '../../components/InputFields/LabeledRadioButton'
import LabeledTextInput from '../../components/InputFields/LabeledTextInput'
import LabledParagraphInput from '../../components/InputFields/LabledParagraph'
import ModalTitle from '../../components/ModalTitle'
import SectionHeader from '../../components/Layout/SectionHeader'
import toNormalCase from '../../components/lib/toNormalCase'
import WorkOrderForm, {
  ApproveWorkOrder,
  CancelWorkOrder,
  renderOperations,
  UpdateWOStatusWithForm
} from './WorkOrderForm'
import { Box, renderData } from './PmTree'
import { capitalizeFirstLetter } from '../../components/lib/capitalizeFirstLetter'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { ViewHistory } from '../ViewCM'
import { stock, workOrderAPI } from '../../components/serverurl'
import { workOrderStatuses } from '../../components/constants/woStatuses'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { Field, Form } from 'react-final-form'
import { FormSection } from './WorkRequestForm'
import { renderField } from '../../components/Modals/CloseCMPermit'
import { MdDelete } from 'react-icons/md'
import { FieldArray } from 'react-final-form-arrays'
import { UserContext } from '../../App'
import { jobcodes } from '../../components/constants/jobcodes'
import SeverityRiskMatrix from '../../components/Modals/SeverityRiskMatrix'
import ControlRiskMatrix from '../../components/Modals/ControlRiskMatrix'
import {
  getRisk,
  getRiskColor,
  riskLevel
} from '../../components/Forms/QRAForm'
import Spacer from '../../components/Spacer'
import ReactToPrint from 'react-to-print'

const renderControls = ({ fields, meta: { error } }: any) => (
  <>
    {fields.map((control: any, index: any) => (
      <React.Fragment key={index}>
        <Row style={{ marginBottom: 3 }}>
          <Col xs="11">
            <Field
              name={control}
              type="text"
              component={renderField}
              label={`Control #${index + 1}`}
            />
          </Col>
          <Col xs="1">
            <MdDelete
              onClick={() => fields.remove(index)}
              style={{ color: 'red' }}
            />
          </Col>
        </Row>
      </React.Fragment>
    ))}
    <>
      <Button color="link" onClick={() => fields.push()}>
        Add Control
      </Button>
    </>
  </>
)

const renderHazards = ({
  values,
  fields,
  meta: { error, submitFailed }
}: any) => (
  <div
    style={{
      // minHeight: '45vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}
  >
    <Table bordered style={{ border: '1px solid #000' }}>
      <thead
        style={{ backgroundColor: 'orange', fontSize: 13, fontWeight: 900 }}
      >
        <tr>
          <th colSpan={5} />
          <th colSpan={2} style={{ textAlign: 'center' }}>
            Risk Evaluation
          </th>
          <th colSpan={2} />
          <th colSpan={2} style={{ textAlign: 'center' }}>
            After Protective
          </th>
          <th colSpan={3} />
        </tr>
        <tr>
          <th>Task </th>
          <th>Hazard</th>
          <th>Consquence / Effect</th>
          <th>Activity</th>
          <th>Condition</th>
          <th>Severity/ Probability</th>
          <th>Control/ People</th>
          <th>Initial Risk</th>
          <th style={{ textAlign: 'center' }}>Existing Control Measures</th>
          <th>Severity/ Probability</th>
          <th>Control/ People</th>
          <th>Residual Risk</th>
          <th>Risk Category</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {fields.map((hazard: any, index: any) => (
          <React.Fragment key={index}>
            <tr>
              <td>
                <Field
                  name={`${hazard}.task`}
                  type="textarea"
                  component={renderField}
                  label="Task"
                />
              </td>
              <td>
                <Field
                  name={`${hazard}.name`}
                  type="textarea"
                  component={renderField}
                  label="Hazard"
                />
              </td>
              <td>
                <Field
                  name={`${hazard}.effect`}
                  type="textarea"
                  component={renderField}
                  label="Effect"
                />
              </td>
              <td>
                <Field name={`${hazard}.routine`} component="select">
                  <option />
                  <option value="Routine">Routine</option>
                  <option value="Non Routine">Non Routine</option>
                </Field>
              </td>
              <td>
                <Field name={`${hazard}.activity`} component="select">
                  <option />
                  <option value="Normal">Normal</option>
                  <option value="Abnormal">Abnormal</option>
                  <option value="Emergency">Emergency</option>
                </Field>
              </td>
              <td>
                <SeverityRiskMatrix
                  name={`${hazard}.initalSeverityProb`}
                  values={values?.hazards?.[index]?.initalSeverityProb}
                />
              </td>
              <td>
                <ControlRiskMatrix
                  name={`${hazard}.initalControlPeople`}
                  values={values?.hazards?.[index]?.initalControlPeople}
                />
              </td>
              <td
                style={{
                  width: '1%',
                  backgroundColor: getRiskColor(
                    getRisk(
                      values?.hazards?.[index]?.initalSeverityProb,
                      values?.hazards?.[index]?.initalControlPeople
                    )
                  ),
                  textAlign: 'center',
                  color: 'white',
                  fontWeight: 700
                }}
              >
                {getRisk(
                  values?.hazards?.[index]?.initalSeverityProb,
                  values?.hazards?.[index]?.initalControlPeople
                )}
              </td>
              <td>
                <Row>
                  <FieldArray
                    name={`${hazard}.controls`}
                    component={renderControls}
                  />
                </Row>
              </td>
              <td>
                <SeverityRiskMatrix
                  name={`${hazard}.residualSeverityProb`}
                  values={values?.hazards?.[index]?.residualSeverityProb}
                />
              </td>
              <td>
                <ControlRiskMatrix
                  name={`${hazard}.residualControlPeople`}
                  values={values?.hazards?.[index]?.residualControlPeople}
                />
              </td>
              <td
                style={{
                  width: '1%',
                  backgroundColor: getRiskColor(
                    getRisk(
                      values?.hazards?.[index]?.residualSeverityProb,
                      values?.hazards?.[index]?.residualControlPeople
                    )
                  ),
                  textAlign: 'center',
                  color: 'white',
                  fontWeight: 700
                }}
              >
                {getRisk(
                  values?.hazards?.[index]?.residualSeverityProb,
                  values?.hazards?.[index]?.residualControlPeople
                )}
              </td>
              <td>
                {riskLevel(
                  getRisk(
                    values?.hazards?.[index]?.residualSeverityProb,
                    values?.hazards?.[index]?.residualControlPeople
                  )
                )}
              </td>
              <td style={{ color: 'red' }}>
                <MdDelete onClick={() => fields.remove(index)} />
              </td>
            </tr>
          </React.Fragment>
        ))}
        <tr>
          <td style={{ borderRight: 0 }}>
            <Button
              type="button"
              style={{ width: '100%' }}
              onClick={() => fields.push({})}
              color="warning"
            >
              Add Hazard
            </Button>
          </td>
        </tr>
        <>
          {submitFailed && error && (
            <span style={{ color: 'red' }}>{error._error}</span>
          )}
        </>
      </tbody>
    </Table>
  </div>
)

const renderMaterialFields = ({
  fields,
  meta: { error, submitFailed }
}: any) => (
  <>
    {fields.map((activity: any, index: any) => (
      <React.Fragment key={index}>
        <tr>
          <td style={{ width: '30%' }}>
            <Field
              name={`${activity}.Spare Part Number`}
              type="text"
              defaultValue={incrementby1(index)}
              component={renderField}
            />
          </td>
          <td style={{ width: '30%' }}>
            <Field
              name={`${activity}.Spare Part Description`}
              type="text"
              component={renderField}
            />
          </td>
          <td>
            <Field
              name={`${activity}.spareType`}
              type="text"
              component={renderField}
            />
          </td>
          <td style={{ width: 10 }}>
            <Field
              name={`${activity}.uom`}
              type="text"
              component={renderField}
            />
          </td>
          <td style={{ width: 5 }}>
            <Field
              name={`${activity}.onHandQuantity`}
              type="text"
              component={renderField}
            />
          </td>
          <td style={{ width: 5 }}>
            <Field
              name={`${activity}.quantityRequested`}
              type="text"
              component={renderField}
            />
          </td>
          <td style={{ width: 5 }}>
            <Field
              name={`${activity}.quantityReturned`}
              type="text"
              component={renderField}
            />
          </td>
          <td style={{ color: 'red' }}>
            <MdDelete onClick={() => fields.remove(index)} />
          </td>
        </tr>
      </React.Fragment>
    ))}
    <tr>
      <td colSpan={5} style={{ border: 0 }}>
        <Button
          type="button"
          style={{ marginTop: 20 }}
          onClick={() => fields.push({})}
          color="warning"
        >
          Add Stock
        </Button>
      </td>
    </tr>
  </>
)

export const renderMaterials = () => (
  <div>
    <Table>
      <thead>
        <tr>
          <th>Part Number</th>
          <th>Part Description</th>
          <th>Spare Type </th>
          <th>UOM</th>
          <th>Quantity on Hand</th>
          <th>Quantity Issued</th>
          <th>Quantity Returned</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <FieldArray name="materialRequest" component={renderMaterialFields} />
      </tbody>
    </Table>
  </div>
)

const CloseWorkOrder = ({
  isOpen,
  toggle,
  workOrder,
  refetch
}: {
  isOpen: any
  toggle: any
  workOrder: any
  refetch: any
}) => {
  const [showSuccess, setShowSuccess] = useState(false)
  const [showFailure, setShowFailure] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [size, setSize]: any = useState(true)

  const { userData } = useContext(UserContext)
  const { accounts, instance } = useMsal()

  const basicDetails: any = {
    Number: workOrder?.number,
    Description: workOrder?.description,
    Status: workOrder?.woStatus,
    'Created By': workOrder?.reportedBy
  }

  const navigate = useNavigate()

  const onSubmit = async (formValues: any) => {
    setIsSubmitting(true)

    const body: any = {
      operations: formValues?.operations,
      materialRequest: formValues?.materialRequest,
      lastestUpdatedBy: userData?.id,
      id: workOrder?._id,
      woStatus: 'closed',
      closeOutReport: {
        dateDone: formValues?.dateDone,
        totalDuration: formValues?.totalDuration,
        downTime: formValues?.downTime,
        miscExpense: formValues?.miscExpense,
        budgetCode: 'TS-0045',
        woCompleted: formValues?.woCompleted,
        history: formValues?.history,
        lessonLearnt: formValues?.lessonLearnt,
        failureClause: formValues?.failureClause,
        failureDescription: formValues?.failureDescription,
        closedBy: userData?.givenName,
        closedDepartment: userData?.department,
        closedAt: moment()
      }
    }

    try {
      const res: any = await makeAuthenticatedRequest(
        `${workOrderAPI}/${workOrder?._id}`,
        'PATCH',
        body,
        accounts,
        instance
      )

      const isSuccess = res?.status === 'success'
      if (isSuccess) {
        setSize('sm')
        setShowSuccess(true)
        await refetch()
      } else {
        setShowFailure(true)
      }

      return res?.status
    } catch (error) {
      setShowFailure(true)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} fullscreen={size}>
      <ModalTitle title="Close Work Order" />
      <ModalBody>
        <>
          <Box heading="Work Order Details">
            {Object.keys(basicDetails)?.map((o: any) =>
              renderData(o, basicDetails[o])
            )}
          </Box>
          {showSuccess ? (
            <div className="success-message">
              <p>Form submitted successfully!</p>
              <ModalFooter>
                <Button
                  type="button"
                  color="warning"
                  onClick={async () => {
                    toggle(showSuccess)
                    setSize(true)

                    setShowSuccess(false)
                    setShowFailure(false)
                    setIsSubmitting(false)

                    // showSuccess &&
                    //   navigate(
                    //     '/workManagement/correctiveMaintenance/inProgress'
                    //   )
                  }}
                >
                  Close
                </Button>
              </ModalFooter>
            </div>
          ) : showFailure ? (
            <div className="error-message">
              Form submission failed. Please try again.
              <ModalFooter>
                <Button
                  type="button"
                  color="warning"
                  onClick={async () => {
                    toggle(showSuccess)
                    setShowSuccess(false)
                    setShowFailure(false)
                    setIsSubmitting(false)
                    setSize(true)
                    // showSuccess &&
                    //   navigate(
                    //     '/workManagement/correctiveMaintenance/inProgress'
                    //   )
                  }}
                >
                  Close
                </Button>
              </ModalFooter>
            </div>
          ) : (
            <div>
              <Form
                onSubmit={(values: any) => {}}
                initialValues={{
                  ...workOrder,
                  operations: workOrder?.operations
                }}
                mutators={{
                  ...arrayMutators
                }}
                render={({ values, form, hasValidationErrors }) => (
                  <form>
                    <FormSection title="General Information">
                      <Row>
                        <LabeledDateTimeInput
                          name="dateDone"
                          label="Date Done"
                          occupy={3}
                        />
                        <LabeledTextInput
                          name="totalDuration"
                          label="Total Duration"
                          occupy={3}
                        />
                        <LabeledTextInput
                          name="downTime"
                          label="Down Time"
                          occupy={3}
                        />
                        <LabeledTextInput
                          name="miscExpense"
                          label="Misc Expense"
                          occupy={3}
                        />
                        <LabeledTextInput
                          name="budgetCode"
                          label="Budget Code"
                          value="TS-0045"
                          disabled
                          occupy={3}
                        />
                        <LabeledRadioButtons
                          name="woCompleted"
                          defaultValue="Yes"
                          label="Work Order Completed"
                          list={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          occupy={3}
                        />
                      </Row>
                    </FormSection>
                    <FormSection title="Reporting">
                      <Row>
                        <LabledParagraphInput
                          name="history"
                          label="History"
                          occupy={6}
                        />
                        <LabledParagraphInput
                          name="lessonLearnt"
                          label="Lesson Learnt"
                          occupy={6}
                        />
                        <Col xs="3">
                          <CustomLabel label="Failure Clause" />
                          <Field
                            name={'failureClause'}
                            component="select"
                            placeholder="s"
                            style={{
                              width: '-webkit-fill-available',
                              height: 38,
                              borderRadius: 5,
                              backgroundColor: '#DCDCDC'
                            }}
                          >
                            <option value="" disabled selected>
                              Select Failure Clause
                            </option>
                            <option value="PM-Routine">PM-Routine</option>
                            <option value="MS-New">MS-New</option>
                            <option value="MA-Wear/Aging">MA-Wear/Aging</option>
                            <option value="ME-Vibration">ME-Vibration</option>
                            <option value="IN-Control Failure">
                              IN-Control Failure
                            </option>
                            <option value="MA-Breakage">MA-Breakage</option>
                            <option value="MA-Corrosion">MA-Corrosion</option>
                            <option value="ME-LEAKAGE EXTERNAL">
                              ME-LEAKAGE EXTERNAL
                            </option>
                            <option value="ME-LUBRICATION">
                              ME-LUBRICATION
                            </option>
                            <option value="CERT">CERT</option>
                            <option value="SERVICE">SERVICE</option>
                          </Field>
                        </Col>
                        <LabledParagraphInput
                          name="failureDescription"
                          label="Failure / Root Cause Description"
                          occupy={9}
                        />
                      </Row>
                    </FormSection>
                    <FormSection title="Resources Used">
                      {renderOperations()}
                    </FormSection>
                    <FormSection title="Stock Used">
                      {renderMaterials()}
                    </FormSection>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle}>
                        Back
                      </Button>
                      <Button
                        color="warning"
                        disabled={isSubmitting}
                        onClick={() => onSubmit(values)}
                      >
                        Submit
                      </Button>
                    </ModalFooter>
                  </form>
                )}
              />
            </div>
          )}
        </>
      </ModalBody>
    </Modal>
  )
}

const CreatedWorkOrdersActions = ({ workOrder }: { workOrder: any }) => {
  const [cancel, setCancel] = useState(false)
  const [approve, setApprove] = useState(false)
  return (
    <div>
      <CancelWorkOrder
        header="Cancel Work Order"
        isOpen={cancel}
        toggle={() => setCancel(!cancel)}
        redirect="/workManagement/correctiveMaintenance/workOrders"
      />
      <ApproveWorkOrder
        isOpen={approve}
        formValues={workOrder}
        toggle={() => setApprove(!approve)}
        updateStatusTo="todo"
        spares={workOrder?.materialRequest}
        workOrder={workOrder}
        header="Approve Work Order"
      />
      <ModalFooter>
        <Button color="danger" onClick={() => setCancel(true)}>
          Cancel
        </Button>
        <Button onClick={() => setApprove(true)} color="warning">
          Approve
        </Button>
      </ModalFooter>
    </div>
  )
}

const TodoActions = ({
  workOrder,
  spares,
  refetch
}: {
  workOrder: any
  spares: any
  refetch: any
}) => {
  const [status, setStatus]: any = useState()
  const [approve, setApprove] = useState(false)
  const [values, setValues]: any = useState()
  const [close, setClose]: any = useState()
  const [hazardModal, setHazardModal] = useState(false)
  const [hazards, setHazards]: any = useState()

  const navigate = useNavigate()

  const form: any = {
    inProgress: <> Are you Sure you want to Submit?</>,
    waitingForMaterial: (
      <Row>
        <Col xs="12">
          <CustomLabel label="PR Number" />
          <Input
            type="text"
            onChange={(e: any) =>
              setValues({ ...values, mrPrNumber: e?.target?.value })
            }
          />
        </Col>
        <Col xs="12">
          <CustomLabel label="MR Comments" />
          <Input
            type="textarea"
            onChange={(e: any) =>
              setValues({ ...values, mrComments: e?.target?.value })
            }
          />
        </Col>
      </Row>
    ),
    waitingFor3rdParty: (
      <Row>
        <Col xs="12">
          <CustomLabel label="PR Number" />
          <Input
            type="text"
            onChange={(e: any) =>
              setValues({ ...values, thirdPartyPrNumber: e?.target?.value })
            }
          />
        </Col>
        <Col xs="12">
          <CustomLabel label="Reason for 3rd Party" />
          <Input
            type="textarea"
            onChange={(e: any) =>
              setValues({ ...values, reasonFor3rdParty: e?.target?.value })
            }
          />
        </Col>
      </Row>
    ),
    waitingForShutdown: (
      <Row>
        <Col xs="12">
          <CustomLabel label="Reason For Shutdown" />
          <Input
            type="text"
            onChange={(e: any) =>
              setValues({ ...values, reasonForShutdown: e?.target?.value })
            }
          />
        </Col>
      </Row>
    ),
    postpone: (
      <Row>
        <Col xs="12">
          <Form
            onSubmit={(hazards: any) =>
              setValues({ ...values, hazards: hazards?.hazards?.hazards })
            }
            mutators={{
              ...arrayMutators
            }}
            render={({ handleSubmit, form, hasValidationErrors, values }) => (
              <form onSubmit={handleSubmit}>
                <CustomLabel label="Reason For Postpone" />
                <Input
                  type="text"
                  onChange={(e: any) =>
                    setValues({ ...values, postponeReason: e?.target?.value })
                  }
                />
                <CustomLabel label="Date to Postpone" />
                <Input
                  type="date"
                  onChange={(e: any) =>
                    setValues({ ...values, dateToPostPone: e?.target?.value })
                  }
                />
                <Spacer height={20} />
                <Button color="warning" onClick={() => setHazardModal(true)}>
                  Add Risk Assessment
                </Button>
                <Modal
                  isOpen={hazardModal}
                  toggle={() => setHazardModal(!hazardModal)}
                  fullscreen
                >
                  <ModalTitle title="Add Hazards" />
                  <ModalBody>
                    <FieldArray
                      name="hazards"
                      component={renderHazards}
                      values={values}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="warning"
                      onClick={() => {
                        form.submit()
                        setHazardModal(!hazardModal)
                      }}
                    >
                      Save & Close
                    </Button>
                  </ModalFooter>
                </Modal>
              </form>
            )}
          />
        </Col>
      </Row>
    )
  }

  const succssView: any = {
    inProgress: (
      <>
        <Row>
          <p onClick={() => navigate('/createPermit')}>
            Click Here to ,
            <b style={{ color: 'blue', textDecoration: 'underline' }}>
              Create New EPTW ?
            </b>
          </p>
        </Row>
      </>
    )
  }

  return (
    <div>
      <ModalFooter>
        {workOrder?.woStatus !== 'inProgress' && (
          <Button
            color="warning"
            onClick={() => {
              setApprove(true)
              setStatus('inProgress')
            }}
          >
            In Progress
          </Button>
        )}
        {workOrder?.woStatus !== 'postponed' && (
          <Button
            color="warning"
            onClick={() => {
              setApprove(true)
              setStatus('postpone')
            }}
          >
            Postpone
          </Button>
        )}
        {workOrder?.woStatus !== 'waitingForMaterial' && (
          <Button
            color="warning"
            onClick={() => {
              setApprove(true)
              setStatus('waitingForMaterial')
            }}
          >
            Waiting For Material
          </Button>
        )}
        {workOrder?.woStatus !== 'waitingFor3rdParty' && (
          <Button
            color="warning"
            onClick={() => {
              setApprove(true)
              setStatus('waitingFor3rdParty')
            }}
          >
            Waiting For 3rd Party
          </Button>
        )}
        {workOrder?.woStatus !== 'waitingForShutdown' && (
          <Button
            color="warning"
            onClick={() => {
              setApprove(true)
              setStatus('waitingForShutdown')
            }}
          >
            Waiting For Shutdown
          </Button>
        )}
        {workOrder?.woStatus === 'inProgress' && (
          <Button
            color="warning"
            onClick={() => {
              setClose(true)
            }}
          >
            Close
          </Button>
        )}
      </ModalFooter>
      <UpdateWOStatusWithForm
        isOpen={approve}
        toggle={() => setApprove(!approve)}
        updateStatusTo={status}
        header={`Change WO Status to ${workOrderStatuses[status]}`}
        form={form[status]}
        successView={succssView[status]}
        values={values}
        redirect="/cmms/workorders/todo"
        size={'lg'}
        spares={spares}
      />
      <CloseWorkOrder
        isOpen={close}
        toggle={() => setClose(!close)}
        workOrder={workOrder}
        refetch={refetch}
      />
    </div>
  )
}

export const getReadableTime = (time: any) => moment(time).format('DD-MM-YYYY')

export const JobPlanTable = ({ data }: { data: any }) => (
  <>
    <Table>
      <thead>
        <tr>
          <th>OP NO.</th>
          <th>Description</th>
          <th>Main Org</th>
          <th>Planned Hours</th>
          <th>Planned Resources</th>
          <th>Total Man Hours</th>
          <th>Remarks</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((o: any) => (
          <tr key={o?.number}>
            <td>{o?.number}</td>
            <td>{o?.description}</td>
            <td>{o?.mainOrg}</td>
            <td style={{ textAlign: 'center' }}>{o?.plannedHours}</td>
            <td>{o?.plannedResources}</td>
            <td style={{ textAlign: 'center' }}>{o?.totalManHours}</td>
            <td>{o?.remarks}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </>
)

export default function WorkOrderDetails() {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const fetchWorkRequests = async () => {
    const response = await makeAuthenticatedRequest(
      `${workOrderAPI}/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, isRefetching, refetch }: any = useQuery(
    'workRequests',
    fetchWorkRequests
  )

  const workOrder = data?.workrequest
  const history = data?.history
  const materialHistory = data?.materialHistory

  const generalDetails: any = {
    'Vessel / Rig': workOrder?.vessel,
    'WO description': workOrder?.description,
    'Tag ID': workOrder?.tagId,
    'Tag Description': workOrder?.tagDescription,
    'Tag Type': workOrder?.tagType,
    'Function Number': workOrder?.function,
    'Function Description': workOrder?.functionDescription
  }

  const planningInformation: any = {
    'Responsible Department': capitalizeFirstLetter(workOrder?.department),
    'Reported By': workOrder?.reportedBy,
    'Work Type': toNormalCase(workOrder?.workType),
    Priority: workOrder?.priority,
    Criticality: workOrder?.criticality,
    'Estimated Hours': workOrder?.estimatedHours
  }

  const prepare: any = {
    'Work Request Number': workOrder?.number,
    ...(workOrder?.pmNumber ? { 'PM Number': workOrder?.pmNumber } : {}),
    'Work Description': workOrder?.problemNoticed,
    'Job to be Done': workOrder?.jobPending,
    Status: workOrderStatuses[workOrder?.woStatus] || workOrder?.woStatus,
    'Created Date': getReadableTime(workOrder?.createdAt),
    ...(workOrder?.mrComments
      ? { 'Material Request Comments': workOrder?.mrComments }
      : {}),
    ...(workOrder?.mrPrNumber
      ? { 'Material PO Number': workOrder?.mrPrNumber }
      : {}),
    ...(workOrder?.reasonFor3rdParty
      ? { 'Reason for 3rd Party Request': workOrder?.reasonFor3rdParty }
      : {}),
    ...(workOrder?.thirdPartyPrNumber
      ? { 'PR Number': workOrder?.thirdPartyPrNumber }
      : {}),
    ...(workOrder?.reasonForShutdown
      ? {
          'Reason for Shutdown': workOrder?.reasonForShutdown
        }
      : {})
  }

  const planningSchedule: any = {
    'Planned Start': getReadableTime(workOrder?.plannedStart),
    'Planned Finish': getReadableTime(workOrder?.plannedFinish),
    'Original Plan Start': getReadableTime(workOrder?.orgPlanStart),
    'Original Plan Finish': getReadableTime(workOrder?.orgPlanFinish),
    'Calculated Plan Start': getReadableTime(workOrder?.calculatedPlanStart),
    'Remaining Hours': workOrder?.remainingHours,
    'Executed Time': workOrder?.executionTime,
    'Total Man Hours': workOrder?.totalManHours
  }

  const interval: any = {
    'TQ / MOC Number': workOrder?.mocNumber
  }

  const sourceInformation: any = {
    'Fault Description': workOrder?.faultDescription,
    Discovery: workOrder?.discovery,
    'Inspected Notes': workOrder?.inspectedNotes
  }

  const closeOutDetails: any = {
    dateDone: getReadableTime(workOrder?.closeOutReport?.dateDone),
    totalDuration: workOrder?.closeOutReport?.totalDuration,
    downTime: workOrder?.closeOutReport?.downTime,
    miscExpense: workOrder?.closeOutReport?.miscExpense,
    budgetCode: 'TS-0045',
    woCompleted: workOrder?.closeOutReport?.woCompleted,
    history: workOrder?.closeOutReport?.history,
    lessonLearnt: workOrder?.closeOutReport?.lessonLearnt,
    failureClause: workOrder?.closeOutReport?.failureClause,
    failureDescription: workOrder?.closeOutReport?.failureDescription,
    closedBy: workOrder?.closeOutReport?.closedBy,
    closedDepartment: workOrder?.closeOutReport?.closedDepartment,
    closedAt: getReadableTime(workOrder?.closeOutReport?.closedAt)
  }

  const OperationsTable = () => (
    <>
      <Table>
        <thead>
          <tr>
            <th>Operations Number</th>
            <th>Description</th>
            <th>Main Org</th>
            <th>Planned Hours</th>
            <th>Planned Resources</th>
            <th>Total Man Hours</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {workOrder?.operations?.map((o: any) => (
            <tr key={o?.number}>
              <td>{o?.number}</td>
              <td>{o?.description}</td>
              <td>{o?.mainOrg}</td>
              <td>{o?.plannedHours}</td>
              <td>{o?.plannedResources}</td>
              <td>{o?.totalManHours}</td>
              <td>{o?.remarks}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )

  const MaterialsTable = () => (
    <>
      <Table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Part Number</th>
            <th>Part Description</th>
            <th>Spare Type</th>
            <th>UOM</th>
            <th>Quantity on Hand</th>
            <th>Quantity Required</th>
            {workOrder?.woStatus === 'closed' && <th>Material Returned</th>}
            <th>Date Required</th>
          </tr>
        </thead>
        <tbody>
          {workOrder?.materialRequest?.map((s: any, index: number) => (
            <tr key={s?.['Spare Part Number']}>
              <td>{incrementby1(index)}</td>
              <td>{s?.['Spare Part Number']}</td>
              <td>{s?.['Spare Part Description']}</td>
              <td>{s?.spareType}</td>
              <td>{s?.uom}</td>
              <td>{s?.onHandQuantity}</td>
              <td>{s?.quantityRequested}</td>
              {workOrder?.woStatus === 'closed' && (
                <td>{s?.quantityReturned || '-'}</td>
              )}
              <td>{getReadableTime(s?.dateRequested)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )

  const todoActionsAccess = [
    'todo',
    'inProgress',
    'waitingForMaterial',
    'waitingFor3rdParty',
    'waitingForShutdown'
  ]

  const canViewTodoActions = todoActionsAccess?.includes(workOrder?.woStatus)

  const pmTypes = ['PM', 'SCPM', 'CLASS']

  const [sparesList, setSparesList] = useState([])
  const [spares, setSpares]: any = useState([])
  const [printModal, setPrintModal] = useState(false)

  const fetchStock = async () =>
    await makeAuthenticatedRequest(
      `${stock}/inventory`,
      'GET',
      null,
      accounts,
      instance
    )

  useQuery('Stock', fetchStock, {
    onSuccess: (data) => setSparesList(data?.inventoryList)
  })
  const componentRef: any = useRef()

  return (
    <>
      <div>
        <Row style={{ margin: 15 }}>
          <Col xs="12">
            <Box heading="Prepare" height={'5vh'}>
              {Object.keys(prepare)?.map((key: any) =>
                renderData(key, prepare[key])
              )}
            </Box>
          </Col>
          <Col>
            <Box heading="General Details">
              {Object.keys(generalDetails)?.map((key: any) =>
                renderData(key, generalDetails[key])
              )}
            </Box>
          </Col>
          <Col>
            <Box heading="Planning Information">
              {Object.keys(planningInformation)?.map((key: any) =>
                renderData(key, planningInformation[key])
              )}
            </Box>
          </Col>
          <Row>
            {workOrder?.faultDescription && (
              <Col xs="6">
                <Box heading="Source Information" height={'5vh'}>
                  {Object.keys(sourceInformation)?.map((key: any) =>
                    renderData(key, sourceInformation[key])
                  )}
                </Box>
              </Col>
            )}
            <Col xs="6">
              {workOrder?.plannedStart && (
                <Box heading="Planning Schedule">
                  {Object.keys(planningSchedule)?.map((key: any) =>
                    renderData(key, planningSchedule[key])
                  )}
                </Box>
              )}
              {workOrder?.mocNumber && (
                <Box heading="Interval" height={'5vh'}>
                  {Object.keys(interval)?.map((key: any) =>
                    renderData(key, interval[key])
                  )}
                </Box>
              )}
            </Col>
          </Row>
          {workOrder?.operations?.length > 0 && (
            <div>
              <Box heading="Operations">
                <OperationsTable />
              </Box>
            </div>
          )}
          {pmTypes?.includes(workOrder?.workType) && (
            <div>
              <Box heading={`Job Plan - ${workOrder?.jobCode}`}>
                <JobPlanTable data={jobcodes[workOrder?.jobCode]} />
              </Box>
            </div>
          )}
          {workOrder?.materialRequest?.length > 0 && (
            <div>
              <Box heading="Materials Request">
                <MaterialsTable />
              </Box>
            </div>
          )}
        </Row>
      </div>
      {workOrder?.woStatus === 'closed' && (
        <Row>
          {workOrder?.closeOutReport?.dateDone && (
            <Box heading="Close Out Details" height={'5vh'}>
              {Object.keys(closeOutDetails)?.map((key: any) =>
                renderData(toNormalCase(key), closeOutDetails[key])
              )}
            </Box>
          )}
        </Row>
      )}
      <Row>
        <Col>
          <ViewHistory
            data={history}
            title={
              <p
                style={{
                  margin: 0,
                  fontSize: 20,
                  fontWeight: 600,
                  paddingTop: 5,
                  paddingBottom: 5
                }}
              >
                WO APPROVAL HISTORY
              </p>
            }
            permitColor="#FFD580"
          />
        </Col>
      </Row>
      {materialHistory?.length > 0 && (
        <ViewHistory
          data={materialHistory}
          title={
            <p
              style={{
                margin: 0,
                fontSize: 20,
                fontWeight: 600,
                paddingTop: 5,
                paddingBottom: 5
              }}
            >
              MATERIAL APPROVAL HISTORY
            </p>
          }
          permitColor="#FFD580"
        />
      )}
      {(workOrder?.woStatus === 'wrCreated' ||
        workOrder?.woStatus === 'woDraft') && (
        <WorkOrderForm workOrder={workOrder} refetch={refetch} />
      )}
      {workOrder?.woStatus === 'woCreated' && (
        <CreatedWorkOrdersActions workOrder={workOrder} />
      )}
      {/* {workOrder?.woStatus === 'woDraft' && (
        <WorkOrderForm workOrder={workOrder} />
      )} */}
      {canViewTodoActions && (
        <ModalFooter>
          <Button color="warning" onClick={() => setPrintModal(true)}>
            Print
          </Button>
        </ModalFooter>
      )}
      {canViewTodoActions && (
        <TodoActions spares={spares} workOrder={workOrder} refetch={refetch} />
      )}
      {printModal && (
        <Modal
          isOpen={printModal}
          size="xl"
          toggle={() => setPrintModal(!printModal)}
        >
          <ModalBody>
            <div style={{ fontSize: 8 }} ref={componentRef}>
              <SectionHeader title={workOrderStatuses[workOrder?.woStatus]} />
              <h4 style={{ textDecoration: 'underline' }}>Prepare</h4>
              {Object.keys(prepare)?.map((key: any) =>
                renderData(key, prepare[key], 8)
              )}
              <h4 style={{ textDecoration: 'underline' }}>General Details</h4>
              {Object.keys(generalDetails)?.map((key: any) =>
                renderData(key, generalDetails[key], 8)
              )}
              <h4 style={{ textDecoration: 'underline' }}>
                Planning Information
              </h4>
              {Object.keys(planningInformation)?.map((key: any) =>
                renderData(key, planningInformation[key], 8)
              )}
              {pmTypes?.includes(workOrder?.workType) && (
                <div>
                  <h4 style={{ textDecoration: 'underline' }}>Job plan</h4>
                  <JobPlanTable data={jobcodes[workOrder?.jobCode]} />
                </div>
              )}
              {workOrder?.materialRequest?.length > 0 && (
                <div>
                  <h4 style={{ textDecoration: 'underline' }}>Materials</h4>
                  <MaterialsTable />
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={() => setPrintModal(!printModal)}>
              Back
            </Button>
            <ReactToPrint
              pageStyle={'padding: 20'}
              documentTitle={`Work Order - ${workOrder?.number}`}
              trigger={() => <Button color="warning" children="Print" />}
              content={() => componentRef.current}
            />
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
