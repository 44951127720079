import arrayMutators from 'final-form-arrays'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { Button, Col, Input, ModalFooter, Row, Table } from 'reactstrap'
import { Field, Form, useForm } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { MdDelete } from 'react-icons/md'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import CustomLabel from '../../components/InputFields/CustomLabel'
import LabeledDateTimeInput from '../../components/InputFields/DateTime'
import LabeledTextInput from '../../components/InputFields/LabeledTextInput'
import LabledParagraphInput from '../../components/InputFields/LabledParagraph'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import toNormalCase from '../../components/lib/toNormalCase'
import { Box, renderData } from './PmTree'
import { capitalizeFirstLetter } from '../../components/lib/capitalizeFirstLetter'
import { equipmentAPI, stock, workOrderAPI } from '../../components/serverurl'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { UserContext } from '../../App'
import { workOrderStatuses } from '../../components/constants/woStatuses'
import { getReadableTime } from './WorkOrderDetails'
import { jobcodes } from '../../components/constants/jobcodes'

export const CancelWorkOrder = ({
  isOpen,
  toggle,
  header,
  redirect
}: {
  isOpen: boolean
  toggle: any
  header: string
  redirect: any
}) => {
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const navigate = useNavigate()
  const { id } = useParams()

  const [comment, setComment] = useState()

  const onSubmit = async () => {
    const body: any = {
      comment,
      lastestUpdatedBy: userData?.id
    }
    try {
      const res: any = await makeAuthenticatedRequest(
        `${workOrderAPI}/${id}`,
        'DELETE',
        body,
        accounts,
        instance
      )

      return res?.status
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <SubmitModalWithForm
      isOpen={isOpen}
      onClose={(val: any) => {
        toggle()
        val && navigate(redirect)
      }}
      onSubmit={async (e: any) => {
        e.preventDefault()
        const res = await onSubmit()
        return res
      }}
      size={'xl'}
      header={header}
      form={
        <>
          <CustomLabel label="Reason for Cancel *" />
          <Input
            type="text"
            onChange={(e: any) => setComment(e?.target?.value)}
          />
        </>
      }
    />
  )
}

export const UpdateWOStatusWithForm = ({
  isOpen,
  toggle,
  header,
  redirect,
  form,
  values,
  successView,
  updateStatusTo,
  size,
  spares
}: {
  isOpen: boolean
  toggle: any
  header: string
  redirect: any
  form: any
  values: any
  successView: any
  updateStatusTo: any
  size: any
  spares: any
}) => {
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const navigate = useNavigate()
  const { id } = useParams()

  const onSubmit = async () => {
    const body: any = {
      ...values,
      lastestUpdatedBy: userData?.id,
      woStatus: updateStatusTo,
      ...(spares?.length > 0 ? { materialRequest: spares } : {}),
      id
    }
    try {
      const res: any = await makeAuthenticatedRequest(
        `${workOrderAPI}/${id}`,
        'PATCH',
        body,
        accounts,
        instance
      )

      return res?.status
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <SubmitModalWithForm
      isOpen={isOpen}
      onClose={(val: any) => {
        toggle()
        val && navigate(redirect)
      }}
      onSubmit={async (e: any) => {
        e.preventDefault()
        const res = await onSubmit()
        return res
      }}
      size={size}
      header={header}
      form={form}
      sucessView={successView}
    />
  )
}

export const ApproveWorkOrder = ({
  formValues,
  toggle,
  isOpen,
  spares,
  workOrder,
  updateStatusTo,
  header,
  refetch
}: {
  formValues: any
  toggle: any
  isOpen: any
  spares?: any
  workOrder?: any
  updateStatusTo: any
  header: any
  refetch?: any
}) => {
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const navigate = useNavigate()
  const { id } = useParams()

  const generalDetails: any = {
    'Vessel / Rig': workOrder?.vessel,
    'WO description': workOrder?.description,
    'Tag ID': workOrder?.tagId,
    'Tag Description': workOrder?.tagDescription,
    'Tag Type': workOrder?.tagType,
    'Function Number': workOrder?.function,
    'Function Description': workOrder?.functionDescription
  }

  const planningInformation: any = {
    Department: capitalizeFirstLetter(workOrder?.department),
    'Reported By': workOrder?.reportedBy,
    'Work Type': toNormalCase(workOrder?.workType),
    Priority: workOrder?.priority,
    Criticality: workOrder?.criticality,
    'Estimated Hours': workOrder?.estimatedHours
  }

  const prepare: any = {
    'Work Request Number': workOrder?.number,
    'Work Description': workOrder?.problemNoticed,
    'Job to be Done': workOrder?.jobPending,
    Status: workOrderStatuses[workOrder?.woStatus] || workOrder?.woStatus,
    'Created Date': moment().format('DD-MM-YYYY')
  }

  const planningSchedule: any = {
    'Planned Start': getReadableTime(formValues?.plannedStart),
    'Planned Finish': getReadableTime(formValues?.plannedFinish),
    'Original Plan Start': getReadableTime(formValues?.orgPlanStart),
    'Original Plan Finish': getReadableTime(formValues?.orgPlanFinish),
    'Calculated Plan Start': getReadableTime(formValues?.calculatedPlanStart),
    'Remaining Hours': formValues?.remainingHours,
    ExecurtedTime: formValues?.executionTime,
    'Total Man Hours': formValues?.totalManHours
  }

  const interval: any = {
    'TQ / MOC Number': formValues?.mocNumber
  }

  const sourceInformation: any = {
    'Fault Description': formValues?.faultDescription,
    Discovery: formValues?.discovery,
    Symptom: formValues?.symptom,
    'Inspected Notes': formValues?.inspectedNotes
  }

  const OperationsTable = () => (
    <>
      <Table>
        <thead>
          <tr>
            <th>Operations Number</th>
            <th>Description</th>
            <th>Main Org</th>
            <th>Planned Hours</th>
            <th>Planned Resources</th>
            <th>Total Man Hours</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {formValues?.operations?.map((o: any) => (
            <tr key={o?.number}>
              <td>{o?.number}</td>
              <td>{o?.description}</td>
              <td>{o?.mainOrg}</td>
              <td>{o?.plannedHours}</td>
              <td>{o?.plannedResources}</td>
              <td>{o?.totalManHours}</td>
              <td>{o?.remarks}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )

  const MaterialsTable = () => (
    <>
      <Table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Part Number</th>
            <th>Part Description</th>
            <th>Spare Type</th>
            <th>UOM</th>
            <th>Quantity on Hand</th>
            <th>Quantity Required</th>
            <th>Date Required</th>
          </tr>
        </thead>
        <tbody>
          {spares?.map((s: any, index: number) => (
            <tr key={s?.['Spare Part Number']}>
              <td>{incrementby1(index)}</td>
              <td>{s?.['Spare Part Number']}</td>
              <td>{s?.['Spare Part Description']}</td>
              <td>{s?.spareType}</td>
              <td>{s?.uom}</td>
              <td>{s?.onHandQuantity}</td>
              <td>{s?.quantityRequested}</td>
              <td>{getReadableTime(s?.dateRequested)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )

  const onSubmit = async () => {
    try {
      const res: any = await makeAuthenticatedRequest(
        `${workOrderAPI}/${id}`,
        'PATCH',
        {
          ...formValues,
          id: workOrder?._id,
          materialRequest: spares,
          woStatus: updateStatusTo,
          lastestUpdatedBy: userData?.id,
          wrStatus: spares?.length > 0 && 'mrCreated'
        },
        accounts,
        instance
      )

      if (res?.status === 'success' && refetch) {
        await refetch()
      }

      return res?.status
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <SubmitModalWithForm
        isOpen={isOpen}
        onClose={(val: any) => {
          toggle()
          // val && navigate('/cmms/workOrders/created')
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()
          const res = await onSubmit()
          return res
        }}
        size={'xl'}
        header={header}
        form={
          <>
            <Row>
              <Col xs="12">
                <Box heading="Prepare" height={'5vh'}>
                  {Object.keys(prepare)?.map((key: any) =>
                    renderData(key, prepare[key])
                  )}
                </Box>
              </Col>
              <Col>
                <Box heading="General Details">
                  {Object.keys(generalDetails)?.map((key: any) =>
                    renderData(key, generalDetails[key])
                  )}
                </Box>
              </Col>
              <Col>
                <Box heading="Planning Information">
                  {Object.keys(planningInformation)?.map((key: any) =>
                    renderData(key, planningInformation[key])
                  )}
                </Box>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <Box heading="Source Information" height={'5vh'}>
                  {Object.keys(sourceInformation)?.map((key: any) =>
                    renderData(key, sourceInformation[key])
                  )}
                </Box>
              </Col>
              <Col xs="6">
                <Box heading="Planning Schedule">
                  {Object.keys(planningSchedule)?.map((key: any) =>
                    renderData(key, planningSchedule[key])
                  )}
                </Box>
                <Box heading="Interval" height={'5vh'}>
                  {Object.keys(interval)?.map((key: any) =>
                    renderData(key, interval[key])
                  )}
                </Box>
              </Col>
            </Row>
            <div>
              <Box heading="Operations">
                <OperationsTable />
              </Box>
            </div>
            <div>
              <Box heading="Materials Request">
                <MaterialsTable />
              </Box>
            </div>
          </>
        }
        sucessView={
          <div>
            <Table bordered style={{ borderColor: '#000' }}>
              <tbody>
                <tr>
                  <td>WO Number</td>
                  <td>{workOrder?.number || 'Number'}</td>
                </tr>
                <tr>
                  <td>WO Description</td>
                  <td>{workOrder?.description || 'Title'}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        }
      />
    </>
  )
}

const fontSizes: any = {
  xs: 12,
  sm: 14,
  normal: 16,
  lg: 18,
  xl: 20,
  xxl: 22
}

export const Text = ({
  children,
  styles,
  size,
  onClick = () => {}
}: {
  children?: any
  styles?: any
  size?: string | any
  onClick?: any
}) => {
  return (
    <p
      onClick={onClick}
      style={{
        ...styles,
        margin: 0,
        fontSize: fontSizes[size] || fontSizes?.normal
      }}
    >
      {children}
    </p>
  )
}

const FormSection = ({ title, children }: { title: string; children: any }) => (
  <>
    <hr />
    <Row style={{ marginBottom: 20 }}>
      <Col
        xs="2"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Spacer height={10} />
        <Text
          size="xxl"
          styles={{
            fontWeight: 700
          }}
        >
          {title}
        </Text>
      </Col>
      <Col>
        <Row>{children}</Row>
      </Col>
    </Row>
  </>
)

const renderField = ({ input, label, type, meta: { touched, error } }: any) => (
  <div>
    <div>
      <LabledParagraphInput
        {...input}
        type={'textarea'}
        placeholder={label}
        occupy={12}
      />
      {touched && error && <span style={{ color: 'red' }}>{error}</span>}
    </div>
  </div>
)

export const renderFields = ({
  fields,
  meta: { error, submitFailed }
}: any) => (
  <>
    {fields.map((activity: any, index: any) => (
      <React.Fragment key={index}>
        <tr>
          <td style={{ width: 5 }}>
            <Field
              name={`${activity}.number`}
              type="text"
              defaultValue={incrementby1(index)}
              component={renderField}
            />
          </td>
          <td style={{ width: '30%' }}>
            <Field
              name={`${activity}.description`}
              type="text"
              component={renderField}
            />
          </td>
          <td>
            <Field
              name={`${activity}.mainOrg`}
              type="text"
              component={renderField}
            />
          </td>
          <td style={{ width: 5 }}>
            <Field
              name={`${activity}.plannedHours`}
              type="text"
              component={renderField}
            />
          </td>
          <td>
            <Field
              name={`${activity}.plannedResources`}
              type="text"
              component={renderField}
            />
          </td>
          <td style={{ width: 5 }}>
            <Field
              name={`${activity}.totalManHours`}
              type="text"
              component={renderField}
            />
          </td>
          <td>
            <Field
              name={`${activity}.remarks`}
              type="text"
              component={renderField}
            />
          </td>
          <td style={{ color: 'red' }}>
            <MdDelete onClick={() => fields.remove(index)} />
          </td>
        </tr>
      </React.Fragment>
    ))}
    <tr>
      <td colSpan={5} style={{ border: 0 }}>
        <Button
          type="button"
          style={{ marginTop: 20 }}
          onClick={() => fields.push({})}
          color="warning"
        >
          Add Operation
        </Button>
      </td>
    </tr>
  </>
)

export const renderOperations = () => {
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Operations Number</th>
            <th>Description</th>
            <th>Main Org</th>
            <th>Planned Hours</th>
            <th>Planned Resources</th>
            <th>Man Hours</th>
            <th>Remarks</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <FieldArray name="operations" component={renderFields} />
        </tbody>
      </Table>
    </div>
  )
}

const validate = (values: any) => {
  const errors: any = {}
  if (!values.plannedStart) {
    errors.plannedStart = 'Required'
  }
  if (!values.plannedFinish) {
    errors.plannedFinish = 'Required'
  }
  if (!values.executionTime) {
    errors.executionTime = 'Required'
  }
  if (!values.totalManHours) {
    errors.totalManHours = 'Required'
  }
  if (!values.faultDescription) {
    errors.faultDescription = 'Required'
  }

  return errors
}

export default function WorkOrderForm({
  workOrder,
  refetch
}: {
  workOrder?: any
  refetch?: any
}) {
  const { accounts, instance } = useMsal()
  const [searchParams] = useSearchParams()

  const [preview, setPreview] = useState(false)
  const [cancel, setCancel] = useState(false)
  const [spares, setSpares]: any = useState([])
  const [sparesList, setSparesList]: any = useState([])
  const [status, setStatus]: any = useState()

  const navigate = useNavigate()

  const eq = searchParams.get('equipment')

  const fetchTag = async () => {
    const response = await makeAuthenticatedRequest(
      `${equipmentAPI}?equipment=${eq}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data }: any = useQuery('tagDetails', fetchTag)

  const fetchStock = async () =>
    await makeAuthenticatedRequest(
      `${stock}/inventory`,
      'GET',
      null,
      accounts,
      instance
    )

  useQuery('Stock', fetchStock, {
    onSuccess: (data) => setSparesList(data?.inventoryList)
  })

  const equipment = data?.equipment

  const formInitialValues = {
    vessel: equipment?.vessel,
    description: equipment?.systemDescription,
    status: equipment?.operationStatus,
    tagId: equipment?.tag,
    tagDescription: equipment?.tagDescription,
    tagType: equipment?.tagType

    // ...submitSample
  }

  const handleCheckboxClick = (spare: any) => {
    const id = spare?.['Spare Part Number']
    const isSelected = spares?.some((a: any) => a?.['Spare Part Number'] === id)

    if (isSelected) {
      setSpares(spares?.filter((s: any) => s?.['Spare Part Number'] !== id))
    } else {
      setSpares([...spares, spare])
    }
  }

  const handleQuantityChange = (spareId: any, quantity: any) => {
    setSpares(
      spares?.map((s: any) =>
        s?.['Spare Part Number'] === spareId
          ? { ...s, quantityRequested: quantity }
          : s
      )
    )
  }
  const handleDateChange = (spareId: any, date: any) => {
    setSpares(
      spares?.map((s: any) =>
        s?.['Spare Part Number'] === spareId ? { ...s, dateRequested: date } : s
      )
    )
  }

  const validateSpares = spares?.every(
    (s: any) => s?.quantityRequested && s?.dateRequested
  )

  const SelectJobCode = () => {
    const { change } = useForm()
    return (
      <Col xs="3">
        <CustomLabel label="Select Job Code" />
        <select
          defaultValue={''}
          onChange={(e: any) =>
            change('operations', jobcodes?.[e?.target?.value])
          }
        >
          <option value="" disabled>
            Select Job Code
          </option>
          <option value="clear">Clear JobCode</option>
          <option value={Object.keys(jobcodes)[0]}>
            {Object.keys(jobcodes)[0]}
          </option>
          <option value={Object.keys(jobcodes)[1]}>
            {Object.keys(jobcodes)[1]}
          </option>
        </select>
      </Col>
    )
  }

  // localStorage.setItem('createCM', JSON.stringify())
  const onClickPR = () => {
    const outOfStok = spares?.filter((s: any) => s?.onHandQuantity === '0')
    const tagDetails = {
      tag: workOrder?.tagId,
      tagId: workOrder?.tagId,
      tagDescription: workOrder?.tagDescription,
      tagType: workOrder?.tagType
    }
    const prInitial = { spares: outOfStok, tagDetails }
    localStorage.setItem('prItems', JSON.stringify(prInitial))
    navigate('/purchase/create')
  }

  return (
    <>
      <div>
        <div
          style={{
            backgroundColor: '#FFD580'
          }}
        >
          <p
            style={{
              margin: 0,
              lineHeight: 2,
              textAlign: 'center',
              paddingTop: 10,
              paddingBottom: 10,
              fontSize: 20,
              fontWeight: 600
            }}
          >
            CREATE WORK ORDER
          </p>
        </div>
        <Form
          onSubmit={(values: any) => {}}
          initialValues={{ ...formInitialValues, ...workOrder }}
          validate={validate}
          mutators={{
            ...arrayMutators
          }}
          render={({ values, form, hasValidationErrors }) => (
            <form>
              <FormSection title="Planning Schedule">
                <LabeledDateTimeInput
                  name="orgPlanStart"
                  label="Original Plan Start"
                  occupy={3}
                />
                <LabeledDateTimeInput
                  name="orgPlanFinish"
                  label="Original Plan Finish"
                  occupy={3}
                />
                <LabeledDateTimeInput
                  name="plannedStart"
                  label="Planned Start *"
                  occupy={3}
                />
                <LabeledDateTimeInput
                  name="plannedFinish"
                  label="Planned Finish *"
                  occupy={3}
                />
                <LabeledDateTimeInput
                  name="calculatedPlanStart"
                  label="Calculated Plan Start"
                  occupy={3}
                />
                <LabeledTextInput
                  name="executionTime"
                  label="Execution Time *"
                  occupy={3}
                />
                <LabeledTextInput
                  name="totalManHours"
                  label="Total Man Hours *"
                  occupy={3}
                />
              </FormSection>
              <FormSection title="TQ / MOC Number">
                <LabeledTextInput name="mocNumber" occupy={12} />
              </FormSection>
              <FormSection title="Source Information">
                <LabledParagraphInput
                  name="faultDescription"
                  label="Fault Description *"
                  occupy={12}
                />
                <Col xs="6">
                  <CustomLabel label="Dicovery" />
                  <Input type="select" style={{ backgroundColor: '#DCDCDC' }}>
                    <option value="">Select</option>
                    <option>Based on Observation</option>
                    <option>Based on Audit findings</option>
                    <option>Seal leakage</option>
                    <option>Broken part</option>
                    <option>Failure to perform</option>
                    <option>High Vibration</option>
                    <option>High Temperature</option>
                  </Input>
                </Col>
                <LabeledTextInput name="symptom" label="Symptom" occupy={6} />
                <LabledParagraphInput
                  name="inspectedNotes"
                  label="Inspected Notes"
                  occupy={12}
                />
              </FormSection>
              <FormSection title="Operation Details">
                <SelectJobCode />
                {renderOperations()}
              </FormSection>
              <FormSection title="Material Request">
                <>
                  <h4 style={{ textAlign: 'center' }}>
                    INVENTORY LIST FOR {workOrder?.tagId}
                  </h4>
                  <hr />
                  <Table>
                    <thead>
                      <tr>
                        <th>
                          <Input onChange={(e) => {}} type="checkbox" />
                        </th>
                        <th>Part Number</th>
                        <th>Part Description</th>
                        <th>Spare Type</th>
                        <th>UOM</th>
                        <th>Quantity on Hand</th>
                        <th>Quantity Required</th>
                        <th>Date Required</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[
                        sparesList[0],
                        sparesList[1],
                        sparesList[2],
                        sparesList[3],
                        sparesList[4],
                        sparesList[5]
                      ]?.map((s: any) => (
                        <tr key={s?.['Spare Part Number']}>
                          <td>
                            <Input
                              onClick={() => handleCheckboxClick(s)}
                              defaultChecked={spares?.includes(
                                s?.['Spare Part Number']
                              )}
                              type="checkbox"
                            />
                          </td>
                          <td>{s?.['Spare Part Number']}</td>
                          <td>{s?.['Spare Part Description']}</td>
                          <td>{s?.spareType}</td>
                          <td>{s?.uom}</td>
                          <td>{s?.onHandQuantity}</td>
                          <td>
                            <Input
                              type="text"
                              onChange={(e: any) =>
                                handleQuantityChange(
                                  s?.['Spare Part Number'],
                                  e?.target?.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <Input
                              type="date"
                              onChange={(e: any) =>
                                handleDateChange(
                                  s?.['Spare Part Number'],
                                  e?.target?.value
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {!validateSpares && (
                    <span
                      style={{
                        fontSize: 12,
                        color: 'red'
                      }}
                    >
                      * Quantity And Date are mandatory to enter for Selected
                      spares
                    </span>
                  )}
                </>
              </FormSection>
              <ModalFooter>
                <Button
                  color="warning"
                  onClick={(e: any) => {
                    e?.preventDefault()
                    onClickPR()
                  }}
                >
                  Create Purchase Request
                </Button>
                <Button color={'danger'} onClick={() => setCancel(true)}>
                  Cancel
                </Button>
                <Button
                  color={'warning'}
                  onClick={() => {
                    setPreview(true)
                    setStatus('woDraft')
                  }}
                >
                  Save
                </Button>
                <Button
                  color={hasValidationErrors ? 'secondary' : 'warning'}
                  onClick={() => {
                    setPreview(true)
                    setStatus('woCreated')
                  }}
                  disabled={hasValidationErrors}
                >
                  Submit
                </Button>
              </ModalFooter>
              <ApproveWorkOrder
                isOpen={preview}
                toggle={() => setPreview(!preview)}
                formValues={values}
                spares={spares}
                workOrder={workOrder}
                updateStatusTo={status}
                header="Create Work Order"
                refetch={refetch}
              />
              <CancelWorkOrder
                isOpen={cancel}
                toggle={() => setCancel(!cancel)}
                header="Cancel Work Request"
                redirect="/cmms/workRequests/created"
              />
            </form>
          )}
        />
      </div>
    </>
  )
}
