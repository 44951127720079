import { Row, Table } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { pmAPI } from '../../components/serverurl'
import { Box, renderData } from '../workManagement/PmTree'
import toNormalCase from '../../components/lib/toNormalCase'
import SectionHeader from '../../components/Layout/SectionHeader'
import {
  getReadableTime,
  JobPlanTable
} from '../workManagement/WorkOrderDetails'
import { jobcodes } from '../../components/constants/jobcodes'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { workOrderStatuses } from '../../components/constants/woStatuses'

export default function ViewPreventiveMaintenance({ back }: { back: any }) {
  const { id } = useParams()
  const { accounts, instance } = useMsal()

  const fetchPm = async () => {
    const response = await makeAuthenticatedRequest(
      `${pmAPI}/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch, isLoading }: any = useQuery('pm', fetchPm)

  const pm = data?.pm

  const pmDetails: any = {
    number: pm?.number,
    description: pm?.workDescription,
    actionCodeId: pm?.actionCodeId,
    workType: pm?.workType,
    orgCode: pm?.orgCode,
    priorityId: pm?.priorityId,
    interval: pm?.interval,
    pmIntervalUnit: pm?.pmIntervalUnit,
    maintOrg: pm?.maintOrg,
    plannedHours: pm?.plannedHours,
    resources: pm?.resources,
    cost: pm?.cost,
    equipmentNumber: pm?.equipmentNumber,
    equipmentDescription: pm?.equipmentDescription,
    jobPlanCode: pm?.jobPlanCode,
    nextDueDate: getReadableTime(pm?.nextDueDate),
    triggerDate: getReadableTime(pm?.triggerDate)
  }

  return (
    <ProtectedLayout onBack={back}>
      <Row style={{ margin: 10 }}>
        <SectionHeader title={`Preventive Maintenance - ${pm?.number}`} />
        <hr />
        <Box heading="Preventive Maintenance">
          {Object.keys(pmDetails)?.map((key: any) =>
            renderData(toNormalCase(key), pmDetails[key])
          )}
        </Box>
        <Box heading="Job Plan">
          <JobPlanTable data={jobcodes[pm?.jobPlanCode]} />
          {/* {jobcodes[pm?.jobPlanCode]} */}
          {/* <pre style={{ fontFamily: 'poppins', fontSize: 14 }}>
            {jobcodes[pm?.jobPlan]}
          </pre> */}
        </Box>
        <Box heading="Work Order History">
          <Table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>WO Number</th>
                <th>WO Status</th>
              </tr>
            </thead>
            <tbody>
              {pm?.history?.map((h: any, index: number) => (
                <tr key={h?._id}>
                  <td>{incrementby1(index)}</td>
                  <td>{h?.number}</td>
                  <td>{workOrderStatuses[h?.woStatus]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      </Row>
    </ProtectedLayout>
  )
}
