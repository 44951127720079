import axios from "axios";
import { loginRequest, loginRequest2 } from "./authConfig";
import {
  adminCMPermissions,
  adminePermitPermissions,
  adminPMPermissions,
  adminFirsPermissions,
} from "./components/serverurl";
import { callMsGraph } from "./graph";
// const { loginRequest } = require("./authConfig")

export const getAccessToken = async (loginRequest, accounts, instance) => {
  try {
    if (!accounts || accounts.length === 0) {
      console.error("No accounts available");
      return;
    }

    const response = await instance?.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });
    return response.accessToken;
  } catch (error) {
    console.error("Access token retrieval error:", error);
    throw error;
  }
};

export const makeAuthenticatedRequest = async (
  url,
  method = "GET",
  data = null,
  accounts,
  instance
) => {
  try {
    const token = await getAccessToken(loginRequest, accounts, instance);
    const profileToken = await getAccessToken(
      loginRequest2,
      accounts,
      instance
    );
    const userData = await callMsGraph(profileToken);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      UserName: userData?.givenName,
      UserDepartment: userData?.department,
      UserDesignation: userData?.jobTitle,
    };

    const config = {
      method,
      url,
      headers,
      data: data ? data : undefined,
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const getAllPermissions = async (accounts, instance) => {
  try {
    const token = await getAccessToken(loginRequest, accounts, instance);

    const userDetailsToken = await getAccessToken(
      loginRequest2,
      accounts,
      instance
    );

    const userData = await callMsGraph(userDetailsToken);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const config = {
      method: "GET",
      url: `${adminCMPermissions}/${userData?.jobTitle}`,
      headers,
    };

    const response = await axios(config).catch((err) => console.info(err));
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getAllPMPermissions = async (accounts, instance) => {
  try {
    const token = await getAccessToken(loginRequest, accounts, instance);

    const userDetailsToken = await getAccessToken(
      loginRequest2,
      accounts,
      instance
    );

    const userData = await callMsGraph(userDetailsToken);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const config = {
      method: "GET",
      url: `${adminPMPermissions}/${userData?.jobTitle}`,
      headers,
    };

    const response = await axios(config).catch((err) => console.info(err));
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getAllEpermitPermissions = async (accounts, instance) => {
  try {
    const token = await getAccessToken(loginRequest, accounts, instance);

    const userDetailsToken = await getAccessToken(
      loginRequest2,
      accounts,
      instance
    );

    const userData = await callMsGraph(userDetailsToken);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const config = {
      method: "GET",
      url: `${adminePermitPermissions}/${userData?.jobTitle}`,
      headers,
    };

    const response = await axios(config).catch((err) => console.info(err));
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getAllFIRSPermissions = async (accounts, instance) => {
  try {
    const token = await getAccessToken(loginRequest, accounts, instance);

    const userDetailsToken = await getAccessToken(
      loginRequest2,
      accounts,
      instance
    );

    const userData = await callMsGraph(userDetailsToken);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const config = {
      method: "GET",
      url: `${adminFirsPermissions}/${userData?.jobTitle}`,
      headers,
    };

    const response = await axios(config).catch((err) => console.info(err));
    return response.data;
  } catch (err) {
    throw err;
  }
};

// // Make authenticated API requests
// makeAuthenticatedRequest("https://api.example.com/data", "GET", null)
//   .then((data) => {
//   })
//   .catch((error) => {
//     // Handle the error
//   })

// makeAuthenticatedRequest("https://api.example.com/create", "POST", {
//   key: "value"
// })
//   .then((data) => {
//   })
//   .catch((error) => {
//     // Handle the error
//   })
