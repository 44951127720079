import { Row } from 'reactstrap'
import RcmLayout from '../../components/rcm/RcmLayout'

import React from 'react'
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import { useMsal } from '@azure/msal-react'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import { useQuery } from 'react-query'

const CustomPieChart = () => {
  const { accounts, instance } = useMsal()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/counts`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchRcmCounts', fetchRcm)

  const { Stage2, Stage3, Stage4, Stage5, Stage6, Stage7 } =
    data?.countsByStatus || {}

  const pieData = [
    // { name: 'Created', value: Stage2 },
    { name: 'Stage 2', value: Stage2 || 0 },
    { name: 'Stage 3', value: Stage3 || 0 },
    { name: 'Stage 4', value: Stage4 || 0 },
    { name: 'Stage 5', value: Stage5 || 0 },
    { name: 'Stage 6', value: Stage6 || 0 },
    { name: 'Stage 7', value: Stage7 || 0 }
  ]

  const COLORS = [
    '#8884d8',
    '#82ca9d',
    '#ffc658',
    '#ff6b6b',
    '#4e79a7',
    '#f28e2b',
    '#76b7b2'
  ]

  const renderCustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    name,
    value
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180))
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180))

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {`${name}: ${value}`}
      </text>
    )
  }

  return (
    <ResponsiveContainer width="100%" height={600}>
      <PieChart>
        <Pie
          data={pieData}
          cx="50%"
          cy="50%"
          outerRadius={250}
          fill="#8884d8"
          dataKey="value"
          label={({ name, value }) => `${name}: ${value}`}
          labelLine={false}
        >
          {pieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend layout="horizontal" align="center" verticalAlign="bottom" />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default function RcmKpi() {
  return (
    <RcmLayout onBack="/cmms/rcm">
      <Row
        style={{
          margin: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh'
        }}
      >
        <CustomPieChart />
        {/* <img src={require('./../../images/rcmKpi.jpeg')} alt="rcm kpi" /> */}
      </Row>
    </RcmLayout>
  )
}
