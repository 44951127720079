import { useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useAdminPermissions } from './eptw/EditRisks'
import ViewPermit from './eptw/ViewPermit'

export default function ViewQR() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  return <ViewPermit data={data} isLoading={isLoading} />
}
