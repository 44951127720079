import {
  HIGH_RISK_COLOR,
  MEDIUM_RISK_COLOR,
  NO_RISK_COLOR
} from '../../components/constants/riskMatrixColors'

export const getToleranceColor = (score: any, riskToleranceLevel: any) => {
  if (score > riskToleranceLevel) {
    return HIGH_RISK_COLOR
  } else if (score <= riskToleranceLevel) {
    return NO_RISK_COLOR
  }
}

export const isTolerable = (score: any, riskToleranceLevel: any) => {
  if (score > riskToleranceLevel) {
    return 'Non Tolerable Risk'
  } else if (score <= riskToleranceLevel) {
    return 'Tolerable Risk'
  }
}

export const getRcmRiskColor = (score: any) => {
  if (score >= 1 && score <= 6) {
    return NO_RISK_COLOR
  } else if (score >= 7 && score <= 15) {
    return MEDIUM_RISK_COLOR
  } else if (score >= 16 && score <= 25) {
    return HIGH_RISK_COLOR
  } else {
    return '#fff'
  }
}
