import { useParams } from 'react-router-dom'
import ViewManuals from '../dashboardPages/ViewManuals'

export default function ApprovedManuals() {
  const { departmentCode, documentType, logicCode, orginator, origin } =
    useParams()
  return (
    <ViewManuals
      status="Approved"
      title="Approved Manuals"
      onBack="/cmms/manual"
      departmentCode={departmentCode}
      documentType={documentType}
      logicCode={logicCode}
      orginator={orginator}
      origin={origin}
    />
  )
}
