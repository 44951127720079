import { Col, Row } from "reactstrap";

import toNormalCase from "../lib/toNormalCase";
import { renderLongRow } from "../Modals/AuditPreviewModal";
import { Box } from "../../pages/workManagement/PmTree";
import { ViewHistory } from "../../pages/ViewCM";

export const INFORMATIONCARD_TYPES: any = {
  bc: "Behaviour & Conditions",
  ps: "Process & Safety",
};

export const INFORMATION_COLORS: any = {
  bc: "#d0ebff",
  ps: "#ffdaca",
};

export default function PreviewInformationCard({ values, history }: any) {
  const general: any = {
    "Applicant Name": values?.applicantName,
    "Date & Time": values?.date,
    ...(values?.cardNumber ? { "SHE Card Number": values?.cardNumber } : {}),
    Department: values?.department,
    "Boots on Deck": values?.boots,
    "Where did it Happen": values?.happendAt,
    "Equipment / Tag Number": values?.equipment || values?.others,
    Description: values?.equipmentDescription,
    "Equipment Others": values?.others,
    Type: INFORMATIONCARD_TYPES[values?.type],
    "Describe your observation": values?.describe,
    "Describe Your Action / Discussion / Intervention": values?.describeAction,
    ...(values?.comments &&
      values?.status === "rejected" && {
        "Rejected Comments": <b>{values?.comments}</b>,
      }),
    ...(values?.status && {
      Status: <b>{toNormalCase(values?.status)}</b>,
    }),
    ...(values?.icDepartment && {
      "IC Department": values?.icDepartment,
    }),
  };

  const ppe: any = {
    "PPE Worn": values?.ppeWorn,
    "Meets Job Requirement": values?.meetsJob,
    "Worn Correctly": values?.wornRight,
    "Acceptable Condition": values?.acceptable,
  };

  const tools: any = {
    "Tools / Equipment": values?.tools,
    "Safe tools, Equipment, Material": values?.safeTools,
    "Proper guards, Barriers": values?.properGuards,
    "Used correctly": values?.usedCorrectly,
  };

  const people: any = {
    "Exertion: Pushing / Pulling / Lifting / Reaching": values?.exertion,
    "Extreme Temperature: Pushing / Pulling": values?.extremeTemperature,
    "Risk to be struck by objects": values?.risk,
    Training: values?.training,
  };

  const procedure: any = {
    "Estabished and Understood": values?.established,
    "Employee authorized to operate": values?.employee,
    "Maintained and followed": values?.maintained,
    "Adequate for task": values?.adequate,
  };

  const houseKeeping: any = {
    "Area is clear of obstructions": values?.clearArea,
    "Area is used for intended purpose": values?.areaPurpose,
    "Material stored in safe manner": values?.material,
    "Proper disposal procedure followed": values?.disposalProcedure,
  };

  const chemicals: any = {
    "MSDS Available": values?.msds,
    "Chemicals are stored properly": values?.storedProperly,
    "Correct PPE worn around chemicals": values?.ppe,
  };

  const environment: any = {
    "Accidental spills during oils transfer operations, equipment failure, or vessel incidents":
      values?.accidental,
    "Chemical usage and discharge": values?.chemicalUsage,
    "Air Emissions": values?.airEmissions,
    "Noise and Vibrations": values?.noise,
    "Solid waste, hazardous waste and sewage": values?.solid,
  };

  const reputation: any = {
    "Unethical behaviour or misconduct": values?.unethical,
    "Technological advancement in staying ahead of industry trends":
      values?.techAdvancement,
  };

  const processSafety: any = {
    "Information regarding the highly hazardous chemical in process":
      values?.hazardous,
    "Operating Procedure": values?.operating,
    "Procedure not followed": values?.procedure,
    "Operation deviate from current practices": values?.operationDeviate,
    "Safe operating limitations": values?.operatingLimits,
    "Information readily available to employees involved": values?.information,
    "Safety Lock / Interlock": values?.safety,
    "Process Drawings (P&ID, PFD) with latest Revision": values?.process,
  };

  const integrity: any = {
    "Equipment and Construction accordancw with design specifications":
      values?.equipmentConstruction,
    "Proper safety, maintenance, operating and emergency procedures":
      values?.properSafety,
    "Piping systems (including components, values": values?.pipingSystems,
    "Presuure vessels and storage tanks": values?.pressureVessels,
    "Relief and vent systems and storage tanks": values?.relief,
    "Controls (Monitoring devices, alarms, sensors, interlocks)":
      values?.controlsI,
    "Emergency shutdown systems": values?.emergencyShut,
    "Temporary Hoses and connections": values?.temp,
    "Weep and leak in connections and Tubing": values?.weep,
    "Equipment Support": values?.equipmentSupport,
  };

  const training: any = {
    "Familiar with facility rules": values?.familiar,
    "Training and Education Programs": values?.trainingEducation,
    "Operator Training": values?.operatorTraining,
    "Contract employees on or near covered process involved in maintainence":
      values?.contractEmployees,
    "Provide information and train their employees how to safely perform their jobs":
      values?.informatonProvide,
    "Controls (Monitoring deveices, alarms, sensors, interlocks":
      values?.controls,
    "Written operating procedures and tasks with clear instructions":
      values?.operatingProcedures,
  };

  const processHazad: any = {
    "Failure mode and efects analysis (FMEA)": values?.failureMode,
    "Hazard and operability study (HAZOP)": values?.hazardOperability,
    "What if / Checklist": values?.checklist,
    "Potential process hazards": values?.potential,
    "Workspace Analysis": values?.workspaceAnalysis,
    "Modifications to current operting procedures": values?.modifications,
  };

  const permit: any = {
    "Permit Relevant to work and Valid": values?.permitRelevant,
    "Permit Display at the work location": values?.permitDisplay,
    "Tool box JSA and work party": values?.toolBox,
    "Permit display proper location and Equipment":
      values?.permitDisplayLocation,
    "Isolation as per Company Management": values?.isolation,
  };

  return (
    <>
      <Row>
        <Col xs="12">
          <Box heading="Details">
            {Object.keys(general).map((key: any) =>
              renderLongRow(key, general[key], "#FFF", 9, true)
            )}
          </Box>
        </Col>
        <Col xs="12" md="6">
          {values?.type === "bc" && (
            <>
              <Box heading="Chemicals">
                {Object.keys(chemicals).map((key: any) =>
                  renderLongRow(key, chemicals[key], "#FFF", 9, true)
                )}
              </Box>
              <Box heading="Environment">
                {Object.keys(environment).map((key: any) =>
                  renderLongRow(key, environment[key], "#FFF", 9, true)
                )}
              </Box>
              <Box heading="Reputation">
                {Object.keys(reputation).map((key: any) =>
                  renderLongRow(key, reputation[key], "#FFF", 9, true)
                )}
              </Box>
              <Box heading="House Keeping">
                {Object.keys(houseKeeping).map((key: any) =>
                  renderLongRow(key, houseKeeping[key], "#FFF", 9, true)
                )}
              </Box>
            </>
          )}
          {values?.type === "ps" && (
            <>
              <Box heading="Process Hazard">
                {Object.keys(processHazad).map((key: any) =>
                  renderLongRow(key, processHazad[key], "#FFF", 9, true)
                )}
              </Box>
              <Box heading="Permit">
                {Object.keys(permit).map((key: any) =>
                  renderLongRow(key, permit[key], "#FFF", 9, true)
                )}
              </Box>
              <Box heading="Training">
                {Object.keys(training).map((key: any) =>
                  renderLongRow(key, training[key], "#FFF", 9, true)
                )}
              </Box>
            </>
          )}
        </Col>
        <Col md="6" xs="12">
          {values?.type === "bc" && (
            <>
              <Box heading="PPE">
                {Object.keys(ppe).map((key: any) =>
                  renderLongRow(key, ppe[key], "#FFF", 9, true)
                )}
              </Box>
              <Box heading="Tools / Equipment">
                {Object.keys(tools).map((key: any) =>
                  renderLongRow(key, tools[key], "#FFF", 9, true)
                )}
              </Box>
              <Box heading="People">
                {Object.keys(people).map((key: any) =>
                  renderLongRow(key, people[key], "#FFF", 9, true)
                )}
              </Box>
              <Box heading="Procedure">
                {Object.keys(procedure).map((key: any) =>
                  renderLongRow(key, procedure[key], "#FFF", 9, true)
                )}
              </Box>
            </>
          )}
          {values?.type === "ps" && (
            <>
              <Box heading="Process Safety">
                {Object.keys(processSafety).map((key: any) =>
                  renderLongRow(key, processSafety[key], "#FFF", 9, true)
                )}
              </Box>
              <Box heading="Integrity">
                {Object.keys(integrity).map((key: any) =>
                  renderLongRow(key, integrity[key], "#FFF", 9, true)
                )}
              </Box>
            </>
          )}
        </Col>
        {history?.length > 0 && (
          <Col xs="12">
            <ViewHistory
              data={history}
              title="Approval History"
              permitColor="#FFD580"
            />
          </Col>
        )}
      </Row>
    </>
  );
}
