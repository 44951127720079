import { Button, Col, Input, Row, Spinner, Table } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'

import { makeAuthenticatedRequest } from '../../apiRequests'
import { pmAPI } from '../../components/serverurl'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { incrementby1 } from '../../components/Forms/JSAForm'
import SectionHeader from '../../components/Layout/SectionHeader'
import CustomLabel from '../../components/InputFields/CustomLabel'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { Link } from '../workManagement/PmTree'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { useNavigate } from 'react-router-dom'

const pmList: any = [
  {
    number: 10001,
    workDescription: 'IG System: Pressure Vacuum Valves 60M Overhaul',
    jobTobeDone: 'IG System: Pressure Vacuum Valves',
    actionCodeId: 'OVERHAUL',
    workType: 'PM',
    workTypeClass: 'Calender Based',
    orgCode: 'MECH',
    priorityId: 2,
    interval: 60,
    pmIntervalUnit: 'Month',
    maintOrg: 'MAINT',
    plannedHours: 25,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 46.08,
    equipmentDescription: 'Inert Gas System In Engine Room',
    jobPlanCode: 'CAOO5',
    tagType: 'IG-VLV',
    functionNumber: 13.56,
    functionDescription: 'IG System: Pressure Vacuum Valves 60M Overhaul',
    criticality: 'SECE',
    estimatedHours: 25
  },
  {
    number: 10002,
    workDescription:
      'UPS A (690V/230V) - Battery Renewal and Inspection-60M PM',
    jobTobeDone: 'UPS A (690V/230V) - Battery Renewal and Inspection',
    actionCodeId: 'SERVICE',
    workType: 'PM',
    workTypeClass: 'Calender Based',
    orgCode: 'MECH',
    priorityId: 3,
    interval: 60,
    pmIntervalUnit: 'Month',
    maintOrg: 'MAINT',
    plannedHours: 50,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 46.08,
    equipmentDescription: '690V / 230V UPS A',
    jobPlanCode: 'CAOO6',
    tagType: 'UPS-A',
    functionNumber: 13.78,
    functionDescription:
      'UPS A (690V/230V) - Battery Renewal and Inspection-60M PM',
    criticality: 'SECE',
    estimatedHours: 50
  },
  {
    number: 10003,
    workDescription: 'UPS B (690V/230V) - 60M Battery Renewal and Inspection',
    jobTobeDone: 'UPS B (690V/230V) - Battery Renewal and Inspection',
    actionCodeId: 'SERVICE',
    workType: 'PM',
    workTypeClass: 'Calender Based',
    orgCode: 'MECH',
    priorityId: 3,
    interval: 60,
    pmIntervalUnit: 'Month',
    maintOrg: 'MAINT',
    plannedHours: 50,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 46.08,
    equipmentDescription: '690V / 230V UPS B',
    jobPlanCode: 'CAOO6',
    tagType: 'UPS-B',
    functionNumber: 14,
    functionDescription:
      'UPS B (690V/230V) - 60M Battery Renewal and Inspection',
    criticality: 'SECE',
    estimatedHours: 50
  },
  {
    number: 10004,
    workDescription:
      'Liquid Fuel Flow Transmitter B, Offsite Calibration - 60M PM',
    jobTobeDone: 'Liquid Fuel Flow Transmitter B, Offsite Calibration',
    actionCodeId: 'SERVICE',
    workType: 'PM',
    workTypeClass: 'Calender Based',
    orgCode: 'MECH',
    priorityId: 3,
    interval: 60,
    pmIntervalUnit: 'Month',
    maintOrg: 'MAINT',
    plannedHours: 50,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 46.08,
    equipmentDescription:
      'Monitor Liquid Fuel Flow Transmitter for Turbine Generator B',
    jobPlanCode: 'CAOO5',
    tagType: 'FT',
    functionNumber: 14.22,
    functionDescription:
      'Liquid Fuel Flow Transmitter B, Offsite Calibration - 60M PM',
    criticality: 'HIGH',
    estimatedHours: 50
  },
  {
    number: 10005,
    workDescription:
      'Liquid Fuel Flow Transmitter C, Offsite Calibration - 60M PM',
    jobTobeDone: 'Liquid Fuel Flow Transmitter C, Offsite Calibration',
    actionCodeId: 'SERVICE',
    workType: 'PM',
    workTypeClass: 'Calender Based',
    orgCode: 'MECH',
    priorityId: 2,
    interval: 60,
    pmIntervalUnit: 'Month',
    maintOrg: 'MAINT',
    plannedHours: 50,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 46.08,
    equipmentDescription:
      'Monitor Liquid Fuel Flow Transmitter for Turbine Generator C',
    jobPlanCode: 'CAOO6',
    tagType: 'FT',
    functionNumber: 14.44,
    functionDescription:
      'Liquid Fuel Flow Transmitter C, Offsite Calibration - 60M PM',
    criticality: 'HIGH',
    estimatedHours: 50
  },
  {
    number: 10006,
    workDescription:
      'Swivel Torque Arm Load Cell Indicators Calibration and Function Test -12M PM',
    jobTobeDone:
      'Swivel Torque Arm Load Cell Indicators Calibration and Function Test',
    actionCodeId: 'INSP',
    workType: 'PM',
    workTypeClass: 'Calender Based',
    orgCode: 'MECH',
    priorityId: 3,
    interval: 12,
    pmIntervalUnit: 'Month',
    maintOrg: 'MAINT',
    plannedHours: 36,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 46.08,
    equipmentDescription: 'FPSO General System',
    jobPlanCode: 'CAOO6',
    tagType: 'GEN',
    functionNumber: 14.66,
    functionDescription:
      'Swivel Torque Arm Load Cell Indicators Calibration and Function Test -12M PM',
    criticality: 'HIGH',
    estimatedHours: 36
  },
  {
    number: 10007,
    workDescription:
      'Swivel Torque Arm Load Cell Indicators Calibration and Function Test -12M PM',
    jobTobeDone:
      'Swivel Torque Arm Load Cell Indicators Calibration and Function Test',
    actionCodeId: 'INSP',
    workType: 'PM',
    workTypeClass: 'Calender Based',
    orgCode: 'MECH',
    priorityId: 3,
    interval: 12,
    pmIntervalUnit: 'Month',
    maintOrg: 'MAINT',
    plannedHours: 36,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 46.08,
    equipmentDescription: 'FPSO General System',
    jobPlanCode: 'CA009',
    tagType: 'GEN',
    functionNumber: 14.88,
    functionDescription:
      'Swivel Torque Arm Load Cell Indicators Calibration and Function Test -12M PM',
    criticality: 'HIGH',
    estimatedHours: 36
  },
  {
    number: 10008,
    workDescription: 'Heli Refuelling System - 3M PM',
    jobTobeDone: 'Heli Refuelling System',
    actionCodeId: 'SERVICE',
    workType: 'PM',
    workTypeClass: 'Calender Based',
    orgCode: 'MECH',
    priorityId: 3,
    interval: 12,
    pmIntervalUnit: 'Month',
    maintOrg: 'MAINT',
    plannedHours: 50,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 46.08,
    equipmentDescription: 'Other Inspections (UTM, Diving etc)',
    jobPlanCode: 'CA009',
    tagType: 'UTM',
    functionNumber: 15.1,
    functionDescription: 'Heli Refuelling System - 3M PM',
    criticality: 'MEDIUM',
    estimatedHours: 50
  },
  {
    number: 10009,
    workDescription: 'Heli Refuelling System - 3M PM',
    jobTobeDone: 'Heli Refuelling System',
    actionCodeId: 'SERVICE',
    workType: 'PM',
    workTypeClass: 'Calender Based',
    orgCode: 'MECH',
    priorityId: 3,
    interval: 12,
    pmIntervalUnit: 'Month',
    maintOrg: 'MAINT',
    plannedHours: 50,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 46.08,
    equipmentDescription: 'Other Inspections (UTM, Diving etc)',
    jobPlanCode: 'CA009',
    tagType: 'UTM',
    functionNumber: 15.32,
    functionDescription: 'Heli Refuelling System - 3M PM',
    criticality: 'MEDIUM',
    estimatedHours: 50
  },
  {
    number: 10010,
    workDescription: '2000 hrs Maintenance on SOLAR Turbine',
    jobTobeDone: '2000 hrs Maintenance on SOLAR Turbine',
    actionCodeId: 'SERVICE',
    workType: 'PM',
    workTypeClass: 'Running hours based',
    orgCode: 'MECH',
    priorityId: 2,
    interval: 2000,
    pmIntervalUnit: 'Hour',
    maintOrg: 'MAINT',
    plannedHours: 200,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 46.08,
    equipmentDescription: 'SOLAR Turbine',
    jobPlanCode: 'CA009',
    tagType: 'SOLAR',
    functionNumber: 15.54,
    functionDescription: '2000 hrs Maintenance on SOLAR Turbine',
    criticality: 'HIGH',
    estimatedHours: 200
  },
  {
    number: 10011,
    workDescription: '4000 hrs Maintenance on SOLAR Turbine',
    jobTobeDone: '4000 hrs Maintenance on SOLAR Turbine',
    actionCodeId: 'SERVICE',
    workType: 'PM',
    workTypeClass: 'Running hours based',
    orgCode: 'MECH',
    priorityId: 2,
    interval: 4000,
    pmIntervalUnit: 'hrs',
    maintOrg: 'MAINT',
    plannedHours: 250,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 46.08,
    equipmentDescription: 'SOLAR Turbine',
    jobPlanCode: 'CAOO5',
    tagType: 'SOLAR',
    functionNumber: 15.76,
    functionDescription: '4000 hrs Maintenance on SOLAR Turbine',
    criticality: 'HIGH',
    estimatedHours: 250
  },
  {
    number: 10012,
    workDescription: '2000 hrs Maintenance on GE Power Turbine',
    jobTobeDone: '2000 hrs Maintenance on GE Power Turbine',
    actionCodeId: 'SERVICE',
    workType: 'PM',
    workTypeClass: 'Running hours based',
    orgCode: 'MECH',
    priorityId: 2,
    interval: 2000,
    pmIntervalUnit: 'hrs',
    maintOrg: 'MAINT',
    plannedHours: 300,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 46.08,
    equipmentDescription: 'GE Power Turbine',
    jobPlanCode: 'CA011',
    tagType: 'GE TUR',
    functionNumber: 15.98,
    functionDescription: '2000 hrs Maintenance on GE Power Turbine',
    criticality: 'HIGH',
    estimatedHours: 300
  },
  {
    number: 10013,
    workDescription: '4000 hrs Maintenance on GE Power Turbine',
    jobTobeDone: '4000 hrs Maintenance on GE Power Turbine',
    actionCodeId: 'SERVICE',
    workType: 'PM',
    workTypeClass: 'Running hours based',
    orgCode: 'MECH',
    priorityId: 2,
    interval: 4000,
    pmIntervalUnit: 'hrs',
    maintOrg: 'MAINT',
    plannedHours: 350,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 46.08,
    equipmentDescription: 'GE Power Turbine',
    jobPlanCode: 'CA010',
    tagType: 'GETUR',
    functionNumber: 15.98,
    functionDescription: '4000 hrs Maintenance on GE Power Turbine',
    criticality: 'HIGH',
    estimatedHours: 350
  },
  {
    number: 10014,
    workDescription: '1000 hrs Filter Change out for Air Compressor-0034',
    jobTobeDone: '1000 hrs Filter Change out for Air Compressor-0034',
    actionCodeId: 'SERVICE',
    workType: 'PM',
    workTypeClass: 'Running hours based',
    orgCode: 'MECH',
    priorityId: 2,
    interval: 1000,
    pmIntervalUnit: 'hrs',
    maintOrg: 'MAINT',
    plannedHours: 100,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 46.08,
    equipmentDescription: 46.08,
    jobPlanCode: 'CA010',
    tagType: 'COMP',
    functionNumber: 16.42,
    functionDescription: '1000 hrs Filter Change out for Air Compressor-0034',
    criticality: 'LOW',
    estimatedHours: 100
  },
  {
    number: 10015,
    workDescription: "Export Compressor 'A' Servicing - 12M PM",
    jobTobeDone: "Export Compressor 'A' Servicing",
    actionCodeId: 'SERVICE',
    workType: 'PM',
    workTypeClass: 'Calender Based',
    orgCode: 'MECH',
    priorityId: 2,
    interval: 12,
    pmIntervalUnit: 'Month',
    maintOrg: 'MECH',
    plannedHours: 1,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 46.08,
    equipmentDescription: 'Gas Export Compressor A',
    jobPlanCode: 'CA011',
    tagType: 'TT',
    functionNumber: 16.86,
    functionDescription: "Export Compressor 'A' Servicing - 12M PM",
    criticality: 'SECE',
    estimatedHours: 1
  },
  {
    number: 10016,
    workDescription:
      'Deluge Skids - Dry Deluge Water and Foam System Activation Test: 12M PM',
    jobTobeDone:
      'Deluge Skids - Dry Deluge Water and Foam System Activation Test',
    actionCodeId: 'TEST',
    workType: 'SCPM',
    workTypeClass: 'Calender Based',
    orgCode: 'OPS',
    priorityId: 2,
    interval: 12,
    pmIntervalUnit: 'Month',
    maintOrg: 'OPS',
    plannedHours: 28,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 46.08,
    equipmentDescription: 'Deluge Skid No.1, M14',
    jobPlanCode: 'CAOO5',
    tagType: 'TT',
    functionNumber: 17.3,
    functionDescription:
      'Deluge Skids - Dry Deluge Water and Foam System Activation Test: 12M PM',
    criticality: 'SECE',
    estimatedHours: 28
  },
  {
    number: 10017,
    workDescription: "Booster Compressor 'A' Servicing - 12M PM",
    jobTobeDone: "Booster Compressor 'A' Servicing",
    actionCodeId: 'SERVICE',
    workType: 'PM',
    workTypeClass: 'Calender Based',
    orgCode: 'MECH',
    priorityId: 2,
    interval: 12,
    pmIntervalUnit: 'Month',
    maintOrg: 'MECH',
    plannedHours: 1,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 46.08,
    equipmentDescription: 'Booster Compressor 1st Stage Train A',
    jobPlanCode: 'CAOO5',
    tagType: 'TT',
    functionNumber: 17.74,
    functionDescription: "Booster Compressor 'A' Servicing - 12M PM",
    criticality: 'SECE',
    estimatedHours: 1
  },
  {
    number: 10018,
    workDescription:
      'Accommodation Elevator: Inspection by Competent Person-6M PM',
    jobTobeDone: 'Accommodation Elevator: Inspection by Competent Person',
    actionCodeId: 'SERVICE',
    workType: 'SCPM',
    workTypeClass: 'Calender Based',
    orgCode: 'ELECT',
    priorityId: 2,
    interval: 6,
    pmIntervalUnit: 'Month',
    maintOrg: 'ELECT',
    plannedHours: 7,
    resources: 'Senior Electrical Technician',
    cost: 48.75,
    equipmentNumber: '73-MC-0001',
    equipmentDescription: 'Accommodation Elevator',
    jobPlanCode: 'CAOO6',
    tagType: 'TT',
    functionNumber: 18.18,
    functionDescription:
      'Accommodation Elevator: Inspection by Competent Person-6M PM',
    criticality: 'SECE',
    estimatedHours: 7
  },
  {
    number: 10019,
    workDescription: 'Lifeboat No.3 Examination -60M PM',
    jobTobeDone: 'Lifeboat No.3 Examination',
    actionCodeId: 'SERVICE',
    workType: 'SCPM',
    workTypeClass: 'Calender Based',
    orgCode: 'MARINE',
    priorityId: 3,
    interval: 60,
    pmIntervalUnit: 'Month',
    maintOrg: 'SAFETY',
    plannedHours: 8,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: '76-SA-0001B',
    equipmentDescription: 'Freefall Lifeboat No. 3 Stbd Aft',
    jobPlanCode: 'CAOO6',
    tagType: 'TT',
    functionNumber: 18.62,
    functionDescription: 'Lifeboat No.3 Examination -60M PM',
    criticality: 'SECE',
    estimatedHours: 8
  },
  {
    number: 10020,
    workDescription:
      'Immersion / Survival Suit Pressure Test and Inspection by Vendor: 36M PM',
    jobTobeDone:
      'Immersion / Survival Suit Pressure Test and Inspection by Vendor',
    actionCodeId: 'SERVICE',
    workType: 'SCPM',
    workTypeClass: 'Calender Based',
    orgCode: 'SAFETY',
    priorityId: 3,
    interval: 36,
    pmIntervalUnit: 'Month',
    maintOrg: 'SAFETY',
    plannedHours: 6,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: '76-SD-0002',
    equipmentDescription: 'Immersion Suit (Common Tag)',
    jobPlanCode: 'CAOO6',
    tagType: 'TT',
    functionNumber: 19.06,
    functionDescription:
      'Immersion / Survival Suit Pressure Test and Inspection by Vendor: 36M PM',
    criticality: 'SECE',
    estimatedHours: 6
  },
  {
    number: 10021,
    workDescription: 'Fixed Lifting Equipment : LOLER Inspection - 12M PM',
    jobTobeDone: 'Fixed Lifting Equipment : LOLER Inspection',
    actionCodeId: 'INSP',
    workType: 'SCPM',
    workTypeClass: 'Calender Based',
    orgCode: 'MECH',
    priorityId: 3,
    interval: 12,
    pmIntervalUnit: 'Month',
    maintOrg: 'MECH',
    plannedHours: 24,
    resources: 'Crane Operator',
    cost: 49.67,
    equipmentNumber: 73.04,
    equipmentDescription: 'Fixed Lifting Equipment',
    jobPlanCode: 'CAOO6',
    tagType: 'TT',
    functionNumber: 19.5,
    functionDescription: 'Fixed Lifting Equipment : LOLER Inspection - 12M PM',
    criticality: 'SECE',
    estimatedHours: 24
  },
  {
    number: 10022,
    workDescription: 'Inflatable Life Jackets Certification -12M PM',
    jobTobeDone: 'Inflatable Life Jackets Certification',
    actionCodeId: 'INSP',
    workType: 'SCPM',
    workTypeClass: 'Calender Based',
    orgCode: 'SAFETY',
    priorityId: 1,
    interval: 12,
    pmIntervalUnit: 'Month',
    maintOrg: 'SAFETY',
    plannedHours: 2,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: '76-SD-0044',
    equipmentDescription: 'Lifejacket-Inflatable (Common Tag)',
    jobPlanCode: 'CAOO6',
    tagType: 'TT',
    functionNumber: 19.94,
    functionDescription: 'Inflatable Life Jackets Certification -12M PM',
    criticality: 'SECE',
    estimatedHours: 2
  },
  {
    number: 10023,
    workDescription: 'PAGA system Noise Audit -36M PM',
    jobTobeDone: 'PAGA system Noise Audit',
    actionCodeId: 'SERVICE',
    workType: 'SCPM',
    workTypeClass: 'Calender Based',
    orgCode: 'INSTR',
    priorityId: 3,
    interval: 36,
    pmIntervalUnit: 'Month',
    maintOrg: 'INSTR',
    plannedHours: 8,
    resources: 'Instrument Technician',
    cost: 48,
    equipmentNumber: 86,
    equipmentDescription: 'Telecommunication',
    jobPlanCode: 'CAOO5',
    tagType: 'TT',
    functionNumber: 20.38,
    functionDescription: 'PAGA system Noise Audit -36M PM',
    criticality: 'SECE',
    estimatedHours: 8
  },
  {
    number: 10024,
    workDescription:
      'Vibration Monitoring of Pipework by a specialist vendor using the RDI Technologies -12M PM',
    jobTobeDone:
      'Vibration Monitoring of Pipework by a specialist vendor using the RDI Technologies',
    actionCodeId: 'SERVICE',
    workType: 'PM',
    workTypeClass: 'Calender Based',
    orgCode: 'MECH',
    priorityId: 2,
    interval: 12,
    pmIntervalUnit: 'Month',
    maintOrg: 'MECH',
    plannedHours: 12,
    resources: 'Maintenance Superintendent',
    cost: 110.58,
    equipmentNumber: 20,
    equipmentDescription: 'Separation and Stabilisation',
    jobPlanCode: 'CAOO6',
    tagType: 'TT',
    functionNumber: 20.82,
    functionDescription:
      'Vibration Monitoring of Pipework by a specialist vendor using the RDI Technologies -12M PM',
    criticality: 'SECE',
    estimatedHours: 12
  },
  {
    number: 10025,
    workDescription: 'Water Ballast Tank 4P (050-057): 72M Survey',
    jobTobeDone: 'Water Ballast Tank 4P (050-057)',
    actionCodeId: 'CLASS',
    workType: 'CLASS',
    workTypeClass: 'Calender Based',
    orgCode: 'MARINE',
    priorityId: 3,
    interval: 72,
    pmIntervalUnit: 'Month',
    maintOrg: 'MARINE',
    plannedHours: 24,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: '52-TB-S-WB-4P',
    equipmentDescription: 'Water Ballast Tank 4 Port',
    jobPlanCode: 'CAOO6',
    tagType: 'TT',
    functionNumber: 21.26,
    functionDescription: 'Water Ballast Tank 4P (050-057): 72M Survey',
    criticality: 'SECE',
    estimatedHours: 24
  },
  {
    number: 10026,
    workDescription: 'Cargo Oil Tank 2S (064-071): 72M Survey',
    jobTobeDone: 'Cargo Oil Tank 2S (064-071)',
    actionCodeId: 'CL_HS.SA',
    workType: 'CLASS',
    workTypeClass: 'Calender Based',
    orgCode: 'MARINE',
    priorityId: 3,
    interval: 72,
    pmIntervalUnit: 'Month',
    maintOrg: 'MARINE',
    plannedHours: 24,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: '21-TB-S-C0-2S',
    equipmentDescription: 'Cargo Oil Tank 2 STBD',
    jobPlanCode: 'CAOO6',
    tagType: 'TT',
    functionNumber: 21.7,
    functionDescription: 'Cargo Oil Tank 2S (064-071): 72M Survey',
    criticality: 'SECE',
    estimatedHours: 24
  },
  {
    number: 10027,
    workDescription: 'Cargo Oil Tank 3C (057-064): 72M Survey',
    jobTobeDone: 'Cargo Oil Tank 3C (057-064):',
    actionCodeId: 'CL_HS.SA',
    workType: 'CLASS',
    workTypeClass: 'Calender Based',
    orgCode: 'MARINE',
    priorityId: 3,
    interval: 72,
    pmIntervalUnit: 'Month',
    maintOrg: 'MARINE',
    plannedHours: 24,
    resources: 'Production Superintendent',
    cost: 107.67,
    equipmentNumber: '21-TB-S-C0-3C',
    equipmentDescription: 'Cargo Oil Tank 3 Center',
    jobPlanCode: 'CAOO5',
    tagType: 'TT',
    functionNumber: 22.14,
    functionDescription: 'Cargo Oil Tank 3C (057-064): 72M Survey',
    criticality: 'SECE',
    estimatedHours: 24
  },
  {
    number: 10028,
    workDescription: 'Cargo Oil Tank 3P (057-064): 144M Survey',
    jobTobeDone: 'Cargo Oil Tank 3P (057-064): 144M Survey',
    actionCodeId: 'CL_HS.SA',
    workType: 'CLASS',
    workTypeClass: 'Calender Based',
    orgCode: 'MARINE',
    priorityId: 3,
    interval: 144,
    pmIntervalUnit: 'Month',
    maintOrg: 'MARINE',
    plannedHours: 24,
    resources: 'Production Superintendent',
    cost: 107.67,
    equipmentNumber: '21-TB-S-C0-3P',
    equipmentDescription: 'Cargo Oil Tank 3 Port',
    jobPlanCode: 'CAOO6',
    tagType: 'TT',
    functionNumber: 22.58,
    functionDescription: 'Cargo Oil Tank 3P (057-064): 144M Survey',
    criticality: 'SECE',
    estimatedHours: 24
  },
  {
    number: 10029,
    workDescription:
      'Flow transmitter LP/HP Fuel Gas Supply Line to PW, Offsite Calibration - 60M PM',
    jobTobeDone:
      'Flow transmitter LP/HP Fuel Gas Supply Line to PW, Offsite Calibration - 60M PM',
    actionCodeId: 'SERVICE',
    workType: 'PM',
    workTypeClass: 'Calender Based',
    orgCode: 'METERING',
    priorityId: 1,
    interval: 60,
    pmIntervalUnit: 'Month',
    maintOrg: 'METERING',
    plannedHours: 4,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: '45-FIT-6782',
    equipmentDescription: 'Flow transmitter LP/HP Fuel Gas Supply to PW',
    jobPlanCode: 'CAOO5',
    tagType: 'TT',
    functionNumber: 23.02,
    functionDescription:
      'Flow transmitter LP/HP Fuel Gas Supply Line to PW, Offsite Calibration - 60M PM',
    criticality: 'SECE',
    estimatedHours: 4
  },
  {
    number: 10030,
    workDescription: 'Heli Refuelling System - 12M PM',
    jobTobeDone: 'Heli Refuelling System - 12M PM',
    actionCodeId: 'INSP',
    workType: 'SCPM',
    workTypeClass: 'Calender Based',
    orgCode: 'MECH',
    priorityId: 2,
    interval: 12,
    pmIntervalUnit: 'Month',
    maintOrg: 'MECH',
    plannedHours: 4,
    resources: 'Senior Electrical Technician',
    cost: 48.75,
    equipmentNumber: 61.01,
    equipmentDescription: 'Heli Refuelling System (61-XX-0001/61-XX-0002)',
    jobPlanCode: 'CAOO6',
    tagType: 'TT',
    functionNumber: 23.46,
    functionDescription: 'Heli Refuelling System - 12M PM',
    criticality: 'SECE',
    estimatedHours: 4
  },
  {
    number: 10031,
    workDescription: 'CSE Inspection of Voids and Cofferdams - 12M Survey',
    jobTobeDone: 'CSE Inspection of Voids and Cofferdams - 12M Survey',
    actionCodeId: 'CL_HS.SA',
    workType: 'CLASS',
    workTypeClass: 'Calender Based',
    orgCode: 'MARINE',
    priorityId: 3,
    interval: 12,
    pmIntervalUnit: 'Month',
    maintOrg: 'MARINE',
    plannedHours: 24,
    resources: 'Mechanical Technician',
    cost: 46.08,
    equipmentNumber: 91.01,
    equipmentDescription: 'Substructure Void Space',
    jobPlanCode: 'CAOO5',
    tagType: 'TT',
    functionNumber: 23.9,
    functionDescription: 'CSE Inspection of Voids and Cofferdams - 12M Survey',
    criticality: 'SECE',
    estimatedHours: 24
  },
  {
    number: 10032,
    workDescription:
      "HV Electric Motor for HPU 'B' (Cargo / Ballast Pumps) Pump 3D Inspection: 36M PM",
    jobTobeDone:
      "HV Electric Motor for HPU 'B' (Cargo / Ballast Pumps) Pump 3D Inspection: 36M PM",
    actionCodeId: 'INSP',
    workType: 'PM',
    workTypeClass: 'Calender Based',
    orgCode: 'ELECT',
    priorityId: 2,
    interval: 36,
    pmIntervalUnit: 'Month',
    maintOrg: 'ELECT',
    plannedHours: 1,
    resources: 'Electrical Technician',
    cost: 40.17,
    equipmentNumber: '65-PB-0003D-DE',
    equipmentDescription: "HPU 'B' ( 65-CT-0001B ) Pump 3D Motor M1",
    jobPlanCode: 'CAOO6',
    tagType: 'TT',
    functionNumber: 24.34,
    functionDescription:
      "HV Electric Motor for HPU 'B' (Cargo / Ballast Pumps) Pump 3D Inspection: 36M PM",
    criticality: 'SECE',
    estimatedHours: 1
  }
]

type pm = {
  _id: String
  workDescription: string
  number: number
  description: string
  actionCodeId: string
  workType: string
  orgCode: string
  priorityId: number
  interval: number | any
  pmIntervalUnit: string
  maintOrg: string
  plannedHours: number
  resources: string
  cost: string | any
  equipmentNumber: string | any
  equipmentDescription: string
  jobPlanCode: string
  jobPlan: string
  nextDueDate: Date
  triggerDate: Date
  workTypeClass: String
  currentRunningValue: String
  counterLimit: String
  hasActiveWO: String
}

const PmTable = ({
  data,
  setType,
  isLoading,
  isRefetching,
  setPriority,
  setSelectedPM,
  setEditTrigger,
  setWorkClass
}: {
  data: [pm]
  setType: any
  isLoading: boolean
  isRefetching: boolean
  setPriority: any
  setSelectedPM: any
  setEditTrigger: any
  setWorkClass?: any
}) => {
  const navigate = useNavigate()

  return (
    <Row>
      {/* <Row>
        <Col xs="2">
          <CustomLabel label="Work Type" />
          <Input type="select" onChange={(e) => setType(e?.target?.value)}>
            <option value="">Select</option>
            <option>PM</option>
            <option>SCPM</option>
            <option>CLASS</option>
          </Input>
        </Col>
        <Col xs="2">
          <CustomLabel label="Priority" />
          <Input type="select" onChange={(e) => setPriority(e?.target?.value)}>
            <option value="">Select</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
          </Input>
        </Col>
        {setWorkClass && (
          <Col xs="2">
            <CustomLabel label="Work Type Class" />
            <Input
              type="select"
              onChange={(e) => setWorkClass(e?.target?.value)}
            >
              <option value="">Select</option>
              <option>Calender Based</option>
              <option>Running hours based</option>
            </Input>
          </Col>
        )}
      </Row> */}
      <Table>
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Number</th>
            <th>Description</th>
            <th>Equipment</th>
            <th>Job Plan Code</th>
            <th>Work Type</th>
            <th>Type</th>
            <th>Priority</th>
            <th>Planned Hours</th>
            <th>Interval</th>
            <th>Interval Type</th>
            <th>Counter Limit</th>
            <th>Current Value</th>
            <th>Has Active WO</th>
            <th>Update Counters</th>
          </tr>
        </thead>
        <tbody>
          {isLoading || isRefetching ? (
            <tr>
              <td colSpan={12}>
                <Row
                  style={{
                    height: 500,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Spinner />
                </Row>
              </td>
            </tr>
          ) : (
            data?.map((pm: pm, index: number) => (
              <tr key={`${index}-${pm?.number}`}>
                <td>{incrementby1(index)}</td>
                <td>
                  <Link
                    onClick={() =>
                      navigate(
                        `/cmms/planning/pm/updateRunningHours/${pm?._id}`
                      )
                    }
                  >
                    {pm?.number}
                  </Link>
                </td>
                <td>{pm?.workDescription}</td>
                <td>{pm?.equipmentNumber}</td>
                <td>{pm?.jobPlanCode}</td>
                <td>{pm?.workType}</td>
                <td>{pm?.workTypeClass}</td>
                <td>{pm?.priorityId}</td>
                <td>{pm?.plannedHours}</td>
                <td>{pm?.interval}</td>
                <td>{pm?.pmIntervalUnit}</td>
                <td>{pm?.counterLimit}</td>
                <td>{pm?.currentRunningValue}</td>
                <td>{pm?.hasActiveWO}</td>
                <td>
                  <Link
                    onClick={() => {
                      setSelectedPM(pm)
                      setEditTrigger(true)
                    }}
                  >
                    Update Counter
                  </Link>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {Number(data?.length) === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Preventive Maintenance Found
          </p>
        </Row>
      )}
    </Row>
  )
}

export default function RunningHoursBased() {
  const { accounts, instance } = useMsal()

  const [type, setType] = useState()
  const [priority, setPriority] = useState()
  const [updateCounter, setUpdateCounter] = useState(false)
  const [selectedPM, setSelectedPM]: any = useState()

  const fetchPmList = async () => {
    const response = await makeAuthenticatedRequest(
      `${pmAPI}?workType=${type}&priority=${priority}&classType=Running hours based`,
      'GET',
      pmList,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch, isLoading, isRefetching }: any = useQuery(
    'pmListCounters',
    fetchPmList
  )
  const pm = data?.pmList

  useEffect(() => {
    refetch()
  }, [type, priority])

  return (
    <ProtectedLayout onBack="/cmms/planning/pm" title={'UPDATE COUNTERS'}>
      <Row style={{ margin: 10 }}>
        <PmTable
          data={pm}
          setType={setType}
          isLoading={isLoading}
          isRefetching={isRefetching}
          setPriority={setPriority}
          setSelectedPM={setSelectedPM}
          setEditTrigger={setUpdateCounter}
        />
      </Row>
      <SubmitModalWithForm
        isOpen={updateCounter}
        onClose={(val: any) => {
          setUpdateCounter(false)
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()
          const res = await makeAuthenticatedRequest(
            `${pmAPI}/${selectedPM?._id}`,
            'PATCH',
            selectedPM,
            accounts,
            instance
          )

          if (res?.status === 'success') {
            refetch()
          }

          return res?.status
        }}
        size="xs"
        header="Update Counter"
        form={
          <Row>
            <Col>
              <CustomLabel label="Update Latest Running hours" />
              <Input
                type="text"
                onChange={(e: any) =>
                  setSelectedPM({
                    ...selectedPM,
                    currentRunningValue: e?.target?.value
                  })
                }
              />
            </Col>
          </Row>
        }
      />
    </ProtectedLayout>
  )
}
