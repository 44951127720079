import { useState } from 'react'
import { useForm } from 'react-final-form'
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { LOW_RISK_COLOR } from '../constants/riskMatrixColors'
import { getRiskLevel, getRiskMatrixColors } from '../lib/getRiskLevel'
import ModalTitle from '../ModalTitle'
import { Text } from '../RiskAssesmentPreview'

export default function SeverityRiskMatrix({ values, name, equipment }: any) {
  const [isOpen, setIsOpen] = useState(false)
  const { change } = useForm()

  const toggle = () => setIsOpen(!isOpen)

  const risklevel = getRiskLevel(equipment)
  const riskMatrixColors = getRiskMatrixColors(risklevel)

  const renderDetails = (heading?: string, content?: string) => (
    <>
      <Text styles={{ textOVerflow: 'hidden' }}>{heading}</Text>
      {content && <Text>{content}</Text>}
    </>
  )

  const tableHeaders = [
    { title: ' ' },
    { title: 'Factor' },
    {
      title: '1',
      content: 'Unlikely'
    },
    {
      title: '2',
      content: 'Rare'
    },
    {
      title: '3',
      content: 'Likely'
    },
    {
      title: '4',
      content: 'Very Likely'
    },
    {
      title: '5',
      content: 'Certainly'
    }
  ]

  const riskMatrixLevel1 = [
    {
      financial: '$ 100 M',
      'health&safety': '2-20 Fatalities or permanent Total Disabilites',
      environment: 'Large Scale Event,Long term Impact on Environment',
      reputation: 'Multi-NGO and media condemnation. Prolonged large Protest',
      'cultural&socailHeritage':
        'Widespread disruption to a number of communities',
      legal: 'Fatal accident Major accident resulting in Fatality',
      factor: ['5', 'Catastropic'],
      1: { text: '5', value: 'S5/P1' },
      2: { text: '10', value: 'S5/P2' },
      3: { text: '15', value: 'S5/P3' },
      4: { text: '20', value: 'S5/P4' },
      5: { text: '25', value: 'S5/P5' }
    },
    {
      financial: '$10M-100M',
      'health&safety': 'Single Fatality of Permanent Total Disability',
      environment:
        'Medium to large scale event,medium term imoact to environment',
      reputation:
        'Serious Publicor national Media outcry.Damaging NGO Campaign',
      'cultural&socailHeritage': 'Significat impact to regional communities',
      legal:
        'Permanent disabling Injury, Injury requiring hospitalization and person can return back to work within 8 weeks',
      factor: ['4', 'Critical'],
      1: { text: '4', value: 'S4/P1' },
      2: { text: '8', value: 'S4/P2' },
      3: { text: '12', value: 'S4/P3' },
      4: { text: '16', value: 'S4/P4' },
      5: { text: '20', value: 'S4/P5' }
    },
    {
      financial: '$1M-10M',
      'health&safety': 'Major Injury or Illness, permanent partial disability',
      environment:
        'Local to Medium scale event with short to medium term impact on environment',
      reputation:
        'Major adverse National media,Public or NGO attention.Significant Protest',
      'cultural&socailHeritage':
        'Regional community disruption with moderate impact on heritage',
      legal:
        'Temporary disabling Injury. Injury requiring hospitalization and person can return back to work within 2 days to 1 weeks',
      factor: ['3', 'Serious'],
      1: { text: '3', value: 'S3/P1' },
      2: { text: '6', value: 'S3/P2' },
      3: { text: '9', value: 'S3/P3' },
      4: { text: '12', value: 'S3/P4' },
      5: { text: '15', value: 'S3/P5' },
      6: { text: '18', value: 'S3/P6' }
    },
    {
      financial: '$100K-$1M',
      'health&safety': 'Minor Injury or Illness, Restricted Work Case',
      environment:
        'Local scale event with short term impact on the environment',
      reputation: 'Attention from regional Media with highlighted',
      'cultural&socailHeritage':
        'Isolated Community disruption with limited adverse impact on heritage',
      legal:
        'Minor medical treatment, injury requiring medical attention and person can return back to work within 24 hours',
      factor: ['2', 'Marginal'],
      1: { text: '2', value: 'S2/P1' },
      2: { text: '4', value: 'S2/P2' },
      3: { text: '6', value: 'S2/P3' },
      4: { text: '8', value: 'S2/P4' },
      5: { text: '10', value: 'S2/P5' }
    },
    {
      financial: '<100K',
      'health&safety':
        'Slight Injury or Illness,First Air or Medical Treatment Case',
      environment: 'Local scale event with temperory impact on environment',
      reputation:
        'Short term local concern or compliants.Low level media or regulatory issues',
      'cultural&socailHeritage':
        'Minor impact on heritage,aesthetic,economic or recreational values',
      legal:
        'First aid injury-such as small cuts / injury requiring first aid and person can return back to work immediately',
      factor: ['1', 'Negligible'],
      1: { text: '1', value: 'S1/P1' },
      2: { text: '2', value: 'S1/P2' },
      3: { text: '3', value: 'S1/P3' },
      4: { text: '4', value: 'S1/P4' },
      5: { text: '5', value: 'S1/P5' }
    }
  ]

  return (
    <>
      {
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 60,
            backgroundColor: Boolean(values)
              ? riskMatrixColors[values]
              : 'gray',
            cursor: 'pointer',
            color: riskMatrixColors[values] === LOW_RISK_COLOR ? '#000' : '#FFF'
          }}
          onClick={() => toggle()}
        >
          {Boolean(values) ? values : 'Set Risk'}
        </Col>
      }
      <Modal isOpen={isOpen} toggle={toggle} fullscreen>
        <ModalBody>
          <ModalTitle title="QUALITATIVE RISK ASSESSMENT MATRIX (SERVERTIY / PROBABILITY)" />
          <Row>
            <Col xs="1" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <p
                style={{
                  writingMode: 'vertical-rl',
                  fontSize: 20,
                  padding: 20,
                  textOrientation: 'upright',
                  textAlign: 'center',
                  border: '1px solid #000',
                  marginLeft: 0,
                  fontWeight: 700
                }}
              >
                SEVERITY
              </p>
            </Col>
            <Col xs="11">
              <Table bordered style={{ borderColor: '#000' }} responsive>
                <thead>
                  <tr>
                    <th colSpan={2}></th>
                    <th
                      colSpan={5}
                      style={{ textAlign: 'center', fontSize: 20 }}
                    >
                      PROBABILITY
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={2}></td>
                    <td
                      style={{
                        fontSize: 12,
                        width: '10%',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {' '}
                      More than 12 months
                    </td>
                    <td
                      style={{
                        fontSize: 12,
                        width: '10%',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      Once in a month to 6 months
                    </td>
                    <td
                      style={{
                        fontSize: 12,
                        width: '10%',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      once in a week to month
                    </td>
                    <td
                      style={{
                        fontSize: 12,
                        width: '10%',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      Once in day to week
                    </td>
                    <td
                      style={{
                        fontSize: 12,
                        width: '10%',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      Multiples times a day for continous
                    </td>
                  </tr>
                  <tr>
                    {tableHeaders?.map((header: any) => (
                      <th
                        style={{
                          fontSize: 12,
                          // width: '5%',
                          textAlign: 'center',
                          verticalAlign: 'middle',
                          fontWeight: header.title === 'Factor' ? 900 : 700
                        }}
                      >
                        {renderDetails(header.title, header.content)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {riskMatrixLevel1?.map((item: any) => (
                    <>
                      <tr>
                        {[item?.legal]?.map((i: any) => (
                          <td
                            style={{
                              fontSize: 16,
                              textAlign: 'justify',
                              width: '35%'
                            }}
                          >
                            <div style={{ height: 45 }}>{renderDetails(i)}</div>
                          </td>
                        ))}
                        <td
                          style={{
                            fontSize: 14,
                            textAlign: 'center',
                            fontWeight: 900,
                            width: '5%'
                          }}
                        >
                          <div style={{ height: 55, overflow: 'scroll' }}>
                            {renderDetails(item?.factor[0])}
                            {renderDetails(item?.factor[1])}
                          </div>
                        </td>
                        {[item[1], item[2], item[3], item[4], item[5]]?.map(
                          ({ text, value, color }) => (
                            <td
                              onClick={() => {
                                change(name, value)
                                toggle()
                              }}
                              style={{
                                backgroundColor: riskMatrixColors[value],
                                color:
                                  riskMatrixColors[value] === LOW_RISK_COLOR
                                    ? '#000'
                                    : '#FFF',
                                textAlign: 'center',
                                verticalAlign: 'middle',
                                height: '10%',
                                fontWeight: 700,
                                cursor: 'pointer',
                                fontSize: 24
                              }}
                            >
                              {text}
                            </td>
                          )
                        )}
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={toggle}>
            Close
          </Button>
          <Button color="success" onClick={toggle}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
