import { Row } from 'reactstrap'
import moment from 'moment'
import React from 'react'

import PrintPermitHeader from '../prints/PrintPermitHeader'
import PrintPositiveIsolationTalk from '../prints/PrintPositiveIsolationTalk'
import Spacer from '../Spacer'
import { ColoredSubHeader } from './ViewExcavationPermit'
import { PrintHeaderLogos } from '../prints/PrintHeaderLogos'

type ViewExcavation = {
  showPrintHeader?: any
  data: any
  rowStyle: any
  renderRow: any
  permitData: any
}

export default function ViewPositiveIsolation({
  showPrintHeader,
  data,
  rowStyle,
  renderRow,
  permitData
}: ViewExcavation) {
  const fields: any = {
    Purpose: data?.purpose,
    'Install Date': moment(data?.installDate).format('DD-MM-YYYY'),
    'Remove Date': moment(data?.removeDate).format('DD-MM-YYYY')
  }

  const header: any = {
    'Positive Isolation Number': data?.permitNumber,
    'Permit Number': permitData?.woNumber,
    'Permit Title': permitData?.permitTitle
  }

  return (
    <>
      {showPrintHeader && (
        <>
          <PrintHeaderLogos permitType={permitData?.type} />
          <PrintPermitHeader permitData={permitData} printData={permitData} />
        </>
      )}
      <ColoredSubHeader rowStyle={rowStyle} text="Positive Isolation Permit" />
      <Row
        style={{
          ...rowStyle,
          fontSize: 14,
          margin: 0
        }}
      >
        {data?.permitNumber &&
          Object.keys(header).map((key) => renderRow(key, header[key]))}
      </Row>
      <Spacer height={10} />
      <Row>{Object.keys(fields).map((key) => renderRow(key, fields[key]))}</Row>
      <Spacer height={10} />
      {permitData?.status === 'live' && (
        <Row
          style={{
            margin: 0,
            // backgroundColor: rowStyle?.backgroundColor,
            WebkitPrintColorAdjust: 'exact'
          }}
        >
          <PrintPositiveIsolationTalk />
        </Row>
      )}
    </>
  )
}
