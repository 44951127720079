import { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'

import NewPermitForm from '../components/Forms/NewPermitForm'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import theme from '../components/constants/defaultTheme.json'
import { ServiceCard } from './Services'

export function EmptyContent({ label }: any) {
  return (
    <Container>
      <Row>
        <Col xs="12" style={{ width: '100%', height: '100%' }}>
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              height: '50vh',
              fontWeight: 700
            }}
          >
            {label}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default function CreateNewPermit({ ...props }) {
  const [permitType, setPermitType] = useState('none')
  const [color, setColor] = useState(theme.colors.white)
  const [selectedPermit, setSelectedPermit]: any = useState({})

  const createFromLTI: any = localStorage.getItem('createFromLTI')
  const ltiData = JSON.parse(createFromLTI)

  const onChange = () => {}

  const getColor = (value: any) => {
    if (value === 'hotWork' || value === 0) {
      setColor(theme.workPermit.hotWork)
      setPermitType('hotWork')
      return theme.workPermit.hotWork
    } else if (value === 'coldWork' || value === 1) {
      setColor(theme.workPermit.coldWork)
      setPermitType('coldWork')
      return theme.workPermit.coldWork
    } else {
      setColor(theme.colors.white)
      setPermitType('none')
      return theme.colors.blue
    }
  }

  const buttonStyles: any = {
    marginRight: 20,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
    width: 150,
    cursor: 'pointer'
  }

  const PermitButton = ({ styles, type, img, label }: any) => (
    <>
      <div
        style={{
          border: '3px solid #FFF',
          padding: 0,
          height: 105,
          width: 156,
          marginRight: 20,
          borderRadius: 5,
          boxShadow:
            'rgba(0, 0, 0, 0.4) 0px 1px 2px, rgba(0, 0, 0, 0.3) 0px 2px 3px -3px, rgba(0, 0, 0, 0.2) 0px -2px 0px inset'
        }}
      >
        <div
          style={styles}
          onClick={() => {
            getColor(type)
            setSelectedPermit({})
          }}
        >
          <img
            src={img}
            alt="cieptw Logo"
            height={60}
            style={{ alignSelf: 'right' }}
          />
          <p
            style={{
              margin: 0,
              fontSize: 14,
              fontFamily: 'Copper Plate',
              fontWeight: 600
            }}
          >
            {label}
          </p>
        </div>
      </div>
    </>
  )

  return (
    <ProtectedLayout onBack="/eptw/dashboard">
      <div
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: color,
          width: '100%'
        }}
      >
        {permitType !== 'none' && (
          <Row>
            <Col style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <PermitButton
                type="hotWork"
                img={require('./../images/Hot_Work_Emb.png')}
                label="HOT WORK"
                styles={{
                  ...buttonStyles,
                  border: `5px solid ${theme.workPermit.hotWorkButton}`,
                  color: theme.workPermit.hotWorkButton,
                  backgroundColor: theme.workPermit.hotWork
                }}
              />
            </Col>
            <Col>
              <PermitButton
                type="coldWork"
                img={require('./../images/Cold_Work_Emb.png')}
                label="COLD WORK"
                styles={{
                  ...buttonStyles,
                  border: `5px solid ${theme.workPermit.coldWorkButton}`,
                  backgroundColor: theme.workPermit.coldWork,
                  color: theme.workPermit.coldWorkButton
                }}
              />
            </Col>
          </Row>
        )}
        {permitType === 'none' ? (
          <div
            style={{
              paddingTop: 30,
              minHeight: '80vh',
              backgroundColor: theme.colors.lightBlue1
            }}
          >
            <Row>
              <Col xs="2" />
              <Col>
                <ServiceCard
                  service={{
                    title: 'HOT WORK',
                    img: require('./../images/Hot_Work_Emb.png')
                  }}
                  onClick={() => getColor('hotWork')}
                />
              </Col>
              <Col style={{ marginRight: 30 }}>
                <ServiceCard
                  service={{
                    title: 'COLD WORK',
                    img: require('./../images/Cold_Work_Emb.png')
                  }}
                  onClick={() => getColor('coldWork')}
                />
              </Col>
              <Col xs="2" />
              {/* <EmptyContent label="Please Select permit Type" /> */}
            </Row>
          </div>
        ) : (
          <NewPermitForm
            type={permitType}
            selectedForm={selectedPermit}
            onChange={() => onChange}
            initialValues={ltiData}
          />
        )}
      </div>
    </ProtectedLayout>
  )
}
