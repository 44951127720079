import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Table } from "reactstrap";
import { makeAuthenticatedRequest } from "../apiRequests";
import { incrementby1 } from "../components/Forms/JSAForm";
import ProtectedLayout from "../components/Layout/ProtectedLayout";
import Loader from "../components/Loader";
import { tagsAPI } from "../components/serverurl";
import Spacer from "../components/Spacer";
import { EmptyContent } from "./CreateNewPermit";

export default function TagNumbers() {
  const [pageNumber, setPageNumber]: any = useState(1)
  const [data, setData]: any = useState([])
  const { accounts, instance } = useMsal()
  const [loading, setLoading] = useState(false)

  const fetchTags = async () => await makeAuthenticatedRequest(
    `${tagsAPI}?page=${pageNumber}`,
    'GET',
    null,
    accounts,
    instance
  ).then((res: any) => {
    setData(res)
    setLoading(false)
  }).catch((err: any) => console.info(err))

  useEffect(() => {
    fetchTags()
  }, [pageNumber])

  return (
    <>
      <ProtectedLayout onBack="/cmms/dashboard">
        <Spacer height={20} />
        <Table striped bordered style={{ borderColor: '#000', fontSize: 14 }}>
          <thead>
            <tr style={{ backgroundColor: 'orange' }}>
              {['S.No ', 'Tag Number', 'Tag Description', 'Type', 'PID Number', 'Attached To Equipment']?.map((header) => <th key={header}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {
              loading && <tr><td colSpan={6}><EmptyContent label={<Loader />} /></td></tr>
            }
            {!loading && data?.paginatedTags?.map((tag: any, index: number) => <React.Fragment key={`${tag.tagNumber}-${index}`}>
              <tr>
                <td>{incrementby1(index)}</td>
                <td>{tag?.tagNumber}</td>
                <td>{tag?.tagDescription}</td>
                <td>{tag?.type}</td>
                <td>{tag?.pidNumber}</td>
                <td>{tag?.equipmentAttached}</td>
              </tr>
            </React.Fragment>)}
          </tbody>
        </Table>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={data?.totalCount / 20}
          marginPagesDisplayed={10}
          pageRangeDisplayed={2}
          onPageChange={(values) => {
            setLoading(true)
            setPageNumber(values.selected)
          }}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </ProtectedLayout>
    </>
  )
}