export const sparesList = [
  {
    vessel: 'UNIT-1',
    department: 'INST',
    'Tag Number': '13-EV-1221',
    'Equipment Description': 'Main - Function Prod well 2 (CP2)',
    'Spare Part Number': '16-2026-00206',
    'Spare Part Description': 'AIR END KIT,PN:476-253-559',
    spareType: 'CONSUMABLE',
    serialNumber: '73-0986-F-112',
    uom: 'EA',
    model: 'E-098-KIP-234',
    manufacturer: 'EMERSON',
    manufacturerPartNumber: 'E73-0098',
    certificateNeeded: 'YES',
    consumable: 'YES',
    supplier: 'ABC Pvt Ltd',
    supplierContact: '+47897354677',
    originalPO: 'PO-E73-9359',
    unitOneVeendorId: 675,
    equipmentLineNumber: 248,
    status: 'Operation',
    functionNumber: 16,
    functionDescription: 'Main - Function Prod well 2 (CP2)',
    estEquipType: 'PSV',
    attachments: 'YES',
    weightUnit: 'KG',
    weight: 149,
    isGeneric: 'YES',
    account: 'UNIT-1MAINT',
    costCenter: 16,
    projectId: 16,
    priceCurrency: 'USD',
    priceIncludingTax: 103,
    originalQuantity: 44,
    currencyCode: 'USD',
    buyUnit: 32,
    safetyCode: 'LS',
    accountGroup: 'KOS',
    productCode: 16,
    productFamily: 16,
    partFrequency: 'H',
    assetClass: 'MEDIUM',
    netWeight: 149,
    netVolume: 149,
    quantity: 149,
    locationNumber: '730A',
    locationType: 'AC',
    locationGroup: 'NEY',
    Warehouse: 'AC-STORE',
    bay: '7E',
    row: '99-G',
    tier: 'L16',
    bin: '3A',
    onHandQuantity: 30,
    created: '02/26/2022',
    modified: '07/26/2022',
    planningMethod: 'C',
    safetyStock: 3,
    orderPoint: 2,
    lotSize: 4,
    orderCoverTime: 1,
    minLotSize: 2,
    maxLotSize: 2,
    multipleLotSize: 2,
    scrapFactor: 10,
    stdLotSize: 2,
    proposalRelease: 'Approved',
    defaultSupplyType: 'Cretaed',
    partType: 'Spare',
    operativeValue: 7,
    operativeValueSource: 20,
    additionalTechInformation: 'Check Manual',
    notes: 'Need to Install New Item as per SOP',
    inventoryType: 'Spare',
    taxable: 'YES',
    qualityApprovalDate: '02/26/2022',
    qCAnalystDate: '02/26/2022',
    qCAnalysisID: '02/26/2022',
    dimQuantity: 1,
    buyerID: 'E7-948',
    techCoordinates: 'SING',
    buyerName: 'E73 AI Innovations',
    remarks: 'Spares to be ordered '
  },
  {
    vessel: 'UNIT-1',
    department: 'INST',
    'Tag Number': '13-EY-1221',
    'Equipment Description': 'Main - Function Prod well 2 (CP2)',
    'Spare Part Number': '16-2113-00101',
    'Spare Part Description': 'KIT,AIR END,PN:476-239-000',
    spareType: 'CONSUMABLE',
    serialNumber: '73-0986-F-113',
    uom: 'EA',
    model: 'E-098-KIP-234',
    manufacturer: 'EMERSON',
    manufacturerPartNumber: 'E73-0098',
    certificateNeeded: 'YES',
    consumable: 'YES',
    supplier: 'ABC Pvt Ltd',
    supplierContact: '+47897354677',
    originalPO: 'PO-E73-9360',
    unitOneVeendorId: 675,
    equipmentLineNumber: 249,
    status: 'Operation',
    functionNumber: 16,
    functionDescription: 'Main - Function Prod well 2 (CP2)',
    estEquipType: 'PSV',
    attachments: 'YES',
    weightUnit: 'LBS',
    weight: 158,
    isGeneric: 'YES',
    account: 'UNIT-1MAINT',
    costCenter: 16,
    projectId: 16,
    priceCurrency: 'USD',
    priceIncludingTax: 105,
    originalQuantity: 47,
    currencyCode: 'USD',
    buyUnit: 34,
    safetyCode: 'LS',
    accountGroup: 'KOS',
    productCode: 16,
    productFamily: 16,
    partFrequency: 'M',
    assetClass: 'MEDIUM',
    netWeight: 158,
    netVolume: 158,
    quantity: 158,
    locationNumber: '730A',
    locationType: 'AC',
    locationGroup: 'NEY',
    Warehouse: 'AC-STORE',
    bay: '7E',
    row: '99-G',
    tier: 'L17',
    bin: '3A',
    onHandQuantity: 32,
    created: '02/27/2022',
    modified: '07/27/2022',
    planningMethod: 'D',
    safetyStock: 3,
    orderPoint: 2,
    lotSize: 4,
    orderCoverTime: 1,
    minLotSize: 2,
    maxLotSize: 2,
    multipleLotSize: 2,
    scrapFactor: 10,
    stdLotSize: 2,
    proposalRelease: 'Approved',
    defaultSupplyType: 'Cretaed',
    partType: 'Spare',
    operativeValue: 7,
    operativeValueSource: 20,
    additionalTechInformation: 'Check Manual',
    notes: 'Need to Install New Item as per SOP',
    inventoryType: 'Spare',
    taxable: 'YES',
    qualityApprovalDate: '02/27/2022',
    qCAnalystDate: '02/27/2022',
    qCAnalysisID: '02/27/2022',
    dimQuantity: 1,
    buyerID: 'E7-949',
    techCoordinates: 'SING',
    buyerName: 'E73 AI Innovations',
    remarks: 'Spares to be ordered '
  },
  {
    vessel: 'UNIT-1',
    department: 'INST',
    'Tag Number': '13-HS-1221',
    'Equipment Description': 'Main - Function Prod well 2 (CP2)',
    'Spare Part Number': '16-2114-00003',
    'Spare Part Description': 'PUMP,PNEUMATIC,METALLIC,MM:S20B1SGTYWS600',
    spareType: 'CONSUMABLE',
    serialNumber: '73-0986-F-114',
    uom: 'EA',
    model: 'E-098-KIP-234',
    manufacturer: 'EMERSON',
    manufacturerPartNumber: 'E73-0098',
    certificateNeeded: 'YES',
    consumable: 'YES',
    supplier: 'ABC Pvt Ltd',
    supplierContact: '+47897354677',
    originalPO: 'PO-E73-9361',
    unitOneVeendorId: 675,
    equipmentLineNumber: 250,
    status: 'Operation',
    functionNumber: 16,
    functionDescription: 'Main - Function Prod well 2 (CP2)',
    estEquipType: 'PSV',
    attachments: 'YES',
    weightUnit: 'KG',
    weight: 167,
    isGeneric: 'YES',
    account: 'UNIT-1MAINT',
    costCenter: 16,
    projectId: 16,
    priceCurrency: 'USD',
    priceIncludingTax: 107,
    originalQuantity: 50,
    currencyCode: 'USD',
    buyUnit: 36,
    safetyCode: 'LS',
    accountGroup: 'KOS',
    productCode: 16,
    productFamily: 16,
    partFrequency: 'L',
    assetClass: 'MEDIUM',
    netWeight: 167,
    netVolume: 167,
    quantity: 167,
    locationNumber: '730A',
    locationType: 'AC',
    locationGroup: 'NEY',
    Warehouse: 'AC-STORE',
    bay: '7E',
    row: '99-G',
    tier: 'L18',
    bin: '3A',
    onHandQuantity: 34,
    created: '02/28/2022',
    modified: '07/28/2022',
    planningMethod: 'B',
    safetyStock: 3,
    orderPoint: 2,
    lotSize: 4,
    orderCoverTime: 1,
    minLotSize: 2,
    maxLotSize: 2,
    multipleLotSize: 2,
    scrapFactor: 10,
    stdLotSize: 2,
    proposalRelease: 'Approved',
    defaultSupplyType: 'Cretaed',
    partType: 'Spare',
    operativeValue: 7,
    operativeValueSource: 20,
    additionalTechInformation: 'Check Manual',
    notes: 'Need to Install New Item as per SOP',
    inventoryType: 'Spare',
    taxable: 'YES',
    qualityApprovalDate: '02/28/2022',
    qCAnalystDate: '02/28/2022',
    qCAnalysisID: '02/28/2022',
    dimQuantity: 1,
    buyerID: 'E7-950',
    techCoordinates: 'SING',
    buyerName: 'E73 AI Innovations',
    remarks: 'Spares to be ordered '
  },
  {
    vessel: 'UNIT-1',
    department: 'INST',
    'Tag Number': '13-XY-1221',
    'Equipment Description': 'Main - Function Prod well 2 (CP2)',
    'Spare Part Number': '16-2114-00004',
    'Spare Part Description': 'PUMP,PNEUMATIC,NON METALLIC,MM:S20B3C2PCUS600',
    spareType: 'CONSUMABLE',
    serialNumber: '73-0986-F-115',
    uom: 'EA',
    model: 'E-098-KIP-234',
    manufacturer: 'EMERSON',
    manufacturerPartNumber: 'E73-0098',
    certificateNeeded: 'YES',
    consumable: 'YES',
    supplier: 'ABC Pvt Ltd',
    supplierContact: '+47897354677',
    originalPO: 'PO-E73-9362',
    unitOneVeendorId: 675,
    equipmentLineNumber: 251,
    status: 'Operation',
    functionNumber: 16,
    functionDescription: 'Main - Function Prod well 2 (CP2)',
    estEquipType: 'PSV',
    attachments: 'YES',
    weightUnit: 'LBS',
    weight: 176,
    isGeneric: 'YES',
    account: 'UNIT-1MAINT',
    costCenter: 16,
    projectId: 16,
    priceCurrency: 'USD',
    priceIncludingTax: 109,
    originalQuantity: 53,
    currencyCode: 'USD',
    buyUnit: 38,
    safetyCode: 'LS',
    accountGroup: 'KOS',
    productCode: 16,
    productFamily: 16,
    partFrequency: 'H',
    assetClass: 'MEDIUM',
    netWeight: 176,
    netVolume: 176,
    quantity: 176,
    locationNumber: '730A',
    locationType: 'AC',
    locationGroup: 'NEY',
    Warehouse: 'AC-STORE',
    bay: '7E',
    row: '99-G',
    tier: 'L19',
    bin: '3A',
    onHandQuantity: 36,
    created: '03/01/2022',
    modified: '07/29/2022',
    planningMethod: 'B',
    safetyStock: 3,
    orderPoint: 2,
    lotSize: 4,
    orderCoverTime: 1,
    minLotSize: 2,
    maxLotSize: 2,
    multipleLotSize: 2,
    scrapFactor: 10,
    stdLotSize: 2,
    proposalRelease: 'Approved',
    defaultSupplyType: 'Cretaed',
    partType: 'Spare',
    operativeValue: 7,
    operativeValueSource: 20,
    additionalTechInformation: 'Check Manual',
    notes: 'Need to Install New Item as per SOP',
    inventoryType: 'Spare',
    taxable: 'YES',
    qualityApprovalDate: '03/01/2022',
    qCAnalystDate: '03/01/2022',
    qCAnalysisID: '03/01/2022',
    dimQuantity: 1,
    buyerID: 'E7-951',
    techCoordinates: 'SING',
    buyerName: 'E73 AI Innovations',
    remarks: 'Spares to be ordered '
  },
  {
    vessel: 'UNIT-1',
    department: 'INST',
    'Tag Number': '13-EV-1223',
    'Equipment Description': 'Main - Function Prod well 2 (CP2)',
    'Spare Part Number': '16-2118-00104',
    'Spare Part Description': 'KIT,WET END,F/ S1F,PN:476.194.635',
    spareType: 'CONSUMABLE',
    serialNumber: '73-0986-F-116',
    uom: 'EA',
    model: 'E-098-KIP-234',
    manufacturer: 'EMERSON',
    manufacturerPartNumber: 'E73-0098',
    certificateNeeded: 'YES',
    consumable: 'YES',
    supplier: 'ABC Pvt Ltd',
    supplierContact: '+47897354677',
    originalPO: 'PO-E73-9363',
    unitOneVeendorId: 675,
    equipmentLineNumber: 252,
    status: 'Operation',
    functionNumber: 16,
    functionDescription: 'Main - Function Prod well 2 (CP2)',
    estEquipType: 'PSV',
    attachments: 'YES',
    weightUnit: 'KG',
    weight: 185,
    isGeneric: 'YES',
    account: 'UNIT-1MAINT',
    costCenter: 16,
    projectId: 16,
    priceCurrency: 'USD',
    priceIncludingTax: 111,
    originalQuantity: 56,
    currencyCode: 'USD',
    buyUnit: 40,
    safetyCode: 'LS',
    accountGroup: 'KOS',
    productCode: 16,
    productFamily: 16,
    partFrequency: 'M',
    assetClass: 'MEDIUM',
    netWeight: 185,
    netVolume: 185,
    quantity: 185,
    locationNumber: '730A',
    locationType: 'AC',
    locationGroup: 'NEY',
    Warehouse: 'AC-STORE',
    bay: '7E',
    row: '99-G',
    tier: 'L20',
    bin: '3A',
    onHandQuantity: 38,
    created: '03/02/2022',
    modified: '07/30/2022',
    planningMethod: 'C',
    safetyStock: 3,
    orderPoint: 2,
    lotSize: 4,
    orderCoverTime: 1,
    minLotSize: 2,
    maxLotSize: 2,
    multipleLotSize: 2,
    scrapFactor: 10,
    stdLotSize: 2,
    proposalRelease: 'Approved',
    defaultSupplyType: 'Cretaed',
    partType: 'Spare',
    operativeValue: 7,
    operativeValueSource: 20,
    additionalTechInformation: 'Check Manual',
    notes: 'Need to Install New Item as per SOP',
    inventoryType: 'Spare',
    taxable: 'YES',
    qualityApprovalDate: '03/02/2022',
    qCAnalystDate: '03/02/2022',
    qCAnalysisID: '03/02/2022',
    dimQuantity: 1,
    buyerID: 'E7-952',
    techCoordinates: 'SING',
    buyerName: 'E73 AI Innovations',
    remarks: 'Spares to be ordered '
  },
  {
    vessel: 'UNIT-1',
    department: 'INST',
    'Tag Number': '13-EY-1223',
    'Equipment Description': 'Main - Function Prod well 2 (CP2)',
    'Spare Part Number': '16-2118-00109',
    'Spare Part Description': 'KIT,AIR END,F/ S1F,PN:476.228.000',
    spareType: 'CONSUMABLE',
    serialNumber: '73-0986-F-117',
    uom: 'EA',
    model: 'E-098-KIP-234',
    manufacturer: 'EMERSON',
    manufacturerPartNumber: 'E73-0098',
    certificateNeeded: 'YES',
    consumable: 'YES',
    supplier: 'ABC Pvt Ltd',
    supplierContact: '+47897354677',
    originalPO: 'PO-E73-9364',
    unitOneVeendorId: 675,
    equipmentLineNumber: 253,
    status: 'Operation',
    functionNumber: 16,
    functionDescription: 'Main - Function Prod well 2 (CP2)',
    estEquipType: 'PSV',
    attachments: 'YES',
    weightUnit: 'LBS',
    weight: 194,
    isGeneric: 'YES',
    account: 'UNIT-1MAINT',
    costCenter: 16,
    projectId: 16,
    priceCurrency: 'USD',
    priceIncludingTax: 113,
    originalQuantity: 59,
    currencyCode: 'USD',
    buyUnit: 42,
    safetyCode: 'LS',
    accountGroup: 'KOS',
    productCode: 16,
    productFamily: 16,
    partFrequency: 'L',
    assetClass: 'MEDIUM',
    netWeight: 194,
    netVolume: 194,
    quantity: 194,
    locationNumber: '730A',
    locationType: 'AC',
    locationGroup: 'NEY',
    Warehouse: 'AC-STORE',
    bay: '7E',
    row: '99-G',
    tier: 'L21',
    bin: '3A',
    onHandQuantity: 40,
    created: '03/03/2022',
    modified: '07/31/2022',
    planningMethod: 'D',
    safetyStock: 3,
    orderPoint: 2,
    lotSize: 4,
    orderCoverTime: 1,
    minLotSize: 2,
    maxLotSize: 2,
    multipleLotSize: 2,
    scrapFactor: 10,
    stdLotSize: 2,
    proposalRelease: 'Approved',
    defaultSupplyType: 'Cretaed',
    partType: 'Spare',
    operativeValue: 7,
    operativeValueSource: 20,
    additionalTechInformation: 'Check Manual',
    notes: 'Need to Install New Item as per SOP',
    inventoryType: 'Spare',
    taxable: 'YES',
    qualityApprovalDate: '03/03/2022',
    qCAnalystDate: '03/03/2022',
    qCAnalysisID: '03/03/2022',
    dimQuantity: 1,
    buyerID: 'E7-953',
    techCoordinates: 'SING',
    buyerName: 'E73 AI Innovations',
    remarks: 'Spares to be ordered '
  },
  {
    vessel: 'UNIT-1',
    department: 'INST',
    'Tag Number': '13-HS-1223',
    'Equipment Description': 'Main - Function Prod well 2 (CP2)',
    'Spare Part Number': '18-2122-00541',
    'Spare Part Description': 'WEAR PARTS,KE RF25 ES,PN:102940.1028',
    spareType: 'CONSUMABLE',
    serialNumber: '73-0986-F-118',
    uom: 'EA',
    model: 'E-098-KIP-234',
    manufacturer: 'EMERSON',
    manufacturerPartNumber: 'E73-0098',
    certificateNeeded: 'YES',
    consumable: 'YES',
    supplier: 'ABC Pvt Ltd',
    supplierContact: '+47897354677',
    originalPO: 'PO-E73-9365',
    unitOneVeendorId: 675,
    equipmentLineNumber: 254,
    status: 'Operation',
    functionNumber: 16,
    functionDescription: 'Main - Function Prod well 2 (CP2)',
    estEquipType: 'RN',
    attachments: 'YES',
    weightUnit: 'KG',
    weight: 203,
    isGeneric: 'YES',
    account: 'UNIT-1MAINT',
    costCenter: 16,
    projectId: 16,
    priceCurrency: 'USD',
    priceIncludingTax: 115,
    originalQuantity: 62,
    currencyCode: 'USD',
    buyUnit: 44,
    safetyCode: 'LS',
    accountGroup: 'KOS',
    productCode: 16,
    productFamily: 16,
    partFrequency: 'H',
    assetClass: 'MEDIUM',
    netWeight: 203,
    netVolume: 203,
    quantity: 203,
    locationNumber: '730A',
    locationType: 'AC',
    locationGroup: 'NEY',
    Warehouse: 'AC-STORE',
    bay: '7E',
    row: '99-G',
    tier: 'L22',
    bin: '3A',
    onHandQuantity: 42,
    created: '03/04/2022',
    modified: '08/01/2022',
    planningMethod: 'B',
    safetyStock: 3,
    orderPoint: 2,
    lotSize: 4,
    orderCoverTime: 1,
    minLotSize: 2,
    maxLotSize: 2,
    multipleLotSize: 2,
    scrapFactor: 10,
    stdLotSize: 2,
    proposalRelease: 'Approved',
    defaultSupplyType: 'Cretaed',
    partType: 'Spare',
    operativeValue: 7,
    operativeValueSource: 20,
    additionalTechInformation: 'Check Manual',
    notes: 'Need to Install New Item as per SOP',
    inventoryType: 'Spare',
    taxable: 'YES',
    qualityApprovalDate: '03/04/2022',
    qCAnalystDate: '03/04/2022',
    qCAnalysisID: '03/04/2022',
    dimQuantity: 1,
    buyerID: 'E7-954',
    techCoordinates: 'SING',
    buyerName: 'E73 AI Innovations',
    remarks: 'Spares to be ordered '
  },
  {
    vessel: 'UNIT-1',
    department: 'INST',
    'Tag Number': '13-XY-1223',
    'Equipment Description': 'Main - Function Prod well 2 (CP2)',
    'Spare Part Number': '16-2119-00101',
    'Spare Part Description': 'WEAR PARTS,KE RF25 ES,PN:102940.1028',
    spareType: 'CONSUMABLE',
    serialNumber: '73-0986-F-119',
    uom: 'EA',
    model: 'E-098-KIP-234',
    manufacturer: 'EMERSON',
    manufacturerPartNumber: 'E73-0098',
    certificateNeeded: 'YES',
    consumable: 'YES',
    supplier: 'ABC Pvt Ltd',
    supplierContact: '+47897354677',
    originalPO: 'PO-E73-9366',
    unitOneVeendorId: 675,
    equipmentLineNumber: 255,
    status: 'Operation',
    functionNumber: 16,
    functionDescription: 'Main - Function Prod well 2 (CP2)',
    estEquipType: 'RN',
    attachments: 'YES',
    weightUnit: 'LBS',
    weight: 212,
    isGeneric: 'YES',
    account: 'UNIT-1MAINT',
    costCenter: 16,
    projectId: 16,
    priceCurrency: 'USD',
    priceIncludingTax: 117,
    originalQuantity: 65,
    currencyCode: 'USD',
    buyUnit: 46,
    safetyCode: 'LS',
    accountGroup: 'KOS',
    productCode: 16,
    productFamily: 16,
    partFrequency: 'M',
    assetClass: 'MEDIUM',
    netWeight: 212,
    netVolume: 212,
    quantity: 212,
    locationNumber: '730A',
    locationType: 'AC',
    locationGroup: 'NEY',
    Warehouse: 'AC-STORE',
    bay: '7E',
    row: '99-G',
    tier: 'L23',
    bin: '3A',
    onHandQuantity: 44,
    created: '03/05/2022',
    modified: '08/02/2022',
    planningMethod: 'B',
    safetyStock: 3,
    orderPoint: 2,
    lotSize: 4,
    orderCoverTime: 1,
    minLotSize: 2,
    maxLotSize: 2,
    multipleLotSize: 2,
    scrapFactor: 10,
    stdLotSize: 2,
    proposalRelease: 'Approved',
    defaultSupplyType: 'Cretaed',
    partType: 'Spare',
    operativeValue: 7,
    operativeValueSource: 20,
    additionalTechInformation: 'Check Manual',
    notes: 'Need to Install New Item as per SOP',
    inventoryType: 'Spare',
    taxable: 'YES',
    qualityApprovalDate: '03/05/2022',
    qCAnalystDate: '03/05/2022',
    qCAnalysisID: '03/05/2022',
    dimQuantity: 1,
    buyerID: 'E7-955',
    techCoordinates: 'SING',
    buyerName: 'E73 AI Innovations',
    remarks: 'Spares to be ordered '
  },
  {
    vessel: 'UNIT-1',
    department: 'INST',
    'Tag Number': '13-EV-1233',
    'Equipment Description': 'Main - Function Prod well 2 (CP2)',
    'Spare Part Number': '16-2119-00102',
    'Spare Part Description':
      'SANDWICH DIAPHRAGM,BD124 M900H GREASE,PN:116936.0012',
    spareType: 'CONSUMABLE',
    serialNumber: '73-0986-F-120',
    uom: 'EA',
    model: 'E-098-KIP-234',
    manufacturer: 'EMERSON',
    manufacturerPartNumber: 'E73-0098',
    certificateNeeded: 'YES',
    consumable: 'YES',
    supplier: 'ABC Pvt Ltd',
    supplierContact: '+47897354677',
    originalPO: 'PO-E73-9367',
    unitOneVeendorId: 675,
    equipmentLineNumber: 256,
    status: 'Operation',
    functionNumber: 16,
    functionDescription: 'Main - Function Prod well 2 (CP2)',
    estEquipType: 'RN',
    attachments: 'YES',
    weightUnit: 'KG',
    weight: 221,
    isGeneric: 'YES',
    account: 'UNIT-1MAINT',
    costCenter: 16,
    projectId: 16,
    priceCurrency: 'USD',
    priceIncludingTax: 119,
    originalQuantity: 68,
    currencyCode: 'USD',
    buyUnit: 48,
    safetyCode: 'LS',
    accountGroup: 'KOS',
    productCode: 16,
    productFamily: 16,
    partFrequency: 'L',
    assetClass: 'MEDIUM',
    netWeight: 221,
    netVolume: 221,
    quantity: 221,
    locationNumber: '730A',
    locationType: 'AC',
    locationGroup: 'NEY',
    Warehouse: 'AC-STORE',
    bay: '7E',
    row: '99-G',
    tier: 'L24',
    bin: '3A',
    onHandQuantity: 46,
    created: '03/06/2022',
    modified: '08/03/2022',
    planningMethod: 'C',
    safetyStock: 3,
    orderPoint: 2,
    lotSize: 4,
    orderCoverTime: 1,
    minLotSize: 2,
    maxLotSize: 2,
    multipleLotSize: 2,
    scrapFactor: 10,
    stdLotSize: 2,
    proposalRelease: 'Approved',
    defaultSupplyType: 'Cretaed',
    partType: 'Spare',
    operativeValue: 7,
    operativeValueSource: 20,
    additionalTechInformation: 'Check Manual',
    notes: 'Need to Install New Item as per SOP',
    inventoryType: 'Spare',
    taxable: 'YES',
    qualityApprovalDate: '03/06/2022',
    qCAnalystDate: '03/06/2022',
    qCAnalysisID: '03/06/2022',
    dimQuantity: 1,
    buyerID: 'E7-956',
    techCoordinates: 'SING',
    buyerName: 'E73 AI Innovations',
    remarks: 'Spares to be ordered '
  },
  {
    vessel: 'UNIT-1',
    department: 'INST',
    'Tag Number': '13-EY-1233',
    'Equipment Description': 'Main - Function Prod well 2 (CP2)',
    'Spare Part Number': '16-2120-00101',
    'Spare Part Description': 'KIT,AIR END,PN:476-227-000',
    spareType: 'CONSUMABLE',
    serialNumber: '73-0986-F-121',
    uom: 'EA',
    model: 'E-098-KIP-234',
    manufacturer: 'EMERSON',
    manufacturerPartNumber: 'E73-0098',
    certificateNeeded: 'YES',
    consumable: 'YES',
    supplier: 'ABC Pvt Ltd',
    supplierContact: '+47897354677',
    originalPO: 'PO-E73-9368',
    unitOneVeendorId: 675,
    equipmentLineNumber: 257,
    status: 'Operation',
    functionNumber: 16,
    functionDescription: 'Main - Function Prod well 2 (CP2)',
    estEquipType: 'RN',
    attachments: 'YES',
    weightUnit: 'LBS',
    weight: 230,
    isGeneric: 'YES',
    account: 'UNIT-1MAINT',
    costCenter: 16,
    projectId: 16,
    priceCurrency: 'USD',
    priceIncludingTax: 121,
    originalQuantity: 71,
    currencyCode: 'USD',
    buyUnit: 50,
    safetyCode: 'LS',
    accountGroup: 'KOS',
    productCode: 16,
    productFamily: 16,
    partFrequency: 'H',
    assetClass: 'MEDIUM',
    netWeight: 230,
    netVolume: 230,
    quantity: 230,
    locationNumber: '730A',
    locationType: 'AC',
    locationGroup: 'NEY',
    Warehouse: 'AC-STORE',
    bay: '7E',
    row: '99-G',
    tier: 'L25',
    bin: '3A',
    onHandQuantity: 48,
    created: '03/07/2022',
    modified: '08/04/2022',
    planningMethod: 'D',
    safetyStock: 3,
    orderPoint: 2,
    lotSize: 4,
    orderCoverTime: 1,
    minLotSize: 2,
    maxLotSize: 2,
    multipleLotSize: 2,
    scrapFactor: 10,
    stdLotSize: 2,
    proposalRelease: 'Approved',
    defaultSupplyType: 'Cretaed',
    partType: 'Spare',
    operativeValue: 7,
    operativeValueSource: 20,
    additionalTechInformation: 'Check Manual',
    notes: 'Need to Install New Item as per SOP',
    inventoryType: 'Spare',
    taxable: 'YES',
    qualityApprovalDate: '03/07/2022',
    qCAnalystDate: '03/07/2022',
    qCAnalysisID: '03/07/2022',
    dimQuantity: 1,
    buyerID: 'E7-957',
    techCoordinates: 'SING',
    buyerName: 'E73 AI Innovations',
    remarks: 'Spares to be ordered '
  },
  {
    vessel: 'UNIT-1',
    department: 'INST',
    'Tag Number': '13-HS-1233',
    'Equipment Description': 'Main - Function Prod well 2 (CP2)',
    'Spare Part Number': '16-9038-00001',
    'Spare Part Description': 'PUMP HAND PLASTIC FOR PAIL CAN IMPA 614010',
    spareType: 'CONSUMABLE',
    serialNumber: '73-0986-F-122',
    uom: 'EA',
    model: 'E-098-KIP-234',
    manufacturer: 'EMERSON',
    manufacturerPartNumber: 'E73-0098',
    certificateNeeded: 'YES',
    consumable: 'YES',
    supplier: 'ABC Pvt Ltd',
    supplierContact: '+47897354677',
    originalPO: 'PO-E73-9369',
    unitOneVeendorId: 675,
    equipmentLineNumber: 258,
    status: 'Operation',
    functionNumber: 16,
    functionDescription: 'Main - Function Prod well 2 (CP2)',
    estEquipType: 'RN',
    attachments: 'YES',
    weightUnit: 'KG',
    weight: 239,
    isGeneric: 'YES',
    account: 'UNIT-1MAINT',
    costCenter: 16,
    projectId: 16,
    priceCurrency: 'USD',
    priceIncludingTax: 123,
    originalQuantity: 74,
    currencyCode: 'USD',
    buyUnit: 52,
    safetyCode: 'LS',
    accountGroup: 'KOS',
    productCode: 16,
    productFamily: 16,
    partFrequency: 'M',
    assetClass: 'MEDIUM',
    netWeight: 239,
    netVolume: 239,
    quantity: 239,
    locationNumber: '730A',
    locationType: 'AC',
    locationGroup: 'NEY',
    Warehouse: 'AC-STORE',
    bay: '7E',
    row: '99-G',
    tier: 'L26',
    bin: '3A',
    onHandQuantity: 50,
    created: '03/08/2022',
    modified: '08/05/2022',
    planningMethod: 'B',
    safetyStock: 3,
    orderPoint: 2,
    lotSize: 4,
    orderCoverTime: 1,
    minLotSize: 2,
    maxLotSize: 2,
    multipleLotSize: 2,
    scrapFactor: 10,
    stdLotSize: 2,
    proposalRelease: 'Approved',
    defaultSupplyType: 'Cretaed',
    partType: 'Spare',
    operativeValue: 7,
    operativeValueSource: 20,
    additionalTechInformation: 'Check Manual',
    notes: 'Need to Install New Item as per SOP',
    inventoryType: 'Spare',
    taxable: 'YES',
    qualityApprovalDate: '03/08/2022',
    qCAnalystDate: '03/08/2022',
    qCAnalysisID: '03/08/2022',
    dimQuantity: 1,
    buyerID: 'E7-958',
    techCoordinates: 'SING',
    buyerName: 'E73 AI Innovations',
    remarks: 'Spares to be ordered '
  },
  {
    vessel: 'UNIT-1',
    department: 'INST',
    'Tag Number': '13-XY-1233',
    'Equipment Description':
      'Pressure Safety Indication Transmitter for 1st Stage Separator',
    'Spare Part Number': '17-0029-00111',
    'Spare Part Description':
      'ELEMENT,OIL FILTER,INR-S-00320-API-PF25-V,PN:87482544',
    spareType: 'CONSUMABLE',
    serialNumber: '73-0986-F-123',
    uom: 'EA',
    model: 'E-098-KIP-234',
    manufacturer: 'EMERSON',
    manufacturerPartNumber: 'E73-0098',
    certificateNeeded: 'YES',
    consumable: 'YES',
    supplier: 'ABC Pvt Ltd',
    supplierContact: '+47897354677',
    originalPO: 'PO-E73-9370',
    unitOneVeendorId: 675,
    equipmentLineNumber: 259,
    status: 'Operation',
    functionNumber: 17,
    functionDescription:
      'Pressure Safety Indication Transmitter for 1st Stage Separator',
    estEquipType: 'RN',
    attachments: 'YES',
    weightUnit: 'LBS',
    weight: 248,
    isGeneric: 'YES',
    account: 'UNIT-1MAINT',
    costCenter: 17,
    projectId: 17,
    priceCurrency: 'USD',
    priceIncludingTax: 125,
    originalQuantity: 77,
    currencyCode: 'USD',
    buyUnit: 54,
    safetyCode: 'LS',
    accountGroup: 'KOS',
    productCode: 17,
    productFamily: 17,
    partFrequency: 'L',
    assetClass: 'MEDIUM',
    netWeight: 248,
    netVolume: 248,
    quantity: 248,
    locationNumber: '730A',
    locationType: 'AC',
    locationGroup: 'NEY',
    Warehouse: 'AC-STORE',
    bay: '7E',
    row: '99-G',
    tier: 'L27',
    bin: '3A',
    onHandQuantity: 52,
    created: '03/09/2022',
    modified: '08/06/2022',
    planningMethod: 'B',
    safetyStock: 3,
    orderPoint: 2,
    lotSize: 4,
    orderCoverTime: 1,
    minLotSize: 2,
    maxLotSize: 2,
    multipleLotSize: 2,
    scrapFactor: 10,
    stdLotSize: 2,
    proposalRelease: 'Approved',
    defaultSupplyType: 'Cretaed',
    partType: 'Spare',
    operativeValue: 7,
    operativeValueSource: 20,
    additionalTechInformation: 'Check Manual',
    notes: 'Need to Install New Item as per SOP',
    inventoryType: 'Spare',
    taxable: 'YES',
    qualityApprovalDate: '03/09/2022',
    qCAnalystDate: '03/09/2022',
    qCAnalysisID: '03/09/2022',
    dimQuantity: 1,
    buyerID: 'E7-959',
    techCoordinates: 'SING',
    buyerName: 'E73 AI Innovations',
    remarks: 'Spares to be ordered '
  },
  {
    vessel: 'UNIT-1',
    department: 'INST',
    'Tag Number': '13-EV-1235',
    'Equipment Description':
      'Pressure Safety Indication Transmitter for 1st Stage Separator',
    'Spare Part Number': '17-0029-00112',
    'Spare Part Description':
      'HOUSING O-RING,OIL FILTER,VITON OR EQUAL,PN:81045224',
    spareType: 'CONSUMABLE',
    serialNumber: '73-0986-F-124',
    uom: 'EA',
    model: 'E-098-KIP-234',
    manufacturer: 'EMERSON',
    manufacturerPartNumber: 'E73-0098',
    certificateNeeded: 'YES',
    consumable: 'YES',
    supplier: 'ABC Pvt Ltd',
    supplierContact: '+47897354677',
    originalPO: 'PO-E73-9371',
    unitOneVeendorId: 675,
    equipmentLineNumber: 260,
    status: 'Operation',
    functionNumber: 17,
    functionDescription:
      'Pressure Safety Indication Transmitter for 1st Stage Separator',
    estEquipType: 'RN',
    attachments: 'YES',
    weightUnit: 'KG',
    weight: 257,
    isGeneric: 'YES',
    account: 'UNIT-1MAINT',
    costCenter: 17,
    projectId: 17,
    priceCurrency: 'USD',
    priceIncludingTax: 127,
    originalQuantity: 80,
    currencyCode: 'USD',
    buyUnit: 56,
    safetyCode: 'LS',
    accountGroup: 'KOS',
    productCode: 17,
    productFamily: 17,
    partFrequency: 'H',
    assetClass: 'MEDIUM',
    netWeight: 257,
    netVolume: 257,
    quantity: 257,
    locationNumber: '730A',
    locationType: 'AC',
    locationGroup: 'NEY',
    Warehouse: 'AC-STORE',
    bay: '7E',
    row: '99-G',
    tier: 'L28',
    bin: '3A',
    onHandQuantity: 54,
    created: '03/10/2022',
    modified: '08/07/2022',
    planningMethod: 'C',
    safetyStock: 3,
    orderPoint: 2,
    lotSize: 4,
    orderCoverTime: 1,
    minLotSize: 2,
    maxLotSize: 2,
    multipleLotSize: 2,
    scrapFactor: 10,
    stdLotSize: 2,
    proposalRelease: 'Approved',
    defaultSupplyType: 'Cretaed',
    partType: 'Spare',
    operativeValue: 7,
    operativeValueSource: 20,
    additionalTechInformation: 'Check Manual',
    notes: 'Need to Install New Item as per SOP',
    inventoryType: 'Spare',
    taxable: 'YES',
    qualityApprovalDate: '03/10/2022',
    qCAnalystDate: '03/10/2022',
    qCAnalysisID: '03/10/2022',
    dimQuantity: 1,
    buyerID: 'E7-960',
    techCoordinates: 'SING',
    buyerName: 'E73 AI Innovations',
    remarks: 'Spares to be ordered '
  },
  {
    vessel: 'UNIT-1',
    department: 'INST',
    'Tag Number': '13-EY-1235',
    'Equipment Description': 'SCSSV (Downhole valve)',
    'Spare Part Number': '19-5102-00114',
    'Spare Part Description':
      'GASKET,1550OD X 1465ID X 3MM THK,KLINGERSIL C-4430,PN:3365020',
    spareType: 'CONSUMABLE',
    serialNumber: '73-0986-F-125',
    uom: 'EA',
    model: 'E-098-KIP-234',
    manufacturer: 'EMERSON',
    manufacturerPartNumber: 'E73-0098',
    certificateNeeded: 'YES',
    consumable: 'YES',
    supplier: 'ABC Pvt Ltd',
    supplierContact: '+47897354677',
    originalPO: 'PO-E73-9372',
    unitOneVeendorId: 675,
    equipmentLineNumber: 261,
    status: 'Operation',
    functionNumber: 19,
    functionDescription: 'SCSSV (Downhole valve)',
    estEquipType: 'RN',
    attachments: 'YES',
    weightUnit: 'LBS',
    weight: 266,
    isGeneric: 'YES',
    account: 'UNIT-1MAINT',
    costCenter: 19,
    projectId: 19,
    priceCurrency: 'USD',
    priceIncludingTax: 129,
    originalQuantity: 83,
    currencyCode: 'USD',
    buyUnit: 58,
    safetyCode: 'LS',
    accountGroup: 'KOS',
    productCode: 19,
    productFamily: 19,
    partFrequency: 'M',
    assetClass: 'MEDIUM',
    netWeight: 266,
    netVolume: 266,
    quantity: 266,
    locationNumber: '730A',
    locationType: 'AC',
    locationGroup: 'NEY',
    Warehouse: 'AC-STORE',
    bay: '7E',
    row: '99-G',
    tier: 'L29',
    bin: '3A',
    onHandQuantity: 56,
    created: '03/11/2022',
    modified: '08/08/2022',
    planningMethod: 'D',
    safetyStock: 3,
    orderPoint: 2,
    lotSize: 4,
    orderCoverTime: 1,
    minLotSize: 2,
    maxLotSize: 2,
    multipleLotSize: 2,
    scrapFactor: 10,
    stdLotSize: 2,
    proposalRelease: 'Approved',
    defaultSupplyType: 'Cretaed',
    partType: 'Spare',
    operativeValue: 7,
    operativeValueSource: 20,
    additionalTechInformation: 'Check Manual',
    notes: 'Need to Install New Item as per SOP',
    inventoryType: 'Spare',
    taxable: 'YES',
    qualityApprovalDate: '03/11/2022',
    qCAnalystDate: '03/11/2022',
    qCAnalysisID: '03/11/2022',
    dimQuantity: 1,
    buyerID: 'E7-961',
    techCoordinates: 'SING',
    buyerName: 'E73 AI Innovations',
    remarks: 'Spares to be ordered '
  },
  {
    vessel: 'UNIT-1',
    department: 'INST',
    'Tag Number': '13-HS-1235',
    'Equipment Description': 'SCSSV (Downhole valve)',
    'Spare Part Number': '19-5102-00117',
    'Spare Part Description':
      'GASKET,1455OD X 1425ID X 3MM THK,KLINGERSIL C-4430,PN:3365023',
    spareType: 'CONSUMABLE',
    serialNumber: '73-0986-F-126',
    uom: 'EA',
    model: 'E-098-KIP-234',
    manufacturer: 'EMERSON',
    manufacturerPartNumber: 'E73-0098',
    certificateNeeded: 'YES',
    consumable: 'YES',
    supplier: 'ABC Pvt Ltd',
    supplierContact: '+47897354677',
    originalPO: 'PO-E73-9373',
    unitOneVeendorId: 675,
    equipmentLineNumber: 262,
    status: 'Operation',
    functionNumber: 19,
    functionDescription: 'SCSSV (Downhole valve)',
    estEquipType: 'RN',
    attachments: 'YES',
    weightUnit: 'KG',
    weight: 275,
    isGeneric: 'YES',
    account: 'UNIT-1MAINT',
    costCenter: 19,
    projectId: 19,
    priceCurrency: 'USD',
    priceIncludingTax: 131,
    originalQuantity: 86,
    currencyCode: 'USD',
    buyUnit: 60,
    safetyCode: 'LS',
    accountGroup: 'KOS',
    productCode: 19,
    productFamily: 19,
    partFrequency: 'L',
    assetClass: 'MEDIUM',
    netWeight: 275,
    netVolume: 275,
    quantity: 275,
    locationNumber: '730A',
    locationType: 'AC',
    locationGroup: 'NEY',
    Warehouse: 'AC-STORE',
    bay: '7E',
    row: '99-G',
    tier: 'L30',
    bin: '3A',
    onHandQuantity: 58,
    created: '03/12/2022',
    modified: '08/09/2022',
    planningMethod: 'B',
    safetyStock: 3,
    orderPoint: 2,
    lotSize: 4,
    orderCoverTime: 1,
    minLotSize: 2,
    maxLotSize: 2,
    multipleLotSize: 2,
    scrapFactor: 10,
    stdLotSize: 2,
    proposalRelease: 'Approved',
    defaultSupplyType: 'Cretaed',
    partType: 'Spare',
    operativeValue: 7,
    operativeValueSource: 20,
    additionalTechInformation: 'Check Manual',
    notes: 'Need to Install New Item as per SOP',
    inventoryType: 'Spare',
    taxable: 'YES',
    qualityApprovalDate: '03/12/2022',
    qCAnalystDate: '03/12/2022',
    qCAnalysisID: '03/12/2022',
    dimQuantity: 1,
    buyerID: 'E7-962',
    techCoordinates: 'SING',
    buyerName: 'E73 AI Innovations',
    remarks: 'Spares to be ordered '
  }
]
