import { Row } from 'reactstrap'

import DashboardNavigation from '../../components/Dashboard/DashboardNavigation'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import ObservationCard from '../ObservationCard'
import ViewPermitsByLayout from '../ViewPermitsByLayout'

export default function ViewByLayout() {
  return (
    <>
      <ProtectedLayout
        onBack="/services"
        hasSideList
        hasUserProfile
        sidePanel={<DashboardNavigation />}
      >
        <Row style={{ margin: 10 }}>
          <ViewPermitsByLayout />
        </Row>
      </ProtectedLayout>
    </>
  )
}
