import { Row } from 'reactstrap'
import { useContext, useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import ViewPermits from '../../components/Tables/ViewPermits'
import { epermit } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { UserContext } from '../../App'

export default function SuspendedList() {
  const [pageNumber, setPageNumber] = useState(0)
  const [equipment, setEquipment]: any = useState()
  const [location, setLocation]: any = useState()
  const [permitNumber, setPermitNumber]: any = useState()

  const { userData } = useContext(UserContext)
  const { accounts, instance } = useMsal()

  const fetchAdminPermissions = async () => {
    const response = await makeAuthenticatedRequest(
      `${epermit}?page=${pageNumber}&status=suspended&equipment=${equipment}&location=${location}&permitNumber=${permitNumber}&department=${userData?.department?.trim()}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, refetch }: any = useQuery(
    'ClosedPermits',
    fetchAdminPermissions
  )

  useEffect(() => {
    refetch()
  }, [equipment, location, permitNumber])

  return (
    <>
      <ProtectedLayout onBack="/eptw/dashboard">
        <Row style={{ margin: 10 }}>
          <ViewPermits
            data={data}
            isLoading={isLoading}
            refetch={refetch}
            setPageNumber={setPageNumber}
            navigateTo={`/eptw/suspended`}
            equipment={equipment}
            setEquipment={setEquipment}
            location={location}
            setLocation={setLocation}
            permitNumber={permitNumber}
            setPermitNumber={setPermitNumber}
          />
        </Row>
      </ProtectedLayout>
    </>
  )
}
