import { Row } from "reactstrap";
import { IconCard } from "../../components/Dashboard/IconCard";

import ProtectedLayout from "../../components/Layout/ProtectedLayout";

export default function TravelActions() {
  const flows: any = [
    {
      //   src: require("./../../images/Icons/unit1.png"),
      name: "CREATE TRAVEL REQUEST",
      nav: "create",
    },
    // {
    //   //   src: require("./../../images/Icons/unit2.png"),
    //   name: "VIEW",
    //   nav: "/cmms/supplyChain/itemmaster/view",
    // },
  ];

  return (
    <>
      <ProtectedLayout onBack="/cmms" title="TRAVEL TICKET DASHBOARD">
        <Row style={{ margin: 15 }}>
          {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
            <IconCard
              key={`${index}-${name}`}
              name={name}
              nav={nav}
              canView={canView}
              count={count}
              src={src}
            />
          ))}
        </Row>
      </ProtectedLayout>
    </>
  );
}
