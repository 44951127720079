import { useMsal } from '@azure/msal-react'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { PermissionsContext, UserContext } from '../../App'
import toNormalCase from '../../components/lib/toNormalCase'
import { countsEndpoint } from '../../components/serverurl'
import ViewIcons from '../../components/ViewIcons'

export default function WOIActions() {
  const { permissions } = useContext(PermissionsContext)
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const userlocation = toNormalCase(permissions?.location)

  const permitsCountByflow = async () => {
    const response = await makeAuthenticatedRequest(
      `${countsEndpoint}/woi?department=${userData?.department}&location=${userlocation}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data }: any = useQuery('woiCount', permitsCountByflow)

  const counts = data?.counts

  const icons = [
    {
      name: 'VERIFY (WOI)',
      src: require('./../../images/Icons/Permits without isolations_Verify.png'),
      nav: '/eptw/woi/verify',
      canView: permissions?.canVerifyWOI,
      count: counts?.verify
    },
    {
      name: 'ISSUE (WOI)',
      src: require('./../../images/Icons/Permits without isolations_Issue.png'),
      nav: '/eptw/woi/issue',
      canView: permissions?.canIssueWOI,
      count: counts?.issue
    },
    {
      name: 'APPROVE (WOI)',
      src: require('./../../images/Icons/Permits without isolations_Approve.png'),
      nav: '/eptw/woi/approve',
      canView: permissions?.canApproveWOI,
      count: counts?.approve
    },
    {
      name: 'MAKE LIVE (WOI)',
      src: require('./../../images/Icons/Permits without isolations_Live.png'),
      nav: '/eptw/woi/makeLive',
      canView: permissions?.canCreateWOI,
      count: counts?.makeLive
    }
  ]

  return <ViewIcons icons={icons} />
}
