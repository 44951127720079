import moment from 'moment'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import React, { useState } from 'react'

import AshokLeylandLayout from '../ashokleyland/AshokLeylandLayout'
import { incrementby1 } from '../components/Forms/JSAForm'
import { Box, Link, renderData } from './workManagement/PmTree'
import { makeAuthenticatedRequest } from '../apiRequests'
import { TableLoadingState } from './moc/MocList'
import { getTickets, ticketApi } from '../components/serverurl'
import { SubHeader } from './VerifyCM'
import SubmitModalWithForm from '../components/Modals/SubmitModalWithForm'
import CustomLabel from '../components/InputFields/CustomLabel'

const TicketsMenu = ({ active, setActive }: any) => {
  const commonItems = ['Unassigned tickets', 'My open tickets']
  const itemsByStatus = [
    'Pending',
    'In progress',
    'On hold',
    'Done',
    'Testing',
    'Closed'
  ]

  return (
    <div
      style={{
        height: '85vh',
        backgroundColor: '#f3f7f9',
        paddingTop: '10',
        paddingLeft: 20,
        paddingRight: 20
      }}
    >
      <div
        style={{
          paddingTop: 40,
          paddingBottom: 20,
          borderBottom: '1px solid gray'
        }}
      >
        <h3>Tickets</h3>
      </div>
      <div
        onClick={() => setActive('All tickets')}
        style={{
          paddingTop: 20,
          paddingBottom: 20,
          borderBottom: '1px solid gray'
        }}
      >
        <p
          style={{
            fontSize: 16,
            paddingTop: 10,
            paddingBottom: 10,
            margin: 0,
            color: active === 'All tickets' ? 'blue' : '#212529',
            fontWeight: active === 'All tickets' ? 700 : 500
          }}
        >
          All tickets
        </p>
      </div>
      <div
        style={{
          paddingTop: 20,
          paddingBottom: 20,
          borderBottom: '1px solid gray'
        }}
      >
        {commonItems?.map((i) => (
          <p
            onClick={() => setActive(i)}
            style={{
              fontSize: 16,
              paddingTop: 10,
              paddingBottom: 10,
              margin: 0,
              color: active === i ? 'blue' : '#212529',
              fontWeight: active === i ? 700 : 500
            }}
            key={i}
          >
            {i}
          </p>
        ))}
      </div>
      <div
        style={{
          paddingTop: 20,
          paddingBottom: 20
        }}
      >
        {itemsByStatus?.map((i) => (
          <p
            onClick={() => setActive(i)}
            style={{
              fontSize: 16,
              paddingTop: 10,
              paddingBottom: 10,
              margin: 0,
              color: active === i ? 'blue' : '#212529',
              fontWeight: active === i ? 700 : 500
            }}
            key={i}
          >
            {i}
          </p>
        ))}
      </div>
      <div
        style={{
          paddingTop: 20,
          paddingBottom: 20,
          borderTop: '1px solid gray'
        }}
      >
        <p
          onClick={() => setActive('Report')}
          style={{
            fontSize: 16,
            paddingTop: 10,
            paddingBottom: 10,
            margin: 0,
            color: active === 'Report' ? 'blue' : '#212529',
            fontWeight: active === 'Report' ? 700 : 500
          }}
        >
          Report
        </p>
      </div>
    </div>
  )
}

const TicketsTable = ({
  active,
  tickets,
  isLoading,
  isRefectching,
  refetch
}: any) => {
  const [selected, setSelected]: any = useState()

  return (
    <div>
      <div
        style={{
          height: '85vh',
          paddingTop: '10',
          paddingLeft: 20,
          paddingRight: 20
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: 40,
            paddingBottom: 20,
            borderBottom: '1px solid gray'
          }}
        >
          <h3>{active}</h3>
        </div>
        <Table bordered style={{ borderColor: '#000', marginTop: 20 }}>
          <thead>
            <tr style={{ backgroundColor: 'lightblue' }}>
              {[
                'S.No',
                'Number',
                'Subject',
                'Asignee',
                'Status',
                'Priority',
                'Raised by',
                'Create on'
              ]?.map((h: any) => <th key={h}>{h}</th>)}
            </tr>
          </thead>
          {isLoading || isRefectching ? (
            <TableLoadingState />
          ) : (
            <>
              {tickets?.length > 0 && (
                <tbody>
                  {tickets?.map((ticket: any, index: any) => (
                    <tr key={ticket?._id}>
                      <td>{incrementby1(index)}</td>
                      <td>
                        <Link onClick={() => setSelected(ticket)}>
                          {ticket?.number}
                        </Link>
                      </td>
                      <td>{ticket?.subject}</td>
                      <td>{ticket?.asignee || 'Unassigned'}</td>
                      <td>{ticket?.status}</td>
                      <td>{ticket?.priority || 'Low'}</td>
                      <td>{ticket?.userName}</td>
                      <td>
                        {moment(ticket?.createdOn)?.format(
                          'DD-MM-YYYY hh:mm A'
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </>
          )}
        </Table>
        <ViewTicketModal
          ticket={selected}
          setSelected={setSelected}
          refetch={refetch}
        />
      </div>
    </div>
  )
}

const ViewReport = ({ active, isLoading, isRefectching, report }: any) => {
  const headers = report?.reduce(
    (acc: any, { date, createdCount, closedCount }: any) => {
      // Split the date string and rearrange to DD-MM-YYYY
      const [year, month, day] = date.split('-')
      const formattedDate = `${day}-${month}-${year}`

      acc[formattedDate] = { createdCount, closedCount }
      return acc
    },
    {}
  )

  console.log({ headers })
  return (
    <div>
      <div
        style={{
          height: '85vh',
          paddingTop: '10',
          paddingLeft: 20,
          paddingRight: 20
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: 40,
            paddingBottom: 20,
            borderBottom: '1px solid gray'
          }}
        >
          <h3>{active}</h3>
        </div>
        <Table bordered style={{ borderColor: '#000', marginTop: 20 }}>
          <thead>
            <tr style={{ backgroundColor: 'lightblue' }}>
              <th>Date</th>
              <th>Created</th>
              <th>Closed</th>
            </tr>
          </thead>
          {isLoading || isRefectching ? (
            <TableLoadingState />
          ) : (
            <tbody>
              {Object.keys(headers)?.map((h: any, index: any) => (
                <tr key={h}>
                  <td>{h}</td>
                  <td>{headers?.[h]?.createdCount}</td>
                  <td>{headers?.[h]?.closedCount}</td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </div>
    </div>
  )
}

export default function TicketsDashboard() {
  const { accounts, instance } = useMsal()
  const [active, setActive]: any = useState('All tickets')

  const fetchTickets = async () => {
    const response = await makeAuthenticatedRequest(
      ticketApi,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, isRefetching }: any = useQuery(
    'fetchTickets',
    fetchTickets
  )

  const { tickets, report, refetch } = data || {}

  console.log(report)

  return (
    <AshokLeylandLayout onBack={'/ashokleyland'} title="Tickets">
      <Row style={{ margin: 0 }}>
        <Col md="2">
          <TicketsMenu active={active} setActive={setActive} />
        </Col>
        <Col md="10" gap="0">
          {active !== 'Report' ? (
            <TicketsTable
              active={active}
              tickets={tickets}
              isLoading={isLoading}
              isRefetching={isRefetching}
              refetch={refetch}
            />
          ) : (
            <ViewReport
              report={report}
              isLoading={isLoading}
              isRefectching={isRefetching}
              active={active}
            />
          )}
        </Col>
      </Row>
    </AshokLeylandLayout>
  )
}

const ViewTicketModal = ({ ticket, setSelected, refetch }: any) => {
  const { accounts, instance } = useMsal()
  const [modal, setModal]: any = useState(false)
  const [status, setStatus]: any = useState()
  return (
    <Modal fullscreen isOpen={ticket?._id}>
      <ModalBody>
        <SubHeader header={ticket?.number} />
        <Box heading="Ticket Details">
          <>
            {renderData('Subject', ticket?.subject, 16)}
            {renderData('Description', ticket?.description, 16)}
            {renderData('Steps to Reproduce', ticket?.stepsToReproduce, 16)}
            {renderData('Category', ticket?.category, 16)}
            {renderData('Sub category', ticket?.subCategory, 16)}
            {renderData('Priority', ticket?.priority, 16)}
            {renderData('Department', ticket?.department, 16)}
            {renderData('Created by', ticket?.userName, 16)}
            {renderData('User department', ticket?.userDepartment, 16)}
            {renderData(
              'Creted on',
              moment(ticket?.createdOn)?.format('DD-MM-YYYY hh:mm A'),
              16
            )}
          </>
        </Box>
        <Box heading="Attachments">
          {ticket?.attachments?.map((a: any) => (
            <iframe
              key={a}
              title="Document Preview"
              src={`${getTickets}/${a}`}
              width="100%"
              height="700"
            />
          ))}
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => setSelected()}>
          Close
        </Button>
        <Button color="primary" onClick={() => setModal(true)}>
          Update Status
        </Button>
      </ModalFooter>
      <SubmitModalWithForm
        isOpen={modal}
        onClose={(val: any) => {
          setModal(false)
        }}
        // onSubmit={() => console.log('here')}
        onSubmit={async (e: any) => {
          const body: any = {
            id: ticket?._id,
            status
          }
          const res = await makeAuthenticatedRequest(
            `${ticketApi}/close`,
            'POST',
            body,
            accounts,
            instance
          )

          console.log(res?.status)

          if (res?.status === 'success') {
            await refetch()
          }

          return res?.status
        }}
        size="xs"
        header="Update Status"
        form={
          <Row>
            <Col>
              <CustomLabel label="Update Ticket Status" />
              <Input
                type="select"
                onChange={(e: any) => setStatus(e?.target?.value)}
              >
                <option></option>
                <option>Pending</option>
                <option>Done</option>
                <option>Testing</option>
                <option>Closed</option>
              </Input>
            </Col>
          </Row>
        }
      />
    </Modal>
  )
}
