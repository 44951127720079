import { useContext } from 'react'
import { PermissionsContext } from '../App'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import toNormalCase from '../components/lib/toNormalCase'
import Loader from '../components/Loader'
import PermitsByStatus from './deIsolationAndClose/ViewPermits'

export default function FetchPermitsByLocation({ status, flow }: any) {
  const { permissions } = useContext(PermissionsContext)
  const userlocation = toNormalCase(permissions?.location)
  return (
    <>
      {userlocation ? (
        <PermitsByStatus
          currentPermits={status}
          flow={flow}
          checkLocation
          userLocation={userlocation}
        />
      ) : (
        <ProtectedLayout>
          <Loader />
        </ProtectedLayout>
      )}
    </>
  )
}
