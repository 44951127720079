// const neccesarryData = newdata?.map((t) => ({
//   vessel: t?.vessel,
//   system: t?.sytem,
//   systemDescription: t?.systemDescription,
//   subsystemDescription: t?.subsystemDescription,
//   functionDescription: t?.functionDescription,
//   subSystem: t?.subSystem,
//   function: t?.function,
//   tag: t?.tag,
//   tagDescription: t?.tagDescription
// }));

// export const result = [];

// for (const item of neccesarryData) {
//   const existingVessel = result.find(
//     (vessel) => vessel.name === `${item.vessel} CAT`
//   );

//   if (!existingVessel) {
//     const newVessel = {
//       name: `${item.vessel} CAT`,
//       children: []
//     };
//     result.push(newVessel);
//   } else {
//     const existingSystem = existingVessel.children.find(
//       (system) => system.name === `${item.system} ${item?.systemDescription}`
//     );

//     if (!existingSystem) {
//       const newSystem = {
//         name: `${item.system} ${item?.systemDescription}`,
//         children: []
//       };
//       existingVessel.children.push(newSystem);
//     }

//     const existingSubSystem = existingVessel.children
//       .find((system) => system.name === `${item.system} ${item?.systemDescription}`)
//       ?.children.find(
//         (subSystem) => subSystem.name === `${item.subSystem} ${item?.subsystemDescription}`
//       );

//     if (!existingSubSystem) {
//       const newSubSystem = {
//         name: `${item.subSystem} ${item?.subsystemDescription}`,
//         children: []
//       };
//       existingVessel.children
//         .find((system) => system.name === `${item.system} ${item?.systemDescription}`)
//         ?.children.push(newSubSystem);
//     }

//     const existingFunction = existingVessel.children
//       .find((system) => system.name === `${item.system} ${item?.systemDescription}`)
//       ?.children.find(
//         (subSystem) => subSystem.name === `${item.subSystem} ${item?.subsystemDescription}`
//       )?.children.find((func) => func.name === `${item.function} ${item?.functionDescription}`);

//     if (!existingFunction) {
//       const newFunction = {
//         name: `${item.function} ${item?.functionDescription}`,
//         children: []
//       };
//       existingVessel.children
//         .find((system) => system.name === `${item.system} ${item?.systemDescription}`)
//         ?.children.find(
//           (subSystem) => subSystem.name === `${item.subSystem} ${item?.subsystemDescription}`
//         )?.children.push(newFunction);
//     }

//     existingVessel.children
//       .find((system) => system.name === `${item.system} ${item?.systemDescription}`)
//       ?.children.find(
//         (subSystem) => subSystem.name === `${item.subSystem} ${item?.subsystemDescription}`
//       )?.children.find((func) => func.name === `${item.function} ${item?.functionDescription}`)
//       ?.children.push({
//         name: `${item.tag}`
//       });
//   }
// }

export const treeData =
{
  "name": "UNIT-1",
  "children": [
    {
      "name": "13 Production Flow lines",
      "children": [
        {
          "name": "13.01 Production Flow lines",
          "children": [
            {
              "name": "13.01.030 Production Flow lines",
              "children": [
                {
                  "name": "13-EV-1221"
                },
                {
                  "name": "13-EV-1223"
                },
                {
                  "name": "13-EY-1223"
                },
                {
                  "name": "13-HS-1223"
                },
                {
                  "name": "13-EV-1235"
                },
                {
                  "name": "13-EY-1235"
                },
                {
                  "name": "13-HS-1235"
                },
                {
                  "name": "13-EY-1221"
                },
                {
                  "name": "13-HS-1221"
                },
                {
                  "name": "13-XY-1221"
                },
                {
                  "name": "13-PDSIT-1234"
                },
                {
                  "name": "13-PDSIT-1246"
                },
                {
                  "name": "13-PDSIT-1222"
                },
                {
                  "name": "13-PSIT-1214"
                },
                {
                  "name": "13-PSIT-1226"
                },
                {
                  "name": "13-PSIT-1238"
                }
              ]
            },
            {
              "name": "13.01.040 Production Flow lines",
              "children": [
                {
                  "name": "13-BV-1216"
                },
                {
                  "name": "13-BV-1217"
                },
                {
                  "name": "13-EY-1217"
                },
                {
                  "name": "13-BV-1229"
                },
                {
                  "name": "13-EY-1229"
                },
                {
                  "name": "13-BV-1241"
                },
                {
                  "name": "13-EY-1241"
                },
                {
                  "name": "13-EV-1221"
                },
                {
                  "name": "13-EY-1221"
                },
                {
                  "name": "13-HS-1221"
                },
                {
                  "name": "13-XY-1221"
                },
                {
                  "name": "13-EV-1223"
                },
                {
                  "name": "13-EY-1223"
                },
                {
                  "name": "13-HS-1223"
                },
                {
                  "name": "13-XY-1223"
                },
                {
                  "name": "13-EV-1233"
                },
                {
                  "name": "13-EY-1233"
                },
                {
                  "name": "13-HS-1233"
                },
                {
                  "name": "13-XY-1233"
                },
                {
                  "name": "13-EV-1235"
                },
                {
                  "name": "13-EY-1235"
                },
                {
                  "name": "13-HS-1235"
                },
                {
                  "name": "13-XY-1235"
                },
                {
                  "name": "13-EV-1245"
                },
                {
                  "name": "13-EY-1245"
                },
                {
                  "name": "13-HS-1245"
                },
                {
                  "name": "13-XY-1245"
                },
                {
                  "name": "13-EV-1247"
                },
                {
                  "name": "13-EY-1247"
                },
                {
                  "name": "13-HS-1247"
                },
                {
                  "name": "13-XY-1247"
                },
                {
                  "name": "13-LX-8003"
                },
                {
                  "name": "13-LX-8001"
                }
              ]
            }
          ]
        },
        {
          "name": "13.02 Main- Production Flow Transfer System",
          "children": [
            {
              "name": "13.02.000 Main- Production Flow Transfer System",
              "children": [
                {
                  "name": "13-LX-8002"
                },
                {
                  "name": "13-LX-8000"
                },
                {
                  "name": "13-PSIT-8001"
                }
              ]
            },
            {
              "name": "13.02.030 Main- Production Flow Transfer System",
              "children": [
                {
                  "name": "13-PSIT-8002"
                },
                {
                  "name": "13-PSIT-8003"
                },
                {
                  "name": "13-PSIT-8022"
                },
                {
                  "name": "13-EY-7999"
                }
              ]
            },
            {
              "name": "13.02.040 Main- Production Flow Transfer System",
              "children": [
                {
                  "name": "13-EY-8000"
                },
                {
                  "name": "13-EY-8001"
                },
                {
                  "name": "13-HS-8001"
                },
                {
                  "name": "13-XY-8001"
                },
                {
                  "name": "13-EV-8011"
                },
                {
                  "name": "13-EY-8011"
                },
                {
                  "name": "13-HS-8011"
                },
                {
                  "name": "13-XY-8011"
                },
                {
                  "name": "13-EV-8021"
                },
                {
                  "name": "13-EY-8021"
                },
                {
                  "name": "13-HS-8021"
                },
                {
                  "name": "13-XY-8021"
                }
              ]
            }
          ]
        },
        {
          "name": "13.9 Corrosion Loop - CL-01 Multiphase Fluids",
          "children": [
            {
              "name": "13.90.01 Corrosion Loop - CL-01 Multiphase Fluids",
              "children": [
                {
                  "name": "12-PT-13-1000-FD20-0N"
                },
                {
                  "name": "12-PT-13-1009-FD20-0N"
                },
                {
                  "name": "12-PT-13-1010-FD20-0N"
                },
                {
                  "name": "12-PT-13-1052-FD20-0N"
                },
                {
                  "name": "12-PT-13-1053-FD20-0N"
                },
                {
                  "name": "12-PT-13-1054-FD20-0N"
                },
                {
                  "name": "12-PT-13-8001-FD20-0N"
                },
                {
                  "name": "12-PT-13-8002-FD20-0N"
                },
                {
                  "name": "12-PT-13-8003-FD20-0N"
                },
                {
                  "name": "12-PT-13-8011-FD20-0N"
                },
                {
                  "name": "12-PT-13-8012-FD20-0N"
                },
                {
                  "name": "12-PT-13-8013-FD20-0N"
                },
                {
                  "name": "12-PT-13-8021-FD20-0N"
                },
                {
                  "name": "12-PT-13-8022-FD20-0N"
                },
                {
                  "name": "12-PT-13-8023-FD20-0N"
                },
                {
                  "name": "2-DC-13-1011-FD20-4Y"
                },
                {
                  "name": "2-DC-13-1012-FD20-4Y"
                },
                {
                  "name": "2-DC-13-1013-FD20-4Y"
                },
                {
                  "name": "2-PT-13-8004-FD20-0N"
                },
                {
                  "name": "2-PT-13-8014-FD20-0N"
                },
                {
                  "name": "2-PT-13-8024-FD20-0N"
                },
                {
                  "name": "2-VF-13-1002-FD20-4Y"
                },
                {
                  "name": "2-VF-13-1016-FD20-4Y"
                },
                {
                  "name": "2-VF-13-1017-FD20-4Y"
                },
                {
                  "name": "2-VF-13-1500-FD20-4Y"
                },
                {
                  "name": "2-VF-13-1501-FD20-4Y"
                },
                {
                  "name": "2-VF-13-1502-FD20-4Y"
                },
                {
                  "name": "3-VF-13-1001-FD20-4Y"
                },
                {
                  "name": "3-VF-13-1014-FD20-4Y"
                },
                {
                  "name": "3-VF-13-1015-FD20-4Y"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "name": "18 Unit 1 Prod Well",
      "children": [
        {
          "name": "18.01 Production Well 1 (CP1)",
          "children": [
            {
              "name": "18.01.10 Production Well 1 (CP1)",
              "children": [
                {
                  "name": "18.01.10.040"
                },
                {
                  "name": "18-EV-1001"
                },
                {
                  "name": "18-EY-1001"
                },
                {
                  "name": "18-EV-1002"
                },
                {
                  "name": "18-EY-1002"
                },
                {
                  "name": "18-EV-1003"
                },
                {
                  "name": "18-EY-1003"
                },
                {
                  "name": "18-EY-1004"
                }
              ]
            },
            {
              "name": "18.01.12 Production Well 1 (CP1)",
              "children": [
                {
                  "name": "18-EY-1005"
                },
                {
                  "name": "18-UC-1100"
                },
                {
                  "name": "18-UC-1101"
                },
                {
                  "name": "18-SSV-1112"
                },
                {
                  "name": "18-SSV-1113"
                }
              ]
            },
            {
              "name": "18.01.13 Production Well 1 (CP1)",
              "children": [
                {
                  "name": "18-SSV-1114"
                },
                {
                  "name": "18-UC-1200"
                },
                {
                  "name": "18-UC-1201"
                },
                {
                  "name": "18-SSV-1212"
                },
                {
                  "name": "18-SSV-1213"
                }
              ]
            },
            {
              "name": "18.01.14 Production Well 1 (CP1)",
              "children": [
                {
                  "name": "18-UC-1299"
                },
                {
                  "name": "18-UC-1300"
                },
                {
                  "name": "18-SSV-1311"
                },
                {
                  "name": "18-SSV-1312"
                },
                {
                  "name": "18-UC-1398"
                }
              ]
            },
            {
              "name": "18.01.15 Production Well 1 (CP1)",
              "children": [
                {
                  "name": "18-UC-1399"
                },
                {
                  "name": "18-UC-1400"
                },
                {
                  "name": "18-SSV-1411"
                },
                {
                  "name": "18-SSV-1412"
                },
                {
                  "name": "18-UC-1498"
                }
              ]
            },
            {
              "name": "18.01.16 Production Well 1 (CP1)",
              "children": [
                {
                  "name": "18-UC-1499"
                },
                {
                  "name": "18-UC-1500"
                },
                {
                  "name": "18-SSV-1511"
                },
                {
                  "name": "18-SSV-1512"
                }
              ]
            },
            {
              "name": "18.01.17 Production Well 1 (CP1)",
              "children": [
                {
                  "name": "18-UC-1599"
                },
                {
                  "name": "18-UC-1600"
                },
                {
                  "name": "18-SSV-1611"
                },
                {
                  "name": "18-SSV-1612"
                }
              ]
            },
            {
              "name": "18.01.18 Production Well 1 (CP1)",
              "children": [
                {
                  "name": "18-UC-1699"
                },
                {
                  "name": "18-UC-1700"
                },
                {
                  "name": "18-SSV-1706"
                },
                {
                  "name": "18-SSV-1707"
                }
              ]
            },
            {
              "name": "18.01.19 Production Well 1 (CP1)",
              "children": [
                {
                  "name": "18-SSV-1708"
                },
                {
                  "name": "18-UC-1800"
                },
                {
                  "name": "18-UC-1801"
                },
                {
                  "name": "18-UC-1802"
                }
              ]
            },
            {
              "name": "18.01.20 Production Well 1 (CP1)",
              "children": [
                {
                  "name": "18-UC-1899"
                },
                {
                  "name": "18-UC-1900"
                },
                {
                  "name": "18-SSV-1906"
                },
                {
                  "name": "18-SSV-1907"
                }
              ]
            }
          ]
        },
        {
          "name": "18.02 Prod well 1 (VP1)",
          "children": [
            {
              "name": "18.02.10 Prod well 1 (VP1)",
              "children": [
                {
                  "name": "18-SSV-1908"
                },
                {
                  "name": "18-EV-2001"
                },
                {
                  "name": "18-EY-2001"
                },
                {
                  "name": "18-EV-2002"
                },
                {
                  "name": "18-EY-2002"
                }
              ]
            },
            {
              "name": "18.02.12 Prod well 1 (VP1)",
              "children": [
                {
                  "name": "18-EY-2003"
                },
                {
                  "name": "18-UC-2100"
                },
                {
                  "name": "18-UC-2101"
                },
                {
                  "name": "18-SSV-2112"
                }
              ]
            },
            {
              "name": "18.02.13 Prod well 1 (VP1)",
              "children": [
                {
                  "name": "18-SSV-2113"
                },
                {
                  "name": "18-UC-2200"
                },
                {
                  "name": "18-UC-2201"
                },
                {
                  "name": "18-SSV-2212"
                }
              ]
            },
            {
              "name": "18.02.14 Prod well 1 (VP1)",
              "children": [
                {
                  "name": "18-SSV-2213"
                },
                {
                  "name": "18-UC-2300"
                },
                {
                  "name": "18-UC-2301"
                },
                {
                  "name": "18-SSV-2312"
                }
              ]
            },
            {
              "name": "18.02.15 Prod well 1 (VP1)",
              "children": [
                {
                  "name": "18-SSV-2313"
                },
                {
                  "name": "18-UC-2400"
                },
                {
                  "name": "18-UC-2401"
                },
                {
                  "name": "18-SSV-2412"
                }
              ]
            },
            {
              "name": "18.02.16 Prod well 1 (VP1)",
              "children": [
                {
                  "name": "18-SSV-2413"
                },
                {
                  "name": "18-UC-2500"
                },
                {
                  "name": "18-UC-2501"
                },
                {
                  "name": "18-SSV-2512"
                }
              ]
            },
            {
              "name": "18.02.17 Prod well 1 (VP1)",
              "children": [
                {
                  "name": "18-SSV-2513"
                },
                {
                  "name": "18-UC-2600"
                },
                {
                  "name": "18-UC-2601"
                },
                {
                  "name": "18-SSV-2612"
                }
              ]
            },
            {
              "name": "18.02.18 Prod well 1 (VP1)",
              "children": [
                {
                  "name": "18-SSV-2613"
                },
                {
                  "name": "18-UC-2700"
                },
                {
                  "name": "18-UC-2701"
                },
                {
                  "name": "18-SSV-2707"
                }
              ]
            },
            {
              "name": "18.02.19 Prod well 1 (VP1)",
              "children": [
                {
                  "name": "18-SSV-2708"
                },
                {
                  "name": "18-UC-2800"
                },
                {
                  "name": "18-UC-2801"
                },
                {
                  "name": "18-SSV-2807"
                }
              ]
            },
            {
              "name": "18.02.20 Prod well 1 (VP1)",
              "children": [
                {
                  "name": "18-SSV-2808"
                },
                {
                  "name": "18-UC-2900"
                },
                {
                  "name": "18-UC-2901"
                },
                {
                  "name": "18-SSV-2907"
                }
              ]
            }
          ]
        },
        {
          "name": "18.03 Prod well 1 (VP1)",
          "children": [
            {
              "name": "18.03.10 Prod well 1 (VP1)",
              "children": [
                {
                  "name": "18-SSV-2908"
                }
              ]
            }
          ]
        },
        {
          "name": "18.03 Riser Base Towhead",
          "children": [
            {
              "name": "18.03.10 Riser Base Towhead",
              "children": [
                {
                  "name": "18-EV-3001"
                },
                {
                  "name": "18-EY-3001"
                },
                {
                  "name": "18-EV-3002"
                },
                {
                  "name": "18-EY-3002"
                }
              ]
            },
            {
              "name": "18.03.12 Riser Base Towhead",
              "children": [
                {
                  "name": "18-EY-3003"
                },
                {
                  "name": "18-UC-3100"
                },
                {
                  "name": "18-UC-3101"
                },
                {
                  "name": "18-SSV-3112"
                }
              ]
            },
            {
              "name": "18.03.13 Riser Base Towhead",
              "children": [
                {
                  "name": "18-UC-3200"
                },
                {
                  "name": "18-UC-3201"
                },
                {
                  "name": "18-SSV-3212"
                }
              ]
            },
            {
              "name": "18.03.14 Riser Base Towhead",
              "children": [
                {
                  "name": "18-UC-3300"
                },
                {
                  "name": "18-SSV-3312"
                }
              ]
            },
            {
              "name": "18.03.15 Riser Base Towhead",
              "children": [
                {
                  "name": "18-UC-3400"
                },
                {
                  "name": "18-SSV-3412"
                }
              ]
            },
            {
              "name": "18.03.16 Riser Base Towhead",
              "children": [
                {
                  "name": "18-UC-3500"
                },
                {
                  "name": "18-SSV-3512"
                }
              ]
            },
            {
              "name": "18.03.17 Riser Base Towhead",
              "children": [
                {
                  "name": "18-UC-3600"
                },
                {
                  "name": "18-SSV-3612"
                }
              ]
            },
            {
              "name": "18.03.18 Riser Base Towhead",
              "children": [
                {
                  "name": "18-UC-3700"
                },
                {
                  "name": "18-SSV-3707"
                }
              ]
            },
            {
              "name": "18.03.19 Riser Base Towhead",
              "children": [
                {
                  "name": "18-UC-3800"
                },
                {
                  "name": "18-SSV-3807"
                }
              ]
            },
            {
              "name": "18.03.20 Riser Base Towhead",
              "children": [
                {
                  "name": "18-UC-3900"
                },
                {
                  "name": "18-SSV-3907"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "name": "20 1st Stage Separator",
      "children": [
        {
          "name": "20.01 Inlet Heating and 1st Stage Separator",
          "children": [
            {
              "name": "20.01.030 Inlet Heating and 1st Stage Separator",
              "children": [
                {
                  "name": "18-SSV-3908"
                },
                {
                  "name": "20-LSIT-1051"
                },
                {
                  "name": "20-LSIT-1054"
                },
                {
                  "name": "20-PSIT-1057"
                },
                {
                  "name": "20-PSIT-1252"
                },
                {
                  "name": "20-TSIT-1037"
                },
                {
                  "name": "20-XV-1063"
                },
                {
                  "name": "20-XV-1064"
                }
              ]
            },
            {
              "name": "20.01.040 Inlet Heating and 1st Stage Separator",
              "children": [
                {
                  "name": "20-XV-1065"
                },
                {
                  "name": "20-BV-1061"
                },
                {
                  "name": "20-EY-1061"
                },
                {
                  "name": "20-EY-1062"
                },
                {
                  "name": "20-PSV-1033"
                },
                {
                  "name": "20-PSV-1034"
                },
                {
                  "name": "20-PSV-1043"
                },
                {
                  "name": "20-PSV-1044"
                },
                {
                  "name": "20-PSV-1058"
                },
                {
                  "name": "20-PSV-1059"
                },
                {
                  "name": "20-PSV-1200"
                },
                {
                  "name": "20-PSV-1201"
                },
                {
                  "name": "20-PSV-1202"
                },
                {
                  "name": "20-PSV-1203"
                }
              ]
            }
          ]
        },
        {
          "name": "20.02 Inlet Heating and Test Separator",
          "children": [
            {
              "name": "20.02.030 Inlet Heating and Test Separator",
              "children": [
                {
                  "name": "20-LSIT-1011"
                },
                {
                  "name": "20-LSIT-1014"
                },
                {
                  "name": "20-PSIT-1016"
                },
                {
                  "name": "20-XV-1022"
                },
                {
                  "name": "20-XV-1023"
                },
                {
                  "name": "20-XV-1039"
                }
              ]
            },
            {
              "name": "20.02.040 Inlet Heating and Test Separator",
              "children": [
                {
                  "name": "20-BV-1020"
                },
                {
                  "name": "20-EY-1020"
                },
                {
                  "name": "20-PSV-1002"
                },
                {
                  "name": "20-PSV-1003"
                },
                {
                  "name": "20-PSV-1017"
                },
                {
                  "name": "20-PSV-1018"
                },
                {
                  "name": "20-PSV-1211"
                },
                {
                  "name": "20-PSV-1212"
                }
              ]
            }
          ]
        },
        {
          "name": "20.03 Crude /Crude Exchanger A/B",
          "children": [
            {
              "name": "20.03.100 Crude /Crude Exchanger A/B",
              "children": [
                {
                  "name": "20-PSV-1213"
                },
                {
                  "name": "20-PSV-1214"
                },
                {
                  "name": "20-PSV-1215"
                },
                {
                  "name": "20-PSV-1665"
                },
                {
                  "name": "20-PSV-1666"
                },
                {
                  "name": "20-PSV-1667"
                },
                {
                  "name": "20-PSV-1668"
                },
                {
                  "name": "20-PSV-1673"
                },
                {
                  "name": "20-PSV-1674"
                },
                {
                  "name": "20-PSV-1675"
                },
                {
                  "name": "20-PSV-1676"
                }
              ]
            }
          ]
        },
        {
          "name": "20.04 Interstage Crude heater",
          "children": [
            {
              "name": "20.04.030 Interstage Crude heater",
              "children": [
                {
                  "name": "20-PSV-1677"
                },
                {
                  "name": "20-TSIT-1538"
                },
                {
                  "name": "20-TE-1538"
                },
                {
                  "name": "20-TE-1539"
                },
                {
                  "name": "20-TE-1540"
                },
                {
                  "name": "20-PSV-1671"
                },
                {
                  "name": "20-PSV-1672"
                },
                {
                  "name": "20-PSV-1690"
                },
                {
                  "name": "20-PSV-1691"
                }
              ]
            }
          ]
        },
        {
          "name": "20.05 2ND Stage Separator and Pump",
          "children": [
            {
              "name": "20.05.030 2ND Stage Separator and Pump",
              "children": [
                {
                  "name": "20-PSV-1692"
                },
                {
                  "name": "20-LSIT-1545"
                },
                {
                  "name": "20-LSIT-1548"
                },
                {
                  "name": "20-PSIT-1540"
                },
                {
                  "name": "20-XV-1553"
                },
                {
                  "name": "20-XV-1555"
                },
                {
                  "name": "20-XV-1678"
                },
                {
                  "name": "20-XV-1679"
                }
              ]
            },
            {
              "name": "20.05.050 2ND Stage Separator and Pump",
              "children": [
                {
                  "name": "20-BV-1551"
                },
                {
                  "name": "20-EY-1551"
                },
                {
                  "name": "20-EY-1552"
                },
                {
                  "name": "20-PSV-1541"
                },
                {
                  "name": "20-PSV-1542"
                }
              ]
            }
          ]
        },
        {
          "name": "20.06 Crude oil Booster Pump A/B",
          "children": [
            {
              "name": "20.06.030 Crude oil Booster Pump A/B",
              "children": [
                {
                  "name": "20-PSV-1543"
                },
                {
                  "name": "20-PSIT-1030A"
                },
                {
                  "name": "20-PSIT-1030B"
                },
                {
                  "name": "20-PSIT-1031A"
                },
                {
                  "name": "20-PSIT-1031B"
                },
                {
                  "name": "20-PSIT-1560"
                },
                {
                  "name": "20-PSIT-1562"
                },
                {
                  "name": "20-PSIT-1565"
                }
              ]
            }
          ]
        },
        {
          "name": "20.06 Electrostatic Coalescer",
          "children": [
            {
              "name": "20.06.030 Electrostatic Coalescer",
              "children": [
                {
                  "name": "20-PSIT-1567"
                }
              ]
            }
          ]
        },
        {
          "name": "20.07 Electrostatic Coalescer",
          "children": [
            {
              "name": "20.07.030 Electrostatic Coalescer",
              "children": [
                {
                  "name": "20-PSIT-1568"
                },
                {
                  "name": "20-LSIT-1571"
                },
                {
                  "name": "20-LSIT-1572"
                },
                {
                  "name": "20-PSHH-1586"
                },
                {
                  "name": "20-PSIT-1576"
                },
                {
                  "name": "20-TSHH-1585"
                },
                {
                  "name": "20-XV-1679"
                }
              ]
            },
            {
              "name": "20.07.070 Electrostatic Coalescer",
              "children": [
                {
                  "name": "20-XV-1680"
                },
                {
                  "name": "20-PSV-1574"
                },
                {
                  "name": "20-PSV-1575"
                },
                {
                  "name": "20-PSV-1582"
                }
              ]
            }
          ]
        },
        {
          "name": "20.08 Production and Test manifold",
          "children": [
            {
              "name": "20.08.030 Production and Test manifold",
              "children": [
                {
                  "name": "20-PSV-1583"
                },
                {
                  "name": "20-PSV-1584"
                },
                {
                  "name": "20-PSIT-1262"
                },
                {
                  "name": "20-XV-1261"
                },
                {
                  "name": "20-XV-1262"
                },
                {
                  "name": "20-EY-1260"
                },
                {
                  "name": "20-EY-1261"
                }
              ]
            },
            {
              "name": "20.08.040 Production and Test manifold",
              "children": [
                {
                  "name": "20-BV-1250"
                },
                {
                  "name": "20-BV-1260"
                }
              ]
            },
            {
              "name": "20.08.100 Production and Test manifold",
              "children": [
                {
                  "name": "20-PSV-1255"
                },
                {
                  "name": "20-PSV-1256"
                },
                {
                  "name": "20-PSV-1257"
                },
                {
                  "name": "20-PSV-1258"
                },
                {
                  "name": "20-PSV-1265"
                },
                {
                  "name": "20-PSV-1266"
                },
                {
                  "name": "20-PSV-1267"
                }
              ]
            },
            {
              "name": "20.09.030 Production and Test manifold",
              "children": [
                {
                  "name": "20-PSV-1268"
                }
              ]
            }
          ]
        },
        {
          "name": "20.09 Condensate Return Manifold",
          "children": [
            {
              "name": "20.09.030 Condensate Return Manifold",
              "children": [
                {
                  "name": "20-PSV-1269"
                },
                {
                  "name": "20-PSIT-1701"
                },
                {
                  "name": "20-PSIT-1702"
                }
              ]
            },
            {
              "name": "20.09.100 Condensate Return Manifold",
              "children": [
                {
                  "name": "20-PSV-1702"
                },
                {
                  "name": "20-PSV-1703"
                }
              ]
            }
          ]
        },
        {
          "name": "20.9 Condensate Return Manifold",
          "children": [
            {
              "name": "20.90.10 Condensate Return Manifold",
              "children": [
                {
                  "name": "10-VF-20-1243-AD20-4Y"
                },
                {
                  "name": "10-VF-20-1245-AD20-4Y"
                },
                {
                  "name": "10-VF-20-1246-AD20-4Y"
                },
                {
                  "name": "12-PT-20-1018-AD20-1N"
                },
                {
                  "name": "12-PT-20-1019-AD20-0N"
                },
                {
                  "name": "12-PT-20-1020-AD20-1N"
                },
                {
                  "name": "12-PT-20-1031-AD20-1N"
                },
                {
                  "name": "12-PT-20-1032-AD20-4Y"
                },
                {
                  "name": "12-PT-20-1110-AD20-0N"
                },
                {
                  "name": "12-PT-20-1111-AD20-1N"
                },
                {
                  "name": "12-PT-20-1112-AD20-1N"
                },
                {
                  "name": "12-PT-20-1113-AD20-1N"
                },
                {
                  "name": "12-PT-20-1167-AD20-0N"
                },
                {
                  "name": "12-PT-20-1168-AD20-1N"
                },
                {
                  "name": "12-PT-20-1172-AD20-4Y"
                },
                {
                  "name": "12-PT-20-1186-FD20-0N"
                },
                {
                  "name": "12-PT-20-1187-FD20-0N"
                },
                {
                  "name": "12-PT-20-1188-FD20-0N"
                },
                {
                  "name": "12-PT-20-1189-FD20-0N"
                },
                {
                  "name": "12-PT-20-1190-FD20-0N"
                },
                {
                  "name": "12-PT-20-1191-FD20-0N"
                },
                {
                  "name": "12-PT-20-1300-AD20-0N"
                },
                {
                  "name": "12-PT-20-1301-AD20-0N"
                },
                {
                  "name": "12-VF-20-1242-AD20-4Y"
                },
                {
                  "name": "14-PT-20-1194-FD20-0N"
                },
                {
                  "name": "14-VF-20-1244-AD20-4Y"
                },
                {
                  "name": "18-PT-20-1018-AD20-1N"
                },
                {
                  "name": "18-PT-20-1021-AD20-4Y"
                },
                {
                  "name": "18-PT-20-1110-AD20-0N"
                },
                {
                  "name": "18-PT-20-1112-AD20-1N"
                },
                {
                  "name": "18-PT-20-1120-AD20-4Y"
                },
                {
                  "name": "18-PT-20-1193-FD20-0N"
                },
                {
                  "name": "2-DC-20-1028-AD20-4Y"
                },
                {
                  "name": "2-DC-20-1029-AD20-4Y"
                },
                {
                  "name": "2-DC-20-1036-AD20-4Y"
                },
                {
                  "name": "2-DC-20-1114-AD20-4Y"
                },
                {
                  "name": "2-DC-20-1115-AD20-4Y"
                },
                {
                  "name": "2-DC-20-1171-AD20-4Y"
                },
                {
                  "name": "2-DC-20-1201-FD20-4Y"
                },
                {
                  "name": "2-DC-20-1202-FD20-4Y"
                },
                {
                  "name": "2-VF-20-1024-AD20-4Y"
                },
                {
                  "name": "2-VF-20-1027-AD20-4Y"
                },
                {
                  "name": "2-VF-20-1035-AD20-4Y"
                },
                {
                  "name": "2-VF-20-1210-AD20-4Y"
                },
                {
                  "name": "2-VF-20-1211-AD20-4Y"
                },
                {
                  "name": "2-VF-20-1212-AD20-4Y"
                },
                {
                  "name": "3-VF-20-1022-AD20-4Y"
                },
                {
                  "name": "3-VF-20-1023-AD20-4Y"
                },
                {
                  "name": "3-VF-20-1025-AD20-4Y"
                },
                {
                  "name": "3-VF-20-1026-AD20-4Y"
                },
                {
                  "name": "3-VF-20-1033-AD20-4Y"
                },
                {
                  "name": "3-VF-20-1034-AD20-4Y"
                },
                {
                  "name": "3-VF-20-1208-FD20-4Y"
                },
                {
                  "name": "3-VF-20-1209-FD20-4Y"
                },
                {
                  "name": "4-VF-20-1116-AD20-4Y"
                },
                {
                  "name": "4-VF-20-1117-AD20-4Y"
                },
                {
                  "name": "4-VF-20-1118-AD20-4Y"
                },
                {
                  "name": "4-VF-20-1119-AD20-4Y"
                },
                {
                  "name": "6-VF-20-1169-AD20-4Y"
                },
                {
                  "name": "6-VF-20-1170-AD20-4Y"
                },
                {
                  "name": "8-VF-20-1247-AD20-4Y"
                },
                {
                  "name": "8-VF-20-1248-AD20-4Y"
                },
                {
                  "name": "2-PL-20-1253-BC21-0N"
                },
                {
                  "name": "10-PV-20-1173-AD20-0N"
                },
                {
                  "name": "10-VF-20-1123-AD20-0N"
                },
                {
                  "name": "14-PV-20-1121-AD20-0N"
                },
                {
                  "name": "2-VF-20-1122-AD20-0N"
                },
                {
                  "name": "2-VF-20-1174-AD20-0N"
                },
                {
                  "name": "3-PV-20-1137-AD20-0N"
                },
                {
                  "name": "6-PV-20-1281-AD20-3N"
                },
                {
                  "name": "6-VF-20-1126-AD20-0N"
                },
                {
                  "name": "6-VF-20-1127-AD20-0N"
                },
                {
                  "name": "6-VF-20-1177-AD20-0N"
                },
                {
                  "name": "6-VF-20-1180-AD20-0N"
                },
                {
                  "name": "8-VF-20-1175-AD20-0N"
                },
                {
                  "name": "10-PV-20-1176-AD20-3N"
                },
                {
                  "name": "6-VF-20-1146-AD20-3N"
                },
                {
                  "name": "6-VF-20-1147-AD20-3N"
                },
                {
                  "name": "8-VF-20-1197-AD20-3N"
                },
                {
                  "name": "8-VF-20-1198-AD20-3N"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "name": "21 Crude Oil Cooler A/B",
      "children": [
        {
          "name": "21.01 Crude Oil Cooler A/B",
          "children": [
            {
              "name": "21.01.030 Crude Oil Cooler A/B",
              "children": [
                {
                  "name": "21-TSIT-1598"
                }
              ]
            }
          ]
        },
        {
          "name": "21.01 Process Shutdown - Crude Oil Cooler A/B",
          "children": [
            {
              "name": "21.01.030 Process Shutdown - Crude Oil Cooler A/B",
              "children": [
                {
                  "name": "21-TSIT-1599"
                },
                {
                  "name": "21-TW-1599"
                },
                {
                  "name": "21-TW-1600"
                },
                {
                  "name": "21-EV-1598"
                },
                {
                  "name": "21-EY-1598"
                },
                {
                  "name": "21-HS-1598"
                },
                {
                  "name": "21-XY-1598"
                }
              ]
            },
            {
              "name": "21.01.040 Process Shutdown - Crude Oil Cooler A/B",
              "children": [
                {
                  "name": "21-EV-1606"
                },
                {
                  "name": "21-EY-1606"
                },
                {
                  "name": "21-HS-1606"
                },
                {
                  "name": "21-XY-1606"
                }
              ]
            },
            {
              "name": "21.01.100 Process Shutdown - Crude Oil Cooler A/B",
              "children": [
                {
                  "name": "21-PSV-1594"
                },
                {
                  "name": "21-PSV-1604"
                }
              ]
            }
          ]
        },
        {
          "name": "21.02 Safety - Cargo loading Header",
          "children": [
            {
              "name": "21.02.100 Safety - Cargo loading Header",
              "children": [
                {
                  "name": "21-PSV-2182"
                },
                {
                  "name": "21-PSV-2192"
                }
              ]
            }
          ]
        },
        {
          "name": "21.03 Cargo Offloading and Transfer",
          "children": [
            {
              "name": "21.02.100 Cargo Offloading and Transfer",
              "children": [
                {
                  "name": "21-PSV-2193"
                }
              ]
            },
            {
              "name": "21.03.090 Cargo Offloading and Transfer",
              "children": [
                {
                  "name": "21-PIT-0384"
                }
              ]
            }
          ]
        },
        {
          "name": "21.04 Cargo Oil Tanks",
          "children": [
            {
              "name": "21.04.10 Cargo Oil Tanks",
              "children": [
                {
                  "name": "21-PIT-0385"
                },
                {
                  "name": "21-TB-S-C0-2C"
                },
                {
                  "name": "21-TB-S-C0-2S"
                },
                {
                  "name": "21-TB-S-C0-3C"
                },
                {
                  "name": "21-TB-S-C0-3P"
                },
                {
                  "name": "21-TB-S-C0-4C"
                },
                {
                  "name": "21-TB-S-C0-4P"
                },
                {
                  "name": "21-TB-S-C0-4S"
                },
                {
                  "name": "21-TB-S-C0-5P"
                },
                {
                  "name": "21-TB-S-C0-5S"
                },
                {
                  "name": "21-TB-S-C0-5S"
                }
              ]
            },
            {
              "name": "21.04.030 Cargo Oil Tanks",
              "children": [
                {
                  "name": "21-HV-0216"
                },
                {
                  "name": "21-HY-0216"
                },
                {
                  "name": "21-ZE-0216"
                },
                {
                  "name": "21-LSHH-0319"
                },
                {
                  "name": "21-LSHH-0320"
                },
                {
                  "name": "21-LSHH-0321"
                },
                {
                  "name": "21-LSHH-0322"
                },
                {
                  "name": "21-LSHH-0333"
                },
                {
                  "name": "21-LSHH-0334"
                },
                {
                  "name": "21-LSHH-0335"
                },
                {
                  "name": "21-LSHH-0336"
                },
                {
                  "name": "21-LSHH-0337"
                },
                {
                  "name": "21-LSHH-0339"
                },
                {
                  "name": "21-LSHH-0350"
                },
                {
                  "name": "21-LSHH-0351"
                },
                {
                  "name": "21-LSHH-0352"
                },
                {
                  "name": "21-LSHH-0353"
                },
                {
                  "name": "21-LSHH-0354"
                },
                {
                  "name": "21-LSHH-0355"
                },
                {
                  "name": "21-LSHH-0356"
                },
                {
                  "name": "21-LSHH-0357"
                },
                {
                  "name": "21-LSHH-0358"
                },
                {
                  "name": "21-LSHH-0359"
                }
              ]
            },
            {
              "name": "21.04.090 Cargo Oil Tanks",
              "children": [
                {
                  "name": "21-LT-0062"
                },
                {
                  "name": "21-LT-0063"
                },
                {
                  "name": "21-LT-0064"
                },
                {
                  "name": "21-LT-0065"
                },
                {
                  "name": "21-LT-0066"
                },
                {
                  "name": "21-LT-0067"
                },
                {
                  "name": "21-LT-0068"
                },
                {
                  "name": "21-LT-0069"
                },
                {
                  "name": "21-LT-0070"
                },
                {
                  "name": "21-LT-0072"
                },
                {
                  "name": "21-PT-0062"
                },
                {
                  "name": "21-PT-0063"
                },
                {
                  "name": "21-PT-0064"
                },
                {
                  "name": "21-PT-0065"
                },
                {
                  "name": "21-PT-0066"
                },
                {
                  "name": "21-PT-0067"
                },
                {
                  "name": "21-PT-0068"
                },
                {
                  "name": "21-PT-0069"
                },
                {
                  "name": "21-PT-0070"
                },
                {
                  "name": "21-PT-0072"
                }
              ]
            }
          ]
        },
        {
          "name": "21.05 Cargo Oil Pumps",
          "children": [
            {
              "name": "21.05.030 Cargo Oil Pumps",
              "children": [
                {
                  "name": "21-PT-0073"
                },
                {
                  "name": "21-PT-0074"
                },
                {
                  "name": "21-PSIT-0086"
                },
                {
                  "name": "21-PSIT-0092"
                },
                {
                  "name": "21-PSIT-0097"
                },
                {
                  "name": "21-PSIT-0102"
                },
                {
                  "name": "21-PSIT-0107"
                },
                {
                  "name": "21-PSIT-0112"
                },
                {
                  "name": "21-PSIT-0117"
                },
                {
                  "name": "21-PSIT-0122"
                },
                {
                  "name": "21-PSIT-0127"
                },
                {
                  "name": "21-PSIT-0137"
                }
              ]
            },
            {
              "name": "21.05.090 Cargo Oil Pumps",
              "children": [
                {
                  "name": "21-PIT-0571"
                }
              ]
            }
          ]
        },
        {
          "name": "21.07 Offloading Hose and Hose Reel",
          "children": [
            {
              "name": "21.07.000 Offloading Hose and Hose Reel",
              "children": [
                {
                  "name": "21-MN-2900"
                },
                {
                  "name": "21-LX-2907"
                },
                {
                  "name": "21-LX-2908"
                },
                {
                  "name": "21-LX-2909"
                },
                {
                  "name": "21-LX-2910"
                },
                {
                  "name": "21-PSIT-2902"
                },
                {
                  "name": "21-PSIT-2903"
                },
                {
                  "name": "21-EV-0196"
                },
                {
                  "name": "21-EY-0196"
                },
                {
                  "name": "21-XY-0196"
                },
                {
                  "name": "21-HS-2903"
                }
              ]
            }
          ]
        },
        {
          "name": "21.09 Crude Oil Metering",
          "children": [
            {
              "name": "21.09.100 Crude Oil Metering",
              "children": [
                {
                  "name": "21-HS-2904"
                }
              ]
            }
          ]
        },
        {
          "name": "21.1 Safety - Oil Sample System",
          "children": [
            {
              "name": "21.10.100 Safety - Oil Sample System",
              "children": [
                {
                  "name": "21-PSV-2204"
                },
                {
                  "name": "21-PSV-2225"
                }
              ]
            }
          ]
        },
        {
          "name": "21.9 Corrosion Loop - CL-04 Process Liquid ",
          "children": [
            {
              "name": "21.90.040 Corrosion Loop - CL-04 Process Liquid ",
              "children": [
                {
                  "name": "0800-PL-21-0005-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0007-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0009-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0010-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0011-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0014-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0017-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0021-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0023-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0076-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0087-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0100-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0113-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0132-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0133-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0161-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0164-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0167-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0170-DC52-0N"
                },
                {
                  "name": "0800-PL-21-0178-DC50-0N"
                },
                {
                  "name": "0800-PL-21-0219-DC50-0N"
                },
                {
                  "name": "0800-PL-21-0220-DC50-0N"
                },
                {
                  "name": "0800-PL-21-0221-DC50-0N"
                },
                {
                  "name": "0800-PL-21-0222-DC50-0N"
                },
                {
                  "name": "0800-PL-21-0223-DC50-0N"
                },
                {
                  "name": "0800-PL-21-0224-DC50-0N"
                },
                {
                  "name": "0800-PL-21-0225-DC50-0N"
                },
                {
                  "name": "0800-PL-21-0226-DC50-0N"
                },
                {
                  "name": "0800-PL-21-0444-DC52-0N"
                },
                {
                  "name": "1000-PL-21-0027-DC52-0N"
                },
                {
                  "name": "1000-PL-21-0030-DC52-0N"
                },
                {
                  "name": "1000-PL-21-0034-DC52-0N"
                },
                {
                  "name": "1000-PL-21-0035-DC52-0N"
                },
                {
                  "name": "1000-PL-21-0038-DC52-0N"
                },
                {
                  "name": "1000-PL-21-0040-DC52-0N"
                },
                {
                  "name": "1000-PL-21-0041-DC52-0N"
                },
                {
                  "name": "1000-PL-21-0043-DC52-0N"
                },
                {
                  "name": "1000-PL-21-0070-DC52-0N"
                },
                {
                  "name": "1000-PL-21-0089-DC52-0N"
                },
                {
                  "name": "1000-PL-21-0102-DC52-0N"
                },
                {
                  "name": "1000-PL-21-0115-DC52-0N"
                },
                {
                  "name": "1000-PL-21-0217-DC52-0N"
                },
                {
                  "name": "1000-PL-21-0434-DC52-0N"
                },
                {
                  "name": "1000-PL-21-0445-DC52-0N"
                },
                {
                  "name": "1000-PL-21-0835-DC52-0N"
                },
                {
                  "name": "1000-PL-21-0836-DC52-0N"
                },
                {
                  "name": "1200-PL-21-0033-DC52-0N"
                },
                {
                  "name": "1200-PL-21-0036-DC52-0N"
                },
                {
                  "name": "1200-PL-21-0039-DC52-0N"
                },
                {
                  "name": "1400-PL-21-0436-DC52-0N"
                },
                {
                  "name": "20-PL-21-2250-AC11-0N"
                },
                {
                  "name": "20-PL-21-2251-AC11-0N"
                },
                {
                  "name": "20-PL-21-2252-AC11-0N"
                },
                {
                  "name": "20-PL-21-2253-AC11-0N"
                },
                {
                  "name": "2000-PL-21-0177-DC52-0N"
                },
                {
                  "name": "24-PL-21-2254-AC11-0N"
                },
                {
                  "name": "2600-PL-21-0176-DC52-0N"
                },
                {
                  "name": "30-PL-21-1056-AC21-0N"
                },
                {
                  "name": "30-PL-21-1057-AC21-0N"
                },
                {
                  "name": "3000-PL-21-0029-DC52-0N"
                },
                {
                  "name": "3000-PL-21-0173-DC52-0N"
                },
                {
                  "name": "3000-PL-21-0212-DC50-0N"
                },
                {
                  "name": "3000-PL-21-0367-DC52-0N"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "name": "23 Flash Gas Compressor",
      "children": [
        {
          "name": "23.01 Process Shutdown - Flash Gas Compressor",
          "children": [
            {
              "name": "23.01.030 Process Shutdown - Flash Gas Compressor",
              "children": [
                {
                  "name": "23-LSIT-1631"
                },
                {
                  "name": "23-LSIT-1632"
                },
                {
                  "name": "23-PDSIT-6115"
                },
                {
                  "name": "23-PSIT-1643A"
                },
                {
                  "name": "23-PSIT-1643B"
                },
                {
                  "name": "23-PSIT-1651"
                },
                {
                  "name": "23-PSIT-6010A"
                },
                {
                  "name": "23-PSIT-6010B"
                },
                {
                  "name": "23-PSIT-6103"
                },
                {
                  "name": "23-TSHH-6201"
                },
                {
                  "name": "23-TSIT-6101"
                },
                {
                  "name": "23-VE-6100"
                },
                {
                  "name": "23-VE-6101"
                },
                {
                  "name": "23-VE-6104"
                },
                {
                  "name": "23-VE-6105"
                },
                {
                  "name": "23-XV-1625"
                },
                {
                  "name": "23-XY-1625"
                },
                {
                  "name": "23-XV-1634"
                },
                {
                  "name": "23-XY-1634"
                },
                {
                  "name": "23-XV-1658"
                },
                {
                  "name": "23-XY-1658"
                },
                {
                  "name": "23-XV-6117"
                },
                {
                  "name": "23-XY-6117"
                },
                {
                  "name": "23-XV-6118"
                },
                {
                  "name": "23-XY-6118"
                },
                {
                  "name": "23-XV-6119"
                },
                {
                  "name": "23-XY-6119"
                },
                {
                  "name": "23-XY-6120"
                }
              ]
            },
            {
              "name": "23.01.040 Process Shutdown - Flash Gas Compressor",
              "children": [
                {
                  "name": "23-BV-1659"
                },
                {
                  "name": "23-EY-1659"
                },
                {
                  "name": "23-ES-6100"
                },
                {
                  "name": "23-ES-6101"
                }
              ]
            },
            {
              "name": "23.01.100 Process Shutdown - Flash Gas Compressor",
              "children": [
                {
                  "name": "23-PSV-1630"
                },
                {
                  "name": "23-PSV-1650"
                },
                {
                  "name": "23-PSV-1699"
                },
                {
                  "name": "23-PSV-1700"
                },
                {
                  "name": "23-PSV-6107"
                },
                {
                  "name": "23-PSV-6110"
                },
                {
                  "name": "23-PSV-6112"
                },
                {
                  "name": "23-PSV-6128"
                },
                {
                  "name": "23-PSV-6132"
                }
              ]
            }
          ]
        },
        {
          "name": "23.9 Corrosion Loop - CL-06 Condensate",
          "children": [
            {
              "name": "23.90.06 Corrosion Loop - CL-06 Condensate",
              "children": [
                {
                  "name": "2-DC-23-6008-AS20-4Y"
                },
                {
                  "name": "2-DC-23-6024-AS20-4Y"
                },
                {
                  "name": "2-DC-23-6025-AS20-4Y"
                },
                {
                  "name": "2-DC-23-6026-AS20-4Y"
                },
                {
                  "name": "2-DC-23-6027-AS20-4Y"
                },
                {
                  "name": "2-PL-23-6007-AS20-4Y"
                },
                {
                  "name": "2-PL-23-6011-AS20-4Y"
                },
                {
                  "name": "2-PL-23-6012-AS20-4Y"
                },
                {
                  "name": "2-PL-23-6016-AS20-4Y"
                },
                {
                  "name": "2-PL-23-6017-AS20-4Y"
                },
                {
                  "name": "10-PV-23-6001-AD20-3N"
                },
                {
                  "name": "10-PV-23-6003-AS20-0N"
                },
                {
                  "name": "10-PV-23-6006-AS20-0N"
                },
                {
                  "name": "10-PV-23-6200-BS20-0N"
                },
                {
                  "name": "2-PV-23-6205-BS20-3N"
                },
                {
                  "name": "2-VF-23-6010-AS20-0N"
                },
                {
                  "name": "2-VF-23-6013-AS20-0N"
                },
                {
                  "name": "2-VF-23-6196-AD20-3N"
                },
                {
                  "name": "2-VF-23-6992-AS20-0N"
                },
                {
                  "name": "2-PV-23-6204-BC11-3N"
                },
                {
                  "name": "2-VF-23-6021-AC21-3N"
                },
                {
                  "name": "2-VF-23-6022-AC21-3N"
                },
                {
                  "name": "4-PV-23-6018-AC21-3N"
                },
                {
                  "name": "4-PV-23-6023-AD20-3N"
                },
                {
                  "name": "4-PV-23-6201-BC11-3N"
                },
                {
                  "name": "4-PV-23-6202-BC11-3N"
                },
                {
                  "name": "4-PV-23-6203-BC11-3N"
                },
                {
                  "name": "4-VF-23-6014-AC21-3N"
                },
                {
                  "name": "4-VF-23-6020-AC21-3N"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "name": "24 Teg Contactor",
      "children": [
        {
          "name": "24.01 Process Shutdown - Teg Contactor",
          "children": [
            {
              "name": "24.01.030 Process Shutdown - Teg Contactor",
              "children": [
                {
                  "name": "24-LSIT-6911"
                },
                {
                  "name": "24-LSIT-6912"
                },
                {
                  "name": "24-LSIT-6919"
                },
                {
                  "name": "24-PDSIT-6939"
                },
                {
                  "name": "24-PSIT-6531"
                },
                {
                  "name": "24-PSIT-6888"
                },
                {
                  "name": "24-PSIT-6924"
                },
                {
                  "name": "24-XV-6562"
                },
                {
                  "name": "24-XY-6562"
                },
                {
                  "name": "24-XV-6915"
                },
                {
                  "name": "24-XY-6915"
                },
                {
                  "name": "24-XV-6922"
                },
                {
                  "name": "24-XY-6922"
                },
                {
                  "name": "24-XV-6923"
                },
                {
                  "name": "24-XY-6923"
                },
                {
                  "name": "24-XV-6940"
                },
                {
                  "name": "24-XY-6940"
                },
                {
                  "name": "24-XV-6941"
                },
                {
                  "name": "24-XY-6941"
                }
              ]
            },
            {
              "name": "24.01.040 Process Shutdown - Teg Contactor",
              "children": [
                {
                  "name": "24.01.040"
                },
                {
                  "name": "24-BV-6927"
                },
                {
                  "name": "24-EY-6927"
                },
                {
                  "name": "24-BV-6937"
                },
                {
                  "name": "24-EY-6937"
                },
                {
                  "name": "24-EY-6938"
                },
                {
                  "name": "24-PSV-6563"
                }
              ]
            },
            {
              "name": "24.01.100 Process Shutdown - Teg Contactor",
              "children": [
                {
                  "name": "24-PSV-6564"
                },
                {
                  "name": "24-PSV-6925"
                },
                {
                  "name": "24-PSV-6926"
                }
              ]
            }
          ]
        },
        {
          "name": "24.02 Teg Gas Generation Package ",
          "children": [
            {
              "name": "24.02.030 Teg Gas Generation Package ",
              "children": [
                {
                  "name": "24-PSV-6927"
                },
                {
                  "name": "24-LSIT-3803"
                },
                {
                  "name": "24-LSIT-3804"
                },
                {
                  "name": "24-LSIT-3814"
                },
                {
                  "name": "24-LSIT-3817"
                },
                {
                  "name": "24-PSIT-3801"
                },
                {
                  "name": "24-PSIT-3810"
                },
                {
                  "name": "24-PSIT-3823"
                },
                {
                  "name": "24-PSIT-3824"
                },
                {
                  "name": "24-TSIT-3812"
                },
                {
                  "name": "24-TSIT-3818"
                },
                {
                  "name": "24-TT-3830"
                },
                {
                  "name": "24-TE-3830"
                },
                {
                  "name": "24-TT-3831"
                },
                {
                  "name": "24-TE-3831"
                },
                {
                  "name": "24-TT-3832"
                },
                {
                  "name": "24-TE-3832"
                },
                {
                  "name": "24-TT-3833"
                },
                {
                  "name": "24-TE-3833"
                },
                {
                  "name": "24-TT-3834"
                },
                {
                  "name": "24-TE-3834"
                },
                {
                  "name": "24-TT-3835"
                },
                {
                  "name": "24-TE-3835"
                },
                {
                  "name": "24-TT-3836"
                },
                {
                  "name": "24-TE-3836"
                },
                {
                  "name": "24-TT-3837"
                },
                {
                  "name": "24-TE-3837"
                },
                {
                  "name": "24-XV-3806"
                },
                {
                  "name": "24-XY-3806"
                },
                {
                  "name": "24-XV-6733"
                },
                {
                  "name": "24-XY-6733"
                }
              ]
            },
            {
              "name": "24.02.100 Teg Gas Generation Package ",
              "children": [
                {
                  "name": "24-XY-6734"
                },
                {
                  "name": "24-XY-6735"
                },
                {
                  "name": "24-PSV-3808"
                },
                {
                  "name": "24-PSV-3809"
                },
                {
                  "name": "24-PSV-3821"
                },
                {
                  "name": "24-PSV-3822"
                },
                {
                  "name": "24-PSV-3828"
                },
                {
                  "name": "24-PSV-3860"
                },
                {
                  "name": "24-PSV-3861"
                },
                {
                  "name": "24-PSV-3862"
                },
                {
                  "name": "24-PSV-3863"
                }
              ]
            }
          ]
        },
        {
          "name": "24.03 Gas Treatment Train A/B",
          "children": [
            {
              "name": "24.02.100 Gas Treatment Train A/B",
              "children": [
                {
                  "name": "24-PSV-3864"
                }
              ]
            },
            {
              "name": "24.03.030 Gas Treatment Train A/B",
              "children": [
                {
                  "name": "24-LSIT-6950A"
                },
                {
                  "name": "24-LSIT-6950B"
                },
                {
                  "name": "24-LSIT-6952A"
                },
                {
                  "name": "24-LSIT-6952B"
                },
                {
                  "name": "24-PDSIT-6832A"
                },
                {
                  "name": "24-PDSIT-6832B"
                },
                {
                  "name": "24-PDSIT-6833A"
                },
                {
                  "name": "24-PDSIT-6833B"
                },
                {
                  "name": "24-PDSIT-6965A"
                },
                {
                  "name": "24-PDSIT-6965B"
                },
                {
                  "name": "24-TSIT-6866A"
                },
                {
                  "name": "24-TSIT-6866B"
                },
                {
                  "name": "24-TSIT-6962A"
                },
                {
                  "name": "24-TSIT-6962B"
                },
                {
                  "name": "24-XV-6856A"
                },
                {
                  "name": "24-XY-6856A"
                },
                {
                  "name": "24-XV-6856B"
                },
                {
                  "name": "24-XY-6856B"
                },
                {
                  "name": "24-XV-6857A"
                },
                {
                  "name": "24-XY-6857A"
                },
                {
                  "name": "24-XV-6857B"
                },
                {
                  "name": "24-XY-6857B"
                },
                {
                  "name": "24-XV-6859A"
                },
                {
                  "name": "24-XY-6859A"
                },
                {
                  "name": "24-XV-6859B"
                },
                {
                  "name": "24-XY-6859B"
                },
                {
                  "name": "24-XV-6958A"
                },
                {
                  "name": "24-XY-6958A"
                },
                {
                  "name": "24-XV-6958B"
                },
                {
                  "name": "24-XY-6958B"
                },
                {
                  "name": "24-XV-6966A"
                },
                {
                  "name": "24-XY-6966A"
                },
                {
                  "name": "24-XV-6966B"
                },
                {
                  "name": "24-XY-6966B"
                },
                {
                  "name": "24-XV-6967A"
                },
                {
                  "name": "24-XY-6967A"
                },
                {
                  "name": "24-XV-6967B"
                },
                {
                  "name": "24-XY-6967B"
                }
              ]
            },
            {
              "name": "24.03.040 Gas Treatment Train A/B",
              "children": [
                {
                  "name": "24-XY-6968B"
                },
                {
                  "name": "24-BV-6853A"
                },
                {
                  "name": "24-EY-6853A"
                },
                {
                  "name": "24-BV-6853B"
                },
                {
                  "name": "24-EY-6853B"
                },
                {
                  "name": "24-EY-6854B"
                }
              ]
            },
            {
              "name": "24.03.100 Gas Treatment Train A/B",
              "children": [
                {
                  "name": "24-PSV-6635A"
                },
                {
                  "name": "24-PSV-6635B"
                },
                {
                  "name": "24-PSV-6829A"
                },
                {
                  "name": "24-PSV-6829B"
                },
                {
                  "name": "24-PSV-6886A"
                },
                {
                  "name": "24-PSV-6886B"
                },
                {
                  "name": "24-PSV-6955A"
                },
                {
                  "name": "24-PSV-6955B"
                }
              ]
            }
          ]
        },
        {
          "name": "24.04 Gas Treatment CO2 Removal Membrane",
          "children": [
            {
              "name": "24.03.100 Gas Treatment CO2 Removal Membrane",
              "children": [
                {
                  "name": "24-FSIT-6681"
                },
                {
                  "name": "24-FSIT-6682"
                }
              ]
            },
            {
              "name": "24.04.030 Gas Treatment CO2 Removal Membrane",
              "children": [
                {
                  "name": "24-FSIT-6683"
                },
                {
                  "name": "24-FE-6683"
                },
                {
                  "name": "24-PDSIT-6651"
                },
                {
                  "name": "24-PSIT-6649"
                },
                {
                  "name": "24-XV-6641"
                },
                {
                  "name": "24-XV-6642"
                }
              ]
            },
            {
              "name": "24.04.040 Gas Treatment CO2 Removal Membrane",
              "children": [
                {
                  "name": "24-BV-6689"
                },
                {
                  "name": "24-EY-6689"
                },
                {
                  "name": "24-EY-6690"
                }
              ]
            },
            {
              "name": "24.04.100 Gas Treatment CO2 Removal Membrane",
              "children": [
                {
                  "name": "24-PSV-6644"
                },
                {
                  "name": "24-PSV-6645"
                },
                {
                  "name": "24-PSV-6830A"
                },
                {
                  "name": "24-PSV-6830B"
                },
                {
                  "name": "24-PSV-6830C"
                },
                {
                  "name": "24-PSV-6830D"
                },
                {
                  "name": "24-PSV-6830E"
                },
                {
                  "name": "24-PSV-6830F"
                }
              ]
            }
          ]
        },
        {
          "name": "24.05 Process Shutdown - Gas Treatment ",
          "children": [
            {
              "name": "24.05.030 Process Shutdown - Gas Treatment ",
              "children": [
                {
                  "name": "24-PSV-6840F"
                },
                {
                  "name": "24-PSIT-6660A"
                },
                {
                  "name": "24-PSIT-6660B"
                },
                {
                  "name": "24-XV-6640"
                },
                {
                  "name": "24-XY-6640"
                },
                {
                  "name": "24.05.040"
                }
              ]
            },
            {
              "name": "24.05.040 Process Shutdown - Gas Treatment ",
              "children": [
                {
                  "name": "24-BV-6642"
                },
                {
                  "name": "24-EY-6642"
                },
                {
                  "name": "24-EY-6643"
                },
                {
                  "name": "24-PSV-6650A"
                }
              ]
            },
            {
              "name": "24.05.100 Process Shutdown - Gas Treatment ",
              "children": [
                {
                  "name": "24-PSV-6650B"
                },
                {
                  "name": "24-PSV-6831A"
                },
                {
                  "name": "24-PSV-6831B"
                }
              ]
            }
          ]
        },
        {
          "name": "24.9 Corrosion Loop - CL-07 Condensate",
          "children": [
            {
              "name": "24.90.07 Corrosion Loop - CL-07 Condensate",
              "children": [
                {
                  "name": "2-DC-24-6834-DS20-0N"
                },
                {
                  "name": "2-DC-24-6837-DC11-0N"
                },
                {
                  "name": "2-DC-24-6918-DC11-0N"
                },
                {
                  "name": "2-PL-24-6777-DS20-0N"
                },
                {
                  "name": "2-PL-24-6785-DC11-0N"
                },
                {
                  "name": "2-PL-24-6835-DC11-0N"
                },
                {
                  "name": "2-PL-24-6836-DC11-0N"
                },
                {
                  "name": "2-PL-24-6914-DC11-0N"
                },
                {
                  "name": "2-PL-24-6931-DC11-0N"
                },
                {
                  "name": "1-PV-24-3851-AS20-0N"
                },
                {
                  "name": "1-PV-24-3852-AS20-0N"
                },
                {
                  "name": "2-PV-24-3850-AD20-3N"
                },
                {
                  "name": "2-PV-24-6098-AD20-3N"
                },
                {
                  "name": "2-VF-24-3860-AC11-3N"
                },
                {
                  "name": "2-VF-24-3861-AC11-3N"
                },
                {
                  "name": "2-VF-24-3863-AS20-0N"
                },
                {
                  "name": "2-VF-24-3864-AS20-0N"
                },
                {
                  "name": "2-VF-24-6681-AD20-3N"
                },
                {
                  "name": "2-VF-24-6040-DS20-0N"
                },
                {
                  "name": "8-PV-24-6933-DS20-0N"
                },
                {
                  "name": "10-PV-24-3606-DC11-0N"
                },
                {
                  "name": "10-PV-24-6600-DC11-1N"
                },
                {
                  "name": "10-PV-24-6782-DC11-0N"
                },
                {
                  "name": "10-PV-24-6844-DC11-1N"
                },
                {
                  "name": "10-PV-24-6845-DC11-1N"
                },
                {
                  "name": "10-PV-24-6846-DC11-1N"
                },
                {
                  "name": "10-PV-24-6847-DC11-1N"
                },
                {
                  "name": "10-PV-24-6848-DC11-1N"
                },
                {
                  "name": "10-PV-24-6876-DC11-0N"
                },
                {
                  "name": "10-PV-24-6877-DC11-1N"
                },
                {
                  "name": "10-PV-24-6910-DC11-1N"
                },
                {
                  "name": "10-PV-24-6911-DC11-1N"
                },
                {
                  "name": "10-PV-24-6912-DC11-1N"
                },
                {
                  "name": "10-PV-24-6913-DC11-1N"
                },
                {
                  "name": "2-DC-24-6840-DC11-0N"
                },
                {
                  "name": "2-DC-24-6842-DC11-0N"
                },
                {
                  "name": "2-DC-24-6843-DC11-0N"
                },
                {
                  "name": "2-DC-24-6922-DC11-0N"
                },
                {
                  "name": "2-DC-24-6925-DC11-0N"
                },
                {
                  "name": "2-DC-24-6926-DC11-0N"
                },
                {
                  "name": "2-PV-24-6625-DC11-1N"
                },
                {
                  "name": "2-PV-24-6626-DC11-1N"
                },
                {
                  "name": "2-PV-24-6627-DC11-1N"
                },
                {
                  "name": "2-PV-24-6628-DC11-1N"
                },
                {
                  "name": "2-PV-24-6629-DC11-1N"
                },
                {
                  "name": "2-PV-24-6630-DC11-1N"
                },
                {
                  "name": "2-PV-24-6713-DC11-0N"
                },
                {
                  "name": "2-PV-24-6828-DC11-1N"
                },
                {
                  "name": "2-PV-24-6905-DC11-1N"
                },
                {
                  "name": "2-VF-24-6690-DC11-1N"
                },
                {
                  "name": "2-VF-24-6701-DC11-1N"
                },
                {
                  "name": "2-VF-24-6725-DC11-0N"
                },
                {
                  "name": "2-VF-24-6726-DC11-1N"
                },
                {
                  "name": "2-VF-24-6757-DC11-1N"
                },
                {
                  "name": "2-VF-24-6780-DC11-1N"
                },
                {
                  "name": "2-VF-24-6786-DC11-0N"
                },
                {
                  "name": "2-VF-24-6832-DC11-0N"
                },
                {
                  "name": "2-VF-24-6839-DC11-1N"
                },
                {
                  "name": "2-VF-24-6916-DC11-0N"
                },
                {
                  "name": "2-VF-24-6917-DC11-1N"
                },
                {
                  "name": "2-VF-24-6921-DC11-1N"
                },
                {
                  "name": "2-VF-24-6949-DC11-1N"
                },
                {
                  "name": "2-VF-24-6973-DC11-1N"
                },
                {
                  "name": "2-VF-24-6974-DC11-1N"
                },
                {
                  "name": "2-VF-24-6975-DC11-1N"
                },
                {
                  "name": "2-VF-24-6976-DC11-1N"
                },
                {
                  "name": "2-VF-24-6977-DC11-1N"
                },
                {
                  "name": "2-VF-24-6978-DC11-1N"
                },
                {
                  "name": "2-VF-24-6989-DC11-1N"
                },
                {
                  "name": "2-VF-24-6997-DC11-1N"
                },
                {
                  "name": "2-VF-24-6999-DC11-0N"
                },
                {
                  "name": "3-VF-24-6769-DC11-0N"
                },
                {
                  "name": "3-VF-24-6838-DC11-1N"
                },
                {
                  "name": "3-VF-24-6841-DC11-1N"
                },
                {
                  "name": "3-VF-24-6920-DC11-1N"
                },
                {
                  "name": "3-VF-24-6923-DC11-1N"
                },
                {
                  "name": "4-PV-24-6095-DC11-0N"
                },
                {
                  "name": "4-VF-24-6796-DC11-0N"
                },
                {
                  "name": "6-PV-24-6096-BD20-0N"
                },
                {
                  "name": "6-PV-24-6601-DC11-1N"
                },
                {
                  "name": "6-PV-24-6602-DC11-1N"
                },
                {
                  "name": "6-PV-24-6603-DC11-1N"
                },
                {
                  "name": "6-PV-24-6604-DC11-1N"
                },
                {
                  "name": "6-PV-24-6605-DC11-1N"
                },
                {
                  "name": "6-PV-24-6606-DC11-1N"
                },
                {
                  "name": "6-VF-24-6099-BD20-0N"
                },
                {
                  "name": "6-VF-24-6100-BD20-0N"
                },
                {
                  "name": "10-PV-24-6619-DC11-0N"
                },
                {
                  "name": "10-PV-24-6849-DC11-0N"
                },
                {
                  "name": "10-PV-24-6850-DC11-0N"
                },
                {
                  "name": "2-PV-24-6691-DC11-0N"
                },
                {
                  "name": "2-PV-24-6924-DC11-0N"
                },
                {
                  "name": "3-VF-24-6868-DC11-0N"
                },
                {
                  "name": "3-VF-24-6869-DC11-0N"
                },
                {
                  "name": "6-PV-24-6607-DC11-0N"
                },
                {
                  "name": "6-PV-24-6608-DC11-0N"
                },
                {
                  "name": "6-PV-24-6609-DC11-0N"
                },
                {
                  "name": "6-PV-24-6610-DC11-0N"
                },
                {
                  "name": "6-PV-24-6611-DC11-0N"
                },
                {
                  "name": "6-PV-24-6612-DC11-0N"
                },
                {
                  "name": "6-VF-24-6863-DC11-0N"
                },
                {
                  "name": "10-PV-24-6851-DC11-0N"
                },
                {
                  "name": "10-PV-24-6852-DC11-0N"
                },
                {
                  "name": "10-PV-24-6853-DC11-0N"
                },
                {
                  "name": "10-PV-24-6854-DC11-0N"
                },
                {
                  "name": "10-PV-24-6855-DC11-0N"
                },
                {
                  "name": "10-PV-24-6856-DC11-0N"
                },
                {
                  "name": "10-PV-24-6857-DC11-0N"
                },
                {
                  "name": "10-PV-24-6858-DC11-0N"
                },
                {
                  "name": "10-PV-24-6895-DC11-0N"
                },
                {
                  "name": "2-DC-24-6872-DC11-0N"
                },
                {
                  "name": "2-DC-24-6873-DC11-0N"
                },
                {
                  "name": "2-DC-24-6874-DC11-0N"
                },
                {
                  "name": "2-DC-24-6875-DC11-0N"
                },
                {
                  "name": "2-VF-24-6870-DC11-0N"
                },
                {
                  "name": "2-VF-24-6871-DC11-0N"
                },
                {
                  "name": "2-VF-24-6991-DC11-0N"
                },
                {
                  "name": "2-VF-24-6993-DC11-0N"
                },
                {
                  "name": "3-VF-24-6862-DC11-0N"
                },
                {
                  "name": "2-VF-24-3896-AC11-0N"
                },
                {
                  "name": "3-VF-24-6621-DC11-0N"
                },
                {
                  "name": "3-VF-24-6622-DC11-0N"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "name": "25 Gas Treatment Trains",
      "children": [
        {
          "name": "25.01 Gas Treatment Train A",
          "children": [
            {
              "name": "25.01.040 Gas Treatment Train A",
              "children": [
                {
                  "name": "25-BV-6567"
                },
                {
                  "name": "25-EY-6567"
                },
                {
                  "name": "25-EY-6568"
                }
              ]
            },
            {
              "name": "25.01.100 Gas Treatment Train A",
              "children": [
                {
                  "name": "25-PSV-6565"
                },
                {
                  "name": "25-PSV-6566"
                },
                {
                  "name": "25-PSV-6693A"
                },
                {
                  "name": "25-PSV-6746A"
                }
              ]
            }
          ]
        },
        {
          "name": "25.02 Gas Treatment Train B",
          "children": [
            {
              "name": "25.02.100 Gas Treatment Train B",
              "children": [
                {
                  "name": "25-PSV-6749A"
                },
                {
                  "name": "25-PSV-6693B"
                },
                {
                  "name": "25-PSV-6746B"
                }
              ]
            }
          ]
        },
        {
          "name": "25.03 Low Temperature Separator",
          "children": [
            {
              "name": "25.03.030 Low Temperature Separator",
              "children": [
                {
                  "name": "25-LSIT-6672"
                },
                {
                  "name": "25-LSIT-6673"
                },
                {
                  "name": "25-PSIT-6671"
                },
                {
                  "name": "25-XV-6675"
                },
                {
                  "name": "25-XY-6675"
                },
                {
                  "name": "25-XY-6676"
                }
              ]
            },
            {
              "name": "25.03.040 Low Temperature Separator",
              "children": [
                {
                  "name": "25-BV-6680"
                },
                {
                  "name": "25-EY-6680"
                },
                {
                  "name": "25-BV-6784"
                },
                {
                  "name": "25-EY-6784"
                }
              ]
            },
            {
              "name": "25.03.100 Low Temperature Separator",
              "children": [
                {
                  "name": "25-EY-6785"
                },
                {
                  "name": "25-PSV-6678"
                },
                {
                  "name": "25-PSV-6679"
                }
              ]
            }
          ]
        },
        {
          "name": "25.9 Corrosion Loop - CL-07 Condensate",
          "children": [
            {
              "name": "25.90.07 Corrosion Loop - CL-07 Condensate",
              "children": [
                {
                  "name": "2-DC-25-6894-DC11-0N"
                },
                {
                  "name": "2-PL-25-6890-DC11-0N"
                },
                {
                  "name": "2-VF-25-6799-DC11-0N"
                },
                {
                  "name": "2-VF-25-6801-DC11-0N"
                },
                {
                  "name": "2-VF-25-6813-DC11-0N"
                },
                {
                  "name": "2-VF-25-6878-DC11-0N"
                },
                {
                  "name": "2-VF-25-6928-DC11-0N"
                },
                {
                  "name": "2-VF-25-6930-DC11-0N"
                },
                {
                  "name": "2-VF-25-6934-DC11-0N"
                },
                {
                  "name": "8-PV-25-6809-DC11-0N"
                },
                {
                  "name": "8-PV-25-6881-DC11-0N"
                },
                {
                  "name": "8-PV-25-6896-DC11-0N"
                },
                {
                  "name": "8-PV-25-6900-DC11-0N"
                },
                {
                  "name": "2-VF-25-6885-DC11-0N"
                },
                {
                  "name": "2-VF-25-6886-DC11-0N"
                },
                {
                  "name": "2-VF-25-6891-DC11-0N"
                },
                {
                  "name": "2-VF-25-6892-DC11-0N"
                },
                {
                  "name": "2-VF-25-6893-DC11-0N"
                },
                {
                  "name": "2-VF-25-6904-DC11-0N"
                },
                {
                  "name": "2-VF-25-6907-DC11-0N"
                },
                {
                  "name": "2-VF-25-6908-DC11-0N"
                },
                {
                  "name": "3-VF-25-6901-DC11-0N"
                },
                {
                  "name": "3-VF-25-6902-DC11-0N"
                },
                {
                  "name": "4-PV-25-6879-DC11-0N"
                },
                {
                  "name": "8-PV-25-6880-DC11-0N"
                },
                {
                  "name": "8-PV-25-6882-DC11-0N"
                },
                {
                  "name": "8-PV-25-6898-DC11-0N"
                },
                {
                  "name": "8-PV-25-6899-DC11-0N"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "name": "27 Booster Gas Compressor ",
      "children": [
        {
          "name": "27.01 Process Shutdown - Booster Compressor",
          "children": [
            {
              "name": "27.01.030 Process Shutdown - Booster Compressor",
              "children": [
                {
                  "name": "27-LSIT-6003A"
                },
                {
                  "name": "27-LSIT-6002A"
                },
                {
                  "name": "27-LSIT-6312A"
                },
                {
                  "name": "27-LSIT-6353A"
                },
                {
                  "name": "27-PSIT-6002A"
                },
                {
                  "name": "27-PSIT-6006A"
                },
                {
                  "name": "27-PSIT-6007A"
                },
                {
                  "name": "27-PSIT-6008A"
                },
                {
                  "name": "27-PSIT-6009A"
                },
                {
                  "name": "27-PSIT-6010A"
                },
                {
                  "name": "27-PSIT-6321A"
                },
                {
                  "name": "27-PSIT-6325A"
                },
                {
                  "name": "27-PSIT-6361A"
                },
                {
                  "name": "27-PSIT-6365A"
                },
                {
                  "name": "27-SSHH-6002A"
                },
                {
                  "name": "27-TSHH-6002A"
                },
                {
                  "name": "27-TSHH-6003A"
                },
                {
                  "name": "27-TSHH-6251A"
                },
                {
                  "name": "27-TSIT-6021A"
                },
                {
                  "name": "27-TE-6021A"
                },
                {
                  "name": "27-TSIT-6022A"
                },
                {
                  "name": "27-TE-6022A"
                },
                {
                  "name": "27-TSIT-6324A"
                },
                {
                  "name": "27-TE-6324A"
                },
                {
                  "name": "27-TSIT-6330A"
                },
                {
                  "name": "27-TSIT-6364A"
                },
                {
                  "name": "27-TSIT-6392A"
                },
                {
                  "name": "27-TW-6392A"
                },
                {
                  "name": "27-VST-6002-01-A"
                },
                {
                  "name": "27-VE-6002-01-A"
                },
                {
                  "name": "27-VST-6002-02-A"
                },
                {
                  "name": "27-VE-6002-02-A"
                },
                {
                  "name": "27-VST-6003-01-A"
                },
                {
                  "name": "27-VE-6003-01-A"
                },
                {
                  "name": "27-VST-6003-02-A"
                },
                {
                  "name": "27-VE-6003-02-A"
                },
                {
                  "name": "27-VST-6004-01-A"
                },
                {
                  "name": "27-VE-6004-01-A"
                },
                {
                  "name": "27-VST-6004-02-A"
                },
                {
                  "name": "27-VE-6004-02-A"
                },
                {
                  "name": "27-VST-6005-01-A"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "name": "Measurements",
      "children": [
        {
          name: '16.42'
        },
        {
          name: '15.98'
        },
        {
          name: '15.99'
        },
        {
          name: '15.76'
        },
        {
          name: '15.54'
        }
      ]
    }
  ]
}

export const ashokleyandEquipmentHierarchy = {
  name: 'Ashok Leyland',
  children: [
    {
      name: 'ELECTRICAL AMC',
      children: [
        {
          name: 'Power plus',
          children: [{
            name: 'CANTEEN',
            children: [
              { name: "81-EZ-001  -  Kitchen Equipments" },
              { name: "97-GK-001  -  Fume Extractor " },
              { name: "97-GK-002  -  Fume Extractor" },
            ]
          }]
        },
        {
          name: 'Kone',
          children: [{
            name: 'CANTEEN',
            children: [
              { name: '73-MC-001  -  Goods Lift' }
            ]
          }]
        },
        {
          name: 'Professional',
          children: [{
            name: 'CANTEEN',
            children: [{
              name: '73-MC-002  -  Goods Lift'
            }]
          }]
        },
        {
          name: 'Sarvam Safety ',
          children: [{
            name: 'CANTEEN',
            children: [{
              name: '73-AB-001  -  Canteen Gas leak alarm Calibration & Test'
            }]
          }]
        },
        {
          name: 'Siemens',
          children: [{
            name: 'FIRE ALARM SYSTEM',
            children: [
              { name: '73-BF-001  -  Siemens Fire Alarm System' },
              { name: '73-BF-002  -  Copper Fire Alarm System' },
              { name: '73-BF-003  -  Copper Fire Alarm System' },
              { name: '73-BF-004  -  Siemens Fire Alarm System' },
            ]
          }]
        },
        {
          name: 'Cherytech',
          children: [{
            name: 'FIRE ALARM SYSTEM',
            children: [
              { name: '73-BF-005  -  Ravel Fire Alarm System' },
              { name: '73-BF-006  -  Notifier Fire Alarm System' },
              { name: '73-BF-007  -  Fire Alarm System' },
              { name: '73-BF-008  -  Ravel Fire Alarm System' },
              { name: '73-BF-009  -  Ravel Fire Alarm System' },
              { name: '73-BF-010  -  Ravel Fire Alarm System' },
            ]
          }]
        },
        {
          name: 'Exalt',
          children: [{
            name: 'CCTV',
            children: [
              { name: '95-XA-001  -  Exalt CCTV System' }
            ]
          }]
        },
        {
          name: 'Maini',
          children: [{
            name: 'MAINI',
            children: [
              { name: '95-CG-001  -  Maini 6 Seater Old Golf Car' },
              { name: '95-CG-002  -  Maini 8 Seater Old Golf Car' },
            ]
          }]
        },
        {
          name: 'Mats India',
          children: [{
            name: 'ENV MONITORING',
            children: [{
              name: '77-RA-001  -  Environmental Monitoring '
            }]
          }]
        },
        {
          name: 'Hari Prasanna',
          children: [{
            name: 'EPABX',
            children: [
              { name: '86-RP-001  -  EPABX-1  ( SV-9100)' },
              { name: '86-RP-002  -  EPABX-2 (SV-8300)& EDC' },
            ]
          }]
        },
        {
          name: 'Zenith',
          children: [{
            name: 'ACCESS CONTROL',
            children: [
              { name: '76-RV-001  -  Phase 1 GF Front LS Door BR No 4' },
              { name: '76-RV-002  -  Phase 1 GF Front RS Door BR No 10' },
              { name: '76-RV-003  -  Phase 1 GF Rear Door BR No 11' },
              { name: '76-RV-004  -  Phase 1 FF Front LS Door BR No 12' },
              { name: '76-RV-005  -  Phase 1 FF Front RS Door BR 13' },
              { name: '76-RV-006  -  Phase 1 FF Rear Door BR 14' },
              { name: '76-RV-007  -  Phase 2 GF Front LS Door BR 17' },
              { name: '76-RV-008  -  Phase 2 GF Front RS Door BR 20' },
              { name: '76-RV-009  -  Phase 2 GF Rear Door BR 18' },
              { name: '76-RV-010  -  Phase 2 FF Front LS Door BR 21' },
              { name: '76-RV-011  -  Phase 2 FF Front RS Door BR 22' },
              { name: '76-RV-012  -  Phase 2 FF Rear Door BR 23' },
              { name: '76-RV-013  -  Phase 1 GF Rear Door BR 06' },
              { name: '76-RV-014  -  Phase 1 FF Rear Door BR 09' },
              { name: '76-RV-015  -  Canteen 1 BR 07' },
              { name: '76-RV-016  -  Canteen 1 BR 08' },
              { name: '76-RV-017  -  Canteen 1 LHS BR 28' },
              { name: '76-RV-018  -  Canteen 2 BR 29' },
              { name: '76-RV-019  -  Canteen 2 Finger Print 1 BR 41' },
              { name: '76-RV-020  -  Canteen 2 Finger Print 2 BR 42' },
              { name: '76-RV-021  -  Protoshop 1 BR 02' },
              { name: '76-RV-022  -  Engineering Office BR 01' },
              { name: '76-RV-023  -  CTL Office BR 03' },
              { name: '76-RV-024  -  Old VET Office BR 05' },
              { name: '76-RV-025  -  New VET Office BR 25' },
              { name: '76-RV-026  -  MED Office BR 27' },
              { name: '76-RV-027  -  EDC GF Office BR 39' },
              { name: '76-RV-028  -  EDC FF Office LHS BR 44' },
              { name: '76-RV-029  -  EDC FF Office RHS BR 45' },
              { name: '76-RV-030  -  Styling studio BR 40' },
              { name: '76-RV-031  -  Six poster Office BR 16' },
              { name: '76-RV-032  -  Stores BR15' },
              { name: '76-RV-033  -  Visitor Management system' },
              { name: '76-RV-034  -  EDC Reception BR 46 ' },
              { name: '76-RV-035  -  NDO 2 BR 30' },
              { name: '76-RV-036  -  NDO 2 BR 31' },
              { name: '76-RV-037  -  NDO 2 BR 32' },
              { name: '76-RV-038  -  NDO 2 BR 33' },
              { name: '76-RV-039  -  NDO 2 BR 34' },
              { name: '76-RV-040  -  NDO 2 BR 35' },
              { name: '76-RV-041  -  NDO 2 BR 36' },
              { name: '76-RV-042  -  NDO 2 BR 37' },
              { name: '76-RV-043  -  AEG Lab (FF) BR 24' },
            ]
          }]
        },
        {
          name: 'Emerson',
          children: [{
            name: 'EMERSON DB POWER & NUMERIC',
            children: [
              { name: '85-ER-001  -  APM 90KVA UPS-1' },
              { name: '85-ER-002  -  APM 90KVA UPS-2' },
              { name: '85-ER-003  -  250 KVA UPS-2  DB Power' },
              { name: '85-ER-004  -  60 KVA UPS 1 Emerson' },
              { name: '85-ER-005  -  60 KVA UPS 2 Emerson' },
              { name: '85-ER-006  -  20 KVA UPS 1 Emerson' },
              { name: '85-ER-007  -  20 KVA UPS 2 Emerson' },
            ]
          }]
        },
        {
          name: 'DB Power',
          children: [{
            name: 'EMERSON DB POWER & NUMERIC',
            children: [
              { name: '85-ER-008  -   80KVA UPS-1- DB power' },
              { name: '85-ER-009  -  80KVA UPS-2-DB power' },
              { name: '85-ER-010  -   80KVA UPS-3- DB power' },
              { name: '85-ER-011  -   80KVA UPS-4- DB power' },
              { name: '85-ER-012  -  40KVA UPS-2- DB power' },
            ]
          }]
        },
        {
          name: 'Emerson',
          children: [{
            name: 'EMERSON DB POWER & NUMERIC',
            children: [
              { name: '85-ER-013  -  40KVA UPS- Emerson' }
            ]
          }]
        },
        {
          name: 'DB Power',
          children: [{
            name: 'EMERSON DB POWER & NUMERIC',
            children: [
              { name: '85-ER-014  -  20KVA UPS- DB power' }
            ]
          }]
        },
        {
          name: 'NOVATEUR ',
          children: [{
            name: 'EMERSON DB POWER & NUMERIC',
            childern: [
              { name: '85-ER-015  -  6 KVA UPS- NUMERIC' },
              { name: '85-ER-016  -  6 KVA UPS- NUMERIC' },
              { name: '85-ER-017  -  5 KVA UPS Numeric' },
            ]
          }]
        },
        {
          name: 'Emerson',
          children: [{
            name: 'EMERSON DB POWER & NUMERIC',
            children: [
              { name: '85-ER-018  -  80 KVA UPS 1 Emerson' },
              { name: '85-ER-019  -  80KVA UPS-2 Emerson' },
              { name: '85-ER-020  -  Emerson 40KVA UPS' },
            ]
          }]
        },
        {
          name: 'Emmar Elect',
          children: [{
            name: 'TRANSFORMER',
            children: [
              { name: '81-ET-001  -  1000 KVA Transformer' },
              { name: '81-ET-002  -  2000 KVA Transformer 1' },
              { name: '81-ET-003  -  2000 KVA Transformer 2' },
              { name: '81-ET-004  -  500 KVA Transformer' },
              { name: '81-ET-005  -  3150 KVA Transformer 1' },
              { name: '81-ET-006  -  3150 KVA Transformer 2' },
              { name: '81-ET-007  -  2500 KVA Transformer 1' },
            ]
          }]
        },
        {
          name: 'Siemens',
          children: [
            {
              name: 'LT ACB',
              children: [
                { name: '82-EC-001  -  Incomer LT Breaker- LT Panel-A' },
                { name: '82-EC-002  -  MVSB Existing LT Breake-LT Panel-A' },
                { name: '82-EC-003  -  LT Panel B Breaker' },
                { name: '82-EC-004  -  Capacitor Bank LT Breaker' },
                { name: '82-EC-005  -  EB Incomer LT Breaker' },
                { name: '82-EC-006  -  Bus Coupler 1 LT Breaker' },
                { name: '82-EC-007  -  Bus Coupler 2 LT Breaker' },
                { name: '82-EC-008  -  DG Incomer 2000 KVA LT Breaker' },
                { name: '82-EC-009  -  DG Incomer 1250 KVA LT Breaker' },
                { name: '82-EC-010  -  NDO 1 Ph 1 A/C LT Breaker' },
                { name: '82-EC-011  -  DG Perkins 1250 KVA Lt Breaker' },
                { name: '82-EC-012  -  DG-1 1250 KVA Incoemr LT Breaker' },
                { name: '82-EC-013  -  Outgoing- 1 LT Breaker' },
                { name: '82-EC-014  -  DG- 2 2000 KVA Incomer LT Breaker' },
                { name: '82-EC-015  -  DG -3 1250 KVA Incomer LT Breaker' },
                { name: '82-EC-016  -  Outgoing 2 LT Breaker' },
                { name: '82-EC-017  -  Chiller LT Breaker' },
                { name: '82-EC-018  -  UPS LT Breaker' },
                { name: '82-EC-019  -  DG Bus Coupler LT Breaker' },
                { name: '82-EC-020  -  Transformer 2 Incomer LT Breaker' },
                { name: '82-EC-021  -  APFC Panel LT Breaker' },
                { name: '82-EC-022  -  Transformer 1 Incomer LT Breaker' },
                { name: '82-EC-023  -  EB Side Bus Coupler Incomer LT Breaker' },
                { name: '82-EC-024  -  Transformer 2 Incomer LT Breaker' },
                { name: '82-EC-025  -  Chiller Panel LT Breaker' },
                { name: '82-EC-026  -  Cooling Tower LT Breaker' },
                { name: '82-EC-027  -  STP LT Breaker' },
                { name: '82-EC-028  -  Chiller Panel LT Breaker' },
                { name: '82-EC-029  -  Main AHU Panel 2 LT Breaker' },
                { name: '82-EC-030  -  Raw Power Panel 2 LT Breaker' },
                { name: '82-EC-031  -  New CTL LT Breaker sub panel' },
                { name: '82-EC-032  -  APFC Panel 4 LT Breaker' },
                { name: '82-EC-033  -  APFC Panel 3 LT Breaker' },
                { name: '82-EC-034  -  Transformer 2 Incomer LT Breaker' },
                { name: '82-EC-035  -  Bus Coupler LT Breaker' },
                { name: '82-EC-036  -  Transformer 1 Incomer LT Breaker' },
                { name: '82-EC-037  -  APFC Panel 2 LT Breaker' },
                { name: '82-EC-038  -  APFC Panel 1 LT Breaker' },
                { name: '82-EC-039  -  Sub Panel 9 LT Breaker' },
                { name: '82-EC-040  -  Main AHU Panel 1 LT Breaker' },
                { name: '82-EC-041  -  Raw Power Panel 1 LT Breaker' },
                { name: '82-EC-042  -  ETB Sub Panel 4 LT Breaker' },
                { name: '82-EC-043  -  ETB Sub Panel 3 LT Breaker' },
                { name: '82-EC-044  -  ETB Sub Panel 2 LT Breaker' },
                { name: '82-EC-045  -  ETB Sub Panel 1 LT Breaker' },
                { name: '82-EC-046  -  APFC Panel 1 LT Breaker' },
                { name: '82-EC-047  -  APFC Panel 2 LT Breaker' },
                { name: '82-EC-048  -  APFC Panel 3 LT Breaker' },
                { name: '82-EC-049  -  APFC Panel 4 LT Breaker' },
                { name: '82-EC-050  -  Sub Panel 8' },
                { name: '82-EC-051  -  Sub Panel 9' },
                { name: '82-EC-052  -  CTL HPU Panel-9F2' },
                { name: '82-EC-053  -  Spare-15F2' },
                { name: '82-EC-054  -  Raw Power Panel 1 LT Breaker' },
                { name: '82-EC-055  -  Raw Power Panel 2 LT Breaker' },
                { name: '82-EC-056  -  Main AHU Panel 1 LT Breaker' },
                { name: '82-EC-057  -  Main AHU Panel 2 LT Breaker' },
                { name: '82-EC-058  -  Sub Panel 1 Incomer LT Breaker' },
                { name: '82-EC-059  -  IGBT Panel 1 LT Breaker' },
                { name: '82-EC-060  -  IGBT Panel 5 LT Breaker' },
                { name: '82-EC-061  -  IGBT Panel 3 LT Breaker' },
                { name: '82-EC-062  -  Subpanel 2 Incomer LT Breaker' },
                { name: '82-EC-063  -  IGBT Panel 6 LT Breaker' },
                { name: '82-EC-064  -  IGBT Panel 4 LT Breaker' },
                { name: '82-EC-065  -  IGBT Panel 2 LT Breaker' },
                { name: '82-EC-066  -  Subpanel 3 Incomer LT Breaker' },
                { name: '82-EC-067  -  IGBT Panel 7 LT Breaker' },
                { name: '82-EC-068  -  IGBT Panel 9 LT Breaker' },
                { name: '82-EC-069  -  IGBT Panel 11 LT Breaker' },
                { name: '82-EC-070  -  Subpanel 4 Incomer LT Breaker' },
                { name: '82-EC-071  -  IGBT Panel 12 LT Breaker' },
                { name: '82-EC-072  -  IGBT Panel 10 LT Breaker' },
                { name: '82-EC-073  -  IGBT Panel 8 LT Breaker' },
                { name: '82-EC-074  -  Sub Panel-9 ( Compressor room)' },
                { name: '82-EC-075  -  APFC Panel-1' },
                { name: '82-EC-076  -  CTL Main Panel' },
                { name: '82-EC-077  -  CTL Main Panel( Gear box Testing)' },
                { name: '82-EC-078  -  HPU Panel' },
                { name: '82-EC-079  -  Sub Panel' },
                { name: '82-EC-080  -  Chassis dyno panel (incomer)' },
                { name: '82-EC-081  -  Chassis dyno panel ( LCD Isolator)' },
                { name: '82-EC-082  -  Chassis dyno panel (isolator panel Bay-3)' },
                { name: '82-EC-083  -  Sub panel (3WL 1110-2FG35-4GA4)' },
              ]
            }
          ]
        },
        {
          name: 'Emmar Elect',
          children: [
            {
              name: 'HT VCB',
              children: [
                { name: '81-EC-001  -  MRS Incomer HT Breaker' },
                { name: '81-EC-002  -  Outgoing to SS1 HT Breaker' },
                { name: '81-EC-003  -  Outgoing to SS3 HT Breaker' },
                { name: '81-EC-004  -  Bus Coupler HT Breaker' },
                { name: '81-EC-005  -  Outgoing to SS4 HT Breaker 1' },
                { name: '81-EC-006  -  Outgoing to SS4 HT Breaker 2' },
                { name: '81-EC-007  -  Main Incomer HT Breaker' },
                { name: '81-EC-008  -  Outgoing to SS2 HT Breaker' },
                { name: '81-EC-009  -  DG Incomer HT Breaker' },
                { name: '81-EC-010  -  Outgoing to 1000 KVA HT Breaker' },
                { name: '81-EC-011  -  Main Incomer HT Breaker' },
                { name: '81-EC-012  -  Outgoing to SS2 HT Breaker' },
                { name: '81-EC-013  -  DG Incomer HT Breaker' },
                { name: '81-EC-014  -  Outgoing to 500 KVA HT Breaker' },
                { name: '81-EC-015  -  Outgoing to 2000 KVA Transformer 1 HT Breaker' },
                { name: '81-EC-016  -  Incoming from SS1 HT Breaker' },
                { name: '81-EC-017  -  Incoming from SS3 HT Breaker' },
                { name: '81-EC-018  -  Bus Coupler HT Breaker' },
                { name: '81-EC-019  -  DG Incomer HT Breaker' },
                { name: '81-EC-020  -  Outgoing to 2000 KVA Transformer 2 HT Breaker' },
                { name: '81-EC-021  -  HT DG-1 Incomer Breaker-1' },
                { name: '81-EC-022  -  HT DG-2 Incomer Breaker-2' },
                { name: '81-EC-023  -  Outgoing SS-2' },
                { name: '81-EC-024  -  Outgoing SS-1' },
                { name: '81-EC-025  -  Outgoing SS-4' },
                { name: '81-EC-026  -  Outgoing SS-3' },
                { name: '81-EC-027  -  MRS-1 Incomer' },
                { name: '81-EC-028  -  MRS-2 Incomer' },
                { name: '81-EC-029  -  3150 KVA Transformer-1' },
                { name: '81-EC-030  -  Bus Coupler HT Breaker' },
                { name: '81-EC-031  -  3150 KVA Transformer-2' },
                { name: '81-EC-032  -  Outgoing to SS-4' },
                { name: '81-EC-033  -  2500 KVA Transformer -3' },
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'MECHANICAL AMC',
      children: [
        {
          name: 'M/s Prodectvity',
          children: [
            {
              name: 'MED',
              children: [
                { name: '63-KB-501  -  Six poster' },

              ]
            },
            {
              name: 'Proto',
              children: [
                { name: '63-KB-502  -  Proto Atlos copco' },

              ]
            },
            {
              name: 'VET',
              children: [
                { name: '63-KB-503  -  VET - Atlos copco' },

              ]
            }
          ]
        },
        {
          name: 'M/s Sakthi',
          children: [
            {
              name: 'CTL',
              children: [
                { name: '63-KB-504  -  CTL - Elgi' },

              ]
            },
          ]
        },
        {
          name: 'Jayantash',
          children: [
            {
              name: 'LCV /VET/CTL/ETL/Six Poster/Proto1/EDC/Store /Maint & Canteen Lift',
              children: [
                { name: '73-XA-001  -  Chain Sling /Steel Wire Sling /FlatWeb Sling' },

              ]
            },
          ]
        },
        {
          name: 'SS Eng ',
          children: [
            {
              name: 'EDC',
              children: [
                { name: '73-MD-001  -  EDC F L' },

              ]
            }, {
              name: 'STORE',
              children: [
                { name: '73-MD-002  -  Store   FL' },

              ]
            },
          ]
        },
        {
          name: 'Technoo Fab',
          children: [
            {
              name: 'Insurt',
              children: [
                { name: '73-MZ-001  -  120T weighing Meachine' },

              ]
            }
          ]
        },
        {
          name: 'Vertive ',
          children: [
            {
              name: 'Data Center ',
              children: [
                { name: '86-RQ-001  -  Data Center' },
                { name: '86-RQ-002  -  Data Center' }

              ]
            },
          ]
        },
        {
          name: 'CARRIER',
          children: [
            {
              name: 'BSNL ROOM',
              children: [
                { name: '97-GB-001  -  CTL-OLD  GF' }
              ]
            },
            {
              name: 'CALIBRATION ROOM',
              children: [
                { name: '97-GB-002  -  ETL' }
              ]
            },
            {
              name: 'COLD ROOM',
              children: [
                { name: '97-GB-003  -  CANTEEN-1' }
              ]
            },
            {
              name: 'MANAGER ROOM',
              children: [
                { name: '97-GB-004  -  ETL  FF' }
              ]
            },
            {
              name: 'MEETING ROOM',
              children: [
                { name: '97-GB-005  -  ETL FF' },
                { name: '97-GB-006  -  ETL  FF' }
              ]
            },
            {
              name: 'NETWORK ROOM',
              children: [
                { name: '97-GB-007  -  EO FF Net Work' },
                { name: '97-GB-008  -  EO FF Net Work' }
              ]
            },
            {
              name: 'NEW ROOM',
              children: [
                { name: '97-GB-009  -  Bus Psarking' },
                { name: '97-GB-010  -  Bus Parking' }
              ]
            },
            {
              name: 'OFFICE',
              children: [
                { name: '97-GB-011  -  VET' },
                { name: '97-GB-012  -  VET' },
                { name: '97-GB-013  -  CTL-OLD  GF' },
                { name: '97-GB-014  -  CTL-OLD  GF' },
                { name: '97-GB-015  -  CTL-OLD  GF' },
                { name: '97-GB-016  -  CTL-OLD  GF' },
                { name: '97-GB-017  -  CTL-OLD  GF' },
              ]
            },
            {
              name: 'OFFICE ROOM',
              children: [
                { name: '97-GB-018  -  ETL' },
                { name: '97-GB-019  -  ETL' },
                { name: '97-GB-020  -  EO' },
                { name: '97-GB-021  -  EO' },
                { name: '97-GB-022  -  EO' },
                { name: '97-GB-023  -  EO' },
                { name: '97-GB-024  -  EO' },
                { name: '97-GB-025  -  EO' },
              ]
            },
            {
              name: 'OLD ADMIN',
              children: [
                { name: '97-GB-026  -  CTL-OLD FF' },
                { name: '97-GB-027  -  CTL-OLD FF' },
                { name: '97-GB-028  -  CTL-OLD FF' },
                { name: '97-GB-029  -  CTL-OLD FF' },
                { name: '97-GB-030  -  CTL-OLD FF' },
              ]
            },
            {
              name: 'SERVER ROOM',
              children: [
                { name: '97-GB-031  -  ETL  FF' },
              ]
            },
            {
              name: 'UPS ROOM',
              children: [
                { name: '97-GB-032  -  R Building' },
                { name: '97-GB-033  -  R Building' },
                { name: '97-GB-034  -  ETL' },
                { name: '97-GB-035  -  EO' },
                { name: '97-GB-036  -  EO' },
              ],
            },
            {
              name: 'VIBRATION ROOM',
              children: [
                { name: '97-GB-037  -  ETL' },
                { name: '97-GB-038  -  ETL' },
              ],
            },
            {
              name: 'WORK STATION',
              children: [
                { name: '97-GB-039  -  VET' },
              ],
            },
            {
              name: 'BED ROOM',
              children: [
                { name: '97-GB-040  -  FIRST AID' },
              ],
            },
            {
              name: 'C D',
              children: [
                { name: '97-GB-041  -  NEW CTL' },
                { name: '97-GB-042  -  NEW CTL' },
                { name: '97-GB-043  -  NEW CTL' },
                { name: '97-GB-044  -  NEW CTL' },
                { name: '97-GB-045  -  NEW CTL' },
                { name: '97-GB-046  -  NEW CTL' },
                { name: '97-GB-086  -  N CTL' },
                { name: '97-GB-087  -  N CTL' },
                { name: '97-GB-088  -  N CTL' },
              ],
            },
            {
              name: 'CONFERENCE ROOM',
              children: [
                { name: '97-GB-047  -  MED' },
              ],
            },
            {
              name: 'DINING HALL',
              children: [
                { name: '97-GB-048  -  SERNITY' },
              ],
            },
            {
              name: 'GEAR BOX ROOM',
              children: [
                { name: '97-GB-049  -  NEW CTL' },
                { name: '97-GB-050  -  NEW CTL' },
              ],
            },
            {
              name: 'MEETING ROOM',
              children: [
                { name: '97-GB-051  -  SERNITY' },
              ],
            },
            {
              name: 'NEW SERVER ROOM',
              children: [
                { name: '97-GB-052  -  NDO 2' },
              ],
            },
            {
              name: 'OFFICE',
              children: [
                { name: '97-GB-053  -  SIX POSTER' },
                { name: '97-GB-054  -  SIX POSTER' },
                { name: '97-GB-055  -  SIX POSTER' },
                { name: '97-GB-056  -  SIX POSTER' },
                { name: '97-GB-057  -  STORES' },
                { name: '97-GB-058  -  STORES' },
                { name: '97-GB-059  -  STORES' },
                { name: '97-GB-060  -  STORES' },
                { name: '97-GB-061  -  STORES' },
              ],
            },
            {
              name: 'RECEPTION',
              children: [
                { name: '97-GB-062  -  FIRST AID' },
              ],
            }, {
              name: 'RING ROOM',
              children: [
                { name: '97-GB-063  -  EDC' },
                { name: '97-GB-064  -  EDC' },
              ],
            }, {
              name: 'TEST BED  1 to12',
              children: [
                { name: '97-GB-065  -  EDC' },
              ],
            }, {
              name: 'TEST BED  1 to12',
              children: [
                { name: '97-GB-066  -  EDC' },
              ]
            }, {
              name: 'TEST BED  13 to 24',
              children: [
                { name: '97-GB-067  -  EDC' },
              ]
            }, {
              name: 'TEST BED  13 to 24',
              children: [
                { name: '97-GB-068  -  EDC' },
              ]
            },
            {
              name: 'VIBRATION ROOM',
              children: [
                { name: '97-GB-069  -  NEW CTL' },
                { name: '97-GB-070  -  NEW CTL' },
                { name: '97-GB-071  -  GATE NO-1' },
                { name: '97-GB-072  -  GATE NO-1' },
              ],
            },
            {
              name: '3D ROOM',
              children: [
                { name: '97-GB-073  -  NDO 1' },
                { name: '97-GB-074  -  NDO 1' },
              ],
            },
            {
              name: 'AUDITORIUM',
              children: [
                { name: '97-GB-075  -  NDO 1' },
                { name: '97-GB-076  -  NDO 1' },
                { name: '97-GB-077  -  NDO 1' },
                { name: '97-GB-078  -  NDO 1' },
                { name: '97-GB-079  -  NDO 1' },
                { name: '97-GB-080  -  NDO 1' },
                { name: '97-GB-081  -  NDO 1' },
              ],
            },
            {
              name: 'BUG ROOM',
              children: [
                { name: '97-GB-082  -  R BUILDING' },
                { name: '97-GB-083  -  R BUILDING' },
                { name: '97-GB-084  -  R BUILDING' },
              ],
            },
            {
              name: 'CCTV',
              children: [
                { name: '97-GB-085  -  DATA CENTER' },
              ],
            },
            {
              name: 'DCM MANAGER ROOM',
              children: [
                { name: '97-GB-089  -  DATA CENTER' },
              ],
            },
            {
              name: 'EV Lab',
              children: [
                { name: '97-GB-090  -  N CTL EV Lab' },
                { name: '97-GB-091  -  N CTL EV Lab' },
              ],
            },
            {
              name: 'IBM ROOM',
              children: [
                { name: '97-GB-092  -  DATA CENTER' },
                { name: '97-GB-093  -  DATA CENTER' },
              ],
            },
            {
              name: 'NEAR DATA CENTER',
              children: [
                { name: '97-GB-094  -  NDO 1 UPS' },
                { name: '97-GB-095  -  NDO 1 UPS' },
                { name: '97-GB-096  -  NDO 1 UPS' },
              ],
            },
            {
              name: 'NEW SERVER ROOM',
              children: [
                { name: '97-GB-097  -  NDO 2' },
                { name: '97-GB-098  -  NDO 2' },
                { name: '97-GB-099  -  NDO 2' },
                { name: '97-GB-100  -  NDO 2' },
                { name: '97-GB-101  -  NDO 2' },
              ],
            },
            {
              name: 'PASSAGE',
              children: [
                { name: '97-GB-102  -  DATA CENTER' },
                { name: '97-GB-103  -  DATA CENTER' },
              ],
            },
            {
              name: 'Proto',
              children: [
                { name: '97-GB-104  -  Proto' },
                { name: '97-GB-105  -  Proto' },
                { name: '97-GB-106  -  Proto' },
                { name: '97-GB-107  -  Proto' },
                { name: '97-GB-108  -  Proto' },
              ],
            },
            {
              name: 'STORE ROOM',
              children: [
                { name: '97-GB-109  -  DATA CENTER' },
              ],
            },
            {
              name: 'STYLING STUDIO',
              children: [
                { name: '97-GB-110  -  R BUILDING' },
                { name: '97-GB-111  -  R BUILDING' },
              ],
            },
            {
              name: 'UPS ROOM',
              children: [
                { name: '97-GB-112  -  DATA CENTER' },
                { name: '97-GB-113  -  DATA CENTER' },
                { name: '97-GB-114  -  DATA CENTER' },
                { name: '97-GB-115  -  DATA CENTER' }
              ],
            },
            {
              name: 'VIP ROOM',
              children: [
                { name: '97-GB-116  -  CANTEEN' },
                { name: '97-GB-117  -  CANTEEN' },
                { name: '97-GB-118  -  CANTEEN' },
              ],
            },
            {
              name: 'VVIP ROOM',
              children: [
                { name: '97-GB-119  -  CANTEEN' },
                { name: '97-GB-120  -  CANTEEN' },
              ],
            }
          ]
        },
        {
          name: 'Johnson',
          children: [
            {
              name: 'NDO 2',
              children: [
                { name: '97-GB-121  -  York Chiller 1,2 &3 E 250 tr' },
                { name: '97-GB-124  -  Low side work' },
              ]
            },
            {
              name: 'EDC',
              children: [
                { name: '97-GB-122  -  Chiller 150TR X 3 nos' },
                { name: '97-GB-123  -  Chiller  250 TR' }
              ]
            }
          ]
        },
        {
          name: 'Mitsubishi',
          children: [
            {
              name: 'EDC',
              children: [
                { name: '97-GB-125  -  Mitsubishi VRF Outdoor Unit - UPS ROOM  & EDC Office ' },
              ]
            }
          ]
        },
        {
          name: 'Vignesh Enter',
          children: [
            {
              name: 'NCTL',
              children: [
                { name: '97-GB-126  -  Dyikin  HVAC' },
                { name: '97-GB-127  -  Dyikin  HVAC' },
              ]
            }
          ]
        },
        {
          name: 'VOLTAS',
          children: [
            {
              name: 'Canteen',
              children: [
                { name: '97-GB-128  -  Canteen -  (G Floor)' },
                { name: '97-GB-129  -  Canteen -  (G Floor)' },
                { name: '97-GB-130  -  Canteen -  (G Floor)' },
                { name: '97-GB-131  -  Canteen -  (G Floor)' },
                { name: '97-GB-132  -  Canteen -  (First Floor)' },
                { name: '97-GB-133  -  VIP Canteen' },
                { name: '97-GB-134  -  Canteen -  (Ground Floor)' },
              ]
            },
            {
              name: 'ETL',
              children: [
                { name: '97-GB-135  -  ETL ( First Floor) New VET' },
                { name: '97-GB-136  -  ETL ( First Floor) New VET' },
                { name: '97-GB-137  -  ETL ( First Floor) New VET' },
              ]
            },
            {
              name: 'MED',
              children: [
                { name: '97-GB-138  -  MED' },
                { name: '97-GB-139  -  MED' },
              ]
            },
            {
              name: 'NDO 1',
              children: [
                { name: '97-GB-140  -  NDO- 1  (Ground Floor)- Phase -1  (B-side)' },
                { name: '97-GB-141  -  NDO- 1  (Ground Floor)- Phase -1  (B-side)' },
                { name: '97-GB-142  -  NDO- 1  (Ground Floor)- Phase -1  (B-side)' },
                { name: '97-GB-143  -  NDO- 1  (Ground Floor)- Phase -1  (B-side)' },
                { name: '97-GB-144  -  NDO- 1  (Ground Floor)- Phase -1  (B-side)' },
                { name: '97-GB-145  -  NDO- 1  (Ground Floor)- Phase -1  (A-side)' },
                { name: '97-GB-146  -  NDO- 1  (Ground Floor)- Phase -1  (A-side)' },
                { name: '97-GB-147  -  NDO- 1  (Ground Floor)- Phase -1  (A-side)' },
                { name: '97-GB-148  -  NDO- 1  (Ground Floor)- Phase -1  (A-side)' },
                { name: '97-GB-149  -  NDO- 1  (Ground Floor)- Phase -1  (A-side)' },
                { name: '97-GB-150  -  NDO- 1  (First Floor)- Phase -1  (B-side)' },
                { name: '97-GB-151  -  NDO- 1  (First Floor)- Phase -1  (B-side)' },
                { name: '97-GB-152  -  NDO- 1  (First Floor)- Phase -1  (B-side)' },
                { name: '97-GB-153  -  NDO- 1  (First Floor)- Phase -1  (B-side)' },
                { name: '97-GB-154  -  NDO- 1  (First Floor)- Phase -1  (B-side)' },
                { name: '97-GB-155  -  NDO- 1  (First Floor)- Phase -1  (A-side)' },
                { name: '97-GB-156  -  NDO- 1  (First Floor)- Phase -1  (A-side)' },
                { name: '97-GB-157  -  NDO- 1  (First Floor)- Phase -1  (A-side)' },
                { name: '97-GB-158  -  NDO- 1  (First Floor)- Phase -1  (A-side)' },
                { name: '97-GB-159  -  NDO- 1  (First Floor)- Phase -1  (A-side)' },
                { name: '97-GB-160  -  NDO- 1  (First Floor)- Phase -2 (A-side)' },
                { name: '97-GB-161  -  NDO- 1  (First Floor)- Phase -2 (A-side)' },
                { name: '97-GB-162  -  NDO- 1  (First Floor)- Phase -2 (A-side)' },
                { name: '97-GB-163  -  NDO- 1  (First Floor)- Phase -2 (A-side)' },
                { name: '97-GB-164  -  NDO- 1  (First Floor)- Phase -2 (B-side)' },
                { name: '97-GB-165  -  NDO- 1  (First Floor)- Phase -2 (B-side)' },
                { name: '97-GB-166  -  NDO- 1  (First Floor)- Phase -2 (B-side)' },
                { name: '97-GB-167  -  NDO- 1  (First Floor)- Phase -2 (B-side)' },
                { name: '97-GB-168  -  NDO- 1  (First Floor)- Phase -2 (B-side)' },
                { name: '97-GB-169  -  NDO- 1  (Grand Floor)- Phase -2 (B-side)' },
                { name: '97-GB-170  -  NDO- 1  (Grand Floor)- Phase -2 (B-side)' },
                { name: '97-GB-171  -  NDO- 1  (Grand Floor)- Phase -2 (B-side)' },
                { name: '97-GB-172  -  NDO- 1  (Grand Floor)- Phase -2 (B-side)' },
                { name: '97-GB-173  -  NDO- 1  (Grand Floor)- Phase -2 (B-side)' },
                { name: '97-GB-174  -  NDO- 1  (Grand Floor)- Phase -2 (A-side)' },
                { name: '97-GB-175  -  NDO- 1  (Grand Floor)- Phase -2 (A-side)' },
                { name: '97-GB-176  -  NDO- 1  (Grand Floor)- Phase -2 (A-side)' },
                { name: '97-GB-177  -  NDO- 1  (Grand Floor)- Phase -2 (A-side)' },
                { name: '97-GB-178  -  NDO1 Lobby 2 nos' },
              ]
            },
            {
              name: 'VET',
              children: [
                { name: '97-GB-179  -  Old VET (First Floor)' },
              ]
            },

          ]
        },
      ]
    },
    {
      name: 'IN HOUSE',
      children: [
        {
          name: '51   Cooling Water System',
          children: [
            {
              name: 'Cooling tower',
              children: [{
                name: 'EDC',
                children: [
                  { name: '51-FA-001  - Cooling tower 1 Fan (TB 1 to 12) ' },
                  { name: '51-FA-002  - Cooling tower 2 Fan (TB 1 to 12) ' },
                  { name: '51-FA-003  - Cooling tower Fan 3 (TB 13 to 24) ' },
                  { name: '51-FA-004  - Cooling tower  Fan 4 (TB 13 to 24) ' },
                  { name: '51-PA-005A -  Cooling tower  Hot Water Pump 1  (TB 1 to 12)' },
                  { name: '51-PA-005B -  Cooling tower    Hot Water Pump 2  (TB 1 to 12)' },
                  { name: '51-PA-006A -  Cooling tower    Cold Water Pump 1  (TB 1 to 12)' },
                  { name: '51-PA-006B -  Cooling tower   Cold Water Pump 2  (TB 1 to 12)' },
                  { name: '51-PA-007A -  Cooling tower  Hot Water Pump 1  (TB 13 to 24) ' },
                  { name: '51-PA-007B -  Cooling tower Hot Water Pump 2 (TB 13 to 24) ' },
                  { name: '51-PA-008A -  Cooling tower  Cold Water Pump 1 (TB 13 to 24) ' },
                  { name: '51-PA-008B -  Cooling tower  Cold Water Pump 2 (TB 13 to 24) ' },
                  { name: '51-PA-008C -  Cooling tower  Cold Water Pump 3 (TB 13 to 24) ' },
                  { name: '51-PV-001  - Cooling tower  Vaccum  Pump  (TB 1 to 12)' },
                  { name: '51-PV-002  - Cooling tower  Vaccum  Pump (TB 13 to 24) ' },
                ]
              },
              {
                name: 'SUBSTATION- 2',
                children: [
                  { name: '51-FA-001 - 1250 KVA Cooling tower Fan' },
                  { name: '51-FA-002 - 2000 KVA Cooling tower Fan' },
                  { name: '51-PA-001 - 1250 KVA Cooling tower pump' },
                  { name: '51-PA-002 - 2000 KVA Cooling tower pump' },
                  { name: '51-PA-003 - Cooling tower Spare pump' },
                ]
              },
              {
                name: 'SUBSTATION- 4',
                children: [
                  { name: '51-FC-001A - Coil Cooler 1 Condenser Fan A' },
                  { name: '51-FC-001B - Coil Cooler 1 Condenser Fan B' },
                  { name: '51-FC-001C - Coil Cooler 1 Condenser Fan C' },
                  { name: '51-FC-001D - Coil Cooler 1 Condenser Fan D' },
                  { name: '51-FC-002A - Coil Cooler 2 Condenser Fan A' },
                  { name: '51-FC-002B - Coil Cooler 2 Condenser Fan B' },
                  { name: '51-FC-002C - Coil Cooler 2 Condenser Fan C' },
                  { name: '51-FC-002D - Coil Cooler 2 Condenser Fan D' },
                  { name: '51-FE-001A - HTDG-1 Exhaust fan - 1 ' },
                  { name: '51-FE-001B - HTDG-1 Exhaust fan - 2' },
                  { name: '51-FE-002A - HTDG-2 Exhaust fan - 1' },
                  { name: '51-FE-002B - HTDG-2 Exhaust fan - 2' },
                  { name: '51-PA-004A - 2000 KVA Coil Cooler Pump 1' },
                  { name: '51-PA-004B - 2000 KVA Coil Cooler Pump 2' },
                  { name: '51-PA-004C - 2000 KVA Coil Cooler Pump 3' },
                  { name: '51-PA-004D - 2000 KVA Coil Cooler Pump 4' },
                ]
              }
              ]
            },
          ]
        },
        {
          name: '53   Potable water ',
          children: [
            {
              name: 'RO Plant Pumps',
              children: [
                {
                  name: 'CANTEEN 1',
                  children: [
                    { name: '53-PA-009 - RO Plant 1 Feed Pump' },
                    { name: '53-PA-010 - RO Plant 1 Pressure Pump' },
                    { name: '53-PA-011 - RO Plant 1 Transfer Pump 1' },
                    { name: '53-PA-012 - RO Plant 1 Transfer Pump 2' },
                  ]
                },
                {
                  name: 'CANTEEN 2',
                  children: [
                    { name: '53-PA-013 - RO Plant 2 Feed Pump' },
                    { name: '53-PA-014 - RO Plant 2 Pressure Pump' },
                    { name: '53-PA-015 - RO Plant 2 Transfer Pump 1' },
                    { name: '53-PA-016 - RO Plant 2 Dosing Pump' },
                  ]
                },
                {
                  name: 'EDC',
                  children: [
                    { name: '53-PA-017 - EDC RO Plant Feed Pump' },
                    { name: '53-PA-018 - EDC RO Plant Pressure Pump' },
                    { name: '53-PA-019 - EDC RO Plant Transfer Pump 1' },
                    { name: '53-PA-020 - EDC RO Plant Transfer Pump 2' },
                  ]
                },
                {
                  name: 'NCTL',
                  children: [
                    { name: '53-PA-021 - FEED PUMP-1' },
                    { name: '53-PA-022 - FEED PUMP-2' },
                    { name: '53-PA-023 - PRESSER PUMP' },
                    { name: '53-PA-024 - TRANSFER PUMP-1' },
                    { name: '53-PA-025 - TRANSFER PUMP-2' },
                    { name: '53-PA-026 - TRANSFER PUMP-3' },
                    { name: '53-PA-027 - TRANSFER PUMP-4' },
                  ]
                },
                {
                  name: 'CONEFRENCE CENTRE',
                  childern: [
                    { name: '53-PA-004 - transfer [STP]' }
                  ]
                },
                {
                  name: 'EDC ',
                  childern: [
                    { name: '53-PA-002 - EDC Garden Pump (RO Rejection)' }
                  ]
                },
                {
                  name: 'NCTL METRO ROOM',
                  childern: [
                    { name: '53-PA-005A - OHT  TRANSER PUMP-1' },
                    { name: '53-PA-005B - OHT  TRANSER PUMP-2' },
                    { name: '53-PA-006  - TRANSER PUMP [C/GROUND]' }
                  ]
                },
                {
                  name: 'NDO2',
                  childern: [
                    { name: '53-PA-001A - NDO2 Pump 1' },
                    { name: '53-PA-001B - NDO2 Pump 2' },
                  ]
                },
                {
                  name: 'New CTL',
                  childern: [
                    { name: '53-PS-004 - New CTL   Bore Well Pump' },
                  ]
                },
                {
                  name: 'New METRO WATER',
                  childern: [
                    { name: '53-PS-001A - New Metro Water submersible  Pump 1' },
                    { name: '53-PS-001B - New Metro Water submersible  Pump 2' },
                    { name: '53-PS-002 - New Metro Water Bore Well Pump' },
                  ]
                },
                {
                  name: 'New VET Office',
                  childern: [
                    { name: '53-PS-003 - New VET Office   Bore Well Pump' },
                  ]
                },
                {
                  name: 'SUMP 3',
                  childern: [
                    { name: '53-PA-003A - Transfer pump 1' },
                    { name: '53-PA-003B - Transfer pump 2' },
                  ]
                }
              ]
            }
          ]
        },
        {
          name: '62   Fuel Oil System',
          children: [
            {
              name: 'Diesel Yard Pumps',
              children: [
                {
                  name: 'EDC',
                  children: [
                    { name: '62-PB-005 - TB Diesel Transfer Pump 1 (TB1 to TB12)' },
                    { name: '62-PB-006 - TB Diesel Transfer Pump 2 (TB1 to TB12)' },
                    { name: '62-PB-007 - TB Diesel Transfer Pump 1 (TB13 to TB24)' },
                    { name: '62-PB-008 - TB Diesel Transfer Pump 2 (TB13 to TB24)' }
                  ]
                },
                {
                  name: 'SUBSTATION- 1',
                  children: [
                    { name: '62-PB-001 - Dispenser unit' }
                  ]
                },
                {
                  name: 'SUBSTATION- 2',
                  children: [
                    { name: '62-PB-002 - Transfer Pump 1' },
                    { name: '62-PB-003 - Transfer Pump 2' }
                  ]
                },
                {
                  name: 'SUBSTATION- 4',
                  children: [
                    { name: '62-PB-004 - Transfer Pump 1' },
                  ]
                }
              ]
            }
          ]
        },
        {
          name: '63   Compressed Air',
          children: [
            {
              name: 'Compressor',
              children: [
                {
                  name: 'INSTRUMENT',
                  children: [
                    { name: '63-KB-001A - ELGI 101B Portable Compressor' }
                  ]
                },
                {
                  name: 'PROTOSHOP',
                  children: [
                    { name: '63-KB-004 - ELGI TS 15 LB Comperssor' }
                  ]
                },
                {
                  name: 'SIX POSTER',
                  children: [
                    { name: '63-KB-002 - ATLAS COPCO GX11 Screw Compressor' }
                  ]
                },
                {
                  name: 'STYLING STUDIO',
                  children: [
                    { name: '63-KB-001B - ELGI 101B Portable Compressor' }
                  ]
                },
                {
                  name: 'VET',
                  children: [
                    { name: '63-KB-003 - INGERSOLL RAND PV- 06 (IR)' }
                  ]
                }
              ]
            }
          ]
        },
        {
          name: '66   Sewage Treatment',
          children: [
            {
              name: 'STP Pumps',
              children: [
                {
                  name: 'CTL',
                  children: [
                    { name: '66-PS-003 - Submersible Pump 1' }
                  ]
                },
                {
                  name: 'NEAR STORES',
                  children: [
                    { name: '66-KF-001 - Air Blower ' },
                    { name: '66-PS-004 - Submersible Pump 1' },
                    { name: '66-PS-005 - Submersible Pump 2' },
                  ]
                },
                {
                  name: 'New STP PLANT',
                  children: [
                    { name: '66-KF-002   - Air Blower 1' },
                    { name: '66-KF-003   - Air Blower 2' },
                    { name: '66-PA-001   - Raw Pump 1' },
                    { name: '66-PA-002   - Raw Pump 2' },
                    { name: '66-PA-003A  -  Filter Feed Pump 1' },
                    { name: '66-PA-003B  -  Filter Feed Pump 2' },
                    { name: '66-PA-004   - UF Raw Pump 1' },
                    { name: '66-PA-005   - UF Back Wash Pump' },
                    { name: '66-PA-006   - UF Outlet Pump' },
                    { name: '66-PB-001A  -  Sludge Pump 1' },
                    { name: '66-PB-001B  -  Sludge Pump 2' },
                    { name: '66-PB-002   - Secondary Sludge Pump' },
                    { name: '66-PB-003   - Dosing pump' },
                    { name: '66-PB-004   - UF Dosing Pump 1' },
                    { name: '66-PG-001   - Screw Pump' },
                    { name: '66-SO-001-DE - Oil Skimmer Motor' },
                  ]
                },
                {
                  name: 'Old STP COLLECTION SUMP',
                  children: [
                    { name: '66 - 04     Air Blower ' },
                    { name: '66-PS-006 - Submersible Pump 1' },
                    { name: '66-PS-007 - Submersible Pump 2' }
                  ]
                },
                {
                  name: 'Old STP TREATED WATER SUMP',
                  children: [
                    { name: '66-PS-008 - Submersible Pump 1' },
                    { name: '66-PS-009 - Submersible Pump 2' }
                  ]
                },
                {
                  name: 'PROTOSHOP 1',
                  children: [
                    { name: '66-PS-010 - Submersible Pump 1' }
                  ]
                },
                {
                  name: 'PROTOSHOP 2',
                  children: [
                    { name: '66-PS-011 - Single Ph Portable pump' }
                  ]
                },
                {
                  name: 'SS4 BACK SIDE',
                  children: [
                    { name: '66-KF-005 - Air Blower ' },
                    { name: '66-PS-012 - Submersible Pump 1' },
                    { name: '66-PS-013 - Submersible Pump 2' }
                  ]
                },
                {
                  name: 'VET',
                  children: [
                    { name: '66-KF-006 - Air Blower ' },
                    { name: '66-PA-007 - Transfer Pump 1' },
                    { name: '66-PA-008 - Transfer Pump 2' },
                    { name: '66-PS-014 - Submersible Pump 1' },
                    { name: '66-PS-015 - Submersible Pump 2' },
                  ]
                }
              ]
            },
            {
              name: 'Water Pumps',
              childern: [
                {
                  name: 'EDC',
                  childern: [
                    { name: '66-PS-001 - EDC Drain water submersible   pump TB 1 - 12' },
                    { name: '66-PS-002 - EDC Drain water submersible   pump TB 13-24' }
                  ]
                }
              ]
            }
          ]
        },
        {
          name: '73   Material Handling',
          children: [
            {
              name: 'Cranes',
              children: [
                {
                  name: 'CTL',
                  children: [
                    { name: '73-ME-008 - 10T Crane' },
                    { name: '73-ME-009 - 5T Crane' }
                  ]
                },
                {
                  name: 'EDC', children: [
                    { name: '73-MB-001 - EDC TB 1 - 2T Crane' },
                    { name: '73-MB-002 - EDC TB 2 - 2T Crane' },
                    { name: '73-MB-003 - EDC TB 3 - 2T Crane' },
                    { name: '73-MB-004 - EDC TB 4 - 2T Crane' },
                    { name: '73-MB-005 - EDC TB 5 - 2T Crane' },
                    { name: '73-MB-006 - EDC TB 6 - 2T Crane' },
                    { name: '73-MB-007 - EDC TB 7 - 2T Crane' },
                    { name: '73-MB-008 - EDC TB 8 - 2T Crane' },
                    { name: '73-MB-009 - EDC TB 9 - 2T Crane' },
                    { name: '73-MB-010 - EDC TB 10 - 2T Crane' },
                    { name: '73-MB-011 - EDC TB 11 - 2T Crane' },
                    { name: '73-MB-012 - EDC TB 12 - 2T Crane' },
                    { name: '73-MB-013 - EDC TB 13 - 2T Crane' },
                    { name: '73-MB-014 - EDC TB 14 - 2T Crane' },
                    { name: '73-MB-015 - EDC TB 15 - 2T Crane' },
                    { name: '73-MB-016 - EDC TB 16 - 2T Crane' },
                    { name: '73-MB-017 - EDC TB 17 - 2T Crane' },
                    { name: '73-MB-018 - EDC TB 18 - 2T Crane' },
                    { name: '73-MB-019 - EDC TB 19 - 2T Crane' },
                    { name: '73-MB-020 - EDC TB 20 - 2T Crane' },
                    { name: '73-MB-021 - EDC TB 21 - 2T Crane' },
                    { name: '73-MB-022 - EDC TB 22 - 2T Crane' },
                    { name: '73-MB-023 - EDC TB 23 - 2T Crane' },
                    { name: '73-MB-024 - EDC TB 24 - 2T Crane' },
                    { name: '73-MB-025 - Service Floor 4T Crane (TB1 to TB12)' },
                    { name: '73-MB-026 - Service Floor 4T Crane (TB13 to TB24)' },
                    { name: '73-MB-027 - Engine Assembly 2T Crane' },
                    { name: '73-MB-028 - Engine Stores 2T Crane' },
                    { name: '73-MB-029 - Swirl Room 1T Crane' },
                  ]
                },
                {
                  name: 'MED', children: [
                    { name: '73-ME-002 - 1T Apex Jib Crane' }
                  ]
                },
                {
                  name: 'N CTL', children: [
                    { name: '73-MB-030     N CTL 10 T Crane  Bay 1' },
                    { name: '73-MB-031     N CTL 10 T Crane  Bay 2' },
                    { name: '73-MB-032     N CTL 5 T Crane  Bay 1' },
                    { name: '73-MB-033     NCTL/WELDING ROOM 2T' },
                    { name: '73-MB-034     NCTL HPU ROOM 2T  [MANUAL]' },
                    { name: '73-MB-035     NCTL/STORE 2T' }
                  ]
                },
                {
                  name: 'PROTOSHOP 1', children: [
                    { name: '73-ME-004 - 3T/10T Crane' },
                    { name: '73-ME-005 - 5T Crane' }
                  ]
                },
                {
                  name: 'PROTOSHOP 2', children: [
                    { name: '73-ME-006 - 3T/10T Crane' }
                  ]
                },
                {
                  name: 'SIX POSTER', children: [
                    { name: '73-ME-001A - 5T Crane 1' },
                    { name: '73-ME-001B - 5T Crane 2' },
                  ]
                },
                {
                  name: 'STORES', children: [
                    { name: '73-ME-003 - 5T Crane' }

                  ]
                },
                {
                  name: 'VET - WORKSHOP', children: [
                    { name: '73-ME-007 - 2T Crane' }
                  ]
                },
              ]
            }
          ]
        },
        {
          name: '81   HT POWER',
          children: [
            {
              name: 'Electrical Panels',
              children: [
                {
                  name: 'EDC COMPRESSOR ROOM',
                  children: [
                    { name: '81-EC-001 - Compressor VFD Panel 1' },
                    { name: '81-EC-002 - Compressor VFD Panel 1' }
                  ]
                }
              ]
            },
            {
              name: 'DG',
              children: [
                {
                  name: 'SUBSTATION- 2',
                  children: [
                    { name: '81-EG-001 - 2000 KVA Cummins LT DG' },
                    { name: '81-EG-002 - 1250 KVA Cummins LT DG' }
                  ]
                },
                {
                  name: 'SUBSTATION- 4',
                  children: [
                    { name: '81-EG-003 - 2000 KVA  Perkins HT DG 1' },
                    { name: '81-EG-004 - 2000 KVA Perkins HT DG 2' }
                  ]
                }
              ]
            },
            {
              name: 'Diesel pump',
              children: [
                {
                  name: 'CG',
                  children: [
                    { name: '62-PB-012 - Kirloskar 5 HP Pump 4' }
                  ]
                },
                {
                  name: 'SS4 ( canteen 1 )',
                  children: [
                    { name: '62-PB-009 - Kirloskar 5 HP Pump 1' }
                  ]
                },
                {
                  name: 'SUBSTATION- 3',
                  children: [
                    { name: '62-PB-011 - Kirloskar 5 HP Pump 3' }
                  ]
                },
                {
                  name: 'SUBSTATION- 4',
                  children: [
                    { name: '62-PB-010 - Kirloskar 5 HP Pump 2' }
                  ]
                },
              ]
            },
            {
              name: 'Electrical Panels',
              children: [
                {
                  name: 'BUS PARKING AREA',
                  children: [
                    { name: '81-EC-044 - SSB panel' }
                  ]
                },
                {
                  name: 'BUS PARKING EV',
                  children: [
                    { name: '81-EC-064 - MAIN MV PANEL' },
                    { name: '81-EC-065 - RTCC PANEL' },
                    { name: '81-EC-066 - 300 KVAR APFC PANE' }
                  ]
                },
                {
                  name: 'CANTEEN 1',
                  children: [
                    { name: '81-EC-019 - PDB C2 GF (Right Side) ' },
                    { name: '81-EC-020 - PDB C3 GF (Left Side)' },
                    { name: '81-EC-021 - LDB C2 GF (Right Side)' },
                    { name: '81-EC-022 - LDB C3 GF (Left Side)' },
                    { name: '81-EC-023 - LDB C5 FF (Left side)' },
                    { name: '81-EC-024 - PDB C7 FF (Left side)' },
                    { name: '81-EC-025 - LDB C6 FF (Right side)' },
                    { name: '81-EC-026 - PDB C8 FF (Right side)' },
                    { name: '81-EC-027 - LDB C1 (Kitchen)' },
                    { name: '81-EC-028 - PDB C1 (Kitchen)' },
                    { name: '81-EC-029 - PDB C4 (Kitchen)' },
                    { name: '81-EC-030 - Power Panel' },
                    { name: '81-EC-031 - Control Panel' },
                    { name: '81-EC-032 - Canteen 1 RO Panel' }
                  ]
                },
                {
                  name: 'CANTEEN 2',
                  children: [
                    { name: '81-EC-020 - GF - PDB - WC 1' },
                    { name: '81-EC-021 - GF - PDB - WC 2' },
                    { name: '81-EC-022 - GF - LDB 1' },
                    { name: '81-EC-023 - FF LDB 1' },
                    { name: '81-EC-033 - Canteen 2 RO Panel' }
                  ]
                },
                {
                  name: 'CONFRENCE CENTRE',
                  children: [
                    { name: '81-EC-039 - Main panel' }
                  ]
                },
                {
                  name: 'Cricket Ground Rest room GF',
                  children: [
                    { name: '81-EC-046 - Cubical Switch Board' }
                  ]
                },
                {
                  name: 'EAST PUMP ROOM',
                  children: [
                    { name: '81-EC-068 - MCC PANEL 1' },
                    { name: '81-EC-069 - VFD PANEL' }
                  ]
                },
                {
                  name: 'EDC COMPRESSOR ROOM',
                  children: [
                    { name: '81-EC-067 - SUB PANEL 9' }
                  ]
                },
                {
                  name: 'ENGINE OFFICE GF',
                  children: [
                    { name: '81-EC-024 - PDB - G1' },
                    { name: '81-EC-025 - UPS DB - G1' },
                    { name: '81-EC-026 - LDB - G1' },
                    { name: '81-EC-027 - LSB - EO' },
                    { name: '81-EC-028 - LDB - G2' },
                  ]
                },
                {
                  name: 'FF 1-12 SIDE',
                  children: [
                    { name: '81-EC-080 - HVAC MCC-1' },
                    { name: '81-EC-081 - HVAC MCC-2' },
                    { name: '81-EC-082 - SUB PANEL-1' },
                    { name: '81-EC-083 - SUB PANEL-2' },
                    { name: '81-EC-084 - SUB PANEL-3' },
                    { name: '81-EC-085 - SUB PANEL-4' },
                  ]
                },
                {
                  name: 'FF 13-24 SIDE',
                  children: [
                    { name: '81-EC-086 - HVAC PANEL MCC-1' },
                    { name: '81-EC-087 - HVAC PANEL MCC-3' },
                    { name: '81-EC-088 - SUB PANEL-5' },
                    { name: '81-EC-089 - SUB PANEL-6' },
                  ]
                },
                {
                  name: 'FF CENTER AREA NEAR UPS ROOM',
                  children: [
                    { name: '81-EC-072 - CHILLER PANEL' },
                    { name: '81-EC-073 - RAW POWER PANEL -1' },
                    { name: '81-EC-074 - RAW POWER PANEL -2' },
                    { name: '81-EC-075 - HVAC PANEL-06' },
                    { name: '81-EC-076 - MAIN AHU PANEL-1' },
                    { name: '81-EC-077 - MAIN AHU PANEL-2' },
                    { name: '81-EC-078 - LIGHTING PANEL' },
                    { name: '81-EC-079 - LES PANEL' },
                  ]
                },
                {
                  name: 'INSTRUMENT OFFICE',
                  children: [
                    { name: '81-EC-004 - PDB Panel ' },
                    { name: '81-EC-005 - UPS DB Panel' }
                  ]
                },
                {
                  name: 'NCTL',
                  children: [
                    { name: '81-EC-090 - CTL MAIN PANEL' },
                    { name: '81-EC-091 - CTL SUB PANEL' },
                    { name: '81-EC-031 - MAIN LIGHTING PANEL' },
                  ]
                },
                {
                  name: 'NCTL METRO ROOM',
                  children: [
                    { name: '81-EC-041 - OHT-3 PUMP ROOM PANEL' },
                    { name: '81-EC-042 - FIRE MCC PANEL' },
                    { name: '81-EC-043 - DISEL ENGINE CENTEL PANEL' }
                  ]
                },
                {
                  name: 'NCTL/BAY-2',
                  children: [
                    { name: '81-EC-092 - MVP PANEL' }
                  ]
                },
                {
                  name: 'NCTL/BAY-3',
                  children: [
                    { name: '81-EC-095 - MVP PANEL' },
                    { name: '81-EC-096 - MLP PANEL' },
                    { name: '81-EC-097 - Chassis Dyno Panel' }
                  ]
                },
                {
                  name: 'NCTL/F/F',
                  children: [
                    { name: '81-EC-093 - HVAC PANEL-5' },
                    { name: '81-EC-094 - MCCI PANEL' }
                  ]
                },
                {
                  name: 'NDO2',
                  children: [
                    { name: '81-EC-034 - AHU Panel 1' },
                    { name: '81-EC-035 - AHU Panel 2' },
                    { name: '81-EC-036 - Raw Power Panel' },
                    { name: '81-EC-037 - Lighting Panel ' },
                    { name: '81-EC-038 - Chiller Panel' },
                    { name: '85-EC-018 - UPS Panel 1' },
                  ]
                },
                {
                  name: 'NEW STP',
                  children: [
                    { name: '81-EC-045 - MCC.  S2 PANEL' }
                  ]
                },
                {
                  name: 'New VET Office  FF',
                  children: [
                    { name: '81-EC-006 - UPS Panel - 8 (First Floor)' },
                    { name: '81-EC-007 - UPS Panel - 8A (First Floor)' }
                  ]
                },
                {
                  name: 'Old VET Office',
                  children: [
                    { name: '81-EC-008 - A/C DB Panel' },
                    { name: '81-EC-009 - UPS DB - 1' },
                    { name: '81-EC-010 - LDB - Outside Panel' },
                  ]
                },
                {
                  name: 'SS2 FF',
                  children: [
                    { name: '81-EC-011 - 600 KVAR Capacitor Panel' },
                    { name: '81-EC-012 - MV Panel 2 (NDO2)' },
                    { name: '81-EC-013 - DG AMF SYNC Panel' },
                  ]
                },
                {
                  name: 'SS2 GF',
                  children: [
                    { name: '81-EC-001 - RTCC Panel' },
                    { name: '81-EC-002 - 600 KVAR Capacitor Panel' },
                    { name: '81-EC-003 - 1250 KVA Panel' },
                    { name: '81-EC-004 - 2000 KVA Panel' },
                    { name: '81-EC-005 - 1250 KVA Perkins Panel' },
                    { name: '81-EC-006 - MV Panel 1 (EB Side)' },
                    { name: '81-EC-007 - MV Panel 1 (1250 KVA Side)' },
                    { name: '81-EC-008 - MLSB Panel' },

                  ]
                },
                {
                  name: 'STORES OFFICE',
                  children: [
                    { name: '81-EC-014 - SPDB Store (PH 2)' },
                    { name: '81-EC-015 - LDB Panel' },
                    { name: '81-EC-016 - LDB S1' },
                    { name: '81-EC-017 - LDB S2' },
                    { name: '81-EC-018 - PDB S1' },

                  ]
                },
                {
                  name: 'SUBSTATION- 2 GF',
                  children: [
                    { name: '81-EC-009 - MPSB Panel' },
                    { name: '81-EC-010 - PDB - SS' }
                  ]
                },
                {
                  name: 'SUBSTATION- 3',
                  children: [
                    { name: '81-EC-001 - MV Panel' },
                    { name: '81-EC-002 - 200 KVAR Capacitor Panel' }
                  ]
                },
                {
                  name: 'SUBSTATION- 4',
                  children: [

                    { name: '81-EC-047 - NIS and NGR panel' },
                    { name: '81-EC-048 - 700 KVAR APFC with panel 1' },
                    { name: '81-EC-049 - 700 KVAR APFC with panel 2' },
                    { name: '81-EC-050 - 700 KVAR APFC with panel 3' },
                    { name: '81-EC-051 - 700 KVAR APFC with panel 4' },
                    { name: '81-EC-052 - 875 KVAR APFC with panel  5' },
                    { name: '81-EC-053 - Main lighting panel' },
                    { name: '81-EC-054 - Station AUX raw power panel' },
                    { name: '81-EC-055 - RTCC panel 1' },
                    { name: '81-EC-056 - RTCC panel 2' },
                    { name: '81-EC-057 - RTCC panel 3' },
                    { name: '81-EC-058 - Main mv panel Transformer 1 Side ' },
                    { name: '81-EC-059 - Main mv panel Transformer 2 Side ' },
                    { name: '81-EC-060 - Main mv panel Transformer 3 Side ' },
                    { name: '81-EC-061 - DG-1  woodward panel' },
                    { name: '81-EC-062 - DG-2 woodward panel' },
                    { name: '81-EC-063 - DG AUXILARY panel' }

                  ]
                },
                {
                  name: 'Sump 3 Room',
                  children: [
                    { name: '81-EC-040 - Metro water sump 3 Panel' }
                  ]
                },
                {
                  name: 'VET LIFT GATE',
                  children: [
                    { name: '81-EC-003 - Track Lighting Panel' }
                  ]
                },
                {
                  name: 'VET Workshop',
                  children: [
                    { name: '81-EC-011 - UPS DB-1' },
                    { name: '81-EC-012 - VDB - 4' },
                    { name: '81-EC-013 - PDB - 1' },
                    { name: '81-EC-014 - PSB -WC' },
                    { name: '81-EC-015 - PSP ' },
                    { name: '81-EC-016 - PSB - 1' },
                    { name: '81-EC-017 - LSB-1' },
                    { name: '81-EC-018 - VET MSB' },
                    { name: '81-EC-019 - LDB - 6' }
                  ]
                },
                {
                  name: 'WEST PUMP ROOM',
                  children: [
                    { name: '81-EC-070 - MCC PANEL 2' },
                    { name: '81-EC-071 - VFD PANEL' }
                  ]
                },
                {
                  name: 'BUS PARKING',
                  children: [
                    { name: '81-EC-130 - 11 KV VCB ' }
                  ]
                },
                {
                  name: 'MRS ROOM',
                  children: [
                    { name: '81-EN-001 - MRS Incomer HT Breaker' },
                    { name: '81-EN-002 - Outgoing to SS1 HT Breaker' },
                    { name: '81-EN-003 - Outgoing to SS3 HT Breaker' },
                    { name: '81-EN-004 - Outgoing to SS4 HT Breaker 1' },
                    { name: '81-EN-005 - Outgoing to SS4 HT Breaker 2' },
                  ]
                },
                {
                  name: 'SUBSTATION- 1',
                  children: [
                    { name: '81-EN-103 - Main Incomer HT Breaker' },
                    { name: '81-EN-104 - Outgoing to SS2 HT Breaker' },
                    { name: '81-EN-105 - DG Incomer HT Breaker' },
                    { name: '81-EN-106 - Outgoing to 1000 KVA HT Breaker' }
                  ]
                },
                {
                  name: 'SUBSTATION- 2',
                  children: [
                    { name: '81-EN-111 - Outgoing to 2000 KVA Transformer 1 HT Breaker' },
                    { name: '81-EN-112 - Incoming from SS1 HT Breaker' },
                    { name: '81-EN-113 - Incoming from SS3 HT Breaker' },
                    { name: '81-EN-114 - Bus Coupler HT Breaker' },
                    { name: '81-EN-115 - DG Incomer HT Breaker' },
                    { name: '81-EN-116 - Outgoing to 2000 KVA Transformer 2 HT Breaker' }
                  ]
                },
                {
                  name: 'SUBSTATION- 3',
                  children: [
                    { name: '81-EN-107 - Main Incomer HT Breaker' },
                    { name: '81-EN-108 - Outgoing to SS2 HT Breaker' },
                    { name: '81-EN-109 - DG Incomer HT Breaker' },
                    { name: '81-EN-110 - Outgoing to 500 KVA HT Breaker' }
                  ]
                },
                {
                  name: 'SUBSTATION- 4',
                  children: [
                    { name: '81-EN-117 -  HT DG-1 Incomer Breaker-1' },
                    { name: '81-EN-118 -  HT DG-2 Incomer Breaker-2' },
                    { name: '81-EN-119 - Outgoing SS-2' },
                    { name: '81-EN-120 - Outgoing SS-1' },
                    { name: '81-EN-121 - Outgoing SS-4' },
                    { name: '81-EN-122 - Outgoing SS-3' },
                    { name: '81-EN-123 - MRS-1 Incomer' },
                    { name: '81-EN-124 - MRS-2 Incomer' },
                    { name: '81-EN-125 - 3150 KVA Transformer-1' },
                    { name: '81-EN-126 - Bus Coupler HT Breaker' },
                    { name: '81-EN-127 - 3150 KVA Transformer-2' },
                    { name: '81-EN-128 - Outgoing to SS-4' },
                    { name: '81-EN-129 - 2500 KVA Transformer -3' }
                  ]
                },
              ]
            },
          ]
        },
        {
          name: '82   LT Power',
          children: [
            {
              name: 'Solar Street Lights',
              children: [
                {
                  name: 'NCTL to Sump 3',
                  children: [
                    { name: '82-CB-012 - Solar Street Light Battery (  29 No\'s) ' }
                  ]
                }
              ]
            },
            {
              name: 'Canteen Bain Marie',
              children: [
                {
                  name: 'CANTEEN 1',
                  children: [
                    { name: '82-BM-001A - Bain Marie A' },
                    { name: '82-BM-001B - Bain Marie B' },
                    { name: '82-BM-001C - Bain Marie C' },
                    { name: '82-BM-001D - Bain Marie D' },
                    { name: '82-BM-001E - Bain Marie E' },
                    { name: '82-BM-001F - Bain Marie F' },
                    { name: '82-BM-001G - Bain Marie G' },
                    { name: '82-BM-001H - Bain Marie H' },
                    { name: '82-BM-001I - Bain Marie I' },
                    { name: '82-BM-001J - Bain Marie J' }
                  ]
                }
              ]
            },
            {
              name: 'Dishwashing Machine',
              children: [
                {
                  name: 'CANTEEN 1',
                  children: [
                    { name: '82-FW-001A - Dishwashing Machine Fillter Cleaning  A' },
                    { name: '82-FW-001B - Dishwashing Machine Fillter Cleaning  B' },
                    { name: '82-FW-001C - Dishwashing Machine Fillter Cleaning  C' },
                    { name: '82-FW-001D - Dishwashing Machine Fillter Cleaning  D' }
                  ]
                }
              ]
            },
            {
              name: 'Exhaust Blower ',
              children: [
                {
                  name: 'CANTEEN 1',
                  children: [
                    { name: '82-BE-001 - Exhaust Blower Old Kitchen' },
                    { name: '82-BE-002 -Exhaust Blower New Kitchen ' }
                  ]
                }
              ]
            },
            {
              name: 'Oil Skimmer',
              children: [
                {
                  name: 'CANTEEN 1',
                  children: [
                    { name: '82-SO-001 - Oil Skimmer' },
                  ]
                }
              ]
            },
            {
              name: 'Canteen Bain Marie',
              children: [
                {
                  name: 'CANTEEN 2',
                  children: [
                    { name: '82-BM-002A - Bain Marie A' },
                    { name: '82-BM-002B - Bain Marie B' },
                    { name: '82-BM-002C - Bain Marie C' },
                  ]
                }
              ]
            },
            {
              name: 'Dishwashing Machine',
              children: [
                {
                  name: 'CANTEEN 2',
                  children: [
                    { name: '82-FW-002A - Dishwashing Machine Fillter Cleaning A' },
                    { name: '82-FW-002B - Dishwashing Machine Fillter Cleaning B' },
                  ]
                }
              ]
            },
            {
              name: 'Exhaust Blower ',
              children: [
                {
                  name: 'CANTEEN 2',
                  children: [
                    { name: '82-BE-003 - Exhaust Blower 1' },
                    { name: '82-BE-004 - Exhaust Blower 1' }
                  ]
                }
              ]
            },
            {
              name: 'Oil Skimmer',
              children: [
                {
                  name: 'CANTEEN 2',
                  children: [
                    { name: '82-SO-002 - Oil Skimmer' }
                  ]
                }
              ]
            },
            {
              name: 'Electrical Panels',
              children: [
                {
                  name: 'PROTOSHOP 1',
                  children: [
                    { name: '82-EC-009 - SB - PT Panel' },
                    { name: '82-EC-010 - LSB - PT Panel' },
                    { name: '82-EC-011 - LDB-P1' },
                    { name: '82-EC-012 - LDB-P2' },
                    { name: '82-EC-013 - LDB-P3' },
                    { name: '82-EC-014 - PDB -PT I' },
                    { name: '82-EC-015 - PDB - S3' },
                    { name: '82-EC-016 - PDB - P2' },
                    { name: '82-EC-017 - PDB - S2' },
                    { name: '82-EC-018 - PDB - P1' }
                  ]
                },
                {
                  name: 'PROTOSHOP 2',
                  children: [
                    { name: '82-EC-019 - SSB PT - 2/1' },
                    { name: '82-EC-020 - SBPT-PT2' },
                    { name: '82-EC-021 - PDB PT 2/2' },
                    { name: '82-EC-022 - LDB - PT2' }
                  ]
                },
                {
                  name: 'SIX POSTER',
                  children: [
                    { name: '82-EC-023 - HPS U1' },
                    { name: '82-EC-024 - HPS U2' },
                    { name: '82-EC-025 - HPS U3' },
                    { name: '82-EC-026 - HPS U4' },
                    { name: '82-EC-027 - PDB - 6P/A & 6P/B' },
                    { name: '82-EC-028 - SSB - 6P' },
                    { name: '82-EC-029 - LDB - 6P' },
                    { name: '82-EC-030 - UPS DB - 6P' }
                  ]
                },
                {
                  name: 'SUBSTATION- 1',
                  children: [
                    { name: '82-EC-001 - LT Panel - A' },
                    { name: '82-EC-002 - MV SB Panel' },
                    { name: '82-EC-003 - Double Bus Panel' },
                    { name: '82-EC-004 - MLSB Panel' },
                    { name: '82-EC-005 - 90 KVAR Capacitor Panel' },
                    { name: '82-EC-006 - 200 KVAR Capacitor Panel' },
                    { name: '82-EC-007 - RTCC Panel' },
                    { name: '82-EC-008 - LDB - ST' }
                  ]
                },

              ]
            },
            {
              name: 'Solar Panel',
              children: [
                {
                  name: 'Old CTL',
                  children: [
                    { name: '82-CB-010 - Solar Panel' }
                  ]
                }
              ]
            },
            {
              name: 'Solar Street Lights',
              children: [
                {
                  name: 'BUS PARKING TO SS4',
                  children: [
                    { name: '82-CB-013 - Solar Street Light Battery (  21 No\'s) ' }
                  ]
                },
                {
                  name: 'CG to NSTP',
                  children: [
                    { name: '82-CB-012 - Solar Street Light Battery ( 10  No]\'s) ' }
                  ]
                },
                {
                  name: 'DO2',
                  children: [
                    { name: '82-CB-011 - Solar Street Light Battery (2 No\'s) ' }
                  ]
                }
              ]
            }
          ]
        },
        {
          name: '85   UPS',
          children: [
            {
              name: 'Electrical Panels',
              children: [
                {
                  name: 'EDC UPS room',
                  children: [
                    { name: '85-EC-017 - 80 kva UPS Panel 1 & 2' },
                    { name: '85-EC-018 - 80 kva UPS Panel 3 & 4' },
                    { name: '85-EC-019 - 40 kva UPS panel 1 & 2' },
                  ]
                },
                {
                  name: 'ENGINE OFFICE FF',
                  children: [
                    { name: '85-EC-009 - LDB-F4' },
                    { name: '85-EC-010 - PDB-F4' },
                    { name: '85-EC-011 - LDB -3' },
                    { name: '85-EC-012 - PDB -3' },
                    { name: '85-EC-013 - UPS DB - 7' },
                    { name: '85-EC-014 - UPS DB - 8' },
                    { name: '85-EC-015 - UPS DB - 9' },
                    { name: '85-EC-016 - A/C Panel - FF' }
                  ]
                },
                {
                  name: 'ENGINE OFFICE GF',
                  children: [
                    { name: '81-EC-029 - PDB - G2' },
                    { name: '85-EC-001 - UPS DB - G2' },
                    { name: '85-EC-002 - UPS DB - G3' },
                    { name: '85-EC-003 - UPS DB - G4' },
                    { name: '85-EC-004 - UPS DB - G5' },
                    { name: '85-EC-005 - UPS DB - G6' },
                    { name: '85-EC-006 - SB - UPS' },
                    { name: '85-EC-007 - SB - EO' },
                    { name: '85-EC-008 - A/C Panel - GF' }

                  ]
                },
                {
                  name: 'NCTL',
                  children: [
                    { name: '85-EC-026 - HPU PANEL' }
                  ]
                },
                {
                  name: 'NCTL /bay-2',
                  children: [
                    { name: '85-EC-022 - 75 KVA Stabilizer' }
                  ]
                },
                {
                  name: 'NCTL /f/f',
                  children: [
                    { name: '85-EC-023 - 150 KVA Stabilizer' }
                  ]
                },
                {
                  name: 'NCTL /LDS/Room',
                  children: [
                    { name: '85-EC-021 - 45 KVA Stabilizer' }
                  ]
                },
                {
                  name: 'NCTL Bay-1',
                  children: [
                    { name: '85-EC-020 - 125 KVA Stabilizer' },
                    { name: '85-EC-025 - APFC PANEL' }
                  ]
                },
                {
                  name: 'NCTL/BAY-3',
                  children: [
                    { name: '85-EC-024 - 50 KVA Stabilizer' }
                  ]
                },
                {
                  name: 'STORES OFFICE',
                  children: [
                    { name: '85-EC-017 - UPS DB -1' }
                  ]
                },
                {
                  name: 'CTL',
                  children: [
                    { name: '85-EC-027 - 40 KVA UPS 1 Emerson' }
                  ]
                },
                {
                  name: 'EDC ',
                  children: [
                    { name: '85-EC-034 - 40 KVA UPS 1 Emerson' }
                  ]
                },
                {
                  name: 'ENGINEERING OFFICE',
                  children: [
                    { name: '85-EC-028 - 80 KVA UPS 2 Emerson' }
                  ]
                },
                {
                  name: 'MED',
                  children: [
                    { name: '85-EC-030 - 20 KVA UPS DB Power' }
                  ]
                },
                {
                  name: 'NDO 2',
                  children: [
                    { name: '85-EC-029 - 250 KVA UPS 1 DB Power' }
                  ]
                },
                {
                  name: 'OLD VET Office',
                  children: [
                    { name: '85-EC-033 - 5 KVA UPS Numeric' }
                  ]
                },
                {
                  name: 'R BUILDING',
                  children: [
                    { name: '85-EC-031 - 20 KVA UPS DB Power' }
                  ]
                },
                {
                  name: 'STORES',
                  children: [
                    { name: '85-EP-032 - 6 KVA UPS Numeric' }
                  ]
                }
              ]
            },
            {
              name: 'Battery Car',
              children: [
                {
                  name: 'Near SUBSTATION-1',
                  children: [
                    { name: '85-CB-001 - Battery Car new & Charging Unit' },
                    { name: '85-CB-002 - Battery Car new & Charging Unit' }
                  ]
                }
              ]
            },
            {
              name: 'Battery Charger',
              children: [
                {
                  name: '33KV ROOM',
                  children: [
                    { name: '85-CB-009 - Battery Charger' }]
                },
                {
                  name: 'SS1',
                  children: [
                    { name: '85-CB-003 - Battery Charger' }]
                },
                {
                  name: 'SS2',
                  children: [
                    { name: '85-CB-004 - Battery Charger' },
                    { name: '85-CB-005 - Movable Battery Charger' }
                  ]
                },
                {
                  name: 'SS3',
                  children: [
                    { name: '85-CB-006 - Battery Charger' }]
                },
                {
                  name: 'SS4',
                  children: [
                    { name: '85-CB-007 - Battery Charger - 1' },
                    { name: '85-CB-008 - Battery Charger - 2' }]
                }
              ]
            }
          ]
        },
        {
          name: '88   EARTHING',
          children: [
            {
              name: 'Earth Pits',
              children: [
                {
                  name: 'BUS PARKING / STP / CG', children: [
                    { name: '88-EW-021 - Bus Parking / STP / CG' }
                  ]
                },
                {
                  name: 'CANTEEN 1', children: [
                    { name: '88-EW-010 - Canteen-1' }
                  ]
                },
                {
                  name: 'EDC', children: [
                    { name: '88-EW-022 - EDC Area' }
                  ]
                },
                {
                  name: 'EO', children: [
                    { name: '88-EW-013 - EO' }
                  ]
                },
                {
                  name: 'MED', children: [
                    { name: '88-EW-006 - MED Area' }
                  ]
                },
                {
                  name: 'MES & MRS', children: [
                    { name: '88-EW-016 - MES & MRS' }
                  ]
                },
                {
                  name: 'NDO-1', children: [
                    { name: '88-EW-008 - NDO-1' }
                  ]
                },
                {
                  name: 'NDO-2', children: [
                    { name: '88-EW-009 - NDO-2' }
                  ]
                },
                {
                  name: 'New CTL ', children: [
                    { name: '88-EW-017 - New CTL ' }
                  ]
                },
                {
                  name: 'New CTL PUMP ROOM', children: [
                    { name: '88-EW-019 - New CTL Pump room' }
                  ]
                },
                {
                  name: 'Old CTL', children: [
                    { name: '88-EW-014 - CTL' }
                  ]
                },
                {
                  name: 'PROTOSHOP 1 & 2', children: [
                    { name: '88-EW-012 - Protoshop - 1 & 2' }
                  ]
                },
                {
                  name: 'R BUILDING', children: [
                    { name: '88-EW-011 - R.Building' }
                  ]
                },
                {
                  name: 'SERINITY ROOM', children: [
                    { name: '88-EW-020 - Serinity Room' }
                  ]
                },
                {
                  name: 'SIX POSTER', children: [
                    { name: '88-EW-005 - Six Poster Area' }
                  ]
                },
                {
                  name: 'STORE', children: [
                    { name: '88-EW-007 - Stores' }
                  ]
                },
                {
                  name: 'SUBSTATION- 1', children: [
                    { name: '88-EW-001 - Sub Station 1 Area' }
                  ]
                },
                {
                  name: 'SUBSTATION- 2', children: [
                    { name: '88-EW-002 - Sub Station 2 Area' }
                  ]
                },
                {
                  name: 'SUBSTATION- 3', children: [
                    { name: '88-EW-002 - Sub Station 2 Area' }
                  ]
                },
                {
                  name: 'SUBSTATION- 4', children: [
                    { name: '88-EW-004 - Sub Station 4 Area' }
                  ]
                },
                {
                  name: 'SUMP 3', children: [
                    { name: '88-EW-018 - Sump 3' }
                  ]
                },
                {
                  name: 'VET',
                  children: [
                    { name: '88-EW-015 - VET' }
                  ]
                }
              ]
            }
          ]
        },
        {
          name: '93  Buildings, Access & Security',
          children: [
            {
              name: 'Boom Barrier',
              children: [
                {
                  name: 'GATE 2',
                  children: [
                    { name: '93-BB-002 - Gate 2 Boom Barrier' }
                  ]
                },
                {
                  name: 'OPEN STORE',
                  children: [
                    { name: '93-BB-004 - EDC Way Boom Barrier' }
                  ]
                },
                {
                  name: 'STORES',
                  children: [
                    { name: '93-BB-003 - NDO 2 Way Boom Barrier' }
                  ]
                },
                {
                  name: 'VET',
                  children: [
                    { name: '93-BB-001A - High Speed Track Entry - LH Boom' },
                    { name: '93-BB-001B - High Speed Track Entry - RH Boom' }
                  ]
                },
              ]
            },
            {
              name: 'Exhaust Blower',
              children: [
                {
                  name: 'EDC',
                  children: [
                    { name: '93-KF-001A-DE - Room Exhaust Motor TB1' },
                    { name: '93-KF-001B-DE - Room Exhaust Motor TB2' },
                    { name: '93-KF-001C-DE - Room Exhaust Motor TB3' },
                    { name: '93-KF-001D-DE - Room Exhaust Motor TB4' },
                    { name: '93-KF-001E-DE - Room Exhaust Motor TB5' },
                    { name: '93-KF-001F-DE - Room Exhaust Motor TB6' },
                    { name: '93-KF-001G-DE - Room Exhaust Motor TB7' },
                    { name: '93-KF-001H-DE - Room Exhaust Motor TB8' },
                    { name: '93-KF-001I-DE - Room Exhaust Motor TB9' },
                    { name: '93-KF-001J-DE - Room Exhaust Motor TB10' },
                    { name: '93-KF-001K-DE - Room Exhaust Motor TB11' },
                    { name: '93-KF-001L-DE - Room Exhaust Motor TB12' },
                    { name: '93-KF-001M-DE - Room Exhaust Motor TB13' },
                    { name: '93-KF-001N-DE - Room Exhaust Motor TB14' },
                    { name: '93-KF-001O-DE - Room Exhaust Motor TB15' },
                    { name: '93-KF-001P-DE - Room Exhaust Motor TB16' },
                    { name: '93-KF-001Q-DE - Room Exhaust Motor TB17' },
                    { name: '93-KF-001R-DE - Room Exhaust Motor TB18' },
                    { name: '93-KF-001S-DE - Room Exhaust Motor TB19' },
                    { name: '93-KF-001T-DE - Room Exhaust Motor TB20' },
                    { name: '93-KF-001U-DE - Room Exhaust Motor TB21' },
                    { name: '93-KF-001V-DE - Room Exhaust Motor TB22' },
                    { name: '93-KF-001W-DE - Room Exhaust Motor TB23' }
                  ]
                }
              ]
            }
          ]
        },
        {
          name: '97   HVAC',
          children: [
            {
              name: 'CHILLER',
              children: [
                {
                  name: 'EDC',
                  children: [
                    { name: '97-PA-001 - Primary Pump 1' },
                    { name: '97-PA-002 - Primary Pump 2' },
                    { name: '97-PA-003 - Primary Pump 3' },
                    { name: '97-PA-004 - Primary Pump 4' },
                    { name: '97-PA-005 - Primary Pump 5' },
                    { name: '97-PA-006 - Primary Pump 6' }
                  ]
                }
              ]
            },
            {
              name: 'HVAC Pumps & Blowers',
              children: [
                {
                  name: 'EDC',
                  children: [
                    { name: '97-BF-001 - AHU Fresh Air Blower TB1' },
                    { name: '97-BF-002 - AHU Fresh Air Blower TB2' },
                    { name: '97-BF-003 - AHU Fresh Air Blower TB3' },
                    { name: '97-BF-004 - AHU Fresh Air Blower TB4' },
                    { name: '97-BF-005 - AHU Fresh Air Blower TB5' },
                    { name: '97-BF-006 - AHU Fresh Air Blower TB6' },
                    { name: '97-BF-007 - AHU Fresh Air Blower TB7' },
                    { name: '97-BF-008 - AHU Fresh Air Blower TB8' },
                    { name: '97-BF-009 - AHU Fresh Air Blower TB9' },
                    { name: '97-BF-010 - AHU Fresh Air Blower TB10' },
                    { name: '97-BF-011 - AHU Fresh Air Blower TB11' },
                    { name: '97-BF-012 - AHU Fresh Air Blower TB12' },
                    { name: '97-BF-013 - AHU Fresh Air Blower TB13' },
                    { name: '97-BF-014 - AHU Fresh Air Blower TB14' },
                    { name: '97-BF-015 - AHU Fresh Air Blower TB15' },
                    { name: '97-BF-016 - AHU Fresh Air Blower TB16' },
                    { name: '97-BF-017 - AHU Fresh Air Blower TB17' },
                    { name: '97-BF-018 - AHU Fresh Air Blower TB18' },
                    { name: '97-BF-019 - AHU Fresh Air Blower TB19' },
                    { name: '97-BF-020 - AHU Fresh Air Blower TB20' },
                    { name: '97-BF-021 - AHU Fresh Air Blower TB21' },
                    { name: '97-BF-022 - AHU Fresh Air Blower TB22' },
                    { name: '97-BF-023 - AHU Fresh Air Blower TB23' }
                  ]
                }
              ]
            },
            {
              name: 'Normal AC',
              children: [
                {
                  name: 'CRUECH',
                  children: [
                    { name: '97-GI-020 - Samsung 1.5TR' },
                    { name: '97-GI-021 - Samsung 1.5TR' },
                  ]
                },
                {
                  name: 'EDC',
                  children: [
                    { name: '97-GI-015 - Hitachi Outdoor Roof Top Unit - 30 TR (TB1 to TB12)' },
                    { name: '97-GI-016 - Hitachi Outdoor Roof Top Unit - 30 TR (TB13 to TB24)' },
                    { name: '97-GI-017 - Hitachi Outdoor Roof Top Unit - 10AT3S (Engine Assly) - 1' },
                    { name: '97-GI-018 - Hitachi Outdoor Roof Top Unit - 10AT3S (Engine Assly) - 2' },
                    { name: '97-GI-019 - Hitachi Outdoor Roof Top Unit - 25 TR (First Floor Office)' },
                  ]
                },
                {
                  name: 'NDO 1 - Phase 2',
                  children: [
                    { name: '97-GI-004 - Voltas 17 TR Unit No 21 Lobby' },
                    { name: '97-GI-005 - Voltas 17 TR Unit No 22 Lobby' },
                    { name: '97-GI-006 - Voltas 17 TR Unit No 23 Lobby' },
                    { name: '97-GI-007 - Voltas 17 TR Unit No 24 Lobby' },
                  ]
                },
                {
                  name: 'OLD CTL',
                  children: [
                    { name: '97-GI-001 - Toshiba 2 TR GF Instrumentation' },
                    { name: '97-GI-002 - Carrier 2 TR GF Instrumentation' },
                    { name: '97-GI-003 - Carrier 2 TR GF Instrumentation' },
                  ]
                },
                {
                  name: 'R BUILDING',
                  children: [
                    { name: '97-GI-008 - ETA 2 TR D1' },
                    { name: '97-GI-009 - ETA 2 TR D2' },
                    { name: '97-GI-010 - ETA 2 TR D3' },
                    { name: '97-GI-011 - ETA 2 TR D4' },
                    { name: '97-GI-012 - ETA 3 TR D5  FF' },
                    { name: '97-GI-013 - ETA 2 TR GM Room GF' },
                    { name: '97-GI-014 - ETA 2 TR Quality GF' },
                    { name: '97-GI-015 - ETA 11 TR/GF' },
                    { name: '97-GI-016 - ETA 11 TR/GF' },
                    { name: '97-GI-017 - ETA 5.5 TR/GF' },
                    { name: '97-GI-018 - ETA 8.5 TR FF' }
                  ]
                },
              ]
            },
            {
              name: 'Solar AC',
              children:
                [
                  {
                    name: 'Dr ROOM MEDICAL',
                    children: [
                      { name: '97-GI-S03 - GREEN STAR AMC' },
                      { name: '97-GI-S04 - GREEN STAR AMC' }
                    ]
                  },
                  {
                    name: 'NDO1 HP ROOM',
                    children: [
                      { name: '97-GI-S05 - GREEN STAR AMC' },
                      { name: '97-GI-S06 - GREEN STAR AMC' }
                    ]
                  },
                  {
                    name: 'OLD CTL',
                    children: [
                      { name: '97-GI-S01 - GREEN STAR AMC' },
                      { name: '97-GI-S02 - GREEN STAR AMC' }
                    ]
                  }
                ]
            }
          ]
        }
      ]
    },
    {
      name: 'STATUTORY'
    }
  ]
}
