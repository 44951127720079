import { Button, Row } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useContext, useState } from 'react'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { deIsolationCloseEndPoint } from '../../components/serverurl'
import React from 'react'
import { useAdminPermissions } from '../eptw/EditRisks'
import ViewPermit from '../eptw/ViewPermit'
import {
  doneRequiredOptions,
  RadioButtonGroup,
  yesNoOptions
} from '../eptw/VerifyPermit'
import { PermissionsContext } from '../../App'
import IsolationsCheckboxTable from '../../components/Tables/IsolationsCheckBoxTable'

export default function VerifyDeIsolationAndClose() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const { permissions } = useContext(PermissionsContext)

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const [submitModal, setSubmitModal]: any = useState(false)

  const [formData, setFormData]: any = useState({
    taskCompleted: 'No',
    lockKeyDelivered: 'No',
    communicationEstablished: 'Not Required'
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const submitValues: any = {
      consent: {
        ...formData
      },

      permitId: data?.permit?._id,
      consentId: data?.permit?.consent?._id
    }

    const response = await makeAuthenticatedRequest(
      `${deIsolationCloseEndPoint}/verify`,
      'PATCH',
      submitValues,
      accounts,
      instance
    )

    return response.status
  }

  const form = (
    <div>
      <RadioButtonGroup
        question="Is the task completed Successfully ?"
        name="taskCompleted"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Lock Key delivered to IA and discussed about the Deislation task?"
        name="lockKeyDelivered"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Communication establihed with IM and all the team members about Deisolation "
        name="communicationEstablished"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
    </div>
  )

  return (
    <ProtectedLayout onBack="/eptw/deIsolationAndClose/verify">
      <ViewPermit data={data} isLoading={isLoading} />
      {data?.permit?.status === 'deIsolationAndCloseInitiated' &&
        permissions?.canVerifyDeIsolationAndClose && (
          <>
            {/* <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
              <Button
                color="warning"
                style={{ marginTop: 10 }}
                onClick={() => setSubmitModal(true)}
              >
                Verify
              </Button>
            </Row> */}
            <IsolationsCheckboxTable
              printData={data}
              checkBoxHeader="Verify"
              buttonText="Verify Permit"
              data={data?.permit?.isolations?.isolations}
              onSubmit={(val: any) => {
                setSubmitModal(true)
              }}
            />
            {submitModal && (
              <SubmitModalWithForm
                isOpen={submitModal}
                onClose={(val: any) => {
                  setSubmitModal(false)
                  val && navigate('/eptw/deIsolationAndClose/verify')
                }}
                onSubmit={handleSubmit}
                form={form}
              />
            )}
          </>
        )}
    </ProtectedLayout>
  )
}
