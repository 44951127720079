import { useState } from 'react'
import { Button, Row, Table } from 'reactstrap'

import ProcessFlowDiagram from '../../SVGs/ProcessFlowDiagram'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'

import './../../../src/index.css'

function getRandomArbitrary(min: any, max: any) {
  return Math.random() * (max - min) + min
}

const systems = [
  'System 0',
  'System 20',
  'System 21',
  'System 10',
  'System 24',
  'System 25',
  'System 27',
  'System 29',
  'System 40',
  'System 41',
  'System 42',
  'System 43',
  'System 44',
  'System 45',
  'System 50',
  'System 51',
  'System 52',
  'System 53',
  'System 55',
  'System 56'
]

export default function DynamicKeyDrawings({ onBack, rcmData }: any) {
  const rows = []
  for (let i = 0; i < systems.length; i += 4) {
    rows.push(systems.slice(i, i + 4))
  }

  const [system, setSystem]: any = useState('')

  const zeroSystems = ['System 0', 'System 53']

  return (
    <ProtectedLayout onBack={onBack}>
      <Row style={{ margin: 15 }}>
        <div
          style={{ display: 'flex', gap: 2, width: '100%', marginBottom: 10 }}
        >
          <div id="customTable" style={{ width: '50%' }}>
            <div className="custom-table-responsive">
              <Table
                id="customTable"
                bordered
                style={{ height: '20vh', width: '100%', borderColor: '#000' }}
                responsive
              >
                <thead
                  style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    background: 'white'
                  }}
                >
                  <tr style={{ backgroundColor: '#fafad2' }}>
                    {Object.keys(rcmData[0])?.map((k: any) => (
                      <th key={k}>{k}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {rcmData?.map((r: any, rIndex: any) => (
                    <tr
                      key={`${rIndex}-${r?.['Number']}`}
                      style={{
                        backgroundColor: 'red',
                        color: 'white',
                        fontWeight: 700
                      }}
                    >
                      {Object.keys(rcmData[0])?.map((e: any, eIndex: any) => (
                        <td key={`${rIndex}-${eIndex}-${e}`}>{r[e]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          <div id="customTable" style={{ width: '50%' }}>
            <div className="custom-table-responsive">
              {!system ? (
                <Table bordered style={{ borderColor: '#000' }}>
                  <tbody>
                    {rows.map((row, rowIndex) => (
                      <tr key={rowIndex} style={{ borderColor: '#fff' }}>
                        {row.map((s, colIndex) => (
                          <td
                            style={{
                              borderColor: '#fff',
                              height: 10,
                              padding: 0
                            }}
                            onClick={() => setSystem(s)}
                            key={colIndex}
                          >
                            <Button
                              style={{
                                backgroundColor: '#fafad2',
                                color: '#000',
                                width: '-webkit-fill-available',
                                marginRight: 5,
                                marginLeft: 5,
                                marginBottom: 5,
                                border: 0,
                                boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px'
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  margin: 5
                                }}
                              >
                                <p style={{ margin: 0 }}></p>
                                <p
                                  style={{
                                    margin: 0,
                                    fontWeight: 700,
                                    fontSize: 18
                                  }}
                                >
                                  {s}
                                </p>
                                <p
                                  style={{
                                    margin: 0,
                                    height: 'auto',
                                    minWidth: 30,
                                    padding: 5,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    backgroundColor: zeroSystems?.includes(s)
                                      ? 'green'
                                      : 'red',
                                    color: '#fff',
                                    marginRight: 5,
                                    borderRadius: 5,
                                    fontWeight: 700
                                  }}
                                >
                                  {s === 'System 43'
                                    ? rcmData?.length
                                    : zeroSystems?.includes(s)
                                    ? 0
                                    : Math.floor(
                                        getRandomArbitrary(rowIndex, colIndex)
                                      ) + 1}
                                </p>
                              </div>
                            </Button>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <Table bordered style={{ borderColor: '#000' }}>
                  <tbody>
                    <tr>
                      {[
                        'HP Flare Tip',
                        'LP Flare Tip',
                        'HP Flare KO Drum',
                        'Flare Ignition panel',
                        'LP Ignition panel'
                      ]?.map((p: any) => (
                        <td
                          key={p}
                          style={{
                            height: 10,
                            textAlign: 'center',
                            fontWeight: 700,
                            backgroundColor: '#fafad2',
                            boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px'
                          }}
                        >
                          {p}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </div>
        <ProcessFlowDiagram setPid={() => {}} />
      </Row>
    </ProtectedLayout>
  )
}
