const formLabelStyles = {
  fontWeight: 600,
  fontSize: 16,
  color: '#000',
  display: 'flex',
  margin: 0,
  marginBottom: 10,
  marginTop: 10
}

export default function CustomLabel({ label }: any) {
  return <p style={formLabelStyles}>{label}</p>
}
