import { useMsal } from '@azure/msal-react'
import { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useQuery } from 'react-query'
import {
  Button,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane
} from 'reactstrap'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { incrementby1 } from '../../components/Forms/JSAForm'
import CustomLabel from '../../components/InputFields/CustomLabel'
import LabeledDateTimeInput from '../../components/InputFields/DateTime'
import LabeledTextInput from '../../components/InputFields/LabeledTextInput'
import LabledParagraphInput from '../../components/InputFields/LabledParagraph'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { usersEndpoint } from '../../components/serverurl'
import Spacer from '../../components/Spacer'
import { SubHeader } from '../VerifyCM'
import { renderArray } from '../WaitingForPIC'
import { Link } from '../workManagement/PmTree'
import { getReadableTime } from '../workManagement/WorkOrderDetails'

const TABS = {
  ADD_VENDOR: 'ADD-VENDOR',
  VENDOR_LIST: 'VENDOR-LIST'
}

const AbanUsersTable = ({
  data,
  isLoading,
  isRefetching,
  page,
  refetch // setPage,
} // url
: {
  data: any
  isLoading: boolean
  isRefetching: boolean
  refetch: any
  page: any
  // setPage: any
  // url: string
}) => {
  const { accounts, instance } = useMsal()
  const [searchText, setSearchText]: any = useState([])
  const [filtered, setFiltered]: any = useState(data)
  const [update, setUpdate]: any = useState(false)
  const [selectedUser, setSelectedUser]: any = useState({})

  // useEffect(() => {
  //   setFiltered(data)
  // }, [data, refetch])

  // useEffect(() => {
  //   if (searchText && data?.length > 0) {
  //     setFiltered([
  //       ...data?.filter((s: any) => s?.userId?.includes(searchText))
  //     ])
  //   }
  // }, [searchText])

  return (
    <>
      <Row>
        {/* <Col xs="3">
          <CustomLabel label="Search User Id" />
          <Input
            type="text"
            name="searchText"
            onChange={(e: any) => setSearchText(e?.target?.value)}
          />
        </Col>
        <Col xs="3">
          <CustomLabel label="Clear" />
          <Button color="warning" onClick={() => setFiltered(data)}>
            Clear
          </Button>
        </Col> */}
      </Row>
      <Table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Id</th>
            <th>Category ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Type</th>
            <th>Import Tax</th>
            <th>GST No.</th>
            <th>Registration Date</th>
            <th>Approval Status</th>
            <th>User Name</th>
            <th>Contact Name</th>
            <th>Phone Number</th>
          </tr>
        </thead>
        <tbody>
          <>
            {isLoading || isRefetching ? (
              <tr>
                <td colSpan={12}>
                  <Row
                    style={{
                      height: 500,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Spinner />
                  </Row>
                </td>
              </tr>
            ) : (
              data?.length > 0 &&
              data?.map((w: any, index: number) => (
                <tr key={`${w?._id}-${index}`}>
                  <td>{incrementby1(page * 20 + index)}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    <Link
                      onClick={() => {
                        setUpdate(true)
                        setSelectedUser(w)
                      }}
                    >
                      {w?.vendorId}
                    </Link>
                  </td>
                  <td>{w?.catergoryId}</td>
                  <td>{w?.userName}</td>
                  <td>{w?.email}</td>
                  <td>{w?.type}</td>
                  <td>{w?.importTax}</td>
                  <td>{w?.gst}</td>
                  <td>{getReadableTime(w?.registeredDate)}</td>
                  <td>{w?.approvalStatus}</td>
                  <td>{w?.userName}</td>
                  <td>{w?.contactPerson}</td>
                  <td>{w?.phone}</td>
                </tr>
              ))
            )}
          </>
        </tbody>
      </Table>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Users Found,
            <span
              style={{
                color: 'blue',
                textDecoration: 'underline',
                marginLeft: 2,
                cursor: 'pointer'
              }}
              onClick={() =>
                // navigate('/workManagement/correctiveMaintenance/create')
                {}
              }
            >
              Add one?
            </span>
          </p>
        </Row>
      )}
      {/* <SubmitModalWithForm
        isOpen={update}
        onClose={(val: any) => {
          setUpdate(false)
        }}
        form={
          <>
            <Box heading={`Crew Member Details`}>
              {renderData('Name', selectedUser?.userName)}
              {renderData('Id', selectedUser?.userId)}
              {renderData('Email', selectedUser?.emailId)}
              {renderData('Designation', selectedUser?.desgination)}
              {renderData('Group', selectedUser?.group)}
            </Box>
            <Row>
              <Col xs="10">
                <CustomLabel label="Group" />
                <Input
                  type="select"
                  onChange={(e: any) =>
                    setSelectedUser({
                      ...selectedUser,
                      group: e?.target?.value
                    })
                  }
                >
                  <option value="" disabled>
                    Select
                  </option>

                  {groups?.map((d: any) => (
                    <option selected={d === selectedUser?.group} value={d}>
                      {d}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col xs="10">
                <CustomLabel label="Designation" />
                <Input
                  type="select"
                  onChange={(e: any) =>
                    setSelectedUser({
                      ...selectedUser,
                      designation: e?.target?.value
                    })
                  }
                >
                  <option value="" disabled>
                    Select
                  </option>

                  {designations?.map((d: any) => (
                    <option
                      selected={d === selectedUser?.designation}
                      value={d}
                    >
                      {d}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
            <CustomLabel label="Select Units" />
            <Row>
              <Col xs="1">
                <Input
                  type="checkbox"
                  id="unit"
                  value={units.UNIT1}
                  checked={selectedUser?.unit?.includes(units.UNIT1)}
                  onChange={(e: any) =>
                    setSelectedUser({
                      ...selectedUser,
                      unit: e?.target?.checked
                        ? [...selectedUser?.unit, e?.target?.value]
                        : selectedUser?.unit?.filter(
                            (i: any) => i !== e?.target?.value
                          )
                    })
                  }
                />
              </Col>
              <Col>
                <p>{units.UNIT1}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="1">
                <Input
                  type="checkbox"
                  id="unit"
                  value={units.UNIT2}
                  checked={selectedUser?.unit?.includes(units.UNIT2)}
                  onChange={(e: any) =>
                    setSelectedUser({
                      ...selectedUser,
                      unit: e?.target?.checked
                        ? [...selectedUser?.unit, e?.target?.value]
                        : selectedUser?.unit?.filter(
                            (i: any) => i !== e?.target?.value
                          )
                    })
                  }
                />
              </Col>
              <Col>
                <p>{units.UNIT2}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="1">
                <Input
                  type="checkbox"
                  id="unit"
                  value={units.UNIT3}
                  checked={selectedUser?.unit?.includes(units.UNIT3)}
                  onChange={(e: any) =>
                    setSelectedUser({
                      ...selectedUser,
                      unit: e?.target?.checked
                        ? [...selectedUser?.unit, e?.target?.value]
                        : selectedUser?.unit?.filter(
                            (i: any) => i !== e?.target?.value
                          )
                    })
                  }
                />
              </Col>
              <Col>
                <p>{units.UNIT3}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="1">
                <Input
                  type="checkbox"
                  id="unit"
                  value={units.UNIT4}
                  checked={selectedUser?.unit?.includes(units.UNIT4)}
                  onChange={(e: any) =>
                    setSelectedUser({
                      ...selectedUser,
                      unit: e?.target?.checked
                        ? [...selectedUser?.unit, e?.target?.value]
                        : selectedUser?.unit?.filter(
                            (i: any) => i !== e?.target?.value
                          )
                    })
                  }
                />
              </Col>
              <Col>
                <p>{units.UNIT4}</p>
              </Col>
            </Row>
            <Spacer height={20} />
            <Row>
              <Col xs="1">
                <Input type="checkbox" id="pass" value={'reset'} />
              </Col>
              <Col>
                <p>Reset Password</p>
              </Col>
            </Row>
          </>
        }
        onSubmit={async (e: any) => {
          e?.preventDefault()
          const res = await makeAuthenticatedRequest(
            `${usersEndpoint}/aban`,
            'PATCH',
            selectedUser,
            accounts,
            instance
          )

          if (res.status === 'success') {
            await refetch()
          }

          return res.status
        }}
        size="xs"
        header="Create Crew"
      /> */}
    </>
  )
}

export default function Vendors() {
  const { accounts, instance } = useMsal()

  const [active, setActive]: any = useState(TABS.VENDOR_LIST)
  const [createModal, setCreateModal]: any = useState()

  const fetchVendors = async () => {
    const response = await makeAuthenticatedRequest(
      `${usersEndpoint}/vendor`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, isRefetching, refetch }: any = useQuery(
    'vendorsData',
    fetchVendors
  )

  const vendorsData = data?.vendors
  return (
    <ProtectedLayout onBack="/cmms/admin" title={'Vendor'}>
      <Row style={{ margin: 15 }}>
        <Nav tabs style={{ backgroundColor: '#fff', paddingTop: 0 }}>
          <NavItem>
            <NavLink
              className={active === TABS.ADD_VENDOR ? 'active' : ''}
              onClick={() => setActive(TABS.ADD_VENDOR)}
            >
              {TABS?.ADD_VENDOR}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === TABS.VENDOR_LIST ? 'active' : ''}
              onClick={() => setActive(TABS.VENDOR_LIST)}
            >
              {TABS?.VENDOR_LIST}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={active}>
          <TabPane tabId={TABS.ADD_VENDOR}>
            <SubHeader header="ADD VENDOR" permitColor="#FFD580" />
            <Form
              onSubmit={(values: any) => {}}
              // validate={validate}
              initialValues={{}}
              render={({ form, values, hasValidationErrors }) => (
                <form
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <LabeledTextInput
                    label="Vendor Name"
                    name="name"
                    occupy={6}
                  />
                  <LabeledTextInput
                    label="Category Id"
                    name="catergoryId"
                    occupy={6}
                  />
                  <Col xs="6">
                    <CustomLabel label="Type *" />
                    <Field
                      name={'type'}
                      component="select"
                      defaultValue=""
                      style={{
                        width: '-webkit-fill-available',
                        height: 68,
                        borderRadius: 5,
                        backgroundColor: '#DCDCDC'
                      }}
                    >
                      <option value="" disabled>
                        Select
                      </option>

                      <option value={'Local'}>Local</option>
                      <option value={'Foreign'}>Foreign</option>
                    </Field>
                  </Col>
                  <LabeledTextInput label="GST No." name="gst" occupy={6} />
                  <LabeledTextInput
                    label="Approval Status"
                    name="approvalStatus"
                    occupy={6}
                  />
                  <LabeledDateTimeInput
                    label="Registration Date"
                    name="registeredDate"
                    occupy={6}
                  />
                  <LabeledTextInput
                    label="Vendor Contact Person"
                    name="contactPerson"
                    occupy={6}
                  />
                  <LabeledTextInput label="Phone No." name="phone" occupy={6} />
                  <LabeledTextInput
                    label="User Name"
                    name="userName"
                    occupy={6}
                  />
                  <LabeledTextInput
                    label="Import Tax"
                    name="importTax"
                    occupy={6}
                  />
                  <LabledParagraphInput
                    label="Vendor Address"
                    name="name"
                    occupy={6}
                  />
                  <LabledParagraphInput
                    label="Remarks"
                    name="remarks"
                    occupy={6}
                  />
                  <Spacer height={20} />
                  <Button color="warning" onClick={() => setCreateModal(true)}>
                    Add Vendor Member
                  </Button>
                  <SubmitModalWithForm
                    isOpen={createModal}
                    onClose={(val: any) => {
                      setCreateModal(false)
                    }}
                    onSubmit={async (e: any) => {
                      e?.preventDefault()
                      const res = await makeAuthenticatedRequest(
                        `${usersEndpoint}/vendor`,
                        'POST',
                        values,
                        accounts,
                        instance
                      )
                      if (res.status === 'success') {
                        form.reset()
                      }

                      return res.status
                    }}
                    size="xs"
                    header="Create Vendor"
                  />
                </form>
              )}
            />
          </TabPane>
        </TabContent>
        <TabContent activeTab={active}>
          <TabPane tabId={TABS.VENDOR_LIST}>
            <SubHeader header="VENDORS LIST" permitColor="#FFD580" />
            <AbanUsersTable
              isLoading={isLoading}
              isRefetching={isRefetching}
              data={vendorsData}
              page={0}
              refetch={refetch}
              // setPage={() => {}}
              // url=""
            />
          </TabPane>
        </TabContent>
      </Row>
    </ProtectedLayout>
  )
}
