import { useMsal } from '@azure/msal-react'
import { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import { Row } from 'reactstrap'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { UserContext } from '../../App'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { capitalizeFirstLetter } from '../../components/lib/capitalizeFirstLetter'
import { epermit } from '../../components/serverurl'
import ViewPermits from '../../components/Tables/ViewPermits'

export default function PermitsByStatus({
  currentPermits,
  hasIsolations,
  checkLocation,
  flow = 'deIsolationAndClose',
  checkDepartment,
  isolationStatus,
  userLocation,
  noIsolations,
  onBack,
  showPermitNumber
}: {
  userLocation?: String
  currentPermits: String
  hasIsolations?: Boolean
  checkLocation?: Boolean
  flow?: String
  checkDepartment?: Boolean
  isolationStatus?: String
  noIsolations?: Boolean
  onBack?: String
  showPermitNumber?: any
}) {
  const { accounts, instance } = useMsal()
  const [pageNumber, setPageNumber] = useState(0)
  const { userData } = useContext(UserContext)

  const location = useLocation()

  const currentStage = location?.pathname?.split('/')?.at(-1)

  const currentRoute = location?.pathname

  const baseUrl = currentRoute.substring(0, currentRoute.lastIndexOf('/'))

  const locationCheckURI = `${epermit}?page=${pageNumber}&status=${currentPermits}&location=${userLocation}`
  const normalURI = `${epermit}?page=${pageNumber}&status=${currentPermits}&noIsolations=${noIsolations}`
  const checkDepartmentURI = `${epermit}?page=${pageNumber}&status=${currentPermits}&department=${userData?.department}`
  const isolationURI = ` ${epermit}?page=${pageNumber}&status=${currentPermits}&hasIsolation=${hasIsolations}&location=${userLocation}&stage=${isolationStatus}&department=${capitalizeFirstLetter(
    userData?.department
  )}`

  const fetchAdminPermissions = async () => {
    const response = await makeAuthenticatedRequest(
      (hasIsolations && isolationURI) ||
        (checkLocation && locationCheckURI) ||
        (checkDepartment && checkDepartmentURI) ||
        normalURI,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, refetch }: any = useQuery(
    `eptw${currentPermits}`,
    fetchAdminPermissions
  )

  return (
    <ProtectedLayout onBack={onBack ? onBack : baseUrl}>
      <Row style={{ margin: 10 }}>
        <ViewPermits
          data={data}
          isLoading={isLoading}
          refetch={refetch}
          setPageNumber={setPageNumber}
          navigateTo={`/eptw/${flow}/${currentStage}`}
          showPermitNumber={showPermitNumber}
        />
      </Row>
    </ProtectedLayout>
  )
}
