import { Form } from 'react-final-form'
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap'
import LabeledDateTimeInput from '../InputFields/DateTime'
import LabeledRadioButtons from '../InputFields/LabeledRadioButton'
import LabeledTextInput from '../InputFields/LabeledTextInput'
import ModalTitle from '../ModalTitle'
import Spacer from '../Spacer'

export default function ExcavationPermit({
  toggle,
  setAttachedForms,
  isOpen,
  attachedForms
}: any) {
  const validate = (values: any) => {
    const errors: any = {}
    if (!values.description) {
      errors.description = 'Required'
    }

    return errors
  }
  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalBody>
          <ModalTitle title="Excavation Certificate" />
          <Form
            validate={validate}
            initialValues={attachedForms}
            onSubmit={(values: any) => {
              setAttachedForms(values)
              toggle()
            }}
            render={({ form, hasValidationErrors }) => (
              <form>
                <Row>
                  <LabeledDateTimeInput
                    name="startDate"
                    label="Start Date"
                    occupy={12}
                  />
                  <LabeledDateTimeInput
                    name="endDate"
                    label="Estimated completion time"
                    occupy={12}
                  />
                  <LabeledTextInput
                    name="description"
                    label="Description of the job performed"
                    occupy={12}
                  />
                  <LabeledTextInput
                    name="extentExcavatoin"
                    label="Extent of Excavation"
                    occupy={12}
                  />
                  <LabeledTextInput
                    name="excavationDepth"
                    label="Excavation Depth"
                    occupy={12}
                  />
                  <LabeledTextInput
                    name="type"
                    label="Type of Escort"
                    occupy={12}
                  />
                  <LabeledRadioButtons
                    name="tools"
                    label="Tools / equipment to be used:"
                    defaultValue="Earth moving equipment"
                    list={[
                      'Hand tools',
                      'Powered tools',
                      'Earth moving equipment'
                    ]?.map((field: string) => ({ value: field, label: field }))}
                    occupy={12}
                  />
                  <LabeledRadioButtons
                    name="drawingEnclosed"
                    label="Drawing Enclosed"
                    list={['Yes', 'No']?.map((field: string) => ({
                      value: field,
                      label: field
                    }))}
                    occupy={12}
                  />
                </Row>
                <Spacer height={30} />
                <Row>
                  <Col xs="12">
                    <Button
                      style={{ width: 'inherit' }}
                      onClick={form.submit}
                      color={hasValidationErrors ? 'secondary' : 'warning'}
                      disabled={hasValidationErrors}
                    >
                      Save & Next
                    </Button>
                    <Spacer height={10} />
                    <Button
                      style={{ width: 'inherit' }}
                      color="warning"
                      onClick={toggle}
                    >
                      Cancel
                    </Button>
                    <Spacer height={10} />
                  </Col>
                </Row>
              </form>
            )}
          />
        </ModalBody>
      </Modal>
    </>
  )
}
