import React from 'react'
import { Button, ModalFooter, Row } from 'reactstrap'
import { useContext, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import ConfinedSpaceEntry from '../../components/Forms/ConfinedSpaceEntryPermit'
import EditPermitDetails from '../../components/Forms/EditPermitDetails'
import ExcavationPermit from '../../components/Forms/ExcavationPermit'
import IsolationForm from '../../components/Forms/IsolationForm'
import PositiveIsolation from '../../components/Forms/PositiveIsolationPermit'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import VehiclePermit from '../../components/Forms/VehiclePermit'
import ViewPermit from '../eptw/ViewPermit'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { epermit, newPermitEndpoint } from '../../components/serverurl'
import { PermissionsContext, UserContext } from '../../App'
import { UpdateModal, useAdminPermissions } from '../eptw/EditRisks'
import WorkAtHeight from '../../components/Forms/WorkAtHeight'

export default function RejectedPermit() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const { permissions } = useContext(PermissionsContext)
  const { userData } = useContext(UserContext)
  const navigate = useNavigate()

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const [riskModal, setRiskModal]: any = useState(false)
  const [riskValues, setRiskValues]: any = useState(
    data?.permit?.riskAssesmentPermit?.template
  )
  const [isolationsModal, setIsolationsModal]: any = useState(false)
  const [permitModal, setPermitModal]: any = useState(false)
  const [veModal, setVeModal]: any = useState(false)
  const [epModal, setEpModal]: any = useState(false)
  const [piModal, setPiModal]: any = useState(false)
  const [csModal, setCsModal]: any = useState(false)
  const [whModal, setWhModal]: any = useState(false)

  const [submitModal, setSubmitModal]: any = useState(false)
  const [deleteModal, setDeleteModal]: any = useState(false)
  const riskId = data?.permit?.riskAssesmentPermit?.template?._id
  const permitId = data?.permit?._id
  const vehiclePermitId = data?.permit?.vehiclePermit?._id
  const confinedSpaceEntryId = data?.permit?.confinedSpaceEntry?._id
  const excavationPermitId = data?.permit?.excavationPermit?._id
  const positiveIsolationId = data?.permit?.positiveIsolation?._id
  const WorkAtHeightId = data?.permit?.workAtHeight?._id
  const isolationsId = data?.permit?.isolations?._id

  const isolationsInitialData = data?.permit?.isolations?.isolations
  const isJSA = data?.permit?.isJSA === 'Non Critical'
  const [isolations, setIsolations]: any = useState(isolationsInitialData)
  const [permitValues, setPermitValues]: any = useState()
  const [vp, setVp]: any = useState(data?.permit?.vehiclePermit)
  const [ep, setEp]: any = useState(data?.permit?.excavationPermit)
  const [pi, setPi]: any = useState(data?.permit?.positiveIsolation)
  const [cs, setCs]: any = useState(data?.permit?.confinedSpaceEntry)
  const [wh, setWh]: any = useState(data?.permit?.workAtHeight)

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const values: any = {
      riskId,
      permitId,
      vehiclePermitId,
      confinedSpaceEntryId,
      WorkAtHeightId,
      excavationPermitId,
      positiveIsolationId,
      permitValues,
      vehiclePermitValues: vp,
      excavationValues: ep,
      confinedSpaceValues: cs,
      workAtHeightValues: wh,
      positiveIsolationValues: pi,
      riskAssessmentValues: riskValues,
      isolationsId,
      serialNumber: data?.permit?.woNumber?.split('/')[1],
      newIsolations: isolations,
      isJSA: data?.permit?.isJSA,
      isolationValues: isolations
    }

    const response = await makeAuthenticatedRequest(
      `${newPermitEndpoint}/update`,
      'PATCH',
      values,
      accounts,
      instance
    )

    return response.status
  }

  const deletePermit = async (e: any) => {
    e.preventDefault()

    const values: any = {
      riskId,
      permitId,
      vehiclePermitId,
      confinedSpaceEntryId,
      excavationPermitId,
      positiveIsolationId,
      permitValues,
      vehiclePermitValues: vp,
      excavationValues: ep,
      confinedSpaceValues: cs,
      positiveIsolationValues: pi,
      riskAssessmentValues: riskValues,
      isolationsId,
      serialNumber: data?.permit?.woNumber?.split('/')[1],
      newIsolations: isolations,
      isJSA: data?.permit?.isJSA,
      isolationValues: isolations,
      name: userData?.givenName
    }

    const response = await makeAuthenticatedRequest(
      `${epermit}`,
      'DELETE',
      values,
      accounts,
      instance
    )

    return response.status
  }

  const permit = data?.permit
  const riskAssessmentValues = permit?.riskAssesmentPermit
  const intialData = riskAssessmentValues?.template

  localStorage.setItem('riskData', JSON.stringify(intialData))
  const riskFromTemplate = data?.permit?.riskAssesmentPermit?.templateNumber

  return (
    <ProtectedLayout onBack="/eptw/rejected">
      <ViewPermit data={data} isLoading={isLoading} />
      {data?.permit?.status === 'rejected' && permissions?.canCreate && (
        <>
          <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {/* <p style={{ fontSize: 20 }}>Reason: {data?.permit?.comments}</p> */}
            </div>
            <div
              style={{
                justifyContent: 'space-around',
                display: 'flex',
                flexWrap: 'wrap',
                flexGrow: 1,
                flexBasis: 'auto'
              }}
            >
              <Button
                color="warning"
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => setPermitModal(true)}
              >
                Edit Permit details
              </Button>
              {!riskFromTemplate && (
                <Button
                  color="warning"
                  style={{ marginRight: 10, marginBottom: 10 }}
                  onClick={() => setRiskModal(true)}
                >
                  Edit Risk Assessment
                </Button>
              )}
              <Button
                color="warning"
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => setIsolationsModal(true)}
              >
                Edit Isolations
              </Button>
              <Button
                color="warning"
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => setVeModal(true)}
              >
                Edit Vehicle Permit
              </Button>
              <Button
                color="warning"
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => setEpModal(true)}
              >
                Edit Excavation Permit
              </Button>
              <Button
                color="warning"
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => setPiModal(true)}
              >
                Edit Positive Isolation
              </Button>
              <Button
                color="warning"
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => setCsModal(true)}
              >
                Edit Confined Space Entry
              </Button>
              <Button
                color="warning"
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => setWhModal(true)}
              >
                Edit Work At Height
              </Button>
            </div>
            <ModalFooter>
              <Button
                color="danger"
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => setDeleteModal(true)}
              >
                Delete
              </Button>
              <Button
                color="warning"
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => setSubmitModal(true)}
              >
                {permitValues?.riskAssessment === 'Yes' ||
                data?.permit?.riskAssessment === 'Yes'
                  ? 'Submit to HSE'
                  : 'Submit to PIC'}
              </Button>
            </ModalFooter>
          </>
          {riskModal && (
            <UpdateModal
              isOpen={riskModal}
              riskValues={
                riskValues || data?.permit?.riskAssesmentPermit?.template
              }
              setRiskValues={setRiskValues}
              isJSA={data?.permit?.isJSA}
              toggle={() => {
                setRiskModal(!riskModal)
              }}
            />
          )}
          {submitModal && (
            <SubmitModalWithForm
              isOpen={submitModal}
              onClose={(val: any) => {
                setSubmitModal(false)
                val && navigate('/eptw/rejected')
              }}
              onSubmit={handleSubmit}
            />
          )}
          {deleteModal && (
            <SubmitModalWithForm
              isOpen={deleteModal}
              onClose={(val: any) => {
                setDeleteModal(false)
                val && navigate('/eptw/rejected')
              }}
              onSubmit={deletePermit}
            />
          )}
          {isolationsModal && (
            <IsolationForm
              isOpen={isolationsModal}
              setValues={setIsolations}
              toggle={() => setIsolationsModal(!isolationsModal)}
              values={isolations || data?.permit?.isolations?.isolations}
            />
          )}
          {veModal && (
            <VehiclePermit
              toggle={() => setVeModal(!veModal)}
              isOpen={veModal}
              attachedForms={vp || data?.permit?.vehiclePermit}
              setAttachedForms={setVp}
            />
          )}
          {epModal && (
            <ExcavationPermit
              toggle={() => setEpModal(!epModal)}
              isOpen={epModal}
              attachedForms={ep || data?.permit?.excavationPermit}
              setAttachedForms={setEp}
            />
          )}
          {piModal && (
            <PositiveIsolation
              toggle={() => setPiModal(!piModal)}
              isOpen={piModal}
              attachedForms={pi || data?.permit?.positiveIsolation}
              setAttachedForms={setPi}
            />
          )}
          {csModal && (
            <ConfinedSpaceEntry
              toggle={() => setCsModal(!csModal)}
              isOpen={csModal}
              attachedForms={cs || data?.permit?.confinedSpaceEntry}
              setAttachedForms={setCs}
            />
          )}
          {whModal && (
            <WorkAtHeight
              toggle={() => setWhModal(!whModal)}
              isOpen={whModal}
              attachedForms={wh || data?.permit?.workAtHeight}
              setAttachedForms={setWh}
            />
          )}
          {permitModal && (
            <EditPermitDetails
              isOpen={permitModal}
              toggle={() => setPermitModal(!permitModal)}
              values={permitValues || data?.permit}
              setValues={setPermitValues}
            />
          )}
        </>
      )}
    </ProtectedLayout>
  )
}
