import { Row } from 'reactstrap'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { IconCard } from '../../components/Dashboard/IconCard'

export default function ManualDashboard() {
  const flows: any = [
    {
      name: 'CREATE',
      nav: '/cmms/manual/create',
      src: require('./../../images/Icons/CreateManual.png')
    },
    {
      name: 'CREATED',
      nav: '/cmms/manual/created',
      src: require('./../../images/Icons/CreatedManual.png')
    },
    {
      name: 'DRAFT',
      nav: '/cmms/manual/draft',
      src: require('./../../images/Icons/DraftManual.png')
    },
    {
      name: 'APPROVED',
      nav: '/cmms/manual/view',
      src: require('./../../images/Icons/ApprovedManual.png')
    },
    {
      name: 'REJECTED',
      nav: '/cmms/manual/rejected',
      src: require('./../../images/Icons/RejectedManual.png')
    },
    {
      name: 'HIERARCHY',
      nav: '/cmms/manual/hierarchy',
      src: require('./../../images/Icons/HierarchyManual.png')
    }
  ]

  return (
    <>
      <ProtectedLayout onBack="/cmms" title="MANUAL">
        <Row style={{ margin: 10, fontSize: 20 }}>
          {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
            <IconCard
              key={`${index}-${name}`}
              name={name}
              nav={nav}
              canView={canView}
              count={count}
              src={src}
            />
          ))}
        </Row>
      </ProtectedLayout>
    </>
  )
}
