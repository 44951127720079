import React from 'react'

import RcmLayout from '../../components/rcm/RcmLayout'
import FailureModes from './FailureModes'

export const failureModesHeaderKeys = [
  'Discipline',
  'Asset Type',
  'EQ Object Type',
  'Failure Mode'
]

export default function RcmFailureModes({
  setPin,
  pin
}: {
  pin: any
  setPin: any
}) {
  return (
    <RcmLayout
      onBack={'/cmms/rcm'}
      tabHeader={<h4 style={{ margin: 0 }}>Failure Modes</h4>}
    >
      <FailureModes setPin={setPin} pin={pin} />
    </RcmLayout>
  )
}
