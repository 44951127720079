import FolderTree from 'react-folder-tree'
import { BsFileEarmarkTextFill } from 'react-icons/bs'
import { PiFolderSimpleFill } from 'react-icons/pi'
import { FaFolder, FaFolderOpen } from 'react-icons/fa'

import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import 'react-folder-tree/dist/style.css'

import AshokLeylandLayout from './AshokLeylandLayout'
import CustomLabel from '../components/InputFields/CustomLabel'
import SubmitModalWithForm from '../components/Modals/SubmitModalWithForm'
import { makeAuthenticatedRequest } from '../apiRequests'
import { alEquipmentApi, pmAPI } from '../components/serverurl'
import { ashokleyandEquipmentHierarchy } from '../pages/workManagement/hierarchyData'
import {
  BasicLoader,
  Box,
  Link,
  renderData,
  ViewDocument,
  ViewSpares
} from '../pages/workManagement/PmTree'
import { SubHeader } from '../pages/VerifyCM'
import { jobcodes } from '../components/constants/jobcodes'

const CheckListModal = ({ isOpen, onClose }: { isOpen: any; onClose: any }) => {
  return (
    <Modal isOpen={isOpen} size="xl">
      <ModalBody>
        <SubHeader header="Check List" permitColor="gold" />
        <Table>
          <thead>
            <tr>
              <th>OP NO.</th>
              <th>Description</th>
              <th>Main Org</th>
              <th>Planned Hours</th>
              <th>Planned Resources</th>
              <th>Total Man Hours</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {jobcodes.CAOO5?.map((o: any) => (
              <tr key={o?.number}>
                <td>{o?.number}</td>
                <td>{o?.description}</td>
                <td>{o?.mainOrg}</td>
                <td style={{ textAlign: 'center' }}>{o?.plannedHours}</td>
                <td>{o?.plannedResources}</td>
                <td style={{ textAlign: 'center' }}>{o?.totalManHours}</td>
                <td>{o?.remarks}</td>
                {/* <td>
                                      {
                                        report?.closeOutReport?.jobPlanHours?.[
                                          o?.name
                                        ]?.actualHours
                                      }
                                    </td>
                                    <td>
                                      {
                                        report?.closeOutReport?.jobPlanHours?.[
                                          o?.name
                                        ]?.actualManHours
                                      }
                                    </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default function AlHierarchy() {
  const { accounts, instance } = useMsal()

  const [counterValue, setCounterValue]: any = useState()
  const [document, setDocument]: any = useState()
  const [select, setSelect]: any = useState()
  const [updateCounter, setUpdateCounter]: any = useState()
  const [viewSpares, setViewSpares]: any = useState(false)
  const [checklist, setChecklist]: any = useState(false)

  const fetchTag = async () => {
    const response = await makeAuthenticatedRequest(
      `${alEquipmentApi}?equipment=${select}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, refetch, isRefetching }: any = useQuery(
    'tagDetails',
    fetchTag
  )

  const equipment = data?.data?.equipment
  const spares = data?.spares

  // const technicalDetails: any = {
  //   Type: equipment?.tagType,
  //   Model: equipment?.modelNo,
  //   'Serial Number': equipment?.serialNo,
  //   Manufacturer: equipment?.manufacturer,
  //   Supplier: equipment?.supplier,
  //   'Operation Status': equipment?.operationStatus,
  //   'Ex Rating': equipment?.exrating,
  //   'Class Code': equipment?.classCode,
  //   'Regulatory Ref': equipment?.regulatoryRef,
  //   Reference: equipment?.reference,
  //   'Drawing NO': equipment?.pid,
  //   Note: equipment?.note
  // }

  const basicDetails: any = {
    'Tag Number': equipment?.tagNumber,
    Description: equipment?.description,
    System: equipment?.system,
    Location: equipment?.location,
    Manufacturer: equipment?.manufacturer,
    Type: equipment?.type,
    Capacity: equipment?.capacity,
    Frequency: equipment?.frequency,
    'PM Starting Month': equipment?.pmStartingMonth,
    'PM Trigger Date': equipment?.pmTriggerDate,
    'Last Carried Out': equipment?.lastCarriedOut,
    'Service Provider': equipment?.serviceProv,
    'PM No': equipment?.pmJobNo,
    'Rev No': equipment?.rev
  }

  useEffect(() => {
    refetch()
  }, [select])

  const onNameClick = ({ defaultOnClick, nodeData }: any) => {
    defaultOnClick()
    const partsOfEquipment = nodeData?.name?.split('-')
    const [a, b, c] = partsOfEquipment || []
    const equipment = `${a}-${b}-${c}`
    setSelect(equipment)
  }

  const FolderIcon = ({ onClick: defaultOnClick }: any) => {
    return <FaFolder onClick={defaultOnClick} color="orange" />
  }

  const FolderOpenIcon = ({ onClick: defaultOnClick }: any) => {
    return <FaFolderOpen onClick={defaultOnClick} color="orange" />
  }

  const FileIcon = ({ onClick: defaultOnClick }: any) => {
    return <BsFileEarmarkTextFill onClick={defaultOnClick} color="lightblue" />
  }

  const customIdentPixels = 50
  const iconComponents = {
    FolderIcon,
    FolderOpenIcon,
    FileIcon
  }

  const navigate = useNavigate()

  const sty = `.FolderTree {
  font-size: 20px}`

  return (
    <AshokLeylandLayout onBack="/ashokleyland/mms" title="EQUIPMENT HIERARCHY">
      <style>{sty}</style>
      <Row style={{ margin: 15, fontSize: 16 }}>
        <div style={{ display: 'flex' }}>
          <div className="hierarchy" style={{ width: '50%', marginRight: 10 }}>
            <Box heading="Hierarchy" height="76vh">
              <FolderTree
                onNameClick={onNameClick}
                data={ashokleyandEquipmentHierarchy}
                iconComponents={iconComponents}
                indentPixels={customIdentPixels}
                showCheckbox={false}
                initOpenStatus="closed"
                readOnly
              />
            </Box>
          </div>

          {equipment && (
            <div style={{ width: '50%', marginLeft: 10 }}>
              <Box heading="PM Job Plan Details" height={'25vh'}>
                <>
                  {isLoading || isRefetching ? (
                    <BasicLoader />
                  ) : (
                    Object.keys(basicDetails)?.map((key: any) =>
                      renderData(key, basicDetails[key], 20)
                    )
                  )}
                </>
              </Box>
              {/* <Box heading="Technical Details" height={'25vh'}>
                <>
                  {isLoading || isRefetching ? (
                    <BasicLoader />
                  ) : (
                    Object.keys(technicalDetails)?.map((key: any) =>
                      renderData(key, technicalDetails[key], 20)
                    )
                  )}
                </>
              </Box> */}

              <Box heading="Documentation Details" height={'10vh'}>
                <>
                  {isLoading || isRefetching ? (
                    <BasicLoader />
                  ) : (
                    <Row style={{ marginLeft: 40 }}>
                      {equipment?.hasCounter && equipment?.pmId && (
                        <Link size={20} onClick={() => setUpdateCounter(true)}>
                          Update Counter
                        </Link>
                      )}
                      <Link
                        size={20}
                        onClick={() =>
                          navigate(`/ashokleyland/mms/maintenance/create`)
                        }
                      >
                        Create Work Request
                      </Link>
                      <Link size={20} onClick={() => setChecklist(true)}>
                        PM Job check list
                      </Link>
                      {/* <Link
                      onClick={() => {
                        setDocument(require('./../../documents/pid.pdf'))
                      }}
                    >
                      Pid
                    </Link> */}
                      {/* <Link
                      onClick={() => {
                        setDocument(require('./../../documents/manual.pdf'))
                      }}
                    >
                      Manuals
                    </Link>
                    <Link
                      onClick={() => {
                        setDocument(require('./../../documents/otherdoc.pdf'))
                      }}
                    >
                      Other Technical Documents
                    </Link> */}
                      <Link size={20}>Calibration Sheets</Link>
                      <Link size={20}>Statutory documents</Link>
                      <Link size={20}>Work History</Link>
                      <Link size={20} onClick={() => setViewSpares(true)}>
                        View Spares
                      </Link>
                    </Row>
                  )}
                </>
              </Box>
            </div>
          )}
        </div>
      </Row>
      <CheckListModal isOpen={checklist} onClose={() => setChecklist(false)} />
      <ViewDocument
        isOpen={!!document}
        toggle={() => setDocument(!document)}
        src={document}
        title={'Document'}
      />
      {equipment && (
        <ViewSpares
          isOpen={viewSpares}
          toggle={() => setViewSpares(!viewSpares)}
          equipment={equipment}
          spares={spares}
        />
      )}
      <SubmitModalWithForm
        isOpen={updateCounter}
        onClose={(val: any) => {
          setUpdateCounter(false)
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()
          const body: any = {
            currentRunningValue: counterValue
          }

          const res = await makeAuthenticatedRequest(
            `${pmAPI}/${equipment?.pmId}`,
            'PATCH',
            body,
            accounts,
            instance
          )

          if (res?.status === 'success') {
            refetch()
          }

          return res?.status
        }}
        size="xs"
        header="Update Counter"
        form={
          <Row>
            <Col>
              <CustomLabel label="Update Latest Running hours" />
              <Input
                type="text"
                onChange={(e: any) => setCounterValue(e?.target?.value)}
              />
            </Col>
          </Row>
        }
      />
    </AshokLeylandLayout>
  )
}
