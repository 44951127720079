import React from 'react'
import { Button, ModalFooter, Row, Table } from 'reactstrap'
import { useContext, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import ConfinedSpaceEntry from '../components/Forms/ConfinedSpaceEntryPermit'
import EditPermitDetails from '../components/Forms/EditPermitDetails'
import ExcavationPermit from '../components/Forms/ExcavationPermit'
import IsolationForm from '../components/Forms/IsolationForm'
import PositiveIsolation from '../components/Forms/PositiveIsolationPermit'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../components/Modals/SubmitModalWithForm'
import VehiclePermit from '../components/Forms/VehiclePermit'
import ViewPermit from './eptw/ViewPermit'
import WorkAtHeight from '../components/Forms/WorkAtHeight'
import { epermit } from '../components/serverurl'
import { makeAuthenticatedRequest } from '../apiRequests'
import { PermissionsContext } from '../App'
import { UpdateModal, useAdminPermissions } from './eptw/EditRisks'
import {
  doneRequiredOptions,
  RadioButtonGroup,
  yesNoOptions
} from './eptw/VerifyPermit'

export default function CreateFromDraft() {
  const { id }: any = useParams()
  const { accounts, instance } = useMsal()
  const { permissions } = useContext(PermissionsContext)
  const navigate = useNavigate()

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const [riskModal, setRiskModal]: any = useState(false)
  const [riskValues, setRiskValues]: any = useState(
    data?.permit?.riskAssesmentPermit?.template
  )
  const [isolationsModal, setIsolationsModal]: any = useState(false)
  const [permitModal, setPermitModal]: any = useState(false)
  const [veModal, setVeModal]: any = useState(false)
  const [epModal, setEpModal]: any = useState(false)
  const [piModal, setPiModal]: any = useState(false)
  const [csModal, setCsModal]: any = useState(false)
  const [whModal, setWhModal]: any = useState(false)
  const [result, setResult]: any = useState(false)

  const [submitModal, setSubmitModal]: any = useState(false)
  const riskId = data?.permit?.riskAssesmentPermit?.template?._id
  const permitId = data?.permit?._id
  const vehiclePermitId = data?.permit?.vehiclePermit?._id
  const confinedSpaceEntryId = data?.permit?.confinedSpaceEntry?._id
  const excavationPermitId = data?.permit?.excavationPermit?._id
  const positiveIsolationId = data?.permit?.positiveIsolation?._id
  const isolationsId = data?.permit?.isolations?._id
  const WorkAtHeightId = data?.permit?.workAtHeight?._id

  const isolationsInitialData = data?.permit?.isolations?.isolations
  const isJSA = data?.permit?.isJSA === 'Non Critical'
  const [isolations, setIsolations]: any = useState(isolationsInitialData)
  const [permitValues, setPermitValues]: any = useState()
  const [vp, setVp]: any = useState(data?.permit?.vehiclePermit)
  const [ep, setEp]: any = useState(data?.permit?.excavationPermit)
  const [pi, setPi]: any = useState(data?.permit?.positiveIsolation)
  const [cs, setCs]: any = useState(data?.permit?.confinedSpaceEntry)
  const [wh, setWh]: any = useState(data?.permit?.workAtHeight)
  const [deleteModal, setDeleteModal]: any = useState(false)

  const [formData, setFormData]: any = useState({
    discussRiskAssesment: 'No',
    equipmentPreparation: 'Not Required',
    materialAvailability: 'Not Required',
    otherHazardsConsidered: 'No'
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const values: any = {
      riskId,
      permitId,
      vehiclePermitId,
      workAtHeightValues: wh,
      WorkAtHeightId,
      confinedSpaceEntryId,
      excavationPermitId,
      positiveIsolationId,
      permitValues,
      vehiclePermitValues: vp,
      excavationValues: ep,
      confinedSpaceValues: cs,
      positiveIsolationValues: pi,
      riskAssessmentValues: riskValues,
      isolationsId,
      serialNumber: data?.permit?.woNumber?.split('/')[1],
      newIsolations: isolations,
      isJSA: data?.permit?.isJSA,
      isolationValues: isolations,
      type: data?.permit?.type === 'hotWork' ? 'HP' : 'CP',
      ...formData
    }

    const response = await makeAuthenticatedRequest(
      `${epermit}/draft`,
      'PATCH',
      values,
      accounts,
      instance
    )

    setResult(response?.data)

    return response.status
  }

  const deleteDraft = async (e: any) => {
    e.preventDefault()

    const body: any = {
      riskId,
      permitId,
      vehiclePermitId,
      confinedSpaceEntryId,
      excavationPermitId,
      positiveIsolationId
    }

    const res: any = await makeAuthenticatedRequest(
      `${epermit}/draft/${id}`,
      'DELETE',
      body,
      accounts,
      instance
    )

    return res?.status
  }

  const permit = data?.permit
  const riskAssessmentValues = permit?.riskAssesmentPermit
  const intialData = riskAssessmentValues?.template

  localStorage.setItem('riskData', JSON.stringify(intialData))

  const riskFromTemplate = data?.permit?.riskAssesmentPermit?.templateNumber

  const form = (
    <div>
      <RadioButtonGroup
        question="Discussed the Risk assesment, and work scope of this Permit with IM and with all parties Involved ?"
        name="discussRiskAssesment"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Equipment Preparation & Site visit carried out ?"
        name="equipmentPreparation"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Checked Material availability for this Permit ?"
        name="materialAvailability"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Hazard from other routine / non-routine operations considered and persons alerted ?"
        name="otherHazardsConsidered"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
    </div>
  )

  return (
    <ProtectedLayout onBack="/eptw/drafts">
      <Row style={{ margin: 10 }}>
        <ViewPermit data={data} isLoading={isLoading} />
      </Row>
      {data?.permit?.status === 'draft' && permissions?.canCreate && (
        <Row style={{ margin: 10 }}>
          <>
            <div style={{ display: 'flex', justifyContent: 'center' }}></div>
            <div
              style={{
                justifyContent: 'space-around',
                display: 'flex',
                flexWrap: 'wrap',
                flexGrow: 1,
                flexBasis: 'auto'
              }}
            >
              <Button
                color="warning"
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => setPermitModal(true)}
              >
                Edit Permit details
              </Button>
              {!riskFromTemplate && (
                <Button
                  color="warning"
                  style={{ marginRight: 10, marginBottom: 10 }}
                  onClick={() => setRiskModal(true)}
                >
                  Edit Risk Assessment
                </Button>
              )}
              <Button
                color="warning"
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => setIsolationsModal(true)}
              >
                Edit Isolations
              </Button>
              <Button
                color="warning"
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => setVeModal(true)}
              >
                Edit Vehicle Permit
              </Button>
              <Button
                color="warning"
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => setEpModal(true)}
              >
                Edit Excavation Permit
              </Button>
              <Button
                color="warning"
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => setPiModal(true)}
              >
                Edit Positive Isolation
              </Button>
              <Button
                color="warning"
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => setCsModal(true)}
              >
                Edit Confined Space Entry
              </Button>
              <Button
                color="warning"
                style={{ marginRight: 10, marginBottom: 10 }}
                onClick={() => setWhModal(true)}
              >
                Edit Work At Height
              </Button>
            </div>
            <ModalFooter>
              <Button color="danger" onClick={() => setDeleteModal(true)}>
                Delete Draft
              </Button>
              <Button color="warning" onClick={() => setSubmitModal(true)}>
                {permitValues?.riskAssessment
                  ? permitValues?.riskAssessment === 'Yes'
                    ? 'Submit to HSE'
                    : 'Submit to PIC'
                  : data?.permit?.riskAssessment
                  ? 'Submit to HSE'
                  : 'Submi to PIC'}
              </Button>
            </ModalFooter>
          </>
          {riskModal && (
            <UpdateModal
              isOpen={riskModal}
              riskValues={
                riskValues || data?.permit?.riskAssesmentPermit?.template
              }
              setRiskValues={setRiskValues}
              isJSA={data?.permit?.isJSA}
              toggle={() => {
                setRiskModal(!riskModal)
              }}
            />
          )}
          {submitModal && (
            <SubmitModalWithForm
              isOpen={submitModal}
              onClose={(val: any) => {
                setSubmitModal(false)
                val && navigate('/eptw/drafts')
                val && setResult(false)
              }}
              onSubmit={handleSubmit}
              form={form}
              sucessView={
                <div>
                  <Table bordered style={{ borderColor: '#000' }}>
                    <tbody>
                      <tr>
                        <td>Permit Number</td>
                        <td>{result?.woNumber || 'Number'}</td>
                      </tr>
                      <tr>
                        <td>Permit Title</td>
                        <td>{result?.permitTitle || 'Title'}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              }
            />
          )}
          {isolationsModal && (
            <IsolationForm
              isOpen={isolationsModal}
              setValues={setIsolations}
              toggle={() => setIsolationsModal(!isolationsModal)}
              values={isolations || data?.permit?.isolations?.isolations}
            />
          )}
          {veModal && (
            <VehiclePermit
              toggle={() => setVeModal(!veModal)}
              isOpen={veModal}
              attachedForms={vp || data?.permit?.vehiclePermit}
              setAttachedForms={setVp}
            />
          )}
          {epModal && (
            <ExcavationPermit
              toggle={() => setEpModal(!epModal)}
              isOpen={epModal}
              attachedForms={ep || data?.permit?.excavationPermit}
              setAttachedForms={setEp}
            />
          )}
          {piModal && (
            <PositiveIsolation
              toggle={() => setPiModal(!piModal)}
              isOpen={piModal}
              attachedForms={pi || data?.permit?.positiveIsolation}
              setAttachedForms={setPi}
            />
          )}
          {csModal && (
            <ConfinedSpaceEntry
              toggle={() => setCsModal(!csModal)}
              isOpen={csModal}
              attachedForms={cs || data?.permit?.confinedSpaceEntry}
              setAttachedForms={setCs}
            />
          )}
          {whModal && (
            <WorkAtHeight
              toggle={() => setWhModal(!whModal)}
              isOpen={whModal}
              attachedForms={wh || data?.permit?.workAtHeight}
              setAttachedForms={setWh}
            />
          )}
          {permitModal && (
            <EditPermitDetails
              isOpen={permitModal}
              toggle={() => setPermitModal(!permitModal)}
              values={permitValues || data?.permit}
              setValues={setPermitValues}
            />
          )}
          {deleteModal && (
            <SubmitModalWithForm
              isOpen={deleteModal}
              onClose={(val: any) => {
                setDeleteModal(false)
                val && navigate('/eptw/drafts')
              }}
              onSubmit={(h: any) => deleteDraft(h)}
              form="Are you sure you want to Delete Draft"
              header="Delete Draft"
            />
          )}
        </Row>
      )}
    </ProtectedLayout>
  )
}
