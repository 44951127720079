import React from 'react'
import { Row, Table } from 'reactstrap'

import PrintPermitHeader from '../prints/PrintPermitHeader'
import PrintVehivlePermitTalk from '../prints/PrintVehiclePermitTalk'
import Spacer from '../Spacer'
import { ColoredSubHeader } from './ViewExcavationPermit'
import { PermitHeader } from '../../pages/WaitingForPIC'
import { PrintHeaderLogos } from '../prints/PrintHeaderLogos'
import { renderLongRow } from '../Modals/AuditPreviewModal'

type ViewVechiclePermit = {
  showPrintHeader?: any
  data: any
  rowStyle: any
  renderRow: any
  permitData: any
}

export default function ViewVehiclePermit({
  showPrintHeader,
  data,
  rowStyle,
  renderRow,
  permitData
}: ViewVechiclePermit) {
  const fields: any = {
    'Route Entry / Passage / Plant': data?.rePassagePlant,
    Purpose: data?.purpose,
    'Technician Escort': data?.techEscort,
    // 'Type of Escort': data?.escortType,
    'Type of Vehicle / Equipment': data?.vehicleType,
    'ToolBox Talk Conducted': data?.toolBoxTalk,
    Recorded: data?.recorded,
    'Vehicle / equipment fitted with Spark Arrestor': data?.sparkArrestor,
    'Exhaust system in good Condition': data?.exhaustSystem,
    'Any oil leaks from vehicle': data?.oilLeaks,
    'Driver aware of the hazards of plant / route  mentioned':
      data?.driverAware,
    'Signal man required for vehicle / equipment': data?.signalMan
  }

  const header: any = {
    'Vehicle Permit No.': data?.permitNumber,
    'Permit Number': permitData?.woNumber,
    'Permit Title': permitData?.permitTitle
  }

  return (
    <>
      {showPrintHeader && (
        <>
          <PrintHeaderLogos permitType={permitData?.type} />
          <PrintPermitHeader permitData={permitData} printData={permitData} />
        </>
      )}
      <ColoredSubHeader
        rowStyle={rowStyle}
        text="Vehicle / Equipment Entry Permit"
      />
      <Row
        style={{
          ...rowStyle,
          fontSize: 14,
          margin: 0
        }}
      >
        {data?.permitNumber &&
          Object.keys(header).map((key) => renderRow(key, header[key]))}
      </Row>
      <Spacer height={5} />
      <Row
        style={{
          fontSize: 12,
          margin: 0
        }}
      >
        {Object.keys(fields).map((key) =>
          renderLongRow(key, fields[key], rowStyle.backgroundColor, 6)
        )}
      </Row>
      <Spacer height={5} />
      {permitData?.status === 'live' && (
        <Row
          style={{
            margin: 0,
            WebkitPrintColorAdjust: 'exact'
          }}
        >
          <PrintVehivlePermitTalk />
        </Row>
      )}
    </>
  )
}
