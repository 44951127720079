import { Button, Input, Row, Table } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useContext, useState } from 'react'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { cwoiEndpoint } from '../../components/serverurl'
import React from 'react'
import { useAdminPermissions } from '../eptw/EditRisks'
import ViewPermit from '../eptw/ViewPermit'
import {
  doneRequiredOptions,
  RadioButtonGroup,
  yesNoOptions
} from '../eptw/VerifyPermit'
import { PermissionsContext } from '../../App'
import { ColoredSubHeader } from '../../components/previews/ViewExcavationPermit'
import CustomLabel from '../../components/InputFields/CustomLabel'

export default function ApproveCWOI() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const { permissions } = useContext(PermissionsContext)

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const [submitModal, setSubmitModal]: any = useState(false)

  const [formData, setFormData]: any = useState({
    workCompleted: 'No',
    cleanlinessCheck: 'No',
    properlySecured: 'No',
    isSafe: 'No',
    hydroCarbonsPresent: 'No',
    hazardsoursMaterials: 'No',
    properlyDrained: 'Not Required',
    properlySteamed: 'Not Required',
    waterFlushed: 'Not Required',
    oilCheck: 'Not Required',
    areaChecked: 'Not Required',
    unitShutdown: 'No',
    plantshutdown: 'No',
    prodLoss: 'No',
    ltiIncident: 'No',
    comments: 'NA'
  })

  const [gasReadings, setGasreadings]: any = useState({})

  const handleInputChange2 = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    const [gas, time] = name.split('_')

    setGasreadings((prevData: any) => {
      const updatedData = {
        ...prevData,
        [time]: {
          ...(prevData[time] || {}),
          [gas]: value
        }
      }

      return updatedData
    })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const submitValues: any = {
      consent: {
        ...formData
      },
      gasReadings,

      permitId: data?.permit?._id,
      consentId: data?.permit?.consent?._id
    }

    const response = await makeAuthenticatedRequest(
      `${cwoiEndpoint}/approve`,
      'PATCH',
      submitValues,
      accounts,
      instance
    )

    return response.status
  }

  const continous: any = [
    '7AM',
    '8AM',
    '9AM',
    '10AM',
    '11AM',
    '12PM',
    '1PM',
    '2PM',
    '3PM',
    '4PM',
    '5PM'
  ]

  const intermittent = ['9AM', '12PM', '3PM', '5PM']

  const interval =
    (data?.permit?.gasMeasurements === 'Continuous' && continous) ||
    (data?.permit?.gasMeasurements === 'Intermittent' && intermittent) ||
    null

  const gases: any = ['H2S', 'O2', 'CO', 'LEL']

  const form = (
    <>
      <div>
        <RadioButtonGroup
          question="Is the work Completed ?"
          name="workCompleted"
          options={yesNoOptions}
          formData={formData}
          onChange={handleInputChange2}
          defaultValue="Not Required"
        />
        <RadioButtonGroup
          question="Is the area is checked for cleanliness after work ?"
          name="cleanlinessCheck"
          options={yesNoOptions}
          formData={formData}
          onChange={handleInputChange2}
          defaultValue="Not Required"
        />
        <RadioButtonGroup
          question="Equipment Tools are properly secured and kept in correct Place ?"
          name="properlySecured"
          options={yesNoOptions}
          formData={formData}
          onChange={handleInputChange2}
          defaultValue="Not Required"
        />
        <RadioButtonGroup
          question="Is it safe for Job Performer to proceed the Job ?"
          name="isSafe"
          options={yesNoOptions}
          formData={formData}
          onChange={handleInputChange2}
          defaultValue="Not Required"
        />
        <RadioButtonGroup
          question="Is the area free from Hydrocarbons and acceptable LEL ?"
          name="hydroCarbonsPresent"
          options={yesNoOptions}
          formData={formData}
          onChange={handleInputChange2}
          defaultValue="Not Required"
        />
        <RadioButtonGroup
          question="Is the area clear from Hazardous materials and Dropped Object and Bad weather Conditions ?"
          name="hazardsoursMaterials"
          options={yesNoOptions}
          formData={formData}
          onChange={handleInputChange2}
          defaultValue="Not Required"
        />
        <RadioButtonGroup
          question="Equipment properly drained ?"
          name="properlyDrained"
          options={doneRequiredOptions}
          formData={formData}
          onChange={handleInputChange2}
          defaultValue="Not Required"
        />
        <RadioButtonGroup
          question="Equipment properly steamed / purged ?"
          name="properlySteamed"
          options={doneRequiredOptions}
          formData={formData}
          onChange={handleInputChange2}
          defaultValue="Not Required"
        />
        <RadioButtonGroup
          question="Equipment water flushed ?"
          name="waterFlushed"
          options={doneRequiredOptions}
          formData={formData}
          onChange={handleInputChange2}
          defaultValue="Not Required"
        />
        <RadioButtonGroup
          question="Checked for oil / gas trapped behind lining in equipment  ?"
          name="oilCheck"
          options={doneRequiredOptions}
          formData={formData}
          onChange={handleInputChange2}
          defaultValue="Not Required"
        />
        <RadioButtonGroup
          question="Surrounding area checked ?"
          name="areaChecked"
          options={doneRequiredOptions}
          formData={formData}
          onChange={handleInputChange2}
          defaultValue="Not Required"
        />
        <RadioButtonGroup
          question="Unit shutdown occurred while performing this Task ?"
          name="uniShutdown"
          options={yesNoOptions}
          formData={formData}
          onChange={handleInputChange2}
          defaultValue="Not Required"
        />
        <RadioButtonGroup
          question="Plant shutdown occurred while performing this Task ?"
          name="plantShutdown"
          options={yesNoOptions}
          formData={formData}
          onChange={handleInputChange2}
          defaultValue="Not Required"
        />
        <RadioButtonGroup
          question="Any Production loss occurred while performing this Task ?"
          name="anyLoss"
          options={yesNoOptions}
          formData={formData}
          onChange={handleInputChange2}
          defaultValue="Not Required"
        />
        <RadioButtonGroup
          question="Any LTI incident while performing this Task? ?"
          name="ltiIncident"
          options={yesNoOptions}
          formData={formData}
          onChange={handleInputChange2}
          defaultValue="Not Required"
        />
        <div className="form-group">
          <CustomLabel label="Comments" />
          <Input type="text" name="comments" onChange={handleInputChange2} />
        </div>
      </div>
      {interval && (
        <div>
          <ColoredSubHeader text="Gas Readings" />
          <Table bordered style={{ borderColor: '#000', marginTop: 10 }}>
            <thead>
              <tr style={{ backgroundColor: 'orange' }}>
                <th>Gas</th>
                {interval?.map((g: any) => <th key={g}>{g}</th>)}
              </tr>
            </thead>
            <tbody>
              {gases?.map((g: any) => (
                <tr key={g}>
                  <td>{g}</td>
                  {interval?.map((c: any) => (
                    <td key={c}>
                      <div style={{ padding: 1 }}>
                        <Input
                          type="text"
                          name={`${g}_${c}`}
                          onChange={handleInputChange}
                          value={
                            gasReadings[c] && gasReadings[c][g]
                              ? gasReadings[c][g]
                              : ''
                          }
                        />
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  )

  return (
    <ProtectedLayout onBack="/eptw/cwoi/approve">
      <ViewPermit data={data} isLoading={isLoading} />
      {data?.permit?.status === 'Verified CWOI' &&
        permissions?.canApproveCWOI && (
          <>
            <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
              <Button
                color="warning"
                style={{ marginTop: 10 }}
                onClick={() => setSubmitModal(true)}
              >
                Approve
              </Button>
            </Row>
            {submitModal && (
              <SubmitModalWithForm
                size="xl"
                isOpen={submitModal}
                onClose={(val: any) => {
                  setSubmitModal(false)
                  val && navigate('/eptw/cwoi/approve')
                }}
                onSubmit={handleSubmit}
                form={form}
              />
            )}
          </>
        )}
    </ProtectedLayout>
  )
}
