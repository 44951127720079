import axios from 'axios'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Table
} from 'reactstrap'
import { FaFileImport } from 'react-icons/fa'
import { useMsal } from '@azure/msal-react'
import { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import ModalTitle from '../../components/ModalTitle'
import RcmLayout from '../../components/rcm/RcmLayout'
import { getAccessToken, makeAuthenticatedRequest } from '../../apiRequests'
import { getAttachment, systemsApi } from '../../components/serverurl'
import { IconButton } from './Systems'
import { loginRequest } from '../../authConfig'
import { useQuery } from 'react-query'
import { SubHeader } from '../VerifyCM'
import { incrementby1 } from '../../components/Forms/JSAForm'

export const maintLibraryHeaders = [
  'Failure Mode',
  'Failure Effect',
  'Failure Pattern',
  'Estimed Time',
  'Time To repair',
  'Price',
  'Prevention'
]

export const maintLibraryDataKeys = [
  'failureMode',
  'failureEffect',
  'failurePattern',
  'estimatedTime',
  'timeToRepair',
  'pricePonc',
  'tasksToPrevent'
]

export const ViewMaintenanceLibrary = ({ data }: any) => {
  const library = data?.maintenanceLibraries?.[0]

  return (
    <>
      <SubHeader header={library?.title} />
      <Table>
        <thead>
          <tr>
            <th>S.No</th>
            {maintLibraryHeaders?.map((h: any) => <th key={h}>{h}</th>)}
          </tr>
        </thead>
        <tbody>
          {library?.data?.map((l: any, index: any) => (
            <tr>
              <td>{incrementby1(index)}</td>
              {maintLibraryDataKeys?.map((k: any) => (
                <td key={l?._id}>{l[k]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default function RcmMaintenance() {
  const { accounts, instance } = useMsal()

  const fetchMaintainLibraries = async () => {
    const response = await makeAuthenticatedRequest(
      `${systemsApi}/maintenanceLibrary`,
      'GET',
      null,
      accounts,
      instance
    )
    return response
  }

  const { data }: any = useQuery(
    'fetchMaintainLibraries data',
    fetchMaintainLibraries
  )

  const [importModal, setImportModal]: any = useState(false)
  const [file, setFile]: any = useState()
  const [submitting, setSubmitting]: any = useState(false)

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0])
  }

  return (
    <RcmLayout
      onBack={'/cmms'}
      title={'RCM'}
      tabHeader={<h4 style={{ margin: 0 }}>Maintenance Library</h4>}
    >
      <div style={{ margin: 0 }}>
        <Col xs="1">
          <IconButton
            icon={<FaFileImport size={25} />}
            buttonText={'Import'}
            onClick={() => setImportModal(true)}
          />
        </Col>
      </div>
      {importModal && (
        <Modal isOpen={importModal} toggle={() => setImportModal(!importModal)}>
          <ModalTitle height={50} title="Import File" />
          <ModalBody>
            <div>
              <form>
                <Input
                  type={'file'}
                  id={'csvFileInput'}
                  accept={'.csv'}
                  onChange={handleOnChange}
                />
                <br />
                <a
                  href={`${getAttachment}/template.csv`}
                  target="_blank"
                  download={'template.csv'}
                  rel="noreferrer"
                >
                  Download Template
                </a>
              </form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={() => setImportModal(false)}>
              Close
            </Button>
            <Button
              color="warning"
              disabled={submitting}
              onClick={async (e: any) => {
                e.preventDefault()
                setSubmitting(true)
                const token = await getAccessToken(
                  loginRequest,
                  accounts,
                  instance
                )
                const formData: any = new FormData()
                formData.append('file', file)

                try {
                  const res: any = await axios.post(
                    `${systemsApi}/maintenanceLibrary`,
                    formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`
                      }
                    }
                  )

                  if (res?.data?.status === 'success') {
                    setSubmitting(false)
                    setImportModal(false)
                    toast('Import Successfully Completed')
                  }

                  return res?.data?.status
                } catch (error) {
                  console.error(error)
                }
              }}
            >
              {submitting ? 'Importing...' : 'Import'}
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <ViewMaintenanceLibrary data={data} />
      <ToastContainer
        theme="dark"
        position="top-center"
        style={{ fontSize: 16 }}
      />
    </RcmLayout>
  )
}
