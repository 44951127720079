import { Route, Routes } from 'react-router-dom'
import { useContext } from 'react'

import ApproveWorkRequest from '../pages/workManagement/ApproveWorkRequest'
import WorkOrders from '../pages/workManagement/WorkOrders'
import { UserContext } from '../App'

export default function WorkOrderRouter() {
  const { userData } = useContext(UserContext)
  const userDepartment = userData?.department?.toLowerCase()

  return (
    <Routes>
      <Route
        path="/draft"
        element={
          <WorkOrders
            status="woDraft"
            department={userDepartment?.trim()}
            onBack="/cmms/draft"
            url="/cmms/workorders/draft"
            title="Work Orders -  Draft"
          />
        }
      />
      <Route
        path="/draft/:id"
        element={<ApproveWorkRequest onBack={'/cmms/workorders'} />}
      />
      <Route
        path="/created"
        element={
          <WorkOrders
            status="woCreated"
            department={userDepartment?.trim()}
            onBack="/cmms/workOrders"
            url="/cmms/workorders/created"
            title="Work Orders - Created"
          />
        }
      />
      <Route
        path="/created/:id"
        element={<ApproveWorkRequest onBack={'/cmms/workorders'} />}
      />
      <Route
        path="/todo"
        element={
          <WorkOrders
            status="todo"
            department={userDepartment?.trim()}
            onBack="/cmms/workOrders"
            url="/cmms/workorders/todo"
            title="Work Orders - Todo"
          />
        }
      />
      <Route
        path="/todo/:id"
        element={<ApproveWorkRequest onBack="/cmms/workorders/todo" />}
      />
      <Route
        path="/waitingForMaterial"
        element={
          <WorkOrders
            status="waitingForMaterial"
            department={userDepartment?.trim()}
            onBack="/cmms/workorders"
            url="/cmms/workorders/waitingForMaterial"
            title="Work Orders - Waiting For Material"
          />
        }
      />
      <Route
        path="/waitingForMaterial/:id"
        element={
          <ApproveWorkRequest onBack="/cmms/workorders/waitingForMaterial" />
        }
      />
      <Route
        path="/inProgress"
        element={
          <WorkOrders
            status="inProgress"
            department={userDepartment?.trim()}
            onBack="/cmms/workorders"
            url="/cmms/workorders/inProgress"
            title="Work Orders - In Progress"
          />
        }
      />
      <Route
        path="/inProgress/:id"
        element={<ApproveWorkRequest onBack="/cmms/workorders/inProgress" />}
      />
      <Route
        path="/waitingFor3Party"
        element={
          <WorkOrders
            status="waitingFor3rdParty"
            department={userDepartment?.trim()}
            onBack="/cmms/workorders"
            url="/cmms/workorders/waitingFor3Party"
            title="Work Orders - Waiting For 3rd Party"
          />
        }
      />
      <Route
        path="/waitingFor3Party/:id"
        element={
          <ApproveWorkRequest onBack="/cmms/workorders/waitingFor3Party" />
        }
      />
      <Route
        path="/waitingForShutdown"
        element={
          <WorkOrders
            status="waitingForShutdown"
            department={userDepartment?.trim()}
            onBack="/cmms/workorders"
            url="/cmms/workorders/waitingForShutdown"
            title="Work Orders - Waiting For Shutdown"
          />
        }
      />
      <Route
        path="/waitingForShutdown/:id"
        element={
          <ApproveWorkRequest onBack="/cmms/workorders/waitingForShutdown" />
        }
      />
      <Route
        path="/closed"
        element={
          <WorkOrders
            status="closed"
            department={userDepartment?.trim()}
            onBack="/cmms/workorders"
            url="/cmms/workorders/closed"
            title="Work Orders - Closed"
          />
        }
      />
      <Route
        path="/closed/:id"
        element={<ApproveWorkRequest onBack="/cmms/workorders/closed" />}
      />
      <Route
        path="/cancelledWorkOrders"
        element={
          <WorkOrders
            status="cancelled"
            department={userDepartment?.trim()}
            onBack="/cmms/workorders"
            url="/cmms/workorders/cancelledWorkOrders"
            title="Cancelled Work Orders"
          />
        }
      />
      <Route
        path="/cancelledWorkOrders/:id"
        element={
          <ApproveWorkRequest onBack="/cmms/workorders/cancelledWorkOrders" />
        }
      />
      {/* unsorted */}
    </Routes>
  )
}
