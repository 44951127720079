import { Route, Routes } from 'react-router-dom'
import AdminDashboard from '../pages/admin/AdminDashboard'
import AssignGroups from '../pages/admin/AssignGroups'
import Vendors from '../pages/inventory/Vendors'

export default function AdminRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AdminDashboard />} />
      <Route path="/groups" element={<AssignGroups />} />
      <Route path="/vendors" element={<Vendors />} />
    </Routes>
  )
}
