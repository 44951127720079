import arrayMutators from 'final-form-arrays'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { Button, Col, ModalFooter, Row, Table } from 'reactstrap'
import { Field, Form } from 'react-final-form'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'

import AutoCompleteAndApi from '../../components/InputFields/AutoCompleteAndAPI'
import CustomLabel from '../../components/InputFields/CustomLabel'
import LabeledTextInput from '../../components/InputFields/LabeledTextInput'
import LabledParagraphInput from '../../components/InputFields/LabledParagraph'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import toNormalCase from '../../components/lib/toNormalCase'
import { Box, renderData } from './PmTree'
import { capitalizeFirstLetter } from '../../components/lib/capitalizeFirstLetter'
import { equipmentAPI, workOrderAPI } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { UserContext } from '../../App'

const WOPreview = ({
  formValues,
  toggle,
  isOpen
}: {
  formValues: any
  toggle: any
  isOpen: any
}) => {
  const { accounts, instance } = useMsal()
  const [result, setResult]: any = useState()

  const { userData } = useContext(UserContext)

  const userDepartment = userData?.department?.toLowerCase()

  const generalDetails: any = {
    'Vessel / Rig': formValues?.vessel,
    'WO description': formValues?.description,
    'Created Date': moment().format('DD-MM-YYYY'),
    'Reported By': formValues?.reportedBy,
    'Tag ID': formValues?.tagId,
    'Tag Description': formValues?.tagDescription,
    'Tag Type': formValues?.tagType,
    Function: formValues?.function,
    'Function Description': formValues?.functionDescription
  }

  const planningInformation: any = {
    Department: capitalizeFirstLetter(formValues?.department),
    'Work Type': toNormalCase(formValues?.workType),
    // 'Operation Status': capitalizeFirstLetter(formValues?.operationStatus),
    Priority: formValues?.priority,
    Criticality: formValues?.criticality,
    'Estimated Hours': formValues?.estimatedHours
  }

  const prepare: any = {
    'Work Description': formValues?.problemNoticed,
    'Job to be Done': formValues?.jobPending
  }

  const onSubmit = async () => {
    try {
      const res: any = await makeAuthenticatedRequest(
        workOrderAPI,
        'POST',
        {
          ...formValues,
          createdAt: moment(),
          createdDepartment: userDepartment?.trim(),
          lastestUpdatedBy: userData?.id,
          woStatus: 'wrCreated'
        },
        accounts,
        instance
      )

      setResult(res)
      return res?.status
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <SubmitModalWithForm
        isOpen={isOpen}
        onClose={(val: any) => {
          toggle()
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()
          const res = await onSubmit()
          return res
        }}
        size="lg"
        header="Create Work Request"
        form={
          <Row style={{ margin: 10 }}>
            <Box heading="General Details">
              {Object.keys(generalDetails)?.map((key: any) =>
                renderData(key, generalDetails[key])
              )}
            </Box>
            <Box heading="Planning Information">
              {Object.keys(planningInformation)?.map((key: any) =>
                renderData(key, planningInformation[key])
              )}
            </Box>
            <Box heading="Prepare" height={'5vh'}>
              {Object.keys(prepare)?.map((key: any) =>
                renderData(key, prepare[key])
              )}
            </Box>
          </Row>
        }
        sucessView={
          <div>
            <Table bordered style={{ borderColor: '#000' }}>
              <tbody>
                <tr>
                  <td>WO Number</td>
                  <td>{result?.permit?.number || 'Number'}</td>
                </tr>
                <tr>
                  <td>WO Description</td>
                  <td>{result?.permit?.description}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        }
      />
    </>
  )
}

const WoDraftModal = ({
  formValues,
  toggle,
  isOpen
}: {
  formValues: any
  toggle: any
  isOpen: any
}) => {
  const { accounts, instance } = useMsal()
  const [result, setResult]: any = useState()

  const { userData } = useContext(UserContext)

  const userDepartment = userData?.department?.toLowerCase()

  const generalDetails: any = {
    'Vessel / Rig': formValues?.vessel,
    'WO description': formValues?.description,
    'Created Date': moment().format('DD-MM-YYYY'),
    'Reported By': formValues?.reportedBy,
    'Tag ID': formValues?.tagId,
    'Tag Description': formValues?.tagDescription,
    'Tag Type': formValues?.tagType,
    Function: formValues?.function,
    'Function Description': formValues?.functionDescription
  }

  const planningInformation: any = {
    Department: capitalizeFirstLetter(formValues?.department),
    'Work Type': toNormalCase(formValues?.workType),
    // 'Operation Status': capitalizeFirstLetter(formValues?.operationStatus),
    Priority: formValues?.priority,
    Criticality: formValues?.criticality,
    'Estimated Hours': formValues?.estimatedHours
  }

  const prepare: any = {
    'Work Description': formValues?.problemNoticed,
    'Job to be Done': formValues?.jobPending
  }

  const onSubmit = async () => {
    try {
      const res: any = await makeAuthenticatedRequest(
        workOrderAPI,
        'POST',
        {
          ...formValues,
          createdAt: moment(),
          createdDepartment: userDepartment?.trim(),
          lastestUpdatedBy: userData?.id,
          woStatus: 'wrDraft'
        },
        accounts,
        instance
      )

      setResult(res)
      return res?.status
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <SubmitModalWithForm
        isOpen={isOpen}
        onClose={(val: any) => {
          toggle()
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()
          const res = await onSubmit()
          return res
        }}
        size="lg"
        header="Draft Work Request"
        form={
          <Row style={{ margin: 10 }}>
            <Box heading="General Details">
              {Object.keys(generalDetails)?.map((key: any) =>
                renderData(key, generalDetails[key])
              )}
            </Box>
            <Box heading="Planning Information">
              {Object.keys(planningInformation)?.map((key: any) =>
                renderData(key, planningInformation[key])
              )}
            </Box>
            <Box heading="Prepare" height={'5vh'}>
              {Object.keys(prepare)?.map((key: any) =>
                renderData(key, prepare[key])
              )}
            </Box>
          </Row>
        }
        sucessView={
          <div>
            <Table bordered style={{ borderColor: '#000' }}>
              <tbody>
                <tr>
                  <td>WO Number</td>
                  <td>{result?.permit?.number || 'Number'}</td>
                </tr>
                <tr>
                  <td>WO Description</td>
                  <td>{result?.permit?.description}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        }
      />
    </>
  )
}

export const submitSample: any = {
  vessel: 'UNIT-1',
  description: 'Unit 1 Prod Well',
  status: 'In Operation',
  tagId: '18.01.10.040',
  tagDescription: 'Emergency Contactor',
  tagType: 'SS',
  department: 'mechanical',
  workType: 'correctiveMaintenance',
  operationStatus: 'obselete',
  priority: '2',
  criticality: 'medium',
  estimatedHours: '23',
  problemNoticed: 'Some Work Description',
  jobPending: 'Hob to be Dione in Somerbis. tornsbfd'
}

const fontSizes: any = {
  xs: 12,
  sm: 14,
  normal: 16,
  lg: 18,
  xl: 20,
  xxl: 22
}

export const Text = ({
  children,
  styles,
  size,
  onClick = () => {}
}: {
  children?: any
  styles?: any
  size?: string | any
  onClick?: any
}) => {
  return (
    <p
      onClick={onClick}
      style={{
        ...styles,
        margin: 0,
        fontSize: fontSizes[size] || fontSizes?.normal
      }}
    >
      {children}
    </p>
  )
}

export const FormSection = ({
  title,
  children,
  isInitial = false
}: {
  title: string
  children: any
  isInitial?: boolean
}) => (
  <>
    {!isInitial && <hr />}
    <Row style={{ marginBottom: 20 }}>
      <Col
        xs="2"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Spacer height={10} />
        <Text
          size="xxl"
          styles={{
            fontWeight: 700
          }}
        >
          {title}
        </Text>
      </Col>
      <Col>
        <Row>{children}</Row>
      </Col>
    </Row>
  </>
)

const validate = (values: any) => {
  const errors: any = {}
  if (!values.vessel) {
    errors.vessel = 'Required'
  }
  if (!values.description) {
    errors.description = 'Required'
  }
  if (!values.tagId) {
    errors.tagId = 'Required'
  }
  if (!values.description) {
    errors.description = 'Required'
  }
  if (!values.tagDescription) {
    errors.tagDescription = 'Required'
  }
  if (!values.tagType) {
    errors.tagType = 'Required'
  }
  if (!values.department) {
    errors.department = 'Required'
  }
  if (!values.description) {
    errors.description = 'Required'
  }
  if (!values.workType) {
    errors.workType = 'Required'
  }

  if (!values.priority) {
    errors.priority = 'Required'
  }
  if (!values.criticality) {
    errors.criticality = 'Required'
  }
  if (!values.estimatedHours) {
    errors.estimatedHours = 'Required'
  }
  if (!values.problemNoticed) {
    errors.problemNoticed = 'Required'
  }
  if (!values.jobPending) {
    errors.jobPending = 'Required'
  }
  if (!values.status) {
    errors.status = 'Required'
  }

  return errors
}

export default function WorkRequestForm({ onBack }: { onBack: string }) {
  const { userData } = useContext(UserContext)
  const { accounts, instance } = useMsal()

  const [searchParams] = useSearchParams()
  const [preview, setPreview] = useState(false)
  const [draftModal, setDraftModal] = useState(false)

  const eq = searchParams.get('equipment')

  const fetchTag = async () => {
    const response = await makeAuthenticatedRequest(
      `${equipmentAPI}?equipment=${eq}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data }: any = useQuery('tagDetails', fetchTag)

  const equipment = data?.equipment

  const localData: any = localStorage.getItem('cm')
  const { number, tag } = (localData && JSON.parse(localData)) || {}

  const formInitialValues = {
    vessel: equipment?.vessel,
    // description: equipment?.systemDescription,
    tag: equipment?.tag || tag,
    tagId: equipment?.tag,
    tagDescription: equipment?.tagDescription,
    tagType: equipment?.tagType,
    reportedBy: userData?.givenName,
    criticality: equipment?.criticality,
    function: eq ? equipment?.function || '13.0.0.1' : '',
    functionDescription: equipment?.functionDescription,
    status: 'Unplanned WO',
    incidentReportNumber: number
    // ...submitSample
  }

  return (
    <ProtectedLayout onBack={onBack} title={'CREATE WORK REQUEST'}>
      <Row style={{ margin: 10 }}>
        <Form
          onSubmit={(values: any) => {}}
          mutators={{
            ...arrayMutators
          }}
          validate={validate}
          initialValues={{
            ...formInitialValues
          }}
          render={({ values, form, hasValidationErrors }) => (
            <form>
              <FormSection title="General Details">
                <LabeledTextInput
                  name="vessel"
                  label="Vessel / Rig *"
                  isDisabled
                  occupy={3}
                />
                <LabledParagraphInput
                  name="description"
                  label="WO Description  *"
                  occupy={6}
                />
                <LabeledTextInput
                  name="reportedBy"
                  label="Reported By"
                  isDisabled
                  occupy={3}
                />
                {eq ? (
                  <LabeledTextInput
                    name="tag"
                    label="Tag"
                    isDisabled
                    occupy={3}
                  />
                ) : (
                  <Col xs="3">
                    <CustomLabel label="Tag" />
                    <Field name="tag" style={{ background: '#DCDCDC' }}>
                      {({ input, ...rest }) => (
                        <div>
                          <AutoCompleteAndApi
                            isMulti={false}
                            name="tagId"
                            url={`${workOrderAPI}/filters`}
                            value={values?.tagId}
                            handleChange={(option: any, change: any) => {
                              input.onChange(option?.value)
                              change('tagId', option?.value)
                              change('tagDescription', option?.description)
                              change('tagType', option?.tagType)
                              change(
                                'functionDescription',
                                option?.functionDescription
                              )
                              change('function', option?.function)
                              change('criticality', option?.criticality)
                              change('vessel', option?.vessel)
                            }}
                            needSelect={false}
                            input={input}
                            {...rest}
                          />
                        </div>
                      )}
                    </Field>
                  </Col>
                )}
                <LabeledTextInput
                  name="tagDescription"
                  label="Tag Description *"
                  isDisabled
                  occupy={6}
                />
                <LabeledTextInput
                  name="tagType"
                  label="Tag Type *"
                  isDisabled
                  occupy={3}
                />
                <LabeledTextInput
                  name="function"
                  label="Function Number *"
                  isDisabled
                  occupy={3}
                />
                <LabeledTextInput
                  name="functionDescription"
                  label="Function Description *"
                  isDisabled
                  occupy={6}
                />
                <LabeledTextInput
                  name="criticality"
                  label="Equipment Criticality *"
                  isDisabled
                  occupy={3}
                />
                <LabeledTextInput
                  name="createdOn"
                  label="Created Date"
                  value={moment().format('DD-MM-YYYY hh:mm:ss')}
                  occupy={3}
                />
                <LabeledTextInput
                  label="Incident Report Number"
                  name="incidentReportNumber"
                  occupy={3}
                />
              </FormSection>
              <FormSection title="Planning Information">
                <Col xs="3">
                  <CustomLabel label="Department *" />
                  <Field
                    name={'department'}
                    component="select"
                    defaultValue=""
                    style={{
                      width: '-webkit-fill-available',
                      height: 38,
                      borderRadius: 5,
                      backgroundColor: '#DCDCDC'
                    }}
                  >
                    <option value="" disabled>
                      Select Department
                    </option>
                    <option value="mechanical">Mechanical</option>
                    <option value="electrical">Electrical</option>
                    <option value="instrument">Instrument</option>
                    <option value="production">Production</option>
                    <option value="hse">HSE</option>
                    <option value="adminWareHouse">Admin Ware House</option>
                  </Field>
                </Col>
                <Col xs="3">
                  <CustomLabel label="Work Type *" />
                  <Field
                    name={'workType'}
                    component="select"
                    defaultValue=""
                    style={{
                      width: '-webkit-fill-available',
                      height: 38,
                      borderRadius: 5,
                      backgroundColor: '#DCDCDC'
                    }}
                  >
                    <option value="" disabled>
                      Select Work Type
                    </option>
                    <option value="correctiveMaintenance">
                      Corrective Maintenance
                    </option>
                    <option value="breakdownMaintenance">
                      Breakdown Maintenance
                    </option>
                    <option value="modification">Modification</option>
                  </Field>
                </Col>
                <Col xs="3">
                  <CustomLabel label="Priority *" />
                  <Field
                    name={'priority'}
                    component="select"
                    placeholder="s"
                    style={{
                      width: '-webkit-fill-available',
                      height: 38,
                      borderRadius: 5,
                      backgroundColor: '#DCDCDC'
                    }}
                  >
                    <option value="" disabled>
                      Select Priority
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </Field>
                </Col>
                <LabeledTextInput
                  name="estimatedHours"
                  label="Estimated Hours *"
                  occupy={3}
                />
              </FormSection>
              <FormSection title="Prepare">
                <LabledParagraphInput
                  name="problemNoticed"
                  label="Work Description *"
                  occupy={12}
                />
                <LabledParagraphInput
                  name="jobPending"
                  label="Job to be Done *"
                  occupy={12}
                />
              </FormSection>
              <ModalFooter>
                <Button color={'warning'} onClick={() => setDraftModal(true)}>
                  Save
                </Button>
                <Button
                  color={hasValidationErrors ? 'secondary' : 'warning'}
                  onClick={() => setPreview(true)}
                  disabled={hasValidationErrors}
                >
                  Submit
                </Button>
              </ModalFooter>
              <WOPreview
                isOpen={preview}
                toggle={() => setPreview(!preview)}
                formValues={values}
              />
              <WoDraftModal
                isOpen={draftModal}
                toggle={() => setDraftModal(!draftModal)}
                formValues={values}
              />
            </form>
          )}
        />
      </Row>
    </ProtectedLayout>
  )
}
