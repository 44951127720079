import { Row, Table } from 'reactstrap'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { incrementby1 } from '../../components/Forms/JSAForm'
const valueRegisterData = [
  {
    'P&ID Ref Number': '1',
    'Valve Tag': 'T25GA551',
    Description: 'T25PSV074 upstream isolation valve',
    'P&ID': 'DTT74001',
    Location: 'MOD10',
    System: 'Knock out drum',
    'Normal Position': 'LO',
    Purpose: 'gas',
    'Specific Requirements': 'NO',
    'Current Position  Correct?': 'YES',
    'Lock / Seal Number': '21641',
    'Key Box No (if used)': '',
    'Control Sheet / PTW': '',
    'Ref Number': ''
  },
  {
    'P&ID Ref Number': '2',
    'Valve Tag': 'T76BL296',
    Description: 'T25PSV074 downstream isolation valve',
    'P&ID': 'DTT74001',
    Location: 'MOD10',
    System: 'Knock out drum',
    'Normal Position': 'LO',
    Purpose: 'gas',
    'Specific Requirements': 'NO',
    'Current Position  Correct?': 'YES',
    'Lock / Seal Number': '21642',
    'Key Box No (if used)': '',
    'Control Sheet / PTW': '',
    'Ref Number': ''
  },
  {
    'P&ID Ref Number': '3',
    'Valve Tag': 'T74BL059',
    Description: 'T74PSV087A upstream isolation valve',
    'P&ID': 'DTT74001',
    Location: 'MOD10',
    System: 'Knock out drum',
    'Normal Position': 'LO',
    Purpose: 'gas',
    'Specific Requirements': 'NO',
    'Current Position  Correct?': 'YES',
    'Lock / Seal Number': '85270',
    'Key Box No (if used)': '',
    'Control Sheet / PTW': '',
    'Ref Number': ''
  },
  {
    'P&ID Ref Number': '4',
    'Valve Tag': 'T76BL100',
    Description: 'T74PSV087A downstream isolation valve',
    'P&ID': 'DTT74001',
    Location: 'MOD10',
    System: 'Knock out drum',
    'Normal Position': 'LO',
    Purpose: 'gas',
    'Specific Requirements': 'NO',
    'Current Position  Correct?': 'YES',
    'Lock / Seal Number': '21684',
    'Key Box No (if used)': '',
    'Control Sheet / PTW': '',
    'Ref Number': ''
  },
  {
    'P&ID Ref Number': '5',
    'Valve Tag': 'T74BL058',
    Description: 'T74PSV087B upstream isolation valve',
    'P&ID': 'DTT74001',
    Location: 'MOD10',
    System: 'Knock out drum',
    'Normal Position': 'LC',
    Purpose: 'gas',
    'Specific Requirements': 'NO',
    'Current Position  Correct?': 'YES',
    'Lock / Seal Number': '87579',
    'Key Box No (if used)': '',
    'Control Sheet / PTW': '',
    'Ref Number': ''
  },
  {
    'P&ID Ref Number': '6',
    'Valve Tag': 'T76BL101',
    Description: 'T74PSV087B downstream isolation valve',
    'P&ID': 'DTT74001',
    Location: 'MOD10',
    System: 'Knock out drum',
    'Normal Position': 'LO',
    Purpose: 'gas',
    'Specific Requirements': 'NO',
    'Current Position  Correct?': 'YES',
    'Lock / Seal Number': '81272',
    'Key Box No (if used)': '',
    'Control Sheet / PTW': '',
    'Ref Number': ''
  },
  {
    'P&ID Ref Number': '7',
    'Valve Tag': 'T74BL061',
    Description: 'T74BDV089 downstream isolation valve',
    'P&ID': 'DTT74001',
    Location: 'MOD10',
    System: 'Knock out drum',
    'Normal Position': 'LO',
    Purpose: 'gas',
    'Specific Requirements': 'NO',
    'Current Position  Correct?': 'YES',
    'Lock / Seal Number': '21541',
    'Key Box No (if used)': '',
    'Control Sheet / PTW': '',
    'Ref Number': ''
  },
  {
    'P&ID Ref Number': '8',
    'Valve Tag': 'T74BL958',
    Description: 'T74PST088 isolation valve',
    'P&ID': 'DTT74001',
    Location: 'MOD10',
    System: 'Knock out drum',
    'Normal Position': 'LO',
    Purpose: 'gas',
    'Specific Requirements': 'NO',
    'Current Position  Correct?': 'YES',
    'Lock / Seal Number': '81271',
    'Key Box No (if used)': '',
    'Control Sheet / PTW': '',
    'Ref Number': ''
  },
  {
    'P&ID Ref Number': '9',
    'Valve Tag': 'T74BL076',
    Description: 'T74LST086 top tap off isolation',
    'P&ID': 'DTT74001',
    Location: 'MOD10',
    System: 'Knock out drum',
    'Normal Position': 'LO',
    Purpose: 'gas',
    'Specific Requirements': 'NO',
    'Current Position  Correct?': 'YES',
    'Lock / Seal Number': '148266',
    'Key Box No (if used)': '',
    'Control Sheet / PTW': '',
    'Ref Number': ''
  },
  {
    'P&ID Ref Number': '10',
    'Valve Tag': 'T74BL075',
    Description: 'T74LST086 bottom tap off isolation',
    'P&ID': 'DTT74001',
    Location: 'MOD10',
    System: 'Knock out drum',
    'Normal Position': 'LO',
    Purpose: 'gas',
    'Specific Requirements': 'NO',
    'Current Position  Correct?': 'YES',
    'Lock / Seal Number': '148369',
    'Key Box No (if used)': '',
    'Control Sheet / PTW': '',
    'Ref Number': ''
  }
]

export default function ValueRegister() {
  const hederKeys = Object.keys(valueRegisterData[0])
  return (
    <ProtectedLayout onBack="/eptw/menu">
      <Row style={{ margin: 20 }}>
        <Table bordered style={{ borderColor: 'black' }}>
          <thead style={{ backgroundColor: 'gold' }}>
            <tr>{hederKeys?.map((h: any) => <th key={h}>{h}</th>)}</tr>
          </thead>
          <tbody>
            {valueRegisterData?.map((r: any, index: any) => (
              <tr key={`${r?.['Valve Tag']}-${index}`}>
                {hederKeys?.map((k: any, kIndex: any) => (
                  <td key={`${index}-${kIndex}-${k}`}>{r[k]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </ProtectedLayout>
  )
}
