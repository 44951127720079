import { Input } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useState } from 'react'

import { useAdminPermissions } from './EditRisks'
import IsolationsCheckboxTable from '../../components/Tables/IsolationsCheckBoxTable'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import ViewPermit from './ViewPermit'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { newPermitEndpoint } from '../../components/serverurl'
import { SubHeader } from '../VerifyCM'

import React from 'react'
import CustomLabel from '../../components/InputFields/CustomLabel'

export const yesNoOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
]

export const doneRequiredOptions = [
  { value: 'Done', label: 'Done' },
  { value: 'Not Required', label: 'Not Required' }
]

export function RadioButtonGroup({
  name,
  options,
  formData,
  onChange,
  question,
  defaultValue
}: any) {
  if (formData[name] === undefined) {
    formData[name] = defaultValue
  }

  return (
    <div>
      <CustomLabel label={question} />
      <div className="form-group" style={{ display: 'flex' }}>
        {options.map((option: any) => (
          <div key={option.value} style={{ display: 'flex', marginRight: 10 }}>
            <input
              type="radio"
              name={name}
              value={option.value}
              checked={formData[name] === option.value}
              onChange={onChange}
            />
            <label>{option.label}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default function VerifyPermit({ onBack }: any) {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const [submitModal, setSubmitModal]: any = useState(false)

  const [formData, setFormData]: any = useState({
    discussedIsolations: 'Not Required',
    equipmentPreparation: 'Not Required',
    appropriatePermit: 'Not Required',
    simopsCarriedOut: 'No'
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const submitValues = {
      ...formData,

      permitId: data?.permit?._id,
      isolationsId: data?.permit?.isolations?._id,
      consentId: data?.permit?.consent?._id
    }
    const response = await makeAuthenticatedRequest(
      `${newPermitEndpoint}/verify`,
      'PATCH',
      submitValues,
      accounts,
      instance
    )

    return response.status
  }

  const hasIsolations = data?.permit?.isolations?.isolations?.length > 0
  const hasLockBoxNumber = data?.permit?.isolations?.lockBoxNumber

  const form = (
    <div>
      {hasIsolations && !hasLockBoxNumber && (
        <div className="form-group">
          <CustomLabel label="Lock Number" />
          <Input type="text" name="lockNo" onChange={handleInputChange} />
          {!formData?.lockNo && (
            <>
              <span
                style={{
                  fontSize: 12,
                  color: 'red'
                }}
              >
                * Please Enter a Lock Number
              </span>
            </>
          )}
        </div>
      )}
      <RadioButtonGroup
        question="Have you discussed the Isolation requirement is sufficient for this Work with IM,AO and JP ?"
        name="discussedIsolations"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Equipment Preparation & Site visit carried out ?"
        name="equipmentPreparation"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Is this Permit type appropriate for this task carried out in this Permit ?"
        name="appropriatePermit"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="SIMOPS carried out ?"
        name="simopsCarriedOut"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
    </div>
  )

  const isDisabled = hasLockBoxNumber ? false : formData?.lockNo === ''

  return (
    <ProtectedLayout onBack={onBack}>
      <ViewPermit data={data} isLoading={isLoading} />
      {(data?.permit?.status === 'HSE Verified' ||
        data?.permit?.status === 'Created') && (
        <>
          {hasIsolations && <SubHeader header="Verify Isolations" />}
          <IsolationsCheckboxTable
            printData={data}
            checkBoxHeader="Verify"
            buttonText="Verify Permit"
            data={data?.permit?.isolations?.isolations}
            consentId={data?.permit?.consent?._id}
            onSubmit={(val: any) => {
              setSubmitModal(true)
            }}
            showReject
          />
          {submitModal && (
            <SubmitModalWithForm
              isOpen={submitModal}
              isDisabled={isDisabled}
              onClose={(val: any) => {
                setSubmitModal(false)
                val && navigate(onBack)
              }}
              onSubmit={handleSubmit}
              form={form}
            />
          )}
        </>
      )}
    </ProtectedLayout>
  )
}
