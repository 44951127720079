import React, { useState } from 'react'
import { Button, ModalFooter, Table } from 'reactstrap'
import { RejectModal } from '../../pages/eptw/EditRisks'
import { incrementby1 } from '../Forms/JSAForm'
import toNormalCase from '../lib/toNormalCase'
import PrintIsolations from '../prints/PrintIsolations'

function IsolationsCheckboxTable({
  data,
  onSubmit,
  buttonText,
  checkBoxHeader,
  printData,
  isPrint,
  showReject,
  consentId
}: {
  data: any
  onSubmit: any
  buttonText: any
  checkBoxHeader: any
  printData?: any
  isPrint?: any
  showReject?: any
  consentId?: any
}) {
  const initialCheckboxesState = data?.reduce(
    (acc: any, _: any, index: any) => {
      acc[index] = false
      return acc
    },
    {}
  )
  const [checkboxes, setCheckboxes] = useState(initialCheckboxesState)
  const [reject, setReject]: any = useState(false)

  const [print, setPrint] = useState(false)

  if (!data) {
    return (
      <ModalFooter>
        {showReject && (
          <Button color="danger" onClick={() => setReject(true)}>
            Reject
          </Button>
        )}
        <Button type="submit" color="warning" onClick={onSubmit}>
          Verify Permit
        </Button>
        <RejectModal
          consentId={consentId}
          data={printData}
          isOpen={reject}
          setSubmitModal={setReject}
          redirectUrl="/eptw/woi/verify"
          toggle={() => {
            setReject(!reject)
          }}
        />
      </ModalFooter>
    )
  }

  function areAllCheckboxesChecked() {
    const checkboxValues = checkboxes ? Object.values(checkboxes) : []
    return checkboxValues.every((isChecked) => isChecked)
  }

  return (
    <>
      {data?.map((i: any, index: number) => (
        <Table bordered striped>
          <thead>
            <tr style={{ backgroundColor: 'orange' }}>
              {[
                checkBoxHeader,
                'S.No',
                'Equipment Description',
                'Equipment Tag',
                'Isolation Tag',
                'Method',
                'Type',
                'Description',
                'State',
                'deIsolated State',
                'Pressure Buildup',
                'Override',
                'PBU Readings',
                'Remarks'
              ]?.map((s: any) => <th key={s}>{s}</th>)}
            </tr>
          </thead>
          <tbody>
            <tr key={index}>
              <td>
                <td>
                  <input
                    onChange={(e) => {
                      const isChecked = e.target.checked
                      setCheckboxes((prevState: any) => ({
                        ...prevState,
                        [index]: isChecked
                      }))
                    }}
                    type="checkbox"
                  />
                </td>
              </td>
              {[
                incrementby1(index),
                ...(i?.number ? [i?.number] : []),
                i?.isolationPoint,
                i?.isolationTag,
                i?.isolationMethod,
                i?.isolationType,
                i?.isolationDescription,
                i?.isolationState,
                i?.deIsolatedState,
                toNormalCase(i?.pressureBuildup),
                i?.override,
                i?.readings || 'NA',
                i?.isolationComment
              ]?.map((f: any, index: number) => (
                <td key={`${f}_${index}`}>{f}</td>
              ))}
            </tr>
          </tbody>
        </Table>
      ))}
      <ModalFooter>
        {showReject && (
          <Button color="danger" onClick={() => setReject(true)}>
            Reject
          </Button>
        )}
        <Button color="warning" onClick={() => setPrint(true)}>
          Print Isolations
        </Button>
        <Button
          type="submit"
          color="warning"
          disabled={!areAllCheckboxesChecked()}
          onClick={() => {
            onSubmit(checkboxes)
          }}
        >
          {buttonText || 'approve'}
        </Button>
      </ModalFooter>
      <PrintIsolations
        isOpen={print}
        toggle={() => setPrint(!print)}
        printData={printData}
        setPrint={setPrint}
      />
      <RejectModal
        consentId={consentId}
        data={printData}
        isOpen={reject}
        setSubmitModal={setReject}
        redirectUrl="/eptw/newPermit/verify"
        toggle={() => {
          setReject(!reject)
        }}
      />
    </>
  )
}

export default IsolationsCheckboxTable
