import moment from "moment";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useMsal } from "@azure/msal-react";
import { useState } from "react";

import { informationCardEndpoint } from "../serverurl";
import { makeAuthenticatedRequest } from "../../apiRequests";
import PreviewInformationCard from "../previews/PreviewInformationCard";
import SubmitModalWithForm from "./SubmitModalWithForm";

export default function InformationCardModal({
  isOpen,
  toggle,
  form,
  values,
  status,
}: any) {
  const { instance, accounts } = useMsal();

  const [submitting, setSubmitting]: any = useState(false);
  const [submitModal, setSubmitModal]: any = useState(false);
  const [result, setResult]: any = useState({});

  const createCard = async () => {
    try {
      setSubmitting(true);
      const res: any = await makeAuthenticatedRequest(
        informationCardEndpoint,
        "POST",
        { ...values, createdAt: moment(), status },
        accounts,
        instance
      );

      console.log(res);

      if (res.status === "success") {
        setResult(res);
        form.reset();
      }
    } catch (error) {
    } finally {
      setSubmitting(false);
    }

    return result.status;
  };

  return (
    <Modal isOpen={isOpen} size="xl">
      <ModalBody>
        <PreviewInformationCard values={values} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => toggle()}>
          Edit
        </Button>
        <Button
          color={submitting ? "secondary" : "warning"}
          onClick={() => setSubmitModal(true)}
        >
          {submitting ? "Submitting" : "Submit"}
        </Button>
      </ModalFooter>
      {submitModal && (
        <SubmitModalWithForm
          isOpen={async () => {
            await submitModal();
            console.log(result);
            return result.status;
          }}
          onClose={(val: any) => {
            setSubmitModal(false);
            toggle();
          }}
          onSubmit={createCard}
          size="xs"
          header="Create Information Card"
          sucessView={<div>SHE Card Number: {result?.data?.cardNumber}</div>}
        />
      )}
    </Modal>
  );
}
