import { useMsal } from '@azure/msal-react'
import { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { HiOutlineSquares2X2, HiSquares2X2 } from 'react-icons/hi2'

import CMTable from '../components/Tables/CMTable'
import CorrectiveMeasureForm from './CorrectiveMeasureForm'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import SectionHeader from '../components/Layout/SectionHeader'
import theme from '../components/constants/defaultTheme.json'
import toNormalCase from '../components/lib/toNormalCase'
import { Badge } from 'reactstrap'
import { capitalizeFirstLetter } from '../components/lib/capitalizeFirstLetter'
import { CMStatuses, cmTableHeaders } from './ViewCM'
import { getAllCMMSWO } from '../components/serverurl'
import { getPermissions } from '../components/lib/permissions'
import { makeAuthenticatedRequest } from '../apiRequests'
import { UserContext } from '../App'

export const departments = {
  MECHANICAL: 'Mechanical',
  ELECTRICAL: 'Electrical',
  INSTRUMENT: 'Instrument',
  PRODUCTION: 'Producntion',
  HSE: 'Hse',
  ADMINWAREHOUSE: 'Admin Ware House'
}

const SidePaneItem = ({ type, activeItem, setActiveItem, count }: any) => {
  return (
    <div
      key={type}
      style={{
        border:
          activeItem === type ? `2px solid ${theme.borderColor.white}` : 'none',
        display: 'flex',
        padding: activeItem === type ? 5 : 7,
        backgroundColor:
          activeItem === type ? theme.colors.blue : 'transparent',
        borderRadius: 5,
        minWidth: '18vh',
        cursor: 'pointer',
        marginBottom: 35,
        position: 'relative',
        marginRight: 12,
        boxShadow:
          'rgba(0, 0, 0, 0.4) 0px 1px 2px, rgba(0, 0, 0, 0.3) 0px 2px 3px -3px, rgba(0, 0, 0, 0.2) 0px -2px 0px inset'
      }}
      onClick={() => setActiveItem(type)}
    >
      {activeItem === type ? (
        <HiSquares2X2
          style={{
            marginTop: 4,
            color:
              activeItem === type ? theme.colors.white : theme.colors.lightBlue
          }}
        />
      ) : (
        <HiOutlineSquares2X2
          style={{
            marginTop: 4,
            color:
              activeItem === type ? theme.colors.white : theme.colors.lightBlue
          }}
        />
      )}
      <p
        style={{
          margin: '0px 0px 0px 10px',
          fontWeight: 700,
          color:
            activeItem === type
              ? theme.fontColor.white
              : theme.fontColor.lightBlue
        }}
      >
        {toNormalCase(type)}
      </p>
      {count > 0 && (
        <Badge
          pill
          color="danger"
          style={{
            position: 'absolute',
            right: '-11px',
            top: '-15px',
            color: '#fff',
            height: 30,
            width: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <p style={{ margin: 0, fontSize: 16 }}>
            {count > 99 ? '99+' : count}
          </p>
        </Badge>
      )}
    </div>
  )
}

export function SidePane({ list, activeItem, setActiveItem }: any) {
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)

  const fetchCounts = async () => {
    const response = await makeAuthenticatedRequest(
      `${getAllCMMSWO}/counts?department=${capitalizeFirstLetter(
        userData?.department
      )}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data }: any = useQuery('fetchCMCounts', fetchCounts, {
    refetchInterval: 60000
  })

  const cmCounts = data?.counts

  const counts: any = {
    createNew: 0,
    verify: cmCounts?.created,
    approve: cmCounts?.verified,
    inProgress: cmCounts?.inProgress,
    waitingForShutdown: cmCounts?.waitingForShutdown,
    waitingForMaterial: cmCounts?.waitingForMaterial,
    waitingfor3rdParty: cmCounts?.waitingParty,
    closed: 0,
    rejected: cmCounts?.rejected,
    todo: cmCounts?.todo
  }

  return (
    <>
      <div style={{ paddingLeft: 10, paddingRight: 10 }}>
        {list?.map((type: any) => (
          <SidePaneItem
            key={type}
            setActiveItem={setActiveItem}
            type={type}
            activeItem={activeItem}
            count={counts[type]}
          />
        ))}
      </div>
    </>
  )
}

export default function CMDashboard() {
  const { instance, accounts } = useMsal()
  const { userData } = useContext(UserContext)

  const sidePaneItems = [
    // 'waitingForShutdown',
    // 'waitingForMaterial',
    // 'waitingfor3rdParty',
    'closed',
    'rejected'
  ]

  const [data, setData]: any = useState([])
  const [permissions, setPermissions]: any = useState()
  const items = [
    ...(permissions?.canCreateCM ? ['createNew'] : []),
    ...(permissions?.canVerifyCM ? ['verify'] : []),
    ...(permissions?.canApproveCM ? ['approve'] : []),
    ...(permissions?.canViewInprogress ? ['todo'] : []),
    ...(permissions?.canViewInprogress ? ['inProgress'] : []),
    ...(permissions?.canViewShutdown ? ['waitingForShutdown'] : []),
    ...(permissions?.canView3Party ? ['waitingfor3rdParty'] : []),
    ...(permissions?.canViewMaterial ? ['waitingForMaterial'] : []),
    ...sidePaneItems
  ]
  const [active, setActive]: any = useState(items[1])

  const getCurrentStatus = () => {
    switch (active) {
      case 'verify':
        return 'Created'
      case 'approve':
        return 'verified'
      case 'todo':
        return 'approved'
      case 'inProgress':
        return 'inProgress'
      case 'waitingForShutdown':
        return CMStatuses.WAITINGFORSHUTDOWN
      case 'waitingfor3rdParty':
        return CMStatuses.WAITINGFOR3RDPARTY
      case 'waitingForMaterial':
        return CMStatuses.WAITINGFORMATERIAL
      case 'closed':
        return CMStatuses.CLOSED
      case 'rejected':
        return CMStatuses.REJECTED
      default:
        break
    }
  }

  const getDepartment = () => {
    if (
      userData?.department === 'Production' ||
      userData?.department === 'PRODUCTION'
    ) {
      return undefined
    }
    if (userData?.department === 'WAREHOUSE') {
      return 'Admin Ware House'
    }
    if (userData?.department === 'HSE') {
      return 'HSE'
    } else return capitalizeFirstLetter(userData?.department)
  }

  useEffect(() => {
    try {
      makeAuthenticatedRequest(
        `${getAllCMMSWO}?status=${getCurrentStatus()}&department=${getDepartment()}`,
        'GET',
        null,
        accounts,
        instance
      ).then((response: any) => setData(response?.data?.permits))
      if (!permissions) {
        getPermissions(accounts, instance).then((response: any) =>
          setPermissions(response)
        )
      }
    } catch (err) {
      console.error(err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  const createCMData: any = localStorage.getItem('createCM')

  useEffect(() => {
    if (createCMData) {
      setActive('Create New')
    }
  }, [createCMData])

  const isCreateNew = active === 'createNew'

  const ViewPermits = () => (
    <div style={{ margin: 10 }}>
      <SectionHeader title={`Corrective Maintenance ${active}`.toUpperCase()} />
      <hr />
      <CMTable headers={cmTableHeaders} data={data} />
    </div>
  )

  return (
    <ProtectedLayout
      hasSideList
      hasUserProfile
      onBack="/cmms/services"
      sidePanel={
        <SidePane list={items} activeItem={active} setActiveItem={setActive} />
      }
    >
      {isCreateNew ? (
        <>
          <CorrectiveMeasureForm initalData={JSON.parse(createCMData)} />
        </>
      ) : (
        <ViewPermits />
      )}
    </ProtectedLayout>
  )
}
