import moment from 'moment'
import { Button, Col, Row } from 'reactstrap'
import { useRef, useState } from 'react'
import QRCode from 'qrcode.react'

import Accknowledgements from './previews/ViewAccknowledgements'
import BPUTable from './Tables/PBUTable'
import GasReadingsTable from './Tables/GasReadingsTable'
import ReactToPrint from 'react-to-print'
import Spacer from './Spacer'
import theme from './../components/constants/defaultTheme.json'
import toNormalCase from './lib/toNormalCase'
import ToolBoxMeeting from './ToolBoxTalkMeeting'
import ViewConfinedSpace from './previews/ViewConfinedSpace'
import ViewExcavation from './previews/ViewExcavationPermit'
import ViewGasReadings from './previews/ViewGasReadings'
import ViewIsolations from './previews/ViewIsolations'
import ViewPBUReadings from './previews/ViewPBUReadings'
import ViewPermitDetails, { permitStatuses } from './previews/ViewPermitdetails'
import ViewPositiveIsolation from './previews/ViewPositiveIsolation'
import ViewRiskAssesment from './previews/ViewRiskAssesment'
import ViewVehiclePermit from './previews/ViewVehiclePermit'
import { PermitHeader, renderArray } from '../pages/WaitingForPIC'
import { ViewCloseOutDetails, ViewHistory } from '../pages/ViewCM'
import './css/print.css'
import { qrUrl } from './serverurl'
import PrintPermit from './prints/PrintPermit'
import { capitalizeFirstLetter } from './lib/capitalizeFirstLetter'
import ViewWorkAtHeight from './previews/ViewWorkAtHeight'

export function Text({ children, styles }: any) {
  return (
    <p style={{ margin: 0, lineHeight: 2, textOverflow: 'clip', ...styles }}>
      {children}
    </p>
  )
}

type PreviewFormType = {
  attachments?: any
  gasReadings?: any
  isolationHistory?: any
  isolationValues?: any
  pbuData?: any
  permitHistory?: any
  riskAssesmentHistory?: any
  riskAssesmentValues?: any
  selectedPermit?: any
  showStatus?: any
  refetch?: any
  viewNextPermit?: any
  riskAssesment?: any
  report?: any
}

export const toNormalDate = (date: any) => moment(date).format('DD-MM-YYYY')

export const getPermitHeaderDetails = (selectedPermit: any) => ({
  'Permit Number': selectedPermit?.woNumber,
  'Permit Type': toNormalCase(selectedPermit?.type),
  'Start Date': toNormalDate(selectedPermit?.startDate),
  'End Date': toNormalDate(selectedPermit?.endDate)
  // Status: <b>{toNormalCase(selectedPermit?.status)}</b>
})

export const getUserData = (selectedPermit: any) => ({
  Name: selectedPermit?.applicantName,
  Designation: selectedPermit?.applicantDesignation,
  Department: selectedPermit?.applicantDepartment,
  'Employee Id': selectedPermit?.employeeId
})

export const permitColor: any = (permitType: any) =>
  permitType === 'hotWork'
    ? theme.workPermit.hotWork
    : theme.workPermit.coldWork

const renderTitle = (title: string) => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <Text>
      <b>{title}</b>
    </Text>
    <Text>:</Text>
  </div>
)

export const rowStyle: any = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'auto',
  paddingTop: 5,
  paddingBottom: 5,
  fontSize: 16,
  WebkitPrintColorAdjust: 'exact'
}

export default function ViewAndApprovePermit({
  attachments,
  gasReadings,
  isolationHistory,
  isolationValues,
  pbuData,
  permitHistory,
  riskAssesmentHistory,
  riskAssesmentValues,
  selectedPermit,
  showStatus,
  refetch,
  report
}: PreviewFormType) {
  const permitType = selectedPermit?.type
  const [print, setPrtint]: any = useState(false)

  const defaultStyles: any = {
    ...rowStyle,
    backgroundColor: permitColor(permitType)
  }

  const renderRow = (title: string, content?: any) => (
    <div key={title}>
      <Row>
        <Col
          xs="3"
          style={{
            backgroundColor:
              permitType === 'hotWork'
                ? theme.workPermit.hotWork
                : theme.workPermit.coldWork,
            WebkitPrintColorAdjust: 'exact'
          }}
        >
          {renderTitle(title)}
        </Col>
        <Col xs="9">
          <Text>{content || 'NA'}</Text>
        </Col>
      </Row>
    </div>
  )

  const data: any = {
    System: selectedPermit?.system,
    'Location of Work': selectedPermit?.workLocation,
    'Location Others': renderArray(selectedPermit?.locationOthers, false),
    'Equipment Involved': renderArray(selectedPermit?.equipment, false),
    'Equipment Others': selectedPermit?.othersEquipmentNumbers,
    // ...(selectedPermit?.type === 'coldWork'
    //   ? {}
    //   : {
    //       'Any Conflict Permit': selectedPermit?.otherPermitNo
    //     }),
    'Section / Department': renderArray(selectedPermit?.department, false),
    // 'Conflict Permit Number': selectedPermit?.otherPermitNumber,
    'Risk Assessment Type': selectedPermit?.isJSA,
    'Isolation Attached': isolationValues?.length > 0 ? 'Yes' : 'No',
    'Equipment Preparation': renderArray(
      selectedPermit?.equipmentPreparation,
      false
    ),
    'Equipment Preparation Others': selectedPermit?.othersEquipmentPreparation,
    ...(selectedPermit?.type === 'coldWork'
      ? {}
      : {
          'Equipment Protection and Stand by': renderArray(
            selectedPermit?.protectStdByEquipment,
            false
          )
        }),
    ...(selectedPermit?.type === 'coldWork'
      ? {}
      : {
          'Equipment Protection Others ':
            selectedPermit?.othersProtectStdByEquipment
        }),
    'PPE Required': renderArray(selectedPermit?.ppeRequirement, false),
    'PPE Requires Others': selectedPermit?.othersPpeRequirement,
    'Mobile Machinery': renderArray(selectedPermit?.machinery, false),
    'Machinery and Tools Others': selectedPermit?.othersTools,
    'Safety Watch Required': selectedPermit?.watchRequired,
    'HSE Assistance Required': selectedPermit?.riskAssessment,
    'Special Precautions': selectedPermit?.specialInstructions,
    ...(selectedPermit?.type === 'coldWork'
      ? {}
      : { 'Gas Measurements': selectedPermit?.gasMeasurements }),
    Status: (
      <b>
        {permitStatuses?.[selectedPermit?.status] || selectedPermit?.status}
      </b>
    ),
    'Will permit applicant be the job performer for this job':
      selectedPermit?.teamType,
    ...(selectedPermit?.teamType === 'Yes'
      ? {
          'Team Members': renderArray(
            selectedPermit?.teamMembers?.map((t: any) => t?.name),
            false
          )
        }
      : {}),
    ...(selectedPermit?.teamType === 'No'
      ? {
          'Vendor Team Members': renderArray(
            selectedPermit?.teamMembers?.map((t: any) => t?.name),
            false
          )
        }
      : {})
  }

  const headerDetails: any = getPermitHeaderDetails(selectedPermit)

  const userDetails: any = getUserData(selectedPermit)

  const titleAndDescription: any = {
    'Permit Title': selectedPermit?.permitTitle,
    'Work Description': selectedPermit?.workDescription
  }

  const componentRef: any = useRef()

  return (
    <>
      <div
        style={{
          fontSize: 14,
          fontFamily: 'Nunito, sans-serif',
          backgroundColor: '#FFF',
          padding: 2,
          marginLeft: 10,
          marginRight: 10
          // overflowX: 'auto',
          // overflow: 'auto'
        }}
        ref={componentRef}
        className="print-page-break"
      >
        <Row>
          <Col>
            <PermitHeader permitType={permitType} />
          </Col>
        </Row>
        <Row
          style={{
            ...defaultStyles,
            fontSize: 14,
            flexWrap: 'wrap',
            margin: 0
          }}
        >
          <Col xs="12" md="6">
            {Object.keys(headerDetails).map((key) =>
              renderRow(key, headerDetails[key])
            )}
          </Col>
          <Col xs="12" md="4">
            {Object.keys(userDetails).map((key) =>
              renderRow(key, userDetails[key])
            )}
          </Col>
          <Col
            xs="12"
            md="2"
            style={{ textAlign: 'end' }}
            className="d-none d-md-block"
          >
            <QRCode
              value={
                // 'http://localhost:3000/eptw/newPermit/approve/65277bd26793f1002308739e'
                `${qrUrl}/permit/${selectedPermit?._id}`
              }
              size={128}
            />
          </Col>
        </Row>
        <Spacer height={10} />
        <Row style={{ margin: 0 }}>
          {Object.keys(titleAndDescription).map((key) =>
            renderRow(key, titleAndDescription[key])
          )}
        </Row>
        <ViewPermitDetails
          rowStyle={defaultStyles}
          data={data}
          renderRow={renderRow}
          showStatus={showStatus}
        />
        {gasReadings?.length > 0 && (
          <ViewGasReadings data={gasReadings} rowStyle={defaultStyles} />
        )}
        {permitHistory?.length > 0 && (
          <ViewHistory
            data={permitHistory}
            title="Permit Approval History"
            permitColor={permitColor(permitType)}
          />
        )}
        <div>
          {selectedPermit?.isJSA && (
            <ViewRiskAssesment
              permitData={selectedPermit}
              data={
                riskAssesmentValues?.new
                  ? riskAssesmentValues
                  : riskAssesmentValues?.template
              }
              rowStyle={defaultStyles}
              renderRow={renderRow}
            />
          )}
          {riskAssesmentHistory?.length > 0 &&
            selectedPermit?.isJSA &&
            selectedPermit?.riskAssesmentPermit && (
              <ViewHistory
                data={riskAssesmentHistory}
                title="Risk Assessment Approval History"
                permitColor={permitColor(permitType)}
              />
            )}
        </div>
        <div className="print-page-break">
          {isolationValues?.length > 0 && (
            <ViewIsolations
              data={
                selectedPermit?.Isolations ||
                selectedPermit?.isolations?.isolations
              }
              permitData={selectedPermit}
              rowStyle={defaultStyles}
              renderRow={renderRow}
            />
          )}
          {pbuData?.length > 0 && (
            <ViewPBUReadings data={pbuData} rowStyle={defaultStyles} />
          )}
          {isolationValues?.length > 0 && isolationHistory?.length > 0 && (
            <ViewHistory
              data={isolationHistory}
              title="Isolations Approval History"
              permitColor={permitColor(permitType)}
            />
          )}
        </div>
        <div className="print-page-break">
          {attachments?.vehicle && (
            <ViewVehiclePermit
              permitData={selectedPermit}
              data={attachments?.vehicle}
              rowStyle={defaultStyles}
              renderRow={renderRow}
            />
          )}
          {attachments?.excavation && (
            <ViewExcavation
              permitData={selectedPermit}
              data={attachments?.excavation}
              rowStyle={defaultStyles}
              renderRow={renderRow}
            />
          )}
          {attachments?.positiveIsolation && (
            <ViewPositiveIsolation
              permitData={selectedPermit}
              data={attachments?.positiveIsolation}
              rowStyle={defaultStyles}
              renderRow={renderRow}
            />
          )}
          {attachments?.confinedSpace && (
            <ViewConfinedSpace
              permitData={selectedPermit}
              data={attachments?.confinedSpace}
              rowStyle={defaultStyles}
              renderRow={renderRow}
            />
          )}
          {attachments?.workAtHeight && (
            <ViewWorkAtHeight
              permitData={selectedPermit}
              data={attachments?.workAtHeight}
              rowStyle={defaultStyles}
              renderRow={renderRow}
            />
          )}
        </div>
        {selectedPermit?.consent && (
          <Accknowledgements
            data={selectedPermit?.consent}
            rowStyle={defaultStyles}
          />
        )}
        {/* <div className="print-page-break">
          {selectedPermit?.status === 'live' && (
            <ToolBoxMeeting
              rowStyle={defaultStyles}
              selectedPermit={selectedPermit}
            />
          )}
        </div> */}
        <div>
          {selectedPermit?.status === 'live' && (
            <GasReadingsTable
              rowStyle={defaultStyles}
              measureMents={selectedPermit?.gasMeasurements}
            />
          )}
        </div>
        <div>
          {selectedPermit?.status === 'live' && (
            <BPUTable rowStyle={defaultStyles} isolations={isolationValues} />
          )}
        </div>
        {selectedPermit?.status === 'closed' && report && (
          <ViewCloseOutDetails data={report} epermit />
        )}
        {(selectedPermit?.status === 'live' ||
          selectedPermit?.status === 'closed') && (
          <Row style={{ margin: 0 }}>
            <Button color="warning" onClick={() => setPrtint(true)}>
              Print
            </Button>
          </Row>
        )}
        {print && (
          <PrintPermit
            isOpen={print}
            toggle={() => setPrtint(!print)}
            attachments={attachments}
            gasReadings={gasReadings}
            isolationHistory={isolationHistory}
            isolationValues={isolationValues}
            pbuData={pbuData}
            permitHistory={permitHistory}
            riskAssesmentHistory={riskAssesmentHistory}
            riskAssesmentValues={riskAssesmentValues}
            selectedPermit={selectedPermit}
            showStatus={showStatus}
            report={report}
          />
        )}
      </div>
    </>
  )
}
