import { Row } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import AshokLeylandLayout from './AshokLeylandLayout'
import { alEquipmentApi } from '../components/serverurl'
import { IconCard } from '../components/Dashboard/IconCard'
import { makeAuthenticatedRequest } from '../apiRequests'

export default function AlPmActions() {
  const { accounts, instance } = useMsal()

  const fetchReport = async () =>
    await makeAuthenticatedRequest(
      `${alEquipmentApi}/workorders/counts`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data }: any = useQuery('Al PM Work Order Counts', fetchReport)
  const counts = data?.data?.countsByStatus
  const flows: any = [
    {
      name: 'PM JOB PLANS LIST',
      nav: 'pm/list',
      src: require('./../images/Icons/pmList.png')
    },
    {
      name: 'CREATE NEW PM',
      nav: 'pm/create',
      src: require('./../images/Icons/createNewPm.png')
    },
    {
      name: 'PM JOB PLANS ',
      nav: 'pm/joblist',
      src: require('./../images/Icons/pmJobPlans.png')
    },
    {
      name: 'UPDATE RUNNING HOURS',
      nav: 'pm/runningHours',
      src: require('./../images/Icons/updateRunningHours.png')
    }
  ]

  return (
    <>
      <AshokLeylandLayout
        onBack="/ashokleyland/mms"
        title="PREVENTIVE MAINTENANCE"
      >
        <Row style={{ margin: 10, fontSize: 20 }}>
          {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
            <IconCard
              key={`${index}-${name}`}
              name={name}
              nav={nav}
              canView={canView}
              count={count}
              src={src}
            />
          ))}
        </Row>
      </AshokLeylandLayout>
    </>
  )
}
