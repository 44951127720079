import React from 'react'
import { Input } from 'reactstrap'
import { Text } from '../../components/RiskAssesmentPreview'
import './../../../src/components/css/tree.css'

const treeData = [
  {
    id: '1',
    diamond: false,
    component: (
      <>
        <Text>
          Under normal condition do the operators know that something has
          Occured?
        </Text>
        <Input type="checkbox" />
      </>
    ),
    children: [
      {
        id: '2',
        component: (
          <>
            <Text>
              <b>Yes</b>
            </Text>
            <Text>Does the failure mode cause a failure problem ?</Text>
            <Input type="checkbox" />
          </>
        ),
        diamond: false,
        children: [
          {
            id: '3',
            component: (
              <>
                <Text>
                  <b>Yes</b>
                </Text>
                <Text>Safety Problem (S)</Text>
              </>
            ),
            diamond: false
          },
          {
            id: '5',
            component: (
              <>
                <Text>
                  <b>No</b>
                </Text>
                <Text>
                  Does the failure mode cause a environmental problem ?
                </Text>
                <Input type="checkbox" />
              </>
            ),
            diamond: false,
            children: [
              {
                id: '6',
                component: (
                  <>
                    <Text>
                      <b>Yes</b>
                    </Text>
                    <Text>Environmental Problem (E)</Text>
                  </>
                ),
                diamond: false
              },
              {
                id: '4',
                component: (
                  <>
                    <Text>
                      <b>No</b>
                    </Text>
                    <Text>
                      Does the failure mode result in a full or partial outage
                      of plant ?
                    </Text>
                    <Input type="checkbox" />
                  </>
                ),
                diamond: false,
                children: [
                  {
                    id: '8',
                    component: (
                      <>
                        <Text>
                          <b>Yes</b>
                        </Text>
                        <Text>Outage Problem (O)</Text>
                      </>
                    ),
                    diamond: false
                  },
                  {
                    id: '9',
                    component: (
                      <>
                        <Text>
                          <b>No</b>
                        </Text>
                        <Text>
                          Mirror to insignificant economic Problem (N)
                        </Text>
                      </>
                    ),
                    diamond: false
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id: '7',
        component: (
          <>
            <Text>
              <b>No</b>
            </Text>
            <Text>Hidden Failure (H)</Text>
          </>
        ),
        diamond: false
      }
    ]
  }
]

const RcmConsequenceAnalysis = () => {
  return <div className="tree">{treeRendering(treeData)}</div>
}

export const treeRendering = (treeData: any) => {
  return (
    <>
      <ul>
        {treeData.map((item: any) => (
          <li className={item.text + item.id}>
            <div>{item.component}</div>
            {item.children && item.children.length
              ? treeRendering(item.children)
              : ''}
          </li>
        ))}
      </ul>
    </>
  )
}

export default RcmConsequenceAnalysis
