import { Button, Input, ModalFooter, Row, Spinner, Table } from "reactstrap";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import ProtectedLayout from "../../components/Layout/ProtectedLayout";
import { incrementby1 } from "../../components/Forms/JSAForm";
import { Link } from "./../workManagement/PmTree";
import { makeAuthenticatedRequest } from "../../apiRequests";
import { stock } from "../../components/serverurl";
import { useState } from "react";

const StockTable = ({
  data,
  isLoading,
  isRefetching,
}: {
  data: any;
  isLoading: any;
  isRefetching: any;
}) => {
  const navigate = useNavigate();
  const [selectedSpares, setSelectedSpares]: any = useState([]);

  const redirectToPRForm = async () => {
    const spares = selectedSpares;
    await localStorage.setItem("selectedSpare", JSON.stringify(spares));
    navigate("/purchase/create");
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <td></td>
            <th>S.No</th>
            <th>Spare Part Number</th>
            <th>Part Description</th>
            <th>Spare Type</th>
            <th>Equipment</th>
            <th>UOM</th>
            <th>Qty on Hand</th>
            <th>Price</th>
            <th>Currency</th>
            <th>Manufacturer</th>
            <th>Warehouse</th>
            <th>Location Type</th>
            <th>Location Number</th>
            <th>Location Group</th>
            <th>Bin</th>
          </tr>
        </thead>
        <tbody>
          <>
            {isLoading || isRefetching ? (
              <tr>
                <td colSpan={12}>
                  <Row
                    style={{
                      height: 500,
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner />
                  </Row>
                </td>
              </tr>
            ) : (
              data?.length > 0 &&
              data?.map((s: any, index: number) => (
                <tr key={s?._id}>
                  <td>
                    <Input
                      type="checkbox"
                      checked={
                        selectedSpares?.filter(
                          (spare: any) =>
                            spare["Spare Part Number"] ===
                            s?.["Spare Part Number"]
                        )?.length > 0
                      }
                      onClick={(e: any) =>
                        e?.target?.checked
                          ? setSelectedSpares([...selectedSpares, s])
                          : setSelectedSpares(
                              selectedSpares?.filter(
                                (sp: any) =>
                                  sp?.["Spare Part Number"] !==
                                  s?.["Spare Part Number"]
                              )
                            )
                      }
                    />
                  </td>
                  <td>{incrementby1(index)}</td>
                  <td>
                    <Link
                      onClick={() =>
                        navigate(
                          `/cmms/supplychain/wareHouse/stockRegister/${s?._id}`
                        )
                      }
                    >
                      {s?.["Spare Part Number"]}
                    </Link>
                  </td>
                  <td>{s?.["Spare Part Description"]}</td>
                  <td>{s?.spareType}</td>
                  <td>{s?.["Tag Number"]}</td>
                  <td>{s?.uom}</td>
                  <td>{s?.onHandQuantity}</td>
                  <td>{s?.priceIncludingTax}</td>
                  <td>{s?.priceCurrency}</td>
                  <td>{s?.manufacturer}</td>
                  <td>{s?.Warehouse}</td>
                  <td>{s?.locationType}</td>
                  <td>{s?.locationNumber}</td>
                  <td>{s?.locationGroup}</td>
                  <td>{s?.bin}</td>
                </tr>
              ))
            )}
          </>
        </tbody>
      </Table>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: "100%",
            margin: 0,
          }}
        >
          <p
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No Stock Found,
            <span
              style={{
                color: "blue",
                textDecoration: "underline",
                marginLeft: 2,
                cursor: "pointer",
              }}
              onClick={
                () => {}
                // navigate('/workManagement/correctiveMaintenance/create')
              }
            >
              Add one?
            </span>
          </p>
        </Row>
      )}
      <ModalFooter>
        <Button color="warning" onClick={redirectToPRForm}>
          Create PR
        </Button>
      </ModalFooter>
    </>
  );
};

export default function StockRegister() {
  const { accounts, instance } = useMsal();

  const fetchStock = async () =>
    await makeAuthenticatedRequest(
      `${stock}/inventory`,
      "GET",
      null,
      accounts,
      instance
    );

  const { data, isLoading, isRefetching }: any = useQuery("Stock", fetchStock);

  return (
    <ProtectedLayout
      onBack="/cmms/supplychain/warehouse"
      title="WARE HOUSE / STOCK REGISTER"
    >
      <Row style={{ margin: 15 }}>
        <StockTable
          data={data?.inventoryList}
          isLoading={isLoading}
          isRefetching={isRefetching}
        />
      </Row>
    </ProtectedLayout>
  );
}
