/* eslint-disable no-loop-func */
import { Button, Col, Input, ModalFooter, Row, Table } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import RcmLayout from '../../components/rcm/RcmLayout'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { Box } from '../workManagement/PmTree'
import { taskInterval } from './rcmConstants'
import { getRcmRiskColor } from './rcmLib'
import { LoadingState } from './RcmViewStage4'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import { RcmBasicDetails } from './RcmViewStage1'
import { SubHeader } from '../VerifyCM'
import RcmStepper from './RcmStepper'

export default function RcmViewStage9() {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchRcm', fetchRcm)

  const fetchRiskMatrix = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/riskMatrix`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data: riskData, isLoading }: any = useQuery(
    'fetchRiskMatrix',
    fetchRiskMatrix
  )
  const riskMatrixData = riskData?.riskMatrix?.[0]
  const riskToleranceLevel = Number(riskMatrixData?.riskToleranceLevel) || 16

  const [submit, setSubmit]: any = useState(false)
  const [result, setResult]: any = useState({})

  const currentAnalysisData = data?.analysis
  const hasCurrentRcm = currentAnalysisData?.number
  const riskDetails = currentAnalysisData?.riskDetails || {}
  const categorizedEquipment = currentAnalysisData?.categorizedEquipment

  const [entries, setEntries] = useState(riskDetails)
  const [updateStage, setUpdateStage]: any = useState(false)

  useEffect(() => {
    if (Object.keys(entries)?.length <= 0) {
      setEntries(riskDetails)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.analysis?.failuresModes, riskDetails])

  if (isLoading) {
    return <LoadingState />
  }

  const handleTaskInputChange = (
    categoryKey: any,
    failureIndex: number,
    taskIndex: number,
    field: string,
    value: any
  ) => {
    setEntries((prev: any) => {
      const updatedEntries = { ...prev }

      const currentCategory = updatedEntries[categoryKey]
      if (currentCategory && currentCategory[failureIndex]?.tasks) {
        const currentFailureMode = { ...currentCategory[failureIndex] }

        const updatedTasks = [...currentFailureMode.tasks]
        const updatedTask = { ...updatedTasks[taskIndex], [field]: value }
        updatedTasks[taskIndex] = updatedTask

        currentFailureMode.tasks = updatedTasks
        currentCategory[failureIndex] = currentFailureMode

        updatedEntries[categoryKey] = currentCategory

        return updatedEntries
      }
      return prev
    })
  }

  const calculateTotalCost = (tasks: any[], key: any) => {
    return tasks.reduce((sum, task) => {
      const yearlyMaintenance = Number(task.yearlyMaintenance) || 0
      const intervalValue = taskInterval[task?.[key]] || 1
      return sum + yearlyMaintenance * intervalValue
    }, 0)
  }

  const getRatio = (failureMode: any, key: any) => {
    const economicEffect = Number(failureMode?.economicEffect) || 0
    const totalMaintenanceCost = calculateTotalCost(failureMode.tasks, key)

    return economicEffect / totalMaintenanceCost
  }

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15, maxWidth: '87vw' }}>
        <Col>
          <h4
            style={{ fontWeight: 700 }}
            children={`${hasCurrentRcm ? currentAnalysisData?.number : ''}`}
          />
          <RcmStepper stage={currentAnalysisData?.status} />
          <Spacer height={30} />
          <RcmBasicDetails data={currentAnalysisData} />
          <Spacer height={20} />
          <h4 style={{ fontWeight: 700 }} children="Functions" />
          <div
            style={{
              backgroundColor: '#fff',
              margin: 0,
              padding: 10,
              borderRadius: 10
            }}
          >
            {Object.keys(riskDetails).map(
              (mode: any, modeIndex: number) =>
                riskDetails?.[mode]?.some(
                  (entry: any) => Number(entry.riskScore) > riskToleranceLevel
                ) && (
                  <div key={modeIndex}>
                    {entries?.[mode]?.map(
                      (failureMode: any, failureIndex: any) => (
                        <React.Fragment key={failureMode?._id}>
                          <Table
                            bordered
                            style={{ borderColor: '#000', border: '1px solid' }}
                          >
                            <tbody>
                              <tr>
                                <td rowSpan={2} style={{ width: '15vw' }}>
                                  <h4>{mode}</h4>
                                  <p className="m-0">
                                    <strong>Associated Equipment:</strong>{' '}
                                    {categorizedEquipment[mode]
                                      ?.map((a: any) => a?.equipmentNumber)
                                      ?.join(', ')}{' '}
                                  </p>
                                  <p>
                                    <strong>Functions:</strong>{' '}
                                    {currentAnalysisData?.functions?.[mode]}
                                  </p>
                                </td>
                                <td
                                  rowSpan={2}
                                  className="align-content-center"
                                  style={{ width: '15vw' }}
                                >
                                  <h4 className="m-0">Failure Mode</h4>
                                  <p className="m-0">
                                    {failureMode?.failureMode}
                                  </p>
                                </td>
                                <td
                                  colSpan={3}
                                  className="text-center"
                                  style={{
                                    fontWeight: 700,
                                    border: '1px solid #000'
                                  }}
                                >
                                  {' '}
                                  Old Risk
                                </td>
                                <td
                                  colSpan={3}
                                  className="text-center"
                                  style={{
                                    fontWeight: 700,
                                    border: '1px solid #000'
                                  }}
                                >
                                  {' '}
                                  New Risk
                                </td>
                              </tr>
                              <tr>
                                {[
                                  {
                                    value: failureMode?.healthSafetyScore,
                                    label: 'HS'
                                  },
                                  {
                                    value: failureMode?.environmentScore,
                                    label: 'EV'
                                  },
                                  {
                                    value: failureMode?.economicScore,
                                    label: 'EC'
                                  },
                                  {
                                    value: failureMode?.newHealthScore,
                                    label: 'HS'
                                  },
                                  {
                                    value: failureMode?.newEnvironmentScore,
                                    label: 'EV'
                                  },
                                  {
                                    value: failureMode?.newEcoScore,
                                    label: 'EC'
                                  }
                                ]?.map((s: any, index: any) => (
                                  <td
                                    key={`${s}-${s?.label}-${index}`}
                                    style={{ width: '8vw' }}
                                    className="text-center align-content-center"
                                  >
                                    <p
                                      className="m-0"
                                      style={{
                                        textAlign: 'center',
                                        alignContent: 'center',
                                        height: '5vh',
                                        backgroundColor: getRcmRiskColor(
                                          s?.value
                                        ),
                                        color: '#fff',
                                        fontWeight: 600
                                      }}
                                    >
                                      {s?.label}: {s?.value}
                                    </p>
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </Table>
                          <Table striped style={{ borderColor: '#000' }}>
                            <thead>
                              <tr
                                className="text-center"
                                style={{ backgroundColor: 'lightgray' }}
                              >
                                <th className="top-left">S.No</th>
                                <th>Task Name</th>
                                <th>Task Type</th>
                                <th>Compliance</th>
                                <th>Tags</th>
                                <th>Current Task Interval</th>
                                <th>Clamp Recomended</th>
                                <th>Client Decision</th>
                                <th className="top-right">Remarks</th>
                              </tr>
                            </thead>
                            <tbody>
                              {failureMode?.tasks?.length > 0 &&
                                failureMode?.tasks?.map(
                                  (task: any, taskIndex: number) => (
                                    <>
                                      <tr
                                        className="text-center"
                                        key={taskIndex}
                                      >
                                        <td>{taskIndex + 1}</td>
                                        <td style={{ textAlign: 'left' }}>
                                          {task.taskname}
                                        </td>
                                        <td>{task.taskType}</td>
                                        <td>{task.compliance}</td>
                                        <td>
                                          {task?.tags
                                            ?.map(
                                              (t: any) =>
                                                t?.itemNumber || t?.tagNo
                                            )
                                            ?.join(', ')}
                                        </td>
                                        <td>
                                          {task?.givenTaskInterval ||
                                            task?.taskInterval}
                                        </td>
                                        <td>{task?.recommendedInterval}</td>
                                        <td>
                                          <Input
                                            type="select"
                                            value={
                                              task?.clientDecision || 'Select'
                                            }
                                            onChange={(e: any) =>
                                              handleTaskInputChange(
                                                mode,
                                                failureIndex,
                                                taskIndex,
                                                'clientDecision',
                                                e?.target?.value
                                              )
                                            }
                                          >
                                            <option>Select</option>
                                            {['Retained', 'Deleted']?.map(
                                              (a: any) => (
                                                <option key={a} value={a}>
                                                  {a}
                                                </option>
                                              )
                                            )}
                                          </Input>
                                        </td>
                                        <td>
                                          <Input
                                            type="textarea"
                                            value={task?.remarks}
                                            style={{ height: 40 }}
                                            onChange={(e: any) =>
                                              handleTaskInputChange(
                                                mode,
                                                failureIndex,
                                                taskIndex,
                                                'remarks',
                                                e?.target?.value
                                              )
                                            }
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  )
                                )}
                            </tbody>
                          </Table>
                        </React.Fragment>
                      )
                    )}
                  </div>
                )
            )}
          </div>

          <SubmitModalWithForm
            isOpen={submit}
            onClose={(val: any) => {
              setSubmit(false)
            }}
            onSubmit={async (e: any) => {
              e?.preventDefault()

              const body: any = {
                ...currentAnalysisData,
                riskDetails: entries,
                status: 'Stage7'
              }

              const res = await makeAuthenticatedRequest(
                `${rcmApi}/rcmAnalysis/${id}`,
                'PATCH',
                body,
                accounts,
                instance
              )

              if (res.status === 'success') {
                await refetch()
                setResult(res?.analysis)
              }

              return res.status
            }}
            sucessView={<p>RCM Number: {result?.number}</p>}
            size="xs"
            header="Failure Modes"
          />

          <SubmitModalWithForm
            isOpen={updateStage}
            onClose={(val: any) => {
              setUpdateStage(false)
            }}
            onSubmit={async (e: any) => {
              e?.preventDefault()

              const body: any = {
                ...currentAnalysisData,
                riskDetails: entries,
                status: 'Stage9'
              }

              const res = await makeAuthenticatedRequest(
                `${rcmApi}/rcmAnalysis/${id}`,
                'PATCH',
                body,
                accounts,
                instance
              )

              if (res.status === 'success') {
                await refetch()
                setResult(res?.analysis)
              }

              return res.status
            }}
            sucessView={<p>RCM Number: {result?.number}</p>}
            size="xs"
            header="Move to Stage 9"
          />
        </Col>
      </Row>
      <ModalFooter>
        <Button
          color="warning"
          onClick={async () => {
            // await performAnalysis()
            setUpdateStage(true)
          }}
        >
          Submit
        </Button>
      </ModalFooter>
    </RcmLayout>
  )
}
