import { Route, Routes } from 'react-router-dom'

import ApproveRevalidation from '../pages/revalidation/ApproveRevalidation'
import IssueRevalidation from '../pages/revalidation/IssueRevalidation'
import IssueRevalidations from '../pages/revalidation/IssueRevalidations'
import MakeLiveRevalidation from '../pages/revalidation/MakeLiveRevalidation'
import MakeLiveRevalidations from '../pages/revalidation/MakeLiveRevalidations'
import VerifyRevalidations from '../pages/revalidation/VerifyRevalidations'
import FetchPermitsByLocation from '../pages/FetchPermitsByLocation'
import VerifyRevalidation from '../pages/revalidation/VerifyRevalidation'

export default function RevalidateRoutes() {
  return (
    <Routes>
      <Route path="/verify" element={<VerifyRevalidations />} />
      <Route path="/verify/:id" element={<VerifyRevalidation />} />
      <Route path="/issue" element={<IssueRevalidations />} />
      {/* <Route
        path="/approve"
        element={
          <FetchPermitsByLocation
            status="revalidationIssued"
            flow="revalidate"
          />
        }
      /> */}
      <Route path="/issue/:id" element={<IssueRevalidation />} />
      <Route
        path="/approve"
        element={
          <FetchPermitsByLocation
            status="revalidationIssued"
            flow="revalidate"
          />
        }
      />
      revalidationApproved
      <Route path="/approve/:id" element={<ApproveRevalidation />} />
      <Route path="/makeLive" element={<MakeLiveRevalidations />} />
      <Route path="/makeLive/:id" element={<MakeLiveRevalidation />} />
    </Routes>
  )
}
