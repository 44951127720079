import { Col, Row } from 'reactstrap'
import { workOrderStatuses } from '../constants/woStatuses'
import { ColoredSubHeader } from './ViewExcavationPermit'

export const permitStatuses: any = {
  draft: 'Draft',
  verified: 'Verified',
  Created: 'Created',
  initiateRevalidation: 'Initiated Revalid',
  revalidationVerified: 'Verified Revalid',
  revalidationIssued: 'Issued Revalid',
  revalidationApproved: 'Approved Revalid',
  live: 'LIVE',
  deIsolationAndCloseInitiated: 'Initated D&C',
  deIsolationAndCloseVerified: 'Verified D&C',
  deIsolatedForClose: 'DeIsolated',
  deIsolationAndCloseApproved: 'Approved D&C',
  cioInitiated: 'Initiated CIO',
  closed: 'CLOSED',
  ltiInitiated: 'Initiated LTI',
  ltiVerified: 'Verified LTI',
  ltiApproved: 'Approved LTI',
  ltiIssued: 'Issued LTI',
  'Verified woi': 'Verified WOI',
  isolated: 'Isolated',
  approved: 'Approved',
  rejected: 'Rejected',
  issued: 'Issued',
  created: 'Created',
  suspended: 'Suspended',
  overdue: 'Overdue',
  expired: 'Expired',
  ...workOrderStatuses
}

type ViewPermit = {
  data: any
  rowStyle: any
  renderRow: any
  showStatus: any
}

export default function ViewPermitDetails({
  data,
  rowStyle,
  renderRow,
  showStatus
}: ViewPermit) {
  return (
    <Row style={{ margin: 0 }}>
      <ColoredSubHeader rowStyle={rowStyle} text="Permit Details" />
      <Row>
        <Col xs="12" md="9">
          {Object.keys(data).map((key) => renderRow(key, data[key]))}
        </Col>
        <Col
          md="3"
          className="d-none d-md-flex"
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              writingMode: 'vertical-lr',
              height: 'inherit'
            }}
          >
            <div
              style={{
                fontSize: 80,
                WebkitTransform: 'rotateX(360deg)',
                transform: 'rotateX(360deg)',
                fontWeight: 900,
                textShadow:
                  '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'
              }}
            >
              <p style={{ color: rowStyle?.backgroundColor }}>
                {permitStatuses?.[data?.['Status']] || data?.['Status']}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Row>
  )
}
