import { Button, Col, ModalFooter, Row, Table } from 'reactstrap'
import { useQuery } from 'react-query'
import { useMsal } from '@azure/msal-react'

import RcmLayout from '../../components/rcm/RcmLayout'
import { SubHeader } from '../VerifyCM'
import { rcmApi } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export function ViewSelectedEquipmentDetails({
  nextStep,
  disabled,
  selectedTags,
  setSelectedTags
}: {
  nextStep?: any
  disabled?: any
  selectedTags?: any
  setSelectedTags?: any
}) {
  const { accounts, instance } = useMsal()

  const localStorageTags: any = localStorage.getItem('rcmTags')
  const tags: any = String(JSON.parse(localStorageTags))?.split(',')

  const getTagsData = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/tags`,
      'PATCH',
      tags,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchTagsAData', getTagsData)

  useEffect(() => {
    setSelectedTags(data?.tags?.map((t: any) => t?._id))
  }, [data?.tags])

  return (
    <Col xs={12}>
      <Table bordered className="min-vh-50" style={{ borderColor: '#000' }}>
        <thead>
          <tr>
            {[
              'S.No',
              'System Name',
              'System Number',
              'Equipment Number',
              'Criticality'
            ]?.map((h: any) => <th key={h}>{h}</th>)}
          </tr>
        </thead>
        <tbody>
          {data?.tags?.map((e: any, Eindex: number) => (
            <tr key={e?.value}>
              <td>{Eindex + 1}</td>
              <td>{e?.systemDescription}</td>
              <td>{e?.sytem}</td>
              <td>{e?.tag}</td>
              <td>{e?.criticality}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Col>
  )
}

export default function RcmPidEquipmentAnalysis() {
  const navigate = useNavigate()
  return (
    <RcmLayout onBack={'/cmms/rcm/systemBoundary'}>
      <Row style={{ margin: 15 }}>
        <Col xs={12}>
          <SubHeader header={'Equipments'} permitColor="gold" />
        </Col>
        <ViewSelectedEquipmentDetails />
        <Col xs={12}>
          <ModalFooter>
            <Button
              color="warning"
              onClick={() => navigate('/cmms/rcm/createAnalysis?step=1')}
            >
              Analyse
            </Button>
          </ModalFooter>
        </Col>
      </Row>
    </RcmLayout>
  )
}
