import ProtectedLayout from '../components/Layout/ProtectedLayout'
import OilStorageSVG from '../SVGs/OilStorageSVG'

export default function OilStorage() {
  return (
    <ProtectedLayout onBack="/eptw/dashboard">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 10,
          marginBottom: 10
        }}
      >
        <OilStorageSVG />
      </div>
    </ProtectedLayout>
  )
}
