import { Button, Row } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useContext, useState } from 'react'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { cioEndpoint } from '../../components/serverurl'
import React from 'react'
import { useAdminPermissions } from '../eptw/EditRisks'
import ViewPermit from '../eptw/ViewPermit'
import { RadioButtonGroup, yesNoOptions } from '../eptw/VerifyPermit'
import { PermissionsContext } from '../../App'

export default function MakeLiveCIO() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const { permissions } = useContext(PermissionsContext)

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const [submitModal, setSubmitModal]: any = useState(false)

  const [formData, setFormData]: any = useState({
    taskUnderstood: 'No',
    manpowerAlloted: 'No',
    correctSpare: 'No',
    documentsAttached: 'No'
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const submitValues: any = {
      consent: {
        ...formData
      },

      permitId: data?.permit?._id,
      consentId: data?.permit?.consent?._id
    }

    const response = await makeAuthenticatedRequest(
      `${cioEndpoint}/makeLive`,
      'PATCH',
      submitValues,
      accounts,
      instance
    )

    return response.status
  }

  const form = (
    <div>
      <RadioButtonGroup
        question="Understood the complete task of this permit and communicated to IM, PIC and AO ?"
        name="taskUnderstood"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Is the manpower allotted is sufficient to complete this Task ?"
        name="manpowerAlloted"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Is the correct spare taken from the stores if applicable ?"
        name="correctSpare"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Is all the documents attached to this Permit sufficient to complete this task ?"
        name="documentsAttached"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
    </div>
  )

  return (
    <ProtectedLayout onBack="/eptw/cio/makeLive">
      <ViewPermit data={data} isLoading={isLoading} />
      {data?.permit?.status === 'Approved CIO' &&
        permissions?.canMakeLiveCIO && (
          <>
            <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
              <Button
                color="warning"
                style={{ marginTop: 10 }}
                onClick={() => setSubmitModal(true)}
              >
                Make Permit Live
              </Button>
            </Row>
            {submitModal && (
              <SubmitModalWithForm
                isOpen={submitModal}
                onClose={(val: any) => {
                  setSubmitModal(false)
                  val && navigate('/eptw/cio/makeLive')
                }}
                onSubmit={handleSubmit}
                form={form}
              />
            )}
          </>
        )}
    </ProtectedLayout>
  )
}
