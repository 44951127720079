import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
  Table
} from 'reactstrap'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import React, { useContext, useState } from 'react'
import ReactPaginate from 'react-paginate'

import CreateNewPM from '../Modals/CreateNewPM'
import LabeledDateTimeInput from '../InputFields/DateTime'
import LabeledTextInput from '../InputFields/LabeledTextInput'
import ModalTitle from '../ModalTitle'
import moment from 'moment'
import MultiSelect from '../InputFields/MultiSelect'
import Spacer from '../Spacer'
import { EmptyContent } from '../../pages/CreateNewPermit'
import { getOptions } from '../../pages/firs'
import { getPMList } from '../serverurl'
import { incrementby1 } from '../Forms/JSAForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { useMsal } from '@azure/msal-react'
import { UserContext } from '../../App'
import CustomLabel from '../InputFields/CustomLabel'

export const TDLink = ({ onClick, children }: any) => {
  return (
    <td
      onClick={onClick}
      style={{
        color: 'blue',
        textDecoration: 'underline',
        cursor: 'pointer'
      }}
    >
      {children}
    </td>
  )
}

const UpdatePMModal = ({
  pmDetails,
  isOpen,
  toggle,
  refetch,
  setPmDetails
}: any) => {
  const { accounts, instance } = useMsal()
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = async (values: any) => {
    setSubmitting(true)
    makeAuthenticatedRequest(
      `${getPMList}/${pmDetails?._id}`,
      'PATCH',
      values,
      accounts,
      instance
    ).then(async (response) => {
      await refetch().then(() => {
        toggle()
      })
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      size="xs"
      toggle={toggle}
      onClosed={() => {
        setPmDetails(false)
        setSubmitting(false)
      }}
    >
      <ModalBody>
        <ModalTitle title={pmDetails?.tagNumber} />
        <Form
          onSubmit={(values) =>
            onSubmit({
              priority: values.priority?.value,
              frequency: values?.frequency,
              status: values?.status.value,
              dueDate: new Date(values?.dueDate)
            })
          }
          initialValues={{
            frequency: pmDetails?.frequency,
            priority: {
              value: pmDetails?.priority,
              label: pmDetails?.priority
            },
            status: { value: pmDetails?.status, label: pmDetails?.status },
            dueDate: new Date(pmDetails?.dueDate)
          }}
          render={({ form }) => (
            <form>
              <Row>
                <MultiSelect
                  name="priority"
                  label="Priority"
                  options={getOptions(['P1', 'P2', 'P3'])}
                  isMulti={false}
                  occupy={12}
                />
                <LabeledTextInput
                  name="frequency"
                  label="Frequency"
                  occupy={12}
                />
                <MultiSelect
                  name="status"
                  label="Status"
                  options={getOptions(['Active', 'Obsolete'])}
                  isMulti={false}
                  occupy={12}
                />
                {/* <Col>
                  <CustomLabel label="Due Date" />
                  <Input
                    name="dueDate"
                    type="date"
                    defaultValue={pmDetails?.dueDate}
                  />
                </Col> */}
                <LabeledDateTimeInput
                  name="dueDate"
                  label="Due Date"
                  occupy={12}
                />
              </Row>
              <ModalFooter>
                <Button color="warning" onClick={toggle}>
                  Close
                </Button>
                <Button
                  color="success"
                  onClick={form.submit}
                  disabled={submitting}
                >
                  Update
                </Button>
              </ModalFooter>
            </form>
          )}
        />
      </ModalBody>
    </Modal>
  )
}

export default function PMTodoTable({
  headers,
  data,
  setPageNumber,
  totalCount,
  loading,
  refetch
}: any) {
  const navigate = useNavigate()
  const [modal, setModal]: any = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [pmDetails, setPMDetails]: any = useState()

  const { userData } = useContext(UserContext)
  const isAdmin = userData?.jobTitle === 'admin'

  return (
    <>
      {isAdmin && (
        <Button color="warning" onClick={() => setModal(true)}>
          create New PM
        </Button>
      )}
      {pmDetails && (
        <Button
          color="warning"
          style={{ marginLeft: 10 }}
          onClick={() => setUpdateModal(true)}
        >
          Edit PM
        </Button>
      )}
      <Spacer height={10} />
      <Table
        bordered
        striped
        style={{ border: '1px solid #000', fontSize: 14 }}
      >
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            {[...(isAdmin ? [''] : []), ...headers].map((header: string) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody style={{ height: '100%' }}>
          {!loading &&
            data?.map((d: any, index: number) => (
              <tr key={`${d.title}-${index}`}>
                {isAdmin && (
                  <td>
                    <input
                      onChange={(e) =>
                        setPMDetails(e.target.checked ? d : null)
                      }
                      type="checkbox"
                      checked={d?._id === pmDetails?._id}
                    />
                  </td>
                )}
                <td>{incrementby1(index)}</td>
                <td>{d?.title}</td>
                <TDLink
                  onClick={() => navigate(`/protectiveMaintenance/${d?._id}`)}
                >
                  {d?.tagNumber}
                </TDLink>
                <td>{d?.respDepartment}</td>
                {[
                  d?.priority,
                  d?.frequency,
                  d?.status,
                  moment(d?.dueDate).format('DD-MM-YYYY')
                ]?.map((i: any, index: number) => (
                  <td key={`${index}-${i}`}>{i}</td>
                ))}
              </tr>
            ))}
          {loading && (
            <tr>
              <td colSpan={9}>
                <EmptyContent label={<Spinner />} />
              </td>
            </tr>
          )}
          {data?.length === 0 && (
            <tr>
              <td colSpan={9}>
                <EmptyContent label="No data found" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={totalCount}
        marginPagesDisplayed={10}
        pageRangeDisplayed={2}
        onPageChange={(values) => setPageNumber(values.selected)}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
      <CreateNewPM isOpen={modal} toggle={() => setModal(!modal)} />
      <UpdatePMModal
        isOpen={updateModal}
        toggle={() => setUpdateModal(!updateModal)}
        pmDetails={pmDetails}
        setPmDetails={setPMDetails}
        refetch={refetch}
      />
    </>
  )
}
