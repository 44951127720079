import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function RedirectToServices() {
  const navigate = useNavigate()

  useEffect(() => navigate(`../services`), [])

  return null
}
