import { Table } from 'reactstrap'
import { ColoredSubHeader } from '../previews/ViewExcavationPermit'

export default function GasReadingsTable({ rowStyle, measureMents }: any) {
  const continous = [
    '7AM',
    '8AM',
    '9AM',
    '10AM',
    '11AM',
    '12PM',
    '1PM',
    '2PM',
    '3PM',
    '4PM',
    '5PM'
  ]

  const intermittent = ['9AM', '12PM', '3PM', '5PM']

  const interval =
    (measureMents === 'Continuous' && continous) ||
    (measureMents === 'Intermittent' && intermittent) ||
    null

  const gases = ['H2S', 'O2', 'CO', 'LEL']

  if (!interval) {
    return <></>
  }

  return (
    <>
      <ColoredSubHeader rowStyle={rowStyle} text="Gas Readings" />
      <Table bordered striped style={{ borderColor: '#000' }}>
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            <th>Gas Type</th>
            {interval?.map((g: any) => <th key={g}>{g}</th>)}
          </tr>
        </thead>
        <tbody>
          {gases?.map((g: any) => (
            <tr key={g}>
              <th>{g}</th>
              <td></td>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}
