import { useState } from 'react'

import BoundarySvg from '../../SVGs/boundarySvg'
import CreateFromSystem43 from '../../SVGs/CreateFromSystem43'
import LoopDrawing from '../../SVGs/LoopDrawing'
import ProcessFlowDiagram from '../../SVGs/ProcessFlowDiagram'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SingleLineDrawing from '../../SVGs/SingleLineDrawing'
import SelectIsolations from '../../SVGs/SelectIsolations'
import { SubHeader } from '../VerifyCM'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import EptwModalHeader from '../../SVGs/EPTWModalHeader'
import SelectSLDIsolations from '../../SVGs/SelectSLDIsolations'

export default function CreateFromPID({}) {
  const [pid, setPid]: any = useState('pfd')
  const [modal, setModal]: any = useState(false)

  const pidList: any = {
    pfd: <ProcessFlowDiagram setPid={setPid} />,
    System43: <CreateFromSystem43 setPid={setPid} setModal={setModal} />,
    boundary: (
      <div style={{ margin: 10 }}>
        <BoundarySvg showButton={true} setPid={setPid} />
      </div>
    ),
    sld: <SelectSLDIsolations />,
    ld: <LoopDrawing />,
    isolations: <SelectIsolations setPid={setPid} />
  }

  const headers: any = {
    System43: 'Select Equipment',
    isolations: 'Select Isolations'
  }

  const styles: any = {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer'
  }

  const selectIsolations = (i: any) => {
    setPid(i)
    setModal(false)
  }

  return (
    <ProtectedLayout onBack="/eptw/dashboard">
      <SubHeader header={headers[pid]} permitColor="gold" />
      {pidList[pid]}
      <Modal
        id="customModal"
        isOpen={Boolean(modal)}
        size="sm"
        style={{
          borderRadius: '20%',
          width: '908px',
          backgroundColor: 'transparent'
        }}
      >
        <ModalHeader
          className="custom-modal"
          style={{
            padding: 0,
            borderRadius: '20%',
            margin: 0,
            background: 'transparent',
            marginLeft: -4
          }}
        >
          <EptwModalHeader />
        </ModalHeader>
        <ModalBody>
          <h6>Select Isolations from </h6>
          <p
            style={styles}
            onClick={() => {
              selectIsolations('isolations')
            }}
          >
            P & id
          </p>
          <p style={styles} onClick={() => selectIsolations('boundary')}>
            Boundary Drawing
          </p>
          <p style={styles} onClick={() => selectIsolations('sld')}>
            Single Line Drawing
          </p>
        </ModalBody>
      </Modal>
    </ProtectedLayout>
  )
}
