import ProtectedLayout from '../components/Layout/ProtectedLayout'

export default function AshokLeylandLayout({ onBack, title, children }: any) {
  return (
    <ProtectedLayout
      onBack={onBack}
      title={title}
      logo={require('./../images/Icons/Clamp.png')}
      backgroundColor="#eff9f9"
    >
      {children}
    </ProtectedLayout>
  )
}
