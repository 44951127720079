import { Row } from 'reactstrap'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { IconCard } from '../../components/Dashboard/IconCard'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { mocApi } from '../../components/serverurl'

export default function MocActions() {
  const { accounts, instance } = useMsal()

  localStorage.removeItem('moc')

  const fetchReport = async () =>
    await makeAuthenticatedRequest(
      `${mocApi}/counts`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data }: any = useQuery('FIR Counts', fetchReport)

  const counts = data?.data?.countsByStatus

  const flows: any = [
    {
      name: 'Stage 1',
      nav: '/firs/moc/stage1'
    },
    {
      name: 'Stage 2 MOC',
      nav: '/firs/moc/stage2',
      count: counts?.mocStage2
    }
  ]

  return (
    <>
      <ProtectedLayout onBack="/firs" title="MOC">
        <Row style={{ margin: 10, fontSize: 20 }}>
          {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
            <IconCard
              key={`${index}-${name}`}
              name={name}
              nav={nav}
              canView={canView}
              count={count}
              src={src}
            />
          ))}
        </Row>
      </ProtectedLayout>
    </>
  )
}
