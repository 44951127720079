import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane
} from 'reactstrap'

import ProtectedLayout from '../components/Layout/ProtectedLayout'
import Spacer from '../components/Spacer'
import SubmitModal from '../components/SubmitModal'
import toNormalCase from '../components/lib/toNormalCase'
import { adminPMPermissions } from '../components/serverurl'
import { makeAuthenticatedRequest } from '../apiRequests'
import { useMsal } from '@azure/msal-react'

function AuthTable({
  designation,
  stage,
  data,
  objectId,
  fetchAdminPermissions
}: any) {
  const permitEntries: any = data?.['permit']
  const [isopen, setIsOpen] = useState(false)
  const [update, setUpdate]: any = useState({})

  const { accounts, instance } = useMsal()

  const permitDepartments: any = [
    'mechanical',
    'electrical',
    'instrument',
    'production',
    'hse',
    'adminwarehouse'
  ]
  const permitActions: any = [
    'inProgress',
    'waitingForMaterial',
    'waitingForShutdown',
    'waitingFor3rdParty',
    'close'
  ]

  const renderTableData = (data: any, action: string, department: string) => (
    <td
      style={{
        cursor: 'pointer',
        color: data?.[action]?.[department] === 'Yes' ? 'darkGreen' : 'red',
        fontWeight: data?.[action]?.[department] === 'Yes' ? 700 : 500
        // width: '5%'
      }}
      onClick={() => {
        setIsOpen(true)
        setUpdate({
          designation,
          action,
          department,
          changeTo: data?.[action]?.[department] === 'Yes' ? 'No' : 'Yes',
          id: data?._id,
          stage
        })
      }}
    >
      <p style={{ margin: 0, fontSize: 16, textAlign: 'center' }}>
        {data?.[action]?.[department]}
      </p>
    </td>
  )

  const updatePermission = async () => {
    await makeAuthenticatedRequest(
      adminPMPermissions,
      'PATCH',
      { ...update, id: objectId },
      accounts,
      instance
    )
      .then()
      .catch((err) => console.info(err))

    await fetchAdminPermissions()
  }

  return (
    <>
      <p style={{ textAlign: 'center' }}>
        You are now Modifying the Authorities of <b>{designation}</b> for{' '}
        <b>{stage} Permit</b>
      </p>
      <Row>
        <Col xs="12" md="12">
          <Table
            bordered
            style={{ border: '1px solid #000', width: '100%', fontSize: 12 }}
          >
            <thead>
              <tr>
                <th
                  colSpan={7}
                  style={{
                    textAlign: 'center',
                    backgroundColor: 'orange',
                    fontSize: 16
                  }}
                >
                  Permit
                </th>
              </tr>
              <tr>
                <th
                  rowSpan={2}
                  style={{ textAlign: 'center', verticalAlign: 'middle' }}
                >
                  Actions
                </th>
                <th colSpan={6} style={{ textAlign: 'center' }}>
                  Departments
                </th>
              </tr>
              <tr style={{ textAlign: 'center' }}>
                <th>Mechanical</th>
                <th>Electrical</th>
                <th>Instrument</th>
                <th>Production</th>
                <th>HSE</th>
                <th>Ware House</th>
              </tr>
            </thead>
            <tbody>
              {permitActions?.map((action: string) => (
                <tr key={action}>
                  <td>{toNormalCase(action)}</td>
                  {permitDepartments?.map(
                    (department: string, index: number) => (
                      <React.Fragment key={`${action}-${index}-${department}`}>
                        {renderTableData(permitEntries, action, department)}
                      </React.Fragment>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <SubmitModal
          isOpen={isopen}
          toggle={() => {
            setIsOpen(!isopen)
          }}
          refetch={() => null}
          onSubmit={() => updatePermission()}
          size="md"
          afterClose={() => {
            setIsOpen(false)
            setUpdate({})
          }}
          submitModalContent={{
            title: (
              <>
                <p> Are you sure you want to Update ? </p>
                <Table bordered style={{ border: '1px solid #000' }}>
                  <tbody>
                    <tr>
                      <th>Designation</th>
                      <td>{update?.designation}</td>
                    </tr>
                    <tr>
                      <th>Flow</th>
                      <td>{update?.stage}</td>
                    </tr>
                    <tr>
                      <th>Action</th>
                      <td>{toNormalCase(update?.action)}</td>
                    </tr>
                    <tr>
                      <th>Department</th>
                      <td>{toNormalCase(update?.department)}</td>
                    </tr>
                    <tr>
                      <th>Update Access</th>
                      <td>{update?.changeTo}</td>
                    </tr>
                  </tbody>
                </Table>
              </>
            )
          }}
        />
      </Row>
    </>
  )
}

const stages = [
  {
    name: 'Protective Maintenane',
    component: <AuthTable create />
  }
]

function Authorities({
  designation,
  data,
  objectId,
  fetchAdminPermissions
}: any) {
  const [stage, setStage] = useState(0)

  const renderNavItem = (tabNumber: number, label: any) => (
    <NavItem>
      <NavLink
        className={stage === tabNumber ? 'active' : ''}
        onClick={() => setStage(tabNumber)}
      >
        <b>{label}</b>
      </NavLink>
    </NavItem>
  )

  const renderTabPane = (
    id: number,
    children: any,
    stage: string,
    objectId: any,
    fetchAdminPermissions: any
  ) => (
    <TabPane tabId={id}>
      <Row>
        <Col sm="12">
          <Spacer height={10} />
          {React.cloneElement(children, {
            stage,
            designation,
            data,
            id,
            objectId,
            fetchAdminPermissions
          })}
        </Col>
      </Row>
    </TabPane>
  )

  return (
    <>
      <Nav justified pills>
        {stages?.map((stage: any, index: number) => (
          <React.Fragment key={stage?.name}>
            {renderNavItem(index, stage?.name)}
          </React.Fragment>
        ))}
      </Nav>
      <hr />
      <TabContent activeTab={stage} style={{ width: '100%', paddingRight: 10 }}>
        {stages?.map((stage: any, index: number) => (
          <React.Fragment key={`${index}-${stage?.title}`}>
            {renderTabPane(
              index,
              stage?.component,
              stage?.name,
              objectId,
              fetchAdminPermissions
            )}
          </React.Fragment>
        ))}
      </TabContent>
    </>
  )
}

export default function PMAdmin() {
  const [activeTab, setActiveTab] = useState(0)
  const [data, setData]: any = useState(null)

  const { accounts, instance } = useMsal()

  const renderNavItem = (tabNumber: number, label: any) => (
    <NavItem
      style={{
        borderTop: tabNumber === 0 ? '1px solid #000' : 0,
        borderBottom: '1px solid #000'
      }}
    >
      <NavLink
        className={activeTab === tabNumber ? 'active' : ''}
        onClick={() => setActiveTab(tabNumber)}
        style={{ borderBottom: 0 }}
      >
        <b>{label}</b>
      </NavLink>
    </NavItem>
  )

  const renderTabPane = (
    id: any,
    children: any,
    designation: string,
    data: any,
    objectId: any,
    fetchAdminPermissions: any
  ) => (
    <TabPane tabId={id}>
      <Row>
        <Col sm="12">
          <Spacer height={10} />
          {React.cloneElement(children, {
            designation,
            data,
            objectId,
            fetchAdminPermissions
          })}
        </Col>
      </Row>
    </TabPane>
  )

  const fetchAdminPermissions = async () => {
    await makeAuthenticatedRequest(
      adminPMPermissions,
      'GET',
      null,
      accounts,
      instance
    )
      .then(
        (response: any) => setData(response?.data) //error here
      )
      .catch((err) => console.info(err))
    // await axios.get(adminCMPermissions).then((res: any) => {
    //   setData(res?.data?.data)
    // }).catch((err => console.info(err)))
  }

  useEffect(() => {
    fetchAdminPermissions()
  }, [])

  const getdata = (designation: any) =>
    data?.filter(
      (permissions: any) =>
        permissions?.userDesignation === designation && permissions
    )[0]

  const designations = [
    {
      title: 'Mines Manager',
      data: getdata('MINES MANAGER')
    },
    {
      title: 'Installation Manager',
      data: getdata('DEPUTY INSTALLATION MANAGER')
    },
    {
      title: 'Deputy Installation Manager',
      data: getdata('INSTALLATION MANAGER')
    },
    {
      title: 'Production Incharge',
      data: getdata('PRODUCTION INCHARGE')
    },
    {
      title: 'Deputy Incharge HSE',
      data: getdata('DEPUTY MANAGER-HSE')
    },
    {
      title: 'Fire Officer',
      data: getdata('FIRE OFFICER')
    },
    {
      title: 'Admin Ware House',
      data: getdata('ADMIN WARE HOUSE')
    },
    {
      title: 'Mechanical Incharge',
      data: getdata('MECHANICAL INCHARGE')
    },
    {
      title: 'Mechanical Engineer',
      data: getdata('MECHANICAL ENGINEER')
    },
    {
      title: 'Electrical Engineer',
      data: getdata('ELECTRICAL ENGINEER')
    },
    {
      title: 'Instrument Engineer',
      data: getdata('INSTRUMENT INCHARGE')
    },
    {
      title: 'Production Engineer',
      data: getdata('PRODUCTION ENGINEER')
    },
    {
      title: 'Panel Engineer',
      data: getdata('PANEL ENGINEER')
    },
    {
      title: 'Field Engineer',
      data: getdata('FIELD ENGINEER')
    }
  ]

  return (
    <ProtectedLayout>
      <Row style={{ height: '100%' }}>
        <Col xs="2">
          <Nav
            pills
            vertical
            style={{
              borderRight: '1px solid #000',
              borderLeft: '1px solid #000'
            }}
          >
            {designations?.map((designation: any, index: number) => (
              <React.Fragment key={designation?.title}>
                {renderNavItem(index, designation?.title)}
              </React.Fragment>
            ))}
          </Nav>
        </Col>
        <Col xs="10">
          <TabContent
            activeTab={activeTab}
            style={{ minHeight: '80vh', width: '100%', paddingRight: 10 }}
          >
            {designations?.map((designation: any, index: number) => (
              <React.Fragment key={`${index}-${designation?.title}`}>
                {renderTabPane(
                  index,
                  <Authorities />,
                  designation?.title,
                  designation?.data?.permissions,
                  designation?.data?._id,
                  fetchAdminPermissions
                )}
              </React.Fragment>
            ))}
          </TabContent>
        </Col>
      </Row>
    </ProtectedLayout>
  )
}
