import { Col, Row } from 'reactstrap'

import AshokLeylandLayout from './AshokLeylandLayout'
import AlMmsMenu from '../SVGs/AlmmsMenu'

export default function MmsServices() {
  return (
    <AshokLeylandLayout onBack="/ashokleyland" title="MMS">
      <Row>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <AlMmsMenu />
        </Col>
      </Row>
    </AshokLeylandLayout>
  )
}
