import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useState } from 'react'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import toNormalCase from '../../components/lib/toNormalCase'
import { Box, Link, renderData } from '../workManagement/PmTree'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { invoiceAPI, manualAPI } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { useNavigate, useParams } from 'react-router-dom'
import Manuals, { UploadManualForm } from './Manuals'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'

export const dFields = [
  'number',
  'title',
  'tag',
  'description',
  'department',
  'documentType',
  'originCode',
  'createdAt',
  'uploadedBy',
  'status'
]

export default function ViewManuals({
  departmentCode,
  documentType,
  logicCode,
  orginator,
  origin,
  status,
  onBack,
  title
}: {
  departmentCode?: any
  documentType?: any
  logicCode?: any
  orginator?: any
  origin?: any
  status?: any
  onBack?: any
  title: any
}) {
  const { accounts, instance } = useMsal()
  // const { departmentCode, documentType, logicCode, orginator, origin } =
  //   useParams()

  const [doc, setDoc]: any = useState(null)
  const [approvalStatus, setApprovalStatus]: any = useState(null)
  const [res, setRes]: any = useState(null)
  const [comment, setComments]: any = useState(null)

  const fetchManuals = async () => {
    const response = await makeAuthenticatedRequest(
      `${manualAPI}?departmentCode=${departmentCode}&documentType=${documentType}&logicCode=${logicCode}&orginator=${orginator}&origin=${origin}&status=${status}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response.data
  }

  const { data, refetch }: any = useQuery('fetchManuals', fetchManuals)

  const navigate = useNavigate()
  console.log(doc)

  return (
    <ProtectedLayout title={title} onBack={onBack}>
      <Row style={{ margin: 15 }}>
        {data?.map((i: any) => (
          <Col xs="12" md="2">
            <div
              key={i?.file}
              style={{
                padding: 10,
                marginBottom: 10,
                borderRadius: 10,
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
              }}
            >
              {i.file && (
                <iframe
                  title="Document Preview"
                  src={`${invoiceAPI}/${i.file}`}
                  style={{ marginBottom: 10, border: '1px solid #000' }}
                  width="100%"
                  height="300px"
                />
              )}
              <h5>{i?.title}</h5>
              <p style={{ fontSize: 14 }}>{i?.number}</p>
              <Button
                color="warning"
                style={{ width: '100%' }}
                onClick={() => setDoc(i)}
              >
                View
              </Button>
            </div>
          </Col>
        ))}
      </Row>
      {((doc && doc?.status === 'Approved') ||
        (doc && doc?.status === 'Rejected')) && (
        <Modal isOpen={doc} toggle={() => setDoc(null)} size="xl">
          <ModalBody>
            {doc?.file && (
              <iframe
                title="Document Preview"
                src={`${invoiceAPI}/${doc?.file}`}
                style={{ marginBottom: 10, border: '1px solid #000' }}
                width="100%"
                height="700px"
              />
            )}
            <Box heading="Manual Details">
              <>
                {dFields?.map((k: any) =>
                  renderData(
                    toNormalCase(k),
                    k === 'createdAt' ? getReadableTime(doc[k]) : doc[k]
                  )
                )}
              </>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={() => setDoc(null)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {doc && doc?.status === 'Draft' && (
        <Modal isOpen={doc} toggle={() => setDoc(null)} fullscreen>
          <ModalBody>
            <div style={{ width: 'inehrit' }}>
              <UploadManualForm initialValues={doc} />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={() => setDoc(null)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {doc && doc?.status === 'Created' && (
        <Modal isOpen={doc} toggle={() => setDoc(null)} size="xl">
          <ModalBody>
            {doc?.file && (
              <iframe
                title="Document Preview"
                src={`${invoiceAPI}/${doc?.file}`}
                style={{ marginBottom: 10, border: '1px solid #000' }}
                width="100%"
                height="700px"
              />
            )}
            <Box heading="Manual Details">
              <>
                {dFields?.map((k: any) =>
                  renderData(
                    toNormalCase(k),
                    k === 'createdAt' ? getReadableTime(doc[k]) : doc[k]
                  )
                )}
              </>
            </Box>
            {doc?.status === 'Created' && (
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={() => setApprovalStatus('Rejected')}
                >
                  Reject
                </Button>
                <Button
                  color="warning"
                  onClick={() => setApprovalStatus('Approved')}
                >
                  Approve
                </Button>
              </ModalFooter>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={() => setDoc(null)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <SubmitModalWithForm
        isOpen={approvalStatus}
        onClose={(val: any) => {
          setApprovalStatus(false)
        }}
        onSubmit={async (e: any) => {
          const body: any = {
            id: doc?._id,
            status: approvalStatus,
            comment
          }
          try {
            const res: any = await makeAuthenticatedRequest(
              `${manualAPI}/approve`,
              'PATCH',
              body,
              accounts,
              instance
            )

            console.log(res?.status)

            if (res?.status === 'success') {
              setRes(res?.data?.data)
            }
            return res?.status
          } catch (error) {}
        }}
        size="xs"
        header="Upload Manual"
        sucessView={
          <div>
            <p>Document Number: {doc?.number}</p>
            <Link
              onClick={() => {
                if (res?.status === 'Approved') {
                  navigate(
                    `/cmms/manual/view/${doc.originatorCode}/${doc.originCode}/${doc.logicType}/${doc.department}/${doc.documentType}/docs`
                  )
                } else if (doc.status === 'Rejected') {
                  navigate('/cmms/manual/rejected')
                }
              }}
            >
              View Document
            </Link>
          </div>
        }
        form={
          <>
            <p>Are you Sure you want to Continue ?</p>
            {approvalStatus === 'Rejected' && (
              <>
                <p>Reject Comments</p>
                <Input
                  type="text"
                  onChange={(e: any) => setComments(e?.target?.value)}
                />
              </>
            )}
          </>
        }
      />
    </ProtectedLayout>
  )
}
