import { Row, Button, Modal, ModalFooter, ModalBody } from 'reactstrap'

import LabeledRadioButtons from '../InputFields/LabeledRadioButton'
import SelectJSATemplate from '../Templates/SelectJSATemplate'
import SelectQRATemplate from '../Templates/SelectQRATemplate'
import RiskAssessmentForm from './RiskAssesmentForm'

type FormType = {
  equipment: number
  isOpen: boolean
  setValues?: any
  toggle: any
  values?: any
  permitValues: any
}

export const criticalList = [
  { label: 'Critical', value: 'Critical' },
  { label: 'Non Critical', value: 'Non Critical' }
]

export default function HOECRiskAssesmentForm({
  isOpen,
  toggle,
  values,
  setValues,
  permitValues
}: FormType) {
  const { isJSA, IsNewTemplate } = permitValues
  const critical = isJSA === 'Critical'
  const isNewTemplate = IsNewTemplate === 'New'

  const templateList = [
    { label: 'New', value: 'New' },
    { label: 'Existing', value: 'Existing' }
  ]

  return (
    <Modal isOpen={isOpen} toggle={toggle} fullscreen>
      <ModalBody>
        <Row style={{ textAlign: 'center', marginBottom: 20, marginTop: 20 }}>
          <LabeledRadioButtons
            occupy={4}
            name="isJSA"
            label="Task Criticality"
            list={criticalList}
            defaultValue={'Critical'}
          />
          <LabeledRadioButtons
            occupy={4}
            name="IsNewTemplate"
            label="Template"
            list={templateList}
            defaultValue={'New'}
          />
        </Row>
        {isNewTemplate && (
          <RiskAssessmentForm
            initialValuesEqual={false}
            setValues={setValues}
            values={values}
            toggle={toggle}
            isJSA={isJSA}
          />
        )}

        {/* {critical && isNewTemplate && (
          <QRAForm setValues={setValues} values={values} toggle={toggle} />
        )} */}
        {critical && !isNewTemplate && (
          <SelectQRATemplate setSelect={setValues} select={values} />
        )}
        {/* {!critical && isNewTemplate && (
          <JSAForm setValues={setValues} values={values} toggle={toggle} />
        )} */}
        {!critical && !isNewTemplate && (
          <SelectJSATemplate setSelect={setValues} select={values} />
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={toggle}>
          Previous
        </Button>
        <Button color="warning" onClick={toggle}>
          Next
        </Button>
      </ModalFooter>
      <p style={{ color: 'red', textAlign: 'right' }}>
        * Please save your Assessment before proceeding next.
      </p>
    </Modal>
  )
}
