import { Button, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";

import FPSOBoundary3Svg from "../../SVGs/FpsoBoundary3Svg";
import RcmLayout from "../../components/rcm/RcmLayout";

export default function FpsoBoundary3() {
  const navigate = useNavigate()
  return (
    <RcmLayout title={'FPSO Boundary'} onBack=''>
      <FPSOBoundary3Svg />
      {/* <Row style={{ margin: 15 }}>
        <Button onClick={() => navigate('cmms/rcm/boundary3iso')} color="warning">View ISO Standard</Button>
      </Row> */}
    </RcmLayout >
  )
}