import { Row } from 'reactstrap'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { FIRSAnalytics } from '../FIRSDashboard'

export default function FIRSKpi() {
  return (
    <ProtectedLayout onBack={'/firs'}>
      <Row>
        <FIRSAnalytics />
      </Row>
    </ProtectedLayout>
  )
}
