import arrayMutators from 'final-form-arrays'
import { useMsal } from '@azure/msal-react'
import { useState } from 'react'
import { MdDelete, MdEdit } from 'react-icons/md'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { jobcodes } from '../../components/constants/jobcodes'
import { incrementby1 } from '../../components/Forms/JSAForm'
import CustomLabel from '../../components/InputFields/CustomLabel'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import ModalTitle from '../../components/ModalTitle'
import { pmAPI } from '../../components/serverurl'
import Spacer from '../../components/Spacer'
import { ViewHistory } from '../ViewCM'
import { Box, renderData } from './PmTree'
import { JobPlanTable } from './WorkOrderDetails'
import { FieldArray } from 'react-final-form-arrays'
import { renderFields } from './WorkOrderForm'
import { Form } from 'react-final-form'
import { FormSection } from './WorkRequestForm'

const history = [
  {
    updatedTo: 'Created',
    updatedAt: '02-02-2024, 12:56 AM',
    userDetails: {
      givenName: 'Alvaro',
      jobTitle: 'Mechanical Engineer',
      department: 'Mechanical'
    }
  },
  {
    updatedTo: 'Verified',
    updatedAt: '03-02-2024, 9:34 AM',
    userDetails: {
      givenName: 'Isabella',
      jobTitle: 'Production Incharge',
      department: 'Production'
    }
  },
  {
    updatedTo: 'Approved',
    updatedAt: '03-02-2024, 10:21 AM',
    userDetails: {
      givenName: 'Vincenzo',
      jobTitle: 'Installation Manager',
      department: 'Production'
    }
  }
]

const UpdateJD = ({ isOpen, toggle, jd, pm }: any) => {
  const job = jd

  const renderStep = (item: number) => (
    <div
      style={{
        display: 'flex',
        gap: 10,
        alignItems: 'center',
        marginBottom: 5
      }}
    >
      <p style={{ margin: 0 }}>{incrementby1(item)}.</p>
      <Input type="text" defaultValue={job?.jobDescription?.steps[item]} />
      <MdEdit color="blue" />
      <MdDelete color="red" />
    </div>
  )

  const renderEditField = (value: string) => (
    <div
      style={{
        display: 'flex',
        gap: 10,
        alignItems: 'center',
        marginBottom: 5
      }}
    >
      <Input type="text" defaultValue={value} />
      <MdEdit color="blue" />
      <MdDelete color="red" />
    </div>
  )

  return (
    <Modal isOpen={isOpen} toggle={toggle} fullscreen>
      <ModalTitle title="EDIT JOB PLAN - JP-001" />
      <ModalBody>
        <Row style={{ margin: 10 }}>
          <FormSection title="General Information">
            <CustomLabel label="Job Title" />
            {renderEditField(pm?.workDescription)}
            <CustomLabel label="Work Type" />
            {renderEditField(pm?.workType)}
          </FormSection>
          <Form
            onSubmit={(values: any) => {}}
            initialValues={{
              operations: jd
            }}
            // validate={validate}
            mutators={{
              ...arrayMutators
            }}
            render={({}) => (
              <form>
                <FormSection title="Operations">
                  <div>
                    <Table>
                      <thead>
                        <tr>
                          <th>Operations Number</th>
                          <th>Description</th>
                          <th>Main Org</th>
                          <th>Planned Hours</th>
                          <th>Planned Resources</th>
                          <th>Man Hours</th>
                          <th>Remarks</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray
                          name="operations"
                          component={renderFields}
                        />
                      </tbody>
                    </Table>
                  </div>
                </FormSection>
              </form>
            )}
          />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Cancel</Button>
        <Button color="warning" onClick={toggle}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default function PmJobDescription() {
  const [modal, setModal] = useState(false)

  const { id } = useParams()
  const { accounts, instance } = useMsal()

  const fetchPm = async () => {
    const response = await makeAuthenticatedRequest(
      `${pmAPI}/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data }: any = useQuery('pmJOB', fetchPm)

  const pm = data?.pm
  return (
    <ProtectedLayout onBack="/cmms/planning/pm/jobs">
      <Row style={{ margin: 10 }}>
        <SectionHeader title={`job Plan - ${pm?.jobPlanCode}`} />
        <hr />
        <Box heading="PM Details">
          <Row style={{ margin: 0 }}>
            {renderData('Title', pm?.workDescription)}
            {renderData('Function', pm?.functionNumber)}
            {renderData('Work Type', pm?.workType)}
            {renderData('Work Type Class', pm?.workTypeClass)}
          </Row>
        </Box>
        <Box heading="Job Plan">
          {/* <div>
            <h4>{job.jobDescription.title}</h4>
            <h5>{job.jobDescription.subheader}</h5>
            <ol>
              {job.jobDescription?.steps?.map((s: string, index: number) => (
                <li key={`${index}-${s}`}>{s}</li>
              ))}
            </ol>
          </div> */}
          <JobPlanTable data={jobcodes[pm?.jobPlanCode]} />
        </Box>

        <Box heading="Job Approval History">
          <ViewHistory data={history} showTitle={false} />
        </Box>

        <ModalFooter>
          <Button color="warning" onClick={() => setModal(true)}>
            Edit Job Description
          </Button>
        </ModalFooter>
      </Row>
      <UpdateJD
        isOpen={modal}
        toggle={() => setModal(!modal)}
        jd={jobcodes[pm?.jobPlanCode]}
        pm={pm}
      />
    </ProtectedLayout>
  )
}
