import React, { useState } from 'react'
import { FaFileExport } from 'react-icons/fa'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { ToastContainer } from 'react-toastify'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import 'react-toastify/dist/ReactToastify.css'

import RcmLayout from '../../components/rcm/RcmLayout'
import { systemsApi } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { IconButton, handleExport } from './Systems'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { SubHeader } from '../VerifyCM'
import CustomLabel from '../../components/InputFields/CustomLabel'

export const flocHeaderKeys = [
  'FLOC',
  'Failure Mode',
  'Failure Mode Type',
  'Function system Unit',
  'Function system Number',
  'Function System',
  'Function',
  'Function Failure',
  'ETBF (Years)',
  'ETBC (Years)',
  'Environmental',
  'Health & Safety',
  'PLE',
  'PL Down hours (hrs)',
  'Spare Part lead time (days)',
  'Material Spare cost',
  'Misc Cost',
  'Craft man cost',
  'Operator Cost',
  'Staff hours',
  'Contractor hours',
  'Task name',
  'Task Type',
  'Task Frequency',
  '	Task description',
  'Task Mandatory or not',
  'Task reason',
  'PLE (Contractor)',
  'PL down hours hrs (Contractor)',
  'Spare part lead time (Contractor)',
  'Material Spare cost (Contractor)',
  'Misc Cost (Contractor)',
  'Craft man cost (Contractor)',
  'Operator cost (Contractor)',
  'Staff hours (Contractor)',
  'Contractor hours',
  'Task Justification',
  'Enviromental Impact',
  'Health safety impact',
  'Production Loss in cost (USD)',
  'Material cost',
  'Labout cost',
  'PONC Annual without maintenance',
  'PONC Annual with maintenance',
  'POC',
  'POC material',
  'POC manpower cost',
  'POC Annual $',
  'MEI Threshoold',
  'Task existing or new'
]

const dataFields = [
  'HIERARCHY FLOC ID',
  'Failure Mode',
  'failureModeType',
  'Unit',
  'System',
  'functionalSystem',
  'function',
  'functionalFailure',
  'ETBF',
  'ETBC',
  'Environment',
  'Health Safety',
  'PLE',
  'PL Down hours (hrs)',
  'Spare Part lead time (days)',
  'Material Spare cost',
  'Misc Cost',
  'Craft man cost',
  'Operator Cost',
  'Staff hours',
  'Contractor hours',
  'Task name',
  'Task Type',
  'Task Frequency',
  'Task description',
  'Task Mandatory or not',
  'Task reason',
  'PLE (Contractor)',
  'PL down hours hrs (Contractor)',
  'Spare part lead time (Contractor)',
  'Material Spare cost (Contractor)',
  'Misc Cost (Contractor)',
  'Craft man cost (Contractor)',
  'Operator cost (Contractor)',
  'Staff hours (Contractor)',
  'Contractor hours',
  'Task Justification',
  'Enviromental Impact',
  'Health safety impact',
  'Production Loss in cost (USD)',
  'Material cost',
  'Labout cost',
  'PONC Annual without maintenance',
  'PONC Annual with maintenance',
  'POC',
  'POC material',
  'POC manpower cost',
  'POC Annual $',
  'MEI Threshoold',
  'Task existing or new'
]

export default function RcmAnalysis({
  setPin,
  pin
}: {
  pin: any
  setPin: any
}) {
  const { accounts, instance } = useMsal()

  const fetchSystems = async () => {
    const response = await makeAuthenticatedRequest(
      `${systemsApi}/floc?system=43`,
      'GET',
      null,
      accounts,
      instance
    )
    return response
  }

  const { data }: any = useQuery('Rcm Analysis data', fetchSystems)

  const analysisData = data?.systems?.flatMap((item: any) =>
    item.failureModes.map((cItem: any) => ({
      ...item,
      ...cItem,
      ...data?.functionFailure
    }))
  )

  const [selected, setSelected]: any = useState()
  const [calculateMeiModal, setCalculateMeiModal]: any = useState(false)
  const [calculatePleModal, setCalculatePleModal]: any = useState(false)
  const [meiValues, setMeiValues]: any = useState()
  const [pleValues, setPleValues]: any = useState()
  const [flocData, setFlocdata]: any = useState(analysisData)

  const calculateMei: any = () =>
    (meiValues?.['PONC Without Maintenance'] -
      meiValues?.['PONC With Maintenance']) /
    meiValues?.['PC Annual $']

  const calculatePle: any = () =>
    Number(pleValues?.['Operator Cost']) +
    Number(pleValues?.['Craft man cost']) +
    Number(pleValues?.['Contractor hours']) +
    Number(pleValues?.['Staff hours']) +
    Number(pleValues?.['Production Loss in cost (USD)']) +
    Number(pleValues?.['Staff hours (Contractor)']) +
    Number(pleValues?.['Operator cost (Contractor)']) +
    Number(pleValues?.['Craft man cost (Contractor)']) +
    Number(pleValues?.['Material Spare cost (Contractor)']) +
    Number(pleValues?.['Spare part lead time (Contractor)']) +
    Number(pleValues?.['Material cost']) *
      Number(pleValues?.['Spare Part lead time (days)'])

  return (
    <RcmLayout
      onBack={'/cmms'}
      title={'RCM'}
      tabHeader={<h4 style={{ margin: 0 }}>Analysis</h4>}
    >
      <div style={{ display: 'flex', marginBottom: 10 }}>
        <IconButton
          icon={<FaFileExport size={25} />}
          buttonText={'Export'}
          onClick={() => handleExport(flocData || analysisData)}
        />
        {selected && (
          <Button
            color="warning"
            onClick={() => {
              setCalculateMeiModal(true)
              setMeiValues({
                'PONC Without Maintenance':
                  selected?.['PONC Annual without maintenance'],
                'PONC With Maintenance':
                  selected?.['PONC Annual with maintenance'],
                'PC Annual $': selected?.['POC Annual $']
              })
            }}
          >
            Calculate MEI
          </Button>
        )}
        {selected && (
          <Button
            color="warning"
            style={{ marginLeft: 10 }}
            onClick={() => {
              setCalculatePleModal(true)
              setPleValues({
                'Contractor hours': selected?.['Contractor hours'],
                'Staff hours': selected?.['Staff hours'],
                'Material cost': selected?.['Material cost'],
                'Production Loss in cost (USD)':
                  selected?.['Production Loss in cost (USD)'],
                'Spare Part lead time (days)':
                  selected?.['Spare Part lead time (days)'],
                'Spare part lead time (Contractor)':
                  pleValues?.['Spare part lead time (Contractor)'],
                'Material Spare cost (Contractor)':
                  pleValues?.['Material Spare cost (Contractor)'],
                'Craft man cost (Contractor)':
                  pleValues?.['Craft man cost (Contractor)'],
                'Operator cost (Contractor)':
                  pleValues?.['Operator cost (Contractor)'],
                'Staff hours (Contractor)':
                  pleValues?.['Staff hours (Contractor)'],
                'Craft man cost': pleValues?.['Craft man cost'],
                'Operator Cost': pleValues?.['Operator Cost']
              })
            }}
          >
            Calculate PLE
          </Button>
        )}
      </div>
      <Table
        responsive
        striped
        style={{ border: '1px solid #000', width: 'max-content' }}
      >
        <thead>
          <tr>
            <th>S.No</th>
            {['', ...flocHeaderKeys]?.map((k: any, index: any) => (
              <th key={`${k}-${index}`}>{k}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {(flocData || analysisData)?.map((hazard: any, index: any) => (
            <React.Fragment key={index}>
              <tr>
                <td>
                  <Input
                    type="checkbox"
                    checked={
                      selected?.['Failure Mode'] === hazard?.['Failure Mode']
                    }
                    onChange={(e) => setSelected(hazard)}
                  />
                </td>
                <td>{incrementby1(index)}</td>
                {dataFields?.map((a: any, index: any) => (
                  <td key={`${a}-${index}`}>{hazard?.[a]}</td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      <MeiModal
        analysisData={analysisData}
        calculateMei={calculateMei}
        calculateMeiModal={calculateMeiModal}
        flocData={flocData}
        meiValues={meiValues}
        selected={selected}
        setCalculateMeiModal={setCalculateMeiModal}
        setFlocdata={setFlocdata}
        setMeiValues={setMeiValues}
      />
      <PleModal
        analysisData={analysisData}
        calculatePle={calculatePle}
        calculatePleModal={calculatePleModal}
        flocData={flocData}
        pleValues={pleValues}
        selected={selected}
        setCalculatePleModal={setCalculatePleModal}
        setFlocdata={setFlocdata}
        setPleValues={setPleValues}
      />
      <ToastContainer
        theme="dark"
        position="top-center"
        style={{ fontSize: 16 }}
      />
    </RcmLayout>
  )
}

const PleModal = ({
  calculatePleModal,
  selected,
  pleValues,
  setPleValues,
  calculatePle,
  setCalculatePleModal,
  setFlocdata,
  flocData,
  analysisData
}: {
  calculatePleModal: any
  selected: any
  pleValues: any
  setPleValues: any
  calculatePle: any
  setCalculatePleModal: any
  setFlocdata: any
  flocData: any
  analysisData: any
}) => {
  return (
    <Modal isOpen={calculatePleModal} size="xl">
      <SubHeader
        header={`Calculate PLE Value for ${selected?.['Failure Mode']}`}
      />
      <ModalBody>
        <Row>
          <Col xs="6">
            <CustomLabel label="Craft man cost" />
            <Input
              type="text"
              defaultValue={pleValues?.['Craft man cost']}
              onChange={(e) =>
                setPleValues({
                  ...pleValues,
                  'Craft man cost': e?.target?.value
                })
              }
            />
          </Col>
          <Col xs="6">
            <CustomLabel label="Operator Cost" />
            <Input
              type="text"
              defaultValue={pleValues?.['Operator Cost']}
              onChange={(e) =>
                setPleValues({
                  ...pleValues,
                  'Operator Cost': e?.target?.value
                })
              }
            />
          </Col>
          <Col xs="6">
            <CustomLabel label="Contractor hours" />
            <Input
              type="text"
              defaultValue={pleValues?.['Contractor hours']}
              onChange={(e) =>
                setPleValues({
                  ...pleValues,
                  'Contractor hours': e?.target?.value
                })
              }
            />
          </Col>
          <Col xs="6">
            <CustomLabel label="Staff hours" />
            <Input
              type="text"
              defaultValue={pleValues?.['Staff hours']}
              onChange={(e) =>
                setPleValues({
                  ...pleValues,
                  'Staff hours': e?.target?.value
                })
              }
            />
          </Col>
          <Col xs="6">
            <CustomLabel label="Material cost" />
            <Input
              type="text"
              defaultValue={pleValues?.['Material cost']}
              onChange={(e) =>
                setPleValues({
                  ...pleValues,
                  'Material cost': e?.target?.value
                })
              }
            />
          </Col>
          <Col xs="6">
            <CustomLabel label="Spare part lead time (Contractor)" />
            <Input
              type="text"
              defaultValue={pleValues?.['Spare part lead time (Contractor)']}
              onChange={(e) =>
                setPleValues({
                  ...pleValues,
                  'Spare part lead time (Contractor)': e?.target?.value
                })
              }
            />
          </Col>
          <Col xs="6">
            <CustomLabel label="Material Spare cost (Contractor)" />
            <Input
              type="text"
              defaultValue={pleValues?.['Material Spare cost (Contractor)']}
              onChange={(e) =>
                setPleValues({
                  ...pleValues,
                  'Material Spare cost (Contractor)': e?.target?.value
                })
              }
            />
          </Col>
          <Col xs="6">
            <CustomLabel label="Craft man cost (Contractor)" />
            <Input
              type="text"
              defaultValue={pleValues?.['Craft man cost (Contractor)']}
              onChange={(e) =>
                setPleValues({
                  ...pleValues,
                  'Craft man cost (Contractor)': e?.target?.value
                })
              }
            />
          </Col>
          <Col xs="6">
            <CustomLabel label="Operator cost (Contractor)" />
            <Input
              type="text"
              defaultValue={pleValues?.['Operator cost (Contractor)']}
              onChange={(e) =>
                setPleValues({
                  ...pleValues,
                  'Operator cost (Contractor)': e?.target?.value
                })
              }
            />
          </Col>
          <Col xs="6">
            <CustomLabel label="Staff hours (Contractor)" />
            <Input
              type="text"
              defaultValue={pleValues?.['Staff hours (Contractor)']}
              onChange={(e) =>
                setPleValues({
                  ...pleValues,
                  'Staff hours (Contractor)': e?.target?.value
                })
              }
            />
          </Col>
          <Col xs="6">
            <CustomLabel label="Production Loss in cost (USD)" />
            <Input
              type="text"
              defaultValue={pleValues?.['Production Loss in cost (USD)']}
              onChange={(e) =>
                setPleValues({
                  ...pleValues,
                  'Production Loss in cost (USD)': e?.target?.value
                })
              }
            />
          </Col>
          <Col xs="6">
            <CustomLabel label="Spare Part lead time (days)" />
            <Input
              type="text"
              defaultValue={pleValues?.['Spare Part lead time (days)']}
              onChange={(e) =>
                setPleValues({
                  ...pleValues,
                  'Spare Part lead time (days)': e?.target?.value
                })
              }
            />
          </Col>
          <Col xs="6">
            <CustomLabel label="PLE" />
            {calculatePle()}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={() => setCalculatePleModal(false)}>
          Close
        </Button>
        <Button
          color="warning"
          onClick={() => {
            console.log('save')
            setFlocdata(
              (flocData || analysisData)?.map((a: any) =>
                a?.['Failure Mode'] === selected?.['Failure Mode']
                  ? {
                      ...a,
                      'Contractor hours': pleValues?.['Contractor hours'],
                      'Staff hours': pleValues?.['Staff hours'],
                      'Material cost': pleValues?.['Material cost'],
                      'Production Loss in cost (USD)':
                        pleValues?.['Production Loss in cost (USD)'],
                      'Spare Part lead time (days)':
                        pleValues?.['Spare Part lead time (days)'],
                      'Spare part lead time (Contractor)':
                        pleValues?.['Spare part lead time (Contractor)'],
                      'Material Spare cost (Contractor)':
                        pleValues?.['Material Spare cost (Contractor)'],
                      'Craft man cost (Contractor)':
                        pleValues?.['Craft man cost (Contractor)'],
                      'Operator cost (Contractor)':
                        pleValues?.['Operator cost (Contractor)'],
                      'Staff hours (Contractor)':
                        pleValues?.['Staff hours (Contractor)'],
                      'Craft man cost': pleValues?.['Craft man cost'],
                      'Operator Cost': pleValues?.['Operator Cost'],
                      PLE: calculatePle()
                    }
                  : a
              )
            )
            setCalculatePleModal(false)
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const MeiModal = ({
  calculateMeiModal,
  selected,
  meiValues,
  setMeiValues,
  calculateMei,
  setCalculateMeiModal,
  setFlocdata,
  flocData,
  analysisData
}: {
  calculateMeiModal: any
  selected: any
  meiValues: any
  setMeiValues: any
  calculateMei: any
  setCalculateMeiModal: any
  setFlocdata: any
  flocData: any
  analysisData: any
}) => {
  return (
    <Modal isOpen={calculateMeiModal} size="xl">
      <SubHeader
        header={`Calculate MEI Value for ${selected?.['Failure Mode']}`}
      />
      <ModalBody>
        <Row>
          <Col xs="6">
            <CustomLabel label="PONC Without Maintenance" />
            <Input
              type="text"
              defaultValue={meiValues?.['PONC Without Maintenance']}
              onChange={(e) =>
                setMeiValues({
                  ...meiValues,
                  'PONC Without Maintenance': e?.target?.value
                })
              }
            />
          </Col>
          <Col xs="6">
            <CustomLabel label="PONC With Maintenance" />
            <Input
              type="text"
              defaultValue={meiValues?.['PONC With Maintenance']}
              onChange={(e) =>
                setMeiValues({
                  ...meiValues,
                  'PONC With Maintenance': e?.target?.value
                })
              }
            />
          </Col>
          <Col xs="6">
            <CustomLabel label="PC Annual $" />
            <Input
              type="text"
              defaultValue={meiValues?.['PC Annual $']}
              onChange={(e) =>
                setMeiValues({
                  ...meiValues,
                  'PC Annual $': e?.target?.value
                })
              }
            />
          </Col>
          <Col xs="6">
            <CustomLabel label="MEI Thresold" />
            {calculateMei()}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={() => setCalculateMeiModal(false)}>
          Close
        </Button>
        <Button
          color="warning"
          onClick={() => {
            console.log('save')
            setFlocdata(
              (flocData || analysisData)?.map((a: any) =>
                a?.['Failure Mode'] === selected?.['Failure Mode']
                  ? {
                      ...a,
                      'PONC Annual without maintenance':
                        meiValues?.['PONC Without Maintenance'],
                      'PONC Annual with maintenance':
                        meiValues?.['PONC With Maintenance'],
                      'POC Annual $': meiValues?.['PC Annual $'],
                      'MEI Threshoold': calculateMei()
                    }
                  : a
              )
            )
            setCalculateMeiModal(false)
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}
