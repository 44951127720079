import { Route, Routes } from 'react-router-dom'
import MmsServices from './MmsServices'
import AlFirsServices from './AlFirsServices'
import AlHierarchy from './AlHierarchy'
import AlPmActions from './alPmActions'
import ALPmList from './alPMList'
import AlViewPM from './AlViewPM'
import AlWorkOrderList from './AlWorkorders'
import AlViewWo from './AlViewWo'
import AlWoActions from './AlWoActions'
import AlWorkRequestForm from './AlWorkRequestForm'
import AlIncidentReportingActions from './AlIncidentReportingActions'
import AlStage1Form from './AlStage1Form'
import AlFirsTable from './AlFirsTable'
import AlViewReport from './AlViewReport'
import AlFirsSelectIncident from './AlFirsSelectIncidentType'
import AlNearmissActions from './AlNearmissActions'
import AlSheCardActions from './AlSheCardActions'
import AlSheCardCreate from './AlSheCardCreate'
import AlSheCardList from './AlSheCardList'
import AlViewSheCard from './AlViewSheCard'
import AlCloseSheCard from './AlCloseSheCard'
import AlRecommendSheCard from './AlRecommendSheCard'
import AlUsers from './AlUsers'
import AlAdminActions from './AlAdminActions'
import AlVendors from './AlVendor'
import AlCrew from './AlCrew'
import AlTrainingActions from './AlTrainingActions'
import AlTrainingList from './AlTrainingList'
import AlPepTalk from './AlPepTalk'
import AlWarningList from './AlWarningList'
import AlWarningLetter from './AlWarningLetter'
import AlScoreCardDashboard from './AlScoreCard'
import AlTrainingKpi from './AlTrainingKPI'
import AlCreatePM from './AlCreatePM'
import AlViewCounterPM from './AlViewCounterPM'
import AlRunningHours from './AlRunningHours'
import AlPmJobList from './AlPmJobCodes'
import AlPmJobDescription from './AlJobDescription'
import AlKPIStatistics from './AlMmsKpi'

export default function AlRoutes() {
  return (
    <Routes>
      <Route path="/mms" element={<MmsServices />} />
      <Route path="/mms/maintenance" element={<AlWoActions />} />
      <Route
        path="/mms/maintenance/create"
        element={<AlWorkRequestForm onBack="/ashokleyland/mms/maintenance" />}
      />
      <Route
        path="/mms/maintenance/created"
        element={<AlWorkOrderList status="Created" />}
      />
      <Route
        path="/mms/maintenance/waitingformaterial"
        element={<AlWorkOrderList status="Waiting For Material" />}
      />
      <Route
        path="/mms/maintenance/waitingformaterial/:id"
        element={
          <AlViewWo
            title="Waiting For Material"
            onBack="/ashokleyland/mms/maintenance/waitingformaterial"
          />
        }
      />
      <Route
        path="/mms/maintenance/waitingfor3rdparty"
        element={<AlWorkOrderList status="Waiting For 3rd Party" />}
      />
      <Route
        path="/mms/maintenance/waitingfor3rdparty/:id"
        element={
          <AlViewWo
            title="Waiting For 3rd Party"
            onBack="/ashokleyland/mms/maintenance/waitingfor3rdparty"
          />
        }
      />
      <Route
        path="/mms/maintenance/waitingforshutdown"
        element={<AlWorkOrderList status="Waiting For Shutdown" />}
      />
      <Route
        path="/mms/maintenance/waitingforshutdown/:id"
        element={
          <AlViewWo
            title="Waiting For Shutdown"
            onBack="/ashokleyland/mms/maintenance/waitingforshutdown"
          />
        }
      />
      <Route
        path="/mms/maintenance/created/:id"
        element={
          <AlViewWo
            title="CREATED"
            onBack="/ashokleyland/mms/maintenance/created"
          />
        }
      />
      <Route
        path="/mms/maintenance/wocreated"
        element={<AlWorkOrderList status="Workorder Created" />}
      />
      <Route
        path="/mms/maintenance/wocreated/:id"
        element={
          <AlViewWo
            title="Work Order CREATED"
            onBack="/ashokleyland/mms/maintenance/wocreated"
          />
        }
      />
      <Route path="/mms/hierarchy" element={<AlHierarchy />} />
      <Route path="/mms/kpi" element={<AlKPIStatistics />} />
      <Route path="/pm/" element={<AlPmActions />} />
      <Route path="/pm/runningHours" element={<AlRunningHours />} />
      <Route path="/pm/joblist" element={<AlPmJobList />} />
      <Route path="/pm/joblist/:id" element={<AlPmJobDescription />} />
      <Route
        path="/pm/runningHours/:id"
        element={<AlViewCounterPM back="/ashokleyland/pm/runningHours" />}
      />
      <Route
        path="/pm/create"
        element={<AlCreatePM onBack={'/ashokleyland/pm'} />}
      />
      <Route
        path="/mms/maintenance/todo"
        element={<AlWorkOrderList status="Todo" />}
      />
      <Route
        path="/mms/maintenance/inprogress"
        element={<AlWorkOrderList status="In Progress" />}
      />
      <Route
        path="/mms/maintenance/todo/:id"
        element={
          <AlViewWo title="TODO" onBack="/ashokleyland/mms/maintenance/todo" />
        }
      />
      <Route
        path="/mms/maintenance/inprogress/:id"
        element={
          <AlViewWo
            title="In Progress"
            onBack="/ashokleyland/mms/maintenance/inprogress"
          />
        }
      />
      <Route
        path="/mms/maintenance/closed"
        element={<AlWorkOrderList status="Closed" />}
      />
      <Route
        path="/mms/maintenance/closed/:id"
        element={
          <AlViewWo
            title="Closed"
            onBack="/ashokleyland/mms/maintenance/closed"
          />
        }
      />
      <Route path="/pm/list" element={<ALPmList />} />
      <Route path="/pm/list/:number" element={<AlViewPM />} />
      {/* FIRS Routes */}
      <Route path="/firs" element={<AlFirsServices />} />
      <Route path="/firs/select" element={<AlFirsSelectIncident />} />
      {/* Incident Reporting Routes */}

      <Route
        path="/firs/incidentreporting"
        element={<AlIncidentReportingActions />}
      />
      <Route
        path="/firs/incidentreporting/stage1"
        element={
          <AlStage1Form
            title="CREATE AN INCIDENT REPORT"
            onBack="/ashokleyland/firs/incidentreporting"
          />
        }
      />
      <Route
        path="/firs/incidentreporting/stage2"
        element={
          <AlFirsTable
            status={'Created'}
            onBack="/ashokleyland/firs/incidentreporting"
          />
        }
      />
      <Route
        path="/firs/incidentreporting/stage2/:id"
        element={
          <AlViewReport onBack="/ashokleyland/firs/incidentreporting/stage2" />
        }
      />
      <Route
        path="/firs/incidentreporting/stage3"
        element={
          <AlFirsTable
            status={'Stage3'}
            onBack="/ashokleyland/firs/incidentreporting"
          />
        }
      />
      <Route
        path="/firs/incidentreporting/stage3/:id"
        element={
          <AlViewReport onBack="/ashokleyland/firs/incidentreporting/stage3" />
        }
      />
      <Route
        path="/firs/incidentreporting/stage4"
        element={
          <AlFirsTable
            status={'Stage4'}
            onBack="/ashokleyland/firs/incidentreporting"
          />
        }
      />
      <Route
        path="/firs/incidentreporting/stage4/:id"
        element={
          <AlViewReport onBack="/ashokleyland/firs/incidentreporting/stage4" />
        }
      />
      <Route
        path="/firs/incidentreporting/stage5"
        element={
          <AlFirsTable
            status={'Stage5'}
            onBack="/ashokleyland/firs/incidentreporting"
          />
        }
      />
      <Route
        path="/firs/incidentreporting/stage5/:id"
        element={
          <AlViewReport onBack="/ashokleyland/firs/incidentreporting/stage5" />
        }
      />
      <Route
        path="/firs/incidentreporting/closed"
        element={
          <AlFirsTable
            status={'Closed'}
            onBack="/ashokleyland/firs/incidentreporting"
          />
        }
      />
      <Route
        path="/firs/incidentreporting/closed/:id"
        element={
          <AlViewReport onBack="/ashokleyland/firs/incidentreporting/closed" />
        }
      />
      <Route
        path="/firs/incidentreporting/warninglist"
        element={
          <AlWarningList onBack="/ashokleyland/firs/incidentreporting" />
        }
      />
      <Route
        path="/firs/incidentreporting/warninglist/:id"
        element={
          <AlWarningLetter onBack="/ashokleyland/firs/incidentreporting/warninglist" />
        }
      />

      <Route
        path="/firs/incidentreporting/rejected"
        element={
          <AlFirsTable
            status={'Rejected'}
            onBack="/ashokleyland/firs/incidentreporting"
          />
        }
      />
      <Route
        path="/firs/incidentreporting/rejected/:id"
        element={
          <AlViewReport onBack="/ashokleyland/firs/incidentreporting/rejected" />
        }
      />

      {/* Nearmiss routes */}
      <Route path="/firs/nearmiss" element={<AlNearmissActions />} />
      <Route
        path="/firs/nearmiss/stage1"
        element={
          <AlStage1Form
            title="REPORT NEARMISS INCIDENT"
            onBack="/ashokleyland/firs/nearmiss"
          />
        }
      />

      {/* AL TSHE Card Routes */}
      <Route
        path="/firs/tshe"
        element={<AlSheCardActions onBack="/ashokleyland/firs" />}
      />
      <Route path="/firs/tshe/create" element={<AlSheCardCreate />} />
      <Route
        path="/firs/tshe/created"
        element={<AlSheCardList status="created" />}
      />
      <Route path="/firs/tshe/created/:id" element={<AlViewSheCard />} />
      <Route
        path="/firs/tshe/inProgress"
        element={<AlSheCardList status="icProgress" />}
      />
      <Route
        path="/firs/tshe/inProgress/:id"
        element={
          <AlCloseSheCard
            onBack="/ashokleyland/firs/tshe/inProgress"
            currentStatus="icProgress"
            nextStatus="departmentClosed"
          />
        }
      />
      <Route
        path="/firs/tshe/recommendAward"
        element={<AlSheCardList status="recomendAward" />}
      />
      <Route
        path="/firs/tshe/recommendAward/:id"
        element={
          <AlRecommendSheCard onBack="/ashokleyland/firs/tshe/recommendAward" />
        }
      />
      <Route
        path="/firs/tshe/awarded"
        element={<AlSheCardList status="awarded" />}
      />
      <Route
        path="/firs/tshe/awarded/:id"
        element={
          <AlRecommendSheCard onBack="/ashokleyland/firs/tshe/awarded" />
        }
      />
      <Route
        path="/firs/tshe/warninglist"
        element={<AlWarningList onBack={'/ashokleyland/firs/tshe'} />}
      />
      <Route
        path="/firs/tshe/warninglist/:number"
        element={
          <AlWarningLetter onBack={'/ashokleyland/firs/tshe/warninglist'} />
        }
      />
      <Route
        path="/firs/tshe/closeSafetyOfficer"
        element={<AlSheCardList status="departmentClosed" />}
      />
      <Route
        path="/firs/tshe/closeSafetyOfficer/:id"
        element={
          <AlCloseSheCard
            onBack="/ashokleyland/firs/tshe/closeSafetyOfficer"
            currentStatus="departmentClosed"
            nextStatus="safetyOfficerClosed"
          />
        }
      />
      <Route
        path="/firs/tshe/closeOim"
        element={<AlSheCardList status="safetyOfficerClosed" />}
      />
      <Route
        path="/firs/tshe/closeOim/:id"
        element={
          <AlCloseSheCard
            onBack="/ashokleyland/firs/tshe/closeOim"
            currentStatus="safetyOfficerClosed"
            nextStatus="closed"
          />
        }
      />
      <Route
        path="/firs/tshe/closed"
        element={<AlSheCardList status="closed" />}
      />
      <Route
        path="/firs/tshe/closed/:id"
        element={<AlRecommendSheCard onBack="/ashokleyland/firs/tshe/closed" />}
      />
      {/* Admin Routes */}
      <Route path="/admin" element={<AlAdminActions />} />
      <Route path="/admin/vendors" element={<AlVendors />} />
      <Route path="/admin/crew" element={<AlCrew />} />
      <Route path="/admin/trainingdata" element={<AlUsers />} />
      {/* Trainig Routes */}
      <Route path="/training" element={<AlTrainingActions />} />
      <Route path="/training/traininglist" element={<AlTrainingList />} />
      <Route path="/training/trainingdata" element={<AlUsers />} />
      <Route path="/training/peptalkawareness" element={<AlPepTalk />} />
      <Route path="/training/scorecard" element={<AlScoreCardDashboard />} />
      <Route path="/training/kpi" element={<AlTrainingKpi />} />
    </Routes>
  )
}
