import DashboardMenuItem from './DashboardMenuItem'
import { Routes } from '../constants/navbarRoutes'
import { useContext, useState } from 'react'
import { UserContext } from '../../App'
import { Designations } from '../constants/Designations'

export const canViewPermitAudit = [
  Designations.MM,
  Designations.HSE,
  Designations.PIC,
  Designations.IM,
  Designations.DIM,
  Designations.FO
]

export default function DashboardNavigation() {
  const { userData } = useContext(UserContext)
  const [finalRoutes, setFinalRoutes] = useState(Routes)
  const permitAuditRoute = {
    label: 'Permit Audit',
    routeName: 'permitAudit/create'
  }

  if (canViewPermitAudit.includes(userData?.jobTitle)) {
    if (!finalRoutes?.find((n: any) => n?.label === 'Permit Audit')) {
      setFinalRoutes([...finalRoutes, permitAuditRoute])
    }
  }

  return (
    <div
      style={{
        marginTop: 20
      }}
    >
      <div
        style={{
          marginLeft: 15,
          marginRight: 10
        }}
      >
        {finalRoutes.map((route: any, index: any) => (
          <DashboardMenuItem
            key={`${route?.label}-${index}`}
            label={route.label}
            navigateTo={route.routeName}
            base="/eptw/"
          />
        ))}
      </div>
    </div>
  )
}
