import { Col, Input, Row, Spinner, Table } from 'reactstrap'
import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import CustomLabel from '../../components/InputFields/CustomLabel'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { Link } from '../workManagement/PmTree'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { pmAPI } from '../../components/serverurl'

type pm = {
  _id: String
  workDescription: string
  number: number
  description: string
  actionCodeId: string
  workType: string
  orgCode: string
  priorityId: number
  interval: number | any
  pmIntervalUnit: string
  maintOrg: string
  plannedHours: number
  resources: string
  cost: string | any
  equipmentNumber: string | any
  equipmentDescription: string
  jobPlanCode: string
  jobPlan: string
  nextDueDate: Date
  triggerDate: Date
  workTypeClass: String
}

const PmTable = ({
  data,
  setType,
  isLoading,
  isRefetching,
  setPriority,
  setSelectedPM,
  setEditTrigger,
  setWorkClass
}: {
  data: [pm]
  setType: any
  isLoading: boolean
  isRefetching: boolean
  setPriority: any
  setSelectedPM: any
  setEditTrigger: any
  setWorkClass: any
}) => {
  const navigate = useNavigate()

  return (
    <Row>
      <Row>
        <Col xs="2">
          <CustomLabel label="Work Type" />
          <Input type="select" onChange={(e) => setType(e?.target?.value)}>
            <option value="">Select</option>
            <option>PM</option>
            <option>SCPM</option>
            <option>CLASS</option>
          </Input>
        </Col>
        <Col xs="2">
          <CustomLabel label="Priority" />
          <Input type="select" onChange={(e) => setPriority(e?.target?.value)}>
            <option value="">Select</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
          </Input>
        </Col>
        <Col xs="2">
          <CustomLabel label="Work Type Class" />
          <Input type="select" onChange={(e) => setWorkClass(e?.target?.value)}>
            <option value="">Select</option>
            <option>Calender Based</option>
            <option>Running hours based </option>
          </Input>
        </Col>
      </Row>
      <Table>
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Number</th>
            <th>Description</th>
            <th>Equipment</th>
            <th>Job Plan Code</th>
            <th>Work Type</th>
            <th>Type</th>
            <th>Priority</th>
            <th>Planned Hours</th>
            <th>Interval</th>
            <th>Interval Type</th>
            <th>Next Due Date</th>
            <th>Trigger Date</th>
          </tr>
        </thead>
        <tbody>
          {isLoading || isRefetching ? (
            <tr>
              <td colSpan={12}>
                <Row
                  style={{
                    height: 500,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Spinner />
                </Row>
              </td>
            </tr>
          ) : (
            data?.map((pm: pm, index: number) => (
              <tr key={`${index}-${pm?.number}`}>
                <td>{incrementby1(index)}</td>
                <td>
                  <Link
                    onClick={() =>
                      navigate(`/cmms/planning/pm/list/${pm?._id}`)
                    }
                  >
                    {pm?.number}
                  </Link>
                </td>
                <td>{pm?.workDescription}</td>
                <td>{pm?.equipmentNumber}</td>
                <td>{pm?.jobPlanCode}</td>
                <td>{pm?.workType}</td>
                <td>{pm?.workTypeClass}</td>
                <td>{pm?.priorityId}</td>
                <td>{pm?.plannedHours}</td>
                <td>{pm?.interval}</td>
                <td>{pm?.pmIntervalUnit}</td>
                <td>{getReadableTime(pm?.nextDueDate)}</td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <Link
                    onClick={() => {
                      setSelectedPM(pm)
                      setEditTrigger(true)
                    }}
                  >
                    {getReadableTime(pm?.triggerDate)}
                  </Link>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {Number(data?.length) === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Preventive Maintenance Found
            {/* <span
              style={{
                color: 'blue',
                textDecoration: 'underline',
                marginLeft: 2,
                cursor: 'pointer'
              }}
              onClick={() =>
                navigate('/workManagement/correctiveMaintenance/create')
              }
            >
              Create one?
            </span> */}
          </p>
        </Row>
      )}
    </Row>
  )
}

export default function PmList() {
  const { accounts, instance } = useMsal()

  const [type, setType] = useState()
  const [priority, setPriority] = useState()
  const [editTrigger, setEditTrigger] = useState(false)
  const [selectedPM, setSelectedPM]: any = useState()
  const [workClass, setWorkClass] = useState()

  const fetchPmList = async () => {
    const response = await makeAuthenticatedRequest(
      `${pmAPI}?workType=${type}&priority=${priority}&classType=${workClass}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch, isLoading, isRefetching }: any = useQuery(
    'pmList',
    fetchPmList
  )
  const pm = data?.pmList

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, priority, workClass])

  return (
    <ProtectedLayout
      onBack="/cmms/planning/pm"
      title={'PREVENTIVE MAINTENANCE LIST'}
    >
      <Row style={{ margin: 10 }}>
        <PmTable
          data={pm}
          setType={setType}
          isLoading={isLoading}
          isRefetching={isRefetching}
          setPriority={setPriority}
          setSelectedPM={setSelectedPM}
          setEditTrigger={setEditTrigger}
          setWorkClass={setWorkClass}
        />
      </Row>
      <SubmitModalWithForm
        isOpen={editTrigger}
        onClose={(val: any) => {
          setEditTrigger(false)
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()
          const res = await makeAuthenticatedRequest(
            `${pmAPI}/calenderBased/${selectedPM?._id}`,
            'PATCH',
            selectedPM,
            accounts,
            instance
          )

          if (res?.status === 'success') {
            refetch()
          }

          return res?.status
        }}
        size="xs"
        header="Update Trigger Date"
        form={
          <Row>
            <Col>
              <CustomLabel label="Trigger Date" />
              <Input
                type="date"
                onChange={(e: any) =>
                  setSelectedPM({
                    ...selectedPM,
                    triggerDate: e?.target?.value
                  })
                }
              />
            </Col>
          </Row>
        }
      />
    </ProtectedLayout>
  )
}
