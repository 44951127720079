import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import arrayMutators from 'final-form-arrays'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { HiOutlineSquares2X2, HiSquares2X2 } from 'react-icons/hi2'
import ReactPaginate from 'react-paginate'
import { Button, Col, Input, Row, Table } from 'reactstrap'
import { hoecRiskMatrixColors } from '../components/constants/riskMatrixColors'
import CustomLabel from '../components/InputFields/CustomLabel'
import LabeledDateTimeInput from '../components/InputFields/DateTime'
import LabeledCheckBoxes from '../components/InputFields/LabeledCheckBoxes'
import LabeledRadioButtons from '../components/InputFields/LabeledRadioButton'
import LabeledTextInput from '../components/InputFields/LabeledTextInput'
import LabledParagraphInput from '../components/InputFields/LabledParagraph'
import MultiSelect from '../components/InputFields/MultiSelect'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import SectionHeader from '../components/Layout/SectionHeader'
import toNormalCase from '../components/lib/toNormalCase'
import SeverityRiskMatrix from '../components/Modals/SeverityRiskMatrix'
import PermitHeaderDetails from '../components/PermitHeaderDetails'
import {
  incidentReport,
  stage2API,
  stage3API,
  stage4API
} from '../components/serverurl'
import Spacer from '../components/Spacer'

import theme from './../components/constants/defaultTheme.json'
import { departmentList, getOptions } from './firs'
import { renderRow, SubHeader } from './VerifyCM'
import { renderArray } from './WaitingForPIC'
import { MdDelete } from 'react-icons/md'
import { renderField } from '../components/Modals/CloseCMPermit'
import Loader from '../components/Loader'
import { useMsal } from '@azure/msal-react'
import { makeAuthenticatedRequest } from '../apiRequests'

const SidePane = ({
  list,
  activeItem,
  setActiveItem,
  pageNumber,
  setPageNumber
}: any) => {
  if (!activeItem) {
    setActiveItem(list?.reports?.[0])
  }

  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      {list?.reports?.map((type: any) => (
        <div
          key={type.woNumber}
          style={{
            border:
              activeItem === type
                ? `2px solid ${theme.borderColor.white}`
                : 'none',
            display: 'flex',
            padding: activeItem === type ? 5 : 7,
            backgroundColor:
              activeItem === type ? theme.colors.blue : 'transparent',
            borderRadius: 5,
            minWidth: '18vh',
            cursor: 'pointer',
            marginBottom: 35,
            boxShadow:
              'rgba(0, 0, 0, 0.4) 0px 1px 2px, rgba(0, 0, 0, 0.3) 0px 2px 3px -3px, rgba(0, 0, 0, 0.2) 0px -2px 0px inset'
          }}
          onClick={() => setActiveItem(type)}
        >
          {activeItem === type ? (
            <HiSquares2X2
              style={{
                marginTop: 4,
                color:
                  activeItem === type
                    ? theme.colors.white
                    : theme.colors.lightBlue
              }}
            />
          ) : (
            <HiOutlineSquares2X2
              style={{
                marginTop: 4,
                color:
                  activeItem === type
                    ? theme.colors.white
                    : theme.colors.lightBlue
              }}
            />
          )}
          <p
            style={{
              margin: '0px 0px 0px 10px',
              fontWeight: 700,
              color:
                activeItem === type
                  ? theme.fontColor.white
                  : theme.fontColor.lightBlue
            }}
          >
            FIR No: {type.woNumber}
            <p style={{ margin: 0 }}>Title - {type?.stage1?.incidentTitle}</p>
            <p style={{ margin: 0 }}>Equipment - {type?.stage1?.equipment}</p>
          </p>
        </div>
      ))}
      {list?.reports?.length > 10 && (
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={list?.totalCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={(a) => setPageNumber(a?.selected)}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      )}
    </div>
  )
}

export const immidiateCausesSA = [
  'Operating Equipment Without Authority',
  'Failure to warn',
  'Failure to secure',
  'Oprating at improper speed',
  'Rendering safety devices inoperable',
  'Removing safety devices',
  'Using defective equipment',
  'Using equipment improperly',
  'Failure to use PPE properly',
  'Improper loading',
  'Improper placement',
  'Improper lifting',
  'Improper position for task',
  'Servicing equipment in operation',
  'Horseplay',
  'Under influence of alcohol or drugs'
]

export const immidiateCausesSC = [
  'Inadequate guards or barriers',
  'Inadequate protective equipment',
  'Defective tools, equipment or material',
  'Congestion or restricted action',
  'Inadequate warning system',
  'Fire and explosion hazards',
  'Poor housekeeping; disorder',
  'Noise exposures',
  'Extreme temperature gradients',
  'Extreme pressure gradients',
  'Radiation exposures',
  'Inadequate or improper illumination',
  'nadequate ventilation',
  'Gases, vapours, dust, smoke, etc'
]

export const personalFactors = [
  'Inadequate capability',
  'Lack of knowledge',
  'Lack of Skill',
  'Physical or mental stress',
  'Improper motivation',
  'Lack of training',
  'Poor Job Planning',
  'Conflicting objectives'
]

export const jobFactors = [
  'Inadequate supervision/ leadership',
  'Inadequate engineering',
  'Inadequate purchasing',
  'Inadequate maintenance',
  'Inadequate tools/equipment',
  'Inadequate Work standards',
  'Wear & tear',
  'Abuse or misuse'
]

export const renderDate = ({
  input,
  label,
  type,
  meta: { touched, error }
}: any) => (
  <div>
    <div>
      <Input {...input} type={'date'} />
      {touched && error && <span style={{ color: 'red' }}>{error}</span>}
    </div>
  </div>
)

export const renderDepartments = ({
  input,
  label,
  type,
  meta: { touched, error }
}: any) => (
  <Row>
    <Col>
      <LabeledRadioButtons
        {...input}
        type={'radio'}
        list={departmentList}
        occupy={12}
      />
    </Col>
    {touched && error && <span style={{ color: 'red' }}>{error}</span>}
  </Row>
)

const renderActions = ({ fields, meta: { error } }: any) => (
  <>
    {fields.map((stock: any, index: any) => (
      <React.Fragment key={index}>
        <Row style={{ marginBottom: 3 }}>
          <Col xs="2">
            <Field
              name={`${stock}.code`}
              type="text"
              component={renderField}
              label={`Corrective Action Code #${index + 1}`}
            />
          </Col>
          <Col xs="3">
            <Field
              name={`${stock}.correctiveAction`}
              type="text"
              component={renderField}
              label={`Recomended Corrective Action #${index + 1}`}
            />
          </Col>
          <Col xs="2">
            <Field
              name={`${stock}.responsiblePerson`}
              type="text"
              component={renderField}
              label={`Responsible Person #${index + 1}`}
            />
          </Col>
          <Col xs="2">
            <Field
              name={`${stock}.date`}
              type="datetime"
              component={renderDate}
            />
          </Col>
          <Col xs="2">
            <Field
              name={`${stock}.refeNo`}
              type="text"
              component={renderField}
              label={`Reference Number #${index + 1}`}
            />
          </Col>
          <Col xs="1">
            <MdDelete
              onClick={() => fields.remove(index)}
              style={{ color: 'red' }}
            />
          </Col>
        </Row>
      </React.Fragment>
    ))}
    <>
      <Button color="link" onClick={() => fields.push()}>
        Add Corrective Action
      </Button>
    </>
  </>
)

const renderInvestigations = ({ fields, meta: { error } }: any) => (
  <>
    {fields.map((stock: any, index: any) => (
      <React.Fragment key={index}>
        <Row style={{ marginBottom: 3 }}>
          <Col xs="2">
            <Field
              name={`${stock}.name`}
              type="text"
              component={renderField}
              label={`Name #${index + 1}`}
            />
          </Col>
          <Col xs="9">
            <Field name={`${stock}.department`} component={renderDepartments} />
          </Col>
          <Col xs="1">
            <MdDelete
              onClick={() => fields.remove(index)}
              style={{ color: 'red' }}
            />
          </Col>
        </Row>
      </React.Fragment>
    ))}
    <>
      <Button color="link" onClick={() => fields.push()}>
        Add Investigation Member
      </Button>
    </>
  </>
)

export default function ApproveStage1() {
  const [data, setData]: any = useState([])
  const [pageNumber, setPageNumber]: any = useState(1)
  const [loading, setLoading]: any = useState(false)
  const [active, setActive]: any = useState()
  const [submitting, setSubmitting] = useState(false)
  const { stage1, stage2, stage3, stage4 } = active || {}

  const { accounts, instance } = useMsal()

  useEffect(() => {
    fetchReports()
  }, [pageNumber])

  const fetchReports = async () =>
    await makeAuthenticatedRequest(
      incidentReport,
      'GET',
      null,
      accounts,
      instance
    )
      .then((reponse: any) => {
        setData(reponse)
      })
      .catch((err: any) => console.error(err))

  const fields = {
    'Work Order Number': active?.woNumber,
    'Incident Title': active?.stage1?.incidentTitle,
    'Created On': moment(active?.stage1?.createdAt).format('DD/MM/YYYY')
  }

  const permitData: any = {
    Originator: stage1?.originator,
    Equipment: stage1?.equipment,
    'Tag Number': stage1?.tagNumber,
    Location: stage1?.location,
    Department: stage1?.department,
    Description: stage1?.description,
    Date: moment(stage1?.date).format('DD-MM-YYYY'),
    'Incident Type': renderArray(stage1?.incidentType, false),
    'Other Incident Type': stage1?.others_incidentType,
    'Immidiate Actioin Required': toNormalCase(stage1?.actionRequired),
    'Immidiate Action Description': stage1?.immidateDescription,
    Service: stage1?.service,
    'Production Loss': stage1?.prodLoss,
    'Injury Details': renderArray(stage1?.injuryDetails, false),
    'Other Injury Details': stage1?.others_injuryDetails,
    'Part of Body': renderArray(stage1?.partOgBody, false),
    Witnes: stage1?.witness,
    'Installation Manager': stage1?.im,
    'Area Authority': stage1?.areaAuthority,
    'Safety Officer': stage1?.safetyOfficer
  }

  const injuredDetails: any = {
    Name: stage1?.victimName,
    Gender: stage1?.victimGender,
    'Date of Birth': stage1?.dateofBirth,
    Age: stage1?.victimAge,
    Employeer: stage1?.victimEmployeer,
    'Job Title': stage1?.victimJobTitle,
    'Job Experience': stage1?.victimExperience,
    'Employment Type': stage1?.victimEmploymentType,
    'Duty Type': stage1?.victimDutyType
  }

  const stage2Details: any = {
    'Standard Action': renderArray(stage2?.immidiateCausesSA, false),
    'Others Standard Action': stage1?.others_immidiateCausesSA,
    'Standard Condition': renderArray(stage2?.immidiateCausesSC, false),
    'Others Standard Condition': stage2?.others_immidiateCausesSC,
    'Immidiate Cause Description': stage2?.immidiateCauseDescription,
    'Personal Factors': renderArray(stage2?.personalFactors, false),
    'Others Personal Factors': stage2?.others_personalFactors,
    'Job Factors': renderArray(stage2?.jobFactors, false),
    'Others Job Factors': stage2?.others_jobFactors,
    'Basic Cause Description': stage2?.basicCauseDescription,
    'Others Observations': stage2?.otherObservations
  }

  const stage3Details: any = {
    'Assigned Department': stage3?.assignDepartment,
    'Investigation Member': stage3?.investigationMember,
    'Start Date': moment(stage3?.startDate).format('DD-MM-YYYY'),
    'Completion Date': moment(stage3?.completionDate).format('DD-MM-YYYY'),
    'Incident Repeatable': stage3?.incidentRepeatable,
    Priority: stage3?.assignPriority
  }

  const stage4Details: any = {
    'Statuatory Reporting': stage4?.statuatoryReporting,
    'Statuatory Reporting Description': stage4?.statuatoryReportingDescription,
    'Root Cause Analysis': stage4?.rootCauseAnalysis,
    'Implementation Schedule': stage4?.implementationSchedule
  }

  if (data?.length === 0) {
    return <Loader />
  }

  const approveStage2 = async (values: any) => {
    setSubmitting(true)
    await axios
      .post(stage2API, { ...values, incidentReport: active?._id })
      .then()
      .catch((err) => console.warn(err))
    await fetchReports()
    setSubmitting(false)
  }

  const approveStage3 = async (values: any) => {
    setSubmitting(true)
    await axios
      .post(stage3API, { ...values, incidentReport: active?._id })
      .then()
      .catch((err) => console.warn(err))
    await fetchReports()
    setSubmitting(false)
  }

  const approveStage4 = async (values: any) => {
    setSubmitting(true)
    await axios
      .post(stage4API, { ...values, incidentReport: active?._id })
      .then()
      .catch((err) => console.warn(err))
    await fetchReports()
    setSubmitting(false)
  }

  const closeIncidentReport = async (values: any) => {
    setSubmitting(true)
    await axios
      .patch(incidentReport, { ...values, incidentReport: active?._id })
      .then()
      .catch((err) => console.error(err))
    await fetchReports()
    setSubmitting(false)
  }

  return (
    <ProtectedLayout
      onBack="/incidentReporting"
      hasSideList
      sidePanel={
        <SidePane
          list={data}
          activeItem={active}
          setActiveItem={setActive}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      }
    >
      <SectionHeader title="First Incident Reporting System" />
      <PermitHeaderDetails permit={fields} />
      <SubHeader header="Stage 1" />
      {Object.keys(permitData).map((key) => renderRow(key, permitData[key]))}
      <SubHeader header="Deatils of Injured" />
      {Object.keys(injuredDetails).map((key) =>
        renderRow(key, injuredDetails[key])
      )}
      {stage2 && (
        <>
          {' '}
          <SubHeader header="Stage 2" />
          {Object.keys(stage2Details).map((key) =>
            renderRow(key, stage2Details[key])
          )}
        </>
      )}
      {stage3 && (
        <>
          <SubHeader header="Stage 3" />
          {Object.keys(stage3Details).map((key) =>
            renderRow(key, stage3Details[key])
          )}
        </>
      )}
      {stage4 && (
        <>
          <SubHeader header="Stage 4" />
          {Object.keys(stage4Details).map((key) =>
            renderRow(key, stage4Details[key])
          )}
          <Spacer height={20} />
          <Table bordered striped style={{ borderColor: '#000' }}>
            <thead>
              <tr style={{ backgroundColor: 'orange' }}>
                {['Code', 'Measure', 'Person', 'Date', 'Ref.No']?.map(
                  (i: any) => <th key={i}>{i}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {active?.stage4?.actions?.map((action: any, index: any) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{action.code}</td>
                    <td>{action.correctiveAction}</td>
                    <td>{action?.responsiblePerson}</td>
                    <td>{action?.date}</td>
                    <td>{action?.refeNo}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
          <Spacer height={20} />
          <Table bordered striped style={{ borderColor: '#000' }}>
            <thead>
              <tr style={{ backgroundColor: 'orange' }}>
                {['Investigator Name', 'Department']?.map((i: any) => (
                  <th key={i}>{i}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {active?.stage4?.investigationTeam?.map(
                (action: any, index: any) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td>{action?.name}</td>
                      <td>{action?.department}</td>
                    </tr>
                  </React.Fragment>
                )
              )}
            </tbody>
          </Table>
        </>
      )}
      {!active?.stage2 && (
        <>
          <SubHeader header="Stage 2 Form" />
          <Form
            onSubmit={(values) => approveStage2(values)}
            render={({ handleSubmit, form, hasValidationErrors, values }) => (
              <form>
                <Row>
                  <LabeledCheckBoxes
                    name="immidiateCausesSA"
                    label="Immidiate Causes - Substandard Actions "
                    list={getOptions(immidiateCausesSA)}
                    hasOthers
                    othersName="others_immidiateCausesSA"
                    occupy={12}
                  />
                  <LabeledCheckBoxes
                    name="immidiateCausesSC"
                    label="Immidiate Causes - Substandard Conditions"
                    list={getOptions(immidiateCausesSC)}
                    hasOthers
                    othersName="others_immidiateCausesSC"
                    occupy={12}
                  />
                  <LabledParagraphInput
                    name="immidiateCauseDescription"
                    label="Description of the Immidiate Causes"
                    occupy={12}
                  />
                  <LabeledCheckBoxes
                    name="personalFactors"
                    label="Personal Factors "
                    list={getOptions(personalFactors)}
                    hasOthers
                    othersName="others_personalFactors"
                    occupy={12}
                  />
                  <LabeledCheckBoxes
                    name="jobFactors"
                    label="Job Factors"
                    list={getOptions(jobFactors)}
                    hasOthers
                    othersName="others_jobFactors"
                    occupy={12}
                  />
                  <LabledParagraphInput
                    name="basicCauseDescription"
                    label="Description of the Basic Causes"
                    occupy={12}
                  />
                  <LabledParagraphInput
                    name="otherObservations"
                    label="Other Observations from the investigation"
                    occupy={12}
                  />
                  <Spacer height={20} />
                  <Button
                    color="success"
                    onClick={() => form.submit()}
                    disabled={submitting}
                  >
                    Approve
                  </Button>
                </Row>
              </form>
            )}
          />
        </>
      )}
      {!active?.stage3 && active?.stage2 && (
        <>
          <SubHeader header="Stage 3 Form" />
          <Form
            onSubmit={(values) =>
              approveStage3({
                ...values,
                assignDepartment: values?.assignDepartment?.value
              })
            }
            render={({ form, values }) => (
              <form>
                <Row>
                  <MultiSelect
                    isMulti={false}
                    name="assignDepartment"
                    label="Assign Investigation Department"
                    options={departmentList}
                    occupy={4}
                  />
                  <LabeledTextInput
                    name="investigationMember"
                    label="Assign Investigation Member"
                    occupy={4}
                  />
                  <LabeledDateTimeInput
                    name="startDate"
                    label="Assign Start Date"
                    occupy={4}
                  />
                  <LabeledDateTimeInput
                    name="completionDate"
                    label="Assign Completion Date"
                    occupy={4}
                  />
                  <LabeledRadioButtons
                    name="incidentRepeatable"
                    label="Incident Repetable"
                    list={[
                      { label: 'Yes', value: 'Yes' },
                      { label: 'No', value: 'No' }
                    ]}
                    occupy={4}
                  />
                  <Spacer height={10} />
                  <Row>
                    <Col xs="2">
                      <CustomLabel label="Assign Priority" />
                    </Col>
                    <Col
                      style={{
                        backgroundColor:
                          hoecRiskMatrixColors[values.assignPriority]
                      }}
                    >
                      <SeverityRiskMatrix
                        name="assignPriority"
                        values={values.assignPriority}
                      />
                    </Col>
                  </Row>
                  <Spacer height={20} />
                  <Button
                    color="success"
                    onClick={() => form.submit()}
                    disabled={submitting}
                  >
                    Approve
                  </Button>
                </Row>
              </form>
            )}
          />
        </>
      )}
      {!active?.stage4 && active?.stage2 && active?.stage3 && (
        <>
          <SubHeader header="Stage 4 Form" />
          <Form
            onSubmit={(values) => approveStage4(values)}
            mutators={{
              ...arrayMutators
            }}
            render={({ form }) => (
              <form>
                <Row>
                  <LabledParagraphInput
                    name="rootCauseAnalysis"
                    label="Root Cause Analysis"
                  />
                  <LabledParagraphInput
                    name="implementationSchedule"
                    label="Implementation Schedule"
                  />
                  <LabeledRadioButtons
                    name="statuatoryReporting"
                    label="Statuatory Reporting"
                    list={[
                      { label: 'Yes', value: 'Yes' },
                      { label: 'No', value: 'No' }
                    ]}
                    occupy={6}
                  />
                  <LabledParagraphInput
                    name="statuatoryReportingDescription"
                    label="Statuatory Reporting Description"
                  />
                  <Spacer height={10} />
                  <FieldArray
                    name={`investigationTeam`}
                    component={renderInvestigations}
                  />
                  <Spacer height={10} />
                  <FieldArray name={`actions`} component={renderActions} />
                  <Spacer height={20} />
                  <Button
                    color="success"
                    onClick={() => form.submit()}
                    disabled={submitting}
                  >
                    Approve
                  </Button>
                </Row>
              </form>
            )}
          />
        </>
      )}

      {active?.stage2 && active?.stage3 && active?.stage4 && (
        <>
          <SubHeader header="Close out Form" />
          <Form
            onSubmit={(values) => closeIncidentReport(values)}
            render={({ form }) => (
              <form>
                <Row>
                  <LabeledRadioButtons
                    name="rootCauseIdentified"
                    label="Root Cause Identified"
                    list={[
                      { label: 'Yes', value: 'Yes' },
                      { label: 'No', value: 'No' }
                    ]}
                    occupy={6}
                  />
                  <LabeledRadioButtons
                    name="correctiveActionPlan"
                    label="Corrective Action Plan Completed"
                    list={[
                      { label: 'Yes', value: 'Yes' },
                      { label: 'No', value: 'No' }
                    ]}
                    occupy={6}
                  />
                  <LabledParagraphInput name="comments" label="Comments" />
                  <Spacer height={20} />
                  <Button
                    color="success"
                    onClick={() => form.submit()}
                    disabled={submitting}
                  >
                    Close
                  </Button>
                </Row>
              </form>
            )}
          />
        </>
      )}
      <Spacer height={20} />
    </ProtectedLayout>
  )
}
