import moment from 'moment'
import {
  Button,
  Col,
  Input,
  ModalFooter,
  Row,
  Spinner,
  Table
} from 'reactstrap'
import { useContext, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import CustomLabel from '../../components/InputFields/CustomLabel'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { AcceptedPOTable } from '../vendor/vendorView'
import { Box, renderData } from '../workManagement/PmTree'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { prStatuses } from '../../components/constants/woStatuses'
import { stock, usersEndpoint } from '../../components/serverurl'
import { UserContext } from '../../App'

const rigdetails = [
  {
    rigName: 'Unit 1',
    rigCode: 'AB I',
    address: 'Chennai',
    responsiblePerson: 'Raj',
    email: 'Raj@e73.ai',
    phone: 919000000123,
    status: 'Active'
  },
  {
    rigName: 'Unit 2',
    rigCode: 'AB II',
    address: 'Mumbai',
    responsiblePerson: 'Kumar',
    email: 'Kumar@e73.ai',
    phone: 919000000124,
    status: 'Active'
  },
  {
    rigName: 'Unit 3',
    rigCode: 'AB III',
    address: 'Kakinada',
    responsiblePerson: 'Robert',
    email: 'Robert@e73.ai',
    phone: 919000000125,
    status: 'Active'
  },
  {
    rigName: 'Unit 4',
    rigCode: 'AB IV',
    address: 'Pondiherry',
    responsiblePerson: 'Mathew',
    email: 'Mathew@e73.ai',
    phone: 919000000126,
    status: 'Active'
  }
]

export const PRTable = ({
  data,
  isLoading,
  isRefetching,
  page,
  setPage,
  url,
  po,
  setSelectedPO,
  selectedPO
}: {
  data: any
  isLoading: boolean
  isRefetching: boolean
  page: any
  setPage: any
  url: string
  po: boolean
  setSelectedPO: any
  selectedPO: any
}) => {
  const navigate = useNavigate()
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>S.No</th>
            <th>Number</th>
            <th>Tag</th>
            <th>Tag Description</th>
            <th>Class Code</th>
            <th>Priority</th>
            <th>Department</th>
            <th>Status</th>
            <th>Created on</th>
          </tr>
        </thead>
        <tbody>
          <>
            {isLoading || isRefetching ? (
              <tr>
                <td colSpan={12}>
                  <Row
                    style={{
                      height: 500,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Spinner />
                  </Row>
                </td>
              </tr>
            ) : (
              data?.length > 0 &&
              data?.map((w: any, index: number) => (
                <tr key={w?._id}>
                  <th>
                    <Input
                      type="checkbox"
                      id={w?._id}
                      name={w?._id}
                      defaultChecked={
                        selectedPO?.filter((spo: any) => spo?._id === w?._id)
                          ?.length > 0
                      }
                      onClick={(e: any) =>
                        e?.target?.checked
                          ? setSelectedPO([...selectedPO, w])
                          : setSelectedPO(
                              selectedPO?.filter(
                                (spo: any) => spo?.number !== w?.number
                              )
                            )
                      }
                    />
                  </th>
                  <td>{incrementby1(page * 20 + index)}</td>
                  <td>{po ? w?.number?.replace('PR', 'PO') : w?.number}</td>
                  <td>{w?.pr?.tagId}</td>
                  <td>{w?.pr?.tagDescription}</td>
                  <td>{w?.pr?.classCode}</td>
                  <td>{w?.pr?.priority}</td>
                  <td>{w?.pr?.department}</td>
                  <td>{prStatuses[w?.status] || w?.status}</td>
                  <td>{moment(w?.pr?.createdAt).format('DD/MM/YYYY')}</td>
                </tr>
              ))
            )}
          </>
        </tbody>
      </Table>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Purchase Requests Found
          </p>
        </Row>
      )}
    </>
  )
}

export default function CreateTransportOrder({
  onBack,
  url,
  title,
  status,
  po
}: {
  onBack: string
  url: any
  title: string
  status: string
  po: boolean
}) {
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const [page, setPage] = useState(0)
  const [createModal, setCreateModal] = useState(false)
  const [selectedPO, setSelectedPO]: any = useState([])
  const [forwarder, setForwarder]: any = useState()
  const [rig, setRig]: any = useState()
  const [result, setResult]: any = useState()
  const [customs, setCustoms]: any = useState()

  const fetchprs = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/pr/quotations?status=acceptedByVendor`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, isRefetching, refetch }: any = useQuery(
    'prs',
    fetchprs
  )

  console.log(data)

  const prs = data?.quotations

  const fetchFreight = async () => {
    const response = await makeAuthenticatedRequest(
      `${usersEndpoint}/aban?designation=FREIGHT FORWARDER`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const freightQuery: any = useQuery('freightCrew', fetchFreight)

  const fetchCustoms = async () => {
    const response = await makeAuthenticatedRequest(
      `${usersEndpoint}/aban?designation=CUSTOMS CLEARING AGENT`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const customsQuery: any = useQuery('fetchCustomsCrew', fetchCustoms)

  const freightCrew = freightQuery?.data?.crew
  const freightCustoms = customsQuery?.data?.crew

  const trasnportOrderDetails: any = {
    'Vendor Name': selectedPO?.[0]?.approvedVendor?.name,
    'Vendor Id': selectedPO?.[0]?.approvedVendor?.vendorId,
    'Vendor Type': selectedPO?.[0]?.approvedVendor?.type,
    'Logistics Coordinator Name': userData?.givenName,
    'Created On': getReadableTime(new Date()),
    'Freight Forwarder Name': forwarder?.userName,
    'Freight Forwareder Id': forwarder?.userId,
    'Warehouse Id': rig?.rigCode,
    'Warehouse Location': rig?.address,
    'Customs Handler Name': customs?.userName,
    'Customs Handler Id': customs?.userId
  }

  return (
    <ProtectedLayout onBack={onBack} title={title}>
      <Row style={{ margin: 10 }}>
        <div style={{ minHeight: '78vh' }}>
          <PRTable
            data={prs}
            page={page}
            isLoading={isLoading}
            isRefetching={isRefetching}
            setPage={setPage}
            url={url}
            po={po}
            setSelectedPO={setSelectedPO}
            selectedPO={selectedPO}
          />
        </div>
      </Row>
      <ModalFooter>
        <Button color="warning" onClick={() => setCreateModal(true)}>
          Create Transport Order
        </Button>
      </ModalFooter>
      <SubmitModalWithForm
        isOpen={createModal}
        onClose={(val: any) => {
          setCreateModal(false)
          // val && navigate('/purchase/dashboard')
        }}
        form={
          <Row style={{ margin: 10 }}>
            <Box heading="Transport Order Details">
              {Object.keys(trasnportOrderDetails)?.map((key: any) =>
                renderData(key, trasnportOrderDetails[key])
              )}
            </Box>
            <Box heading="Purchase Orders">
              <AcceptedPOTable data={selectedPO} />
            </Box>
            <Row>
              <Col xs="4">
                <CustomLabel label="Select Freight Forwarder" />
                <Input
                  type="select"
                  defaultValue={''}
                  onChange={(e: any) =>
                    setForwarder(
                      freightCrew?.filter(
                        (f: any) => f?._id === e?.target?.value
                      )?.[0]
                    )
                  }
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {freightCrew?.map((f: any) => (
                    <option
                      key={f?._id}
                      value={f?._id}
                      onClick={() => setForwarder(f)}
                    >
                      {f?.userName}-{f?.assignWarehouse}
                    </option>
                  ))}
                </Input>
              </Col>
              {selectedPO?.[0]?.approvedVendor?.type === 'Foreign' && (
                <Col xs="4">
                  <CustomLabel label="Select Customs Handler" />
                  <Input
                    type="select"
                    defaultValue={''}
                    onChange={(e: any) =>
                      setCustoms(
                        freightCustoms?.filter(
                          (f: any) => f?._id === e?.target?.value
                        )?.[0]
                      )
                    }
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {freightCustoms?.map((f: any) => (
                      <option
                        key={f?._id}
                        value={f?._id}
                        onClick={() => setCustoms(f)}
                      >
                        {f?.userName}-{f?.assignWarehouse}
                      </option>
                    ))}
                  </Input>
                </Col>
              )}
              <Col xs="4">
                <CustomLabel label="Select Unit Details" />
                <Input
                  type="select"
                  defaultValue={''}
                  onChange={(e: any) =>
                    setRig(
                      rigdetails?.filter(
                        (f: any) => f?.rigCode === e?.target?.value
                      )?.[0]
                    )
                  }
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {rigdetails?.map((f: any) => (
                    <option
                      key={f?.rigCode}
                      value={f?.rigCode}
                      onClick={() => setForwarder(f)}
                    >
                      {f?.rigCode}-{f?.address}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
          </Row>
        }
        onSubmit={async (e: any) => {
          e.preventDefault()
          try {
            const body: any = {
              vendorName: selectedPO?.[0]?.approvedVendor?.name,
              vendorId: selectedPO?.[0]?.approvedVendor?.vendorId,
              logisticsCoordinator: userData?.givenName,
              createdOn: new Date(),
              forwarderName: forwarder?.userName,
              forwarderId: forwarder?.userId,
              wareHouseId: rig?.rigCode,
              wareHouseLocation: rig?.address,
              poList: selectedPO,
              latestUpdatedby: userData?.id,
              status: 'transportOrderCreated',
              vendorType: selectedPO?.[0]?.approvedVendor?.type,
              customsHandlerName: customs?.userName,
              customsHandlerId: customs?.userId
            }

            const res = await makeAuthenticatedRequest(
              `${stock}/inventory/to`,
              'POST',
              body,
              accounts,
              instance
            )

            setResult(res)

            if (res.status === 'success') {
              await refetch()
              setSelectedPO([])
            }

            return res.status
          } catch (error) {}
        }}
        fullscreen
        sucessView={
          <Row style={{ margin: 10 }}>
            <Col>
              <Box heading="TO Details">
                {renderData('TO Number', result?.to?.to)}
                {renderData('JOC Number', result?.to?.joc)}
                {renderData('JOF Number', result?.to?.jof)}
              </Box>
            </Col>
          </Row>
        }
        header="Create Transport Order"
      />
    </ProtectedLayout>
  )
}
