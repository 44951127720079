import React from 'react'
import { Button, Col, Row, Table } from 'reactstrap'
import { renderArray } from '../../pages/WaitingForPIC'
import Spacer from '../Spacer'
import { incrementby1 } from './JSAForm'

export default function JSATemplateView({
  risk,
  setRisk,
  select,
  showSelect = true
}: any) {
  const selected = select?.template?.task === risk?.template?.task
  const data = risk?.template ? risk?.template : risk

  return (
    <>
      <React.Fragment>
        <Table
          bordered
          style={{
            border: '1px solid #000',
            fontSize: 12,
            marginLeft: 10,
            width: '98%'
          }}
        >
          <thead>
            <tr>
              <th>DEPARTMENT</th>
              <td>{data?.department}</td>
              <th>ACTIVITY / TASK</th>
              <td>{data?.task}</td>
              <th>LOCATION</th>
              <td>{data?.location}</td>
            </tr>
            <tr>
              <th>ASSESMENT TEAM</th>
              <td colSpan={3}>{renderArray(data?.team, false)}</td>
              {/* <th>TOOLBOX TALK CONDUCTED</th> */}
              {/* <td>{data?.toolBox}</td> */}
              <th>DATE</th>
              <td>{data?.date}</td>
            </tr>
          </thead>
          <tbody></tbody>
        </Table>
        <Spacer height={10} />
        <Table
          bordered
          striped
          responsive
          style={{ border: '1px solid #000', fontSize: 12 }}
        >
          <thead>
            <tr style={{ backgroundColor: 'orange' }}>
              <th>Sl.No</th>
              <th>Activity</th>
              <th>Potential Hazards and Risk</th>
              <th>Risk control Measures</th>
              <th>Who is responsible</th>
            </tr>
          </thead>
          <tbody>
            <React.Fragment>
              {data?.activities?.map((activity: any, index: number) => (
                <>
                  <tr key={`${index}_${activity?.name}`}>
                    <td style={{ width: '1%' }}>{incrementby1(index)}</td>
                    <td style={{ width: '15%' }}>{activity?.name}</td>
                    <td style={{ width: '25%' }}>
                      {activity?.risks?.map((risk: string, index: number) => (
                        <li key={index}>{risk}</li>
                      ))}
                    </td>
                    <td style={{ width: '55%' }}>
                      {activity?.controls?.map(
                        (control: string, index: number) => (
                          <li key={index}>{control}</li>
                        )
                      )}
                    </td>
                    <td style={{ width: '10%' }}>
                      {renderArray(activity?.responsible, false)}
                    </td>
                  </tr>
                </>
              ))}
            </React.Fragment>
          </tbody>
        </Table>
        {showSelect && (
          <Row>
            <Col xs="12">
              <Button
                color={selected ? 'success' : 'primary'}
                style={{ width: 'inherit', marginBottom: 10 }}
                onClick={() => setRisk(risk)}
              >
                {selected ? ' Selected' : 'Select'}
              </Button>
            </Col>
          </Row>
        )}
      </React.Fragment>
    </>
  )
}
