import { Form } from 'react-final-form'
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap'
import LabeledDateTimeInput from '../InputFields/DateTime'
import LabeledTextInput from '../InputFields/LabeledTextInput'
import ModalTitle from '../ModalTitle'
import Spacer from '../Spacer'

export default function PositiveIsolation({
  toggle,
  setAttachedForms,
  attachedForms,
  isOpen
}: any) {
  const validate = (values: any) => {
    const errors: any = {}
    if (!values.purpose) {
      errors.purpose = 'Required'
    }

    return errors
  }

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalBody>
          <ModalTitle title="Positive Isolation" />
          <Form
            validate={validate}
            initialValues={attachedForms}
            onSubmit={(values: any) => {
              setAttachedForms(values)
              toggle()
            }}
            render={({ form, hasValidationErrors }) => (
              <form>
                <Row>
                  <LabeledTextInput
                    name="purpose"
                    label="Purpose"
                    occupy={12}
                  />
                  <LabeledDateTimeInput
                    name="installDate"
                    label="Install Date"
                    occupy={12}
                  />
                  <LabeledDateTimeInput
                    name="removeDate"
                    label="Removal Date"
                    occupy={12}
                  />
                </Row>
                <Spacer height={30} />
                <Row>
                  <Col xs="12">
                    <Button
                      style={{ width: 'inherit' }}
                      onClick={form.submit}
                      color={hasValidationErrors ? 'secondary' : 'warning'}
                      disabled={hasValidationErrors}
                    >
                      Save & Next
                    </Button>
                    <Spacer height={10} />
                    <Button
                      style={{ width: 'inherit' }}
                      color="warning"
                      onClick={toggle}
                    >
                      Cancel
                    </Button>
                    <Spacer height={10} />
                  </Col>
                </Row>
              </form>
            )}
          />
        </ModalBody>
      </Modal>
    </>
  )
}
