import { Row } from 'reactstrap'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { IconCard } from '../../components/Dashboard/IconCard'

export default function IsolationsActions() {
  const flows: any = [
    {
      src: require('./../../images/Icons/Permits with isolations.png'),
      name: 'WITH ISOLATION',
      nav: '/eptw/newPermit'
    },
    {
      src: require('./../../images/Icons/DIC_Icon.png'),
      name: 'DEISOLATION AND CLOSE',
      nav: '/eptw/deisolationAndClose'
    },
    {
      src: require('./../../images/Icons/LTI.png'),
      name: 'LONG TERM ISOLATION',
      nav: '/eptw/lti'
    },
    {
      src: require('./../../images/Icons/EQUIPMENT OVERRIDE.png'),
      name: 'OVERRIDE REGISTER',
      nav: '/eptw/overrides'
    },
    {
      src: require('./../../images/Icons/DIC_Icon.png'),
      name: 'DEISOLATION AND CLOSE',
      nav: '/eptw/deisolationAndClose'
    },
    {
      name: 'LIVE ISOLATIONS (LTI)',
      src: require('./../../images/Icons/LTI_Button.png'),
      nav: '/eptw/liveIsolations'
    }
  ]

  return (
    <>
      <ProtectedLayout onBack="/eptw/menu" title="ISOLATIONS MANAGEMENT">
        <Row style={{ margin: 10, fontSize: 20 }}>
          {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
            <IconCard
              key={`${index}-${name}`}
              name={name}
              nav={nav}
              canView={canView}
              count={count}
              src={src}
            />
          ))}
        </Row>
      </ProtectedLayout>
    </>
  )
}
