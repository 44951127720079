import AshokLeylandLayout from './AshokLeylandLayout'
import React, { useState } from 'react'
import { Input, Row, Table } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import { alEquipmentApi } from '../components/serverurl'
import { incrementby1 } from '../components/Forms/JSAForm'
import { makeAuthenticatedRequest } from '../apiRequests'
import { getReadableTime } from '../pages/workManagement/WorkOrderDetails'
import SubmitModalWithForm from '../components/Modals/SubmitModalWithForm'
import toNormalCase from '../components/lib/toNormalCase'
import CustomLabel from '../components/InputFields/CustomLabel'

export const trainingTypes = {
  proto: "Proto Shop - EV PPE's usage & HV cable ",
  crcl: 'CRCL  - Fire Fighting Training',
  evSwitchExperts: 'EV bus safety measures training for Bus Monitors ',
  basicTrainingForLead: 'Basic Safety Training for lead Employees ',
  ciss: 'CISS Security - Training',
  fire: 'Fire Fighting Training ',
  basicTrainingForKeeping: 'Basic Safety Training for House keeping ',
  evSwitchTeam: 'EV bus safety measures training'
}

export default function AlUsers({}) {
  const { accounts, instance } = useMsal()

  const fetchUsers = async () =>
    await makeAuthenticatedRequest(
      `${alEquipmentApi}/users`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data, refetch }: any = useQuery('AL Users List', fetchUsers)
  const trainingData = data?.users

  const [modal, setModal] = useState(false)
  const [update, setUpdate]: any = useState()
  const [name, setName]: any = useState()

  const handleNameChange = (
    id: any,
    month: any,
    type: any,
    activity: any,
    date: any
  ) => {
    setName({
      id,
      month,
      type,
      activity,
      date
    })
    setModal(true)
    console.log(name)
  }

  const handleUpdate = (date: any) => {
    setUpdate(date)
  }

  const renderDate = (date: any) => {
    return date && getReadableTime(date)
  }

  return (
    <AshokLeylandLayout title="USERS TRAINING DATE" onBack="/ashokleyland">
      <Row style={{ margin: 15 }}>
        <Table bordered responsive style={{ borderColor: '#000' }}>
          <thead style={{ backgroundColor: 'gold' }}>
            <tr>
              {[
                'S.No',
                'Employee Number',
                'Employee Name',
                'Designation',
                'Department'
              ]?.map((h: any) => <th key={h}>{h}</th>)}
              {Object.values(trainingTypes)?.map((h: any) => (
                <th colSpan={2} key={h}>
                  {h}
                </th>
              ))}
            </tr>
            <tr>
              <th colSpan={5}></th>
              {Object.keys(trainingTypes)?.map((k: any, index: any) => (
                <React.Fragment key={k}>
                  <th>Training Completed on</th>
                  <th>Training Due</th>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {trainingData?.map((t: any, index: any) => (
              <tr key={t?._id}>
                <td>{incrementby1(index)}</td>
                <td>{t?.employeeNumber}</td>
                <td>{t?.employeeName}</td>
                <td>{t?.designation}</td>
                <td>{t?.department}</td>
                <td
                  onClick={() =>
                    handleNameChange(
                      t?._id,
                      'training',
                      'proto',
                      'trainingCompletedOn',
                      t?.training?.proto?.trainingCompletedOn
                    )
                  }
                >
                  {renderDate(t?.training?.proto?.trainingCompletedOn)}
                </td>
                <td
                  onClick={() =>
                    handleNameChange(
                      t?._id,
                      'training',
                      'proto',
                      'trainingDue',
                      t?.training?.proto?.trainingDue
                    )
                  }
                >
                  {renderDate(t?.training?.proto?.trainingDue)}
                </td>
                <td
                  onClick={() =>
                    handleNameChange(
                      t?._id,
                      'training',
                      'proto',
                      'trainingCompletedOn',
                      t?.training?.proto?.trainingCompletedOn
                    )
                  }
                >
                  {renderDate(t?.training?.crcl?.trainingCompletedOn)}
                </td>
                <td
                  onClick={() =>
                    handleNameChange(
                      t?._id,
                      'training',
                      'crcl',
                      'trainingDue',
                      t?.training?.proto?.trainingDue
                    )
                  }
                >
                  {renderDate(t?.training?.crcl?.trainingDue)}
                </td>
                <td
                  onClick={() =>
                    handleNameChange(
                      t?._id,
                      'training',
                      'evSwitchExperts',
                      'trainingCompletedOn',
                      t?.training?.proto?.trainingCompletedOn
                    )
                  }
                >
                  {renderDate(
                    t?.training?.evSwitchExperts?.trainingCompletedOn
                  )}
                </td>
                <td
                  onClick={() =>
                    handleNameChange(
                      t?._id,
                      'training',
                      'evSwitchExperts',
                      'trainingDue',
                      t?.training?.proto?.trainingDue
                    )
                  }
                >
                  {renderDate(t?.training?.evSwitchExperts?.trainingDue)}
                </td>
                <td
                  onClick={() =>
                    handleNameChange(
                      t?._id,
                      'training',
                      'basicTrainingForLead',
                      'trainingCompletedOn',
                      t?.training?.proto?.trainingCompletedOn
                    )
                  }
                >
                  {renderDate(
                    t?.training?.basicTrainingForLead?.trainingCompletedOn
                  )}
                </td>
                <td
                  onClick={() =>
                    handleNameChange(
                      t?._id,
                      'training',
                      'basicTrainingForLead',
                      'trainingDue',
                      t?.training?.proto?.trainingDue
                    )
                  }
                >
                  {renderDate(t?.training?.basicTrainingForLead?.trainingDue)}
                </td>
                <td
                  onClick={() =>
                    handleNameChange(
                      t?._id,
                      'training',
                      'ciss',
                      'trainingCompletedOn',
                      t?.training?.proto?.trainingCompletedOn
                    )
                  }
                >
                  {renderDate(t?.training?.ciss?.trainingCompletedOn)}
                </td>
                <td
                  onClick={() =>
                    handleNameChange(
                      t?._id,
                      'training',
                      'ciss',
                      'trainingDue',
                      t?.training?.proto?.trainingDue
                    )
                  }
                >
                  {renderDate(t?.training?.ciss?.trainingDue)}
                </td>
                <td
                  onClick={() =>
                    handleNameChange(
                      t?._id,
                      'training',
                      'fire',
                      'trainingCompletedOn',
                      t?.training?.proto?.trainingCompletedOn
                    )
                  }
                >
                  {renderDate(t?.training?.fire?.trainingCompletedOn)}
                </td>
                <td
                  onClick={() =>
                    handleNameChange(
                      t?._id,
                      'training',
                      'fire',
                      'trainingDue',
                      t?.training?.proto?.trainingDue
                    )
                  }
                >
                  {renderDate(t?.training?.fire?.trainingDue)}
                </td>
                <td
                  onClick={() =>
                    handleNameChange(
                      t?._id,
                      'training',
                      'basicTrainingForKeeping',
                      'trainingCompletedOn',
                      t?.training?.proto?.trainingCompletedOn
                    )
                  }
                >
                  {renderDate(
                    t?.training?.basicTrainingForKeeping?.trainingCompletedOn
                  )}
                </td>
                <td
                  onClick={() =>
                    handleNameChange(
                      t?._id,
                      'training',
                      'basicTrainingForKeeping',
                      'trainingDue',
                      t?.training?.proto?.trainingDue
                    )
                  }
                >
                  {renderDate(
                    t?.training?.basicTrainingForKeeping?.trainingDue
                  )}
                </td>
                <td
                  onClick={() =>
                    handleNameChange(
                      t?._id,
                      'training',
                      'evSwitchTeam',
                      'trainingCompletedOn',
                      t?.training?.proto?.trainingCompletedOn
                    )
                  }
                >
                  {renderDate(t?.training?.evSwitchTeam?.trainingCompletedOn)}
                </td>
                <td
                  onClick={() =>
                    handleNameChange(
                      t?._id,
                      'training',
                      'evSwitchTeam',
                      'trainingDue',
                      t?.training?.proto?.trainingDue
                    )
                  }
                >
                  {renderDate(t?.training?.evSwitchTeam?.trainingDue)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      <SubmitModalWithForm
        isOpen={modal}
        onClose={(val: any) => {
          setModal(false)
        }}
        form={
          <div>
            <p style={{ margin: 0 }}>Activity: {name?.month}</p>
            <p style={{ margin: 0 }}>Type: {name?.type}</p>
            <p style={{ margin: 0 }}>
              Activity: {toNormalCase(name?.activity)}
            </p>
            <CustomLabel label="Update Date to" />
            <Input
              name="updateDate"
              type="date"
              onChange={(e) => handleUpdate(e?.target?.value)}
            />
          </div>
        }
        onSubmit={async (e: any) => {
          console.log(update)
          const body: any = {
            key: `${name?.month}.${name?.type}.${name?.activity}`,
            date: update
          }
          e?.preventDefault()
          const res = await makeAuthenticatedRequest(
            `${alEquipmentApi}/users/${name?.id}`,
            'PATCH',
            body,
            accounts,
            instance
          )

          if (res.status === 'success') {
            await refetch()
          }

          return res.status
        }}
        size="xs"
        header="Update Training Data"
      />
    </AshokLeylandLayout>
  )
}
