import { Col, Row } from 'reactstrap'

export default function SectionHeader({
  title,
  img
}: {
  title?: String
  img?: any
}) {
  return (
    <Row
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 10,
        marginBottom: 10
      }}
    >
      <Col xs="2">
        <img
          src={require('./../../images/e73_logo.jpeg')}
          alt="HOEC Logo"
          height={100}
        />
      </Col>
      <Col
        xs="8"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {title && (
          <h3>
            <b>{title?.toUpperCase()}</b>
          </h3>
        )}
        {img && img}
      </Col>
      <Col xs="2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <img
          src={require('./../../images/aban.png')}
          alt="aban Logo"
          height={90}
        />
      </Col>
    </Row>
  )
}
