import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Col, Input, ModalFooter, Row, Table } from 'reactstrap'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import Spacer from '../../components/Spacer'
import { stock } from '../../components/serverurl'
import { Box, renderData } from '../workManagement/PmTree'
import { ViewHistory } from '../ViewCM'
import { SubHeader } from '../VerifyCM'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { Text } from '../workManagement/WorkRequestForm'
import './../../components/css/print.css'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { UserContext } from '../../App'
import SectionHeader from '../../components/Layout/SectionHeader'

const date = new Date()
date.setDate(date.getDate() + 5)

export default function ReceiveMaterial({
  nextStatus,
  buttonText,
  hasPrint
}: {
  nextStatus?: any
  buttonText?: any
  hasPrint?: boolean
}) {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const navigate = useNavigate()
  const componentRef: any = useRef()
  const [submit, setSubmit]: any = useState(false)

  const fetchpr = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/inventory/po/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const [spares, setSpares]: any = useState([])

  const { data, isLoading, isRefetching }: any = useQuery('pr', fetchpr, {
    onSuccess: (data: any) => {
      // setSpares(data?.pr?.materialRequest)
    }
  })

  useEffect(() => {
    setSpares(data?.pr?.materialRequest)
  }, [data])

  const pr = data?.pr
  const history = data?.history
  const poNumber = `PO-${pr?.number?.split('-')[1]}`

  const handleQuantityChange = (spareId: any, received: any) => {
    setSpares(
      spares?.map((s: any) =>
        s?.['Spare Part Number'] === spareId
          ? { ...s, receivedQuantity: received, receivedDate: new Date() }
          : s
      )
    )
  }

  const styles = `@media print
  {    
      .no-print, .no-print *
      {
          display: none !important;
      }
  }`

  return (
    <>
      <>
        <style>{styles}</style>
      </>
      <ProtectedLayout onBack="/warehouse/materialsReceipts">
        <div ref={componentRef} style={{ margin: 20 }}>
          <SectionHeader title={`Update PO - ${poNumber}`} />
          <hr />
          <Row>
            <Col xs="12">
              <Table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Part Number</th>
                    <th>Part Description</th>
                    <th>UOM</th>
                    <th>Required Quantity</th>
                    <th>Offered Quantity</th>
                    <th>Gross Price</th>
                    <th>Discount</th>
                    <th>Line Cost</th>
                    <th>Currency</th>
                    <th>Note</th>
                    <th>Received Quantity</th>
                    <th>Received Date</th>
                  </tr>
                </thead>
                <tbody>
                  {spares?.map((s: any, index: number) => (
                    <React.Fragment key={s?.['Spare Part Number']}>
                      <tr>
                        <td>{incrementby1(index)}</td>
                        <td style={{ width: '15%', whiteSpace: 'nowrap' }}>
                          {s?.['Spare Part Number']}
                        </td>
                        <td>{s?.['Spare Part Description']}</td>
                        <td>{s?.uom}</td>
                        <td style={{ width: '5%' }}>{s?.quantityRequested}</td>
                        <td style={{ width: '5%' }}>{s?.quantityRequested}</td>
                        <td style={{ width: '5%' }}>{s?.grossPrice}</td>
                        <td style={{ width: '5%' }}>{s?.discount}</td>
                        <td>{s?.amount}</td>
                        <td>{s?.priceCurrency}</td>
                        <td>Need to Install New Item</td>
                        <td style={{ width: '5%' }}>
                          <Input
                            id={`name_4${s?.['Spare Part Number']}`}
                            type="text"
                            style={{ height: 40 }}
                            onChange={(e: any) =>
                              handleQuantityChange(
                                s?.['Spare Part Number'],
                                e?.target?.value
                              )
                            }
                          />
                          {!s?.receivedQuantity && (
                            <span
                              style={{
                                color: 'red',
                                fontSize: 12,
                                whiteSpace: 'nowrap'
                              }}
                            >
                              * Required
                            </span>
                          )}
                        </td>
                        <td style={{ width: '5%', whiteSpace: 'nowrap' }}>
                          {getReadableTime(new Date())}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                  <tr>
                    <td>{spares?.length + 1}</td>
                    <td>001</td>
                    <td>Freight Charges</td>
                    <td colSpan={5}></td>
                    <td style={{ width: '5%' }}>{pr?.fCharges}</td>
                    <td colSpan={4}></td>
                  </tr>
                  <tr>
                    <td>{spares?.length + 2}</td>
                    <td>002</td>
                    <td>Mobilization</td>
                    <td colSpan={5}></td>
                    <td style={{ width: '5%' }}>{pr?.mCharges}</td>
                    <td colSpan={4}></td>
                  </tr>
                  <tr>
                    <td>{spares?.length + 3}</td>
                    <td>003</td>
                    <td>Packaging Charges</td>
                    <td colSpan={5}></td>
                    <td style={{ width: '5%' }}>{pr?.pCharges}</td>
                    <td colSpan={4}></td>
                  </tr>
                  <tr>
                    <td colSpan={3}></td>
                    <td colSpan={3}></td>
                    <th colSpan={2}>Total Cost</th>
                    <th>{pr?.total}</th>
                    <td colSpan={4}></td>
                  </tr>
                  <tr>
                    <td colSpan={12}>
                      <div>
                        <p style={{ margin: 0, fontWeight: 600 }}>
                          1. Packing and Marking
                        </p>
                        <p style={{ margin: 0, fontWeight: 600 }}>
                          2. Third Party Certification
                        </p>
                        <p style={{ margin: 0, fontWeight: 600 }}>
                          3. Deliver CFR to Singapore Port
                        </p>
                        <p style={{ margin: 0, fontWeight: 600 }}>
                          Delivery Date: {getReadableTime(date)}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <div className="no-print">
              <ViewHistory
                data={history}
                title={
                  <p
                    style={{
                      margin: 0,
                      fontSize: 20,
                      fontWeight: 600,
                      paddingTop: 5,
                      paddingBottom: 5
                    }}
                  >
                    APPROVAL HISTORY
                  </p>
                }
                permitColor="#FFD580"
              />
            </div>
          </Row>
          <Spacer height={10} />
        </div>
        {hasPrint && (
          <ReactToPrint
            documentTitle={`Purchase Request - ${pr?.number}`}
            pageStyle={'padding: 20'}
            trigger={() => (
              <ModalFooter>
                <Button color="warning" children="Print" />
              </ModalFooter>
            )}
            content={() => componentRef.current}
          />
        )}
        <ModalFooter>
          {buttonText && (
            <Button color="warning" onClick={() => setSubmit(true)}>
              {buttonText}
            </Button>
          )}
        </ModalFooter>
        <SubmitModalWithForm
          isOpen={submit}
          onClose={(val: any) => {
            setSubmit(false)
            val && navigate('/warehouse/materialsReceipts')
          }}
          onSubmit={async (e: any) => {
            e.preventDefault()
            try {
              const body = {
                ...data?.pr,
                materialRequest: spares,
                lastestUpdatedBy: userData?.id,
                status: nextStatus
              }

              const res = await makeAuthenticatedRequest(
                `${stock}/inventory/material/${id}`,
                'PATCH',
                body,
                accounts,
                instance
              )

              return res.status
            } catch (error) {}
          }}
          size="lg"
          header="Update Material"
        />
      </ProtectedLayout>
    </>
  )
}
