import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { useAdminPermissions } from './EditRisks'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import ViewPermit from './ViewPermit'

import React from 'react'

export default function ViewFullPermit({ onBack }: any) {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  return (
    <ProtectedLayout onBack={onBack}>
      <ViewPermit data={data} isLoading={isLoading} />
    </ProtectedLayout>
  )
}
