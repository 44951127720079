import { Route, Routes } from "react-router-dom";

import MaterialRequests from "../pages/warehouse/MaterialRequests";
import MaterialReturned from "../pages/warehouse/MaterialReturned";
import StockRegister from "../pages/warehouse/StockRegister";
import ViewSpareDetails from "../pages/warehouse/ViewSpareDetails";
import WarehouseDashboard from "../pages/warehouse/WarehouseDashboard";
import PRList from "../pages/inventory/PRList";
import ReceiveMaterial from "../pages/warehouse/ReceiveMaterial";
import MaterialOnboard from "../pages/warehouse/MaterialOnboard";
import AddNewStockItem from "../pages/warehouse/AddNewStockItem";

export default function WareHouseRoutes() {
  return (
    <Routes>
      <Route path="/" element={<WarehouseDashboard />} />
      <Route path="/stockRegister" element={<StockRegister />} />
      <Route path="/stockRegister/:id" element={<ViewSpareDetails />} />
      <Route path="/addNewStockItem" element={<AddNewStockItem />} />
      <Route path="/materialRequests" element={<MaterialRequests />} />
      <Route path="/materialsReturned" element={<MaterialReturned />} />
      <Route
        path="/materialsReceipts"
        element={
          <PRList
            onBack="/cmms/supplychain/warehouse"
            title="MATERIAL RECEIPTS"
            url="/warehouse/materialsReceipts"
            status="issuedPO"
            po
          />
        }
      />
      <Route
        path="/materialsReceipts/:id"
        element={
          <ReceiveMaterial
            buttonText="Update Material"
            nextStatus={"materialReceived"}
          />
        }
      />
      <Route
        path="/materialsReceived"
        element={
          <PRList
            onBack="/cmms/supplychain/warehouse"
            title="MATERIAL RECEIVED"
            url="/warehouse/materialsReceived"
            status="materialReceived"
            po
          />
        }
      />
      <Route
        path="/materialsReceived/:id"
        element={
          <MaterialOnboard
          // buttonText="Update Material"
          // nextStatus={'materialReceived'}
          />
        }
      />
    </Routes>
  );
}
