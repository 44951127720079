import { Col, Row } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import { makeAuthenticatedRequest } from '../apiRequests'
import { alEquipmentApi } from '../components/serverurl'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import { IconCard } from '../components/Dashboard/IconCard'
import { Box } from '../pages/workManagement/PmTree'
import AshokLeylandLayout from './AshokLeylandLayout'

export default function AlNearmissActions() {
  const { accounts, instance } = useMsal()

  const fetchReport = async () =>
    await makeAuthenticatedRequest(
      `${alEquipmentApi}/firs/incident/count`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data }: any = useQuery('AL FIR Counts', fetchReport)

  const counts = data?.counts

  const flows: any = [
    {
      src: require('./../images/Icons/Stage - 1.png'),
      name: 'Stage 1 Originator',
      nav: '/ashokleyland/firs/nearmiss/stage1',
      level: 1
    },
    {
      src: require('./../images/Icons/Stage - 2.png'),
      name: 'Stage 2 Safety Committee Member',
      nav: '/ashokleyland/firs/incidentreporting/stage2',
      count: counts?.['Created'],
      level: 1
    },
    {
      src: require('./../images/Icons/Stage - 3.png'),
      name: 'Stage 3 Department HOD',
      nav: '/ashokleyland/firs/incidentreporting/stage3',
      count: counts?.['Stage3'],
      level: 3
    },
    {
      src: require('./../images/Icons/Stage - 4.png'),
      nav: '/ashokleyland/firs/incidentreporting/stage4',
      name: 'Stage 4 Safety EHS',
      count: counts?.['Stage4'],
      level: 3
    },
    {
      src: require('./../images/Icons/Stage - 5.png'),
      nav: '/ashokleyland/firs/incidentreporting/stage5',
      name: 'Stage 5 HR Head',
      count: counts?.stage5,
      level: 3
    },
    {
      src: require('./../images/Icons/Stage - 8.png'),
      name: 'Closed',
      nav: '/ashokleyland/firs/incidentreporting/closed',
      count: counts?.['Closed'],
      level: 4
    },
    {
      src: require('./../images/Icons/Stage - A.png'),
      name: 'Rejectecd',
      nav: '/ashokleyland/firs/incidentreporting/rejected',
      count: counts?.['Rejected'],
      level: 4
    }
  ]

  return (
    <>
      <AshokLeylandLayout
        onBack="/ashokleyland/firs/select"
        title="NEAR MISS INCIDENT"
      >
        <Row style={{ margin: 10 }}>
          <Row style={{ display: 'flex' }}>
            <Col xs="4" style={{ marginTop: '5vh' }}>
              <Box heading="Near Miss Request" headingCenter>
                <Col style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {flows
                    ?.filter((f: any) => f?.level === 1)
                    .map(
                      ({ name, nav, count, canView, src }: any, index: any) => (
                        <IconCard
                          key={`${index}-${name}`}
                          name={name}
                          nav={nav}
                          count={count}
                          src={src}
                        />
                      )
                    )}
                </Col>
              </Box>
            </Col>
            <Col xs="5" style={{ marginTop: '15vh' }}>
              <Box heading="Near Misss Action" headingCenter>
                <Col style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {flows
                    ?.filter((f: any) => f?.level === 3)
                    .map(
                      ({ name, nav, count, canView, src }: any, index: any) => (
                        <IconCard
                          key={`${index}-${name}`}
                          name={name}
                          nav={nav}
                          count={count}
                          src={src}
                        />
                      )
                    )}
                </Col>
              </Box>
            </Col>
            <Col xs="3" style={{ marginTop: '25vh' }}>
              <Box heading="Near Miss Completed" headingCenter>
                <Col
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center'
                  }}
                >
                  {flows
                    ?.filter((f: any) => f?.level === 4)
                    .map(
                      ({ name, nav, count, canView, src }: any, index: any) => (
                        <IconCard
                          key={`${index}-${name}`}
                          name={name}
                          nav={nav}
                          count={count}
                          src={src}
                        />
                      )
                    )}
                </Col>
              </Box>
            </Col>
          </Row>
        </Row>
      </AshokLeylandLayout>
    </>
  )
}
