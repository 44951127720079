import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
  Table
} from 'reactstrap'
import { FaFileExport, FaFileImport } from 'react-icons/fa'
import { MdEditDocument } from 'react-icons/md'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { ToastContainer, toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import CustomLabel from '../../components/InputFields/CustomLabel'
import ModalTitle from '../../components/ModalTitle'
import RcmLayout from '../../components/rcm/RcmLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { Box, renderData } from '../workManagement/PmTree'
import { getAttachment, systemsApi } from '../../components/serverurl'
import { getAccessToken, makeAuthenticatedRequest } from '../../apiRequests'
import { IconButton, handleExport } from './Systems'
import { loginRequest } from '../../authConfig'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import { incrementby1 } from '../../components/Forms/JSAForm'

const y = ['4A', '3A', '2A', '1A', '3B', '2B', '1B', '2C', '1C']
const o = ['5A', '5B', '4B', '4C', '3C', '3D', '2D', '2E', '1D', '1E']
const r = ['5C', '5D', '5E', '4D', '4E', '3E']

export const failureModesHeaderKeys = [
  // 'Equipment ID',
  'SAP Equipment No',
  // 'FLOC ID',
  'System',
  // 'CMMS Type',
  // 'Active',
  // 'Description',
  // 'Equipment Group',
  // 'Start Date',
  // 'Equipment Status',
  // 'Spare Equipment',
  // 'Spare Description',
  // 'Longitude',
  // 'Latitude',
  // 'Altitude (m)',
  // 'Manufacturer',
  // 'Model No',
  // 'Manufactured Part Number',
  // 'Serial No',
  // 'Supplier',
  // 'Purchased Date',
  // 'Use Pipe Line Number',
  // 'Perform RCM',
  // 'Equipment Strategy',
  // 'RBI',
  'Team Members',
  'Function',
  'Functional Failure',
  'Failure Mode',
  'Failure Effect'
]

export default function RcmFMEA({ setPin, pin }: { pin: any; setPin: any }) {
  const { accounts, instance } = useMsal()
  const [file, setFile] = useState()
  const [importModal, setImportModal]: any = useState(false)
  const [selected, setSelected]: any = useState()
  const [edit, setEdit]: any = useState(false)
  const [formData, setFormData]: any = useState({})
  const [deleteSystem, setDeleteSystem]: any = useState(false)
  const [submitting, setSubmitting]: any = useState(false)
  const [pageNumber, setPageNumber]: any = useState(1)

  const fetchRcmEquipment = async () => {
    const response = await makeAuthenticatedRequest(
      `${systemsApi}/fmea?page=${pageNumber}&system=${pin?.['System Number']}`,
      'GET',
      null,
      accounts,
      instance
    )
    return response
  }

  const { data, refetch, loading, isRefetching }: any = useQuery(
    'rcmEquipment data',
    fetchRcmEquipment
  )

  useEffect(() => {
    if (pageNumber) {
      refetch()
    }
  }, [pageNumber])

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0])
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const setRiskMatrix = (p: any, e: any) =>
    setFormData({
      ...formData,
      Probability: p,
      Risk: e,
      Consequence: e?.split('')?.[1]
    })

  const updateSystemForm = (
    <Row>
      <Col xs="6">
        <CustomLabel label="Equipment" />
        <Input
          type="text"
          name="SAP Equipment No"
          onChange={handleInputChange}
        />
        <CustomLabel label="Function" />
        <Input type="text" name="Function" onChange={handleInputChange} />
        <CustomLabel label="Functional Failure" />
        <Input
          type="text"
          name="Functional Failure"
          onChange={handleInputChange}
        />
        <CustomLabel label="Failure Mode" />
        <Input type="text" name="Failure Mode" onChange={handleInputChange} />
        <CustomLabel label="Failure Effect" />
        <Input type="text" name="Failure Effect" onChange={handleInputChange} />
        <CustomLabel label="Safety" />
        <Input type="text" name="Safety" onChange={handleInputChange} />
      </Col>
      <Col xs="6">
        <CustomLabel label="Status" />
        <Input type="text" name="Status" onChange={handleInputChange} />
        <CustomLabel label="Environment" />
        <Input type="text" name="Environment" onChange={handleInputChange} />
        <CustomLabel label="Asset or Production Loss" />
        <Input
          type="text"
          name="Asset or Production Loss"
          onChange={handleInputChange}
        />
        <CustomLabel label="Reputation" />
        <Input type="text" name="Reputation" onChange={handleInputChange} />
        <CustomLabel label="Frequency" />
        <Input type="text" name="Frequency" onChange={handleInputChange} />
        <CustomLabel label="Team Memebers" />
        <Input type="text" name="Team Members" onChange={handleInputChange} />
      </Col>
      <Col xs="12">
        <CustomLabel label="Risk" />
        <Table bordered style={{ textAlign: 'center', borderColor: '#000' }}>
          <thead>
            <tr>
              <th colSpan={8}>Consequence</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th />
              {['A', 'B', 'C', 'D', 'E']?.map((e: any) => <th key={e}>{e}</th>)}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={7} style={{ width: 10 }}>
                <p
                  style={{
                    writingMode: 'vertical-rl',
                    textOrientation: 'upright',
                    textAlign: 'center',
                    margin: 0,
                    fontWeight: 700
                  }}
                >
                  Probability
                </p>
              </td>
              <td>5</td>
              <td>{'> 0.5'}</td>
              {['5A', '5B', '5C', '5D', '5E']?.map((e: any) => (
                <td
                  key={e}
                  onClick={() => setRiskMatrix(5, e)}
                  style={{
                    borderWidth: formData?.Risk === e ? 5 : 1,
                    backgroundColor: y?.includes(e)
                      ? 'yellow'
                      : o?.includes(e)
                      ? 'orange'
                      : r?.includes(e)
                      ? '#ff474c'
                      : 'none'
                  }}
                >
                  {e}
                </td>
              ))}
            </tr>
            <tr>
              <td>4</td>
              <td>{'0.5 - 0.1'}</td>
              {['4A', '4B', '4C', '4D', '4E']?.map((e: any) => (
                <td
                  key={e}
                  onClick={() => setRiskMatrix(4, e)}
                  style={{
                    borderWidth: formData?.Risk === e ? 5 : 1,
                    backgroundColor: y?.includes(e)
                      ? 'yellow'
                      : o?.includes(e)
                      ? 'orange'
                      : r?.includes(e)
                      ? '#ff474c'
                      : 'none'
                  }}
                >
                  {e}
                </td>
              ))}
            </tr>
            <tr>
              <td>3</td>
              <td>{'0.1 - 0.01'}</td>
              {['3A', '3B', '3C', '3D', '3E']?.map((e: any) => (
                <td
                  key={e}
                  onClick={() => setRiskMatrix(3, e)}
                  style={{
                    borderWidth: formData?.Risk === e ? 5 : 1,
                    backgroundColor: y?.includes(e)
                      ? 'yellow'
                      : o?.includes(e)
                      ? 'orange'
                      : r?.includes(e)
                      ? '#ff474c'
                      : 'none'
                  }}
                >
                  {e}
                </td>
              ))}
            </tr>
            <tr>
              <td>2</td>
              <td>{'0.01 - 0.001'}</td>
              {['2A', '2B', '2C', '2D', '2E']?.map((e: any) => (
                <td
                  key={e}
                  onClick={() => setRiskMatrix(2, e)}
                  style={{
                    borderWidth: formData?.Risk === e ? 5 : 1,
                    backgroundColor: y?.includes(e)
                      ? 'yellow'
                      : o?.includes(e)
                      ? 'orange'
                      : r?.includes(e)
                      ? '#ff474c'
                      : 'none'
                  }}
                >
                  {e}
                </td>
              ))}
            </tr>
            <tr>
              <td style={{ width: '10%' }}>1</td>
              <td>{'< 0.0001'}</td>
              {['1A', '1B', '1C', '1D', '1E']?.map((e: any) => (
                <td
                  key={e}
                  onClick={() => setRiskMatrix(1, e)}
                  style={{
                    borderWidth: formData?.Risk === e ? 5 : 1,
                    backgroundColor: y?.includes(e)
                      ? 'yellow'
                      : o?.includes(e)
                      ? 'orange'
                      : r?.includes(e)
                      ? '#ff474c'
                      : 'none'
                  }}
                >
                  {e}
                </td>
              ))}
            </tr>
            <tr>
              <td />
              <td />
              <td style={{ width: '10%' }}>Slight Impact</td>
              <td style={{ width: '10%' }}>Limited Impact</td>
              <td style={{ width: '10%' }}>Considerable Impact</td>
              <td style={{ width: '10%' }}>National Impact</td>
              <td style={{ width: '10%' }}>International Impact</td>
            </tr>
            <tr>
              <td />
              <td />
              <td style={{ width: '10%' }}>A</td>
              <td style={{ width: '10%' }}>B</td>
              <td style={{ width: '10%' }}>C</td>
              <td style={{ width: '10%' }}>D</td>
              <td style={{ width: '10%' }}>E</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  )

  const onEdit = () => {
    setEdit(true)
    setFormData({
      Discipline: selected?.['Discipline'],
      'Asset Type': selected?.['Asset Type'],
      'EQ Object Type': selected?.['EQ Object Type'],
      'Failure Mode': selected?.['Failure Mode']
    })
  }

  return (
    <RcmLayout
      onBack={'/cmms/rcm'}
      tabHeader={<h4 style={{ margin: 0 }}>FMEA</h4>}
    >
      <div style={{ display: 'flex', marginBottom: 10 }}>
        <IconButton
          icon={<FaFileImport size={25} />}
          buttonText={'Import'}
          onClick={() => setImportModal(true)}
        />
        <IconButton
          icon={<FaFileExport size={25} />}
          buttonText={'Export'}
          onClick={() => handleExport(data?.systems)}
        />
        {/* <IconButton
          icon={<FaFileCircleXmark size={25} />}
          buttonText={'Delete'}
          onClick={() => setDeleteSystem(true)}
          isDisabled={!selected}
        /> */}
        <IconButton
          icon={<MdEditDocument size={25} />}
          buttonText={'Create New'}
          onClick={onEdit}
        />
      </div>
      {data?.systems?.length > 0 && (
        <>
          <Table
            responsive
            key={'rcm Equipment data'}
            style={{ borderColor: '#000' }}
            bordered
          >
            <thead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                background: 'white'
              }}
            >
              <tr key={'header'}>
                {/* <th></th> */}
                <th>S.No</th>
                {failureModesHeaderKeys?.map((key) => <th key={key}>{key}</th>)}
              </tr>
            </thead>
            <tbody>
              {loading || isRefetching ? (
                <>
                  <tr>
                    <td
                      colSpan={12}
                      style={{
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        height: '60vh'
                      }}
                    >
                      <Spinner />
                    </td>
                  </tr>
                </>
              ) : (
                data?.systems?.map((item: any, index: any) => (
                  <tr key={`${index}-${item?.['SAP Equipment No']}`}>
                    {/* <td>
                      <Input
                        type="checkbox"
                        checked={selected?._id === item?._id}
                        onClick={(e: any) =>
                          e?.target?.checked ? setSelected(item) : setSelected()
                        }
                      />
                    </td> */}
                    <td>
                      {incrementby1(
                        pageNumber === 1 ? index : index + pageNumber * 20
                      )}
                    </td>
                    {failureModesHeaderKeys.map((key: any, index: number) =>
                      key === 'Start Date' || key === 'Purchased Date' ? (
                        <td key={index}>
                          {moment(item[key]).format('DD-MM-YYYY')}
                        </td>
                      ) : (
                        <td key={index}>{item[key]}</td>
                      )
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </Table>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(data?.totalDocuments / 20)}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={(selectedPage: any) => {
              setPageNumber(selectedPage.selected)
            }}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </>
      )}
      {importModal && (
        <Modal isOpen={importModal} toggle={() => setImportModal(!importModal)}>
          <ModalTitle height={50} title="Import File" />
          <ModalBody>
            <div>
              <form>
                <Input
                  type={'file'}
                  id={'csvFileInput'}
                  accept={'.csv'}
                  onChange={handleOnChange}
                />
                <br />
                <a
                  href={`${getAttachment}/template.csv`}
                  target="_blank"
                  download={'template.csv'}
                  rel="noreferrer"
                >
                  Download Template
                </a>
              </form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={() => setImportModal(false)}>
              Close
            </Button>
            <Button
              color="warning"
              disabled={submitting}
              onClick={async (e) => {
                setSubmitting(true)
                // await handleOnSubmit(e)
                const token = await getAccessToken(
                  loginRequest,
                  accounts,
                  instance
                )
                const formData: any = new FormData()

                formData.append('file', file)
                try {
                  const res: any = await axios.post(
                    `${systemsApi}/fmea`,
                    formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`
                      }
                    }
                  )

                  if (res?.data?.status === 'success') {
                    await refetch()
                    setSubmitting(false)
                    setPin()
                    setSelected()
                    toast('Import Done Successfully')
                  }

                  return res?.data?.status
                } catch (error) {
                  console.error(error)
                  setSubmitting(false)
                  toast('Some Error Occured!')
                }

                setPin()
                setSelected()
                setImportModal(false)
              }}
            >
              {submitting ? 'Importing ...' : 'Import'}
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {edit && (
        <SubmitModalWithForm
          isOpen={edit}
          fullscreen={true}
          onClose={(val: any) => {
            setEdit(false)
          }}
          form={updateSystemForm}
          onSubmit={async (e: any) => {
            e.preventDefault()
            try {
              const res = await makeAuthenticatedRequest(
                `${systemsApi}/fmea/new`,
                'POST',
                formData,
                accounts,
                instance
              )

              if (res?.status === 'success') {
                await refetch()
              }

              return res?.status
            } catch (error) {
              console.error(error)
            }
          }}
          header={`Create New`}
        />
      )}
      {deleteSystem && (
        <SubmitModalWithForm
          isOpen={deleteSystem}
          size="lg"
          onClose={(val: any) => {
            setDeleteSystem(false)
            setSelected()
          }}
          onSubmit={async (e: any) => {
            e.preventDefault()
            try {
              const res = await makeAuthenticatedRequest(
                `${systemsApi}/fmea?id=${selected?._id}`,
                'DELETE',
                null,
                accounts,
                instance
              )

              if (res?.status === 'success') {
                await refetch()
              }

              return res?.status
            } catch (error) {
              console.error(error)
            }
          }}
          form={
            <>
              <p>Are you sure you want to delete System ?</p>
              <Box heading="System Details">
                {failureModesHeaderKeys?.map((key: any) =>
                  renderData(key, selected?.[key])
                )}
              </Box>
            </>
          }
          header={`Delete Floc System`}
        />
      )}
      <ToastContainer
        theme="dark"
        position="top-center"
        style={{ fontSize: 16 }}
      />
    </RcmLayout>
  )
}
