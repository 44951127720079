import { getAllPermissions } from '../../apiRequests'

export const getPermissions = async (accounts: any, instance: any) => {
  const data = await getAllPermissions(accounts, instance).then(
    (res: any) => res.data
  )
  const createPermissions = data?.permissions?.permit?.create
  const verifyCMPermissions = data?.permissions?.permit?.verify
  const approveCMPermissions = data?.permissions?.permit?.approve
  const inProgressCMPermnissions = data?.permissions?.permit?.inProgress
  const waitingFor3rdPartyCMPermnissions =
    data?.permissions?.permit?.waitingFor3rdParty
  const waitingForShutdownCMPermnissions =
    data?.permissions?.permit?.waitingForShutdown
  const waitingForMaterialCMPermnissions =
    data?.permissions?.permit?.waitingForMaterial
  const closeCMPermnissions = data?.permissions?.permit?.close

  const getTruthy = (value: String) => value === 'Yes'
  const getAccess = (values: any) =>
    values?.map((d: any) => getTruthy(d))?.includes(true)

  const canCreateCM = getAccess([
    createPermissions?.mechanical,
    createPermissions?.electrical,
    createPermissions?.instrument,
    createPermissions?.production,
    createPermissions?.hse,
    createPermissions?.wareHouse
  ])

  const canVerifyCM = getAccess([
    verifyCMPermissions?.mechanical,
    verifyCMPermissions?.electrical,
    verifyCMPermissions?.instrument,
    verifyCMPermissions?.production,
    verifyCMPermissions?.hse,
    verifyCMPermissions?.wareHouse
  ])

  const canApproveCM = getAccess([
    approveCMPermissions?.mechanical,
    approveCMPermissions?.electrical,
    approveCMPermissions?.instrument,
    approveCMPermissions?.production,
    approveCMPermissions?.hse,
    approveCMPermissions?.wareHouse
  ])

  const canViewInprogress = getAccess([
    inProgressCMPermnissions?.mechanical,
    inProgressCMPermnissions?.electrical,
    inProgressCMPermnissions?.instrument,
    inProgressCMPermnissions?.production,
    inProgressCMPermnissions?.hse,
    inProgressCMPermnissions?.wareHouse
  ])

  const canViewShutdown = getAccess([
    waitingForMaterialCMPermnissions?.mechanical,
    waitingForMaterialCMPermnissions?.electrical,
    waitingForMaterialCMPermnissions?.instrument,
    waitingForMaterialCMPermnissions?.production,
    waitingForMaterialCMPermnissions?.hse,
    waitingForMaterialCMPermnissions?.wareHouse
  ])

  const canView3Party = getAccess([
    waitingFor3rdPartyCMPermnissions?.mechanical,
    waitingFor3rdPartyCMPermnissions?.electrical,
    waitingFor3rdPartyCMPermnissions?.instrument,
    waitingFor3rdPartyCMPermnissions?.production,
    waitingFor3rdPartyCMPermnissions?.hse,
    waitingFor3rdPartyCMPermnissions?.wareHouse
  ])

  const canViewMaterial = getAccess([
    waitingForMaterialCMPermnissions?.mechanical,
    waitingForMaterialCMPermnissions?.electrical,
    waitingForMaterialCMPermnissions?.instrument,
    waitingForMaterialCMPermnissions?.production,
    waitingForMaterialCMPermnissions?.hse,
    waitingForMaterialCMPermnissions?.wareHouse
  ])

  const canCreateMechanicalCM = getTruthy(createPermissions?.mechnical)
  const canCreateElectricalCM = getTruthy(createPermissions?.electrical)
  const canCreateInstrumentCM = getTruthy(createPermissions?.instrument)
  const canCreateProductionCM = getTruthy(createPermissions?.production)
  const canCreateHSECM = getTruthy(createPermissions?.hse)
  const canCreateWareHouseCM = getTruthy(createPermissions?.wareHouse)

  const canMakeInProgressCM = (department: any) => {
    return getTruthy(inProgressCMPermnissions[department])
  }

  const canMakeWaitingForMaterialCM = (department: any) =>
    getTruthy(waitingForMaterialCMPermnissions[department])

  const canMakeWaitingFor3rdPartyCM = (department: any) =>
    getTruthy(waitingFor3rdPartyCMPermnissions[department])

  const canMakeWaitingForShutdownCM = (department: any) =>
    getTruthy(waitingForShutdownCMPermnissions[department])

  const canMakeCloseCM = (department: any) =>
    getTruthy(closeCMPermnissions[department])

  // const canMakeCloseCM = true

  return {
    canCreateCM,
    canVerifyCM,
    canApproveCM,
    canCreateMechanicalCM,
    canCreateElectricalCM,
    canCreateInstrumentCM,
    canCreateProductionCM,
    canCreateHSECM,
    canCreateWareHouseCM,
    canViewShutdown,
    canView3Party,
    canViewMaterial,
    canMakeInProgressCM,
    canMakeWaitingForMaterialCM,
    canMakeWaitingFor3rdPartyCM,
    canMakeWaitingForShutdownCM,
    canViewInprogress,
    canMakeCloseCM
  }
}
