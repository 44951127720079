import { Button, Col, ModalFooter, Row } from 'reactstrap'
import { Form } from 'react-final-form'

import AuditPreviewModal from '../Modals/AuditPreviewModal'
import CustomLabel from '../InputFields/CustomLabel'
import DropDownFetch from '../InputFields/DropDownFetch'
import LabeledDateTimeInput from '../InputFields/DateTime'
import LabeledParagraphInput from './../InputFields/LabledParagraph'
import LabeledRadioButtons from '../InputFields/LabeledRadioButton'
import LabeledTextInput from '../InputFields/LabeledTextInput'
import MultiSelect from '../InputFields/MultiSelect'
import Spacer from '../Spacer'
import theme from './../../components/constants/defaultTheme.json'
import { getOptions } from '../../pages/firs'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { permitFilters, usersEndpoint } from '../serverurl'
import { permitStatuses } from '../previews/ViewPermitdetails'
import { useContext, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { UserContext } from '../../App'
import { yesNoOptions } from '../../pages/eptw/VerifyPermit'

export default function AuditForm() {
  const { userData } = useContext(UserContext)
  // const { allUsers } = useContext(AllUsersContext)
  const { accounts, instance } = useMsal()
  const [preview, setPreview]: any = useState(false)

  const [permitNumber, setPermitNumber]: any = useState()
  const [status, setStatus]: any = useState()

  const fetchUsers = async () => {
    const response = await makeAuthenticatedRequest(
      usersEndpoint,
      'GET',
      null,
      accounts,
      instance
    )

    return response.data
  }

  const { data }: any = useQuery('fetchUsers', fetchUsers)

  const validate = (values: any) => {
    const errors: any = {}
    if (!values.isolationType) {
      errors.isolatoinType = 'Required'
    }
    if (!values.isolationMethod) {
      errors.isolationMethod = 'Required'
    }
    if (!values.pressureBuildup) {
      errors.pressureBuildup = 'Required'
    }
    if (!values.isolationState) {
      errors.isolationState = 'Required'
    }
    if (!values.deIsolatedState) {
      errors.deIsolationState = 'Required'
    }
    if (!values.isolationComment) {
      errors.isolationComment = 'Required'
    }
    if (!values.isolationName) {
      errors.isolationName = 'Required'
    }

    return errors
  }

  const teamList = data?.map(
    (user: any) => `${user?.jobTitle}-${user?.givenName}`
  )

  return (
    <>
      <Row>
        <Form
          onSubmit={(values: any) => {}}
          initialValues={{
            distributionAndDisplay: 'Yes',
            permitOriginator: `${userData?.surname}. ${userData?.givenName}`
          }}
          render={({ handleSubmit, form, hasValidationErrors, values }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <LabeledTextInput
                  name="permitOriginator"
                  label="Permit Originator"
                  value={`${userData?.surname}. ${userData?.givenName}`}
                  isDisabled
                  occupy={6}
                />
                <Col xs="3">
                  <CustomLabel label="Permit Number" />
                  <DropDownFetch
                    name="permitNumber"
                    value={permitNumber}
                    setValue={setPermitNumber}
                    url={`${permitFilters}/number`}
                    setDescription={setStatus}
                  />
                </Col>
                <Col xs="3">
                  <CustomLabel label="Permit Status" />
                  <p>{permitStatuses[status] || status}</p>
                </Col>
              </Row>
              <Spacer height={10} />
              <Row>
                <LabeledDateTimeInput name="auditDate" label="Audit Date" />
                <MultiSelect
                  name="auditPerson"
                  label="Audit Person"
                  options={getOptions(teamList)}
                  occupy={6}
                  isMulti={false}
                />
              </Row>
              <Spacer height={10} />
              <Row>
                <LabeledRadioButtons
                  defaultValue="Yes"
                  name="distributionAndDisplay"
                  label="Distribution and Dispaly"
                  description="Have permit copies been properly distributed, and where neccessary is a permit prominently displayed ?"
                  list={yesNoOptions}
                />
                <LabeledRadioButtons
                  name="scopeOfWork"
                  label="Scope of Work"
                  description="Is the work description on the permit adequate, ie does it adequately describe the work location, the equipment to be worked on , and the exact nature and scope of the work?"
                  list={yesNoOptions}
                />
              </Row>
              <Spacer height={10} />
              <Row>
                <CustomLabel label="Validity" />
              </Row>
              <Row
                style={{
                  backgroundColor: theme.colors.lightGray,
                  marginLeft: 5,
                  marginRight: 5,
                  padding: 10,
                  border: `1px solid ${theme.colors.lightGray1}`,
                  borderRadius: '5px'
                }}
              >
                <LabeledRadioButtons
                  occupy={4}
                  name="validityA"
                  description="The permit should clearlt state the time and dates between which its valid"
                  list={yesNoOptions}
                />
                <LabeledRadioButtons
                  occupy={4}
                  name="validityB"
                  description="Is permit revaidation being signed and dated by Issue Authority ?"
                  list={yesNoOptions}
                />
                <LabeledRadioButtons
                  occupy={4}
                  name="validityC"
                  description="Are revalidations up to date ?"
                  list={yesNoOptions}
                />
              </Row>
              <Spacer height={10} />
              <Row>
                <CustomLabel label="Hazards" />
              </Row>
              <Row
                style={{
                  backgroundColor: theme.colors.lightGray,
                  marginLeft: 5,
                  marginRight: 5,
                  padding: 10,
                  border: `1px solid ${theme.colors.lightGray1}`,
                  borderRadius: '5px'
                }}
              >
                <LabeledRadioButtons
                  occupy={6}
                  name="hazardA"
                  description="Are hazards clearly identified ?"
                  list={yesNoOptions}
                />
                <LabeledRadioButtons
                  occupy={6}
                  name="hazardB"
                  description="Are they directly applicable to the job being undertaken ?"
                  list={yesNoOptions}
                />
              </Row>
              <Spacer height={10} />
              <Row>
                <CustomLabel label="Precatutions" />
              </Row>
              <Row
                style={{
                  backgroundColor: theme.colors.lightGray,
                  marginLeft: 5,
                  marginRight: 5,
                  padding: 10,
                  border: `1px solid ${theme.colors.lightGray1}`,
                  borderRadius: '5px'
                }}
              >
                <Row>
                  <LabeledRadioButtons
                    occupy={4}
                    name="precautionA"
                    description="Are appropriate precautions identified and specific enough ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={4}
                    name="precautionB"
                    description="Have other affected personnel outside the permit area been notified of the permit work ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={4}
                    name="precautionC"
                    description="Have all the precautions been implemented at the worksite ?"
                    list={yesNoOptions}
                  />
                </Row>
                <Spacer height={10} />
                <Row>
                  <LabeledRadioButtons
                    occupy={4}
                    name="precautionD"
                    description="Have all the precautions been implemented at the worksite ?"
                    list={yesNoOptions}
                  />
                  <LabeledParagraphInput
                    description="If not, explain deficiency"
                    name="precautionE"
                  />
                </Row>
              </Row>
              <Spacer height={20} />
              <Row>
                <CustomLabel label="Gas Tests" />
              </Row>
              <Row
                style={{
                  backgroundColor: theme.colors.lightGray,
                  marginLeft: 5,
                  marginRight: 5,
                  padding: 10,
                  border: `1px solid ${theme.colors.lightGray1}`,
                  borderRadius: '5px'
                }}
              >
                <Row>
                  <LabeledRadioButtons
                    occupy={4}
                    name="gasTestA"
                    description="have gas test been undertaken ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={4}
                    name="gasTestB"
                    description="Are tests valid for this period ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={4}
                    name="gasTestC"
                    description="Are portable gas monitors fully oprable at worksite and caliberated ?"
                    list={yesNoOptions}
                  />
                </Row>
                <Spacer height={10} />
                <Row>
                  <LabeledRadioButtons
                    occupy={4}
                    name="gasTestD"
                    description="Are on-site personnel knowledgeable on how to operate equipment ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={4}
                    name="gasTestE"
                    description="Have they been properly trained to use ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={4}
                    name="gasTestF"
                    description="Has periodic testing been carried out as appropriate ?"
                    list={yesNoOptions}
                  />
                </Row>
              </Row>
              <Spacer height={10} />
              <Row>
                <CustomLabel label="Precatutions taken by Performing Auhority" />
              </Row>
              <Row
                style={{
                  backgroundColor: theme.colors.lightGray,
                  marginLeft: 5,
                  marginRight: 5,
                  padding: 10,
                  border: `1px solid ${theme.colors.lightGray1}`,
                  borderRadius: '5px'
                }}
              >
                <Row>
                  <LabeledRadioButtons
                    occupy={4}
                    name="precautionPAA"
                    description="Has performin Authority briefed everyone in the Work Party"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={4}
                    name="precautionPAB"
                    description="Have all persons in the Work Party read the permit ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={4}
                    name="precautionPAC"
                    description="Do all fully understand the safety requirements and the precautions stated on the PTW ?"
                    list={yesNoOptions}
                  />
                </Row>
                <Spacer height={10} />
                <Row>
                  <LabeledRadioButtons
                    occupy={4}
                    name="precautionPAD"
                    description="Are they in complicance, eg using safety clothing as specified , isolation equipment at breaks, etc?"
                    list={yesNoOptions}
                  />
                  <LabeledParagraphInput
                    description="If not, specify"
                    name="precautionPAE"
                  />
                </Row>
              </Row>
              <Spacer height={10} />
              <Row>
                <CustomLabel label="Isolation" />
              </Row>
              <Row
                style={{
                  backgroundColor: theme.colors.lightGray,
                  marginLeft: 5,
                  marginRight: 5,
                  padding: 10,
                  border: `1px solid ${theme.colors.lightGray1}`,
                  borderRadius: '5px'
                }}
              >
                <Row>
                  <LabeledRadioButtons
                    occupy={3}
                    name="isolationA"
                    description="Are isolation certificates attached to the permits ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={3}
                    name="isolationB"
                    description="Are they cross-referenced ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={3}
                    name="isolationC"
                    description="Are all isolations secure ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={3}
                    name="isolationD"
                    description="Do all fully understand the safety requirements and the precautions stated on the PTW ?"
                    list={yesNoOptions}
                  />
                </Row>
                <Spacer height={10} />
                <Row>
                  <LabeledRadioButtons
                    occupy={4}
                    name="isolationE"
                    description="Do certificates or attachments detail specific isolations point ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={4}
                    name="isolationF"
                    description="If more than one task, on same isolation, has multiple lock/key system or other suitable control been used ?"
                    list={yesNoOptions}
                  />
                  <LabeledParagraphInput
                    occupy={4}
                    description="If so, give details"
                    name="isolationG"
                  />
                </Row>
              </Row>

              <Spacer height={10} />
              <Row>
                <CustomLabel label="Precatutions by Issue Auhority" />
              </Row>
              <Row
                style={{
                  backgroundColor: theme.colors.lightGray,
                  marginLeft: 5,
                  marginRight: 5,
                  padding: 10,
                  border: `1px solid ${theme.colors.lightGray1}`,
                  borderRadius: '5px'
                }}
              >
                <Row>
                  <LabeledRadioButtons
                    occupy={6}
                    name="precautionIAA"
                    description="Has task been fully discussed with person carrying out the isolation ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={6}
                    name="precautionIAB"
                    description="Has the task been discussed with performing Authority ?"
                    list={yesNoOptions}
                  />
                </Row>
                <Spacer height={10} />
                <Row>
                  <LabeledRadioButtons
                    occupy={6}
                    name="precautionIAC"
                    description="If more than one permit on equipment / system, have all appropriate cross-references been made and all neccessary personnel been made aware ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={6}
                    name="precautionIAD"
                    description="Where tasks may impinge on other responsible persons / areas, have affected persons signed to acknowledge their awareness of the work ?"
                    list={yesNoOptions}
                  />
                </Row>
              </Row>

              <Spacer height={10} />
              <Row>
                <CustomLabel label="Hand Over" />
              </Row>
              <Row
                style={{
                  backgroundColor: theme.colors.lightGray,
                  marginLeft: 5,
                  marginRight: 5,
                  padding: 10,
                  border: `1px solid ${theme.colors.lightGray1}`,
                  borderRadius: '5px'
                }}
              >
                <Row>
                  <LabeledRadioButtons
                    occupy={6}
                    name="handOverA"
                    description="Has handover of permit been done between Issue Authorityn at shift change ?"
                    list={yesNoOptions}
                  />
                  <LabeledParagraphInput
                    occupy={6}
                    description="How is this being documented ?"
                    name="handOverB"
                  />
                </Row>
                <Spacer height={10} />
                <Row>
                  <LabeledRadioButtons
                    occupy={6}
                    name="handOverC"
                    description="Has a handover of jobs between Performing Authorities been done ?"
                    list={yesNoOptions}
                  />
                  <LabeledParagraphInput
                    occupy={6}
                    description="How is this being documented ?"
                    name="handOverD"
                  />
                </Row>
              </Row>
              <Spacer height={10} />
              <Row>
                <CustomLabel label="Work Suspened / On Hold / Completed" />
              </Row>
              <Row
                style={{
                  backgroundColor: theme.colors.lightGray,
                  marginLeft: 5,
                  marginRight: 5,
                  padding: 10,
                  border: `1px solid ${theme.colors.lightGray1}`,
                  borderRadius: '5px'
                }}
              >
                <Row>
                  <LabeledRadioButtons
                    occupy={3}
                    name="WOCA"
                    description="Has site be left in a safe and tidy conditions ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={3}
                    name="WOCB"
                    description="If work not completed, are isolations secure ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={3}
                    name="WOCC"
                    description="Is Issue Authority aware of status ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={3}
                    name="WOCD"
                    description="Is control room aware of status ?"
                    list={yesNoOptions}
                  />
                </Row>
              </Row>

              <Spacer height={10} />
              <Row>
                <CustomLabel label="Training" />
              </Row>
              <Row
                style={{
                  backgroundColor: theme.colors.lightGray,
                  marginLeft: 5,
                  marginRight: 5,
                  padding: 10,
                  border: `1px solid ${theme.colors.lightGray1}`,
                  borderRadius: '5px'
                }}
              >
                <Row>
                  <LabeledRadioButtons
                    occupy={4}
                    name="trainingA"
                    description="Have personnel, who are currently associated with this permit, received training in the companys PTW system ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={4}
                    name="trainingB"
                    description="Is the type and frequency of training in accordance with company policy ?"
                    list={yesNoOptions}
                  />
                  <LabeledRadioButtons
                    occupy={4}
                    name="trainingC"
                    description="Is evidence of their training and appointment readily available ?"
                    list={yesNoOptions}
                  />
                </Row>
              </Row>
              <Spacer height={30} />
              <ModalFooter>
                <Button
                  onClick={() => setPreview(true)}
                  color="warning"
                  children="Preview"
                />
              </ModalFooter>
              <AuditPreviewModal
                isOpen={preview}
                toggle={() => setPreview(!preview)}
                values={{
                  ...values,
                  permitNumber: permitNumber,
                  permitStatus: status
                }}
                form={form}
              />
            </form>
          )}
        />
      </Row>
    </>
  )
}
