import { RiLoader2Line } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { Badge, Col } from 'reactstrap'

import defaultTheme from './../constants/defaultTheme.json'

type Type = {
  style?: object
  src?: any
  name?: string
  nav?: string
  canView?: boolean
  count?: any
}

export function IconCard({
  style,
  src,
  name,
  nav,
  canView = true,
  count = 0
}: Type) {
  const navigate = useNavigate()

  if (!canView) {
    return null
  }

  const redirectTo = () => {
    navigate(`../${nav}`)
  }

  const gradiantBox = {
    alignItems: 'center',
    background: '#FAFAD2',
    border: '6px solid #FFF',
    borderRadius: 10,
    boxShadow:
      'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
    cursor: 'pointer',
    display: 'flex',
    height: '16vh',
    justifyContent: 'space-between',
    marginBottom: 20,
    marginLeft: 15,
    padding: '10px 5px 10px 6px',
    width: '25vh'
  }

  return (
    <>
      <Col
        xs="1"
        style={{
          ...gradiantBox,
          // ...style,
          alignItems: 'center',
          position: 'relative'
        }}
        onClick={() => redirectTo()}
      >
        <p
          style={{
            fontSize: 14,
            width: '50%',
            fontWeight: 900,
            // color: defaultTheme.fontColor.white,
            textAlign: 'center',
            fontFamily: 'Arial Black',
            margin: 0
          }}
        >
          {name?.toUpperCase()}
        </p>
        <div
          style={{ height: '70%', borderRight: '1px solid #000', width: '5%' }}
        />
        {
          <div
            style={{
              height: '15vh',
              width: '45%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 10
            }}
          >
            <div style={{ height: '9vh', width: 'auto' }}>
              <img
                src={
                  src
                    ? src
                    : require('./../../../src/images/Icons/IconLoading.png')
                }
                alt={'Icon'}
                style={{
                  height: '9vh',
                  width: '9vh',
                  objectFit: 'contain'
                }}
              />
            </div>
          </div>
        }
        {!(count === 0) && typeof count === 'number' && (
          <Badge
            pill
            color="danger"
            style={{
              position: 'absolute',
              right: '-11px',
              top: '-15px',
              color: '#fff',
              height: 50,
              width: 50,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 20,
              fontWeight: 700
            }}
          >
            {count > 99 ? '99+' : count}
          </Badge>
        )}
      </Col>
    </>
  )
}
