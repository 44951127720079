import { Button, ModalFooter, Row } from 'reactstrap'
import RcmLayout from '../../components/rcm/RcmLayout'
import { SubHeader } from '../VerifyCM'
import ProcessFlowDiagram from '../../SVGs/ProcessFlowDiagram'
import { useLocation, useNavigate } from 'react-router-dom'

export default function RcmSystemBoundary() {
  const navigate = useNavigate()
  const location = useLocation()
  const currentRcmId = new URLSearchParams(location.search).get('id')
  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15 }}>
        <SubHeader header={'System Boundary'} permitColor="gold" />
        <ProcessFlowDiagram setPid={(e: any) => navigate(`${e}`)} />
        <ModalFooter>
          <Button
            color="warning"
            onClick={() =>
              navigate(`/cmms/rcm/systemBoundary/manual?id=${currentRcmId}`)
            }
          >
            Do it Manually
          </Button>
        </ModalFooter>
      </Row>
    </RcmLayout>
  )
}
