export const SerialCodes = {
  originator: ['E73', 'ABN', 'HOE'],
  origin: ['UNT1', 'UNT2', 'UNT3', 'UNT4'],
  logicCodes: [
    {
      label: 'Control of Non-Conforming Product or Processes',
      value: 'CNP'
    },
    {
      label: 'Expediting',
      value: 'EXP'
    },
    {
      label: 'Planning and Reporting',
      value: 'PNR'
    }
  ],
  departmentCodes: [
    {
      label: 'Accounting',
      value: 'ACC'
    },
    {
      label: 'Administration',
      value: 'ADM'
    },
    {
      label: 'CMMS',
      value: 'CMM'
    },
    {
      label: 'Electrical',
      value: 'ELE'
    },
    {
      label: 'Instrumentation',
      value: 'INS'
    },
    {
      label: 'Health, Safety and Environment',
      value: 'HSE'
    },
    {
      label: 'Corporate Finance',
      value: 'FIN'
    },
    {
      label: 'Engineering',
      value: 'ENG'
    },
    {
      label: 'Mechanical',
      value: 'MEC'
    },
    {
      label: 'Process',
      value: 'PRS'
    }
  ],
  documentTypeCodes: [
    {
      label: 'Agreement',
      value: 'AGR'
    },
    {
      label: 'Budget',
      value: 'BGT'
    },
    {
      label: 'CBTA’s',
      value: 'CBT'
    },
    {
      label: 'Calculation',
      value: 'CAL'
    },
    {
      label: 'Correspondences',
      value: 'COR'
    },
    {
      label: 'Competency Profiles',
      value: 'CMP'
    },
    {
      label: 'Contracts',
      value: 'CNT'
    },
    {
      label: 'Invoices',
      value: 'INV'
    }
  ]
}
