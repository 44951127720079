import React from 'react'
import { Button, Input, Row } from 'reactstrap'
import { useContext, useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import CustomLabel from '../../components/InputFields/CustomLabel'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import SubmitPermitReport from '../../components/Modals/SubmitPermitReport'
import ViewPermit from './ViewPermit'
import { UserContext } from '../../App'
import {
  cioEndpoint,
  cwoiEndpoint,
  deIsolationCloseEndPoint,
  epermit,
  ltiEndPoint,
  revalidateEndpoint,
  usersEndpoint
} from '../../components/serverurl'
import {
  doneRequiredOptions,
  RadioButtonGroup,
  yesNoOptions
} from './VerifyPermit'
import { getEpermitPermissions } from '../../components/lib/epermitPermissions'
import { getOptions } from '../firs'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { useAdminPermissions } from './EditRisks'

export default function UpdateLivePermit({ onBack }: any) {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  // const { allUsers } = useContext(AllUsersContext)
  const { userData } = useContext(UserContext)

  const navigate = useNavigate()

  const [permissions, setPermissions]: any = useState()
  const [endPoint, setEndPoint]: any = useState()
  const [consentForm, setConsentForm]: any = useState('')
  const [reportModal, setReportModal]: any = useState('')

  const fetchUsers = async () => {
    const response = await makeAuthenticatedRequest(
      usersEndpoint,
      'GET',
      null,
      accounts,
      instance
    )

    return response.data
  }

  const { data: users }: any = useQuery('fetchUsers', fetchUsers)

  const currentDepartmentUsers = users?.filter(
    (u: any) => u?.department?.trim() === userData?.department?.trim()
  )

  const assigneeList = currentDepartmentUsers?.map((d: any) => ({
    department: d?.department,
    desgination: d?.jobTitle,
    name: d?.givenName,
    employeeId: d?.employeeId
  }))

  useEffect(() => {
    if (!permissions) {
      getEpermitPermissions(accounts, instance).then((response: any) =>
        setPermissions(response)
      )
    }
  })

  const { data, isLoading, refetch } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const [submitModal, setSubmitModal]: any = useState(false)

  const [formData, setFormData]: any = useState({
    carriedOutProperly: 'No',
    informed: 'No',
    areaChecked: 'No',
    workComplete: 'Incomplete',
    needNew: 'Not Required',
    unitShutdown: 'No',
    plantShutdown: 'No',
    departmentWorked: 'NA',
    vendorUsed: 'NA',
    history: 'NA',
    witness: 'NA',
    ltiIncident: 'No',
    companyProcedure: 'No',
    communicated: 'No',
    lotoFollowed: 'No',
    reason: 'NA',
    jobScope: 'No',
    teamMembers: 'No',
    discussed: 'No',
    assignTo: undefined,
    surroundingAreaCheck: 'Not Required'
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    if (!endPoint) {
      return null
    }

    const submitValues: any = {
      consent: {
        ...formData
      },

      permitId: data?.permit?._id,
      consentId: data?.permit?.consent?._id
    }

    const response = await makeAuthenticatedRequest(
      `${endPoint}/initiate`,
      'PATCH',
      submitValues,
      accounts,
      instance
    )

    return response.status
  }

  const form = (
    <div>
      <RadioButtonGroup
        question="Any Production loss occurred while performing this Task ?"
        name="areaChecked"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Any LTI incident while performing this Task ?"
        name="ltiIncident"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Surrounding area checked / cleaned ?"
        name="surroundingAreaCheck"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
    </div>
  )

  const deisolationAndClose = (
    <div>
      <RadioButtonGroup
        question="Is this work ?"
        name="workComplete"
        options={getOptions(['Complete', 'Incomplete'])}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Incomplete"
      />
      <RadioButtonGroup
        question="If Incomplete do u need to create a new CM Work Order?"
        name="needNew"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Unit shutdown occurred while performing this Task ?"
        name="unitShutdown"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Any LTI incident while performing this Task?"
        name="ltiIncident"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Plant shutdown occurred while performing this Task ?"
        name="plantShutdown"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
    </div>
  )

  const ltiForm = (
    <div>
      <RadioButtonGroup
        question="Is the Isolation for LTI followed as per Company Procedure? ?"
        name="companyProcedure"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Do you communicate with all the team members, IM, PIC and Control Room for LTI ?"
        name="communicated"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Is the LOTO followed as per Isolation Philosophy ?"
        name="lotoFollowed"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <div className="form-group">
        <CustomLabel label="Reason for LTI" />
        <Input type="text" name="reason" onChange={handleInputChange} />
      </div>
    </div>
  )

  const cioForm = (
    <div>
      <RadioButtonGroup
        question="Is the job scope is permit is discussed with the existing Job Performer ?"
        name="jobScope"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Is your Team members, Control Room,PIC, AO and IM informed about your change in Ownership ?"
        name="teamMembers"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Have you discussed the Risk assessment, and work scope of this Permit with IM ?"
        name="discussed"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <div className="form-group">
        <CustomLabel label="Assign To" />
        <select
          value={formData['assignTo']}
          onChange={handleInputChange}
          name="assignTo"
          style={{
            padding: '5px',
            fontSize: '14px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            width: 'inherit',
            height: '50px'
          }}
        >
          <option value={undefined}></option>
          {assigneeList?.map((u: any) => (
            <>
              <option
                value={JSON.stringify(u)}
              >{`${u?.name}-${u?.desgination}-${u?.department}-${u?.employeeId}`}</option>
            </>
          ))}
        </select>
        {!formData?.assignedTo && (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              color: 'red',
              paddingRight: 10
            }}
          >
            * Please Select user
          </span>
        )}
      </div>
    </div>
  )

  const enableClose = Boolean(data?.report?._id)
  const hasIsolations = data?.permit?.isolations

  return (
    <ProtectedLayout onBack={onBack}>
      <ViewPermit data={data} isLoading={isLoading} />
      {(data?.permit?.status === 'live' ||
        data?.permit?.status === 'suspended' ||
        data?.permit?.status === 'overdue' ||
        data?.permit?.status === 'expired') && (
        <>
          <Row style={{ margin: 10 }}>
            {permissions?.canInitiateRevalidation &&
              data?.permit?.type === 'coldWork' &&
              !enableClose && (
                <Button
                  color="warning"
                  style={{ marginTop: 10 }}
                  onClick={() => {
                    setEndPoint(revalidateEndpoint)
                    setSubmitModal(true)
                    setConsentForm('revalidate')
                  }}
                >
                  Revalidate
                </Button>
              )}
            {permissions?.canInitiateDeisolationClose && hasIsolations && (
              <>
                <Button
                  color={enableClose ? 'warning' : 'secondary'}
                  disabled={!enableClose}
                  style={{ marginTop: 10 }}
                  onClick={() => {
                    setEndPoint(deIsolationCloseEndPoint)
                    setSubmitModal(true)
                    setConsentForm('dic')
                  }}
                >
                  {`DeIsolation And Close`}{' '}
                  {!enableClose && '(Please Submit Report)'}
                </Button>
              </>
            )}
            {permissions?.canInitiateCIO &&
              !enableClose &&
              data?.permit?.status !== 'expired' && (
                <Button
                  color="warning"
                  style={{ marginTop: 10 }}
                  onClick={() => {
                    setEndPoint(cioEndpoint)
                    setSubmitModal(true)
                    setConsentForm('cio')
                  }}
                >
                  Change In Ownership
                </Button>
              )}
            {permissions?.canInitiateLTI && hasIsolations && (
              <Button
                color="warning"
                style={{ marginTop: 10 }}
                onClick={() => {
                  setEndPoint(ltiEndPoint)
                  setSubmitModal(true)
                  setConsentForm('lti')
                }}
              >
                Long Term Isolation
              </Button>
            )}
            {permissions?.canInitateCWOI && !hasIsolations && (
              <Button
                color={enableClose ? 'warning' : 'secondary'}
                disabled={!enableClose}
                style={{ marginTop: 10 }}
                onClick={() => {
                  setEndPoint(cwoiEndpoint)
                  setSubmitModal(true)
                  setConsentForm('cwoi')
                }}
              >
                {`Close With out Isolation`}{' '}
                {!enableClose && '(Please Submit Report)'}
              </Button>
            )}
            {data?.permit?.type === 'coldWork' &&
              data?.permit?.status !== 'suspended' && (
                <Button
                  color="warning"
                  style={{ marginTop: 10 }}
                  onClick={() => {
                    setEndPoint(`${epermit}/suspend`)
                    setSubmitModal(true)
                    setConsentForm('suspend')
                  }}
                >
                  Suspend
                </Button>
              )}
            <Spacer height={20} />
            {permissions?.canInitiateDeisolationClose && !enableClose && (
              <Button
                color="warning"
                style={{ marginTop: 10 }}
                onClick={() => {
                  setReportModal(true)
                }}
              >
                Submit Report
              </Button>
            )}
          </Row>

          {submitModal && (
            <SubmitModalWithForm
              size="xl"
              isOpen={submitModal}
              onClose={(val: any) => {
                setSubmitModal(false)
                val && navigate(onBack)
              }}
              isDisabled={consentForm === 'cio' && !formData?.assignTo}
              onSubmit={handleSubmit}
              form={
                (consentForm === 'cio' && cioForm) ||
                (consentForm === 'revalidate' && form) ||
                (consentForm === 'lti' && ltiForm) ||
                (consentForm === 'dic' && deisolationAndClose) ||
                (consentForm === 'cwoi' && deisolationAndClose) ||
                (consentForm === 'suspend' && (
                  <p>Are you sure you want to Suspend Permit?</p>
                ))
              }
            />
          )}
          {reportModal && (
            <SubmitPermitReport
              size="xl"
              isOpen={reportModal}
              permit={data?.permit}
              refetch={refetch}
              onClose={async (val: any) => {
                setReportModal(false)
              }}
            />
          )}
        </>
      )}
    </ProtectedLayout>
  )
}
