export const isolationDetails: any = {
  '43-BAV-6804': 'Ball Valve',
  '43-PG-003B': 'Pressure Gauge',
  '43-BAV-6741': 'Ball Valve ',
  '43-CHV-6701': 'Check Valve',
  '43-BAV-6742': 'Ball Valve ',
  '43-BAV-6803': 'Ball Valve ',
  '43-BAV-6802': 'Ball Valve ',
  '43-PB-002B': 'MOTOR',
  '43-BAV-6736': 'Ball Valve ',
  '43-SP-6712': 'Ball Valve ',
  '43-BAV-6737': 'Ball Valve ',
  '43-PSV-013B': 'Pressure Safety Valve',
  '43-PB002B-S01': 'Elecrical Motor',
  _43LZT002: 'Level Shutdown Transmitter',
  '43-BAV-6733': 'Ball Valve ',
  '43-PB002A-S01': 'Elecrical Motor',
  '63-BAV-6700': 'Ball Valve ',
  '67-BAV-6700': 'Ball Valve ',
  '43-XL-002B': 'Motor XL',
  '43-XA-002B': 'Motor XA',
  '43-XK-002B': 'Motor XK',
  '43-BAV-6734': 'Ball Valve ',
  '43-PB-002A': 'Motor',
  '43-HS-002A': 'Motor XK',
  '43-XL-002A': 'Motor XL',
  '43-XA-002A': 'Motor XA',
  '43-XK-002A': 'Motor XK',
  '43-LT-001': 'Level Transmitter',
  '43-LG-001': 'Level Guage',
  '43-BAV-6729': 'Ball Valve ',
  '43-BAV-6731': 'Ball Valve ',
  '43-LZT-008': 'Level Shutdown Transmitter',
  '23-CHV-6700': 'Check Valve',
  '23-BAV-6700': 'Ball Valve ',
  '23-PZ-1670': 'Pressure Shutdown Valve',
  '68-PZ-1651': 'Pressure Shutdown Valve',
  '68-BAV-6700': 'Ball Valve ',
  '68-CHV-6700': 'Check Valve',
  '68-XV-655': 'Valve',
  '68-ZSC-655': 'Valve Close Command',
  '68-ZSO-655': 'Valve Open Command',
  '43-PG-004': 'Pressure Guage',
  '43-BAV-6743': 'Ball Valve ',
  '43-PT-001': 'Pressure Transmitter',
  '43-FE-001': 'Flow Element',
  '43-FT-001': 'Flow Transmitter',
  '43-BAV-6801': 'Ball Valve ',
  '43-TW-001': 'Temperature Element',
  '43-TE-001': 'Temperature Element',
  '43-PT-001-2': 'Pressure Transmitter',
  '43-XY-001': 'Valve',
  '43-S-001': 'Valve',
  '43-BAV-6819': 'Ball Valve ',
  '43-BAV-6807': 'Ball Valve ',
  '43-PSV-013A': 'Pressure Safety Valve',
  '43-BAV-6806': 'Ball Valve ',
  '43-BAV-6805': 'Ball Valve ',
  '43-PG-003A': 'Pressuure Gauge',
  '43-BAV-6739': 'Ball Valve ',
  '43-CHV-6700': 'Check Valve',
  '7110-GTG': 'GTG',
  '7111-GTG': 'GTG',
  '7112-GTG': 'GTG',
  '7510-A18-VCB-1': 'VCB',
  '7510-A21-VCB-2': 'VCB',
  '7510-A26-VCB': 'VCB',
  '7510-A20-VCB-4': 'VCB',
  '7510-A23-VCB-5': 'VCB',
  '7510-A14-VCB-6': 'VCB',
  '7510-A14-VCU-SPARE-STARTER': 'VCB',
  '7510-A14-VCB-SPARE-FEEDER': 'VCB',
  '7510-A14-VCB': 'VCB',
  '7510-A12-VCB-7': 'VCB',
  '7510-A13-VCB-7': 'VCB',
  '7510-A13-VCU': 'VCB',
  '7510-A16-VCB': 'VCB',
  '7510-A17-VCB': 'VCB',
  '7510-A15-VCB-9': 'VCB',
  '7510-A27-VCB-10': 'VCB',
  '7510-A28VCB-11': 'VCB',
  '7510-A32-VCB-12': 'VCB',
  '7510-A31-VCB': 'VCB',
  '7510-A31-VCB-SPARE-FEEDER': 'VCB',
  '7510-A31-VCU-SPARE-STARTER': 'VCB',
  '7510-A30-VCB': 'VCB',
  '7510-A29-VCB-13': 'VCB',
  '7510-A29-VCU': 'VCB',
  '7510-A29-VCU-2': 'VCB',
  '7530-01': 'Electrical Board',
  MOTOR_STR_NOTE7: 'Electrical Board',
  '7534': 'Electrical Board',
  '7520-01': 'Electrical Board',
  MOTOR_1: 'Electrical Board',
  PCC1: 'Electrical Board',
  '7520-02': 'Electrical Board',
  '7530-02': 'Electrical Board',
  'TXR-7520-02': 'Electrical Board',
  'PCC1-2': 'Electrical Board',
  '7520-04': 'Electrical Board',
  'MOTOR-1-2': 'Electrical Board',
  'MOTOR-STR-NOTE7-2': 'Electrical Board',
  '7510-SWB': 'Electrical Board',
  '7610-SWB': 'Electrical Board',
  '3X-46': 'Electrical Board',
  '3X-47': 'Electrical Board',
  '3X-21': 'Electrical Board',
  '3X-22': 'Electrical Board',
  '3X-23': 'Electrical Board',
  '3X-24': 'Electrical Board',
  '3X-25': 'Electrical Board',
  '3X-26': 'Electrical Board',
  '3X-27': 'Electrical Board',
  '3X-31': 'Electrical Board',
  '3X-28': 'Electrical Board',
  '3X-29': 'Electrical Board',
  '3X-32': 'Electrical Board',
  '3X-30': 'Electrical Board',
  '3X-33': 'Electrical Board',
  '3X-85': 'Electrical Board',
  '7610-02-SWB': 'Electrical Board',
  '3X-71': 'Electrical Board',
  '3X-72': 'Electrical Board',
  '3X-84': 'Electrical Board',
  '3X-34': 'Electrical Board',
  '3X-35': 'Electrical Board',
  '3X-36': 'Electrical Board',
  '3X-37': 'Electrical Board',
  '3X-38': 'Electrical Board',
  '3X-39': 'Electrical Board',
  '3X-40': 'Electrical Board',
  '3X-42': 'Electrical Board',
  '3X-43': 'Electrical Board',
  '3X-44': 'Electrical Board',
  '3X-45': 'Electrical Board',
  '7620-01-SWB': 'Electrical Board',
  '3X-73': 'Electrical Board',
  '3X-74': 'Electrical Board',
  '3X-89': 'Electrical Board',
  '3X-48': 'Electrical Board',
  '3X-49': 'Electrical Board',
  '3X-50': 'Electrical Board',
  '3X-51': 'Electrical Board',
  '3X-52': 'Electrical Board',
  '3X-53': 'Electrical Board',
  '3X-54': 'Electrical Board',
  '3X-55': 'Electrical Board',
  '3X-56': 'Electrical Board',
  '3X-57': 'Electrical Board',
  '3X-58': 'Electrical Board',
  '3X-59': 'Electrical Board',
  Group_22: 'Electrical Board',
  Group_22_copy: 'Electrical Board',
  '7810-01-SWB-copy': 'Electrical Board',
  '3X-90': 'Electrical Board',
  '3X-91': 'Electrical Board',
  '3X-88': 'Electrical Board',
  '3X-60': 'Electrical Board',
  '3X-61': 'Electrical Board',
  '3X-62': 'Electrical Board',
  '3X-63': 'Electrical Board',
  '3X-64': 'Electrical Board',
  '3X-65': 'Electrical Board',
  '3X-66': 'Electrical Board',
  '3X-67': 'Electrical Board',
  '3X-68': 'Electrical Board',
  '3X-69': 'Electrical Board',
  '3X-70': 'Electrical Board',
  '7510-02-SWG': 'Electrical Board',
  '3X-75': 'Electrical Board',
  '3X-76': 'Electrical Board',
  '3X-77': 'Electrical Board',
  '3X-78': 'Electrical Board',
  '3X-79': 'Electrical Board',
  '3X-86': 'Electrical Board',
  '3X-87': 'Electrical Board',
  '3X-80': 'Electrical Board',
  'Mail-4': 'Electrical Board',
  'Mail-5': 'Electrical Board',
  '3X-81': 'Electrical Board',
  '3X-82': 'Electrical Board',
  'Mail-6': 'Electrical Board',
  'Mail-7': 'Electrical Board',
  '3X-83': 'Electrical Board',
  '7930': 'Electrical Board',
  '7920': 'Electrical Board'
}

export const pressureGuage = ['43-PG-003B']
