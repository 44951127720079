import { Row, Spinner, Table } from 'reactstrap'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { mocApi } from '../../components/serverurl'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { incrementby1 } from '../../components/Forms/JSAForm'
import moment from 'moment'
import { Link } from '../workManagement/PmTree'
import { useNavigate } from 'react-router-dom'

export const TableLoadingState: any = () => (
  <tbody>
    <tr>
      <td
        colSpan={12}
        style={{
          height: '60vh',
          textAlign: 'center',
          verticalAlign: 'middle'
        }}
      >
        <Spinner size={'sm'} />
      </td>
    </tr>
  </tbody>
)

export default function MocList({ title, onBack, stage }: any) {
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const fetchReports = async () =>
    await makeAuthenticatedRequest(
      `${mocApi}?status=${stage}`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data, isLoading, refetch, isRefetching }: any = useQuery(
    `${stage} Reports`,
    fetchReports
  )

  const headers = {
    Type: 'mocType',
    Equipment: 'equipment',
    Department: 'department',
    'Created on': 'createdOn',
    Originator: 'originator'
  }

  return (
    <ProtectedLayout title={title} onBack={'/firs/moc'}>
      <Row style={{ margin: 15 }}>
        <Table bordered style={{ borderColor: '#000' }}>
          <thead style={{ backgroundColor: 'gold' }}>
            <tr>
              <th>S.No</th>
              <th>Moc Number</th>
              {Object.keys(headers)?.map((h: any, index: any) => (
                <th key={h}>{h}</th>
              ))}
            </tr>
          </thead>
          {isLoading || isRefetching ? (
            <TableLoadingState />
          ) : (
            <tbody>
              {data?.reports?.map((r: any, index: any) => (
                <tr key={r?._id}>
                  <td>{incrementby1(index)}</td>
                  <td>
                    <Link onClick={() => navigate(r?._id)}>{r?.number}</Link>
                  </td>
                  {Object.values(headers)?.map((v: any) => (
                    <td>
                      {v === 'createdOn'
                        ? moment(r[v]).format('DD-MM-YYYY')
                        : r[v]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </Row>
    </ProtectedLayout>
  )
}
