import {
  Button,
  Col,
  Input,
  ModalFooter,
  Row,
  Spinner,
  Table
} from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import { Box, renderData } from '../workManagement/PmTree'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { stock, usersEndpoint } from '../../components/serverurl'
import { Text } from '../../components/RiskAssesmentPreview'
import ReactToPrint from 'react-to-print'
import React, { useContext, useEffect, useRef, useState } from 'react'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { UserContext } from '../../App'
import CustomLabel from '../../components/InputFields/CustomLabel'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { Form } from 'react-final-form'
function generateValuesUntilZero(startingNumber: any) {
  if (typeof startingNumber !== 'number' || isNaN(startingNumber)) {
    throw new Error('Please provide a valid number.')
  }

  const resultArray = []
  for (let i = startingNumber; i >= 0; i--) {
    resultArray.push(i)
  }

  return resultArray
}

export const AcceptedPOTable = ({
  data,
  isLoading,
  isRefetching
}: {
  data: any
  isLoading?: boolean
  isRefetching?: boolean
}) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Spare Part Number</th>
            <th>Spare Part Description</th>
            <th>Quantity</th>
            <th>Gross Price</th>
            <th>Promised Date</th>
          </tr>
        </thead>
        <tbody>
          <>
            {isLoading || isRefetching ? (
              <tr>
                <td colSpan={12}>
                  <Row
                    style={{
                      height: 500,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Spinner />
                  </Row>
                </td>
              </tr>
            ) : (
              data?.length > 0 &&
              data?.map((w: any, index: number) => (
                <React.Fragment>
                  <tr key={w?._id}>
                    <th colSpan={12}>
                      {w?.number?.replace('PR', 'PO')}-{w?.tagDescription}
                      <br />
                      Order Value: {w?.approvedVendor?.total}
                    </th>
                  </tr>
                  {w?.approvedVendor?.materialRequest?.map(
                    (mr: any, index: any) => (
                      <tr key={`${index}-${mr?.['Spare Part Number']}`}>
                        <td>{incrementby1(index)}</td>
                        <td>{mr?.['Spare Part Number']}</td>
                        <td>{mr?.['Spare Part Description']}</td>
                        <td>{mr?.['quantityRequested']}</td>
                        <td>{mr?.['grossPrice']}</td>
                        <td>{getReadableTime(mr?.['promisedDate'])}</td>
                      </tr>
                    )
                  )}
                </React.Fragment>
              ))
            )}
          </>
        </tbody>
      </Table>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Old Quotations Found
          </p>
        </Row>
      )}
    </>
  )
}

export default function CreateConsignmentManifest() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const componentRef: any = useRef()

  const [createManifest, setCreateManifest]: any = useState(false)
  const [store, setSore]: any = useState()
  const [unit, setUnit] = useState()

  const [sparesList, setSparesList]: any = useState([])

  const fetchto = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/inventory/to/spares/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, isRefetching, refetch }: any = useQuery(
    'toDetails',
    fetchto
  )

  const fetchStoreKeeper = async () => {
    const response = await makeAuthenticatedRequest(
      `${usersEndpoint}/aban?designation=STOREKEEPER`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const storekeeperquery: any = useQuery('storekeeper', fetchStoreKeeper)

  const sKeeperList = storekeeperquery?.data?.crew

  const toData = data?.toDetails

  useEffect(() => {
    setSparesList(
      poList?.flatMap(
        (po: any) =>
          po?.approvedVendor?.quotation?.map((mr: any, index: any) => ({
            ...mr,
            lineCode: `${po?.number?.replace('PR', 'PO')}/${incrementby1(
              index
            )}`
          }))
      )
    )
  }, [data])

  const trasnportOrderDetails: any = {
    'TO Number': toData?.number,
    'Vendor Name': toData?.vendorName,
    'Vendor Id': toData?.vendorId,
    'Logistics Coordinator Name': toData?.logisticsCoordinator,
    'Created On': getReadableTime(toData?.createdOn),
    'Freight Forwarder Name': toData?.forwarderName,
    'Freight Forwareder Id': toData?.forwarderId,
    'Warehouse Id': toData?.wareHouseId,
    'Warehouse Location': toData?.wareHouseLocation
  }
  const cmDetails: any = {
    'TO Number': toData?.number,
    'Logistics Coordinator Name': toData?.logisticsCoordinator,
    'Created On': getReadableTime(toData?.createdOn),
    'Freight Forwarder Name': toData?.forwarderName,
    'Freight Forwareder Id': toData?.forwarderId,
    'Warehouse Id': toData?.wareHouseId,
    'Warehouse Location': toData?.wareHouseLocation,
    'Store Keeper': store?.userName,
    'UNIT /VESSEL': unit
  }

  const units: any = ['UNIT 1', 'UNIT 2', 'UNIT 3', 'UNIT 4']

  const poList = toData?.poList

  const EnterVessel = ({
    data,
    isLoading,
    isRefetching,
    showNote
  }: {
    data: any
    isLoading?: boolean
    isRefetching?: boolean
    showNote: any
  }) => {
    return (
      <>
        {showNote && (
          <p>
            Note: Please Mark Yes for the items you want to Raise Discripency
          </p>
        )}
        <Table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Line.No</th>
              <th>Spares Part Number</th>
              <th>Spare Part Description</th>
              <th>Quantity Requested</th>
              {showNote && <th>Raise Discrepancy</th>}
              {showNote && (
                <>
                  <th>Quantity Received</th>
                  <th>Remarks</th>
                </>
              )}
              {!showNote && (
                <>
                  <th>Quantity Received</th>
                  <th>Remarks</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            <>
              {isLoading || isRefetching ? (
                <tr>
                  <td colSpan={12}>
                    <Row
                      style={{
                        height: 500,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Spinner />
                    </Row>
                  </td>
                </tr>
              ) : (
                data?.map((w: any, index: number) => (
                  <React.Fragment key={w?._id}>
                    <tr key={`${index}-${w?.['Spare Part Number']}`}>
                      <td>{incrementby1(index)}</td>
                      <td>{w?.lineCode}</td>
                      <td>{w?.spare?.['Spare Part Number']}</td>
                      <td>{w?.spare?.['Spare Part Description']}</td>
                      <td>{w?.['quantityRequested']}</td>
                      {showNote && (
                        <td style={{ display: 'flex' }}>
                          <p style={{ marginRight: 5 }}>
                            <Input
                              id={w?.lineCode}
                              type="radio"
                              name={`raiseDiscripency_${w?.lineCode}`}
                              value={'Yes'}
                              defaultValue="No"
                              style={{ marginRight: 5 }}
                              checked={sparesList.some(
                                (sp: any) =>
                                  sp?.['Spare Part Number'] ===
                                    w?.['Spare Part Number'] &&
                                  sp?.raiseDiscripency === 'Yes'
                              )}
                              onChange={(e: any) =>
                                setSparesList((prevSparesList: any) =>
                                  prevSparesList.map((sp: any) =>
                                    sp?.['Spare Part Number'] ===
                                    w?.['Spare Part Number']
                                      ? {
                                          ...sp,
                                          raiseDiscripency: e?.target?.value
                                        }
                                      : sp
                                  )
                                )
                              }
                            />
                            Yes
                          </p>
                          <p style={{ marginRight: 5 }}>
                            <Input
                              id={w?.lineCode}
                              defaultValue="No"
                              type="radio"
                              name={`raiseDiscripency_${w?.lineCode}`}
                              value={'No'}
                              style={{ marginRight: 5 }}
                              checked={sparesList.some(
                                (sp: any) =>
                                  sp?.['Spare Part Number'] ===
                                    w?.['Spare Part Number'] &&
                                  sp?.raiseDiscripency === 'No'
                              )}
                              onChange={(e: any) =>
                                setSparesList((prevSparesList: any) =>
                                  prevSparesList.map((sp: any) =>
                                    sp?.['Spare Part Number'] ===
                                    w?.['Spare Part Number']
                                      ? {
                                          ...sp,
                                          raiseDiscripency: e?.target?.value
                                        }
                                      : sp
                                  )
                                )
                              }
                            />
                            No
                          </p>
                        </td>
                      )}
                      {showNote && (
                        <>
                          <td>
                            <Input
                              type="select"
                              value={sparesList[index]?.quantityReceived}
                              onChange={(e: any) =>
                                setSparesList((prevSparesList: any) =>
                                  prevSparesList.map((sp: any) =>
                                    sp?.['Spare Part Number'] ===
                                    w?.['Spare Part Number']
                                      ? {
                                          ...sp,
                                          quantityReceived: e?.target?.value
                                        }
                                      : sp
                                  )
                                )
                              }
                            >
                              <option value="">Select</option>
                              {w?.quantityRequested &&
                                generateValuesUntilZero(
                                  parseInt(w?.quantityRequested)
                                )?.map((n: any) => (
                                  <option key={n}>{n}</option>
                                ))}
                            </Input>
                          </td>
                          <td>
                            <Input
                              type="select"
                              value={sparesList[index]?.remarksd}
                              onChange={(e: any) =>
                                setSparesList((prevSparesList: any) =>
                                  prevSparesList.map((sp: any) =>
                                    sp?.['Spare Part Number'] ===
                                    w?.['Spare Part Number']
                                      ? {
                                          ...sp,
                                          remarksd: e?.target?.value
                                        }
                                      : sp
                                  )
                                )
                              }
                            >
                              <option value="">Select</option>
                              <option>Part Number not Matched</option>
                              <option>Quality Issue</option>
                              <option>Packing Issue</option>
                              <option>Pending</option>
                            </Input>
                          </td>
                        </>
                      )}
                      {!showNote && (
                        <>
                          <td>{w?.quantityReceived}</td>
                          <td>{w?.remarksd}</td>
                        </>
                      )}
                    </tr>
                  </React.Fragment>
                ))
              )}
            </>
          </tbody>
        </Table>
        {data?.length === 0 && (
          <Row
            style={{
              width: '100%',
              margin: 0
            }}
          >
            <p
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              No Data Found
            </p>
          </Row>
        )}
      </>
    )
  }

  const isDiabled =
    sparesList?.filter((sp: any) => !sp?.raiseDiscripency)?.length > 0

  console.log(sparesList)

  return (
    <ProtectedLayout
      onBack="/purchase/createdTOs"
      title={'Create Consignment Manifest Order'}
    >
      <div ref={componentRef} style={{ margin: 15 }}>
        <Row style={{ margin: 10 }}>
          <Box heading="Transport Order Details">
            {Object.keys(trasnportOrderDetails)?.map((key: any) =>
              renderData(key, trasnportOrderDetails[key])
            )}
          </Box>
          <Box heading="Purchase Orders">
            <>
              <EnterVessel
                data={sparesList}
                isLoading={isLoading}
                isRefetching={isRefetching}
                showNote
              />
            </>
          </Box>
          <Box heading="Acknowledgment">
            <Row>
              <Col xs="6">
                <Text>For and behalf of </Text>
                <Text>{toData?.forwarderName}</Text>
                <Text>Agreed and Accepted by</Text>
                <br />
                <Text>Name:___________________________ </Text>
                <Text>Title:____________________________</Text>
                <Text>Date:___________________________ </Text>
              </Col>
              <Col xs="6">
                <Text>For and behalf of </Text>
                <Text>{toData?.logisticsCoordinator}</Text>
                <Text>Agreed and Accepted by</Text>
                <br />
                <Text>Name:___________________________ </Text>
                <Text>Title:____________________________ </Text>
                <Text>Date:___________________________ </Text>
              </Col>
            </Row>
          </Box>
        </Row>
      </div>
      <ReactToPrint
        documentTitle={`Transport Number - ${toData?.number}`}
        pageStyle={'padding: 20'}
        trigger={() => (
          <ModalFooter>
            <Button color="warning">Print</Button>
          </ModalFooter>
        )}
        content={() => componentRef.current}
      />
      {toData?.status === 'sparesReceived' && (
        <div style={{ textAlign: 'right' }}>
          {isDiabled && (
            <span style={{ fontSize: 14, color: 'red', marginRight: 10 }}>
              * Please Select either Yes or No from above items list
            </span>
          )}
          <ModalFooter>
            <Button
              onClick={() => setCreateManifest(true)}
              color={isDiabled ? 'secondary' : 'warning'}
              disabled={isDiabled}
            >
              Create Consignment Manifest Order
            </Button>
          </ModalFooter>
        </div>
      )}
      <SubmitModalWithForm
        isOpen={createManifest}
        onClose={(val: any) => {
          setCreateManifest(false)
          // val && navigate('/purchase/dashboard')
        }}
        form={
          <Row>
            <Col xs="12">
              <Box heading="Consignment Manifest Details">
                {Object.keys(cmDetails)?.map((key: any) =>
                  renderData(key, cmDetails[key])
                )}
              </Box>
              <Box heading="Consignment Manifest Items">
                <EnterVessel
                  data={sparesList?.filter(
                    (sp: any) => sp?.raiseDiscripency === 'No'
                  )}
                  showNote={false}
                />
              </Box>
              <Box heading="Discrepancy Items">
                <EnterVessel
                  data={sparesList?.filter(
                    (sp: any) => sp?.raiseDiscripency === 'Yes'
                  )}
                  showNote={false}
                />
              </Box>
            </Col>
            <Col xs="6">
              <CustomLabel label="Select Store Keeper" />
              <Input
                type="select"
                defaultValue={''}
                onChange={(e: any) =>
                  setSore(
                    sKeeperList?.filter(
                      (f: any) => f?._id === e?.target?.value
                    )?.[0]
                  )
                }
              >
                <option value="" disabled>
                  Select
                </option>
                {sKeeperList?.map((f: any) => (
                  <option
                    key={f?._id}
                    value={f?._id}
                    onClick={() => setSore(f)}
                  >
                    {f?.userName}-{f?.shoreType}
                  </option>
                ))}
              </Input>
            </Col>
            <Col xs="6">
              <CustomLabel label="Select Unit Details" />
              <Input
                type="select"
                defaultValue={''}
                onChange={(e: any) =>
                  setUnit(
                    units?.filter((f: any) => f === e?.target?.value)?.[0]
                  )
                }
              >
                <option value="" disabled>
                  Select
                </option>
                {units?.map((f: any) => (
                  <option key={f} value={f} onClick={() => setUnit(f)}>
                    {f}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
        }
        onSubmit={async (e: any) => {
          e.preventDefault()
          const body: any = {
            latestupdatedBy: userData?.id,
            storeKeeper: store?.userName,
            storeKeeperId: store?.userId,
            manifestCreatedOn: new Date(),
            id: toData?._id,
            cmList: sparesList?.filter(
              (sp: any) => sp?.raiseDiscripency === 'No'
            ),
            dsList: sparesList?.filter(
              (sp: any) => sp?.raiseDiscripency === 'Yes'
            ),

            vessel: unit
          }
          try {
            const res = await makeAuthenticatedRequest(
              `${stock}/inventory/createManifest`,
              'POST',
              body,
              accounts,
              instance
            )

            if (res.status === 'success') {
              await refetch()
            }
            return res.status
          } catch (error) {}
        }}
        size="xl"
        header="Create Consignment Manifest"
      />
    </ProtectedLayout>
  )
}
