type rolesType = {
  HSE: String
  PIC: String
  IA: String
  PS: String
  DIM: String
  IM: String
  AO: String
  JP: String
}

export const roles: rolesType | any = {
  HSE: 'HSELead',
  PIC: 'Prod In Charge',
  IA: 'Isolation Authority',
  PS: 'Prod Supt',
  DIM: 'Deputy IM',
  IM: 'IM',
  AO: 'Area In Charge',
  JP: 'Job Performer'
}

export function getShorthandRole(value: string) {
  return Object.keys(roles).find((key: any) => roles[key] === value)
}

export const RCM_USER = 'RCM User'
