import { Row } from 'reactstrap'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { IconCard } from '../../components/Dashboard/IconCard'
import { SerialCodes } from '../../components/constants/manualConstants'
import { useLocation } from 'react-router-dom'

const FolderTemplate = ({
  flows,
  onBack,
  title
}: {
  flows: any
  title: any
  onBack: any
}) => {
  return (
    <ProtectedLayout onBack={onBack} title={title}>
      <Row style={{ margin: 10, fontSize: 20 }}>
        {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
          <IconCard
            key={`${index}-${name}`}
            name={name}
            nav={nav}
            canView={canView}
            count={count}
            src={src}
          />
        ))}
      </Row>
    </ProtectedLayout>
  )
}

export function OriginatorFolders() {
  const flows: any = SerialCodes.originator?.map((o) => ({
    name: o,
    nav: `/cmms/manual/view/${o}`
  }))

  return <FolderTemplate flows={flows} onBack={-1} title="Originator Folders" />
}

export function OriginFolders() {
  const { pathname } = useLocation()

  const flows: any = SerialCodes.origin?.map((o) => ({
    name: o,
    nav: `${pathname}/${o}`
  }))

  return <FolderTemplate flows={flows} onBack={-1} title="Origin Folders" />
}

export function LogicCodesFolder() {
  const { pathname } = useLocation()

  const flows: any = SerialCodes.logicCodes?.map((o) => ({
    name: o?.value,
    nav: `${pathname}/${o?.value}`
  }))

  return (
    <FolderTemplate flows={flows} onBack={-1} title="Logic Codes Folders" />
  )
}

export function DepartmentCodesFolder() {
  const { pathname } = useLocation()

  const flows: any = SerialCodes.departmentCodes?.map((o) => ({
    name: o?.value,
    nav: `${pathname}/${o?.value}`
  }))

  return <FolderTemplate flows={flows} onBack={-1} title="Department Folders" />
}

export function DocumentTypeCodesFolder() {
  const { pathname } = useLocation()

  const flows: any = SerialCodes.documentTypeCodes?.map((o) => ({
    name: o?.value,
    nav: `${pathname}/${o?.value}/docs`
  }))

  return (
    <FolderTemplate flows={flows} onBack={-1} title="Document Type Folders" />
  )
}
