import { Button, Col, Input, ModalFooter, Row, Table } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'

import RcmLayout from '../../components/rcm/RcmLayout'
import { equipmentList, maintItemsList, tagsList } from './rcmConstants'
import Spacer from '../../components/Spacer'

export default function FpsoSystemBoundary() {
  const navigate = useNavigate()
  const [equipment, setEquipment]: any = useState([])
  const [items, setItems]: any = useState([])
  const [tags, setTags]: any = useState([])

  const sty = `.table-responsive {
  max-height: 70vh;
  /* max-width: 90vw; */
}`
  return (
    <RcmLayout title={'FPSO Boundary'} onBack="">
      <style>
        {sty}
        {`
          table {
            width: 100%;
            border-spacing: 0; 
            border-collapse: separate;
          }

          .top-left {
            border: 2px solid lightgray;
            border-top-left-radius: 10px;
          }

          .top-right {
            border: 2px solid lightgray;
            border-top-right-radius: 10px;
          }
          `}
      </style>
      <Row>
        <Spacer height={20} />
        <Col xs={12} md={6}>
          <h4 style={{ fontWeight: 700 }}>Equipment Level Analysis</h4>
          <div style={{ backgroundColor: '#fff', borderRadius: 10 }}>
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: 'lightgray' }}>
                  <th className="top-left">Select</th>
                  <th>Equipment</th>
                  <th>Equipment Number</th>
                  <th>Manufacturer</th>
                  <th className="top-right">Criticality</th>
                </tr>
              </thead>
              <tbody>
                {equipmentList?.map((d: any) => (
                  <tr key={d?.equipmentNumber}>
                    <td className="text-center" style={{ width: '3vw' }}>
                      <Input
                        type="checkbox"
                        onChange={(e) =>
                          e?.target?.checked
                            ? setEquipment([...equipment, d])
                            : setEquipment(
                                equipment?.filter(
                                  (s: any) =>
                                    s?.equipmentNumber !== d?.equipmentNumber
                                )
                              )
                        }
                        checked={equipment?.some(
                          (s: any) => s?.equipmentNumber === d?.equipmentNumber
                        )}
                      />
                    </td>
                    <td>{d?.equipmentNumber}</td>
                    <td>{d?.equipmentDescription}</td>
                    <td>{d?.manufacturer}</td>
                    <td>{d?.criticality}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <Spacer height={10} />
          <h4 style={{ fontWeight: 700 }}>Equipment / Tag Level Analysis</h4>
          <div style={{ backgroundColor: '#fff', borderRadius: 10 }}>
            <Table striped>
              <thead>
                <tr style={{ borderColor: 'lightgray' }}>
                  <th className="top-left">Select</th>
                  <th>Item Number</th>
                  <th>Item Description</th>
                  <th>Manufacturer</th>
                  <th className="top-right">Criticality</th>
                </tr>
              </thead>
              <tbody>
                {maintItemsList?.map((d: any, index: any) => (
                  <tr key={d?.equipmentNumber}>
                    <td className="text-center" style={{ width: '3vw' }}>
                      <Input
                        type="checkbox"
                        onChange={(e) =>
                          e?.target?.checked
                            ? setItems([...items, d])
                            : setItems(
                                items?.filter(
                                  (s: any) =>
                                    s?.equipmentNumber !== d?.equipmentNumber
                                )
                              )
                        }
                        checked={items?.some(
                          (s: any) => s?.equipmentNumber === d?.equipmentNumber
                        )}
                      />
                    </td>
                    <td>{d?.equipmentNumber}</td>
                    <td>{d?.equipmentDescription}</td>
                    <td>{d?.manufacturer}</td>
                    <td>{d?.criticality}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
        <Col xs={12} md={6} style={{ paddingLeft: 3, height: '80vh' }}>
          <h4
            style={{ fontWeight: 700 }}
            children="Equipment / Tag Level Analysis"
          />
          <div
            style={{
              backgroundColor: '#fff',
              borderRadius: 10,
              height: '80vh',
              overflow: 'scroll'
            }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: 'lightgray' }}>
                  <th className="top-left">Select</th>
                  <th>Tag Number</th>
                  <th>Tag Description</th>
                  <th>Manufacturer</th>
                  <th className="top-right">Criticality</th>
                </tr>
              </thead>
              <tbody>
                {tagsList?.map((d: any, index) => (
                  <React.Fragment key={d?.equipmentNumber || index}>
                    {d?.isHeader && (
                      <tr>
                        <td></td>
                        <td>
                          <strong>{d?.itemNumber}</strong>
                        </td>
                        <td colSpan={3}>
                          <strong>{d?.itemDescription}</strong>
                        </td>
                      </tr>
                    )}
                    {!d?.isHeader && (
                      <tr>
                        <td className="text-center" style={{ width: '3vw' }}>
                          <Input
                            type="checkbox"
                            onChange={(e) =>
                              e?.target?.checked
                                ? setTags([...tags, d])
                                : setTags(
                                    tags?.filter(
                                      (s: any) =>
                                        s?.equipmentNumber !==
                                        d?.equipmentNumber
                                    )
                                  )
                            }
                            checked={tags?.some(
                              (s: any) =>
                                s?.equipmentNumber === d?.equipmentNumber
                            )}
                          />
                        </td>
                        <td>{d?.equipmentNumber}</td>
                        <td>{d?.equipmentDescription}</td>
                        <td>{d?.manufacturer}</td>
                        <td>{d?.criticality}</td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <ModalFooter style={{ margin: 15 }}>
        <Button
          color="warning"
          onClick={() => {
            localStorage.setItem('equipmentList', JSON.stringify(equipment))
            localStorage.setItem('itemsList', JSON.stringify(items))
            localStorage.setItem('tagsList', JSON.stringify(tags))
            const rcmId = localStorage.getItem('rcmId')
            navigate(`/cmms/rcm/stage2/${rcmId}`)
          }}
        >
          Add
        </Button>
      </ModalFooter>
    </RcmLayout>
  )
}
