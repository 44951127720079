import { getAllPMPermissions } from '../../apiRequests'

export const getPMPermissions = async (accounts: any, instance: any) => {
  const data = await getAllPMPermissions(accounts, instance).then(
    (res: any) => res.data
  )

  const inProgressPermnissions = data?.permissions?.permit?.inProgress
  const waitingFor3rdPartyPermnissions =
    data?.permissions?.permit?.waitingFor3rdParty
  const waitingForShutdownPermnissions =
    data?.permissions?.permit?.waitingForShutdown
  const waitingForMaterialPermnissions =
    data?.permissions?.permit?.waitingForMaterial
  const closePermnissions = data?.permissions?.permit?.close

  const getTruthy = (value: String) => value === 'Yes'
  const getAccess = (values: any) =>
    values?.map((d: any) => getTruthy(d))?.includes(true)

  const canViewInprogress = getAccess([
    inProgressPermnissions?.mechanical,
    inProgressPermnissions?.electrical,
    inProgressPermnissions?.instrument,
    inProgressPermnissions?.production,
    inProgressPermnissions?.hse,
    inProgressPermnissions?.wareHouse
  ])

  const canMakeInProgress = (department: any) => {
    return getTruthy(inProgressPermnissions[department])
  }

  const canMakeWaitingForMaterial = (department: any) =>
    getTruthy(waitingForMaterialPermnissions[department])

  const canMakeWaitingFor3rdParty = (department: any) =>
    getTruthy(waitingFor3rdPartyPermnissions[department])

  const canMakeWaitingForShutdown = (department: any) =>
    getTruthy(waitingForShutdownPermnissions[department])

  const canMakeClose = (department: any) =>
    getTruthy(closePermnissions[department])

  // const canMakeClose = true

  return {
    canMakeInProgress,
    canMakeWaitingForMaterial,
    canMakeWaitingFor3rdParty,
    canMakeWaitingForShutdown,
    canViewInprogress,
    canMakeClose
  }
}
