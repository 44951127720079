import { getAllEpermitPermissions } from '../../apiRequests'

export const getEpermitPermissions = async (accounts: any, instance: any) => {
  const data = await getAllEpermitPermissions(accounts, instance).then(
    (res: any) => res.data
  )

  if (!data) return

  const { permissions } = data || {}
  const {
    createFlow,
    changeInOwnership,
    closeFlow,
    closeFlowWithOutIsolation,
    deIsolationAndClose,
    deIsolationForTest,
    longTermIsolation,
    reIsolation,
    revalidate,
    withOutIsolation
  } = permissions || {}
  const { permit } = createFlow
  const { isolation } = createFlow
  const { approve } = createFlow

  const createPermissions = permit?.create
  const issuePermissions = permit?.issue
  const updateRiskPermissions = permit?.updateRiskAssesment
  const verifyPermissions = permit?.verify

  const initiateRevalidationP = revalidate

  const { approve: revalidateApprove } = revalidate
  const { approve: deIsolationAndCloseApprove } = deIsolationAndClose
  const { approve: ltiApprove } = longTermIsolation
  const { approve: cioApprove } = changeInOwnership
  const { approve: woiApprove } = withOutIsolation
  const { approve: cwoiApprove } = closeFlowWithOutIsolation

  const startIsolationPermissions = isolation?.create

  function checkLocationPermission(obj: any) {
    for (const key in obj) {
      if (obj[key] === 'Yes') {
        return true
      }
      if (typeof obj[key] === 'object' && checkLocationPermission(obj[key])) {
        return true
      }
    }
    return false
  }

  function getLocations(obj: any) {
    const locations = []
    for (const location in obj) {
      for (const department in obj[location]) {
        if (obj[location][department] === 'Yes') {
          locations.push(location)
          break
        }
      }
    }
    return locations
  }

  const canApprove = checkLocationPermission(approve)
  const locations = getLocations(approve)

  const getTruthy = (value: String) => value === 'Yes'
  const getAccess = (values: any) =>
    values?.map((d: any) => getTruthy(d))?.includes(true)

  const departments = [
    'mechanical',
    'electrical',
    'instrument',
    'production',
    'hse',
    'adminwarehouse'
  ]

  const canCreate = getAccess(
    departments?.map((d: any) => createPermissions?.[d])
  )

  const canUpdateRisk = getAccess(
    departments?.map((d: any) => updateRiskPermissions?.[d])
  )

  const canVerify = getAccess(
    departments?.map((d: any) => verifyPermissions?.[d])
  )

  const canIssue = getAccess(
    departments?.map((d: any) => issuePermissions?.[d])
  )

  const canViewIsolations = getAccess(
    departments?.map((d: any) => startIsolationPermissions?.[d])
  )

  const canInitiateRevalidation = getAccess(
    departments?.map((d: any) => initiateRevalidationP?.permit?.initiate?.[d])
  )

  const canVerifyRevalidation = getAccess(
    departments?.map((d: any) => initiateRevalidationP?.permit?.verify?.[d])
  )

  const canIssueRevalidation = getAccess(
    departments?.map((d: any) => initiateRevalidationP?.permit?.issue?.[d])
  )

  const canInitiateDeisolationClose = getAccess(
    departments?.map((d: any) => deIsolationAndClose?.permit?.initiate?.[d])
  )

  const canVerifyDeIsolationAndClose = getAccess(
    departments?.map((d: any) => deIsolationAndClose?.permit?.verify?.[d])
  )

  const canApproveReIsolation = checkLocationPermission(revalidateApprove)
  const canApproveDeIsolationAndClose =
    checkLocationPermission(deIsolationAndClose)

  const canStartDeIsolationAndClose = getAccess(
    departments?.map((d: any) => deIsolationAndClose?.deIsolation?.start?.[d])
  )

  const canFinishDeIsolationAndClose = getAccess(
    departments?.map((d: any) => deIsolationAndClose?.deIsolation?.finish?.[d])
  )

  const canCloseDeIsolationAndClose = getAccess(
    departments?.map((d: any) => deIsolationAndClose?.deIsolation?.live?.[d])
  )

  const canApproveDeIsolatoionAndClose = checkLocationPermission(
    deIsolationAndCloseApprove
  )

  const canApproveLTI = checkLocationPermission(ltiApprove)
  const canApproveCIO = checkLocationPermission(cioApprove)
  const canApproveWOI = checkLocationPermission(woiApprove)
  const canApproveCWOI = checkLocationPermission(cwoiApprove)
  const canApproveDIC = checkLocationPermission(deIsolationAndClose?.approve)

  const canIssueDeIsolationAndClose = getAccess(
    departments?.map((d: any) => deIsolationAndClose?.permit?.issue?.[d])
  )

  const canInitiateLTI = getAccess(
    departments?.map((d: any) => longTermIsolation?.permit?.initiate?.[d])
  )

  const canIssueLTI = getAccess(
    departments?.map((d: any) => longTermIsolation?.permit?.issue?.[d])
  )

  const canVerifyLTI = getAccess(
    departments?.map((d: any) => longTermIsolation?.permit?.verify?.[d])
  )

  // const canMakeLiveLTI = getAccess(
  //   departments?.map((d: any) => longTermIsolation?.deIsolation?.live?.[d])
  // )

  const hasAccess = (data: any) =>
    getAccess(departments?.map((d: any) => data?.[d]))

  // Testing required in terms of isolation , basically check if there is any side effect on making isolation live

  const canMakeLiveLTI = hasAccess(longTermIsolation?.permit?.initiate)

  const canInitiateCIO = hasAccess(changeInOwnership?.permit?.initiate)
  const canVerifyCIO = hasAccess(changeInOwnership?.permit?.verify)
  const canIssueCIO = hasAccess(changeInOwnership?.permit?.issue)
  const canMakeLiveCIO = hasAccess(changeInOwnership?.permit?.initiate)

  const canCreateWOI = hasAccess(withOutIsolation?.permit?.create)
  const canIssueWOI = hasAccess(withOutIsolation?.permit?.issue)
  const canVerifyWOI = hasAccess(withOutIsolation?.permit?.verify)

  const canInitateCWOI = hasAccess(closeFlowWithOutIsolation?.permit?.initiate)
  const canVerifyCWOI = hasAccess(closeFlowWithOutIsolation?.permit?.verify)
  const canIssueCWOI = hasAccess(closeFlowWithOutIsolation?.permit?.issue)

  return {
    canCreate,
    canVerify,
    canUpdateRisk,
    canViewIsolations,
    canIssue,
    canApprove,
    location: locations[0],
    canInitiateRevalidation,
    canVerifyRevalidation,
    canIssueRevalidation,
    canApproveReIsolation,
    canInitiateDeisolationClose,
    canVerifyDeIsolationAndClose,
    canStartDeIsolationAndClose,
    canFinishDeIsolationAndClose,
    canCloseDeIsolationAndClose,
    canApproveDeIsolatoionAndClose,
    canApproveDeIsolationAndClose,
    canIssueDeIsolationAndClose,
    canInitiateLTI,
    canVerifyLTI,
    canApproveLTI,
    canIssueLTI,
    canMakeLiveLTI,
    canInitiateCIO,
    canVerifyCIO,
    canIssueCIO,
    canApproveCIO,
    canMakeLiveCIO,
    canCreateWOI,
    canIssueWOI,
    canApproveDIC,
    canApproveWOI,
    canInitateCWOI,
    canVerifyCWOI,
    canApproveCWOI,
    canIssueCWOI,
    canVerifyWOI
  }
}
