import { Row } from 'reactstrap'
import { useContext, useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import ViewPermits from '../../components/Tables/ViewPermits'
import { epermit } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { UserContext } from '../../App'

export default function AllPermitsList() {
  const [pageNumber, setPageNumber] = useState(1)
  const [equipment, setEquipment]: any = useState()
  const [location, setLocation]: any = useState()
  const [permitNumber, setPermitNumber]: any = useState()
  const [department, setDepartment]: any = useState()
  const [status, setStatus]: any = useState()
  const [startDate, setStartDate]: any = useState()
  const [endDate, setEndDate]: any = useState()

  const { userData } = useContext(UserContext)
  const { accounts, instance } = useMsal()

  const fetchAdminPermissions = async () => {
    const response = await makeAuthenticatedRequest(
      `${epermit}/filters?page=${pageNumber}&status=${status}&equipment=${equipment}&location=${location}&permitNumber=${permitNumber}&department=${department?.toUpperCase()}&startDate=${startDate}&endDate=${endDate}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, refetch, isRefetching }: any = useQuery(
    'ClosedPermits',
    fetchAdminPermissions
  )

  useEffect(() => {
    refetch()
  }, [
    equipment,
    location,
    permitNumber,
    department,
    status,
    startDate,
    endDate
  ])

  return (
    <>
      <ProtectedLayout onBack="/eptw/dashboard">
        <Row style={{ margin: 10 }}>
          <ViewPermits
            pageNumber={pageNumber}
            data={data}
            isLoading={isLoading || isRefetching}
            refetch={refetch}
            setPageNumber={setPageNumber}
            navigateTo={`/eptw/allPermits`}
            equipment={equipment}
            setEquipment={setEquipment}
            permitNumber={permitNumber}
            setPermitNumber={setPermitNumber}
            department={department}
            setDepartment={setDepartment}
            status={status}
            setStatus={setStatus}
            // setStartDate={setStartDate}
            // setEndDate={setEndDate}
          />
        </Row>
      </ProtectedLayout>
    </>
  )
}
