import { Row } from 'reactstrap'
import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import ProtectedLayout from '../components/Layout/ProtectedLayout'
import SectionHeader from '../components/Layout/SectionHeader'
import ViewPermits from '../components/Tables/ViewPermits'
import { epermit } from '../components/serverurl'
import { makeAuthenticatedRequest } from '../apiRequests'

export default function SIMOPS() {
  const [pageNumber, setPageNumber] = useState(0)
  const [equipment, setEquipment]: any = useState()
  const [location, setLocation]: any = useState()
  const [department, setDepartment]: any = useState()
  const [permitNumber, setPermitNumber]: any = useState()
  const { accounts, instance } = useMsal()

  function convertToUppercase(string: any) {
    return string?.toUpperCase()
  }

  const fetchAdminPermissions = async () => {
    const response = await makeAuthenticatedRequest(
      `${epermit}/simops?page=${pageNumber}&status=closed&equipment=${equipment}&location=${location}&permitNumber=${permitNumber}&department=${convertToUppercase(
        department
      )}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, refetch }: any = useQuery(
    'ClosedPermits',
    fetchAdminPermissions
  )

  useEffect(() => {
    refetch()
  }, [equipment, location, department, permitNumber])
  return (
    <ProtectedLayout onBack="/eptw/dashboard">
      <Row style={{ margin: 10 }}>
        <SectionHeader title="Simultaneous Operations" />
        <ViewPermits
          data={data}
          isLoading={isLoading}
          refetch={refetch}
          setPageNumber={setPageNumber}
          navigateTo={`/eptw/closedPermits`}
          equipment={equipment}
          setEquipment={setEquipment}
          location={location}
          setLocation={setLocation}
          department={department}
          setDepartment={setDepartment}
          permitNumber={permitNumber}
          setPermitNumber={setPermitNumber}
        />
      </Row>
    </ProtectedLayout>
  )
}
