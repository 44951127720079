import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { makeAuthenticatedRequest } from '../apiRequests'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import Loader from '../components/Loader'
import { riskAssessmentAPI } from '../components/serverurl'
import ApprovedRisksTable from '../components/Tables/ApprovedRisksTable'

export default function RiskAssessmentTemplates() {
  const { accounts, instance } = useMsal()

  const fetchRiskAssesmentTemplates = async () => {
    const response = await makeAuthenticatedRequest(
      riskAssessmentAPI,
      'GET',
      null,
      accounts,
      instance
    )

    return response.data
  }

  const { data, isLoading }: any = useQuery(
    'riskAssessments',
    fetchRiskAssesmentTemplates
  )

  return (
    <ProtectedLayout onBack="/eptw/dashboard">
      {isLoading ? <Loader /> : <ApprovedRisksTable data={data} />}
    </ProtectedLayout>
  )
}
