import { Field } from "react-final-form";
import { Col } from "reactstrap";
import CustomLabel from "./CustomLabel";

export const textArea = {
  border: "1px solid #CCC",
  borderRadius: 5,
  width: "100%",
  height: 40,
  paddingLeft: 5,
  backgroundColor: "#DCDCDC",
  fontWeight: 500,
  color: "#000",
};

type InputType = {
  label?: string;
  name?: any;
  isDisabled?: boolean;
  description?: boolean;
  occupy?: number;
  height?: number;
  md: number;
  sm: number;
};

export default function LabeledTextInput({
  label,
  name,
  isDisabled = false,
  description = false,
  occupy = 6,
  value,
  height = 40,
  md,
  sm,
}: InputType | any) {
  return (
    <Col md={md || occupy} sm={sm} key={`${name}*${label}`}>
      {label && <CustomLabel label={label} />}
      <Field name={name} style={textArea}>
        {({ input, meta }) => (
          <div key={`${name}^${label}`}>
            <input
              {...input}
              type="text"
              style={{ ...textArea, height: height || 40 }}
              disabled={isDisabled}
              value={value || input.value}
            />
            {meta.error && meta.touched && (
              <span style={{ color: "red", fontWeight: 400, fontSize: 14 }}>
                {meta.error}
              </span>
            )}
          </div>
        )}
      </Field>
    </Col>
  );
}
