import FolderTree from 'react-folder-tree'
import { BsFileEarmarkTextFill } from 'react-icons/bs'
import { IoIosFolder } from 'react-icons/io'
import { Button, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import { useState } from 'react'
import { FaFolderOpen } from 'react-icons/fa'

import 'react-folder-tree/dist/style.css'

import ModalTitle from '../../components/ModalTitle'
import RcmLayout from '../../components/rcm/RcmLayout'
import RcmMatrix from '../../components/rcm/RcmMatrix'
import RCMTable from '../../components/rcm/RcmTable'
import Spacer from '../../components/Spacer'
import { rcmTreeData } from '../rcm/rcmTreeData'
import { Link, ViewDocument } from './PmTree'

const renderData = (key: any, value: any, fontSize = 14) => (
  <div
    key={key}
    style={{
      marginBottom: 2,
      width: '100%',
      fontSize: fontSize
    }}
  >
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <p
        style={{
          width: '30%',
          margin: 0,
          fontSize: 18,
          fontFamily: 'Helvetica',
          fontWeight: 500
        }}
      >
        {key}
      </p>
      <p
        style={{
          margin: 0,
          width: '70%',
          fontSize: 18,
          fontFamily: 'Helvetica',
          textOverflow: 'ellipsis'
        }}
      >
        {value}
      </p>
    </div>
  </div>
)

const unitData = {
  basicDetails: {
    'Unit Name': 'FPSO Crudesafe',
    'Storage capacity': '2,400,000 bbl',
    'Offloading capacity': '1,000,000 bbl/day',
    'Mooring System': 'Spread-Moored (optional turret)',
    'Topside weight': '40,000 tons',
    'Length *Breadth* Depth': '330m * 62 m* 33 m',
    'Accommodation, up to': '200 persons',
    'Design service life': 'Minimum 25 years without dry-docking',
    Class: 'ABS',
    'Daily Production': '24000 bbl',
    'Expected Life time': '5 years'
  },
  technicalDetails: {
    'RCM Performed system': <Link onClick={() => {}}> Click Here</Link>,
    'Failure Modes': <Link onClick={() => {}}> Click Here</Link>,
    'System Criticality ': <Link onClick={() => {}}> Click Here</Link>,
    'Maintenance Strategy': <Link onClick={() => {}}> Click Here</Link>,
    'Maintenance Philosophy': <Link onClick={() => {}}> Click Here</Link>,
    'Performance Standards': <Link onClick={() => {}}> Click Here</Link>,
    'Spare Parts Strategy': <Link onClick={() => {}}> Click Here</Link>,
    Engineering: <Link onClick={() => {}}> Click Here</Link>,
    'Supply chain': <Link onClick={() => {}}> Click Here</Link>,
    'Asset Management': <Link onClick={() => {}}> Click Here</Link>,
    Documentation: <Link onClick={() => {}}> Click Here</Link>,
    'Technical Safety': <Link onClick={() => {}}> Click Here</Link>,
    'Base Office Contacts': <Link onClick={() => {}}> Click Here</Link>
  }
}

const compressorData = {
  basicDetails: {
    'Vessel System': 'Module 23 - Gas Compressor',
    'Sub Systems': '1st Stage,2nd Stage, Lube oil,Control,Instruments',
    Function: 'Compress gas to 35 bar',
    Tag: 'K-T7110',
    'Tag Description': 'Gas Compressor',
    'Criticality ': 'HIGH',
    Location: 'Top side',
    'Cost Center': 'A-023',
    'Vendor code': 'CS-GE-1032',
    'Installed year with date': '2014, 12 November'
  },
  technicalDetails: {
    'Type Model': 'LM-2500 PE Basic',
    'Serial Number': 'W0987495432-R20',
    Manufacturer: 'General Electric',
    Supplier: 'General Electric',
    'Operation Status': 'In Operation',
    'Technical description': <Link onClick={() => {}}> Click Here</Link>,
    Technical: <Link onClick={() => {}}> Click Here</Link>,
    'Operation Manual': <Link onClick={() => {}}> Click Here</Link>,
    'Maintenance Manual': <Link onClick={() => {}}> Click Here</Link>,
    'Spare parts and special tools': (
      <Link onClick={() => {}}> Click Here</Link>
    ),
    'Document & Drawings': <Link onClick={() => {}}> Click Here</Link>,
    "Existing PM's": <Link onClick={() => {}}> Click Here</Link>,
    'Performace Standards': <Link onClick={() => {}}> Click Here</Link>,
    'Ex Performance': <Link onClick={() => {}}> Click Here</Link>,
    'Regulatory reference': <Link onClick={() => {}}> Click Here</Link>
  }
}

const firstStage = {
  basicDetails: {
    'Vessel System': '1st Stage centrifugal Compressor',
    'Sub Systems': '1st Stage, Lube oil,Control,Instruments& Electricals',
    Function: 'Compress gas to 35 bar',
    Tag: 'K-T7111',
    'Tag Description': 'Centrifugal Compressor 1st Stage',
    'Criticality ': 'HIGH',
    Location: 'Top side',
    'Cost Center': 'A-023',
    'Vendor code': 'CS-GE-1032',
    'Installed year with date': '2019, 01 November'
  },
  technicalDetails: {
    'Type Model': '6GH-360-S',
    'Serial Number': 'W09983-R01',
    Manufacturer: 'Euroflex',
    Supplier: 'Euroflex',
    'Operation Status': 'In Operation',
    'Technical description': <Link onClick={() => {}}> Click Here</Link>,
    Technical: <Link onClick={() => {}}> Click Here</Link>,
    'Operation Manual': <Link onClick={() => {}}> Click Here</Link>,
    'Maintenance Manual': <Link onClick={() => {}}> Click Here</Link>,
    'Spare parts and special tools': (
      <Link onClick={() => {}}> Click Here</Link>
    ),
    'Document & Drawings': <Link onClick={() => {}}> Click Here</Link>,
    "Existing PM's": <Link onClick={() => {}}> Click Here</Link>,
    'Performace Standards': <Link onClick={() => {}}> Click Here</Link>,
    'Ex Performance': <Link onClick={() => {}}> Click Here</Link>,
    'Regulatory reference': <Link onClick={() => {}}> Click Here</Link>
  }
}

const gtData = {
  basicDetails: {
    'Vessel System': 'DR-61G / LM2500-PE Basic SAC Dual Fuel',
    'Sub Systems': '1st Stage, Lube oil,Control,Instruments& Electricals',
    Function: 'Compress gas to 35 bar',
    Tag: '23-GT-2331A',
    'Tag Description': 'Centrifugal Compressor 1st Stage',
    'Criticality ': 'HIGH',
    Location: 'Top side',
    'Cost Center': 'A-023',
    'Vendor code': 'CS-GE-1032',
    'Installed year with date': '2019, 01 November'
  },
  technicalDetails: {
    'Type Model': 'LM2500-PE',
    'Serial Number': 'W09983-R01',
    Manufacturer: 'Euroflex',
    Supplier: 'Euroflex',
    'Operation Status': 'In Operation',
    'Technical description': <Link onClick={() => {}}> Click Here</Link>,
    Technical: <Link onClick={() => {}}> Click Here</Link>,
    'Operation Manual': <Link onClick={() => {}}> Click Here</Link>,
    'Maintenance Manual': <Link onClick={() => {}}> Click Here</Link>,
    'Spare parts and special tools': (
      <Link onClick={() => {}}> Click Here</Link>
    ),
    'Document & Drawings': <Link onClick={() => {}}> Click Here</Link>,
    "Existing PM's": <Link onClick={() => {}}> Click Here</Link>,
    'Performace Standards': <Link onClick={() => {}}> Click Here</Link>,
    'Ex Performance': <Link onClick={() => {}}> Click Here</Link>,
    'Regulatory reference': <b>DRE-73600-ME-DS-1004</b>
  }
}

const beData = {
  basicDetails: {
    'Vessel System': 'Combustion ignition unit',
    'Sub Systems': 'Combustion Unit,Control,Instruments& Electricals',
    Function: 'Ignite as required as per Spec by GE',
    Tag: '23-BE-2331A',
    'Tag Description': 'Combustion ignition unit',
    'Criticality ': 'HIGH',
    Location: 'Top side',
    'Cost Center': 'A-023',
    'Vendor code': 'CS-GE-1032',
    'Installed year with date': '2019, 01 November'
  },
  technicalDetails: {
    'Type Model': 'CB-673-01',
    'Serial Number': 'W0432-R01',
    Manufacturer: 'General Electric',
    Supplier: 'General Electric',
    'Operation Status': 'In Operation',
    'Technical description': <Link onClick={() => {}}> Click Here</Link>,
    Technical: <Link onClick={() => {}}> Click Here</Link>,
    'Operation Manual': <Link onClick={() => {}}> Click Here</Link>,
    'Maintenance Manual': <Link onClick={() => {}}> Click Here</Link>,
    'Spare parts and special tools': (
      <Link onClick={() => {}}> Click Here</Link>
    ),
    'Document & Drawings': <Link onClick={() => {}}> Click Here</Link>,
    "Existing PM's": <Link onClick={() => {}}> Click Here</Link>,
    'Performace Standards': <Link onClick={() => {}}> Click Here</Link>,
    'Ex Performance': <Link onClick={() => {}}> Click Here</Link>,
    'Regulatory reference': <b>DRE-74500-ME-DS-1032</b>
  }
}

const getData: any = {
  'UNIT-1': unitData,
  'SYSTEM 23-GAS COMPRESSION': compressorData,
  COMPRESSORS: compressorData,
  'K-T7111 - 1st Stage Gas Compressor': firstStage,
  '23-GT-2331A- Gas turbine driver LM2500 SAC Gas Fuel': gtData,
  '23-BE-2331A-Combustion ignition unit': beData
}

export default function RCM() {
  const [select, setSelect]: any = useState()
  const [document, setDocument]: any = useState()
  const [matrix, setMatrix]: any = useState(false)
  const [rcmTable, setRcmTable]: any = useState(false)
  const [data, setData]: any = useState({})

  const onNameClick = ({ defaultOnClick, nodeData }: any) => {
    defaultOnClick()
    setSelect(nodeData?.name)
    setData(getData[nodeData?.name])
  }

  console.log(select)

  const FolderIcon = ({ onClick: defaultOnClick }: any) => {
    return <IoIosFolder onClick={defaultOnClick} color="black" size={24} />
  }

  const FolderOpenIcon = ({ onClick: defaultOnClick }: any) => {
    return <FaFolderOpen onClick={defaultOnClick} color="black" size={24} />
  }

  const FileIcon = ({ onClick: defaultOnClick }: any) => {
    return (
      <BsFileEarmarkTextFill onClick={defaultOnClick} color="black" size={24} />
    )
  }

  const customIdentPixels = 50
  const iconComponents = {
    FolderIcon,
    FolderOpenIcon,
    FileIcon
  }

  const onMatrixSelection = (e: any) => console.log(e)
  const onRcmSelection = (e: any) => console.log(e)

  return (
    <RcmLayout onBack="/cmms" title="Reliability Centered Maintenance">
      <style>
        {`.displayName {
          font-size: 18px;
          }`}
      </style>
      <Spacer height={15} />
      <Row>
        <h4 style={{ fontWeight: 700, marginLeft: 10 }}>HIERARCHY</h4>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              minWidth: '50%',
              margin: 10,
              marginRight: 0,
              backgroundColor: '#fff',
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10
            }}
          >
            <div
              style={{
                backgroundColor: '#fff',
                height: '80vh',
                overflow: 'scroll',
                borderRadius: 10,
                padding: 10
              }}
            >
              <FolderTree
                onNameClick={onNameClick}
                data={rcmTreeData}
                iconComponents={iconComponents}
                indentPixels={customIdentPixels}
                showCheckbox={false}
                initOpenStatus="closed"
                readOnly
              />
            </div>
          </div>
          <div
            style={{
              width: '50%',
              backgroundColor: '#fff',
              margin: 10,
              marginLeft: 0,
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
              paddingBottom: 10
            }}
          >
            {Object.keys(getData)?.includes(select) && (
              <>
                <Spacer height={20} />
                <div
                  style={{
                    backgroundColor: 'gray',
                    borderRadius: 10,
                    padding: 10,
                    marginRight: 10
                  }}
                >
                  <h4 style={{ fontWeight: 700 }}>BASIC DETAILS</h4>
                  <div
                    style={{
                      backgroundColor: '#fff',
                      height: '30vh',
                      padding: 10,
                      borderRadius: 10
                    }}
                  >
                    {Object.keys(data?.basicDetails)?.map((key: any) =>
                      renderData(<b>{key}</b>, data?.basicDetails[key])
                    )}
                  </div>
                </div>
                <Spacer height={20} />
                <div
                  style={{
                    backgroundColor: 'gray',
                    borderRadius: 10,
                    padding: 10,
                    marginRight: 10
                  }}
                >
                  <h4 style={{ fontWeight: 700 }}>TECHNICAL DETAILS</h4>
                  <div
                    style={{
                      backgroundColor: '#fff',
                      height: '46vh',
                      padding: 10,
                      borderRadius: 10
                    }}
                  >
                    <>
                      {Object.keys(data?.technicalDetails)?.map((key: any) =>
                        renderData(<b>{key}</b>, data?.technicalDetails[key])
                      )}
                    </>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Row>
      <ViewDocument
        isOpen={!!document}
        toggle={() => setDocument(!document)}
        src={document}
        title={'Document'}
      />

      {matrix && (
        <Modal isOpen={matrix} toggle={() => setMatrix(!matrix)} size="xl">
          <ModalTitle title="Matrix" height={50} />
          <ModalBody>
            <RcmMatrix onClick={onMatrixSelection} />
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={() => setMatrix(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {rcmTable && (
        <Modal
          isOpen={rcmTable}
          toggle={() => setRcmTable(!rcmTable)}
          size="xl"
        >
          <ModalTitle title="Matrix" height={50} />
          <ModalBody>
            <RCMTable onClick={onRcmSelection} />
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={() => setRcmTable(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </RcmLayout>
  )
}
