import { Col, Row } from 'reactstrap'
import AshokLeylandLayout from './AshokLeylandLayout'

export default function AlTrainingKpi() {
  return (
    <AshokLeylandLayout title="KPI" onBack="/ashokleyland/training">
      <Row style={{ margin: 15 }}>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            src={require('./../images/Icons/trainingkpi.jpeg')}
            alt="training kpi"
            style={{ height: '75vh', width: 'auto' }}
          />
        </Col>
      </Row>
    </AshokLeylandLayout>
  )
}
