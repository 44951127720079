import AshokLeylandLayout from './AshokLeylandLayout'
import React, { useState } from 'react'
import { Col, Input, Row, Table } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import { alEquipmentApi } from '../components/serverurl'
import { getReadableTime } from '../pages/workManagement/WorkOrderDetails'
import { incrementby1 } from '../components/Forms/JSAForm'
import { makeAuthenticatedRequest } from '../apiRequests'
import CustomLabel from '../components/InputFields/CustomLabel'
import SubmitModalWithForm from '../components/Modals/SubmitModalWithForm'
import toNormalCase from '../components/lib/toNormalCase'

export default function AlPepTalk() {
  const { accounts, instance } = useMsal()
  const [modal, setModal] = useState(false)
  const [update, setUpdate]: any = useState()
  const [name, setName]: any = useState()

  const fetchUsers = async () =>
    await makeAuthenticatedRequest(
      `${alEquipmentApi}/users`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data, refetch }: any = useQuery('AL Users List', fetchUsers)
  const trainingData = data?.users

  const months = [
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
    'January',
    'February',
    'March'
  ]

  const handleNameChange = (
    id: any,
    month: any,
    type: any,
    activity: any,
    date: any
  ) => {
    setName({
      id,
      month,
      type,
      activity,
      date
    })
    setModal(true)
    console.log(name)
  }

  const handleUpdate = (date: any) => {
    setUpdate(date)
  }

  const renderDate = (date: any) => {
    return date && getReadableTime(date)
  }
  return (
    <AshokLeylandLayout
      title="PEP TALK AWARENESS TRAINING - 2024"
      onBack="/ashokleyland/training"
    >
      <Row style={{ margin: 15 }}>
        <Col>
          <Table
            responsive
            bordered
            style={{ borderColor: '#000', fontSize: 12 }}
          >
            <thead style={{ backgroundColor: 'gold' }}>
              <tr>
                {[
                  'S.No',
                  'Employee Number',
                  'Employee Name',
                  'Designation',
                  'Department'
                ]?.map((h: any) => (
                  <th rowSpan={3} key={h}>
                    {h}
                  </th>
                ))}
                {months?.map((m: any) => (
                  <th colSpan={8} style={{ textAlign: 'center' }} key={m}>
                    {m}
                  </th>
                ))}
              </tr>
              <tr>
                {months?.map((m: any) => (
                  <React.Fragment key={m}>
                    <th colSpan={4} style={{ textAlign: 'center' }}>
                      Pep Talk
                    </th>
                    <th colSpan={4} style={{ textAlign: 'center' }}>
                      Awareness Training
                    </th>
                  </React.Fragment>
                ))}
              </tr>
              <tr>
                {months?.map((m: any) => (
                  <React.Fragment key={m}>
                    <th style={{ whiteSpace: 'nowrap' }}>Planned On</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Completed On</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Head Count</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Total Minutes</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Planned On</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Completed On</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Head Count</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Total Minutes</th>
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {trainingData?.map((t: any, index: any) => (
                <tr key={t?._id}>
                  <td>{incrementby1(index)}</td>
                  <td>{t?.employeeNumber}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>{t?.employeeName}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>{t?.designation}</td>
                  <td>{t?.department}</td>
                  {months?.map((m: any) => (
                    <React.Fragment key={m}>
                      <td
                        onClick={() =>
                          handleNameChange(
                            t?._id,
                            m,
                            'pepTalk',
                            'plannedOn',
                            t?.pep?.[m]?.pepTalk?.plannedOn
                          )
                        }
                      >
                        {renderDate(t?.pep?.[m]?.pepTalk?.plannedOn)}
                      </td>
                      <td
                        onClick={() =>
                          handleNameChange(
                            t?._id,
                            m,
                            'pepTalk',
                            'completedOn',
                            t?.pep?.[m]?.pepTalk?.plannedOn
                          )
                        }
                      >
                        {renderDate(t?.pep?.[m]?.pepTalk?.completedOn)}
                      </td>
                      <td
                        onClick={() =>
                          handleNameChange(
                            t?._id,
                            m,
                            'pepTalk',
                            'headCount',
                            t?.pep?.[m]?.pepTalk?.headCount
                          )
                        }
                      >
                        {t?.pep?.[m]?.pepTalk?.headCount}
                      </td>
                      <td
                        onClick={() =>
                          handleNameChange(
                            t?._id,
                            m,
                            'pepTalk',
                            'totalMinutes',
                            t?.pep?.[m]?.pepTalk?.totalMinutes
                          )
                        }
                      >
                        {t?.pep?.[m]?.pepTalk?.totalMinutes}
                      </td>
                    </React.Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <SubmitModalWithForm
        isOpen={modal}
        onClose={(val: any) => {
          setModal(false)
        }}
        form={
          <div>
            <p style={{ margin: 0 }}>Month: {name?.month}</p>
            <p style={{ margin: 0 }}>Type: {name?.type}</p>
            <p style={{ margin: 0 }}>
              Activity: {toNormalCase(name?.activity)}
            </p>
            <CustomLabel label="Update Date" />
            <Input
              name="updateDate"
              type="date"
              onChange={(e) => handleUpdate(e?.target?.value)}
            />
            <Input
              name="time"
              type="text"
              onChange={(e) => handleUpdate(e?.target?.value)}
            />
          </div>
        }
        onSubmit={async (e: any) => {
          console.log(update)
          const body: any = {
            key: `pep.${name?.month}.${name?.type}.${name?.activity}`,
            date: update
          }
          e?.preventDefault()
          const res = await makeAuthenticatedRequest(
            `${alEquipmentApi}/users/${name?.id}`,
            'PATCH',
            body,
            accounts,
            instance
          )

          if (res.status === 'success') {
            await refetch()
          }

          return res.status
        }}
        size="xs"
        header="Update Pep Date"
      />
    </AshokLeylandLayout>
  )
}
