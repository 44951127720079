import arrayMutators from 'final-form-arrays'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { Field, Form, useForm } from 'react-final-form'
import { ToastContainer, toast } from 'react-toastify'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useState } from 'react'

import 'react-toastify/dist/ReactToastify.css'

import AshokLeylandLayout from './AshokLeylandLayout'
import CustomLabel from '../components/InputFields/CustomLabel'
import LabeledCheckBoxes from '../components/InputFields/LabeledCheckBoxes'
import LabeledDateTimeInput from '../components/InputFields/DateTime'
import LabeledRadioButtons from '../components/InputFields/LabeledRadioButton'
import LabeledTextInput from '../components/InputFields/LabeledTextInput'
import LabledParagraphInput from '../components/InputFields/LabledParagraph'
import ModalTitle from '../components/ModalTitle'
import SubmitModalWithForm from '../components/Modals/SubmitModalWithForm'
import { alEquipmentApi } from '../components/serverurl'
import { Box, Link, renderData } from '../pages/workManagement/PmTree'
import { FieldArray } from 'react-final-form-arrays'
import { FormSection } from '../pages/workManagement/WorkRequestForm'
import { getOptions } from '../pages/firs'
import { incrementby1 } from '../components/Forms/JSAForm'
import { jobcodes } from '../components/constants/jobcodes'
import { makeAuthenticatedRequest } from '../apiRequests'
import { MdDelete } from 'react-icons/md'
import { renderField, units } from '../components/Modals/CloseCMPermit'
import { ViewPMDetails } from './AlViewPM'
import { yesNoOptions } from '../pages/eptw/VerifyPermit'
import { ViewHistory } from '../pages/ViewCM'
import { SubHeader } from '../pages/VerifyCM'
import { capitalizeFirstLetter } from '../components/lib/capitalizeFirstLetter'
import toNormalCase from '../components/lib/toNormalCase'
import { getReadableTime } from '../pages/workManagement/WorkOrderDetails'
import { WorkRequestPreview } from './AlWorkRequestForm'

export const alDepartments = [
  'Maintenance',
  'Canteen & Security',
  'Civil',
  'Proto Shop',
  'EDC',
  'CTL',
  'VET',
  'VD/NVH',
  'IVT',
  'PPKM'
]

const spareList = [
  {
    partNumber: '17-0029-00111',
    description: 'ELEMENT,OIL FILTER,INR-S-00320-API-PF25-V,PN:87482544',
    spareType: 'CONSUMABLE',
    uom: 'EA',
    onHandQuantity: '27'
  },
  {
    partNumber: '18-2122-00541',
    description: 'WEAR PARTS,KE RF25 ES,PN:102940.1028',
    spareType: 'CONSUMABLE',
    uom: 'EA',
    onHandQuantity: '1'
  },
  {
    partNumber: '17-0029-00112',
    description: 'HOUSING O-RING,OIL FILTER,VITON OR EQUAL,PN:81045224',
    spareType: 'CONSUMABLE',
    uom: 'EA',
    onHandQuantity: '3'
  },
  {
    partNumber: '16-2026-00206',
    description: 'AIR END KIT,PN:476-253-559',
    spareType: 'CONSUMABLE',
    uom: 'EA',
    onHandQuantity: '60'
  },
  {
    partNumber: '16-2113-00101',
    description: 'KIT,AIR END,PN:476-239-000',
    spareType: 'CONSUMABLE',
    uom: 'EA',
    onHandQuantity: '25'
  },
  {
    partNumber: '16-2118-00109',
    description: 'KIT,AIR END,F/ S1F,PN:476.228.000',
    spareType: 'CONSUMABLE',
    uom: 'EA',
    onHandQuantity: '56'
  }
]

const initialValues = {
  checkList: {
    oilChecks: {
      ok: 'No'
    },
    radiator: {
      ok: 'No'
    },
    coolingWater: {
      ok: 'Yes'
    },
    exhauseSystem: {
      ok: 'No'
    },
    electricalControlPanel: {
      ok: 'No'
    },
    electricalConnections: {
      ok: 'No'
    },
    gfciUnit: {
      ok: 'Yes'
    },
    earthBonding: {
      ok: 'No',
      remarks: 'Needs to be replaced'
    },
    machineLifting: {
      ok: 'No'
    },
    fireExtinguisher: {
      ok: 'No',
      remarks: 'Stock not available'
    },
    fuelLines: {
      ok: 'No'
    },
    fuelTank: {
      ok: 'Yes'
    },
    soundDampers: {
      ok: 'No'
    },
    sideScreens: {
      ok: 'No'
    },
    batteryCondition: {
      ok: 'No'
    },
    secondaryContainment: {
      ok: 'No'
    },
    emergencyStop: {
      ok: 'Yes'
    }
  },
  workClassification: {
    workType: 'Unexpected',
    maintenanceType: 'Corrective Maintenance',
    maintenanceClass: 'Item Intrusive',
    maintenanceCause: 'Aging'
  },
  stockUsed: [
    {
      name: 'Stock Item 1',
      price: '20',
      quantity: '5',
      unit: 'UOM'
    },
    {
      name: 'Stock Item 2',
      price: '6',
      quantity: '6',
      unit: 'UOM'
    }
  ],
  specialTools: [
    {
      tool: 'Tool1 ',
      toolDescription: 'Tool description 4',
      itemCode: 'item code q',
      location: 'Dump Yard',
      department: 'Mechanical',
      dateRequired: '2024-08-30'
    },
    null
  ],
  generalInformation: {
    dateDone: '2024-08-30',
    totalHours: '23',
    downTime: '12',
    cost: '314',
    currencyType: 'USD',
    budgetCode: '343',
    jobState: 'COMPLETE'
  },
  currentJob: {
    component: '12.43.54',
    componentDescription: 'Pump',
    function: '43',
    jobNumber: 'PR-0009'
  },
  reporting: {
    options: ['History', 'Stock Used', 'Resource Used'],
    history: 'Some History about the Work Order',
    lessonLearnt: 'Lesson Learnt'
  },
  jobPlanHours: {
    equipmetnAvailability: {
      actualHours: '12',
      actualManHours: '6'
    },
    verificationScheme: {
      actualHours: '5',
      actualManHours: '3'
    },
    riskAssessment: {
      actualHours: '13',
      actualManHours: '5'
    },
    toolboxTalk: {
      actualHours: '2',
      actualManHours: '5'
    },
    practiceCodes: {
      actualHours: '11',
      actualManHours: '4'
    }
  }
}

const checkList = [
  { label: 'Oil leaks (engine)', name: 'oilChecks' },
  { label: 'Radiator (water leaks, water sufficient)', name: 'radiator' },
  { label: 'Cooling Water Hoses', name: 'coolingWater' },
  { label: 'Exhaust System/spark arrestor', name: 'exhauseSystem' },
  { label: 'Electrical Control Panel', name: 'electricalControlPanel' },
  {
    label: 'Electrical distribution board connections',
    name: 'electricalConnections'
  },
  { label: 'GFCI/ELCB unit', name: 'gfciUnit' },
  { label: 'Earth Bonding Connections', name: 'earthBonding' },
  { label: 'Machine Lifting Eye', name: 'machineLifting' },
  { label: 'Fire Extinguisher', name: 'fireExtinguisher' },
  { label: 'Fuel lines (leaks)', name: 'fuelLines' },
  { label: 'Fuel Tank and Filler Cap', name: 'fuelTank' },
  { label: 'Sound dampers', name: 'soundDampers' },
  { label: 'Side screens in Position', name: 'sideScreens' },
  { label: 'Battery condition', name: 'batteryCondition' },
  { label: 'Secondary containment(spill tray)', name: 'secondaryContainment' },
  { label: 'Emergency stop', name: 'emergencyStop' }
]

export const LabeledDropdown = ({
  name,
  options,
  label,
  occupy = 4
}: {
  name: string
  options: string[]
  label?: string
  occupy: string | number
}) => {
  return (
    <Col xs={`${occupy}`}>
      {label && <CustomLabel label={label} />}
      <Field
        name={name}
        component="select"
        placeholder="s"
        style={{
          width: '-webkit-fill-available',
          height: 38,
          borderRadius: 5,
          backgroundColor: '#DCDCDC'
        }}
      >
        <option value="" disabled selected>
          Select
        </option>
        {options?.map((o: any) => <option>{o}</option>)}
      </Field>
    </Col>
  )
}

const TableData = ({ children }: { children: any }) => (
  <td style={{ padding: '0px 10px 0px 10px' }}>{children}</td>
)

export const renderRisks = ({ fields, meta: { error } }: any) => (
  <>
    {fields.map((stock: any, index: any) => (
      <>
        <Row key={index} style={{ marginBottom: 5 }}>
          <Col xs="3">
            <Field
              name={`${stock}.name`}
              type="text"
              component={renderField}
              label={`Stock Item Code #${index + 1}`}
            />
          </Col>
          <Col xs="3">
            <Field
              name={`${stock}.price`}
              type="text"
              component={renderField}
              label={`Stock Name #${index + 1}`}
            />
          </Col>
          <Col xs="3">
            <Field
              name={`${stock}.quantity`}
              type="text"
              component={renderField}
              label={`Stock Quantity #${index + 1}`}
            />
          </Col>
          <Col xs="2">
            <Field
              name={`${stock}.unit`}
              component="select"
              style={{
                width: '-webkit-fill-available',
                height: 38,
                borderRadius: 5
              }}
            >
              <option value="" disabled selected>
                Select Unit
              </option>
              {units?.map((u: any) => (
                <option key={u} value={u}>
                  {u}
                </option>
              ))}
            </Field>
          </Col>
          <Col xs="1">
            <MdDelete
              onClick={() => fields.remove(index)}
              style={{ color: 'red' }}
            />
          </Col>
        </Row>
      </>
    ))}
    <tr>
      <td colSpan={5}>
        <Button color="warning" onClick={() => fields.push()}>
          Add Stock
        </Button>
      </td>
    </tr>
    {/* <>
      <Button color="link" onClick={() => fields.push()}>
        Add Stock
      </Button>
    </> */}
  </>
)

export const renderSpecialToolsUsed = ({ fields, meta: { error } }: any) => (
  <>
    {fields.map((stock: any, index: any) => (
      <>
        <Row key={index} style={{ marginBottom: 5 }}>
          <Col xs="2">
            <Field name={`${stock}.tool`} type="text" component={renderField} />
          </Col>
          <Col xs="2">
            <Field
              name={`${stock}.toolDescription`}
              type="textarea"
              component={renderField}
            />
          </Col>
          <Col xs="1">
            <Field
              name={`${stock}.itemCode`}
              type="text"
              component={renderField}
            />
          </Col>
          <Col xs="2">
            <Field
              name={`${stock}.location`}
              type="text"
              component={renderField}
            />
          </Col>
          <Col xs="2">
            <Field
              name={`${stock}.department`}
              type="text"
              component={renderField}
            />
          </Col>
          <Col xs="2">
            <Field
              name={`${stock}.dateRequired`}
              type="date"
              component={renderField}
            />
          </Col>
          <Col xs="1">
            <MdDelete
              onClick={() => fields.remove(index)}
              style={{ color: 'red' }}
            />
          </Col>
        </Row>
      </>
    ))}
    <tr>
      <td colSpan={5}>
        <Button color="warning" onClick={() => fields.push()}>
          Add Special Tool
        </Button>
      </td>
    </tr>
  </>
)

export const renderResources = ({ fields, meta: { error } }: any) => (
  <>
    {fields.map((resource: any, index: any) => (
      <Row key={index}>
        <Col xs="4">
          <LabeledDropdown
            name={`${resource}.name`}
            options={alDepartments}
            occupy={12}
          />
        </Col>
        <Col xs="4">
          <Field
            name={`${resource}.hours`}
            type="text"
            component={renderField}
            label={`Hours #${index + 1}`}
          />
        </Col>
        <Col>
          <MdDelete
            onClick={() => fields.remove(index)}
            style={{ color: 'red' }}
          />
        </Col>
      </Row>
    ))}
    <tr>
      <td colSpan={3}>
        <Button color="warning" onClick={() => fields.push()}>
          Add Resource
        </Button>
      </td>
    </tr>
  </>
)

const CheckListForm = ({ tag }: { tag: any }) => {
  return (
    <FormSection title="Inspection Checklist" isInitial>
      <Table bordered striped style={{ borderColor: '#000', padding: 0 }}>
        <thead>
          <tr>
            <th>Model</th>
            <th colSpan={4}>
              {tag?.tagNumber} - {tag?.tagDescription}
            </th>
          </tr>
          <tr>
            <th>S.No</th>
            <th>ITEM</th>
            <th>OK</th>
            <th>REMARKS</th>
          </tr>
        </thead>
        <tbody>
          {checkList?.map((i: any, Iindex: any) => (
            <tr key={`${i?.name}-${Iindex}`}>
              <TableData>{incrementby1(Iindex)}</TableData>
              <TableData>{i?.label}</TableData>
              <TableData>
                <LabeledRadioButtons
                  name={`checkList.${i?.name}.ok`}
                  list={yesNoOptions}
                  occupy={12}
                />
              </TableData>
              <TableData>
                <LabeledTextInput
                  name={`checkList.${i?.name}.remarks`}
                  height={20}
                  occupy={12}
                />
              </TableData>
            </tr>
          ))}
        </tbody>
      </Table>
    </FormSection>
  )
}

const GeneralInformationForm = () => {
  const parentObj = 'generalInformation'
  return (
    <FormSection title="General Information">
      <LabeledDateTimeInput
        name={`${parentObj}.dateDone`}
        occupy={3}
        label="Date Done"
      />
      <LabeledTextInput
        name={`${parentObj}.overDueReason`}
        label="Over due Reason"
        occupy={3}
      />
      <LabeledTextInput
        name={`${parentObj}.totalHours`}
        label="Total Hours"
        occupy={3}
      />
      <LabeledTextInput
        name={`${parentObj}.downTime`}
        label="Down time"
        occupy={3}
      />
      <LabeledTextInput
        name={`${parentObj}.cost`}
        label="Currency"
        occupy={2}
      />
      <LabeledDropdown
        label="Curr. Type"
        name={`${parentObj}.currencyType`}
        occupy={1}
        options={['INR', 'USD']}
      />
      <LabeledTextInput
        name={`${parentObj}.budgetCode`}
        label="Budget Code"
        occupy={3}
      />
      <LabeledDropdown
        label="Job State"
        name={`${parentObj}.jobState`}
        occupy={3}
        options={['COMPLETE', 'IN COMPLETE']}
      />
      {/* Use these only for CM */}
      {/* <LabeledDateTimeInput
        name={`${parentObj}.serviceInitiated`}
        label="Service Action Initiated"
        occupy={3}
        time
      />
      <LabeledDateTimeInput
        name={`${parentObj}.serviceClosed`}
        label="Service Action Closed"
        occupy={3}
        time
      /> */}
    </FormSection>
  )
}

const WorkClassificationForm = () => {
  const parentObj = 'workClassification'
  return (
    <FormSection title="Work Classification">
      <LabeledRadioButtons
        name={`${parentObj}.workType`}
        label="Work"
        list={getOptions(['Expected', 'Unexpected'])}
        occupy={3}
      />
      <LabeledDropdown
        name={`${parentObj}.maintenanceType`}
        options={['Breakdown Maintenance', 'Corrective Maintenance']}
        occupy={3}
        label="Maintenance Type"
      />
      <LabeledDropdown
        name={`${parentObj}.maintenanceClass`}
        options={[
          'Non Intrusive',
          'Item Intrusive',
          'Package Intrusive',
          'Partial Prod SD',
          'Total Prod SD'
        ]}
        occupy={3}
        label="Maintenance Class"
      />
      <LabeledDropdown
        name={`${parentObj}.maintenanceCause`}
        options={[
          'Wear',
          'Aging',
          'Vibration',
          'Control Failure',
          'Breakage',
          'Corrosion',
          'Leakage External',
          'Lubrication'
        ]}
        occupy={3}
        label="Maintenance Cause"
      />
    </FormSection>
  )
}

const CurrentJobForm = () => {
  const parentObj = 'currentJob'
  return (
    <FormSection title="Current Job">
      <LabeledTextInput
        name={`${parentObj}.component`}
        label="Component"
        occupy={3}
      />
      <LabeledTextInput
        name={`${parentObj}.componentDescription`}
        label="Component Description"
        occupy={3}
      />
      <LabeledTextInput
        name={`${parentObj}.function`}
        label="Function"
        occupy={3}
      />
      <LabeledTextInput
        name={`${parentObj}.functionDescription`}
        label="Function Description"
        occupy={3}
      />
      <LabeledTextInput
        name={`${parentObj}.jobNumber`}
        label="Job Number"
        occupy={3}
      />
      <LabeledTextInput
        name={`${parentObj}.jobDescription`}
        label="Job Description"
        occupy={3}
      />
    </FormSection>
  )
}

const ReportingOptions = () => {
  const parentObj = 'reporting'
  return (
    <FormSection title="Reporting Options">
      <LabeledCheckBoxes
        occupy={12}
        name={`${parentObj}.options`}
        label="Reporting Options"
        list={getOptions(['History', 'Stock Used', 'Resource Used'])}
      />
      <LabledParagraphInput
        name={`${parentObj}.history`}
        label="History"
        occupy={12}
      />
      <LabledParagraphInput
        name={`${parentObj}.lessonLearnt`}
        label="Lesson Learnt"
        occupy={12}
      />
    </FormSection>
  )
}

const StockUsedForm = () => {
  return (
    <FormSection title="Stock Used">
      <Row>
        <Col xs="3">
          <CustomLabel label="Item Code" />
        </Col>
        <Col xs="3">
          <CustomLabel label="Item Name" />
        </Col>
        <Col xs="3">
          <CustomLabel label="Item Quantity" />
        </Col>
        <Col xs="2">
          <CustomLabel label="Unit" />
        </Col>
        <Col xs="1">
          <CustomLabel label="Delete" />
        </Col>
      </Row>
      <FieldArray name="stockUsed" component={renderRisks} />
    </FormSection>
  )
}

const ResourceUsedForm = () => {
  return (
    <FormSection title="Resources Used">
      <Row>
        <Col xs="4">
          <CustomLabel label="Department" />
        </Col>
        <Col xs="4">
          <CustomLabel label="Hours" />
        </Col>
      </Row>
      <FieldArray name="resourceUsed" component={renderResources} />
    </FormSection>
  )
}

const SpecialToolsUsedForm = () => {
  return (
    <FormSection title="Special Tools Used">
      <Row>
        <Col xs="2">
          <CustomLabel label="Tool" />
        </Col>
        <Col xs="2">
          <CustomLabel label="Tool Description" />
        </Col>
        <Col xs="1">
          <CustomLabel label="Item Code" />
        </Col>
        <Col xs="2">
          <CustomLabel label="Location" />
        </Col>
        <Col xs="2">
          <CustomLabel label="Responsible Department" />
        </Col>
        <Col xs="2">
          <CustomLabel label="Date Required" />
        </Col>
        <Col xs="1">
          <CustomLabel label="Delete" />
        </Col>
      </Row>
      <FieldArray name="specialTools" component={renderSpecialToolsUsed} />
    </FormSection>
  )
}

const JobPansTableForm = () => {
  const data = jobcodes.CAOO5
  const parentObj = 'jobPlanHours'
  return (
    <FormSection title="Job Plan Hours">
      <Table>
        <thead>
          <tr>
            <th>OP NO.</th>
            <th>Description</th>
            <th>Main Org</th>
            <th>Planned Hours</th>
            <th>Planned Resources</th>
            <th>Total Man Hours</th>
            <th>Remarks</th>
            <th>Actual Planned Hours</th>
            <th>Actual Man Hours</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((o: any) => (
            <tr key={o?.number}>
              <td>{o?.number}</td>
              <td>{o?.description}</td>
              <td>{o?.mainOrg}</td>
              <td style={{ textAlign: 'center' }}>{o?.plannedHours}</td>
              <td>{o?.plannedResources}</td>
              <td style={{ textAlign: 'center' }}>{o?.totalManHours}</td>
              <td>{o?.remarks}</td>
              <td>
                <LabeledTextInput
                  name={`${parentObj}.${o?.name}.actualHours`}
                  occupy={12}
                />
              </td>
              <td>
                <LabeledTextInput
                  name={`${parentObj}.${o?.name}.actualManHours`}
                  occupy={12}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </FormSection>
  )
}

const PmTagCloseOutForm = ({
  closeoutModal,
  setCloseoutModal,
  tag,
  refetch
}: any) => {
  const { accounts, instance } = useMsal()
  const [modal, setModal]: any = useState(false)
  const { id } = useParams()

  return (
    <Modal
      isOpen={closeoutModal}
      fullscreen
      style={{ backgroundColor: '#FFF' }}
    >
      <ModalTitle height={40} title="Close Out Form" />
      <Form
        onSubmit={(val: any) => console.log(val)}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators
        }}
        render={({ form, values }: any) => (
          <form>
            <ModalBody style={{ padding: 0, margin: 0 }}>
              <div
                style={{
                  overflow: 'scroll',
                  maxHeight: '85vh',
                  overflowX: 'hidden'
                }}
              >
                <CheckListForm tag={tag} />
                <GeneralInformationForm />
                <WorkClassificationForm />
                <CurrentJobForm />
                <ReportingOptions />
                <StockUsedForm />
                <SpecialToolsUsedForm />
                <JobPansTableForm />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="warning"
                onClick={() => setCloseoutModal(!closeoutModal)}
              >
                Close
              </Button>
              <Button color="warning" onClick={() => setModal(true)}>
                Submit
              </Button>
            </ModalFooter>
            <SubmitModalWithForm
              isOpen={modal}
              onClose={(val: any) => {
                setModal(false)
              }}
              onSubmit={async (e: any) => {
                e.preventDefault()
                const body: any = {
                  closeoutReport: {
                    ...tag,
                    closeOutReport: values
                  }
                }

                const res = await makeAuthenticatedRequest(
                  `${alEquipmentApi}/pm/workorders/${id}`,
                  'PATCH',
                  body,
                  accounts,
                  instance
                )

                if (res?.status === 'success') {
                  if (refetch) {
                    await refetch()
                  }
                  toast('Close report Submitted')
                  setModal(false)
                  setCloseoutModal(false)
                }

                return res?.status
              }}
              size="xs"
              header="Submit Closeout Report"
            />
          </form>
        )}
      />
    </Modal>
  )
}

const ViewCloseoutReports = ({
  reports,
  showPM
}: {
  reports: any
  showPM: any
}) => {
  return (
    <Row>
      <Col>
        <Box heading="Close out Reports" headingCenter>
          <Row>
            {reports?.map(
              (report: any) =>
                report && (
                  <Col md={12} key={report?.tagNumber}>
                    {report?.tagNumber && (
                      <SubHeader
                        header={`${report?.tagNumber}   ${report?.tagDescription}`}
                        permitColor="gold"
                      />
                    )}
                    <Row>
                      <Col md="6">
                        <Box heading="General Information">
                          {Object.keys(
                            report?.closeOutReport?.generalInformation
                          )?.map((g: any) =>
                            renderData(
                              toNormalCase(g),
                              report?.closeOutReport?.generalInformation[g]
                            )
                          )}
                        </Box>
                        <Box heading="Reporting">
                          {renderData(
                            toNormalCase('Lesson Learnt'),
                            report?.closeOutReport?.reporting?.lessonLearnt
                          )}
                          {renderData(
                            toNormalCase('History'),
                            report?.closeOutReport?.reporting?.history
                          )}
                        </Box>
                      </Col>
                      <Col md="6">
                        <Box heading="Work Classification">
                          {Object.keys(
                            report?.closeOutReport?.workClassification
                          )?.map((g: any) =>
                            renderData(
                              toNormalCase(g),
                              report?.closeOutReport?.workClassification[g]
                            )
                          )}
                        </Box>
                        <Box heading="Current Job">
                          {Object.keys(report?.closeOutReport?.currentJob)?.map(
                            (g: any) =>
                              renderData(
                                toNormalCase(g),
                                report?.closeOutReport?.currentJob[g]
                              )
                          )}
                        </Box>
                      </Col>
                      {showPM && (
                        <Col md="12">
                          <Box heading="Inspection List">
                            <Table
                              bordered
                              striped
                              style={{ borderColor: '#000', padding: 0 }}
                            >
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>ITEM</th>
                                  <th>OK</th>
                                  <th>REMARKS</th>
                                </tr>
                              </thead>
                              <tbody>
                                {checkList?.map((i: any, Iindex: any) => (
                                  <tr key={`${i?.name}-${Iindex}`}>
                                    <TableData>
                                      {incrementby1(Iindex)}
                                    </TableData>
                                    <TableData>{i?.label}</TableData>
                                    <TableData>
                                      {
                                        report?.closeOutReport?.checkList?.[
                                          i?.name
                                        ]?.ok
                                      }
                                    </TableData>
                                    <TableData>
                                      {
                                        report?.closeOutReport?.checkList?.[
                                          i?.name
                                        ]?.remarks
                                      }
                                    </TableData>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Box>
                          <Box heading="Job Plan">
                            <Table>
                              <thead>
                                <tr>
                                  <th>OP NO.</th>
                                  <th>Description</th>
                                  <th>Main Org</th>
                                  <th>Planned Hours</th>
                                  <th>Planned Resources</th>
                                  <th>Total Man Hours</th>
                                  <th>Remarks</th>
                                  <th>Actual Hours</th>
                                  <th>Actual Man Hours</th>
                                </tr>
                              </thead>
                              <tbody>
                                {jobcodes.CAOO5?.map((o: any) => (
                                  <tr key={o?.number}>
                                    <td>{o?.number}</td>
                                    <td>{o?.description}</td>
                                    <td>{o?.mainOrg}</td>
                                    <td style={{ textAlign: 'center' }}>
                                      {o?.plannedHours}
                                    </td>
                                    <td>{o?.plannedResources}</td>
                                    <td style={{ textAlign: 'center' }}>
                                      {o?.totalManHours}
                                    </td>
                                    <td>{o?.remarks}</td>
                                    <td>
                                      {
                                        report?.closeOutReport?.jobPlanHours?.[
                                          o?.name
                                        ]?.actualHours
                                      }
                                    </td>
                                    <td>
                                      {
                                        report?.closeOutReport?.jobPlanHours?.[
                                          o?.name
                                        ]?.actualManHours
                                      }
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Box>
                          <Box heading="Stock Details">
                            <Table>
                              <thead>
                                <tr>
                                  {[
                                    'S.No',
                                    'Name',
                                    'Price',
                                    'Quantity',
                                    'Unit'
                                  ]?.map((a: any) => <th key={a}>{a}</th>)}
                                </tr>
                              </thead>
                              <tbody>
                                {report?.closeOutReport?.stockUsed?.map(
                                  (d: any, index: any) => (
                                    <tr key={d?.itemCode}>
                                      <td>{incrementby1(index)}</td>
                                      <td>{d?.name}</td>
                                      <td>{d?.price}</td>
                                      <td>{d?.quantity}</td>
                                      <td>{d?.unit}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </Box>
                          <Box heading="Special Tools Used">
                            <Table>
                              <thead>
                                <tr>
                                  {[
                                    'S.No',
                                    'Tool Name',
                                    'Description',
                                    'Item Code',
                                    'Department',
                                    'Location',
                                    'Date Required'
                                  ]?.map((h: any) => <th key={h}>{h}</th>)}
                                </tr>
                              </thead>
                              <tbody>
                                {report?.closeOutReport?.specialTools?.map(
                                  (d: any, index: any) =>
                                    d?.itemCode && (
                                      <tr key={d?.itemCode}>
                                        <td>{incrementby1(index)}</td>
                                        <td>{d?.tool}</td>
                                        <td>{d?.toolDescription}</td>
                                        <td>{d?.itemCode}</td>
                                        <td>{d?.location}</td>
                                        <td>{d?.department}</td>
                                        <td>
                                          {getReadableTime(d?.dateRequired)}
                                        </td>
                                      </tr>
                                    )
                                )}
                              </tbody>
                            </Table>
                          </Box>
                        </Col>
                      )}
                      {!showPM && (
                        <Col md="12">
                          {/* <Box heading="Inspection List">
                            <Table
                              bordered
                              striped
                              style={{ borderColor: '#000', padding: 0 }}
                            >
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>ITEM</th>
                                  <th>OK</th>
                                  <th>REMARKS</th>
                                </tr>
                              </thead>
                              <tbody>
                                {checkList?.map((i: any, Iindex: any) => (
                                  <tr key={`${i?.name}-${Iindex}`}>
                                    <TableData>
                                      {incrementby1(Iindex)}
                                    </TableData>
                                    <TableData>{i?.label}</TableData>
                                    <TableData>
                                      {
                                        report?.closeOutReport?.checkList?.[
                                          i?.name
                                        ]?.ok
                                      }
                                    </TableData>
                                    <TableData>
                                      {
                                        report?.closeOutReport?.checkList?.[
                                          i?.name
                                        ]?.remarks
                                      }
                                    </TableData>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Box> */}
                          <Box heading="Stock Details">
                            <Table>
                              <thead>
                                <tr>
                                  {[
                                    'S.No',
                                    'Name',
                                    'Price',
                                    'Quantity',
                                    'Unit'
                                  ]?.map((a: any) => <th key={a}>{a}</th>)}
                                </tr>
                              </thead>
                              <tbody>
                                {report?.closeOutReport?.stockUsed?.map(
                                  (d: any, index: any) => (
                                    <tr key={d?.itemCode}>
                                      <td>{incrementby1(index)}</td>
                                      <td>{d?.name}</td>
                                      <td>{d?.price}</td>
                                      <td>{d?.quantity}</td>
                                      <td>{d?.unit}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </Box>
                          <Box heading="Resources Used">
                            <Table>
                              <thead>
                                <tr>
                                  {['S.No', 'Department', 'Hours']?.map(
                                    (h: any) => <th key={h}>{h}</th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {report?.closeOutReport?.resourceUsed?.map(
                                  (d: any, index: any) => (
                                    <tr key={`${d?.name}-${index}`}>
                                      <td>{incrementby1(index)}</td>
                                      <td>{d?.name}</td>
                                      <td>{d?.hours}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </Box>
                        </Col>
                      )}
                    </Row>
                  </Col>
                )
            )}
          </Row>
        </Box>
      </Col>
    </Row>
  )
}

const PMView = ({
  workorder,
  setCloseoutModal,
  setSelectedTag,
  setStatus,
  setModal,
  history
}: {
  workorder: any
  setCloseoutModal: any
  setSelectedTag: any
  setStatus: any
  setModal: any
  history: any
}) => {
  const isCloseoutSubmitted = (tag: any) =>
    workorder?.closoutReports?.some((a: any) => a?.tagNumber === tag)

  const allCloseoutsSubmitted = workorder?.pmJob?.tagDetails
    ?.map((t: any) => isCloseoutSubmitted(t?.tagNumber))
    ?.some((a: any) => a)
  return (
    <>
      <ViewPMDetails pm={workorder?.pmJob} workorder={workorder} />
      {workorder?.status === 'In Progress' && (
        <Row>
          <Col>
            <Box heading="Close Out">
              <Table>
                <thead>
                  <tr>
                    {['Tag Number', 'Tag Description']?.map((h: any) => (
                      <th key={h}>{h}</th>
                    ))}
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {workorder?.pmJob?.tagDetails?.map((doc: any, index: any) => (
                    <tr key={doc?._id}>
                      <td>{doc?.tagNumber}</td>
                      <td>{workorder?.pmJob?.pmJobDescription}</td>
                      <td>
                        {isCloseoutSubmitted(doc?.tagNumber) ? (
                          'Closeout Report Submitted'
                        ) : (
                          <Link
                            onClick={() => {
                              setCloseoutModal(true)
                              setSelectedTag(doc)
                            }}
                          >
                            Update Close out details
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
          </Col>
        </Row>
      )}
      {workorder?.status === 'Closed' && (
        <ViewCloseoutReports
          reports={workorder?.closoutReports}
          showPM={true}
        />
      )}
      {history?.length > 0 && (
        <Row>
          <Col>
            <Box heading="Work Order Approval History">
              <ViewHistory
                data={history}
                showTitle={false}
                permitColor={'gold'}
              />
            </Box>
          </Col>
        </Row>
      )}
      <ModalFooter>
        {workorder?.status === 'Todo' && (
          <Button
            color="warning"
            onClick={() => {
              setModal(true)
              setStatus('In Progress')
            }}
          >
            In Progress
          </Button>
        )}
        {workorder?.status === 'In Progress' && allCloseoutsSubmitted && (
          <Button
            color="warning"
            onClick={() => {
              setModal(true)
              setStatus('Closed')
              // setPreview(true)
            }}
          >
            Close
          </Button>
        )}
      </ModalFooter>
    </>
  )
}

const CreateWorkOrderForm = ({ workorder }: { workorder: any }) => {
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const [modal, setModal]: any = useState(false)
  const { id } = useParams()
  const cm = workorder?.cm
  return (
    <>
      <Form
        onSubmit={(values: any) => console.log(values)}
        mutators={{
          ...arrayMutators
        }}
        render={({ values, form, hasValidationErrors }) => (
          <form>
            <FormSection title="Planning Schedule">
              <LabeledDateTimeInput
                name="orgPlanStart"
                label="Original Plan Start"
                occupy={3}
              />
              <LabeledDateTimeInput
                name="orgPlanFinish"
                label="Original Plan Finish"
                occupy={3}
              />
              <LabeledDateTimeInput
                name="plannedStart"
                label="Planned Start *"
                occupy={3}
              />
              <LabeledDateTimeInput
                name="plannedFinish"
                label="Planned Finish *"
                occupy={3}
              />
              <LabeledDateTimeInput
                name="calculatedPlanStart"
                label="Calculated Plan Start"
                occupy={3}
              />
              <LabeledTextInput
                name="executionTime"
                label="Execution Time *"
                occupy={3}
              />
              <LabeledTextInput
                name="totalManHours"
                label="Total Man Hours *"
                occupy={3}
              />
            </FormSection>
            <FormSection title="FIRS Number">
              <LabeledTextInput name="mocNumber" occupy={12} />
            </FormSection>
            <FormSection title="Source Information">
              <LabledParagraphInput
                name="faultDescription"
                label="Fault Description *"
                occupy={12}
              />
              <Col xs="6">
                <CustomLabel label="Dicovery" />
                <Input
                  type="select"
                  style={{ backgroundColor: '#DCDCDC' }}
                  name="discovery"
                >
                  <option value="">Select</option>
                  <option>Based on Observation</option>
                  <option>Based on Audit findings</option>
                  <option>Seal leakage</option>
                  <option>Broken part</option>
                  <option>Failure to perform</option>
                  <option>High Vibration</option>
                  <option>High Temperature</option>
                </Input>
              </Col>
              <LabeledTextInput name="symptom" label="Symptom" occupy={6} />
              <LabledParagraphInput
                name="inspectedNotes"
                label="Inspected Notes"
                occupy={12}
              />
            </FormSection>
            <ModalFooter>
              <Button color={'danger'} onClick={() => form.submit()}>
                Cancel
              </Button>
              <Button color={'warning'} onClick={() => setModal(true)}>
                Preview
              </Button>
            </ModalFooter>
            <SubmitModalWithForm
              isOpen={modal}
              onClose={(val: any) => {
                setModal(false)
              }}
              onSubmit={async (e: any) => {
                e.preventDefault()
                const body: any = {
                  ...values,
                  ...cm,
                  status: 'Workorder Created'
                }

                const res = await makeAuthenticatedRequest(
                  `${alEquipmentApi}/pm/workorders/${id}`,
                  'PATCH',
                  body,
                  accounts,
                  instance
                )

                if (res?.status === 'success') {
                  navigate('/ashokleyland/mms/maintenance/created')
                }

                return res?.status
              }}
              size="xs"
              header="Update Workorder status"
            />
          </form>
        )}
      />
    </>
  )
}

const CMCloseoutForm = ({ closeoutModal, setCloseoutModal, tag }: any) => {
  const { accounts, instance } = useMsal()
  const [modal, setModal]: any = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()

  return (
    <Modal
      isOpen={closeoutModal}
      fullscreen
      style={{ backgroundColor: '#FFF' }}
    >
      <ModalTitle height={40} title="Close Out Form" />
      <Form
        onSubmit={(val: any) => console.log(val)}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators
        }}
        render={({ form, values }: any) => (
          <form>
            <ModalBody style={{ padding: 0, margin: 0 }}>
              <div
                style={{
                  overflow: 'scroll',
                  maxHeight: '85vh',
                  overflowX: 'hidden'
                }}
              >
                {/* <CheckListForm tag={tag} /> */}
                <GeneralInformationForm />
                <WorkClassificationForm />
                <CurrentJobForm />
                <ReportingOptions />
                <StockUsedForm />
                <ResourceUsedForm />
                {/* <SpecialToolsUsedForm /> */}
                {/* <JobPansTableForm /> */}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="warning"
                onClick={() => setCloseoutModal(!closeoutModal)}
              >
                Close
              </Button>
              <Button color="warning" onClick={() => setModal(true)}>
                Submit
              </Button>
            </ModalFooter>
            <SubmitModalWithForm
              isOpen={modal}
              onClose={(val: any) => {
                setModal(false)
              }}
              onSubmit={async (e: any) => {
                e.preventDefault()
                const body: any = {
                  status: 'Closed',
                  type: 'cm',
                  ...tag,
                  closeoutReport: { closeOutReport: values }
                }

                const res = await makeAuthenticatedRequest(
                  `${alEquipmentApi}/pm/workorders/${id}`,
                  'PATCH',
                  body,
                  accounts,
                  instance
                )

                if (res?.status === 'success') {
                  navigate('/ashokleyland/mms/maintenance/inprogress')
                  toast('Close report Submitted')
                  setModal(false)
                  setCloseoutModal(false)
                }

                return res?.status
              }}
              size="xs"
              header="Submit Closeout Report"
            />
          </form>
        )}
      />
    </Modal>
  )
}

const ViewCMDetails = ({
  workorder,
  onBack,
  history,
  woStatus,
  wo
}: {
  workorder: any
  onBack: any
  history: any
  woStatus: any
  wo: any
}) => {
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const { id } = useParams()
  const planningSchedule: any = {
    'Planned Start': getReadableTime(workorder?.plannedStart),
    'Planned Finish': getReadableTime(workorder?.plannedFinish),
    'Original Plan Start': getReadableTime(workorder?.orgPlanStart),
    'Original Plan Finish': getReadableTime(workorder?.orgPlanFinish),
    'Calculated Plan Start': getReadableTime(workorder?.calculatedPlanStart),
    'Remaining Hours': workorder?.remainingHours,
    'Execution Time': workorder?.executionTime,
    'Total Man Hours': workorder?.totalManHours
  }

  const interval: any = {
    'TQ / MOC Number': workorder?.mocNumber
  }

  const sourceInformation: any = {
    'Fault Description': workorder?.faultDescription,
    Discovery: workorder?.discovery,
    'Inspected Notes': workorder?.inspectedNotes,
    Symptoms: workorder?.symptom
  }

  const generalDetails: any = {
    Location: workorder?.location,
    'WO description': workorder?.description,
    'Tag Number': workorder?.tagId,
    'Tag Description': workorder?.tagDescription,
    'Work Description': workorder?.problemNoticed,
    'Job to be Done': workorder?.jobPending
  }

  const planningInformation: any = {
    'Responsible Department': capitalizeFirstLetter(workorder?.department),
    'Reported By': workorder?.reportedBy,
    'Work Type': toNormalCase(workorder?.workType),
    Priority: workorder?.priority,
    'Estimated Hours': workorder?.estimatedHours
  }

  const [modal, setModal]: any = useState(false)
  const [status, setStatus]: any = useState()
  const [closeoutModal, setCloseoutModal]: any = useState()

  return (
    <>
      <Col>
        <Box heading="General Details">
          {Object.keys(generalDetails)?.map((key: any) =>
            renderData(key, generalDetails[key])
          )}
        </Box>
        {workorder?.faultDescription && (
          <Box heading="Source Information" height={'5vh'}>
            {Object.keys(sourceInformation)?.map((key: any) =>
              renderData(key, sourceInformation[key])
            )}
          </Box>
        )}
        {workorder?.mocNumber && (
          <Box heading="Interval" height={'5vh'}>
            {Object.keys(interval)?.map((key: any) =>
              renderData(key, interval[key])
            )}
          </Box>
        )}
      </Col>
      <Col>
        <Box heading="Planning Information">
          {Object.keys(planningInformation)?.map((key: any) =>
            renderData(key, planningInformation[key])
          )}
        </Box>
        {workorder?.plannedStart && (
          <Box heading="Planning Schedule">
            {Object.keys(planningSchedule)?.map((key: any) =>
              renderData(key, planningSchedule[key])
            )}
          </Box>
        )}
      </Col>
      {/* <Row>
        <Col>
          <JobPlanDesription description={workorder?.description} />
          <Box heading={`Stock Used`}>
            <StockDetailsTable />
          </Box>
          <Box heading={`Special Tools Used`}>
            <SpecialToolsTable />
          </Box>
        </Col>
      </Row> */}
      {woStatus === 'Closed' && (
        <ViewCloseoutReports reports={wo?.closoutReports} showPM={false} />
      )}
      {history?.length > 0 && (
        <Row>
          <Col>
            <Box heading="Work Order Approval History">
              <ViewHistory
                data={history}
                showTitle={false}
                permitColor={'gold'}
              />
            </Box>
          </Col>
        </Row>
      )}
      <ModalFooter>
        {woStatus === 'Workorder Created' && (
          <Button
            color="warning"
            onClick={() => {
              setStatus('Todo')
              setModal(true)
            }}
          >
            Todo
          </Button>
        )}
        {woStatus === 'Todo' && (
          <Button
            color="warning"
            onClick={() => {
              setStatus('In Progress')
              setModal(true)
            }}
          >
            In Progress
          </Button>
        )}
        {woStatus === 'In Progress' && (
          <>
            <Button
              color="warning"
              onClick={() => {
                setStatus('Waiting For Material')
                setModal(true)
              }}
            >
              Waiting For Material
            </Button>
            <Button
              color="warning"
              onClick={() => {
                setStatus('Waiting For 3rd Party')
                setModal(true)
              }}
            >
              Waiting For 3rd Party
            </Button>
            <Button
              color="warning"
              onClick={() => {
                setStatus('Waiting For Shutdown')
                setModal(true)
              }}
            >
              Waiting For Shutdown
            </Button>
            <Button
              color="warning"
              onClick={() => {
                setStatus('Closed')
                setCloseoutModal(true)
              }}
            >
              Close
            </Button>
          </>
        )}
        {[
          'Waiting For Material',
          'Waiting For Shutdown',
          'Waiting For 3rd Party'
        ]?.includes(woStatus) && (
          <Button
            color="warning"
            onClick={() => {
              setStatus('In Progress')
              setModal(true)
            }}
          >
            In Progress
          </Button>
        )}
      </ModalFooter>
      <SubmitModalWithForm
        isOpen={modal}
        onClose={(val: any) => {
          setModal(false)
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()
          const body: any = {
            status,
            type: 'cm'
          }

          const res = await makeAuthenticatedRequest(
            `${alEquipmentApi}/pm/workorders/${id}`,
            'PATCH',
            body,
            accounts,
            instance
          )

          if (res?.status === 'success') {
            navigate(onBack)
          }

          return res?.status
        }}
        size="xs"
        header="Update Workorder status"
      />
      <CMCloseoutForm
        closeoutModal={closeoutModal}
        setCloseoutModal={setCloseoutModal}
        tag={workorder?.tagId}
        // refetch={refetch}
      />
    </>
  )
}

const CMView = ({
  workorder,
  onBack,
  history
}: {
  workorder: any
  onBack: any
  history: any
}) => {
  const cm = workorder?.cm
  return (
    <>
      {workorder?.status !== 'Created' && (
        <ViewCMDetails
          workorder={workorder?.cm}
          onBack={onBack}
          history={history}
          woStatus={workorder?.status}
          wo={workorder}
        />
      )}
      {workorder?.status === 'Created' && (
        <>
          <WorkRequestPreview wr={cm} fullScreen={false} />
          <CreateWorkOrderForm workorder={workorder} />
        </>
      )}
    </>
  )
}

export default function AlViewWo({ onBack, title }: any) {
  const { accounts, instance } = useMsal()
  const { id } = useParams()
  const navigate = useNavigate()

  const fetchAlPMList = async () =>
    await makeAuthenticatedRequest(
      `${alEquipmentApi}/pm/workorders/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data, refetch }: any = useQuery('AL PM WO', fetchAlPMList)
  const workorder = data?.data?.workorder
  const history = data?.data?.history
  const [modal, setModal]: any = useState(false)
  const [status, setStatus]: any = useState(data?.data?.workorder?.status)
  const [closeoutModal, setCloseoutModal]: any = useState(false)
  const [selectedTag, setSelectedTag]: any = useState()

  return (
    <AshokLeylandLayout onBack={onBack} title={`${workorder?.number}-${title}`}>
      <Row style={{ margin: 15 }}>
        {workorder?.workType ? (
          <CMView workorder={workorder} onBack={onBack} history={history} />
        ) : (
          <PMView
            setCloseoutModal={setCloseoutModal}
            setModal={setModal}
            setSelectedTag={setSelectedTag}
            setStatus={setStatus}
            workorder={workorder}
            history={history}
          />
        )}
      </Row>
      <SubmitModalWithForm
        isOpen={modal}
        onClose={(val: any) => {
          setModal(false)
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()
          const body: any = {
            status,
            type: 'pm'
          }

          const res = await makeAuthenticatedRequest(
            `${alEquipmentApi}/pm/workorders/${id}`,
            'PATCH',
            body,
            accounts,
            instance
          )

          if (res?.status === 'success') {
            navigate(onBack)
          }

          return res?.status
        }}
        size="xs"
        header="Update Workorder status"
      />
      <PmTagCloseOutForm
        closeoutModal={closeoutModal}
        setCloseoutModal={setCloseoutModal}
        tag={selectedTag}
        refetch={refetch}
      />
      <ToastContainer
        theme="dark"
        position="top-center"
        style={{ fontSize: 16 }}
      />
    </AshokLeylandLayout>
  )
}
