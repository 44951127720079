import React, { useEffect, useState } from 'react'
import { Button, Input, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import CustomLabel from '../../components/InputFields/CustomLabel'
import JSATemplateView from '../../components/Forms/JSATemplateView'
import ModalTitle from '../../components/ModalTitle'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import QRATemplateView from '../../components/Forms/QRATemplateView'
import RiskAssessmentForm from '../../components/Forms/RiskAssesmentForm'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import ViewPermit from './ViewPermit'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { epermit, riskAssessmentAPI } from '../../components/serverurl'
import {
  doneRequiredOptions,
  RadioButtonGroup,
  yesNoOptions
} from './VerifyPermit'

export function useAdminPermissions(
  id: any,
  accounts: any,
  instance: any,
  useQuery: any
) {
  const fetchAdminPermissions = async () => {
    const response = await makeAuthenticatedRequest(
      `${epermit}/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response.data
  }

  const { data, isLoading, refetch, isRefetching } = useQuery(
    'permitDetails',
    fetchAdminPermissions
  )

  return { data, isLoading, refetch, isRefetching }
}

export const RejectModal = ({
  isOpen,
  toggle,
  data,
  setSubmitModal,
  redirectUrl,
  consentId
}: any) => {
  const [comments, setComments]: any = useState('')
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const [success, setSuccess]: any = useState(false)

  const rejectPermit = async () => {
    const response = await makeAuthenticatedRequest(
      `${epermit}/${data?.permit?._id}?consentId=${consentId}`,
      'PATCH',
      comments,
      accounts,
      instance
    )

    return response.status
  }

  return (
    <Modal isOpen={isOpen} size="xs">
      <ModalBody>
        {!success && (
          <div>
            <CustomLabel label="Comments" />
            <Input
              type="textarea"
              name="comments"
              onChange={(e: any) => setComments({ comment: e?.target?.value })}
            />
            {!comments && (
              <>
                <span
                  style={{
                    fontSize: 12,
                    color: 'red'
                  }}
                >
                  * Please Enter some Comments
                </span>
              </>
            )}
          </div>
        )}
        {success && <p>Permit Rejected</p>}
        <ModalFooter>
          <Button
            onClick={() => {
              success && navigate(redirectUrl)
              toggle()
            }}
            color="warning"
          >
            Close
          </Button>
          {!success && (
            <Button
              onClick={async () =>
                await rejectPermit().then((val) => {
                  val === 'success' && setSuccess(true)
                })
              }
              color="danger"
            >
              Reject
            </Button>
          )}
        </ModalFooter>
      </ModalBody>
    </Modal>
  )
}

export const UpdateModal = ({
  isOpen,
  toggle,
  riskValues,
  isJSA,
  setRiskValues
}: {
  isOpen: any
  toggle: any
  intialData?: any
  riskValues: any
  isJSA: Boolean
  setRiskValues: any
}) => {
  return (
    <Modal isOpen={isOpen} fullscreen>
      <ModalBody>
        <RiskAssessmentForm
          values={riskValues}
          setValues={setRiskValues}
          isJSA={isJSA}
          initialValuesEqual
        />
        <ModalFooter>
          <Button onClick={() => toggle()} color="warning">
            Next
          </Button>
        </ModalFooter>
        <p style={{ color: 'red', textAlign: 'right' }}>
          * Please save your Assessment before proceeding next.
        </p>
      </ModalBody>
    </Modal>
  )
}

export default function EditRisks() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const permit = data?.permit
  const riskAssessmentValues = permit?.riskAssesmentPermit

  const intialData = riskAssessmentValues?.template
  const [riskValues, setRiskValues]: any = useState()
  const [submitModal, setSubmitModal]: any = useState(false)
  const [reject, setReject]: any = useState(false)

  localStorage.setItem('riskData', JSON.stringify(intialData))

  useEffect(() => {
    setRiskValues(intialData)
  }, [riskAssessmentValues])

  const jsa = data?.permit?.isJSA
  const riskId = data?.permit?.riskAssesmentPermit?.template?._id

  const navigate = useNavigate()

  const [modal, setModal]: any = useState(false)

  const [formData, setFormData]: any = useState({
    approveRisks: 'No',
    discussedRisk: 'No',
    typeApproriate: 'No',
    appropriatePermit: 'Not Required',
    surfacesCovered: 'Not Required',
    portable: 'Not Required',
    standbyPersonnel: 'Not Required',
    comments: ''
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const values: any = {
      consent: {
        ...formData
      },
      riskAssessment: riskValues || intialData,
      permitId: data?.permit?._id,
      isJSA: data?.permit?.isJSA,
      riskAssessmentId: data?.permit?.riskAssesmentPermit?._id,
      consentId: data?.permit?.consent?._id,
      serialNumber: data?.permit?.woNumber?.split('/')?.[1]
    }

    const response = await makeAuthenticatedRequest(
      `${riskAssessmentAPI}/${riskId}`,
      'PATCH',
      values,
      accounts,
      instance
    )

    return response.status
  }

  const isJSA: any = data?.permit?.isJSA

  const existingTemplate = Boolean(
    data?.permit?.riskAssesmentPermit?.templateNumber
  )

  const form = (
    <div>
      <RadioButtonGroup
        question="Equipment Preparation & Site visit carried out ?"
        name="appropriatePermit"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Is the Risk Assessment Reviewed ?"
        name="discussedRisk"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Any corrections made in risk assessment?"
        name="typeApproriate"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      {!existingTemplate && (
        <RadioButtonGroup
          question="Do you want to approve the Risk Assessment Template ?"
          name="approveRisks"
          options={yesNoOptions}
          formData={formData}
          onChange={handleInputChange}
          defaultValue="No"
        />
      )}
      {/* <RadioButtonGroup
        question="Is Sewers, Manholes, Closed Blow Down (CBD) etc. and Hot Surfaces covered ?"
        name="surfacesCovered"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Is Portable equipment / Hose nozzles properly grounded ?"
        name="portable"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Standby personnel provided for fire watch from Process / Maintenance / Contractor / Fire Department ?"
        name="standbyPersonnel"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      /> */}
    </div>
  )
  return (
    <ProtectedLayout onBack="/eptw/newPermit/updateRiskAssessments">
      <ViewPermit data={data} isLoading={isLoading} />
      {data?.permit?.status === 'Created' &&
        data?.permit?.riskAssessment === 'Yes' && (
          <>
            <Row style={{ margin: 10 }}>
              {isJSA === 'Non Critical' && (
                <>
                  <ModalTitle
                    title="Modify Risk Assessment by HSE"
                    height={40}
                  />
                  <JSATemplateView showSelect={false} risk={riskValues} />
                </>
              )}
              {isJSA === 'Critical' && (
                <>
                  <ModalTitle
                    title="Modify Risk Assessment by HSE"
                    height={40}
                  />
                  <QRATemplateView showSelect={false} template={riskValues} />
                </>
              )}
            </Row>
            <ModalFooter>
              <Button color="danger" onClick={() => setReject(true)}>
                Reject
              </Button>
              {!existingTemplate && (
                <Button color="warning" onClick={() => setModal(true)}>
                  Modify Risk Assessment
                </Button>
              )}
              <Button color="warning" onClick={() => setSubmitModal(true)}>
                Submit
              </Button>
            </ModalFooter>
            <SubmitModalWithForm
              isOpen={submitModal}
              onClose={(val: any) => {
                setSubmitModal(false)
                val && navigate('/eptw/newPermit/updateRiskAssessments')
              }}
              onSubmit={handleSubmit}
              form={form}
            />
            {modal && (
              <UpdateModal
                isOpen={modal}
                riskValues={riskValues}
                setRiskValues={setRiskValues}
                isJSA={isJSA}
                toggle={() => {
                  setModal(!modal)
                }}
              />
            )}
            <RejectModal
              data={data}
              isOpen={reject}
              setSubmitModal={setReject}
              consentId={data?.permit?.consent?._id}
              redirectUrl="/eptw/newPermit/updateRiskAssessments"
              toggle={() => {
                setReject(!reject)
              }}
            />
          </>
        )}
    </ProtectedLayout>
  )
}
