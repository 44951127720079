import { Button, Col, ModalFooter, Row, Spinner, Table } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import { Box, renderData } from '../workManagement/PmTree'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { invoiceAPI, stock } from '../../components/serverurl'
import { Text } from '../../components/RiskAssesmentPreview'
import ReactToPrint from 'react-to-print'
import React, { useContext, useRef, useState } from 'react'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { UserContext } from '../../App'
import { prStatuses } from '../../components/constants/woStatuses'
import { incrementby1 } from '../../components/Forms/JSAForm'

const AcceptedPOTable = ({
  data,
  isLoading,
  isRefetching
}: {
  data: any
  isLoading?: boolean
  isRefetching?: boolean
}) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Line Code</th>
            <th>Spare Part Number</th>
            <th>Spare Part Description</th>
            <th>Quantity</th>
            <th>Gross Price</th>
            <th>Promised Date</th>
          </tr>
        </thead>
        <tbody>
          <>
            {isLoading || isRefetching ? (
              <tr>
                <td colSpan={12}>
                  <Row
                    style={{
                      height: 500,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Spinner />
                  </Row>
                </td>
              </tr>
            ) : (
              data?.length > 0 &&
              data?.map((w: any, index: number) => (
                <React.Fragment>
                  <tr key={w?._id}>
                    <th colSpan={12}>
                      {w?.number?.replace('PR', 'PO')}-{w?.tagDescription}
                      <br />
                      Order Value: {w?.approvedVendor?.total}
                    </th>
                  </tr>
                  {w?.approvedVendor?.quotation?.map((mr: any, index: any) => (
                    <tr key={`${index}-${mr['Spare Part Number']}`}>
                      <td>{incrementby1(index)}</td>
                      <td>{mr?.['lineCode']}</td>
                      <td>{mr?.spare?.['Spare Part Number']}</td>
                      <td>{mr?.spare?.['Spare Part Description']}</td>
                      <td>{mr?.['quantityRequested']}</td>
                      <td>{mr?.['grossPrice']}</td>
                      <td>{getReadableTime(mr?.['promisedDate'])}</td>
                    </tr>
                  ))}
                </React.Fragment>
              ))
            )}
          </>
        </tbody>
      </Table>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Old Quotations Found
          </p>
        </Row>
      )}
    </>
  )
}

export default function ViewTransportOrder() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const [closeTo, setClostTo]: any = useState(false)
  const componentRef: any = useRef()

  const navigate = useNavigate()

  const fetchto = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/inventory/to/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('toDetails', fetchto)

  const toData = data?.toDetails

  const trasnportOrderDetails: any = {
    'TO Number': toData?.number,
    'TO Status': prStatuses?.[toData?.status],
    ...(toData?.jobOrderC?.number
      ? { 'JOC Number': toData?.jobOrderC?.number }
      : {}),
    ...(toData?.jobOrderC?.number
      ? { 'JOC Satus': `${prStatuses?.[toData?.jobOrderC?.status]} Customs` }
      : {}),
    'JOF Number': toData?.jobOrderF?.number,
    'JOF Satus': `${prStatuses?.[toData?.jobOrderF?.status]} FF`,
    'Vendor Name': toData?.vendorName,
    'Vendor Id': toData?.vendorId,
    'Logistics Coordinator Name': toData?.logisticsCoordinator,
    'Created On': getReadableTime(toData?.createdOn),
    'Freight Forwarder Name': toData?.forwarderName,
    'Freight Forwareder Id': toData?.forwarderId,
    'Warehouse Id': toData?.wareHouseId,
    'Warehouse Location': toData?.wareHouseLocation
  }
  return (
    <ProtectedLayout onBack="/purchase/createdTOs" title={'Transport Order'}>
      <div ref={componentRef}>
        <Row style={{ margin: 20 }}>
          <Box heading="Transport Order Details">
            {Object.keys(trasnportOrderDetails)?.map((key: any) =>
              renderData(key, trasnportOrderDetails[key])
            )}
          </Box>
          <Box heading="Purchase Orders">
            <AcceptedPOTable data={toData?.poList} />
          </Box>
          <Row>
            <Col xs="6">
              <Box heading="Customs Invoice">
                <iframe
                  title="Document Preview"
                  src={`${invoiceAPI}/${toData?.jobOrderC?.attachments?.[0]}`}
                  width="100%"
                  height="700px"
                />
              </Box>
            </Col>
            <Col xs="6">
              <Box heading="Frieght Forwarder Invoice">
                <iframe
                  title="Document Preview"
                  src={`${invoiceAPI}/${toData?.jobOrderF?.attachments?.[0]}`}
                  width="100%"
                  height="700px"
                />
              </Box>
            </Col>
          </Row>
          <Box heading="Acknowledgment">
            <Row>
              <Col xs="6">
                <Text>For and behalf of </Text>
                <Text>{toData?.forwarderName}</Text>
                <Text>Agreed and Accepted by</Text>
                <br />
                <Text>Name:___________________________ </Text>
                <Text>Title:____________________________</Text>
                <Text>Date:___________________________ </Text>
              </Col>
              <Col xs="6">
                <Text>For and behalf of </Text>
                <Text>{toData?.logisticsCoordinator}</Text>
                <Text>Agreed and Accepted by</Text>
                <br />
                <Text>Name:___________________________ </Text>
                <Text>Title:____________________________ </Text>
                <Text>Date:___________________________ </Text>
              </Col>
            </Row>
          </Box>
        </Row>
      </div>
      <ReactToPrint
        documentTitle={`Transport Number - ${toData?.number}`}
        pageStyle={'padding: 20'}
        trigger={() => (
          <ModalFooter>
            <Button color="warning">Print</Button>
          </ModalFooter>
        )}
        content={() => componentRef.current}
      />
      {toData?.status === 'transportOrderCreated' && (
        <ModalFooter>
          <Button onClick={() => setClostTo(true)} color="warning">
            Close the Transport Order
          </Button>
        </ModalFooter>
      )}
      <SubmitModalWithForm
        isOpen={closeTo}
        onClose={(val: any) => {
          setClostTo(false)
          // val && navigate('/purchase/dashboard')
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()
          try {
            const res = await makeAuthenticatedRequest(
              `${stock}/inventory/to/${toData?._id}?userId=${userData?.id}`,
              'PATCH',
              null,
              accounts,
              instance
            )

            if (res.status === 'success') {
              await refetch()
            }

            return res.status
          } catch (error) {}
        }}
        size="xs"
        header="Close Transport Order"
      />
    </ProtectedLayout>
  )
}
