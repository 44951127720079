import { Row } from 'reactstrap'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import { IconCard } from '../components/Dashboard/IconCard'

export default function FIRSPage() {
  const flows: any = [
    {
      // src: require('./../../images/Icons/unit1.png'),
      name: 'FIRS Dashboard',
      nav: '/firs/dashboard'
    },
    {
      // src: require('./../../images/Icons/unit2.png'),
      name: 'Analytics',
      nav: '/firs/analytics'
    },
    {
      // src: require('./../../images/Icons/unit3.png'),
      name: 'SHE CARD',
      nav: '/firs/sheCard'
    }
  ]

  return (
    <>
      <ProtectedLayout onBack="/" title="FIR's">
        <Row style={{ margin: 10 }}>
          {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
            <IconCard
              key={`${index}-${name}`}
              name={name}
              nav={nav}
              canView={canView}
              count={count}
              src={src}
            />
          ))}
        </Row>
      </ProtectedLayout>
    </>
  )
}
