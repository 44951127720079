import { createUseStyles } from "react-jss";
import { Form } from "react-final-form";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Spacer from "../components/Spacer";
import { Col, Container, Row, Button } from "reactstrap";

const useStyles = createUseStyles((theme: any) => ({
  content: {
    minHeight: "100vh",
    display: "flex",
  },
  formContainer: {
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "center",
    minHeight: "100vh",
    padding: "0",
    paddingLeft: 20,
  },
  imageContainer: {
    padding: "0", // Remove padding for the image column
    height: "100vh",
    overflow: "hidden", // Ensure no overflow from the image
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover", // Ensure the image covers the container without distortion
  },
}));

export default function Login() {
  const classes = useStyles();
  const borderRadius: number = 10;

  const login = (values: any) => {
    if (values.username === values.password) {
      // handle login
    }
  };

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.username) {
      errors.username = "Required";
    }
    if (!values.password) {
      errors.password = "Required";
    }
    return errors;
  };

  const { instance } = useMsal();

  const handleLogin = (loginType: string) => {
    if (loginType === "popup") {
      instance
        .loginRedirect(loginRequest)
        .then()
        .catch((e) => {});
    }
  };

  return (
    <Container fluid className={classes.content}>
      <Row className="w-100">
        {/* Image Section: visible only on medium and larger screens */}
        <Col md={6} className={`d-none d-md-block ${classes.imageContainer}`}>
          <img
            src={require("./../images/BG_Image_crop.png")}
            alt="background_image"
            className={classes.image}
          />
        </Col>

        {/* Form Section: visible on all screen sizes */}
        <Col xs={12} md={6} className={classes.formContainer}>
          <div>
            <Form
              onSubmit={(values: any) => login(values)}
              validate={(values: any) => validate(values)}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Spacer height={"25vh"} />
                  <div className="text-center">
                    <img
                      src={require("./../images/Icons/Clamp_TM.png")}
                      alt="Logo"
                      height={100}
                    />
                    <Spacer height={"5vh"} />
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: 24,
                        marginBottom: "20px",
                      }}
                    >
                      Welcome to CLAMP ERP SYSTEM
                    </p>
                  </div>
                  <Spacer height={"5vh"} />

                  <div className="text-center">
                    <Button
                      color="primary"
                      size="lg"
                      style={{ width: "100%", borderRadius: 40 }}
                      onClick={() => handleLogin("popup")}
                    >
                      LOGIN
                    </Button>
                  </div>

                  <Spacer height={"8vh"} />

                  <div className="d-flex justify-content-between">
                    <img
                      src={require("./../../src/images/Icons/FIRS_TM.png")}
                      alt="Logo1"
                      height={100}
                    />
                    <img
                      src={require("./../images/Icons/Logo_New_TM.png")}
                      alt="Logo2"
                      height={100}
                    />
                  </div>
                </form>
              )}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
