import moment from "moment";
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import { useMsal } from "@azure/msal-react";
import { useContext, useState } from "react";

import PreviewAudit from "../previews/PreviewAudit";
import SubmitModalWithForm from "./SubmitModalWithForm";
import { auditEndpoint } from "../serverurl";
import { makeAuthenticatedRequest } from "../../apiRequests";
import { renderTitle } from "../PermitHeaderDetails";
import { UserContext } from "../../App";

export const renderLongRow = (
  title: string,
  content?: any,
  color = "#fefaa2",
  rowSize = 9,
  isMobile = false
) => (
  <div>
    <Row style={{ margin: 0 }}>
      <Col
        xs={rowSize}
        style={{
          backgroundColor: color,
          WebkitPrintColorAdjust: "exact",
          fontSize: 14,
        }}
      >
        {renderTitle(title)}
      </Col>
      <Col>
        <p style={{ fontSize: 14, marginBottom: 0 }}>{content || "NA"}</p>
      </Col>
    </Row>
  </div>
);

export default function AuditPreviewModal({
  isOpen,
  toggle,
  values,
  form,
}: any) {
  const { userData } = useContext(UserContext);
  const { accounts, instance } = useMsal();

  const [submitting, setSubmitting]: any = useState(false);
  const [submitModal, setSubmitModal]: any = useState(false);

  const createAudit: any = async () => {
    try {
      setSubmitting(true);
      const res = await makeAuthenticatedRequest(
        auditEndpoint,
        "POST",
        {
          ...values,
          auditPerson: values?.auditPerson?.value,
          applicantDepartment: userData?.department,
          createdAt: moment(),
        },
        accounts,
        instance
      );

      if (res.status === "success") {
        form.reset();
        toggle();
      }
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalBody>
        <PreviewAudit values={values} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => toggle()} color="secondary">
          Go Back
        </Button>
        <Button
          color={submitting ? "secondary" : "warning"}
          onClick={() => setSubmitModal(true)}
          disabled={submitting}
        >
          {submitting ? "Submitting" : "Submit"}
        </Button>
      </ModalFooter>
      {submitModal && (
        <SubmitModalWithForm
          isOpen={submitModal}
          onClose={(val: any) => {
            setSubmitModal(false);
          }}
          onSubmit={createAudit}
          size="xs"
          header="Create Audit"
        />
      )}
    </Modal>
  );
}
