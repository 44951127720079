import { Button, Col, Input, ModalFooter, Row } from 'reactstrap'
import { useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import CustomLabel from '../../components/InputFields/CustomLabel'
import PreviewInformationCard from '../../components/previews/PreviewInformationCard'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { informationCardEndpoint } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'

export default function ViewInformationCard() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()

  const navigate = useNavigate()

  const [rejecting, setRejecting]: any = useState(false)
  const [approving, setApproving]: any = useState(false)
  const [submitModal, setSubmitModal]: any = useState(false)
  const [status, setStatus]: any = useState(false)
  const [formData, setFormData]: any = useState({
    comments: 'No',
    icDepartment: '',
    asisgnComments: 'NA',
    awardComments: '',
    hseComments: ''
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const fetchCard = async () => {
    const response = await makeAuthenticatedRequest(
      `${informationCardEndpoint}/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchCard', fetchCard)

  const body: any = {
    ...formData,
    status
  }

  const updateStatus = async () => {
    try {
      const res = await makeAuthenticatedRequest(
        `${informationCardEndpoint}/${id}?status=${status}&comments=${formData?.comments}&icDepartment=${formData?.icDepartment}`,
        'PATCH',
        body,
        accounts,
        instance
      )

      if (res?.status === 'success') {
        setSubmitModal(false)
        await refetch()
      }
    } catch (error) {
    } finally {
      setApproving(false)
      setRejecting(false)
    }
  }

  const form = (
    <div>
      {status === 'closed' && (
        <div className="form-group">
          <CustomLabel label="Reject Reason" />
          <Input type="text" name="hseComments" onChange={handleInputChange} />
        </div>
      )}
      {status === 'recomendAward' && (
        <div className="form-group">
          <CustomLabel label="Award Comments" />
          <Input
            type="text"
            name="awardComments"
            onChange={handleInputChange}
          />
        </div>
      )}
      {status === 'icProgress' && (
        <>
          <div className="form-group">
            <CustomLabel label="Assign Comments" />
            <Input
              type="text"
              name="asisgnComments"
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <Col xs="12">
              <CustomLabel label="Select IC Department" />
              <Input
                type="select"
                name="icDepartment"
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                <option value="Electrical">Electrical</option>
                <option value="Instrument">Instrument</option>
                <option value="Mechanical">Mechanical</option>
                <option value="Production">Production</option>
              </Input>
            </Col>
          </div>
        </>
      )}
    </div>
  )

  return (
    <ProtectedLayout onBack="/eptw/informationCard">
      <Row style={{ margin: 10 }}>
        <PreviewInformationCard values={data?.data} history={data?.history} />
        {data?.data?.status === 'created' && (
          <>
            <ModalFooter style={{ marginTop: 10 }}>
              <Button
                color={rejecting ? 'secondary' : 'danger'}
                disabled={rejecting}
                onClick={() => {
                  setRejecting(true)
                  setStatus('closed')
                  setSubmitModal(true)
                }}
              >
                {rejecting ? 'Submitting...' : 'Close'}
              </Button>
              <Button
                color={approving ? 'secondary' : 'success'}
                disabled={approving}
                onClick={() => {
                  setApproving(true)
                  setStatus('icProgress')
                  setSubmitModal(true)
                }}
              >
                {approving ? 'Assigning...' : 'Assign Corrective Action'}
              </Button>
              <Button
                color={'warning'}
                onClick={() => {
                  setStatus('recomendAward')
                  setSubmitModal(true)
                }}
              >
                Recommend to Award
              </Button>
              <Button
                color={'warning'}
                onClick={() => {
                  // setStatus('recomendAward')
                  // setSubmitModal(true)
                }}
              >
                Issue Warning Letter
              </Button>
            </ModalFooter>
            {submitModal && (
              <SubmitModalWithForm
                isOpen={submitModal}
                onClose={(val: any) => {
                  setSubmitModal(false)
                  setRejecting(false)
                  setApproving(false)
                  setStatus()
                }}
                sucessView={<div></div>}
                onSubmit={updateStatus}
                form={form}
              />
            )}
          </>
        )}
      </Row>
    </ProtectedLayout>
  )
}
