import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import WorkOrderDetails from './WorkOrderDetails'

export default function ApproveWorkRequest({ onBack }: { onBack: any }) {
  return (
    <ProtectedLayout onBack={onBack}>
      <div style={{ margin: 20 }}>
        <WorkOrderDetails />
      </div>
    </ProtectedLayout>
  )
}
