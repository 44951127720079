import { Button, Input, Row } from 'reactstrap'
import { useContext, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import React from 'react'

import CustomLabel from '../../components/InputFields/CustomLabel'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import ViewPermit from '../eptw/ViewPermit'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { newPermitEndpoint, woiEndpoint } from '../../components/serverurl'
import { PermissionsContext } from '../../App'
import { RadioButtonGroup, yesNoOptions } from '../eptw/VerifyPermit'
import { useAdminPermissions } from '../eptw/EditRisks'

export default function ApproveWOI() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const { permissions } = useContext(PermissionsContext)
  const [submitModal, setSubmitModal]: any = useState(false)

  const [formData, setFormData]: any = useState({
    h2S: '',
    o2: '',
    cO: '',
    lEL: '',
    isSafe: 'No',
    hydroCarbonsPresent: 'No',
    hazardsoursMaterials: 'No'
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const submitValues = {
      ...formData,

      permitId: data?.permit?._id,
      consentId: data?.permit?.consent?._id
    }
    const response = await makeAuthenticatedRequest(
      `${woiEndpoint}/approve`,
      'PATCH',
      submitValues,
      accounts,
      instance
    )

    return response.status
  }

  const form = (
    <div>
      <div className="form-group">
        <CustomLabel label="H2S" />
        <Input type="text" name="h2S" onChange={handleInputChange} />
      </div>
      <div className="form-group">
        <CustomLabel label="O2" />
        <Input type="text" name="o2" onChange={handleInputChange} />
      </div>
      <div className="form-group">
        <CustomLabel label="CO" />
        <Input type="text" name="cO" onChange={handleInputChange} />
      </div>
      <div className="form-group">
        <CustomLabel label="LEL" />
        <Input type="text" name="lEL" onChange={handleInputChange} />
      </div>
      <RadioButtonGroup
        question="Is it safe for Job Performer to proceed the Job ?"
        name="isSafe"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Is the area free from Hydrocarbons and acceptable LEL ?"
        name="hydroCarbonsPresent"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Is the area clear from Hazardous materials and Dropped Object and Bad weather Conditions ?"
        name="hazardsoursMaterials"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
    </div>
  )

  return (
    <ProtectedLayout onBack="/eptw/woi/approve">
      <ViewPermit data={data} isLoading={isLoading} />
      {data?.permit?.status === 'Issued WOI' && permissions?.canApproveWOI && (
        <>
          <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Button color="warning" onClick={() => setSubmitModal(true)}>
              Approve Permit
            </Button>
          </Row>
          {submitModal && (
            <SubmitModalWithForm
              isOpen={submitModal}
              onClose={(val: any) => {
                setSubmitModal(false)
                val && navigate('/eptw/woi/approve')
              }}
              onSubmit={handleSubmit}
              form={form}
            />
          )}
        </>
      )}
    </ProtectedLayout>
  )
}
