import { Col, Row } from "reactstrap";
import RcmLayout from "../../components/rcm/RcmLayout";
import FPSO from "../../SVGs/Fpso";
import FPSOBoundary321 from "../../SVGs/FpsoBoundary1Svg";

export default function FpsoBoundary2() {
  return (
    <RcmLayout title={'FPSO Boundary'} onBack=''>
      <FPSOBoundary321 />
    </RcmLayout >
  )
}