import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import React, { useState } from 'react'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import ViewPermits from '../../components/Tables/ViewPermits'
import { epermit } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'

export default function IssueRevalidations() {
  const { accounts, instance } = useMsal()
  const [pageNumber, setPageNumber] = useState(0)

  const fetchAdminPermissions = async () => {
    const response = await makeAuthenticatedRequest(
      `${epermit}?page=${pageNumber}&status=revalidationVerified`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, refetch }: any = useQuery(
    'eptwRevlidateIssue',
    fetchAdminPermissions
  )

  return (
    <ProtectedLayout onBack="/eptw/dashboard">
      <ViewPermits
        data={data}
        isLoading={isLoading}
        refetch={refetch}
        setPageNumber={setPageNumber}
        navigateTo="/eptw/revalidate/issue"
      />
    </ProtectedLayout>
  )
}
