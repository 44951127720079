import { useState } from 'react'
import { Button, ModalFooter, Row } from 'reactstrap'
import { useNavigate } from 'react-router-dom'

import RcmLayout from '../../components/rcm/RcmLayout'
import RcmSystem43Raw from './RcmSystem43Raw'
import { SubHeader } from '../VerifyCM'

export default function RcmSystem43() {
  const navigate = useNavigate()
  const [selectedTags, setSelectedTags]: any = useState([])

  return (
    <RcmLayout onBack={'/cmms/rcm/systemBoundary'}>
      <Row style={{ margin: 15 }}>
        <SubHeader header={'System 43'} permitColor="gold" />
        <RcmSystem43Raw
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
        <ModalFooter>
          <Button color="warning">Select more P&ID</Button>
          <Button
            color="warning"
            onClick={() => {
              const tags = selectedTags?.map((s: any) => s?.id)
              localStorage.setItem('rcmTags', JSON.stringify(tags))
              const rcmId = localStorage.getItem('rcmId')
              navigate(`/cmms/rcm/stage2/${rcmId}`)
            }}
          >
            View Selected Tag Details
          </Button>
        </ModalFooter>
      </Row>
    </RcmLayout>
  )
}
