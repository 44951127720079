/* eslint-disable react-hooks/exhaustive-deps */
import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Container, Row, Spinner } from 'reactstrap'
import { createContext, useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from 'react-jss'
import { useIsAuthenticated } from '@azure/msal-react'
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from '@azure/msal-react'

import Admin from './pages/Admin'
import Audit from './pages/audit'
import CMAdmin from './pages/CMAdmin'
import CMDashboard from './pages/CMDashboard'
import CMMSSerivces from './pages/CMMSSerives'
import CorrectiveMeasureForm from './pages/CorrectiveMeasureForm'
import CreateNewPermit from './pages/CreateNewPermit'
import CreateUser from './pages/CreateUser'
import Dashboard from './pages/Dashboard'
import EPTWRoutes from './routes/EptwRoutes'
import EquipmentOverrides from './pages/EquipmentOverrides'
import FirsAdmin from './pages/FirsAdmin'
import FIRSDashboard from './pages/FIRSDashboard'
import Home from './pages/Home'
import IsolatedPermit from './pages/IsolatedPermit'
import LessonLearnt from './pages/LessonLearnt'
import LivePermit from './pages/LivePermit'
import Login from './pages/Login'
import LOTORegister from './pages/LOTORegister'
// import LTI from './pages/LTI'
import NotFound from './pages/NotFound'
import ObservationCard from './pages/ObservationCard'
import PendingPermit from './pages/PendingPermit'
import PMAdmin from './pages/PMAdmin'
import PMMSDashboard from './pages/PMDashboard'
import RedirectToDashBoard from './pages/RedirectToDashBoard'
import SearchPermit from './pages/SearchPermit'
import Services from './pages/Services'
import SIMOPS from './pages/SIMOPS'
import StockList from './pages/StockList'
import TagNumbers from './pages/TagNumbers'
import Verify from './pages/eptw/verify'
import VerifyLTI from './pages/lti/VerifyLTI'
import VerifyPM from './pages/VerifyPM'
import ViewClosedCM from './pages/ViewClosedCM'
import ViewCM from './pages/ViewCM'
import ViewFIR from './pages/ViewFIR'
import ViewPM from './pages/ViewPM'

import { callAllUsersMsGraph, callMsGraph } from './graph'
import { getEpermitPermissions } from './components/lib/epermitPermissions'
import { loginRequest, loginRequest2 } from './authConfig'
import ViewQR from './pages/ViewQR'
import RedirectToServices from './components/RedirectToSerives'
import OilStorage from './pages/OilStorage'
import HOECLayout from './pages/HOECLayout'
import OilStorageFull from './pages/layouts/OilStorageFull'
import ViewPermitsByLayout from './pages/ViewPermitsByLayout'
import PMTree from './pages/workManagement/PmTree'
import ComponentList from './pages/workManagement/ComponentList'
import PreventiveMaintenanceList from './pages/workManagement/PreventiveMaintenanceList'
import InventorySerivces from './pages/inventory/InventoryServices'
import PMJobsDashboard from './pages/planning/PMJobsDashboard'
import WorkMangementRouter from './routes/WorkMangementRoutes'
import WorkRequestForm from './pages/workManagement/WorkRequestForm'
import WareHouseRoutes from './routes/WarehouseRoutes'
import PurchaseRoutes from './routes/PurchaseRoutes'
import ViewPreventiveMaintenance from './pages/pm/ViewPM'
import RunningHoursBased from './pages/pm/RunningHoursBased'
import VendorView from './pages/vendor/vendorView'
import VendorQuotation from './pages/inventory/VendorQuotation'
import VendorAcceptPO from './pages/inventory/VendorAcceptPO'
import FFView from './pages/vendor/FFView'
import CustomsView from './pages/vendor/CustomsView'
import AuditView from './pages/vendor/AuditView'
import ApproveWorkRequest from './pages/workManagement/ApproveWorkRequest'
import EquipmentSerives from './pages/planning/EquipmentServices'
import DocumentationTree from './pages/workManagement/Documentation'
import Payables from './pages/finance/Payables'
import CMMSRoutes from './routes/CmmsRoutes'
import MyProfile from './pages/MyProfile'
import RaiseTicket from './pages/RaiseTicket'
import PersonalDashboard from './components/Dashboard/PersonalDashboard'
import Overdue from './pages/dashboardPages/WoOverdue'
import InformationCardRoutes from './routes/InformationCardRoutes'
import FIRSRoutes from './routes/FIRSRoutes'
import CmmsDashboard from './pages/dashboardPages/CmmsDashboard'
import EptwDashboard from './pages/eptw/eptwDashboard'
import FirsServices from './pages/FirsServices'
import EptwSerivces from './pages/eptw/EptwServices'
import ImageReader from './components/ImagetoTextPage'
import ALServices from './ashokleyland/ALServices'
import AlRoutes from './ashokleyland/ALRoutes'
import TicketsDashboard from './pages/TicketsDashboard'
import CreateTSHEMobile from './pages/informationCard/CreateTSHEMobile'
import TravelRoutes from './routes/TravelRoutes'
import WorkRequestFormMobile from './pages/workManagement/CreateWOMobile'

const theme = {
  background: '#aaa',
  colors: {
    white: '#FFF'
  }
}

export const UserContext = createContext<any>('Default Value')
export const AllUsersContext = createContext<any>('Default Value')
export const PermissionsContext = createContext<any>('Default Value')

function App() {
  const isAuthenticated = useIsAuthenticated()

  const { inProgress, accounts, instance } = useMsal()
  const queryClient = new QueryClient()

  if (inProgress === 'none' && !isAuthenticated) {
    setTimeout(() => {
      if (accounts.length === 0) {
        ;<div>
          {' '}
          not from app
          <Login />
        </div>
      }
    }, 500)
  }

  const [userData, setUserData]: any = useState(null)
  const [allUsers, setAllUsers]: any = useState(null)
  const [permissions, setPermissions]: any = useState()

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      instance.setActiveAccount(accounts[0])
    }
  }, [accounts, instance])

  useEffect(() => {
    if (!permissions) {
      getEpermitPermissions(accounts, instance).then((response: any) =>
        setPermissions(response)
      )
    }
  })

  function RequestProfileData() {
    instance
      ?.acquireTokenSilent({
        ...loginRequest2,
        account: accounts[0]
      })
      .then((response) => {
        callMsGraph(response.accessToken)
          .then((response: any) => {
            setUserData(response)
          })
          .catch((err) => console.info(err))
      })
  }

  function RequestAllUsers() {
    instance
      ?.acquireTokenSilent({
        ...loginRequest2,
        account: accounts[0]
      })
      .then((response) => {
        callAllUsersMsGraph(response.accessToken)
          .then((response: any) => setAllUsers(response?.value))
          .catch((err) => console.info(err))
      })
  }

  function RequestAPIToken() {
    instance
      ?.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      })
      .then((response) => {})
  }

  useEffect(() => {
    if (!userData) {
      RequestProfileData()
      RequestAPIToken()
      RequestAllUsers()
    }
  }, [userData])

  if (isAuthenticated && !userData) {
    setTimeout(() => {
      RequestProfileData()
    }, 3000)
    return (
      <AuthenticatedTemplate>
        <Container>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh'
            }}
          >
            <Spinner type="grow" />
            <Spinner type="grow" />
            <Spinner type="grow" />
          </Row>
        </Container>
      </AuthenticatedTemplate>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <AuthenticatedTemplate>
        <QueryClientProvider client={queryClient}>
          <UserContext.Provider value={{ userData }}>
            <AllUsersContext.Provider value={{ allUsers }}>
              <PermissionsContext.Provider value={{ permissions }}>
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/imageReader" element={<ImageReader />} />
                    <Route path="/createtshe" element={<CreateTSHEMobile />} />
                    <Route
                      path="/createtwr"
                      element={<WorkRequestFormMobile onBack="" />}
                    />
                    <Route
                      path="/firs/ic/*"
                      element={<InformationCardRoutes />}
                    />
                    <Route path="/login" element={<RedirectToDashBoard />} />
                    <Route path="/ashokleyland" element={<ALServices />} />
                    <Route path="/ashokleyland/*" element={<AlRoutes />} />
                    <Route path="/eptw" element={<Dashboard />} />
                    <Route path="/eptw/dashboard" element={<Dashboard />} />
                    <Route path="/eptw/menu" element={<EptwSerivces />} />
                    <Route
                      path="/eptw/dashboard/main"
                      element={<EptwDashboard />}
                    />
                    <Route path="/myprofile" element={<MyProfile />} />
                    <Route path="/raiseTicket" element={<RaiseTicket />} />
                    <Route path="/tickets" element={<TicketsDashboard />} />
                    <Route
                      path="/myDashboard"
                      element={<PersonalDashboard />}
                    />
                    <Route path="/cmmsDashboard" element={<CmmsDashboard />} />
                    <Route path="/myDashboard/overdue" element={<Overdue />} />
                    <Route path="/cmms" element={<CMMSSerivces />} />
                    {/* not in use */}
                    <Route path="/cmms/dashboard" element={<CMDashboard />} />
                    {/* not in use */}
                    <Route path="/pm/dashboard" element={<PMMSDashboard />} />
                    <Route
                      path="/equipment/services"
                      element={<EquipmentSerives />}
                    />
                    {/* <Route
                      path="/planning/services"
                      element={<PlanningServices />}
                    /> */}
                    <Route
                      path="/planning/pmjobs/dashboard"
                      element={<PMJobsDashboard />}
                    />
                    {/* <Route
                      path="/planning/pmjobs/pmList"
                      element={<PmList />}
                    /> */}
                    <Route
                      path="/planning/pmjobs/hourBased"
                      element={<RunningHoursBased />}
                    />
                    {/* <Route
                      path="/planning/pmjobs/pmList/:id"
                      element={
                        <ViewPreventiveMaintenance
                          back={'/planning/pmJobs/pmList'}
                        />
                      }
                    /> */}

                    <Route
                      path="/equipment/cm/new"
                      element={<WorkRequestForm onBack="/equipment" />}
                    />
                    <Route path="/component/list" element={<ComponentList />} />
                    <Route
                      path="/pm/list"
                      element={<PreventiveMaintenanceList />}
                    />
                    {/* <Route path="/pm/create" element={<PMForm />} /> */}
                    {/* <Route path="/pm/jobs" element={<PMJobs />} /> */}
                    {/* <Route path="/pm/jobs/:id" element={<PmJobDescription />} /> */}
                    <Route
                      path="/equipment/hierarchy"
                      element={<PMTree onBack={'/cmms'} />}
                    />
                    <Route
                      path="/equipment/documentation"
                      element={<DocumentationTree />}
                    />
                    <Route path="cmms/*" element={<CMMSRoutes />} />
                    <Route path="/vendor/dashboard" element={<VendorView />} />
                    <Route
                      path="/customs/dashboard"
                      element={<CustomsView />}
                    />
                    <Route
                      path="/frieghtForwarder/dashboard"
                      element={<FFView />}
                    />
                    <Route path="/audit/dashboard" element={<AuditView />} />
                    <Route
                      path="/audit/pmjobs/:id"
                      element={
                        <ViewPreventiveMaintenance back={'/audit/dashboard'} />
                      }
                    />
                    <Route
                      path="/audit/pmjobs/closed/:id"
                      element={
                        <ApproveWorkRequest onBack={'/audit/dashboard'} />
                      }
                    />

                    <Route
                      path="/vendor/newRfq/:id"
                      element={<VendorQuotation po={false} />}
                    />
                    <Route
                      path="/vendor/acceptPO/:id"
                      element={<VendorAcceptPO po={false} />}
                    />
                    <Route
                      path="/inventory/services"
                      element={<InventorySerivces />}
                    />
                    <Route
                      path="/workManagement/*"
                      element={<WorkMangementRouter />}
                    />
                    <Route
                      path="/cmms/finance/unit1"
                      element={<Payables unit={'unit1'} />}
                    />
                    <Route
                      path="/cmms/finance/unit2"
                      element={<Payables unit={'unit2'} />}
                    />
                    <Route
                      path="/cmms/finance/unit3"
                      element={<Payables unit={'unit3'} />}
                    />
                    <Route
                      path="/cmms/finance/unit4"
                      element={<Payables unit={'unit4'} />}
                    />
                    <Route
                      path="/cmms/finance/project"
                      element={<Payables unit={'project'} />}
                    />
                    <Route path="/purchase/*" element={<PurchaseRoutes />} />
                    <Route path="/warehouse/*" element={<WareHouseRoutes />} />
                    <Route path="/cmms/stock" element={<StockList />} />
                    <Route path="/firs" element={<FirsServices />} />
                    <Route path="/firs/*" element={<FIRSRoutes />} />
                    <Route path="/incidentReport/:id" element={<ViewFIR />} />
                    <Route path="/tagNumbers" element={<TagNumbers />} />
                    <Route path="/createUser" element={<CreateUser />} />
                    <Route path="/livePermit" element={<LivePermit />} />
                    <Route path="/pendingPermit" element={<PendingPermit />} />
                    <Route
                      path="/isolatedPermit"
                      element={<IsolatedPermit />}
                    />
                    <Route path="/eptw/verify" element={<Verify />} />
                    <Route path="/eptw/*" element={<EPTWRoutes />} />
                    <Route path="/createPermit" element={<CreateNewPermit />} />
                    <Route
                      path="/eptw/searchPermit"
                      element={<SearchPermit />}
                    />
                    <Route path="/lessonLearnt" element={<LessonLearnt />} />
                    <Route
                      path="/equipmentsOverrides"
                      element={<EquipmentOverrides />}
                    />
                    <Route path="/audit" element={<Audit />} />
                    <Route
                      path="/observationCard"
                      element={<ObservationCard />}
                    />
                    <Route path="/lotoRegister" element={<LOTORegister />} />
                    <Route path="/VerifyLTI/:userId" element={<VerifyLTI />} />
                    <Route path="/SIMOPS" element={<SIMOPS />} />
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/admin/dashboard/cm" element={<CMAdmin />} />
                    <Route path="/admin/dashboard/pm" element={<PMAdmin />} />
                    <Route
                      path="/admin/dashboard/firs"
                      element={<FirsAdmin />}
                    />
                    <Route
                      path="/correctiveMeasureForm"
                      element={<CorrectiveMeasureForm />}
                    />
                    <Route
                      path="/correctiveMaintenance/closed/:id"
                      element={<ViewClosedCM />}
                    />
                    <Route
                      path="/correctiveMaintence/:id"
                      element={<ViewCM />}
                    />
                    <Route
                      path="/protectiveMaintenance/:id"
                      element={<ViewPM />}
                    />
                    <Route
                      path="/protectiveMaintenance/inProgress"
                      element={
                        <VerifyPM
                          currentStatus="inProgress"
                          nextStatus="close"
                          canClose
                        />
                      }
                    />
                    <Route
                      path="/protectiveMaintenance/waitingForMaterial"
                      element={
                        <VerifyPM
                          currentStatus="waitingForMaterial"
                          nextStatus="inPropgress"
                        />
                      }
                    />
                    <Route
                      path="/protectiveMaintenance/waitingFor3rdParty"
                      element={
                        <VerifyPM
                          currentStatus="waitingFor3rdParty"
                          nextStatus="inProgress"
                        />
                      }
                    />
                    <Route
                      path="/protectiveMaintenance/waitingForShutdown"
                      element={
                        <VerifyPM
                          currentStatus="waitingForShutdown"
                          nextStatus="inProgress"
                        />
                      }
                    />
                    <Route path="/layout/oilStorage" element={<OilStorage />} />
                    <Route
                      path="/pAndId/oilStorage"
                      element={<OilStorageFull />}
                    />
                    <Route
                      path="/layout/permits"
                      element={<ViewPermitsByLayout />}
                    />
                    <Route path="/pAndId" element={<HOECLayout />} />
                    <Route path="/permit/:id" element={<ViewQR />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/dashboard" element={<RedirectToServices />} />
                    <Route path="/permit/:id" element={<ViewQR />} />
                    <Route path="/travel/*" element={<TravelRoutes />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </BrowserRouter>
              </PermissionsContext.Provider>
            </AllUsersContext.Provider>
          </UserContext.Provider>
        </QueryClientProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <BrowserRouter>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </UnauthenticatedTemplate>
    </ThemeProvider>
  )
}

export default App
