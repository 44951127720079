import { useMsal } from '@azure/msal-react'
import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import {
  Button,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane
} from 'reactstrap'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { getAccessToken, makeAuthenticatedRequest } from '../../apiRequests'
import { SubHeader } from '../VerifyCM'
import { UserContext } from '../../App'
import { stock, workOrderAPI } from '../../components/serverurl'
import { Link } from '../workManagement/PmTree'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import CustomLabel from '../../components/InputFields/CustomLabel'
import axios from 'axios'
import { loginRequest } from '../../authConfig'
import { useNavigate } from 'react-router-dom'

const tabs = {
  NEW_ORDERS: "CLASS PM'S",
  CLOSED_ORDERS: 'CLOSED WORK ORDERS'
}

export const JobOrdersTable = ({
  data,
  isLoading,
  isRefetching,
  refetch,
  clickable,
  url
}: {
  data: any
  isLoading?: boolean
  isRefetching?: boolean
  refetch: any
  clickable: any
  url: any
}) => {
  const { accounts, instance } = useMsal()
  const [close, setClose]: any = useState(false)
  const [id, setId]: any = useState()
  const [attachment, setAttachment]: any = useState()

  const navigate = useNavigate()

  const handleFileUpload = (event: any) => {
    setAttachment(event.target.files)
  }

  return (
    <>
      <>
        <Table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Number</th>
              <th>Work Description</th>
              <th>Function Number</th>
              <th>Criticality</th>
              <th>Interval</th>
              <th>Interval Type</th>
              <th>Next Due Date</th>
              <th>Next Trigger Date</th>
            </tr>
          </thead>
          <tbody>
            <>
              {isLoading || isRefetching ? (
                <tr>
                  <td colSpan={12}>
                    <Row
                      style={{
                        height: 500,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Spinner />
                    </Row>
                  </td>
                </tr>
              ) : (
                data?.length > 0 &&
                data?.map((w: any, index: number) => (
                  <React.Fragment key={w?._id}>
                    <tr key={w?._id}>
                      <td>{incrementby1(index)}</td>
                      <td>
                        {clickable ? (
                          <Link onClick={() => navigate(`${url}/${w?._id}`)}>
                            {w?.number}
                          </Link>
                        ) : (
                          w?.number
                        )}
                      </td>
                      <td>{w?.functionDescription}</td>
                      <td>{w?.functionNumber || w?.function}</td>
                      <td>{w?.criticality}</td>
                      <td>{w?.interval || 144}</td>
                      <td>{w?.pmIntervalUnit || 'Month'}</td>
                      <td>{getReadableTime(w?.nextDueDate)}</td>
                      <td>{getReadableTime(w?.triggerDate)}</td>
                    </tr>
                  </React.Fragment>
                ))
              )}
            </>
          </tbody>
        </Table>
        <ModalFooter>
          <Button color="warning">Print</Button>
        </ModalFooter>
        <SubmitModalWithForm
          isOpen={close}
          onClose={(val: any) => {
            setClose(false)
            // val && navigate('/purchase/dashboard')
          }}
          form={
            <Row>
              <CustomLabel label="Upload Invoice" />
              <input
                type="file"
                name="attachment"
                accept="application/pdf, image/*"
                onChange={handleFileUpload}
                multiple
              />
            </Row>
          }
          onSubmit={async (e: any) => {
            e.preventDefault()
            const token = await getAccessToken(loginRequest, accounts, instance)

            const formData: any = new FormData()

            if (attachment) {
              for (let i = 0; i < attachment.length; i++) {
                formData.append('attachments', attachment[i])
              }
            }
            try {
              const response = await axios.patch(
                `${stock}/jof/${id}`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                  }
                }
              )

              if (response?.data.status === 'success') {
                await refetch()
              }

              return response?.data?.status
            } catch (error) {}
          }}
          size="xs"
          header="Close Job Order"
        />
      </>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Data Found
          </p>
        </Row>
      )}
    </>
  )
}

export default function AuditView() {
  const [active, setActive]: any = useState(tabs.NEW_ORDERS)
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)

  const fetchJofs = async () => {
    const response = await makeAuthenticatedRequest(
      `${workOrderAPI}/audit`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, isRefetching, refetch }: any = useQuery(
    'PMCLASS',
    fetchJofs
  )

  const fewtchCLosedPM = async () => {
    const response = await makeAuthenticatedRequest(
      `${workOrderAPI}/audit/closed`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const closedQuery: any = useQuery('PMCLASSCLOSED', fewtchCLosedPM)

  const pmList = data?.pmlist

  const pmListClosed = closedQuery?.data?.pmlist

  return (
    <ProtectedLayout>
      <Row style={{ margin: 10 }}>
        <SectionHeader title={`AUDIT`} />
        <hr />
        <Nav tabs>
          <NavItem>
            <NavLink
              className={active === tabs.NEW_ORDERS ? 'active' : ''}
              onClick={() => setActive(tabs.NEW_ORDERS)}
            >
              {tabs?.NEW_ORDERS}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === tabs.CLOSED_ORDERS ? 'active' : ''}
              onClick={() => setActive(tabs.CLOSED_ORDERS)}
            >
              {tabs?.CLOSED_ORDERS}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={active}>
          <TabPane tabId={tabs.NEW_ORDERS}>
            <SubHeader header={tabs.NEW_ORDERS} permitColor="#FFD580" />
            <JobOrdersTable
              data={pmList}
              isLoading={isLoading}
              isRefetching={isRefetching}
              refetch={refetch}
              clickable
              url={'/audit/pmjobs'}
            />
          </TabPane>
          <TabPane tabId={tabs.CLOSED_ORDERS}>
            <SubHeader header={tabs.CLOSED_ORDERS} permitColor="#FFD580" />
            <JobOrdersTable
              data={pmListClosed}
              isLoading={isLoading}
              isRefetching={isRefetching}
              refetch={refetch}
              clickable
              url={'/audit/pmjobs/closed'}
            />
          </TabPane>
        </TabContent>
      </Row>
    </ProtectedLayout>
  )
}
