import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { useMsal } from '@azure/msal-react'
import { pmTags } from '../serverurl'

const MyDropdown = ({ value, setValue }: any) => {
  const [options, setOptions] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const { accounts, instance } = useMsal()
  const pageSize = 10

  useEffect(() => {
    loadOptions()
  }, [currentPage])

  const loadOptions = () => {
    setIsLoading(true)

    const apiUrl = `${pmTags}/tagNumbers?page=${currentPage}&pageSize=${pageSize}&searchTerm=${searchTerm}`

    makeAuthenticatedRequest(apiUrl, 'GET', null, accounts, instance).then(
      (response: any) => {
        const newOptions = response.map((item: any) => ({
          value: item.value,
          label: item.label
        }))
        setOptions((prevOptions) => prevOptions.concat(newOptions))
        setIsLoading(false)
      }
    )
  }

  const handleInputChange = (inputValue: any) => {
    setSearchTerm(inputValue)
  }

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1)
  }

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999
    })
  }

  return (
    <Select
      isSearchable
      onInputChange={handleInputChange}
      options={options}
      styles={customStyles}
      onChange={(e: any) => setValue(e?.value)}
      isLoading={isLoading}
      placeholder="Search and select..."
      noOptionsMessage={() => (isLoading ? 'Loading...' : 'No options found')}
      menuPortalTarget={document.body}
      isOptionSelected={() => false}
      onMenuScrollToBottom={handleLoadMore}
      value={{ value: setValue, label: value }}
    />
  )
}

export default MyDropdown
