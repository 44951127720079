import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import './css/pagination.css'

import { EmptyContent } from '../pages/CreateNewPermit'
import PermitQuickView from './PermitQuickView'
import CMQuickView from './CMquickView'

export default function PermitsPagination({
  permitsData,
  setSelectedPermit,
  selectedPermit,
  sidePanel
}: any) {
  const { pathname } = useLocation()

  const pageSize = 8
  const pagesCount = Math.ceil(permitsData?.length / pageSize)
  const [currentPage, setCurrentPage] = useState(0)

  const handlePageChange = (selectedPage: any) => {
    setCurrentPage(selectedPage.selected)
  }

  useEffect(() => {
    if (
      permitsData &&
      Object.keys(selectedPermit).length === 0 &&
      pathname !== '/createPermit'
    ) {
      if (permitsData[0]?.permitType === 0 || permitsData[0]?.permitType) {
        setSelectedPermit(permitsData[0])
      }
    }
  }, [selectedPermit, permitsData, setSelectedPermit, pathname])

  const styles = `@media print
  {    
      .no-print, .no-print *
      {
          display: none !important;
      }
  }`

  return (
    <>
      <style>{styles}</style>
      <div style={{ alignItems: 'center' }} className="no-print">
        {permitsData?.length > 0 &&
          permitsData
            .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
            .map((permit: any) =>
              permit.permitNumber ? (
                <div key={permit.PermitNumber}>
                  <PermitQuickView
                    permit={permit}
                    selectedPermit={selectedPermit}
                    onClick={(values: any) => setSelectedPermit(values)}
                  />
                  <div style={{ textAlign: 'right' }}>
                    <img
                      // src={require('./../../images/PoweredBy.png')}
                      src={require('./../images/PoweredBy.png')}
                      alt="cieptw Logo"
                      height={50}
                      style={{ alignSelf: 'right' }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <CMQuickView
                    permit={permit}
                    selectedPermit={selectedPermit}
                    onClick={(values: any) => setSelectedPermit(values)}
                  />
                  <img
                    // src={require('./../../images/PoweredBy.png')}
                    src={require('./../images/PoweredBy.png')}
                    alt="cieptw Logo"
                    height={50}
                    style={{ alignSelf: 'right' }}
                  />
                </>
              )
            )}
      </div>
      {sidePanel}
      {permitsData && (
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pagesCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageChange}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      )}
      {permitsData?.length === 0 && <EmptyContent label="No permits found" />}
    </>
  )
}
