import { Route, Routes } from 'react-router-dom'

import ApproveLTI from '../pages/lti/ApproveLTI'
import IssueLTI from '../pages/lti/IssueLTI'
import MakeLiveLTI from '../pages/lti/MakeLiveLTI'
import PermitsByStatus from '../pages/deIsolationAndClose/ViewPermits'
import VerifyLTI from '../pages/lti/VerifyLTI'
import FetchPermitsByLocation from '../pages/FetchPermitsByLocation'

export default function LTIRoutes() {
  return (
    <Routes>
      <Route
        path="/verify"
        element={<PermitsByStatus currentPermits="ltiInitiated" flow={'lti'} />}
      />
      <Route path="/verify/:id" element={<VerifyLTI />} />
      <Route
        path="/approve"
        element={<FetchPermitsByLocation status="ltiVerified" flow="lti" />}
      />
      <Route path="/approve/:id" element={<ApproveLTI />} />
      <Route
        path="/issue"
        element={<PermitsByStatus currentPermits="ltiApproved" flow={'lti'} />}
      />
      <Route path="/issue/:id" element={<IssueLTI />} />
      <Route
        path="/makeLive"
        element={
          <PermitsByStatus
            currentPermits="ltiIssued"
            flow={'lti'}
            checkDepartment
          />
        }
      />
      <Route path="/makeLive/:id" element={<MakeLiveLTI />} />
    </Routes>
  )
}
