import moment from 'moment'
import React from 'react'
import { Table } from 'reactstrap'

import { SubHeader } from '../../pages/VerifyCM'
import { incrementby1 } from '../Forms/JSAForm'
import toNormalCase from '../lib/toNormalCase'
import { renderTableData } from '../ToolBoxTalkMeeting'

export const PrintHistory = ({
  data,
  title = 'Approval History',
  permitColor
}: any) => {
  return (
    <>
      <SubHeader header={title} permitColor={permitColor} />
      <Table
        bordered
        style={{
          fontSize: 12,
          borderCollapse: 'collapse',
          borderColor: '#000'
        }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            {renderTableData('S.No', 1, 1, 'left')}
            {renderTableData('Status', 1, 1, 'left')}
            {renderTableData('Name', 1, 1, 'left')}
            {renderTableData('Designation', 1, 1, 'left')}
            {renderTableData('Department', 1, 1, 'left')}
            {renderTableData('Updated On', 1, 1, 'left')}

            {/* <th>S.No</th>
            <th>Status</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Department</th>
            <th>Updated On</th> */}
          </tr>
        </thead>
        <tbody>
          {data?.map((history: any, index: number) => (
            <React.Fragment key={history?._id}>
              <tr>
                {renderTableData(incrementby1(index), 1, 1, 'left')}
                {renderTableData(
                  toNormalCase(history?.updatedTo),
                  1,
                  1,
                  'left'
                )}
                {renderTableData(history?.userDetails?.givenName, 1, 1, 'left')}
                {renderTableData(history?.userDetails?.jobTitle, 1, 1, 'left')}
                {renderTableData(
                  history?.userDetails?.department,
                  1,
                  1,
                  'left'
                )}
                {renderTableData(
                  moment(history?.updatedAt).format('DD-MM-YYYY, hh:mm:ss A'),
                  1,
                  1,
                  'left'
                )}

                {/* <td>{incrementby1(index)}</td>
                <td>{toNormalCase(history?.updatedTo)}</td>
                <td>{history?.userDetails?.givenName}</td>
                <td>{history?.userDetails?.jobTitle}</td>
                <td>{history?.userDetails?.department}</td> */}
                {/* <td>
                  {moment(history?.updatedAt).format('DD-MM-YYYY, hh:mm:ss A')}
                </td> */}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </>
  )
}
