import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'

import CloseCMPermit from '../components/Modals/CloseCMPermit'
import CustomLabel from '../components/InputFields/CustomLabel'
import Loader from '../components/Loader'
import ModalTitle from '../components/ModalTitle'
import PermitHeaderDetails from '../components/PermitHeaderDetails'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import SectionHeader from '../components/Layout/SectionHeader'
import Spacer from '../components/Spacer'
import SubmitModalWithForm from '../components/Modals/SubmitModalWithForm'
import toNormalCase from '../components/lib/toNormalCase'
import {
  createCMMSWO,
  getAllCMMSWO,
  getAttachment
} from '../components/serverurl'
import { getPermissions } from '../components/lib/permissions'
import { incrementby1 } from '../components/Forms/JSAForm'
import { permitStatuses } from '../components/previews/ViewPermitdetails'
import { makeAuthenticatedRequest } from '../apiRequests'
import { renderRow, SubHeader } from './VerifyCM'

export const cmTableHeaders = [
  'S.No',
  'WO Number',
  'Title',
  'Priority',
  'Equipment',
  'Resp. Department',
  'Status',
  'Created At',
  'Due Date'
]

export const pmTableHeaders = [
  'S.No',
  'Title',
  'Tag Number',
  'Priority',
  'Resp. Department',
  'Frequency',
  'Due Date'
]

export const CMStatuses = {
  CREATED: 'created',
  VERIFIED: 'verified',
  APPROVED: 'approved',
  TODO: 'todo',
  INPROGRESS: 'inProgress',
  WAITINGFORSHUTDOWN: 'waitingForShutdown',
  WAITINGFOR3RDPARTY: 'waitingFor3rdParty',
  WAITINGFORMATERIAL: 'waitingForMaterial',
  CLOSED: 'closed',
  REJECTED: 'rejected'
}

const ViewAttachment = ({
  isOpen,
  toggle,
  attachment
}: {
  isOpen: boolean
  toggle: any
  attachment: string
}) => {
  const [isAttachmentAvailable, setIsAttachmentAvailable] = useState(true)

  useEffect(() => {
    setIsAttachmentAvailable(true)
  }, [attachment])

  useEffect(() => {
    const checkDocumentAvailability = async () => {
      try {
        const response = await fetch(`${getAttachment}/${attachment}`)
        if (!response.ok) {
          setIsAttachmentAvailable(false)
        }
      } catch (error) {
        setIsAttachmentAvailable(false)
      }
    }

    checkDocumentAvailability()
  }, [attachment, getAttachment])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalTitle title="Attachment" />
      <ModalBody>
        <div style={{ marginLeft: 10, marginRight: 10 }}>
          {isAttachmentAvailable ? (
            <iframe
              title="Document Preview"
              src={`${getAttachment}/${attachment}`}
              width="100%"
              height="700px"
              onLoad={() => {
                setIsAttachmentAvailable(true)
              }}
              onError={() => {
                setIsAttachmentAvailable(false)
              }}
            />
          ) : (
            <p>Error loading attachment or attachment not available.</p>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const CMPreview = ({ data }: any) => {
  const [attachment, setAttachment]: any = useState(false)

  const fields = {
    'CM Number': data?.woNumber,
    'CM Title': data?.title,
    'Responsible Department': data?.respDiscipline,
    'Created On': moment(data?.createdAt).format('DD/MM/YYYY'),
    'Due Finish Date': moment(data?.createdAt).format('DD/MM/YYYY')
  }

  const permitData: any = {
    'CM Description': data?.reason,
    Equipment: data?.equipment,
    'Equipment Description': data?.equipmentDescription,
    'Equipment Others': data?.equipmentOthers,
    'Equipment Description Others': data?.eDescriptionOthers,
    Location: data?.location,
    Status: toNormalCase(data?.status),
    'System Criticality': data?.systemCriticality,
    'Vendor Required': data?.vendorRequired,
    'Need Shutdown': data?.needShutdown,
    'Work Order Category': data?.woCategory,
    'Maintenance Class': data?.mainClass,
    'Maintenance Cause': data?.mainCause,
    Priority: data?.priority,
    'Estimated Duration (hrs)': data?.estimatedDuration,
    Comments: data?.comments,
    // 'Created By': data?.createdBy,
    'Planning Date': moment(data?.planningDate).format('DD/MM/YYYY'),
    'DueFinish Date': moment(data?.dueFinishDate).format('DD/MM/YYYY'),
    'Schedule Start': moment(data?.schStart).format('DD/MM/YYYY'),
    'Schedule Finish': moment(data?.schFinish).format('DD/MM/YYYY')
  }

  return (
    <>
      <Spacer height={10} />
      <ModalTitle
        height={40}
        title={`${data?.woNumber} - ${toNormalCase(
          data?.status
        ).toUpperCase()}`}
      />
      <PermitHeaderDetails permit={fields} />
      <Spacer height={10} />
      <SubHeader header="Corrective Maintenance Details" />
      <Spacer height={10} />
      {Object.keys(permitData).map((key) => renderRow(key, permitData[key]))}
      <>
        {data?.attachments?.length > 0 && (
          <>
            <Spacer height={10} />
            <SubHeader header="Attachments" />
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              {data?.attachments?.map((a: string, index: number) => (
                <div key={a} onClick={() => setAttachment(a)}>
                  <p
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: 'blue'
                    }}
                  >
                    Attachment{incrementby1(index)}
                  </p>
                </div>
              ))}
            </div>
          </>
        )}
      </>
      {!!attachment && (
        <ViewAttachment
          isOpen={!!attachment}
          toggle={() => setAttachment(!attachment)}
          attachment={attachment}
        />
      )}
    </>
  )
}

export const ViewHistory = ({
  data,
  title = 'Approval History',
  permitColor,
  showTitle = true
}: any) => {
  return (
    <>
      {showTitle && <SubHeader header={title} permitColor={permitColor} />}
      <Table bordered striped style={{ borderColor: '#000', fontSize: 14 }}>
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            <th>S.No</th>
            <th>Status</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Department</th>
            <th>Updated On</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((history: any, index: number) => (
            <React.Fragment key={history?._id}>
              <tr>
                <td>{incrementby1(index)}</td>
                <td>
                  {permitStatuses[history?.updatedTo] ||
                    toNormalCase(history?.updatedTo)}
                </td>
                <td>{history?.userDetails?.givenName}</td>
                <td>{history?.userDetails?.jobTitle}</td>
                <td>{history?.userDetails?.department}</td>
                <td>
                  {moment(history?.updatedAt).format('DD-MM-YYYY, hh:mm:ss A')}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      <Spacer height={10} />
    </>
  )
}

export const ViewCloseOutDetails = ({ data, epermit, cm }: any) => {
  const formData: any = {
    'Date Completed': moment(data?.dateCompleted).format('DD/MM/YYYY'),
    'Total Duration': data?.totalDuration,
    'Service Mode': data?.serviceMode,
    'Core Crew Working Hours': data?.coreCrewWorking,
    'Vendor Working Hours': data?.vendorWorking,
    'Plant ShutDown': data?.plantShutdown,
    'Unit ShutDown': data?.unitShutdown,
    'Lesson Learnt': data?.lessonLearnt,
    'Trial Run Conducted': data?.trialRunConducted,
    'Experiment Handover to': data?.equipmentHandoverTo,
    'Historty Card Updated': data?.historyCardUpdated,
    'Department Worked': data?.departmentWorked,
    'Budget Code': data?.budgetCode,
    History: data?.history,
    Checklist: data?.checkList,
    'Corrective Measure': data?.correctiveMeasure,
    'Special Tools Used': data?.specialToolsUsed,
    'Trial Run Observation': data?.trialRunObervation,
    'Equipment Witness By': data?.trialRunObervation
  }

  const ePermitReport: any = {
    'Submitted At': moment(data?.createdAt).format('DD-MM-YYYY hh:mm:ss A'),
    'Submittied By': data?.userDetails?.givenName,
    'Job Complete': data?.jobComplete,
    'LTI Incident': data?.ltiIncident,
    'Need CM Work Order': data?.needCMWorkOrder,
    'Plant Shutdown': data?.plantShutdown,
    'Unit Shutdown': data?.unitShutdowmn,
    'Production Loss': data?.productionLoss,
    'Lesson Learnt': data?.lessonLearnt,
    Comments: data?.comments
  }

  return (
    <>
      <SubHeader header="Close Out Details" />
      {cm &&
        Object.keys(formData).map((key: any) => renderRow(key, formData[key]))}
      {epermit &&
        Object.keys(ePermitReport).map((key: any) =>
          renderRow(key, ePermitReport[key])
        )}
      <Spacer height={10} />
      <Row>
        <Col>
          <SubHeader header="Stock Used" />
          <Spacer height={10} />
          <Table bordered striped style={{ border: '1px solid #000' }}>
            <thead>
              <tr style={{ backgroundColor: 'orange' }}>
                <td>S.No</td>
                <td>Stock Item Code</td>
                <td>Stock Name</td>
                <td>Quantityy</td>
                <td>Unit</td>
              </tr>
            </thead>
            <tbody>
              {data?.stockUsed?.map((stock: any, index: number) => (
                <tr key={index}>
                  <td style={{ width: '5%' }}>{incrementby1(index)}</td>
                  <td>{stock?.name}</td>
                  <td>{stock?.price}</td>
                  <td>{stock?.quantity}</td>
                  <td>{stock?.unit}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        {/* <Col>
          <SubHeader header="Stock Used" />
          <Spacer height={10} />
          <Table bordered striped style={{ border: '1px solid #000' }}>
            <thead>
              <tr style={{ backgroundColor: 'orange' }}>
                <td>S.No</td>
                <td>Stock Item Code</td>
                <td>Stock Name</td>
              </tr>
            </thead>
            <tbody>
              {data?.stockUsed?.map((stock: any, index: number) => (
                <tr key={index}>
                  <td style={{ width: '5%' }}>{incrementby1(index)}</td>
                  <td>{stock?.name}</td>
                  <td>{stock?.price}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col> */}
        <Col>
          <SubHeader header="Vendor Used" />
          <Spacer height={10} />
          <Table bordered striped style={{ border: '1px solid #000' }}>
            <thead>
              <tr style={{ backgroundColor: 'orange' }}>
                <td>S.No</td>
                <td>Vendor Name</td>
                <td>Hours</td>
              </tr>
            </thead>
            <tbody>
              {data?.vendorUsed?.map((stock: any, index: number) => (
                <tr key={index}>
                  <td style={{ width: '5%' }}>{incrementby1(index)}</td>
                  <td>{stock?.name}</td>
                  <td>{stock?.hours}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col>
          <SubHeader header="Core Crew Used" />
          <Spacer height={10} />
          <Table bordered striped style={{ border: '1px solid #000' }}>
            <thead>
              <tr style={{ backgroundColor: 'orange' }}>
                <td>S.No</td>
                <td>Resource Name</td>
                <td>Hours</td>
              </tr>
            </thead>
            <tbody>
              {data?.resourceUsed?.map((resource: any, index: number) => (
                <tr key={index}>
                  <td style={{ width: '5%' }}>{incrementby1(index)}</td>
                  <td>{resource?.name}</td>
                  <td>{resource?.hours}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  )
}

export default function ViewCM() {
  const { accounts, instance } = useMsal()
  const { id } = useParams()
  const navigate = useNavigate()

  const [closeModal, setCloseModal]: any = useState(false)
  const [data, setData]: any = useState()
  const [history, setHistory]: any = useState()
  const [closeOutForm, setcloseOutForm]: any = useState()
  const [permissions, setPermissions]: any = useState()
  const [submitModal, setSubimtModal]: any = useState(false)
  const [comments, setComments]: any = useState()

  useEffect(() => {
    try {
      if (!data)
        makeAuthenticatedRequest(
          `${getAllCMMSWO}/${id}`,
          'GET',
          null,
          accounts,
          instance
        ).then((response: any) => {
          setData(response?.data?.permits)
          setHistory(response?.data?.history)
          setcloseOutForm(response?.data?.closeOutForm)
        })
    } catch (err) {
      console.error(err)
    }
    if (!permissions) {
      getPermissions(accounts, instance).then((response: any) =>
        setPermissions(response)
      )
    }
  })

  if (!data) {
    return <Loader />
  }

  const onSubmit = async () => {
    await makeAuthenticatedRequest(
      `${createCMMSWO}?status=${submitModal}&id=${data?._id}&comments=${comments}`,
      'PATCH',
      null,
      accounts,
      instance
    ).then((res) => {
      navigate('/cmms/dashboard')
      setSubimtModal(false)
    })
  }

  const SubmitButton = ({ canView, updateTo, text, color = 'success' }: any) =>
    canView && (
      <Button
        color={color}
        onClick={() => setSubimtModal(updateTo)}
        style={{ marginBottom: 10 }}
      >
        {text}
      </Button>
    )

  const showSubmitButtons = !(
    data?.status === CMStatuses.CLOSED || data?.status === CMStatuses.REJECTED
  )

  const respDiscipline =
    data?.respDiscipline === 'Admin Ware House'
      ? 'wareHouse'
      : data?.respDiscipline?.toLowerCase()

  const canMakeInProgress =
    permissions?.canMakeInProgressCM(respDiscipline) &&
    CMStatuses.APPROVED &&
    data?.status !== CMStatuses.INPROGRESS

  const canMakeVerify = permissions?.canVerifyCM

  const canMakeApprove =
    permissions?.canApproveCM && data?.status === CMStatuses.VERIFIED

  const canMake3rdParty =
    permissions?.canMakeWaitingFor3rdPartyCM(respDiscipline) &&
    CMStatuses.APPROVED &&
    data?.status !== CMStatuses.WAITINGFOR3RDPARTY

  const canMakeWaitingMaterial =
    permissions?.canMakeWaitingForMaterialCM(respDiscipline) &&
    CMStatuses.APPROVED &&
    data?.status !== CMStatuses.WAITINGFORMATERIAL

  const canMakeWaitingShutdown =
    permissions?.canMakeWaitingForShutdownCM(respDiscipline) &&
    CMStatuses.APPROVED &&
    data?.status !== CMStatuses.WAITINGFORSHUTDOWN

  return (
    <ProtectedLayout onBack="/cmms/dashboard">
      <SectionHeader title="CORRECTIVE MAINTENANCE" />
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <CMPreview data={data} />
        <Spacer height={10} />
        {history.length > 0 && <ViewHistory data={history} />}
        {closeOutForm && <ViewCloseOutDetails data={closeOutForm} cm />}
        {data?.status !== 'closed' && data?.status !== 'rejected' && (
          <ModalFooter>
            <SubmitButton
              canView={canMakeVerify || canMakeApprove}
              updateTo="rejected"
              text="Reject"
              color="danger"
            />
            <SubmitButton
              canView={canMakeVerify}
              updateTo="verified"
              text="Verify"
            />
            <SubmitButton
              canView={canMakeApprove}
              updateTo="approved"
              text="Approve"
            />
            <SubmitButton
              canView={canMakeInProgress}
              updateTo="inProgress"
              text="In Progress"
            />
            <SubmitButton
              canView={canMake3rdParty}
              updateTo="waitingFor3rdParty"
              text="Waiting For 3rd Party"
            />
            <SubmitButton
              canView={canMakeWaitingMaterial}
              updateTo="waitingForMaterial"
              text="Waiting For Material"
            />
            <SubmitButton
              canView={canMakeWaitingShutdown}
              updateTo="waitingForShutdown"
              text="Waiting For Shutdown"
            />
            {CMStatuses.INPROGRESS &&
              data?.status !== CMStatuses.APPROVED &&
              permissions?.canMakeCloseCM(respDiscipline) && (
                <Button
                  color="success"
                  onClick={() => setCloseModal(true)}
                  style={{ marginBottom: 10 }}
                >
                  Close
                </Button>
              )}
          </ModalFooter>
        )}
        <CloseCMPermit
          isOpen={closeModal}
          toggle={() => setCloseModal(!closeModal)}
          permitId={data?._id}
          type="CM"
        />
        {/* <SubmitModal
          isOpen={!!submitModal}
          toggle={() => setSubimtModal(!submitModal)}
          onSubmit={onSubmit}
          submitModalContent={{ title: 'Are you sure you want to submit?' }}
        /> */}
        <SubmitModalWithForm
          isOpen={!!submitModal}
          onClose={(val: any) => {
            setSubimtModal(!submitModal)
            setComments()
          }}
          onSubmit={async (e: any) => {
            e.preventDefault()
            const res = await onSubmit()
            return res
          }}
          form={
            submitModal === 'rejected' && (
              <div>
                <CustomLabel label="Comments" />
                <Input
                  type="text"
                  onChange={(e: any) => setComments(e?.target?.value)}
                />
              </div>
            )
          }
          size="xs"
          header="Corrective Maintenance"
        />
      </div>
    </ProtectedLayout>
  )
}
