import { Row, Col } from 'reactstrap'
import { useNavigate } from 'react-router-dom'

import Breadcrumbs from '../Dashboard/Breadcrumbs'
import DashboardHeader from './../Dashboard/DashboardHeader'
import PermitsPagination from '../PermitsPagination'
import Spacer from '../Spacer'
import theme from './../constants/defaultTheme.json'
import UserProfile from '../UserProfile'

export default function ProtectedLayout({
  allPermits,
  children,
  hasSideList,
  hasUserProfile,
  onBack,
  selectedPermit,
  setSelectedPermit,
  sidePanel,
  title,
  logo,
  backgroundColor,
  backButtonVisible = true
}: any) {
  const navigate = useNavigate()

  return (
    <>
      <div
        style={{
          backgroundColor: backgroundColor || '#FAFAD2'
        }}
      >
        <DashboardHeader
          onBack={() => navigate(onBack)}
          title={title}
          logo={logo}
          backButtonVisible={backButtonVisible}
        />
      </div>
      <div>
        <Breadcrumbs />
      </div>
      <div style={{}}>
        <Row style={{ padding: 0, display: 'flex' }}>
          {hasSideList && (
            <Col xs="12" md="2" style={{ padding: 0 }} className="no-print">
              <Spacer height={20} />
              <PermitsPagination
                permitsData={allPermits}
                setSelectedPermit={(values: any) => {
                  setSelectedPermit(values)
                }}
                selectedPermit={selectedPermit}
                sidePanel={sidePanel}
              />
            </Col>
          )}
          <Col
            xs="12"
            md={hasSideList ? (hasUserProfile ? '8' : '10') : '12'}
            style={{
              backgroundColor: theme.colors.white,
              borderRight: 0,
              border: hasSideList
                ? `5px solid ${theme?.colors?.lightGray1}`
                : 0,
              borderTop: 0,
              borderBottom: 0,
              borderRightWidth: 10,
              // borderTopLeftRadius: hasSideList ? 10 : 0,
              // borderTopRightRadius: hasUserProfile ? 10 : 0,
              minHeight: '80vh',
              padding: 0,
              paddingBottom: 10,
              paddingTop: hasSideList ? 10 : 0
            }}
          >
            <Row>
              <Col>
                <>{children}</>
              </Col>
            </Row>
          </Col>
          {hasUserProfile && (
            <Col
              xs="12"
              md="2"
              style={{
                marginTop: 15,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <div>
                <UserProfile />
              </div>
              <div className="no-print" style={{ textAlign: 'center' }}>
                <p>
                  <b>
                    <i>Powered by</i>
                  </b>
                </p>
                <img
                  src={require('./../../images/Logo.png')}
                  alt="cieptw Logo"
                  height={150}
                  style={{ alignSelf: 'right', paddingBottom: 30 }}
                />
              </div>
            </Col>
          )}
        </Row>
      </div>
    </>
  )
}
