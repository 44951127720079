import { Button, Col, ModalFooter, Row, Table } from 'reactstrap'

import Spacer from '../components/Spacer'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import { warningListData } from './WarningLetterList'

export default function WarningLetter({ onBack }: { onBack: any }) {
  const warningLetterData: any = warningListData?.[0]

  return (
    <ProtectedLayout onBack={onBack} title="WARNING LETTER">
      <Row style={{ margin: 20 }}>
        <Col md="3" />
        <Col style={{ border: '1px dashed #000', height: '80vh' }}>
          <Spacer height={15} />
          <h3 style={{ textAlign: 'center' }}>
            WARNING LETTER ON SAFETY VIOLATION
          </h3>
          <Spacer height={15} />
          <Table bordered style={{ borderColor: '#000' }}>
            <thead>
              <tr>
                <th>Date</th>
                <td>{warningLetterData?.createdOn}</td>
                <th>Name</th>
                <td>{warningLetterData?.name}</td>
              </tr>
              <tr>
                <th>Id.No</th>
                <td>Al-0455</td>
                <th>Category</th>
                <td></td>
              </tr>
              <tr>
                <th>Department</th>
                <td>PPKM</td>
                <th>Line</th>
                <td></td>
              </tr>
              <tr>
                <th>Letter Issued for</th>
                <td></td>
                <th></th>
                <th>Unsafe Work Practices</th>
              </tr>
            </thead>
          </Table>

          <p>Description of the Safety Violation</p>
          <Spacer height={50} />
          <p>Name</p>
          <p>HR/IR</p>
          <Spacer height={15} />
          <p>
            I have read, understood and accept the Warning memo. I assure that,
            I will not involve in any unsafe work practices and monitor such
            unsafe work practices & condition and report to the concerned.
          </p>
          <p>Defaulter signature Name (Employee ID)</p>
          <Spacer height={15} />

          <img
            src={require('./../images/e73_logo.jpeg')}
            alt="logo"
            style={{ width: 200, height: 'auto', textAlign: 'left' }}
          />
          <ModalFooter>
            <Button color="warning">Email</Button>
            <Button color="warning">Print</Button>
          </ModalFooter>
        </Col>
        <Col md="3" />
      </Row>
    </ProtectedLayout>
  )
}
