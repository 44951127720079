import { useContext, useEffect, useState } from 'react'
import { HiOutlineSquares2X2, HiSquares2X2 } from 'react-icons/hi2'
import Select from 'react-select'

import CustomLabel from '../components/InputFields/CustomLabel'
import DropdownWithPagination from '../components/InputFields/DropDownWithPagination'
import PMFrequencyFilter from '../components/InputFields/PMFrequencyFilter'
import PMPreviewTable from '../components/Tables/PMPermitTable'
import PMTodoTable from '../components/Tables/PMTable'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import SectionHeader from '../components/Layout/SectionHeader'
import theme from '../components/constants/defaultTheme.json'
import toNormalCase from '../components/lib/toNormalCase'
import { Button, Col, Input, Row } from 'reactstrap'
import { capitalizeFirstLetter } from '../components/lib/capitalizeFirstLetter'
import { getOptions } from './firs'
import { getPMList, protectiveMaintenancePermit } from '../components/serverurl'
import { makeAuthenticatedRequest } from '../apiRequests'
import { useMsal } from '@azure/msal-react'
import { UserContext } from '../App'
import { useSearchParams } from 'react-router-dom'

function SidePane({ list, activeItem, setActiveItem }: any) {
  return (
    <>
      <div style={{ paddingLeft: 10, paddingRight: 10 }}>
        {list?.map((type: any) => (
          <div
            key={type}
            style={{
              border:
                activeItem === type
                  ? `2px solid ${theme.borderColor.white}`
                  : 'none',
              display: 'flex',
              padding: activeItem === type ? 5 : 7,
              backgroundColor:
                activeItem === type ? theme.colors.blue : 'transparent',
              borderRadius: 5,
              minWidth: '18vh',
              cursor: 'pointer',
              marginBottom: 35,
              boxShadow:
                'rgba(0, 0, 0, 0.4) 0px 1px 2px, rgba(0, 0, 0, 0.3) 0px 2px 3px -3px, rgba(0, 0, 0, 0.2) 0px -2px 0px inset'
            }}
            onClick={() => setActiveItem(type)}
          >
            {activeItem === type ? (
              <HiSquares2X2
                style={{
                  marginTop: 4,
                  color:
                    activeItem === type
                      ? theme.colors.white
                      : theme.colors.lightBlue
                }}
              />
            ) : (
              <HiOutlineSquares2X2
                style={{
                  marginTop: 4,
                  color:
                    activeItem === type
                      ? theme.colors.white
                      : theme.colors.lightBlue
                }}
              />
            )}
            <p
              style={{
                margin: '0px 0px 0px 10px',
                fontWeight: 700,
                color:
                  activeItem === type
                    ? theme.fontColor.white
                    : theme.fontColor.lightBlue
              }}
            >
              {toNormalCase(type)}
            </p>
          </div>
        ))}
      </div>
    </>
  )
}

const pmTableHeaders = [
  'S.No',
  'PM Description',
  'Tag Number',
  'Resp. Department',
  'Priority',
  'Frequency',
  'Status',
  'Due Date'
]

const sidePaneItems = [
  'todo',
  'inProgress',
  'waitingForMaterial',
  'waitingFor3rdParty',
  'waitingForShutdown',
  'closed'
]

export default function PMMSDashboard() {
  const [searchParams] = useSearchParams()
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)

  const currentView: any = searchParams.get('view')
  const [pageNumber, setPageNumber] = useState(1)
  const [loading, setLoading] = useState(true)
  const [status, setStatus]: any = useState('')
  const [department, setDepartment]: any = useState('')
  const [priority, setPriority]: any = useState('')
  const [tagNumber, setTagNumber]: any = useState('')
  const [frequency, setFrequency]: any = useState('')
  const [startDate, setStartDate]: any = useState('')
  const [endDate, setEndDate]: any = useState('')

  const currentTab =
    sidePaneItems.indexOf(currentView) !== -1
      ? sidePaneItems.indexOf(currentView)
      : 0

  const [active, setActive]: any = useState(sidePaneItems[currentTab])
  const [data, setData]: any = useState([])
  const [todo, setTodo]: any = useState([])

  const getDepartment = (department: any) => {
    if (
      department === 'Mechanial' ||
      department === 'Electrical' ||
      department === 'Instrument' ||
      department === 'Production' ||
      department === 'hse' ||
      department === 'adminwarehouse'
    ) {
      return capitalizeFirstLetter(department)
    } else {
      return ''
    }
  }

  //2023-10-15T00:00:00.000+00:00

  const fetchTodo = async () =>
    await makeAuthenticatedRequest(
      `${getPMList}?page=${pageNumber}&status=${status}&priority=${priority}&tagNumber=${tagNumber}&frequency=${frequency}&startDate=${startDate}&endDate=${endDate}&department=${capitalizeFirstLetter(
        userData?.department
      )}`,
      'GET',
      null,
      accounts,
      instance
    ).then((response: any) => {
      setTodo(response)
      setLoading(false)
    })

  useEffect(() => {
    try {
      setLoading(true)
      fetchTodo()
    } catch (err) {
      console.error(err)
    }
  }, [
    active,
    pageNumber,
    status,
    priority,
    tagNumber,
    frequency,
    startDate,
    endDate
  ])

  useEffect(() => {
    try {
      makeAuthenticatedRequest(
        `${protectiveMaintenancePermit}?status=${active}&department=${capitalizeFirstLetter(
          userData?.department
        )}`,
        'GET',
        null,
        accounts,
        instance
      ).then((response: any) => {
        setData(response?.documents)
        setLoading(false)
      })
    } catch (err) {
      console.error(err)
    }
  }, [active])

  const todoView = active === sidePaneItems[0]

  const onPageChange = (value: any) => {
    setPageNumber(value)
    setLoading(true)
  }

  return (
    <ProtectedLayout
      hasSideList
      hasUserProfile
      onBack="/cmms/services"
      sidePanel={
        <SidePane
          list={sidePaneItems}
          activeItem={active}
          setActiveItem={setActive}
        />
      }
    >
      {active !== sidePaneItems[0] && (
        <>
          <SectionHeader
            title={`preventive Maintenance ${active}`.toUpperCase()}
          />
          <hr />
        </>
      )}
      {todoView ? (
        <>
          <Row style={{ display: 'flex', alignItems: 'end', margin: 10 }}>
            <Col>
              <CustomLabel label="Tag Number" />
              <DropdownWithPagination
                value={tagNumber}
                setValue={setTagNumber}
              />
            </Col>
            <Col>
              <CustomLabel label="Frequency" />
              <PMFrequencyFilter value={frequency} setValue={setFrequency} />
            </Col>
            <Col>
              <CustomLabel label="Priority" />
              <Select
                onChange={(e: any) => setPriority(e.value)}
                value={{ label: priority, value: priority }}
                options={getOptions(['', 'P1', 'P2', 'P3'])}
              />
            </Col>
            <Col>
              <CustomLabel label="Status" />
              <Select
                id="status"
                onChange={(e: any) => setStatus(e.value)}
                value={{ value: status, label: status }}
                options={getOptions(['', 'Active', 'Obselete'])}
              />
            </Col>
            <Col>
              <CustomLabel label="Department" />
              <Select
                id="department"
                onChange={(e: any) => setDepartment(e.value)}
                value={{ value: status, label: status }}
                options={getOptions([
                  'Instrument',
                  'Electrical',
                  'Mechanical',
                  'Production'
                ])}
              />
            </Col>
            <Col>
              <CustomLabel label="Start Date" />
              <Input
                type="date"
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
            </Col>
            <Col>
              <CustomLabel label="End Date" />
              <Input
                type="date"
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
            </Col>
            <Col>
              <Button
                color="link"
                onClick={() => {
                  setStatus('')
                  setTagNumber('')
                  setPriority('')
                  setFrequency('')
                  setStartDate('')
                  setEndDate('')
                }}
              >
                Clear Filter
              </Button>
            </Col>
          </Row>
          <Row style={{ margin: 10 }}>
            <PMTodoTable
              headers={pmTableHeaders}
              data={todo?.paginatedDocuments}
              totalCount={Math.ceil(todo?.paginatedDocuments / 20)}
              setPageNumber={onPageChange}
              loading={loading}
              refetch={fetchTodo}
            />
          </Row>
        </>
      ) : (
        <Row style={{ margin: 10 }}>
          <PMPreviewTable
            data={data}
            totalCount={Math.ceil(data / 20)}
            setPageNumber={onPageChange}
            loading={loading}
          />
        </Row>
      )}
    </ProtectedLayout>
  )
}
