export const documents = {
  name: 'Management System Manual',
  children: [
    {
      name: 'Traning Manual', children: [
        { name: 'Introduction, Training Policy and Objectives' },
        { name: 'Training Requirements and Types of Training' },
        { name: 'Training Course Processes and Procedures' },
        { name: 'Training Records' },
        { name: 'Employee Orientation' },
        { name: 'On The Job Training Modules' },
        { name: 'Well Control Training' },
        { name: 'Environmental Training' },
        { name: 'Short Service Employee Program' },
        { name: 'Short Service Employee Enrollment Form' },
        { name: 'On The Job Training(OJT) Module Enrollment Form' },
        { name: 'Rig Training Nomination Form' },
        { name: 'Short Service Employee Enrollment Form' },
        { name: 'On The Job Training(OJT) Module Enrollment Form' },
        { name: 'Rig Training Nomination Form' }
      ]
    },
    {
      name: 'Marine Operations', children: [
        { name: 'Introduction' },
        { name: 'Classification and Statutory Certificates and Surveys(Jack - ups)' },
        { name: 'VHF / UHF Radio Equipment for Rig Moves' },
        { name: "Flag State and International Regulations and Regulatory Publications" },
        { name: 'Reporting of Marine Incident and Marine Casualty' },
        { name: 'International Maritime Satellite(INMARSAT) Units And Emergency Position Indicating Radio Beacons(EPIRBs)' },
        { name: "Rig Certificates and Documents" },
        { name: 'Marine Licensing and Minimum Safe Manning Requirements' },
        { name: 'Jack- up Location Approval' },
        { name: "Jack - up Towing" },
        { name: 'Annex A, Towing Wire List' },
        { name: "Rig Move Notification" },
        { name: 'Rig Elevated Loading' },
        { name: "Safe Manning Under Tow" },
        { name: 'Moored Vessel Pre - Deployment Drilling Location Survey Requirements' },
        { name: 'Semi - Submersible Rig Move Approval' },
        { name: 'Rig Move Planning(Jack - Ups)' },
        { name: 'Daily Reports for Voyages and Extended Tows' },
        { name: 'Entering and Clearing Ports' },
        { name: "Mooring / Unmooring Drilling Vessels" },
        { name: 'Guidelines for Moving Jackups Adjacent to Fixed Structures' },
        { name: "Rig Move Report" },
        { name: 'Precautionary Measures for Moored Vessels' },
        { name: "Bulk Fuel / Hydrocarbon Transfer Procedure" },
        { name: "Fuel Oil Sampling Procedure" },
        { name: "Vessels in Distress" },
        { name: "Adjacent Vessel Policy" },
        { name: "Underwater Exam in Lieu of Dry - docking(UWILD) for Jackups" },
        { name: "Lightship Changes" },
        { name: "Stability Calculations" },
        { name: "Weather Forecast Services" },
        { name: "Rig Move Notification Form" },
        { name: "Rig Move Check List" },
        { name: "Declaration of Inspection" },
        { name: "Return of Death for Singapore - Flagged Rigs" },
        { name: "Aban Offshore Tow Boat Check List(AOL)" },
        { name: "Aban Offshore Tow Boat Check List(ASPL)" },
        { name: "Return of Death for Singapore - Flagged Rigs" },
        { name: "BAHAMAS" },
        { name: "Bahamas Form CRF - 1(02 - 2008) Casualty Report Form" },
        { name: "Bahamas Form RBD - 1(02 - 2008) Return of Deaths" },
        { name: "LIBERIA" },
        { name: "Liberia Form RLM - 109, Report of Vessel Casualty or Accident(Rev. 9 / 07)" },
        { name: "Liberia Form RLM - 109 - 1, Report of Personal Injury or Loss of Life(Rev. 12 / 09)" },
        { name: "PANAMA" },
        { name: "Panama No.de Control F - ISAM - 01 - 01(Version 0) Preliminary Casualty Report" },
        { name: "SINGAPORE" },
        { name: "Singapore Report of a Shipping Casualty Form" },
        { name: "UNITED STATES" },
        { name: "United States Form CG - 2692, “Report of Marine Accident, Injury or Death" },
        { name: "United States Form CG - 2692B, “Report of Required Drug and Alcohol Testing Following a Serious Marine Incident" }
      ]
    },
    {
      name: 'Drilling Operations', children: [
        { name: 'Introduction' },
        { name: 'Permanent drilling instructions' },
        { name: 'Crew handover at crew change' },
        { name: 'Pre-tour drilling checklist' },
        { name: 'Avoiding crown block damage (Crown-o-matic)' },
        { name: 'Blind/shear ram operations' },
        { name: 'Casing conductor cutting procedure' },
        { name: 'Caustic soda handling procedure' },
        { name: 'Control of high pressure hoses/chicksans' },
        { name: 'Derrickman’s procedures' },
        { name: 'Drifting tubulars' },
        { name: 'Drill string make-up torque using manual tongs' },
        { name: 'Fishing with Bowen FS-150 overshot' },
        { name: 'Function and testing of diverter' },
        { name: 'Grating control and change out procedure' },
        { name: 'Handling BOPs – 13-5/8”' },
        { name: 'Handling bottom hole assemblies' },
        { name: 'Handling of tubulars on deck' },
        { name: 'High pressure pump' },
        { name: 'Hydril and Koomey filling procedure' },
        { name: 'Install Kelly in Kelly bushing' },
        { name: 'Installing/removing flare booms' },
        { name: 'Installing/removing Texas deck' },
        { name: 'Jarring operation' },
        { name: 'Lay out and pick up top drive ' },
        { name: 'LSA scale handling' },
        { name: 'Making a connection with top drive' },
        { name: 'Making mousehole connections' },
        { name: 'Milling casing and handling swarf' },
        { name: 'Mixing sacks/drums of chemicals' },
        { name: 'Mud pit entry and cleaning' },
        { name: 'Mud tank sampling' },
        { name: 'Nippling up and nippling Christmas tree' },
        { name: 'Nippling up and nippling down BOPs' },
        { name: 'Operation and care of auto slips ' },
        { name: 'Operation and care of manual elevators ' },
        { name: 'Operation and care of manual rig tongs' },
        { name: 'Operation and care of manual slips ' },
        { name: 'Operation and care of pipe spinning wrench ' },
        { name: 'Operation and replacement of shale shakers' },
        { name: 'Operation of single joint elevators and power tongs' },
        { name: 'Operation of stabbing board' },
        { name: 'Operation of the Iron Roughneck' },
        { name: 'Operation of top drive pipe handler' },
        { name: 'Pick up/lay down Kelly' },
        { name: 'Picking up and laying out drill pipe in singles ' },
        { name: 'Preparation of tubulars for backload' },
        { name: 'Pressure testing BOP and choke manifold' },
        { name: 'Raising/lowering of raw water tower' },
        { name: 'Removal/installation of control clamps ' },
        { name: 'Rigging up surface line and cement job preparation ' },
        { name: 'Rigging up coiled tubing' },
        { name: 'Rigging up/down chicksans' },
        { name: 'Rigging up/down electric wire line' },
        { name: 'Rigging up/down slick line ' },
        { name: 'Run and pull wear bushing' },
        { name: 'Running casing' },
        { name: 'Running in the hole' },
        { name: 'Running/pulling completion string ' },
        { name: 'Signaling from and to the stabbing board procedure' },
        { name: 'Skidding cantilever and rig package' },
        { name: 'Slip and cut drill line ' },
        { name: 'Sounding bulk tanks' },
        { name: 'Stripping operations' },
        { name: 'Tailing tubulars and equipment in/out of drill floor' },
        { name: 'Tripping in/out of the hole' },
        { name: 'Unreeving drilling line prior to lower traveling block and crown block' },
        { name: 'Use of Swaco degasser' },
        { name: 'Use of utility winches' },
        { name: 'Well control procedure' },
        { name: 'Wireline procedure ' },
        { name: 'Working inside bulk silos' },
        { name: 'Care and maintenance of mud pump fluid ends' },
        { name: 'Change out BOP Ram and Packer' },
        { name: 'Change out drill line spool' },
        { name: 'Change out top drive IBOP' },
        { name: 'Changing diaphragm of K-series dampener' },
        { name: 'Changing of elevator ' },
        { name: 'Changing of elevator links ' },
        { name: 'Changing out winch wires' },
        { name: 'Changing washpipe packing ' },
        { name: 'Drawworks brake adjustment ' },
        { name: 'Greasing crown block' },
        { name: 'Greasing travelling block' },
        { name: 'Installation and removal of master bushing and inserts ' },
        { name: 'Installation/removal 29-1/2” diverter' },
        { name: 'Installation/removal of bell nipple' },
        { name: 'Installation/removal riser' },
        { name: 'Lowering crown block' },
        { name: 'Replacing/reeving on new drilling line ' },
        { name: 'Top drive speed and torque settings' },
        { name: 'Working on Koomey units procedure' },
        { name: 'GOP Drilling operation BOP Storage' }
      ]
    },
    {
      name: 'Human Resources', children: [
        { name: 'Personnel Responsibilities' },
        { name: 'Lifting Equipment' },
        { name: 'Safe Lifting Techniques' },
        { name: 'Guidelines for Slinging' },
        { name: 'Manriding' },
        { name: 'Banksman Guidelines' },
        { name: 'Documentation' },
        { name: 'Tubular Load Chart Guides' },
        { name: 'Beam Clamps' },
        { name: 'Beam Trolleys' },
        { name: 'BOP, Gantry, and Related Lifting Appliances' },
        { name: 'Chain Hoists' },
        { name: 'Containers, Tanks, Etc.' },
        { name: 'Cranes' },
        { name: 'Drill Line' },
        { name: 'Eyebolts' },
        { name: 'Forklifts' },
        { name: 'Handling Barrels' },
        { name: 'Handling Flexible Intermediate Bulk Containers (FIBC)' },
        { name: 'Handling Rig Floor Equipment' },
        { name: 'Hooks' },
        { name: 'Hydraulic Jacks / Rams' },
        { name: 'Pad-eyes' },
        { name: 'Personnel Elevators' },
        { name: 'Plate Clamps' },
        { name: 'Shackles' },
        { name: 'Sheave Blocks' },
        { name: 'Spider Baskets' },
        { name: 'Synthetic Fiber Slings' },
        { name: 'Tugger Winches' },
        { name: 'Turnbuckles' },
        { name: 'Wire' },
        { name: 'Wire Rope Clamps / Grips' },
        { name: 'Wire Rope Slings' },
        { name: 'Work Baskets' }
      ]
    },
    {
      name: 'Material Management', children: [
        { name: 'Doc' }
      ]
    },
    {
      name: 'Maintenance', children: [
        { name: 'Doc' }
      ]
    },
    {
      name: 'Engineering', children: [
        { name: 'Doc' }
      ]
    },
    {
      name: 'Accounting', children: [
        { name: 'Doc' }
      ]
    }

  ]
}