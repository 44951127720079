import ReactToPrint from 'react-to-print'
import { Button, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import { useRef } from 'react'

import PrintIsolationsTable from './PrintIsolationsTable'
import PrintPermitHeader from './PrintPermitHeader'
import ViewPBUReadings from '../previews/ViewPBUReadings'
import { permitColor, rowStyle } from '../ViewAndApprovePermit'
import { PermitHeader } from '../../pages/WaitingForPIC'
import { PrintHistory } from './PrintHistory'
import { renderRow } from '../../pages/VerifyCM'
import './../css/a4print.css'
import './../css/print.css'
import { PrintHeaderLogos } from './PrintHeaderLogos'

export default function PrintIsolations({
  isOpen,
  toggle,
  printData,
  setPrint
}: any) {
  const componentRef: any = useRef()
  const permitData = printData?.permit

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} fullscreen>
        <ModalBody>
          <div ref={componentRef} style={{ paddingLeft: 10, paddingRight: 10 }}>
            <div>
              <PrintHeaderLogos permitType={printData?.permit?.type} />
              <PrintPermitHeader
                permitData={permitData}
                printData={printData}
              />
            </div>
            <div>
              <Row>
                <PrintIsolationsTable
                  data={printData?.permit?.isolations?.isolations}
                  permitData={printData?.permit}
                  rowStyle={{
                    ...rowStyle,
                    backgroundColor: permitColor(printData?.permit?.type)
                  }}
                  renderRow={renderRow}
                />
              </Row>
            </div>
            <div>
              <PrintHistory
                data={printData?.isolationHistory}
                title="Isolations Approval History"
                permitColor={permitColor(printData?.permit?.type)}
              />
            </div>
            {printData?.pbuData?.length > 0 && printData?.status === 'live' && (
              <ViewPBUReadings data={printData?.pbuData} rowStyle={rowStyle} />
            )}
            <div style={{ textAlign: 'center' }}>
              <img
                alt="e73"
                src={require('./../../../src/images/e73_logo.jpeg')}
                height={80}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={() => setPrint(!isOpen)}>
            Back
          </Button>
          <ReactToPrint
            documentTitle={`${permitData?.woNumber}-ISOLATIONS`}
            pageStyle={'padding: 20'}
            trigger={() => <Button color="warning" children="Print" />}
            content={() => componentRef.current}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}
