import axios from 'axios'
import moment from 'moment'
import { Button } from 'reactstrap'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { useContext, useState } from 'react'

import CustomLabel from '../components/InputFields/CustomLabel'
import LabledParagraphInput from '../components/InputFields/LabledParagraph'
import MultiSelect from '../components/InputFields/MultiSelect'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../components/Modals/SubmitModalWithForm'
import { getOptions } from './firs'
import { UserContext } from '../App'
import { useMsal } from '@azure/msal-react'
import { ticketApi } from '../components/serverurl'
import { getAccessToken } from '../apiRequests'
import { loginRequest } from '../authConfig'

export default function RaiseTicket() {
  const { userData } = useContext(UserContext)
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const [attachments, setAttachments]: any = useState()
  const [submit, setSubmit]: any = useState()

  const img = require('./../images/cloud.jpeg')

  const cmmsOptions = [
    'Work Management',
    'Inventory Management',
    'Purchase Management',
    'Vendor Management',
    'Crew Management',
    'Finance Management',
    'KPI Management',
    'Others'
  ]

  const firsOptions = [
    'Stage 1',
    'Stage 2',
    'Stage 3',
    'Stage 4',
    'Stage 5',
    'Stage 6',
    'Stage 7',
    'Stage 8',
    'Others'
  ]

  const handleFileUpload = (event: any) => {
    setAttachments(event.target.files)
  }

  return (
    <ProtectedLayout onBack="/">
      <div
        style={{
          backgroundImage: `url(${img})`,
          height: '85vh',
          width: '-webkit-fill-available',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          position: 'relative',
          backgroundBlendMode: 'multiply',
          marginTop: 0
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.6)'
          }}
        >
          <div
            style={{
              margin: '10vh 15vh 10vh 15vh',
              padding: '5vh',
              backgroundColor: '#FFF',
              height: '70vh',
              borderRadius: 10,
              boxShadow:
                'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'
            }}
          >
            <h1 style={{ textAlign: 'center' }}>Raise a Ticket</h1>
            <Form
              onSubmit={(val: any) => console.log(val)}
              render={({ form, values }) => (
                <form>
                  <div
                    style={{
                      margin: 0,
                      padding: '2vh',
                      display: 'flex',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: 'rgba(173, 216, 230, 0.3)',
                        width: '30%',
                        padding: '3vh',
                        borderRadius: 10,
                        boxShadow: '13px 14px 20px 2px rgba(0,0,0,0.1)'
                      }}
                    >
                      <MultiSelect
                        isMulti={false}
                        name="category"
                        label="Category"
                        options={getOptions(['CMMS', 'FIRS'])}
                        occupy={12}
                      />
                      <MultiSelect
                        isMulti={false}
                        name="subCategory"
                        label="Sub Category"
                        options={getOptions([...cmmsOptions, ...firsOptions])}
                        occupy={12}
                      />
                      <MultiSelect
                        isMulti={false}
                        name="priority"
                        label="Priority"
                        options={getOptions([
                          'Crirtical',
                          'High',
                          'Normal',
                          'Low'
                        ])}
                        occupy={12}
                      />
                      <MultiSelect
                        isMulti={false}
                        name="department"
                        label="Department"
                        options={getOptions([
                          'Mechanical',
                          'Production',
                          'Electrical',
                          'Instrument',
                          'Cargo'
                        ])}
                        occupy={12}
                      />
                      <CustomLabel label="Upload Attachment" />
                      <input
                        type="file"
                        name="attachment"
                        accept="application/pdf, image/*"
                        onChange={handleFileUpload}
                        multiple
                      />
                    </div>
                    <div
                      style={{
                        backgroundColor: 'rgba(173, 216, 230, 0.3)',
                        width: '70%',
                        padding: '3vh',
                        marginLeft: '3vh',
                        borderRadius: 10,
                        boxShadow: '13px 14px 20px 2px rgba(0,0,0,0.1)'
                      }}
                    >
                      <LabledParagraphInput
                        label="Subject"
                        name="subject"
                        occupy={12}
                      />
                      <LabledParagraphInput
                        label="Description"
                        name="description"
                        occupy={12}
                      />
                      <LabledParagraphInput
                        label="Steps to reproduce"
                        name="stepsToReproduce"
                        height={'10vh'}
                        occupy={12}
                      />
                      <div
                        style={{
                          paddingTop: '3vh',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Button color="warning" onClick={() => setSubmit(true)}>
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                  <SubmitModalWithForm
                    isOpen={submit}
                    onClose={(val: any) => {
                      if (val) {
                        val && form.reset()
                        setAttachments()
                      }
                      setSubmit(!submit)
                    }}
                    onSubmit={async (e: any) => {
                      e.preventDefault()

                      const formData: any = new FormData()
                      const token = await getAccessToken(
                        loginRequest,
                        accounts,
                        instance
                      )

                      if (attachments) {
                        for (let i = 0; i < attachments.length; i++) {
                          formData.append('attachments', attachments[i])
                        }
                      }

                      formData.append('category', values?.category?.value)
                      formData.append('subCategory', values?.subCategory?.value)
                      formData.append('priority', values?.priority?.value)
                      formData.append('department', values?.department?.value)
                      formData.append('subject', values?.subject)
                      formData.append('description', values?.description)
                      formData.append(
                        'stepsToReproduce',
                        values?.stepsToReproduce
                      )
                      formData.append('userName', userData?.givenName)
                      formData.append('userDepartment', userData?.department)
                      formData.append('createdAt', moment())
                      formData.append('status', 'Created')

                      const res = await axios.post(ticketApi, formData, {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                          Authorization: `Bearer ${token}`
                        }
                      })

                      return res?.data?.status
                    }}
                    size={'md'}
                    header={'Create Ticket'}
                  />
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </ProtectedLayout>
  )
}
