import { PrintHeaderLogos } from '../prints/PrintHeaderLogos'
import PrintIsolationsTable from '../prints/PrintIsolationsTable'
import PrintPermitHeader from '../prints/PrintPermitHeader'

type ViewIsolationsType = {
  data: any
  rowStyle: any
  renderRow: any
  permitData: any
  showPrintHeader: any
}

export default function PrintPermitIsolations({
  data,
  rowStyle,
  permitData,
  showPrintHeader
}: ViewIsolationsType) {
  return (
    <>
      {showPrintHeader && (
        <>
          <PrintHeaderLogos permitType={permitData?.type} />
          <PrintPermitHeader permitData={permitData} printData={permitData} />
        </>
      )}
      <PrintIsolationsTable
        data={data}
        rowStyle={rowStyle}
        permitData={permitData}
      />
    </>
  )
}
