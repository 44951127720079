import { Row } from 'reactstrap'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { IconCard } from '../../components/Dashboard/IconCard'

export default function AdminDashboard() {
  const flows: any = [
    {
      name: 'CREW',
      nav: '/cmms/admin/groups',
      src: require('../../images/Icons/Crew.png')
    },
    {
      name: 'VENDORS',
      nav: '/cmms/admin/vendors',
      src: require('../../images/Icons/vendor.png')
    }
  ]

  return (
    <>
      <ProtectedLayout onBack="/cmms" title="ADMIN">
        <Row style={{ margin: 10, fontSize: 20 }}>
          {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
            <IconCard
              key={`${index}-${name}`}
              name={name}
              nav={nav}
              canView={canView}
              count={count}
              src={src}
            />
          ))}
        </Row>
      </ProtectedLayout>
    </>
  )
}
