import { FiChevronsLeft, FiChevronsRight, FiXCircle } from 'react-icons/fi'
import { RiDashboardLine } from 'react-icons/ri'
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar'
import { PiBoundingBoxLight, PiPrinterThin } from 'react-icons/pi'
import { PiBiohazardThin } from 'react-icons/pi'
import { SiAzurefunctions } from 'react-icons/si'
import { RiFlowChart } from 'react-icons/ri'
import { IoCreateOutline, IoPieChartSharp } from 'react-icons/io5'

import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { Badge } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../serverurl'
import { useQuery } from 'react-query'
import { FaCubes } from 'react-icons/fa'
import { TbDatabaseSearch, TbHierarchy3 } from 'react-icons/tb'

const ICON_SIZE = 25

export default function RcmSideBar({ counts }: { counts?: any }) {
  const [collapsed, setCollapsed] = useState(false)

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const currentTab = pathname?.split('/')?.[3] || 'dashboard'

  const navigateTo = (route: any) => navigate(`/cmms/rcm/${route}`)

  const { accounts, instance } = useMsal()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/counts`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchRcmCounts', fetchRcm)

  const { Stage2, Stage3, Stage4, Stage5, Stage6, Stage7 } =
    data?.countsByStatus || {}

  const NavItem = ({
    route,
    label,
    icon,
    count
  }: {
    route: any
    label: any
    icon: any
    count: any
  }) => {
    const [hover, setHover]: any = useState(false)

    const styles = {
      normal: {
        backgroundColor: currentTab === route ? 'gray' : '#FAFAD2',
        fontWeight: 600,
        color: currentTab === route ? '#FFF' : '#000',
        padding: 5,
        paddingLeft: 15
      },
      hover: {
        background: '#D7D7D7',
        fontWeight: 600,
        padding: 5,
        paddingLeft: 15
      }
    }

    return (
      <MenuItem
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={hover ? styles.hover : styles.normal}
        onClick={() => navigateTo(route)}
        icon={icon}
        suffix={
          count && (
            <Badge variant="danger" shape="circle" color="danger">
              {count}
            </Badge>
          )
        }
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            fontSize: 18
          }}
        >
          {label}
        </div>
      </MenuItem>
    )
  }

  const routes = [
    {
      route: 'riskmatrix',
      label: 'Risk Matrix',
      icon: <FaCubes size={ICON_SIZE} />
    },
    {
      route: 'assetcriticality',
      label: 'Criticality',
      icon: <PiPrinterThin size={ICON_SIZE} />
      // count: Stage7
    },
    {
      route: 'hierarchy',
      label: 'Hierarchy',
      icon: <TbHierarchy3 size={ICON_SIZE} />
    },
    {
      route: 'kpi',
      label: 'KPI',
      icon: <IoPieChartSharp size={ICON_SIZE} />
    },
    {
      route: 'createAnalysis',
      label: 'RCM Analysis',
      icon: <IoCreateOutline size={ICON_SIZE} />
    },
    {
      route: 'stage2',
      label: 'Boundary',
      icon: <PiBoundingBoxLight size={ICON_SIZE} />
      // count: Stage2
    },
    {
      route: 'stage3',
      label: 'Function',
      icon: <SiAzurefunctions size={ICON_SIZE} />
      // count: Stage3
    },
    {
      route: 'stage4',
      label: 'Func Failures',
      icon: <FiXCircle size={ICON_SIZE} />
      // count: Stage4
    },
    {
      route: 'stage5',
      label: 'Risk',
      icon: <PiBiohazardThin size={ICON_SIZE} />
      // count: Stage5
    },
    {
      route: 'stage6',
      label: 'Assign Task',
      icon: <RiFlowChart size={ICON_SIZE} />
      // count: Stage6
    },
    {
      route: 'stage7',
      label: 'Task Decision',
      icon: <PiPrinterThin size={ICON_SIZE} />
      // count: Stage7
    },
    {
      route: 'stage8',
      label: 'Our Recommend',
      icon: <PiPrinterThin size={ICON_SIZE} />
      // count: Stage7
    },
    {
      route: 'stage9',
      label: 'Task Approval',
      icon: <PiPrinterThin size={ICON_SIZE} />
      // count: Stage7
    },
    {
      route: 'print',
      label: 'Approved RCM',
      icon: <PiPrinterThin size={ICON_SIZE} />
      // count: Stage7
    },
    {
      route: 'search',
      label: 'Search',
      icon: <TbDatabaseSearch size={ICON_SIZE} />
    }
    // {
    //   route: 'sytems',
    //   label: 'Systems',
    //   icon: <SlScreenDesktop size={ICON_SIZE} />
    // },
    // {
    //   route: 'floc',
    //   label: 'F Location',
    //   icon: <TbLocation size={ICON_SIZE} />,
    //   count: counts?.floc
    // },
    // {
    //   route: 'functionFailure',
    //   label: 'Function Failure',
    //   icon: <FiXCircle size={ICON_SIZE} />
    // },
    // {
    //   route: 'equipment',
    //   label: 'Equipment',
    //   icon: <RiToolsLine size={ICON_SIZE} />
    // },
    // {
    //   route: 'analysis',
    //   label: 'Analysis',
    //   icon: <IoAnalyticsSharp size={ICON_SIZE} />
    // },
    // {
    //   route: 'maintenanceLibrary',
    //   label: 'Maint. Library',
    //   icon: <IoLibraryOutline size={ICON_SIZE} />
    // },
    // {
    //   route: 'conditionHistory',
    //   label: 'Condition History',
    //   icon: <TbHistoryToggle size={ICON_SIZE} />
    // },
    // {
    //   route: 'customReports',
    //   label: 'Custom reports',
    //   icon: <HiOutlineDocument size={ICON_SIZE} />
    // },
    // {
    //   route: 'failureModes',
    //   label: 'Failure Modes',
    //   icon: <FiXCircle size={ICON_SIZE} />
    // },
    // {
    //   route: 'fmea',
    //   label: 'FMEA',
    //   icon: <FiInfo size={ICON_SIZE} />
    // },
    // {
    //   route: 'decissionChart',
    //   label: 'Descission Chart',
    //   icon: <RiOrganizationChart size={ICON_SIZE} color="#000" />
    // }
  ]

  return (
    <>
      <div
        // onMouseEnter={() => setCollapsed(false)}
        // onMouseLeave={() => setCollapsed(true)}
        style={{ height: 'auto', backgroundColor: '#FAFAD2' }}
      >
        <Sidebar
          collapsed={collapsed}
          backgroundColor="#FAFAD2"
          style={{ height: 'auto', backgroundColor: '#FAFAD2' }}
        >
          <Menu style={{ marginTop: 10 }}>
            {collapsed ? (
              <MenuItem
                icon={<FiChevronsRight />}
                onClick={() => setCollapsed(false)}
              ></MenuItem>
            ) : (
              <MenuItem
                suffix={<FiChevronsLeft />}
                onClick={() => setCollapsed(true)}
              >
                <div
                  style={{
                    padding: '9px',
                    // textTransform: "uppercase",
                    fontWeight: 900,
                    fontSize: 20,
                    letterSpacing: '1px'
                  }}
                >
                  RCM MENU
                </div>
              </MenuItem>
            )}
            {routes?.map((r: any) => (
              <NavItem
                key={r?.route}
                route={r?.route}
                label={r?.label?.toUpperCase()}
                icon={r?.icon}
                count={r?.count}
              />
            ))}
          </Menu>
        </Sidebar>
      </div>
    </>
  )
}
