import React from 'react'
import { Col, Row } from 'reactstrap'
import { Text } from './RiskAssesmentPreview'

export const renderTitle = (title: string) => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <Text>
      <b>{title}</b>
    </Text>
    <Text>:</Text>
  </div>
)

export default function PermitHeaderDetails({ permit }: any) {
  const rowStyle: any = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 16,
    WebkitPrintColorAdjust: 'exact',
    backgroundColor: '#fefaa2'
  }

  return (
    <Row style={{ ...rowStyle, fontSize: 14, margin: 0 }}>
      {Object.keys(permit)?.map((key: any, index: number) => (
        <React.Fragment key={index}>
          <Col xs="3">{renderTitle(key)}</Col>
          <Col xs="9">{permit[key]}</Col>
        </React.Fragment>
      ))}
    </Row>
  )
}
