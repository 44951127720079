import { Col, Row } from 'reactstrap'
import { useContext } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { UserContext } from '../App'
import { makeAuthenticatedRequest } from '../apiRequests'
import { alEquipmentApi, workOrderAPI } from '../components/serverurl'
import AshokLeylandLayout from './AshokLeylandLayout'
import { Box } from '../pages/workManagement/PmTree'
import { IconCard } from '../components/Dashboard/IconCard'

export default function AlWoActions() {
  const { accounts, instance } = useMsal()

  const fetchReport = async () =>
    await makeAuthenticatedRequest(
      `${alEquipmentApi}/workorders/counts`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data }: any = useQuery('Al PM Work Order Counts', fetchReport)
  const counts = data?.data?.countsByStatus

  const flows: any = [
    {
      name: 'CREATE WORK REQUEST',
      // nav: '/cmms/workRequests/create',
      nav: '/ashokleyland/mms/maintenance/create',
      src: require('./../images/Icons/CreateWorkRequest.png'),
      level: 1
    },
    {
      name: 'CREATED WORK REQUEST',
      nav: '/ashokleyland/mms/maintenance/created',
      src: require('./../images/Icons/CreatedWorkRequest.png'),
      count: counts?.Created,
      level: 1
    },
    {
      name: 'CREATED WORK ORDER',
      nav: '/ashokleyland/mms/maintenance/wocreated',
      src: require('./../images/Icons/CreatedWorkOrder.png'),
      count: counts?.['Workorder Created'],
      level: 1
    },
    {
      name: 'TODO',
      nav: '/ashokleyland/mms/maintenance/todo',
      count: counts?.Todo,
      src: require('./../images/Icons/Todo.png'),
      level: 3
    },
    {
      name: 'INPROGESS',
      nav: '/ashokleyland/mms/maintenance/inprogress',
      count: counts?.['In Progress'],
      src: require('./../images/Icons/In-Progress.png'),
      level: 3
    },
    {
      name: 'WAITING FOR MATERIAL',
      nav: '/ashokleyland/mms/maintenance/waitingformaterial',
      src: require('./../images/Icons/Waiting for Material.png'),
      count: counts?.['Waiting For Material'],
      level: 3
    },
    {
      name: 'WAITING FOR 3RD PARTY',
      nav: '/ashokleyland/mms/maintenance/waitingfor3rdparty',
      src: require('./../images/Icons/Waiting for Vendor.png'),
      count: counts?.['Waiting For 3rd Party'],
      level: 3
    },
    {
      name: 'WAITING FOR SHUTDOWN',
      nav: '/ashokleyland/mms/maintenance/waitingforshutdown',
      src: require('./../images/Icons/Waiting for Shutdown.png'),
      count: counts?.['Waiting For Shutdown'],
      level: 3
    },
    {
      name: 'CLOSED',
      nav: '/ashokleyland/mms/maintenance/closed',
      count: counts?.Closed,
      src: require('./../images/Icons/Closed.png'),
      level: 4
    },
    {
      name: 'CANCELLED WORK ORDER',
      // nav: '/cmms/workorders/cancelledWorkOrders',
      // count: getcount('cancelled'),
      src: require('./../images/Icons/cancelWO.png'),
      level: 4
    }
  ]

  return (
    <>
      <AshokLeylandLayout onBack="/ashokLeyland/mms" title="Maintenance">
        <Row style={{ margin: 10 }}>
          <Row style={{ display: 'flex' }}>
            <Col xs="4" style={{ marginTop: '5vh' }}>
              <Box heading="Work Request" headingCenter>
                <Col style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {flows
                    ?.filter((f: any) => f?.level === 1)
                    .map(
                      ({ name, nav, count, canView, src }: any, index: any) => (
                        <IconCard
                          key={`${index}-${name}`}
                          name={name}
                          nav={nav}
                          count={count}
                          src={src}
                        />
                      )
                    )}
                </Col>
              </Box>
            </Col>
            <Col xs="5" style={{ marginTop: '15vh' }}>
              <Box heading="Work Order In Action" headingCenter>
                <Col style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {flows
                    ?.filter((f: any) => f?.level === 3)
                    .map(
                      ({ name, nav, count, canView, src }: any, index: any) => (
                        <IconCard
                          key={`${index}-${name}`}
                          name={name}
                          nav={nav}
                          count={count}
                          src={src}
                        />
                      )
                    )}
                </Col>
              </Box>
            </Col>
            <Col xs="3" style={{ marginTop: '25vh' }}>
              <Box heading="Work Order Completed" headingCenter>
                <Col
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center'
                  }}
                >
                  {flows
                    ?.filter((f: any) => f?.level === 4)
                    .map(
                      ({ name, nav, count, canView, src }: any, index: any) => (
                        <IconCard
                          key={`${index}-${name}`}
                          name={name}
                          nav={nav}
                          count={count}
                          src={src}
                        />
                      )
                    )}
                </Col>
              </Box>
            </Col>
          </Row>
        </Row>
      </AshokLeylandLayout>
    </>
  )
}
