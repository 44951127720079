function getTotal(condition: any, count: any, totalCount: any) {
  if (condition) {
    totalCount += count
  }
  return totalCount || 0
}

export function getNewPermitCounts(permissions: any, newPermitData: any) {
  let totalCount = 0

  if (!newPermitData || !permissions) {
    return 0
  }

  const {
    approve,
    finishIsolation,
    issue,
    makeLiveIsolation,
    startIsloation,
    // updateRiskAssesment,
    verify
  } = newPermitData

  const {
    // canUpdateRisk,
    canVerify,
    canIssue,
    canApprove,
    canViewIsolations,
    canCreate
  } = permissions

  // totalCount = getTotal(canUpdateRisk, updateRiskAssesment, totalCount)
  totalCount = getTotal(canVerify, verify, totalCount)
  totalCount = getTotal(canIssue, issue, totalCount)
  totalCount = getTotal(canApprove, approve, totalCount)
  totalCount = getTotal(canViewIsolations, startIsloation, totalCount)
  totalCount = getTotal(canViewIsolations, finishIsolation, totalCount)
  totalCount = getTotal(canViewIsolations, makeLiveIsolation, totalCount)
  totalCount = getTotal(canCreate, newPermitData?.makeLive, totalCount)

  return totalCount || 0
}

export function getrevalidateCounts(permissions: any, newPermitData: any) {
  let totalCount = 0

  if (!newPermitData || !permissions) {
    return 0
  }

  const { approve, issue, verify, makeLive } = newPermitData

  const {
    canIssueRevalidation,
    canVerifyRevalidation,
    canApproveReIsolation,
    canInitiateRevalidation
  } = permissions

  totalCount = getTotal(canVerifyRevalidation, verify, totalCount)
  totalCount = getTotal(canIssueRevalidation, issue, totalCount)
  totalCount = getTotal(canApproveReIsolation, approve, totalCount)
  totalCount = getTotal(canInitiateRevalidation, makeLive, totalCount)

  return totalCount || 0
}

export function getDICCounts(permissions: any, countsData: any) {
  let totalCount = 0

  if (!countsData || !permissions) {
    return 0
  }

  const { approve, issue, verify, finish, close, start } = countsData

  const {
    canVerifyDeIsolationAndClose,
    canApproveDeIsolationAndClose,
    canIssueDeIsolationAndClose,
    canStartDeIsolationAndClose,
    canFinishDeIsolationAndClose,
    canCloseDeIsolationAndClose
  } = permissions

  totalCount = getTotal(canVerifyDeIsolationAndClose, verify, totalCount)
  totalCount = getTotal(canApproveDeIsolationAndClose, approve, totalCount)
  totalCount = getTotal(canIssueDeIsolationAndClose, issue, totalCount)
  totalCount = getTotal(canStartDeIsolationAndClose, start, totalCount)
  totalCount = getTotal(canFinishDeIsolationAndClose, finish, totalCount)
  totalCount = getTotal(canCloseDeIsolationAndClose, close, totalCount)

  return totalCount || 0
}

export function getLTICounts(permissions: any, countsData: any) {
  let totalCount = 0

  if (!countsData || !permissions) {
    return 0
  }

  const { approve, issue, verify, makeLive } = countsData

  const { canIssueLTI, canVerifyLTI, canApproveLTI, canMakeLiveLTI } =
    permissions

  totalCount = getTotal(canVerifyLTI, verify, totalCount)
  totalCount = getTotal(canIssueLTI, issue, totalCount)
  totalCount = getTotal(canApproveLTI, approve, totalCount)
  totalCount = getTotal(canMakeLiveLTI, makeLive, totalCount)

  return totalCount || 0
}

export function getCIOCounts(permissions: any, newPermitData: any) {
  let totalCount = 0

  if (!newPermitData || !permissions) {
    return 0
  }

  const { approve, issue, verify, makeLive } = newPermitData

  const { canVerifyCIO, canIssueCIO, canApproveCIO, canMakeLiveCIO } =
    permissions

  totalCount = getTotal(canVerifyCIO, verify, totalCount)
  totalCount = getTotal(canIssueCIO, issue, totalCount)
  totalCount = getTotal(canApproveCIO, approve, totalCount)
  totalCount = getTotal(canMakeLiveCIO, makeLive, totalCount)

  return totalCount || 0
}

export function getWOICounts(permissions: any, newPermitData: any) {
  let totalCount = 0

  if (!newPermitData || !permissions) {
    return 0
  }

  const { approve, issue, verify, makeLive } = newPermitData

  const { canVerifyWOI, canIssueWOI, canApproveWOI, canCreateWOI } = permissions

  totalCount = getTotal(canVerifyWOI, verify, totalCount)
  totalCount = getTotal(canIssueWOI, issue, totalCount)
  totalCount = getTotal(canApproveWOI, approve, totalCount)
  totalCount = getTotal(canCreateWOI, makeLive, totalCount)

  return totalCount || 0
}

export function getCWOICounts(permissions: any, newPermitData: any) {
  let totalCount = 0

  if (!newPermitData || !permissions) {
    return 0
  }

  const { approve, issue, verify } = newPermitData

  const { canVerifyCWOI, canIssueCWOI, canApproveCWOI } = permissions

  totalCount = getTotal(canVerifyCWOI, verify, totalCount)
  totalCount = getTotal(canIssueCWOI, issue, totalCount)
  totalCount = getTotal(canApproveCWOI, approve, totalCount)

  return totalCount || 0
}
