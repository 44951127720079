import React from 'react'
import { Row } from 'reactstrap'
import { ColoredSubHeader } from '../previews/ViewExcavationPermit'
import PrintRiskHeader from './PrintRiskHeader'
import Spacer from '../Spacer'
import PrintJSA from './PrintJSA'
import PrintQRA from './PrintQRA'

type PrintRiskAssesmentType = {
  data: any
  rowStyle: any
  renderRow: any
  permitData: any
}

export default function PrintRiskAssessment({
  data,
  rowStyle,
  renderRow,
  permitData
}: PrintRiskAssesmentType) {
  const header: any = {
    'Risk Assessment Number': permitData?.riskAssesmentPermit?.permitNumber
    // 'Risk Assessment Template Number': data?.templateNumber,
    // 'Risk Assessment Type': permitData?.isJSA,
    // 'Permit Number': permitData?.woNumber,
    // 'Permit Title': permitData?.permitTitle
  }

  return (
    <>
      <ColoredSubHeader rowStyle={rowStyle} text="Risk Assessment" />
      {permitData?.woNumber && (
        <Row
          style={{
            ...rowStyle,
            fontSize: 14,
            margin: 0
          }}
        >
          {Object.keys(header).map((key) => renderRow(key, header[key]))}
        </Row>
      )}
      {!permitData?.woNumber && (
        <Row
          style={{
            ...rowStyle,
            fontSize: 14
          }}
        >
          {renderRow('Template Number', data?.templateNumber)}
        </Row>
      )}
      <Spacer height={10} />
      <PrintRiskHeader risk={data} />
      {permitData?.isJSA === 'Non Critical' && <PrintJSA risk={data} />}
      {permitData?.isJSA === 'Critical' && (
        <PrintQRA template={data} showSelect={false} />
      )}
      <Spacer height={10} />
    </>
  )
}
