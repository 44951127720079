import { Col, Row } from 'reactstrap'
import { useContext } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { IconCard } from '../../components/Dashboard/IconCard'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { UserContext } from '../../App'
import { workOrderAPI } from '../../components/serverurl'
import { Box } from './PmTree'

const hasAccess: any = {
  canCreate: ['Production Tech', 'MECHANICAL ENGINEER'],
  canCreateWO: ['MECHANICAL ENGINEER'],
  canApprove: ['Maintenance Supt'],
  canViewCancel: ['MECHANICAL ENGINEER'],
  canTodo: ['MECHANICAL ENGINEER'],
  canWFM: ['MECHANICAL ENGINEER'],
  canWFS: ['MECHANICAL ENGINEER'],
  canWFT: ['MECHANICAL ENGINEER'],
  canInprogress: ['MECHANICAL ENGINEER'],
  canClosed: ['MECHANICAL ENGINEER']
}

export default function CorrectiveMaintenanceDashboard() {
  const { userData } = useContext(UserContext)

  const { accounts, instance } = useMsal()

  const fetchWorkRequests = async () => {
    const response = await makeAuthenticatedRequest(
      `${workOrderAPI}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data }: any = useQuery('workRequestsALLLL', fetchWorkRequests)

  const workOrders = data?.workrequests

  const getcount = (status: any) => {
    const list = workOrders?.filter((wo: any) => wo?.woStatus === status)

    return list?.length
  }

  const flows: any = [
    {
      name: 'CREATE WORK REQUEST',
      nav: '/cmms/workRequests/create',
      canView: hasAccess?.['canCreate']?.includes(userData?.jobTitle),
      src: require('../../images/Icons/CreateWorkRequest.png'),
      level: 1
    },
    {
      name: 'DRAFT WORK REQUEST',
      nav: '/cmms/workRequests/draft',
      src: require('../../images/Icons/Draft Work Request.png'),
      count: getcount('wrDraft'),
      level: 1
    },
    {
      name: 'CREATED WORK REQUEST',
      nav: '/cmms/workRequests/created',
      src: require('../../images/Icons/CreatedWorkRequest.png'),
      count: getcount('wrCreated'),
      level: 1
    },
    {
      name: 'DRAFT WORK ORDER',
      nav: '/cmms/workorders/draft',
      canView: hasAccess?.['canApprove']?.includes(userData?.jobTitle),
      src: require('../../images/Icons/Draft Work Order.png'),

      count: getcount('woDraft'),
      level: 1
    },
    {
      name: 'CREATED WORK ORDER',
      nav: '/cmms/workorders/created',
      canView: hasAccess?.['canApprove']?.includes(userData?.jobTitle),
      src: require('../../images/Icons/CreatedWorkOrder.png'),
      count: getcount('woCreated'),
      level: 1
    },
    {
      name: 'TODO',
      nav: '/cmms/workorders/todo',
      canView: hasAccess?.['canTodo']?.includes(userData?.jobTitle),
      count: getcount('todo'),
      src: require('../../images/Icons/Todo.png'),
      level: 3
    },
    {
      name: 'INPROGESS',
      nav: '/cmms/workorders/inProgress',
      canView: hasAccess?.['canInprogress']?.includes(userData?.jobTitle),
      count: getcount('inProgress'),
      src: require('../../images/Icons/In-Progress.png'),
      level: 3
    },
    {
      name: 'WAITING FOR MATERIAL',
      nav: '/cmms/workorders/waitingForMaterial',
      canView: hasAccess?.['canWFM']?.includes(userData?.jobTitle),
      src: require('../../images/Icons/Waiting for Material.png'),
      level: 3
    },
    {
      name: 'WAITING FOR 3RD PARTY',
      nav: '/cmms/workorders/waitingFor3Party',
      canView: hasAccess?.['canWFT']?.includes(userData?.jobTitle),
      src: require('../../images/Icons/Waiting for Vendor.png'),
      level: 3
    },
    {
      name: 'WAITING FOR SHUTDOWN',
      nav: '/cmms/workorders/waitingForShutdown',
      canView: hasAccess?.['canWFS']?.includes(userData?.jobTitle),
      src: require('../../images/Icons/Waiting for Shutdown.png'),
      level: 3
    },
    {
      name: 'CLOSED',
      nav: '/cmms/workorders/closed',
      canView: hasAccess?.['canClosed']?.includes(userData?.jobTitle),
      count: getcount('closed'),
      src: require('../../images/Icons/Closed.png'),
      level: 4
    },
    {
      name: 'CANCELLED WORK ORDER',
      nav: '/cmms/workorders/cancelledWorkOrders',
      canView: hasAccess?.['canViewCancel']?.includes(userData?.jobTitle),
      count: getcount('cancelled'),
      src: require('../../images/Icons/cancelWO.png'),
      level: 4
    }
  ]

  return (
    <>
      <ProtectedLayout onBack="/cmms" title="Maintenance">
        <Row style={{ margin: 10 }}>
          <Row style={{ display: 'flex' }}>
            <Col xs="4" style={{ marginTop: '5vh' }}>
              <Box heading="Work Request" headingCenter>
                <Col style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {flows
                    ?.filter((f: any) => f?.level === 1)
                    .map(
                      ({ name, nav, count, canView, src }: any, index: any) => (
                        <IconCard
                          key={`${index}-${name}`}
                          name={name}
                          nav={nav}
                          count={count}
                          src={src}
                        />
                      )
                    )}
                </Col>
              </Box>
            </Col>
            <Col xs="5" style={{ marginTop: '15vh' }}>
              <Box heading="Work Order In Action" headingCenter>
                <Col style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {flows
                    ?.filter((f: any) => f?.level === 3)
                    .map(
                      ({ name, nav, count, canView, src }: any, index: any) => (
                        <IconCard
                          key={`${index}-${name}`}
                          name={name}
                          nav={nav}
                          count={count}
                          src={src}
                        />
                      )
                    )}
                </Col>
              </Box>
            </Col>
            <Col xs="3">
              <div className="align-content-center text-center">
                <img
                  src={require('./../../images/Icons/workrequesrQrCode.png')}
                  style={{ width: '15vw' }}
                  alt="tshe card qr"
                />
                <h4>Scan here to create Work Request</h4>
              </div>
              <Box heading="Work Order Completed" headingCenter>
                <Col
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center'
                  }}
                >
                  {flows
                    ?.filter((f: any) => f?.level === 4)
                    .map(
                      ({ name, nav, count, canView, src }: any, index: any) => (
                        <IconCard
                          key={`${index}-${name}`}
                          name={name}
                          nav={nav}
                          count={count}
                          src={src}
                        />
                      )
                    )}
                </Col>
              </Box>
            </Col>
          </Row>
        </Row>
      </ProtectedLayout>
    </>
  )
}
