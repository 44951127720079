import defaultTheme from './constants/defaultTheme.json'
import getStatus from './lib/getStatus'

export default function PermitQuickView({ permit, onClick, selectedPermit }: any) {
  const isActive = permit.Id === selectedPermit.Id

  return (
    <div
      style={{
        margin: '0px 10px 10px 10px ',
        padding: 10,
        border: isActive ? `3px solid #fff` : `3px solid #ccc`,
        borderRadius: 10,
        backgroundColor: isActive ? defaultTheme.colors.lightBlue : defaultTheme.colors.lightBlue1,
        color: isActive ? defaultTheme.fontColor.white : defaultTheme.fontColor.lightBlue,
        cursor: 'pointer',
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 1px 2px, rgba(0, 0, 0, 0.3) 0px 2px 3px -3px, rgba(0, 0, 0, 0.2) 0px -2px 0px inset',
      }}
      onClick={() => onClick(permit)}
    >
      <p style={{ margin: 0, fontSize: 14 }}>Permit Number - {permit?.permitNumber}</p>
      <p style={{ margin: 0, fontSize: 14 }}>Permit Title - {permit?.permitTitle}</p>
      <p style={{ margin: 0 }}>Status - <b>{getStatus(permit?.Status)}</b></p>
    </div>
  )
}