import { useMsal } from '@azure/msal-react'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { PermissionsContext, UserContext } from '../../App'
import toNormalCase from '../../components/lib/toNormalCase'
import { countsEndpoint } from '../../components/serverurl'
import ViewIcons from '../../components/ViewIcons'

export default function DICActions() {
  const { permissions } = useContext(PermissionsContext)
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const userlocation = toNormalCase(permissions?.location)

  const permitsCountByflow = async () => {
    const response = await makeAuthenticatedRequest(
      `${countsEndpoint}/dic?department=${userData?.department}&location=${userlocation}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data }: any = useQuery('dicCount', permitsCountByflow)

  const counts = data?.counts

  const icons = [
    {
      name: 'VERIFY (D&C)',
      src: require('./../../images/Icons/Verify_De-Isolation & Close.png'),
      nav: '/eptw/deIsolationAndClose/verify',
      canView: permissions?.canVerifyDeIsolationAndClose,
      count: counts?.verify
    },
    {
      name: 'APPROVE (D&C)',
      src: require('./../../images/Icons/Approve_De-Isolation & Close.png'),
      nav: '/eptw/deIsolationAndClose/approve',
      canView: permissions?.canApproveDIC,
      count: counts?.approve
    },
    {
      name: 'ISSUE (D&C)',
      src: require('./../../images/Icons/Issue_De-Isolation & Close.png'),
      nav: '/eptw/deIsolationAndClose/issue',
      canView: permissions?.canIssueDeIsolationAndClose,
      count: counts?.issue
    },
    {
      name: 'START DE ISOLATION (D&C)',
      src: require('./../../images/Icons/Start_DeIsolation.png'),
      nav: '/eptw/deIsolationAndClose/start',
      canView: permissions?.canStartDeIsolationAndClose,
      count: counts?.start
    },
    {
      name: 'FINISH DE ISOLATION (D&C)',
      src: require('./../../images/Icons/Finish_DeIsolation.png'),
      nav: '/eptw/deIsolationAndClose/finish',
      canView: permissions?.canFinishDeIsolationAndClose,
      count: counts?.finish
    },
    {
      name: 'DEISOLATED (D&C)',
      src: require('./../../images/Icons/Deisolated.png'),
      nav: '/eptw/deIsolationAndClose/close',
      canView: permissions?.canCloseDeIsolationAndClose,
      count: counts?.close
    }
  ]

  return <ViewIcons icons={icons} />
}
