import { useLocation, useNavigate } from 'react-router-dom'

import { HiOutlineSquares2X2, HiSquares2X2 } from 'react-icons/hi2'

import defaultTheme from './../constants/defaultTheme.json'
import toNormalCase from '../lib/toNormalCase'

type MenuItemType = {
  label: string
  navigateTo: string
  base: string
}

export default function DashboardMenuItem({
  label,
  navigateTo,
  base = '/eptw'
}: MenuItemType) {
  const location = useLocation()
  const navigate = useNavigate()
  const currentRoute = location?.pathname
  const baseUrl = currentRoute?.split('/')?.[2]

  const isActive = toNormalCase(baseUrl) === label

  const redirectTo = () => {
    navigate(`${base}${navigateTo}`)
  }

  return (
    <div
      style={{
        border: isActive
          ? `2px solid ${defaultTheme.borderColor.white}`
          : 'none',
        display: 'flex',
        padding: isActive ? 5 : 7,
        backgroundColor: isActive ? defaultTheme.colors.blue : 'transparent',
        borderRadius: 5,
        minWidth: '18vh',
        cursor: 'pointer',
        marginBottom: 35,
        boxShadow:
          'rgba(0, 0, 0, 0.4) 0px 1px 2px, rgba(0, 0, 0, 0.3) 0px 2px 3px -3px, rgba(0, 0, 0, 0.2) 0px -2px 0px inset'
      }}
      onClick={() => redirectTo()}
    >
      {isActive ? (
        <HiSquares2X2
          style={{
            marginTop: 4,
            color: isActive
              ? defaultTheme.colors.white
              : defaultTheme.colors.lightBlue
          }}
        />
      ) : (
        <HiOutlineSquares2X2
          style={{
            marginTop: 4,
            color: isActive
              ? defaultTheme.colors.white
              : defaultTheme.colors.lightBlue
          }}
        />
      )}
      <p
        style={{
          margin: '0px 0px 0px 10px',
          fontWeight: 700,
          color: isActive
            ? defaultTheme.fontColor.white
            : defaultTheme.fontColor.lightBlue
        }}
      >
        {label}
      </p>
    </div>
  )
}
