import { Table } from 'reactstrap'
import { renderTableData } from '../ToolBoxTalkMeeting'

export default function PrintVehivlePermitTalk({}) {
  return (
    <>
      {/* <Table bordered style={{ borderColor: '#000', fontSize: 14 }}>
        <thead>
          <tr>
            <th>2.</th>
            <th colSpan={3}>
              SAFETY REQUIREMENTS FOR VEHICLE (Area Operator is visusally check
              priority to entry)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>a.</td>
            <td>Vehicle / equipment fitted with Spark Arrestor</td>
            <td>Yes</td>
            <td>No</td>
          </tr>
          <tr>
            <td>b.</td>
            <td>Exhaust system in good Condition</td>
            <td>Yes</td>
            <td>No</td>
          </tr>
          <tr>
            <td>c.</td>
            <td>Any oil leaks from vehicle</td>
            <td>Yes</td>
            <td>No</td>
          </tr>
          <tr>
            <td>d.</td>
            <td>Driver aware of the hazards of plant / route mentioned</td>
            <td>Yes</td>
            <td>No</td>
          </tr>
          <tr>
            <td>e.</td>
            <td>Signal man required for Vehicle / equipment</td>
            <td>Yes</td>
            <td>No</td>
          </tr>
        </tbody>
      </Table> */}
      <Table bordered style={{ borderColor: '#000' }}>
        <thead>
          <tr>
            {/* <th>3.</th> */}
            {renderTableData('3')}
            {renderTableData(
              <p style={{ margin: 0, fontSize: 10 }}>
                Atmoshpheric Gas Testing: a) All along the Route / specific
                points along the route. b) Continous / Intermittent / At the
                point
              </p>,
              14
            )}

            {/* <th colSpan={14}>
              Atmoshpheric Gas Testing: a) All along the Route / specific points
              along the route. b) Continous / Intermittent / At the point
            </th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            {/* <td>Time</td> */}
            {renderTableData('Time', 1, 1, 'left')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
          </tr>
          <tr>
            {renderTableData('Result', 1, 1, 'left')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
          </tr>
          <tr>
            {renderTableData('Sig', 1, 1, 'left')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
            {renderTableData('')}
          </tr>
        </tbody>
      </Table>
    </>
  )
}
