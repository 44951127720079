import { Form } from 'react-final-form'
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap'
import {
  doneRequiredOptions,
  RadioButtonGroup,
  yesNoOptions
} from '../../pages/eptw/VerifyPermit'
import LabeledRadioButtons from '../InputFields/LabeledRadioButton'
import LabeledTextInput from '../InputFields/LabeledTextInput'
import ModalTitle from '../ModalTitle'
import Spacer from '../Spacer'

export default function VehiclePermit({
  toggle,
  setAttachedForms,
  attachedForms,
  isOpen
}: any) {
  const validate = (values: any) => {
    const errors: any = {}
    if (!values.rePassagePlant) {
      errors.rePassagePlant = 'Required'
    }

    return errors
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        <ModalTitle title="Vehicle Permit" />
        <Form
          onSubmit={(values: any) => {
            setAttachedForms(values)
            toggle()
          }}
          validate={validate}
          initialValues={attachedForms}
          render={({ form, hasValidationErrors }) => (
            <form>
              <Row>
                <LabeledTextInput
                  name="rePassagePlant"
                  label="Route Entry / Passage / Plant"
                  occupy={12}
                />
                <LabeledTextInput name="purpose" label="Purpose" occupy={12} />
                <LabeledTextInput
                  name="techEscort"
                  label="Technician Escort"
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="vehicleType"
                  label="Type of Vehicle / Equipment"
                  list={[
                    'Hydra',
                    'Fork Lift',
                    'Car',
                    'Crane',
                    'Truck',
                    'Tanker'
                  ]?.map((field: string) => ({ value: field, label: field }))}
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="toolBoxTalk"
                  label="ToolBox Talk Conducted"
                  list={yesNoOptions}
                />
                <LabeledRadioButtons
                  name="recorded"
                  label="Recorded"
                  list={doneRequiredOptions}
                />
                <Spacer height={10} />
                <p style={{ margin: 0 }}>
                  <b>SAFETY REQUIREMENTS FOR VEHICLE : </b>
                  <br /> (Area Operator to visually check prior to entry)
                </p>
                <LabeledRadioButtons
                  name="sparkArrestor"
                  label="Vehicle / equipment fitted with Spark Arrestor"
                  list={yesNoOptions}
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="exhaustSystem"
                  label="Exhaust system in good Condition"
                  list={yesNoOptions}
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="oilLeaks"
                  label="Any oil leaks from vehicle"
                  list={yesNoOptions}
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="driverAware"
                  label="Driver aware of the hazards of plant / route  mentioned"
                  list={yesNoOptions}
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="signalMan"
                  label="Signal man required for vehicle / equipment"
                  list={yesNoOptions}
                  occupy={12}
                />
              </Row>
              <Spacer height={30} />
              <Row>
                <Col xs="12">
                  <Button
                    style={{ width: 'inherit' }}
                    onClick={form.submit}
                    color={hasValidationErrors ? 'secondary' : 'warning'}
                    disabled={hasValidationErrors}
                  >
                    Save & Next
                  </Button>
                  <Spacer height={10} />
                  <Button
                    style={{ width: 'inherit' }}
                    color="warning"
                    onClick={toggle}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </ModalBody>
    </Modal>
  )
}
