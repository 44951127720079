import { useNavigate } from 'react-router-dom'
import { Row, Table } from 'reactstrap'
import { incrementby1 } from '../Forms/JSAForm'
import { capitalizeFirstLetter } from '../lib/capitalizeFirstLetter'

export default function ApprovedRisksTable({ data }: any) {
  const headers = [
    'S.No',
    'Template Number',
    'Activity Task',
    'Location',
    'Department'
  ]

  // const { jsaTemplates, qraTempaltes } = data?.jsatemplates && data
  const jsaTemplates = data?.jsaTemplates
  const qraTemplates = data?.qraTemplates

  const templates = [...jsaTemplates, ...qraTemplates]

  const navigate = useNavigate()

  return (
    <Row style={{ margin: 10 }}>
      <Table
        bordered
        responsive
        style={{ borderColor: '#000', marginTop: 10, fontSize: 14 }}
      >
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            {headers?.map((h: any) => <th key={h}>{h}</th>)}
          </tr>
        </thead>
        <tbody>
          {templates?.map((t: any, index: number) => (
            <tr key={t?.templateNumber}>
              <td>{incrementby1(index)}</td>
              <td
                onClick={() =>
                  navigate(`/eptw/riskAssessmentTemplates/${t?._id}`)
                }
                style={{
                  color: 'blue',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
              >
                {t?.templateNumber}
              </td>
              <td>{t?.task}</td>
              <td>{t?.location}</td>
              <td>{t?.department}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Row>
  )
}
