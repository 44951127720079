import { Row, Table } from 'reactstrap'
import { incrementby1 } from '../../components/Forms/JSAForm'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'

const data = [
  {
    Number: '2024/00001',
    'Job Title': 'Pyrotechnics Monthly Inspection',
    Component: 'A-F3310             ',
    Priority: 2,
    'Resp. Discipline': 'Safety',
    Status: 'To Do (1)',
    'Func. No.': 'VE333-20101   ',
    'Func. Description': 'PS Lifeboat',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'IN-Control Failure',
    'Job Code': 'PM00161A',
    'Planned Start Date ': '02/26/2024'
  },
  {
    Number: '2024/00002',
    'Job Title': 'Pyrotechnics Monthly Inspection',
    Component: 'A-F3320             ',
    Priority: 2,
    'Resp. Discipline': 'Procurement Local',
    Status: 'To Do (1)',
    'Func. No.': 'VE333-20201   ',
    'Func. Description': 'SB Lifeboat',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'IN-Control Failure',
    'Job Code': 'PM00161A',
    'Planned Start Date ': '02/26/2024'
  },
  {
    Number: '2024/00003',
    'Job Title': 'Helideck Fire Pump Test Run',
    Component: 'P-F3102             ',
    Priority: 1,
    'Resp. Discipline': 'Maint E/R',
    Status: 'To Do (1)',
    'Func. No.': 'VE331-01101   ',
    'Func. Description': 'Pump - Fire Water Accom & Helideck',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'MA-Breakage',
    'Job Code': 'PM00655A',
    'Planned Start Date ': '02/27/2024'
  },
  {
    Number: '2024/00004',
    'Job Title': 'Escape Ladders Inspection',
    Component: 'VE333-CSGR-0001_MIS ',
    Priority: 1,
    'Resp. Discipline': 'Cargo',
    Status: 'To Do (1)',
    'Func. No.': 'VE333-72001   ',
    'Func. Description': 'Escape Ladders / Steps Ladders & Ropes',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'MA-Breakage',
    'Job Code': 'PM00517A',
    'Planned Start Date ': '02/27/2024'
  },
  {
    Number: '2024/00005',
    'Job Title': 'Drain Inspection',
    Component: 'TS666-PKLE-0001_MIS ',
    Priority: 2,
    'Resp. Discipline': 'Maint T/S',
    Status: 'To Do (1)',
    'Func. No.': 'TS666-00000   ',
    'Func. Description': 'Laboratory',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'MA-Corrosion',
    'Job Code': 'PM00546A',
    'Planned Start Date ': '02/27/2024'
  },
  {
    Number: '2024/00006',
    'Job Title': 'Davit General Inspections',
    Component: 'X-F0501             ',
    Priority: 2,
    'Resp. Discipline': 'Cargo',
    Status: 'To Do (1)',
    'Func. No.': 'TS874-30001   ',
    'Func. Description': 'Davit - Top Pmp Rm Hatch',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'MA-Corrosion',
    'Job Code': 'PM01409A',
    'Planned Start Date ': '02/27/2024'
  },
  {
    Number: '2024/00007',
    'Job Title': 'Davit General Inspections',
    Component: 'X-T0512             ',
    Priority: 2,
    'Resp. Discipline': 'Cargo',
    Status: 'To Do (1)',
    'Func. No.': 'TS874-30012   ',
    'Func. Description': 'Davit - Methanol Skid',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'MA-Corrosion',
    'Job Code': 'PM01409A',
    'Planned Start Date ': '02/27/2024'
  },
  {
    Number: '2024/00008',
    'Job Title': 'Davit General Inspections',
    Component: 'X-T0500             ',
    Priority: 2,
    'Resp. Discipline': 'Cargo',
    Status: 'To Do (1)',
    'Func. No.': 'TS874-30003   ',
    'Func. Description': 'Davit - Module 02',
    'Maint. Class': '03 Package Intrusive',
    'Maint. Cause': 'MA-Corrosion',
    'Job Code': 'PM01409A',
    'Planned Start Date ': '02/27/2024'
  },
  {
    Number: '2024/00009',
    'Job Title': 'Test Generator Space Heater ',
    Component: 'G-F1103             ',
    Priority: 3,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'VE111-10002   ',
    'Func. Description': 'Generator Essential',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'MA-Wear/Aging',
    'Job Code': 'EL-00002',
    'Planned Start Date ': '02/27/2024'
  },
  {
    Number: '2024/00010',
    'Job Title': '1M Turret Pipework Visual Inspection',
    Component: 'MS890-TUPI-0010_MIS ',
    Priority: 3,
    'Resp. Discipline': 'Production',
    Status: 'To Do (1)',
    'Func. No.': 'MS890-20010   ',
    'Func. Description': 'Deluge Lines',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'MA-Wear/Aging',
    'Job Code': 'PR-00009',
    'Planned Start Date ': '06/27/2024'
  },
  {
    Number: '2024/00011',
    'Job Title': '1Daily Helicopter fuelling inspection',
    Component: 'A-T0410             ',
    Priority: 1,
    'Resp. Discipline': 'Safety',
    Status: 'To Do (1)',
    'Func. No.': 'VE333-50100   ',
    'Func. Description': 'HELI-FUEL STORE & TRANSFER SKID A-T0410',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'MA-Wear/Aging',
    'Job Code': 'SA-00111',
    'Planned Start Date ': '06/27/2024'
  },
  {
    Number: '2024/00012',
    'Job Title': 'Portable VHF, UHF, GMDSS Radio Checks',
    Component: 'PF566-CMVH-0014_MIS ',
    Priority: 1,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF566-50001   ',
    'Func. Description': 'VHF Fixed Radio With Charger',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'MA-Wear/Aging',
    'Job Code': 'SA-00089',
    'Planned Start Date ': '06/27/2024'
  },
  {
    Number: '2024/00013',
    'Job Title': 'Battery Check',
    Component: 'S-F3120             ',
    Priority: 3,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'VE331-03223   ',
    'Func. Description': 'Battery Box No2',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'MA-Wear/Aging',
    'Job Code': 'PM00565A',
    'Planned Start Date ': '06/27/2024'
  },
  {
    Number: '2024/00014',
    'Job Title': 'N2 Generator - O2 Analyzer Calibration ',
    Component: 'A-F1860             ',
    Priority: 3,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'TS110-00000   ',
    'Func. Description': 'NITROGEN GENERATOR SKID',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'MA-Wear/Aging',
    'Job Code': 'IN-00505',
    'Planned Start Date ': '06/27/2024'
  },
  {
    Number: '2024/00015',
    'Job Title': 'ODME Calibration and Function Test',
    Component: 'A-F1311             ',
    Priority: 1,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'VE663-60100   ',
    'Func. Description': 'OCMU Drain Tank Gravity Discharge',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'MA-Wear/Aging',
    'Job Code': 'IN-00080',
    'Planned Start Date ': '07/27/2024'
  },
  {
    Number: '2024/00016',
    'Job Title': 'Firewater Pump Package Test Run',
    Component: 'VE331-PKFW-0001_MIS ',
    Priority: 2,
    'Resp. Discipline': 'Maint T/S',
    Status: 'To Do (1)',
    'Func. No.': 'VE331-02000   ',
    'Func. Description': 'EMGY FIRE PMPS - DIESEL DRIVE FORE',
    'Maint. Class': '03 Package Intrusive',
    'Maint. Cause': 'MA-Wear/Aging',
    'Job Code': 'PM00700A',
    'Planned Start Date ': '07/27/2024'
  },
  {
    Number: '2024/00017',
    'Job Title': 'Water Mist System Inspection',
    Component: 'TS111-SEWM-0001_MIS ',
    Priority: 2,
    'Resp. Discipline': 'Maint T/S',
    Status: 'To Do (1)',
    'Func. No.': 'TS111-10600   ',
    'Func. Description':
      'Fire Fighting System, Water Mist, Power Generation Pkg A',
    'Maint. Class': '03 Package Intrusive',
    'Maint. Cause': 'ME-Leakage External',
    'Job Code': 'PM00523A',
    'Planned Start Date ': '07/27/2024'
  },
  {
    Number: '2024/00018',
    'Job Title': 'Water Mist System Inspection',
    Component: 'TS111-SEWM-0002_MIS ',
    Priority: 2,
    'Resp. Discipline': 'Maint T/S',
    Status: 'To Do (1)',
    'Func. No.': 'TS111-20600   ',
    'Func. Description':
      'Fire Fighting System, Water Mist, Power Generation Pkg B',
    'Maint. Class': '03 Package Intrusive',
    'Maint. Cause': 'ME-Leakage External',
    'Job Code': 'PM00523A',
    'Planned Start Date ': '07/27/2024'
  },
  {
    Number: '2024/00019',
    'Job Title': 'Firewater Pump Package Test Run',
    Component: 'VE331-PKFW-0001_MIS ',
    Priority: 2,
    'Resp. Discipline': 'Maint T/S',
    Status: 'To Do (1)',
    'Func. No.': 'VE331-02000   ',
    'Func. Description': 'EMGY FIRE PMPS - DIESEL DRIVE FORE',
    'Maint. Class': '03 Package Intrusive',
    'Maint. Cause': 'ME-Leakage External',
    'Job Code': 'PM00700A',
    'Planned Start Date ': '07/27/2024'
  },
  {
    Number: '2024/00020',
    'Job Title': 'Water Mist System Inspection',
    Component: 'TS111-SEWM-0002_MIS ',
    Priority: 2,
    'Resp. Discipline': 'Maint T/S',
    Status: 'To Do (1)',
    'Func. No.': 'TS111-20600   ',
    'Func. Description':
      'Fire Fighting System, Water Mist, Power Generation Pkg B',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'ME-Leakage External',
    'Job Code': 'PM00523A',
    'Planned Start Date ': '07/27/2024'
  },
  {
    Number: '2024/00021',
    'Job Title': 'Firewater Pump Package Test Run',
    Component: 'VE331-PKFW-0001_MIS ',
    Priority: 2,
    'Resp. Discipline': 'Service Eng',
    Status: 'To Do (1)',
    'Func. No.': 'VE331-02000   ',
    'Func. Description': 'EMGY FIRE PMPS - DIESEL DRIVE FORE',
    'Maint. Class': '03 Package Intrusive',
    'Maint. Cause': 'ME-Leakage External',
    'Job Code': 'PM00700A',
    'Planned Start Date ': '07/27/2024'
  },
  {
    Number: '2024/00022',
    'Job Title': 'General Inspections',
    Component: 'X-G0510             ',
    Priority: 2,
    'Resp. Discipline': 'Cargo',
    Status: 'To Do (1)',
    'Func. No.': 'TS874-00101   ',
    'Func. Description': 'Cranes - Deck Pedestal Fixed Boom Crane',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'ME-Lubrication',
    'Job Code': 'PM01301A',
    'Planned Start Date ': '07/27/2024'
  },
  {
    Number: '2024/00023',
    'Job Title': 'Instrument Checks',
    Component: 'F65-PT-401          ',
    Priority: 2,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'TS664-00306   ',
    'Func. Description': 'Press Trans - To Crude Line 4',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'ME-Vibration',
    'Job Code': 'IN-00001',
    'Planned Start Date ': '07/27/2024'
  },
  {
    Number: '2024/00024',
    'Job Title': 'CCTV Monthly Checks',
    Component: 'PF566-CMTV-0008_MIS ',
    Priority: 3,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF566-01008   ',
    'Func. Description': 'TV Surveillance System',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'ME-Vibration',
    'Job Code': 'IN-00211',
    'Planned Start Date ': '07/27/2024'
  },
  {
    Number: '2024/00025',
    'Job Title': 'Calibrate Flue Gas Oxygen Content Meter',
    Component: 'F27-AST-0001        ',
    Priority: 1,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'VE127-00501   ',
    'Func. Description': 'O2 Analyser',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'MS-New',
    'Job Code': 'IN-00076',
    'Planned Start Date ': '07/27/2024'
  },
  {
    Number: '2024/00026',
    'Job Title': 'Transformer Checks',
    Component: 'T-F4251B            ',
    Priority: 2,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF441-20109   ',
    'Func. Description': 'Transformer - S-F4201 To S-F4202 Line B',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'MS-New',
    'Job Code': 'PM00038A',
    'Planned Start Date ': '07/27/2024'
  },
  {
    Number: '2024/00027',
    'Job Title': 'Test Generator Space Heater ',
    Component: 'G-F1105             ',
    Priority: 3,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'TS111-50002   ',
    'Func. Description': 'Generator Emergency',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'MS-New',
    'Job Code': 'EL-00002',
    'Planned Start Date ': '07/17/2024'
  },
  {
    Number: '2024/00028',
    'Job Title': 'CCTV Monthly Checks',
    Component: 'PF566-CMTV-0003_MIS ',
    Priority: 3,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF566-01003   ',
    'Func. Description': 'TV Surveillance System',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'MS-New',
    'Job Code': 'IN-00211',
    'Planned Start Date ': '07/17/2024'
  },
  {
    Number: '2024/00029',
    'Job Title': 'CCTV Monthly Checks',
    Component: 'PF566-CMTV-0004_MIS ',
    Priority: 3,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF566-01004   ',
    'Func. Description': 'TV Surveillance System',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'MS-New',
    'Job Code': 'IN-00211',
    'Planned Start Date ': '07/17/2024'
  },
  {
    Number: '2024/00030',
    'Job Title': 'CCTV Monthly Checks',
    Component: 'PF566-CMTV-0005_MIS ',
    Priority: 3,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF566-01005   ',
    'Func. Description': 'TV Surveillance System',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'MS-New',
    'Job Code': 'IN-00211',
    'Planned Start Date ': '07/17/2024'
  },
  {
    Number: '2024/00031',
    'Job Title': 'CCTV Monthly Checks',
    Component: 'PF566-CMTV-0006_MIS ',
    Priority: 3,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF566-01006   ',
    'Func. Description': 'TV Surveillance System',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'MS-New',
    'Job Code': 'IN-00211',
    'Planned Start Date ': '07/17/2024'
  },
  {
    Number: '2024/00032',
    'Job Title': 'CCTV Monthly Checks',
    Component: 'PF566-CMTV-0007_MIS ',
    Priority: 3,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF566-01007   ',
    'Func. Description': 'TV Surveillance System',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'MS-New',
    'Job Code': 'IN-00211',
    'Planned Start Date ': '07/17/2024'
  },
  {
    Number: '2024/00033',
    'Job Title': 'CCTV Monthly Checks',
    Component: 'PF566-CMTV-0008_MIS ',
    Priority: 3,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF566-01008   ',
    'Func. Description': 'TV Surveillance System',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'MS-New',
    'Job Code': 'IN-00211',
    'Planned Start Date ': '07/21/2024'
  },
  {
    Number: '2024/00034',
    'Job Title': 'CCTV Monthly Checks',
    Component: 'PF566-CMTV-0009_MIS ',
    Priority: 3,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF566-01009   ',
    'Func. Description': 'TV Surveillance System',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'MS-New',
    'Job Code': 'IN-00211',
    'Planned Start Date ': '07/21/2024'
  },
  {
    Number: '2024/00035',
    'Job Title': 'Check Temperature Sensor',
    Component: 'VE557-CATG-0001_MIS ',
    Priority: 3,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'VE557-30001   ',
    'Func. Description': 'MMC Tk Gauge',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'MS-New',
    'Job Code': 'IN-00131',
    'Planned Start Date ': '07/21/2024'
  },
  {
    Number: '2024/00036',
    'Job Title': 'Calibrate Level Transmitter',
    Component: 'A-F2705             ',
    Priority: 1,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'VE127-00601   ',
    'Func. Description': 'Deck Water Seal',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'MS-New',
    'Job Code': 'IN-00091',
    'Planned Start Date ': '07/21/2024'
  },
  {
    Number: '2024/00037',
    'Job Title': 'Check Condition/Charged',
    Component: 'A-F3320             ',
    Priority: 2,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'VE333-20201   ',
    'Func. Description': 'SB Lifeboat',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'MS-New',
    'Job Code': 'EL-00001',
    'Planned Start Date ': '07/21/2024'
  },
  {
    Number: '2024/00038',
    'Job Title': 'Crane Weekly Inspection',
    Component: 'X-G0503B            ',
    Priority: 3,
    'Resp. Discipline': 'Maint T/S',
    Status: 'To Do (1)',
    'Func. No.': 'TS874-10002   ',
    'Func. Description': 'Crane - Marine Crane 5 ton',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'MS-New',
    'Job Code': 'ME-00057',
    'Planned Start Date ': '07/21/2024'
  },
  {
    Number: '2024/00039',
    'Job Title': 'Instrument Checks',
    Component: 'F23-LSH-219         ',
    Priority: 3,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'VE123-30015   ',
    'Func. Description': 'Lev Switch Aft bilge Stbd Near LO pp',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'MS-New',
    'Job Code': 'IN-00001',
    'Planned Start Date ': '07/21/2024'
  },
  {
    Number: '2024/00040',
    'Job Title': 'Calibrate Portable Gas Detector',
    Component: 'VE333-FGGP-0005_MIS ',
    Priority: 1,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'VE333-60005   ',
    'Func. Description': 'Portable Gas Detector - Triple Plus',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'MS-New',
    'Job Code': 'PM00055A',
    'Planned Start Date ': '07/21/2024'
  },
  {
    Number: '2024/00041',
    'Job Title': 'Davits - Limit Switches',
    Component: 'A-F0721             ',
    Priority: 1,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'VE333-22002   ',
    'Func. Description': 'Davit For Line Handling Work Boat',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'EL-00151',
    'Planned Start Date ': '07/21/2024'
  },
  {
    Number: '2024/00042',
    'Job Title': 'Instrument Checks',
    Component: 'F65-TT-201          ',
    Priority: 3,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'TS664-00208   ',
    'Func. Description': 'Temp Trans - To Crude Line 2',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'IN-00001',
    'Planned Start Date ': '07/21/2024'
  },
  {
    Number: '2024/00043',
    'Job Title': 'Test Air Cooler Leak Detector',
    Component: 'G-F1110             ',
    Priority: 2,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'VE111-50103   ',
    'Func. Description': 'Generator',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'IN-00087',
    'Planned Start Date ': '07/21/2024'
  },
  {
    Number: '2024/00044',
    'Job Title': 'Battery Check',
    Component: 'S-F4541             ',
    Priority: 1,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'TS111-50010   ',
    'Func. Description': 'Battery Set 1/2',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'PM00565A',
    'Planned Start Date ': '07/21/2024'
  },
  {
    Number: '2024/00045',
    'Job Title': 'Battery Check',
    Component: 'S-F4531             ',
    Priority: 3,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'VE111-10010   ',
    'Func. Description': 'Battery Set 1/2',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'PM00565A',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00046',
    'Job Title': 'Check Calibration of Crane Load Sensor',
    Component: 'X-G0510             ',
    Priority: 1,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'TS874-00101   ',
    'Func. Description': 'Cranes - Deck Pedestal Fixed Boom Crane',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'IN-00083',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00047',
    'Job Title': 'Check Calibration of Crane Load Sensor',
    Component: 'X-G0520             ',
    Priority: 1,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'TS874-00201   ',
    'Func. Description': 'Cranes - Deck Pedestal Fixed Boom Crane',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'IN-00083',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00048',
    'Job Title': 'Test Generator Space Heater ',
    Component: 'G-F1103             ',
    Priority: 3,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'VE111-10002   ',
    'Func. Description': 'Generator Essential',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'EL-00002',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00049',
    'Job Title': 'Test Engine Alarms & Trips',
    Component: 'A-F3119             ',
    Priority: 3,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'VE331-03101   ',
    'Func. Description': 'Diesel Engine - FW Pump AFT',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'PM00747A',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00050',
    'Job Title': 'Control Panel Visual Inspection',
    Component: 'S-T7210             ',
    Priority: 2,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'TS772-10323   ',
    'Func. Description': 'Ctrl Pnl - TEG Regen Heater',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'PM00041A',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00051',
    'Job Title': 'Turbine Vibration Modules & cables insp.',
    Component: 'GT-F1110            ',
    Priority: 2,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'VE111-50101   ',
    'Func. Description': 'Steam Turbine',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'ME-00859',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00052',
    'Job Title': 'Davits - Limit Switches',
    Component: 'A-F3311             ',
    Priority: 1,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'VE333-20103   ',
    'Func. Description': 'Davit - PS Lifeboat',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'EL-00151',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00053',
    'Job Title': 'Insulation Test of Alternator windings',
    Component: 'G-F1110             ',
    Priority: 3,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'VE111-50103   ',
    'Func. Description': 'Generator',
    'Maint. Class': '03 Package Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'PM00186A',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00054',
    'Job Title': 'Boiler BMS Function Test',
    Component: 'A-F1510             ',
    Priority: 2,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'VE115-00001   ',
    'Func. Description': 'Main Boiler Exist',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'PM00577A',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00055',
    'Job Title': 'Transformer Checks',
    Component: 'T-M4601             ',
    Priority: 2,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF441-20116   ',
    'Func. Description': 'Transformer - S-M4101 To S-M4601',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'PM00038A',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00056',
    'Job Title': 'Transformer Checks',
    Component: 'T-F4352B            ',
    Priority: 2,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF441-20115   ',
    'Func. Description': 'Transformer - S-F4302 To S-F4303',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'PM00038A',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00057',
    'Job Title': 'Portable Electrical Appliance Inspection',
    Component: '441-PKEL-0001_MIS   ',
    Priority: 2,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': "'441           ",
    'Func. Description': 'MAIN & AUXILIARY POWER DISTRIBUTION',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'EL-00620',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00058',
    'Job Title': 'Boiler Clean/Inspection',
    Component: 'A-F1510             ',
    Priority: 2,
    'Resp. Discipline': 'Maint E/R',
    Status: 'To Do (1)',
    'Func. No.': 'VE115-00001   ',
    'Func. Description': 'Main Boiler Exist',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'ME-00007',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00059',
    'Job Title': 'Transformer Checks',
    Component: 'T-T4151B            ',
    Priority: 2,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF441-20104   ',
    'Func. Description': 'Transformer - S-T4101 Sect B To S-T4102',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'PM00038A',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00060',
    'Job Title': 'Transformer Checks',
    Component: 'T-T4151A            ',
    Priority: 2,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF441-20103   ',
    'Func. Description': 'Transformer - S-T4101 Sect A To S-T4102',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'PM00038A',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00061',
    'Job Title': 'Transformer Checks',
    Component: 'T-F4351B            ',
    Priority: 2,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF441-20111   ',
    'Func. Description': 'Transformer - S-T4101 Sect B To S-F4301',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'PM00038A',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00062',
    'Job Title': 'Transformer Checks',
    Component: 'T-F4351A            ',
    Priority: 2,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF441-20110   ',
    'Func. Description': 'Transformer - S-T4101 Sect A To S-F4301',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'PM00038A',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00063',
    'Job Title': 'Test Generator Space Heater ',
    Component: 'G-T1110             ',
    Priority: 2,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'TS111-10003   ',
    'Func. Description': 'Generator',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'EL-00002',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00064',
    'Job Title': 'Instrument Checks',
    Component: 'F65-PT-301          ',
    Priority: 2,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'TS664-00256   ',
    'Func. Description': 'Press Trans - To Crude Line 3',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'IN-00001',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00065',
    'Job Title': 'Portable VHF, UHF, GMDSS Radio Checks',
    Component: 'PF566-CMVH-0013_MIS ',
    Priority: 1,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF566-40001   ',
    'Func. Description': 'VHF Handportable Radio With Charger',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'SA-00089',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00066',
    'Job Title': 'Chloropac Electrical Inspection',
    Component: 'A-F2220             ',
    Priority: 2,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'VE122-20200   ',
    'Func. Description': 'ANTI MARINE GROWTH SYSTEM TOPSIDES',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'PM00562A',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00067',
    'Job Title': 'Deck Seal High/Low Level & Flow Alarms',
    Component: 'A-F2705             ',
    Priority: 1,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'VE127-00601   ',
    'Func. Description': 'Deck Water Seal',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'IN-00092',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00068',
    'Job Title': 'Test Generator Space Heater ',
    Component: 'G-T1120             ',
    Priority: 2,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'TS111-20003   ',
    'Func. Description': 'Generator',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'EL-00002',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00069',
    'Job Title': 'Portable VHF, UHF, GMDSS Radio Checks',
    Component: 'PF566-CMVH-0001_MIS ',
    Priority: 1,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'PF566-20001   ',
    'Func. Description': 'VHF GMDSS Survival Craft Portable Radio',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'SA-00089',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00070',
    'Job Title': 'Battery Check',
    Component: 'S-F4541             ',
    Priority: 1,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'TS111-50010   ',
    'Func. Description': 'Battery Set 1/2',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'PM00565A',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00071',
    'Job Title': 'Battery Check',
    Component: 'S-F4531             ',
    Priority: 3,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'VE111-10010   ',
    'Func. Description': 'Battery Set 1/2',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'PM00565A',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00072',
    'Job Title': 'DCS Weekly Backup',
    Component: 'BB0075              ',
    Priority: 1,
    'Resp. Discipline': 'DCS',
    Status: 'To Do (1)',
    'Func. No.': 'PF550-00001   ',
    'Func. Description': 'ICSS System',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'DC-00002',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00073',
    'Job Title': 'Boiler BMS Function Test',
    Component: 'A-F1520             ',
    Priority: 2,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'VE115-10001   ',
    'Func. Description': 'Aux Boiler Exist',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'PM00577A',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00074',
    'Job Title': 'Test Turbine Trips/Alarms',
    Component: 'VE663-STMS-0001_MIS ',
    Priority: 2,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'VE663-00105   ',
    'Func. Description': 'Steam Turbine - To Cargo Oil Pump 1',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'ME-00043',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00075',
    'Job Title': 'Test Turbine Trips/Alarms',
    Component: 'VE663-STMS-0002_MIS ',
    Priority: 2,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'VE663-00205   ',
    'Func. Description': 'Steam Turbine - To Cargo Oil Pump 2',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'ME-00043',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00076',
    'Job Title': 'Test Turbine Trips/Alarms',
    Component: 'VE663-STMS-0004_MIS ',
    Priority: 2,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'VE663-00405   ',
    'Func. Description': 'Steam Turbine - To Cargo Oil Pump 4',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'ME-00043',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00077',
    'Job Title': 'Test Turbine Trips/Alarms',
    Component: 'VE663-STMS-0003_MIS ',
    Priority: 2,
    'Resp. Discipline': 'Instrument',
    Status: 'To Do (1)',
    'Func. No.': 'VE663-00305   ',
    'Func. Description': 'Steam Turbine - To Cargo Oil Pump 3',
    'Maint. Class': '01 Non Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'ME-00043',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00078',
    'Job Title': '3M  Junction Boxe Inspection',
    Component: 'P-T2201D            ',
    Priority: 3,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'VE122-13401   ',
    'Func. Description': 'Pump - SW Lift D',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'EL-00850',
    'Planned Start Date ': '03/21/2025'
  },
  {
    Number: '2024/00079',
    'Job Title': '3M  Junction Boxe Inspection',
    Component: 'P-T2201C            ',
    Priority: 3,
    'Resp. Discipline': 'Electrical',
    Status: 'To Do (1)',
    'Func. No.': 'VE122-13301   ',
    'Func. Description': 'Pump - SW Lift C',
    'Maint. Class': '02 Item Intrusive',
    'Maint. Cause': 'PM-Routine',
    'Job Code': 'EL-00850',
    'Planned Start Date ': '03/21/2025'
  }
]

export default function Overdue() {
  return (
    <>
      <ProtectedLayout onBack="/myDashboard/overdue" title="Over due">
        <Row style={{ margin: 10 }}>
          <Table striped>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Number</th>
                <th>Job title</th>
                <th>Component</th>
                <th>Priority</th>
                <th>Resp. Discipline</th>
                <th>Status</th>
                <th>Func. No.</th>
                <th>Func. Description</th>
                <th>Maint. Class</th>
                <th>Maint. Cause</th>
                <th>Job Code</th>
                <th>Planned Start Date</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((e: any, index: number) => (
                <tr key={`${e?.Number}-${index}`}>
                  <td>{incrementby1(index)}</td>
                  <td>{e?.['Number']}</td>
                  <td>{e?.['Job Title']}</td>
                  <td>{e?.['Component']}</td>
                  <td>{e?.['Priority']}</td>
                  <td>{e?.['Resp. Discipline']}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>{e?.['Status']}</td>
                  <td>{e?.['Func. No.']}</td>
                  <td>{e?.['Func. Description']}</td>
                  <td>{e?.['Maint. Class']}</td>
                  <td>{e?.['Maint. Cause']}</td>
                  <td>{e?.['Job Code']}</td>
                  <td>{e?.['Planned Start Date ']}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      </ProtectedLayout>
    </>
  )
}
