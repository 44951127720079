import React from 'react'
import { Row } from 'reactstrap'
import JSATemplateView from '../Forms/JSATemplateView'
import QRATemplateView from '../Forms/QRATemplateView'
import Spacer from '../Spacer'
import { ColoredSubHeader } from './ViewExcavationPermit'

type ViewRiskAssesmentType = {
  data: any
  rowStyle: any
  renderRow: any
  permitData: any
}

export default function ViewRiskAssesment({
  data,
  rowStyle,
  renderRow,
  permitData
}: ViewRiskAssesmentType) {
  const header: any = {
    'Risk Assessment Number': permitData?.riskAssesmentPermit?.permitNumber,
    'Risk Assessment Template Number': data?.templateNumber,
    'Risk Assessment Type': permitData?.isJSA,
    'Permit Number': permitData?.woNumber,
    'Permit Title': permitData?.permitTitle
  }

  return (
    <>
      <ColoredSubHeader rowStyle={rowStyle} text="Risk Assesment" />
      {permitData?.woNumber && (
        <Row
          style={{
            ...rowStyle,
            fontSize: 14,
            margin: 0
          }}
        >
          {Object.keys(header).map((key) => renderRow(key, header[key]))}
        </Row>
      )}
      {!permitData?.woNumber && (
        <Row
          style={{
            ...rowStyle,
            fontSize: 14
          }}
        >
          {renderRow('Template Number', data?.templateNumber)}
        </Row>
      )}
      <Spacer height={10} />
      {permitData?.isJSA === 'Non Critical' && (
        <JSATemplateView risk={data} showSelect={false} />
      )}
      {permitData?.isJSA === 'Critical' && (
        <QRATemplateView template={data} showSelect={false} />
      )}
      <Spacer height={10} />
    </>
  )
}
