/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import React, { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import RcmLayout from '../../components/rcm/RcmLayout'
import RcmStepper from './RcmStepper'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { Link, renderData } from '../workManagement/PmTree'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import { LoadingState } from './RcmViewStage4'
import { getRcmRiskColor } from './rcmLib'
import { SubHeader } from '../VerifyCM'
import CustomLabel from '../../components/InputFields/CustomLabel'

export const RcmEquipmentTable = ({ items }: { items: any }) => {
  return (
    <Table striped>
      <thead>
        <tr style={{ backgroundColor: 'lightgray' }}>
          <th className="top-left text-center">S.No</th>
          {['Number', 'Description', 'Manufacturer', 'Criticality']?.map(
            (h: any) => <th key={h}>{h}</th>
          )}
          <th className="top-right">Type</th>
        </tr>
      </thead>
      <tbody>
        {items?.map((e: any, Eindex: number) => (
          <tr key={e?.itemNumber}>
            <td style={{ width: '3vw' }} className="text-center">
              {Eindex + 1}
            </td>
            <td style={{ width: '7vw' }}>{e?.equipmentNumber}</td>
            <td style={{ width: '20vw' }}>{e?.equipmentDescription}</td>
            <td style={{ width: '10vw' }}>{e?.manufacturer}</td>
            <td style={{ width: '5vw' }}>{e?.criticality}</td>
            <td style={{ width: '10vw' }}>{e?.type}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export const RcmFunctionalFailure = ({
  failures,
  equipment
}: {
  failures: any
  equipment: any
}) => {
  return (
    <>
      {failures.map((mode: any, modeIndex: number) => (
        <div key={modeIndex}>
          <h4>{mode.name}</h4>
          <p className="m-0">
            <strong>Associated Equipment:</strong>{' '}
            {mode?.tags?.map((a: any) => a?.equipmentNumber)?.join(', ')}{' '}
          </p>
          <Table striped className="text-center">
            <thead>
              <tr style={{ backgroundColor: 'lightgray' }}>
                <th className="top-left">S.No</th>
                <th>Functional Failure</th>
                <th>Failure modes</th>
                <th className="top-right">Failure Effect</th>
              </tr>
            </thead>
            <tbody>
              {mode?.failures?.map((entry: any, index: number) => (
                <tr key={index}>
                  <td style={{ width: '5%', alignContent: 'center' }}>
                    {index + 1}
                  </td>
                  <td style={{ width: '10%' }}>{entry.functionalFailure}</td>
                  <td style={{ width: '10%' }}>{entry.failureMode}</td>
                  <td style={{ width: '10%' }}>{entry.failureEffect}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
    </>
  )
}

export const RcmRiskAssessment = ({
  riskDetails,
  equipment
}: {
  riskDetails: any
  equipment: any
}) => {
  const getTags = (mode: any) =>
    equipment[mode]?.map((a: any) => a?.equipmentNumber)?.join(', ')

  const getScoreStyles: any = (score: any) => ({
    textAlign: 'center',
    backgroundColor: getRcmRiskColor(score)
  })
  return (
    <>
      {Object.keys(riskDetails).map((mode: any, modeIndex: number) => (
        <div key={modeIndex}>
          <h4>{mode}</h4>
          <p className="m-0">
            <strong>Associated Equipment: </strong> {getTags(mode)}
          </p>
          <Table striped>
            <thead>
              <tr style={{ backgroundColor: 'lightgray' }}>
                <th className="top-left text-center" style={{ width: '3vw' }}>
                  {' '}
                  S.No{' '}
                </th>
                <th style={{ width: '10vw' }}>Failure modes</th>
                <th style={{ width: '5vw' }}>Mtbf</th>
                <th style={{ width: '5vw' }}>Health</th>
                <th style={{ width: '5vw' }}>Score</th>
                <th style={{ width: '5vw' }}>Economic</th>
                <th style={{ width: '5vw' }}>Economic Consequences</th>
                <th style={{ width: '5vw' }}>Score</th>
                <th style={{ width: '5vw' }}>Environment</th>
                <th className="top-right" style={{ width: '5vw' }}>
                  Score
                </th>
              </tr>
            </thead>
            <tbody>
              {riskDetails[mode]?.map(
                (
                  {
                    riskScore,
                    failureMode,
                    healthSafetyScore,
                    environmentScore,
                    economicScore,
                    tasks,
                    rcmDecision,
                    clientDecision,
                    healthSafety,
                    econonmic,
                    environment,
                    economicEffect,
                    mtbf
                  }: any,
                  index: number
                ) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td>{failureMode}</td>
                      <th>{mtbf}</th>
                      <td>{healthSafety}</td>
                      <td style={getScoreStyles(healthSafetyScore)}>
                        {healthSafetyScore}
                      </td>
                      <td>{econonmic}</td>
                      <td>{economicEffect}</td>
                      <td style={getScoreStyles(economicScore)}>
                        {economicScore}
                      </td>
                      <td>{environment}</td>
                      <td style={getScoreStyles(environmentScore)}>
                        {environmentScore}
                      </td>
                    </tr>
                  </React.Fragment>
                )
              )}
            </tbody>
          </Table>
        </div>
      ))}
    </>
  )
}

export const RcmCategorizedEquipment = ({ items }: { items: any }) => {
  const tags = Object.keys(items)
    ?.map(
      (item: any) => items[item]?.map((a: any) => ({ ...a, component: item }))
    )
    ?.flat(1)
  return (
    <Table striped>
      <thead>
        <tr style={{ backgroundColor: 'lightgray' }}>
          <th className="top-left text-center">S.No</th>
          <th>Number</th>
          <th>Description</th>
          <th>Criticality</th>
          <th className="top-right">Component</th>
        </tr>
      </thead>
      <tbody>
        {tags?.map((t: any, index: any) => (
          <tr key={t?._id}>
            <td className="text-center">{index + 1}</td>
            <td>{t?.equipmentNumber}</td>
            <td>{t?.equipmentDescription}</td>
            <td>{t?.criticality}</td>
            <td>{t?.component}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export const RcmBasicDetails = ({ data }: { data: any }) => {
  const rcmDetails: any = {
    Unit: data?.unit || data?.plantUnit,
    System: data?.system,
    Location: data?.location,
    Description: data?.analysisDescription,
    'Created On': getReadableTime(data?.createdAt)
  }

  const equipmentList = [
    ...(data?.equipmentList || []),
    ...(data?.itemsList || []),
    ...(data?.tagsList || [])
  ]?.map((i: any) => ({
    equipmentNumber: i?.equipmentNumber || i?.itemNumber || i?.tagNo,
    equipmentDescription:
      i?.equipmentDescription || i?.itemDescription || i?.tagDescription,
    manufacturer: i?.manufacturer,
    criticality: i?.criticality,
    type: i?.equipmentNumber
      ? 'Equipment'
      : i?.itemNumber
      ? 'Maintainable Item'
      : i?.tagNo
      ? 'Tag'
      : ''
  }))

  if (!data) {
    return null
  }

  console.log(Object?.keys(data?.riskDetails || {})?.length > 0)

  return (
    <>
      <style>
        {`
          table {
            width: 100%;
            border-spacing: 0; 
            border-collapse: separate;
          }

          .top-left {
            border: 2px solid lightgray;
            border-top-left-radius: 10px;
          }

          .top-right {
            border: 2px solid lightgray;
            border-top-right-radius: 10px;
          }
          `}
      </style>
      <Row>
        <Col md={6}>
          <h4 style={{ fontWeight: 700 }}>RCM BASIC DETAILS</h4>
          <div
            style={{
              backgroundColor: '#fff',
              padding: 10,
              borderRadius: 10,
              height: 200
            }}
          >
            {Object.keys(rcmDetails)?.map((k: any) =>
              renderData(<b>{k}</b>, rcmDetails[k], 16)
            )}
          </div>
        </Col>
        <Col md={6}>
          <h4 style={{ fontWeight: 700 }}>TEAM MEMBERS</h4>
          <div
            style={{ backgroundColor: '#fff', borderRadius: 10, height: 200 }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: 'lightgray' }}>
                  <th className="top-left">S.No</th>
                  {['Name', 'Emp No', 'Department']?.map(
                    (h: any, index: any) => <th key={`${h}-${index}`}>{h}</th>
                  )}
                  <th className="top-right">Designation</th>
                </tr>
              </thead>
              <tbody>
                {data?.team?.map((e: any, Eindex: number) => (
                  <tr key={e?.value}>
                    <td>{Eindex + 1}</td>
                    <td>{e?.givenName}</td>
                    <td>{e?.employeeId}</td>
                    <td>{e?.department}</td>
                    <td>{e?.jobTitle}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
        <Spacer height={10} />
      </Row>
      <Row>
        <Col>
          {equipmentList?.length >= 1 && (
            <>
              <h4 style={{ fontWeight: 700 }} children="Equipment" />
              <div
                style={{
                  backgroundColor: '#fff',
                  margin: 0,
                  padding: 0,
                  borderRadius: 10
                }}
              >
                <RcmEquipmentTable items={equipmentList} />
              </div>
            </>
          )}
          {Object?.keys(data?.categorizedEquipment || {})?.length > 0 && (
            <>
              <Spacer height={10} />
              <h4
                style={{ fontWeight: 700 }}
                children="Categorized Equipment"
              />
              <div
                style={{ backgroundColor: '#fff', margin: 0, borderRadius: 10 }}
              >
                <RcmCategorizedEquipment items={data?.categorizedEquipment} />
              </div>
            </>
          )}
          {/* {data?.failuresModes?.length > 0 && (
            <>
              <Spacer height={10} />
              <h4 style={{ fontWeight: 700 }} children="Functional Failures" />
              <div
                style={{
                  backgroundColor: '#fff',
                  margin: 0,
                  padding: 10,
                  borderRadius: 10
                }}
              >
                <RcmFunctionalFailure
                  failures={data?.failuresModes}
                  equipment={data?.categorizedEquipment}
                />
              </div>
            </>
          )} */}
          {/* {Object?.keys(data?.riskDetails || {})?.length > 0 && (
            <>
              <Spacer height={10} />
              <h4 style={{ fontWeight: 700 }} children="Risk Details" />
              <div
                style={{
                  backgroundColor: '#fff',
                  margin: 0,
                  padding: 10,
                  borderRadius: 10
                }}
              >
                <RcmRiskAssessment
                  riskDetails={data?.riskDetails}
                  equipment={data?.categorizedEquipment}
                />
              </div>
            </>
          )} */}
        </Col>
      </Row>
    </>
  )
}

const NewTagModal = ({
  isOpen,
  onClose,
  onSave
}: {
  isOpen: any
  onClose: any
  onSave: any
}) => {
  const [values, setValues]: any = useState({})
  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        <SubHeader header={'New Tag'} permitColor={'gold'} />
        <Spacer height={10} />
        <CustomLabel label="Tag Number" />
        <Input
          type="text"
          value={values?.equipmentNumber}
          onChange={(e) =>
            setValues({ ...values, equipmentNumber: e?.target?.value })
          }
        />
        <CustomLabel label="Tag Description" />
        <Input
          type="textarea"
          value={values?.equipmentDescription}
          onChange={(e) =>
            setValues({ ...values, equipmentDescription: e?.target?.value })
          }
        />
        <CustomLabel label="Manufacturer" />
        <Input
          type="text"
          value={values?.manufacturer}
          onChange={(e) =>
            setValues({ ...values, manufacturer: e?.target?.value })
          }
        />
        <CustomLabel label="Criticality" />
        <Input
          type="select"
          value={values?.manufacturer}
          onChange={(e) =>
            setValues({ ...values, criticality: e?.target?.value })
          }
        >
          <option>Select</option>
          {['HIGH', 'MEDIUM', 'LOW']?.map((c: any) => (
            <option value={c} key={c}>
              {c}
            </option>
          ))}
        </Input>
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={onClose}>
          Close
        </Button>
        <Button
          color="warning"
          disabled={Object.keys(values)?.length <= 0}
          onClick={() => {
            onSave(values)
            setValues({})
          }}
        >
          Add
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default function RcmViewStage1() {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch, isLoading }: any = useQuery('fetchRcm', fetchRcm)
  const [equipment, setEquipment]: any = useState([])
  const [submit, setSubmit]: any = useState(false)
  const [result, setResult]: any = useState({})
  const [tagModal, setTagModal]: any = useState(false)

  const currentAnalysisData = data?.analysis
  const hasCurrentRcm = currentAnalysisData?.number

  const navigate = useNavigate()
  const eqString: any = localStorage.getItem('equipmentList')
  const eq = JSON.parse(eqString || `[]`)

  const itemString: any = localStorage.getItem('itemsList')
  const itemsList = JSON.parse(itemString || `[]`)

  const tagsString: any = localStorage.getItem('tagsList')
  const tagsList = JSON.parse(tagsString || `[]`)

  const localId = localStorage.getItem('rcmId')

  useEffect(() => {
    if (localId === id) {
      setEquipment([...eq, ...itemsList, ...tagsList])
    }

    return () => {}
  }, [])

  if (isLoading) {
    return <LoadingState />
  }

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15 }}>
        <Col>
          <h4
            style={{ fontWeight: 700 }}
            children={`Analysis Number : ${
              hasCurrentRcm ? currentAnalysisData?.number : ''
            }`}
          />
          <RcmStepper stage={currentAnalysisData?.status} />
          <Spacer height={20} />
          <RcmBasicDetails data={currentAnalysisData} />
          <Spacer height={20} />
          <Col xs={12}>
            <div style={{ backgroundColor: '#fff', borderRadius: 10 }}>
              {equipment?.length > 0 && (
                <Table striped className="min-vh-50">
                  <thead>
                    <tr style={{ backgroundColor: 'lightgray' }}>
                      <th className="top-left text-center">S.No</th>
                      {[
                        'Tag No',
                        'Tag Description',
                        'Manufacturer',
                        'Criticality'
                      ]?.map((h: any) => <th key={h}>{h}</th>)}
                      <th className="top-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {equipment?.map((e: any, Eindex: number) => (
                      <tr key={e?.equipmentNumber}>
                        <td style={{ width: '3vw' }} className="text-center">
                          {Eindex + 1}
                        </td>
                        <td style={{ width: '10vw' }}>{e?.equipmentNumber}</td>
                        <td>{e?.equipmentDescription}</td>
                        <td style={{ width: '10vw' }}>{e?.manufacturer}</td>
                        <td style={{ width: '10vw' }}>{e?.criticality}</td>
                        <td style={{ width: '10vw' }}>
                          <Link
                            onClick={() =>
                              setEquipment(
                                equipment.filter(
                                  (s: any) =>
                                    s?.equipmentNumber !== e?.equipmentNumber
                                )
                              )
                            }
                          >
                            Remove
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
          </Col>
          <ModalFooter>
            <Button color="warning" onClick={() => setTagModal(true)}>
              Add New Tag
            </Button>
            <Button
              color="warning"
              onClick={() => {
                localStorage.setItem('rcmId', `${id}`)
                navigate('/cmms/rcm/systemBoundary')
              }}
            >
              Select from P & IDs
            </Button>
            <Button
              style={{ marginLeft: 5 }}
              color="warning"
              onClick={() => {
                localStorage.setItem('rcmId', `${id}`)
                navigate('/cmms/rcm/stage2/boundary1')
              }}
            >
              Boundary Drawing
            </Button>
          </ModalFooter>
          {currentAnalysisData?.status === 'Stage2' && (
            <ModalFooter>
              <Button color="warning" onClick={() => setSubmit(true)}>
                Submit
              </Button>
            </ModalFooter>
          )}
          <SubmitModalWithForm
            isOpen={submit}
            onClose={(val: any) => {
              setSubmit(false)
            }}
            onSubmit={async (e: any) => {
              e?.preventDefault()
              const body: any = {
                equipmentList: equipment,
                status: 'Stage3'
              }
              const res = await makeAuthenticatedRequest(
                `${rcmApi}/rcmAnalysis/${id}`,
                'PATCH',
                body,
                accounts,
                instance
              )

              if (res.status === 'success') {
                await refetch()
                setResult(res?.analysis)
              }

              return res.status
            }}
            sucessView={<p>RCM Number: {result?.number}</p>}
            size="xs"
            header="Add Tags"
          />
          <NewTagModal
            isOpen={tagModal}
            onClose={() => setTagModal(false)}
            onSave={(values: any) => {
              setEquipment([...equipment, values])
              setTagModal(false)
            }}
          />
        </Col>
      </Row>
    </RcmLayout>
  )
}
