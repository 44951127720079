import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { TbPlusMinus } from 'react-icons/tb'
import { ToastContainer, toast } from 'react-toastify'
import { MdAssignmentAdd } from 'react-icons/md'

import 'react-toastify/dist/ReactToastify.css'

import CustomLabel from '../../components/InputFields/CustomLabel'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { Box, renderData } from '../workManagement/PmTree'
import { failureModesHeaderKeys } from './RcmFailureModes'
import { flocHeaderKeys } from './Floc'
import { IconButton } from './Systems'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { maintLibraryDataKeys, maintLibraryHeaders } from './RcmMaintenance'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { SubHeader } from '../VerifyCM'
import { systemsApi } from '../../components/serverurl'

const FLocList = ({
  flocModal,
  setFLocModal,
  systemNumber,
  refetchFloc
}: any) => {
  const { accounts, instance } = useMsal()
  const fetchFlocs = async () => {
    const response = await makeAuthenticatedRequest(
      `${systemsApi}/floc`,
      'GET',
      null,
      accounts,
      instance
    )
    return response
  }

  const { data }: any = useQuery('flock list', fetchFlocs)

  const [selected, setSelected]: any = useState([])
  const [submitting, setSubmitting]: any = useState(false)

  return (
    <Modal isOpen={flocModal} fullscreen>
      <SubHeader header="Add / Remove Floc" />
      <ModalBody>
        <Table key={'flock data'} bordered style={{ borderColor: '#000' }}>
          <thead>
            <tr key={'header'}>
              <th></th>
              {flocHeaderKeys?.map((key: any) => <th key={key}>{key}</th>)}
            </tr>
          </thead>
          <tbody>
            {data?.systems?.map((item: any, index: any) => (
              <tr key={`${index}-${item?._id}`}>
                <td>
                  <Input
                    type="checkbox"
                    checked={selected?.includes(item?._id)}
                    onClick={(e: any) =>
                      e?.target?.checked
                        ? setSelected([...selected, item?._id])
                        : setSelected(
                            selected?.filter((a: any) => a !== item?._id)
                          )
                    }
                  />
                </td>
                {flocHeaderKeys.map((key: any, index: number) => (
                  <td key={index}>{item[key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button
          color="warning"
          onClick={() => {
            setFLocModal(false)
            setSubmitting(false)
          }}
        >
          Close
        </Button>
        <Button
          color="warning"
          disabled={submitting}
          onClick={async (e: any) => {
            e.preventDefault()
            setSubmitting(true)
            const flocData = data?.systems?.map((a: any) => a?._id)

            const body: any = {
              addFloc: selected,
              removeFloc: flocData?.filter((c: any) => !selected?.includes(c))
            }

            try {
              const res = await makeAuthenticatedRequest(
                `${systemsApi}/updateFlocSystem?systemNumber=${systemNumber}`,
                'PATCH',
                body,
                accounts,
                instance
              )

              if (res?.status === 'success') {
                await refetchFloc()
                setSubmitting(false)
                setFLocModal(false)
                toast('Import Successfully Completed')
              }

              return res?.data?.status
            } catch (error) {
              console.error(error)
            }
          }}
        >
          {submitting ? 'Saving...' : 'Save'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const FailureModesModal = ({
  isOpen,
  close,
  data,
  selected,
  setSelected,
  refetchFloc,
  flocId
}: any) => {
  const { accounts, instance } = useMsal()
  const [submitting, setSubmitting]: any = useState(false)
  return (
    <>
      <Modal isOpen={isOpen} fullscreen>
        <SubHeader header="Select Failure Modes" />
        <ModalBody>
          {data?.length > 0 && (
            <>
              <Table
                key={'failure modes data'}
                style={{ width: 'max-content', borderColor: '#000' }}
                bordered
              >
                <thead>
                  <tr key={'header'}>
                    <th></th>
                    {failureModesHeaderKeys?.map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item: any, index: any) => (
                    <tr key={`${index}-${item?.['HIERARCHY FLOC ID']}`}>
                      <td>
                        <Input
                          type="checkbox"
                          checked={selected?.includes(item?._id)}
                          onClick={(e: any) =>
                            e?.target?.checked
                              ? setSelected([...selected, item?._id])
                              : setSelected(
                                  selected?.filter((a: any) => a !== item?._id)
                                )
                          }
                        />
                      </td>
                      {failureModesHeaderKeys.map((key: any, index: number) => (
                        <td key={index}>{item[key]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={close}>
            Close
          </Button>
          <Button
            color="warning"
            disabled={submitting}
            onClick={async (e: any) => {
              e.preventDefault()
              setSubmitting(true)

              try {
                const res = await makeAuthenticatedRequest(
                  `${systemsApi}/updateFlocFailureModes?flocId=${flocId}`,
                  'PATCH',
                  selected,
                  accounts,
                  instance
                )

                if (res?.status === 'success') {
                  await refetchFloc()
                  setSubmitting(false)
                  close()
                  toast('Updated Successfully')
                }

                return res?.data?.status
              } catch (error) {
                console.error(error)
              }
            }}
          >
            {submitting ? 'Saving...' : 'Save'}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default function RcmSystemDetail() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()

  const fetchSystemDetail = async () => {
    const response = await makeAuthenticatedRequest(
      `${systemsApi}/functionFailure/${id}`,
      'GET',
      null,
      accounts,
      instance
    )
    return response
  }

  const { data, refetch }: any = useQuery(
    'fetchSystemDetail',
    fetchSystemDetail
  )

  const { system, floc } = data || {}

  const fetchMaintainLibraries = async () => {
    const response = await makeAuthenticatedRequest(
      `${systemsApi}/maintenanceLibrary`,
      'GET',
      null,
      accounts,
      instance
    )
    return response
  }

  const { data: mQuery }: any = useQuery(
    'fetchMaintainLibraries data System Page',
    fetchMaintainLibraries
  )

  const { maintenanceLibraries } = mQuery || {}

  const fetchFailureModes = async () => {
    const response = await makeAuthenticatedRequest(
      `${systemsApi}/failureMode`,
      'GET',
      null,
      accounts,
      instance
    )
    return response
  }

  const { data: failureModes }: any = useQuery(
    'failureModes data system detail page',
    fetchFailureModes
  )

  const [flocModal, setFLocModal] = useState(false)
  const [assignStrategy, setAssignStrategy]: any = useState(false)
  const [selectedStragtegy, setSelectedStrategy]: any = useState()
  const [selectedFloc, setSelectedFloc]: any = useState()
  const [failureModesModal, setFailureModesModal]: any = useState(false)
  const [selectedFModes, setSelectedFModes]: any = useState(
    selectedFloc?.failureModes?.map((a: any) => a?._id) || []
  )

  useEffect(() => {
    setSelectedFModes(selectedFloc?.failureModes?.map((a: any) => a?._id) || [])
  }, [selectedFloc])

  return (
    <ProtectedLayout onBack={'/cmms/rcm/sytems'} title={'System'}>
      <Row style={{ margin: 20 }}>
        <div style={{ display: 'flex' }}>
          <IconButton
            icon={<TbPlusMinus size={25} />}
            buttonText={'FLoc'}
            onClick={() => setFLocModal(true)}
          />
          <IconButton
            icon={<MdAssignmentAdd size={25} />}
            buttonText={'Assign to Strategy'}
            onClick={() => setAssignStrategy(true)}
          />
        </div>
        <SubHeader header={system?.['System Name']} />
        <Table
          style={{
            width: '90vw',
            overflow: 'scroll',
            height: 100,
            borderColor: '#000'
          }}
          bordered
        >
          <thead>
            <tr key={'header'}>
              <th>S.No</th>
              {flocHeaderKeys?.map((key: any) => <th>{key}</th>)}
            </tr>
          </thead>
          <tbody>
            {floc?.map((item: any, index: any) => (
              <tr
                key={`${index}-${item?.['HIERARCHY FLOC ID']}`}
                style={{
                  cursor: 'pointer',
                  backgroundColor:
                    item?.['HIERARCHY FLOC ID'] ===
                    selectedFloc?.['HIERARCHY FLOC ID']
                      ? 'gold'
                      : 'transparent'
                }}
                onClick={() => setSelectedFloc(item)}
              >
                <td>{incrementby1(index)}</td>
                {flocHeaderKeys?.map((key: any, index: number) => (
                  <td key={index}>{item[key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
        {selectedFloc && (
          <>
            <Row style={{ margin: 0 }}>
              <Col xs="3">
                <Box heading="Floc Details">
                  {flocHeaderKeys?.map((a: any) =>
                    renderData(a, selectedFloc[a])
                  )}
                </Box>
              </Col>
              <Col xs="9">
                <Col xs="2">
                  <IconButton
                    icon={<TbPlusMinus size={25} />}
                    buttonText={'Failure Modes'}
                    onClick={() => setFailureModesModal(true)}
                  />
                  {selectedFloc?.failureModes?.length > 0 && (
                    <Table
                      key={'failure modes data'}
                      style={{
                        width: 'max-content',
                        borderColor: '#000',
                        marginTop: 10
                      }}
                      bordered
                    >
                      <thead>
                        <tr key={'header'}>
                          {failureModesHeaderKeys?.map((key) => (
                            <th key={key}>{key}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {selectedFloc?.failureModes?.map(
                          (item: any, index: any) => (
                            <tr key={`${index}-${item?.['HIERARCHY FLOC ID']}`}>
                              {failureModesHeaderKeys.map(
                                (key: any, index: number) => (
                                  <td key={index}>{item[key]}</td>
                                )
                              )}
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Col>
            </Row>
          </>
        )}
        {selectedStragtegy?.data && (
          <>
            <SubHeader
              header={`Maintenance Strategy - ${selectedStragtegy?.title}`}
            />
            <>
              <Table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    {maintLibraryHeaders?.map((h: any) => <th key={h}>{h}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {selectedStragtegy?.data?.map((l: any, index: any) => (
                    <tr key={`${l?._id}-${index}`}>
                      <td>{incrementby1(index)}</td>
                      {maintLibraryDataKeys?.map((k: any) => (
                        <td key={l?._id}>{l[k]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          </>
        )}
      </Row>
      <FLocList
        flocModal={flocModal}
        setFLocModal={setFLocModal}
        systemNumber={system?.['System Number']}
        refetchFloc={refetch}
      />
      <FailureModesModal
        data={failureModes?.systems}
        isOpen={failureModesModal}
        close={() => setFailureModesModal(false)}
        selected={selectedFModes}
        setSelected={setSelectedFModes}
        refetchFloc={refetch}
        flocId={selectedFloc?._id}
      />
      <ToastContainer
        theme="dark"
        position="top-center"
        style={{ fontSize: 16 }}
      />
      <Modal isOpen={assignStrategy} size="md">
        <SubHeader header="Assing to Strategy" />
        <ModalBody>
          <CustomLabel label="Select Strategy" />
          <Input
            type="select"
            onClick={(e: any) =>
              setSelectedStrategy(
                maintenanceLibraries?.filter(
                  (a: any) => a?.title === e?.target?.value
                )?.[0]
              )
            }
          >
            <option value="">Select</option>
            {maintenanceLibraries?.map((a: any) => (
              <option value={a?.title} key={a?.title}>
                {a?.title}
              </option>
            ))}
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button
            color="warning"
            onClick={() => setAssignStrategy(false)}
            size="sm"
          >
            Close
          </Button>
          <Button
            color="warning"
            size="sm"
            onClick={() => setAssignStrategy(false)}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </ProtectedLayout>
  )
}
