/* eslint-disable no-loop-func */
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import React, { useEffect, useState, useRef } from 'react'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import CustomLabel from '../../components/InputFields/CustomLabel'
import RcmLayout from '../../components/rcm/RcmLayout'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { Box, Link } from '../workManagement/PmTree'
import { disciplineDepartments, tagsList, taskInterval } from './rcmConstants'
import { getRcmRiskColor } from './rcmLib'
import { LoadingState } from './RcmViewStage4'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import { RcmBasicDetails } from './RcmViewStage1'
import { SubHeader } from '../VerifyCM'
import RcmStepper from './RcmStepper'

const totalCost = (
  spare: number,
  externamCost: number,
  resource1: number,
  resource2: number,
  resource3: number
) => {
  const totalResourceCost = resource1 + resource2 + resource3 * 23
  const result = spare + externamCost + totalResourceCost

  return result
}

function TaskModal({
  onClose,
  mode,
  index,
  entry,
  rcmData,
  tags,
  onSave,
  task
}: {
  onClose: any
  mode: any
  index: any
  entry: any
  rcmData: any
  tags: any
  onSave: any
  task: any
}) {
  const [values, setValues]: any = useState(task)
  const [viewDiscipline2, setViewDiscipline2] = useState(false)
  const [viewDiscipline3, setViewDiscipline3] = useState(false)
  const [currentTag, setCurrentTag]: any = useState()
  const [selectedTags, setSelectedTags]: any = useState(values?.tags || [])

  const handleTaskChange = (field: any, value: any) => {
    setValues((prev: any) => ({ ...prev, [field]: value }))
  }

  const calculateTotalCost = () => {
    const yearlyMaintenance = totalCost(
      Number(values?.spareCost) || 0,
      Number(values?.externalCost) || 0,
      Number(values?.discipline1Hours) || 0,
      Number(values?.discipline2Hours) || 0,
      Number(values?.discipline3Hours) || 0
    )
    handleTaskChange('yearlyMaintenance', yearlyMaintenance)
  }

  return (
    <Modal isOpen fullscreen>
      <ModalBody>
        <SubHeader
          permitColor={'gold'}
          header={`Task Details Sheet - ${task?.taskname}`}
        />
        <Row>
          <Col
            md={12}
            className="d-flex justify-content-center align-items-center"
          >
            <div
              style={{
                border: '2px solid gold',
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'column',
                height: '18vh',
                justifyContent: 'center',
                marginRight: 10,
                padding: 10,
                width: 500
              }}
            >
              {[
                {
                  key: 'Number',
                  value: rcmData?.number
                },
                {
                  key: 'System',
                  value: mode
                },
                {
                  key: 'Equipment / Maintainable Items',
                  value: tags
                },
                {
                  key: 'Failure Mode',
                  value: entry?.failureMode
                },
                {
                  key: 'Econimic Value',
                  value: entry?.economicEffect
                },
                {
                  key: 'Clamp Recommended Task',
                  value: entry?.rcmDecision
                }
              ]?.map(({ key, value }: any) => (
                <p className="m-0 small" key={key}>
                  <b>{key} :</b> {value}
                </p>
              ))}
            </div>
            <div
              style={{
                border: '2px solid gold',
                borderRadius: 5,
                padding: 10,
                height: '18vh',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Table bordered style={{ borderColor: '#000' }}>
                <thead>
                  <tr>
                    <th>MTBF</th>
                    <th>Health / Safety</th>
                    <th>Environment</th>
                    <th>Economic</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: 12 }}>
                  <tr key={index}>
                    <td style={{ width: '10%' }}>{entry?.mtbf}</td>
                    <td style={{ width: '10%' }}>
                      <Col
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          minHeight: 60,
                          height: 'auto',
                          backgroundColor: getRcmRiskColor(
                            Number(entry?.healthSafetyScore)
                          ),
                          cursor: 'pointer',
                          color: '#fff'
                        }}
                      >
                        <p className="m-0">
                          {entry?.healthSafety} - {entry?.healthSafetyScore}
                        </p>
                      </Col>
                    </td>
                    <td style={{ width: '10%' }}>
                      <Col
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          minHeight: 60,
                          height: 'auto',
                          backgroundColor: getRcmRiskColor(
                            Number(entry?.environmentScore)
                          ),
                          cursor: 'pointer',
                          color: '#fff'
                        }}
                      >
                        <p className="m-0">
                          {entry?.environment} - {entry?.environmentScore}
                        </p>
                      </Col>
                    </td>
                    <td style={{ width: '10%' }}>
                      <Col
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          minHeight: 60,
                          height: 'auto',
                          backgroundColor: getRcmRiskColor(
                            Number(entry?.economicScore)
                          ),
                          cursor: 'pointer',
                          color: '#fff'
                        }}
                      >
                        <p className="m-0">
                          {entry?.econonmic} - {entry?.economicScore}
                        </p>
                      </Col>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
          <Row className="d-flex justify-content-center">
            {/* <Col md={3}>
              <CustomLabel label="Task Status" />
              <Input
                type="select"
                value={values?.taskStatus || 'Select'}
                onChange={(e: any) =>
                  handleTaskChange('taskStatus', e?.target?.value)
                }
              >
                <option>Select</option>
                {['EXISTING', 'NEW']?.map((a: any) => (
                  <option key={a} value={a}>
                    {a}
                  </option>
                ))}
              </Input>
            </Col>
            <Col xs={3}>
              <CustomLabel label="Task Interval" />
              <Input
                type="select"
                value={values?.taskInterval || 'Select'}
                onChange={(e: any) => {
                  handleTaskChange('taskInterval', e?.target?.value)
                  handleTaskChange('givenTaskInterval', e?.target?.value)
                  calculateTotalCost()
                }}
              >
                <option disabled value="Select">
                  Select
                </option>
                {[
                  'Daily',
                  'Weekly',
                  'Monthly',
                  '3 Monthly',
                  '6 Monthly',
                  '1 Yearly',
                  '2 Yearly',
                  '3 Yearly',
                  '5 Yearly'
                ]?.map((a: any) => (
                  <option key={a} value={a}>
                    {a}
                  </option>
                ))}
              </Input>
            </Col> */}
            <Col xs={3}>
              <CustomLabel label="Compliance / Regulatory / Class / Flag State" />
              <div
                style={{
                  border: '1px solid #DCDCDC',
                  padding: 5,
                  borderRadius: 5
                }}
              >
                <Input
                  name="compliance"
                  type="radio"
                  defaultChecked={task?.compliance === 'Yes'}
                  value={'Yes'}
                  onChange={(e: any) => {
                    handleTaskChange('compliance', e?.target?.value)
                  }}
                />{' '}
                Yes {'  '}
                <Input
                  name="compliance"
                  type="radio"
                  value={'No'}
                  defaultChecked={task?.compliance === 'No'}
                  onChange={(e: any) => {
                    handleTaskChange('compliance', e?.target?.value)
                  }}
                />{' '}
                No
              </div>
            </Col>
          </Row>
          <Col xs={12}>
            <SubHeader permitColor={'gold'} header="Add Associated Tags" />
          </Col>
          <Row
            className="d-flex justify-content-center "
            style={{ marginBottom: 10 }}
          >
            <Col xs="3">
              <Input
                type="select"
                onChange={(e: any) => {
                  setCurrentTag(tagsList?.[e?.target?.value])
                  handleTaskChange('tags', selectedTags)
                }}
              >
                <option>Select</option>
                {tagsList?.map((a: any, index: any) => (
                  <option
                    key={a?.tagNo || a?.itemNumber || a?.equipmentNumber}
                    value={index}
                  >
                    {a?.tagNo || a?.itemNumber || a?.equipmentNumber}
                  </option>
                ))}
              </Input>
            </Col>
            <Col xs="3" className="mb-10">
              {currentTag && (
                <Button
                  style={{ width: '-webkit-fill-available' }}
                  color="warning"
                  onClick={() => setSelectedTags([...selectedTags, currentTag])}
                >
                  Assign Tag
                </Button>
              )}
            </Col>
          </Row>
          {selectedTags?.map((st: any, Tindex: any) => (
            <Row className="d-flex justify-content-center" key={st?.tag}>
              <Col xs="3">
                <Input
                  type="text"
                  disabled
                  value={st?.tagNo || st?.itemNumber || st?.equipmentNumber}
                />
              </Col>
              <Col xs="6" style={{ marginBottom: 10 }}>
                <Input
                  type="text"
                  disabled
                  value={
                    st?.tagDescription ||
                    st?.itemDescription ||
                    st?.equipmentDescription
                  }
                />
              </Col>
              <Col xs="1">
                <Link
                  onClick={() =>
                    setSelectedTags(
                      selectedTags.filter((s: any) => s?.tag !== st?.tag)
                    )
                  }
                >
                  Delete
                </Link>
              </Col>
            </Row>
          ))}
          <Col xs={12}>
            <SubHeader
              permitColor={'gold'}
              header="Calculation of Yearly Maintenance Cost"
            />
          </Col>
          <Row className="d-flex justify-content-center">
            <Col xs={3}>
              <CustomLabel label="External Cost" />
              <Input
                type="text"
                value={values?.externalCost}
                onChange={(e: any) => {
                  handleTaskChange('externalCost', e?.target?.value)
                  calculateTotalCost()
                }}
              />
            </Col>
            <Col md={3}>
              <CustomLabel label="Spare Cost" />
              <Input
                value={values?.spareCost}
                type="text"
                onChange={(e: any) => {
                  handleTaskChange('spareCost', e?.target?.value)
                  calculateTotalCost()
                }}
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={3}>
              <CustomLabel label="Discipline 1" />
              <Input
                type="select"
                value={values?.discipline1 || 'Select'}
                onChange={(e: any) =>
                  handleTaskChange('discipline1', e?.target?.value)
                }
              >
                <option>Select</option>
                {disciplineDepartments?.map((a: any) => (
                  <option key={a} value={a}>
                    {a}
                  </option>
                ))}
              </Input>
            </Col>
            <Col md={3}>
              <CustomLabel label="Discipline 1 Hours" />
              <Input
                type="text"
                onChange={(e: any) => {
                  handleTaskChange('discipline1Hours', e?.target?.value)
                  calculateTotalCost()
                }}
              />
            </Col>
          </Row>
          {!viewDiscipline2 && (
            <Row className="d-flex justify-content-center">
              <Col md={3}>
                <Button
                  color="warning"
                  style={{ marginTop: 10, width: '-webkit-fill-available' }}
                  onClick={() => setViewDiscipline2(true)}
                >
                  Add Discipline
                </Button>
              </Col>
            </Row>
          )}
          <Row className="d-flex justify-content-center">
            {viewDiscipline2 && (
              <>
                <Col md={3}>
                  <CustomLabel label="Discipline 2" />
                  <Input
                    value={values?.discipline2}
                    type="select"
                    onChange={(e: any) =>
                      handleTaskChange('discipline2', e?.target?.value)
                    }
                  >
                    <option>Select</option>
                    {disciplineDepartments?.map((a: any) => (
                      <option key={a} value={a}>
                        {a}
                      </option>
                    ))}
                  </Input>
                </Col>
                <Col md={3}>
                  <CustomLabel label="Discipline 2 Hours" />
                  <Input
                    type="text"
                    value={values?.discipline2Hours}
                    onChange={(e: any) => {
                      handleTaskChange('discipline2Hours', e?.target?.value)
                      calculateTotalCost()
                    }}
                  />
                </Col>
              </>
            )}
          </Row>
          {viewDiscipline2 && !viewDiscipline3 && (
            <Row className="d-flex justify-content-center">
              <Col md={3}>
                <Button
                  color="warning"
                  style={{ marginTop: 10, width: '-webkit-fill-available' }}
                  onClick={() => setViewDiscipline3(true)}
                >
                  Add Discipline
                </Button>
              </Col>
            </Row>
          )}
          {viewDiscipline3 && (
            <Row className="d-flex justify-content-center">
              <Col md={3}>
                <CustomLabel label="Discipline 3" />
                <Input
                  type="select"
                  value={values?.discipline3 || 'Select'}
                  onChange={(e: any) =>
                    handleTaskChange('discipline3', e?.target?.value)
                  }
                >
                  <option>Select</option>
                  {disciplineDepartments?.map((a: any) => (
                    <option key={a} value={a}>
                      {a}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col md={3}>
                <CustomLabel label="Discipline 3 Hours" />
                <Input
                  type="text"
                  value={values?.discipline3Hours}
                  onChange={(e: any) => {
                    handleTaskChange('discipline3Hours', e?.target?.value)
                    calculateTotalCost()
                  }}
                />
              </Col>
            </Row>
          )}

          <Col md={3}></Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => onClose(values)} color="warning">
          Close
        </Button>
        <Button onClick={() => onSave(values)} color="warning">
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default function RcmViewStage7() {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchRcm', fetchRcm)

  const fetchRiskMatrix = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/riskMatrix`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data: riskData, isLoading }: any = useQuery(
    'fetchRiskMatrix',
    fetchRiskMatrix
  )
  const riskMatrixData = riskData?.riskMatrix?.[0]
  const riskToleranceLevel = Number(riskMatrixData?.riskToleranceLevel) || 16

  const [submit, setSubmit]: any = useState(false)
  const [result, setResult]: any = useState({})
  const [taskModal, setTaskModal]: any = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const taskModalRef = useRef(false)

  const toggleModal = () => {
    taskModalRef.current = !taskModalRef.current
    setIsModalVisible(taskModalRef.current)
  }

  const currentAnalysisData = data?.analysis
  const hasCurrentRcm = currentAnalysisData?.number
  const riskDetails = currentAnalysisData?.riskDetails || {}
  const categorizedEquipment = currentAnalysisData?.categorizedEquipment

  const [entries, setEntries] = useState(riskDetails)
  const [updateStage, setUpdateStage]: any = useState(false)

  useEffect(() => {
    if (Object.keys(entries)?.length <= 0) {
      setEntries(riskDetails)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.analysis?.failuresModes, riskDetails])

  if (isLoading) {
    return <LoadingState />
  }

  const handleTaskInputChange = (
    categoryKey: any,
    failureIndex: number,
    taskIndex: number,
    field: string,
    value: any
  ) => {
    setEntries((prev: any) => {
      const updatedEntries = { ...prev }

      const currentCategory = updatedEntries[categoryKey]
      if (currentCategory && currentCategory[failureIndex]?.tasks) {
        const currentFailureMode = { ...currentCategory[failureIndex] }

        const updatedTasks = [...currentFailureMode.tasks]
        const updatedTask = { ...updatedTasks[taskIndex], [field]: value }
        updatedTasks[taskIndex] = updatedTask

        currentFailureMode.tasks = updatedTasks
        currentCategory[failureIndex] = currentFailureMode

        updatedEntries[categoryKey] = currentCategory

        return updatedEntries
      }
      return prev
    })
  }

  const calculateTotalCost = (tasks: any[], key: any) => {
    return tasks.reduce((sum, task) => {
      const yearlyMaintenance = Number(task.yearlyMaintenance) || 0
      const intervalValue = taskInterval?.[task?.[key]] || 1
      return sum + yearlyMaintenance * intervalValue
    }, 0)
  }

  const performAnalysis = () => {
    const maxIterations = 10
    const entriesKeys = Object.keys(entries)
    const newEntries = Object.keys(entries)?.map(
      (key: any) =>
        entries?.[key]?.map((failureMode: any, index: any) => {
          const economicEffect = Number(failureMode?.economicEffect) || 0
          let iterationIndex = 0
          let prevResult = 0

          let totalMaintenanceCost = calculateTotalCost(
            failureMode?.tasks,
            iterationIndex === 0 ? 'givenTaskInterval' : 'recommendedInterval'
          )
          let result = economicEffect / totalMaintenanceCost

          console.log(
            'key',
            key,
            'index',
            index,
            'Ieration',
            iterationIndex,
            'result',
            result,
            'economic Effect',
            economicEffect,
            'total Maintenance',
            totalMaintenanceCost
          )
          console.log(failureMode?.tasks)
          while (result < 1 && iterationIndex < maxIterations) {
            failureMode.tasks
              .filter((task: any) => task.compliance === 'No')
              .forEach((task: any) => {
                const intervalKeys = Object.keys(taskInterval)
                const currentIntervalKey = task.taskInterval
                const nextIntervalIndex =
                  intervalKeys.indexOf(currentIntervalKey) + 1
                const nextIntervalKey = intervalKeys[nextIntervalIndex]
                const newInterval = taskInterval[nextIntervalKey]

                if (newInterval) {
                  task.taskInterval = nextIntervalKey
                  task.recommendedInterval = nextIntervalKey
                }
              })

            totalMaintenanceCost = calculateTotalCost(
              failureMode.tasks,
              iterationIndex === 0 ? 'givenTaskInterval' : 'recommendedInterval'
            )
            prevResult = result
            result = economicEffect / totalMaintenanceCost

            // if (Math.abs(prevResult - result) < 0.001) break

            iterationIndex++
          }

          return {
            ...failureMode,
            tasks: failureMode.tasks.map((task: any) => ({
              ...task,
              recommendedInterval: task.recommendedInterval || task.taskInterval
            }))
          }
        })
    )

    const finalEntries = Object.fromEntries(
      entriesKeys.map((year, index) => [year, newEntries[index]])
    )

    return finalEntries
  }

  // const calculateTotalCost = (tasks: any[], key: string) => {
  //   return tasks.reduce((sum, task) => {
  //     const yearlyMaintenance = Number(task.yearlyMaintenance) || 0;
  //     const intervalValue = taskInterval[task[key]] || 1;
  //     return sum + yearlyMaintenance * intervalValue;
  //   }, 0);
  // };

  // const performAnalysis = () => {
  //   const maxIterations = 10;
  //   const entriesKeys = Object.keys(entries);

  //   const newEntries = entriesKeys.map((key: any) =>
  //     entries[key].map((failureMode: any) => {
  //       const economicEffect = 812;
  //       let iterationIndex = 0;
  //       let prevResult = 0;

  //       // Initial total maintenance cost
  //       let totalMaintenanceCost = calculateTotalCost(failureMode.tasks, 'taskInterval');
  //       let result = economicEffect / totalMaintenanceCost;

  //       console.log(`Iteration ${iterationIndex}:`, { result, economicEffect, totalMaintenanceCost });

  //       while (result < 1 && iterationIndex < maxIterations) {
  //         // Find the task with the highest interval cost to adjust
  //         let maxCostTaskIndex = 0;
  //         let maxCost = 0;

  //         failureMode.tasks.forEach((task: any, index: number) => {
  //           const intervalValue = taskInterval[task.taskInterval] || 1;
  //           const cost = Number(task.yearlyMaintenance) * intervalValue;

  //           if (task.compliance === 'No' && cost > maxCost) {
  //             maxCost = cost;
  //             maxCostTaskIndex = index;
  //           }
  //         });

  //         // Adjust the interval of the highest-cost task
  //         const taskToAdjust = failureMode.tasks[maxCostTaskIndex];
  //         const currentIntervalKey = taskToAdjust.taskInterval;
  //         const intervalKeys = Object.keys(taskInterval);
  //         const nextIntervalIndex = intervalKeys.indexOf(currentIntervalKey) + 1;
  //         const nextIntervalKey = intervalKeys[nextIntervalIndex];

  //         if (nextIntervalKey) {
  //           taskToAdjust.taskInterval = nextIntervalKey;
  //           taskToAdjust.recommendedInterval = nextIntervalKey;
  //         } else {
  //           // Exit if no further interval is available for adjustment
  //           console.warn(`No valid interval found for task ${taskToAdjust.taskname} - exiting loop.`);
  //           break;
  //         }

  //         // Recalculate the total cost with updated intervals
  //         totalMaintenanceCost = calculateTotalCost(failureMode.tasks, 'taskInterval');
  //         prevResult = result;
  //         result = economicEffect / totalMaintenanceCost;

  //         console.log(`Iteration ${iterationIndex + 1}:`, { result, totalMaintenanceCost });

  //         // Break if result stabilizes
  //         if (Math.abs(prevResult - result) < 0.000000001) break;

  //         iterationIndex++;
  //       }

  //       // Return updated failure mode with adjusted intervals
  //       return {
  //         ...failureMode,
  //         tasks: failureMode.tasks.map((task: any) => ({
  //           ...task,
  //           recommendedInterval: task.recommendedInterval || task.taskInterval,
  //         })),
  //       };
  //     })
  //   );

  //   const finalEntries = Object.fromEntries(
  //     entriesKeys.map((year, index) => [year, newEntries[index]])
  //   );

  //   return finalEntries;
  // };

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15, maxWidth: '87vw' }}>
        <Col>
          <h4
            style={{ fontWeight: 700 }}
            children={`${hasCurrentRcm ? currentAnalysisData?.number : ''}`}
          />
          <RcmStepper stage={currentAnalysisData?.status} />
          <Spacer height={20} />
          <RcmBasicDetails data={currentAnalysisData} />
          <Spacer height={20} />
          <div
            style={{
              backgroundColor: '#fff',
              margin: 0,
              padding: 10,
              borderRadius: 10
            }}
          >
            {Object.keys(riskDetails).map(
              (mode: any, modeIndex: number) =>
                riskDetails?.[mode]?.some(
                  (entry: any) => Number(entry.riskScore) > riskToleranceLevel
                ) && (
                  <div key={modeIndex}>
                    {entries?.[mode]?.map(
                      (failureMode: any, failureIndex: any) => (
                        <React.Fragment key={failureMode?._id}>
                          <Table
                            bordered
                            style={{ borderColor: '#000', border: '1px solid' }}
                          >
                            <tbody>
                              <tr>
                                <td style={{ width: '15vw' }}>
                                  <h4>{mode}</h4>
                                  <p className="m-0">
                                    <strong>Associated Equipment:</strong>{' '}
                                    {categorizedEquipment[mode]
                                      ?.map((a: any) => a?.equipmentNumber)
                                      ?.join(', ')}{' '}
                                  </p>
                                  <p>
                                    <strong>Functions:</strong>{' '}
                                    {currentAnalysisData?.functions?.[mode]}
                                  </p>
                                </td>
                                <td
                                  style={{
                                    width: '15vw',
                                    alignContent: 'center'
                                  }}
                                >
                                  <h4 className="m-0">Failure Mode</h4>
                                  <p className="m-0">
                                    {failureMode?.failureMode}
                                  </p>
                                </td>
                                {[
                                  {
                                    value: failureMode?.healthSafetyScore,
                                    label: 'Health Safety Score'
                                  },
                                  {
                                    value: failureMode?.economicScore,
                                    label: 'Economic Score'
                                  },
                                  {
                                    value: failureMode?.environmentScore,
                                    label: 'Environment Score'
                                  }
                                ]?.map((s: any, index: any) => (
                                  <td
                                    key={`${s}-${s?.label}-${index}`}
                                    style={{ width: '8vw' }}
                                    className="text-center align-content-center"
                                  >
                                    <p
                                      className="m-0"
                                      style={{
                                        textAlign: 'center',
                                        alignContent: 'center',
                                        height: '3vh',
                                        backgroundColor: getRcmRiskColor(
                                          s?.value
                                        )
                                      }}
                                    >
                                      {s?.label}: {s?.value}
                                    </p>
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </Table>
                          <Table striped>
                            <thead>
                              <tr
                                style={{ backgroundColor: 'lightgray' }}
                                className="text-center"
                              >
                                <th className="top-left">S.No</th>
                                <th>Task Name</th>
                                <th>Task Type</th>
                                <th>Task Interval</th>
                                <th>Task Status</th>
                                <th className="top-right"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {failureMode?.tasks?.length > 0 &&
                                failureMode?.tasks?.map(
                                  (task: any, taskIndex: number) => (
                                    <>
                                      <tr
                                        className="text-center"
                                        key={taskIndex}
                                      >
                                        <td>{taskIndex + 1}</td>
                                        <td style={{ textAlign: 'left' }}>
                                          {task.taskname}
                                        </td>
                                        <td>{task.taskType}</td>
                                        <td>{task.taskInterval}</td>
                                        <td>{task.taskStatus}</td>
                                        <td>
                                          <Button
                                            color={
                                              task.compliance
                                                ? 'success'
                                                : 'warning'
                                            }
                                            onClick={() => {
                                              setTaskModal({
                                                mode,
                                                failureIndex,
                                                taskIndex,
                                                entry: failureMode,
                                                task: task
                                              })
                                              toggleModal()
                                            }}
                                          >
                                            Task Details
                                          </Button>
                                        </td>
                                      </tr>
                                    </>
                                  )
                                )}
                            </tbody>
                          </Table>
                        </React.Fragment>
                      )
                    )}
                  </div>
                )
            )}
          </div>
          {isModalVisible && (
            <TaskModal
              rcmData={currentAnalysisData}
              tags={categorizedEquipment?.[taskModal?.mode]
                ?.map((a: any) => a?.equipmentNumber)
                ?.join(', ')}
              onClose={async (values: any) => {
                const tasks = Object.keys(values)?.map(async (k: any) => {
                  await handleTaskInputChange(
                    taskModal?.mode,
                    taskModal?.failureIndex,
                    taskModal?.taskIndex,
                    k,
                    values[k]
                  )
                })

                await Promise.all(tasks)
                toggleModal()
              }}
              onSave={async (values: any) => {
                const tasks = Object.keys(values)?.map(async (k: any) => {
                  await handleTaskInputChange(
                    taskModal?.mode,
                    taskModal?.failureIndex,
                    taskModal?.taskIndex,
                    k,
                    values[k]
                  )
                })

                await Promise.all(tasks)
                toggleModal()
                setSubmit(true)
              }}
              mode={taskModal?.mode}
              index={taskModal?.index}
              entry={taskModal?.entry}
              task={taskModal?.task}
            />
          )}

          <SubmitModalWithForm
            isOpen={submit}
            onClose={(val: any) => {
              setSubmit(false)
            }}
            onSubmit={async (e: any) => {
              e?.preventDefault()

              const body: any = {
                ...currentAnalysisData,
                riskDetails: entries,
                status: 'Stage7'
              }

              const res = await makeAuthenticatedRequest(
                `${rcmApi}/rcmAnalysis/${id}`,
                'PATCH',
                body,
                accounts,
                instance
              )

              if (res.status === 'success') {
                await refetch()
                setResult(res?.analysis)
              }

              return res.status
            }}
            sucessView={<p>RCM Number: {result?.number}</p>}
            size="xs"
            header="Failure Modes"
          />

          <SubmitModalWithForm
            isOpen={updateStage}
            onClose={(val: any) => {
              setUpdateStage(false)
            }}
            onSubmit={async (e: any) => {
              e?.preventDefault()

              const body: any = {
                ...currentAnalysisData,
                riskDetails: await performAnalysis(),
                status: 'Stage8'
              }
              console.log(body)

              const res = await makeAuthenticatedRequest(
                `${rcmApi}/rcmAnalysis/${id}`,
                'PATCH',
                body,
                accounts,
                instance
              )

              if (res.status === 'success') {
                await refetch()
                setResult(res?.analysis)
              }

              return res.status
            }}
            sucessView={<p>RCM Number: {result?.number}</p>}
            size="xs"
            header="Move to Stage 8"
          />
        </Col>
      </Row>
      <ModalFooter>
        <Button
          color="warning"
          onClick={async () => {
            // performAnalysis()
            setUpdateStage(true)
          }}
        >
          Calculate
        </Button>
      </ModalFooter>
    </RcmLayout>
  )
}
