import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import React from 'react'
import { useAdminPermissions } from '../eptw/EditRisks'
import ViewPermit from '../eptw/ViewPermit'

export default function CloseOutPermit() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  return (
    <ProtectedLayout onBack="/eptw/closeOutReports">
      <ViewPermit data={data} isLoading={isLoading} />
    </ProtectedLayout>
  )
}
