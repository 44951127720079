import axios from 'axios'
import { useEffect, useState } from 'react'
import { HiOutlineSquares2X2, HiSquares2X2 } from 'react-icons/hi2'
import { Button, Col } from 'reactstrap'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import toNormalCase from '../components/lib/toNormalCase'
import CloseCMPermit from '../components/Modals/CloseCMPermit'
import { protectiveMaintenancePermit } from '../components/serverurl'
import SubmitModal from '../components/SubmitModal'
import theme from './../components/constants/defaultTheme.json'
import PMAction from './PMAction'

const INITIAL_STATE = {
  title: 'Are you sure you want to submit?',
  icon: null,
  details: null
}

function SidePane({ list, activeItem, setActiveItem }: any) {
  return (
    <>
      <div style={{ paddingLeft: 10, paddingRight: 10 }}>
        {list?.map((type: any) => (
          <div
            key={type}
            style={{
              border:
                activeItem === type
                  ? `2px solid ${theme.borderColor.white}`
                  : 'none',
              display: 'flex',
              padding: activeItem === type ? 5 : 7,
              backgroundColor:
                activeItem === type ? theme.colors.blue : 'transparent',
              borderRadius: 5,
              minWidth: '18vh',
              cursor: 'pointer',
              marginBottom: 35,
              boxShadow:
                'rgba(0, 0, 0, 0.4) 0px 1px 2px, rgba(0, 0, 0, 0.3) 0px 2px 3px -3px, rgba(0, 0, 0, 0.2) 0px -2px 0px inset'
            }}
            onClick={() => setActiveItem(type)}
          >
            {activeItem === type ? (
              <HiSquares2X2
                style={{
                  marginTop: 4,
                  color:
                    activeItem === type
                      ? theme.colors.white
                      : theme.colors.lightBlue
                }}
              />
            ) : (
              <HiOutlineSquares2X2
                style={{
                  marginTop: 4,
                  color:
                    activeItem === type
                      ? theme.colors.white
                      : theme.colors.lightBlue
                }}
              />
            )}
            <p
              style={{
                margin: '0px 0px 0px 10px',
                fontWeight: 700,
                color:
                  activeItem === type
                    ? theme.fontColor.white
                    : theme.fontColor.lightBlue
              }}
            >
              {type.woNumber}
            </p>
          </div>
        ))}
      </div>
    </>
  )
}

export default function VerifyPM({
  currentStatus,
  nextStatus,
  canClose
}: {
  currentStatus?: String
  nextStatus?: string | any
  canClose?: boolean
}) {
  const [data, setData]: any = useState()
  const [selected, setSeleced]: any = useState(data?.data[0])
  const [submit, setSubmit]: any = useState(false)
  const [message, setMessge]: any = useState(INITIAL_STATE)
  const [closeModal, setCloseModal]: any = useState(false)

  const fetchPermits = async () => {
    try {
      await axios
        .get(`${protectiveMaintenancePermit}?status=${currentStatus}`)
        .then((response: any) => {
          setData(response)
        })
    } catch (err) {
      console.warn(err)
    }
  }

  useEffect(() => {
    fetchPermits()
  }, [])

  // if (!data) {
  //   return (<Loader />)
  // }

  const onSubmit = () => {
    try {
      axios
        .patch(
          `${protectiveMaintenancePermit}?status=${nextStatus}&id=${selected?._id}`
        )
        .then((response: any) => {
          const data = response?.data?.data?.permit
          setMessge({
            title:
              response?.data?.status === 'success'
                ? 'Verified Successfully'
                : 'Unable to Verify',
            icon: response?.data?.status,
            details:
              response?.data?.status === 'success'
                ? {
                    'WO Number': data?.woNumber,
                    Title: data?.title,
                    Status: data?.status
                  }
                : null
            // response?.data?.data?.permit
          })
          fetchPermits()
        })
    } catch (error) {
      console.warn(error)
      return { satus: false }
    }
  }

  if (data?.length === 0) {
    return (
      <ProtectedLayout>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '50vh'
          }}
        >
          <p>No Permits to be Verified</p>
        </div>
      </ProtectedLayout>
    )
  }

  return (
    <ProtectedLayout
      hasSideList
      hasUserProfile
      onBack="/pm/dashboard"
      sidePanel={
        <SidePane
          list={data?.data}
          activeItem={selected}
          setActiveItem={setSeleced}
        />
      }
    >
      <PMAction data={selected} hasOngoingPermit={[]} />
      {!canClose && (
        <Col xs="12">
          <Button
            style={{ width: 'inherit' }}
            color="success"
            onClick={() => setSubmit(true)}
          >
            {toNormalCase(nextStatus)}
          </Button>
        </Col>
      )}
      {canClose && (
        <Col xs="12">
          <Button
            style={{ width: 'inherit' }}
            color="success"
            onClick={() => setCloseModal(true)}
          >
            Close
          </Button>
        </Col>
      )}
      <SubmitModal
        isOpen={submit}
        toggle={() => setSubmit(!submit)}
        onSubmit={() => onSubmit()}
        refetch={() => fetchPermits()}
        submitModalContent={message}
        afterClose={() => setMessge(INITIAL_STATE)}
        size="lg"
      />
      <CloseCMPermit
        isOpen={closeModal}
        toggle={() => setCloseModal(!closeModal)}
        permitId={selected?._id}
        fetchPermits={fetchPermits}
        type="PM"
      />
    </ProtectedLayout>
  )
}
