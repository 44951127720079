import { Field } from 'react-final-form'
import CustomLabel from './CustomLabel'
import TextInput from './TextInput'

import theme from './../constants/defaultTheme.json'

export default function LabeledCheckBoxes({
  label,
  name,
  list,
  hasOthers,
  othersName,
  occupy = 6,
  backgroundColor = theme.colors.lightGray,
  borderWidth = 1
}: any) {
  return (
    <div className={`col-md-${occupy}`} key={`${name}`}>
      <CustomLabel label={label} />
      <div
        style={{
          display: 'flex',
          flexFlow: 'wrap',
          justifyContent: 'flex-between',
          border: `${borderWidth}px solid #ccc`,
          borderRadius: 5,
          padding: 5,
          background: backgroundColor
        }}
      >
        {list.map((item: any) => (
          <div
            key={item.value}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: 15,
              marginTop: 5,
              marginBottom: 5
            }}
          >
            <Field
              name={name}
              component="input"
              type="checkbox"
              value={item.value}
              style={{ backgroundColor: '#000' }}
            />
            <p style={{ margin: '0px 0px 0px 5px', fontSize: 14 }}>
              {item.label}
            </p>
          </div>
        ))}
        {hasOthers && (
          <>
            <hr
              style={{
                width: '100%',
                borderColor: 'transparent',
                margin: 0,
                height: 0
              }}
            />
            <div style={{ paddingTop: 10, height: 'inherit' }}>
              <TextInput name={othersName} placeholder="Others" />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
