import { Route, Routes } from 'react-router-dom'

import ApproveWOI from '../pages/woi/ApproveWOI'
import IssueWOI from '../pages/woi/IssueWOI'
import MakeLiveWOI from '../pages/woi/MakeLiveWOI'
import PermitsByStatus from '../pages/deIsolationAndClose/ViewPermits'
import FetchPermitsByLocation from '../pages/FetchPermitsByLocation'
import VerifyCIO from '../pages/cio/VerifyCIO'
import IssueCIO from '../pages/cio/IssueCIO'
import ApproveCIO from '../pages/cio/ApproveCIO'
import MakeLiveCIO from '../pages/cio/MakeLiveCIO'

export default function CIORoutes() {
  return (
    <Routes>
      <Route
        path="/verify"
        element={<PermitsByStatus currentPermits="cioInitiated" flow={'cio'} />}
      />
      <Route path="/verify/:id" element={<VerifyCIO />} />
      <Route
        path="/issue"
        element={<PermitsByStatus currentPermits="Verified CIO" flow={'cio'} />}
      />
      <Route path="/issue/:id" element={<IssueCIO />} />
      <Route
        path="/approve"
        element={<FetchPermitsByLocation status="Issued CIO" flow="cio" />}
      />

      <Route path="/approve/:id" element={<ApproveCIO />} />
      <Route
        path="/makeLive"
        element={
          <PermitsByStatus
            currentPermits="Approved CIO"
            flow={'cio'}
            checkDepartment
          />
        }
      />
      <Route path="/makeLive/:id" element={<MakeLiveCIO />} />
    </Routes>
  )
}
