import axios from 'axios'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Table } from 'reactstrap'
import { incrementby1 } from '../components/Forms/JSAForm'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import SectionHeader from '../components/Layout/SectionHeader'
import PermitHeaderDetails from '../components/PermitHeaderDetails'
import { getCloseOutPermit } from '../components/serverurl'
import Spacer from '../components/Spacer'
import { renderRow, SubHeader } from './VerifyCM'

export default function ViewClosedCM() {
  const [data, setData]: any = useState()

  const { id } = useParams()

  useEffect(() => {
    try {
      axios
        .get(`${getCloseOutPermit}/${id}`)
        .then((response: any) => setData(response?.data?.permitCloseOuts[0]))
    } catch (err) {
      console.error(err)
    }
  }, [])

  const fields = {
    'Work Order Number': data?.permitDetails[0]?.woNumber,
    'Permit Title': data?.permitDetails[0]?.title
  }

  const formData: any = {
    'Date Completed': moment(data?.dateCompleted).format('DD/MM/YYYY'),
    'Total Duration': data?.totalDuration,
    'Service Mode': data?.serviceMode,
    'Core Crew Working Hours': data?.coreCrewWorking,
    'Vendor Working Hours': data?.vendorWorking,
    'Plant ShutDown': data?.plantShutdown,
    'Unit ShutDown': data?.unitShutdown,
    'Trial Run Conducted': data?.trialRunConducted,
    'Experiment Handover to': data?.equipmentHandoverTo,
    'Historty Card Updated': data?.historyCardUpdated,
    'Department Worked': data?.departmentWorked,
    'Budget Code': data?.budgetCode,
    History: data?.history,
    Checklist: data?.checkList,
    'Corrective Measure': data?.correctiveMeasure,
    'Special Tools Used': data?.specialToolsUsed,
    'Trial Run Observation': data?.trialRunObervation,
    'Equipment Witness By': data?.trialRunObervation
  }

  return (
    <ProtectedLayout>
      <SectionHeader title="Closed Out Corrrective Maintenance Work Orders" />
      <hr style={{ margin: 0 }} />
      <Spacer height={10} />
      <SubHeader header="Permit details" />
      <Spacer height={10} />
      <PermitHeaderDetails permit={fields} />
      <Spacer height={10} />
      <SubHeader header="Close Out details" />
      <Spacer height={10} />
      {Object.keys(formData).map((key: any) => renderRow(key, formData[key]))}
      <Spacer height={10} />
      <SubHeader header="Stock Used" />
      <Spacer height={10} />
      <Table bordered striped style={{ border: '1px solid #000' }}>
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            <td>S.No</td>
            <td>Stock Name</td>
            <td>Currency</td>
          </tr>
        </thead>
        <tbody>
          {data?.stockUsed?.map((stock: any, index: number) => (
            <tr key={index}>
              <td style={{ width: '5%' }}>{incrementby1(index)}</td>
              <td>{stock?.name}</td>
              <td>{stock?.currency}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Spacer height={10} />
      <SubHeader header="Vendor Used" />
      <Spacer height={10} />
      <Table bordered striped style={{ border: '1px solid #000' }}>
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            <td>S.No</td>
            <td>Vendor Name</td>
            <td>Hours</td>
          </tr>
        </thead>
        <tbody>
          {data?.vendorUsed?.map((stock: any, index: number) => (
            <tr key={index}>
              <td style={{ width: '5%' }}>{incrementby1(index)}</td>
              <td>{stock?.name}</td>
              <td>{stock?.hours}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Spacer height={10} />
      <SubHeader header="Resource Used" />
      <Spacer height={10} />
      <Table bordered striped style={{ border: '1px solid #000' }}>
        <thead>
          <tr style={{ backgroundColor: 'orange' }}>
            <td>S.No</td>
            <td>Resource Name</td>
            <td>Hours</td>
          </tr>
        </thead>
        <tbody>
          {data?.resourceUsed?.map((resource: any, index: number) => (
            <tr key={index}>
              <td style={{ width: '5%' }}>{incrementby1(index)}</td>
              <td>{resource?.name}</td>
              <td>{resource?.hours}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </ProtectedLayout>
  )
}
