import { useState } from 'react'
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane
} from 'reactstrap'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import { SubHeader } from '../VerifyCM'

const TABS = {
  ONE: 'SAFETY CRITICAL EQUIPMENT SPARES',
  TWO: 'MAINTENANCE BACK LOG',
  THREE: 'CLASS OVerdue WO Status',
  FOUR: 'WO - System',
  FIVE: 'PM Vs CM',
  SIX: 'AUDIT FINDING'
}

export const TabOne = () => {
  const data = [
    {
      name: 'January',
      Expected: 3,
      Remaining: 1
    },

    {
      name: 'February',
      Expected: 2,
      Remaining: 3
    },
    {
      name: 'March',
      Expected: 0,
      Remaining: 3
    },
    {
      name: 'April',
      Expected: 1,
      Remaining: 2
    },
    {
      name: 'May',
      Expected: 1,
      Remaining: 1
    },
    {
      name: 'June',
      Expected: 1,
      Remaining: 0
    }
  ]
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <BarChart
        width={1000}
        height={700}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Remaining" stackId="a" fill="#D03632" />
        <Bar dataKey="Expected" stackId="a" fill="#28CEE7" />
      </BarChart>
    </div>
  )
}

export const TabTwo = () => {
  const data = [
    {
      name: 'JAN -On Time',
      SCPM: 6,
      PM: 15,
      CM: 6
    },
    {
      name: 'JAN Backlog',
      SCPM: 0,
      PM: 1,
      CM: 1
    },
    {
      name: 'JAN Cum Baclog',
      SCPM: 0,
      PM: 1,
      CM: 1
    },
    {
      name: 'Feb -On Time',
      SCPM: 4,
      PM: 14,
      CM: 7
    },
    {
      name: 'Feb Backlog',
      SCPM: 1,
      PM: 1,
      CM: 2
    },
    {
      name: 'Feb Cum Baclog',
      SCPM: 1,
      PM: 2,
      CM: 3
    },
    {
      name: 'Mar -On Time',
      SCPM: 7,
      PM: 18,
      CM: 8
    },
    {
      name: 'Mar Backlog',
      SCPM: 2,
      PM: 2,
      CM: 1
    },
    {
      name: 'Mar Cum Baclog',
      SCPM: 3,
      PM: 4,
      CM: 4
    },
    {
      name: 'Apr -On Time',
      SCPM: 4,
      PM: 15,
      CM: 8
    },
    {
      name: 'Apr Backlog',
      SCPM: 0,
      PM: 0,
      CM: 0
    },
    {
      name: 'Apr Cum Baclog',
      SCPM: 3,
      PM: 2,
      CM: 4
    },
    {
      name: 'May -On Time',
      SCPM: 6,
      PM: 16,
      CM: 3
    },
    {
      name: 'May Backlog',
      SCPM: 0,
      PM: 0,
      CM: 0
    },
    {
      name: 'May Cum Baclog',
      SCPM: 3,
      PM: 0,
      CM: 4
    },
    {
      name: 'Jun -On Time',
      SCPM: 6,
      PM: 15,
      CM: 4
    },
    {
      name: 'Jun Backlog',
      SCPM: 0,
      PM: 1,
      CM: 0
    },
    {
      name: 'Jun Cum Baclog',
      SCPM: 3,
      PM: 1,
      CM: 2
    }
  ]
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <BarChart
        width={1000}
        height={700}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="SCPM" fill="#8884d8" />
        <Bar dataKey="PM" fill="#82ca9d" />
        <Bar dataKey="CM" fill="#28CEE7" />
      </BarChart>
    </div>
  )
}

const TabThree = () => {
  const data = [
    {
      name: 'January',
      'On time': 2,
      Backlog: 0,
      'Cum Backlog': 0
    },
    {
      name: 'February',
      'On time': 2,
      Backlog: 0,
      'Cum Backlog': 0
    },
    {
      name: 'March',
      'On time': 1,
      Backlog: 1,
      'Cum Backlog': 1
    },
    {
      name: 'April',
      'On time': 1,
      Backlog: 0,
      'Cum Backlog': 1
    },
    {
      name: 'May',
      'On time': 2,
      Backlog: 1,
      'Cum Backlog': 2
    },
    {
      name: 'June',
      'On time': 2,
      Backlog: 0,
      'Cum Backlog': 2
    }
  ]
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <BarChart
        width={1000}
        height={700}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="On time" fill="#8884d8" />
        <Bar dataKey="Backlog" fill="#D03632" />
        <Bar dataKey="Cum Backlog" fill="#28CEE7" />
      </BarChart>
    </div>
  )
}

const TabFour = () => {
  const data = [
    {
      label: 'GENERAL RIG COMPONENT DERRICK/DRAWORKS',
      jan: 2,
      feb: 2,
      mar: 1,
      apr: 2,
      may: 4,
      Jun: 3
    },
    {
      label: 'MAST /DERRICK/JACKING SYSTEM/SUBSTRUCTURE',
      jan: 1,
      feb: 2,
      mar: 3,
      apr: 4,
      may: 3,
      Jun: 2
    },
    {
      label: 'MUD PUMPS',
      jan: 6,
      feb: 4,
      mar: 6,
      apr: 3,
      may: 6,
      Jun: 1
    },
    {
      label: 'DRIVE SYSTEMS',
      jan: 3,
      feb: 2,
      mar: 1,
      apr: 1,
      may: 2,
      Jun: 2
    },
    {
      label: 'ROTATING & TRAVELING EQUIPMENT',
      jan: 5,
      feb: 3,
      mar: 4,
      apr: 6,
      may: 1,
      Jun: 5
    },
    {
      label: 'SUBSEA EQUIPMENT',
      jan: 1,
      feb: 0,
      mar: 0,
      apr: 1,
      may: 0,
      Jun: 0
    },
    {
      label: 'MUD SOLIDS CONTROL',
      jan: 3,
      feb: 1,
      mar: 3,
      apr: 3,
      may: 1,
      Jun: 2
    }
  ]
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Table bordered style={{ borderColor: '#000' }}>
        <thead>
          <tr>
            <th></th>
            <th>January</th>
            <th>February</th>
            <th>March</th>
            <th>April</th>
            <th>May</th>
            <th>June</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((d: any) => (
            <tr key={d?.label}>
              <td>{d?.label}</td>
              <td>{d?.jan}</td>
              <td>{d?.feb}</td>
              <td>{d?.mar}</td>
              <td>{d?.apr}</td>
              <td>{d?.may}</td>
              <td>{d?.Jun}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

const TabFive = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img
        src={require('./../../../src/images/Picture 1.png')}
        style={{ width: '100%', marginTop: 20 }}
      />
    </div>
  )
}

const TabSix = () => {
  const data = [
    {
      name: 'January',
      Registered: 1,
      Completed: 0
    },
    {
      name: 'February',
      Registered: 0,
      Completed: 1
    },
    {
      name: 'March',
      Registered: 0,
      Completed: 0
    },
    {
      name: 'April',
      Registered: 2,
      Completed: 1
    },
    {
      name: 'May',
      Registered: 0,
      Completed: 1
    },
    {
      name: 'June',
      Registered: 1,
      Completed: 1
    }
  ]
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <BarChart
        width={1000}
        height={700}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Completed" stackId="a" fill="#D03632" />
        <Bar dataKey="Registered" stackId="a" fill="#28CEE7" />
      </BarChart>
    </div>
  )
}

export default function KPIStatistics() {
  const [active, setActive] = useState(TABS.ONE)
  return (
    <ProtectedLayout onBack="/cmms">
      <Row style={{ margin: 10 }}>
        <SectionHeader title="KPI STATISTICS" />
        <hr />
        <Nav tabs>
          <NavItem>
            <NavLink
              className={active === TABS.ONE ? 'active' : ''}
              onClick={() => setActive(TABS.ONE)}
            >
              {TABS.ONE}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === TABS.TWO ? 'active' : ''}
              onClick={() => setActive(TABS.TWO)}
            >
              {TABS.TWO}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === TABS.THREE ? 'active' : ''}
              onClick={() => setActive(TABS.THREE)}
            >
              {TABS.THREE}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === TABS.FOUR ? 'active' : ''}
              onClick={() => setActive(TABS.FOUR)}
            >
              {TABS.FOUR}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === TABS.FIVE ? 'active' : ''}
              onClick={() => setActive(TABS.FIVE)}
            >
              {TABS.FIVE}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={active === TABS.SIX ? 'active' : ''}
              onClick={() => setActive(TABS.SIX)}
            >
              {TABS.SIX}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={active}>
          <TabPane tabId={TABS.ONE}>
            <SubHeader header={TABS.ONE} permitColor="#FFD580" />
            <TabOne />
          </TabPane>

          <TabPane tabId={TABS.TWO}>
            <SubHeader header={TABS.TWO} permitColor="#FFD580" />
            <TabTwo />
          </TabPane>

          <TabPane tabId={TABS.THREE}>
            <SubHeader header={TABS.THREE} permitColor="#FFD580" />
            <TabThree />
          </TabPane>

          <TabPane tabId={TABS.FOUR}>
            <SubHeader header={TABS.FOUR} permitColor="#FFD580" />
            <TabFour />
          </TabPane>

          <TabPane tabId={TABS.FIVE}>
            <SubHeader header={TABS.FIVE} permitColor="#FFD580" />
            <TabFive />
          </TabPane>

          <TabPane tabId={TABS.SIX}>
            <SubHeader header={TABS.SIX} permitColor="#FFD580" />
            <TabSix />
          </TabPane>
        </TabContent>
      </Row>
    </ProtectedLayout>
  )
}
