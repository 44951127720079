import { Button, Input, Row } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { revalidateEndpoint } from '../../components/serverurl'

import React from 'react'

import { getEpermitPermissions } from '../../components/lib/epermitPermissions'
import { useAdminPermissions } from '../eptw/EditRisks'
import ViewPermit from '../eptw/ViewPermit'
import {
  doneRequiredOptions,
  RadioButtonGroup,
  yesNoOptions
} from '../eptw/VerifyPermit'
import CustomLabel from '../../components/InputFields/CustomLabel'

export default function ApproveRevalidation() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const [permissions, setPermissions]: any = useState()

  useEffect(() => {
    if (!permissions) {
      getEpermitPermissions(accounts, instance).then((response: any) =>
        setPermissions(response)
      )
    }
  })

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const [submitModal, setSubmitModal]: any = useState(false)

  const [formData, setFormData]: any = useState({
    h2S: '',
    o2: '',
    cO: '',
    lEL: '',
    isSafe: 'No',
    hydroCarbonsPresent: 'No',
    hazardsoursMaterials: 'No',
    properlyDrained: 'Not Required',
    properlySteamed: 'Not Required',
    waterFlushed: 'Not Required',
    oilCheck: 'Not Required',
    areaChecked: 'Not Required'
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const submitValues: any = {
      consent: {
        isSafe: formData?.isSafe,
        hydroCarbonsPresent: formData?.hydroCarbonsPresent,
        hazardsoursMaterials: formData?.hazardsoursMaterials,
        properlyDrained: formData?.properlyDrained,
        properlySteamed: formData?.properlySteamed,
        waterFlushed: formData?.waterFlushed,
        oilCheck: formData?.oilCheck,
        areaChecked: formData?.areaChecked
      },
      gasReadings: {
        h2S: formData?.h2S,
        o2: formData?.o2,
        cO: formData?.cO,
        lEL: formData?.lEL
      },

      permitId: data?.permit?._id,
      consentId: data?.permit?.consent?._id
    }

    const response = await makeAuthenticatedRequest(
      `${revalidateEndpoint}/approve`,
      'PATCH',
      submitValues,
      accounts,
      instance
    )

    return response.status
  }

  const form = (
    <div>
      <div className="form-group">
        <CustomLabel label="H2S" />
        <Input type="text" name="h2S" onChange={handleInputChange} />
      </div>
      <div className="form-group">
        <CustomLabel label="O2" />
        <Input type="text" name="o2" onChange={handleInputChange} />
      </div>
      <div className="form-group">
        <CustomLabel label="CO" />
        <Input type="text" name="cO" onChange={handleInputChange} />
      </div>
      <div className="form-group">
        <CustomLabel label="LEL" />
        <Input type="text" name="lEL" onChange={handleInputChange} />
      </div>
      <RadioButtonGroup
        question="Is it safe for Job Performer to proceed the Job ?"
        name="isSafe"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Is the area free from Hydrocarbons and acceptable LEL ?"
        name="hydroCarbonsPresent"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Is the area clear from Hazardous materials and Dropped Object and Bad weather Conditions ?"
        name="hazardsoursMaterials"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Equipment properly drained / depressurised ?"
        name="properlyDrained"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Equipment properly steamed / purged? ?"
        name="properlySteamed"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Equipment water flushed  ?"
        name="waterFlushed"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Checked for oil / gas trapped behind lining in equipmen ?"
        name="oilCheck"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
      <RadioButtonGroup
        question="Surrounding area checked / cleaned ?"
        name="areaChecked"
        options={doneRequiredOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="Not Required"
      />
    </div>
  )

  return (
    <ProtectedLayout onBack="/eptw/revalidate/approve">
      <ViewPermit data={data} isLoading={isLoading} />
      {data?.permit?.status === 'revalidationIssued' && (
        <>
          <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Button
              color="warning"
              style={{ marginTop: 10 }}
              onClick={() => setSubmitModal(true)}
            >
              Approve
            </Button>
          </Row>
          {submitModal && (
            <SubmitModalWithForm
              isOpen={submitModal}
              onClose={(val: any) => {
                setSubmitModal(false)
                val && navigate('/eptw/revalidate/approve')
              }}
              onSubmit={handleSubmit}
              form={form}
            />
          )}
        </>
      )}
    </ProtectedLayout>
  )
}
