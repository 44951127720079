import { Col, Row, Spinner, Table, Button } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import React, { useEffect, useState, useRef } from 'react'
import ReactPaginate from 'react-paginate'
import Select from 'react-select'
import { CSVLink, CSVDownload } from "react-csv";
import ReactToPrint from 'react-to-print'


import ProtectedLayout from '../components/Layout/ProtectedLayout'
import SectionHeader from '../components/Layout/SectionHeader'
import { capitalizeFirstLetter } from '../components/lib/capitalizeFirstLetter'
import { EmptyContent } from './CreateNewPermit'
import { getOptions } from './firs'
import { incrementby1 } from '../components/Forms/JSAForm'
import { liveIsolationsLTI } from '../components/serverurl'
import { makeAuthenticatedRequest } from '../apiRequests'
import { PermitHeader } from './WaitingForPIC'
import { renderTableData } from '../components/ToolBoxTalkMeeting'

export default function LOTORegister() {
  const { accounts, instance } = useMsal()
  const [pageNumber, setPageNumber] = useState(0)
  const [department, setDepartment] = useState()
  const [isolationStatus, setIsolationStatus] = useState()
  const componentRef = useRef()


  const fetchLiveIsolations = async () => {
    const response = await makeAuthenticatedRequest(
      `${liveIsolationsLTI}/all?page=${pageNumber}&department=${department}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, refetch } = useQuery('lti', fetchLiveIsolations)

  useEffect(() => {
    refetch()
  }, [pageNumber, department])

  const renderArrayAsList = (values) =>
    values?.map((v, index) => <li key={`${v}-${index}`}>{v}</li>)

  const renderArrayAsString = (values) => values?.map((v) => v).join('\n')
  const printHeaders = ['S.No', 'Isolation Status', 'Permit Title', 'Permit Number', 'Lock Box', 'Isolation Number', 'Isolation Point', 'Isolation Title', 'Responsible Department', 'Isolation Status']
  const isolationData = data?.isolations
  const printData = isolationData?.map((p, index) => ([
    incrementby1(index),
    capitalizeFirstLetter(p?.status),
    p?.permit?.permitTitle,
    p?.permit?.woNumber,
    p?.lockBoxNumber || 'Waiting for PIC',
    renderArrayAsString(
      p?.isolations?.map((i) => i?.number)
    ),
    renderArrayAsString(
      p?.isolations?.map((i) => i?.isolationPoint)
    ),
    renderArrayAsString(
      p?.isolations?.map((i) => i?.isolationDescription)
    ),
    renderArrayAsString(
      p?.isolations?.map((i) => i?.isolationType)
    ),
    renderArrayAsString(
      p?.isolations?.map((i) =>
        capitalizeFirstLetter(i?.status)
      )
    )
  ]))
  const csvData = [
    printHeaders,
    ...(printData?.length > 0 ? printData : [])

  ]
  const styles = `@page { size: landscape; }`

  return (
    <div
      style={{
        fontSize: 14,
        fontFamily: 'Nunito, sans-serif',
        backgroundColor: '#FFF',
        padding: 2
      }}
      ref={componentRef}
      className="print-page-break"
    >
      <style type="text/css" media="print">
        {styles}
      </style>
      <Row style={{ margin: 10 }} >
        <SectionHeader title="LOTO Register" />
        <>
          <Row style={{ margin: 0, display: 'flex', justifyContent: 'space-between' }}>
            <Col xs="12" md="3">
              <p style={{ fontWeight: 700 }}>Applied Department</p>
            </Col>
          </Row>
          <Row style={{ margin: 0 }}>
            <Col xs="12" md="3">
              <Select
                options={[
                  { value: undefined, label: 'Select' },
                  ...getOptions([
                    'MECHANICAL',
                    'ELECTRICAL',
                    'PRODUCTION',
                    'INSTRUMENT'
                  ])
                ]}
                onChange={(e) => setDepartment(e?.value)}
              />
            </Col>
          </Row>
          <Table
            striped
            bordered
            style={{ borderColor: '#000', fontSize: 12, marginTop: 10 }}
          >
            <thead>
              <tr style={{ backgroundColor: 'orange' }}>
                {[
                  'S.No.',
                  'Responsible Department',
                  'Permit Title',
                  'Permit Number',
                  'Applicant Department',
                  'Lock Box',
                  'Isolation Number',
                  'Isolation Point',
                  // 'Isolation Title',
                  'Isolation Status',
                  // 'Isolations Status'
                ]?.map((h) => <th key={h}>{h}</th>)}
              </tr>
            </thead>
            <tbody>
              {isLoading && !data && <EmptyContent label={<Spinner />} />}
              {data?.isolations?.map((p, index) => (
                <React.Fragment key={`${p?._id}-${index}`}>
                  <tr>
                    <td style={{ verticalAlign: 'middle' }}>
                      {incrementby1(index)}
                    </td>
                    <td style={{ verticalAlign: 'middle', width: '10%' }}>
                      {renderArrayAsList(
                        p?.isolations?.map((i) => i?.isolationType)
                      )}
                    </td>
                    <td style={{ verticalAlign: 'middle', width: '20%' }}>
                      {p?.permit?.permitTitle}
                    </td>
                    <td style={{ verticalAlign: 'middle', width: '20%' }}>
                      {p?.permit?.woNumber}
                    </td>
                    <td style={{ verticalAlign: 'middle', width: '10%' }}>
                      {p?.permit?.applicantDepartment}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {p?.lockBoxNumber || 'NA'}
                    </td>
                    <td style={{ width: '25%' }}>
                      {renderArrayAsList(
                        p?.isolations?.map((i) => i?.number)
                      )}
                    </td>
                    <td td style={{ width: '10%' }}>
                      {renderArrayAsList(
                        p?.isolations?.map((i) => i?.isolationPoint)
                      )}
                    </td>
                    {/* <td td style={{ width: '15%' }}>
                      {renderArrayAsList(
                        p?.isolations?.map((i) => i?.isolationDescription)
                      )}
                    </td> */}
                    <td style={{ verticalAlign: 'middle', width: '10%' }}>
                      {capitalizeFirstLetter(p?.status)}
                    </td>
                    {/* <td style={{ verticalAlign: 'middle', width: '20%' }}>
                      {renderArrayAsList(
                        p?.isolations?.map((i) =>
                          capitalizeFirstLetter(i?.status)
                        )
                      )}
                    </td> */}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(data?.totalDocuments / 20)}
            marginPagesDisplayed={10}
            pageRangeDisplayed={2}
            onPageChange={async (values) => {
              await setPageNumber(values.selected + 1)
              refetch()
            }}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
          <ReactToPrint
            documentTitle={'LOTO'}
            pageStyle={'padding: 20'}
            trigger={() => <Button color="warning" children="Print" />}
            content={() => componentRef.current}
          />
          <Row>
            <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
              <CSVLink data={csvData} filename={'LOTO DATA'}>Download CSV</CSVLink>
            </Col>
          </Row>
        </>
      </Row>
    </div>
  )
}
