import { useMsal } from '@azure/msal-react'
import { makeAuthenticatedRequest } from '../../apiRequests'
import RcmLayout from '../../components/rcm/RcmLayout'
import { equipmentAPI } from '../../components/serverurl'
import { useQuery } from 'react-query'
import { Spinner, Table } from 'reactstrap'
import ReactPaginate from 'react-paginate'
import { useState } from 'react'
import { incrementby1 } from '../../components/Forms/JSAForm'

export default function RcmEquipment() {
  const { accounts, instance } = useMsal()
  const [pageNumber, setPageNumber]: any = useState(1)

  const fetchTag = async () => {
    const response = await makeAuthenticatedRequest(
      `${equipmentAPI}/all?page=${pageNumber}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, refetch, isRefetching }: any = useQuery(
    'tagDetails',
    fetchTag
  )

  return (
    <RcmLayout
      onBack={'/cmms'}
      title={'RCM'}
      tabHeader={<h4 style={{ margin: 0 }}>Equipment</h4>}
    >
      <p></p>
      <Table key={'flock data'} bordered style={{ borderColor: '#000' }}>
        <thead>
          <tr key={'header'}>
            {[
              'S.No',
              'Tag Number',
              'Tag Description',
              'System',
              'Criticality',
              'Priority'
            ]?.map((key: any) => <th key={key}>{key}</th>)}
          </tr>
        </thead>
        <tbody>
          {isLoading || isRefetching ? (
            <>
              <tr>
                <td
                  colSpan={12}
                  style={{
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    height: '60vh'
                  }}
                >
                  <Spinner />
                </td>
              </tr>
            </>
          ) : (
            data?.systems?.map((item: any, index: any) => (
              <tr key={`${index}-${item?.['HIERARCHY FLOC ID']}`}>
                {/* <td>
                        <Input
                          type="checkbox"
                          checked={selected?._id === item?._id}
                          onClick={(e: any) =>
                            e?.target?.checked ? setSelected(item) : setSelected()
                          }
                        />
                      </td> */}
                <td>{incrementby1(index) + pageNumber * 20}</td>
                {[
                  'tag',
                  'tagDescription',
                  'sytem',
                  'criticality',
                  'priority'
                ].map((key: any, index: number) => (
                  <td key={index}>{item[key]}</td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </Table>
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(data?.totalDocuments / 20)}
        marginPagesDisplayed={10}
        pageRangeDisplayed={2}
        onPageChange={async (values) => {
          await setPageNumber(values.selected + 1)
          refetch()
        }}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </RcmLayout>
  )
}
