import ViewManuals from '../dashboardPages/ViewManuals'

export default function RejectedManuals() {
  return (
    <ViewManuals
      status="Rejected"
      onBack="/cmms/manual"
      title="Rejected Manuals"
    />
  )
}
