import React from 'react'
import { Col, Container } from 'reactstrap'

import { ViewServices } from '../Services'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import PlanningServicesMenu from '../../SVGs/PlanningServicesSvg'

export default function PlanningServices() {
  const services: any = [
    {
      img: require('./../../../src/images/pmPlan.png'),
      title: 'PM Job plans',
      description: 'Protective Maintenance system',
      redirect: '/cmms/planning/pm'
    },
    {
      img: require('./../../../src/images/Icons/lookAhead.png'),
      title: 'look ahead plans',
      description: 'Corrective Maintenance system',
      redirect: '/cmms/planning/lookahead'
    }
  ]

  return (
    <ProtectedLayout onBack="/cmms">
      {/* <ViewServices services={services} /> */}
      <Col
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <div
          style={{
            height: '70vh',
            width: '60vw',
            marginTop: -100
          }}
        >
          <PlanningServicesMenu />
        </div>
      </Col>
    </ProtectedLayout>
  )
}
