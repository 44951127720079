import React from 'react'
import { Table } from 'reactstrap'
import { renderArray } from '../../pages/WaitingForPIC'
import { incrementby1 } from '../Forms/JSAForm'

export default function PrintJSA({ risk }: any) {
  const data = risk?.template ? risk?.template : risk

  return (
    <>
      <React.Fragment>
        <Table bordered style={{ border: '1px solid #000', fontSize: 12 }}>
          <thead>
            <tr>
              <th>Sl.No</th>
              <th>Activity</th>
              <th>Potential Hazards and Risk</th>
              <th>Risk control Measures</th>
              <th>Who is responsible</th>
            </tr>
          </thead>
          <tbody>
            <React.Fragment>
              {data?.activities?.map((activity: any, index: number) => (
                <>
                  <tr key={`${index}_${activity?.name}`}>
                    <td style={{ width: '1%' }}>{incrementby1(index)}</td>
                    <td style={{ width: '15%' }}>{activity?.name}</td>
                    <td style={{ width: '15%' }}>
                      {activity?.risks?.map((risk: string, index: number) => (
                        <li key={index}>{risk}</li>
                      ))}
                    </td>
                    <td style={{ width: '55%' }}>
                      {activity?.controls?.map(
                        (control: string, index: number) => (
                          <li key={index}>{control}</li>
                        )
                      )}
                    </td>
                    <td style={{ width: '10%' }}>
                      {renderArray(activity?.responsible, false)}
                    </td>
                  </tr>
                </>
              ))}
            </React.Fragment>
          </tbody>
        </Table>
      </React.Fragment>
    </>
  )
}
