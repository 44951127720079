import { Button, Col, Input, ModalFooter, Row, Table } from "reactstrap";
import React, { useContext, useState } from "react";
import arrayMutators from "final-form-arrays";
import { Field, Form } from "react-final-form";
import LabeledTextInput from "../components/InputFields/LabeledTextInput";
import MultiSelect from "../components/InputFields/MultiSelect";

import equipmentList from "./../components/constants/equipmentList.json";
import tagNumberList from "./../components/constants/tagNumber.json";
import LabeledDateTimeInput from "../components/InputFields/DateTime";
import LabledParagraphInput from "../components/InputFields/LabledParagraph";
import LabeledCheckBoxes from "../components/InputFields/LabeledCheckBoxes";
import LabeledRadioButtons from "../components/InputFields/LabeledRadioButton";
import Spacer from "../components/Spacer";
import CustomLabel from "../components/InputFields/CustomLabel";
import { SubHeader } from "./VerifyCM";
import { UserContext } from "../App";
import {
  incidentReport,
  stage2API,
  stage3API,
  stage4API,
} from "../components/serverurl";
import ModalTitle from "../components/ModalTitle";
import SubmitModal from "../components/SubmitModal";
import { makeAuthenticatedRequest } from "../apiRequests";
import { useMsal } from "@azure/msal-react";
import { useNavigate, useParams } from "react-router-dom";
import {
  immidiateCausesSA,
  immidiateCausesSC,
  jobFactors,
  personalFactors,
  renderDate,
  renderDepartments,
} from "./ApproveStage1";
import { hoecRiskMatrixColors } from "../components/constants/riskMatrixColors";
import SeverityRiskMatrix from "../components/Modals/SeverityRiskMatrix";
import { FieldArray } from "react-final-form-arrays";
import { renderField } from "../components/Modals/CloseCMPermit";
import { MdDelete } from "react-icons/md";
import ProtectedLayout from "../components/Layout/ProtectedLayout";
import SubmitModalWithForm from "../components/Modals/SubmitModalWithForm";
import { incrementby1 } from "../components/Forms/JSAForm";
import { alDepartments, LabeledDropdown } from "../ashokleyland/AlViewWo";

export const departmentList = [
  {
    value: "Mechanical",
    label: "Mechanical",
  },
  {
    value: "Electrical",
    label: "Electrical",
  },
  {
    value: "Instrument",
    label: "Instrument",
  },
  {
    value: "Production",
    label: "Production",
  },
  {
    value: "Admin Ware House",
    label: "Admin Ware House",
  },
  {
    value: "HSE",
    label: "HSE",
  },
];

export const incidentType = [
  "Injury/ Illness",
  "Loss of Containment",
  "Environment",
  "Fire",
  "Process Trip",
  "Damage",
  "Explosion",
  "Equipment Failure",
  "Security Incident",
  "Third Party",
  "Near Miss",
  "Fatality",
  "Alternative duty",
  "Near Miss",
  "Non Work-related",
  "Lost time",
  "Medical Treatment",
  "Property Damage",
  "Unknown at this time",
  "First Aid",
  "Occu. illness",
  "Pollution Event",
  "Diesel Spillage",
];
export const injuryDetails = [
  "Loss of Conciousness",
  "Effects of chemicals",
  "Amputation",
  "Fracture",
  "Burns",
  "Cuts / grazes",
  "Crushing / Bruises",
  "Sprain / Strain",
  "Loss of sight",
  "Concussion",
  "Dislocation",
  "Open Wounds",
  "Bites & Stings",
];
export const partOfBody = [
  "Skull",
  "Head",
  "Eyes",
  "Back",
  "Torso",
  "Lungs",
  "Ear",
  "Leg / Ankle",
  "Foot / Toes",
  "Arm / wrist",
  "Hand / Finger",
  "Multiple",
  "shoulder",
  "Hip",
];

export const getOptions = (options: any) =>
  options?.map((o: any) => ({ value: o, label: o }));

const Stage1Fields = () => {
  return (
    <>
      <Row>
        <LabeledTextInput name="originator" label="Originator" occupy={4} />
        <LabeledTextInput
          name="incidentTitle"
          label="Incident Title"
          occupy={4}
        />
        <MultiSelect
          isMulti={false}
          name="equipment"
          label="Equipment"
          options={equipmentList}
          occupy={4}
        />
        <MultiSelect
          isMulti={false}
          name="tagNumber"
          label="Tag Number"
          options={tagNumberList}
          occupy={4}
        />
        <MultiSelect
          isMulti={false}
          name="location"
          label="Location"
          options={getOptions([
            "Fire Station",
            "Admin Ware House",
            "Mechanical Workshop",
            "Substation",
            "Instrument Workshop",
            "Control Room",
            "Process Area",
          ])}
          occupy={4}
        />
        <MultiSelect
          isMulti={false}
          name="department"
          label="Department"
          options={departmentList}
          occupy={4}
        />
        <LabeledDateTimeInput
          name="date"
          label="Date and Time of Incident"
          occupy={4}
        />
        <LabledParagraphInput
          name="description"
          label="Description of the Incident"
          occupy={4}
        />
        <MultiSelect
          isMulti={false}
          name="incidentType"
          label="Incident Type"
          options={getOptions(incidentType)}
          occupy={4}
        />
      </Row>
      <Spacer height={10} />
      <SubHeader header="Details of Injured" />
      <Spacer height={10} />
      <Row>
        <LabeledTextInput name="victimName" label="Name" occupy={4} />
        <LabeledTextInput name="victimEmployer" label="Employer" occupy={4} />
        <LabeledDateTimeInput
          name="dateofBirth"
          label="Date of Birth"
          occupy={4}
        />
        <LabeledTextInput name="victimJobTitle" label="Job Title" occupy={4} />
        <LabeledTextInput
          name="victimExperience"
          label="Experience"
          occupy={4}
        />
        <LabeledTextInput name="victimAge" label="Age" occupy={4} />
        <LabeledRadioButtons
          name="victimGender"
          label="Gender"
          list={[
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
          ]}
          occupy={4}
        />
        <LabeledRadioButtons
          name="victimEmploymentType"
          label="Employment Type (with HOEC)"
          list={getOptions([
            "Full Time",
            "Part Time",
            "Contractor",
            "Contracted Directly",
            "Service Company",
            "Others",
          ])}
          occupy={4}
        />
        <LabeledRadioButtons
          name="victimDutyType"
          label="Duty Type at time of injury"
          list={getOptions([
            "On duty",
            "Off duty",
            "Travelling to / from work",
          ])}
          occupy={4}
        />
        <LabeledCheckBoxes
          name="injuryDetails"
          label="Injury Details"
          list={getOptions(injuryDetails)}
          hasOthers
          othersName="others_injuryDetails"
          occupy={12}
        />
        <LabeledCheckBoxes
          name="partOfBody"
          label="Part of Body Injured"
          list={getOptions(partOfBody)}
          occupy={12}
        />
        <LabeledRadioButtons
          name="service"
          label="Service"
          list={getOptions([
            "Medical Evacuation",
            "Scheduled Service",
            "Unscheduled Service",
          ])}
          occupy={4}
        />
        <LabeledRadioButtons
          name="actionRequired"
          label="Immediate Action Required"
          list={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ]}
          occupy={4}
        />
        <LabeledRadioButtons
          name="productionLoss"
          label="Loss of Deferment of Production"
          list={[
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" },
          ]}
          occupy={4}
        />
        <LabledParagraphInput
          name="immidateDescription"
          label="Immediate Action Description"
          occupy={12}
        />
      </Row>
      <Spacer height={10} />
      <SubHeader header="Personal Involved during Incident" />
      <Spacer height={10} />
      <Row>
        <LabeledTextInput name="im" label="IM" occupy={12} />
        <LabeledTextInput
          name="areaAuthority"
          label="Area Authority"
          occupy={12}
        />
        <LabeledTextInput
          name="safetyOfficer"
          label="Safety Officer"
          occupy={12}
        />
        <LabeledTextInput name="witness" label="Witness" occupy={12} />
      </Row>
      <Spacer height={15} />
      <Row>
        <Col xs="1">
          <p>Attachments :</p>
        </Col>
        <Col xs="11">
          <Input type="file" accept="application/pdf" />
        </Col>
      </Row>
    </>
  );
};

const initialValues = {
  victimGender: "Male",
  victimEmploymentType: "Part Time",
  victimDutyType: "Off duty",
  service: "Unscheduled Service",
  actionRequired: "yes",
  productionLoss: "No",
  originator: "Vinith",
  incidentTitle: "Some Title",
  equipment: getOptions(["43PB002 B"]),
  tagNumber: getOptions(["20-PSV-1256"]),
  location: getOptions(["Mechanical Workshop"]),
  department: getOptions(["Mechanical"]),
  date: "2023-12-31",
  description: "Some Description",
  incidentType: ["Alternative duty", "Near Miss"],
  others_incidentType: "Other Types",
  victimName: "John",
  victimEmployer: "HOEC",
  dateofBirth: "2015-12-31",
  victimJobTitle: "Engineer",
  victimExperience: "4",
  victimAge: "23",
  injuryDetails: ["Effects of chemicals", "Dislocation"],
  partOfBody: ["Back", "Torso"],
  immidateDescription: "Some Insr",
  im: "Name 1",
  areaAuthority: "Name 2",
  safetyOfficer: "Name 3",
  witness: "Name 4",
  userId: "ed57ff95-3f79-401f-a898-73f6c70b5f3f",
};

const validate = (values: any) => {
  const errors: any = {};
  if (!values.equipment) {
    errors.equipment = "Required";
  }
  if (!values.tagNumber) {
    errors.tagNumber = "Required";
  }

  if (!values.location) {
    errors.location = "Required";
  }
  if (!values.department) {
    errors.department = "Required";
  }

  return errors;
};

export function Stage1Form() {
  const { userData }: any = useContext(UserContext);
  const [submitModal, setSubmitModal]: any = useState(false);
  const [submitValues, setSubmitValue]: any = useState();
  const { accounts, instance } = useMsal();
  const navigate = useNavigate();

  const createIncident = async () => {
    const response = await makeAuthenticatedRequest(
      incidentReport,
      "POST",
      submitValues,
      accounts,
      instance
    );
    return response;
  };

  return (
    <ProtectedLayout
      title="Report Incident"
      onBack={"/firs/incidentManagement"}
    >
      <Row style={{ margin: 20 }}>
        <Form
          initialValues={initialValues}
          validate={validate}
          onSubmit={(values: any) => {
            setSubmitValue({
              ...values,
              equipment: values?.equipment?.value,
              tagNumber: values?.tagNumber?.value,
              location: values?.location?.value,
              department: values?.department?.value,
              incidentType: values?.incidentType?.value,
              userId: userData?.id,
            });
            setSubmitModal(true);
          }}
          render={({ handleSubmit, form, hasValidationErrors }) => (
            <form onSubmit={handleSubmit}>
              <Stage1Fields />
              <Spacer height={20} />
              <Row>
                <Col xs="12">
                  <Button
                    color="success"
                    // disabled={submitting}
                    onClick={async () => {
                      await form.submit();
                    }}
                    disabled={hasValidationErrors}
                    style={{ width: "inherit" }}
                  >
                    Submit
                  </Button>
                </Col>
                <SubmitModalWithForm
                  isOpen={submitModal}
                  onClose={(val: any) => {
                    val && navigate("/firs/incidentManagement");
                    setSubmitModal(!submitModal);
                  }}
                  onSubmit={async (e: any) => {
                    e.preventDefault();
                    const res = await createIncident();
                    return res?.status;
                  }}
                  size={"md"}
                  header={"Create Report"}
                />
              </Row>
            </form>
          )}
        />
      </Row>
    </ProtectedLayout>
  );
}

const Stage2Fields = ({ values }: any) => {
  return (
    <>
      <Row>
        <LabeledCheckBoxes
          name="followingProcedures"
          label="Following Procedures"
          list={getOptions([
            "Violation by individual",
            "Violation by group",
            "Violation by Supervisor",
            "Operation of equipment without authority",
            "Improper position/posture for work",
            "Overexertion of physical capability",
            "Work or motion at improper speed",
            "Improper lifting",
            "Improper Loading",
            "Shortcuts",
          ])}
          hasOthers
          othersName="others_followingProcedures"
          occupy={4}
        />
        <LabeledCheckBoxes
          name="tools"
          label="Use of Tools & Equipment "
          list={getOptions([
            "Improper use of equipment",
            "Improper use of tools",
            "Use of defective equipment",
            "Use of defective tools (aware)",
            "Improper placement of tools, equipment or materials",
            "Operation of equipment at improper speed",
            "Servicing of equipment in operation",
          ])}
          hasOthers
          othersName="others_tools"
          occupy={4}
        />
        <LabeledCheckBoxes
          name="protectiveMethods"
          label="Use of Protective Methods"
          list={getOptions([
            "Lack of knowledge of hazards present",
            "Personal protective equipment not used",
            "Improper use of personal protective equipment",
            "Servicing of energized equipment",
            "Equipment or materials not secured",
            "Disabled guards, warning systems or safety devices",
            "Removal of guards, warning systems or safety devices",
            "Personal protection equipment not available",
          ])}
          hasOthers
          othersName="others_protectiveMethods"
          occupy={4}
        />
        <LabeledCheckBoxes
          name="awareness"
          label="Inattention/Lack of Awareness"
          list={getOptions([
            "Improper decision making or lack of judgment",
            "Distracted by other concerns",
            "Inattention for footing and surroundings",
            "Horseplay",
            "Act of violence",
            "Failure to warn",
            "Use of drugs or alcohol",
            "Routine activity without thought",
          ])}
          hasOthers
          othersName="others_awareness"
          occupy={4}
        />
        <LabeledCheckBoxes
          name="protectiveSystems"
          label="Protective Systems"
          list={getOptions([
            "Inadequate guards or protective devices",
            "Defective guards or protective devices",
            "Inadequate personal protective equipment",
            "Defective personal protective equipment",
            "Inadequate warning systems",
            "Defective warning systems",
            "Inadequate isolation of process or equipment",
            "Inadequate safety devices",
            "Defective safety devices",
          ])}
          hasOthers
          othersName="others_protectiveSystems"
          occupy={4}
        />
        <LabeledCheckBoxes
          name="equipmentTools"
          label="Tools, Equipment & Vehicles "
          list={getOptions([
            "Defective equipment",
            "Inadequate equipment",
            "Improperly prepared equipment",
            "Defective tools",
            "Inadequate tools",
            "Improperly prepared tools",
            "Defective vehicle",
            "Inadequate vehicle for the purpose",
            "Improperly prepared vehicle",
          ])}
          hasOthers
          othersName="others_equipmentTools"
          occupy={4}
        />
        <LabeledCheckBoxes
          name="workExposure"
          label="Work Exposure to"
          list={getOptions([
            "Fire or explosion",
            "Noise",
            "Energized electrical Systems",
            "Energized systems, other than electrical",
            "Radiation",
            "Temperature extremes",
            "Hazardous chemicals",
            "Mechanical hazards",
            "Clutter or debris",
            "Storms or acts of nature",
            "Slippery floors on walkways",
          ])}
          hasOthers
          othersName="others_workExposure"
          occupy={4}
        />
        <LabeledCheckBoxes
          name="environment"
          label="Work Place Environment/Layout "
          list={getOptions([
            "Congestion or restricted motion",
            "Inadequate or excessive illumination",
            "Inadequate ventilation",
            "Unprotected height",
            "Inadequate work place layout",
          ])}
          hasOthers
          othersName="others_environment"
          occupy={4}
        />
        <LabeledCheckBoxes
          name="immidiateCausesSA"
          label="Immidiate Causes - Substandard Actions "
          list={getOptions(immidiateCausesSA)}
          hasOthers
          othersName="others_immidiateCausesSA"
          occupy={4}
        />
        <LabeledCheckBoxes
          name="immidiateCausesSC"
          label="Immidiate Causes - Substandard Conditions"
          list={getOptions(immidiateCausesSC)}
          hasOthers
          othersName="others_immidiateCausesSC"
          occupy={4}
        />
        <LabledParagraphInput
          name="immidiateCauseDescription"
          label="Description of the Immidiate Causes"
          occupy={4}
        />
        <LabeledCheckBoxes
          name="personalFactors"
          label="Personal Factors "
          list={getOptions(personalFactors)}
          hasOthers
          othersName="others_personalFactors"
          occupy={4}
        />
        <LabeledCheckBoxes
          name="jobFactors"
          label="Job Factors"
          list={getOptions(jobFactors)}
          hasOthers
          othersName="others_jobFactors"
          occupy={4}
        />
        <LabledParagraphInput
          name="basicCauseDescription"
          label="Description of the Basic Causes"
          occupy={4}
        />
        <LabledParagraphInput
          name="otherObservations"
          label="Other Observations from the investigation"
          occupy={4}
        />
        <MultiSelect
          isMulti={false}
          name="assignDepartment"
          label="Assign Investigation Department"
          options={departmentList}
          occupy={4}
        />
        <LabeledTextInput
          name="investigationMember"
          label="Assign Investigation Member"
          occupy={4}
        />
        <LabeledDateTimeInput
          name="startDate"
          label="Assign Start Date"
          occupy={4}
        />
        <LabeledDateTimeInput
          name="completionDate"
          label="Assign Completion Date"
          occupy={4}
        />
        <LabeledRadioButtons
          name="incidentRepeatable"
          label="Incident Repetable"
          list={[
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" },
          ]}
          occupy={4}
        />
        <MultiSelect
          isMulti={false}
          name="investigationTier"
          label="Investigation Tier"
          options={getOptions(["Tier 1", "Tier 2", "Tier 3"])}
          occupy={4}
        />
        <Col xs="4">
          <CustomLabel label="Assign Priority" />
          <SeverityRiskMatrix
            name="assignPriority"
            values={values.assignPriority}
          />
        </Col>
      </Row>
    </>
  );
};

export function Stage2Form({ toggle }: any) {
  const { id } = useParams();
  const { accounts, instance } = useMsal();

  const [submit, setSubmit]: any = useState(false);
  const [submitValues, setSubmitValues]: any = useState();

  const onSubmitStage2 = async () => {
    const res = await makeAuthenticatedRequest(
      stage2API,
      "POST",
      {
        ...submitValues,
        incidentReport: id,
      },
      accounts,
      instance
    );

    return res;
  };
  const navigate = useNavigate();

  return (
    <>
      <Form
        onSubmit={(values: any) => {
          setSubmitValues({
            ...values,
            investigationTier: values?.investigationTier?.value,
            assignDepartment: values?.assignDepartment?.value,
          });
          setSubmit(true);
        }}
        render={({ values, handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Stage2Fields values={values} />
            <Spacer height={20} />
            <ModalFooter>
              <Button color="warning" onClick={toggle}>
                Back
              </Button>
              <Button color="warning" onClick={form.submit}>
                Submit
              </Button>
            </ModalFooter>
            <SubmitModalWithForm
              isOpen={submit}
              onClose={(val: any) => {
                val && navigate("/firs/stage2");
                setSubmit(!submit);
              }}
              onSubmit={async (e: any) => {
                e.preventDefault();
                const res = await onSubmitStage2();
                return res?.status;
              }}
              size={"md"}
              header={"Stage 2"}
            />
          </form>
        )}
      />
    </>
  );
}

const Stage3Fields = ({ values }: any) => {
  return (
    <Row>
      <LabeledRadioButtons
        name="incidentAtWorkplace"
        label="Is the Incident happened in our workplace"
        list={[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ]}
        occupy={4}
      />
      <LabeledRadioButtons
        name="safetyAlert"
        label="Send safety alert to all department "
        list={[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ]}
        occupy={4}
      />
      <LabeledTextInput
        name="budgetEstimation"
        label="Opportunity Approximate Budget Estimation:"
        occupy={4}
      />
      <LabeledTextInput
        name="paybackReturns"
        label="Payback returns"
        occupy={4}
      />

      <LabeledCheckBoxes
        name="fatigue"
        label="Fatigue"
        list={getOptions([
          "due to workload",
          "due to lack of rest",
          "due to sensory overload",
        ])}
        hasOthers={false}
        occupy={4}
      />
      <LabeledCheckBoxes
        name="dimishedPerformance"
        label="Diminished performance"
        list={getOptions([
          "due to temperature extremes",
          "due to lack of oxygen",
          "due to changes in pressure",
        ])}
        hasOthers={false}
        occupy={4}
      />
      <LabeledCheckBoxes
        name="impairment"
        label="Impairment due to drug or alcohol use"
        list={getOptions([
          "Not applicable",
          "Mental State",
          "Mental Stress",
          "Behaviour",
          "Skill Leve",
        ])}
        hasOthers={false}
        occupy={4}
      />
      <LabeledCheckBoxes
        name="jobFactors"
        label="Job factors"
        list={getOptions([
          "Training / Knowledge Transfer",
          "Supervision & Leadership",
          "Contractor Selection & Oversight",
          "Engineering / Design",
          "Work Planning",
          "Purchasing & Handling Material Control",
          "Tools & Equipment",
          "Policies / Standards & Procedures",
        ])}
        hasOthers={false}
        occupy={4}
      />

      <Spacer height={20} />
    </Row>
  );
};

export const renderPriority = ({
  input,
  label,
  type,
  meta: { touched, error },
}: any) => (
  <div>
    <div>
      <Input {...input} type="select">
        <option>Yes</option>
        <option>No</option>
      </Input>
      {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
  </div>
);

export const renderStatus = ({
  input,
  label,
  type,
  meta: { touched, error },
}: any) => (
  <div>
    <div>
      <Input {...input} type="select">
        <option>Open</option>
        <option>Verified</option>
        <option>Approved</option>
        <option>Closed</option>
        <option>Rejected</option>
      </Input>
      {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
  </div>
);

export const renderCondition = ({
  input,
  label,
  type,
  meta: { touched, error },
}: any) => (
  <div>
    <div>
      <Input {...input} type="select">
        <option>OR</option>
        <option>AND</option>
      </Input>
      {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
  </div>
);

const renderMitigations = ({ fields, meta: { error } }: any) => (
  <>
    <Table striped>
      <thead style={{ backgroundColor: "gold" }}>
        <tr>
          {[
            "Action Number",
            "What do you want to understand ?",
            "What Action is required",
            "Action Owner",
            "Status",
            "Target Date",
            "Priority Actions",
            "Findings",
            "",
          ]?.map((h: any) => (
            <th key={h}>{h}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {fields.map((stock: any, index: any) => (
          <tr key={index}>
            <td>
              <Field
                name={`${stock}.actionNumber`}
                type="text"
                component={renderField}
                initialValue={incrementby1(index)}
              />
            </td>
            <td>
              <Field
                name={`${stock}.understanding`}
                type="textarea"
                component={renderField}
              />
            </td>
            <td>
              <Field
                name={`${stock}.actionRequired`}
                type="textarea"
                component={renderField}
              />
            </td>
            <td>
              <Field
                name={`${stock}.actionOwner`}
                type="text"
                component={renderField}
              />
            </td>
            <td>
              <Field
                name={`${stock}.status`}
                type="text"
                component={renderStatus}
              />
            </td>
            <td>
              <Field
                name={`${stock}.targetDate`}
                type="date"
                component={renderField}
              />
            </td>
            <td>
              <Field
                name={`${stock}.priorityAction`}
                component={renderPriority}
              />
            </td>
            <td>
              <Field
                name={`${stock}.findings`}
                type="textarea"
                component={renderField}
              />
            </td>
            <td>
              <MdDelete
                onClick={() => fields.remove(index)}
                style={{ color: "red" }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    <Button color="link" onClick={() => fields.push()}>
      Add Mitigation
    </Button>
  </>
);

const renderPotentialCause = ({ fields, meta: { error } }: any) => (
  <>
    {fields.map((stock: any, index: any) => (
      <tr key={index}>
        <td></td>
        <td></td>
        <td>
          <Field
            name={`${stock}.condition`}
            type="textarea"
            component={renderCondition}
          />
        </td>
        <td>
          <Field
            name={`${stock}.potentialCause`}
            type="textarea"
            component={renderField}
          />
        </td>
        <td>
          <Field
            name={`${stock}.isTrue`}
            type="text"
            component={renderPriority}
          />
        </td>
        <td>
          <Field
            name={`${stock}.evidence`}
            type="text"
            component={renderField}
          />
        </td>
        <td>
          <Field
            name={`${stock}.actionToUnderstand`}
            type="textarea"
            component={renderField}
          />
        </td>
        <td>
          <MdDelete
            onClick={() => fields.remove(index)}
            style={{ color: "red" }}
          />
        </td>
      </tr>
    ))}
    <>
      <Button color="link" onClick={() => fields.push()}>
        Add Additional Potential Cause
      </Button>
    </>
  </>
);

const renderUnderstandings = ({ fields, meta: { error } }: any) => (
  <>
    <Table striped bordered>
      <thead style={{ backgroundColor: "gold" }}>
        <tr>
          {[
            "Understaing",
            "Parent Cause",
            "AND / OR",
            "Potential Cause",
            "Is this True?",
            "Evidence",
            "Action to Understand",
            "",
          ]?.map((h: any) => (
            <th key={h}>{h}</th>
          ))}
        </tr>
      </thead>
      <>
        {fields.map((stock: any, index: any) => (
          <React.Fragment key={index}>
            <tbody style={{ border: "3px solid #000", marginBottom: 10 }}>
              <tr key={index}>
                <td>
                  <Field
                    name={`${stock}.understanding`}
                    type="text"
                    component={renderField}
                  />
                </td>
                <td>
                  <Field
                    name={`${stock}.parentCause`}
                    type="textarea"
                    component={renderField}
                  />
                </td>
                <td colSpan={4}></td>
                <td colSpan={2}>
                  <Button color="link" onClick={() => fields.remove(index)}>
                    Delete Parent Cause
                  </Button>
                </td>
              </tr>
              <FieldArray
                name={`${stock}.potentialCause`}
                component={renderPotentialCause}
              />
            </tbody>
          </React.Fragment>
        ))}
      </>
    </Table>
    <Button color="link" onClick={() => fields.push()}>
      Add Understanding
    </Button>
  </>
);

const alInvestigations = ({ fields, meta: { error } }: any) => (
  <>
    <Table bordered>
      <thead>
        <tr style={{ backgroundColor: "gold" }}>
          <th>Name</th>
          <th>Department</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {fields.map((stock: any, index: any) => (
          <tr key={index}>
            <td>
              <Field
                name={`${stock}.name`}
                type="text"
                component={renderField}
                label={`Name #${index + 1}`}
              />
            </td>
            <td>
              <LabeledDropdown
                name={`${stock}.department`}
                options={alDepartments}
                occupy={4}
              />
            </td>
            <td>
              <MdDelete
                onClick={() => fields.remove(index)}
                style={{ color: "red" }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    <>
      <Button color="link" onClick={() => fields.push()}>
        Add Team Member
      </Button>
    </>
  </>
);

const renderInvestigations = ({ fields, meta: { error } }: any) => (
  <>
    {fields.map((stock: any, index: any) => (
      <React.Fragment key={index}>
        <Row style={{ marginBottom: 3 }}>
          <Col xs="2">
            <Field
              name={`${stock}.name`}
              type="text"
              component={renderField}
              label={`Name #${index + 1}`}
            />
          </Col>
          <Col xs="9">
            <Field name={`${stock}.department`} component={renderDepartments} />
          </Col>
          <Col xs="1">
            <MdDelete
              onClick={() => fields.remove(index)}
              style={{ color: "red" }}
            />
          </Col>
        </Row>
      </React.Fragment>
    ))}
    <>
      <Button color="link" onClick={() => fields.push()}>
        Add Team Member
      </Button>
    </>
  </>
);

const CloseFields = () => {
  return (
    <Row>
      <LabeledRadioButtons
        name="rootCauseIdentified"
        label="Root Cause Identified"
        list={[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ]}
        occupy={6}
      />
      <LabeledRadioButtons
        name="correctiveActionPlan"
        label="Corrective Action Plan Completed"
        list={[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ]}
        occupy={6}
      />
      <LabledParagraphInput name="comments" label="Comments" />
      <Spacer height={20} />
    </Row>
  );
};

export function Stage3Form() {
  const { id } = useParams();
  const { accounts, instance } = useMsal();

  const [submit, setSubmit]: any = useState(false);
  const [submitValues, setSubmitValues]: any = useState();

  const onSubmitStage3 = async () => {
    const res = await makeAuthenticatedRequest(
      stage3API,
      "POST",
      {
        ...submitValues,
        incidentReport: id,
      },
      accounts,
      instance
    );

    return res;
  };

  const navigate = useNavigate();

  return (
    <>
      <Form
        onSubmit={(values: any) => {
          setSubmitValues({
            ...values,
            assignDepartment: values?.assignDepartment?.label,
          });
          setSubmit(true);
        }}
        render={({ handleSubmit, form, hasValidationErrors, values }) => (
          <form onSubmit={handleSubmit}>
            <Stage3Fields values={values} />
            <Row>
              <Button onClick={form.submit} color="warning">
                Submit
              </Button>
            </Row>
            <SubmitModalWithForm
              isOpen={submit}
              onClose={(val: any) => {
                val && navigate("/firs/stage3");
                setSubmit(!submit);
              }}
              onSubmit={async (e: any) => {
                e.preventDefault();
                const res = await onSubmitStage3();
                return res?.status;
              }}
              size={"md"}
              header={"Stage 3"}
            />
          </form>
        )}
      />
    </>
  );
}

export function Stage4Form() {
  const { id } = useParams();
  const { accounts, instance } = useMsal();

  const [submit, setSubmit]: any = useState(false);
  const [submitValues, setSubmitValues]: any = useState();

  const onSubmitStage4 = async () => {
    const res = await makeAuthenticatedRequest(
      stage4API,
      "POST",
      {
        ...submitValues,
        incidentReport: id,
      },
      accounts,
      instance
    );

    return res;
  };
  const navigate = useNavigate();
  return (
    <>
      <Form
        onSubmit={(values) => {
          setSubmitValues(values);
          setSubmit(true);
        }}
        mutators={{
          ...arrayMutators,
        }}
        render={({ form }) => (
          <form>
            <Row>
              <LabledParagraphInput
                name="regulatoryAuthorities"
                label="Regulatory Authorities may conduct their own investigation"
                occupy={4}
              />
              <LabeledTextInput
                name="budgetEstimation"
                label="Budget Estimation"
                occupy={4}
              />
              <LabeledRadioButtons
                name="productionImpact"
                label="Your Score on Production Impact"
                list={[
                  { label: "Low", value: "Low" },
                  { label: "Medium", value: "Medium" },
                  { label: "High", value: "High" },
                ]}
                occupy={4}
              />
              <LabeledRadioButtons
                name="environmentImpact"
                label="Your Score on Environment Impact"
                list={[
                  { label: "Low", value: "Low" },
                  { label: "Medium", value: "Medium" },
                  { label: "High", value: "High" },
                ]}
                occupy={4}
              />
              <LabeledRadioButtons
                name="humanImpact"
                label="Your Score on Human Impact"
                list={[
                  { label: "Low", value: "Low" },
                  { label: "Medium", value: "Medium" },
                  { label: "High", value: "High" },
                ]}
                occupy={4}
              />
              <LabeledRadioButtons
                name="equipmentImpact"
                label="Your Score on Equipment Impact"
                list={[
                  { label: "Low", value: "Low" },
                  { label: "Medium", value: "Medium" },
                  { label: "High", value: "High" },
                ]}
                occupy={4}
              />
              <LabeledRadioButtons
                name="financialImpact"
                label="Your Score on Financial Impact"
                list={[
                  { label: "Low", value: "Low" },
                  { label: "Medium", value: "Medium" },
                  { label: "High", value: "High" },
                ]}
                occupy={4}
              />
              <LabeledRadioButtons
                name="companyReputation"
                label="Your Score on Company Reputation"
                list={[
                  { label: "Low", value: "Low" },
                  { label: "Medium", value: "Medium" },
                  { label: "High", value: "High" },
                ]}
                occupy={4}
              />
              <Spacer heught={10} />
            </Row>
            <ModalFooter>
              <Button onClick={form.submit} color="warning">
                Submit
              </Button>
            </ModalFooter>
            <SubmitModalWithForm
              isOpen={submit}
              onClose={(val: any) => {
                val && navigate("/firs/stage4");
                setSubmit(!submit);
              }}
              onSubmit={async (e: any) => {
                e.preventDefault();
                const res = await onSubmitStage4();
                return res?.status;
              }}
              size={"md"}
              header={"Stage 4"}
            />
          </form>
        )}
      />
    </>
  );
}

export const AlStage2Fields = () => {
  return (
    <Row>
      <Col>
        <SubHeader header="Stage 2 Analysis - Safety Committee Member" />
        <LabledParagraphInput occupy={12} name="rootCause" label="Root Cause" />
        <SubHeader header="Team Members" />
        <FieldArray name={`teamMembers`} component={alInvestigations} />
        <Spacer height={20} />
        <SubHeader header="5 Why's - Root Cause Analysis" />
        <FieldArray name={`understandings`} component={renderUnderstandings} />
        <SubHeader header="Mitigations" />
        <FieldArray name={`mitigations`} component={renderMitigations} />
        <Spacer height={20} />
        <SubHeader header="Sustainable Action" />
        <LabeledRadioButtons
          name={`sustainableAction`}
          list={getOptions([
            "Initiate Corrective Workorder",
            "Initiate Preventive Action",
            "Initiate Horizontal Deployment",
            "Initate Warning Letter",
          ])}
          occupy={12}
        />
        <Spacer height={20} />
      </Col>
    </Row>
  );
};

export function Stage5Form() {
  const { id } = useParams();
  const { accounts, instance } = useMsal();

  const [submit, setSubmit]: any = useState(false);
  const [submitValues, setSubmitValues]: any = useState();
  const [status, setStatus]: any = useState("");

  const onSubmitStage3 = async () => {
    const res = await makeAuthenticatedRequest(
      `${stage4API}/stage5`,
      "POST",
      {
        ...submitValues,
        incidentReport: id,
        status,
      },
      accounts,
      instance
    );

    return res;
  };

  const navigate = useNavigate();
  return (
    <>
      <Form
        onSubmit={(values) => {
          // console.log(values)
          setSubmitValues(values);
          setSubmit(true);
        }}
        mutators={{
          ...arrayMutators,
        }}
        render={({ form }) => (
          <form>
            <Row>
              <SubHeader header="Team Members" />
              <FieldArray
                name={`teamMembers`}
                component={renderInvestigations}
              />
              <Spacer height={20} />
              <SubHeader header="Understandings" />
              <FieldArray
                name={`understandings`}
                component={renderUnderstandings}
              />
              <SubHeader header="Mitigations" />
              <FieldArray name={`mitigations`} component={renderMitigations} />
              <Spacer height={20} />
            </Row>
            <ModalFooter>
              <div style={{ width: "100%" }}>
                <SubHeader header="Sustainable Solutions" />
              </div>
              <Button
                onClick={() => {
                  setStatus("moc");
                  form.submit();
                }}
                color="warning"
              >
                Management of Change
              </Button>
              <Button
                onClick={() => {
                  setStatus("cm");
                  form.submit();
                }}
                color="warning"
              >
                Corrective WorkOrder
              </Button>
              <Button
                onClick={() => {
                  setStatus("closed");
                  form.submit();
                }}
                color="warning"
              >
                Close
              </Button>
            </ModalFooter>
            {/* <SubmitModal
              isOpen={submit}
              onSubmit={() => onSubmitStage3()}
              toggle={() => setSubmit(!submit)}
            /> */}

            <SubmitModalWithForm
              isOpen={submit}
              onClose={(val: any) => {
                val && navigate("/firs/stage4");
                setSubmit(!submit);
              }}
              onSubmit={async (e: any) => {
                e.preventDefault();
                const res = await onSubmitStage3();
                return res?.status;
              }}
              size={"md"}
              header={"Stage 5"}
            />
          </form>
        )}
      />
    </>
  );
}

export function CloseFIR() {
  const { id } = useParams();
  const { accounts, instance } = useMsal();

  const [submit, setSubmit]: any = useState(false);
  const [submitValues, setSubmitValues]: any = useState();

  const onCloseFIR = async () => {
    const res = await makeAuthenticatedRequest(
      incidentReport,
      "PATCH",
      {
        ...submitValues,
        status: "closed",
        incidentReport: id,
      },
      accounts,
      instance
    );

    return res;
  };

  return (
    <>
      <Form
        onSubmit={(values: any) => {
          setSubmitValues({
            ...values,
            assignDepartment: values?.assignDepartment?.label,
          });
          setSubmit(true);
        }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <CloseFields />
            <Row>
              <Button onClick={form.submit} color="warning">
                Submit
              </Button>
            </Row>
            <SubmitModal
              isOpen={submit}
              onSubmit={() => onCloseFIR()}
              toggle={() => setSubmit(!submit)}
            />
          </form>
        )}
      />
    </>
  );
}

export function RejectFIR() {
  const { id } = useParams();
  const { accounts, instance } = useMsal();

  const [submit, setSubmit]: any = useState(false);
  const [submitValues, setSubmitValues]: any = useState();

  const reject = async () => {
    const res = await makeAuthenticatedRequest(
      incidentReport,
      "PATCH",
      {
        ...submitValues,
        status: "rejected",
        incidentReport: id,
      },
      accounts,
      instance
    );

    return res;
  };

  const navigate = useNavigate();

  return (
    <>
      <Form
        onSubmit={(values: any) => {
          setSubmitValues({
            ...values,
            assignDepartment: values?.assignDepartment?.label,
          });
          setSubmit(true);
        }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <LabeledTextInput label="Reason" name="rejectReason" occupy={12} />
            <Spacer height={20} />
            <Row>
              <Button onClick={form.submit} color="warning">
                Submit
              </Button>
            </Row>
            <SubmitModalWithForm
              isOpen={submit}
              onClose={(val: any) => {
                val && navigate("/firs/stage2");
                setSubmit(!submit);
              }}
              onSubmit={async (e: any) => {
                e.preventDefault();
                const res = await reject();
                return res?.status;
              }}
              size={"md"}
              header={"Reject"}
            />
          </form>
        )}
      />
    </>
  );
}
