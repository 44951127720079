import moment from 'moment'
import { Row, Spinner, Table } from 'reactstrap'
import { useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { capitalizeFirstLetter } from '../../components/lib/capitalizeFirstLetter'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { Link } from './PmTree'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { workOrderAPI } from '../../components/serverurl'
import { workOrderStatuses } from '../../components/constants/woStatuses'

const WorkOrdersTable = ({
  data,
  isLoading,
  isRefetching,
  page,
  setPage,
  url
}: {
  data: any
  isLoading: boolean
  isRefetching: boolean
  page: any
  setPage: any
  url: string
}) => {
  const navigate = useNavigate()
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>WO Number</th>
            <th>Tag Number</th>
            <th>Work Description</th>
            <th>Tag Description</th>
            <th>Vessel</th>
            <th>Priority</th>
            <th>Criticality</th>
            <th>Department</th>
            <th>Status</th>
            <th>Created on</th>
          </tr>
        </thead>
        <tbody>
          <>
            {isLoading || isRefetching ? (
              <tr>
                <td colSpan={12}>
                  <Row
                    style={{
                      height: 500,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Spinner />
                  </Row>
                </td>
              </tr>
            ) : (
              data?.length > 0 &&
              data?.map((w: any, index: number) => (
                <tr key={w?._id}>
                  <td>{incrementby1(page * 20 + index)}</td>
                  <td>
                    <Link onClick={() => navigate(`${url}/${w?._id}`)}>
                      {w?.number}
                    </Link>
                  </td>
                  <td>{w?.tagId}</td>
                  <td>{w?.description}</td>
                  <td>{w?.tagDescription}</td>
                  <td>{w?.vessel}</td>
                  <td>{w?.priority}</td>
                  <td>{w?.criticality}</td>
                  <td>{capitalizeFirstLetter(w?.department)}</td>
                  <td>{workOrderStatuses[w?.woStatus]}</td>
                  <td>{moment(w?.createdAt).format('DD/MM/YYYY')}</td>
                </tr>
              ))
            )}
          </>
        </tbody>
      </Table>
      {data?.length === 0 && (
        <Row
          style={{
            height: 500,
            width: '100%',
            margin: 0
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No Work Orders Found,
            <span
              style={{
                color: 'blue',
                textDecoration: 'underline',
                marginLeft: 2,
                cursor: 'pointer'
              }}
              onClick={() =>
                navigate('/workManagement/correctiveMaintenance/create')
              }
            >
              Create one?
            </span>
          </p>
        </Row>
      )}
    </>
  )
}

export default function WorkOrders({
  onBack,
  status,
  department,
  url,
  title
}: {
  onBack: string
  status?: string
  department?: string
  url: any
  title: string
}) {
  const { accounts, instance } = useMsal()
  const [page, setPage] = useState(0)

  const fetchWorkRequests = async () => {
    const response = await makeAuthenticatedRequest(
      `${workOrderAPI}?page=${page}&status=${status}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading, isRefetching }: any = useQuery(
    'workRequests',
    fetchWorkRequests
  )

  const workRequests = data?.workrequests

  console.log({ workRequests })

  return (
    <ProtectedLayout onBack={onBack} title={title}>
      <Row style={{ marginLeft: 15, marginRight: 15 }}>
        <WorkOrdersTable
          data={workRequests}
          page={page}
          isLoading={isLoading}
          isRefetching={isRefetching}
          setPage={setPage}
          url={url}
        />
      </Row>
    </ProtectedLayout>
  )
}
