import { Field, Form } from 'react-final-form'
import { Button, Col, ModalFooter, Row } from 'reactstrap'
import { jobcodes } from '../../components/constants/jobcodes'
import CustomLabel from '../../components/InputFields/CustomLabel'
import LabeledDateTimeInput from '../../components/InputFields/DateTime'
import LabeledTextInput from '../../components/InputFields/LabeledTextInput'
import LabledParagraphInput from '../../components/InputFields/LabledParagraph'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import Spacer from '../../components/Spacer'

const initalValues = {
  subFunctionNumber: '13.01.030',
  level: 'VESSEL',
  vesel: 'SAT FPSO',
  systemGroupNumber: '0-10',
  systemGroupDescription: 'Process Support Systems',
  systemNumber: '43',
  systemDescription: 'Flare',
  systemCriticality: 'SECE',
  subSytemNumber: '43.04',
  subsystemDescription:
    'LP/ HP Flare Tip and Flare Ignition Panel (43-XX-3001)',
  subSystemCriticality: 'SECE`',
  functionNumber: '18.01.17',
  functionDescription: 'Production Well 6 (CP6) Future',
  functionCriticality: 'MEDIUM',
  mainFunctionNumber: '13.02.000',
  mainFunctionDesciption: 'Main- Production Flow Transfer System',
  mainFunctionCriticality: 'SECE',
  subFunctionDescription:
    'Process Shutdown,Emergency Shutdown,Safety - Production Flow lines',
  subFunctionCriticality: 'SECE',
  componentNumber: '43-HY-0007A',
  componentDescription: 'Solenoid Valve A for 43-HV-0007',
  componentCriticality: 'SECE',
  tagNumber: '13-PDSIT-1222',
  tagDescription:
    'Press Diff Safety Transmitter on Production Flow Line from Catcher EV',
  tagCriticality: 'SECE',
  maintainableItems: 'YES',
  nonMaintainableItems: 'NO',
  conservativeMaintenance: 'YES',
  maintenanceScope: 'Client',
  objectType: 'BN',
  objectTypeDescription: 'Multisensor Smoke/ Heat (Neural)',
  catergoryId: 'I',
  categoryIdDesciprion: 'INSTRUMENTATION',
  areaCode: 'Zone 1',
  pid: 'DTT-0021',
  hsCode: 'HSN 00034',
  location: 'DU - UPPER DECK',
  opsStatus: 'In Operation',
  performaceStd: 'C-02 Emergency Shutdown System',
  classCode: 'HULEXA',
  extracting: 'Ex d Zone 2, IIB, T3',
  rbiRisk: 'H',
  respDisipline: 'EIT-INSTRUMENTATION',
  techArc: 'INST TECH',
  reference: 'MOC-CAT-M1319',
  regulatryRef:
    'ECE2.02 Loss of Containment of Non-Flammable Process Chemicals',
  model: 'MIRZ54TD6K0PB2036HGW99A20T3ELD',
  serialNo: 'C0138649-000100',
  drawingNo: '4307-PDA-EV591-E-XE-00001.001',
  manufacturer: 'CONSOLIDATED VALVES',
  supplier: 'Wartsila UK Limited',
  vendorCode: 'V-00123',
  note: 'Capacity: 2.5 m3/h ,Absorbed Power (kW):0.3 , Inlet Pressure (barg) :0.77~1,35 ,Outlet Pressure (barg) :3.3~4 ,'
}

export default function PMForm({ onBack }: { onBack: any }) {
  return (
    <ProtectedLayout onBack={onBack}>
      <Row style={{ margin: 10 }}>
        <hr />
        <Form
          onSubmit={(values: any) => {}}
          initialValues={{}}
          render={({
            handleSubmit,
            form,
            hasValidationErrors,
            errors,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <LabeledTextInput label="Job Code" name="jobcode" occupy={3} />
                <LabledParagraphInput
                  label="Description"
                  name="workDescription"
                  occupy={3}
                />
                <LabeledTextInput
                  label="Action Code ID"
                  name="actionCodeId"
                  occupy={3}
                />
                <Col xs="3">
                  <CustomLabel label="Priority *" />
                  <Field
                    name={'priorityId'}
                    component="select"
                    placeholder="s"
                    style={{
                      width: '-webkit-fill-available',
                      height: 38,
                      borderRadius: 5,
                      backgroundColor: '#DCDCDC'
                    }}
                  >
                    <option value="" disabled>
                      Select Priority
                    </option>
                    <option value="1 - 14 Days">1 - 14 Days</option>
                    <option value="2 - 30 Days">2 - 30 Days</option>
                    <option value="3 - 90 Days">3 - 90 Days</option>
                  </Field>
                </Col>
                <Col xs="3">
                  <CustomLabel label="Work Type *" />
                  <Field
                    name={'prioworkTyperityId'}
                    component="select"
                    placeholder="s"
                    style={{
                      width: '-webkit-fill-available',
                      height: 38,
                      borderRadius: 5,
                      backgroundColor: '#DCDCDC'
                    }}
                  >
                    <option value="" disabled>
                      Select Priority
                    </option>
                    <option value="PM">PM</option>
                    <option value="SCPM">SCPM</option>
                    <option value="CLASS">CLASS</option>
                  </Field>
                </Col>
                <Col xs="3">
                  <CustomLabel label="Work Type Class *" />
                  <Field
                    name={'workTypeClass'}
                    component="select"
                    placeholder="s"
                    style={{
                      width: '-webkit-fill-available',
                      height: 38,
                      borderRadius: 5,
                      backgroundColor: '#DCDCDC'
                    }}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="Running hours based">
                      Running hours based
                    </option>
                    <option value="Calender Based">Calender Based</option>
                  </Field>
                </Col>
                <Col xs="3">
                  <CustomLabel label="Org Code *" />
                  <Field
                    name={'orgCode'}
                    component="select"
                    placeholder="s"
                    style={{
                      width: '-webkit-fill-available',
                      height: 38,
                      borderRadius: 5,
                      backgroundColor: '#DCDCDC'
                    }}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="MECH">MECH</option>
                    <option value="ELEC">ELEC</option>
                  </Field>
                </Col>
                <Col xs="3">
                  <CustomLabel label="Criticality" />
                  <Field
                    name={'criticality'}
                    component="select"
                    placeholder="s"
                    style={{
                      width: '-webkit-fill-available',
                      height: 38,
                      borderRadius: 5,
                      backgroundColor: '#DCDCDC'
                    }}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="SCE">SCE</option>
                    <option value="LOW">LOW</option>
                    <option value="MEDIUM">MEDIUM</option>
                    <option value="HIGH">HIGH</option>
                  </Field>
                </Col>
                <Col xs="3">
                  <CustomLabel label="Job Plan Code *" />
                  <Field
                    name={'jobPlanCode'}
                    component="select"
                    placeholder="s"
                    style={{
                      width: '-webkit-fill-available',
                      height: 38,
                      borderRadius: 5,
                      backgroundColor: '#DCDCDC'
                    }}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {Object.keys(jobcodes)?.map((k) => (
                      <option key={k} value={k}>
                        {k}
                      </option>
                    ))}
                  </Field>
                </Col>
                <LabeledTextInput
                  label="Planned Hours"
                  name="plannedHours"
                  occupy={3}
                />
                <LabeledTextInput
                  label="Resources"
                  name="resources"
                  occupy={3}
                />
                <LabeledTextInput label="Cost" name="cost" occupy={3} />
                <LabeledTextInput
                  label="Equipment Number"
                  name="equipmentNumber"
                  occupy={3}
                />
                <LabeledTextInput
                  label="Equipment Description"
                  name="equipmentDescription"
                  occupy={3}
                />
                <LabeledTextInput label="Tag Type" name="tagType" occupy={3} />
                <LabeledTextInput
                  label="Function Number"
                  name="functionNumber"
                  occupy={3}
                />
                <LabeledTextInput
                  label="Function Description"
                  name="functionDescription"
                  occupy={3}
                />
                <LabeledTextInput
                  label="Estimated Hours"
                  name="estimatedHours"
                  occupy={3}
                />
                <LabeledDateTimeInput
                  name="nextDueDate"
                  label="Next Due Date *"
                  occupy={3}
                />
                <LabeledDateTimeInput
                  name="triggerDate"
                  label="Trigger Date *"
                  occupy={3}
                />
              </Row>
              <Spacer height={10} />
              <ModalFooter>
                <Button
                  onClick={form.submit}
                  color="warning"
                  children="Create"
                  disabled={hasValidationErrors}
                />
              </ModalFooter>
            </form>
          )}
        />
      </Row>
    </ProtectedLayout>
  )
}
