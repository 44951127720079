import { Col, Row, Table } from 'reactstrap'

import AshokLeylandLayout from './AshokLeylandLayout'

export default function AlTrainingList() {
  return (
    <AshokLeylandLayout title="TRAINING LIST" onBack="/ashokleyland/training">
      <Row style={{ margin: 15 }}>
        <Col>
          <Table bordered style={{ borderColor: '#000' }}>
            <thead style={{ backgroundColor: 'gold' }}>
              <tr>
                {[
                  'S.No',
                  'Training Course',
                  'Training Required',
                  'Training Provider'
                ]?.map((v: any, index: any) => <th key={v}>{v}</th>)}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Proto Shop - EV PPE's usage & HV cable routing </td>
                <td></td>
                <td>Proto Shop</td>
              </tr>
              <tr>
                <td>2</td>
                <td>CRCL - Fire Fighting Training using Fire Extinguisher</td>
                <td></td>
                <td>CRCL</td>
              </tr>
              <tr>
                <td>3</td>
                <td>EV bus safety measures training </td>
                <td>Bus monitors, Drivers & technicians</td>
                <td>SWITCH EXPERTS</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Basic Safety Training for LEAD Employees</td>
                <td>Lead Employees at proto Shop</td>
                <td>Lead Safety representative</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Basic Safety Training for House Keeping</td>
                <td>House Keeping Employees</td>
                <td>House Keeping Employees</td>
              </tr>
              <tr>
                <td>6</td>
                <td>EV bus safety measures training for </td>
                <td>PPR/Kun Employees</td>
                <td>Depot/Switch Team Drivers</td>
              </tr>
              <tr>
                <td>7</td>
                <td>
                  CISS Security - Training on Fire Fighting (Classroom &
                  Practical)
                </td>
                <td>Security</td>
                <td>ISS Trainer</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Fire Fighting Training for Security & Canteen Employees</td>
                <td>Security & Canteen Employees</td>
                <td>EHS Department</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </AshokLeylandLayout>
  )
}
