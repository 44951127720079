import defaultTheme from './constants/defaultTheme.json'
import { capitalizeFirstLetter } from './lib/capitalizeFirstLetter'

export default function CMQuickView({ permit, onClick, selectedPermit }: any) {
  const isActive = permit.woNumber === selectedPermit.woNumber

  return (
    <div
      style={{
        margin: '0px 10px 10px 10px ',
        padding: 10,
        border: isActive ? `3px solid #fff` : `3px solid #ccc`,
        borderRadius: 10,
        backgroundColor: isActive ? defaultTheme.colors.lightBlue : defaultTheme.colors.lightBlue1,
        color: isActive ? defaultTheme.fontColor.white : defaultTheme.fontColor.lightBlue,
        cursor: 'pointer',
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 1px 2px, rgba(0, 0, 0, 0.3) 0px 2px 3px -3px, rgba(0, 0, 0, 0.2) 0px -2px 0px inset',
      }}
      onClick={() => onClick(permit)}
    >
      <p style={{ margin: 0, fontSize: 14 }}>WO Number - {permit?.woNumber}</p>
      <p style={{ margin: 0, fontSize: 14 }}>Title - {permit?.title}</p>
      <p style={{ margin: 0 }}>Status - <b>{capitalizeFirstLetter(permit?.status)}</b></p>
    </div>
  )
}