import { Col, Row } from 'reactstrap'
import Spacer from './Spacer'

export default function ModalTitle({
  title,
  height = 100
}: {
  title: string
  height?: number
}) {
  return (
    <>
      <Row
        style={{
          textAlign: 'center',
          backgroundColor: 'orange',
          height: height,
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          fontSize: 24,
          margin: 0
        }}
      >
        <b>{title}</b>
      </Row>
      <Spacer height={10} />
    </>
  )
}
