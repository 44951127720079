import { Form } from 'react-final-form'
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap'

import LabeledRadioButtons from '../InputFields/LabeledRadioButton'
import ModalTitle from '../ModalTitle'
import Spacer from '../Spacer'
import { doneRequiredOptions } from '../../pages/eptw/VerifyPermit'

export default function WorkAtHeight({
  toggle,
  setAttachedForms,
  attachedForms,
  isOpen
}: any) {
  const validate = (values: any) => {
    const errors: any = {}
    if (!values.rePassagePlant) {
      errors.rePassagePlant = 'Required'
    }

    return errors
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        <ModalTitle title="Work At Height Permit" />
        <Form
          onSubmit={(values: any) => {
            setAttachedForms(values)
            toggle()
          }}
          // validate={validate}
          initialValues={attachedForms}
          render={({ form, hasValidationErrors }) => (
            <form>
              <Row>
                <p style={{ margin: 0 }}>
                  <b>Risk Control Measures to prevent Fall of Person </b>
                </p>

                <LabeledRadioButtons
                  name="safeAccess"
                  label="Whether Safe access to work place is provided"
                  list={doneRequiredOptions}
                  defaultValue="Not Required"
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="edgeProtection"
                  label="Whether the edge protection provided adequate"
                  list={doneRequiredOptions}
                  defaultValue="Not Required"
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="workAreaAway"
                  label="Is the work area away from the vicinity of moving objects"
                  list={doneRequiredOptions}
                  defaultValue="Not Required"
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="workPlatform"
                  label="Whether work platform is adequate with respect to strength and dimension."
                  list={doneRequiredOptions}
                  defaultValue="Not Required"
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="fullyBoarded"
                  label="Is the working platform fully boarded."
                  list={doneRequiredOptions}
                  defaultValue="Not Required"
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="guardAll"
                  label="Work platforms provided with guard rail."
                  list={doneRequiredOptions}
                  defaultValue="Not Required"
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="workMenScreened"
                  label="Are the workmen screened for working at height?"
                  list={doneRequiredOptions}
                  defaultValue="Not Required"
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="protectionArrangement"
                  label="Adequate fall protection arrangement made (Static line, Double Lanyard harness, Fall Arrestor, Safety Net)"
                  list={doneRequiredOptions}
                  defaultValue="Not Required"
                  occupy={12}
                />
                <Spacer height={10} />
                <p style={{ margin: 0 }}>
                  <b>Risk Control measures for avoiding Fall of Materials</b>
                </p>
                <LabeledRadioButtons
                  name="oneElevation"
                  label="Work at more than one elevation at the same segment is restricted."
                  list={doneRequiredOptions}
                  defaultValue="Not Required"
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="overheadWorkplaces"
                  label="Walkways, aisles & all overhead workplaces cleared of loose material"
                  list={doneRequiredOptions}
                  defaultValue="Not Required"
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="allTools"
                  label="Whether all tools & tackles inspected before use"
                  list={doneRequiredOptions}
                  defaultValue="Not Required"
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="handlingMaterials"
                  label="Whether hand tools and handling materials secured against accidental fall?"
                  list={doneRequiredOptions}
                  defaultValue="Not Required"
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="providedBag"
                  label="Workmen provided with bag / box to carry bolt, nuts & hand tools"
                  list={doneRequiredOptions}
                  defaultValue="Not Required"
                  occupy={12}
                />
                <LabeledRadioButtons
                  name="unauthorizedEntries"
                  label="Whether area below, where the height work being performed is cordoned and unauthorized entries are avoided."
                  list={doneRequiredOptions}
                  defaultValue="Not Required"
                  occupy={12}
                />
              </Row>
              <Spacer height={30} />
              <Row>
                <Col xs="12">
                  <Button
                    style={{ width: 'inherit' }}
                    onClick={form.submit}
                    color={hasValidationErrors ? 'secondary' : 'warning'}
                    disabled={hasValidationErrors}
                  >
                    Save & Next
                  </Button>
                  <Spacer height={10} />
                  <Button
                    style={{ width: 'inherit' }}
                    color="warning"
                    onClick={toggle}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </ModalBody>
    </Modal>
  )
}
