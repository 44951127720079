import { Row, Col, Container } from 'reactstrap'
import defaultTheme from '../components/constants/defaultTheme.json'

type TitleDetailType = {
  title?: string
  detail?: string
  titleColor?: string
  shortTitle?: boolean
}

export default function TitleDetail({
  title,
  titleColor = defaultTheme.fontColor.lightBlue,
  detail,
  shortTitle = true
}: TitleDetailType) {
  return (
    <Container fluid>
      <Row className="d-flex">
        <div style={{ display: 'flex' }}>
          <Col xs={shortTitle ? '4' : '7'}>
            <p
              style={{
                color: titleColor,
                fontSize: 12,
                fontWeight: 800,
                margin: 0,
                whiteSpace: 'nowrap'
              }}
            >
              {title}
            </p>
          </Col>
          <Col xs="1">
            <p style={{ margin: 0, color: titleColor, fontSize: 12 }}>:</p>
          </Col>
          <Col xs={shortTitle ? '7' : '4'}>
            <p
              style={{
                fontSize: 12,
                fontWeight: 800,
                margin: 0
              }}
            >
              {detail}
            </p>
          </Col>
        </div>
      </Row>
    </Container>
  )
}
