export function capitalizeFirstLetter(string: string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase()
}

export function validateWordCount(
  value: String,
  maxWords: Number,
  required: Boolean
) {
  if (required) {
    return !(value?.length <= maxWords)
  }

  return value?.length > 0 && value?.length >= maxWords
}
