import { Button, Input, Row } from 'reactstrap'
import { useContext, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import React from 'react'

import CustomLabel from '../../components/InputFields/CustomLabel'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import ViewPermit from '../eptw/ViewPermit'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { woiEndpoint } from '../../components/serverurl'
import { PermissionsContext } from '../../App'
import { RadioButtonGroup, yesNoOptions } from '../eptw/VerifyPermit'
import { useAdminPermissions } from '../eptw/EditRisks'

export default function MakeLiveWOI() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const { permissions } = useContext(PermissionsContext)
  const [submitModal, setSubmitModal]: any = useState(false)

  const [formData, setFormData]: any = useState({
    understoodTask: 'No',
    manpowerAlloted: 'No',
    correctSpare: 'No',
    documentsAttached: 'No'
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const submitValues: any = {
      consent: {
        ...formData
      },

      permitId: data?.permit?._id,
      consentId: data?.permit?.consent?._id
    }
    const response = await makeAuthenticatedRequest(
      `${woiEndpoint}/makeLive`,
      'PATCH',
      submitValues,
      accounts,
      instance
    )

    return response.status
  }

  const form = (
    <div>
      <RadioButtonGroup
        question="Understood the complete task of this permit and communicated to IM and PIC ?"
        name="understoodTask"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Is the manpower allotted is sufficient to complete this Task ?"
        name="manpowerAlloted"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Is the correct spare taken from the stores if applicable ?"
        name="correctSpare"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Is all the documents attached to this Permit sufficient to complete this task ?"
        name="documentsAttached"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
    </div>
  )

  return (
    <ProtectedLayout onBack="/eptw/woi/makeLive">
      <ViewPermit data={data} isLoading={isLoading} />
      {data?.permit?.status === 'Approved WOI' && permissions?.canCreateWOI && (
        <>
          <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Button color="warning" onClick={() => setSubmitModal(true)}>
              Make Live
            </Button>
          </Row>
          {submitModal && (
            <SubmitModalWithForm
              isOpen={submitModal}
              onClose={(val: any) => {
                setSubmitModal(false)
                val && navigate('/eptw/woi/makeLive')
              }}
              onSubmit={handleSubmit}
              form={form}
            />
          )}
        </>
      )}
    </ProtectedLayout>
  )
}
