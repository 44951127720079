import { Row } from 'reactstrap'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { incidentReport } from '../../components/serverurl'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import FIRSTable from './FirsTable'

export default function ViewReports({ title, onBack, stage }: any) {
  const { accounts, instance } = useMsal()
  const fetchReports = async () =>
    await makeAuthenticatedRequest(
      `${incidentReport}?status=${stage}`,
      'GET',
      null,
      accounts,
      instance
    )

  const { data, isLoading, refetch, isRefetching }: any = useQuery(
    `${stage} Reports`,
    fetchReports
  )
  return (
    <ProtectedLayout title={title} onBack={onBack}>
      <Row style={{ margin: 15 }}>
        <FIRSTable
          data={data}
          isLoading={isLoading}
          isRefetching={isRefetching}
        />
      </Row>
    </ProtectedLayout>
  )
}
