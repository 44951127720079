import { Col, Row, Spinner } from 'reactstrap'
import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import CustomLabel from '../components/InputFields/CustomLabel'
import DropDownFetch from '../components/InputFields/DropDownFetch'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import Spacer from '../components/Spacer'
import ViewPermit from './eptw/ViewPermit'
import { EmptyContent } from './CreateNewPermit'
import { makeAuthenticatedRequest } from '../apiRequests'
import { permitFilters } from '../components/serverurl'

export default function SearchPermit() {
  const [permitNumber, setPermiNumber]: any = useState('PY1-EPTW-HP-2023/1')
  const { accounts, instance } = useMsal()

  const fetchPermit = async () => {
    const response = await makeAuthenticatedRequest(
      `${permitFilters}/byWoNumber?woNumber=${permitNumber}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response.data
  }

  const { data, isLoading, refetch, isRefetching }: any = useQuery(
    'permitData',
    fetchPermit
  )

  useEffect(() => {
    refetch()
  }, [permitNumber])

  return (
    <ProtectedLayout onBack="/eptw/dashboard">
      <Row style={{ margin: 10 }}>
        <Col>
          <CustomLabel label="Permit Number" />
          <DropDownFetch
            value={permitNumber}
            setValue={setPermiNumber}
            url={`${permitFilters}/number`}
          />
        </Col>
        <Spacer height={20} />
        {data ? (
          <>
            {isRefetching || isLoading ? (
              <EmptyContent label={<Spinner />} />
            ) : (
              <ViewPermit data={data} isLoading={isLoading} />
            )}
          </>
        ) : (
          <EmptyContent label={<Spinner />} />
        )}
      </Row>
    </ProtectedLayout>
  )
}
