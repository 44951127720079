import moment from 'moment'
import { Button, Col, ModalFooter, Row, Table } from 'reactstrap'
import { Form } from 'react-final-form'
import { useContext, useEffect, useRef, useState } from 'react'
import { Stepper } from 'react-form-stepper'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'

import CustomLabel from '../../components/InputFields/CustomLabel'
import DropDownFetch from '../../components/InputFields/DropDownFetch'
import LabeledTextInput from '../../components/InputFields/LabeledTextInput'
import RcmLayout from '../../components/rcm/RcmLayout'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi, usersEndpoint } from '../../components/serverurl'
import { SubHeader } from '../VerifyCM'
import { ViewSelectedEquipmentDetails } from './RcmPidEquipmentAnalysis'
import { useQuery } from 'react-query'
import { renderData } from '../workManagement/PmTree'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { LabeledDropdown } from '../../ashokleyland/AlViewWo'
import LabledParagraphInput from '../../components/InputFields/LabledParagraph'
import { UserContext } from '../../App'

const Step1 = ({ nextStep, disabled }: { nextStep: any; disabled: any }) => {
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const [usersList, setUsersList]: any = useState()
  const [createAnalysis, setCreateAnalysis]: any = useState(false)
  const [formValues, setFormValues]: any = useState({})
  const [result, setResult]: any = useState()

  return (
    <Form
      onSubmit={(values) => { }}
      initialValues={{
        ...formValues,
        date: new Date()
      }}
      render={({ form, hasValidationErrors, errors, values }) => (
        <form>
          <Row>
            <LabeledTextInput name="system" label="System" md={3} sm={12} />
            <LabeledTextInput
              name="date"
              label="Date"
              md={3}
              sm={12}
              value={moment().format('YYYY-MM-DD')}
            />
            <LabeledTextInput name="unit" label="Unit" md={3} sm={12} />
            <LabeledTextInput name="location" label="Location" md={3} sm={12} />
            <LabeledTextInput
              name="analysisDescription"
              label="Analysis Description"
              md={3}
              sm={12}
            />
            <Col xs="12">
              <CustomLabel label="Select Team Members" />
              <DropDownFetch
                name="usersList"
                value={usersList}
                setValue={setUsersList}
                url={`${usersEndpoint}/filters`}
                isMulti
                needSelect={false}
              />
            </Col>
            <Col xs="12">
              <Spacer height={20} />
              <Table
                bordered
                className="min-vh-50"
                style={{ borderColor: '#000' }}
              >
                <thead>
                  <tr>
                    {[
                      'S.No',
                      'Name',
                      'Emp No',
                      'Department',
                      'Designation'
                    ]?.map((h: any) => <th key={h}>{h}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {usersList?.map((e: any, Eindex: number) => (
                    <tr key={e?.value}>
                      <td>{Eindex + 1}</td>
                      <td>{e?.value}</td>
                      <td>{e?.employeeId}</td>
                      <td>{e?.department}</td>
                      <td>{e?.jobTitle}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col xs={2}>
              {!disabled && (
                <Button
                  color="warning"
                  onClick={(e) => {
                    e.preventDefault()
                    setFormValues(values)
                    setCreateAnalysis(true)
                  }}
                >
                  Submit
                </Button>
              )}
            </Col>
          </Row>
          <SubmitModalWithForm
            isOpen={createAnalysis}
            onClose={(val: any) => {
              setCreateAnalysis(false)
              navigate(`/cmms/rcm/createAnalysis?id=${result?._id}`)
              nextStep()
            }}
            onSubmit={async (e: any) => {
              e?.preventDefault()
              const body: any = {
                ...values,
                status: 'Stage2',
                team: usersList?.map((u: any) => u?.id)
              }
              const res = await makeAuthenticatedRequest(
                `${rcmApi}/rcmAnalysis`,
                'POST',
                body,
                accounts,
                instance
              )

              if (res.status === 'success') {
                setResult(res?.analysis)
                console.log(result)
                form.reset()
              }

              return res.status
            }}
            sucessView={<p>RCM Number: {result?.number}</p>}
            size="xs"
            header="Create Crew Analysis"
          />
        </form>
      )}
    />
  )
}

const Step2 = ({
  rcmData,
  nextStep,
  disabled,
  id,
  status,
  refetch
}: {
  rcmData: any
  nextStep: any
  disabled: any
  id: any
  status: any
  refetch: any
}) => {
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const [createAnalysis, setCreateAnalysis]: any = useState(false)
  const [result, setResult]: any = useState()
  const [selectedTags, setSelectedTags]: any = useState()

  return (
    <>
      <Col>
        <Button
          color="warning"
          onClick={() => navigate(`/cmms/rcm/systemBoundary?id=${id}`)}
        >
          Select Tags
        </Button>
        <Spacer height={20} />
      </Col>
      <ViewSelectedEquipmentDetails
        nextStep={nextStep}
        disabled={disabled}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
      <Col xs={2}>
        {status === 'Stage2' && (
          <Button
            color="warning"
            onClick={(e) => {
              e.preventDefault()
              setCreateAnalysis(true)
            }}
          >
            Submit
          </Button>
        )}
      </Col>
      <SubmitModalWithForm
        isOpen={createAnalysis}
        onClose={(val: any) => {
          setCreateAnalysis(false)
          navigate(`/cmms/rcm/createAnalysis?id=${result?._id}`)
          nextStep()
        }}
        onSubmit={async (e: any) => {
          e?.preventDefault()
          const body: any = {
            equipmentList: selectedTags,
            status: 'Stage3'
          }
          const res = await makeAuthenticatedRequest(
            `${rcmApi}/rcmAnalysis/${id}`,
            'PATCH',
            body,
            accounts,
            instance
          )

          if (res.status === 'success') {
            await refetch()
            setResult(res?.analysis)
            console.log(result)
          }

          return res.status
        }}
        sucessView={<p>RCM Number: {result?.number}</p>}
        size="xs"
        header="Add Tags"
      />
    </>
  )
}

const Step3 = ({
  rcmData,
  nextStep,
  disabled,
  id,
  status,
  refetch
}: {
  rcmData: any
  nextStep: any
  disabled: any
  id: any
  status: any
  refetch: any
}) => {
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const [createAnalysis, setCreateAnalysis]: any = useState(false)
  const [result, setResult]: any = useState()
  const [selectedTags, setSelectedTags]: any = useState()

  return (
    <>
      <Col>
        <Button
          color="warning"
          onClick={() => navigate(`/cmms/rcm/systemBoundary?id=${id}`)}
        >
          Select Tags
        </Button>
        <Spacer height={20} />
      </Col>
      <ViewSelectedEquipmentDetails
        nextStep={nextStep}
        disabled={disabled}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
      <Col xs={2}>
        {status === 'Stage3' && (
          <Button
            color="warning"
            onClick={(e) => {
              e.preventDefault()
              setCreateAnalysis(true)
            }}
          >
            Submit
          </Button>
        )}
      </Col>
      <SubmitModalWithForm
        isOpen={createAnalysis}
        onClose={(val: any) => {
          setCreateAnalysis(false)
          navigate(`/cmms/rcm/createAnalysis?id=${result?._id}`)
          nextStep()
        }}
        onSubmit={async (e: any) => {
          e?.preventDefault()
          const body: any = {
            equipmentList: selectedTags,
            status: 'Stage4'
          }
          const res = await makeAuthenticatedRequest(
            `${rcmApi}/rcmAnalysis/${id}`,
            'PATCH',
            body,
            accounts,
            instance
          )

          if (res.status === 'success') {
            await refetch()
            setResult(res?.analysis)
            console.log(result)
          }

          return res.status
        }}
        sucessView={<p>RCM Number: {result?.number}</p>}
        size="xs"
        header="Add Function"
      />
    </>
  )
}

export default function RcmCreateAnalysis() {
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const navigate = useNavigate()
  const [usersList, setUsersList]: any = useState()
  const [createAnalysis, setCreateAnalysis]: any = useState(false)
  const [formValues, setFormValues]: any = useState({})
  const [result, setResult]: any = useState()

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15 }}>
        <SubHeader header={`Create Analysis`} permitColor="gold" />
        <Form
          onSubmit={(values) => { }}
          initialValues={{
            ...formValues,
            rcmInitiator: userData?.givenName,
            date: new Date()
          }}
          render={({ form, hasValidationErrors, errors, values }) => (
            <form>
              <Row>
                <LabeledDropdown
                  name="plantUnit"
                  label="Plant Unit"
                  occupy={3}
                  options={[
                    'Platform',
                    'Hydrocracker',
                    'FPSO',
                    'FSO',
                    'Drilling Rigs'
                  ]}
                />
                <LabeledDropdown
                  name="system"
                  label="System"
                  occupy={3}
                  options={['System 23', 'System 27', 'System 43', 'System 68', 'System 71']}
                />
                <LabeledTextInput
                  name="date"
                  label="Date"
                  md={3}
                  sm={12}
                  value={moment().format('YYYY-MM-DD')}
                />
                <LabeledDropdown
                  name="location"
                  label="System Location"
                  occupy={3}
                  options={['Top Side', 'Marine', 'Engine Room']}
                />
                <LabeledTextInput
                  name="initiator"
                  label="RCM Initiator"
                  value={userData?.givenName}
                  isDisabled
                  occupy={3}
                />
                <LabledParagraphInput
                  name="analysisDescription"
                  label="Analysis Description"
                  occupy={12}
                  height={80}
                />

                <Spacer height={20} />
                <ModalFooter>
                  <Button
                    color="warning"
                    onClick={(e) => {
                      e.preventDefault()
                      setFormValues(values)
                      setCreateAnalysis(true)
                    }}
                  >
                    Select Team Member
                  </Button>
                </ModalFooter>
              </Row>
              <SubmitModalWithForm
                isOpen={createAnalysis}
                onClose={(val: any) => {
                  setCreateAnalysis(false)
                  navigate(`/cmms/rcm/createAnalysis`)
                }}
                onSubmit={async (e: any) => {
                  e?.preventDefault()
                  const body: any = {
                    ...values,
                    status: 'Stage2',
                    team: usersList?.map((u: any) => u?.id)
                  }
                  const res = await makeAuthenticatedRequest(
                    `${rcmApi}/rcmAnalysis`,
                    'POST',
                    body,
                    accounts,
                    instance
                  )

                  if (res.status === 'success') {
                    setResult(res?.analysis)
                    console.log(result)
                    setFormValues({})
                    setUsersList()
                    form.reset()
                  }

                  return res.status
                }}
                sucessView={
                  <p>
                    RCM Number: <b> {result?.number}</b>
                  </p>
                }
                size="lg"
                header="Create RCM Analysis"
                form={
                  <>
                    <Col>
                      {renderData(<b>Plant Unit</b>, values?.plantUnit)}
                      {renderData(<b>System</b>, values?.system)}
                      {renderData(<b>System Location</b>, values?.location)}
                      {renderData(<b>RCM Initiator</b>, values?.rcmInitiator)}
                      {renderData(<b>Date</b>, getReadableTime(values?.date))}
                      {renderData(
                        <b>Analysis Description</b>,
                        values?.analysisDescription
                      )}
                    </Col>
                    <Col xs="12">
                      <p className="text-center fw-bold">Select Team Members</p>
                      <DropDownFetch
                        name="usersList"
                        value={usersList}
                        setValue={setUsersList}
                        url={`${usersEndpoint}/filters`}
                        isMulti
                        needSelect={false}
                      />
                    </Col>
                    <Col xs="12">
                      <Spacer height={20} />
                      <Table
                        bordered
                        className="min-vh-50"
                        style={{ borderColor: '#000' }}
                      >
                        <thead>
                          <tr>
                            {[
                              'S.No',
                              'Name',
                              'Emp No',
                              'Department',
                              'Designation'
                            ]?.map((h: any) => <th key={h}>{h}</th>)}
                          </tr>
                        </thead>
                        <tbody>
                          {usersList?.map((e: any, Eindex: number) => (
                            <tr key={e?.value}>
                              <td>{Eindex + 1}</td>
                              <td>{e?.value}</td>
                              <td>{e?.employeeId}</td>
                              <td>{e?.department}</td>
                              <td>{e?.jobTitle}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </>
                }
              />
            </form>
          )}
        />
      </Row>
    </RcmLayout>
  )
}
