import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'

import ProtectedLayout from '../components/Layout/ProtectedLayout'
import ProjectsOperationsToggle from '../components/ProjectsOperationsToggle'
import RcmCircleMenu from '../SVGs/RcmCircleMenu'
import FirsCircleMenu from '../SVGs/FirsCircleMenu'

export default function FirsServices() {
  const [mode, setMode] = useState(true)
  return (
    <ProtectedLayout onBack="/">
      <Row>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100px',
            alignItems: 'center'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                marginBottom: 0,
                fontWeight: 700,
                fontSize: 20,
                textDecoration: !mode ? 'underline' : 'none'
              }}
            >
              Projects
            </p>
            <ProjectsOperationsToggle
              onClick={() => setMode(!mode)}
              mode={mode}
            />
            <p
              style={{
                marginBottom: 0,
                fontWeight: 700,
                fontSize: 20,
                textDecoration: mode ? 'underline' : 'none'
              }}
            >
              Operations
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <div
            style={{
              height: '90vh',
              width: '90vh'
            }}
          >
            <FirsCircleMenu />
          </div>
        </Col>
      </Row>
    </ProtectedLayout>
  )
}
