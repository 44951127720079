import { useEffect, useState } from 'react'

import { getPMList } from '../components/serverurl'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import { useParams } from 'react-router-dom'
import Loader from '../components/Loader'
import PMPreview from './PMPreview'
import Spacer from '../components/Spacer'
import { makeAuthenticatedRequest } from '../apiRequests'
import { useMsal } from '@azure/msal-react'
import { Row } from 'reactstrap'

export const cmTableHeaders = [
  'S.No',
  'WO Number',
  'Title',
  'Priority',
  'Equipment',
  'Resp. Department',
  'Status',
  'Created At',
  'Due Date'
]

export default function ViewPM() {
  const { id } = useParams()
  const { instance, accounts } = useMsal()
  const [data, setData]: any = useState()

  useEffect(() => {
    if (!data) {
      try {
        makeAuthenticatedRequest(
          `${getPMList}/${id}`,
          'GET',
          null,
          accounts,
          instance
        ).then((response: any) => {
          setData(response)
        })
      } catch (err) {
        console.error(err)
      }
    }
  })

  if (!data) {
    return <Loader />
  }

  return (
    <ProtectedLayout onBack="/pm/dashboard">
      <Row style={{ margin: 10 }}>
        <PMPreview
          data={data?.data}
          closeOutData={data?.closeOutForm}
          hasOngoingPermit={data?.hasOngoingPermit}
          approvalHistory={data?.approvalHistory}
        />
      </Row>
      <Spacer height={20} />
    </ProtectedLayout>
  )
}
