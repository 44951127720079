import React, { useContext, useState } from 'react'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap'
import { Field, Form } from 'react-final-form'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import Spacer from '../../components/Spacer'
import { UserContext } from '../../App'
import { SubHeader } from '../VerifyCM'
import ViewTravelRequest from './ViewTravelRequest'
import { ticketApi } from '../../components/serverurl'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { useMsal } from '@azure/msal-react'

const initialValues = {
  crewName: 'Alejandro',
  employeeId: 'E-9345',
  designation: 'Maintenance Supt',
  passport: {
    firstName: 'William',
    middleName: 'James',
    lastName: 'Jonathan',
    issuePlace: 'Forida',
    dob: '1993-03-10',
    issue: '2014-10-29',
    expiry: '2026-11-30',
    number: '9928348'
  },
  airlineProgram: 'Emirates',
  airlineNumber: '7812023',
  destination1: {
    travelDate: '2024-12-02',
    mode: 'Flight',
    origin: 'London',
    destination: 'Singapore',
    suggestedMode: 'Flight',
    suggestedRouteOrigin: 'Dubai',
    suggestedRouteDestination: 'Seattle'
  },
  destination2: {
    travelDate: '2025-12-31',
    mode: 'Flight',
    origin: 'Italy',
    destination: 'Singapore',
    suggestedMode: 'Flight',
    suggestedRouteOrigin: 'Dubai',
    suggestedRouteDestination: 'Seattle'
  },
  addDestination: true,
  destination3: {
    travelDate: '2024-11-30',
    mode: 'Train',
    origin: 'Newyork',
    destination: 'London',
    suggestedMode: 'Train',
    suggestedRouteOrigin: 'Newyork',
    suggestedRouteDestination: 'London'
  },
  certificates: {
    yellowFeverVaccination: 'Yes',
    bosiet: 'Yes',
    malaria: 'Yes'
  },
  emergency: {
    contactRelation: 'Father',
    contactName: 'Uncle Pal',
    medicalHistory: 'Anxiety Attacks',
    physicialContact: 'Doctor Kins',
    physicialContactNumber: '+938424'
  },
  notForMe: true,
  other: {
    crewName: 'Mike',
    employeeId: 'Smith',
    designation: 'Jonas'
  }
}

export function TextField({
  name,
  label,
  isDisabled,
  value,
  sm,
  md
}: {
  name: string
  label: string
  isDisabled?: boolean
  value?: string
  sm?: number
  md?: number
}) {
  return (
    <Col sm={sm} md={md}>
      <Field name={name}>
        {({ input, meta }) => (
          <div key={`${name}^${label}`}>
            <Input
              {...input}
              type="text"
              disabled={isDisabled}
              value={value || input.value}
              style={{ fontSize: 14 }}
            />
            {meta.error && meta.touched && (
              <span style={{ color: 'red', fontWeight: 400, fontSize: 14 }}>
                {meta.error}
              </span>
            )}
          </div>
        )}
      </Field>
      <Spacer height=".3vh" />
      <span style={{ color: '#56647e', fontSize: '.75rem' }}>{label}</span>
      <Spacer height=".7vh" />
    </Col>
  )
}

export function DateField({
  name,
  label,
  isDisabled,
  value,
  sm,
  md
}: {
  name: string
  label: string
  isDisabled?: boolean
  value?: string
  sm?: number
  md?: number
}) {
  return (
    <Col sm={sm} md={md}>
      <Field name={name}>
        {({ input, meta }) => (
          <div key={`${name}^${label}`} className="d-flex">
            <Input
              {...input}
              type="date"
              disabled={isDisabled}
              value={value || input.value}
            />
            {meta.error && meta.touched && (
              <span style={{ color: 'red', fontWeight: 400, fontSize: 14 }}>
                {meta.error}
              </span>
            )}
          </div>
        )}
      </Field>
      <Spacer height=".3vh" />
      <span style={{ color: '#56647e', fontSize: '.75rem' }}>{label}</span>
      <Spacer height=".7vh" />
    </Col>
  )
}

export function RadioButtons({
  name,
  label,
  isDisabled,
  value,
  sm,
  md,
  list,
  defaultValue
}: {
  name: string
  label: string
  isDisabled?: boolean
  value?: string
  sm?: number
  md?: number
  list: { value: string; label: string }[] // Updated list type
  defaultValue?: string
}) {
  return (
    <Col sm={sm} md={md}>
      <Col
        className="d-flex align-items-center"
        style={{
          border: '1px solid #ced4da', // Bootstrap input border
          padding: '0.375rem 0.75rem', // Matches form-control padding
          width: 'auto',
          borderRadius: '.25rem',
          backgroundClip: 'padding-box',
          height: '38px' // Bootstrap input height
        }}
      >
        {list.map((item) => (
          <Field
            name={name}
            type="radio"
            value={item.value}
            key={`${name}^${item.value}`}
          >
            {({ input }) => (
              <div className="d-flex align-items-center">
                <Input
                  {...input}
                  type="radio"
                  id={`${name}-${item.value}`}
                  disabled={isDisabled}
                  defaultChecked={defaultValue === item.value}
                  style={{ marginTop: 0 }}
                />
                <label
                  htmlFor={`${name}-${item.value}`}
                  style={{
                    marginRight: 6,
                    marginLeft: 6,
                    marginBottom: 0,
                    fontSize: '.9rem'
                  }}
                >
                  {item.label}
                </label>
              </div>
            )}
          </Field>
        ))}
      </Col>
      <Spacer height=".3vh" />
      <Col className="">
        <span style={{ marginLeft: 10, color: '#56647e', fontSize: '.75rem' }}>
          {label}
        </span>
      </Col>
      <Spacer height=".7vh" />
    </Col>
  )
}

export function FormGroup({
  title,
  children
}: {
  title: string
  children: any
}) {
  return (
    <>
      <Spacer height={'1.5vh'} />
      <h5 style={{ fontSize: '1.3rem' }}>{title}</h5>
      {children}
    </>
  )
}

export function SingleCheckBox({
  name,
  label,
  isDisabled,
  value,
  sm,
  md
}: {
  name: string
  label: string
  isDisabled?: boolean
  value?: string
  sm?: number
  md?: number
}) {
  return (
    <Col sm={sm} md={md} className="d-flex">
      <Spacer height=".3vh" />
      <span>{label}</span>
      <Field name={name}>
        {({ input, meta }) => (
          <div key={`${name}^${label}`}>
            <Input
              {...input}
              type="checkbox"
              disabled={isDisabled}
              value={value || input.value}
              style={{ marginLeft: 6 }}
            />
            {meta.error && meta.touched && (
              <span style={{ color: 'red', fontWeight: 400, fontSize: 14 }}>
                {meta.error}
              </span>
            )}
          </div>
        )}
      </Field>
      <Spacer height=".7vh" />
    </Col>
  )
}

export const CrewDetails = ({ name }: { name?: any }) => {
  return (
    <>
      <FormGroup title="Crew Details">
        <Spacer height="1vh" />
        <TextField
          md={4}
          sm={12}
          name={name ? `${name}.crewName` : 'crewName'}
          label="Crew Name"
          isDisabled={!name}
        />
        <TextField
          md={4}
          sm={12}
          name={name ? `${name}.employeeId` : 'employeeId'}
          label="Employee ID"
          isDisabled={!name}
        />
        <TextField
          md={4}
          sm={12}
          name={name ? `${name}.designation` : 'designation'}
          label="Desgination"
          isDisabled={!name}
        />
      </FormGroup>
      <FormGroup title="Passport Information">
        <Spacer height="1vh" />
        <TextField
          md={4}
          sm={12}
          name={name ? `${name}.passport.firstName` : 'passport.firstName'}
          label="First Name"
        />
        <TextField
          md={4}
          sm={12}
          name={name ? `${name}.passport.middleName` : 'passport.middleName'}
          label="Middle Name"
        />
        <TextField
          md={4}
          sm={12}
          name={name ? `${name}.passport.lastName` : 'passport.LastName'}
          label="Last Name"
        />
        <TextField
          md={4}
          sm={12}
          name={name ? `${name}.passport.issuePlace` : 'passport.issuePlace'}
          label="Issue Place"
        />
        <DateField
          md={4}
          sm={12}
          name={name ? `${name}.passport.dob` : 'passport.dob'}
          label="Date of Birth"
        />
        <DateField
          md={4}
          sm={12}
          name={name ? `${name}.passport.issue` : 'passport.issue'}
          label="Passport Issue"
        />
        <DateField
          md={4}
          sm={12}
          name={name ? `${name}.passport.expiry` : 'passport.expiry'}
          label="Passport Expiry"
        />
        <TextField
          md={4}
          sm={12}
          name={name ? `${name}.passport.number` : 'passport.number'}
          label="Passport Number"
        />
        <RadioButtons
          label="Airline Loyalty Program"
          name={name ? `${name}.airlineProgram` : 'airlineProgram'}
          list={[
            { label: 'Emirates', value: 'Emirates' },
            { label: 'Lufthansa', value: 'Lufthansa' },
            { label: 'Indigo', value: 'Indigo' }
          ]}
        />
        <TextField
          md={4}
          sm={12}
          name={name ? `${name}.airlineNumber` : 'airlineNumber'}
          label="Airline Number"
        />
      </FormGroup>
    </>
  )
}

export const Destination = ({
  name,
  title
}: {
  name: string
  title: string
}) => {
  return (
    <FormGroup title={title}>
      <Spacer height="1vh" />
      <DateField
        sm={12}
        md={4}
        name={`${name}.travelDate`}
        label="Travel Date"
      />
      <RadioButtons
        sm={12}
        md={4}
        name={`${name}.mode`}
        label="Travel Mode"
        list={[
          { label: 'Bus', value: 'Bus' },
          { label: 'Train', value: 'Train' },
          { label: 'Flight', value: 'Flight' }
        ]}
      />

      <TextField name={`${name}.origin`} label="Where From" sm={12} md={4} />
      <TextField name={`${name}.destination`} label="Where To" sm={12} md={4} />
      <RadioButtons
        sm={12}
        md={4}
        name={`${name}.suggestedMode`}
        label="Suggested Mode"
        list={[
          { label: 'Bus', value: 'Bus' },
          { label: 'Train', value: 'Train' },
          { label: 'Flight', value: 'Flight' }
        ]}
      />
      <TextField
        name={`${name}.suggestedRouteOrigin`}
        label="Suggested Route From"
        sm={12}
        md={4}
      />
      <TextField
        name={`${name}.suggestedRouteDestination`}
        label="Suggested Route To"
        sm={12}
        md={4}
      />
    </FormGroup>
  )
}

export const EmergencyContact = () => {
  return (
    <FormGroup title="Emergency Contact">
      <Spacer heihgt="1vh" />
      <RadioButtons
        sm={12}
        md={4}
        name={`emergency.contactRelation`}
        label="Emergency Contact Relation"
        list={[
          { label: 'Wife', value: 'Wife' },
          { label: 'Father', value: 'Father' },
          { label: 'Gaurdian', value: 'Guardian' },
          { label: 'Others', value: 'Others' }
        ]}
      />
      <TextField
        name={`emergency.contactName`}
        label="Contact Name"
        sm={12}
        md={4}
      />
      <TextField
        name={`emergency.medicalHistory`}
        label="Medical History"
        sm={12}
        md={4}
      />
      <TextField
        name={`emergency.physicialContact`}
        label="Emergency Physician Contact"
        sm={12}
        md={4}
      />
      <TextField
        name={`emergency.physicialContactNumber`}
        label="Emergency Physician Contact Number"
        sm={12}
        md={4}
      />
    </FormGroup>
  )
}

export default function CreateTravelForm() {
  const { userData } = useContext(UserContext)
  const { accounts, instance } = useMsal()

  const [preview, setPreview] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [result, setResult]: any = useState()

  return (
    <ProtectedLayout title="CREATE TRAVEL REQUEST">
      <Row style={{ margin: 15 }}>
        <Col>
          <Form
            onSubmit={(values: any) => console.log(values)}
            initialValues={{
              ...initialValues,
              crewName: userData?.givenName,
              employeeId: userData?.employeeId,
              designation: userData?.jobTitle
            }}
            render={({ values, form }) => (
              <form>
                <Row style={{ paddingLeft: '5vh', paddingRight: '5vh' }}>
                  <CrewDetails />
                  <Row>
                    <Spacer height="1vh" />
                    <SingleCheckBox name="notForMe" label="Not For me" />
                  </Row>
                  {values?.notForMe && <CrewDetails name={'other'} />}
                  <Row>
                    <Destination name="destination1" title="Destination 1" />
                    <Destination name="destination2" title="Destination 2" />
                  </Row>
                  <Row>
                    <Spacer height="1vh" />
                    <SingleCheckBox
                      name="addDestination"
                      label="Add Destination"
                    />
                  </Row>
                  {values?.addDestination && (
                    <Row>
                      <Destination name="destination3" title="Destination 3" />
                    </Row>
                  )}
                  <Spacer height="3vh" />
                  <Row>
                    <FormGroup title="Certificates">
                      <Spacer heihgt="1vh" />
                      <RadioButtons
                        sm={12}
                        md={4}
                        name={`certificates.yellowFeverVaccination`}
                        label="Yellow Fever Vaccination"
                        list={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' }
                        ]}
                      />
                      <RadioButtons
                        sm={12}
                        md={4}
                        name={`certificates.bosiet`}
                        label="Valid BOSIET Certificate"
                        list={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' }
                        ]}
                      />
                      <RadioButtons
                        sm={12}
                        md={4}
                        name={`certificates.malaria`}
                        label="Valid Malaria Certificate"
                        list={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' }
                        ]}
                      />
                    </FormGroup>
                  </Row>
                  <Row>
                    <EmergencyContact />
                  </Row>
                  <ModalFooter>
                    <Button onClick={() => setPreview(true)} color="warning">
                      Submit
                    </Button>
                  </ModalFooter>
                  {preview && (
                    <Modal
                      isOpen={preview}
                      toggle={() => setPreview(false)}
                      fullscreen
                    >
                      <ModalBody>
                        <SubHeader header="Travel Details" permitColor="gold" />
                        <ViewTravelRequest data={values} />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          color="warning"
                          onClick={() => setPreview(false)}
                        >
                          Close
                        </Button>
                        <Button color="warning" onClick={() => setSubmit(true)}>
                          Submit Travel Request
                        </Button>
                      </ModalFooter>
                    </Modal>
                  )}
                  {submit && (
                    <SubmitModalWithForm
                      isOpen={submit}
                      onClose={(val: any) => {
                        setSubmit(false)
                      }}
                      onSubmit={async (e: any) => {
                        e?.preventDefault()
                        const res = await makeAuthenticatedRequest(
                          `${ticketApi}`,
                          'POST',
                          values,
                          accounts,
                          instance
                        )

                        if (res.status === 'success') {
                          setResult(res)
                        }

                        return res.status
                      }}
                      size="xs"
                      header="Create Travel Request"
                    />
                  )}
                </Row>
              </form>
            )}
          />
        </Col>
      </Row>
    </ProtectedLayout>
  )
}
