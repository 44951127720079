import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Container,
  Row,
  Table,
  Col
} from 'reactstrap'
import { Field, Form } from 'react-final-form'
import { RiDeleteBin6Line } from 'react-icons/ri'

import MultiSelect from '../InputFields/MultiSelect'
import isolationTypes from './../constants/isolations.json'
import LabeledTextInput from '../InputFields/LabeledTextInput'
import electricalIsolationMethods from './../constants/electricalPermitMethods.json'
import { useState } from 'react'
import LabeledRadioButtons from '../InputFields/LabeledRadioButton'
import toNormalCase from '../lib/toNormalCase'
import LabledParagraphInput from '../InputFields/LabledParagraph'
import CustomLabel from '../InputFields/CustomLabel'
import { validateWordCount } from '../lib/capitalizeFirstLetter'
import { FaEdit } from 'react-icons/fa'

function CreateIsolation({
  isOpen,
  toggle,
  isolations,
  setIsolations,
  initialValues,
  setEdit
}: any) {
  const mechanicalMethods = [
    'Immobilization',
    'Block',
    'Double Block & Bleed',
    'Single Block & Bleed',
    'Bleed',
    'Spade',
    'Spectacle Blind',
    'Blind Flange',
    'Spool Disconnection'
  ]?.map((k: any) => ({ label: k, value: k }))

  const instrumentMethods = [
    'Force',
    'Inhibit',
    'By Pass',
    'Disconnect',
    'Block'
  ]?.map((k: any) => ({ label: k, value: k }))

  // const getMethods = (type: any) => type === "electrical" ? electricalIsolationMethods : defaultOptions

  const getMethods = (type: any) => {
    if (type === 'Electrical') {
      return electricalIsolationMethods
    } else if (type === 'Mechanical' || type === 'Production') {
      return mechanicalMethods
    } else if (type === 'Instrument') {
      return instrumentMethods
    }
  }

  const formSubmit = (values: any) => {
    setIsolations([...isolations, values])
    toggle()
  }

  const validate = (values: any) => {
    const errors: any = {}
    if (!values.isolationType) {
      errors.isolationType = 'Required'
    }
    if (!values.isolationMethod) {
      errors.isolationMethod = 'Required'
    }
    if (!values.isolationPoint) {
      errors.isolationPoint = 'Required'
    } else if (validateWordCount(values.isolationPoint, 200, true)) {
      errors.isolationPoint = `Should not exceed ${200} characters`
    }
    if (!values.pressureBuildup) {
      errors.pressureBuildup = 'Required'
    }
    if (!values.isolationState) {
      errors.isolationState = 'Required'
    }
    if (!values.deIsolatedState) {
      errors.deIsolatedState = 'Required'
    }
    if (!values.isolationDescription) {
      errors.isolationDescription = 'Required'
    } else if (validateWordCount(values.isolationDescription, 1000, true)) {
      errors.isolationDescription = `Should not exceed ${1000} characters`
    }

    return errors
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
        <Form
          onSubmit={(values: any) => {
            formSubmit(values)
            setEdit({})
          }}
          validate={validate}
          initialValues={initialValues}
          render={({
            handleSubmit,
            form,
            hasValidationErrors,
            errors,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <Row>
                  <Col
                    xs="12"
                    style={{
                      textAlign: 'center',
                      backgroundColor: 'orange',
                      height: 100,
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      fontSize: 24,
                      marginBottom: 20
                    }}
                  >
                    <b>Add Isolation</b>
                  </Col>
                </Row>
                <Row>
                  <LabledParagraphInput
                    label="Equipment Description"
                    name="isolationDescription"
                    occupy={12}
                  />
                  <LabeledTextInput
                    label="Equipment Tag No."
                    name="isolationPoint"
                    occupy={12}
                  />
                  <LabledParagraphInput
                    label="Isolation Tag No."
                    name="isolationTag"
                    occupy={12}
                  />
                  <Col xs="6">
                    <CustomLabel label="Isolation Type" />
                    <Field
                      name={'isolationType'}
                      component="select"
                      placeholder="s"
                      style={{
                        width: '-webkit-fill-available',
                        height: 38,
                        borderRadius: 5
                      }}
                    >
                      <option value="" disabled selected>
                        Select Type
                      </option>
                      <option value="Mechanical">Mechanical</option>
                      <option value="Electrical">Electrical</option>
                      <option value="Instrument">Instrument</option>
                      <option value="Production">Production</option>
                    </Field>
                    {errors?.['isolationType'] && (
                      <>
                        <span
                          style={{
                            fontSize: 12,
                            color: 'red'
                          }}
                        >
                          * {errors?.['isolationType']}
                        </span>
                      </>
                    )}
                  </Col>
                  <Col xs="6">
                    <CustomLabel label="Isolation Method" />
                    <Field
                      name={'isolationMethod'}
                      component="select"
                      placeholder="s"
                      style={{
                        width: '-webkit-fill-available',
                        height: 38,
                        borderRadius: 5
                      }}
                    >
                      <option value="" disabled selected>
                        Select Method
                      </option>
                      {getMethods(values.isolationType)?.map(
                        (option: any, index: number) => (
                          <option
                            key={`${index}-${option?.value}`}
                            value={option?.value}
                          >
                            {option?.value}
                          </option>
                        )
                      )}
                    </Field>
                    {errors?.['isolationMethod'] && (
                      <>
                        <span
                          style={{
                            fontSize: 12,
                            color: 'red'
                          }}
                        >
                          * {errors?.['isolationMethod']}
                        </span>
                      </>
                    )}
                  </Col>

                  <LabeledTextInput
                    label="Isolation State (Before)"
                    name="isolationState"
                  />
                  <LabeledTextInput
                    label="De Isolation State (After)"
                    name="deIsolatedState"
                  />
                  <>
                    <LabeledRadioButtons
                      name="override"
                      label="Override"
                      list={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' }
                      ]}
                    />
                    <LabeledRadioButtons
                      name="pressureBuildup"
                      label="Pressure Buildup"
                      list={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' }
                      ]}
                    />
                    {errors?.['pressureBuildup'] && (
                      <>
                        <span
                          style={{
                            fontSize: 12,
                            color: 'red'
                          }}
                        >
                          * {errors?.['pressureBuildup']}
                        </span>
                      </>
                    )}
                  </>
                  {values?.pressureBuildup === 'Yes' && (
                    <Col xs="6">
                      <CustomLabel label="Readings" />
                      <Field
                        name={'readings'}
                        component="select"
                        style={{
                          width: '-webkit-fill-available',
                          height: 38,
                          borderRadius: 5
                        }}
                      >
                        <option />
                        <option value="Continous">Continous</option>
                        <option value="Intermittent">Intermittent</option>
                        <option value="One Time">One Time</option>
                      </Field>
                    </Col>
                  )}
                  <LabledParagraphInput
                    label="Remarks"
                    name="isolationComment"
                    occupy={12}
                  />
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={form.submit}
                  color={hasValidationErrors ? 'secondary' : 'warning'}
                  children="Add"
                  disabled={hasValidationErrors}
                />
              </ModalFooter>
            </form>
          )}
        />
      </Modal>
    </>
  )
}

export default function IsolationForm({
  isOpen,
  toggle,
  values,
  setValues
}: any) {
  const [isFormOpen, setIsFormOpen] = useState(false)

  const [isolations, setIsolations] = useState(values || [])
  const [edit, setEdit]: any = useState()

  const toggleForm = () => setIsFormOpen(!isFormOpen)

  const tableHeaders = [
    'S.No',
    'Equipment Description',
    'Equipment Tag No.',
    'Isolation Tag No.',
    'Isolation Type',
    'Isolation Method',
    'Isolation State',
    'De Isolation State',
    'Override',
    'Pressure BuildUp',
    'PBU Readings',
    'Comment',
    '',
    ''
  ]

  const ZeroState = () => (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'inherit',
          margin: 0,
          height: '20vh'
        }}
      />
    </>
  )

  var serialNo = 0

  return (
    <Container>
      <Modal isOpen={isOpen} toggle={toggle} fullscreen>
        <ModalBody>
          <Row>
            <Col
              xs="12"
              style={{
                textAlign: 'center',
                backgroundColor: 'orange',
                height: 100,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                fontSize: 24,
                marginBottom: 20
              }}
            >
              <b>Isolation Certificate</b>
            </Col>
          </Row>
          <Table striped>
            <thead style={{ backgroundColor: 'orange' }}>
              <tr>
                {tableHeaders.map((header: string) => (
                  <th key={`tableHeader_${header}`}>{header}</th>
                ))}
              </tr>
            </thead>
            {isolations.length === 0 ? (
              <ZeroState />
            ) : (
              <tbody>
                {isolations?.map((isolation: any, index: any) => (
                  <tr
                    key={`tableEntry_isolation.${index}.${isolation.isolationType}`}
                  >
                    <td>{++serialNo}</td>
                    <td>{isolation?.isolationDescription}</td>
                    <td>{isolation?.isolationPoint}</td>
                    <td>{isolation?.isolationTag}</td>
                    <td>{isolation?.isolationType}</td>
                    <td>{isolation?.isolationMethod}</td>
                    <td>{isolation?.isolationState}</td>
                    <td>{isolation?.deIsolatedState}</td>
                    <td>{isolation?.override}</td>
                    <td>{toNormalCase(isolation?.pressureBuildup)}</td>
                    <td>{isolation?.readings}</td>
                    <td>{isolation?.isolationComment}</td>
                    <td>
                      <FaEdit
                        style={{ color: 'red' }}
                        onClick={() => {
                          setEdit(isolation)
                          setIsFormOpen(true)
                          setIsolations(
                            isolations.filter(
                              (isolation: any, i: any) => !(index === i)
                            )
                          )
                        }}
                      />
                    </td>
                    <td>
                      <RiDeleteBin6Line
                        style={{ color: 'red' }}
                        onClick={() => {
                          setIsolations(
                            isolations.filter(
                              (isolation: any, i: any) => !(index === i)
                            )
                          )
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
            <br />
            <br />
            {/* <Button onClick={() => { setIsFormOpen(true) }} color="warning">
              Add Isolation
            </Button> */}
          </Table>
          <Row>
            <Col xs="12">
              <Button
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 'inherit'
                }}
                onClick={() => {
                  setIsFormOpen(true)
                }}
                color="warning"
              >
                Click to add Isolation
              </Button>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="warning"
            onClick={() => {
              setValues(isolations)
              toggle()
            }}
          >
            Previous
          </Button>
          <Button
            color="warning"
            onClick={() => {
              setValues(isolations)
              toggle()
            }}
          >
            Save & Next
          </Button>
        </ModalFooter>
        <CreateIsolation
          isOpen={isFormOpen}
          toggle={toggleForm}
          isolations={isolations}
          setIsolations={setIsolations}
          initialValues={edit}
          setEdit={setEdit}
        />
      </Modal>
    </Container>
  )
}
