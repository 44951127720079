import React from 'react'
import { Row } from 'reactstrap'

import PrintConfinedSpaceEntryTalk from '../prints/PrintConfinedTalk'
import PrintPermitHeader from '../prints/PrintPermitHeader'
import Spacer from '../Spacer'
import { ColoredSubHeader } from './ViewExcavationPermit'
import { renderArray } from '../../pages/WaitingForPIC'
import { PrintHeaderLogos } from '../prints/PrintHeaderLogos'
import { renderLongRow } from '../Modals/AuditPreviewModal'

type ViewConfined = {
  showPrintHeader?: any
  data: any
  rowStyle: any
  renderRow: any
  permitData: any
}

export default function ViewConfinedSpace({
  showPrintHeader,
  data,
  rowStyle,
  renderRow,
  permitData
}: ViewConfined) {
  const fields: any = {
    'Location of Work': data?.location,
    'Vessel / Pit / Tank worked on': data?.vessel,
    'Contractor Other': data?.contractor,
    Department: renderArray(data?.department, false)
  }

  const header: any = {
    'Confined Space Entry Permit': data?.permitNumber
    // 'Permit Number': permitData?.woNumber,
    // 'Permit Title': permitData?.permitTitle
  }

  return (
    <>
      {showPrintHeader && (
        <>
          <PrintHeaderLogos permitType={permitData?.type} />
          <PrintPermitHeader permitData={permitData} printData={permitData} />
        </>
      )}
      <ColoredSubHeader
        rowStyle={rowStyle}
        text="Confined Space Entry Permit"
      />
      <Row
        style={{
          ...rowStyle,
          fontSize: 12,
          margin: 0
        }}
      >
        {data?.permitNumber &&
          Object.keys(header).map((k) => renderRow(k, header[k]))}
      </Row>
      <Spacer height={5} />
      <Row
        style={{
          fontSize: 12,
          margin: 0
        }}
      >
        {Object.keys(fields).map((key) =>
          renderLongRow(key, fields[key], rowStyle.backgroundColor, 6)
        )}
      </Row>
      <Spacer height={10} />
      {permitData?.status === 'live' && (
        <Row
          style={{
            margin: 0,
            fontSize: 12,
            WebkitPrintColorAdjust: 'exact'
          }}
        >
          <PrintConfinedSpaceEntryTalk />
        </Row>
      )}
    </>
  )
}
