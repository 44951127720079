import { Button, Col, ModalFooter, Row, Table } from 'reactstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'

import CustomLabel from '../components/InputFields/CustomLabel'
import DropDownFetch from '../components/InputFields/DropDownFetch'
import RcmLayout from '../components/rcm/RcmLayout'
import Spacer from '../components/Spacer'
import { Box } from '../pages/workManagement/PmTree'
import { SubHeader } from '../pages/VerifyCM'
import { workOrderAPI } from '../components/serverurl'

export default function RcmAnalysisManual() {
  const [equipment, setEquipment]: any = useState()
  const navigate = useNavigate()
  const location = useLocation()
  const currentRcmId = new URLSearchParams(location.search).get('id')

  return (
    <RcmLayout onBack={'/cmms/rcm/systemBoundary'}>
      <Row style={{ margin: 15 }}>
        <SubHeader header={'Manual Analysis'} permitColor="gold" />
        <Col xs="5">
          <CustomLabel label="Equipment" />
          <DropDownFetch
            name="equipment"
            value={equipment}
            setValue={setEquipment}
            url={`${workOrderAPI}/filters`}
            isMulti
            needSelect={false}
          />
        </Col>
        <Spacer height={20} />
        <Col xs={12}>
          <Box heading="Selected Equipment Details">
            <Table
              bordered
              className="min-vh-50"
              style={{ borderColor: '#000' }}
            >
              <thead>
                <tr>
                  {[
                    'S.No',
                    'System Name',
                    'System Number',
                    'Equipment Number',
                    'Criticality'
                  ]?.map((h: any) => <th key={h}>{h}</th>)}
                </tr>
              </thead>
              <tbody>
                {equipment?.map((e: any, Eindex: number) => (
                  <tr key={e?.value}>
                    <td>{Eindex + 1}</td>
                    <td>{e?.description}</td>
                    <td>{e?.function?.split('.')?.[0]}</td>
                    <td>{e?.value}</td>
                    <td>{e?.criticality}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>
        </Col>
        <Col xs={12}>
          <ModalFooter>
            <Button
              color="warning"
              onClick={() => {
                const tags = equipment?.map((s: any) => s?.value)
                localStorage.setItem('rcmTags', JSON.stringify(tags))
                navigate(`/cmms/rcm/createAnalysis?step=1&id=${currentRcmId}`)
              }}
            >
              Analyse
            </Button>
          </ModalFooter>
        </Col>
      </Row>
    </RcmLayout>
  )
}
