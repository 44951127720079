import moment from 'moment'
import QRCode from 'qrcode.react'
import { Table } from 'reactstrap'

import toNormalCase from '../lib/toNormalCase'
import { qrUrl } from '../serverurl'
import { renderTableData } from '../ToolBoxTalkMeeting'

export default function PrintPermitHeader({
  permitData,
  printData
}: {
  permitData: any
  printData: any
}) {
  return (
    <>
      <Table
        bordered
        style={{
          borderColor: '#000',
          fontSize: 12
        }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              textAlign: 'center',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            {renderTableData(<b>Permit Details</b>, 2)}
            {renderTableData(<b>User Details</b>, 2)}
            {renderTableData(<b>Scan Code</b>)}
          </tr>
        </thead>
        <tbody>
          <tr>
            {renderTableData(<b>Permit Number</b>)}
            {renderTableData(permitData?.woNumber)}
            {renderTableData(<b>Applicant Name</b>)}
            {renderTableData(permitData?.applicantName)}
            <td
              rowSpan={4}
              style={{
                paddingTop: 2,
                paddingBottom: 2,
                verticalAlign: 'middle',
                textAlign: 'center'
              }}
            >
              <QRCode
                value={`${qrUrl}/${permitData?._id || printData?.permit?._id}`}
                style={{ height: 80, width: 80 }}
              />
            </td>
            {/* {renderTableData(
              <QRCode
                value={`${qrUrl}/${permitData?._id || printData?.permit?._id}`}
                style={{ height: 80, width: 80 }}
              />,
              1,
              4
            )} */}
          </tr>
          <tr>
            {renderTableData(<b>Permit Type</b>)}
            {renderTableData(toNormalCase(permitData?.type))}
            {renderTableData(<b>Applicant Designation</b>)}
            {renderTableData(permitData?.applicantDesignation)}
          </tr>
          <tr>
            {renderTableData(<b>Start Date</b>)}
            {renderTableData(
              moment(permitData?.startDate).format('DD-MM-YYYY')
            )}
            {renderTableData(<b>Applicant Department</b>)}
            {renderTableData(permitData?.applicantDepartment)}
          </tr>
          <tr>
            {renderTableData(<b>Status</b>)}
            {renderTableData(toNormalCase(permitData?.status))}
            {renderTableData(<b>Employee Id</b>)}
            {renderTableData(permitData?.employeeId)}
          </tr>
        </tbody>
      </Table>
    </>
  )
}
