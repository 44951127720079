import { useEffect, useState } from 'react'
import { Link } from '../workManagement/PmTree'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import Spacer from '../../components/Spacer'

export const DecissionTree = ({
  handleChange,
  decision
}: {
  handleChange: any
  decision: any
}) => {
  const [isOpen, setIsOpen]: any = useState(false)
  const [value, setValue]: any = useState()

  const tree: any = {
    q1: ['q2', 'q3'],
    q2: ['q4', 'q5'],
    q3: ['q23', 'q24'],
    q4: ['q7', 'q6'],
    q5: ['q4', 'q12'],
    q6: ['q8', 'q9'],
    q7: ['result1'],
    q8: ['result2'],
    q9: ['q10', 'q11'],
    q10: ['result3'],
    q11: ['result4'],
    q12: ['q14', 'q13'],
    q13: ['q22', 'q19'],
    q14: ['q15,q16'],
    q15: ['q17', 'q18'],
    q16: ['q17', 'q18'],
    q17: ['result6'],
    q18: ['result7'],
    q19: ['q20', 'q21'],
    q20: ['result9'],
    q21: ['result10'],
    q22: ['result10'],
    q23: ['q25', 'q26'],
    q24: ['q32', 'q31'],
    q25: ['result12'],
    q26: ['q27', 'q28'],
    q27: ['result13'],
    q28: ['q29', 'q30'],
    q29: ['result14'],
    q30: ['result15'],
    q31: ['q32', 'q33'],
    q32: ['q34', 'q35'],
    q33: ['q40', 'q41'],
    q34: ['result16'],
    q35: ['q36', 'q37'],
    q36: ['result17'],
    q37: ['q38', 'q39'],
    q38: ['result18'],
    q39: ['result19'],
    q40: ['result20'],
    q41: ['q42', 'q43'],
    q42: ['result21'],
    q43: ['q44', 'q45'],
    q44: ['result22'],
    q45: ['result23']
  }

  const questions: any = {
    q1: 'Will the Loss of Function caused by this Failure mode on its own become evident to the operating crew under normal circumstance?	',
    q2: 'Is there an intolerable risk that the effects of this tailure mode couldn injure or kill someone?',
    q3: 'Is there an intolerable risk that the multiple failure could kill or injure someone?',
    q4: 'Is a scheduled on-condition task technically feasible and worth doing?',
    q5: 'Is there an intolerahle risk that the effects of this  failure mode could breach a known environmental standard or regulation?',
    q6: ' Is a scheduled restoration or scheduled discard task technically feasible and worth doing?',
    q9: 'Is a combination of tasks technically feasible and worth doing?',
    q12: 'Does the failure mode have a direct adverse effect on operational capability?',
    q13: 'Is a scheduled on-condition task technically feasible and worth doing?',
    q14: 'Is a scheduled on-condition task technically feasible and worth doing?',
    q16: 'Is a scheduled restoration or scheduled discard task technically feasible and worth doing?',
    q19: ' Is a scheduled restoration or scheduled discard task technically feasible and worth doing?',
    q23: 'Is a scheduled on-condition task technically feasible and worth doing?',
    q24: 'Is there an intolerable risk that the multiple failure could breach a known environmental  standard or regulation?',
    q26: 'Is a scheduled restoration or scheduled discard task technically feasible and worth doing ?',
    q28: 'Is a scheduled failure-finding task technicallt feasible and woth doing ?',
    q31: 'Does the multiple Failuer have a dorect adverse effect om operational capability ?',
    q32: 'Is a scheduled on condition task technically feasible and worth doing',
    q33: 'Is a scheduled on-condition task technically feasible and worth doing?',
    q35: 'Is a scheduled restoration or scheduled discard task technically feasible and worth doing?',
    q37: 'Is a scheduled failure- finding task technically feasible and worth doing?',
    q41: ' Is a scheduled restoration or scheduled discard task technically feasible and worth doing?',
    q43: 'Is a scheduled failure- finding task technically feasible and worth doing?'
  }

  const results: any = {
    q7: 'Scheduled discard or restoration task',
    q8: 'Scheduled discard or restoration task',
    q10: 'Combination of tasks',
    q11: 'Redesign compulsory',
    q15: 'Scheduled discard or restoration task',
    q17: 'Scheduled discard or restoration task',
    q18: 'Redesign may be desirable',
    q20: 'Scheduled discard or restoration task',
    q21: 'Redesign may be desirable',
    q22: 'Scheduled discard or restoration task',
    q25: 'Scheduled discard or restoration task',
    q27: 'Scheduled discard or restoration task',
    q29: 'Failure Finding Task',
    q30: 'Redesign Compulsary',
    q34: 'Scheduled discard or restoration task',
    q36: 'Scheduled discard or restoration task',
    q38: 'Failure Finding Task',
    q39: 'Redesign may be desirable',
    q40: 'Scheduled discard or restoration task',
    q42: 'Scheduled discard or restoration task',
    q44: 'Failure Finding Task',
    q45: 'Redesign may be desirable'
  }

  const codes: any = {
    q7: 'E-SE-L1-T1',
    q8: 'Scheduled discard or restoration task',
    q10: 'Combination of tasks',
    q11: 'Redesign compulsory',
    q15: 'Scheduled discard or restoration task',
    q17: 'Scheduled discard or restoration task',
    q18: 'Redesign may be desirable',
    q20: 'Scheduled discard or restoration task',
    q21: 'Redesign may be desirable',
    q22: 'Scheduled discard or restoration task',
    q25: 'Scheduled discard or restoration task',
    q27: 'Scheduled discard or restoration task',
    q29: 'Failure Finding Task',
    q30: 'Redesign Compulsary',
    q34: 'Scheduled discard or restoration task',
    q36: 'Scheduled discard or restoration task',
    q38: 'Failure Finding Task',
    q39: 'Redesign may be desirable',
    q40: 'Scheduled discard or restoration task',
    q42: 'Scheduled discard or restoration task',
    q44: 'Failure Finding Task',
    q45: 'Redesign may be desirable'
  }

  const [answers, setAnswers]: any = useState({})
  const [currentNode, setCurrentNode]: any = useState('q1')

  const handleAnswer = (answer: any) => {
    const nextNode = tree[currentNode][answer ? 0 : 1]
    setAnswers((prev: any) => ({
      ...prev,
      [currentNode]: answer ? 'Yes' : 'No'
    }))
    setCurrentNode(nextNode)
  }
  const resultKeys = Object.keys(results)

  useEffect(() => {
    if (currentNode && resultKeys?.includes(currentNode)) {
      setValue(results[currentNode])
    }
  }, [currentNode])

  return (
    <>
      <Link onClick={() => setIsOpen(true)}>
        {decision && decision !== 'undefined' ? decision : 'Perform MTA'}
      </Link>
      <Modal size="lg" isOpen={isOpen} fullscreen>
        <p
          style={{
            backgroundColor: 'gold',
            fontSize: 20,
            textAlign: 'center',
            fontWeight: 700,
            height: 40,
            paddingTop: 10,
            paddingBottom: 10
          }}
        >
          RCM Decision Tree
        </p>
        <ModalBody className="d-flex flex-column align-content-between align-items-center">
          <div>
            {Object.keys(answers).map((questionKey) => (
              <div key={questionKey}>
                <p>
                  {questions[questionKey]}:{' '}
                  <strong>{answers[questionKey]}</strong>
                </p>
              </div>
            ))}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {currentNode in questions && (
                <div
                  className="d-flex flex-column align-items-center"
                  style={{
                    width: '40%',
                    border: '1px solid #000',
                    textAlign: 'center',
                    padding: 10
                  }}
                >
                  <p>{questions[currentNode]}</p>
                  <div className="d-flex justify-content-center">
                    <Button
                      color="warning"
                      onClick={() => handleAnswer(true)}
                      style={{ marginRight: 15 }}
                    >
                      Yes
                    </Button>{' '}
                    {'   '}
                    <Button color="warning" onClick={() => handleAnswer(false)}>
                      No
                    </Button>
                  </div>
                </div>
              )}
            </div>
            {currentNode in results && (
              <div>
                <h3>Decision: {results[currentNode]}</h3>
              </div>
            )}
          </div>

          <Spacer height={20} />
        </ModalBody>
        <ModalFooter>
          <Button
            color="warning"
            onClick={() => {
              setAnswers({})
              setCurrentNode('q1')
              setValue('')
            }}
          >
            reset
          </Button>
          <Button
            color="warning"
            onClick={() => {
              handleChange(value, 'undefined')
              setIsOpen(false)
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
