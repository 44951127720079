import RcmLayout from '../../components/rcm/RcmLayout'

export default function RcmConditionHistory() {
  return (
    <RcmLayout
      onBack={'/cmms'}
      title={'RCM'}
      tabHeader={<h4 style={{ margin: 0 }}>Condition History</h4>}
    >
      <p></p>
    </RcmLayout>
  )
}
