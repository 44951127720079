import { useMsal } from '@azure/msal-react'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { PermissionsContext, UserContext } from '../../App'
import toNormalCase from '../../components/lib/toNormalCase'
import { countsEndpoint } from '../../components/serverurl'
import ViewIcons from '../../components/ViewIcons'

export default function NewPermitActions() {
  const { permissions } = useContext(PermissionsContext)
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const userlocation = toNormalCase(permissions?.location)

  const permitsCountByflow = async () => {
    const response = await makeAuthenticatedRequest(
      `${countsEndpoint}/newPermit?department=${userData?.department}&location=${userlocation}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data }: any = useQuery('newPermitCount', permitsCountByflow)

  const counts = data?.counts

  const icons = [
    {
      name: 'VERIFY (WI)',
      src: require('./../../images/Icons/Permits with isolations_Verify.png'),
      nav: '/eptw/newPermit/verify',
      canView: permissions?.canVerify,
      count: counts?.verify
    },
    {
      name: 'ISSUE  (WI)',
      src: require('./../../images/Icons/Permits with isolations_Issue.png'),
      nav: '/eptw/newPermit/issue',
      canView: permissions?.canIssue,
      count: counts?.issue
    },
    {
      name: 'APPROVE  (WI)',
      src: require('./../../images/Icons/Permits with isolations_Approve.png'),
      nav: '/eptw/newPermit/approve',
      canView: permissions?.canApprove,
      count: counts?.approve
    },
    {
      name: 'START ISOLATION',
      src: require('./../../images/Icons/Start_Isolation.png'),
      nav: '/eptw/newPermit/isolation/start',
      canView: permissions?.canViewIsolations,
      count: counts?.startIsloation
    },
    {
      name: 'FINISH ISOLATION',
      src: require('./../../images/Icons/Finish_Isolation.png'),
      nav: '/eptw/newPermit/isolation/finish',
      canView: permissions?.canViewIsolations,
      count: counts?.finishIsolation
    },
    {
      name: 'MAKE LIVE ISOLATION',
      src: require('./../../images/Icons/Permits with isolations_Live.png'),
      nav: '/eptw/newPermit/isolation/live',
      canView: permissions?.canViewIsolations,
      count: counts?.makeLiveIsolation
    },
    {
      name: 'MAKE PERMIT LIVE (WI)',
      src: require('./../../images/Icons/Make_Isolation_Live.png'),
      nav: '/eptw/newPermit/makeLive',
      canView: permissions?.canCreate,
      count: counts?.makeLive
    }
  ]

  return <ViewIcons icons={icons} />
}
