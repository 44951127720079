import moment from 'moment'
import React from 'react'
import { Table } from 'reactstrap'
import { incrementby1 } from '../Forms/JSAForm'
import { ColoredSubHeader } from './ViewExcavationPermit'

type ViewExcavationType = {
  data: any
  rowStyle: any
}

function isValidDate(dateString: any) {
  // Attempt to create a Date object from the dateString
  const date = new Date(dateString)

  // Check if the date is a valid date and the input string was correctly parsed
  return !isNaN(date.getTime())
}

export default function ViewGasReadings({
  data,
  rowStyle
}: ViewExcavationType) {
  return (
    <>
      <ColoredSubHeader rowStyle={rowStyle} text="Gas Readings" />
      <Table
        bordered
        striped
        responsive
        style={{ borderColor: '#000', fontSize: 14 }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            <th>S.No</th>
            <th>H2S</th>
            <th>CO</th>
            <th>O2</th>
            <th>LEL</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Department</th>
            <th>Updated On</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((d: any, index: number) => (
            <React.Fragment key={d?._id}>
              <tr>
                <td>{incrementby1(index)}</td>
                <td>{d?.h2S}</td>
                <td>{d?.cO}</td>
                <td>{d?.o2}</td>
                <td>{d?.lEL}</td>
                <td>{d?.userDetails?.givenName}</td>
                <td>{d?.userDetails?.jobTitle}</td>
                <td>{d?.userDetails?.department}</td>
                <td>
                  {isValidDate(d?.updatedAt)
                    ? moment(d?.updatedAt).format('h A')
                    : d?.updatedAt}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </>
  )
}
