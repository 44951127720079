import { Button, Row } from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { revalidateEndpoint } from '../../components/serverurl'

import React from 'react'

import { getEpermitPermissions } from '../../components/lib/epermitPermissions'
import { useAdminPermissions } from '../eptw/EditRisks'
import ViewPermit from '../eptw/ViewPermit'
import { RadioButtonGroup, yesNoOptions } from '../eptw/VerifyPermit'

export default function IssueRevalidation() {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()
  const [permissions, setPermissions]: any = useState()

  useEffect(() => {
    if (!permissions) {
      getEpermitPermissions(accounts, instance).then((response: any) =>
        setPermissions(response)
      )
    }
  })

  const { data, isLoading } = useAdminPermissions(
    id,
    accounts,
    instance,
    useQuery
  )

  const [submitModal, setSubmitModal]: any = useState(false)

  const [formData, setFormData]: any = useState({
    isolationVerified: 'No',
    sufficientManpower: 'No',
    communicationCarried: 'No',
    simops: 'No'
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const submitValues: any = {
      consent: {
        ...formData
      },

      permitId: data?.permit?._id,
      consentId: data?.permit?.consent?._id
    }

    const response = await makeAuthenticatedRequest(
      `${revalidateEndpoint}/issue`,
      'PATCH',
      submitValues,
      accounts,
      instance
    )

    return response.status
  }

  const form = (
    <div>
      <RadioButtonGroup
        question="Is the Isolation verified and sufficient to carry other this Work Task for this Permit ?"
        name="isolationVerified"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Is Sufficient Manpower and Materials allocated for this Job ?"
        name="sufficientManpower"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="Is communication carried out between all the teams prior applying this Permit ?"
        name="communicationCarried"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
      <RadioButtonGroup
        question="SIMOPS carried out ?"
        name="simops"
        options={yesNoOptions}
        formData={formData}
        onChange={handleInputChange}
        defaultValue="No"
      />
    </div>
  )

  return (
    <ProtectedLayout onBack="/eptw/revalidate/approve">
      <ViewPermit data={data} isLoading={isLoading} />
      {data?.permit?.status === 'revalidationVerified' && (
        <>
          <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Button
              color="warning"
              style={{ marginTop: 10 }}
              onClick={() => setSubmitModal(true)}
            >
              Issue
            </Button>
          </Row>
          {submitModal && (
            <SubmitModalWithForm
              isOpen={submitModal}
              onClose={(val: any) => {
                setSubmitModal(false)
                val && navigate('/eptw/revalidate/issue')
              }}
              onSubmit={handleSubmit}
              form={form}
            />
          )}
        </>
      )}
    </ProtectedLayout>
  )
}
