import { Col, Row } from 'reactstrap'
import { useNavigate } from 'react-router-dom'

import AshokLeylandLayout from './AshokLeylandLayout'
import AlServicesCircle from '../SVGs/AlServicesCircle'

export default function ALServices() {
  const navigate = useNavigate()
  const sty = `body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}`

  return (
    <AshokLeylandLayout onBack="/ashokLeyland" title="HOME - ASHOK LEYLAND">
      <style>{sty}</style>
      <Row
        style={{
          marginTop: 20,
          height: '80vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Row>
          <Col
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <AlServicesCircle />
          </Col>
        </Row>
      </Row>
    </AshokLeylandLayout>
  )
}
