import { useContext } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import { countsEndpoint } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { PermissionsContext, UserContext } from '../../App'
import toNormalCase from '../../components/lib/toNormalCase'
import ViewIcons from '../../components/ViewIcons'

export default function AuditActions({ onBack }: any) {
  const { permissions } = useContext(PermissionsContext)
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const userlocation = toNormalCase(permissions?.location)

  const permitsCountByflow = async () => {
    const response = await makeAuthenticatedRequest(
      `${countsEndpoint}/audit?department=${userData?.department}&location=${userlocation}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data }: any = useQuery('auditCount', permitsCountByflow)

  const counts = data?.counts

  const icons = [
    {
      name: 'CREATED (AUDIT)',
      src: require('./../../images/Icons/Authorize_Button.png'),
      nav: '/eptw/audits/created',
      count: counts?.created
    },
    {
      name: 'APPROVED (AUDIT)',
      src: require('./../../images/Icons/issue.png'),
      nav: '/eptw/audits/approved',
      count: counts?.approved
    },
    {
      name: 'REJECTED (AUDIT)',
      src: require('./../../images/Icons/Authorize_Button.png'),
      nav: '/eptw/audits/rejected',
      count: counts?.rejected
    }
  ]

  return <ViewIcons icons={icons} onBack={onBack} />
}
