import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Col, ModalFooter, Row, Table } from 'reactstrap'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import Spacer from '../../components/Spacer'
import { stock } from '../../components/serverurl'
import { Box, renderData } from '../workManagement/PmTree'
import { ViewHistory } from '../ViewCM'
import { SubHeader } from '../VerifyCM'
import { makeAuthenticatedRequest } from '../../apiRequests'
import './../../components/css/print.css'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { UserContext } from '../../App'
import { TermsAndConditions } from './VendorQuotation'
import { getReadableTime } from '../workManagement/WorkOrderDetails'

const date = new Date()
date.setDate(date.getDate() + 5)

export default function ApprovedPORFQ({ hasPrint }: { hasPrint: boolean }) {
  const { id } = useParams()
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const navigate = useNavigate()
  const componentRef: any = useRef()
  const [submit, setSubmit]: any = useState(false)

  const fetchpr = async () => {
    const response = await makeAuthenticatedRequest(
      `${stock}/inventory/rfq/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const [spares, setSpares]: any = useState([])

  const { data }: any = useQuery('pr', fetchpr)

  useEffect(() => {
    setSpares(data?.rfq?.materialRequest)
  }, [data])

  const pr = data?.rfq

  const history = data?.history
  const poNumber = `PO-${pr?.number?.split('-')[1]}`

  const poDetails: any = {
    Version: 0,
    'PR Number': pr?.number,
    'Document Date': '31-01-2023',
    Requisitioner: 'RUZANNA',
    'Our Reference': 'PKL-RQ-21021-MRN-021',
    'Vendor Reference': '1410/48',
    Buyer: 'MAZAITUL SHILA',
    "Buyer's Location": 'FPSO',
    "BUYER's Phone Number": '603-21715799',
    "BUYER's Fax Number": '603-21715799',
    'Terms of Payment': '45 days from invoice receipt date by finance',
    'Delivery Terms': 'CFR Signapore Port Incoterms 2010',
    'Reference Terms': 'Supply of Goods'
  }

  const styles = `@media print
  {    
      .no-print, .no-print *
      {
          display: none !important;
      }
  }`

  const approvedVendor = pr?.vendorQuotations?.filter(
    (v: any) => v?.name === pr?.approvedVendor?.name
  )?.[0]

  return (
    <>
      <>
        <style>{styles}</style>
      </>
      <ProtectedLayout onBack="/purchase/dashboard">
        <div ref={componentRef} style={{ margin: 20 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <SubHeader
              permitColor="#fff"
              header={
                <p style={{ margin: 0, fontSize: 20 }}>
                  Purchase Order - {poNumber}
                </p>
              }
            />
            <div
              style={{ display: 'flex', width: 500, justifyContent: 'center' }}
            >
              <img
                src={require('./../../images/e73_logo.jpeg')}
                alt="HOEC Logo"
                style={{
                  height: 100,
                  marginRight: 30
                }}
              />
              <div style={{ paddingLeft: 30, borderLeft: '3px solid #FFD580' }}>
                <p style={{ margin: 0 }}>E73 AI Innovations Private Ltd</p>
                <p style={{ margin: 0 }}>Level 21, Menara Perak</p>
                <p style={{ margin: 0 }}>24, Jalan Perak</p>
                <p style={{ margin: 0 }}>50450 KUALA LUMPUR</p>
                <p style={{ margin: 0 }}>Malaysia</p>
              </div>
            </div>
          </div>
          <hr />
          <Row>
            <Col xs="6">
              {pr?.approvedVendor && (
                <Box heading="Supplier Details">
                  <>
                    <p style={{ margin: 0, marginBottom: 20 }}>
                      {pr?.approvedVendor?.name}
                    </p>
                    <p style={{ margin: 0 }}>POMMERS LANE</p>
                    <p style={{ margin: 0 }}>GREAT YARMOUTH</p>
                    <p style={{ margin: 0 }}>
                      NR 30 3PE NORFOLK United Kingdom
                    </p>
                    <p style={{ margin: 0 }}>Contact Person:</p>
                    <p style={{ margin: 0 }}>
                      Tel: 441493 857936 Fax: 44 1493 850888
                    </p>
                  </>
                </Box>
              )}
              <Box heading="Invoice Address">
                <>
                  <p style={{ margin: 0 }}>Finance &Accounts Department</p>
                  <p style={{ margin: 0 }}>E73 AI Innovations Private Ltd</p>
                  <p style={{ margin: 0 }}>Level 21, Menara Perak</p>
                  <p style={{ margin: 0 }}>24, Jalan Perak</p>
                  <p style={{ margin: 0 }}>50450</p>
                  <p style={{ margin: 0 }}>Kuala Lumpur, Malaysia </p>
                </>
              </Box>
            </Col>
            <Col xs="6">
              <Box heading="Information">
                {Object.keys(poDetails)?.map((key: any) =>
                  renderData(key, poDetails[key])
                )}
              </Box>
            </Col>
            <Row style={{ margin: 0 }}>
              <Box heading="Order Details">
                <Table>
                  <thead>
                    <tr>
                      <th>Line Code</th>
                      <th>Spare Part Name</th>
                      <th>Spare Description</th>
                      <th>Offered Quantity</th>
                      <th>Gross Price</th>
                      <th>Line Cost</th>
                      <th>Promised Date</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvedVendor?.quotation?.map((q: any, index: any) => (
                      <tr key={`${q?.grossPrice}-${index}`}>
                        <td>{q?.lineCode}</td>
                        <td>{q?.spare?.['Spare Part Number']}</td>
                        <td>{q?.spare?.['Spare Part Description']}</td>
                        <td>{q?.quantityRequested}</td>
                        <td>{q?.grossPrice}</td>
                        <td>{q?.amount}</td>
                        <td>{getReadableTime(q?.promisedDate)}</td>
                        <td>{q?.note}</td>
                      </tr>
                    ))}
                    {/* {pr?.approvedVendor?.map((vendor: any, index: any) => (
                    <React.Fragment
                      key={`${vendor?.spare?.['Spare Part Number']}-${index}`}
                    >
                      <tr style={{ backgroundColor: '#D3D3D3' }}>
                        <td colSpan={12}>
                          <td>
                            Vendor Details - {vendor?.name} | Total (Incl. Tax)
                            -{vendor?.total}
                          </td>
                        </td>
                      </tr>

                    </React.Fragment>
                  ))} */}
                  </tbody>
                </Table>
              </Box>
            </Row>
            {pr?.approvedVendor && (
              <Row style={{ margin: 0 }}>
                <TermsAndConditions
                  poNumber={poNumber}
                  vendor={pr?.approvedVendor?.name}
                />
              </Row>
            )}

            <div className="no-print">
              <ViewHistory
                data={history}
                title={
                  <p
                    style={{
                      margin: 0,
                      fontSize: 20,
                      fontWeight: 600,
                      paddingTop: 5,
                      paddingBottom: 5
                    }}
                  >
                    APPROVAL HISTORY
                  </p>
                }
                permitColor="#FFD580"
              />
            </div>
          </Row>
          <Spacer height={10} />
        </div>
        {hasPrint && (
          <ReactToPrint
            documentTitle={`Purchase Request - ${pr?.number}`}
            pageStyle={'padding: 20'}
            trigger={() => (
              <ModalFooter>
                <Button color="warning" children="Print" />
              </ModalFooter>
            )}
            content={() => componentRef.current}
          />
        )}
        <ModalFooter>
          <Button color="warning" onClick={() => setSubmit(true)}>
            Issue PO
          </Button>
        </ModalFooter>
        <SubmitModalWithForm
          isOpen={submit}
          onClose={(val: any) => {
            setSubmit(false)
            val && navigate('/purchase/dashboard')
          }}
          onSubmit={async (e: any) => {
            e.preventDefault()
            try {
              const body = {
                ...data?.rfq,
                lastestUpdatedBy: userData?.id,
                status: 'issuedPO'
              }

              const res = await makeAuthenticatedRequest(
                `${stock}/inventory/rfq/approve/${id}`,
                'PATCH',
                body,
                accounts,
                instance
              )

              return res.status
            } catch (error) {}
          }}
          size="lg"
          header="Issue Purchase Order"
        />
      </ProtectedLayout>
    </>
  )
}
