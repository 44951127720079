import { Col, Input, Row } from 'reactstrap'
import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption
} from 'gantt-task-react'
import 'gantt-task-react/dist/index.css'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { lookAheadData } from '../planning/LookAheadDashboard'
import { useState } from 'react'
import CustomLabel from '../../components/InputFields/CustomLabel'

export default function GanttChart({
  fontSize,
  listCellWidth,
  columnWidth
}: {
  fontSize: string
  listCellWidth: string
  columnWidth: number
}) {
  const data: any = lookAheadData?.map((d: any) => {
    const startDate = d?.['Planned Start Date ']?.split('/')
    const year = startDate[2]
    const date = startDate[1]
    const month = startDate[0]

    const AStartDate = new Date(year, month, date)
    const endDate = new Date(AStartDate)
    const AEndDate = endDate.setDate(endDate.getDate() + 10)

    const statusColors: any = {
      Overdue: '#DC4C64',
      Done: '#14A44D',
      'To Do (1)': '#d3d3d3'
    }

    return {
      start: AStartDate,
      end: new Date(AEndDate),
      name: `${d?.Number} - ${d?.['Job Title']} `,
      id: d?.Number,
      type: 'task',

      progress: d?.progress,
      isDisabled: true,
      styles: {
        progressColor: '#14A44D',
        progressSelectedColor: '#000',
        backgroundColor: statusColors[d?.Status]
      }
    }
  })

  const [view, setView] = useState(ViewMode.Week)

  return (
    <>
      <Row style={{ margin: 10, fontSize: 20 }}>
        <Row>
          <Col xs="1">
            <CustomLabel label="Select Mode" />
          </Col>
          <Col xs="2">
            <Input
              type="select"
              value={view}
              onChange={(e: any) => setView(e?.target?.value)}
            >
              <option value="">Select</option>
              <option value={ViewMode.Hour}>Hour</option>
              <option value={ViewMode.QuarterDay}>Quarter Day</option>
              <option value={ViewMode.HalfDay}>Half Day</option>
              <option value={ViewMode.Day}>Day</option>
              <option value={ViewMode.Week}>Week</option>
              <option value={ViewMode.Month}>Month</option>
              <option value={ViewMode.Year}>Year</option>
            </Input>
          </Col>
        </Row>
        <Gantt
          tasks={data}
          viewMode={view}
          columnWidth={columnWidth}
          listCellWidth={listCellWidth}
          fontSize={fontSize}
          fontFamily="poppins"
          ganttHeight={600}
        />
      </Row>
    </>
  )
}
