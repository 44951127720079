import { Row } from 'reactstrap'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import { IconCard } from '../../components/Dashboard/IconCard'

export default function RcmAdmin() {
  const flows: any = [
    {
      name: 'CREATE / MODIFY RISK MATRIX',
      nav: '/cmms/rcm/admin/riskmatrix'
    },
    { name: 'RCM HIERARCHY', nav: '/cmms/rcm/admin/hierarchy' }
  ]
  return (
    <ProtectedLayout title="RCM ADMIN" onBack="/cmms/rcm">
      <Row style={{ margin: 15, display: 'flex' }}>
        {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
          <>
            <IconCard
              key={`${index}-${name}`}
              name={name}
              nav={nav}
              canView={canView}
              count={count}
              src={src}
            />
          </>
        ))}
      </Row>
    </ProtectedLayout>
  )
}
