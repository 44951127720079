import { Field, Form } from 'react-final-form'
import { Row, Col, Table, Input, Button } from 'reactstrap'
import { useContext, useState } from 'react'

import CustomLabel from '../components/InputFields/CustomLabel'
import DropDownFetch from '../components/InputFields/DropDownFetch'
import InformationCardModal from '../components/Modals/InformationCardModal'
import LabeledDateTimeInput from '../components/InputFields/DateTime'
import LabeledTextInput from '../components/InputFields/LabeledTextInput'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import Spacer from '../components/Spacer'
import { tagsAPI } from '../components/serverurl'
import { UserContext } from '../App'
import { getOptions } from '../pages/firs'
import { alDepartments } from './AlViewWo'
import AshokLeylandLayout from './AshokLeylandLayout'

function CheckBox({ name, label }: any) {
  return (
    // <tr>
    <tr style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <p style={{ margin: 0, fontSize: 14, marginRight: 30 }}>{label}</p>
      </div>
      <td style={{ display: 'flex' }}>
        {[
          { value: 'SAFE', label: 'SAFE' },
          { value: 'UN SAFE', label: 'UN SAFE' }
        ].map((item: any) => (
          <div
            key={item.value}
            style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}
          >
            <Field
              defaultValue={'SAFE'}
              name={name}
              component="input"
              type="radio"
              value={item.value}
              style={{ backgroundColor: '#000' }}
            />
            <p style={{ margin: '0px 0px 0px 5px', fontSize: 14 }}>
              {item.label}
            </p>
          </div>
        ))}
      </td>
    </tr>
    // </tr>
  )
}

export default function AlSheCardCreate() {
  const { userData } = useContext(UserContext)

  const [invert, setInvert] = useState(true)
  const [equipment, setEquipment]: any = useState()
  const [modal, setModal]: any = useState()
  const [equipmentDescription, setEquipmentDescription]: any = useState()

  const validate = (values: any) => {
    const errors: any = {}
    if (!values.happendAt) {
      errors.happendAt = 'Required'
    }

    return errors
  }

  function Section({ name, img, children }: any) {
    return (
      <>
        <Spacer height={20} />
        <Row>
          <Col xs="12">
            <div
              style={{
                backgroundColor: invert ? darkBlue : darkOrange,
                border: `2px solid ${invert ? lightBlue : darkOrange}`,
                padding: 10,
                borderRadius: 10,
                width: 'inherit',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  borderRadius: '50%',
                  border: `2px solid ${invert ? lightBlue : lightOrange}`,
                  width: 80,
                  height: 80,
                  padding: 10,
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <img
                  src={img}
                  alt={name}
                  style={{ height: 50, width: 'auto' }}
                />
              </div>
              <div
                style={{
                  marginLeft: 20,
                  backgroundColor: invert ? lightBlue : lightOrange,
                  margin: 10,
                  width: 'inherit'
                }}
              >
                <Table bordered style={{ border: '1px solid #fff' }}>
                  <thead>
                    <tr>
                      <th>
                        <p style={{ margin: 0, fontSize: 18, marginRight: 30 }}>
                          <b>{name}</b>
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <Spacer height={10} />
                  <tbody>{children}</tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </>
    )
  }

  const lightBlue = '#d0ebff'
  const darkBlue = '#2c9aff'
  const lightOrange = '#ffdaca'
  const darkOrange = '#ff7538'

  const departmentList = getOptions([
    'Production',
    'HSE',
    'Admin Ware House',
    'Mechanical',
    'Electrical',
    'Instrument'
  ])

  const happendAt = getOptions([
    'Mechanical Workshop',
    'Fire Station',
    'Sub Station',
    'Control Room',
    'Process Area',
    'Admin Ware House'
  ])

  const textArea = {
    border: `1px solid ${invert ? darkBlue : darkOrange}`,
    borderRadius: 5,
    height: 40,
    paddingLeft: 5
  }

  return (
    <AshokLeylandLayout
      title="Create New SHE Card"
      onBack="/ashokleyland/firs/tshe"
    >
      <Row style={{ margin: 15 }}>
        <Form
          onSubmit={(values: any) => {}}
          validate={validate}
          render={({ handleSubmit, form, hasValidationErrors, values }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: invert ? lightBlue : lightOrange,
                      width: 400,
                      justifyContent: 'space-between',
                      padding: 4,
                      borderRadius: 10
                    }}
                  >
                    <div
                      style={{
                        width: 190,
                        backgroundColor: invert ? darkBlue : lightOrange,
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                        textAlign: 'center',
                        padding: 10,
                        color: invert ? lightBlue : darkOrange,
                        cursor: 'pointer'
                      }}
                      onClick={() => setInvert(true)}
                    >
                      <h5>Unsafe</h5>
                      <h5>Act</h5>
                    </div>
                    <div
                      style={{
                        width: 190,
                        backgroundColor: invert ? lightBlue : darkOrange,
                        color: invert ? '#2c9aff' : '#ffdaca',
                        textAlign: 'center',
                        cursor: 'pointer',
                        padding: 10,
                        borderTopRightRadius: 10,
                        borderBottomRightRadius: 10
                      }}
                      onClick={() => setInvert(false)}
                    >
                      <h5>Positive</h5>
                      <h5>& Behaviour</h5>
                    </div>
                  </div>
                </Row>
                <Row>
                  <LabeledTextInput
                    label="APPLICANT NAME"
                    name="applicantName"
                    occupy={6}
                    value={userData?.givenName}
                    isDisabled
                  />
                  <LabeledDateTimeInput
                    label="DATE & TIME"
                    name="date"
                    occupy={6}
                  />
                </Row>
                <Spacer height={20} />
                <Row>
                  <Col xs="12">
                    <div
                      style={{
                        border: `1px solid ${invert ? darkBlue : darkOrange}`,
                        padding: 10,
                        borderRadius: 10
                      }}
                    >
                      <p style={{ margin: 0, fontSize: 16 }}>
                        <b>DEPARTMENT : </b>
                      </p>
                      <div
                        style={{
                          display: 'flex',
                          marginTop: 10,
                          marginLeft: 10
                        }}
                      >
                        {getOptions(alDepartments).map((item: any) => (
                          <div
                            key={item.value}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginRight: 10
                            }}
                          >
                            <Field
                              name="department"
                              component="input"
                              type="radio"
                              value={item.value}
                              style={{ backgroundColor: '#000' }}
                            />
                            <p
                              style={{
                                margin: '0px 0px 0px 5px',
                                fontSize: 14
                              }}
                            >
                              {item.label.toUpperCase()}
                            </p>
                          </div>
                        ))}
                      </div>
                      <Spacer height={20} />
                      {invert ? (
                        <>
                          <p style={{ margin: 0, fontSize: 16 }}>
                            <b>SAFETY OBSERVATIONS : </b>
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: 10,
                              marginLeft: 10
                            }}
                          >
                            {getOptions([
                              'Unsafe Act (identifying Risk Behaviour)',
                              'Unsafe Condition',
                              'Unsafe condition due to Unsafe Act',
                              '5S'
                            ]).map((item: any) => (
                              <div
                                key={item.value}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginRight: 10
                                }}
                              >
                                <Field
                                  name="department"
                                  component="input"
                                  type="radio"
                                  value={item.value}
                                  style={{ backgroundColor: '#000' }}
                                />
                                <p
                                  style={{
                                    margin: '0px 0px 0px 5px',
                                    fontSize: 14
                                  }}
                                >
                                  {item.label.toUpperCase()}
                                </p>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <div style={{ display: 'flex', marginTop: 10 }}>
                          <p
                            style={{
                              margin: 0,
                              fontSize: 16,
                              marginRight: 30
                            }}
                          >
                            <b>IDENTIFYING SAFE BEHAVIOUR : </b>
                          </p>
                          {[
                            { value: 'Yes', label: 'YES' },
                            { value: 'No', label: 'NO' }
                          ].map((item: any) => (
                            <div
                              key={item.value}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: 10
                              }}
                            >
                              <Field
                                name="boots"
                                component="input"
                                type="radio"
                                value={item.value}
                                style={{ backgroundColor: '#000' }}
                                defaultValue={'no'}
                              />
                              <p
                                style={{
                                  margin: '0px 0px 0px 5px',
                                  fontSize: 12
                                }}
                              >
                                {item.label}
                              </p>
                            </div>
                          ))}
                        </div>
                      )}

                      <div style={{ display: 'flex', marginTop: 10 }}>
                        <p
                          style={{
                            margin: 0,
                            fontSize: 16,
                            marginRight: 30
                          }}
                        >
                          <b>BOOTS ON DECK : </b>
                        </p>
                        {[
                          { value: 'Yes', label: 'YES' },
                          { value: 'No', label: 'NO' }
                        ].map((item: any) => (
                          <div
                            key={item.value}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginRight: 10
                            }}
                          >
                            <Field
                              name="boots"
                              component="input"
                              type="radio"
                              value={item.value}
                              style={{ backgroundColor: '#000' }}
                              defaultValue={'no'}
                            />
                            <p
                              style={{
                                margin: '0px 0px 0px 5px',
                                fontSize: 12
                              }}
                            >
                              {item.label}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Spacer height={20} />
                <Row>
                  <Col xs="12">
                    <div
                      style={{
                        border: `1px solid ${invert ? darkBlue : darkOrange}`,
                        padding: 10,
                        borderRadius: 10
                      }}
                    >
                      <p style={{ margin: 0, fontSize: 16 }}>
                        <b>WHERE DID IT HAPPEN ? </b>
                      </p>
                      <div
                        style={{
                          display: 'flex',
                          marginTop: 10,
                          marginLeft: 10
                        }}
                      >
                        {getOptions(alDepartments).map((item: any) => (
                          <div
                            key={item.value}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginRight: 10
                            }}
                          >
                            <Field
                              name="happendAt"
                              component="input"
                              type="radio"
                              value={item.value}
                              style={{ backgroundColor: '#000' }}
                            />
                            <p
                              style={{
                                margin: '0px 0px 0px 5px',
                                fontSize: 14
                              }}
                            >
                              {item.label.toUpperCase()}
                            </p>
                          </div>
                        ))}
                      </div>
                      <Row>
                        <Col xs="4">
                          <CustomLabel label="EQUIPMENT / TAG NUMBER" />
                          <DropDownFetch
                            isMulti={false}
                            setDescription={setEquipmentDescription}
                            name="equipment"
                            value={equipment}
                            setValue={setEquipment}
                            url={`${tagsAPI}`}
                          />
                        </Col>
                        <Col>
                          <CustomLabel label={'DESCRIPTION'} />
                          <p>{equipmentDescription}</p>
                        </Col>
                      </Row>
                      <Spacer height={20} />
                      <Col xs="12">
                        <div
                          style={{
                            width: 'inherit'
                          }}
                        >
                          <p
                            style={{
                              marginBottom: 10,
                              fontSize: 16,
                              marginRight: 3,
                              width: '15vh',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <b>OTHER EQUIPMENT:</b>
                          </p>
                          <Field name="others">
                            {({ input, meta }) => (
                              <div style={{ width: 'inherit' }}>
                                <Input
                                  {...input}
                                  type="textarea"
                                  style={{
                                    ...textArea,
                                    width: 'inherit'
                                  }}
                                />
                                {meta.error && meta.touched && (
                                  <span
                                    style={{
                                      color: 'red',
                                      fontWeight: 400,
                                      fontSize: 14
                                    }}
                                  >
                                    {meta.error}
                                  </span>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </Col>
                    </div>
                  </Col>
                </Row>
                <Spacer height={20} />
                <Row>
                  <Col xs="12">
                    <div
                      style={{
                        backgroundColor: invert ? lightBlue : lightOrange,
                        padding: 10,
                        borderRadius: 10,
                        width: 'inherit'
                      }}
                    >
                      <p style={{ margin: 0, fontSize: 16 }}>
                        <b>DESCRIBE YOUR OBSERVATION : </b>
                      </p>
                      <Col xs="12" style={{ display: 'flex', marginTop: 10 }}>
                        <Field name="describe">
                          {({ input, meta }) => (
                            <div style={{ width: 'inherit' }}>
                              <Input
                                {...input}
                                type="textarea"
                                style={{
                                  ...textArea,
                                  backgroundColor: invert
                                    ? lightBlue
                                    : lightOrange,
                                  height: 100,
                                  width: 'inherit'
                                }}
                              />
                              {meta.error && meta.touched && (
                                <span
                                  style={{
                                    color: 'red',
                                    fontWeight: 400,
                                    fontSize: 14
                                  }}
                                >
                                  {meta.error}
                                </span>
                              )}
                            </div>
                          )}
                        </Field>
                      </Col>
                    </div>
                  </Col>
                </Row>
                <Spacer height={20} />
                <Row>
                  <Col xs="12">
                    <div
                      style={{
                        backgroundColor: invert ? lightBlue : lightOrange,
                        padding: 10,
                        borderRadius: 10,
                        width: 'inherit'
                      }}
                    >
                      <p style={{ margin: 0, fontSize: 16 }}>
                        <b>
                          DESCRIBE YOUR ACTION / DISCUSSION / INTERVENTION :{' '}
                        </b>
                      </p>
                      <Col xs="12" style={{ display: 'flex', marginTop: 10 }}>
                        <Field name="describeAction">
                          {({ input, meta }) => (
                            <div style={{ width: 'inherit' }}>
                              <Input
                                {...input}
                                type="textarea"
                                style={{
                                  ...textArea,
                                  backgroundColor: invert
                                    ? lightBlue
                                    : lightOrange,
                                  height: 100,
                                  width: 'inherit'
                                }}
                              />
                              {meta.error && meta.touched && (
                                <span
                                  style={{
                                    color: 'red',
                                    fontWeight: 400,
                                    fontSize: 14
                                  }}
                                >
                                  {meta.error}
                                </span>
                              )}
                            </div>
                          )}
                        </Field>
                      </Col>
                    </div>
                  </Col>
                </Row>
                {invert && (
                  <>
                    <Section name="PPE" img={require('./../images/PPE.png')}>
                      <CheckBox name="ppeWorn" label="PPE Worn" />
                      <CheckBox name="meetsJob" label="Meets Job Requirement" />
                      <CheckBox name="wornRight" label="Worn Correctly" />
                      <CheckBox
                        name="acceptable"
                        label="Acceptable Condition"
                      />
                    </Section>
                    <Section
                      name="TOOLS / EQUIPMENT"
                      img={require('./../images/Tools.png')}
                    >
                      <CheckBox name="tools" label="Tools / Equipment" />
                      <CheckBox
                        name="safeTools"
                        label="Safe tools, equipment, material"
                      />
                      <CheckBox
                        name="properGuards"
                        label="Proper guards, barriers"
                      />
                      <CheckBox name="usedCorrectly" label="Used correctly" />
                    </Section>
                    <Section
                      name="PEOPLE"
                      img={require('./../images/People.png')}
                    >
                      <CheckBox
                        name="exertion"
                        label="Exertion : Pushing / Pulling / Lifting / Reaching"
                      />
                      <CheckBox
                        name="extremeTemperature"
                        label="Extreme Temperature : Pushing / Pulling"
                      />
                      <CheckBox
                        name="risk"
                        label="Risk to be struck by objects"
                      />
                      <CheckBox name="training" label="Training" />
                    </Section>
                    <Section
                      name="PROCEDURE"
                      img={require('./../images/Process.png')}
                    >
                      <CheckBox
                        name="established"
                        label="Established and Understood"
                      />
                      <CheckBox
                        name="employee"
                        label="Employee authorized to operate"
                      />
                      <CheckBox
                        name="maintained"
                        label="Maintained and followed"
                      />
                      <CheckBox name="adequate" label="Adequate for task" />
                    </Section>
                    <Section
                      name="HOUSE KEEPING"
                      img={require('./../images/Housekeeping.png')}
                    >
                      <CheckBox
                        name="clearArea"
                        label="Area is clear of obstructions"
                      />
                      <CheckBox
                        name="areaPurpose"
                        label="Area is used for intended purpose"
                      />
                      <CheckBox
                        name="material"
                        label="Material stored in safe manner"
                      />
                      <CheckBox
                        name="disposalProcedure"
                        label="Proper disposal procedure followed"
                      />
                    </Section>
                    <Section
                      name="CHEMICALS"
                      img={require('./../images/Chemicals.png')}
                    >
                      <CheckBox name="msds" label="MSDS Available" />
                      <CheckBox
                        name="storedProperly"
                        label="Chemicals are stored properly"
                      />
                      <CheckBox
                        name="ppe"
                        label="Correct PPE worn around chemicals"
                      />
                    </Section>
                    <Section
                      name="ENVIRONMENT"
                      img={require('./../images/Environment.png')}
                    >
                      <CheckBox
                        name="accidental"
                        label="Accidental spills during oils transfer operations, equipment failure, or vessel incidents"
                      />
                      <CheckBox
                        name="chemicalUsage"
                        label="Chemical usage and discharge"
                      />
                      <CheckBox name="airEmissions" label="Air Emissions" />
                      <CheckBox name="noise" label="Noise and Vibrations" />
                      <CheckBox
                        name="solid"
                        label="Solid waste, hazardous waste and sewage"
                      />
                    </Section>
                    <Section
                      name="REPUTATION"
                      img={require('./../images/Reputation.png')}
                    >
                      <CheckBox
                        name="unethical"
                        label="Unethical behaviour or misconduct"
                      />
                      <CheckBox
                        name="techAdvancement"
                        label="Technological advancement in staying ahead of industry trends"
                      />
                    </Section>
                  </>
                )}
                {!invert && (
                  <>
                    <Section
                      name="PROCESS SAFETY"
                      img={require('./../images/Process_Safety.png')}
                    >
                      <CheckBox
                        name="hazardous"
                        label="Information regarding the highly hazardous chemical in process"
                      />
                      <CheckBox name="operating" label="Operating Procedure" />
                      <CheckBox
                        name="procedure"
                        label="Procedure not followed"
                      />
                      <CheckBox
                        name="operationDeviate"
                        label="Operation deviate from current practices"
                      />
                      <CheckBox
                        name="operatingLimits"
                        label="Safe operating limitations"
                      />
                      <CheckBox
                        name="information"
                        label="Information readily available to employees involved"
                      />
                      <CheckBox name="safety" label="Safety Lock / Interlock" />
                      <CheckBox
                        name="process"
                        label="Process Drawings (P&ID, PFD) with latest Revision"
                      />
                    </Section>
                    <Section
                      name="INTEGRITY"
                      img={require('./../images/Integrity.png')}
                    >
                      <CheckBox
                        name="equipmentConstruction"
                        label="Equipment and Construction accordancw with design specifications"
                      />
                      <CheckBox
                        name="properSafety"
                        label="Proper safety, maintenance, operating and emergency procedures"
                      />
                      <CheckBox
                        name="pipingSystems"
                        label="Piping systems (including components, values"
                      />
                      <CheckBox
                        name="pressureVessels"
                        label="Presuure vessels and storage tanks"
                      />
                      <CheckBox
                        name="relief"
                        label="Relief and vent systems and storage tanks"
                      />
                      <CheckBox
                        name="controlsI"
                        label="Controls (Monitoring devices, alarms, sensors, interlocks)"
                      />
                      <CheckBox
                        name="emergencyShut"
                        label="Emergency shutdown systems"
                      />
                      <CheckBox
                        name="temp"
                        label="Temporary Hoses and connections"
                      />
                      <CheckBox
                        name="weep"
                        label="Weep and leak in connections and Tubing"
                      />
                      <CheckBox
                        name="equipmentSupport"
                        label="Equipment Support"
                      />
                    </Section>
                    <Section
                      name="TRAINING"
                      img={require('./../images/Training.png')}
                    >
                      <CheckBox
                        name="familiar"
                        label="Familiar with facility rules"
                      />
                      <CheckBox
                        name="trainingEducation"
                        label="Training and Education Programs"
                      />
                      <CheckBox
                        name="operatorTraining"
                        label="Operator Training"
                      />
                      <CheckBox
                        name="contractEmployees"
                        label="Contract employees on or near covered process involved in maintainence"
                      />
                      <CheckBox
                        name="informatonProvide"
                        label="Provide information and train their employees how to safely perform their jobs"
                      />
                      <CheckBox
                        name="controls"
                        label="Controls (Monitoring deveices, alarms, sensors, interlocks"
                      />
                      <CheckBox
                        name="operatingProcedures"
                        label="Written operating procedures and tasks with clear instructions"
                      />
                    </Section>
                    <Section
                      name="PROCESS HAZARD"
                      img={require('./../images/Process_Hazard.png')}
                    >
                      <CheckBox
                        name="failureMode"
                        label="Failure mode and efects analysis (FMEA)"
                      />
                      <CheckBox
                        name="hazardOperability"
                        label="Hazard and operability study (HAZOP)"
                      />
                      <CheckBox name="checklist" label="What if / Checklist" />
                      <CheckBox
                        name="potential"
                        label="Potential process hazards"
                      />
                      <CheckBox
                        name="workspaceAnalysis"
                        label="Workspace Analysis"
                      />
                      <CheckBox
                        name="modifications"
                        label="Modifications to current operting procedures"
                      />
                    </Section>
                    <Section
                      name="PERMIT"
                      img={require('./../images/Permit.png')}
                    >
                      <CheckBox
                        name="permitRelevant"
                        label="Permit Relevant to work and Valid"
                      />
                      <CheckBox
                        name="permitDisplay"
                        label="Permit Display at the work location"
                      />
                      <CheckBox
                        name="toolBox"
                        label="Tool box JSA and work party"
                      />
                      <CheckBox
                        name="permitDisplayLocation"
                        label="Permit display proper location and Equipment"
                      />
                      <CheckBox
                        name="isolation"
                        label="Isolation as per Company Management"
                      />
                    </Section>
                  </>
                )}
                <Spacer height={30} />
                <Row style={{ margin: 0 }}>
                  <Button
                    disabled={hasValidationErrors}
                    onClick={() => setModal(true)}
                    style={{
                      backgroundColor: invert ? darkBlue : darkOrange
                    }}
                  >
                    Preview
                  </Button>
                </Row>
                <InformationCardModal
                  isOpen={modal}
                  toggle={() => setModal(!modal)}
                  form={form}
                  values={{
                    ...values,
                    type: invert ? 'bc' : 'ps',
                    equipment,
                    applicantName: userData?.givenName
                  }}
                />
              </div>
            </form>
          )}
        />
      </Row>
    </AshokLeylandLayout>
  )
}
