import { Row } from 'reactstrap'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import { IconCard } from '../../components/Dashboard/IconCard'

export default function PmJobsDashoard() {
  const flows: any = [
    {
      name: 'CREATE NEW PM',
      nav: '/cmms/planning/pm/create',
      src: require('./../../../src/images/Icons/createNewPm.png')
    },
    {
      name: 'PM JOB PLANS ',
      nav: '/cmms/planning/pm/jobs',
      src: require('./../../../src/images/Icons/pmJobPlans.png')
    },
    {
      name: 'UPDATE RUNNING HOURS',
      nav: '/cmms/planning/pm/updateRunningHours',
      src: require('./../../../src/images/Icons/updateRunningHours.png')
    },
    {
      name: 'PM LIST',
      nav: '/cmms/planning/pm/list',
      src: require('./../../../src/images/Icons/pmList.png')
    }
  ]

  return (
    <>
      <ProtectedLayout onBack="/cmms/planning" title="PLANNING MANAGEMENT">
        <Row style={{ margin: 10 }}>
          {flows?.map(({ name, nav, count, canView, src }: any, index: any) => (
            <IconCard
              key={`${index}-${name}`}
              name={name}
              nav={nav}
              canView={canView}
              count={count}
              src={src}
            />
          ))}
        </Row>
      </ProtectedLayout>
    </>
  )
}
