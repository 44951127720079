import React from 'react'
import { Row } from 'reactstrap'

import PrintPermitHeader from '../prints/PrintPermitHeader'
import PrintVehivlePermitTalk from '../prints/PrintVehiclePermitTalk'
import Spacer from '../Spacer'
import { ColoredSubHeader } from './ViewExcavationPermit'
import { PermitHeader } from '../../pages/WaitingForPIC'
import { PrintHeaderLogos } from '../prints/PrintHeaderLogos'
import { renderLongRow } from '../Modals/AuditPreviewModal'

type ViewVechiclePermit = {
  showPrintHeader?: any
  data: any
  rowStyle: any
  renderRow: any
  permitData: any
}

export default function ViewWorkAtHeight({
  showPrintHeader,
  data,
  rowStyle,
  renderRow,
  permitData
}: ViewVechiclePermit) {
  const fields: any = {
    'Whether Safe access to work place is provided': data?.safeAccess,
    'Whether the edge protection provided adequate': data?.edgeProtection,
    'Is the work area away from the vicinity of moving objects':
      data?.workAreaAway,
    'Whether work platform is adequate with respect to strength and dimension':
      data?.workPlatform,
    'Is the working platform fully boarded': data?.fullyBoarded,
    'Work platforms provided with guard rail': data?.guardAll,
    'Are the workmen screened for working at height': data?.workMenScreened,
    'Adequate fall protection arrangement made (Static line, Double Lanyard harness, Fall Arrestor, Safety Net)':
      data?.protectionArrangement,
    'Work at more than one elevation at the same segment is restricted':
      data?.oneElevation,
    'Walkways, aisles & all overhead workplaces cleared of loose material':
      data?.overheadWorkplaces,
    'Whether all tools & tackles inspected before use': data?.allTools,
    'Whether hand tools and handling materials secured against accidental fall':
      data?.handlingMaterials,
    'Workmen provided with bag / box to carry bolt, nuts & hand tools':
      data?.providedBag,
    'Whether area below, where the height work being performed is cordoned and unauthorized entries are avoided':
      data?.unauthorizedEntries
  }

  const header: any = {
    'Work At Height Permit Number': data?.permitNumber,
    'Permit Number': permitData?.woNumber,
    'Permit Title': permitData?.permitTitle
  }

  return (
    <>
      {showPrintHeader && (
        <>
          <PrintHeaderLogos permitType={permitData?.type} />
          <PrintPermitHeader permitData={permitData} printData={permitData} />
        </>
      )}
      <ColoredSubHeader rowStyle={rowStyle} text="Work At Height" />
      <Row
        style={{
          ...rowStyle,
          fontSize: 14,
          margin: 0
        }}
      >
        {data?.permitNumber &&
          Object.keys(header).map((key) => renderRow(key, header[key]))}
      </Row>
      <Spacer height={10} />
      <Row
        style={{
          fontSize: 14,
          margin: 0
        }}
      >
        {Object.keys(fields).map((key) =>
          renderLongRow(key, fields[key], rowStyle.backgroundColor, 6)
        )}
      </Row>
      <Spacer height={10} />
      {permitData?.status === 'live' && (
        <Row
          style={{
            margin: 0,
            WebkitPrintColorAdjust: 'exact'
          }}
        ></Row>
      )}
    </>
  )
}
