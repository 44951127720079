import { Table } from 'reactstrap'
import { renderArray } from '../prints/PrintHeaderLogos'
import { ColoredSubHeader } from './ViewExcavationPermit'

import './../../components/css/a4print.css'
import { permitStatuses } from './ViewPermitdetails'
import { renderTableData } from '../ToolBoxTalkMeeting'

type ViewPermit = {
  selectedPermit: any
  rowStyle: any
  renderRow: any
}

export default function PrintPermitDetails({
  selectedPermit,
  rowStyle,
  renderRow
}: ViewPermit) {
  const data: any = {
    'Equipment Others': selectedPermit?.othersEquipmentNumbers,
    'Location of Work': selectedPermit?.workLocation,
    'Location Others': renderArray(selectedPermit?.locationOthers, false),
    // 'Any Conflict Permit': selectedPermit?.otherPermitNo,
    // 'Conflict Permit Number': selectedPermit?.otherPermitNumber,
    'Risk Assessment Type': selectedPermit?.isJSA,
    'Isolation Attached':
      selectedPermit?.Isolations || selectedPermit?.isolations?.isolations
        ? 'Yes'
        : 'No',
    'Equipment Preparation': renderArray(
      selectedPermit?.equipmentPreparation,
      false
    ),
    'Equipment Preparation Others': selectedPermit?.othersEquipmentPreparation,
    'Equipment Protection and Stand by': renderArray(
      selectedPermit?.protectStdByEquipment,
      false
    ),
    'Equipment Protection Others ': selectedPermit?.othersProtectStdByEquipment,
    'PPE Required': renderArray(selectedPermit?.ppeRequirement, false),
    'PPE Requires Others': selectedPermit?.othersPpeRequirement,
    'Mobile Machinery': renderArray(selectedPermit?.machinery, false),
    'Machinery and Tools Others': selectedPermit?.othersTools,
    'Safety Watch Required': selectedPermit?.watchRequired ? 'Yes' : 'No',
    'HSE Assistance Required': selectedPermit?.riskAssessment,
    'Special Precautions': selectedPermit?.specialInstructions,
    'Gas Measurements': selectedPermit?.gasMeasurements,
    Status: permitStatuses?.[selectedPermit?.status] || selectedPermit?.status,
    'Will permit applicant be the job performer for this job':
      selectedPermit?.teamType,
    ...(selectedPermit?.teamType === 'Yes'
      ? {
          'Team Members': renderArray(
            selectedPermit?.teamMembers?.map((t: any) => t?.name),
            false
          )
        }
      : {}),
    ...(selectedPermit?.teamType === 'No'
      ? {
          'Vendor Team Members': renderArray(
            selectedPermit?.teamMembers?.map((t: any) => t?.name),
            false
          )
        }
      : {})
  }

  return (
    <>
      <ColoredSubHeader rowStyle={rowStyle} text="Permit Details" />
      <Table
        bordered
        style={{
          fontSize: 12,
          border: '1px solid #000',
          borderColor: '#000'
        }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: 'orange',
              WebkitPrintColorAdjust: 'exact'
            }}
          >
            <th style={{ width: '25%' }}>Permit Details</th>
            <th style={{ width: '75%' }}>Description</th>
            <th style={{ width: '3%' }}>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {/* <td>Section / Department</td> */}
            {renderTableData('Section / Department', 1, 1, 'left')}
            {renderTableData(
              renderArray(selectedPermit?.department, false),
              1,
              1,
              'false'
            )}
            {/* <td>{renderArray(selectedPermit?.department, false)}</td> */}
            <td rowSpan={23} style={{ verticalAlign: 'middle' }}>
              <div
                style={{
                  writingMode: 'vertical-lr'
                }}
              >
                <div
                  style={{
                    fontSize: 50,
                    fontWeight: 700,
                    textShadow:
                      '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
                    transform: 'rotate(360deg)'
                  }}
                >
                  <p style={{ color: rowStyle?.backgroundColor, margin: 0 }}>
                    {permitStatuses?.[data?.['Status']] || data?.['Status']}
                  </p>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            {/* <td>Equipment to be worked on</td> */}
            {renderTableData('Equipment to be worked on', 1, 1, 'left')}
            {renderTableData(
              renderArray(selectedPermit?.equipment, false),
              1,
              1,
              'left'
            )}
            {/* <td>{renderArray(selectedPermit?.equipment, false)}</td> */}
          </tr>
          {Object.keys(data).map(
            (key) =>
              data[key] && (
                <tr key={key}>
                  {/* <td>{key}</td> */}
                  {renderTableData(key, 1, 1, 'left')}
                  {renderTableData(data[key], 1, 1, 'left')}
                  {/* <td>{data[key]}</td> */}
                </tr>
              )
          )}
        </tbody>
      </Table>
    </>
  )
}
