import { Route, Routes } from "react-router-dom";

import TravelActions from "../pages/travel/TravelActions";
import CreateTravelForm from "../pages/travel/CreateTravelForm";

export default function TravelRoutes() {
  console.log("here");
  return (
    <Routes>
      <Route path="/" element={<TravelActions />} />
      <Route path="/create" element={<CreateTravelForm />} />
    </Routes>
  );
}
