import * as XLSX from 'xlsx'
import React, { useState } from 'react'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { FaFileCircleXmark } from 'react-icons/fa6'
import { FaFileExport, FaFileImport, FaSave } from 'react-icons/fa'
import { ImPushpin } from 'react-icons/im'
import { MdCancel, MdEditDocument } from 'react-icons/md'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { ToastContainer, toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import CustomLabel from '../../components/InputFields/CustomLabel'
import ModalTitle from '../../components/ModalTitle'
import RcmLayout from '../../components/rcm/RcmLayout'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { Box, Link, renderData } from '../workManagement/PmTree'
import { getAttachment, systemsApi } from '../../components/serverurl'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { SubHeader } from '../VerifyCM'
import { IoSettings } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

const defaultHeaderKeys = [
  'System Number',
  'System Name',
  'System Description',
  'F LOC',
  'Equipment',
  'Failure Mode',
  'Site',
  'Plant',
  'Unit',
  'Criticality',
  'Overall Priority',
  'Total Annual Price of Confirmation (POC)',
  'Currency'
]

export const handleExport = (jsonData: any) => {
  jsonData?.forEach((element: any) => {
    delete element['_id']
    delete element['__v']
  })

  const worksheet = XLSX.utils.json_to_sheet(jsonData)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = 'systemsData.xlsx'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  URL.revokeObjectURL(url)
}

export const IconButton = ({
  icon,
  buttonText,
  onClick,
  isDisabled = false,
  backgroundColor = false
}: {
  icon: any
  buttonText: any
  onClick: any
  isDisabled?: any
  backgroundColor?: any
}) => (
  <div
    onClick={isDisabled ? () => {} : onClick}
    style={{
      backgroundColor: isDisabled
        ? 'gray'
        : backgroundColor
        ? backgroundColor
        : 'gold',
      borderRadius: 5,
      cursor: 'pointer',
      display: 'flex',
      marginRight: 10,
      padding: '8px 16px 8px 16px'
    }}
  >
    <div style={{ marginRight: 10 }}>{icon}</div>
    <div>
      <p style={{ margin: 0 }}>{buttonText}</p>
    </div>
  </div>
)

export default function RcmSystems({ setPin, pin }: { pin: any; setPin: any }) {
  const { accounts, instance } = useMsal()
  const [file, setFile] = useState()
  const [array, setArray]: any = useState([])
  const [importModal, setImportModal]: any = useState(false)
  const [save, setSave]: any = useState(false)
  const [selected, setSelected]: any = useState()
  const [edit, setEdit]: any = useState(false)
  const [formData, setFormData]: any = useState({})
  const [deleteSystem, setDeleteSystem]: any = useState(false)
  const [headerKeys, setHeaderKeys]: any = useState(defaultHeaderKeys)
  const [settings, setSettings]: any = useState(false)

  if (!selected) {
    setPin()
  }

  const fileReader = new FileReader()
  const navigate = useNavigate()

  const fetchSystems = async () => {
    const response = await makeAuthenticatedRequest(
      systemsApi,
      'GET',
      null,
      accounts,
      instance
    )
    return response
  }

  const { data, refetch }: any = useQuery('Systems', fetchSystems)

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0])
  }

  const csvFileToArray = (string: any) => {
    const csvHeader = string
      .slice(0, string.indexOf('\n'))
      .split(',')
      .map((header: string) => header.trim())
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n')

    const array = csvRows.map((i: any) => {
      const values = i.split(',').map((value: string) => value.trim())
      const obj = csvHeader.reduce((object: any, header: any, index: any) => {
        const trimmedValue = values[index].replace(/^"|"$/g, '')
        object[header] = trimmedValue
        return object
      }, {})
      return obj
    })

    setArray(array)
  }

  const handleOnSubmit = (e: any) => {
    e.preventDefault()

    if (file) {
      fileReader.onload = function (event: any) {
        const text = event?.target?.result
        csvFileToArray(text)
      }

      fileReader.readAsText(file)
    }
  }

  const keysToValidate = [
    'System Number',
    'F LOC',
    'Equipment',
    'Failure Mode',
    'Total Annual Price of Confirmation (POC)'
  ]

  const isNumber = (v: any) => {
    if (!v) {
      return false
    }

    if (isNaN(v)) {
      return true
    } else return false
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const updateSystemForm = (
    <Row>
      <Col xs="6">
        <CustomLabel label="System Number" />
        <Input
          type="text"
          name="System Number"
          onChange={handleInputChange}
          defaultValue={selected?.['System Number']}
        />
        <CustomLabel label="System Name" />
        <Input
          type="text"
          name="System Name"
          onChange={handleInputChange}
          defaultValue={selected?.['System Name']}
        />
        <CustomLabel label="System Description" />
        <Input
          type="text"
          name="System Description"
          defaultValue={selected?.['System Description']}
          onChange={handleInputChange}
        />
        <CustomLabel label="F LOC" />
        <Input
          type="text"
          name="F LOC"
          onChange={handleInputChange}
          defaultValue={selected?.['F LOC']}
        />
        <CustomLabel label="Equipment" />
        <Input
          type="text"
          name="Equipment"
          onChange={handleInputChange}
          defaultValue={selected?.['Equipment']}
        />
        <CustomLabel label="Failure Mode" />
        <Input
          type="text"
          name="Failure Mode"
          onChange={handleInputChange}
          defaultValue={selected?.['Failure Mode']}
        />
        <CustomLabel label="Site" />
        <Input
          type="text"
          name="Site"
          onChange={handleInputChange}
          defaultValue={selected?.['Site']}
        />
      </Col>
      <Col xs="6">
        <CustomLabel label="Plant" />
        <Input
          type="text"
          name="Plant"
          onChange={handleInputChange}
          defaultValue={selected?.['Plant']}
        />
        <CustomLabel label="Unit" />
        <Input
          type="text"
          name="Unit"
          onChange={handleInputChange}
          defaultValue={selected?.['Unit']}
        />
        <CustomLabel label="Criticality" />
        <Input
          type="text"
          name="Criticality"
          onChange={handleInputChange}
          defaultValue={selected?.['Criticality']}
        />
        <CustomLabel label="Overall Priority" />
        <Input
          type="text"
          name="Overall Priority"
          onChange={handleInputChange}
          defaultValue={selected?.['Overall Priority']}
        />
        <CustomLabel label="Total Annual Price of Confirmation (POC)" />
        <Input
          type="text"
          name="Total Annual Price of Confirmation (POC)"
          onChange={handleInputChange}
          defaultValue={selected?.['Total Annual Price of Confirmation (POC)']}
        />
        <CustomLabel label="Currency" />
        <Input
          type="text"
          name="Currency"
          onChange={handleInputChange}
          defaultValue={selected?.['Currency']}
        />
      </Col>
    </Row>
  )

  const onEdit = () => {
    setEdit(true)
    setFormData({
      'System Number': selected?.['System Number'],
      'System Name': selected?.['System Name'],
      'System Description': selected?.['System Description'],
      'F LOC': selected?.['F LOC'],
      Equipment: selected?.['Equipment'],
      'Failure Mode': selected?.['Failure Mode'],
      Site: selected?.['Site'],
      Plant: selected?.['Plant'],
      Unit: selected?.['Unit'],
      Criticality: selected?.['Criticality'],
      'Overall Priority': selected?.['Overall Priority'],
      'Total Annual Price of Confirmation (POC)':
        selected?.['Total Annual Price of Confirmation (POC)'],
      Currency: selected?.['Currency']
    })
  }

  return (
    <RcmLayout
      onBack={'/cmms/rcm'}
      title={'RCM'}
      tabHeader={<h4 style={{ margin: 0 }}>Systems</h4>}
    >
      <div style={{ display: 'flex' }}>
        <IconButton
          icon={<ImPushpin size={25} />}
          backgroundColor={pin && 'lightgreen'}
          buttonText={'Pin'}
          onClick={() => setPin(selected)}
          isDisabled={!selected}
        />
        <IconButton
          icon={<FaFileImport size={25} />}
          buttonText={'Import'}
          onClick={() => setImportModal(true)}
        />
        <IconButton
          icon={<FaFileExport size={25} />}
          buttonText={'Export'}
          onClick={() => handleExport(data?.systems)}
        />
        <IconButton
          icon={<FaFileCircleXmark size={25} />}
          buttonText={'Delete'}
          onClick={() => setDeleteSystem(true)}
          isDisabled={!selected}
        />
        <IconButton
          icon={<MdEditDocument size={25} />}
          buttonText={'Edit'}
          onClick={onEdit}
          isDisabled={!selected}
        />
        <IconButton
          icon={<FaSave size={25} />}
          buttonText={'Save'}
          onClick={() => setSave(true)}
          isDisabled={array?.length === 0}
        />
        <IconButton
          icon={<MdCancel size={25} />}
          buttonText={'Cancel Import'}
          isDisabled={array?.length === 0}
          onClick={() => {
            setArray([])
            toast('Import Cancelled')
          }}
        />
        <IconButton
          icon={<IoSettings size={25} />}
          isDisabled={data?.systems?.length === 0}
          buttonText={'Settings'}
          onClick={() => setSettings(true)}
        />
      </div>
      <div style={{ minHeight: '80vh' }}>
        {array?.length > 0 && (
          <>
            <SubHeader header="Imported Data" permitColor="gold" />
            <Table bordered style={{ borderColor: '#000' }}>
              <thead>
                <tr key={'header'}>
                  {headerKeys?.map((key: any) => <th>{key}</th>)}
                </tr>
              </thead>
              <tbody>
                {array.map((item: any, index: any) => (
                  <tr key={`${item?.id}-${item?.['System Number']}`}>
                    {Object.keys(item).map((key: any, index: number) => (
                      <td key={index}>
                        {item[key]}
                        {keysToValidate?.includes(key) &&
                          isNumber(item[key]) && (
                            <p
                              style={{
                                fontSize: 12,
                                color: 'red',
                                margin: 0,
                                fontWeight: 600
                              }}
                            >
                              ! invalid value
                            </p>
                          )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
        {data?.systems?.length > 0 && array?.length === 0 && (
          <>
            <SubHeader header="System Data" permitColor="gold" />
            <Table key="systems data" bordered style={{ borderColor: '#000' }}>
              <thead>
                <tr key={'header'}>
                  <th></th>
                  {headerKeys?.map((key: any) => <th>{key}</th>)}
                </tr>
              </thead>
              <tbody>
                {data?.systems?.map((item: any, index: any) => (
                  <tr key={`${item?.id}-${item?.['System Number']}`}>
                    <td>
                      <Input
                        type="checkbox"
                        checked={selected?._id === item?._id}
                        onChange={() => {}}
                        onClick={(e: any) =>
                          e?.target?.checked ? setSelected(item) : setSelected()
                        }
                      />
                    </td>
                    {headerKeys.map((key: any, index: number) => (
                      <td key={index}>
                        {index === 1 ? (
                          <Link onClick={() => navigate(`${item?._id}`)}>
                            {item[key]}
                          </Link>
                        ) : (
                          item[key]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </div>
      {importModal && (
        <Modal isOpen={importModal} toggle={() => setImportModal(!importModal)}>
          <ModalTitle height={50} title="Import File" />
          <ModalBody>
            <div>
              <form>
                <Input
                  type={'file'}
                  id={'csvFileInput'}
                  accept={'.csv'}
                  onChange={handleOnChange}
                />
                <br />
                <a
                  href={`${getAttachment}/template.csv`}
                  target="_blank"
                  download={'template.csv'}
                  rel="noreferrer"
                >
                  Download Template
                </a>
              </form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={() => setImportModal(false)}>
              Close
            </Button>
            <Button
              color="warning"
              onClick={async (e) => {
                await handleOnSubmit(e)
                setPin()
                setSelected()
                setImportModal(false)
              }}
            >
              Import
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {save && (
        <SubmitModalWithForm
          isOpen={save}
          size="sm"
          onClose={(val: any) => {
            setSave(false)
          }}
          onSubmit={async (e: any) => {
            e.preventDefault()
            try {
              const res = await makeAuthenticatedRequest(
                systemsApi,
                'POST',
                array,
                accounts,
                instance
              )
              if (res?.status === 'success') {
                await refetch()
                setArray([])
                setPin()
                setSelected()
              }

              return res?.status
            } catch (error) {
              console.error(error)
            }
          }}
          header={`Update Systems`}
        />
      )}
      {edit && (
        <SubmitModalWithForm
          isOpen={edit}
          size="lg"
          onClose={(val: any) => {
            setEdit(false)
          }}
          form={updateSystemForm}
          onSubmit={async (e: any) => {
            e.preventDefault()
            try {
              const res = await makeAuthenticatedRequest(
                `${systemsApi}?id=${selected?._id}`,
                'PATCH',
                formData,
                accounts,
                instance
              )

              if (res?.status === 'success') {
                await refetch()
              }

              return res?.status
            } catch (error) {
              console.error(error)
            }
          }}
          header={`Update System `}
        />
      )}
      {deleteSystem && (
        <SubmitModalWithForm
          isOpen={deleteSystem}
          size="lg"
          onClose={(val: any) => {
            setDeleteSystem(false)
          }}
          onSubmit={async (e: any) => {
            e.preventDefault()
            try {
              const res = await makeAuthenticatedRequest(
                `${systemsApi}?id=${selected?._id}`,
                'DELETE',
                null,
                accounts,
                instance
              )

              if (res?.status === 'success') {
                await refetch()
              }

              return res?.status
            } catch (error) {
              console.error(error)
            }
          }}
          form={
            <>
              <p>Are you sure you want to delete System ?</p>
              <Box heading="System Details">
                {Object.keys(selected)?.map(
                  (key: any) =>
                    key !== '_id' &&
                    key !== '__v' &&
                    renderData(key, selected?.[key])
                )}
              </Box>
            </>
          }
          header={`Delete System`}
        />
      )}
      {settings && (
        <Modal
          isOpen={settings}
          toggle={() => setSettings(!settings)}
          size="xl"
        >
          <ModalTitle height={50} title="Settings" />
          <ModalBody>
            <div>
              {defaultHeaderKeys?.map((key: any) => (
                <div>
                  <Input
                    type="checkbox"
                    id={'fLocHeaders'}
                    name={key}
                    value={key}
                    checked={headerKeys?.includes(key)}
                    onChange={(e: any) =>
                      e?.target?.checked
                        ? setHeaderKeys([...headerKeys, key])
                        : setHeaderKeys(
                            headerKeys?.filter((k: any) => k !== key)
                          )
                    }
                  />
                  <label style={{ marginLeft: 10 }}>{key}</label>
                </div>
              ))}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={() => setSettings(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <ToastContainer
        theme="dark"
        position="top-center"
        style={{ fontSize: 16 }}
      />
    </RcmLayout>
  )
}
