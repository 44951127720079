import React from 'react'
import { Col, Row, Container } from 'reactstrap'
import { IoCaretBack } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

import GradientBox from '../../components/GradientBox'
import Spacer from '../../components/Spacer'
import { ViewServices } from '../Services'

export default function EquipmentSerives() {
  const services: any = [
    {
      img: require('./../../../src/images/ement.png'),
      title: 'Equipment',
      redirect: '/equipment/hierarchy'
    },
    {
      img: require('./../../../src/images/mDocuments.png'),
      title: 'MANAGEMENT SYSTEM',
      redirect: '/equipment/documentation'
    }
  ]

  const navigate = useNavigate()

  return (
    <Container fluid style={{ backgroundColor: '#D6F2FF', minHeight: '100vh' }}>
      <Row>
        <Spacer height={10} />
        <Col
          xs="12"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            backgroundColor: 'white',
            padding: '10px 0 10px 0',
            alignItems: 'center'
          }}
        >
          <GradientBox>
            <IoCaretBack
              size={60}
              color="#FFF"
              onClick={() => navigate('/cmms/services')}
            />
          </GradientBox>
          <div style={{ border: '1px solid #ccc' }}>
            <img
              src={require('./../../../src/images/e73_logo.jpeg')}
              alt="HOEC Logo"
              height={120}
            />
          </div>
          <div />
        </Col>
      </Row>
      <ViewServices services={services} />
    </Container>
  )
}
